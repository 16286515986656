import { get } from 'lodash';

export function getOperationFromDateValue(value) {
  if (value) {
    if (value.includes('today')) {
      return 'today';
    } else if (value.includes('add')) {
      return 'add';
    } else if (value.includes('subtract')) {
      return 'subtract';
    } else if (value.includes('date')) {
      return 'date';
    }
  }
  return null;
}

export function getValueFromDateValue(data) {
  const completionDateOperation = getOperationFromDateValue(get(data, 'completionDate.defaultValue'));
  let completionDateValue = get(data, 'completionDate.defaultValue', '').replace(`${completionDateOperation}`, '');
  completionDateValue = completionDateValue.substr(0, 1) === '-'
    ? completionDateValue.substr(1, completionDateValue.length -1)
    : completionDateValue;

  return completionDateValue;
}

import React from 'react';
import PropTypes from 'prop-types';
import { has, get, noop } from 'lodash';
import { CheckboxGroup } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';
import cl from 'classnames';

import Collapsible from '../../../../General/Collapsible';

const WorkflowTreeDisabledWorkguideComponents = React.memo(function WorkflowTreeDisabledWorkguideComponents({
  form,
  language,
  onValueChange,
  validations,
  workguide
}) {
  const options = get(workguide, 'components', [])
    .map((component) => ({
      value: get(component, 'props.id'),
      label: `${get(component, 'props.type')} - ${get(component, `props.title.${language}`)} (${get(component, 'props.id')})`
    }));

  return (
    <Collapsible
      className={cl({
        'workflow-tree-form-edit-connection--collapsible': true,
        'workflow-tree-form-edit-connection--collapsible--invalid': has(validations, 'disabledWorkguideComponents'),
        'workflow-tree-form-edit-connection--disabled-workguide-components': true,
      })}
      isCollapsed
      label={<FormattedMessage id="Workguide.Workflow.Form.EditConnection.Title.DisabledWorkguideComponents" />}
    >
      <div style={{ paddingTop: '1em' }} />
      <CheckboxGroup
        id="disabledWorkguideComponents"
        multi
        onChange={onValueChange}
        options={options}
        value={get(form, 'data.disabledWorkguideComponents')}
      />
    </Collapsible>
  );
});

WorkflowTreeDisabledWorkguideComponents.propTypes = {
  form: PropTypes.object,
  language: PropTypes.string,
  onValueChange: PropTypes.func,
  validations: PropTypes.object,
  workguide: PropTypes.object.isRequired
};

WorkflowTreeDisabledWorkguideComponents.defaultProps = {
  form: {},
  language: 'de',
  onValueChange: noop,
  validations: {}
};

export default WorkflowTreeDisabledWorkguideComponents;

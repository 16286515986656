import { Actions } from '@evoja-web/redaction';

import { initSourceAction } from './InitSource';
import { initTargetAction } from './InitTarget';
import { sourceItemsAction } from './SourceItems';
import { transferItemAction } from './TransferItem';
import { transferModuleAction } from './TransferModule';

export const actions = Actions([
  initSourceAction,
  initTargetAction,
  sourceItemsAction,
  transferItemAction,
  transferModuleAction
]);

export default actions.get();

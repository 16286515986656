import "moment-timezone";
import moment from 'moment';
import { DATE_FORMAT_GRV, DATE_TIMEZONE } from '../constants/DateTime';

export function convertDateToApi(date) {
  const m = date instanceof moment ? date : moment(date);
  if (!m.isValid()) {
    throw new Error(`Date "${date}" is not valid`, {
      date: date,
      format: DATE_FORMAT_GRV,
      timeZone: DATE_TIMEZONE,
    });
  }
  return moment.tz(m.format("YYYY-MM-DD HH:mm:ss"), DATE_TIMEZONE).format(DATE_FORMAT_GRV);
}

export function convertDateToApp(grvDate) {
  const m = moment(grvDate, DATE_FORMAT_GRV);
  if (!m.isValid()) {
    throw new Error(`Date "${grvDate}" is not valid`, {
      date: grvDate,
      format: DATE_FORMAT_GRV,
      timeZone: DATE_TIMEZONE,
    });
  }

  return moment(m.tz(DATE_TIMEZONE).format("YYYY-MM-DD HH:mm:ss"));
}

import React from 'react';
import { Button } from 'react-bootstrap';
import ListItem from './ListItem';

export default class List extends React.Component {
  /**
   * Render the list
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      items, durations, useTypes, loanProducts, language, onItemChange, onItemRemove, onItemAdd, validate, onItemDuplicate,
    } = this.props;

    const list = items.map((item, key) => {
      return(
        <ListItem
          item={item}
          durations={durations}
          useTypes={useTypes}
          loanProducts={loanProducts}
          language={language}
          key={key}
          onItemChange={onItemChange}
          onItemRemove={onItemRemove}
          onItemAdd={onItemAdd}
          onItemDuplicate={onItemDuplicate}
          validate={validate}>
        </ListItem>
      );
    });

    return(
      <div className="SortableHOCList">
        { list }

        <Button
          bsStyle="primary"
          name="newLink"
          onClick={ onItemAdd.bind(this) }>
          <i className="icon-061-plus"></i>
          Neuer Eintrag
        </Button>
      </div>
    )
  }
}

import React, { Component } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { InputGroup, FormControl, Modal, Button } from 'react-bootstrap';
import DragHandle from '../DragHandle';

import InvestmentProductDetails from './InvestmentProductDetails';

class Item extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
    };
  }

  modalOpen() {
    this.setState({
      modalOpen: true,
    });
  }

  modalClose() {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    const {
      id, item, language, onRemove, editEventField, editValueField,
      knowledgeExperiences, assetSegments, assetClasses, assetAllocations,
      investmentStrategies, serviceSubTypes, investmentDocuments,
    } = this.props;

    return (
      <div className="SortableHOCItem">
        <InputGroup className="navbar-form" style={{flexGrow: 1}}>
          <InputGroup.Addon>
            <DragHandle />
          </InputGroup.Addon>
          <FormControl
            style={{width: "80%"}}
            type="text"
            name="itemName"
            value={item.name[language]}
            onChange={editEventField.bind(this, 'name', id)}
          />
          <FormControl
            componentClass="select"
            style={{width: "20%"}}
            value={item.productType}
            onChange={editEventField.bind(this, 'productType', id)}
          >
            <option value="saving">Ersparnisse</option>
            <option value="investment">Investition</option>
          </FormControl>
          <InputGroup.Addon style={{ cursor: 'pointer' }}>
            <span style={{ marginRight: '10px' }}>
              <i className="icon-213-setting" onClick={this.modalOpen.bind(this)}>
                <span className="hidden">remove</span>
              </i>
            </span>
            <span onClick={onRemove.bind(this, id)}>
              <i className="icon-008-bin"><span className="hidden">remove</span></i>
            </span>
          </InputGroup.Addon>
        </InputGroup>
        <Modal show={this.state.modalOpen} onHide={this.modalClose.bind(this)} bsSize="large">
          <Modal.Header closeButton>
            <Modal.Title>Detaileinstellungen für {item.name[language]}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InvestmentProductDetails
              id={id}
              editEventField={editEventField}
              editValueField={editValueField}
              language={language}
              item={item}
              knowledgeExperiences={knowledgeExperiences}
              investmentDocuments={investmentDocuments}
              assetAllocations={assetAllocations}
              assetSegments={assetSegments}
              assetClasses={assetClasses}
              investmentStrategies={investmentStrategies}
              serviceSubTypes={serviceSubTypes}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.modalClose.bind(this)}><span className="icon-202-clear-circle" />
              Schliessen
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

const SortableItem = SortableElement(Item);

export default class MyElement extends Component {
  render() {
    const {
      id, item, type, language, onRemove, editEventField, editValueField,
      knowledgeExperiences, assetSegments, assetAllocations, assetClasses,
      serviceSubTypes, investmentStrategies, investmentDocuments,
    } = this.props;
    return (
      <SortableItem
        index={id}
        id={id}
        item={item}
        investmentDocuments={investmentDocuments}
        type={type}
        knowledgeExperiences={knowledgeExperiences}
        assetSegments={assetSegments}
        assetAllocations={assetAllocations}
        assetClasses={assetClasses}
        serviceSubTypes={serviceSubTypes}
        investmentStrategies={investmentStrategies}
        editEventField={editEventField}
        editValueField={editValueField}
        onRemove={onRemove}
        language={language}
      />
    );
  }
}

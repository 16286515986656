import { createSelector } from 'reselect';
import { getLanguage } from '../commonSelectors';
import { getEmailSubscriptionsEntityCodes } from './emailSubscriptionsCommonSelectors';

export const getEmailSubscriptionsTypeOptions = createSelector([
  getEmailSubscriptionsEntityCodes,
  getLanguage,
], (entityCodes, locale) => getOptionsByGroup(entityCodes, 'subscriptionType', locale));

export const getEmailSubscriptionsStateOptions = createSelector([
  getEmailSubscriptionsEntityCodes,
  getLanguage,
], (entityCodes, locale) => getOptionsByGroup(entityCodes, 'subscriptionState', locale));

function getOptionsByGroup(options, group, locale) {
  return options
    .filter((it) => it.group === group)
    .map((it) => ({ label: it.text[locale], value: it.id }));
}

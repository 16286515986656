import { CONSULTANTS_UPDATE_ACTIONS } from '../../actions/ConsultantsActions';

export function consultantsUpdateReducer(state, action) {
  switch (action.type) {
    case CONSULTANTS_UPDATE_ACTIONS.PENDING:
      return {
        ...state,
        saving: true,
      };

    case CONSULTANTS_UPDATE_ACTIONS.SUCCESS:
      return {
        ...state,
        saving: false,
        data: {
          ...state.data,
          consultants: state.data.consultants.map(it => it.id === action.data.consultant.id ? action.data.consultant : it),
        },
      };

    case CONSULTANTS_UPDATE_ACTIONS.FAILURE:
      return {
        ...state,
        saving: false,
        error: action.data,
      };

    default:
      return state;
  }
}

export const financingProfilingColumnsOrder = {
  'financingProfile-safe': 0,
  'financingProfile-balanced': 1,
  'financingProfile-dynamic': 2,
};

export const financingProfilingRowsOrder = {
  'financingProfilingPrediction-up': 0,
  'financingProfilingPrediction-stay': 1,
  'financingProfilingPrediction-down': 2,
};

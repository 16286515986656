import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { Button } from 'react-bootstrap';

import Item from './Item';

const Items = SortableContainer(({
  items, collateralTypes, type, language, onAdd, onRemove, editEventField, editValueField,
}) => {
  const aItems = items.map((item, i) => (
    <Item
      key={i}
      index={i}
      id={i}
      item={item}
      collateralTypes={collateralTypes}
      type={type}
      language={language}
      onRemove={onRemove}
      editEventField={editEventField}
      editValueField={editValueField}
    />
  ));

  return (
    <div>
      <div className="SortableHOCList">
        {aItems}
      </div>
      <Button
        bsStyle="primary"
        name="newItem"
        onClick={onAdd.bind(this)}
      >
        <span className="icon-061-plus" /> Neuer Eintrag
      </Button>
    </div>
  );
});

export default Items;

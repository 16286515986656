import { createSelector } from 'reselect';
import { getAnalyticsLogsConsultants, getAnalyticsLogsState } from './analyticsLogsCommonSelectors';

const getRequestDetails = createSelector([getAnalyticsLogsState], (state) => state.requestDetails);
export const getAnalyticsLogsRequestDetails = createSelector([
  getRequestDetails,
  getAnalyticsLogsConsultants,
], (requestDetails, consultants) => {
  if (!requestDetails) {
    return undefined;
  }

  const user = consultants.find((it) => it.username.toLowerCase() === requestDetails.username.toLowerCase());
  return {
    id: requestDetails.id,
    username: requestDetails.username,
    name: user
      ? [user.firstName, user.lastName].filter(Boolean).join(' ')
      : requestDetails.username,
    department: user ? user.department : '',
    division: user ? user.division : '',
    createdAt: requestDetails.createdAt,
    method: requestDetails.method,
    requestTimeS: requestDetails.requestTimeS,
    requestUri: requestDetails.requestUri,
    responseCode: requestDetails.responseCode,
    responseBody: formatRequestBody(requestDetails.responseBody),
    requestBody: formatRequestBody(requestDetails.requestBody),
    userAgent: requestDetails.userAgent,
  };
});

function formatRequestBody(requestBody) {
  try {
    const body = JSON.parse(requestBody);
    return JSON.stringify(body, null, 2);
  } catch (e) {
    return requestBody;
  }
}

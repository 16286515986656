import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isUndefined } from 'lodash';
import { FormGroup, ControlLabel, Col, InputGroup, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from '../../DatePicker';

import moment from 'moment';

const filterAdditionalOptions = {
  select: [
    {key: 'selectIncludes', text: 'Beinhaltet'},
    {key: 'selectExcludes', text: 'Beinhaltet nicht'}
  ],
  date: [
    {key: 'dateEq', text: 'Gleich'},
    {key: 'dateLe', text: 'Kleiner gleich'},
    {key: 'dateGe', text: 'Grösser gleich'}
  ],
  text: [
    {key: 'stringContains', text: 'Beinhaltet'},
    {key: 'stringStartsWith', text: 'Startet mit'},
    {key: 'stringEndsWith', text: 'Endet mit'}
  ]
}

const statusFilterSelectOptions = [
  {value: 'Active',label: 'Aktiv'},
  {value: 'Invalid',label: 'Ungültig'},
  {value: 'Inactive',label: 'Inaktiv'},
  {value: 'Deleted',label: 'Gelöscht'},
  {value: 'InSight',label: 'In Visierung'},
  {value: 'New',label: 'Neu'},
  {value: 'NotSighted',label: 'Nicht Visiert'},
  {value: 'Pending',label: 'Pendent'}
];

class EDossierCustomerDocuments extends React.PureComponent {
  constructor(props) {
    super(props);

    const { id, fetchCodes, editValueField } = props;

    this.state = {
      filename: undefined,
      documentType: undefined
    };

    fetchCodes('archiveDocumentType');
    fetchCodes('archiveMainType');
    fetchCodes('archiveSubType');
    fetchCodes('documentFindCode');

    // Set hideValidations to true. Component will render validations itself
    editValueField(id, 'hideValidations', true);
  }

  onChangeAdditionalOptions(value, fieldId) {
    const { id, item, editValueField} = this.props;
    const props = get(item, 'props');

    const updated = this.updateAdditionalFilterOptions(props, fieldId, value);

    editValueField(id, 'prop', { key: 'specialComponentConfig', value: updated});
  }

  /**
   * Handle onChange of date fields
   *
   * @param  {Date} archiveDate ArchiveDate
   *
   * @return void
   */
  onChangeDate(dateValue, fieldId) {
    const { id, item, editValueField} = this.props;
    const props = get(item, 'props');

    const updated = this.updateSpecialComponentConfig(props, fieldId, dateValue, 'date');

    editValueField(id, 'prop', { key: 'specialComponentConfig', value: updated});
  }

  /**
   * Handle onChange of select Fields.
   *
   * @param  {Object} selected Selected option
   *
   * @return void
   */
  onChangeSelect(selected, fieldId, multi, typeOfFilter) {
    const { id, item, editValueField} = this.props;
    const props = get(item, 'props');
    let value;
    if (multi) {
      value = isUndefined(selected)
        ? undefined
        : selected.map(s => s.value);
    } else {
      value = get(selected, 'value');
    }

    const updated = this.updateSpecialComponentConfig(props, fieldId, value, typeOfFilter);

    editValueField(id, 'prop', { key: 'specialComponentConfig', value: updated});
  }

  getSpecialConfigurationValue(key, propertyKey, defaultValue = false) {
    const { item } = this.props;

    return get(
      get(item, 'props.specialComponentConfig', []).find((s) => get(s, 'key') === key),
      propertyKey,
      defaultValue
    );
  }

  updateSpecialComponentConfig(props, itemToUpdate, value, type) {
    const specialComponentConfigList = get(props, 'specialComponentConfig', []);

    const filteredSpecialComponentConfigList = specialComponentConfigList.filter((s) => get(s, 'key') !== itemToUpdate);
    const updatedElement = specialComponentConfigList.find((s) => get(s, 'key') === itemToUpdate);

    const filterOption = get(updatedElement, 'filterOption', get(filterAdditionalOptions, `${type}.0`))

    const updated = [
      ...filteredSpecialComponentConfigList,
      {
        key: itemToUpdate,
        value,
        type: type,
        filterOption: filterOption
      }
    ]
    return updated;
  }

  updateAdditionalFilterOptions(props, itemToUpdate, value) {
    const specialComponentConfigList = get(props, 'specialComponentConfig', []);

    const filteredSpecialComponentConfigList = specialComponentConfigList.filter((s) => get(s, 'key') !== itemToUpdate);
    const updatedElement = specialComponentConfigList.find((s) => get(s, 'key') === itemToUpdate);

    const updated = [
      ...filteredSpecialComponentConfigList,
      {
        ...updatedElement,
        filterOption: value
      }
    ]
    return updated;
  }

  render() {
    const {
      codes,
      language
    } = this.props;

    const archiveDate = this.getSpecialConfigurationValue('archiveDate', 'value', moment());
    const momentValue = moment(new Date(archiveDate), 'DD.MM.YYYY', true);
    const momentValueFormatted = momentValue.format('L');

    return (
      <React.Fragment>
        <Col sm={2} />
        <Col sm={10} style={{ paddingTop: '10px' }}>
          <h3><strong>Tabellen Filter</strong></h3>
        </Col>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Archivierungsdatum
          </Col>
          <Col sm={6} style={{ paddingTop: '10px' }}>
            <InputGroup>
              <FormControl
                type="input"
                value={momentValue.isValid() ? momentValueFormatted : ''}
                onChange={(value) => this.onChangeDate(value, 'archiveDate')}
              />
              <InputGroup.Addon>
                <DatePicker
                  onDateChange={(value) => this.onChangeDate(value, 'archiveDate')}
                  value={momentValue.isValid() ? momentValue : undefined}
                />
              </InputGroup.Addon>
            </InputGroup>
          </Col>
          <Col sm={3} style={{ paddingTop: '10px' }}>
            <Select
              disabled={!this.getSpecialConfigurationValue('archiveDate', 'value')}
              value={this.getSpecialConfigurationValue('archiveDate', 'filterOption')}
              options={get(filterAdditionalOptions, 'date').map(c => ({ value: c.key, label: c.text }))}
              onChange={(selected) => this.onChangeAdditionalOptions(selected, 'archiveDate', false)}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            Typ
          </Col>
          <Col sm={6} style={{ paddingTop: '10px' }}>
            <Select
              value={this.getSpecialConfigurationValue('type', 'value')}
              options={get(codes, 'archiveDocumentType', []).map(c => ({ value: c.id, label: get(c, `text.${language}`) }))}
              onChange={(selected) => this.onChangeSelect(selected, 'type', false, 'text')}
            />
          </Col>
          <Col sm={3} style={{ paddingTop: '10px' }}>
            <Select
              disabled={!this.getSpecialConfigurationValue('type', 'value')}
              value={this.getSpecialConfigurationValue('type', 'filterOption')}
              options={get(filterAdditionalOptions, 'text').map(c => ({ value: c.key, label: c.text }))}
              onChange={(selected) => this.onChangeAdditionalOptions(selected, 'type', false)}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            Kategorie
          </Col>

          <Col sm={6} style={{ paddingTop: '10px' }}>
            <Select
              value={this.getSpecialConfigurationValue('categorie', 'value')}
              options={get(codes, 'archiveMainType', []).map(c => ({ value: c.id, label: get(c, `text.${language}`) }))}
              onChange={(selected) => this.onChangeSelect(selected, 'categorie', false, 'text')}
            />
          </Col>
          <Col sm={3} style={{ paddingTop: '10px' }}>
            <Select
              disabled={!this.getSpecialConfigurationValue('categorie', 'value')}
              value={this.getSpecialConfigurationValue('categorie', 'filterOption')}
              options={get(filterAdditionalOptions, 'text').map(c => ({ value: c.key, label: c.text }))}
              onChange={(selected) => this.onChangeAdditionalOptions(selected, 'categorie', false)}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            Subkategorie
          </Col>

          <Col sm={6} style={{ paddingTop: '10px' }}>
            <Select
              value={this.getSpecialConfigurationValue('subCategorie', 'value')}
              options={get(codes, 'archiveSubType', []).map(c => ({ value: c.id, label: get(c, `text.${language}`) }))}
              onChange={(selected) => this.onChangeSelect(selected, 'subCategorie', false, 'text')}
            />
          </Col>
          <Col sm={3} style={{ paddingTop: '10px' }}>
            <Select
              disabled={!this.getSpecialConfigurationValue('subCategorie', 'value')}
              value={this.getSpecialConfigurationValue('subCategorie', 'filterOption')}
              options={get(filterAdditionalOptions, 'text').map(c => ({ value: c.key, label: c.text }))}
              onChange={(selected) => this.onChangeAdditionalOptions(selected, 'subCategorie', false)}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Dokumentstatus
          </Col>

          <Col sm={6} style={{ paddingTop: '10px' }}>
            <Select
              type="text"
              value={this.getSpecialConfigurationValue('status', 'value')}
              options={statusFilterSelectOptions}
              onChange={(selected) => this.onChangeSelect(selected, 'status', true, 'select')}
              multi={true}
            />
          </Col>
          <Col sm={3} style={{ paddingTop: '10px' }}>
            <Select
              disabled={!this.getSpecialConfigurationValue('status', 'value')}
              value={this.getSpecialConfigurationValue('status', 'filterOption')}
              options={get(filterAdditionalOptions, 'select').map(c => ({ value: c.key, label: c.text }))}
              onChange={(selected) => this.onChangeAdditionalOptions(selected, 'status', false)}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Auffindcode
          </Col>

          <Col sm={6} style={{ paddingTop: '10px' }}>
            <Select
              value={this.getSpecialConfigurationValue('trackingCode', 'value')}
              options={get(codes, 'documentFindCode', []).map(c => ({ value: c.id, label: get(c, `text.${language}`) }))}
              onChange={(selected) => this.onChangeSelect(selected, 'trackingCode', false, 'text')}
            />
          </Col>
          <Col sm={3} style={{ paddingTop: '10px' }}>
            <Select
              disabled={!this.getSpecialConfigurationValue('trackingCode', 'value')}
              value={this.getSpecialConfigurationValue('trackingCode', 'filterOption')}
              options={get(filterAdditionalOptions, 'text').map(c => ({ value: c.key, label: c.text }))}
              onChange={(selected) => this.onChangeAdditionalOptions(selected, 'trackingCode', false)}
            />
          </Col>
        </FormGroup>
      </React.Fragment>
    )
  }
}


EDossierCustomerDocuments.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  item: PropTypes.object.isRequired,
  codeGroups: PropTypes.array,
  codes: PropTypes.object,
  fetchCodes: PropTypes.func,
  editValueField: PropTypes.func,
  language: PropTypes.string
};

EDossierCustomerDocuments.defaultProps = {
  editValueField: noop,
  codeGroups: [],
  codes: {},
  fetchCodes: noop,
  language: 'de'
};

export default EDossierCustomerDocuments;

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ListGroup, Button } from 'react-bootstrap';

import ListItem from './ListItem';
import Form from './Form';

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      add: false
    };

    this.toggleForm = this.toggleForm.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  toggleForm(add) {
    this.setState({ add: add || !this.state.add })
  }

  onSave(code) {
    const { onSave } = this.props;
    this.toggleForm(false);

    onSave(code);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { language, codes, onSave, onDelete } = this.props;

    const children = codes.map(c => (
      <ListItem
        key={c.id}
        language={language}
        data={c}
        onSave={onSave}
        onDelete={onDelete}
      />
    ));

    return (
      <React.Fragment>
        <ListGroup>
          {children}
        </ListGroup>

        {(this.state.add) ? (
          <Form
            isNew={true}
            onSubmit={this.onSave}
            onCancel={this.toggleForm}
          />
        ) : null}

        <Button bsStyle="primary" onClick={() => this.toggleForm(true)}>
          <span style={{ fontSize: '20px' }}>+</span>
        </Button>
      </React.Fragment>
    );
  }
}

List.propTypes = {
  language: PropTypes.string,
  codes: PropTypes.array,
  onSave: PropTypes.func,
  onDelete: PropTypes.func
};

List.defaultProps = {
  language: 'de',
  codes: [],
  onSave: _.noop,
  onDelete: _.noop
};

export default List;

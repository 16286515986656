import './HolidaysModeYear.css';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { HolidaysCalendarMonth } from './HolidaysCalendarMonth';
import { ANALYTICS_PROP_TYPES_LOCALE } from '../Analytics/AnalyticsPropTypes';

export class HolidaysModeYear extends React.Component {
  render() {
    const { onSelectMonth, year, holidays } = this.props;
    return (
      <div className="holidays-mode-year">
        {moment.months().map((title, index) => (
          <div className="holidays-mode-year__month" key={index}>
            <HolidaysCalendarMonth setMonth={onSelectMonth}
                                   title={title}
                                   year={year}
                                   month={index}
                                   holidays={holidays}/>
          </div>
        ))}
      </div>
    );
  }
}

HolidaysModeYear.propTypes = {
  year: PropTypes.number,
  holidays: PropTypes.array,
  onSelectMonth: PropTypes.func,
  locale: ANALYTICS_PROP_TYPES_LOCALE,
};

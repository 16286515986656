import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import {
  GET_METADATA_PENDING,
  GET_METADATA_FULFILLED,
  GET_METADATA_REJECTED
} from '../../actions/WorkguideActions';
import getConsultants from './getConsultants';
import getCodeGroups from './getCodeGroups';

export default function* getWorkguideMetadata(request) {
  yield put({ type: GET_METADATA_PENDING });

  try {
    if (request.toload.consultants) {
      yield call(getConsultants);
    }

    if (request.toload.codegroups) {
      yield call(getCodeGroups);
    }

    const payload = yield call(handleNextHeaders, {
      url: '/work/workguide/?select(title,keyword,type,group,active,defaultAssignee,excludeFromParamTransfer,target)&limit(1000)'
    });

    yield put({ type: GET_METADATA_FULFILLED, payload, data: payload });

    return payload;
  } catch (error) {
    yield put({ type: GET_METADATA_REJECTED, error, payload: error });

    return error;
  }
}

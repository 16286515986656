import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getFinancingRatesBaseRates,
  getFinancingRatesDurations,
  getFinancingRatesError,
  getFinancingRatesForwards,
  getFinancingRatesLoading,
  getFinancingRatesRefiRates,
} from '../selectors/FinancingRates/financingRatesCommonSelectors';
import { getFinancingRatesForwardPeriods } from '../selectors/FinancingRates/financingRatesForwardSelectors';
import { FinancingRates } from '../components/FinancingRates/FinancingRates';
import {
  FINANCING_RATES_LOAD_ACTIONS,
  FINANCING_RATES_SAVE_ACTIONS, FINANCING_RATES_SYNC_FORWARDS_ACTIONS,
  FINANCING_RATES_UPDATE_BASE_RATE_ACTIONS,
  FINANCING_RATES_UPDATE_FORWARDS_ACTIONS,
  FINANCING_RATES_UPDATE_REFI_RATE_ACTIONS,
} from '../actions/FinancingRatesActions';

const mapStateToProps = createStructuredSelector({
  durations: getFinancingRatesDurations,
  forwards: getFinancingRatesForwards,
  baseRates: getFinancingRatesBaseRates,
  refiRates: getFinancingRatesRefiRates,
  forwardPeriods: getFinancingRatesForwardPeriods,
  loading: getFinancingRatesLoading,
  error: getFinancingRatesError,
});

const mapDispatchToProps = {
  load: () => FINANCING_RATES_LOAD_ACTIONS.request(),
  save: () => FINANCING_RATES_SAVE_ACTIONS.request(),
  updateBaseRate: (item, value) => FINANCING_RATES_UPDATE_BASE_RATE_ACTIONS.request({ item, value }),
  updateRefiRate: (item, value) => FINANCING_RATES_UPDATE_REFI_RATE_ACTIONS.request({ item, value }),
  updateForwards: (duration, forwards) => FINANCING_RATES_UPDATE_FORWARDS_ACTIONS.request({ duration, forwards }),
  syncForwards: (forward) => FINANCING_RATES_SYNC_FORWARDS_ACTIONS.request({ forward }),
};

export const FinancingRatesPage = connect(mapStateToProps, mapDispatchToProps)(FinancingRates);

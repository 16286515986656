import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PageContainer from '../components/Common/PageContainer';
import { getLanguage } from '../selectors/commonSelectors';

import EventstatusDashboard from '@evoja-web/event-status-dashboard';

function EventstatusPage(props) {
  const { language } = props;

  return (
    <PageContainer
      title={`Eventstatus`}
    >
      <EventstatusDashboard
        language={language}
        isAdmin={true}
      />
    </PageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  language: getLanguage
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(EventstatusPage);
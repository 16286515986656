import './SetManagementBundle.css';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { arrayMove } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import { SortableList } from '../Common/SortableList';
import { SelectControl } from '../Common/SelectControl';
import { SetManagementBundleItem } from './SetManagementBundleItem';
import { Table, TableCell, TableHead } from '../Common/Table';
import { sortByAll } from '../../utils';

export class SetManagementBundle extends React.Component {
  state = {
    domain: undefined,
  };

  render() {
    const { domains } = this.props;
    const { domain } = this.state;

    return (
      <div className="set-management-bundle">
        <div className="set-management-bundle__title">
          <FormattedMessage id="setManagement_bundle_title"/>
        </div>

        <div className="set-management-bundle__domain">
          <FormattedMessage id="setManagement_bundle_filterDomain"/>
          <SelectControl
            className="set-management-bundle__domain-select"
            value={domain}
            multi={false}
            options={domains}
            onChange={this.onSelectDomain}
          />
        </div>

        <SortableList onSortEnd={this.onSort} className="set-management-bundle__list">
          <Table>
            <TableHead>
              <TableCell className="set-management-bundle__item-name">
                <FormattedMessage id="setManagement_bundle_name"/>
              </TableCell>
              <TableCell className="set-management-bundle__item-domain">
                <FormattedMessage id="setManagement_bundle_domain"/>
              </TableCell>
              <TableCell className="set-management-bundle__item-bundle-price">
                <FormattedMessage id="setManagement_bundle_bundlePrice"/>
              </TableCell>
              <TableCell className="set-management-bundle__item-bundle-price-reduced">
                <FormattedMessage id="setManagement_bundle_bundlePriceReduced"/>
              </TableCell>
              <TableCell className="set-management-bundle__item-bundle-price-youth">
                <FormattedMessage id="setManagement_bundle_bundlePriceYouth"/>
              </TableCell>
              <TableCell colSpan={2}/>
            </TableHead>
            {this.getBundles().map((bundle, index) => (
              <SetManagementBundleItem
                key={bundle.id}
                index={index}
                id={index}
                bundle={bundle}
                domains={domains}
                onChange={this.onChange}
              />
            ))}
          </Table>
        </SortableList>
      </div>
    );
  }

  onSelectDomain = (domain) => this.setState({ domain });

  onSort = ({ oldIndex, newIndex }) => {
    const { bundles, onChange } = this.props;
    const sorted = arrayMove(this.getBundles(), oldIndex, newIndex)
      .map((item, index) => ({ ...item, order: index }));

    onChange(
      bundles
        .map((bundle) => {
          const sortedBundle = sorted.find((it) => it.id === bundle.id);
          return sortedBundle ? sortedBundle : bundle;
        })
        .sort(sortByAll((it) => it.order)))
  };

  onChange = (bundle) => {
    const { bundles, onChange } = this.props;
    onChange(
      bundles.map((it) => it.id === bundle.id ? bundle : it)
    );
  };

  getBundles() {
    const { bundles } = this.props;
    const { domain } = this.state;

    return domain
      ? bundles.filter((it) => it.domain === domain.value)
      : bundles;
  }
}

SetManagementBundle.propTypes = {
  bundles: PropTypes.array.isRequired,
  domains: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

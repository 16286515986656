import { takeLatest } from 'redux-saga/effects';

import actions from '../actions/Actions';
import initWorkflowTreeConfig from './Workflow/initConfig';
import initWorkflowTreeEditConnectionForm from './Workflow/initEditConnectionForm';
import initWorkflowTreeEditNodeForm from './Workflow/initEditNodeForm';
import saveWorkflowTreeConfig from './Workflow/saveTreeConfig';
import saveWorkflowTreeEditNodeForm from './Workflow/saveEditNodeForm';

const {
  WORKFLOW_TREE_CONFIG_INIT_REQUEST,
  WORKFLOW_TREE_CONFIG_SAVE_REQUEST,
  WORKFLOW_TREE_EDIT_CONNECTION_FORM_INIT_REQUEST,
  WORKFLOW_TREE_EDIT_NODE_FORM_INIT_REQUEST,
  WORKFLOW_TREE_EDIT_NODE_FORM_SAVE_REQUEST
} = actions;

export default function* workguideRootSaga() {
  yield takeLatest(WORKFLOW_TREE_CONFIG_INIT_REQUEST, initWorkflowTreeConfig);
  yield takeLatest(WORKFLOW_TREE_CONFIG_SAVE_REQUEST, saveWorkflowTreeConfig);
  yield takeLatest(WORKFLOW_TREE_EDIT_CONNECTION_FORM_INIT_REQUEST, initWorkflowTreeEditConnectionForm);
  yield takeLatest(WORKFLOW_TREE_EDIT_NODE_FORM_INIT_REQUEST, initWorkflowTreeEditNodeForm);
  yield takeLatest(WORKFLOW_TREE_EDIT_NODE_FORM_SAVE_REQUEST, saveWorkflowTreeEditNodeForm);
}

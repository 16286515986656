import { compareRefs, createRef } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';

export function mapBasicDocumentsToApp(product, basicDocuments) {
  const basicDocument = basicDocuments.find((document) => compareRefs(
    createRef(EndpointName.BASIC_PRODUCT, product.id),
    document.product.$ref,
  ));

  return basicDocument && basicDocument.document
    ? basicDocument.document
    : [];
}

export function mapBasicDocumentsToApi(product) {
  return {
    product: {
      $ref: createRef(EndpointName.BASIC_PRODUCT, product.id),
    },
    document: product.documents,
  };
}

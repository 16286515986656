import {
  EMAIL_SUBSCRIPTIONS_IMPORT_CSV,
  EMAIL_SUBSCRIPTIONS_LOAD,
  EMAIL_SUBSCRIPTIONS_REMOVE,
  EMAIL_SUBSCRIPTIONS_REMOVE_MARKETING,
  EMAIL_SUBSCRIPTIONS_SAVE,
  EMAIL_SUBSCRIPTIONS_SEARCH,
  EMAIL_SUBSCRIPTIONS_UPDATED,
} from '../actions/EmailSubscriptionsActions';
import { EMAIL_SUBSCRIPTIONS_DEFAULT_SORT } from '../constants/EmailSubscriptionsConstants';
import { REDUCER_STATUS_FULFILLED, REDUCER_STATUS_FAILURE, REDUCER_STATUS_PENDING } from '../constants/ReducerStatuses';

const initialState = {
  loading: false,
  error: undefined,
  items: [],
  deletedResult: [],
  totalCount: 0,
  entityCodes: [],
  searchParams: {
    ...EMAIL_SUBSCRIPTIONS_DEFAULT_SORT,
  },
  uploadResult: {
    loading: false,
    errors: undefined,
    results: undefined,
    status: undefined,
  }
};

export function EmailSubscriptionsReducer(state = initialState, action) {
  switch (action.type) {
    case EMAIL_SUBSCRIPTIONS_LOAD.PENDING:
      return { ...state, loading: true, error: undefined };
    case EMAIL_SUBSCRIPTIONS_LOAD.SUCCESS: {
      const { items, totalCount, entityCodes } = action.data;
      return { ...state, loading: false, error: undefined, items, totalCount, entityCodes };
    }
    case EMAIL_SUBSCRIPTIONS_LOAD.FAILURE:
      return { ...state, loading: false, error: action.data };

    case EMAIL_SUBSCRIPTIONS_SEARCH.PENDING:
      return { ...state, loading: true, error: undefined };
    case EMAIL_SUBSCRIPTIONS_SEARCH.SUCCESS: {
      const { searchParams, items, totalCount } = action.data;
      return { ...state, loading: false, items, searchParams, totalCount };
    }
    case EMAIL_SUBSCRIPTIONS_SEARCH.FAILURE:
      return { ...state, loading: false, error: action.data };

    case EMAIL_SUBSCRIPTIONS_SAVE.PENDING:
      return { ...state, loading: true, error: undefined };
    case EMAIL_SUBSCRIPTIONS_SAVE.SUCCESS: {
      const { items, totalCount } = action.data;
      return { ...state, loading: false, error: undefined, items, totalCount };
    }
    case EMAIL_SUBSCRIPTIONS_SAVE.FAILURE:
      return { ...state, loading: false, error: action.data };

    case EMAIL_SUBSCRIPTIONS_REMOVE.PENDING:
      return { ...state, loading: true, error: undefined };
    case EMAIL_SUBSCRIPTIONS_REMOVE.SUCCESS: {
      const { items, totalCount } = action.data;
      return { ...state, loading: false, error: undefined, items, totalCount };
    }
    case EMAIL_SUBSCRIPTIONS_REMOVE.FAILURE:
      return { ...state, loading: false, error: action.data };

    case EMAIL_SUBSCRIPTIONS_REMOVE_MARKETING.PENDING:
      return { ...state, loading: true, error: undefined };

    case EMAIL_SUBSCRIPTIONS_REMOVE_MARKETING.SUCCESS: {
      const { results } = action.data;
      return { ...state, loading: false, error: undefined, deletedResult: results };
    }
    case EMAIL_SUBSCRIPTIONS_REMOVE_MARKETING.FAILURE:
      return { ...state, loading: false, error: action.data };

    case EMAIL_SUBSCRIPTIONS_UPDATED.SUCCESS:
      const { data } = action.data;
      const index = state.items.findIndex(el => el.id === data.id);
      state.items[index] = data;

      return { ...state };

    case EMAIL_SUBSCRIPTIONS_IMPORT_CSV.PENDING:
      return {
        ...state,
        uploadResult: {
          loading: true,
          errors: undefined,
          status: REDUCER_STATUS_PENDING,
        }
      };
    case EMAIL_SUBSCRIPTIONS_IMPORT_CSV.SUCCESS: {
      return {
        ...state,
        uploadResult: {
          loading: false,
          errors: undefined,
          results: action.data,
          status: REDUCER_STATUS_FULFILLED,
        },
      };
    }
    case EMAIL_SUBSCRIPTIONS_IMPORT_CSV.FAILURE:
      return {
        ...state,
        uploadResult: {
          loading: false,
          errors: action.data.errors,
          apiError: action.data.apiError,
          status: REDUCER_STATUS_FAILURE,
        }
      };

    default:
      return state;
  }
}

import './SetManagementBundleItem.css';

import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DragHandle from '../DragHandle';
import { SortableElement } from 'react-sortable-hoc';
import { ModalBody, ModalDialog, ModalHeader } from '../Common/Modal';
import { TableCell, TableRow } from '../Common/Table';
import { SetManagementBundleEdit } from './SetManagementBundleEdit';

class Item extends React.Component {
  state = {
    isModalOpen: false,
  };

  render() {
    const { bundle, domains } = this.props;
    const domain = domains.find((it) => it.value === bundle.domain);

    return (
      <TableRow className="set-management-bundle-item SortableHOCItem">
        <TableCell className="set-management-bundle-item__name">
          {bundle.name}
        </TableCell>
        <TableCell className="set-management-bundle-item__domain">
          {domain ? domain.label : null}
        </TableCell>
        <TableCell className="set-management-bundle-item__bundle-price">
          {bundle.bundlePrice && <FormattedNumber value={bundle.bundlePrice}/>}
        </TableCell>
        <TableCell className="set-management-bundle-item__bundle-price-reduced">
          {bundle.bundlePriceReduced && <FormattedNumber value={bundle.bundlePriceReduced}/>}
        </TableCell>
        <TableCell className="set-management-bundle-item__bundle-price-youth">
          {!isNaN(bundle.bundlePriceYouth) && <FormattedNumber value={bundle.bundlePriceYouth}/>}
        </TableCell>
        <TableCell className="set-management-bundle-item__control">
          <span className="icon-025-edit" onClick={this.openModal}/>
          {this.renderModal()}
        </TableCell>
        <TableCell className="set-management-bundle-item__drag">
          <DragHandle/>
        </TableCell>
      </TableRow>
    );
  }

  renderModal() {
    const { isModalOpen } = this.state;
    const { bundle, domains } = this.props;

    return (
      <ModalDialog show={isModalOpen} onHide={this.closeModal}>
        <ModalHeader closeButton={true}>
          <Modal.Title>
            <div className="set-management-bundle-item__modal-title">{bundle.name}</div>
            <div>ID: {bundle.id}</div>
          </Modal.Title>
        </ModalHeader>
        <ModalBody>
          <SetManagementBundleEdit
            bundle={bundle}
            domains={domains}
            onSave={this.save}
            onCancel={this.closeModal}
          />
        </ModalBody>
      </ModalDialog>
    );
  }

  openModal = () => this.setState({ isModalOpen: true });
  closeModal = () => this.setState({ isModalOpen: false });
  save = (bundle) => {
    const { onChange } = this.props;
    onChange(bundle);
    this.closeModal();
  };
}

export const SetManagementBundleItem = SortableElement(Item);
SetManagementBundleItem.propTypes = {
  bundle: PropTypes.object.isRequired,
  domains: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

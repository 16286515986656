import React from 'react';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
import Select from 'react-select';
import { additionalCheckFields } from './CheckAdditions';
import { get } from 'lodash';

const types = [
  { value: 'CheckEBanking', label: 'Kunde hat Ebanking' },
  { value: 'CheckBasicContract', label: 'Kunde hat Basisvertrag' },
  { value: 'CheckVSBAvailable', label: 'VSB erledigt vorhanden' },
  { value: 'CheckCustomerAge', label: 'Alter' },
  { value: 'CheckCustomerDoppelpartner', label: 'Ist Doppelpartner' }, // for the lack of a better word
  { value: 'CheckG2Customer', label: 'Ist G2 Kunde' },
  { value: 'CheckIsPrivateCustomer', label: 'Ist Privatkunde' },
  { value: 'CheckIsBusinessCustomer', label: 'Ist Geschäftskunde' },
  { value: 'CheckLegalForm', label: 'Rechtsstatus'},
  { value: 'CheckNoteDate', label: 'Anzeige Datum letzte Notiz'},
  { value: 'CheckCustomerRating', label: 'Rating'},
  { value: 'CheckDynamic', label: 'Dynamischer Prüfpunk'},
  { value: 'CheckExistingWorkguide', label: 'Bestehender Workguide'}
];

const CheckConfiguration = ({ id, item, editValueField, codes, fetchCodes, language, ...rest }) => {
  return (
    <React.Fragment>
      <FormGroup>
        <Col componentClass={ControlLabel} sm={3}>
          Typ:
        </Col>
        <Col sm={9}>
          <Select
            placeholder="Eingabefeld Ausprägung wählen"
            clearable={false}
            name={`components.${id}.props.type`}
            options={types}
            value={item.props.type}
            onChange={selectedOption => editValueField(id, 'componentType', get(selectedOption, 'value'))}
          />
        </Col>
      </FormGroup>
      {additionalCheckFields({ id, item, editValueField, codes, fetchCodes, language, ...rest })}
    </React.Fragment>
  );
};

CheckConfiguration.propTypes = {};

CheckConfiguration.defaultProps = {};

export default CheckConfiguration;

import { call } from 'redux-saga/effects';
import { get, isNil } from 'lodash';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { deslashUri } from '@evoja-web/redux-saga-utils';

/**
 * Transfer a workguide to the target.
 * Active status of workguides must not be transfered.
 * Therefor load the current status from target and apply it to data.
 *
 * @param   {Object}  item    Item to transfer
 * @param   {Object}  target  Target system from state.paramTransfer.target
 *
 * @return  {Object} result Result
 */
export default function* transferWorkguide({ item, target }) {
  const axiosInstance = get(target, 'data.axiosInstance');

  const {
    serviceUri,
    uri,
    data
  } = item;

  if (isNil(axiosInstance)) {
    throw new Error(`Unable to transfer item ${item.dataKey} to the target system! No axios instance given`);
  }

  if (isNil(uri)) {
    throw new Error(`Unable to transfer item ${item.dataKey} to the target system! No uri given`);
  }

  if (isNil(data)) {
    throw new Error(`Unable to transfer item ${item.dataKey} to the target system! No data given`);
  }

  const query = QueryBuilder()
    .eq('id', get(item, 'data.id'))
    .select('active')
    .limit(1)
    .getQueryString();

  const { data: current } = yield call(axiosInstance, {
    url: deslashUri(`${serviceUri}/${query}`),
    method: 'get'
  });

  const result = yield call(axiosInstance, {
    url: uri,
    method: 'put',
    data: {
      ...data,
      active: get(current, '0.active', false)
    }
  });

  return result;
}

export const EMAIL_SUBSCRIPTIONS_PAGE_SIZE = 30;

export const EMAIL_SUBSCRIPTIONS_DEFAULT_SORT = {
  sortField: 'firstName',
  sortDirection: 'desc',
};

export const EMAIL_SUBSCRIPTIONS_LANGUAGE_OPTIONS = [
  { label: 'DE', value: 'de' },
  { label: 'EN', value: 'en' },
  { label: 'FR', value: 'fr' },
];

export const EMAIL_SUBSCRIPTIONS_GENDER_OPTIONS = [
  { label: 'MALE', value: 'male' },
  { label: 'FEMALE', value: 'female' },
];

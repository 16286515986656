import React from 'react';
import PropTypes from 'prop-types';
import { chain, get, noop, isNil } from 'lodash';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Select } from '@evoja-web/react-form';
import update from 'immutability-helper';

import UseAsDueDate from './DateField/UseAsDueDate';
import UseAsExpireDate from './DateField/UseAsExpireDate';
import DateValue from './DateField/DateValue/DateValue';

class DateFieldConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.onItemPropChange = this.onItemPropChange.bind(this);
    this.onDefaultValueChange = this.onDefaultValueChange.bind(this);
    this.onMinMaxDateChange = this.onMinMaxDateChange.bind(this);
  }

  /**
   * Update the given prop on item
   *
   * @param   {String}  key    Property key
   * @param   {Mixed}   value  Property value
   *
   * @return  void
   */
  onItemPropChange(key, value) {
    const {
      editValueField,
      id
    } = this.props;

    editValueField(id, 'prop', { key, value });
  }

  /**
   * In case of min / max date the validation definition must be updated as well
   *
   * @param   {String}  key    Prop key (minDate / maxDate)
   * @param   {String}  value  Value
   *
   * @return  void
   */
  onMinMaxDateChange(key, value) {
    const {
      editValueField,
      id,
      item
    } = this.props;

    const updated = chain(item)
      .get('props', {})
      .thru((props) => {
        return isNil(props.validations)
          ? update(props, { validations: { $set: {} } })
          : props;
      })
      .thru((props) => {
        return isNil(value)
          ? update(props, {
            $unset: [key],
            validations: { $unset: [key] },
          })
          : update(props, {
            [key]: { $set: value },
            validations: { [key]: { $set: { [key]: [value] } } }
          });
      })
      .value();

    editValueField(id, 'props', updated);
  }

  /**
   * Handle default value change
   *
   * @param   {Object}  defaultValue  Default value
   *
   * @return  void
   */
  onDefaultValueChange(key, value) {
    const { languages } = this.props;

    const defaults = languages.reduce((result, l) => ({ ...result, [l]: [value] }), {});

    this.onItemPropChange('defaults', defaults);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { item } = this.props;

    return (
      <div className="workguide-component-config--date-field">
        <UseAsDueDate
          id="useAsDueDate"
          onChange={this.onItemPropChange}
          value={get(item, 'props.useAsDueDate')}
        />

        <UseAsExpireDate
          id="useAsExpireDate"
          onChange={this.onItemPropChange}
          value={get(item, 'props.useAsExpireDate')}
        />

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            <FormattedMessage id="Workguide.Configuration.DateField.BlockingDays.Title" />
          </Col>

          <Col sm={9}>
            <Select
              id="blockingDays"
              onChange={this.onItemPropChange}
              options={[{
                value: 'holidays',
                label: <FormattedMessage id="Workguide.Configuration.DateField.BlockingDays.Holidays" />
              }, {
                value: 'weekends',
                label: <FormattedMessage id="Workguide.Configuration.DateField.BlockingDays.Weekends" />
              }, {
                value: 'holidaysAndWeekends',
                label: <FormattedMessage id="Workguide.Configuration.DateField.BlockingDays.HolidaysAndWeekends" />
              }]}
              value={get(item, 'props.blockingDays')}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            <FormattedMessage id="Workguide.Configuration.DateField.MinDate" />
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
            <DateValue
              id="minDate"
              onChange={this.onMinMaxDateChange}
              value={get(item, 'props.minDate')}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            <FormattedMessage id="Workguide.Configuration.DateField.MaxDate" />
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
            <DateValue
              id="maxDate"
              onChange={this.onMinMaxDateChange}
              value={get(item, 'props.maxDate')}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            <FormattedMessage id="Workguide.Configuration.DateField.DefaultValue" />
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
            <DateValue
              id="defaultValue"
              onChange={this.onDefaultValueChange}
              value={get(item, 'props.defaults.de.0')}
            />
          </Col>
        </FormGroup>
      </div>
    );
  }
}

DateFieldConfiguration.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  editValueField: PropTypes.func,
  languages: PropTypes.array
};

DateFieldConfiguration.defaultProps = {
  editValueField: noop,
  languages: ['de', 'fr', 'en']
};

export default DateFieldConfiguration;

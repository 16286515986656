import uuid from 'uuid';
import { put, select } from 'redux-saga/effects';
import { FINANCING_PROFILING_UPDATE_ACTIONS } from '../../actions/FinancingProfilingActions';
import { createRef, getIdFromRef } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';
import { getFinancingProfilingProfilesRaw } from '../../selectors/FinancingProfiling/financingProfilingCommonSelectors';

export function* financingProfilingUpdateSaga({ data: { profiles } }) {
  const prevProfiles = yield select(getFinancingProfilingProfilesRaw);

  const updatedProfiles = profiles.reduce((nextProfiles, product) => {
    const profile = getProfile(product, prevProfiles, nextProfiles);
    const availableProducts = product.durations
      .map((duration) => mapDuration(product, duration));
    const defaultProducts = product.durations
      .filter((duration) => duration.isDefault)
      .map((duration) => mapDuration(product, duration));

    const updatedProfile = {
      ...profile,
      availableProducts: [...profile.availableProducts, ...availableProducts],
      defaultProducts: [...profile.defaultProducts, ...defaultProducts],
    };

    return updatedProfile.id
      ? nextProfiles.filter((it) => it.id !== updatedProfile.id).concat(updatedProfile)
      : nextProfiles.concat({ ...updatedProfile, id: uuid.v4() });
  }, []);

  yield put(FINANCING_PROFILING_UPDATE_ACTIONS.success({ profiles: updatedProfiles }));
}

function createNewProfile(product) {
  return {
    id: undefined,
    prediction: createRef(EndpointName.ENTITY_CODE, product.predictionId),
    profile: createRef(EndpointName.ENTITY_CODE, product.profileId),
    limitStart: product.limitStart,
    limitEnd: product.limitEnd,
    defaultProducts: [],
    availableProducts: [],
  };
}

function findProfile(profiles, product) {
  return profiles.find((profile) =>
    profile.limitStart === product.limitStart &&
    profile.limitEnd === product.limitEnd &&
    getIdFromRef(profile.profile) === product.profileId &&
    getIdFromRef(profile.prediction) === product.predictionId,
  );
}

function mapDuration(product, duration) {
  return {
    product: createRef(EndpointName.FINANCING_LOAN_PRODUCT, product.productId),
    duration: createRef(EndpointName.FINANCING_DURATION, duration.id),
  };
}

function getProfile(product, prevProfiles, nextProfiles) {
  const nextProfile = findProfile(nextProfiles, product);
  if (nextProfile) {
    return nextProfile;
  }

  const prevProfile = findProfile(prevProfiles, product);
  if (prevProfile) {
    return {
      ...prevProfile,
      defaultProducts: [],
      availableProducts: [],
    };
  }

  return createNewProfile(product);
}

import { EXTERNAL_LINKS_LOAD, EXTERNAL_LINKS_SAVE, SET_LINKS } from '../actions/ExternalLinkActions';

export default function ExternalLinkReducer(
  state = {
    externalLinks: [],
    originalLinks: [],
    categories: [],
    requesting: false,
    error: undefined,
    changed: false,
  },
  action,
) {
  switch (action.type) {
    case SET_LINKS:
      return { ...state, externalLinks: action.data };
    case EXTERNAL_LINKS_LOAD.REQUEST:
      return { ...state, requesting: true, error: undefined };
    case EXTERNAL_LINKS_LOAD.SUCCESS:
      const { externalLinks, categories } = action.data;
      return { ...state, requesting: false, externalLinks, categories, originalLinks: externalLinks };
    case EXTERNAL_LINKS_LOAD.FAILURE:
      return { ...state, requesting: false, error: action.data };

    case EXTERNAL_LINKS_SAVE.REQUEST:
      return { ...state, requesting: true, error: undefined };
    case EXTERNAL_LINKS_SAVE.SUCCESS:
      return { ...state, requesting: false, originalLinks: state.externalLinks };
    case EXTERNAL_LINKS_SAVE.FAILURE:
      return { ...state, requesting: false, error: action.data };

    default: {
      return state;
    }
  }
}

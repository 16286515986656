import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  UI_NOTES_CREATE_ACTIONS,
  UI_NOTES_DELETE_ACTIONS,
  UI_NOTES_FETCH_ACTIONS,
  UI_NOTES_UPDATE_ACTIONS,
  UI_NOTES_IMAGE_FETCH_ACTIONS,
  UI_NOTES_IMAGE_UPLOAD_ACTIONS,
} from '../../actions/UiNotesActions';
import { uiNotesFetchSaga } from './uiNotesFetchSaga';
import { uiNotesCreateSaga } from './uiNotesCreateSaga';
import { uiNotesUpdateSaga } from './uiNotesUpdateSaga';
import { uiNotesDeleteSaga } from './uiNotesDeleteSaga';
import { uiNotesImageFetchSaga } from './uiNotesImageFetchSaga';
import { uiNotesImageUploadSaga } from './uiNotesImageUploadSaga';

export function* uiNotesSaga() {
  yield takeLatest(UI_NOTES_FETCH_ACTIONS.REQUEST, uiNotesFetchSaga);
  yield takeEvery(UI_NOTES_CREATE_ACTIONS.REQUEST, uiNotesCreateSaga);
  yield takeEvery(UI_NOTES_UPDATE_ACTIONS.REQUEST, uiNotesUpdateSaga);
  yield takeEvery(UI_NOTES_DELETE_ACTIONS.REQUEST, uiNotesDeleteSaga);

  yield takeLatest(UI_NOTES_IMAGE_FETCH_ACTIONS.REQUEST, uiNotesImageFetchSaga);
  yield takeLatest(UI_NOTES_IMAGE_UPLOAD_ACTIONS.REQUEST, uiNotesImageUploadSaga);
}

import { EndpointName } from '../../constants/EndpointName';
import { createRef, getIdFromRef } from '../../utils';

export function mapPromotionToApp(promotion, files, language) {
  const image = files[getIdFromRef(promotion.image.$ref)];
  const teaserImage = files[getIdFromRef(promotion.teaserImage.$ref)];

  return {
    id: promotion.id,
    title: promotion.title[language],
    imageText: promotion.imageText ? promotion.imageText[language] : '',
    colorCode: promotion.colorCode,
    checklistItem: promotion.checklistItem.map((item) => getIdFromRef(item.$ref)),
    image: image,
    teaserImage: teaserImage,
    document: promotion.document
      ? promotion.document
        .map((document) => files[getIdFromRef(document.$ref)])
        .filter((document) => !!document)
      : [],
    customerSegments: promotion.customerSegments.map((segment) => getIdFromRef(segment.$ref)),
    active: !!promotion.active,
    default: promotion.default,
    from: promotion.from,
    to: promotion.to,
    order: promotion.order,
    saving: promotion.saving,
  };
}

export function mapPromotionToApi(promotion, rawPromotion, language) {
  const title = rawPromotion
    ? {...rawPromotion.title, [language]: promotion.title}
    : {en: promotion.title, [language]: promotion.title};

  const imageText = rawPromotion
    ? {...rawPromotion.imageText, [language]: promotion.imageText}
    : {en: promotion.imageText, [language]: promotion.imageText};

  return {
    id: promotion.id,
    title: title.en ? title : {...title, en: promotion.title},
    imageText: imageText.en ? imageText : {...imageText, en: promotion.imageText},
    colorCode: promotion.colorCode,
    checklistItem: promotion.checklistItem.map((itemId) => ({
      $ref: createRef(EndpointName.CHECKLIST_ITEM, itemId),
    })),
    image: {
      $ref: createRef(EndpointName.FILE, promotion.image.id),
    },
    teaserImage: {
      $ref: createRef(EndpointName.FILE, promotion.teaserImage.id),
    },
    document: promotion.document.map((document) => ({
      $ref: createRef(EndpointName.FILE, document.id),
    })),
    customerSegments: promotion.customerSegments.map((segmentId) => ({
      $ref: createRef(EndpointName.ENTITY_CODE, segmentId),
    })),
    active: !!promotion.active,
    default: promotion.default,
    from: promotion.from,
    to: promotion.to,
    order: promotion.order,
  };
}

import { select } from 'redux-saga/effects';
import { get } from 'lodash';

import { EMAIL_SUBSCRIPTIONS_PAGE_SIZE } from '../../constants/EmailSubscriptionsConstants';
import { getLanguage } from '../../selectors/commonSelectors';

export function* createSearchQuery(params) {
  const { sortField, sortDirection } = params;
  const { firstName, lastName, email, subscriptionTypes, subscriptionStates } = params;

  const locale = yield select(getLanguage);

  const queryParams = [`limit(${get(params, 'limit', EMAIL_SUBSCRIPTIONS_PAGE_SIZE)},${get(params, 'offset', 0)})`];

  if (sortField) {
    queryParams.push(getSortQuery(sortField, sortDirection, locale));
  }

  if (firstName) {
    queryParams.push(`eq(firstName,string:${encodeURIComponent(firstName)})`);
  }

  if (lastName) {
    queryParams.push(`eq(lastName,string:${encodeURIComponent(lastName)})`);
  }

  if (email) {
    queryParams.push(`eq(email,string:${encodeURIComponent(email).replace('_', '%5F')})`);
  }

  if (subscriptionTypes && subscriptionTypes.length) {
    queryParams.push(`in(subscriptionType.id,(${subscriptionTypes.join(',')}))`);
  }

  if (subscriptionStates && subscriptionStates.length) {
    queryParams.push(`in(subscriptionState.id,(${subscriptionStates.join(',')}))`);
  }

  return queryParams.join('&');
}

function getSortQuery(sortField, sortDirection, locale) {
  const sortSign = (sortDirection === 'desc' ? '-' : '+');

  switch (sortField) {
    case 'name':
      return `sort(${sortSign}firstName,${sortSign}lastName)`;

    case 'subscriptionType':
    case 'subscriptionState':
      return `sort(${sortSign}${sortField}.text.${locale})`;

    default:
      return `sort(${sortSign}${sortField})`;
  }
}

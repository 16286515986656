import { createSelector } from 'reselect';

export const getPos = (state) => state.pos;

export const getApiPromotions    = createSelector([getPos], (pos) => pos.promotions);
export const getFiles            = createSelector([getPos], (pos) => pos.files);
export const getSchema           = createSelector([getPos], (pos) => pos.schema);
export const getModules          = createSelector([getPos], (pos) => pos.modules);
export const getCustomerSegments = createSelector([getPos], (pos) => pos.customerSegments);
export const getLoading          = createSelector([getPos], (pos) => pos.loading);
export const getChanged          = createSelector([getPos], (pos) => pos.changed);
export const getError            = createSelector([getPos], (pos) => pos.error);
export const getFilter           = createSelector([getPos], (pos) => pos.filter);
export const getSections         = createSelector([getPos], (pos) => pos.checklistSections);

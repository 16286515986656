import './ConfigPanel.css';

import React from 'react';
import PropTypes from 'prop-types';
import { ConfigPanelItem } from './ConfigPanelItem';

export class ConfigPanel extends React.Component {
  constructor(props) {
    super(props);

    this.update = this.update.bind(this);
  }

  render() {
    const { items } = this.props;
    return (
      <div className="config-panel">
        <div className="config-panel__header">
          <div className="config-panel__title">Name</div>
          <div className="config-panel__value">Wert</div>
        </div>

        {items.map((item) => <ConfigPanelItem key={item.id} item={item} save={this.update} />)}
      </div>
    );
  }

  update(id, value) {
    const { items, update } = this.props;
    update(items.map((item) => item.id === id ? { ...item, value } : item));
  }
}

ConfigPanel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  update: PropTypes.func.isRequired,
};

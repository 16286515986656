import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import './Error.css';

const ParamTransferSourceError = React.memo(function ParamTransferSourceError({ source }) {
  return (
    <Alert
      bsStyle="warning"
      className="param-transfer-source-error"
    >
      <FormattedMessage
        id="ParamTransfer.Source.Error"
        tagName="h4"
      />

      <p>
        Error:
        <br />
        {get(source, 'error.message', '')}
      </p>

      <p>
        Cause:
        <br />
        {get(source, 'error.cause.message', '')}
      </p>
    </Alert>
  );
});

ParamTransferSourceError.propTypes = {
  source: PropTypes.object.isRequired
};

ParamTransferSourceError.defaultProps = {

};

export default ParamTransferSourceError;

import axios from 'axios';
import { all, call, put } from 'redux-saga/effects';
import _ from 'lodash';
import { createRef, encodeRqlString, getIdFromRef } from '../utils';
import { EndpointName } from '../constants/EndpointName';

export const GET = 'adminUI/InvestmentProducts/GET';
export const GET_KNOWLEDGE_EXPERIENCES = 'adminUI/InvestmentProducts/GET_KNOWLEDGE_EXPERIENCES';
export const GET_SEGMENTS = 'adminUI/InvestmentProducts/GET_SEGMENTS';
export const GET_CLASS = 'adminUI/InvestmentProducts/GET_CLASS';
export const GET_ALLOC = 'adminUI/InvestmentProducts/GET_ALLOC';
export const GET_SUBTYPE = 'adminUI/InvestmentProducts/GET_SUBTYPE';
export const GET_STRATEGY = 'adminUI/InvestmentProducts/GET_STRATEGY';
export const GET_DOCUMENT = 'adminUI/InvestmentProducts/GET_DOCUMENT';
export const PUT = 'adminUI/InvestmentProducts/PUT';
export const SET = 'adminUI/InvestmentProducts/SET';

export function setData({ key, value }) {
  return dispatch =>
    dispatch({
      type: SET,
      key,
      value,
    });
}

export const getInvestmentProductsRequest = () => ({
  type: `${GET}_REQUEST`,
});

export function* getInvestmentProducts() {
  try {
    yield put({ type: `${GET}_PENDING` });
    const products = yield call(axios.get, 'investment/product/?limit(100)');
    yield put({ type: `${GET}_FULFILLED`, payload: products });
    const seg = yield call(axios.get, 'entity/code/?eq(group,assetSegment)&limit(100)');
    yield put({ type: `${GET_SEGMENTS}_FULFILLED`, payload: seg });
    const knowledgeExperiences = yield call(axios.get, 'entity/code/?eq(group,knowledgeExperience)&limit(100)');
    yield put({ type: `${GET_KNOWLEDGE_EXPERIENCES}_FULFILLED`, payload: knowledgeExperiences });
    const cls = yield call(axios.get, '/investment/assetclass/?limit(100)');
    yield put({ type: `${GET_CLASS}_FULFILLED`, payload: cls });
    const alloc = yield call(axios.get, 'investment/assetallocation/?limit(100)');
    yield put({ type: `${GET_ALLOC}_FULFILLED`, payload: alloc });
    const subtype = yield call(axios.get, 'investment/servicesubtype/?limit(100)');
    yield put({ type: `${GET_SUBTYPE}_FULFILLED`, payload: subtype });
    const strategy = yield call(axios.get, 'investment/strategy/?limit(100)');
    yield put({ type: `${GET_STRATEGY}_FULFILLED`, payload: strategy });

    const documents = yield call(loadProductsDocuments, products);
    yield put({ type: `${GET_DOCUMENT}_FULFILLED`, payload: documents });

    yield put({ type: SET, key: "requesting", value: false })
  } catch (e) {
    yield put({ type: `${GET}_REJECTED`, payload: e });
  }
}

export const updateInvestmentProductsRequest = (
  { products, investmentDocuments, initialInvestmentDocuments, serviceSubTypes, productsToDelete, productsToUpdate }
) => ({
  type: `${PUT}_REQUEST`,
  products,
  investmentDocuments,
  initialInvestmentDocuments,
  serviceSubTypes,
  productsToDelete,
  productsToUpdate
});

export function* updateInvestmentProductsItems(action) {
  try {
    const { investmentDocuments, initialInvestmentDocuments } = action;
    yield put({ type: `${PUT}_PENDING` });

    for (let i=0; i<action.productsToUpdate.length; i++ ) {
      const productIndex = _.findIndex(action.products, { id: action.productsToUpdate[i] });
      yield call(axios.put, `/investment/product/${action.productsToUpdate[i]}`, action.products[productIndex])
    }
    for (let i=0; i<action.serviceSubTypes.length; i++ ) {
      yield call(axios.delete, `/investment/servicesubtype/${action.serviceSubTypes[i].id}`);
      yield call(axios.put, `/investment/servicesubtype/${action.serviceSubTypes[i].id}`, action.serviceSubTypes[i]);
    }
    for (let i = 0; i < action.productsToDelete.length; i++) {
      yield call(axios.delete, `/investment/product/${action.productsToDelete[i].id}`);
    }
    yield call(deleteDocuments, investmentDocuments, initialInvestmentDocuments);
    yield call(updateDocuments, investmentDocuments, initialInvestmentDocuments);
    yield put({ type: `${PUT}_FULFILLED` });
  } catch (e) {
    yield put({ type: `${PUT}_REJECTED`, payload: e });
  }
}

function* loadProductsDocuments(products) {
  const documentsQuery = products.data
    .reduce((result, product) => {
      const ids = [];
      if (product.investmentDocument && product.investmentDocument.$ref) {
        ids.push(getIdFromRef(product.investmentDocument.$ref));
      }
      if (product.productBasicInformation && product.productBasicInformation.$ref) {
        ids.push(getIdFromRef(product.productBasicInformation.$ref));
      }
      return ids.length ? [...result, ...ids] : result;
    }, [])
    .map((id) => encodeRqlString(id))
    .join(',');

  if (!documentsQuery) {
    return [];
  }

  const { data: documents } = yield call(
    axios.get,
    `investment/document/?id=in=(${documentsQuery})&limit(500)`,
  );
  return documents;
}


function* deleteDocuments(investmentDocuments, initialDocuments) {
  const deletedDocuments = initialDocuments.filter((initial) =>
    !investmentDocuments.some((document) => initial.id === document.id)
  );
  yield all(
    deletedDocuments.map((document) =>
      call(axios.delete, createRef(EndpointName.INVESTMENT_DOCUMENT, document.id))
    ),
  );
}

function* updateDocuments(investmentDocuments, initialDocuments) {
  const changedDocuments = investmentDocuments.filter((document) => {
    const initialDocument = initialDocuments.find((initial) => document.id === initial.id);
    return !initialDocument ||
      initialDocument.documentType[0].documentSource !== document.documentType[0].documentSource;
  });
  yield all(
    changedDocuments.map((document) =>
      call(axios.put, createRef(EndpointName.INVESTMENT_DOCUMENT, document.id), document)
    ),
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from 'react-select';

const CriteriaInput = ({ source, criteria, onChange, edit }) => {
  function onValueChange(selected) {
    onChange({
      source: _.get(source, 'props.id'),
      path: `${_.get(source, 'props.id')}`,
      values: [_.get(selected, 'value')]
    });
  }

  return (edit)
    ? (
      <React.Fragment>
        <strong>Wert</strong>

        <Select
          value={_.get(criteria, 'values.0')}
          options={[{ value: 'true', label: 'Selektiert' }, { value: 'false', label: 'Nicht selektiert' }]}
          onChange={onValueChange}
        />
      </React.Fragment>
    )
    : (
      <span>{_.get(criteria, 'values', []).join(', ')}</span>
    );
}

CriteriaInput.propTypes = {
  source: PropTypes.object.isRequired,
  criteria: PropTypes.object,
  onChange: PropTypes.func,
  edit: PropTypes.bool,
  operation: PropTypes.string
};

CriteriaInput.defaultProps = {
  criteria: {},
  onChange: _.noop,
  edit: false,
  operation: 'eq'
};

export default CriteriaInput;

import ConfigurationForm from '../configuration/Date';

export default {
  key: 'maxDate',
  label: 'Maximum Datum',
  validation: 'maxDate',
  availableFor: ['DateField'],
  ConfigurationForm,
  get: function(params) {
    return { maxDate: params };
  }
}

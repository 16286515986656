import uuid from 'uuid';
import update from 'immutability-helper';
import { get } from 'lodash';

import { reducers as WorkguidePackageReducers } from '../packages/Workguide/reducers/Reducers';

import {
  GET_METADATA,
  GET_WORKGUIDE,
  GET_CONSULTANT,
  GET_CODEGROUP,
  SET,
  WORKGUIDE_CODES_PENDING,
  WORKGUIDE_CODES_FULFILLED,
  WORKGUIDE_CODES_REJECTED,
  WORKGUIDE_PRODUCTGROUPS_PENDING,
  WORKGUIDE_PRODUCTGROUPS_FULFILLED,
  WORKGUIDE_PRODUCTGROUPS_REJECTED,
  WORKGUIDE_SERVICES_PENDING,
  WORKGUIDE_SERVICES_FULFILLED,
  WORKGUIDE_SERVICES_REJECTED,
  WORKGUIDE_SERVICE_DEFINITION_PENDING,
  WORKGUIDE_SERVICE_DEFINITION_FULFILLED,
  WORKGUIDE_SAVE_CODES_REQUEST,
  WORKGUIDE_SAVE_CODES_FULFILLED,
  WORKGUIDE_SAVE_CODES_REJECTED,
  WORKGUIDE_USAGE_PENDING,
  WORKGUIDE_USAGE_FULFILLED,
  WORKGUIDE_USAGE_REJECTED
} from '../actions/WorkguideActions';

//create a default workguide to fill up all fields with default values
const id = uuid.v4();
const defaultWorkguide = {
  id: id,
  title: {
    de: '',
    en: '',
    fr: ''
  },
  processDescription: {
    de: "",
    fr: "",
    en: ""
  },
  documentRawData: {
    de: {},
    fr: {}
  },
  keyword: {
    de: [],
    fr: []
  },
  info: {
    de: "",
    fr: "",
    en: ""
  },
  help: {
    de: "",
    fr: "",
    en: ""
  },
  faq: {
    de: "",
    fr: "",
    en: ""
  },
  components: [],
  active: false,
  type: "info",
  target: "",
  responsible: {
    name: '',
    phone: '',
    mail: ''
  },
  targetTemplate: ""
};

export const defaultState = {
  workguides: [],
  workguideUsage: [],
  workguide: {},
  consultants: [],
  codeGroups: [],
  codes: {},
  activeWorkguide: {},
  requesting: false,
  codesRequesting: false,
  productGroupsRequesting: false,
  productGroups: [],
  error: {},
  hasError: false,
  servicesRequesting: false,
  services: [],
  serviceDefinitionRequesting: false,
  serviceDefinition: undefined,
  saveCodes: {
    requesting: false,
    fulfilled: false,
    error: false,
    updated: undefined
  },
  ...WorkguidePackageReducers.defaultState
};

export function LegacyWorkguideReducer(
  state = defaultState,
  action
) {
  switch (action.type) {
    case GET_METADATA + '_PENDING': {
      return { ...state, requesting: true, hasError: false }
    }
    case GET_METADATA + '_FULFILLED': {
      return { ...state, requesting: false, workguides: action.payload }
    }
    case GET_METADATA + '_REJECTED': {
      return { ...state, requesting: false, hasError: true, error: action.payload.data }
    }
    case GET_WORKGUIDE + '_PENDING': {
      return { ...state, requesting: true, hasError: false }
    }
    case GET_WORKGUIDE + '_FULFILLED': {
      return { ...state, requesting: false, workguide: Object.assign({}, defaultWorkguide, action.payload) }
    }
    case GET_WORKGUIDE + '_REJECTED': {
      return { ...state, requesting: false, hasError: true, error: action.payload.data }
    }
    case GET_CONSULTANT + '_FULFILLED': {
      return { ...state, consultants: action.data }
    }
    case GET_CODEGROUP + '_FULFILLED': {
      return { ...state, codeGroups: action.data }
    }
    case SET: {
      if (action.key === "workguide") {
        //set default values in any case to assure working UI elements
        return { ...state, workguide: Object.assign({}, defaultWorkguide, action.value) }
      } else {
        return { ...state, [action.key]: action.value };
      }
    }
    case WORKGUIDE_CODES_PENDING: {
      return { ...state, codesRequesting: true }
    }
    case WORKGUIDE_CODES_FULFILLED: {
      return update(state, { codes: { [action.group]: { $set: action.codes } }, codesRequesting: { $set: false }, error: { $set: false } })
    }
    case WORKGUIDE_CODES_REJECTED: {
      return update(state, { codesRequesting: { $set: false }, error: { $set: action.error } })
    }
    case WORKGUIDE_PRODUCTGROUPS_PENDING: {
      return { ...state, productGroupsRequesting: true, error: false }
    }
    case WORKGUIDE_PRODUCTGROUPS_FULFILLED: {
      return { ...state, productGroupsRequesting: false, error: false, productGroups: action.data }
    }
    case WORKGUIDE_PRODUCTGROUPS_REJECTED: {
      return { ...state, productGroupsRequesting: false, error: action.error }
    }
    case WORKGUIDE_SERVICES_PENDING: {
      return { ...state, servicesRequesting: true, error: false };
    }
    case WORKGUIDE_SERVICES_FULFILLED: {
      return { ...state, servicesRequesting: false, error: false, services: action.payload };
    }
    case WORKGUIDE_SERVICES_REJECTED: {
      return { ...state, servicesRequesting: false, error: action.error, services: [] };
    }
    case WORKGUIDE_SERVICE_DEFINITION_PENDING: {
      return { ...state, serviceDefinitionRequesting: true, error: false, serviceDefinition: undefined };
    }
    case WORKGUIDE_SERVICE_DEFINITION_FULFILLED: {
      return { ...state, serviceDefinitionRequesting: false, error: false, serviceDefinition: action.payload };
    }
    case WORKGUIDE_USAGE_PENDING: {
      return { ...state, requesting: true, error: false };
    }
    case WORKGUIDE_USAGE_FULFILLED: {
      return { ...state, requesting: true, workguideUsage: action.payload };
    }
    case WORKGUIDE_USAGE_REJECTED: {
      return { ...state, requesting: false, error: action.error };
    }

    case WORKGUIDE_SAVE_CODES_REQUEST: {
      return update(state, {
        saveCodes: {
          requesting: { $set: true },
          fulfilled: { $set: false },
          error: { $set: false },
          updated: { $set: undefined }
        }
      });
    }
    case WORKGUIDE_SAVE_CODES_FULFILLED: {
      return update(state, {
        saveCodes: {
          requesting: { $set: false },
          fulfilled: { $set: true },
          error: { $set: false },
          codes: { $set: get(action, 'payload', []) }
        }
      })
    }
    case WORKGUIDE_SAVE_CODES_REJECTED: {
      return update(state, {
        saveCodes: {
          requesting: { $set: false },
          fulfilled: { $set: false },
          error: { $set: get(action, 'error') }
        }
      });
    }
    default: {
      return state;
    }
  }
}

export default function WorkguideReducer(state, action) {
  const ls = LegacyWorkguideReducer(state, action);

  return WorkguidePackageReducers.get()(ls, action);
}

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Panel } from 'react-bootstrap';

import Spinner from './Spinner';

export default function EditContainer(props) {
  const header = (
    <div id={props.elementId || 'header'}>
      <Link to={props.closeLink}>
        <button type="button" className="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </Link>
      <h4>{props.title}</h4>
      <span>{props.header}</span>
    </div>
  );

  let saveButton = null;
  if (props.saveData) {
    saveButton = (
      <Button
        className="pull-left"
        onClick={props.saveData.bind(this)}
        name="saveData"
        bsStyle="primary"
        disabled={!props.valid }
      >
        <span className="icon-012-check-mark-circle" /> Speichern
      </Button>
    );
  }

  const footer = (
    <div className="clearfix" style={{ textAlign: 'center' }}>
      {saveButton}
      <span>{props.footer}</span>
      <Link to={props.closeLink} className="pull-right">
        <Button><span className="icon-202-clear-circle" /> Schliessen</Button>
      </Link>
    </div>
  );

  const body = props.requesting ? (<Spinner />) : props.body;
  return (
    <div className="container-fluid">
      <Panel>
        <Panel.Heading>{header}</Panel.Heading>
        <Panel.Body>{body}</Panel.Body>
        <Panel.Footer>{footer}</Panel.Footer>
      </Panel>
    </div>
  );
}

EditContainer.propTypes = {
  elementId: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  header: PropTypes.element,
  body: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  footer: PropTypes.element,
  requesting: PropTypes.bool,
  saveData: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  closeLink: PropTypes.string,
  valid: PropTypes.bool
};

EditContainer.defaultProps = {
  elementId: 0,
  footer: <span />,
  requesting: false,
  saveData: false,
  valid: true,
  header: <span />,
  body: <span />,
  closeLink: "/"
};

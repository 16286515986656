import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { noop } from 'lodash';


const confirmSubmit = ({
  intl,
  confirmFunction = noop,
  cancelFunction = noop,
  onClickOutside = noop,
  title = intl.formatMessage({ id: 'confirm_delete_title' }),
  message = intl.formatMessage({ id: 'confirm_delete_message' })
}) => {
  confirmAlert({
    title,
    message,
    onClickOutside,
    buttons: [
      {
        label: intl.formatMessage({ id: 'confirm_delete_yes' }),
        onClick: confirmFunction
      },
      {
        label: intl.formatMessage({ id: 'confirm_delete_no' }),
        onClick: cancelFunction
      }
    ]
  });
};

export default confirmSubmit;

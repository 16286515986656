import { createSelector } from 'reselect';
import { getIdFromRef } from '../../utils';
import { getBasicProducts, getCategories, getProducts } from './getBasicServices';
import { getNewProducts } from './getNewProducts';
import { getRemovedProducts } from './getRemovedProducts';
import { getLanguage } from '../commonSelectors';

export const getUpdatedProducts = createSelector([
  getProducts,
  getBasicProducts,
  getNewProducts,
  getRemovedProducts,
  getCategories,
  getLanguage,
], (products, basicProducts, newProducts, removedProducts, basicCategories, language) => {
  return products
    .filter((product) =>
      !newProducts.some((it) => it.id === product.id) &&
      !removedProducts.some((it) => it.id === product.id)
    )
    .filter((product) => {
      const basicProduct = basicProducts.find((it) => it.id === product.id);
      return isProductChanged(product, basicProduct, basicCategories, language);
    });
});

function isProductChanged(product, basicProduct, basicCategories, language) {
  if (
    product.maxOccurrence !== basicProduct.maxOccurrence ||
    product.order !== basicProduct.order ||
    product.coreType.length !== basicProduct.coreType.length ||
    product.name !== basicProduct.name[language]
  ) {
    return true;
  }

  const category = basicCategories.find((it) => product.category === it.id);
  if (category && getIdFromRef(category.coreType.$ref) !== getIdFromRef(basicProduct.category.$ref)) {
    return true;
  }

  return !product.coreType.every((it) =>
    basicProduct.coreType.some((coreType) => getIdFromRef(coreType.$ref) === it)
  );
}

import { call, put } from 'redux-saga/effects';
import {
  LOAD_BASIC_PRODUCTS_ACTIONS,
  SAVE_BASIC_PRODUCTS_ACTIONS,
} from '../../actions/BasicProductsActions';
import { createBasicProductsSaga } from './createBasicProductsSaga';
import { removeBasicProductsSaga } from './removeBasicProductsSaga';
import { updateBasicProductsSaga } from './updateBasicProductsSaga';
import { saveBasicDocumentsSaga } from './saveBasicDocumentsSaga';
import { saveBasicAdditionSaga } from './saveBasicAdditionSaga';

export function* saveBasicProducts() {
  try {
    yield put(SAVE_BASIC_PRODUCTS_ACTIONS.pending());

    yield call(createBasicProductsSaga);
    yield call(removeBasicProductsSaga);
    yield call(updateBasicProductsSaga);
    yield call(saveBasicDocumentsSaga);
    yield call(saveBasicAdditionSaga);

    yield put(SAVE_BASIC_PRODUCTS_ACTIONS.success());
    yield put(LOAD_BASIC_PRODUCTS_ACTIONS.request());
  } catch(error) {
    yield put(SAVE_BASIC_PRODUCTS_ACTIONS.failure(error));
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isNil } from 'lodash';
import { Col } from 'react-bootstrap';
import cl from 'classnames';

import './HeaderCol.css';

class LeadMassMutationTableHeaderCol extends React.Component {
  constructor(props) {
    super(props);

    this.onSortChange = this.onSortChange.bind(this);
  }

  /**
   * Handle header click (sort change) and call parents onSortChange
   *
   * @return  void
   */
  onSortChange() {
    const {
      id,
      sortOrder,
      sortPath,
      onSortChange
    } = this.props;

    const current = get(sortOrder, id);
    const updated = isNil(current)
      ? { path: sortPath, direction: 'asc' }
      : get(current, 'direction') === 'asc'
        ? { path: sortPath, direction: 'desc' }
        : undefined;

    onSortChange(id, updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      children,
      className,
      id,
      lg,
      md,
      sm,
      sortOrder,
      sortPath,
      ...rest
    } = this.props;

    const classNames = cl('lead-mass-mutation--table--header-col', className);
    const sortDirection = get(sortOrder, `${id}.direction`);

    return (
      <Col
        className={classNames}
        lg={lg}
        md={md}
        sm={sm}
        {...rest}
      >
        <div onClick={this.onSortChange}>
          {children}
        </div>

        {!isNil(sortDirection) && (
          <div
            className={cl({
              'lead-mass-mutation--table--header-col--sort': true,
              mdi: true,
              'mdi-chevron-up': sortDirection === 'asc',
              'mdi-chevron-down': sortDirection === 'desc'
            })}
          />
        )}
      </Col>
    );
  }
}

LeadMassMutationTableHeaderCol.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  id: PropTypes.string.isRequired,
  lg: PropTypes.number,
  md: PropTypes.number,
  onSortChange: PropTypes.func,
  sortOrder: PropTypes.object,
  sortPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]).isRequired,
  sm: PropTypes.number
};

LeadMassMutationTableHeaderCol.defaultProps = {
  children: [],
  className: undefined,
  lg: 1,
  md: 1,
  onSortChange: noop,
  sortOrder: {},
  sm: 1
};

export default LeadMassMutationTableHeaderCol;

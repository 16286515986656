import React from 'react';
import { Button } from 'react-bootstrap';
import Checkpoint from './Checkpoint';

export default class CheckpointList extends React.Component {
  render() {
    const {
      module, checkpoints, language, onAdd, onRemove, onEdit, onEditCondition,
    } = this.props;
    const checkpointList = checkpoints.filter((checkpoint) => {
      const moduleId = checkpoint.module.$ref.substr(checkpoint.module.$ref.lastIndexOf('/') + 1);
      return moduleId === module.id;
    })
      .map((checkpoint, i) =>
        (<Checkpoint
          key={i}
          index={i}
          id={checkpoint.id}
          text={checkpoint.text[language]}
          color={checkpoint.color}
          condition={checkpoint.condition}
          language={language}
          module={module}
          onRemove={onRemove}
          onEdit={onEdit}
          onEditCondition={onEditCondition}
          moveItem={this.moveItem}
        />));

    return (
      <div>
        <ul className="unstyled" id="checkpoint-list">
          {checkpointList}
        </ul>
        <Button
          bsStyle="primary"
          name="newCheckpoint"
          onClick={onAdd.bind(this)}
        >
          <span className="icon-061-plus" /> Neuer Eintrag
        </Button>
      </div>
    );
  }
}

import React from 'react';
import { get, debounce } from 'lodash';
import { FormGroup, FormControl, ControlLabel, Col } from 'react-bootstrap';
import update from 'immutability-helper';

function getOptionValue(id, item, defaultReturn) {
  const options = get(item, 'props.options', []);

  const option = options.find(o => get(o, 'value') === id);

  return get(option, 'label.de', defaultReturn);
}

class LeadScoreConfig extends React.Component {
  constructor(props) {
    super(props);

    const { item } = props;

    this.state = {
      customerTaskTypePath: getOptionValue('customerTaskTypePath', item, 'formData.customerTaskType.value'),
      dueDatePath: getOptionValue('dueDatePath', item, 'formData.dueDate')
    };

    this.onPathValueChange = this.onPathValueChange.bind(this);
    this.updateOption = this.updateOption.bind(this);
    this.updateOption = debounce(this.updateOption, 500);
  }

  onPathValueChange(fieldId, ev) {
    const value = get(ev, 'target.value');

    this.setState({ [fieldId]: value });

    this.updateOption(fieldId, value);
  }

  updateOption(fieldId, value) {
    const { id, item, editValueField } = this.props;
    const currentOptions = get(item, 'props.options', []);
    let updatedOptions = [ ...currentOptions ];

    const index = currentOptions.findIndex(o => get(o, 'value') === fieldId);
    const updatedOption = { value: fieldId, label: { de: value, fr: value, en: value } };

    if (index > -1) updatedOptions = update(currentOptions, {
      $splice: [[index, 1]]
    });

    updatedOptions = update(updatedOptions, {
      $push: [updatedOption]
    })

    editValueField(id, 'componentOptions', updatedOptions)
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      customerTaskTypePath,
      dueDatePath
    } = this.state;

    return (
      <React.Fragment>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Konfiguration
          </Col>

          <Col sm={9}>
            <FormGroup>
              <ControlLabel style={{ textAlign: 'left'}}>
                Pfad zur Leadart
              </ControlLabel>
              <FormControl
                value={customerTaskTypePath}
                type="text"
                onChange={ev => this.onPathValueChange('customerTaskTypePath', ev)}
              />
            </FormGroup>

            <FormGroup>
              <ControlLabel style={{ textAlign: 'left'}}>
                Pfad zum Fälligkeitsdatum
              </ControlLabel>
              <FormControl
                value={dueDatePath}
                type="text"
                onChange={ev => this.onPathValueChange('dueDatePath', ev)}
              />
            </FormGroup>
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

LeadScoreConfig.propTypes = {

};

LeadScoreConfig.defaultProps = {

};

export default LeadScoreConfig;

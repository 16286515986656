import { get, isUndefined, isFunction } from 'lodash';
import Papa from 'papaparse';
import moment from 'moment';

function Csv({ appendDate = true, definition = [] }) {
  let blob;

  /**
   * Generate the csv with the given data
   *
   * @param  {Array}  [data=[]] Data
   *
   * @return {Csv} this This instance
   */
  function generate(data = []) {
    const header = definition.map((item) => get(item, 'title', 'MISSING TITLE'));
    const columns = data.map((record) => {
      return definition.map((def) => {
        const dataKey = get(def, 'dataKey');
        const dataGetter = get(def, 'dataGetter');

        if (isUndefined(dataKey)) {
          if (isUndefined(dataGetter) || !isFunction(dataGetter)) {
            throw new Error('Unable to generate csv! Check your definition. Each item must eather have a dataKey or a dataGetter!')
          }
        }

        return !isUndefined(dataGetter) && isFunction(dataGetter)
          ? dataGetter(record)
          : get(record, dataKey, '')
      });
    });

    const content = Papa.unparse({ fields: header, data: columns }, { delimiter: ';' });
    blob = new Blob(['\ufeff' + content], { type: 'application/octet-stream' });

    return this;
  }

  /**
   * Download the file
   *
   * @param  {String} [filename=`export`] Filename (whitout .csv)
   *
   * @return void
   */
  function download(filename = `export`) {
    if (isUndefined(blob)) {
      throw new Error('No csv generated until now. Did you forget to call generate?');
    }

    filename = appendDate
      ? `${filename}-${moment().format('DD.MM.YYYY')}.csv`
      : `${filename}.csv`;

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
      return;
    }

    const url = window.URL;
    const objectURL = url.createObjectURL(blob);

    const link = document.createElement('a');
    link.download = filename;
    link.href = objectURL;
    link.dispatchEvent(new MouseEvent('click'));

    setTimeout(url.revokeObjectURL.bind(url, objectURL));
  }

  return Object.freeze({
    generate,
    download
  });
}

export default Csv;

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isUndefined } from 'lodash';
import { Button, FormGroup, Col, FormControl } from 'react-bootstrap';

import './form.css';

class DynamicPropsForm extends React.Component {
  constructor(props) {
    super(props);

    const { data } = props;

    this.state = {
      path: get(data, 'path'),
      jmes: get(data, 'jmes')
    };
  }

  onValueChange(field, ev) {
    const value = get(ev, 'target.value');

    this.setState({ [field]: value });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { path, jmes } = this.state;
    const {
      onSubmit,
      onCancel
    } = this.props;

    return (
      <div className="workguide-dynamic-props-form">
        <FormGroup>
          <Col sm={2}>
            <strong>
              Pfad
            </strong>
          </Col>

          <Col sm={10}>
            <FormControl
              id="path"
              name="path"
              value={path}
              onChange={(ev) => this.onValueChange('path', ev)}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col sm={2}>
            <strong>
              JMES Path Instruktion
            </strong>
          </Col>

          <Col sm={10}>
            <FormControl
              componentClass="textarea"
              id="jmes"
              name="jmes"
              value={jmes}
              onChange={(ev) => this.onValueChange('jmes', ev)}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col sm={2} />
          <Col sm={10} style={{ textAlign: 'right' }}>
            <Button
              bsStyle="primary"
              disabled={isUndefined(path) || isUndefined(jmes)}
              onClick={() => onSubmit({ path, jmes })}
            >
              Hinzufügen
            </Button>

            <span style={{ paddingRight: '5px' }} />

            <Button onClick={onCancel}>
              Abbrechen
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  }
}

DynamicPropsForm.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

DynamicPropsForm.defaultProps = {
  data: {},
  onSubmit: noop,
  onCancel: noop
};

export default DynamicPropsForm;

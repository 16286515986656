import ConfigurationForm from '../configuration/ArrayValuesLe';

export default {
  key: 'arrayValuesLe',
  label: 'Summierte Werte kleiner als (array oder array von objekten) ' ,
  validation: 'arrayValuesLe',
  availableFor: ['FundinvestFunds'],
  ConfigurationForm,
  defaultParams: [100, 'amount'],
  get: function(params) {
    return { arrayValuesLe: params };
  }
}

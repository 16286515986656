import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { createSearchQuery } from './createSearchQuery';
import { CONSULTANT_SEARCH } from '../../actions/PermissionGroupAction';

export function* permissionGroupSearchSaga(action) {
  try {
    yield put(CONSULTANT_SEARCH.pending());
    const searchQuery = yield call(createSearchQuery, action.data);
    const { data: consultants, headers } = yield call(axios, {
      url: `${EndpointName.PERSON_CONSULTANT}/?${searchQuery}`,
    });

    const totalCount = headers['x-total-count']
      ? parseInt(headers['x-total-count'], 10)
      : consultants.length;

    yield put(CONSULTANT_SEARCH.success({
      searchParams: action.data,
      consultants,
      totalCount,
    }));
  } catch (error) {
    yield put(CONSULTANT_SEARCH.failure(error));
  }
}

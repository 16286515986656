import { call, put } from 'redux-saga/effects';
import moment from 'moment';
import axios from 'axios';

import actions from '../../actions/LeadHistory/Actions';

const {
  SAVE_LEAD_HISTORY_PENDING,
  SAVE_LEAD_HISTORY_FULFILLED,
  SAVE_LEAD_HISTORY_REJECTED
} = actions;

export default function* saveLeadHistory(request) {
  yield put({ type: SAVE_LEAD_HISTORY_PENDING });

  try {
    const {
      consultantId,
      leadIds,
      functionType
    } = request;

    const url = '/work/leadmanagement/history';

    const data = {
      createdAt: moment().format(),
      consultantId,
      modifiedLeadIds: leadIds,
      type: functionType
    };

    const { data: payload } = yield call(axios.post, url, data);

    yield put({ type: SAVE_LEAD_HISTORY_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: SAVE_LEAD_HISTORY_REJECTED, error });

    return error;
  }
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, isNil, noop } from 'lodash';
import { TextInput, ValidationResult } from '@evoja-web/react-form';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const ActionConfigurationCreateCardSuspension = React.memo(function ActionConfigurationCreateCardSuspension({
  action,
  onJmesParamChange,
  validations
}) {
  useEffect(() => {
    if (isNil(get(action, 'jmesParams.customerId'))) {
      onJmesParamChange('customerId', 'customer.id');
    }
    // This effect must only run once when the component mounts to set the default value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="workguide-action-configuration-create-card-suspension">
      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.Action.Configuration.CardSuspension.JmesCustomerId" />
        </ControlLabel>

        <TextInput
          id="customerId"
          onChange={onJmesParamChange}
          value={get(action, 'jmesParams.customerId')}
        />

        <ValidationResult
          show
          validations={get(validations, 'customerId')}
        />
      </FormGroup>

      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.Action.Configuration.CardSuspension.JmesCardId" />
        </ControlLabel>

        <TextInput
          id="cardId"
          onChange={onJmesParamChange}
          value={get(action, 'jmesParams.cardId')}
        />

        <ValidationResult
          show
          validations={get(validations, 'cardId')}
        />
      </FormGroup>
    </div>
  );
});

ActionConfigurationCreateCardSuspension.propTypes = {
  action: PropTypes.object.isRequired,
  onJmesParamChange: PropTypes.func,
  validations: PropTypes.object
};

ActionConfigurationCreateCardSuspension.defaultProps = {
  onJmesParamChange: noop,
  validations: {}
};

export default ActionConfigurationCreateCardSuspension;

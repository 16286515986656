import './HolidaysCalendarDay.css';

import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { TranslatedMessage } from '../Common/TranslatedMessage';
import { ANALYTICS_PROP_TYPES_LOCALE } from '../Analytics/AnalyticsPropTypes';
import { ModalDialog } from '../Common/Modal';
import { HolidaysCreateForm } from './HolidaysCreateForm';
import { HolidaysUpdateForm } from './HolidaysUpdateForm';

export class HolidaysCalendarDay extends React.Component {
  state = {
    isCreateFormOpen: false,
    editingItem: null,
  };

  constructor(props) {
    super(props);

    this.handleCreateOpen = this.handleCreateOpen.bind(this);
    this.handleCreateCancel = this.handleCreateCancel.bind(this);
    this.handleCreateSubmit = this.handleCreateSubmit.bind(this);

    this.handleUpdateOpen = this.handleUpdateOpen.bind(this);
    this.handleUpdateCancel = this.handleUpdateCancel.bind(this);
    this.handleUpdateSubmit = this.handleUpdateSubmit.bind(this);
  }

  render() {
    const { title, holidays } = this.props;
    return (
      <div className="holidays-calendar-day">
        <div className="holidays-calendar-day__title" onClick={this.handleCreateOpen}>
          <div>{title}</div>
          <div className="holidays-calendar-day__create" title="Add">
            <i className="icon-061-plus"/>
          </div>
        </div>
        <div className="holidays-calendar-day__content">
          {holidays.map((holiday, index) => (
            <div className="holidays-calendar-day__item" key={index}>
              <div>
                <TranslatedMessage value={holiday.name}/>
              </div>
              <div>
                <span className="holidays-calendar-day__link" onClick={this.handleUpdateOpen.bind(this, holiday)}>
                  <i className="icon-025-edit"/>
                </span>
                <span className="holidays-calendar-day__link" onClick={this.remove.bind(this, holiday)}>
                  <i className="icon-008-bin"/>
                </span>
              </div>
            </div>
          ))}
        </div>

        {this.renderUpdateModal()}
        {this.renderCreateModal()}
      </div>
    );
  }

  renderCreateModal() {
    const { date } = this.props;
    const { isCreateFormOpen } = this.state;

    const initialFormValues = {
      name: '',
      isMultiDay: false,
      startDate: date,
      ednDate: null,
    };

    return (
      <ModalDialog
        show={isCreateFormOpen}
        keyboard={true}
        backdrop="static"
        onHide={this.handleCreateCancel}
      >
        <HolidaysCreateForm
          year={date.get('year')}
          initialValues={initialFormValues}
          onCancel={this.handleCreateCancel}
          onSubmit={this.handleCreateSubmit}
        />
      </ModalDialog>
    );
  }

  handleCreateOpen() {
    this.setState({ isCreateFormOpen: true });
  }

  handleCreateCancel() {
    this.setState({ isCreateFormOpen: false });
  }

  handleCreateSubmit(formData) {
    const { create } = this.props;
    create(formData.name, formData.startDate, formData.endDate);

    this.setState({ isCreateFormOpen: false });
  }

  renderUpdateModal() {
    const { date, locale } = this.props;
    const { editingItem } = this.state;

    const initialFormValues = editingItem
      ? { name: editingItem.name[locale] }
      : { name: '' };

    return (
      <ModalDialog
        show={editingItem !== null}
        keyboard={true}
        backdrop="static"
        onHide={this.handleUpdateCancel}
      >
        <TransitionGroup>
          {editingItem !== null && (
            <HolidaysUpdateForm
              year={date.get('year')}
              item={editingItem}
              initialValues={initialFormValues}
              onCancel={this.handleUpdateCancel}
              onSubmit={this.handleUpdateSubmit}
            />
          )}
        </TransitionGroup>
      </ModalDialog>
    );
  }

  handleUpdateOpen(holiday) {
    this.setState({ editingItem: holiday });
  }

  handleUpdateCancel() {
    this.setState({ editingItem: null });
  }

  handleUpdateSubmit(formData) {
    const { editingItem } = this.state;
    const { edit } = this.props;
    edit(editingItem, formData.name);

    this.setState({ editingItem: null });
  }

  remove(holiday) {
    const { remove } = this.props;
    remove(holiday);
  }
}

HolidaysCalendarDay.propTypes = {
  locale: ANALYTICS_PROP_TYPES_LOCALE.isRequired,
  date: PropTypes.object.isRequired,
  holidays: PropTypes.array,
  edit: PropTypes.func,
  remove: PropTypes.func,
  create: PropTypes.func,
};

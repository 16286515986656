import React from 'react';

import AccountSearchForm from './AccountSearchForm';
import AccountSearchWorkguideTable from './AccountSearchWorkguidesTable';

const AccountSearchTab = props => {
    return (
        <div>
            <AccountSearchForm />
            <AccountSearchWorkguideTable />
        </div>
    );
};

AccountSearchTab.propTypes = {
};

export default AccountSearchTab;
import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { FormControl } from 'react-bootstrap';

class DateFieldDefaultValueJmes extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Handle input change
   *
   * @param   {Event}  ev  Change event
   *
   * @return  void
   */
  onChange(ev) {
    const { onChange } = this.props;

    const value = get(ev, 'target.value');

    onChange(`jmes:${value.replace('jmes:', '')}`);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { value } = this.props;

    return (
      <div className="workguide-component-config--date-field--default-value--jmes">
        <FormControl
          id="jmes"
          onChange={this.onChange}
          value={value}
        />
      </div>
    );
  }
}

DateFieldDefaultValueJmes.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
};

DateFieldDefaultValueJmes.defaultProps = {
  onChange: noop,
  value: ''
};

export default DateFieldDefaultValueJmes;


import axios from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { remove, get } from 'lodash';

import { EndpointName } from '../../constants/EndpointName';
import {
  EMAIL_SUBSCRIPTIONS_REMOVE_MARKETING,
  EMAIL_SUBSCRIPTIONS_UPDATED ,
  EMAIL_SUBSCRIPTIONS_SEARCH
} from '../../actions/EmailSubscriptionsActions';
import { EMAIL_SUBSCRIPTIONS_PAGE_SIZE } from '../../constants/EmailSubscriptionsConstants';
import { emailSubscriptionsRemoveSaga } from './emailSubscriptionsRemoveSaga';
import { getEmailSubscriptionsSearchParams } from '../../selectors/EmailSubscriptions/emailSubscriptionsCommonSelectors';

export function* emailSubscriptionsRemoveMarketingSaga() {
  try {
    yield put(EMAIL_SUBSCRIPTIONS_REMOVE_MARKETING.pending());

    const result = yield call(axios, {
      method: 'get',
      url: `${EndpointName.EMAIL_SUBSCRIPTIONS}/?eq(subscriptionType.id,string:subscriptionType-6)&limit(9999)`,
    });

    let results = [];

    for (const el of get(result, 'data', [])) {
      // Remove marketing subscriptionType
      remove(el.subscriptionType, {
        id: 'subscriptionType-6',
      });

      // check if has any other subscriptions if not delete record
      if (el.subscriptionType.length === 0) {
        const deleted = yield call(emailSubscriptionsRemoveSaga, { meta: el.id });
        if (deleted instanceof Error) {
          results.push({ success: false, method: 'delete', email: el.email });
        } else {
          results.push({ success: true, method: 'delete', email: el.email });
        }

      } else {
        const res = yield call(axios, {
          method: 'patch',
          url: `${EndpointName.EMAIL_SUBSCRIPTIONS}/${el.id}`,
          data: [
            {
              op: 'replace',
              path: '/subscriptionType',
              value: el.subscriptionType,
            },
          ],
        });
        if (res.status === 200) {
          results.push({ success: true, method: 'update', email: el.email });
          yield put(
            EMAIL_SUBSCRIPTIONS_UPDATED.success({
              data: el,
            })
          );
        } else {
          results.push({ success: false, method: 'update', email: el.email });
        }
      }
    }

    yield put(
      EMAIL_SUBSCRIPTIONS_REMOVE_MARKETING.success({
        results,
      })
    );

    const searchParams = yield select(getEmailSubscriptionsSearchParams);
    yield put(EMAIL_SUBSCRIPTIONS_SEARCH.request({
      ...searchParams,
      limit: EMAIL_SUBSCRIPTIONS_PAGE_SIZE,
      offset: 0,
      rawData: []
    }))

  } catch (error) {
    yield put(EMAIL_SUBSCRIPTIONS_REMOVE_MARKETING.failure(error));
  }
}

import { createSelector } from 'reselect';
import { getCoreConfigState } from './coreConfigCommonSelectors';

export const getCoreConfigConfigs = createSelector([getCoreConfigState], (state) => state.configs);
export const getCoreConfigKeys = createSelector([getCoreConfigState], (state) => state.keys);
export const getCoreConfigConfigsInitial = createSelector([getCoreConfigState], (state) => state.initialConfigs);

export const getCoreConfigConfigsMapped = createSelector([
  getCoreConfigConfigs,
  getCoreConfigKeys,
], (configs, keys) => configs
  .map(config => {
    const key = keys.find((it) => it.key === config.key);
    if (!key) {
      return undefined;
    }
    return { ...config, label: key.label };
  })
  .filter(Boolean)
);

import {
  SET_MANAGEMENT_CHANGE_ACTIONS,
  SET_MANAGEMENT_LOAD_ACTIONS,
  SET_MANAGEMENT_SAVE_ACTIONS,
} from '../actions/SetManagementActions';

const initialState = {
  loading: false,
  error: undefined,
  bundles: [],
  products: [],
  prices: [],
  domains: [],
  reductionTypes: [],
  original: undefined,
};

export function SetManagementReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MANAGEMENT_LOAD_ACTIONS.PENDING:
      return { ...state, loading: true, error: undefined };
    case SET_MANAGEMENT_LOAD_ACTIONS.SUCCESS:
      return { ...state, loading: false, error: undefined, ...action.data, original: action.data };
    case SET_MANAGEMENT_LOAD_ACTIONS.FAILURE:
      return { ...state, loading: false, error: action.data };


    case SET_MANAGEMENT_CHANGE_ACTIONS.SUCCESS:
      return { ...state, ...action.data };
    case SET_MANAGEMENT_CHANGE_ACTIONS.FAILURE:
      return { ...state, error: action.data };


    case SET_MANAGEMENT_SAVE_ACTIONS.PENDING:
      return { ...state, loading: true, error: undefined };
    case SET_MANAGEMENT_SAVE_ACTIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        original: {
          bundles: [...state.bundles],
          products: [...state.products],
          prices: [...state.prices],
        },
      };
    case SET_MANAGEMENT_SAVE_ACTIONS.FAILURE:
      return { ...state, loading: false, error: action.data };

    default:
      return state;
  }
}

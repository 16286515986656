import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { TableCell, TableRow } from '../Common/Table';
import { FormattedDate } from '../Common/FormattedDate';
import { classList } from '../../utils/classList';
import { ModalBody, ModalDialog, ModalFooter, ModalHeader } from '../Common/Modal';
import { JOB_PROCESSING_PAGE_SIZE } from '../../constants/JobProcessingConstants';

import ExecutedJobsHistoryTable from './ExecutedJobsHistoryTable';
import './ExecutedJobsResultsItem.css';
import ReactJson from 'react-json-view';

const ExecutedJobsResultsItem = ({
  executionHistory,
  item,
  intl,
  jobProcessingActions,
  language
}) => {
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);

    const jobId = get(item, 'job.id');
  const dataKey = `${get(item, 'job.id')}-${get(item, 'document.id')}`;

    const jobTooltip = (
        <Tooltip id={`job-tooltip-${item.id}`}>
            {get(item, `job.name.${language}`)}
        </Tooltip>
    );
    const messageTooltip = (
        <Tooltip id={`message-tooltip-${item.id}`}>
            {item.message}
        </Tooltip>
    );
    const booleanIcons = classList({
        'icon-012-check-mark-circle': item.success === true,
        'green': item.success === true,
        'icon-202-clear-circle': item.success === false,
        'red': item.success === false,
        'icon-size': true
    });
    const modalFields = {
      updateUZVLead: [
        {
            label: intl.formatMessage({ id: 'workguideStatus_accountNumber' }),
            value: get(item, 'document.relatedAccount.numberFormatted', '-')
        },
        {
            label: intl.formatMessage({ id: 'jobProcessing_amount' }),
            value: get(item, 'document.amount', '-')
        },
        {
            label: intl.formatMessage({ id: 'jobProcessing_customerTaskStatus' }),
            value: get(item, `document.customerTaskStatus.text.${language}`, '-')
        },
        {
            label: intl.formatMessage({ id: 'jobProcessing_dueDate' }),
            value: <FormattedDate value={get(item, 'document.dueDate')} format="DD.MM.YYYY" />
        },
        {
            label: intl.formatMessage({ id: 'jobProcessing_expiryDate' }),
            value: <FormattedDate value={get(item, 'document.expireDate')} format="DD.MM.YYYY" />
        },
      ]
    }

    const hasCustomFileds = Object.keys(modalFields).indexOf(jobId) !== -1;


    const onShowHistoryModal = () => {
      jobProcessingActions.executionHistoryRequest({
        dataKey,
        job: get(item, 'job.id'),
        document: get(item, 'document.id'),
        data: [],
        limit: JOB_PROCESSING_PAGE_SIZE,
        offset: 0
      });

      setShowHistoryModal(true);
    };

    return (
        <React.Fragment>
            <TableRow>
                <TableCell className="job-processing-results-item__success">
                    <span className={booleanIcons}></span>
                </TableCell>
                <TableCell className="job-processing-results-item__createDate">
                    {item.createDate ? <FormattedDate value={item.createDate} format="DD.MM.YYYY HH:mm:ss" /> : '-'}
                </TableCell>
                <TableCell className="job-processing-results-item__job">
                    <OverlayTrigger placement="top" overlay={jobTooltip}>
                        <p className={'job-processing__ellpsis'}>
                            {item.job ? get(item, `job.name.${language}`) : '-'}
                        </p>
                    </OverlayTrigger>
                </TableCell>
                <TableCell className="job-processing-results-item__customer">
                    {item.customer ? `${get(item, 'customer.customerNumberAlternate', '')} / ${get(item, 'customer.designation', '')}` : '-'}
                </TableCell>
                <TableCell className="job-processing-results-item__message">
                    <OverlayTrigger placement="top" overlay={messageTooltip}>
                        <p className={'job-processing__ellpsis'}>
                            {item.message ? item.message : '-'}
                        </p>
                    </OverlayTrigger>
                </TableCell>
                <TableCell className="job-processing-results-item__details">
                    <span className="icon-046-information-circle icon-size icon-button" onClick={() => setShowDetailsModal(true)}></span>
                </TableCell>
                <TableCell className="job-processing-results-item__history">
                    <span className="icon-021-clock icon-size icon-button" onClick={onShowHistoryModal}></span>
                </TableCell>
            </TableRow>

            <ModalDialog show={showDetailsModal} onHide={() => setShowDetailsModal(false)} bsSize="lg">
                <ModalHeader closeButton={true}>
                    <div>{get(item, `job.name.${language}`)}, <FormattedDate value={item.createDate} format="DD.MM.YYYY HH:mm:ss" /></div>
                </ModalHeader>
                <ModalBody>
                    {hasCustomFileds ?
                        (
                            get(modalFields, jobId, []).map((field) => <div key={field.label}><b>{field.label}</b>: {field.value}</div>)
                        )
                        :
                        <ReactJson
                            src={get(item, 'jobDetails')}
                            name={false}
                            displayDataTypes={false}
                            collapsed={2}
                            indentWidth={2}
                            iconStyle="triangle"
                            onAdd={false}
                            onEdit={false}
                            onDelete={false}
                            enableClipboard={false}
                        />
                    }

                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setShowDetailsModal(false)} bsSize="sm">
                        <FormattedMessage id="common_cancel" />
                    </Button>
                </ModalFooter>
            </ModalDialog>

            <ModalDialog show={showHistoryModal} onHide={() => setShowHistoryModal(false)} bsSize="lg">
                <ModalHeader closeButton={true}>
                    <div>Job: {get(item, `job.id`, '-')}, Document: {get(item, `document.id`, '-')}</div>
                </ModalHeader>
                <ModalBody>
                    <ExecutedJobsHistoryTable
                      documentId={get(item, `document.id`)}
                      executionHistory={executionHistory}
                      intl={intl}
                      jobId={get(item, `job.id`)}
                      jobProcessingActions={jobProcessingActions}
                      language={language}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setShowHistoryModal(false)} bsSize="sm">
                        <FormattedMessage id="common_cancel" />
                    </Button>
                </ModalFooter>
            </ModalDialog>
        </React.Fragment>
    );
};

ExecutedJobsResultsItem.propTypes = {
  item: PropTypes.object,
  language: PropTypes.string,
  intl: PropTypes.object,
  executionHistory: PropTypes.object,
  jobProcessingActions: PropTypes.object.isRequired
};

export default ExecutedJobsResultsItem;
import { all, call, put, select } from 'redux-saga/effects';
import { BANK_LIST_SAVE } from '../../actions/BanklistActions';
import axios from 'axios';
import { createRef } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';
import { getBankListBanks, getBankListRemovedItems } from '../../selectors/bankListSelectors';

export function* bankListSaveSaga() {
  try {
    yield put(BANK_LIST_SAVE.pending());

    const removedBanksIds = yield select(getBankListRemovedItems);
    yield all(
      removedBanksIds.map((id) =>
        call(axios.delete, createRef(EndpointName.ENTITY_CODE, id)),
      ),
    );

    const banks = yield select(getBankListBanks);
    yield all(
      banks.map((bank) =>
        call(axios.put, createRef(EndpointName.ENTITY_CODE, bank.id), bank),
      ),
    );

    yield put(BANK_LIST_SAVE.success());
  } catch (error) {
    yield put(BANK_LIST_SAVE.failure(error));
  }
}

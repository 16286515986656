import PropTypes from 'prop-types';

const Translatable = PropTypes.shape({
  de: PropTypes.string,
  en: PropTypes.string,
  fr: PropTypes.string,
});
const RefObject = PropTypes.shape({
  $ref: PropTypes.string.isRequired,
});

export const UiNotesTypeShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  text: Translatable.isRequired,
});

export const UiNotesShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  publishDate: PropTypes.string,
  type: UiNotesTypeShape,
  title: Translatable,
  text: Translatable,
  image: RefObject,
});

import React from 'react';
import { Panel } from 'react-bootstrap';
import 'react-rangeslider/lib/index.css';
import Toggle from 'react-toggle';
import 'react-select/dist/react-select.css';
import _ from 'lodash';

export default class Item extends React.Component {

  updateFunction = (value) => {
    const { changeValue, item, index } = this.props;
    changeValue({ item, value: value, field: "transfer", index })
  }

  render() {
    const { item, changeValue } = this.props;
    const { customer, customerTaskStatus, customerTaskType, orderOrigin, note } = item;
    return(
      <Panel>
        <div style={{
          height: 60,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'flex-start',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          }}
        >
        <div
          style={{
              flex: 0,
              marginLeft: 20,
              minWidth: 80,
              marginBottom: 0
            }}
          >
            <Toggle
              onChange={() => changeValue({ item, value: !item.transfer, field: "transfer" })}
              checked={item.transfer}
            />
          </div>
          <span style={{ marginLeft: 20, minWidth: 100 }} >{ _.get(customer, "customerNumberAlternate") }</span>
          <span style={{ marginLeft: 20, minWidth: 150 }} >{ _.get(customer, "designation") }</span>
          <span style={{ marginLeft: 20, minWidth: 150 }} >{ `${_.get(customer, "consultant.lastName")} ${_.get(customer, "consultant.firstName")} (${_.get(customer, "consultant.username")})` }</span>
          <span style={{ marginLeft: 20, minWidth: 100 }} >{ _.get(customerTaskStatus, "text.de") }</span>
          <span style={{ marginLeft: 20, minWidth: 200 }} >{ _.get(customerTaskType, "text.de") }</span>
          <span style={{ marginLeft: 20, minWidth: 100 }} >{ _.get(orderOrigin, "text.de") }</span>
          <span style={{ marginLeft: 20, minWidth: 400 }} >{ note ? note.substr(0,120) : "" }</span>
        </div>
      </Panel>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NoticeImportFileRow } from './NoticeImportFileRow';
import { SortableTable } from '../Common/SortableTable';
import { Pagination } from '../Common/Pagination';


const SORTABLE_FIELDS = [
  {
    key: 'date',
    label: <FormattedMessage id="noticeImport_fileListFields_date" />,
    sortFn: (item) => item.metadata.createDate,
  },
  {
    key: 'user',
    label: <FormattedMessage id="noticeImport_fileListFields_user" />,
    sortFn: (item) => item.username,
  },
  {
    key: 'status',
    label: <FormattedMessage id="noticeImport_fileListFields_status" />,
    sortFn: (item) => item.validate,
  },
  {
    key: 'rows',
    label: <FormattedMessage id="noticeImport_fileListFields_rows" />,
    sortFn: (item) => item.imported,
  },
  {
    key: 'filename',
    label: <FormattedMessage id="noticeImport_fileListFields_filename" />,
    sortFn: (item) => item.metadata.filename,
  },
  {
    key: 'empty',
    label: '',
    sortFn: undefined,
  },
];


class NoticeImportFileListWithIntl extends React.Component {
  constructor(props) {
    super(props);
    this.getTableItems = this.getTableItems.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.renderPagination = this.renderPagination.bind(this);

    this.state = {
      page: 1,
    };
    this.pageSize = 25;
  }

  getTableItems() {
    const { fileListResult, intl } = this.props;
    if (!fileListResult.results) {
      return [];
    }

    const items = fileListResult.results.map((file, i) => {
      let imported = '';
      let username = '';
      let status = '';
      let lastAction = '';
      const additionalProperties = _.get(file, 'metadata.additionalProperties');
      if (additionalProperties) {
        const lastActionProp = additionalProperties.find(prop => prop.name === 'lastAction');
        const importedRowsCount = additionalProperties.find(prop => prop.name === 'importedRowsCount');
        const totalRowsCount = additionalProperties.find(prop => prop.name === 'totalRowsCount');
        const usernameProp = additionalProperties.find(prop => prop.name === 'username');
        const statusProp = additionalProperties.find(prop => prop.name === 'status');
        const failRowsCount = additionalProperties.find(prop => prop.name === 'failRowsCount');

        if (lastActionProp) {
          lastAction = lastActionProp.value;
        }
        if (lastAction) {
          imported = intl.formatMessage({ id: `noticeImport_status_${lastAction}` });
        }
        if (importedRowsCount) {
          imported += ` ${importedRowsCount.value} `;
        }
        if (totalRowsCount) {
          if (importedRowsCount) {
            imported += ` ${intl.formatMessage({ id: 'noticeImport_status_from' })}`;
          }
          imported += ` ${totalRowsCount.value}`;
          if (failRowsCount && failRowsCount.value > 0) {
            imported += ` (${intl.formatMessage({ id: 'noticeImport_status_fail' })} ${failRowsCount.value})`;
          }
        }
        if (usernameProp) {
          username = usernameProp.value;
        }
        if (statusProp) {
          status = statusProp.value;
        }
      }
      const statusLabels = {
        success: <FormattedMessage id="noticeImport_fileListStatus_success" />,
        fail: <FormattedMessage id="noticeImport_fileListStatus_fail" />,
        uploaded: <FormattedMessage id="noticeImport_fileListStatus_uploaded" />,
      };
      return {
        ...file,
        username,
        imported,
        status,
        statusText: statusLabels[status] || '',
      };
    });

    return items;
  }

  renderItem(file) {
    const { onImport, onDelete, importResult, deleteResult } = this.props;
    let importResultOfFile = null;
    if (importResult.fileId === file.id) {
      importResultOfFile = { ...importResult };
    }
    let deleteResultOfFile = null;
    if (deleteResult.fileId === file.id) {
      deleteResultOfFile = { ...deleteResult };
    }
    return (
      <NoticeImportFileRow
        file={file}
        key={file.id}
        importResult={importResultOfFile}
        importResultAll={importResult}
        deleteResult={deleteResultOfFile}
        deleteResultAll={deleteResult}
        onImport={onImport}
        onDelete={onDelete}
      />
    );
  }

  handlePageChange(page) {
    this.setState({ page });
  }

  renderPagination() {
    const { fileListResult } = this.props;
    const { page } = this.state;

    const pageCount = Math.ceil(fileListResult.results.length / this.pageSize);
    if (pageCount < 2) {
      return null;
    }

    return (
      <div className="ui-notes__pagination">
        <Pagination
          prev={true}
          next={true}
          first={true}
          last={true}
          boundaryLinks={true}
          ellipsis={true}
          items={pageCount}
          maxButtons={7}
          activePage={page}
          onSelect={this.handlePageChange}
        />
      </div>
    );
  }

  render() {
    const { fileListResult } = this.props;
    if (!fileListResult.results) {
      return null;
    }

    const { page } = this.state;
    const items = this.getTableItems().slice((page - 1) * this.pageSize, page * this.pageSize);

    return (
      <div>
        <SortableTable
          items={items}
          sortableFields={SORTABLE_FIELDS}
          renderItem={this.renderItem}
          className="analytics-active-users__table"
        />
        {this.renderPagination()}
      </div>
    );
  }
}

export const NoticeImportFileList = injectIntl(NoticeImportFileListWithIntl);

NoticeImportFileList.propTypes = {
  fileListResult: PropTypes.object,
  importResult: PropTypes.object,
  onDelete: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isNil } from 'lodash';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
import { Select, TextInput } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';

import getSpecialConfigValue from '../../Utils/Component/getSpecialConfigValue';

class SpecialComponentConfigCustomerOverwrite extends React.Component {
  constructor(props) {
    super(props);

    this.onInputChange = this.onInputChange.bind(this);
  }

  /**
   * Handle change of the jmes input field
   *
   * @param   {String}  key    Form element key
   * @param   {String}  value  Input value
   *
   * @return  void
   */
  onInputChange(key, value) {
    const { onSpecialConfigurationChange } = this.props;

    const v = isNil(value)
      ? undefined
      : `jmes:${value.replace('jmes:', '')}`;

    onSpecialConfigurationChange(key, v);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      item,
      items,
      language,
      onSpecialConfigurationChange
    } = this.props;

    const customerOverwriteOptions = items
      .filter((item) => get(item, 'component') === 'CustomerSelection' && !get(item, 'props.multi', false))
      .map((item) => ({
        value: `jmes:formData.${get(item, 'props.id')}.value.id`,
        label: get(item, `props.title.${language}`)
      }));
    const customerOverwrite = getSpecialConfigValue(item, 'customerOverwrite');
    const selectedCustomerOverwrite = customerOverwriteOptions.find((o) => o.value === customerOverwrite);

    return (
      <FormGroup controlId="customerOverwrite">
        <Col componentClass={ControlLabel} sm={3}>
          <FormattedMessage id="Workguide.Configuration.SpecialComponentConfig.CustomerOverwrite" />
        </Col>
        <Col sm={9} style={{ paddingTop: '10px' }}>

          {(isNil(customerOverwrite) || !isNil(selectedCustomerOverwrite)) && (
            <Select
              id="customerOverwrite"
              onChange={onSpecialConfigurationChange}
              options={customerOverwriteOptions}
              value={customerOverwrite}
            />
          )}

          <TextInput
            id="customerOverwrite"
            onChange={this.onInputChange}
            style={{ marginTop: '0.5em' }}
            value={customerOverwrite || ''}
          />
        </Col>
      </FormGroup>
    );
  }
}

SpecialComponentConfigCustomerOverwrite.propTypes = {
  item: PropTypes.object.isRequired,
  items: PropTypes.array,
  language: PropTypes.string,
  onSpecialConfigurationChange: PropTypes.func
};

SpecialComponentConfigCustomerOverwrite.defaultProps = {
  items: [],
  language: 'de',
  onSpecialConfigurationChange: noop
};

export default SpecialComponentConfigCustomerOverwrite;

/* eslint jsx-a11y/href-no-hash: 0 */
import './Module.css';

import React from 'react';

import Section from './Section';
import { ChecklistHeader } from './ChecklistHeader';


export default class Module extends React.Component {
  render() {
    const {
      language, onUpdate, onSortEnd, selectedModule, setSelectedModule,
    } = this.props;
    const toggleLabel = this.props.modules[selectedModule].collapsed ? 'Eingeklappt darstellen' : 'Ausgeklappt darstellen';
    const modules = this.props.modules.map((module, i) =>
      (<li key={i} className={i === selectedModule ? 'active' : ''}>
        <a href="#" onClick={setSelectedModule.bind(this, i)}> 
          <span>{module.name[language]}</span>
        </a>
       </li>));
    return (
      <div className="checklist-module">
        <ul className="nav nav-tabs">
          {modules}
        </ul>
        <ChecklistHeader
          title={this.props.modules[selectedModule].name[language]}
          label={toggleLabel}
          isChecked={!this.props.modules[selectedModule].collapsed}
          onChange={onUpdate.bind(this, 'toggleModule', selectedModule, 0, 0)}
        />
        <Section
          key={selectedModule}
          language={language}
          module={selectedModule}
          onUpdate={onUpdate}
          onSortEnd={onSortEnd}
          sections={this.props.modules[selectedModule].useCases[0].sections}
        />
      </div>
    );
  }
}

import { all, call, put, select } from 'redux-saga/effects';
import { FINANCING_RATES_SAVE_ACTIONS } from '../../actions/FinancingRatesActions';
import { getFinancingRatesInitial } from '../../selectors/FinancingRates/financingRatesCommonSelectors';
import { financingRatesSaveRefiRatesSaga } from './financingRatesSaveRefiRatesSaga';
import { financingRatesSaveBaseRatesSaga } from './financingRatesSaveBaseRateSaga';
import { financingRatesSaveForwardRatesSaga } from './financingRatesSaveForwardRatesSaga';
import { financingRatesCreateForwardsSaga } from './financingRatesCreateForwardsSaga';
import { financingRatesRemoveForwardsSaga } from './financingRatesRemoveForwardsSaga';

export function* financingRatesSaveSaga() {
  try {
    yield put(FINANCING_RATES_SAVE_ACTIONS.pending());

    const initialData = yield select(getFinancingRatesInitial);
    yield all([
      call(financingRatesSaveBaseRatesSaga, initialData.baseRates),
      call(financingRatesSaveRefiRatesSaga, initialData.refiRates),

      call(financingRatesRemoveForwardsSaga),
      call(financingRatesCreateForwardsSaga),
      call(financingRatesSaveForwardRatesSaga),
    ]);

    yield put(FINANCING_RATES_SAVE_ACTIONS.success());
  } catch (error) {
    yield put(FINANCING_RATES_SAVE_ACTIONS.failure(error));
  }
}

import axios from 'axios';
import { all, call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { CORE_CONFIG_LOAD } from '../../actions/CoreConfigActions';

export function* coreConfigLoadSaga() {
  try {
    yield put(CORE_CONFIG_LOAD.pending());

    const [
      { data: configs },
      { data: modules },
    ] = yield all([
      yield call(axios, { url: `${EndpointName.CORE_CONFIG}/?limit(100)` }),
      yield call(axios, { url: `${EndpointName.CORE_MODULE}/?or(eq(app.ref.$id,string:evoja),eq(app.ref.$id,string:bap))&limit(100)` }),
    ]);
    yield put(CORE_CONFIG_LOAD.success({
      configs,
      modules,
    }));
  } catch (error) {
    yield put(CORE_CONFIG_LOAD.failure(error));
  }
}

import { takeLatestByDataKey } from '@evoja-web/redux-saga-utils';
import { takeLatest } from 'redux-saga/effects';

import actions from '../actions/Actions';
import initSource from './initSource';
import initTarget from './initTarget';
import transferItem from './transferItem';
import transferModule from './transferModule';
import getSourceItems from './getSourceItems';

const {
  INIT_SOURCE_REQUEST,
  INIT_TARGET_REQUEST,
  SOURCE_ITEMS_REQUEST,
  TRANSFER_ITEM_REQUEST,
  TRANSFER_MODULE_REQUEST
} = actions;

export default function* paramTransferRootSaga() {
  yield takeLatest(INIT_SOURCE_REQUEST, initSource);
  yield takeLatest(INIT_TARGET_REQUEST, initTarget);
  yield takeLatestByDataKey(SOURCE_ITEMS_REQUEST, getSourceItems);
  yield takeLatestByDataKey(TRANSFER_ITEM_REQUEST, transferItem);
  yield takeLatestByDataKey(TRANSFER_MODULE_REQUEST, transferModule);
}

import ConditionList from './List';
import ConditionGeneric from './Generic';

export default {
  and: ConditionList,
  or: ConditionList,
  eq: ConditionGeneric,
  contains: ConditionGeneric,
  gt: ConditionGeneric,
  lt: ConditionGeneric,
  generic: ConditionGeneric
};

import { INIT_ACTIONS } from '../actions/InitActions';

const initialState = {
  loading: true,
  error: undefined,
  i18nMessages: {
    de: {},
    en: {},
    fr: {},
  },
};

export function InitReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_ACTIONS.PENDING:
      return { ...state, loading: true };
    case INIT_ACTIONS.SUCCESS:
      return { ...state, loading: false, error: undefined, i18nMessages: action.data };
    case INIT_ACTIONS.FAILURE:
      return { ...state, loading: false, error: action.data };
    default:
      return state;
  }
}

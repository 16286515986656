import React from 'react';
import PropTypes from 'prop-types';
import { chain, get, noop } from 'lodash';
import update from 'immutability-helper';

import Entry from './MappingEntry';

class ActionConfigurationServiceCallMapping extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  /**
   * Update the mapping value for the given key
   *
   * @param   {String}  key    Mapping key (property name)
   * @param   {String}  value  Mapping value
   *
   * @return  void
   */
  onChange(key, value) {
    const {
      action,
      onJmesParamChange
    } = this.props;

    const current = get(action, 'jmesParams.mapping', {});
    const updated = update(current, { [key]: { $set: value } });

    onJmesParamChange('mapping', updated);
  }

  /**
   * Remove the given key from mapping
   *
   * @param   {String}  key  Key to remove
   *
   * @return  void
   */
  onRemove(key) {
    const {
      action,
      onJmesParamChange
    } = this.props;

    const current = get(action, 'jmesParams.mapping', {});
    const updated = update(current, { $unset: [key] });

    onJmesParamChange('mapping', updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { action } = this.props;

    const children = chain(action)
      .get('jmesParams.mapping')
      .map((entry, key) => {
        return (
          <Entry
            key={key}
            action={action}
            id={key}
            onChange={this.onChange}
            onRemove={this.onRemove}
          />
        );
      })
      .value();

    return (
      <div className="workguide-action-configuration-service-call--mapping">
        {children}
      </div>
    );
  }
}

ActionConfigurationServiceCallMapping.propTypes = {
  action: PropTypes.object.isRequired,
  onJmesParamChange: PropTypes.func,
  validations: PropTypes.object
};

ActionConfigurationServiceCallMapping.defaultProps = {
  onJmesParamChange: noop,
  validations: {}
};

export default ActionConfigurationServiceCallMapping;

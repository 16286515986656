import { createSelector } from 'reselect';
import { getDocuments } from './getBasicServices';
import { getIdFromRef } from '../../utils';
import { getExistingProducts } from './getExistingProducts';

export const getRemovedDocuments = createSelector([
  getExistingProducts,
  getDocuments,
], (products, documents) => {
  const documentsFromRemovedProducts = documents.filter((document) =>
    !products.some((product) => getIdFromRef(document.product.$ref) === product.id)
  );
  const productsWithoutDocuments = products.filter((product) => !product.documents.length);
  const removedDocuments = documents.filter((document) =>
    productsWithoutDocuments.some((product) => getIdFromRef(document.product.$ref) === product.id)
  );

  return [...documentsFromRemovedProducts, ...removedDocuments];
});

import React from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import VirtualizedSelect from 'react-virtualized-select';
import Toggle from 'react-toggle';
import { SingleDatePicker } from 'react-dates';
import { SelectControl } from './Common/SelectControl';
import { InputControl } from './Common/InputControl';
import WysiwygEditor from './Common/WysiwygEditor';
import { DateTimePicker } from './Common/DateTimePicker';
import { DateRangePicker } from './Common/DateRangePicker';

export function FormRow(props) {
  const { id, label, labelSize = 3, meta: { error, touched }, displayErrors = true, children } = props;
  const validationState = displayErrors && touched && error ? 'error' : null;
  const labelWrapper = label
    ? <Col componentClass={ControlLabel} sm={labelSize}>{label}</Col>
    : null;

  return (
    <FormGroup controlId={id} validationState={validationState} className="form-row">
      <Row>
        {labelWrapper}
        <Col sm={labelWrapper ? labelSize === 12 ? 12 : 12 - labelSize : 12}>
          {children}
          <FormControl.Feedback/>
        </Col>
      </Row>
    </FormGroup>
  );
}

export function FormInput(props) {
  const { input, size = 'normal', labelSize, displayErrors, ...restProps } = props;
  const InputComponent = size === 'large' ? InputControl : FormControl;

  return (
    <FormRow {...props}>
      <InputComponent {...input} {...restProps} />
    </FormRow>
  );
}

export function FormSelect(props) {
  const { multi, size = 'normal', options, input, async, ...restProps } = props;
  // react-select/async is not working with simple values
  const onChange = (value) => async
    ? input.onChange(value)
    : input.onChange(multi
      ? value.map(it => it.value)
      : value ? value.value : null,
    );
  const onBlur = () => input.onBlur(input.value);
  const SelectComponent = size === 'large' ? SelectControl : VirtualizedSelect;

  return (
    <FormRow {...props}>
      <SelectComponent options={options}
                       multi={multi}
                       {...input}
                       value={input.value}
                       onChange={onChange}
                       onBlur={onBlur}
                       async={async}
                       {...restProps}/>
    </FormRow>
  );
}

export function FormToggle(props) {
  const { input, ...restProps } = props;
  return (
    <FormRow {...props}>
      <Toggle
        checked={input.value}
        onChange={input.onChange}
        {...restProps}/>
    </FormRow>
  );
}

export class FormDateRange extends React.Component {
  state = {
    focusedInput: undefined,
  };

  constructor(props) {
    super(props);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  render() {
    const { input, meta, label, labelSize, ...restProps } = this.props;
    const { focusedInput } = this.state;

    const startDate = input.value
      ? input.value.startDate
      : null;
    const endDate = input.value
      ? input.value.endDate
      : null;

    return (
      <FormRow {...this.props}>
        <DateRangePicker
          startDate={startDate}
          startDateId="start-date-id"
          endDate={endDate}
          endDateId="end-date-id"

          focusedInput={focusedInput}

          onDatesChange={input.onChange}
          onFocusChange={this.onFocusChange}
          disableScroll={false}

          {...restProps}/>
      </FormRow>
    );
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput });
  }
}

export class FormDate extends React.Component {
  state = {
    focused: false,
  };

  constructor(props) {
    super(props);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  render() {
    const { input, meta, label, labelSize, ...restProps } = this.props;
    const { focused } = this.state;

    const date = input.value ? input.value : null;
    return (
      <FormRow {...this.props}>
        <SingleDatePicker
          date={date}
          id="date-id"
          focused={focused}
          onDateChange={input.onChange}
          onFocusChange={this.onFocusChange}
          disableScroll={false}

          {...restProps}/>
      </FormRow>
    );
  }

  onFocusChange({ focused }) {
    this.setState({ focused });
  }
}

export function FormDateTime(props) {
  const { input, ...restProps } = props;

  return (
    <FormRow {...props}>
      <DateTimePicker
        value={input.value}
        onChange={input.onChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        {...restProps}
      />
    </FormRow>
  );
}

export function FormWysiwygEditor(props) {
  const { input, placeholder, displayErrors, ...restProps } = props;

  return (
    <FormRow {...props}>
      <WysiwygEditor
        value={input.value}
        disabled={input.disabled}
        placeholder={placeholder}
        onChange={input.onChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        {...restProps}/>
    </FormRow>
  );
}

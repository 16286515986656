import { call, put } from 'redux-saga/effects';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { isUndefined, get, slice } from 'lodash';
import axios from 'axios';

import executionHistoryAction from '../../actions/JobProcessing/ExecutionHistory';
import { JOB_PROCESSING_PAGE_SIZE } from '../../constants/JobProcessingConstants';

const {
  EXECUTION_HISTORY_PENDING,
  EXECUTION_HISTORY_FULFILLED,
  EXECUTION_HISTORY_REJECTED
} = executionHistoryAction;

export default function* getExecutionHistory(request) {
  const { dataKey } = request;

  yield put({ type: EXECUTION_HISTORY_PENDING, dataKey });

  try {
    const {
      data = [],
      document: doc,
      job,
      limit = JOB_PROCESSING_PAGE_SIZE,
      offset = 0
    } = request;

    const chunk = slice(data, offset, limit + offset);
    if (get(chunk, 'length', 0) > 0) {
      yield put({ type: EXECUTION_HISTORY_FULFILLED, dataKey, payload: data, limit, offset });
      return data;
    }

    const builder = QueryBuilder();

    if (!isUndefined(job)) {
      builder.like('job.type', job.slice(1))
    }

    if (!isUndefined(doc)) {
      builder.eq('document.id', doc, { type: 'string' });
    }

    builder.sort(['-createDate']);
    builder.limit(limit, offset);

    const url = `/event/status/worker/job-processing/${builder.getQueryString()}`;
    const result = yield call(axios, {
      url,
      method: 'get'
    });

    const payload = [...data, ...get(result, 'data', [])];

    yield put({ type: EXECUTION_HISTORY_FULFILLED, dataKey, payload, limit, offset });

    return payload;
  } catch (error) {
    yield put({ type: EXECUTION_HISTORY_REJECTED, dataKey, error });

    return error;
  }
}

import React from 'react';
import _ from 'lodash';
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';

export default class NumberInput extends React.Component {
  constructor() {
    super();
    this.state = {
      touched: false,
      dirty: false
    };
  }

  /**
   * Call parent onValueChange and set dirty to true
   *
   * @param  {Event} ev Event
   *
   * @return {NumberInput} this This instance
   */
  onValueChange(ev) {
    const { id, onValueChange } = this.props;
    this.setState({ dirty: true });
    onValueChange(id, ev);

    return this;
  }

  /**
   * Set this field to touched
   *
   * @param {Boolean} [touched=true] Touched
   *
   * @return {NumberInput} this This instance
   */
  setTouched(touched=true) {
    this.setState({ touched });

    return this;
  }

  /**
   * Render the input
   *
   * @return {ReactElement} markup
   */
  render() {
    const { value, validation, label='', placeholder='' } = this.props;
    const { touched, dirty } = this.state;

    let validationState = null;
    if (touched || dirty) validationState = _.isEmpty(validation) ? 'success' : 'error';

    return(
      <FormGroup validationState={ validationState }>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          type="string"
          value={ value }
          placeholder={ placeholder }
          onFocus={ this.setTouched.bind(this) }
          onChange={ this.onValueChange.bind(this) }>
        </FormControl>
        { ((touched || dirty) && !_.isEmpty(validation)) ? <HelpBlock>{ validation.message }</HelpBlock> : '' }
      </FormGroup>
    );
  }
}

import {
  GET,
  PUT,
  SET,
} from '../actions/ChecklistActions';

export default function ChecklistReducer(
  state = {
    checklist: {},
    requesting: false,
    error: {},
    hasError: false,
    changed: false,
    showModal: false,
    selectedModule: 0,
  },
  action,
) {
  switch (action.type) {
    case `${GET}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${GET}_FULFILLED`: {
      return { ...state, requesting: false, checklist: action.payload.data };
    }
    case `${GET}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case `${PUT}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${PUT}_FULFILLED`: {
      return { ...state, requesting: false };
    }
    case `${PUT}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case SET: {
      return { ...state, [action.key]: action.value };
    }
    default: {
      return state;
    }
  }
}

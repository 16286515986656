import { put, select } from 'redux-saga/effects';
import { getFinancingRatesRefiRates } from '../../selectors/FinancingRates/financingRatesCommonSelectors';
import { FINANCING_RATES_UPDATE_REFI_RATE_ACTIONS } from '../../actions/FinancingRatesActions';

export function* financingRatesUpdateRefiRateSaga({ data: { item, value } }) {
  if (item.interestRate === value) {
    return;
  }

  const refiRates = yield select(getFinancingRatesRefiRates);
  const updatedRefiRates = refiRates.map((it) =>
    it === item
      ? { ...it, interestRate: value }
      : it,
  );

  yield put(FINANCING_RATES_UPDATE_REFI_RATE_ACTIONS.success({ refiRates: updatedRefiRates }));
}

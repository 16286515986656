import React from 'react';
import PropTypes from 'prop-types';
import { chain, isNil, isString, get, noop } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import cl from 'classnames';

import './Item.css';
import {
  TRANSFER_ITEM_STATUS_WAITING,
  TRANSFER_ITEM_STATUS_PENDING,
  TRANSFER_ITEM_STATUS_FULFILLED,
  TRANSFER_ITEM_STATUS_REJECTED
} from '../../constants';
import getPossibleSourceItemNamePaths from '../../lib/Utils/getPossibleSourceItemNamePaths';
import getPossibleSourceItemValueFromPaths from '../../lib/Utils/getPossibleSourceItemValueFromPaths';

/**
 * Not every item has a name or title.
 * Loop over common, possible attributes and take the first on that is defined.
 * If none is defined, return an empty string
 *
 * @param   {Object}  item      Item
 * @param   {String}  language  Language
 *
 * @return  {String} name Item name
 */
function getName({ item, language = 'de' }) {
  const paths = getPossibleSourceItemNamePaths({ language });
  const value = chain(paths)
    .map((p) => get(item, p))
    .compact()
    .filter((v) => isString(v))
    .first()
    .value();

  return isNil(value)
    ? ''
    : value;
}

/**
 * Same as for item name...
 *
 * @param   {Object}  item      Item
 * @param   {String}  language  Language
 *
 * @return  {String} name Item name
 */
function getType({ item, language }) {

}

class ParamTransferSourceItem extends React.PureComponent {
  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      item,
      language,
      onTransferItem
    } = this.props;

    const statusIconClass = cl({
      mdi: true,
      'mdi-clock-outline': item.status === TRANSFER_ITEM_STATUS_WAITING,
      'mdi-sync': item.status === TRANSFER_ITEM_STATUS_PENDING,
      'mdi-check': item.status === TRANSFER_ITEM_STATUS_FULFILLED,
      'mdi-autorenew-off': item.status === TRANSFER_ITEM_STATUS_REJECTED,
    });

    const name = getPossibleSourceItemValueFromPaths({
      item,
      paths: getPossibleSourceItemNamePaths({ language }),
      filter: isString,
      defaultReturn: ''
    });
    const type = getPossibleSourceItemValueFromPaths({
      item,
      paths: [`data.type.text.${language}`, 'data.type'],
      filter: isString,
      defaultReturn: ''
    });

    return (
      <Row className={['param-transfer-source-items--item', `param-transfer-source-items--item--${item.status}`]}>
        <Col lg={3} md={3}>
          {get(item, 'data.id', '')}
        </Col>

        <Col lg={2} md={2}>
          {get(item, 'serviceUri')}
        </Col>

        <Col lg={4} md={4}>
          {name}
        </Col>

        <Col lg={2} md={2}>
          {type}
        </Col>

        <Col
          className="param-transfer-source-items--item--col-sync"
          lg={1}
          md={1}
        >
          <span
            className="mdi mdi-sync param-transfer-source-items--item--col-sync--icon"
            onClick={() => onTransferItem({ item })}
          />

          <span
            className={statusIconClass}
            title={`${get(item, 'error.message', '')} - ${get(item, 'error.cause.message', '')}`}
          />
        </Col>
      </Row>
    );
  }
}

ParamTransferSourceItem.propTypes = {
  item: PropTypes.object.isRequired,
  language: PropTypes.string,
  onTransferItem: PropTypes.func
};

ParamTransferSourceItem.defaultProps = {
  language: 'de',
  onTransferItem: noop
};

export default ParamTransferSourceItem;

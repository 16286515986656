import { put } from 'redux-saga/effects';

import { get } from 'lodash';
import actions from '../../actions/FeatureFlag/FeatureFlagForm';

const {
  FEATURE_FLAG_FORM_INIT_PENDING,
  FEATURE_FLAG_FORM_INIT_FULFILLED,
  FEATURE_FLAG_FORM_INIT_REJECTED
} = actions;

export default function* initFeatureFlagForm(request) {
  yield put({ type: FEATURE_FLAG_FORM_INIT_PENDING });

  const { featureFlag } = request;

  try {
    const {
      payload = {
        id: get(featureFlag, 'id'),
        identifier: get(featureFlag, 'identifier'),
        name: get(featureFlag, 'name', { de: '', fr: '', en: '' }),
        description: get(featureFlag, 'description', { de: '', fr: '', en: '' }),
        active: get(featureFlag, 'active')
      }
    } = request;

    yield put({ type: FEATURE_FLAG_FORM_INIT_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: FEATURE_FLAG_FORM_INIT_REJECTED, error });

    return error;
  }
}

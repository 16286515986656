export const PERMISSION_GROUP_PAGE_SIZE = 30;

export const PERMISSION_GROUP_DEFAULT_SORT = {
  sortField: 'firstName',
  sortDirection: 'desc',
};

export const PERMISSION_GROUP_LANGUAGE_OPTIONS = [
  { label: 'DE', value: 'de' },
  { label: 'EN', value: 'en' },
  { label: 'FR', value: 'fr' },
];

export const BULK_LOADS_TEXT_BLOCKS_MAP = {
  textIntro: 'adminUIBulkLoadsIntro',
};

export const BULK_LOADS_PAGE_SIZE = 10;
export const BULK_LOADS_REFRESH_INTERVAL = 10;

export const BULK_LOADS_STATES = {
  OPEN: 'open',
  ACTIVE: 'working',
  DONE: 'done',
  ERROR: 'error',
};

export const BULK_LOADS_CHART_COLORS = {
  [BULK_LOADS_STATES.OPEN]: 'rgba(136, 136, 136, 0.5)',
  [BULK_LOADS_STATES.ACTIVE]: 'rgba(0, 139, 200, 0.5)',
  [BULK_LOADS_STATES.DONE]: 'rgba(20, 173, 25, 0.5)',
  [BULK_LOADS_STATES.ERROR]: 'rgba(190, 36, 45, 0.5)',
};

export const BULK_LOADS_DEFAULT_SORT = {
  sortField: ['workHistory.open'],
  sortDirection: ['desc'],
};

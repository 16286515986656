import { Reducers } from '@evoja-web/redaction';

import { reducer as initSourceReducer } from './InitSource';
import { reducer as initTargetReducer } from './InitTarget';
import { reducer as sourceItemsReducer } from './SourceItems';
import { reducer as transferItemReducer } from './TransferItem';
import { reducer as transferModuleReducer } from './TransferModule';

export const reducers = Reducers([
  initSourceReducer,
  initTargetReducer,
  sourceItemsReducer,
  transferItemReducer,
  transferModuleReducer
]);

export default reducers.get();

import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import {
  GET_CODEGROUP_PENDING,
  GET_CODEGROUP_FULFILLED,
  GET_CODEGROUP_REJECTED
} from '../../actions/WorkguideActions';

export default function* getWorkguideCodeGroups() {
  yield put({ type: GET_CODEGROUP_PENDING });

  try {
    const { data } = yield call(axios, {
      url: '/analytics/codeGroup',
      method: 'get'
    });

    const payload = data.map((codegroup) => codegroup.codeGroup);

    yield put({ type: GET_CODEGROUP_FULFILLED, payload, data: payload });

    return payload;
  } catch (error) {
    yield put({ type: GET_CODEGROUP_REJECTED, payload: error, error });

    return error;
  }
}

import './ApiClient.css';

import React from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { handleGravitonError } from '../../utils';
import PageContainer from '../Common/PageContainer';
import { ApiClientLoginForm } from './ApiClientLoginForm';
import { ApiClientForm } from './ApiClientForm';
import { validateApiClientLoginForm } from '../../services/ApiClient/ApiClientLoginFormValidator';
import { validateApiClientForm } from '../../services/ApiClient/ApiClientFormValidator';
import { ModalBody, ModalDialog, ModalFooter, ModalHeader } from '../Common/Modal';
import { Button } from '../Common/Button';
import { ApiClientResponse } from './ApiClientResponse';

export const API_CLIENT_FORM = 'apiClientForm';
export class ApiClient extends React.Component {
  state = {
    isModalOpen: false,
  };

  constructor(props) {
    super(props);
    props.load();
  }

  render() {
    const { error, texts, loading, isAuthorized, result } = this.props;
    if (error) {
      toast.error(handleGravitonError(error));
    }

    return (
      <PageContainer title={<FormattedMessage id="apiClient_title"/>} requesting={loading}>
        <div className="api-client">
          {texts.textIntro ? <div className="api-client__text">{texts.textIntro}</div> : null}
          {isAuthorized ? this.renderRequestForm() : this.renderLoginForm()}
          {isAuthorized && result ? this.renderModal() : null}
        </div>
      </PageContainer>
    );
  }

  renderLoginForm() {
    const { login, isAuthorized } = this.props;
    return (
      <ApiClientLoginForm
        form="apiClientLoginForm"
        login={login}
        validate={validateApiClientLoginForm}
        authError={isAuthorized === false}
      />
    );
  }

  renderRequestForm() {
    const { endpoints, currentValues, texts, schema } = this.props;
    const { executing, loadService, loadingService } = this.props;
    return (
      <ApiClientForm
        form="apiClientForm"
        execute={this.executeRequest}
        executing={executing}
        loadService={loadService}
        loadingService={loadingService}
        validate={validateApiClientForm}
        endpoints={endpoints}
        textParamsHelp={texts.textHelp}
        currentValues={currentValues}
        schema={schema}
      />
    );
  }

  renderModal() {
    const { result } = this.props;
    const { isModalOpen } = this.state;
    const { request } = result;

    return (
      <ModalDialog show={isModalOpen} onHide={this.closeModal} bsSize="lg">
        <ModalHeader>
          {request.method.toUpperCase()}{' '}
          "{request.service || request.customUrl}{request.params ? request.params : ''}"
        </ModalHeader>
        <ModalBody>
          <ApiClientResponse result={result}/>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.closeModal} bsSize="sm">Abbrechen</Button>
        </ModalFooter>
      </ModalDialog>
    );
  }

  executeRequest = (formData) => {
    const { execute } = this.props;
    execute(formData);
    this.setState({ isModalOpen: true });
  };

  closeModal = () => this.setState({ isModalOpen: false });
}

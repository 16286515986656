import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Row, ProgressBar } from 'react-bootstrap';
import { get } from 'lodash';

import './Progress.css';

const Progress = React.memo(({
  description,
  progress,
  title
}) => {
  const {
    count,
    error,
    errors,
    fulfilled,
    requesting,
    total
  } = progress;

  const bsStyle = error
    ? 'danger'
    : fulfilled
      ? 'success'
      : 'info';

  const errorsCount = get(errors, 'length', 0);
  const loaded = total > 0
    ? (100 / total) * count
    : 100;
  const errorneous = (100 / total) * errorsCount;

  return (
    <div className="replay-audit-log-progress">
      <Row>
        <Col lg={12}>
          <Alert bsStyle={bsStyle}>
            <h4>
              {title}
            </h4>

            <p>
              {description}
            </p>

            <div>
              {(requesting || fulfilled) && (
                <ProgressBar>
                  <ProgressBar active={requesting} bsStyle="info" now={loaded} label={`${count}/${total}`} />
                  <ProgressBar active={requesting} bsStyle="warning" now={errorneous} label={errorsCount > 0 ? errorsCount : ''}/>
                </ProgressBar>
              )}
            </div>
          </Alert>
        </Col>
      </Row>
    </div>
  );
});

Progress.propTypes = {
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  progress: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ])
};

Progress.defaultProps = {
  description: '',
  title: ''
};

export default Progress;

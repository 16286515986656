import './Toggle.css';
import React from 'react';
import ReactToggle from 'react-toggle';
import { classList } from '../../utils/classList';

export function Toggle({ className, ...props }) {
  return (
    <ReactToggle {...props}
      className={classList("toggle-component", className)}
      icons={{
        checked: <span className="icon-011-check-mark"/>,
        unchecked: false,
      }}
    />
  );
}

import { createSelector } from 'reselect';

export const getApiClientState = (state) => state.apiClient;
export const getApiClientError = createSelector([getApiClientState], (state) => state.error);
export const getApiClientLoading = createSelector([getApiClientState], (state) => state.loading);
export const getApiClientExecuting = createSelector([getApiClientState], (state) => state.executing);
export const getApiClientTextBlocks = createSelector([getApiClientState], (state) => state.textBlocks);
export const getApiClientIsAuthorized = createSelector([getApiClientState], (state) => state.isAuthorized);
export const getApiClientEndpoints = createSelector([getApiClientState], (state) => state.endpoints);
export const getApiClientLoadingService = createSelector([getApiClientState], (state) => state.loadingService);
export const getApiClientSchema = createSelector([getApiClientState], (state) => state.schema);
export const getApiClientResult = createSelector([getApiClientState], (state) => state.result);

import { attachReducer, chainReducers } from '../utils';
import {
  LoadPromotionsReducer,
  LoadPromotionsFilesReducer,
  FilterPromotionsReducer,
  ShowMorePromotionsReducer,
  RemovePromotionsReducer,
  SavePromotionsReducer,
} from './Promotions';
import {
  FILTER_PROMOTIONS_ACTIONS,
  SHOW_MORE_PROMOTIONS_ACTIONS,
} from '../actions/PromotionActions';

export const PromotionsReducer = chainReducers(
  LoadPromotionsReducer,
  LoadPromotionsFilesReducer,
  SavePromotionsReducer,
  RemovePromotionsReducer,
  attachReducer(FILTER_PROMOTIONS_ACTIONS.REQUEST, FilterPromotionsReducer),
  attachReducer(SHOW_MORE_PROMOTIONS_ACTIONS.REQUEST, ShowMorePromotionsReducer),
);

import { parseLinkHeader } from '@web3-storage/parse-link-header';
import { call } from 'redux-saga/effects';
import axios from 'axios';
import _ from 'lodash';

import { removeHost } from './index';

/**
 * Handle next header. Call the given url as long an next header is set on response
 *
 * @param  {String} url URL
 *
 * @return {Object} data Data from service
 */
export default function* handleNextHeaders(url) {
  let data = [];
  let nextChunk = [];
  let linkHeaders = { next: { url } };
  while (linkHeaders.hasOwnProperty('next')) {
    nextChunk = yield call(axios.get, removeHost(linkHeaders.next.url));
    data = [ ...data, ...nextChunk.data ];
    linkHeaders = parseLinkHeader(nextChunk.headers.link, { maxHeaderLength: 5000 });
    linkHeaders = (!linkHeaders || _.isNull(linkHeaders)) ? {} : linkHeaders;
  }

  return data;
}

import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';
import { get, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';

import './Categories.css';

const ModuleOverviewCategories = React.memo(({
  active,
  categories,
  language,
  onChange
}) => {
  const children = get(categories, 'data', [])
    .map((category) => {
      return (
        <div
          key={category.id}
          className={cl('module-overview--categories--category', { 'module-overview--categories--category--active': active === category.id })}
          onClick={() => onChange(category.id)}
        >
          {get(category, `text.${language}`)}
        </div>
      );
    });

  return (
    <div className="module-overview--categories">
      <div
        className={cl('module-overview--categories--category', { 'module-overview--categories--category--active': active === '*' })}
        onClick={() => onChange('*')}
      >
        <FormattedMessage id="Module.Overview.Category.All" />
      </div>

      {children}
    </div>
  );
});

ModuleOverviewCategories.propTypes = {
  active: PropTypes.string,
  categories: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func
};

ModuleOverviewCategories.defaultProps = {
  active: 'all',
  categories: {},
  language: 'de',
  onChange: noop
};

export default ModuleOverviewCategories;

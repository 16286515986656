import React from 'react';
import { Alert } from 'react-bootstrap';

const UnauthorizedRoute = React.memo(() => {
  return (
    <Alert
      bsStyle="danger"
      style={{ textAlign: 'center' }}
    >
      <h4>
        Du kommst hier net rein!
      </h4>

      <p>
        Sie verfügen nicht über die benötigten Berechtigungen um diese Seite einzusehen!
      </p>
    </Alert>
  );
});

UnauthorizedRoute.propTypes = {

};

UnauthorizedRoute.defaultProps = {

};

export default UnauthorizedRoute;

import React from 'react';
import PropTypes from 'prop-types';
import { get, isInteger, noop } from 'lodash';
import { FormGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

class MaxStringLengthConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
    this.isInputAllowed = this.isInputAllowed.bind(this);
  }

  onValueChange(values){
    const { onChange } = this.props
    const floatValue = get(values, 'floatValue', 0);

    onChange([floatValue]);
  }

  isInputAllowed(values) {
    const floatValue = get(values, 'floatValue', 0);

    return isInteger(floatValue);
  }

  getCurrentValue() {
    const { validation, component } = this.props;

    return get(component, `props.validations.${validation.key}.maxStringLength[0]`, 0);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    return (
      <FormGroup style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <NumberFormat
          className="form-control"
          onValueChange={this.onValueChange}
          value={this.getCurrentValue()}
          isAllowed={this.isInputAllowed}
        />
      </FormGroup>
    );
  }
}

MaxStringLengthConfiguration.propTypes = {
  onChange: PropTypes.func
};

MaxStringLengthConfiguration.defaultProps = {
  onChange: noop
};

export default MaxStringLengthConfiguration;

import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import { getFinancingRatesRemovedForwards } from '../../selectors/FinancingRates/financingRatesForwardSelectors';
import { createRef } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';

export function* financingRatesRemoveForwardsSaga() {
  const removedForwards = yield select(getFinancingRatesRemovedForwards);
  yield all(
    removedForwards.map((forward) => call(axios, {
      url: createRef(EndpointName.FINANCING_FORWARD, forward.id),
      method: 'delete',
    })),
  );
}

import { call } from 'redux-saga/effects';
import QueryBuilder from '@evoja-web/rql-query-builder';

/**
 * Get the target modules from /core/module
 *
 * @param   {Axios}  axiosInstance  Axios instance to use
 *
 * @return  {Array}  modules  Modules from /core/module
 */
export default function* getModules({ axiosInstance }) {
  try {
    const query = QueryBuilder()
      .eq('app.ref.$id', 'adminv2')
      .eq('paramTransferAllowed', true)
      .sort(['+order', '+category.number'])
      .limit(9999)
      .getQueryString();

    const url = `/core/module/${query}`;

    const { data } = yield call(axiosInstance, {
      url,
      method: 'get'
    });

    return data;
  } catch (error) {
    throw new Error(`Unable to load modules from target system ("${axiosInstance}")`, { cause: error });
  }
}

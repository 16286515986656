import React from 'react';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import update from 'immutability-helper';
import { toast } from 'react-toastify';
import * as actions from '../actions/ChecklistActions';

import uuid from 'uuid';

import Module from '../components/Checklist/Module';
import PageContainer from '../components/Common/PageContainer';
import { handleGravitonError } from '../utils';

class Checklist extends React.Component {
  constructor() {
    super();
    this.update = this.update.bind(this);
    this.saveData = this.saveData.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(actions.getChecklist());
  }

  setSelectedModule(id, event) {
    event.preventDefault();
    this.props.dispatch(actions.setData({ key: 'selectedModule', value: id }));
  }

  onSortEnd = ({ oldIndex, newIndex, collection }) => {
    const { selectedModule, checklist } = this.props;
    let newOrder = arrayMove(checklist.modules[selectedModule].useCases[0].sections[collection].items, oldIndex, newIndex);

    newOrder = newOrder.map((o, i) => {
      o.order = i * 100;
      return (o);
    });
    const newChecklist = update(checklist, {
      modules: { [selectedModule]: { useCases: { 0: { sections: { [collection]: { items: { $set: newOrder } } } } } } },
    });
    this.props.dispatch(actions.setData({ key: 'checklist', value: newChecklist }));
    this.props.dispatch(actions.setData({ key: 'changed', value: true }));
  };

  // handle CRUD operations on the checklsit modules/sections/items
  update(action, module = 0, section = 0, item = 0, event) {
    let value,
      name;
    if (typeof event.target === 'object') {
      value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      name = event.target.name;
    } else {
      value = event.value;
      name = event.name;
    }

    switch (action) {
      case 'createItem':
        this.addChecklistItem(module, section, item);
        break;
      case 'deleteItem':
        this.delChecklistItem(module, section, item);
        break;
      case 'updateItem':
        this.updateChecklistItem(module, section, item, name, value);
        break;
      case 'toggleModule':
        this.updateModule(module, value);
        break;
      case 'toggleSection':
        this.updateSection(module, section, value);
        break;
      default:
    }
    this.props.dispatch(actions.setData({ key: 'changed', value: true }));
  }

  // save the changes in ChecklistStore back to the graviton service
  saveData(event) {
    event.preventDefault();
    if (this.props.changed) {
      this.props.dispatch(actions.updateChecklist(this.props.checklist));
      this.props.dispatch(actions.setData({ key: 'changed', value: false }));
    }
  }

  // handle collapsed value toggle on module level
  updateModule(module, value) {
    const { checklist } = this.props;
    const newChecklist = update(checklist, {
      modules: { [module]: { collapsed: { $set: !value } } },
    });
    this.props.dispatch(actions.setData({ key: 'checklist', value: newChecklist }));
  }

  // handle collapsed value toggle on section level
  updateSection(module, section, value) {
    const { checklist } = this.props;
    const newChecklist = update(checklist, {
      modules: { [module]: { useCases: { 0: { sections: { [section]: { collapsed: { $set: !value } } } } } } },
    });
    this.props.dispatch(actions.setData({ key: 'checklist', value: newChecklist }));
  }


  // handle value updates on checklist items
  updateChecklistItem(module, section, item, fieldName, value) {
    const { checklist, language } = this.props;
    const { name } = checklist.modules[module].useCases[0].sections[section].items[item];
    let newChecklist = null;
    switch (fieldName) {
      case 'checklistItem':
        // english is the main language in graviton. make shure english has always a value
        // when making a new entry not in english, the entry in the selected language will
        // be added to english to make shure translatable will work as expected
        newChecklist = update(checklist, {
          modules: { [module]: { useCases: { 0: { sections: { [section]: { items: { [item]: { name: { [language]: { $set: value } } } } } } } } } },
        });
        if (language !== 'en' && name.en === name[language]) {
          newChecklist = update(newChecklist, {
            modules: { [module]: { useCases: { 0: { sections: { [section]: { items: { [item]: { name: { en: { $set: value } } } } } } } } } },
          });
        }
        break;
      case 'checkedState':
        newChecklist = update(checklist, {
          modules: {
            [module]: {
              useCases: {
                0: {
                  sections: {
                    [section]: {
                      items: { [item]: { defaultCheckedState: { $set: value }, isChecked: { $set: value } } }
                    }
                  }
                }
              }
            }
          },
        });
        break;
      case 'displayCondition':
        newChecklist = update(checklist, {
          modules: { [module]: { useCases: { 0: { sections: { [section]: { items: { [item]: { displayCondition: { $set: value } } } } } } } } },
        });
        break;
      default:
        break;
    }
    this.props.dispatch(actions.setData({ key: 'checklist', value: newChecklist }));
  }

  // handle deletes on checklist items
  delChecklistItem(module, section, item) {
    const { checklist } = this.props;
    const items = checklist.modules[module].useCases[0].sections[section].items.filter((o, i) => i !== item);
    const newChecklist = update(checklist, {
      modules: { [module]: { useCases: { 0: { sections: { [section]: { items: { $set: items } } } } } } },
    });
    this.props.dispatch(actions.setData({ key: 'checklist', value: newChecklist }));
  }


  // handle to create new checklist items
  addChecklistItem(module, section, item) {
    const newItem = {
      defaultCheckedState: false,
      order: 999999999,
      isChecked: false,
      isOwnItem: false,
      id: '',
      name: {
        de: '',
        en: '',
        fr: '',
      },
    };
    newItem.id = uuid.v4();
    const { checklist } = this.props;
    const newChecklist = update(checklist, {
      modules: { [module]: { useCases: { 0: { sections: { [section]: { items: { $push: [newItem] } } } } } } },
    });
    this.props.dispatch(actions.setData({ key: 'checklist', value: newChecklist }));
  }

  // embed the checklist in to the EditContainer component
  render() {
    const {
      requesting, language, checklist, selectedModule, hasError, error,
    } = this.props;
    if (hasError) {
      toast.error(handleGravitonError(error));
    }

    return (
      <PageContainer
        requesting={requesting}
        saveData={this.saveData}
        title={`Checkliste (${language})`}>
        {checklist.modules &&
        <div>
          <Module
            modules={checklist.modules}
            selectedModule={selectedModule}
            language={language}
            onUpdate={this.update.bind(this)}
            onSortEnd={this.onSortEnd.bind(this)}
            setSelectedModule={this.setSelectedModule.bind(this)}
          />
        </div>}
      </PageContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    checklist: state.checklist.checklist,
    requesting: state.checklist.requesting,
    language: state.login.language,
    changed: state.checklist.changed,
    showModal: state.checklist.showModal,
    selectedModule: state.checklist.selectedModule,
    hasError: state.checklist.hasError,
    error: state.checklist.error,
  }
}

export default connect(mapStateToProps)(Checklist);

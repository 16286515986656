import CreateCardSuspension from '../../../../../../Action/Configuration/CreateCardSuspension';
import DeleteCardSuspension from '../../../../../../Action/Configuration/DeleteCardSuspension';
import CreateCustomerSuspension from '../../../../../../Action/Configuration/CreateCustomerSuspension';
import DeleteCustomerSuspension from '../../../../../../Action/Configuration/DeleteCustomerSuspension';
import ExternalServiceCall from '../../../../../../Action/Configuration/ExternalServiceCall';
import NoteToEDossier from '../../../../../../Action/Configuration/NoteToEDossier';
import ServiceCall from '../../../../../../Action/Configuration/ServiceCall';
import RuediNotification from '../../../../../../Action/Configuration/RuediNotification';
import SendMail from './SendMail';

export default {
  SendApprovalMail: SendMail,
  SendRejectMail: SendMail,
  CreateCardSuspension,
  DeleteCardSuspension,
  CreateCustomerSuspension,
  DeleteCustomerSuspension,
  NoteToEDossier,
  RuediNotification,
  ServiceCall,
  ExternalServiceCall
};

import { isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { getDocuments } from './getBasicServices';
import { getProductsWithDocuments } from './getProductsWithDocuments';
import { mapBasicDocumentsToApi } from '../../services/BasicProducts';
import { getIdFromRef } from '../../utils';

export const getUpdatedDocuments = createSelector([
  getProductsWithDocuments,
  getDocuments,
], (products, basicDocuments) => {
  const productsWithExistingDocuments = products.filter((product) =>
    basicDocuments.some((document) => getIdFromRef(document.product.$ref) === product.id)
  );

  return basicDocuments.reduce((result, document) => {
    const product = productsWithExistingDocuments.find(
      (product) => getIdFromRef(document.product.$ref) === product.id
    );
    if (!product) {
      return result;
    }
    return isEqual(document.document, product.documents)
      ? result
      : [...result, {...document, ...mapBasicDocumentsToApi(product)}];
  }, []);
});

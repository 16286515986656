import './AnalyticsMenu.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { classList } from '../../utils/classList';

export class AnalyticsMenu extends React.Component {
  render() {
    const { pages, activePage = '' } = this.props;

    const items = pages.map((page, index) => {
      const classes = classList({
        'analytics-menu__item': true,
        'analytics-menu__item--active': activePage === page.value,
      });
      const path = ['analytics', page.value].join('/');
      return (
        <div className={classes} key={index}>
          <Link to={`/${path}`}>{page.title}</Link>
        </div>
      );
    });
    return (
      <div className="analytics-menu">
        {items}
      </div>
    );
  }
}

AnalyticsMenu.propTypes = {
  pages: PropTypes.array.isRequired,
  activePage: PropTypes.string,
};

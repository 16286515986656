import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ListGroupItem } from 'react-bootstrap';

import Form from './Form';

class ListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false
    };

    this.toggleEdit = this.toggleEdit.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  toggleEdit(edit) {
    this.setState({ edit: edit || !this.state.edit });
  }

  onSave(code) {
    const { onSave } = this.props;

    this.setState({ edit: false });
    onSave(code);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { language, data, onDelete } = this.props;

    return(
      <ListGroupItem>
        {(this.state.edit) ? (
          <div style={{ paddingBottom: '20px' }}>
            <Form
              data={data}
              onSubmit={this.onSave}
              onCancel={() => this.toggleEdit(false)}
            />
          </div>
        ) : (
          <div>
            <div className="pull-right">
              <span
                className="glyphicon glyphicon-pencil"
                onClick={() => this.toggleEdit(true)}
                style={{ paddingRight: '5px' }}
              />
              <span
                className="glyphicon glyphicon-remove"
                onClick={() => onDelete(data)}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '40%' }}>
                <strong>ID</strong>
                <div>{_.get(data, `id`)}</div>
              </div>
              <div style={{ width: '50%', float: 'right' }}>
                <strong>Text</strong>
                <div>{_.get(data, `text.${language}`)}</div>
              </div>
            </div>
          </div>
        )}
      </ListGroupItem>
    );
  }
}

ListItem.propTypes = {
  data: PropTypes.object.isRequired,
  language: PropTypes.string,
  onSave: PropTypes.func,
  onDelete: PropTypes.func
};

ListItem.defaultProps = {
  language: 'de',
  onSave: _.noop,
  onDelete: _.noop
};

export default ListItem;

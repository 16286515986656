import { call, put } from 'redux-saga/effects';

// ToDo: Move functions like handleNextHeaders to own repo
import connectTargetAction from '../../actions/ReplayAuditLog/ConnectTarget';
import connect from './connect';

const {
  CONNECT_TARGET_PENDING,
  CONNECT_TARGET_FULFILLED,
  CONNECT_TARGET_REJECTED
} = connectTargetAction;

export default function* connectTarget(request) {
  yield put({ type: CONNECT_TARGET_PENDING });

  try {
    const payload = yield call(connect, request);

    yield put({ type: CONNECT_TARGET_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CONNECT_TARGET_REJECTED, error });

    return error;
  }
}

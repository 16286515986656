import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isNil, find } from 'lodash';
import { withAcl } from '@evoja-web/client-acl';
import { Route, matchPath, Redirect } from 'react-router-dom';

import UnauthorizedRoute from './Unauthoized';
import getLoginUrl from '../../utils/getLoginUrl';

const permissionKeyGetters = {
  '/transfer': () => 'Modules.ParamTransfer.Show',
  default: ({ module }) => `Modules.${get(module, 'id')}.Show`
};

function getPermissionKey({ path, module }) {
  const getter = get(permissionKeyGetters, path, permissionKeyGetters.default);

  return getter({ path, module });
}

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);

    this.renderRoute = this.renderRoute.bind(this);
  }

  /**
   * Check if the user is allowed to view the given route
   *
   * @return  {Boolean}
   */
  isAllowed() {
    const {
      acl,
      modules,
      path
    } = this.props;
    const module = find(modules, (m) => matchPath(get(m, 'data.path'), { path }));

    const permissionKey = getPermissionKey({ module: get(module, 'data'), path });

    return (
      !isNil(permissionKey)
      && (
        acl.isAllowed('Modules.ShowAll')
        || acl.isAllowed(permissionKey)
      )
    );
  }

  /**
   * Render the component (if a session is set)
   * or a redirect to the login page
   *
   * @return {ReactElement} markup
   */
  renderRoute() {
    const {
      component,
      login,
      ...rest
    } = this.props;

    const Component = this.isAllowed()
      ? component
      : UnauthorizedRoute;

    return !isNil(login.session)
      ? (
        <Component
          login={login}
          {...rest}
        />
      )
      : (
        <Redirect
          to={{
            pathname: getLoginUrl(),
            state: { from: get(props, 'location') }
          }}
        />
      );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={this.renderRoute}
      />
    );
  }
}

PrivateRoute.propTypes = {
  acl: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element
  ]),
  login: PropTypes.object,
  modules: PropTypes.object,
  path: PropTypes.string.isRequired
};

PrivateRoute.defaultProps = {
  component: undefined,
  login: {},
  modules: {}
};

function mapStateToProps(state) {
  return {
    login: state.login,
    modules: state.module.modules
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({}, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withAcl()(PrivateRoute)
);

import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  LOAD_PROMOTIONS_ACTIONS,
  LOAD_PROMOTIONS_FILES_ACTIONS,
  REMOVE_PROMOTIONS_ACTIONS,
  SAVE_PROMOTIONS_ACTIONS,
} from '../../actions/PromotionActions';
import { loadPromotions } from './loadPromotionsSaga';
import { loadPromotionsFilesSaga } from './loadPromotionsFilesSaga';
import { removePromotionsSaga } from './removePromotionsSaga';
import { savePromotionsSaga } from './savePromotionsSaga';

export function* promotionsSagas() {
  yield takeLatest(LOAD_PROMOTIONS_ACTIONS.REQUEST, loadPromotions);
  yield takeEvery(LOAD_PROMOTIONS_FILES_ACTIONS.REQUEST, loadPromotionsFilesSaga);
  yield takeEvery(REMOVE_PROMOTIONS_ACTIONS.REQUEST, removePromotionsSaga);
  yield takeEvery(SAVE_PROMOTIONS_ACTIONS.REQUEST, savePromotionsSaga);
}

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ListGroupItem } from 'react-bootstrap';

import ActionForm from './Form';
import Params from './Params';
import { actions } from '../constants';

class ListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onSubmit(action) {
    const { onChange } = this.props;

    this.setState({ edit: false });
    onChange(action);
  }

  onCancel() {
    this.setState({ edit: false });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { edit } = this.state;
    const {
      target,
      action,
      onRemove,
      language,
      codes,
      consultants,
      productGroups
    } = this.props;

    const { type, params, path } = action;

    if (edit) return (
      <ActionForm
        target={target}
        action={action}
        onSubmit={this.onSubmit}
        onCancel={this.onCancel}
      />
    );

    return (
      <ListGroupItem>
        <div>
          <div className="pull-right">
            <span onClick={() => this.setState({ edit: true })}><i className="glyphicon glyphicon-pencil" /></span>
            <span onClick={onRemove}><i className="glyphicon glyphicon-remove" /></span>
          </div>

          <div>
            <strong>Aktion: </strong>{_.get(actions, `${action.type}.label`)}
          </div>

          <div>
            {(type) ? (
              <Params
                target={target}
                language={language}
                codes={codes}
                consultants={consultants}
                productGroups={productGroups}
                action={type}
                onChange={this.onParamsChange}
                value={params}
                path={path}
                edit={false}
              />
            ) : null}

          </div>
        </div>
      </ListGroupItem>
    );
  }
}

ListItem.propTypes = {
  target: PropTypes.object.isRequired,
  language: PropTypes.string,
  condition: PropTypes.object,
  components: PropTypes.array,
  codes: PropTypes.object,
  consultants: PropTypes.array,
  productGroups: PropTypes.array,
  onChange: PropTypes.func
};

ListItem.defaultProps = {
  language: 'de',
  condition: {},
  components: [],
  codes: {},
  consultants: [],
  productGroups: [],
  onChange: _.noop
};

export default ListItem;

import './BulkLoadsQuality.css';

import React from 'react';
import { classList } from '../../utils/classList';
import { BULK_LOADS_STATES } from '../../constants/BulkLoadsConstants';
import { BULK_LOAD_PROP_TYPES_STATE } from './BulkLoadsPropTypes';

const LABELS = {
  [BULK_LOADS_STATES.OPEN]: 'Pending',
  [BULK_LOADS_STATES.ACTIVE]: 'Running',
  [BULK_LOADS_STATES.DONE]: 'OK',
  [BULK_LOADS_STATES.ERROR]: 'Errors',
};

export function BulkLoadsQuality({ quality }) {
  const classes = classList(
    'bulk-loads-quality',
    `bulk-loads-quality--${quality}`,
  );

  return (
    <span className={classes} title={quality}>
      {LABELS[quality]}
    </span>
  );
}

BulkLoadsQuality.propTypes = {
  quality: BULK_LOAD_PROP_TYPES_STATE.isRequired,
};

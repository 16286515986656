import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { chain, get } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import PacmanLoader from 'react-spinners/PacmanLoader';
import cl from 'classnames';

import './Module.css';
import ItemsList from '../Source/List';
import paramTransferActions from '../../actions/Actions';

class ParamTransferModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true
    };

    this.onStartTransfer = this.onStartTransfer.bind(this);
    this.onToggleSourceItemList = this.onToggleSourceItemList.bind(this);
  }

  /**
   * Start the transfer for this module
   *
   * @return  {[type]}  [return description]
   */
  onStartTransfer() {
    const {
      module,
      source,
      target,
      paramTransferActions
    } = this.props;

    this.setState({ collapsed: false });

    const dataKey = get(module, 'key');
    paramTransferActions.transferModuleReset({ dataKey });
    paramTransferActions.sourceItemsReset({ dataKey });
    paramTransferActions.transferModuleRequest({
      dataKey,
      module,
      source,
      target
    });
  }

  /**
   * Expand / collapse the source items list
   *
   * @return  void
   */
  onToggleSourceItemList() {
    const { collapsed } = this.state;

    this.setState({ collapsed: !collapsed });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { collapsed } = this.state;
    const {
      fulfilledItemsCount,
      language,
      module,
      rejectedItemsCount,
      tenant,
      totalItemsCount,
      transferModule
    } = this.props;

    const name = get(module, `name.${language}`, '');
    const description = get(module, `service.0.description.${language}`, '');

    return (
      <div className="param-transfer-module">
        <Row className="param-transfer-module--row">
          <Col
            className="param-transfer-module--info"
            lg={10}
            md={10}
          >
            <div className="param-transfer-module--info--image">
              <img
                src={`/images/${tenant}/badge_${module.key}.jpg`}
                alt={name}
                className="img-circle"
              />
            </div>

            <div className="param-transfer-module--info--service">
              <div className="param-transfer-module--info--service--name">
                {name}
              </div>

              <div className="param-transfer-module--info--service--description">
                {description}
              </div>
            </div>
          </Col>

          <Col
            className="param-transfer-module--sync"
            lg={1}
            md={1}
          >
            {!get(transferModule, 'requesting', false) && (
              <div
                className={cl({
                  'mdi mdi-sync': true,
                  'text-danger': get(transferModule, 'error') instanceof Error,
                  'text-success': get(transferModule, 'fulfilled', false)
                })}
                onClick={this.onStartTransfer}
              />
            )}

            {get(transferModule, 'requesting', false) && (
              <PacmanLoader
                color="#474b4e"
                size={12}
              />
            )}
          </Col>

          <Col
            className="param-transfer-module--sync--summary"
            lg={1}
            md={1}
          >
            {(get(transferModule, 'requesting', false) || get(transferModule, 'fulfilled', false)) && (
              <>
                <span className="text-success">
                  {fulfilledItemsCount}
                </span>

                {' / '}

                <span className="text-danger">
                  {rejectedItemsCount}
                </span>

                <div>
                  {`Total: ${totalItemsCount}`}
                </div>
              </>
            )}
          </Col>
        </Row>

        <Row onClick={this.onToggleSourceItemList}>
          <Col
            className="param-transfer-module--info--exlapse"
            lg={12}
            md={12}
          >
            <div className={cl({ mdi: true, 'mdi-chevron-down': collapsed, 'mdi-chevron-up': !collapsed })} />
          </Col>
        </Row>

        {!collapsed && (
          <Row>
            <Col lg={12} md={12}>
              <ItemsList module={module} />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

ParamTransferModule.propTypes = {
  fulfilledItemsCount: PropTypes.number,
  language: PropTypes.string,
  module: PropTypes.object.isRequired,
  paramTransferActions: PropTypes.object.isRequired,
  rejectedItemsCount: PropTypes.number,
  source: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
  tenant: PropTypes.string,
  totalItemsCount: PropTypes.number,
  transferModule: PropTypes.object
};

ParamTransferModule.defaultProps = {
  fulfilledItemsCount: 0,
  language: 'de',
  rejectedItemsCount: 0,
  tenant: 'swisscom',
  totalItemsCount: 0,
  transferModule: {}
};

function getTotalItemCount(state, ownProps) {
  const { module } = ownProps;

  return get(state, `paramTransfer.sourceItems.${module.key}.data.length`, 0);
}

function getFulfilledItemCount(state, ownProps) {
  const { module } = ownProps;

  return chain(state)
    .get(`paramTransfer.sourceItems.${module.key}.data`, [])
    .map((i) => get(state, `paramTransfer.transferItem.${i.dataKey}`, {}))
    .filter((i) => get(i, 'fulfilled', false))
    .size()
    .value();
}

function getRejectedItemCount(state, ownProps) {
  const { module } = ownProps;

  return chain(state)
    .get(`paramTransfer.sourceItems.${module.key}.data`, [])
    .map((i) => get(state, `paramTransfer.transferItem.${i.dataKey}`, {}))
    .filter((i) => get(i, 'error') instanceof Error)
    .size()
    .value();
}

function mapStateToProps(state, ownProps) {
  const { module } = ownProps;
  const tenant = get(state, 'environment.config.data.tenant');

  const transferModule = get(state, `paramTransfer.transferModule.${module.key}`);

  return {
    fulfilledItemsCount: getFulfilledItemCount(state, ownProps),
    language: state.login.language,
    rejectedItemsCount: getRejectedItemCount(state, ownProps),
    source: state.paramTransfer.source,
    target: state.paramTransfer.target,
    tenant,
    totalItemsCount: getTotalItemCount(state, ownProps),
    transferModule,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    paramTransferActions: bindActionCreators(paramTransferActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ParamTransferModule);

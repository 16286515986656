import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { chain } from 'lodash';

import actions from '../actions/Actions';

const {
  CONSULTANTS_PENDING,
  CONSULTANTS_FULFILLED,
  CONSULTANTS_REJECTED,
  LOCATIONS_FULFILLED,
  REGIONS_FULFILLED
} = actions;

export default function* getConsultants() {
  yield put({ type: CONSULTANTS_PENDING });

  try {
    const query = QueryBuilder()
      .limit(1000)
      .getQueryString();

    const url = `/person/consultant/${query}`;
    const payload = yield call(handleNextHeaders, url);

    const locations = chain(payload)
      .map((c) => c.location)
      .compact()
      .map((location) => ({
        ...location,
        id: location.name
      }))
      .uniqBy('id')
      .value();

    const regions = chain(payload)
      .map((c) => c.location)
      .compact()
      .map((location) => ({
        ...location,
        id: location.description
      }))
      .uniqBy('id')
      .value();

    yield put({ type: CONSULTANTS_FULFILLED, payload });
    yield put({ type: LOCATIONS_FULFILLED, payload: locations });
    yield put({ type: REGIONS_FULFILLED, payload: regions });

    return payload;
  } catch (error) {
    yield put({ type: CONSULTANTS_REJECTED, error });

    return error;
  }
}

import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { v4 } from 'uuid';
import { get } from 'lodash';

// ToDo: Move functions like handleNextHeaders to own repo
import saveAction from '../../actions/CodeMapping/Save';

const {
  CODE_MAPPING_SAVE_PENDING,
  CODE_MAPPING_SAVE_FULFILLED,
  CODE_MAPPING_SAVE_REJECTED
} = saveAction;

export default function* saveCodeMapping(request) {
  yield put({ type: CODE_MAPPING_SAVE_PENDING });

  try {
    const id = get(request, 'id', v4());
    const url = `/entity/codemapping/${id}`;
    const payload = { id, ...get(request, 'data', {}) };

    yield call(axios, {
      url,
      method: 'put',
      data: payload
    });

    yield put({ type: CODE_MAPPING_SAVE_FULFILLED, id, payload });

    return payload;
  } catch (error) {
    yield put({ type: CODE_MAPPING_SAVE_REJECTED, error });

    return error;
  }
}

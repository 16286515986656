export function ConsultantsFormValidator(form) {
  let passwordIsTheSame = true;

  if (form.password) {
    passwordIsTheSame = form.password === form.repeatPassword;
  }

  return {
    firstName: !form.firstName,
    lastName: !form.lastName,
    password: !passwordIsTheSame,
    repeatPassword: !passwordIsTheSame,
  };
}

import { convertDateToApi } from '../../utils/dateConverter';
import { BULK_LOADS_PAGE_SIZE, BULK_LOADS_STATES } from '../../constants/BulkLoadsConstants';

export function createSearchQuery({
  endDate,
  maxActionCount,
  minActionCount,
  sortDirection,
  sortField,
  startDate,
  states,
  loadconfig,
  limit = BULK_LOADS_PAGE_SIZE,
  offset = 0
}) {
  const queryParams = [`limit(${limit},${offset})`];

  if (sortField) {
    let preparedQueryParams = [];
    if (sortField.length > 0) {
      sortDirection.forEach((element, index) => {
        const prefix = element === 'desc' ? '-' : '+';
        const fieldName = sortField.at(index);
        preparedQueryParams.push(`${prefix}${fieldName}`)
      });
      preparedQueryParams = preparedQueryParams.join(',')
      queryParams.push(`sort(${preparedQueryParams})`);
    }
  }

  if (states && states.length) {
    if (states.includes(BULK_LOADS_STATES.ERROR)) {
      queryParams.push(`or(gt(actionCount.error,0),in(state,(${states.join(',')})))`)
    } else {
      queryParams.push(`in(state,(${states.join(',')}))`);
    }
  }

  if (startDate && startDate.isValid()) {
    queryParams.push(`ge(workHistory.open,${convertDateToApi(startDate.startOf('date'))})`);
  }

  if (endDate && endDate.isValid()) {
    queryParams.push(`le(workHistory.open,${convertDateToApi(endDate.endOf('date'))})`);
  }

  if (maxActionCount) {
    queryParams.push(`le(actionCount.total,${maxActionCount})`);
  }

  if (minActionCount) {
    queryParams.push(`ge(actionCount.total,${minActionCount})`);
  }

  if (loadconfig) {
    const regex = new RegExp('[^-]*');
    const found = regex.exec(loadconfig)[0];

    queryParams.push(`eq(id,${found})`);
  }

  return queryParams.join('&');
}

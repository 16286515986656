import './Menu.css';

import React from 'react';
import PropTypes from 'prop-types';
import { classList } from '../../utils/classList';

export const CORE_CONFIG_PANELS = {
  CONFIG: 'config',
  MODULES: 'modules',
};

export class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.openConfigPage = this.openConfigPage.bind(this);
    this.openModulesPage = this.openModulesPage.bind(this);
  }

  render() {
    const { selectedPanel } = this.props;
    const classesConfig = classList({
      'core-config-menu-item': true,
      'core-config-menu-item--active': selectedPanel === CORE_CONFIG_PANELS.CONFIG,
    });
    const classesModules = classList({
      'core-config-menu-item': true,
      'core-config-menu-item--active': selectedPanel === CORE_CONFIG_PANELS.MODULES,
    });
    return (
      <div className="core-config-menu">
        <div className={classesConfig} onClick={this.openConfigPage}>
          Basis
        </div>
        <div className={classesModules} onClick={this.openModulesPage}>
          Modulebezeichnungen
        </div>
      </div>
    );
  }

  openConfigPage() {
    const { setData } = this.props;
    setData({ key: 'selectedPanel', value: CORE_CONFIG_PANELS.CONFIG });
  }

  openModulesPage() {
    const { setData } = this.props;
    setData({ key: 'selectedPanel', value: CORE_CONFIG_PANELS.MODULES });
  }
}

Menu.propTypes = {
  setData: PropTypes.func.isRequired,
  selectedPanel: PropTypes.string.isRequired,
};

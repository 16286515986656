import { createInstance } from '@evoja-web/indexdb-common';
import { isNil } from 'lodash';

const v1 = {
  modified: 'id',
  module: 'id',
  moduleCategory: 'id'
};

let db;

export async function init() {
  db = createInstance({ id: 'adminUi' });
  db.version(1).stores(v1);

  await db.open();

  return db;
}

export async function getDatabase() {
  if (isNil(db)) {
    return init();
  }

  return db;
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { chain, get } from 'lodash';
import { Grid, Row, Col } from 'react-bootstrap';

import Login from '../components/Target/Login';
import TargetInfo from '../components/Target/Info';
import Modules from '../components/Module/Modules';
import SourceError from '../components/Source/Error';
import paramTransferActions from '../actions/Actions';

class ParamTransferOverview extends React.Component {
  constructor(props) {
    super(props);

    this.onInitSource();

    this.onInitTarget = this.onInitTarget.bind(this);
    this.onResetTarget = this.onResetTarget.bind(this);
  }

  /**
   * Reset everything on unmount
   *
   * @return  {[type]}  [return description]
   */
  componentWillUnmount() {
    const { paramTransferActions } = this.props;

    paramTransferActions.initSourceReset();
    paramTransferActions.initTargetReset();
    paramTransferActions.transferModuleResetAll();
    paramTransferActions.transferItemResetAll();
  }

  /**
   * Init the source
   *
   * @return  {[type]}  [return description]
   */
  onInitSource() {
    const { paramTransferActions } = this.props;

    paramTransferActions.initSourceRequest();
  }

  /**
   * Login to the target system and load necessary data
   *
   * @param   {Object}  formData  Login form data
   *
   * @return  void
   */
  onInitTarget({ formData }) {
    const { paramTransferActions } = this.props;

    const {
      password,
      target,
      username
    } = formData;

    paramTransferActions.initTargetRequest({
      password,
      username,
      target
    });
  }

  /**
   * Reset the target
   *
   * @return  void
   */
  onResetTarget() {
    const { paramTransferActions } = this.props;

    paramTransferActions.initTargetReset();
    paramTransferActions.transferModuleResetAll();
    paramTransferActions.transferItemResetAll();
  }

  /**
   * Get target stages from environment.json
   *
   * @return  {Array}  targets Array of target states
   */
  getTargets() {
    const { environment } = this.props;

    const apiUrl = get(environment, 'config.data.api_url');
    return chain(environment)
      .get('config.data')
      .map((value, key) => ({ key, value }))
      .filter(({ key }) => key.startsWith('transfer_stage'))
      .filter(({ value }) => value !== apiUrl)
      .map(({ value, key }) => {
        const name = key.replace('transfer_stage_', '').replace('_url', '');

        return {
          key,
          name,
          url: value
        };
      })
      .value();
  }

  /**
   * Render the source error message if one occured
   *
   * @return {ReactElement} markup
   */
  renderSourceError() {
    const { source } = this.props;

    if (!(get(source, 'error') instanceof Error)) {
      return null;
    }

    return (
      <Row className="param-transfer-overview--source-error-row">
        <Col lg={4} md={4} />

        <Col lg={4} md={4}>
          <SourceError source={source} />
        </Col>

        <Col lg={4} md={4} />
      </Row>
    );
  }

  /**
   * Render the login form if no target is connected
   *
   * @return {ReactElement} markup
   */
  renderLogin() {
    const {
      source,
      target
    } = this.props;

    if (get(target, 'fulfilled', false) || get(source, 'error') instanceof Error) {
      return null;
    }

    return (
      <Row className="param-transfer-overview--login-row">
        <Col lg={4} md={4} />

        <Col lg={4} md={4}>
          <Login
            error={get(target, 'error')}
            onSubmit={this.onInitTarget}
            targets={this.getTargets()}
          />
        </Col>

        <Col lg={4} md={4} />
      </Row>
    );
  }

  /**
   * Render the target info if target is connected
   *
   * @return {ReactElement} markup
   */
  renderTargetInfo() {
    const {
      source,
      target
    } = this.props;

    if (!get(target, 'fulfilled', false) || get(source, 'error') instanceof Error) {
      return null;
    }

    return (
      <Row className="param-transfer-overview--target-info-row">
        <Col lg={4} md={4} />

        <Col lg={4} md={4}>
          <TargetInfo
            onLogout={this.onResetTarget}
            target={target}
          />
        </Col>

        <Col lg={4} md={4} />
      </Row>
    );
  }

  /**
   * Render the modules if source and target are connected
   *
   * @return {ReactElement} markup
   */
  renderModules() {
    const {
      source,
      target
    } = this.props;

    if (!get(target, 'fulfilled', false) || get(source, 'error') instanceof Error) {
      return null;
    }

    return (
      <Row className="param-transfer-overview--modules-row">
        <Col lg={12} md={12}>
          <Modules
            source={source}
            target={target}
          />
        </Col>
      </Row>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      source,
      target
    } = this.props;

    return (
      <Grid
        className="param-transfer-overview"
        fluid
      >
        {this.renderSourceError()}
        {this.renderLogin()}
        {this.renderTargetInfo()}
        {this.renderModules()}
      </Grid>
    );
  }
}

ParamTransferOverview.propTypes = {
  environment: PropTypes.object.isRequired,
  paramTransferActions: PropTypes.object.isRequired,
  source: PropTypes.object,
  target: PropTypes.object
};

ParamTransferOverview.defaultProps = {
  source: {},
  target: {}
};

function mapStateToProps(state, ownProps) {
  return {
    environment: state.environment,
    source: state.paramTransfer.source,
    target: state.paramTransfer.target
  };
}

function mapDispatchToProps(dispatch) {
  return {
    paramTransferActions: bindActionCreators(paramTransferActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ParamTransferOverview);

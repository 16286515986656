import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const MoreButton = ({ value, onClick }) => (
  <Button
    bsSize="large"
    bsStyle="primary"
    onClick={onClick}
  >
    {value}
  </Button>
);
MoreButton.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MoreButton;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop, isUndefined } from 'lodash';
import {
  FormGroup,
  ControlLabel,
  FormControl,
  ListGroup,
  ListGroupItem,
  InputGroup,
  Button
} from 'react-bootstrap';
import update from 'immutability-helper';

import './Form.css';
import AddKey from './AddKey';
import codeMappingActions from '../../actions/CodeMappingActions';

class CodeMappingForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCreate: {
        show: false,
        mappingKey: undefined,
        value: undefined
      },
      showAddKey: false
    };

    const { codeMappingActions, mappingId } = props;
    codeMappingActions.codeMappingFormInitRequest({ data: { id: mappingId } });

    this.onValueChange = this.onValueChange.bind(this);
    this.onShowCreate = this.onShowCreate.bind(this);
    this.onHideCreate = this.onHideCreate.bind(this);
    this.onValueAdd = this.onValueAdd.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onKeyAdd = this.onKeyAdd.bind(this);
  }

  componentWillUnmount() {
    const { codeMappingActions } = this.props;

    codeMappingActions.codeMappingFormReset();
  }

  onValueChange(ev) {

  }

  onShowCreate(mappingKey) {
    this.setState({
      showCreate: {
        show: true,
        mappingKey
      }
    });
  }

  onHideCreate() {
    this.setState({
      showCreate: {
        show: false,
        mappingKey: undefined,
        value: undefined
      }
    });
  }

  onKeyAdd(key) {
    const { codeMappingActions, data } = this.props;

    const mapping = get(data, 'mapping', []);

    const updated = update(mapping, {
      $push: [{ key, value: [] }]
    });

    codeMappingActions.codeMappingFormSetValue('mapping', updated);

    this.setState({ showAddKey: false });
  }

  onValueAdd() {
    const { showCreate } = this.state;
    const { codeMappingActions, data } = this.props;

    const { mappingKey, value } = showCreate;

    const mapping = get(data, 'mapping', []);
    const index = mapping.findIndex((item) => get(item, 'key') === mappingKey)

    const updated = update(mapping, {
      [index]: {
        value: { $push: [value] }
      }
    });

    codeMappingActions.codeMappingFormSetValue('mapping', updated);

    this.setState({
      showCreate: {
        show: false,
        mappingKey: undefined,
        value: undefined
      }
    });
  }

  onValueRemove(mappingKey, value) {
    const { codeMappingActions, data } = this.props;

    const mapping = get(data, 'mapping', []);
    const index = mapping.findIndex((item) => get(item, 'key') === mappingKey);
    const values = get(mapping, `${index}.value`, []);
    const nextValues = values.filter((v) => v !== value);

    const updated = update(mapping, {
      [index]: {
        value: { $set: nextValues }
      }
    });

    codeMappingActions.codeMappingFormSetValue('mapping', updated);
  }

  onSubmit() {
    const { codeMappingActions, data, onSubmit } = this.props;

    codeMappingActions.codeMappingFormSaveRequest({ data });

    onSubmit();
  }

  onCancel() {
    const { codeMappingActions, onCancel } = this.props;

    codeMappingActions.codeMappingFormReset();

    onCancel();
  }

  renderValueInput(mapping) {
    const { showCreate } = this.state;

    return (
      <FormGroup>
        <InputGroup>
          <FormControl
            id="mappingAddValue"
            name="mappingAddValue"
            value={get(showCreate, 'value', '')}
            onChange={(ev) => this.setState({ showCreate: update(showCreate, { value: { $set: get(ev, 'target.value') } }) })}
          />

          <InputGroup.Addon onClick={!isUndefined(get(showCreate, 'value')) ? this.onValueAdd : noop}>
            <span className="mdi mdi-check-outline" />
          </InputGroup.Addon>

          <InputGroup.Addon onClick={this.onHideCreate}>
            <span className="mdi mdi-close-outline" />
          </InputGroup.Addon>
        </InputGroup>
      </FormGroup>
    );
  }

  renderMapping(mapping) {
    const { showCreate } = this.state;
    const { key } = mapping;

    return (
      <React.Fragment>
        <div style={{ paddingRight: '20%', paddingLeft: '20%', paddingTop: '20px', paddingBottom: '20px' }}>
          <Button
            style={{ width: '100%' }}
            bsStyle="primary"
            onClick={() => this.onShowCreate(get(mapping, 'key'))}
            disabled={get(showCreate, 'show', false)}
          >
            Eintrag hinzufügen
          </Button>
        </div>

        {get(showCreate, 'show', false) && get(showCreate, 'mappingKey') === get(mapping, 'key') && (
          <ListGroupItem>
            {this.renderValueInput(mapping)}
          </ListGroupItem>
        )}

        {get(mapping, 'value', []).map((value) => {
          return (
            <ListGroupItem key={value}>
              <div className="code-mapping-form-mapping-value">
                <div>
                  {value}
                </div>

                <div>
                  <span
                    className="mdi mdi-trash-can-outline"
                    style={{ fontSize: '1.2em' }}
                    onClick={() => this.onValueRemove(key, value)}
                  />
                </div>
              </div>
            </ListGroupItem>
          );
        })}
      </React.Fragment>
    );
  }

  renderMappings() {
    const mappings = get(this, 'props.data.mapping', []);

    return mappings.map((mapping) => {
      const key = get(mapping, 'key');

      return (
        <ListGroup>
          <ListGroupItem>
            <FormGroup>
              <ControlLabel>
                Key
              </ControlLabel>

              <FormControl
                id={`${key}-key`}
                name={`${key}-key`}
                value={get(mapping, 'key', '')}
                disabled
              />
            </FormGroup>
          </ListGroupItem>

          {this.renderMapping(mapping)}
        </ListGroup>
      )
    });
  }

  renderAddKey() {
    const { showAddKey } = this.state;
    const { data } = this.props;

    return showAddKey
      ? (
        <AddKey
          data={data}
          onCancel={() => this.setState({ showAddKey: false })}
          onSubmit={this.onKeyAdd}
        />
      )
      : (
        <Button
          bsStyle="primary"
          onClick={() => this.setState({ showAddKey: true })}
          style={{ width: '100%' }}
        >
          Neuen Key hinzufügen
        </Button>
      )
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { data } = this.props;

    return (
      <div className="code-mapping-form">
        <h5>
          {`Code Mapping für ID ${get(data, 'id')} bearbeiten`}
        </h5>

        <FormGroup>
          <ControlLabel>
            ID
          </ControlLabel>

          <FormControl
            id="id"
            name="id"
            value={get(data, 'id', '')}
            onChange={this.onValueChange}
            disabled
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>
            Name
          </ControlLabel>

          <FormControl
            id="name"
            name="name"
            value={get(data, 'name', '')}
            onChange={this.onValueChange}
            disabled
          />
        </FormGroup>

        {this.renderAddKey()}

        <div style={{ paddingTop: '20px', marginBottom: '50px', borderBottom: '1px solid lightgray' }} />

        {this.renderMappings()}

        <div className="code-mapping-form-buttons">
          <Button
            bsStyle="primary"
            onClick={this.onSubmit}
          >
            Speichern
          </Button>

          <span style={{ paddingRight: '10px' }} />

          <Button onClick={this.onCancel}>
            Abbrechen
          </Button>
        </div>
      </div>
    );
  }
}

CodeMappingForm.propTypes = {
  mappingId: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

CodeMappingForm.defaultProps = {
  mappingId: undefined,
  onCancel: noop,
  onSubmit: noop
};

function mapStateToProps(state, ownProps) {
  return {
    data: get(state, 'codeMapping.form.data', {})
  };
}

function mapDispatchToProps(dispatch) {
  return {
    codeMappingActions: bindActionCreators(codeMappingActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CodeMappingForm);

/**
 *
 *  TOOD: properly check of course.....
 */
import axios from 'axios';
import { call, put } from 'redux-saga/effects';

export const GET = 'adminUI/Interests/GET';
export const POST = 'adminUI/Interests/POST';
export const PUT = 'adminUI/Interests/PUT';
export const DEL = 'adminUI/Interests/DEL';
export const SET = 'adminUI/Interests/SET';

export const getInterestsRequest = () => ({
  type: `${GET}_REQUEST`,
});

export function* getInterests() {
  try {
    yield put({ type: `${GET}_PENDING` });
    const result = yield call(axios.get, 'entity/code/?eq(group,string:interestCategory)&limit(1000)');
    yield put({ type: `${GET}_FULFILLED`, payload: result });
  } catch (e) {
    yield put({ type: `${GET}_REJECTED`, payload: e });
  }
}

export const createInterestRequest = (interest, name, categoryType) => ({
  type: `${POST}_REQUEST`,
  interest,
  name,
  categoryType
});

export function* createInterest(action) {
  try {
    const name = action.name;
    yield put({ type: `${POST}_PENDING` });

    name["en"] = name["de"];
    if (name["fr"] === undefined || name["fr"] === "") {
      name["fr"] = name["de"];
    }

    let category = {
      group: "interestCategory",
      text: name,
      recordOrigin: 'map'
    };

    if (action.categoryType === "group") {
      category = {
        ...category,
        parentCodeId: action.interest.de.value
      }
    }

    yield call(axios.post, `entity/code`, category);
    yield put({ type: `${POST}_FULFILLED`, payload: category });
    yield put({ type: `${GET}_REQUEST` });
  } catch (e) {
    yield put({ type: `${POST}_REJECTED`, payload: e });
  }
}

export const updateInterestRequest = interest => ({
  type: `${PUT}_REQUEST`,
  interest
});

export function* updateInterest(action) {
  try {
    yield put({ type: `${PUT}_PENDING` });
    const result = yield call(axios.put, `entity/code/${action.interest.id}`, action.interest);
    yield put({ type: `${PUT}_FULFILLED`, payload: result });
  } catch (e) {
    yield put({ type: `${PUT}_REJECTED`, payload: e });
  }
}

export const deleteInterestRequest = id => ({
  type: `${DEL}_REQUEST`,
  id,
});

export function* deleteInterest(action) {
  try {
    yield put({ type: `${DEL}_PENDING` });
    yield call(axios.delete, `entity/code/${action.id}`);
    yield put({ type: `${DEL}_FULFILLED`, id: action.id });
  } catch (e) {
    yield put({ type: `${DEL}_REJECTED`, payload: e });
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Tabs, Tab, FormControl } from 'react-bootstrap';

class Title extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 'de'
    };

    this.onActiveTabChange = this.onActiveTabChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onActiveTabChange(language) {
    this.setState({ active: language });
  }

  onChange(language, ev) {
    const { onChange } = this.props;
    const { value } = ev.target;

    onChange(language, (value && value.trim().length > 0) ? value : undefined);
  }

  render() {
    const { active } = this.state;
    const { languages, values } = this.props;
    const tabs = languages.map((l) => (
      <Tab key={l} eventKey={l} title={l}>
        <FormControl
          type="text"
          value={get(values, l, '')}
          onChange={(ev) => this.onChange(l, ev)}
        />
      </Tab>
    ));

    return (
      <div>
        <h4>Titel</h4>
        <Tabs
          id="action-title"
          activeKey={active}
          onSelect={this.onActiveTabChange}
        >
          {tabs}
        </Tabs>
      </div>
    );
  }
}

Title.propTypes = {
  values: PropTypes.object,
  languages: PropTypes.array,
  onChange: PropTypes.func
};

Title.defaultProps = {
  values: {},
  languages: ['de', 'fr', 'en'],
  onChange: noop
};

export default Title;

import './AnalyticsActiveUsers.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { PROP_TYPES_ACTIVE_USER } from './AnalyticsPropTypes';
import { AnalyticsActiveUser } from './AnalyticsActiveUser';
import { SortableTable } from '../Common/SortableTable';

const SORTABLE_FIELDS = [
  {
    key: 'username',
    label: <FormattedMessage id="analytics_activeUsers_username"/>,
    sortFn: (item) => item.username,
  },
  {
    key: 'name',
    label: <FormattedMessage id="analytics_activeUsers_name"/>,
    sortFn: (item) => item.name,
  },
  {
    key: 'department',
    label: <FormattedMessage id="analytics_activeUsers_department"/>,
    sortFn: (item) => item.department,
  },
  {
    key: 'division',
    label: <FormattedMessage id="analytics_activeUsers_division"/>,
    sortFn: (item) => item.division,
  },
  {
    key: 'sessions',
    label: <FormattedMessage id="analytics_activeUsers_sessions"/>,
    sortFn: (item) => item.sessions.length,
  },
  {
    key: 'empty',
    label: '',
    sortFn: undefined,
  },
];

export class AnalyticsActiveUsers extends React.Component {
  constructor(props) {
    super(props);
    this.renderItem = this.renderItem.bind(this);
  }

  render() {
    const { text = '', users } = this.props;

    return (
      <div className="analytics-active-users">
        <div className="analytics-active-users__text">
          {text}
        </div>
        <SortableTable
          items={users}
          sortableFields={SORTABLE_FIELDS}
          renderItem={this.renderItem}
          className="analytics-active-users__table"
        />
      </div>
    );
  }

  renderItem(user) {
    const { textDetails = '' } = this.props;
    return (
      <AnalyticsActiveUser user={user} text={textDetails} key={user.username}/>
    );
  }
}

AnalyticsActiveUsers.propTypes = {
  text: PropTypes.string,
  textDetails: PropTypes.string,
  users: PropTypes.arrayOf(PROP_TYPES_ACTIVE_USER).isRequired,
};

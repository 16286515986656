import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { CONSULTANTS_DELETE_ACTIONS } from '../../actions/ConsultantsActions';

export function* consultantsDeleteSaga({ data: { consultant } }) {
  try {
    yield put(CONSULTANTS_DELETE_ACTIONS.pending());

    yield call(axios.delete, `${EndpointName.PERSON_CONSULTANT}/${encodeURIComponent(consultant.id)}`);

    yield put(CONSULTANTS_DELETE_ACTIONS.success({ consultant }));
  } catch (error) {
    yield put(CONSULTANTS_DELETE_ACTIONS.failure(error));
  }
}

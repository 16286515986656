import './HolidaysCreateForm.css';

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, Form, reduxForm, getFormValues } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { FormDate, FormInput, FormToggle } from '../FormControls';

class HolidaysCreateReduxForm extends React.Component {
  constructor(props) {
    super(props);

    this.onChangeMultiDay = this.onChangeMultiDay.bind(this);
    this.isStartDateOutsideRange = this.isStartDateOutsideRange.bind(this);
    this.isEndDateOutsideRange = this.isEndDateOutsideRange.bind(this);
  }

  render() {
    const { onCancel, onSubmit } = this.props;
    const { handleSubmit, submitFailed, invalid, formValues } = this.props;

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton={true}>
          <Modal.Title>
            Neuer Eintrag
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="holidays-create-form__multi">
            <Field component={FormToggle}
                   label={"Multiday event"}
                   size="large"
                   name="isMultiDay"
                   onChange={this.onChangeMultiDay}
            />
          </div>
          <div className="holidays-create-form__date">
            <div className="holidays-create-form__date-item">
              <Field component={FormDate}
                     id="holiday-startDate"
                     name="startDate"
                     displayFormat="DD.MM.YYYY"
                     numberOfMonths={1}
                     isOutsideRange={this.isStartDateOutsideRange}
                     placeholder=""
              />
            </div>
            {formValues.isMultiDay && (
              <React.Fragment>
                <div className="holidays-create-form__date-div">
                  <div className="form-group">-</div>
                </div>
                <Field component={FormDate}
                       id="holiday-endDate"
                       name="endDate"
                       displayFormat="DD.MM.YYYY"
                       numberOfMonths={1}
                       isOutsideRange={this.isEndDateOutsideRange}
                       placeholder=""
                />
              </React.Fragment>
            )}
          </div>
          <div className="holidays-create-form__name">
            <Field component={FormInput}
                   type="text"
                   name="name"
                   placeholder="Name"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button"
                  bsStyle="default"
                  onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit"
                  bsStyle="primary"
                  disabled={submitFailed ? invalid : false}>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    );
  }

  onChangeMultiDay(event, isMultiDay) {
    const { change, formValues } = this.props;
    change('endDate', isMultiDay ? moment(formValues.startDate).add(1, 'day') : null);
  }

  isStartDateOutsideRange(date) {
    const { year } = this.props;
    return date.get('year') !== year;
  }

  isEndDateOutsideRange(date) {
    const { formValues: { startDate } } = this.props;
    return date.isSameOrBefore(moment(startDate).add(1, 'day'));
  }
}

const HolidaysCreateFormConnected = reduxForm({
  form: 'holidays-create-form',
  validate: validateForm,
})(HolidaysCreateReduxForm);

const mapStateToProps = createStructuredSelector({
  formValues: (state, props) => {
    const formValues = getFormValues('holidays-create-form')(state);
    return formValues || props.initialValues;
  },
});

function validateForm(formData) {
  return {
    name: !formData.name,
    startDate: !formData.startDate,
    endDate: isEndDateInvalid(formData),
  };
}
function isEndDateInvalid(formData) {
  if (!formData.isMultiDay) {
    return false;
  } else if (!formData.endDate) {
    return true;
  }

  return formData.endDate.isSameOrBefore(formData.startDate, 'day');
}

export const HolidaysCreateForm = connect(mapStateToProps)(HolidaysCreateFormConnected);
HolidaysCreateForm.propTypes = {
  year: PropTypes.number.isRequired,

  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

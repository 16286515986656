import { chainReducers } from '../../utils';
import { uiNotesFetchReducer } from './uiNotesFetchReducer';
import { uiNotesCreateReducer } from './uiNotesCreateReducer';
import { uiNotesUpdateReducer } from './uiNotesUpdateReducer';
import { uiNotesDeleteReducer } from './uiNotesDeleteReducer';
import { uiNotesImageReducer } from './uiNotesImageReducer';

const initialState = {
  data: null,
  image: null,
  loading: false,
  saving: false,
  error: null,
};

export const uiNotesReducer = chainReducers(
  (state = initialState) => state,
  uiNotesFetchReducer,
  uiNotesCreateReducer,
  uiNotesUpdateReducer,
  uiNotesDeleteReducer,
  uiNotesImageReducer,
);

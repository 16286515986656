import { createActions } from '../utils';

export const NOTICE_IMPORT_FILE_LIST_LOAD = createActions('NOTICE_IMPORT_FILE_LIST_LOAD');

export const NOTICE_IMPORT_EXAMPLES = createActions('NOTICE_IMPORT_EXAMPLES');

export const NOTICE_IMPORT_FILE_POST = createActions('NOTICE_IMPORT_FILE_POST');
export const NOTICE_IMPORT_VALIDATION = createActions('NOTICE_IMPORT_VALIDATION');
export const NOTICE_IMPORT_IMPORT = createActions('NOTICE_IMPORT_IMPORT');
export const NOTICE_IMPORT_DELETE = createActions('NOTICE_IMPORT_DELETE');


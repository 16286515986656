import update from 'immutability-helper';
import {
  GET,
  GET_COLLATERAL_TYPES,
  PUT,
  DEL,
  SET_REMOVED,
  SET_TYPES,
} from '../actions/TypeActions';

export default function TypeReducer(
  state = {
    types: { equity: [], collateral: [], cashflow: [] },
    collateralTypes: [],
    removedItems: { equity: [], collateral: [], cashflow: [] },
    requesting: false,
    error: {},
    hasError: false,
  },
  action,
) {
  switch (action.type) {
    case `${GET}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${GET}_FULFILLED`: {
      return {
        ...state,
        requesting: false,
        types: update(state.types, { [action.meta.type]: { $set: action.payload.data } }),
        removedItems: update(state.removedItems, { [action.meta.type]: { $set: [] } }),
      };
    }
    case `${GET}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case `${GET_COLLATERAL_TYPES}_PENDING`: {
      return { ...state, hasError: false };
    }
    case `${GET_COLLATERAL_TYPES}_FULFILLED`: {
      return { ...state, collateralTypes: action.payload.data };
    }
    case `${GET_COLLATERAL_TYPES}_REJECTED`: {
      return { ...state, hasError: true, error: action.payload };
    }
    case `${PUT}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${PUT}_FULFILLED`: {
      return { ...state, requesting: false };
    }
    case `${PUT}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case `${DEL}_PENDING`: {
      return { ...state, requesting: true };
    }
    case `${DEL}_FULFILLED`: {
      return {
        ...state,
        requesting: false,
        removedItems: update(
          state.removedItems,
          {
            [action.meta.type]:
            { $set: state.removedItems[action.meta.type].filter(o => o !== action.meta.id) },
          },
        ),
      };
    }
    case `${DEL}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case SET_TYPES: {
      return {
        ...state,
        types: update(state.types, { [action.typeName]: { $set: action.value } }),
      };
    }
    case SET_REMOVED: {
      return {
        ...state,
        removedItems:
        update(state.removedItems, { [action.typeName]: { $set: action.value } }),
      };
    }
    default: {
      return state;
    }
  }
}

import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { ANALYTICS_LOGS_DETAILS } from '../../actions/AnalyticsLogsActions';
import { EndpointName } from '../../constants/EndpointName';
import { convertDateToApi } from '../../utils/dateConverter';
import moment from 'moment';

export function* analyticsLogsDetailsSaga(action) {
  try {
    yield put(ANALYTICS_LOGS_DETAILS.pending());

    const { requestId, createdAt } = action.data;

    const dateFrom = moment(createdAt).subtract(30, 'seconds');
    const dateTo = moment(createdAt).add(30, 'seconds');;
    const query = [
      dateFrom
        ? `fromDate=${encodeURIComponent(convertDateToApi(dateFrom))}`
        : undefined,
      dateTo
        ? `toDate=${encodeURIComponent(convertDateToApi(dateTo))}`
        : undefined,
        requestId
        ? `requestId=${encodeURIComponent(requestId)}`
        : undefined,
      'showResponseBody=1&showRequestBody=1',
    ].filter(Boolean).join('&');
    const { data: searchResults } = yield call(axios, { url: `${EndpointName.ANALYTICS_REQUESTS}?${query}` });
    const requestDetails = searchResults[0];

    yield put(ANALYTICS_LOGS_DETAILS.success({ requestDetails }));
  } catch (error) {
    yield put(ANALYTICS_LOGS_DETAILS.failure(error));
  }
}

import React from 'react';
import Toggle from 'react-toggle';
import { Row, Col, Form, ControlLabel, FormGroup, FormControl, Modal, Button, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import _ from 'lodash';
import moment from 'moment';
import Checklist from './Checklist';
import DatePicker from '../DatePicker';
import PromotionDocuments from './Documents';
import ImageDropzone from './ImageDropzone';

const emptyPromotion = {
  active: false,
  default: false,
  title: '',
  imageText: '',
  colorCode: '',
  customerSegments: [],
  checklistItem: [],
  image: {},
  teaserImage: {},
  from: moment(),
  to: moment(),
  document: [],
  order: 1,
};

export default class PromotionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialValues();

    this.onTitleChange = this.onTitleChange.bind(this);
    this.onImageTextChange = this.onImageTextChange.bind(this);
    this.onColorCodeChange = this.onColorCodeChange.bind(this);
    this.onImageRemove = this.onImageRemove.bind(this);
    this.onDocumentsChange = this.onDocumentsChange.bind(this);
    this.orderChange = this.orderChange.bind(this);
    this.onChangeSegment = this.onChangeSegment.bind(this);
    this.onChecklistSectionChange = this.onChecklistSectionChange.bind(this);
    this.setValue = this.setValue.bind(this);
    this.save = this.save.bind(this);
    this.reset = this.reset.bind(this);
    this.onActiveChange = this.onActiveChange.bind(this);
    this.onDefaultChange = this.onDefaultChange.bind(this);
  }

  getInitialValues() {
    const { promotion, sections } = this.props;
    const initPromotion = _.defaults({...promotion}, emptyPromotion);
    const initSections = sections.map((section) => ({
      ...section,
      items: section.items.filter(item => initPromotion.checklistItem.includes(item.id)),
    }));

    return {
      promotion: initPromotion,
      sections: initSections,
      validation: {},
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { promotion, sections } = this.state;
    const { show, language } = this.props;

    if (promotion !== nextState.promotion) return true;
    if (sections !== nextState.sections) return true;
    if (show !== nextProps.show) return true;
    if (language !== nextProps.language) return true;

    return false;
  }

  reset() {
    const { onHide } = this.props;
    this.setState(this.getInitialValues());
    onHide();
  }

  formatDate(date) {
    if (_.isString(date)) date = moment(date);

    return date.format('DD.MM.YYYY');
  }

  save() {
    const { promotion, sections } = this.state;
    const { onSave, onHide } = this.props;

    onSave(promotion, sections);
    onHide();
  }

  validate() {
    const { promotion } = this.state;
    const { schema } = this.props;

    return schema.items.required.reduce((result, field) => {
      if (
        _.isUndefined(promotion[field]) ||
        _.isNull(promotion[field]) ||
        (!_.isBoolean(promotion[field]) && _.isEmpty(promotion[field]))
      ) {
        result[field] = {
          valid: false,
          message: `Dies ist ein Pflichfeld`,
        };
      }

      return result;
    }, {});
  }

  setValue(field, value) {
    this.setState((prev) => ({promotion: {...prev.promotion, [field]: value}}));
  }
  onTitleChange(event) {
    const { value } = event.target;
    this.setValue('title', value);
  }
  onImageTextChange(event) {
    const { value } = event.target;
    this.setValue('imageText', value);
  }
  onColorCodeChange(event) {
    const { value } = event.target;
    this.setValue('colorCode', value);
  }
  orderChange(event) {
    const { value } = event.target;
    this.setValue('order', parseInt(value, 10));
  }
  onActiveChange(event) {
    const { checked } = event.target;
    this.setValue('active', checked);
  }
  onDefaultChange(event) {
    const { checked } = event.target;
    this.setValue('default', checked);
  }
  onChecklistSectionChange(section) {
    this.setState((prev) => {
      const updatedSections = prev.sections.map((it) => it.id === section.id ? section : it);
      const checklistItem = updatedSections.reduce((result, updatedSection) => [
        ...result,
        ...updatedSection.items.map((item) => item.id),
      ], []);

      return {
        sections: updatedSections,
        promotion: {...prev.promotion, checklistItem: checklistItem},
      };
    });
  }
  onDocumentsChange(documents = []) {
    this.setValue('document', documents);
  }
  /**
   * Unset the image on local promotion
   *
   * @param  {String} which: teaserImage | image
   */
  onImageRemove(which) {
    this.setValue(which, {});
  }
  /**
   * Handle date change event from DatePicker
   *
   * @param  {String} which from | to
   * @param  {Object} date  Moment instance
   */
  onDateChange(which, date) {
    date.second(0).minute(0).hour(12);
    this.setValue(which, date);
  }
  onChangeSegment(segments) {
    const updated = segments.map(segment => segment.value);
    this.setValue('customerSegments', updated);
  }

  renderSegments() {
    const { language, customerSegments=[] } = this.props;
    const { promotion } = this.state;
    const selected = customerSegments.filter(segment =>
      promotion.customerSegments.includes(segment.id)
    );

    return (
      <Select
        multi
        placeholder="Bitte Segmente selektieren"
        value={ selected.map(item => ({label: item.text[language], value: item.id })) }
        options={ customerSegments.map(item => ({label: item.text[language], value: item.id })) }
        onChange={ this.onChangeSegment }
        style={{ width: '100%' }}>
      </Select>
    );
  }

  renderChecklist() {
    const { sections: rawSections, language } = this.props;
    const { sections } = this.state;

    if (!rawSections.length) {
      return [];
    }

    return sections.map((section, key) => {
      return (
        <div key={key}>
          <h5>{section.name[language]}</h5>
          <Checklist
            section={section}
            onSectionChange={this.onChecklistSectionChange}
            language={language}>
          </Checklist>
        </div>
      );
    });
  }

  render() {
    const { language, bsSize='lg', show=false, modules } = this.props;
    const { promotion } = this.state;
    const { image={}, teaserImage={} } = promotion;
    const validation = this.validate(promotion);
    const valid = _.isEmpty(validation);
    const maxTitleLength = 23;

    return(
      <Modal bsSize={ bsSize } show={ show } onHide={ this.reset }>
        <Modal.Header closeButton>
          <Modal.Title>Metadaten bearbeiten</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <FormGroup>
                { /* Promotion active / inactive */ }
                <Col componentClass={ControlLabel} sm={2} xs={4}>
                  Promotion
                </Col>
                <Col sm={10} xs={8}>
                  <Toggle
                    checked={ promotion.active }
                    onChange={ this.onActiveChange }>
                  </Toggle>
                </Col>
              </FormGroup>
            </Row>

            <Row>
              <Col md={6} sm={6} xs={12}>
                <FormGroup validationState={promotion.title.length > maxTitleLength ? 'error' : null}>
                  <ControlLabel>Titel</ControlLabel>
                  <FormControl
                    type="text"
                    placeholder="Promotion Titel"
                    value={ promotion.title }
                    maxLength={maxTitleLength}
                    onChange={ this.onTitleChange }>
                  </FormControl>
                  <div className="pull-right" style={{ fontSize: '0.8em' }}>
                    { promotion.title.length }/{maxTitleLength} Zeichen
                  </div>
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Datum</ControlLabel>
                  <div>
                    <span>Von: { this.formatDate(promotion.from) } </span>
                    <DatePicker onDateChange={ date => this.onDateChange('from', date) }/>
                  </div>
                  <div>
                    <span>Bis: { this.formatDate(promotion.to) } </span>
                    <DatePicker onDateChange={ date => this.onDateChange('to', date) }/>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <hr/>

            <Row>
              <Col md={9} sm={9} xs={12}>
                <FormGroup>
                  <ControlLabel>Segemente</ControlLabel>
                  { this.renderSegments() }
                </FormGroup>
              </Col>
              <Col md={3} sm={3} xs={12}>
                <ControlLabel>Reihenfolge</ControlLabel>
                <FormControl
                  type="number"
                  value={ promotion.order }
                  onChange={this.orderChange}>
                </FormControl>
              </Col>
            </Row>

            <Row>
              <FormGroup>
                { /* Promotion active / inactive */ }
                <Col componentClass={ControlLabel} md={9} sm={9} xs={12}>
                  Als Standard für die gewählten Segmente verwenden
                </Col>
                <Col md={3} sm={3} xs={12}>
                  <Toggle
                    checked={ promotion.default }
                    onChange={ this.onDefaultChange }>
                  </Toggle>
                </Col>
              </FormGroup>
            </Row>

            <hr/>

            <Row>
                <Col sm={4}>
                  <ImageDropzone
                    id="image"
                    label={ <span>Hauptbild <span style={{fontSize: '0.8em'}}>(532x398px)</span></span> }
                    value={ image }
                    size={ { width: 532, height: 398 } }
                    language={ language }
                    onImageDrop={ this.setValue }
                    onImageRemove={ this.onImageRemove }>
                  </ImageDropzone>
                </Col>
                <Col sm={4}>
                  <ImageDropzone
                    id="teaserImage"
                    label={ <span>Teaserbild <span style={{fontSize: '0.8em'}}>(794x324px)</span></span> }
                    value={ teaserImage }
                    size={ { width: 794, height: 324 } }
                    language={ language }
                    onImageDrop={ this.setValue }
                    onImageRemove={ this.onImageRemove }>
                  </ImageDropzone>
                </Col>
            </Row>

            <Row>
              <Col md={8} sm={8} xs={8}>
                <FormGroup>
                  <ControlLabel>Bildtext</ControlLabel>
                  <FormControl
                    type="text"
                    placeholder="Bildtext"
                    value={ promotion.imageText }
                    onChange={ this.onImageTextChange }>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={2} sm={2} xs={2}>
                <FormGroup>
                  <ControlLabel>Farbcode</ControlLabel>
                  <InputGroup>
                    <InputGroup.Addon>#</InputGroup.Addon>
                    <FormControl
                      type="text"
                      placeholder="FFFFFF"
                      maxLength={6}
                      value={ promotion.colorCode }
                      onChange={ this.onColorCodeChange }>
                    </FormControl>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            <hr/>

            <h4>Dokumente</h4>

            <Row style={{ minHeight: '250px' }}>
              <PromotionDocuments
                documents={ promotion.document }
                onDocumentsChange={ this.onDocumentsChange }
                modules={ modules }
                language={language}>
              </PromotionDocuments>
            </Row>

            <hr/>

            <h4>Checkliste</h4>
            { this.renderChecklist() }
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={ this.reset }>Abbrechen</Button>
          <Button bsStyle="primary" onClick={ this.save } disabled={ !valid }>Speichern</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

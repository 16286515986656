import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from 'react-select';

const options = [{
  value: '1',
  label: 'Ja'
}, {
  value: '0',
  label: 'Nein'
}]

const CriteriaCheck = ({ edit, onChange, criteria, source }) => {
  function onValueChange(selected) {
    onChange({
      source: _.get(source, 'props.id'),
      path: `${_.get(source, 'props.id')}.value`,
      values: (selected) ? [selected.value] : []
    });
  }

  return (edit)
    ? (
      <Select
        value={ _.get(criteria, 'values.0')}
        onChange={onValueChange}
        options={options}
      />
    )
    : (
      <span>{_.get(criteria, 'values', []).map(v => _.get(options.find(o => o.value === v), 'label')).join(', ')}</span>
    )

}

CriteriaCheck.propTypes = {
  edit: PropTypes.bool,
  onChange: PropTypes.func
};

CriteriaCheck.defaultProps = {
  edit: false,
  onChange: _.noop
};

export default CriteriaCheck;

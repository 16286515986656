import React from 'react';
import PropTypes from 'prop-types';
import { has, get, noop, isUndefined, debounce } from 'lodash';
import { FormGroup, ControlLabel, Col, FormControl } from 'react-bootstrap';
import Toggle from 'react-toggle';
import Select from 'react-select';

import DynamicPropsList from '../DynamicProps/List';

class EDossierUpload extends React.PureComponent {
  constructor(props) {
    super(props);

    const { id, fetchCodes, editValueField } = props;

    this.state = {
      filename: undefined,
      documentType: undefined,
      findCode: undefined
    };

    fetchCodes('archiveDocumentType');
    fetchCodes('mimeType');
    fetchCodes('documentFindCode');

    // Set default validation
    editValueField(id, 'validations', {
      meta: {
        type: 'object',
        validations: {
          filename: {
            type: 'string',
            required: true
          },
          documentType: {
            type: 'object',
            required: true
          },
          findCode: {
            type: 'string',
            required: false
          }
        }
      }
    });

    // Set hideValidations to true. Component will render validations itself
    editValueField(id, 'hideValidations', true);

    this.onToggleMulti = this.onToggleMulti.bind(this);
    this.onMimeTypesChange = this.onMimeTypesChange.bind(this);
    this.onDocumentTypeChange = this.onDocumentTypeChange.bind(this);
    this.onDocumentTypeEditableChange = this.onDocumentTypeEditableChange.bind(this);
    this.onFilenameChange = this.onFilenameChange.bind(this);
    this.onFilenameEditableChange = this.onFilenameEditableChange.bind(this);
    this.onFindCodeChange = this.onFindCodeChange.bind(this);
    this.onFindCodeEditableChange = this.onFindCodeEditableChange.bind(this);
  }

  /**
   * Toggle the multi flag.
   *
   * @return void
   */
  onToggleMulti() {
    const { id, editValueField } = this.props;

    debounce(editValueField, 500)(id, 'multi');
  }

  /**
   * Handle mime types change
   *
   * @param  {Array} selected Array of allowed mime types
   *
   * @return void
   */
  onMimeTypesChange(selected) {
    const { id, editValueField } = this.props;
    const value = isUndefined(selected)
      ? undefined
      : selected.map(s => s.value);

    editValueField(id, 'allowedMimeTypes', value);
  }

  /**
   * Handle onChange of document type.
   * If a documentType is set, the documentType must not be edited (disabled in metadata form).
   * If not, set it as default value (allow user to change)
   *
   * @param  {Object} selected Selected option
   *
   * @return void
   */
  onDocumentTypeChange(selected) {
    const { id, item, editValueField } = this.props;
    const value = get(selected, 'value');

    const documentType = get(item, 'props.documentType');
    const dt = !isUndefined(value)
      ? { value, editable: get(documentType, 'editable', true) }
      : undefined;

    editValueField(id, 'documentType', dt);
  }

  /**
   * Set documentType editable
   *
   * @param  {Event} ev Event object
   *
   * @return void
   */
  onDocumentTypeEditableChange(ev) {
    const { id, item, editValueField } = this.props;

    const checked = get(ev, 'target.checked');
    const documentType = get(item, 'props.documentType');

    editValueField(id, 'documentType', { ...documentType, editable: checked });
  }

  /**
   * Handle filename change
   *
   * @return {[type]} [description]
   */
  onFilenameChange(ev) {
    const { id, item, editValueField } = this.props;

    let value = get(ev, 'target.value', '');
    value = value.trim().length > 0 ? value : undefined;

    const filename = get(item, 'props.filename');
    const fn = !isUndefined(value)
      ? { value, editable: get(filename, 'editable', true) }
      : undefined;

    editValueField(id, 'filename', fn);
  }

  /**
   * Set filename editable
   *
   * @param  {Event} ev Event object
   *
   * @return void
   */
  onFilenameEditableChange(ev) {
    const { id, item, editValueField } = this.props;

    const checked = get(ev, 'target.checked');
    const filename = get(item, 'props.filename');

    editValueField(id, 'filename', { ...filename, editable: checked });
  }

  /**
   * Handle findCode change
   *
   * @return {[type]} [description]
   */
  onFindCodeChange(selected) {
    const { id, item, editValueField } = this.props;

    let value = get(selected, 'value', '');
    value = value.trim().length > 0 ? value : undefined;

    const findCode = get(item, 'props.findCode');
    const fn = !isUndefined(value)
      ? { value, editable: get(findCode, 'editable', true) }
      : undefined;

    editValueField(id, 'findCode', fn);
  }

  /**
   * Set findCode editable
   *
   * @param  {Event} ev Event object
   *
   * @return void
   */
  onFindCodeEditableChange(ev) {
    const { id, item, editValueField } = this.props;

    const checked = get(ev, 'target.checked');
    const findCode = get(item, 'props.findCode');

    editValueField(id, 'findCode', { ...findCode, editable: checked });
  }

  render() {
    const {
      editValueField,
      id,
      item,
      codes,
      language
    } = this.props;

    return (
      <React.Fragment>
        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            Mehrfachauswahl
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
            <Toggle
              checked={get(item, 'props.multi') === undefined ? false : item.props.multi}
              onChange={this.onToggleMulti}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            Erlaubte Dateitypen
          </Col>
          <Col sm={9}>
            <Select
              value={get(item, 'props.allowedMimeTypes', [])}
              options={get(codes, 'mimeType', []).map(c => ({ value: c.number, label: get(c, `text.${language}`) }))}
              onChange={this.onMimeTypesChange}
              multi
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Dokumenttyp Standard Wert
          </Col>

          <Col sm={4}>
            <Select
              value={get(item, 'props.documentType.value')}
              options={get(codes, 'archiveDocumentType', []).map(c => ({ value: c.id, label: get(c, `text.${language}`) }))}
              onChange={this.onDocumentTypeChange}
              multi={false}
            />
          </Col>

          <Col componentClass={ControlLabel} sm={3}>
            Editierbar
          </Col>

          <Col sm={2} style={{ paddingTop: '10px' }}>
            <Toggle
              checked={get(item, 'props.documentType.editable', true)}
              onChange={this.onDocumentTypeEditableChange}
              disabled={!has(item, 'props.documentType.value')}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Dokumentname Standard Wert
          </Col>

          <Col sm={4}>
            <FormControl
              type="text"
              value={get(item, 'props.filename.value', '')}
              onChange={this.onFilenameChange}
            />
          </Col>

          <Col componentClass={ControlLabel} sm={3}>
            Editierbar
          </Col>

          <Col sm={2} style={{ paddingTop: '10px' }}>
            <Toggle
              checked={get(item, 'props.filename.editable', true)}
              onChange={this.onFilenameEditableChange}
              disabled={!has(item, 'props.filename.value')}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Auffindcode Standard Wert
          </Col>

          <Col sm={4}>
            <Select
              value={get(item, 'props.findCode.value', '')}
              options={get(codes, 'documentFindCode', []).map(c => ({ value: c.number, label: get(c, `text.${language}`) }))}
              onChange={(selected) => this.onFindCodeChange(selected)}
              multi={false}
            />
          </Col>

          <Col componentClass={ControlLabel} sm={3}>
            Editierbar
          </Col>

          <Col sm={2} style={{ paddingTop: '10px' }}>
            <Toggle
              checked={get(item, 'props.findCode.editable', true)}
              onChange={this.onFindCodeEditableChange}
              disabled={!has(item, 'props.findCode.value')}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Dynamische Properties
          </Col>

          <Col sm={9}>
            <DynamicPropsList
              id={id}
              editValueField={editValueField}
              mapping={get(item, 'props.dynamicProps', [])}
            />
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

EDossierUpload.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  item: PropTypes.object.isRequired,
  codes: PropTypes.object,
  fetchCodes: PropTypes.func,
  editValueField: PropTypes.func,
  language: PropTypes.string
};

EDossierUpload.defaultProps = {
  editValueField: noop,
  codes: {},
  fetchCodes: noop,
  language: 'de'
};

export default EDossierUpload;

import { FINANCING_RATES_SAVE_ACTIONS } from '../../actions/FinancingRatesActions';

export function FinancingRatesSaveReducer(state, action) {
  switch (action.type) {
    case FINANCING_RATES_SAVE_ACTIONS.PENDING:
      return { ...state, loading: true, error: undefined };
    case FINANCING_RATES_SAVE_ACTIONS.SUCCESS:
      const { forwards, baseRates, refiRates } = state;
      return {
        ...state,
        loading: false,
        error: undefined,
        initialData: {
          ...state.initialData,
          forwards,
          baseRates,
          refiRates,
        },
      };
    case FINANCING_RATES_SAVE_ACTIONS.FAILURE:
      return { ...state, loading: false, error: action.data };
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop } from 'lodash';
import { Modal } from 'react-bootstrap';

import './EditNode.css';
import EditNodeForm from '../Form/EditNode/Form';
import workguideActions from '../../../../actions/Actions';

class WorkflowTreeModalEditNode extends React.Component {
  constructor(props) {
    super(props);

    const { node, workguideActions } = props;
    workguideActions.workflowTreeEditNodeFormInitRequest({ data: node });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      consultants,
      isOpen,
      language,
      node,
      onEditFormSubmit,
      onHide,
      workguide
    } = this.props;

    return (
      <Modal
        bsSize="lg"
        className="workflow-tree-modal-edit-node"
        show={isOpen}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          {`Edit ${get(node, `title.${language}`)}`}
        </Modal.Header>

        <Modal.Body>
          <EditNodeForm
            consultants={consultants}
            language={language}
            node={node}
            onCancel={onHide}
            onSubmit={onEditFormSubmit}
            workguide={workguide}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

WorkflowTreeModalEditNode.propTypes = {
  consultants: PropTypes.array,
  isOpen: PropTypes.bool,
  language: PropTypes.string,
  node: PropTypes.object.isRequired,
  onEditFormSubmit: PropTypes.func,
  onHide: PropTypes.func,
  workguide: PropTypes.object.isRequired,
  workguideActions: PropTypes.object.isRequired
};

WorkflowTreeModalEditNode.defaultProps = {
  consultants: [],
  isOpen: false,
  language: 'de',
  onEditFormSubmit: noop,
  onHide: noop
};

function mapDispatchToProps(dispatch) {
  return {
    workguideActions: bindActionCreators(workguideActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(
  WorkflowTreeModalEditNode
);

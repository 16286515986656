import React from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import _ from 'lodash';
import { arrayMove } from 'react-sortable-hoc';
import { toast } from 'react-toastify';

import EditContainer from '../components/EditContainer';
import * as actions from '../actions/AgendaActions';
import AModule from '../components/Agenda/AModule';

class Agenda extends React.Component {
  constructor() {
    super();
    this.edit = this.edit.bind(this);
    this.checked = this.checked.bind(this);
    this.editMain = this.editMain.bind(this);
    this.toggle = this.toggle.bind(this);
    this.addItem = this.addItem.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.editImageKey = this.editImageKey.bind(this);
    this.saveData = this.saveData.bind(this);
    this.setSelectedModule = this.setSelectedModule.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(actions.getAgendas());
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { selectedModule, agendas } = this.props;
    const sectionOrder = agendas[selectedModule].order;
    const reorderedItems = arrayMove(agendas[selectedModule].items, oldIndex, newIndex)
      .map((item, index) => ({
        ...item,
        order: sectionOrder + (index + 1) * 10,
      }));
    const newAgendas = update(agendas, {
      [selectedModule]: { items: { $set: reorderedItems } },
    });
    this.props.dispatch(actions.setData({ key: 'agendas', value: newAgendas }));
  };

  setSelectedModule(id, event) {
    // set only when tab still exists otherwise do nothing
    event.preventDefault();
    if (this.props.agendas.filter((o, i) => i === id).length > 0) {
      this.props.dispatch(actions.setData({ key: 'selectedModule', value: id }));
    }
  }

  // handle CRUD operations on the agenda modules/items
  addItem() {
    const { agendas, selectedModule, dispatch } = this.props;
    const sectionOrder = agendas[selectedModule].order;
    const index = agendas[selectedModule].items.length;

    const newItem = {
      isChecked: false,
      isOwnItem: false,
      order: sectionOrder + (index + 1) * 10,
      name: {
        en: '',
        de: '',
      },
    };

    const newAgendas = update(agendas, {
      [selectedModule]: { items: { $push: [newItem] } },
    });
    dispatch(actions.setData({ key: 'agendas', value: newAgendas }));
  }

  // handle CRUD operations on the agendalist modules/items
  removeItem(id) {
    const { agendas, selectedModule, dispatch } = this.props;
    const items = agendas[selectedModule].items.filter((o, i) => i !== id);
    const newAgendas = update(agendas, {
      [selectedModule]: { items: { $set: items } },
    });
    dispatch(actions.setData({ key: 'agendas', value: newAgendas }));
  }

  // handle CRUD operations on the agendalist modules/items
  edit(index, event) {
    const {
      agendas, language, selectedModule, dispatch,
    } = this.props;

    let newAgendas = update(agendas, {
      [selectedModule]: { items: { [index]: { name: { [language]: { $set: event.target.value } } } } },
    });
    // //english is the main language in graviton. make sure english has always a value
    if (language !== 'en') {
      newAgendas = update(newAgendas, {
        [selectedModule]: { items: { [index]: { name: { en: { $set: event.target.value } } } } },
      });
    }
    dispatch(actions.setData({ key: 'agendas', value: newAgendas }));
  }

  checked(index, event) {
    const { agendas, selectedModule, dispatch } = this.props;
    const newAgendas = update(agendas, {
      [selectedModule]: { items: { [index]: { isChecked: { $set: event.target.value !== 'true' } } } },
    });
    dispatch(actions.setData({ key: 'agendas', value: newAgendas }));
  }

  editMain(event) {
    const {
      agendas, language, selectedModule, dispatch,
    } = this.props;
    let newAgendas = update(agendas, {
      [selectedModule]: { name: { [language]: { $set: event.target.value } } },
    });

    // english is the main language in graviton. make sure english has always a value
    if (language !== 'en') {
      newAgendas = update(newAgendas, {
        [selectedModule]: { name: { en: { $set: event.target.value } } },
      });
    }
    dispatch(actions.setData({ key: 'agendas', value: newAgendas }));
  }

  editImageKey(event) {
    const { agendas, selectedModule, dispatch } = this.props;

    const newAgendas = update(agendas, {
      [selectedModule]: { imageKey: { $set: event.target.value } },
    });
    dispatch(actions.setData({ key: 'agendas', value: newAgendas }));
  }

  toggle(event) {
    const { agendas, selectedModule, dispatch } = this.props;

    const newAgendas = update(agendas, {
      [selectedModule]: { collapsed: { $set: !agendas[selectedModule].collapsed } },
    });
    dispatch(actions.setData({ key: 'agendas', value: newAgendas }));
  }

  // save the changes in AgendaStore back to the graviton service
  saveData(event) {
    event.preventDefault();
    const { agendas, dispatch } = this.props;

    _.forEach(agendas, (agenda) => {
      dispatch(actions.updateAgenda({ agenda }));
    });
  }

  // render the whole Agenda
  agendaview() {
    const { agendas, language, selectedModule } = this.props;

    if (agendas.length > 0) {
      return (
        <AModule
          modules={agendas}
          selectedModule={selectedModule}
          language={language}
          onAdd={this.addItem}
          onRemove={this.removeItem}
          onEdit={this.edit}
          onChecked={this.checked}
          onToggle={this.toggle}
          onSortEnd={this.onSortEnd}
          onEditMain={this.editMain}
          setSelectedModule={this.setSelectedModule}
          editImageKey={this.editImageKey}
        />
      );
    }
  }

  // embed the agenda in to the EditContainer component
  render() {
    const {
      requesting, language, hasError, error,
    } = this.props;
    if (hasError) {
      toast.error(error.response.data.message);
    }
    return (
      <EditContainer
        requesting={requesting}
        saveData={this.saveData}
        title={`Agenda (${language})`}
        body={this.agendaview()}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    agendas: state.agenda.agendas,
    requesting: state.agenda.requesting,
    language: state.login.language,
    selectedModule: state.agenda.selectedModule,
    error: state.agenda.error,
    hasError: state.agenda.hasError,
  }
}

export default connect(mapStateToProps)(Agenda);

import React from 'react';
import { PortalWithState } from 'react-portal';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { DayPickerSingleDateController } from 'react-dates';
import update from 'immutability-helper';
import { get } from 'lodash';

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: get(props, 'value', moment()),
      focused: true
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    const { value: prevValue } = prevProps;

    if (value !== prevValue && moment.isMoment(value)) this.setState({ date: value });
  }

  /**
   * Handle onchange event. Hide datepicker and call onDateChange callback
   *
   * @param  {Object} date Moment instance
   *
   * @return void
   */
  change(date, closePortal) {
    let { onDateChange } = this.props;

    onDateChange(date);
    closePortal();
  }

  /**
   * Toggle onclick
   *
   * @param  {Object} ev Event
   *
   * @return void
   */
  toggle(openPortal, ev) {
    let { state } = this;
    state = update(
      state,
      {
        top: { $set: ev.clientY },
        left: { $set: ev.clientX },
      },
    );

    this.setState(state);
    openPortal(ev);
  }

  /**
   * Render the portal
   *
   * @param  {Funciton}  openPortal  Open portal function
   * @param  {Funciton}  closePortal Close portal function
   * @param  {Boolean}   isOpen      IsOpen state
   * @param  {Funciton}  portal      Portal function
   *
   * @return {Array} PortalWithState definition
   */
  renderPortal({ openPortal, closePortal, isOpen, portal }) {
    const { top, left } = this.state;

    return [
      <i key="foo" className="glyphicon glyphicon-calendar" onClick={this.toggle.bind(this, openPortal)}></i>,
      portal(
        <div style={{
          position: 'absolute',
          zIndex: '99999',
          top: `${top}px`,
          left: `${left}px`,
          border: '1px solid black',
        }}
        >
          <DayPickerSingleDateController
            date={this.state.date}
            onDateChange={date => this.change(date, closePortal)}
            focused={this.state.focused}
            onFocusChange={({ focused }) => this.setState({ focused })}
            keepOpenOnDateSelect={false}
          />
        </div>
      )
    ];
  }

  /**
   * Render the DatePicker
   *
   * @return {ReactElement} markup
   */
  render() {
    return (
      <PortalWithState closeOnOutsideClick closeOnEsc>
        {this.renderPortal.bind(this)}
      </PortalWithState>
    );
  }
}

import axios from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { get } from 'lodash';

import { EndpointName } from '../../constants/EndpointName';
import {
  EMAIL_SUBSCRIPTIONS_REMOVE,
  EMAIL_SUBSCRIPTIONS_SEARCH
} from '../../actions/EmailSubscriptionsActions';
import {
  getEmailSubscriptionsItemsRaw,
  getEmailSubscriptionsSearchParams
} from '../../selectors/EmailSubscriptions/emailSubscriptionsCommonSelectors';
import { EMAIL_SUBSCRIPTIONS_PAGE_SIZE } from '../../constants/EmailSubscriptionsConstants';

export function* emailSubscriptionsRemoveSaga({ meta: itemId }) {
  try {
    yield put(EMAIL_SUBSCRIPTIONS_REMOVE.pending());
    yield call(axios, {
      method: 'delete',
      url: `${EndpointName.EMAIL_SUBSCRIPTIONS}/${itemId}`,
    });

    const items = yield select(getEmailSubscriptionsItemsRaw);
    const searchParams = yield select(getEmailSubscriptionsSearchParams);

    yield put(EMAIL_SUBSCRIPTIONS_REMOVE.success({
      items: items.filter((it) => it.id !== itemId)
    }));

    yield put(EMAIL_SUBSCRIPTIONS_SEARCH.request({
      ...searchParams,
      limit: EMAIL_SUBSCRIPTIONS_PAGE_SIZE,
      offset: 0,
      rawData: []
    }))

    return { items, totalCount: get(items, 'length', 0) };
  } catch (error) {
    yield put(EMAIL_SUBSCRIPTIONS_REMOVE.failure(error));

    return error;
  }
}

import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { getFinancingRatesBaseRates } from '../../selectors/FinancingRates/financingRatesCommonSelectors';
import { createRef } from '../../utils';

export function* financingRatesSaveBaseRatesSaga(initialRates) {
  const baseRates = yield select(getFinancingRatesBaseRates);

  yield all(baseRates
    .filter((baseRate) => {
      const initial = initialRates.find((it) => it.id === baseRate.id);
      return initial && initial.baseRate !== baseRate.baseRate;
    })
    .map((baseRate) => call(axios, {
      method: 'patch',
      url: createRef(EndpointName.FINANCING_RATE, baseRate.id),
      data: [{
        op: 'replace',
        path: '/baseRate',
        value: baseRate.baseRate,
      }],
    })),
  );
}

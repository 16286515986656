import React from 'react';
import PropTypes from 'prop-types';
import { get, set, noop, defaultsDeep } from 'lodash';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';
import Toggle from 'react-toggle';
import Select from 'react-select';
import update from 'immutability-helper';
import uuid from 'uuid';

import LanguageAwareInput from '../../Common/LanguageAwareInput';
import DateValue from '../Configuration/DateField/DateValue/DateValue';

const additionalConsultantOptions = [{
  value: 'current',
  label: 'Aktueller Benutzer'
}, {
  value: 'customer',
  label: 'Hauptbetreuer des Kunden'
}];

class EditForm extends React.Component {
  constructor(props) {
    super(props);

    const { codes, data } = props;

    const defaults = {
      identifier: uuid.v4(),
      type: get(codes, 'workguideApprovalType', []).find((code) => get(code, 'id') === 'workguideApprovalType-1'),
      comment: {
        defaultValue: {}
      },
      title: {},
      description: {},
      consultant: {
        required: true
      }
    };

    this.state = {
      data: defaultsDeep({}, data, defaults)
    };

    this.onValueChange = this.onValueChange.bind(this);
    this.onCommentChange = this.onCommentChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onTitleDescriptionChange = this.onTitleDescriptionChange.bind(this);
  }

  onValueChange(path, value) {
    const updated = { ...get(this, 'state.data', {}) };
    set(updated, path, value);

    this.setState({ data: updated });
  }

  onCommentChange(field, language, value) {
    const { data } = this.state;

    const updated = update(data, {
      comment: {
        defaultValue: {
          [language]: { $set: value }
        }
      }
    });

    this.setState({ data: updated });
  }

  onTitleDescriptionChange(field, language, value) {
    const { data } = this.state;

    const updated = update(data, {
      [field]: { [language]: { $set: value } }
    });

    this.setState({ data: updated });
  }

  onSubmit() {
    const { data } = this.state;
    const { onSubmit } = this.props;

    onSubmit(data);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { data } = this.state;
    const {
      onCancel,
      consultants
    } = this.props;

    const consultantOptions = consultants.filter((c) => get(c, 'isActive', false)).map((consultant) => ({
      value: get(consultant, 'id'),
      label: `${get(consultant, 'firstName')} ${get(consultant, 'lastName')} (${get(consultant, 'username')})`
    }));

    return (
      <div className="workguide-approval-form">
        <Row>
          <Col lg={8} md={8} style={{ fontWeight: 'bold' }}>
            Titel
          </Col>

          <Col lg={2} md={2} className="align-self-end">
            <FormGroup>
              <span>
                Workguide kann nicht berarbeitet werden
              </span>

              <Toggle
                checked={get(data, 'isWorkguideImmutable', false)}
                onChange={() => this.onValueChange('isWorkguideImmutable', !get(data, 'isWorkguideImmutable', false))}
              />
            </FormGroup>
          </Col>

          <Col lg={2} md={2} className="align-self-end">
            <FormGroup>
              <span>
                Finaler Schritt
              </span>

              <Toggle
                checked={get(data, 'isFinalStep', false)}
                onChange={() => this.onValueChange('isFinalStep', !get(data, 'isFinalStep', false))}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <LanguageAwareInput
              id="title"
              onChange={this.onTitleDescriptionChange}
              edit
              values={get(data, 'title', {})}
            />
          </Col>
        </Row>

        <Row style={{ paddingBottom: '15px' }} />

        <Row>
          <Col lg={12} md={12} style={{ fontWeight: 'bold' }}>
            Beschreibung
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <FormGroup>
              <span>
                Ausblenden
              </span>

              <Toggle
                checked={get(data, 'description.hidden', false)}
                onChange={() => this.onValueChange('description.hidden', !get(data, 'description.hidden', false))}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <LanguageAwareInput
              id="description"
              onChange={this.onTitleDescriptionChange}
              edit
              values={get(data, 'description', {})}
              type="textarea"
            />
          </Col>
        </Row>

        <Row style={{ paddingBottom: '40px' }} />

        <Row>
          <Col lg={12} md={12} style={{ fontWeight: 'bold' }}>
            Erledigungsdatum
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <FormGroup>
              <span>
                Standardwert
              </span>

              <DateValue
                id="completionDate.defaultValue"
                onChange={this.onValueChange}
                value={get(data, 'completionDate.defaultValue')}
              />
            </FormGroup>
          </Col>

          <Col lg={4} md={4}>
            <FormGroup style={{ alignItems: 'center' }}>
              <span>
                Pflichtfeld
              </span>

              <Toggle
                checked={get(data, 'completionDate.required', false)}
                onChange={() => this.onValueChange('completionDate.required', !get(data, 'completionDate.required', false))}
              />
            </FormGroup>
          </Col>

          <Col lg={4} md={4}>
            <FormGroup>
              <span>
                Gesperrt
              </span>

              <Toggle
                checked={get(data, 'completionDate.disabled', false)}
                onChange={() => this.onValueChange('completionDate.disabled', !get(data, 'completionDate.disabled', false))}
              />
            </FormGroup>
          </Col>

          <Col lg={4} md={4}>
            <FormGroup>
              <span>
                Ausblenden
              </span>

              <Toggle
                checked={get(data, 'completionDate.hidden', false)}
                onChange={() => this.onValueChange('completionDate.hidden', !get(data, 'completionDate.hidden', false))}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} style={{ fontWeight: 'bold' }}>
            Empfänger
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <FormGroup>
              <span>
                Standardwert
              </span>

              <Select
                options={[...additionalConsultantOptions, ...consultantOptions]}
                value={get(data, 'consultant.defaultValue')}
                onChange={(selected = []) => this.onValueChange('consultant.defaultValue', selected.map((s) => get(s, 'value')))}
                multi
              />
            </FormGroup>
          </Col>

          <Col lg={4} md={4}>
            <FormGroup style={{ alignItems: 'center' }}>
              <span>
                Pflichtfeld
              </span>

              <Toggle
                checked={get(data, 'consultant.required', false)}
                onChange={() => this.onValueChange('consultant.required', !get(data, 'consultant.required', false))}
              />
            </FormGroup>
          </Col>

          <Col lg={4} md={4}>
            <FormGroup>
              <span>
                Gesperrt
              </span>

              <Toggle
                checked={get(data, 'consultant.disabled', false)}
                onChange={() => this.onValueChange('consultant.disabled', !get(data, 'consultant.disabled', false))}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} style={{ fontWeight: 'bold' }}>
            Kommentar
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <FormGroup>
              <span>
                Standardwert
              </span>

              <LanguageAwareInput
                id="comment"
                onChange={this.onCommentChange}
                edit
                values={get(data, 'comment.defaultValue', {})}
                type="textarea"
              />
            </FormGroup>
          </Col>

          <Col lg={4} md={4}>
            <FormGroup style={{ alignItems: 'center' }}>
              <span>
                Pflichtfeld
              </span>

              <Toggle
                checked={get(data, 'comment.required', false)}
                onChange={() => this.onValueChange('comment.required', !get(data, 'comment.required', false))}
              />
            </FormGroup>
          </Col>

          <Col lg={4} md={4}>
            <FormGroup>
              <span>
                Gesperrt
              </span>

              <Toggle
                checked={get(data, 'comment.disabled', false)}
                onChange={() => this.onValueChange('comment.disabled', !get(data, 'comment.disabled', false))}
              />
            </FormGroup>
          </Col>

          <Col lg={4} md={4}>
            <FormGroup>
              <span>
                Ausblenden
              </span>

              <Toggle
                checked={get(data, 'comment.hidden', false)}
                onChange={() => this.onValueChange('comment.hidden', !get(data, 'comment.hidden', false))}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <div className="pull-right">
            <span style={{ paddingRight: '10px' }}>
              <Button bsStyle="primary" onClick={this.onSubmit}>
                Speichern
              </Button>
            </span>

            <span style={{ paddingRight: '10px' }}>
              <Button onClick={onCancel}>
                Abbrechen
              </Button>
            </span>
          </div>
        </Row>
      </div>
    );
  }
}

EditForm.propTypes = {
  data: PropTypes.object,
  codes: PropTypes.object,
  consultants: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,

};

EditForm.defaultProps = {
  data: {},
  codes: {},
  consultants: [],
  onCancel: noop,
  onSubmit: noop
};

export default EditForm;

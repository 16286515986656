import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { has, get, noop, isNil, toNumber } from 'lodash';
import { CodeSelect, TextInput, NumberInput, ValidationResult } from '@evoja-web/react-form';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import * as workguideLegacyActions from '../../../../../actions/WorkguideActions';

class ActionConfigurationNoteToEDossier extends React.Component {
  constructor(props) {
    super(props);

    const { codes } = props;

    if (!has(codes, 'archiveDocumentType')) {
      workguideLegacyActions.workguideCodesRequest('archiveDocumentType');
    }

    this.onFormNrChange = this.onFormNrChange.bind(this);
  }

  /**
   * Handle search value change.
   * Search value is a number input but must be converted to string if set
   *
   * @param   {String}  key    Form element id
   * @param   {Number}  value  Form value
   * @param   {Array}   rest   Rest args
   *
   * @return  void
   */
  onFormNrChange(key, value, ...rest) {
    const { onJmesParamChange } = this.props;

    const v = isNil(value)
      ? undefined
      : toNumber(value);

    onJmesParamChange(key, v, ...rest);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      action,
      codes,
      onJmesParamChange,
      validations
    } = this.props;

    const formNr = get(codes, 'archiveDocumentType', []).find((c) => get(c, 'number') === `${get(action, 'jmesParams.formNr') }`);

    return (
      <div className="workguide-action-configuration-note-to-edossier">
        <FormGroup>
          <ControlLabel>
            <FormattedMessage id="Workguide.Action.Configuration.NoteToEDossier.FormNr" />
          </ControlLabel>

          <CodeSelect
            id="formNr"
            codes={get(codes, 'archiveDocumentType', [])}
            onChange={this.onFormNrChange}
            returnValue="code.number"
            value={formNr}
          />

          <ValidationResult
            show
            validations={get(validations, 'formNr')}
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>
            <FormattedMessage id="Workguide.Action.Configuration.NoteToEDossier.SearchValue" />
          </ControlLabel>

          <NumberInput
            id="searchValue"
            onChange={onJmesParamChange}
            value={get(action, 'jmesParams.searchValue')}
          />

          <ValidationResult
            show
            validations={get(validations, 'searchValue')}
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>
            <FormattedMessage id="Workguide.Action.Configuration.NoteToEDossier.Filename" />
          </ControlLabel>

          <TextInput
            id="filename"
            onChange={onJmesParamChange}
            value={get(action, 'jmesParams.filename')}
          />

          <ValidationResult
            show
            validations={get(validations, 'filename')}
          />
        </FormGroup>
      </div>
    );
  }
}

ActionConfigurationNoteToEDossier.propTypes = {
  action: PropTypes.object.isRequired,
  codes: PropTypes.object,
  onJmesParamChange: PropTypes.func,
  validations: PropTypes.object
};

ActionConfigurationNoteToEDossier.defaultProps = {
  codes: {},
  onJmesParamChange: noop,
  validations: {}
};

function mapStateToProps(state) {
  return {
    codes: state.workguide.codes
  };
}

export default connect(mapStateToProps)(ActionConfigurationNoteToEDossier);

import React from 'react';
import PropTypes from 'prop-types';
import _, { get } from 'lodash';
import { FormControl, Button } from 'react-bootstrap';
import Select from 'react-select';

export default class MappingForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: _.get(props, 'mapping.key'),
      value: _.get(props, 'mapping.value'),
      operation: _.get(props, 'mapping.operation')
    };

    this.onKeyChange = this.onKeyChange.bind(this);
    this.onOperationChange = this.onOperationChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }

  onKeyChange(ev) {
    const path = get(ev, 'target.value');
    this.setState({ key: path });
  }

  onOperationChange(selected) {
    const { value } = selected;

    this.setState({ operation: value });
  }

  onValueChange(ev) {
    const { value } = ev.target;
    this.setState({ value });
  }

  render() {
    const { key, value, operation } = this.state;
    const { method, onSubmit, onCancel } = this.props;

    const options = [{
      value: 'add',
      label: 'Add'
    }, {
      value: 'replace',
      label: 'Replace'
    }, {
      value: 'remove',
      label: 'Remove'
    }];

    return (
      <div style={{ padding: '10px', border: '1px solid lightgrey' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>

          <div style={{ width: '40%' }}>
            <div><strong>Key</strong></div>
            <FormControl
              id="key"
              value={key || ''}
              onChange={this.onKeyChange}
            />
          </div>

          {(method === 'patch') && (
            <div style={{ width: '20%' }}>
              <div><strong>Operation</strong></div>
              <div>
                <Select
                  value={operation}
                  options={options}
                  onChange={this.onOperationChange}
                />
              </div>
            </div>
          )}

          <div style={{ width: '40%' }}>
            <div><strong>JMES Path Instruktion</strong></div>
            <div>
              <FormControl
                rows="10"
                componentClass="textarea"
                value={value || ''}
                onChange={this.onValueChange}
              />
            </div>
          </div>
        </div>
        <div style={{ width: '100%', textAlign: 'right' }}>
          <Button
            bsStyle="primary"
            style={{ paddingRight: '5px' }}
            disabled={(!key || (method === 'patch' && !operation) || !value)}
            onClick={() => onSubmit({ key, operation, value })}
          >
            Hinzufügen
          </Button>
          <Button onClick={onCancel}>
            Abbrechen
          </Button>
        </div>
      </div>
    );
  }
}

MappingForm.propTypes = {
  method: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  mapping: PropTypes.object
};

MappingForm.defaultProps = {
  method: undefined,
  onSubmit: _.noop,
  onCancel: _.noop,
  mapping: undefined
}

import { isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { getAdditions } from './getBasicServices';
import { mapBasicAdditionToApi, mapBasicAdditionToApp } from '../../services/BasicProducts';
import { getIdFromRef } from '../../utils';
import { getProductsWithAdditions } from './getProductsWithAdditions';

export const getUpdatedAdditions = createSelector([
  getProductsWithAdditions,
  getAdditions,
], (products, basicAdditions) => {
  const productsWithExistingAdditions = products.filter((product) =>
    basicAdditions.some((addition) => getIdFromRef(addition.product.$ref) === product.id)
  );

  return basicAdditions.reduce((result, addition) => {
    const product = productsWithExistingAdditions.find(
      (product) => getIdFromRef(addition.product.$ref) === product.id
    );
    if (!product) {
      return result;
    }

    return isEqual(mapBasicAdditionToApp(addition), product.addition)
      ? result
      : [...result, {...addition, ...mapBasicAdditionToApi(product)}];
  }, []);
});

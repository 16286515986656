import { arrayMove } from 'react-sortable-hoc';

export function SortBasicProductsReducer(state, action) {
  const { oldIndex, newIndex } = action.data;
  const { products } = state;

  const resortedProducts = arrayMove(products, oldIndex, newIndex)
    .map((product, index) => ({...product, order: index + 1}));

  return {...state, products: resortedProducts};
}

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Col, Row } from 'react-bootstrap';

import Progress from './Progress';
import RecordList from './ReplayRecordList';
import RecordListItem from './RecordListItem';

class Replay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false
    };
  }

  renderListGroupItems() {
    const { records, replayed } = this.props;

    return records.map((record) => {
      const id = get(record, 'id');
      const item = get(replayed, id, {});
      const { error, fulfilled, requesting, data } = item;

      const className = {
        'replay-audit-log-record-list-item--errorneous': error,
        'replay-audit-log-record-list-item--fulfilled': fulfilled,
        'replay-audit-log-record-list-item--requesting': requesting
      }

      return (
        <RecordListItem
          className={className}
          key={get(record, 'id')}
          record={record}
          error={error}
          result={data}
        />
      )
    });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { collapsed } = this.state;
    const { progress, records, replayed } = this.props;

    return (
      <div className="replay-audit-log-replay">
        <Progress
          title="Abgearbeitete Einträge"
          progress={get(progress, 'replay', {})}
        />

        <Row>
          <Col lg={12}>
            <h4>
              Einträge
            </h4>
          </Col>
        </Row>

        {!collapsed && (
          <Row>
            <Col lg={12}>
              <RecordList records={records} replayed={replayed} />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

Replay.propTypes = {
  progress: PropTypes.object.isRequired,
  records: PropTypes.array,
  replayed: PropTypes.object
};

Replay.defaultProps = {
  records: []
};

export default Replay;

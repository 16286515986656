import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { ENTITIY_CODE_LOAD } from '../../actions/PermissionGroupAction';

export function* permissionGroupGetEntityCodesSaga() {
  try {
    yield put(ENTITIY_CODE_LOAD.pending());

    const { data: codes, headers } = yield call(axios, { url: `${EndpointName.ENTITY_CODE}/?eq(group,string:userGroup)` });

    const totalCount = headers['x-total-count']
      ? parseInt(headers['x-total-count'], 10)
      : codes.length;

    yield put(ENTITIY_CODE_LOAD.success({ codes, totalCount }));
  } catch (error) {
    yield put(ENTITIY_CODE_LOAD.failure(error));
  }
}

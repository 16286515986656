import update from 'immutability-helper';
import _ from 'lodash';

import {
  INIT,
  ALL,
  GET,
  DELETE,
  ALL_LOANPRODUCTS,
  ALL_DURATIONS,
  ALL_USETYPES,
  UPDATE_ITEM,
  ADD_ITEM,
  REMOVE_ITEM,
  SAVE_LIST,
  GET_SCHEMA,
} from '../actions/FinancingSurchargeLendingrateActions';


// Default state
const defaults = {
  rates: [],
  durations: [],
  useTypes: [],
  requesting: false,
  removed: [],
  schema: {},
};

/**
 * Export default reducer function
 *
 * @param  {Object} state  Current state
 * @param  {Object} action Action
 *
 * @return {Object} state Next state
 */
export default (state, action) => {
  switch (action.type) {
    case `${INIT}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${SAVE_LIST}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${INIT}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload.data,
      };
    }
    case `${SAVE_LIST}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload.data,
      };
    }
    case `${GET}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload.data,
      };
    }
    case `${INIT}_FULFILLED`: {
      return {
        ...state, requesting: false, hasError: false,
      };
    }
    case `${ALL}_FULFILLED`: {
      const { durations, useTypes } = state;
      const items = action.payload.data.map((item) => {
        let updated = { ...item };
        // Map useType / duration to codes/durations
        if (updated.realEstateUseType && updated.realEstateUseType.$ref) {
          updated.realEstateUseType = useTypes.find(type => type.id === updated.realEstateUseType.$ref.split('/').pop());
        }
        if (updated.duration && updated.duration.$ref) {
          updated.duration = durations.find(duration => duration.id === updated.duration.$ref.split('/').pop());
        }

        // Recornvert all number fields to String
        // If rate, lendingRateStart or lendingRateEnd has value has 3 decimal places, add %
        ['lendingRateStart', 'lendingRateEnd', 'rate'].forEach((field) => {
          updated = update(updated, { [field]: { $set: updated[field].toString() } });
        });

        return updated;
      });

      return {
        ...state, rates: items,
      };
    }
    case `${GET}_FULFILLED`: {
      const { durations, useTypes } = state;
      let nextState = { ...state };

      let item = action.payload.data;
      if (item.realEstateUseType && item.realEstateUseType.$ref) item.realEstateUseType = useTypes.find(type => type.id === item.realEstateUseType.$ref.split('/').pop());
      if (item.duration && item.duration.$ref) item.duration = durations.find(duration => duration.id === item.duration.$ref.split('/').pop());

      // Recornvert all number fields to String
      ['lendingRateStart', 'lendingRateEnd', 'rate'].forEach((field) => {
        item = update(item, { [field]: { $set: item[field].toString() } });
      });

      const index = state.rates.findIndex(rate => rate.id === action.meta.id);
      if (index > -1) {
        nextState = update(
          state,
          { rates: { $splice: [[index, 1, item]] }, requesting: { $set: false } },
        );
      } else {
        nextState = update(
          state,
          { rates: { $push: [item] }, requesting: { $set: false }, hasError: { $set: false } },
        );
      }

      return nextState;
    }
    case `${ALL_DURATIONS}_FULFILLED`: {
      return { ...state, durations: action.payload.data };
    }
    case `${ALL_USETYPES}_FULFILLED`: {
      return { ...state, useTypes: action.payload.data };
    }
    case `${DELETE}_FULFILLED`: {
      return update(
        state,
        {
          rates: { $set: state.rates.filter(rate => rate.id !== action.meta.id) },
          removed: { $set: [] },
          requesting: { $set: false },
        },
      );
    }
    case `${GET_SCHEMA}_FULFILLED`: {
      return { ...state, schema: action.payload.data };
    }
    case `${ALL_LOANPRODUCTS}_FULFILLED`: {
      return {
        ...state,
        loanProducts: action.payload.data,
      };
    }
    case UPDATE_ITEM: {
      const { current, updated } = action.payload;
      const index = state.rates.findIndex(rate => rate === current);

      return update(state, { rates: { [index]: { $set: updated } } });
    }
    case ADD_ITEM: {
      return update(state, { rates: { $push: [action.payload] } });
    }
    case REMOVE_ITEM: {
      const index = state.rates.findIndex(rate => rate === action.payload);
      let nextState = update(state, { rates: { $splice: [[index, 1]] } });
      if (!action.payload.isNew) {
        nextState = update(nextState, { removed: { $push: [action.payload] } });
      }

      return nextState;
    }
    default: {
      return _.defaultsDeep(state, defaults);
    }
  }
};

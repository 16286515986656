import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import { get } from 'lodash';

import './BulkLoadsSearchForm.css';
import { classList } from '../../utils/classList';
import { ExpandContent } from '../Common/ExpandContent';
import { Button } from '../Common/Button';
import { FormDate, FormSelect, FormInput } from '../FormControls';
import { BULK_LOADS_DEFAULT_SORT, BULK_LOADS_STATES, BULK_LOADS_PAGE_SIZE } from '../../constants/BulkLoadsConstants';

const LOAD_STATES = [
  { label: 'Open', value: BULK_LOADS_STATES.OPEN },
  { label: 'Active', value: BULK_LOADS_STATES.ACTIVE },
  { label: 'Done', value: BULK_LOADS_STATES.DONE },
  { label: 'Error', value: BULK_LOADS_STATES.ERROR },
];

class BulkLoadsSearchReduxForm extends React.PureComponent {
  state = {
    expanded: false,
    results: this.props.results,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.results === prevState.results) {
      return null;
    }

    return {
      expanded: !nextProps.results || nextProps.results.length === 0,
      results: nextProps.results,
    };
  }

  render() {
    const { expanded } = this.state;
    const { handleSubmit, loading } = this.props;

    const iconClasses = classList({
      'icon-013-chevron-down': true,
      'bulk-loads-search__icon': true,
      'bulk-loads-search__icon--collapsed': !expanded,
    });

    return (
      <div className="bulk-loads-search">
        <Form onSubmit={handleSubmit(this.search)}>
          <div className="bulk-loads-search__header" onClick={this.toggleExpanded}>
            <div className="bulk-loads-search__title">Suche</div>
            <div className={iconClasses}/>
          </div>

          <ExpandContent expanded={expanded} className="bulk-loads-search__body">
            <div className="bulk-loads-search__column">
              <Field component={FormDate}
                     label="Opened from"
                     labelSize={4}
                     name="startDate"
                     displayFormat="DD.MM.YYYY"
                     isOutsideRange={this.isOutsideRange}
                     placeholder=""
              />
              <Field component={FormDate}
                     label="Opened to"
                     labelSize={4}
                     name="endDate"
                     displayFormat="DD.MM.YYYY"
                     isOutsideRange={this.isOutsideRange}
                     placeholder=""
              />
            </div>
            <div className="bulk-loads-search__column">
              <Field component={FormSelect}
                     size='large'
                     label="State"
                     labelSize={3}
                     name="states"
                     multi={true}
                     clearable={true}
                     options={LOAD_STATES}
              />
              <Field component={FormInput}
                     size='large'
                     type='text'
                     label="Loadconfig"
                     labelSize={3}
                     name="loadconfig"
                     placeholder="xxxxxxxxxxx-xxx"
              />
            </div>

            <div className="bulk-loads-search__column">
              <Field
                component={FormInput}
                type="number"
                size='large'
                label="Anzahl von"
                labelSize={3}
                name="minActionCount"
                clearable={true}
              />

              <Field
                component={FormInput}
                type="number"
                size='large'
                label="Anzahl Suchtreffer"
                labelSize={3}
                name="pageSize"
                clearable={true}
              />
            </div>

            <div className="bulk-loads-search__column">
              <Field
                component={FormInput}
                type="number"
                size='large'
                label="Anzahl bis"
                labelSize={3}
                name="maxActionCount"
                clearable={true}
              />
            </div>

            <div className="bulk-loads-search__footer">
              <Button bsStyle="primary" type="submit" disabled={loading}>
                Search
              </Button>
            </div>
          </ExpandContent>
        </Form>
      </div>
    );
  }

  toggleExpanded = () => this.setState((prev) => ({ expanded: !prev.expanded }));
  isOutsideRange = () => false;
  search = (searchParams) => this.props.search({
    ...searchParams,
    ...BULK_LOADS_DEFAULT_SORT,
    limit: get(searchParams, 'pageSize', BULK_LOADS_PAGE_SIZE),
    offset: 0,
    resultsRaw: {}
  });
}

export const BulkLoadsSearchForm = reduxForm({})(BulkLoadsSearchReduxForm);
BulkLoadsSearchForm.propTypes = {
  search: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  results: PropTypes.array,
};

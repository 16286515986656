export function validateAnalyticsLogsForm(form) {
  const {
    dates: {
      startDate,
      endDate,
    } = {},
    users = [],
    methods = [],
    divisions = [],
    departments = [],
    endpoint,
    requestParams,
    responseCode,
  } = form;

  return {
    users: !(
      (startDate && startDate.isValid()) ||
      (endDate && endDate.isValid()) ||
      users.length ||
      methods.length ||
      divisions.length ||
      departments.length ||
      endpoint ||
      responseCode ||
      requestParams
    ),
  };
}

import { createSelector } from 'reselect';

export const getSetManagementState = (state) => state.setManagement;
export const getSetManagementError = createSelector([getSetManagementState], (state) => state.error);
export const getSetManagementLoading = createSelector([getSetManagementState], (state) => state.loading);
export const getSetManagementBundlesRaw = createSelector([getSetManagementState], (state) => state.bundles);
export const getSetManagementProductsRaw = createSelector([getSetManagementState], (state) => state.products);
export const getSetManagementPricesRaw = createSelector([getSetManagementState], (state) => state.prices);
export const getSetManagementOriginal = createSelector([getSetManagementState], (state) => state.original);
export const getSetManagementDomains = createSelector([getSetManagementState], (state) => state.domains);
export const getSetManagementReductionTypes = createSelector([getSetManagementState], (state) => state.reductionTypes);

export const getSetManagementOriginalBundles = createSelector([
  getSetManagementOriginal,
], (original) => original ? original.bundles : []);
export const getSetManagementOriginalProducts = createSelector([
  getSetManagementOriginal,
], (original) => original ? original.products : []);
export const getSetManagementOriginalPrices = createSelector([
  getSetManagementOriginal,
], (original) => original ? original.prices : []);

import { workguideCodesRequest } from './WorkguideActions';

export const GET = 'adminUI/leadqualification/GET';
export const PUT = 'adminUI/leadqualification/PUT';
export const SET = 'adminUI/leadqualification/SET';
export const REQUEST = 'adminUI/leadqualification/REQUEST';
export const POST_ACTIONS_REQUEST = 'adminUI/leadqualification/POST_ACTIONS_REQUEST';

export const leadqualificationRequest = ({ leadqualification=false }={}) => {
  return { type: REQUEST, leadqualification };
}

export function codesRequest(group) {
  return dispatch => dispatch(workguideCodesRequest(group));
}

export const setData = ({ key, value }) => {
  return dispatch =>
    dispatch({
      type: SET,
      key,
      value,
    });
}

import { call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { REF_ERRORS_LOAD_ALL } from '../../actions/RefErrorsActions';
import { handleNextHeaders } from '../../utils';
import { convertDateToApi } from '../../utils/dateConverter';

function queryBuilder(options, chunkSize) {
  const {
    fromDate,
    toDate
  } = options;

  const rqlParts = [];

  if (fromDate && toDate) {
    if (fromDate && fromDate.isValid()) {
      rqlParts.push(`ge(loadedAt,${convertDateToApi(fromDate.startOf('date'))})`);
    }
    if (toDate && toDate.isValid()) {
      rqlParts.push(`le(loadedAt,${convertDateToApi(toDate.endOf('date'))})`);
    }
  }

  if (rqlParts.length > 1) {
    return `and(${rqlParts.join(',')})&limit(${chunkSize})`;
  } else {
    return `${rqlParts.join(',')}&limit(${chunkSize})`;
  }
}

export function* refErrorsLoadAllSaga({data}) {
  try {
    yield put(REF_ERRORS_LOAD_ALL.pending());
    const chunkSize = 5000;

    const query = queryBuilder(data, chunkSize)
    const results = yield call(handleNextHeaders, `${EndpointName.LOADER_REF_ERROR}/?${query}`);



    yield put(REF_ERRORS_LOAD_ALL.success({
      results
    }));
  } catch (error) {
    yield put(REF_ERRORS_LOAD_ALL.failure(error));
  }
}

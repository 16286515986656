import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getSetManagementError,
  getSetManagementLoading,
} from '../selectors/SetManagement/setManagementCommonSelectors';
import { SetManagement } from '../components/SetManagement/SetManagement';
import {
  SET_MANAGEMENT_CHANGE_ACTIONS,
  SET_MANAGEMENT_LOAD_ACTIONS,
  SET_MANAGEMENT_SAVE_ACTIONS,
} from '../actions/SetManagementActions';
import { getLanguage } from '../selectors/commonSelectors';
import { getSetManagementBundles } from '../selectors/SetManagement/setManagementBundleSelectors';
import { getSetManagementProducts } from '../selectors/SetManagement/setManagementProductSelectors';
import { getSetManagementPrices } from '../selectors/SetManagement/setManagementPricingSelectors';
import {
  getSetManagementBundleOptions,
  getSetManagementDomainOptions,
  getSetManagementProductOptions,
  getSetManagementReductionTypeOptions,
} from '../selectors/SetManagement/setManagementOptionsSelectors';

const mapStateToProps = createStructuredSelector({
  loading: getSetManagementLoading,
  error: getSetManagementError,
  locale: getLanguage,
  bundles: getSetManagementBundles,
  products: getSetManagementProducts,
  prices: getSetManagementPrices,
  domains: getSetManagementDomainOptions,
  reductionTypes: getSetManagementReductionTypeOptions,
  bundleOptions: getSetManagementBundleOptions,
  productOptions: getSetManagementProductOptions,
});

const mapDispatchToProps = {
  load: () => SET_MANAGEMENT_LOAD_ACTIONS.request(),
  change: ({ bundles, products, prices }) => SET_MANAGEMENT_CHANGE_ACTIONS.request({ bundles, products, prices }),
  save: () => SET_MANAGEMENT_SAVE_ACTIONS.request(),
};

export const SetManagementPage = connect(mapStateToProps, mapDispatchToProps)(SetManagement);

import './EmailSubscriptions.css';

import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { handleGravitonError } from '../../utils';
import PageContainer from '../Common/PageContainer';
import { EmailSubscriptionsTable } from './EmailSubscriptionsTable';
import {
  EMAIL_SUBSCRIPTIONS_PROP_TYPES_ITEM,
  EMAIL_SUBSCRIPTIONS_PROP_TYPES_SEARCH_PARAMS,
} from './EmailSubscriptionsPropTypes';
import { EmailSubscriptionsSearchForm } from './EmailSubscriptionsSearchForm';
import { EmailSubscriptionsUpload } from './EmailSubscriptionsUpload';
import EmailSubscriptionsExport from './Export/EmailSubscriptionsExport';
import Spinner from '../Spinner';

export class EmailSubscriptions extends React.Component {
  constructor(props) {
    super(props);
    props.load();
  }

  render() {
    const { error, loading, rawData } = this.props;
    const { search, searchParams, items, intl } = this.props;

    const { subscriptionTypeOptions, subscriptionStateOptions, deleteMarketing, deletedMarketing, onCsv, csvUploadResult, creator } = this.props;
    if (error) {
      toast.error(handleGravitonError(error));
    }
    if (csvUploadResult.loading) {
      return <Spinner />;
    }

    return (
      <PageContainer
        title={<FormattedMessage id="emailSubscriptions_title"/>}
        requesting={loading}
        isTransparentLoader={true}
      >
        <div className="email-subscriptions">
          <div className="email-subscriptions__search">
            <EmailSubscriptionsSearchForm
              form="emailSubscriptionsSearchForm"
              search={search}
              loading={loading}
              items={items}
              subscriptionTypeOptions={subscriptionTypeOptions}
              subscriptionStateOptions={subscriptionStateOptions}

            />
          </div>
          <div className="email-subscription-upload">
            <EmailSubscriptionsUpload
              intl={intl}
              deleteMarketing={deleteMarketing}
              deletedMarketing={deletedMarketing}
              onCsv={onCsv}
              csvUploadResult={csvUploadResult}
              creator={creator}
            />
          </div>

          <div className="email-subscription-export">
            <EmailSubscriptionsExport />
          </div>

          <div className="email-subscriptions__table">
            <EmailSubscriptionsTable
              rawData={rawData}
              items={items}
              loading={loading}
              search={search}
              searchParams={searchParams}
            />
          </div>
        </div>
      </PageContainer>
    );
  }
}

EmailSubscriptions.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.arrayOf(EMAIL_SUBSCRIPTIONS_PROP_TYPES_ITEM),
  search: PropTypes.func.isRequired,
  searchParams: EMAIL_SUBSCRIPTIONS_PROP_TYPES_SEARCH_PARAMS,
  subscriptionTypeOptions: PropTypes.arrayOf(PropTypes.object),
  subscriptionStateOptions: PropTypes.arrayOf(PropTypes.object),
  rawData: PropTypes.array
};

EmailSubscriptions.defaultProps = {
  rawData: []
}
import { takeLatest } from 'redux-saga/effects';
import {
  LEADS_SWITCH_IMPORT_CSV,
  LEADS_SWITCH_IMPORT_IMPORT,
} from '../../actions/LeadsSwitchImportActions';
import { leadsSwitchImportCsvSaga } from './leadsSwitchImportCsvSaga';
import { leadsSwitchImportImportSaga } from './leadsSwitchImportImportSaga';

export function* leadsSwitchImportSagas() {
  yield takeLatest(LEADS_SWITCH_IMPORT_CSV.REQUEST, leadsSwitchImportCsvSaga);
  yield takeLatest(LEADS_SWITCH_IMPORT_IMPORT.REQUEST, leadsSwitchImportImportSaga);
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ListGroup } from 'react-bootstrap';
import update from 'immutability-helper';

import ListItem from './ListItem';
import ActionForm from './Form';

class ActionList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      add: false
    };

    this.onActionChange = this.onActionChange.bind(this);
  }

  /**
   * Replace or add the given action
   *
   * @param  {Object} action     Action object
   * @param  {Number} [index=-1] Index
   *
   * @return void
   */
  onActionChange(action, index = -1) {
    const { onChange } = this.props;
    let updated = [ ...this.props.actions ];

    if (index > -1) {
      updated = update(updated, {
        $splice: [[index, 1, action]]
      });
    } else {
      updated = update(updated, {
        $push: [action]
      });
    }

    this.setState({ add: false });

    onChange(updated);
  }

  /**
   * Remove action of given type on the given index
   *
   * @param  {String} type  Type (if/else)
   * @param  {Number} index Index
   *
   * @return void
   */
  onActionRemove(index = -1) {
    const { actions, onChange } = this.props;

    if (index > -1) {
      const updated = update(actions, { $splice: [[index, 1]] });

      onChange(updated);
    }
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { add } = this.state;
    const { target, label, actions, codes, productGroups, consultants } = this.props;

    const children = actions.map((action, index) => (
      <ListItem
        key={index}
        target={target}
        action={action}
        codes={codes}
        consultants={consultants}
        productGroups={productGroups}
        onChange={(action) => this.onActionChange(action, index)}
        onRemove={() => this.onActionRemove(index)}
      />
    ));

    return(
      <React.Fragment>
        <div>
          <strong>{label}</strong>
          <span className="pull-right glyphicon glyphicon-plus" onClick={() => this.setState({ add: true })} />
        </div>
        <ListGroup>
          {children}
        </ListGroup>
        {(add) ? (
          <ActionForm
            target={target}
            onSubmit={(action) => this.onActionChange(action)}
            onCancel={() => this.setState({ add: false })}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

ActionList.propTypes = {
  target: PropTypes.object.isRequired,
  label: PropTypes.string,
  actions: PropTypes.array,
  codes: PropTypes.object,
  consultants: PropTypes.array,
  productGroups: PropTypes.array,
  onChange: PropTypes.func,
  onRemove: PropTypes.func
};

ActionList.defaultProps = {
  action: [],
  codes: {},
  consultants: [],
  productGroups: [],
  onChange: _.noop,
  onRemove: _.noop
};

function mapStateToProps(state, ownProps) {
  return {
    language: state.login.language,
    consultants: state.workguide.consultants,
    codeGroups: state.workguide.codeGroups,
    codes: state.workguide.codes,
    productGroups: state.workguide.productGroups
  };
}

export default connect(mapStateToProps)(ActionList);

import { takeLatest } from 'redux-saga/effects';
import { BULK_LOADS_LOAD, BULK_LOADS_REFRESH, BULK_LOADS_SEARCH } from '../../actions/BulkLoadsActions';
import { bulkLoadsLoadDataSaga } from './bulkLoadsLoadDataSaga';
import { bulkLoadsSearchSaga } from './bulkLoadsSearchSaga';
import { bulkLoadsRefreshSaga } from './bulkLoadsRefreshSaga';

export function* bulkLoadsSagas() {
  yield takeLatest(BULK_LOADS_LOAD.REQUEST, bulkLoadsLoadDataSaga);
  yield takeLatest(BULK_LOADS_SEARCH.REQUEST, bulkLoadsSearchSaga);
  yield takeLatest(BULK_LOADS_REFRESH.REQUEST, bulkLoadsRefreshSaga);
}

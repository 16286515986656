import './ExpandContent.css';

import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

export function ExpandContent({ className, expanded, children }) {
  return (
    <CSSTransition
      in={expanded}
      classNames="expand-content"
      appear={false}
      timeout={300}
      mountOnEnter={false}
      unmountOnExit={true}

      onExit={setContentHeight}
      onExiting={setZeroHeight}
      onExited={cleanupElement}

      onEnter={setZeroHeight}
      onEntering={setContentHeight}
      onEntered={cleanupElement}
    >
      <div className="expand-content">
        <div className={className}>{children}</div>
      </div>
    </CSSTransition>
  );
}

function setContentHeight(element) {
  const bodyHeight = element.firstElementChild.clientHeight;
  element.style.height = `${bodyHeight}px`;
}

function setZeroHeight(element) {
  element.style.height = "0";
}

function cleanupElement(element) {
  element.style.height = "";
}

ExpandContent.propTypes = {
  expanded: PropTypes.bool,
};

import { chainReducers } from '../utils';
import {
  LoadFinancingSurchargeRealestatetypeReducer,
  SaveFinancingSurchargeRealestatetypeReducer,
} from './FinancingSurchargeRealestatetype';

export const FinancingSurchargeRealestatetypeReducer = chainReducers(
  LoadFinancingSurchargeRealestatetypeReducer,
  SaveFinancingSurchargeRealestatetypeReducer,
);

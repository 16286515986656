import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Col, Row, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { NoticeImportFileList } from './NoticeImportFileList';
import {
  REDUCER_STATUS_FULFILLED, REDUCER_STATUS_PENDING, REDUCER_STATUS_PROGRESS,
} from '../../constants/ReducerStatuses';
import { NOTICE_EXAMPLE_TEMPLATE, NOTICE_EXAMPLE_DOCUMENT } from '../../constants/NoticeImport';
import PageContainer from '../Common/PageContainer';
import { NoticeImportProgress } from './NoticeImportProgress';
import Spinner from '../Spinner';
import './NoticeImport.css';
import { get } from 'lodash';

class NoticeImport extends React.Component {
  constructor(props) {
    super(props);

    this.onDrop = this.onDrop.bind(this);
    this.onImport = this.onImport.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.getImportResultOfPostFile = this.getImportResultOfPostFile.bind(this);
    this.displayImportButton = this.displayImportButton.bind(this);
    this.props.examplesLoad();
    this.props.fileListLoad();
  }

  componentWillReceiveProps(nextProps) {
    const {
      filePostResult,
      validationResult,
      importResult,
      deleteResult,
    } = nextProps;

    if (
      (importResult.status === REDUCER_STATUS_FULFILLED
      && importResult.status !== this.props.importResult.status)
        ||
      (deleteResult.status === REDUCER_STATUS_FULFILLED
      && deleteResult.status !== this.props.deleteResult.status)
        ||
      (validationResult.status === REDUCER_STATUS_FULFILLED
      && validationResult.status !== this.props.validationResult.status)
    ) {
      this.props.fileListLoad();
    }

    if (filePostResult.status === REDUCER_STATUS_FULFILLED
      && filePostResult.status !== this.props.filePostResult.status
    ) {
      this.props.validation(filePostResult.results);
    }
  }

  onDrop(acceptedFiles, rejectedFiles) {
    const regex = new RegExp(/[^a-z_\-0-9.]/i);
    const { filePost, user } = this.props;

    acceptedFiles.forEach((file) => {
      if (regex.test(file.name)) {
        toast.error(`${file.name} wurde nicht akzeptiert`, { autoClose: 4000 });
      } else {
        filePost(file, user);
        toast.info(`${file.name} wurde akzeptiert`, { autoClose: 2000 });
      }
    });
    if (rejectedFiles) {
      this.displayErrors(rejectedFiles);
    }
  }

  onImport() {
    const { filePostResult, userId } = this.props;
    this.props.import({...filePostResult.results, userId });
  }

  onDelete(fileId) {
    const { user } = this.props;
    this.props.delete(user, fileId);
  }

  getImportResultOfPostFile() {
    const { filePostResult, importResult } = this.props;
    let importResultOfPostFile = null;
    if (
      filePostResult.status === REDUCER_STATUS_FULFILLED
      && importResult.status
    ) {
      const fileId = filePostResult.results.fileId;
      if (importResult.fileId === fileId) {
        importResultOfPostFile = { ...importResult };
      }
    }
    return importResultOfPostFile;
  }

  displayErrors(rejectedFiles) {
    rejectedFiles.forEach((file) => {
      toast.error(
        `${file.name} hat den falschen Dateityp. Es werden nur CSV-Dateien akzeptiert`,
        { autoClose: 10000, position: toast.POSITION.TOP_CENTER },
      );
    });
  }

  displayImportButton(importResultOfPostFile) {
    const {
      validationResult,
      filePostResult,
      deleteResult,
      importResult,
    } = this.props;

    return (validationResult.status
      && validationResult.status === REDUCER_STATUS_FULFILLED
      && !importResultOfPostFile
      && (filePostResult && filePostResult.status === REDUCER_STATUS_FULFILLED)
      &&
      (
        (!deleteResult || (
          deleteResult.status !== REDUCER_STATUS_PENDING
          && deleteResult.status !== REDUCER_STATUS_PROGRESS
        ))
        &&
        (!importResult || (
          importResult.status !== REDUCER_STATUS_PENDING
          && importResult.status !== REDUCER_STATUS_PROGRESS
        ))
      )
    );
  }

  render() {
    const {
      fileListResult,
      validationResult,
      importResult,
      deleteResult,
      filePostResult,
      examplesResult,
    } = this.props;

    let noticeExampleTemplate = null;
    let noticeExampleDocument = null;
    if (examplesResult && examplesResult.results) {
      noticeExampleTemplate = examplesResult.results[NOTICE_EXAMPLE_TEMPLATE] || null;
      noticeExampleDocument = examplesResult.results[NOTICE_EXAMPLE_DOCUMENT] || null;
    }

    const importResultOfPostFile = this.getImportResultOfPostFile();

    return (
      <PageContainer title="Upload Notizen" requesting={false}>
        <div className="notice-import">

          <div className="notice-import__description">
            <FormattedMessage id="noticeImport_description" />{'\u00A0'}{'\u00A0'}{'\u00A0'}

            {noticeExampleTemplate &&
            <a
              href={URL.createObjectURL(noticeExampleTemplate.blob.data)}
              download={noticeExampleTemplate.metadata.filename}>
              <FormattedMessage id="noticeImport_link-template"/>
            </a>
            }
            {'\u00A0'}{'\u00A0'}{'\u00A0'}
            {noticeExampleDocument &&
            <a
              href={URL.createObjectURL(noticeExampleDocument.blob.data)}
              download={noticeExampleDocument.metadata.filename}>
              <FormattedMessage id="noticeImport_link-document"/>
            </a>
            }
          </div>

          <Row>
            <Col sm={6}>
              <div>CSV Datei auswählen</div>

              <Dropzone
                accept=".csv"
                onDrop={this.onDrop}
                multiple={false}
              >
                <h1 className="text-center">
                  <i className="icon-088-upload-cloud" />
                </h1>
                <p className="text-center">
                  Neue Dateien hier her ziehen oder klicken um sie auszuwählen
                </p>
              </Dropzone>
            </Col>
            <Col sm={6}>
              <Row>
                <Col>

                  {filePostResult.error &&
                    <div>Error: {get(filePostResult, 'error.response.status')} - {get(filePostResult, 'error.response.data.message')}</div>
                  }

                  {filePostResult.loading &&
                  <div className="notice-import__upload">Datei hochladen<Spinner text={false} screen={false} /></div>
                  }

                  {validationResult.loading &&
                  <div className="notice-import__validation">Validierung<Spinner text={false} screen={false} /></div>
                  }

                  <NoticeImportProgress progressResult={importResultOfPostFile} />

                  {(importResultOfPostFile && importResultOfPostFile.status === REDUCER_STATUS_FULFILLED) &&
                  <div>
                    <FormattedMessage id={`noticeImport_status_imported`} /> {importResultOfPostFile.results.success}
                    {'\u00A0'}<FormattedMessage id={`noticeImport_status_from`} /> {importResultOfPostFile.results.total}
                    {importResultOfPostFile.results.fail > 0 &&
                    <span> (<FormattedMessage id={`noticeImport_status_fail`} /> {importResultOfPostFile.results.fail})</span>
                    }
                  </div>
                  }

                  {this.displayImportButton(importResultOfPostFile) &&
                  <div>
                    Prüfung der Datei erfolgreich
                    <Button className="notice-import__button" onClick={this.onImport}>
                      Importieren
                    </Button>
                  </div>
                  }

                  {(validationResult.errors && !filePostResult.loading) &&
                  <div>
                    <div>Datei konnte nicht validiert werden</div>
                    {validationResult.errors.map((error, i) => (
                      <div key={i}>
                        Zeile {error.lineNumber}: Fehler im Feld '{error.fieldName}'
                        - <FormattedMessage id={`noticeImport_validation_${error.errorText}`} />
                      </div>
                    ))}
                  </div>
                  }
                </Col>
              </Row>
            </Col>
          </Row>

          <NoticeImportFileList
            fileListResult={fileListResult}
            importResult={importResult}
            deleteResult={deleteResult}
            onDelete={this.onDelete}
          />

        </div>
      </PageContainer>
    );
  }
}

NoticeImport.propTypes = {
  deleteResult: PropTypes.object,
  examplesResult: PropTypes.object,
  fileListResult: PropTypes.object,
  filePostResult: PropTypes.object,
  importResult: PropTypes.object,
  user: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  validationResult: PropTypes.object
};

NoticeImport.defaultProps = {
  deleteResult: {},
  examplesResult: {},
  fileListResult: {},
  filePostResult: {},
  importResult: {},
  validationResult: {}
};

export default NoticeImport;

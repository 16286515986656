import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { escapeRegExp, get, isUndefined } from 'lodash';
import { all, call, put } from 'redux-saga/effects';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { handleGravitonError } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';
import { ANALYTICS_USER_SUMMARY_EXPORT } from '../../actions/AnalyticsUserSummaryActions';

export function* analyticsUserSummaryExportSaga(action) {
  try {
    yield put(ANALYTICS_USER_SUMMARY_EXPORT.pending());

    const selectedBank = action.data;
    const webDomainRegExp = selectedBank ? `^${escapeRegExp(selectedBank.webDomain)}$` : undefined;

    const { data: entries } = yield call(axios.get, EndpointName.ANALYTICS_USER_SUMMARY, {
      params: {
        limit: 999999,
        webDomain: webDomainRegExp,
      },
    });

    const { data: codes } = yield call(axios, {
      url: '/entity/code/?eq(group,string:userGroup)',
      method: 'get'
    });


    // Extract consultant IDs from entries
    const consultantIds = entries.map((entry) => entry.upstreamUsername);
        // Create chunks of consultant IDs
        const chunkSize = 10;
        const chunks = [];
        for (let i = 0; i < consultantIds.length; i += chunkSize) {
          chunks.push(consultantIds.slice(i, i + chunkSize));
        }
    // Fetch consultants in chunks
    const result = yield all(
      chunks.map(
        (chunk) =>
          new Promise((resolve, reject) => {
            axios({
              url: `/person/consultant/?in(username,(${chunk.join(',')}))`,
              method: 'get'
            })
              .then((response) => {
                const res = get(response, 'data', []).map(el => {
                  return { permission: el.permission, ...entries.find(entry => entry.usernameBackend === el.username)}
                })
                resolve(res);
              })
              .catch((error) => {
                console.error(error);
                reject(error);
              });
          })
      )
    );

    if (entries.length > 0){
      yield call(exportUserSummaryData, selectedBank, result.flat(), codes);
    } else {
      yield call(toast.warn, <FormattedMessage id="analyticsUserSummary_noData"/>);
    }

    yield put(ANALYTICS_USER_SUMMARY_EXPORT.success());
  } catch (error) {
    yield put(ANALYTICS_USER_SUMMARY_EXPORT.failure(error));

    yield call(toast.error, yield call(handleGravitonError, error));
  }
}

function* exportUserSummaryData(bank, entries, codes = []) {
  const header = [
    'Benutzername Portal',
    'Benutzername Clan',
    'Administrator',
    'Aktiviert',
    'Aktivierungsänderung am',
    'Freigegeben',
    'Freigabeänderung am',
    'Letztes Login',
    'Berechtigungsgruppe'
  ].join(';');
  const rows = entries.map((entry) => [
    formatCsvString(entry.username),
    formatCsvString(entry.usernameBackend),
    formatCsvBoolean(entry.isAdmin),
    formatCsvBoolean(entry.isActivated),
    formatCsvDatetime(entry.activatedChangeAt),
    formatCsvBoolean(entry.isApproved),
    formatCsvDatetime(entry.approvedChangeAt),
    formatCsvDatetime(entry.lastLogin),
    formatCsvUserGroups(get(entry, 'permission', []), codes)
  ].join(';'));

  const content = new Blob(['\ufeff' + [header, ...rows].join('\n')], { type: 'text/csv;charset=utf-8' });
  const filename = bank
    ? `${bank.webDomain}_user_summary_export_${moment().format('DD.MM.YYYY_HH-mm-ss')}.csv`
    : `user_summary_export_${moment().format('DD.MM.YYYY_HH-mm-ss')}.csv`;

  yield call(saveAs, content, filename);
}

function formatCsvString(value) {
  return typeof value === 'string' ? `"${value.replace('"', '""')}"` : '';
}

function formatCsvBoolean(value) {
  return typeof value === 'boolean' ? value ? 'Ja' : 'Nein' : '';
}

function formatCsvDatetime(value) {
  return value ? moment(value).format('DD.MM.YYYY HH:mm:ss') : '';
}

function formatCsvUserGroups(value = [], codes = []) {
  return value.map((group) => {
    const code = codes.find((c) => get(c, 'number') === group);

    return !isUndefined(code) ? get(code, 'text.de') : group;
  }).join(', ');
}

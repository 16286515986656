import update from 'immutability-helper';
import _ from 'lodash';
import {
  GET,
  GET_CONSULTANTS,
  PUT,
  SET_TRANSFER,
  SET_TARGET
} from '../actions/LeadSwitch';

const initialState = ({
  consultants: [],
  leads: [],
  staffMemberIdFrom: "",
  staffMemberIdTo: "",
  requesting: false,
  progressIndex: 0,
  progressTotal: 0,
  inProgress: false,
  error: {},
  hasError: false,
});
export default function LeadSwitchReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TRANSFER: {
      const leadIndex = _.findIndex(state.leads, {'id': action.leadId})
      const leads = update(state.leads, {[leadIndex]: { transfer: {$set: action.transferState}}})
      return { ...state, leads };
    }
    case SET_TARGET: {
      return { ...state, staffMemberIdTo: action.staffMemberId }
    }
    case `${GET}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${GET}_FULFILLED`: {
      const leads = action.data.map(o => Object.assign({},o,{transfer:true}));
      return { ...state, requesting: false, leads: leads,  staffMemberIdFrom: action.staffMemberId  };
    }
    case `${GET}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case `${GET_CONSULTANTS}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${GET_CONSULTANTS}_FULFILLED`: {
      return { ...state, requesting: false, consultants: action.data };
    }
    case `${GET_CONSULTANTS}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }

    case `${PUT}_PENDING`: {
      return { ...state, hasError: false, inProgress: true };
    }
    case `${PUT}_PROGRESS`: {
      return { ...state, progressIndex: _.get(action, 'index'), progressTotal: _.get(action, 'total') }
    }
    case `${PUT}_FULFILLED`: {
      let leadIndex = 0, newLeads = state.leads;

      for (let i=0; i<action.leadIds.length; i++) {
        leadIndex = _.findIndex(newLeads, {'id': action.leadIds[i]});
        newLeads = update(newLeads, {$splice: [[leadIndex, 1]]});
      }
      return { ...state, leads: newLeads.map(o => Object.assign({},o,{transfer:true})), progressIndex: 0, progressTotal: 0, inProgress: false };
    }
    case `${PUT}_REJECTED`: {
      return {
        ...state, requesting: false, progressIndex: 0, progressTotal: 0, inProgress: false, hasError: true, error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

import axios from 'axios';
import { all, call, put, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { REF_ERRORS_LOAD } from '../../actions/RefErrorsActions';
import { REF_ERRORS_TEXT_BLOCKS_MAP } 
from '../../constants/RefErrorsConstants';
import { getRefErrorsListId, getRefErrorsSearchParams } from '../../selectors/RefErrors/refErrorsCommonSelectors';
import { createSearchQuery } from './createSearchQuery';

export function* refErrorsLoadDataSaga() {
  try {
    yield put(REF_ERRORS_LOAD.pending());
    const textBlocksIds = Object.values(REF_ERRORS_TEXT_BLOCKS_MAP);
    const refErrorsId = yield select(getRefErrorsListId);
    const searchParams = yield select(getRefErrorsSearchParams);
    const searchQuery = `${EndpointName.LOADER_REF_ERROR}/?${createSearchQuery(searchParams)}`;
    const refErrorsRequest = searchQuery + (refErrorsId ? `&eq(id,string:${encodeURIComponent(refErrorsId)})` : '');

    const [
      { data: textBlocks },
      { data: refErrors, headers },
    ] = yield all([
      call(axios, { url: `${EndpointName.CORE_TEXT_BLOCK}/?in(id,(${textBlocksIds.join(',')}))` }),
      call(axios, { url: refErrorsRequest }),
]);

    const totalCount = headers['x-total-count']
      ? parseInt(headers['x-total-count'], 10)
      : refErrors.length;

    yield put(REF_ERRORS_LOAD.success({
      textBlocks,
      results: {
        refErrors,
        totalCount,
      },
    }));
  } catch (error) {
    yield put(REF_ERRORS_LOAD.failure(error));
  }
}

import moment from 'moment';
import { FILE_EXTENSIONS } from '../../constants/FileExtensions';

export function saveAsFile(rawData, contentType) {
  const extension = FILE_EXTENSIONS[contentType]
    ? '.' + FILE_EXTENSIONS[contentType]
    : '';
  const fileName = `file-${moment().format('YYYY-MM-DD_HH-mm')}${extension}`;
  const data = contentType === 'application/json'
    ? JSON.stringify(rawData, null, 2)
    : rawData;
  window.saveAs(
    new Blob([data], { type: contentType }),
    fileName,
  );
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { diffChars, diffWords, diffWordsWithSpace, diffLines, diffTrimmedLines, diffSentences, diffCss, diffJson } from 'diff';

var fnMap = {
    'chars': diffChars,
    'words': diffWords,
    'wordsWithSpace': diffWordsWithSpace,
    'lines': diffLines,
    'trimmedLines': diffTrimmedLines,
    'sentences': diffSentences,
    'css': diffCss,
    'json': diffJson
};

export default class Diff extends Component {
  render() {
    const diff = fnMap[this.props.type](this.props.inputA, this.props.inputB);
    const result = diff.map((part, index) => {
      const spanStyle = {
        backgroundColor: part.added ? 'lightgreen' : part.removed ? 'salmon' : 'inherit'
      };
      return (
        <span key={index} style={spanStyle}>{part.value}</span>
      );
    });
    return (
      <pre className="diff-result">{result}</pre>
    );
  }
};

Diff.propTypes = {
  inputA: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inputB: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf(['chars', 'words', 'sentences', 'json']),
}

Diff.defaultProps = {
  inputA: '',
  inputB: '',
  type: 'chars',
}

import React from 'react';
import { InputGroup, Row, Col, Panel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import EditForm from './Form';

export default class ListItem extends React.Component {
  constructor() {
    super();
    this.state = {
      collapsed: true
    }
  }

  /**
   * Open panel if its a new item
   *
   * @return {ListItem} this This instance
   */
  componentWillMount() {
    // Automatically open panel when a new item was added
    const { isNew } = this.props.item;
    this.setState({ collapsed: !isNew });

    return this;
  }

  /**
   * Show / Hide the form
   *
   * @return {ListItem} this This instance
   */
  toggle() {
    this.setState({
      collapsed: !this.state.collapsed
    });

    return this;
  }

  /**
   * Render a single list item
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      item, durations, useTypes, loanProducts, language, onItemChange, onItemRemove, onItemDuplicate,
    } = this.props;
    const glyph = this.state.collapsed ? 'down' : 'up';
    const invalid = (item.changed && item.validation && !item.validation.valid);

    return(
      <div>
        <div className="SortableHOCItem">
          <InputGroup style={{flexGrow: 1}}>
            { invalid ? <InputGroup.Addon style={{ color: '#a94442' }}><span><i className="glyphicon glyphicon-alert"></i></span></InputGroup.Addon> : '' }
            <div className="form-control" style={{ backgroundColor: '#eef3f6', fontWeight: 'bold', fontColor: '#5c5c5c' }} onClick={ this.toggle.bind(this) }>
              <Row>
                <Col sm={6}>{item.id}</Col>
                <Col sm={2}>Rate: {item.rate}</Col>
                <Col sm={2}>Start: {item.lendingRateStart}</Col>
                <Col sm={2}>Ende: {item.lendingRateEnd}</Col>
              </Row>
            </div>
            <InputGroup.Addon >
              <span onClick={ this.toggle.bind(this, item) }>
                <i className={ `glyphicon glyphicon-chevron-${glyph}` } />
              </span>
              <OverlayTrigger placement="top" overlay={<Tooltip id="duplicate">Duplizieren</Tooltip>}>
                <span onClick={() => onItemDuplicate(item)}>
                  <i className="glyphicon glyphicon-copy" />
                </span>
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={<Tooltip id="delete">Löschen</Tooltip>}>
                <span onClick={onItemRemove.bind(this, item)}>
                  <i className="icon-008-bin" />
                </span>
              </OverlayTrigger>
            </InputGroup.Addon>
          </InputGroup>
        </div>
        <Panel
          expanded={ !this.state.collapsed }
          onToggle={this.toggle}
          style={{ marginBottom: '0px' }}
        >
          <Panel.Collapse>
            <Panel.Body>
              <EditForm
                durations={durations}
                useTypes={useTypes}
                loanProducts={loanProducts}
                language={language}
                item={item}
                onItemChange={onItemChange}>
              </EditForm>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </div>
    );
  }
}

import axios from 'axios';
import { all, call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { ANALYTICS_LOGS_LOAD } from '../../actions/AnalyticsLogsActions';
import { removeHost } from '../../utils';

const ANALYTICS_TEXT_BLOCK_ID = 'analyticsLogs';

export function* analyticsLogsLoadSaga() {
  try {
    yield put(ANALYTICS_LOGS_LOAD.pending());

    const [
      { data: consultants },
      { data: textBlocks },
      { data: grvServices },
    ] = yield all([
      call(axios, { url: `${EndpointName.PERSON_CONSULTANT}/?limit(999999)` }),
      call(axios, { url: `${EndpointName.CORE_TEXT_BLOCK}/?eq(id,${ANALYTICS_TEXT_BLOCK_ID})` }),
      call(axios, { url: `/` }),
    ]);

    yield put(ANALYTICS_LOGS_LOAD.success({
      consultants,
      textBlock: textBlocks[0],
      endpoints: grvServices.services.map((it) => mapEndpoint(it)),
    }));
  } catch (error) {
    yield put(ANALYTICS_LOGS_LOAD.failure(error));
  }
}

function mapEndpoint(endpoint) {
  return {
    url: removeHost(endpoint.$ref),
    schema: removeHost(endpoint.profile),
  }
}

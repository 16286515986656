import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { TextInput, ValidationResult } from '@evoja-web/react-form';

import Label from './Label';

const FormFieldText = React.memo(function FormFieldText({
  id,
  disabled,
  onChange,
  showLabel,
  validations,
  value
}) {
  return (
    <div className="workflow-tree-form-element">
      {showLabel && (
        <Label id={id} />
      )}

      <div className="workflow-tree-form-element--component">
        <TextInput
          id={id}
          disabled={disabled}
          name={id}
          onChange={onChange}
          value={value}
        />
      </div>

      <ValidationResult
        show
        validations={validations}
      />
    </div>
  );
});

FormFieldText.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  showLabel: PropTypes.bool,
  validations: PropTypes.object,
  value: PropTypes.string
};

FormFieldText.defaultProps = {
  id: 'text',
  disabled: false,
  onChange: noop,
  showLabel: true,
  validations: {},
  value: undefined
};

export default FormFieldText;

import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

export class UiNotesImagePreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: props.file,
      url: props.file
        ? URL.createObjectURL(props.file)
        : null,
    };
  }

  componentWillUnmount() {
    const { url } = this.state;
    if (this.state.url) {
      URL.revokeObjectURL(url);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.file === prevState.file) {
      return null;
    }

    if (prevState.url) {
      URL.revokeObjectURL(prevState.url);
    }

    return {
      file: nextProps.file,
      url: nextProps.file
        ? URL.createObjectURL(nextProps.file)
        : null,
    };
  }

  render() {
    const { url } = this.state;

    return <Image src={url}/>;
  }
}

UiNotesImagePreview.propTypes = {
  file: PropTypes.instanceOf(Blob).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { chain, get } from 'lodash';
import { Virtuoso } from 'react-virtuoso';
import { Row, Col, FormGroup, FormControl, InputGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from '@evoja-web/react-form';

import './List.css';
import { TRANSFER_ITEM_STATUS_REJECTED } from '../../constants';
import Loader from '../Common/Loader';
import ListItem from './Item';
import getItemStatus from '../../lib/Utils/getItemStatus';
import paramTransferActions from '../../actions/Actions';
import filterSourceItemsBySearchTerm from '../../lib/Utils/filterSourceItemsBySearchTerm';
import filterSourceItemsByStatus from '../../lib/Utils/filterSourceItemsByStatus';

class ParamTransferSourceItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: undefined,
      itemStatus: undefined
    };

    this.onInit();

    this.onSearchTermChange = this.onSearchTermChange.bind(this);
    this.onToggleRejectedItems = this.onToggleRejectedItems.bind(this);
    this.onTransferItem = this.onTransferItem.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  /**
   * Load source items if not already done
   *
   * @return  void
   */
  onInit() {
    const {
      items,
      module,
      paramTransferActions,
      source
    } = this.props;

    if (!get(items, 'fulfilled', false) && !get(items, 'requesting', false)) {
      paramTransferActions.sourceItemsRequest({
        dataKey: module.key,
        module,
        source
      });
    }
  }

  /**
   * Transfer a single item
   *
   * @param   {Object}  item  Source item
   *
   * @return  void
   */
  onTransferItem({ item }) {
    const {
      paramTransferActions,
      source,
      target
    } = this.props;

    paramTransferActions.transferItemRequest({
      dataKey: item.dataKey,
      item,
      source,
      target
    });
  }

  /**
   * Handle search term input value change
   *
   * @param   {Event}  ev  Change event
   *
   * @return  void
   */
  onSearchTermChange(ev) {
    const value = get(ev, 'target.value', '');

    const searchTerm = value.trim().length > 0
      ? value
      : undefined;

    this.setState({ searchTerm });
  }

  /**
   * Toggle item status filter rejected
   *
   * @return  void
   */
  onToggleRejectedItems() {
    const { itemStatus } = this.state;

    const updated = itemStatus === TRANSFER_ITEM_STATUS_REJECTED
      ? undefined
      : TRANSFER_ITEM_STATUS_REJECTED;

    this.setState({ itemStatus: updated });
  }

  /**
   * Get an array of items with the corresponsing status
   *
   * @return  {Array} items Array of items
   */
  getItems() {
    const {
      searchTerm,
      itemStatus
    } = this.state;
    const {
      items,
      transferItem,
      language
    } = this.props;

    const filtered = chain(items)
      .get('data', [])
      .map((item) => {
        const ti = get(transferItem, `${item.dataKey}`);

        return {
          ...item,
          status: getItemStatus({ item: ti }),
          error: get(ti, 'error')
        };
      })
      .thru((items) => filterSourceItemsByStatus({ items, status: itemStatus }))
      .thru((items) => filterSourceItemsBySearchTerm({ items, searchTerm, language }))
      .value();

    return filtered;
  }

  /**
   * Render a single list item (virtuoso callback)
   *
   * @param   {Virtuoso}  _   Something virtuoso
   * @param   {[type]}  item  Item data
   *
   * @return {ReactElement} markup
   */
  renderItem(_, item) {
    const { language } = this.props;

    return (
      <ListItem
        language={language}
        item={item}
        onTransferItem={this.onTransferItem}
      />
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      searchTerm,
      itemStatus
    } = this.state;
    const { requesting } = this.props;

    if (requesting) {
      return (
        <Loader />
      );
    }

    return (
      <div className="param-transfer-source-items">
        <Row className="param-transfer-source-items--search-row">
          <Col
            className="param-transfer-source-items--search-row--col-search-term"
            lg={8}
            md={8}
          >
            <FormGroup>
              <InputGroup>
                <InputGroup.Addon>
                  <span className="mdi mdi-magnify" />
                </InputGroup.Addon>
                <FormControl
                  id="searchTerm"
                  type="text"
                  onChange={this.onSearchTermChange}
                  value={searchTerm || ''}
                />
              </InputGroup>
            </FormGroup>
          </Col>

          <Col
            className="param-transfer-source-items--search-row--col-status"
            lg={4}
            md={4}
          >
            <FormGroup>
              <Checkbox
                id="onlyRejected"
                onChange={this.onToggleRejectedItems}
                value={itemStatus === TRANSFER_ITEM_STATUS_REJECTED}
              >
                <FormattedMessage id="ParamTransfer.Source.Filter.OnlyRejected" />
              </Checkbox>
            </FormGroup>
          </Col>
        </Row>

        <Row className="param-transfer-source-items--header-row">
          <Col lg={3} md={3}>
            <FormattedMessage id="ParamTransfer.Source.Item.Id" />
          </Col>

          <Col lg={2} md={2}>
            <FormattedMessage id="ParamTransfer.Source.Item.Service" />
          </Col>

          <Col lg={4} md={4}>
            <FormattedMessage id="ParamTransfer.Source.Item.Name" />
          </Col>

          <Col lg={2} md={2}>
            <FormattedMessage id="ParamTransfer.Source.Item.Type" />
          </Col>

          <Col lg={1} md={1}>
            <FormattedMessage id="ParamTransfer.Source.Item.Sync" />
          </Col>
        </Row>

        <Virtuoso
          className="param-transfer-source-items--virtuoso"
          style={{ height: '400px', overflowX: 'hidden' }}
          data={this.getItems()}
          itemContent={this.renderItem}
        />
      </div>
    );
  }
}

ParamTransferSourceItems.propTypes = {
  paramTransferActions: PropTypes.object.isRequired,
  items: PropTypes.object,
  language: PropTypes.string,
  module: PropTypes.object.isRequired,
  requesting: PropTypes.bool,
  source: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
  transferItem: PropTypes.object
};

ParamTransferSourceItems.defaultProps = {
  language: 'de',
  items: {},
  requesting: false,
  transferItem: {}
};

function mapStateToProps(state, ownProps) {
  const { module } = ownProps;

  const items = get(state, `paramTransfer.sourceItems.${module.key}`, {});

  return {
    items,
    language: state.login.language,
    requesting: get(items, 'requesting'),
    source: state.paramTransfer.source,
    target: state.paramTransfer.target,
    transferItem: state.paramTransfer.transferItem
  };
}

function mapDispatchToProps(dispatch) {
  return {
    paramTransferActions: bindActionCreators(paramTransferActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ParamTransferSourceItems);

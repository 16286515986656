import jmespath from '@metrichor/jmespath-plus';

import { convertValue } from './convert';

export default function jmesConditionLe(config, data) {
  const { path, type, value } = config;

  const values = convertValue(value, type);
  const resolved = jmespath.search(data, path);
  const converted = convertValue(resolved, type);

  return type === 'date'
    ? values.isSameOrBefore(converted)
    : values <= converted;
}

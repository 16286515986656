import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { FormSelect, FormDateTime } from '../FormControls';
import Spinner from '../Spinner';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { Button } from 'react-bootstrap';
import moment from 'moment';

import './TriggerJobs.css';

const TriggerJobs = ({
  definitions,
  jobProcessingActions,
  triggerJob,
  handleSubmit
}) => {
  const [selectedJob, setJob] = useState();

  const possibleJobs = ['updateUZVLead', 'syncActivitySubStatusCodes', 'updateWorkbench', 'ReconciliationActivities', 'ReconciliationBPF', 'reconciliationClanWorkguides', 'reconciliationActivities', 'reconciliationBpf', 'reconciliationSignedDocuments'];

  const jobOptions = get(definitions, 'data', [])
    .filter((code) => possibleJobs.includes(get(code, 'id')))
    .map((job) => ({ label: get(job, `type`, ''), value: get(job, 'id', '') }));

    const renderDateTimePicker = ({ input: { onChange, value }, showTime }) => {
      return <DatePicker
            inputClass="date-picker-replay"
            containerStyle={{
              width: '75%',
            }}
            value={value}
            onChange={onChange}
            format="MM/DD/YYYY HH:mm:ss"
            placeholder="Repay dates"
            multiple
            required
            plugins={[<TimePicker position="bottom" />, <DatePanel markFocused />]}
          />
    }



  const onSubmit = (formData) => {
    const { executionTime, jobSelection } = formData;
    const executionTimeCron = moment(executionTime).format('YYYY-MM-DDTHH:mm:ssZZ');    
    const formattedReplayDates = get(formData, 'replayDates', []).map(date => moment(date.format()).format('YYYY-MM-DDTHH:mm:ssZZ'));

    jobProcessingActions.triggerJobRequest({
      replayDates: formattedReplayDates,
      executionTime: executionTimeCron,
      selectedJob: jobSelection
    });
  };

  if (get(triggerJob, 'requesting', false)) {
    return <Spinner />;
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} key="form">
        <h2>
          <FormattedMessage id="jobProcessing_filter" />
        </h2>
        <Field
          component={FormSelect}
          size="large"
          label={<FormattedMessage id="jobProcessing_job" />}
          labelSize={3}
          id="jobSelection"
          name="jobSelection"
          clearable={true}
          options={jobOptions}
          onChange={(e, value) => setJob(value)}
        />

        <Field component={FormDateTime} label={'Execution time'} labelSize={3} name="executionTime" placeholder="Execution time" />

        {selectedJob === 'updateUZVLead' && (
          <div className="date-create-form__date-item">
            <label className="replay-dates-label">Replay Dates</label>

          <Field
          component={renderDateTimePicker}
          size="large"
          label={<FormattedMessage id="jobProcessing_job" />}
          labelSize={3}
          id="replayDates"
          name="replayDates"
          clearable={true}
        />

          </div>
        )}

        <Button bsStyle="primary" type="submit">
          Execute
        </Button>

        {get(triggerJob, 'error') instanceof Error && (
          <p style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>
            {get(triggerJob, 'error.message', '')}
          </p>
        )}
      </Form>
    </div>
  );
};

TriggerJobs.propTypes = {
  definitions: PropTypes.object,
  jobProcessingActions: PropTypes.object.isRequired,
  triggerJob: PropTypes.object
};

TriggerJobs.defaultProps = {
  definitions: {},
  triggerJob: {}
}

export default reduxForm({ validate: triggerJobsFromValidation, form: 'triggerJobsForm' })(TriggerJobs);

function triggerJobsFromValidation(formData) {
  return {
    jobSelection: !formData.jobSelection,
    executionTime: !formData.executionTime,
    replayDates: !formData.replayDates,
  };
}

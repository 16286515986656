import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _ from 'lodash';
import { FormControl, FormGroup, Button } from 'react-bootstrap';

import EditContainer from '../components/EditContainer';
import * as interestActions from '../actions/InterestActions';
import InterestItem from '../components/Interest/InterestItem';
import Select from 'react-select';
import update from 'immutability-helper';
class Interests extends React.Component {

  constructor() {
    super();
    this.updateService = _.debounce(this.updateService, 200);
    this.onValueChange = this.onValueChange.bind(this);
    this.onCreateGroup = this.onCreateGroup.bind(this);
    this.onCreateCategory = this.onCreateCategory.bind(this);
    this.onInterestChange = this.onInterestChange.bind(this);
    this.onDeleteInterest = this.onDeleteInterest.bind(this);
    this.toggleCreateCategoryView = this.toggleCreateCategoryView.bind(this);

    this.state = {
      selectedInterest: {
        de: undefined,
        fr: undefined
      },
      mainCategoryName: {
        de: undefined,
        fr: undefined
      },
      subCategoryName: {
        de: undefined,
        fr: undefined
      },
      createCategoryView: false
    }
  }

  componentWillMount() {
    this.props.actions.getInterestsRequest();
  }

  toggleCreateCategoryView() {
    const { createCategoryView } = this.state;
    this.setState({ createCategoryView: !createCategoryView })
  }

  onValueChange(e, language) {

    const updated = update(this.state, {
      [e.target.name]: {
        [language]: { $set: e.target.value }
      }
    });

    this.setState(updated);

  }

  onInterestChange(label, value, language) {
    const updated = update(this.state, {
      selectedInterest: {
        [language]: { $set: label },
        value: { $set: value }
      }
    });

    if (_.isNull(label) || _.isUndefined(value)) {
      this.setState({
        selectedInterest: {
          de: undefined,
          fr: undefined
        }
      });
    }

    this.setState(updated);
  }

  //Main Category
  onCreateCategory() {
    const { actions } = this.props;
    const { mainCategoryName } = this.state;
    if (!_.isUndefined(mainCategoryName)) {
      actions.createInterestRequest(undefined, mainCategoryName, "category");
    }

    this.setState({ 
      mainCategoryName: {
        de: undefined,
        fr: undefined
    }});
  }

  //Sub Category
  onCreateGroup() {
    const { actions } = this.props;
    const { subCategoryName, selectedInterest } = this.state;
    if (!_.isUndefined(selectedInterest) && !_.isUndefined(subCategoryName)) {
      actions.createInterestRequest(selectedInterest, subCategoryName, "group"); 
    }

    this.setState({ 
      subCategoryName: {
        de: undefined,
        fr: undefined
    }});
  }

  updateService = (updatedInterest) => {
    const { actions } = this.props;
    actions.updateInterestRequest(updatedInterest);
  }

  onDeleteInterest = (deletedInterestId, categoryType) => {
    const { actions } = this.props;
    actions.deleteInterestRequest(deletedInterestId);
    if (categoryType === "mainCategory") {
      this.setState({ selectedInterest: {
        de: undefined,
        fr: undefined
      } });  
    }
  }

  renderItem() {
    const { language, interests } = this.props;
    const { selectedInterest } = this.state;

    const subCategories = !_.isUndefined(selectedInterest["de"]) ? _.filter(interests, o => _.get(o, 'parentCodeId') === _.get(selectedInterest["de"], 'value')) : [];

    return (
      subCategories.map((o) => <InterestItem key={o.id} item={o} language={language} changeValue={this.changeValue} onDeleteInterest={() => this.onDeleteInterest(o.id, "subCategory")} />)
    );
  }

  interests() {
    return (
      <div>
        <div style={{
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'flex-start',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
        >
          <div style={{ marginLeft: 20, width: 250 }} ></div>
          <div style={{ flex: 0, marginLeft: 20, minWidth: 200 }}></div>
          <div style={{ flex: 0, marginLeft: 40, minWidth: 155 }}><strong>Interessen</strong></div>
        </div>
        <div style={{
          height: 60,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'flex-start',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
        >
          <div style={{ marginLeft: 20, width: 250 }} ><strong>Gruppe</strong></div>
          <div style={{ flex: 0, marginLeft: 20, minWidth: 200 }}><strong>Löschen</strong></div>
        </div>
        <hr style={{ margin: 0 }} />
        {this.renderItem()}
      </div>
    )
  }

  render() {
    const { requesting, interests, language } = this.props;
    const { selectedInterest, subCategoryName } = this.state;

    const filteredInterests = _.filter(interests, i => i.parentCodeId === undefined);

    const interestDropdown = this.renderInterestDropdown(selectedInterest, filteredInterests, language);

    return (
      <div>
        <EditContainer
          requesting={requesting}
          title={<div><span>Interessen</span></div>}
          header={interestDropdown}
          body={this.interests()}
        />
        <div style={{marginLeft: '20px', width: '50%'}}>
        Neue Gruppe erfassen
        <div>
        <FormControl
          type="text"
          id="subCategoryName"
          name="subCategoryName"
          onChange={(e) => this.onValueChange(e, "de")}
          value={subCategoryName["de"]}
          placeholder="Deutsch"
        />
        <br/>
        <FormControl
          type="text"
          id="subCategoryName"
          name="subCategoryName"
          onChange={(e) => this.onValueChange(e, "fr")}
          value={subCategoryName["fr"]}
          placeholder="Français"
        />
        <br />
        <Button 
          bsStyle="primary" 
          name="newGroup" 
          onClick={this.onCreateGroup}
          disabled={(_.isUndefined(selectedInterest["de"]) || (_.isUndefined(subCategoryName["de"]) || subCategoryName["de"]==="")) ? true : false}>
          Hinzufügen
        </Button>
      </div>
    </div>
    </div>
    )
  }

  renderInterestDropdown(selectedInterest, filteredInterests) {
    const { createCategoryView, mainCategoryName } = this.state;
    const { language } = this.props;

    return <div stlye={{ display: 'flex' }}>
      <FormGroup>
        <Select id="interest"
          label="interest"
          value={!_.isUndefined(selectedInterest[language]) ? selectedInterest[language] : selectedInterest["de"]}
          allowEmpty={false}
          onChange={(label, value) => this.onInterestChange(label, value, language)}
          options={filteredInterests.map(type => ({ label: type.text[language], value: type.id }))}
          style={{ width: '50%' }} 
          clearable={false}
          />

        <Button
          bsStyle="primary"
          style={{ marginTop: '20px' }}
          name="newCategory"
          onClick={this.toggleCreateCategoryView}
        >
          {(createCategoryView) ? <div>Neue Kategorie Erfassen ausblenden</div> : <div>Neue Kategorie Erfassen einblenden </div>}
        </Button>

        <Button
          bsStyle="primary"
          style={{ marginTop: '20px', marginLeft: '20px' }}
          name="deleteCategory"
          onClick={() => this.onDeleteInterest(selectedInterest["de"].value, "mainCategory")}
          disabled={_.isUndefined(selectedInterest["de"]) ? true : false}
        >
          Ausgewählte Kategorie löschen
        </Button>
      </FormGroup>
      { createCategoryView ? 
        <div>
          Neue Kategorie erfassen
          <FormControl
            type="text"
            id="mainCategoryName"
            name="mainCategoryName"
            onChange={(e) => this.onValueChange(e, "de")}
            value={mainCategoryName["de"]}
            style={{ width: '50%' }}
            placeholder="Deutsch"
          />
          <br/>
          <FormControl
            type="text"
            id="mainCategoryName"
            name="mainCategoryName"
            onChange={(e) => this.onValueChange(e, "fr")}
            value={mainCategoryName["fr"]}
            style={{ width: '50%' }}
            placeholder="Français"
          />
        <Button
          bsStyle="primary"
          name="createCategory"
          onClick={this.onCreateCategory}
          style={{ marginTop: '20px' }}
          disabled={(_.isUndefined(mainCategoryName["de"]) || mainCategoryName["de"]==="") ? true : false}>
        Hinzufügen</Button>
        </div>
      : undefined}
    </div>
  }
}

function mapStateToProps(state) {

  return {
    interests: state.interests.interests,
    requesting: state.interests.requesting,
    language: state.login.language,
    hasError: state.interests.hasError,
    error: state.interests.error,
  }
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(interestActions, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(Interests);

import _, { get } from 'lodash';
import { createSelector } from 'reselect';
import { getIdFromRef } from '../../utils';
import { getSetManagementProductsRaw } from './setManagementCommonSelectors';
import { getLanguage } from '../commonSelectors';

export const getSetManagementProducts = createSelector([
  getSetManagementProductsRaw,
  getLanguage,
], (products, locale) => {
  return _.sortBy(products, (it) => it.order)
    .map((product) => ({
      id: product.id,
      order: product.order,
      domain: get(product, 'domain', []).map((domain) => getIdFromRef(domain.$ref)),
      bundle: get(product, 'bundle', []).map((bundle) => getIdFromRef(bundle.$ref)),
      name: product.name[locale],
      shortName: product.shortName
        ? product.shortName[locale]
        : undefined,
      unitPrice: product.unitPrice
        ? product.unitPrice
        : undefined,
    }));
});

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap'
import { get, noop } from 'lodash';

import { classList } from '../../utils/classList';
import { ExpandContent } from '../Common/ExpandContent';
import { FormInput, FormDateRange, FormSelect } from '../FormControls';

import './ExecutedJobsFilterForm.css';

const ExecutedJobsFilterReduxForm = ({
  clearForm,
  definitions,
  handleSubmit,
  intl,
  language,
  loading,
  onFilter,
  reset,
  setClearForm
}) => {
  const [expanded, setExpanded] = useState(true);

  const iconClasses = classList({
      'icon-013-chevron-down': true,
      'bulk-loads-search__icon': true,
      'bulk-loads-search__icon--collapsed': !expanded,
  });

  const jobOptions = get(definitions, 'data', []).map(job => ({
    label: get(job, `name.${language}`, ''),
    value: get(job, 'id', '')
  }));

  const successOptions = [
      {label: intl.formatMessage({ id: 'jobProcessing_yes' }), value: true},
      {label: intl.formatMessage({ id: 'jobProcessing_no' }), value: false}
  ]

  useEffect(() => {
      if (clearForm) {
          reset();
          setClearForm(false);
      }
  }, [clearForm])

  return (
    <div className="job-processing-filter__container">
      <Form
        onSubmit={handleSubmit((params) => onFilter(params, 'resetParams'))}
        key="form"
      >
        <div className="job-processing-filter__header" onClick={() => setExpanded(!expanded)}>
          <h2><FormattedMessage id="jobProcessing_filter" /></h2>
          <div className={iconClasses} />
        </div>

        <ExpandContent expanded={expanded}>
          <div className="job-processing-filter__body">
            <Field component={FormSelect}
              size='large'
              label={<FormattedMessage id="jobProcessing_job" />}
              labelSize={3}
              name="job"
              clearable={true}
              options={jobOptions}
            />
            <Field component={FormDateRange}
              label={<FormattedMessage id="jobProcessing_timespan" />}
              labelSize={3}
              name="timespan"
              displayFormat="DD.MM.YYYY"
              isOutsideRange={() => false}
              minimumNights={0}
              startDatePlaceholderText={intl.formatMessage({ id: 'analyticsLogs_searchForm_startDatePlaceholder' })}
              endDatePlaceholderText={intl.formatMessage({ id: 'analyticsLogs_searchForm_endDatePlaceholder' })}
            />
            <Field component={FormInput}
              size='large'
              type="text"
              label={<FormattedMessage id="jobProcessing_customer" />}
              labelSize={3}
              name="customer"
              placeholder="000.000.00"
            />
            <Field component={FormSelect}
              size='large'
              label={<FormattedMessage id="jobProcessing_successful" />}
              labelSize={3}
              name="success"
              clearable={true}
              options={successOptions}
            />
          </div>

          <Button bsStyle="primary" type="submit" disabled={loading}>
            <FormattedMessage id="jobProcessing_apply" />
          </Button>

          <div className="job-processing-filter__clear" onClick={() => onFilter({}, 'clear')}>
            <span className="icon-008-bin"></span>
            <FormattedMessage id="jobProcessing_clear" />
          </div>
        </ExpandContent>
      </Form>
    </div >
  );
};

ExecutedJobsFilterReduxForm.propTypes = {
  definitions: PropTypes.object,
  handleSubmit: PropTypes.func,
  intl: PropTypes.object,
  language: PropTypes.string,
  loading: PropTypes.bool,
  onFilter: PropTypes.func
};

ExecutedJobsFilterReduxForm.defaultProps = {
  definitions: {},
  language: 'de',
  onFilter: noop
}

export default reduxForm({})(ExecutedJobsFilterReduxForm);
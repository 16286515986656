import './ExternalLinkDetails.css';

import React from 'react';
import { FormControl } from 'react-bootstrap';
import { SelectControl } from '../Common/SelectControl';
import { createRef, getIdFromRef } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';
import { USER_PERMISSION_GROUPS } from '../../constants/Permissions'
import { get } from 'lodash';

export class ExternalLinkDetails extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onChangeUrl = this.onChangeUrl.bind(this);
    this.onSelectCategories = this.onSelectCategories.bind(this);
    this.onSelectPermissions = this.onSelectPermissions.bind(this);
    this.onImportanceChange = this.onImportanceChange.bind(this);


    this.state = {
      name: props.link.name,
      url: props.link.url,
      translatedUrl: props.link.translatedUrl,
      selectedCategories: props.link.categories.map((it) => getIdFromRef(it.$ref)),
      selectedPermissions: get(props, "link.permissions", []),
      importanceOptions: ['low', 'medium', 'high'],
      selectedImportance: get(props, 'link.importance')
    };
  }

  render() {
    const { language, categories } = this.props;
    const { name, url, translatedUrl, selectedCategories, selectedPermissions, importanceOptions, selectedImportance } = this.state;
    const categorieOptions = categories.map((it) => ({
      label: it.text[language],
      value: it.id,
    }));

    

    const permissionOptions = Array.from(USER_PERMISSION_GROUPS).map((value) => {
      return {
        label: value, 
        value: value
      };
    });


    return (
      <div className="external-link-details">
        <div className="external-link-details__section">
          <div className="external-link-details__title">Name</div>

          <div className="external-link-details__item">
            <div className="external-link-details__item-title">
              DE {this.renderRequired('de')}
            </div>
            <div className="external-link-details__item-input">
              <FormControl
                type="text"
                name="name-de"
                value={name.de}
                onChange={this.onChangeName.bind(this, 'de')}
                onBlur={this.onChange}
              />
            </div>
          </div>

          <div className="external-link-details__item">
            <div className="external-link-details__item-title">
              EN {this.renderRequired('en')}
            </div>
            <div className="external-link-details__item-input">
              <FormControl
                type="text"
                name="name-en"
                value={name.en}
                onChange={this.onChangeName.bind(this, 'en')}
                onBlur={this.onChange}
              />
            </div>
          </div>

          <div className="external-link-details__item">
            <div className="external-link-details__item-title">
              FR {this.renderRequired('fr')}
            </div>
            <div className="external-link-details__item-input">
              <FormControl
                type="text"
                name="name-fr"
                value={name.fr}
                onChange={this.onChangeName.bind(this, 'fr')}
                onBlur={this.onChange}
              />
            </div>
          </div>
        </div>

        <div className="external-link-details__section">
          <div className="external-link-details__title">URL</div>
          <div className="external-link-details__item">
            <div className="external-link-details__item-title">
              Default {this.renderRequired()}
            </div>
            <div className="external-link-details__item-input">
              <FormControl
                type="text"
                name="url-default"
                value={url}
                onChange={this.onChangeUrl}
                onBlur={this.onChange}
              />
            </div>
          </div>

          <div className="external-link-details__item">
            <div className="external-link-details__item-title">
              DE {this.renderRequired('de')}
            </div>
            <div className="external-link-details__item-input">
              <FormControl
                type="text"
                name="url-de"
                value={translatedUrl.de}
                onChange={this.onChangeTranslatedUrl.bind(this, 'de')}
                onBlur={this.onChange}
              />
            </div>
          </div>

          <div className="external-link-details__item">
            <div className="external-link-details__item-title">
              EN {this.renderRequired('en')}
            </div>
            <div className="external-link-details__item-input">
              <FormControl
                type="text"
                name="url-en"
                value={translatedUrl.en}
                onChange={this.onChangeTranslatedUrl.bind(this, 'en')}
                onBlur={this.onChange}
              />
            </div>
          </div>

          <div className="external-link-details__item">
            <div className="external-link-details__item-title">
              FR {this.renderRequired('fr')}
            </div>
            <div className="external-link-details__item-input">
              <FormControl
                type="text"
                name="url-fr"
                value={translatedUrl.fr}
                onChange={this.onChangeTranslatedUrl.bind(this, 'fr')}
                onBlur={this.onChange}
              />
            </div>
          </div>
        </div>

        <div className="external-link-details__section">
          <div className="external-link-details__title">Categories</div>
          <div className="external-link-details__item">
            <div className="external-link-details__item-select">
              <SelectControl
                value={selectedCategories}
                multi={true}
                options={categorieOptions}
                closeOnSelect={false}
                onChange={this.onSelectCategories}
                onBlur={this.onChange}
              />
            </div>
          </div>
        </div>

        <div className="external-link-details__section">
          <div className="external-link-details__title">Permissions</div>
          <div className="external-link-details__item">
            <div className="external-link-details__item-select">
              <SelectControl
                value={selectedPermissions}
                multi={true}
                options={permissionOptions}
                closeOnSelect={false}
                onChange={this.onSelectPermissions}
                onBlur={this.onChange}
              />
            </div>
          </div>
        </div>

        <div className="external-link-details__section">
          <div className="external-link-details__title">Wichtigkeit</div>
          <div className="external-link-details__item">
            <div className="external-link-details__item-select">
              <SelectControl
                value={selectedImportance}
                multi={false}
                options={importanceOptions.map(imp => ({ value: imp, label: imp}))}
                closeOnSelect={true}
                onChange={this.onImportanceChange}
                onBlur={this.onChange}
              />
            </div>
          </div>
        </div>

        <div className="external-link-details__section">
          <span className="external-link-details_required">*</span> - <i>required fields</i>
        </div>
      </div>
    );
  }

  renderRequired(locale) {
    const { language } = this.props;
    if (locale && language !== locale) {
      return null;
    }
    return (
      <span className="external-link-details_required">*</span>
    );
  }

  onChange() {
    const { onChange, link } = this.props;
    const { name, url, translatedUrl, selectedCategories, selectedPermissions, selectedImportance } = this.state;

    onChange({
      ...link,
      name,
      url,
      translatedUrl,
      categories: selectedCategories.map((category) => ({
        $ref: createRef(EndpointName.ENTITY_CODE, category),
      })),
      permissions: selectedPermissions,
      importance: selectedImportance
    });
  }

  onChangeName(locale, event) {
    const { value } = event.target;
    this.setState((prev) => ({
      name: {
        ...prev.name,
        [locale]: value,
      }
    }));
  }

  onChangeUrl(event) {
    const { value } = event.target;
    this.setState({
      url: value,
    });
  }

  onChangeTranslatedUrl(locale, event) {
    const { value } = event.target;
    this.setState((prev) => ({
      translatedUrl: {
        ...prev.translatedUrl,
        [locale]: value,
      }
    }));
  }

  onSelectCategories(categories) {
    this.setState({ selectedCategories: categories.map((it) => it.value) });
  }

  onSelectPermissions(permissions) {
    this.setState({ selectedPermissions: permissions.map((it) => it.value) });
  }
  onImportanceChange(importance) {
    this.setState({ selectedImportance: importance.value });
  }
}

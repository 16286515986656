import moment from 'moment';
import { call, put } from 'redux-saga/effects';
import { ANALYTICS_HISTORY_SEARCH } from '../../actions/AnalyticsActions';
import { historyLoadSaga } from './analyticsLoadHistorySaga';

export function* historySearchSaga(action) {
  const { date } = action.data;
  yield put(ANALYTICS_HISTORY_SEARCH.pending());

  const startDate = moment(date).startOf('month');
  const endDate = moment(date).endOf('month');
  const { history, items } = yield call(historyLoadSaga, startDate, endDate);

  yield put(ANALYTICS_HISTORY_SEARCH.success({ history, historyItems: items }));
}

import { createSelector } from 'reselect';
import { getFiles, getApiPromotions } from './promotionSelectors';
import { mapPromotionToApp } from '../../services/Promotions';
import { getLanguage } from '../commonSelectors';

export const getPromotions = createSelector([
  getApiPromotions,
  getFiles,
  getLanguage,
], (
  promotions,
  files,
  language,
) => promotions.map((promotion) => mapPromotionToApp(promotion, files, language)));

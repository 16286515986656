import { SAVE_PROMOTIONS_ACTIONS } from '../../actions/PromotionActions';
import { clearRemovedFiles } from '../../services/Promotions';

export function SavePromotionsReducer(state, action) {
  switch (action.type) {
    case SAVE_PROMOTIONS_ACTIONS.PENDING:
      if (!state.promotions.some((it) => it.id === action.meta.promotion.id)) {
        return {...state, loading: true};
      }
      return updatePromotion(state, action.meta.promotion.id, {saving: true});

    case SAVE_PROMOTIONS_ACTIONS.SUCCESS:
      const { apiPromotion, appPromotion, updatedSections } = action.data;
      const sections = state.checklistSections.map((section) => {
          const updated = updatedSections.find((it) => it.id === section.id);
          return updated ? updated : section;
      });

      const updatedState = updatePromotion(
        {
          ...state,
          checklistSections: sections,
          files: updateFiles(state.files, appPromotion),
          loading: false,
        },
        apiPromotion.id,
        {...apiPromotion, saving: false},
      );
      return {
        ...updatedState,
        files: clearRemovedFiles(updatedState.files, updatedState.promotions),
      };

    case SAVE_PROMOTIONS_ACTIONS.FAILURE:
      if (!state.promotions.some((it) => it.id === action.meta.promotion.id)) {
        return {...state, loading: false};
      }
      return updatePromotion(state, action.meta.promotion.id, {saving: false});

    default:
      return state;
  }
}

function updatePromotion(state, promotionId, data) {
  const isNew = !state.promotions.some((it) => it.id === promotionId);
  return {
    ...state,
    promotions: isNew
      ? [...state.promotions, data]
      : state.promotions.map((it) => it.id === promotionId ? {...it, ...data} : it),
  };
}

function updateFiles(files, promotion) {
  return [
    ...promotion.document,
    promotion.image,
    promotion.teaserImage,
  ].reduce((result, document) => {
    if (!document) {
      return result;
    }
    if (files[document.id] && files[document.id].blob) {
      return result;
    }
    return {...result, [document.id]: {...result[document.id], ...document}};
  }, {...files});
}

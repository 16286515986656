import { call, put, delay } from 'redux-saga/effects';
import { some } from 'lodash';

import transferModuleAction from '../actions/TransferModule';
import transferItem from './transferItem';
import getSourceItems from './getSourceItems';

const {
  TRANSFER_MODULE_PENDING,
  TRANSFER_MODULE_FULFILLED,
  TRANSFER_MODULE_REJECTED
} = transferModuleAction;

/**
 * Transfer data of the given module from source to target
 *
 * @param {String} dataKey Data key (module name)
 * @param {Object} module  Module object from /core/module (Be aware that the service array is filtered @see component/Modules.js)
 * @param {Object} source  Source system (state.paramTransfer.source)
 * @param {Object} target  Target system (state.paramTransfer.target)
 *
 * @return  void
 */
export default function* transferModule({
  dataKey,
  module,
  source,
  target
}) {
  yield put({ type: TRANSFER_MODULE_PENDING, dataKey });

  try {
    const items = yield call(getSourceItems, { dataKey, module, source });

    // getSourceRecords catches errors (but returns them). Check if one occured and rethrow
    if (items instanceof Error) {
      throw items;
    }

    const payload = [];
    // eslint-disable-next-line
    for (const item of items) {
      const r = yield call(transferItem, { dataKey: item.dataKey, item, source, target });
      payload.push(r);

      // Add a small delay between calls to make sure that the backend doesn't "overheat"
      yield delay(100);
    }

    if (some(payload, (p) => p instanceof Error)) {
      throw new Error(`One or more items of module ${module.key} could not be synced!`);
    }

    yield put({ type: TRANSFER_MODULE_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: TRANSFER_MODULE_REJECTED, dataKey, error });

    return error;
  }
}

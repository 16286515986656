import { attachReducer, chainReducers } from '../../utils';
import { HolidaysLoadReducer } from './HolidaysLoadReducer';
import { HOLIDAYS_SELECT_YEAR_ACTIONS, HOLIDAYS_VIEW_MODE_ACTIONS } from '../../actions/HolidaysActions';
import { HolidaysViewModeReducer } from './HolidaysViewModeReducer';
import { HolidaysSelectYearReducer } from './HolidaysSelectYearReducer';
import { HolidaysRemoveReducer } from './HolidaysRemoveReducer';
import { HolidaysCreateReducer } from './HolidaysCreateReducer';
import { HolidaysEditReducer } from './HolidaysEditReducer';
import { HolidaysSaveReducer } from './HolidaysSaveReducer';

export  const HolidaysReducer = chainReducers(
  HolidaysLoadReducer,
  HolidaysCreateReducer,
  HolidaysEditReducer,
  HolidaysRemoveReducer,
  HolidaysSaveReducer,
  attachReducer(HOLIDAYS_VIEW_MODE_ACTIONS.REQUEST, HolidaysViewModeReducer),
  attachReducer(HOLIDAYS_SELECT_YEAR_ACTIONS.REQUEST, HolidaysSelectYearReducer),
);

import { Actions } from '@evoja-web/redaction';

import { applyLocalFiltersAction } from './ReplayAuditLog/ApplyLocalFilters';
import { auditLogAction } from './ReplayAuditLog/AuditLog';
import { connectSourceAction } from './ReplayAuditLog/ConnectSource';
import { connectTargetAction } from './ReplayAuditLog/ConnectTarget';
import { filterAction } from './ReplayAuditLog/Filter';
import { localFiltersAction } from './ReplayAuditLog/LocalFilters';
import { replayAction } from './ReplayAuditLog/Replay';
import { replayRecordAction } from './ReplayAuditLog/ReplayRecord';

export const actions = Actions([
  applyLocalFiltersAction,
  auditLogAction,
  connectSourceAction,
  connectTargetAction,
  filterAction,
  localFiltersAction,
  replayAction,
  replayRecordAction
]);

export default actions.get();

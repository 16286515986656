import './UiNotesImageField.css';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { createStructuredSelector } from 'reselect';
import Spinner from '../Spinner';
import { UI_NOTES_IMAGE_FETCH_ACTIONS, UI_NOTES_IMAGE_UPLOAD_ACTIONS } from '../../actions/UiNotesActions';
import { getUiNotesImage } from '../../selectors/UiNotes/uiNotesSelectors';
import { UiNotesImagePreview } from './UiNotesImagePreview';

class UiNotesImageFieldConnected extends React.Component {
  dropzoneRef = React.createRef();

  constructor(props) {
    super(props);

    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleUploadClick = this.handleUploadClick.bind(this);
    this.handleDropAccepted = this.handleDropAccepted.bind(this);
    this.handleDropRejected = this.handleDropRejected.bind(this);

    this.state = {
      hasError: false,
    };
  }

  componentDidMount() {
    const { value, fetchImage } = this.props;
    if (value) {
      fetchImage(value);
    }
  }

  componentWillUnmount() {
    const { cancelFetching, cancelUploading } = this.props;
    cancelFetching();
    cancelUploading();
  }

  componentDidUpdate() {
    const { value, image } = this.props;
    if (image && image.$ref !== value) {
      const { onChange } = this.props;
      onChange(image.$ref);
    }
  }

  render() {
    return (
      <div className="ui-notes-image-field">
        {this.renderPreview()}
        {this.renderDropzone()}
        {this.renderError()}
      </div>
    );
  }


  renderPreview() {
    const { value, image } = this.props;
    if (!value) {
      return null;
    }

    return (
      <div className="ui-notes-image-field__preview">
        <div className="ui-notes-image-field__preview-image">
          {image
            ? <UiNotesImagePreview file={image.file}/>
            : <Spinner/>}
        </div>
        <div className="ui-notes-image-field__preview-actions">
          <a onClick={this.handleRemoveClick}>Bild entfernen</a>
          <a onClick={this.handleUploadClick}>Bild hochladen</a>
        </div>
      </div>
    );
  }

  renderDropzone() {
    const { value } = this.props;

    return (
      <div className="ui-notes-image-field__upload" hidden={!!value}>
        <Dropzone
          ref={this.dropzoneRef}
          className="ui-notes-image-field__dropzone"
          accept="image/*"
          onDropAccepted={this.handleDropAccepted}
          onDropRejected={this.handleDropRejected}
        >
          <h1 className="text-center">
            <i className="icon-088-upload-cloud"/>
          </h1>
          <p className="text-center">
            Neue Dateien hier her ziehen oder klicken um sie auszuwählen
          </p>
        </Dropzone>
      </div>
    );
  }

  renderError() {
    const { hasError } = this.state;
    return hasError
      ? <div className="ui-notes-image-field__error">File has been rejected</div>
      : null;
  }

  handleRemoveClick() {
    const { onChange, cancelUploading } = this.props;
    onChange(null);
    cancelUploading();
  }

  handleUploadClick() {
    this.dropzoneRef.current.open();
  }

  handleDropAccepted([file]) {
    this.setState({ hasError: false });

    const { uploadImage } = this.props;
    uploadImage(file);
  }

  handleDropRejected() {
    this.setState({ hasError: true });
  }
}

UiNotesImageFieldConnected.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,

  image: PropTypes.shape({
    $ref: PropTypes.string.isRequired,
    file: PropTypes.instanceOf(Blob).isRequired,
  }),

  fetchImage: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,

  cancelFetching: PropTypes.func.isRequired,
  cancelUploading: PropTypes.func.isRequired,
};


const mapStateToProps = createStructuredSelector({
  image: getUiNotesImage,
});

const mapDispatchToProps = {
  fetchImage: ($ref) => UI_NOTES_IMAGE_FETCH_ACTIONS.request({ $ref }),
  uploadImage: (file) => UI_NOTES_IMAGE_UPLOAD_ACTIONS.request({ file }),

  cancelFetching: ($ref) => UI_NOTES_IMAGE_FETCH_ACTIONS.request({ $ref: null }),
  cancelUploading: (file) => UI_NOTES_IMAGE_UPLOAD_ACTIONS.request({ file: null }),
};

export const UiNotesImageField = connect(mapStateToProps, mapDispatchToProps)(UiNotesImageFieldConnected);

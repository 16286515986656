import _ from 'lodash';

import {
  GET,
  POST,
  PUT,
  DEL,
  SET
} from '../actions/InterestActions';

import update from 'immutability-helper';

const initialState = ({
  interests: [],
  requesting: false,
  error: {},
  hasError: false,
  removedInterests: [],
});

export default function InterestReducer(state = initialState, action) {
  switch (action.type) {
    case SET: {
      return { ...state, [action.key]: action.value };
    }
    case `${GET}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${GET}_FULFILLED`: {
      return { ...state, requesting: false, interests: _.sortBy(action.payload.data, o => o.order) };
    }
    case `${GET}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload
      };
    }
    case `${POST}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${POST}_FULFILLED`: {
      return update(
        state,
        {
          requesting: { $set: false },
          interests: { $push: [action.payload] }
        }
      )
    }
    case `${POST}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload
      };
    }
    case `${PUT}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${PUT}_FULFILLED`: {
      return update(
        state,
        {
          requesting: { $set: false },
          interests: { $set: _.sortBy(action.payload.data, o => o.order) }
        }
      )
    }
    case `${PUT}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload
      };
    }
    case `${DEL}_PENDING`: {
      return { ...state, requesting: true };
    }
    case `${DEL}_FULFILLED`: {
      const index = state.interests.findIndex(interest => interest.id === action.id);
      return update(
        state,
        {
          requesting: { $set: false },
          interests: { $splice: [[index, 1]] }
        }
      );
    }
    case `${DEL}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload
      };
    }
    default: {
      return state
    }
  }
}

import { call, put, takeEvery, select } from 'redux-saga/effects';
import _ from 'lodash';
import axios from 'axios';

import { handleNextHeaders } from '../utils/index';

import {
  CODES_REQUEST,
  CODES_PENDING,
  CODES_FULFILLED,
  CODES_REJECTED,
  CODE_SAVE_REQUEST,
  CODE_SAVE_PENDING,
  CODE_SAVE_FULFILLED,
  CODE_SAVE_REJECTED,
  CODE_DELETE_REQUEST,
  CODE_DELETE_PENDING,
  CODE_DELETE_FULFILLED,
  CODE_DELETE_REJECTED
} from '../actions/CodeActions';

export function* fetchCodes(request) {
  const { groups } = request;
  yield put({ type: CODES_PENDING, groups });

  try {
    // Get existing codes from state
    const existing = yield select(state => state.codes.groups);
    // Filter out groups that have already been loaded
    const load = _.difference(groups, Object.keys(existing));

    // Add codes from state
    let data = groups.reduce((result, value, key) => {
      if (_.has(existing, value)) result = [ ...result, ..._.get(existing, value, []) ];
      return result;
    }, []);

    if (load.length > 0) {
      const url = `/entity/code/?in(group,(${load.map(g => g).join(',')}))&limit(9999)`;
      const result = yield call(handleNextHeaders, url);
      data = [ ...data, ...result ];
    }

    yield put({ type: CODES_FULFILLED, payload: data });

    return data;
  } catch (error) {
    yield put({ type: CODES_REJECTED, error, groups });

    return error;
  }
}

export function* saveCode(request) {
  const { code } = request;
  yield put({ type: CODE_SAVE_PENDING, code });

  try {
    yield call(axios, {
      url: `/entity/code/${code.id}`,
      method: 'put',
      data: code
    });
    yield put({ type: CODE_SAVE_FULFILLED, code })

    return code;
  } catch (error) {
    yield put({ type: CODE_SAVE_REJECTED, error, code })

    return error;
  }
}

export function* deleteCode(request) {
  const { code } = request;
  yield put({ type: CODE_DELETE_PENDING, code });

  try {
    yield call(axios, {
      url: `/entity/code/${code.id}`,
      method: 'delete'
    });
    yield put({ type: CODE_DELETE_FULFILLED, code });

    return code;
  } catch (error) {
    yield put({ type: CODE_DELETE_REJECTED, error, code });

    return error;
  }
}

export default function* root() {
  yield takeEvery(CODES_REQUEST, fetchCodes);
  yield takeEvery(CODE_SAVE_REQUEST, saveCode);
  yield takeEvery(CODE_DELETE_REQUEST, deleteCode);
}

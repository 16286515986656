import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isNil } from 'lodash';
import { TextInput, Select, ValidationResult } from '@evoja-web/react-form';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import update from 'immutability-helper';

import EventPolling from './ServiceCall/EventPolling';
import Mapping from './ServiceCall/Mapping';

class ActionConfigurationExternalServiceCall extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mappingKey: undefined
    };

    this.onAddMappingKey = this.onAddMappingKey.bind(this);
  }

  /**
   * Add the property from state to mapping
   *
   * @return  void
   */
  onAddMappingKey() {
    const { mappingKey } = this.state;
    const {
      action,
      onJmesParamChange
    } = this.props;

    const current = get(action, 'jmesParams.mapping', {});
    const updated = update(current, { [mappingKey]: { $set: undefined } });

    onJmesParamChange('mapping', updated);
    this.setState({ mappingKey: undefined });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { mappingKey } = this.state;
    const {
      action,
      onJmesParamChange,
      onValueChange,
      validations
    } = this.props;

    const methods = [{
      value: 'post',
      label: 'POST'
    }, {
      value: 'put',
      label: 'PUT'
    }];

    return (
      <div className="workguide-action-configuration-service-call">
        <FormGroup>
          <ControlLabel>
            <FormattedMessage id="Workguide.Action.Configuration.ExternalServiceCall.Url" />
          </ControlLabel>

          <TextInput
            id="url"
            onChange={onValueChange}
            value={get(action, 'url')}
          />

          <ValidationResult
            show
            validations={get(validations, 'url')}
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>
            <FormattedMessage id="Workguide.Action.Configuration.ExternalServiceCall.Method" />
          </ControlLabel>

          <Select
            id="method"
            options={methods}
            onChange={onValueChange}
            value={get(action, 'method')}
          />

          <ValidationResult
            show
            validations={get(validations, 'method')}
          />
        </FormGroup>

        <EventPolling
          action={action}
          onChange={onValueChange}
          validations={validations}
        />

        <FormGroup>
          <ControlLabel>
            <FormattedMessage id="Workguide.Action.Configuration.ServiceCall.AddMapping" />
          </ControlLabel>

          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: '100%' }}
          >
            <div style={{ width: '100%' }}>
              <TextInput
                id="mappingKey"
                onChange={(key, value) => this.setState({ mappingKey: value })}
                value={mappingKey}
              />
            </div>

            <div
              className="mdi mdi-plus-box-outline"
              onClick={isNil(mappingKey) ? noop : this.onAddMappingKey}
              style={{ paddingLeft: '0.5em', fontSize: '1.7em' }}
            />
          </div>
        </FormGroup>

        <Mapping
          action={action}
          onJmesParamChange={onJmesParamChange}
          validations={validations}
        />
      </div>
    );
  }
}

ActionConfigurationExternalServiceCall.propTypes = {
  action: PropTypes.object.isRequired,
  onValueChange: PropTypes.func,
  onJmesParamChange: PropTypes.func,
  validations: PropTypes.object
};

ActionConfigurationExternalServiceCall.defaultProps = {
  onValueChange: noop,
  onJmesParamChange: noop,
  validations: {}
};

export default ActionConfigurationExternalServiceCall;

import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import moment from 'moment';

import summaryAction from '../../actions/JobProcessing/Summary';

const {
  SUMMARY_PENDING,
  SUMMARY_FULFILLED,
  SUMMARY_REJECTED
} = summaryAction;

export default function* getSummary(request) {
  yield put({ type: SUMMARY_PENDING });

  try {
    const query = QueryBuilder()
      .ge('createDate', moment().subtract(24, 'hours').utc().format())
      .limit(1000)
      .getQueryString();

    const url = `/event/status/worker/job-processing/${query}`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: SUMMARY_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: SUMMARY_REJECTED, error });

    return error;
  }
}

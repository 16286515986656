import './SelectControl.css';

import React from 'react';
import VirtualizedSelect from 'react-virtualized-select';
import { injectIntl } from 'react-intl';
import { classList } from '../../utils/classList';

function SelectControlWithIntl({ className, intl, placeholder = null,  ...restProps }) {
  return (
    <VirtualizedSelect
      className={classList('select-component', className)}
    
      placeholder={intl.formatMessage({ id: placeholder ? placeholder: 'common_selectPlaceholder' })}
      {...restProps}
    />
  );
}

export const SelectControl = injectIntl(SelectControlWithIntl);

import React from 'react';
import { ControlLabel, Col, FormGroup, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import { head, get, has } from 'lodash';

import CheckDynamicConfiguration from './DynamicCheckpoint';
import ExistingWorkguideCheck from './ExistingWorkguideCheck';

/**
 * This is the module which will add additional fields to a checkpoint based on the given type.
 * ( or the: "god! i wish we would have a functional language at our disposal..." module)
 */
export const additionalCheckFields = props => {
  const { item } = props;
  return ({
    CheckCustomerAge: fieldsCustomerAge,
    CheckCustomerDoppelpartner: fieldsCustomerDoppelpartner,
    CheckLegalForm: fieldsLegalForm,
    CheckNoteDate: fieldsNoteDate,
    CheckDynamic: fieldsDynamic,
    CheckExistingWorkguide: fieldsExistingWorkguideCheck
  }[item.props.type] || nothing)(props);
};

// field builders
const nothing = () => null;

const fieldsCustomerDoppelpartner = ({ editValueField, item, id }) => {
  const fields = { VALID: 'checkTruthyValue' };
  const { props: itemProps } = item;
  const { options } = itemProps;
  const isValidSelectOptions = [{ value: "true", label: 'Ja' }, { value: "false", label: 'Nein' }];

  // existing values and change functions
  const isValid = dataGetOptionWithId(options, fields.VALID);
  const onIsValidChanged = dataUpdateOrCreateOption({ editValueField, itemId: id, optionId: fields.VALID, options });

  // sanity checks if stuff does not exist set default values
  if (!options) return null;
  if (typeof isValid === 'undefined') return onIsValidChanged(head(isValidSelectOptions).value);

  // create the fields
  return [uiAddSelect('Doppelpartner valide?', isValidSelectOptions, isValid.value, onIsValidChanged, false)];
};

const fieldsCustomerAge = ({ editValueField, item, id }) => {
  const customerAgeFields = { OPERATOR: 'customerAgeOperator', AGE: 'customerAgeAge' };
  const customerAgeOperators = [{ value: '=', label: '=' }, { value: '>', label: '>' }, { value: '<', label: '<' }];
  const { props: itemProps } = item;
  const { options } = itemProps;

  // existing values and change functions
  const selectedOperator = dataGetOptionWithId(options, customerAgeFields.OPERATOR);
  const onOperatorChanged = dataUpdateOrCreateOption({ editValueField, itemId: id, optionId: customerAgeFields.OPERATOR, options });
  const selectedAge = dataGetOptionWithId(options, customerAgeFields.AGE);
  const onAgeChanged = dataUpdateOrCreateOption({ editValueField, itemId: id, optionId: customerAgeFields.AGE, options });

  // sanity checks, if stuff does not exist set default values
  if (!options) return null;
  if (!selectedOperator) return onOperatorChanged(head(customerAgeOperators).value);
  if (!selectedAge) return onAgeChanged('16');

  // create the fields
  return [
    uiAddSelect('Operator', customerAgeOperators, selectedOperator.value, onOperatorChanged, false),
    uiAddInput('Wert', selectedAge.value, onAgeChanged)
  ];
};

const fieldsLegalForm = ({ editValueField, item, id, codes, language, fetchCodes }) => {
  if (!has(codes, 'customerLegalForm')) fetchCodes('customerLegalForm');

  const options = get(codes, 'customerLegalForm', []).map(c => ({
    value: get(c, 'id'),
    label: get(c, `text.${language}`)
  }));

  const field = (<Select
    value={get(item, 'props.options', []).map(o => get(o, 'value'))}
    options={options}
    onChange={(selected = []) => editValueField(id, 'componentOptions', selected.map(s =>({ label: { de: s.value }, value: s.value })))}
    multi={true}
  />);

  return [uiAddField({ name: 'Notizart', inputField: field })];
}

const fieldsNoteDate = ({ editValueField, item, id, codes, language, fetchCodes }) => {
  if (!has(codes, 'customerNoteTopic')) fetchCodes('customerNoteTopic');

  const options = get(codes, 'customerNoteTopic', []).map(c => ({
    value: get(c, 'id'),
    label: get(c, `text.${language}`)
  }));

  const field = (<Select
    value={get(item, 'props.options', []).map(o => get(o, 'value'))}
    options={options}
    onChange={(selected = []) => editValueField(id, 'componentOptions', selected.map(s =>({ label: { de: s.value }, value: s.value })))}
    multi={true}
  />);

  return [uiAddField({ name: 'Notizart', inputField: field })];
}

const fieldsDynamic = (props) => {
  return <CheckDynamicConfiguration {...props} />
}

const fieldsExistingWorkguideCheck = (props) => {
  return <ExistingWorkguideCheck {...props} />
}

// gui helpers
const uiAddSelect = (name, selectOptions, selectedValue, onChange, isClearable = true) => {
  const inputField = (
    <Select options={selectOptions} value={selectedValue} clearable={isClearable} onChange={newOption => onChange(newOption.value)} />
  );
  return uiAddField({ name, inputField });
};

const uiAddInput = (name, selectedValue, onChange) => {
  const inputField = <FormControl value={selectedValue} min={1} type="number" onChange={event => onChange(event.target.value)} />;
  return uiAddField({ name, inputField });
};

const uiAddField = ({ name, inputField }) => {
  return (
    <FormGroup>
      <Col componentClass={ControlLabel} lg={3}>
        {name}
      </Col>
      <Col lg={9}>{inputField}</Col>
    </FormGroup>
  );
};

// data helpers
const dataGetOptionFilter = id => o => o.label.de === id;
const dataGetOptionWithId = (options, id) => {
  return head(options.filter(dataGetOptionFilter(id)));
};

const dataUpdateOrCreateOption = ({ options, itemId, optionId, editValueField }) => value => {
  const restOptions = options.filter(o => !dataGetOptionFilter(optionId)(o));
  const newOption = { label: { de: optionId, en: optionId, fr: optionId }, value };
  const newOptions = [...restOptions, newOption];
  editValueField(itemId, 'componentOptions', newOptions);
  return null;
};

import _ from 'lodash';
import { createSelector } from 'reselect';
import { getIdFromRef } from '../../utils';
import { getSetManagementBundlesRaw } from './setManagementCommonSelectors';
import { getLanguage } from '../commonSelectors';

export const getSetManagementBundles = createSelector([
  getSetManagementBundlesRaw,
  getLanguage,
], (bundles, locale) => {
  return _.sortBy(bundles, (it) => it.order)
    .map((bundle) => ({
      id: bundle.id,
      order: bundle.order,
      domain: bundle.domain.length
        ? getIdFromRef(bundle.domain[0].$ref)
        : undefined,
      name: bundle.name[locale],
      shortName: bundle.shortName
        ? bundle.shortName[locale]
        : undefined,
      bundlePrice: bundle.bundlePrice
        ? bundle.bundlePrice
        : undefined,
      bundlePriceReduced: bundle.bundlePriceReduced
        ? bundle.bundlePriceReduced
        : undefined,
      bundlePriceYouth: bundle.bundlePriceYouth,
    }));
});

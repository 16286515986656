import React from 'react';
import PropTypes from 'prop-types';
import { has, get, noop, debounce } from 'lodash';
import { FormGroup, FormControl, ControlLabel, Col, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import update from 'immutability-helper';

import MarkdownEditor from '../../MarkdownEditor';

const inputTypes = [{
  value: 'none',
  label: 'Standard'
}, {
  value: 'info',
  label: 'Info'
}, {
  value: 'warning',
  label: 'Warnung'
}, {
  value: 'danger',
  label: 'Fehler'
}, {
  value: 'success',
  label: 'Erfolg'
}];

class InfoBoxConfiguration extends React.Component {
  constructor(props) {
    super(props);
    const { id, item, editValueField } = props;

    this.state = {
      activeTab: 'de',
      infoboxTitle: get(item, 'props.infoboxTitle'),
      infoboxContent: get(item, 'props.infoboxContent')
    };

    this.onTypeChange = this.onTypeChange.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.debouncedEditValueField = debounce(editValueField, 500);

    // Set default type
    if (!has(item, 'props.type')) editValueField(id, 'prop', { key: 'type', value: 'info' });
  }

  /**
   * Handle input type change
   *
   * @param  {Object} selected Selected option
   *
   * @return void
   */
  onTypeChange(selected) {
    const { id, editValueField } = this.props;
    const { value } = selected;

    editValueField(id, 'prop', { key: 'type', value });
  }

  /**
   * Set current active tab
   *
   * @param {String} language Selected language
   */
  setActiveTab(language) {
    this.setState({ activeTab: language });
  }

  /**
   * Handle change of one of the text field (title/description)
   *
   * @param  {String} field   Field name
   * @param  {String} lanuage Language
   * @param  {Object} ev      Event from input
   *
   * @return void
   */
  onTextChange(field, language, value) {
    const { id } = this.props;
    const current = get(this, `state.${field}`, {});
    const updated = update(current, { [language]: { $set: value } });
    this.setState({ [field]: updated });

    this.debouncedEditValueField(id, field, updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { active } = this.state;
    const { item, languages } = this.props;

    const tabs = languages.map(l => (
      <Tab key={l} eventKey={l} title={l}>
        <ControlLabel style={{ textAlign: 'left' }}>
          Titel
        </ControlLabel>
        <FormControl
          type="text"
          value={get(this, `state.infoboxTitle.${l}`, '')}
          onChange={(ev) => this.onTextChange('infoboxTitle', l, get(ev, 'target.value'))}
        />

        <ControlLabel style={{ textAlign: 'left' }}>
          Inhalt
        </ControlLabel>
        <div style={{ paddingLeft: '1%' }}>
          <MarkdownEditor
            content={get(this, `state.infoboxContent.${l}`, '')}
            updateFunction={value => this.onTextChange('infoboxContent', l, value)}
            title="Inhalt"
          />
        </div>
      </Tab>
    ));

    return(
      <React.Fragment>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Eingabefeld Art
          </Col>
          <Col sm={9}>
            <Select
              placeholder="Eingabefeld Ausprägung wählen"
              clearable={false}
              name={get(item, 'props.id')}
              options={inputTypes}
              value={get(item, 'props.type')}
              onChange={this.onTypeChange}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Inhalt
          </Col>

          <Col sm={9}>
            <Tabs
              id="action-title"
              activeKey={active}
              onSelect={this.setActiveTab}
            >
              {tabs}
            </Tabs>
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

InfoBoxConfiguration.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  item: PropTypes.object,
  languages: PropTypes.array,
  editValueField: PropTypes.func
};

InfoBoxConfiguration.defaultProps = {
  id: undefined,
  item: undefined,
  languages: ['de', 'fr', 'en'],
  editValueField: noop
};

export default InfoBoxConfiguration;

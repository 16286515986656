import update from 'immutability-helper';
import { ENTITIY_CODE_LOAD, CONSULTANT_LOAD, CONSULTANT_SEARCH, CONSULTANT_UPDATE } from '../actions/PermissionGroupAction';
import { PERMISSION_GROUP_DEFAULT_SORT } from '../constants/PermissionGroupConstants';
const initialState = {
  loading: false,
  error: undefined,
  consultants: [],
  searchParams: {
    ...PERMISSION_GROUP_DEFAULT_SORT,
  },
};

export function PermissionGroupReducer(state = initialState, action) {
  switch (action.type) {
    case CONSULTANT_LOAD.PENDING:
      return { ...state, loading: true, error: undefined };
    case CONSULTANT_LOAD.SUCCESS: {
      const { consultants, totalCount } = action.data;
      return { ...state, loading: false, error: undefined, consultants, totalCount };
    }
    case CONSULTANT_LOAD.FAILURE:
      return { ...state, loading: false, error: action.data };

    case ENTITIY_CODE_LOAD.PENDING:
      return { ...state, loading: true, error: undefined };
    case ENTITIY_CODE_LOAD.SUCCESS: {
      const { codes, totalCount } = action.data;
      return { ...state, loading: false, codes, totalCount };
    }
    case ENTITIY_CODE_LOAD.FAILURE:
      return { ...state, loading: false, error: action.data };

    case CONSULTANT_UPDATE.PENDING:
      return { ...state, loading: true, error: undefined };
    case CONSULTANT_UPDATE.SUCCESS: {
      const { permission, id } = action.data;
      const consultantIndex = state.consultants.findIndex((el) => el.id === id);
      return update(state, {
        consultants: {
          [consultantIndex]: { permission: { $set: permission } },
        },
        loading: { $set: false },
        error: { $set: undefined },
      });
    }
    case CONSULTANT_UPDATE.FAILURE:
      return { ...state, loading: false, error: action.data };

    case CONSULTANT_SEARCH.PENDING:
      return { ...state, loading: true, error: undefined };
    case CONSULTANT_SEARCH.SUCCESS: {
      const { consultants, totalCount, searchParams } = action.data;
      return { ...state, loading: false, error: undefined, consultants, totalCount, searchParams };
    }
    case CONSULTANT_SEARCH.FAILURE:
      return { ...state, loading: false, error: action.data };

    default:
      return state;
  }
}

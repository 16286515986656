/* redux-saga example
* The banklistActions are used to make a working example
* how to use redux-saga instead of redux-promise/redux-thunk
* however, this example should only show how to use it
* redux-saga and redux-thunk can be used both together
* simple GET/PUT/POST calls should not be rewritten to sagas
*/
import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { createActions } from '../utils';

export const BANK_LIST_SAVE = createActions("BANK_LIST_SAVE");
export const GET = 'adminUI/Banklist/GET';
export const SET = 'adminUI/Banklist/SET';

export function setData({ key, value }) {
  return dispatch =>
    dispatch({
      type: SET,
      key,
      value,
    });
}

export const getBanklistRequest = () => ({
  type: `${GET}_REQUEST`,
});

export function* getBanklist() {
  try {
    yield put({ type: `${GET}_PENDING` });
    const result = yield call(axios.get, 'entity/code/?eq(group,string:bankList)&limit(1000)');
    yield put({ type: `${GET}_FULFILLED`, payload: result });
  } catch (e) {
    yield put({ type: `${GET}_REJECTED`, payload: e });
  }
}

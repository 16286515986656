import { FINANCING_RATES_UPDATE_REFI_RATE_ACTIONS } from '../../actions/FinancingRatesActions';

export function FinancingRatesUpdateRefiRateReducer(state, action) {
  switch (action.type) {
    case FINANCING_RATES_UPDATE_REFI_RATE_ACTIONS.SUCCESS:
      return { ...state, refiRates: action.data.refiRates };
    default:
      return state;
  }
}


import React from 'react';
import { injectIntl } from 'react-intl';
import { DateRangePicker as ReactDateRangePicker } from 'react-dates';

function DateRangePickerWithIntl({ intl, ...restProps }) {
  return (
    <ReactDateRangePicker
      startDatePlaceholderText={intl.formatMessage({ id: 'common_datePicker_startDatePlaceholder' })}
      endDatePlaceholderText={intl.formatMessage({ id: 'common_datePicker_endDatePlaceholder' })}
      {...restProps}
    />
  );
}

export const DateRangePicker = injectIntl(DateRangePickerWithIntl);

import React from 'react';
import PropTypes from 'prop-types';
import { Form, ControlLabel, FormControl } from 'react-bootstrap';
import Toggle from 'react-toggle';

export default function EquitySettings(props) {
  const {
    id, item, language, editEventField,
  } = props;
  return (
    <div>
      <Form>
        <ControlLabel>Eigenmittel Bezeichnung</ControlLabel>
        <FormControl
          placeholder="Bezeichnung"
          type="text"
          value={item.name[language]}
          name="title"
          onChange={editEventField.bind(this, 'name', id)}
        />
        <label style={{ display: 'flex', paddingTop: '10px' }}>
          <Toggle
            checked={item.isLiquidity}
            onChange={editEventField.bind(this, 'isLiquidity', id)}
          />
          <span style={{ paddingLeft: '10px' }}>Zählt als Liquidität</span>
        </label>
        <label style={{ display: 'flex', paddingTop: '10px' }}>
          <Toggle
            checked={item.isEffective}
            onChange={editEventField.bind(this, 'isEffective', id)}
          />
          <span style={{ paddingLeft: '10px' }}>Effektive Eigenmittel gemäss Mindestanforderungen SBVg</span>
        </label>
      </Form>
    </div>
  );
}

EquitySettings.propTypes = {
  id: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  editEventField: PropTypes.func.isRequired,
};

import { get, toString, toNumber } from 'lodash';
import moment from 'moment';

const converters = {
  string: (value) => toString(value),
  number: (value) => toNumber(value),
  boolean: (value) => Boolean(value),
  date: (value) => moment(value)
}

export function convertValue(value = '', type = 'string') {
  const converter = get(converters, type, (value) => value);

  return converter(value);
}

export function convertValues(values = [], type = 'string') {
  return values.map((value) => convertValue(value));
}

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { get, noop, isNil } from 'lodash';
import { useDragLayer } from 'react-dnd';

import './Connection.css';
import EditModal from '../Modal/EditConnection';

const WorkflowTreeConnection = React.memo(function WorkflowTreeConnection({
  color,
  connection,
  onChange,
  onRemove
}) {
  useDragLayer((monitor) => {
    return {
      isDragging: monitor.isDragging()
    };
  });

  const [showEditModal, setShowEditModal] = useState(false);

  const onRemoveClick = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();

    onRemove({ event, connection });
  }, [onRemove, connection]);

  const onToggleEditModal = useCallback(() => {
    setShowEditModal(!showEditModal);
  }, [showEditModal, setShowEditModal]);

  const onEditFormSubmit = useCallback(({ data }) => {
    onChange({ connection: data });
  }, [onChange]);

  const s = document.getElementById(`workflow-tree-node-attach-point-${connection.source}-source`);
  const t = document.getElementById(`workflow-tree-node-attach-point-${connection.target}-target`);

  if (isNil(s) || isNil(t)) {
    return null;
  }

  const x1 = get(s, 'offsetLeft', 0) + get(s, 'offsetParent.offsetLeft', 0) + s.offsetWidth + 1;
  const y1 = get(s, 'offsetTop', 0) + get(s, 'offsetParent.offsetTop', 0) + (s.offsetHeight / 2);
  const x2 = get(t, 'offsetLeft', 0) + get(t, 'offsetParent.offsetLeft', 0);
  const y2 = get(t, 'offsetTop', 0) + get(t, 'offsetParent.offsetTop', 0) + (s.offsetHeight / 2);

  const top = y1 > y2
    ? y2 + ((y1 - y2) / 2)
    : y1 + ((y2 - y1) / 2);

  const left = x1 > x2
    ? x2 + ((x1 - x2) / 2)
    : x1 + ((x2 - x1) / 2);

  return (
    <>
      <div
        className="workflow-tree-connections--connection-actions"
        style={{ top, left }}
      >
        <div
          className="mdi mdi-pencil-outline pointer"
          onClick={onToggleEditModal}
        />

        <div
          className="mdi mdi-trash-can-outline pointer"
          onClick={onRemoveClick}
        />
      </div>

      <svg className="workflow-tree-connections--connection">
        <line
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          stroke={color}
        />
      </svg>

      {showEditModal && (
        <EditModal
          connection={connection}
          isOpen
          onEditFormSubmit={onEditFormSubmit}
          onHide={onToggleEditModal}
        />
      )}
    </>
  );
});

WorkflowTreeConnection.propTypes = {
  color: PropTypes.string,
  connection: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func
};

WorkflowTreeConnection.defaultProps = {
  color: 'black',
  onChange: noop,
  onRemove: noop
};

export default WorkflowTreeConnection;

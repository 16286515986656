import { isEqual } from 'lodash';

export function isPromotionChanged(promotion, rawPromotion) {
  if (!rawPromotion) {
    return true;
  }

  const simpleFields = ['active', 'default', 'title', 'imageText', 'colorCode', 'from', 'to'];
  if (simpleFields.some((field) => promotion[field] !== rawPromotion[field])) {
    return true;
  }

  if (
    promotion.customerSegments.length !== rawPromotion.customerSegments.length ||
    promotion.checklistItem.length !== rawPromotion.checklistItem.length ||
    promotion.document.length !== rawPromotion.document.length
  ) {
    return true;
  }

  if (
    promotion.image.id !== rawPromotion.image.id ||
    promotion.teaserImage.id !== rawPromotion.teaserImage.id
  ) {
    return true;
  }

  if (!isEqual(
    [...promotion.customerSegments].sort(),
    [...rawPromotion.customerSegments].sort(),
  )) {
    return true;
  }

  if (!isEqual(promotion.checklistItem, rawPromotion.checklistItem)) {
    return true;
  }

  return !isEqual(
    promotion.document.map((it) => ({id: it.id, order: it.metadata.order})),
    rawPromotion.document.map((it) => ({id: it.id, order: it.metadata.order})),
  );
}

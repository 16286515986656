import React from 'react';
import PropTypes from 'prop-types';
import _, { get } from 'lodash';
import { FormGroup, ControlLabel, Col, FormControl } from 'react-bootstrap';
import Toggle from 'react-toggle';
import Select from 'react-select';

class CodeSelectConfiguration extends React.Component {
  constructor(props) {
    super(props);
    const { item, fetchCodes, codes, languages } = props;
    const defaults = get(item, 'props.defaults', {});

    const isJmesInput = languages.reduce((result, language) => {
      result[language] = get(defaults, `${language}[0]`, '').includes('jmes:');
      return result;
    }, {});

    this.state = {
      isJmesInput
    };

    this.onGroupChange = this.onGroupChange.bind(this);
    this.onDefaultValueChange = this.onDefaultValueChange.bind(this);
    this.toggleMulti = this.toggleMulti.bind(this);
    this.onJmesChange = this.onJmesChange.bind(this);

    if (item.props.group && !_.get(codes, item.props.group)) fetchCodes(item.props.group);
  }

  /**
   * Change handler for group dropdown.
   * Load necessary codes for default select
   *
   * @param  {Object} selected Selected option or null
   *
   * @return void
   */
  onGroupChange(selected) {
    const { id, editValueField, fetchCodes } = this.props;
    editValueField(id, 'componentCodeGroup', selected);
    _.debounce(editValueField, 500)(id, 'defaultValue', null);

    if (selected && selected.value) fetchCodes(selected.value);
  }

  /**
   * Change handler for default value dropdown
   *
   * @param  {Mixed} selected Array of selected values, selected option or null
   *
   * @return void
   */
  onDefaultValueChange(selected) {
    const { id, editValueField } = this.props;
    let value = (_.isArray(selected))
     ? (selected.length > 0) ? selected.map(s => s.value) : null
     : (selected) ? [selected.value] : null;

     // We have to save defaults for all languages.
     // imho this only makes sense in case of input / textarea. Take same value for all
     // languages. Adjust this if customer wants different configuration for each language
     if (!_.isNull(value)) value = {
       de: value,
       fr: value,
       en: value
     };

    editValueField(id, 'defaultValue', value);
  }

  onJmesChange(language, ev) {
    const { id, item, editValueField } = this.props;
    const defaults = get(item, 'props.defaults', {});
    const value = get(ev, 'target.value');

    editValueField(id, 'defaultValue', { ...defaults, [language]: [`jmes:${value.replace('jmes:', '')}`] });
  }

  /**
   * Toggle the multi flag.
   * Reset defauls and debounce execution
   *
   * @return void
   */
  toggleMulti() {
    const { id, editValueField } = this.props;
    // Reset defaults if multi changes
    editValueField(id, 'defaultValue', null);
    _.debounce(editValueField, 500)(id, 'multi');
  }

  toggleJmesInput(language) {
    const { isJmesInput } = this.state;
    const { id, item, editValueField } = this.props;
    const defaults = get(item, 'props.defaults', {});

    this.setState({ isJmesInput: { ...isJmesInput, [language]: !isJmesInput[language] } });
    editValueField(id, 'defaultValue', { ...defaults, [language]: [] });
  }

  render() {
    const { id, item, codeGroups, codes, language } = this.props;
    const codeOptions = codeGroups.map(o=>({label: o, value: o}));
    const defaults = _.get(item, `props.defaults.${language}`, []);

    return (
      <React.Fragment>
        <FormGroup controlId="inputFieldSubType">
          <Col componentClass={ControlLabel} sm={3}>
            Codegruppe
          </Col>
          <Col sm={9}>
            <Select
              placeholder="Codegruppe wählen"
              clearable={false}
              name={`components.${id}.props.type`}
              options={codeOptions}
              value={item.props.group}
              onChange={this.onGroupChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            Mehrfachauswahl
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
          <Toggle
              checked={_.get(item, 'props.multi')===undefined?false:item.props.multi}
              onChange={this.toggleMulti}
          />
          </Col>
        </FormGroup>

      {(item.props.group) ? (
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Standard Wert
          </Col>

          <Col sm={9}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <Toggle checked={get(this, `state.isJmesInput.${language}`, false)} onChange={() => this.toggleJmesInput(language)} />
              </div>
              <div style={{ paddingLeft: '10px' }}>Pfad manuell eingeben</div>
            </div>

            {!get(this, `state.isJmesInput.${language}`, false) && (
              <Select
                value={(item.props.multi) ? defaults : defaults[0]}
                options={(codes[item.props.group]) ? codes[item.props.group].map(c => ({ value: c.id, label: _.get(c, `text.${language}`) })) : null}
                onChange={this.onDefaultValueChange}
                multi={item.props.multi}
              />
            )}

            {get(this, `state.isJmesInput.${language}`, false) && (
              <FormControl
                value={get(defaults, `0`, '')}
                type={item.props.type}
                onChange={(ev) => this.onJmesChange(language, ev)}
              />
            )}

          </Col>
        </FormGroup>
      ) : null}
    </React.Fragment>
    );
  }
}

CodeSelectConfiguration.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  item: PropTypes.object.isRequired,
  codeGroups: PropTypes.array,
  codes: PropTypes.object,
  fetchCodes: PropTypes.func,
  editValueField: PropTypes.func,
  language: PropTypes.string,
  languages: PropTypes.array,
};

CodeSelectConfiguration.defaultProps = {
  editValueField: _.noop,
  codeGroups: [],
  codes: {},
  fetchCodes: _.noop,
  language: 'de',
  languages: ['de', 'fr', 'en'],
};

export default CodeSelectConfiguration;

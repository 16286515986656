import {
  FLP_GET_PENDING,
  FLP_GET_FULFILLED,
  FLP_GET_REJECTED,
  CORETYPE_GET_PENDING,
  CORETYPE_GET_FULFILLED,
  CORETYPE_GET_REJECTED,
  DURATION_GET_PENDING,
  DURATION_GET_FULFILLED,
  DURATION_GET_REJECTED,
  LIBOR_GET_PENDING,
  LIBOR_GET_FULFILLED,
  LIBOR_GET_REJECTED,
} from '../actions/FinancingLoanProductActions';

export default function FinancingLoanProductReducer(
  state = {
    loanProducts: [],
    coreTypes: [],
    durations: [],
    forwards: [],
    libor: {},
    error: {},
    requesting: false,
    hasErro: false,
  },
  action,
) {
  switch (action.type) {
    case FLP_GET_PENDING: {
      return { ...state, requesting: true, hasError: false };
    }
    case FLP_GET_FULFILLED: {
      return { ...state, requesting: false, loanProducts: action.data };
    }
    case FLP_GET_REJECTED: {
      return {
        ...state, requesting: false, hasError: true, error: action.data,
      };
    }
    case CORETYPE_GET_PENDING: {
      return {
        ...state, requesting: true, hasError: false, coreTypes: [],
      };
    }
    case CORETYPE_GET_FULFILLED: {
      return { ...state, requesting: false, coreTypes: [...state.coreTypes, action.data] };
    }
    case CORETYPE_GET_REJECTED: {
      return {
        ...state, requesting: false, hasError: true, error: action.data,
      };
    }
    case DURATION_GET_PENDING: {
      return {
        ...state, requesting: true, hasError: false, durations: [],
      };
    }
    case DURATION_GET_FULFILLED: {
      const duration = action.duration;
      // embed refiRate in duration object
      duration.refiRate = action.refiRate;
      duration.forwards = action.forwards;
      duration.rate = action.rate;
      return { ...state, requesting: false, durations: [...state.durations, duration] };
    }
    case DURATION_GET_REJECTED: {
      return {
        ...state, requesting: false, hasError: true, error: action.data,
      };
    }
    case LIBOR_GET_PENDING: {
      return { ...state, requesting: true, hasError: false };
    }
    case LIBOR_GET_FULFILLED: {
      return { ...state, requesting: false, libor: [...state.libor, action.data] };
    }
    case LIBOR_GET_REJECTED: {
      return {
        ...state, requesting: false, hasError: true, error: action.data,
      };
    }

    default: {
      return state;
    }
  }
}

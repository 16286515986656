import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  CONSULTANTS_CREATE_ACTIONS,
  CONSULTANTS_DELETE_ACTIONS,
  CONSULTANTS_FETCH_ACTIONS,
  CONSULTANTS_UPDATE_ACTIONS,
} from '../../actions/ConsultantsActions';
import { consultantsFetchSaga } from './consultantsFetchSaga';
import { consultantsCreateSaga } from './consultantsCreateSaga';
import { consultantsUpdateSaga } from './consultantsUpdateSaga';
import { consultantsDeleteSaga } from './consultantsDeleteSaga';

export function* consultantsSagas() {
  yield takeLatest(CONSULTANTS_FETCH_ACTIONS.REQUEST, consultantsFetchSaga);
  yield takeEvery(CONSULTANTS_CREATE_ACTIONS.REQUEST, consultantsCreateSaga);
  yield takeEvery(CONSULTANTS_UPDATE_ACTIONS.REQUEST, consultantsUpdateSaga);
  yield takeEvery(CONSULTANTS_DELETE_ACTIONS.REQUEST, consultantsDeleteSaga);
}

import { Actions } from '@evoja-web/redaction';

import { categoriesAction } from './Categories';
import { moduleAction } from './Module';
import { modulesAction } from './Modules';
import { toggleFavouriteAction } from './ToggleFavourite';

export const actions = Actions([
  categoriesAction,
  moduleAction,
  modulesAction,
  toggleFavouriteAction
]);

export default actions.get();

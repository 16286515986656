import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { JobProcessing } from '../components/JobProcessing/JobProcessing';
import jobProcessingActions from '../actions/JobProcessingActions';

function mapStateToProps(state) {
  return {
    definitions: state.jobProcessing.definitions,
    executed: state.jobProcessing.executed,
    executionHistory: state.jobProcessing.executionHistory,
    language: state.login.language,
    summary: state.jobProcessing.summary,
    triggerJob: state.jobProcessing.triggerJob
  };
}

function mapDispatchToProps(dispatch) {
  return {
    jobProcessingActions: bindActionCreators(jobProcessingActions, dispatch)
  };
}

export const JobProcessingPage = connect(mapStateToProps, mapDispatchToProps)(JobProcessing);

import React, { Component } from 'react';
import { Modal, InputGroup, FormControl, Button } from 'react-bootstrap';

import ConditionForm from '../Checklist/ConditionForm';

export default class Checkpoint extends Component {
  constructor() {
    super();
    this.state = { showModal: false };
  }

  modalOpen() {
    this.setState({
      showModal: true,
    });
  }

  modalClose() {
    this.setState({
      showModal: false,
      page: 1,
    });
  }

  render() {
    const {
      id, module, text, color, condition, onEdit, onEditCondition, onRemove,
    } = this.props;
    const displayCondition = condition === undefined ? '' : condition;
    const inputStyle = {
      paddingLeft: '130px',
      borderRadius: '0',
    };
    return (
      <li>
        <InputGroup>
          <div className="input-wrapper">
            <span className="input-label">ID</span>
            <FormControl
              type="text"
              name="id"
              value={id}
              style={inputStyle}
              readOnly
            />
          </div>
          <div className="input-wrapper">
            <span className="input-label">Text</span>
            <FormControl
              type="text"
              name="text"
              value={text}
              onChange={onEdit.bind(this, id, 'text')}
              onFocus={onEdit.bind(this, id, 'text')}
              style={inputStyle}
            />
          </div>
          <div className="input-wrapper">
            <span className="input-label">Color</span>
            <FormControl
              type="text"
              name="color"
              value={color}
              onChange={onEdit.bind(this, id, 'color')}
              onFocus={onEdit.bind(this, id, 'color')}
              style={inputStyle}
            />
          </div>

          <InputGroup.Addon style={{ cursor: 'pointer' }}>
            <span onClick={this.modalOpen.bind(this)}>
              <span
                style={{ cursor: 'pointer', color: displayCondition.length > 0 ? 'Blue' : 'LightGray' }}
                className="icon-046-information-circle"
              />
            </span>
            <span onClick={onRemove.bind(this, id)}>
              <i className="icon-008-bin"><span className="hidden">Remove</span></i>
            </span>
          </InputGroup.Addon>

          <Modal show={this.state.showModal} onHide={this.modalClose.bind(this)}>
            <Modal.Body>
              <ConditionForm
                ref="ConditionForm"
                module={module}
                section={0}
                index={id}
                onUpdate={onEditCondition}
                displayCondition={displayCondition}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.modalClose.bind(this)}><span className="icon-202-clear-circle" />
                Schliessen
              </Button>
            </Modal.Footer>
          </Modal>
        </InputGroup>
      </li>
    );
  }
}

import { captureException, withScope } from '@sentry/browser';
import { get } from 'lodash';


export function sentryCatchError(error, consultant) {
  const extras = {
    consultantUsername: get(consultant, 'username'),
    consultantName: `${get(consultant, 'firstName')} ${get(consultant, 'lastName')}`,
  };

  withScope((scope) => {
    scope.setExtras(extras);
    captureException(error);
  });
}

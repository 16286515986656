import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Chart, Line } from 'react-chartjs-2';

import LoadDataSet from './LoadDataSet';
import { getRandomColor } from './utils';

function DataSets({ results, colors }) {
  const loadIds = get(results, 'loads', []).map((load) => get(load, 'id'));

  return loadIds.map((loadId) => {
    const options = { borderColor: get(colors, loadId, getRandomColor()) };

    return LoadDataSet({ loadId, results, options });
  });
}

class LoadsChart extends React.Component {
  constructor(props) {
    super(props);

    this.onLegendClick = this.onLegendClick.bind(this);
  }

  onLegendClick(ev, item, datasets) {
    const { onLoadSelectionChange } = this.props;
    const { datasetIndex } = item;
    const loadId = get(datasets, `${datasetIndex}.loadId`);

    onLoadSelectionChange(loadId);
  }

  render() {
    const {
      loadColors,
      results
    } = this.props;
    const { onLegendClick } = this;
    const datasets = DataSets({ results, colors: loadColors });

    const data = {
      labels: [
        'spoolingInitializeSeconds',
        'spoolingTimeSeconds',
        'loadDataInfileTimeSeconds',
        'dataProviderInitializeSeconds',
        'dataProviderWorkingSeconds',
        'mongoDbPostProcessingSeconds',
        'mongoDbDatabaseWriteTimeSeconds'
      ],
      datasets
    };

    const options = {
      legend: {
        onClick: function(ev, item) {
          onLegendClick(ev, item, datasets);

          Chart.defaults.global.legend.onClick.call(this, ev, item);
        }
      }
    };

    return (
      <div className="bulk-loads-metrics-charts--loads-chart">
        <Line data={data} options={options} />
      </div>
    );
  }
}

LoadsChart.propTypes = {
  loadColors: PropTypes.object,
  onLoadSelectionChange: PropTypes.func,
  results: PropTypes.object
};

LoadsChart.defaultProps = {
  loadColors: {},
  onLoadSelectionChange: noop,
  results: {}
};

export default LoadsChart;

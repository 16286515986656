import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { HOLIDAYS_LOAD_ACTIONS } from '../../actions/HolidaysActions';

export function* holidaysLoadSaga() {
  try {
    yield put(HOLIDAYS_LOAD_ACTIONS.pending());
    const { data: holidays } = yield call(
      axios,
      { url: `${EndpointName.FINANCING_HOLIDAYS}/?limit(9999)` },
    );
    yield put(HOLIDAYS_LOAD_ACTIONS.success(holidays));
  } catch (error) {
    yield put(HOLIDAYS_LOAD_ACTIONS.failure(error));
  }
}

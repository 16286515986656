import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import { handleGravitonError } from '../utils';
import PageContainer from '../components/Common/PageContainer';
import { SearchBlock } from '../components/Common/SearchBlock';
import { AnalyticsActiveUsers } from '../components/Analytics/AnalyticsActiveUsers';
import { getCommonSearch, getLanguage } from '../selectors/commonSelectors';
import {
  ANALYTICS_ACTIVE_USERS_LOAD,
  ANALYTICS_HISTORY_SEARCH,
  ANALYTICS_HISTORY_SEARCH_DETAILS,
} from '../actions/AnalyticsActions';
import { AnalyticsMenu } from '../components/Analytics/AnalyticsMenu';
import { AnalyticsHistory } from '../components/Analytics/AnalyticsHistory';
import {
  getAnalyticsError,
  getAnalyticsLoading,
  getAnalyticsTexts,
} from '../selectors/Analytics/analyticsCommonSelectors';
import { getAnalyticsActiveUsersList } from '../selectors/Analytics/analyticsActveUsersSelectors';
import {
  getAnalyticsHistoryItems,
  getAnalyticsHistoryItemsDetails,
  getAnalyticsHistoryLoading,
  getHistoryUniqUsersStatistic,
} from '../selectors/Analytics/analyticsHistoryUsersSelectors';

class AnalyticsPageWithState extends React.Component {
  constructor(props) {
    super(props);

    props.loadActiveUsers();
  }

  renderPage() {
    const { match, activeUsers, texts, historyLoading, language } = this.props;
    const { historyItems, historySearch } = this.props;
    const { historyItemsDetails, historySearchDetails, historyItemsDetailsStat } = this.props;

    const page = get(match, 'params.page');

    switch (page) {
      case 'history':
        return (
          <AnalyticsHistory
            loading={historyLoading}
            search={historySearch}
            searchDetails={historySearchDetails}
            items={historyItems}
            itemsDetails={historyItemsDetails}
            itemsDetailsStatistic={historyItemsDetailsStat}
            text={texts.textHistory}
            textDetails={texts.textHistoryDetails}
            locale={language}
          />
        );

      default:
        return (
          <AnalyticsActiveUsers
            users={activeUsers}
            text={texts.textActiveUsers}
            textDetails={texts.textActiveUsersDetails}
          />
        );
    }
  }

  render() {
    const {
      loading,
      error,
      match
    } = this.props;
    const page = get(match, 'params.page');

    if (error) {
      toast.error(handleGravitonError(error));
    }
    const pages = [
      { title: <FormattedMessage id="analytics_page_menuActiveUsers" />, value: '' },
      { title: <FormattedMessage id="analytics_page_menuHistory" />, value: 'history' },
    ];
    const header = page === 'history' ? null : <SearchBlock />;

    return (
      <PageContainer
        title={<FormattedMessage id="analytics_page_title" />}
        header={header}
        requesting={loading}
      >
        <AnalyticsMenu pages={pages} activePage={page} />
        {this.renderPage()}
      </PageContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: getAnalyticsLoading,
  error: getAnalyticsError,
  language: getLanguage,
  search: getCommonSearch,
  activeUsers: getAnalyticsActiveUsersList,
  texts: getAnalyticsTexts,
  historyLoading: getAnalyticsHistoryLoading,
  historyItems: getAnalyticsHistoryItems,
  historyItemsDetails: getAnalyticsHistoryItemsDetails,
  historyItemsDetailsStat: getHistoryUniqUsersStatistic,
});

const mapDispatchToProps = {
  loadActiveUsers: () => ANALYTICS_ACTIVE_USERS_LOAD.request(),
  historySearch: (date) => ANALYTICS_HISTORY_SEARCH.request({ date }),
  historySearchDetails: (startDate, endDate) => ANALYTICS_HISTORY_SEARCH_DETAILS.request({ startDate, endDate }),
};

export const AnalyticsPage = connect(mapStateToProps, mapDispatchToProps)(
  withRouter(AnalyticsPageWithState)
);

import { takeLatest} from 'redux-saga/effects';

import actions from '../../actions/Environment';
import getConfig from './getConfig';

const { CONFIG_REQUEST } = actions;

export default function* environmentRootSaga() {
  yield takeLatest(CONFIG_REQUEST, getConfig);
}

import './AnalyticsLogsSearchResultsComparison.css';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Table, TableCell, TableHead, TableRow } from '../Common/Table';
import { DATE_TIME_FORMAT } from '../../constants/DateTime';
import { PROP_TYPES_DETAILS } from './AnalyticsLogsPropTypes';
import { FormattedGravitonDate } from '../FormattedGravitonDate';
import Diff from '../Diff';

export function AnalyticsLogsSearchResultsComparison({ comparisonDetails }) {
  return (
    <Table className="analytics-logs-results-comparison">
      <TableHead>
        <TableCell className="analytics-logs-results-comparison__label"/>
        <TableCell className="analytics-logs-results-comparison__value">
          {comparisonDetails.first.id}
        </TableCell>
        <TableCell className="analytics-logs-results-comparison__value">
          {comparisonDetails.second.id}
        </TableCell>
      </TableHead>

      <TableRow>
        <TableCell className="analytics-logs-results-comparison__label">
          <FormattedMessage id="analyticsLogs_searchResultsDetails_username"/>
        </TableCell>
        <TableCell className="analytics-logs-results-comparison__value">
          {comparisonDetails.first.username}
        </TableCell>
        <TableCell className="analytics-logs-results-comparison__value">
          {comparisonDetails.second.username}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell className="analytics-logs-results-comparison__label">
          <FormattedMessage id="analyticsLogs_searchResultsDetails_requestUri"/>
        </TableCell>
        <TableCell className="analytics-logs-results-comparison__value">
          {comparisonDetails.first.requestUri}
        </TableCell>
        <TableCell className="analytics-logs-results-comparison__value">
          {comparisonDetails.second.requestUri}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell className="analytics-logs-results-comparison__label">
          <FormattedMessage id="analyticsLogs_searchResultsDetails_createdAt"/>
        </TableCell>
        <TableCell className="analytics-logs-results-comparison__value">
          <FormattedGravitonDate value={comparisonDetails.first.createdAt} format={DATE_TIME_FORMAT}/>
        </TableCell>
        <TableCell className="analytics-logs-results-comparison__value">
          <FormattedGravitonDate value={comparisonDetails.second.createdAt} format={DATE_TIME_FORMAT}/>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell className="analytics-logs-results-comparison__label">
          <FormattedMessage id="analyticsLogs_searchResultsDetails_requestBody"/>
        </TableCell>
        <TableCell className="analytics-logs-results-comparison__value analytics-logs-results-comparison__pre"
                   colSpan={2}>
          <Diff type="json"
                inputA={comparisonDetails.first.requestBody}
                inputB={comparisonDetails.second.requestBody}
          />
        </TableCell>
      </TableRow>
    </Table>
  );
}

AnalyticsLogsSearchResultsComparison.propTypes = {
  comparisonDetails: PropTypes.shape({
    first: PROP_TYPES_DETAILS,
    second: PROP_TYPES_DETAILS,
  }),
};

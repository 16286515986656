export default {
  key: 'hasBusinessMailContact',
  label: 'Kontaktinformation Email vom Type Business hinterlegt',
  validation: 'hasBusinessMailContact',
  availableFor: ['ConsultantSelect'],
  get: function(params) {
    return {
      contacts: {
        type: 'plainArray',
        validations: {
          hasBusinessMailContact: []
        }
      }
    };
  }
}

import React from 'react';
import { PdfPreview } from '@evoja-web/react-document-preview';

import './ApiClientPdfPreview.css';
import { Pagination } from '../Common/Pagination';

export class ApiClientPdfPreview extends React.PureComponent {
  state = {
    activePage: 1,
    countPages: 0,
  };

  render() {
    return (
      <div className="api-client-pdf-preview">
        {this.renderPdf()}
        {this.renderPagination()}
      </div>
    );
  }

  renderPdf() {
    const { activePage } = this.state;
    const { blob } = this.props;
    if (!blob) {
      return null;
    }

    return (
      <div className="api-client-pdf-preview__content">
        <PdfPreview
          blob={blob}
          errorTitle={<span className="icon-094-warning api-client-pdf-preview__error" />}
        />
      </div>
    );
  }

  renderPagination() {
    const { activePage, countPages } = this.state;
    if (countPages < 2) {
      return null;
    }
    return (
      <div className="api-client-pdf-preview__pagination">
        <Pagination
          prev={true}
          next={true}
          first={true}
          last={true}
          boundaryLinks={true}
          ellipsis={true}
          items={countPages}
          maxButtons={4}
          activePage={activePage}
          onSelect={this.onSelect}
        />
      </div>
    );
  }

  onLoad = ({ numPages }) => this.setState({ countPages: numPages });
  onSelect = (activePage) => this.setState({ activePage });
}

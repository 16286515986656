import { takeLatest } from 'redux-saga/effects';
import {
  WORKGUIDE_STATUS_ENTITIY_CODE_LOAD,
  ACCOUNT_WORKGUIDES_LOAD,
  ACCOUNT_WORKGUIDES_UPDATE,
  ACCOUNT_WORKGUIDES_CLEAR,
  NETTED_ACCOUNTS_WORKGUIDES_LOAD,
  NETTED_ACCOUNTS_WORKGUIDES_UPDATE,
  ACCOUNT_SEARCH_LOAD
} from '../../actions/WorkguideStatusActions';
import { workguideStatusGetEntityCodesSaga } from './workguideStatusGetEntityCodesSaga';
import { workguideStatusGetAccountWorkguidesSaga } from './workguideStatusGetAccountWorkguidesSaga';
import { workguideStatusUpdateWorkguideSaga } from './workguideStatusUpdateWorkguideSaga';
import { workguideStatusClearAccountWorkguidesSaga } from './workguideStatusClearAccountWorkguidesSaga';
import { workguideStatusGetNettedAccountsWorkguidesSaga } from './workguideStatusGetNettedAccountsWorkguidesSaga';
import { workguideStatusUpdateNettedAccountsWorkguidesSaga } from './workguideStatusUpdateNettedAccountsWorkguidesSaga';
import { workguideStatusSearchAccountSaga } from './workguideStatusSearchAccountSaga';

export function* workguideStatusSagas() {
  yield takeLatest(WORKGUIDE_STATUS_ENTITIY_CODE_LOAD.REQUEST, workguideStatusGetEntityCodesSaga);
  yield takeLatest(ACCOUNT_WORKGUIDES_LOAD.REQUEST, workguideStatusGetAccountWorkguidesSaga);
  yield takeLatest(ACCOUNT_WORKGUIDES_UPDATE.REQUEST, workguideStatusUpdateWorkguideSaga);
  yield takeLatest(ACCOUNT_WORKGUIDES_CLEAR.REQUEST, workguideStatusClearAccountWorkguidesSaga);
  yield takeLatest(NETTED_ACCOUNTS_WORKGUIDES_LOAD.REQUEST, workguideStatusGetNettedAccountsWorkguidesSaga);
  yield takeLatest(NETTED_ACCOUNTS_WORKGUIDES_UPDATE.REQUEST, workguideStatusUpdateNettedAccountsWorkguidesSaga);
  yield takeLatest(ACCOUNT_SEARCH_LOAD.REQUEST, workguideStatusSearchAccountSaga);
}

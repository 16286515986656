import { HOLIDAYS_EDIT_ACTIONS } from '../../actions/HolidaysActions';

export function HolidaysEditReducer(state, action) {
  switch (action.type) {
    case HOLIDAYS_EDIT_ACTIONS.SUCCESS:
      return { ...state, holidaysLists: action.data.holidaysLists };
    default:
      return state;
  }
}

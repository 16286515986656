import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import Toggle from 'react-toggle';

const EditableIfPending = React.memo(({
  editValueField,
  workguide
}) => {
  const value = get(workguide, 'editableIfPending', false);

  return (
    <div className="workguide-detail-general-editable-if-pending">
      <FormGroup>
        <ControlLabel>
          Bearbeitung im Status "pendent" möglich
        </ControlLabel>

        <br />

        <Toggle
          checked={value}
          onChange={() => editValueField('', 'workguidePropery', { key: 'editableIfPending', value: !value })}
        />
      </FormGroup>
    </div>
  );
});

EditableIfPending.propTypes = {
  editValueField: PropTypes.func,
  workguide: PropTypes.object.isRequired
};

EditableIfPending.defaultProps = {
  editValueField: noop
};

export default EditableIfPending;

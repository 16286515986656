import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import NumberFormat from 'react-number-format';
import { FormGroup, ControlLabel } from 'react-bootstrap';

class IsDivisibleBy extends React.Component {
  constructor(props){
    super(props);

    this.onChange = this.onChange.bind(this);
  }
  onChange({ floatValue }){
    const { onChange } = this.props

    onChange([floatValue])
  }

  getCurrentValue() {
    const { validation, component } = this.props;
    return component.props.validations[validation.key].isGe[0];
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { component } = this.props;
    const params = get(component, 'props.validations.isDivisibleBy.isDivisibleBy', []);

    return (
      <div style={{ paddingRight: '15px', paddingLeft: '15px' }}>
        <FormGroup>
          <ControlLabel>
            Minimum Wert
          </ControlLabel>

          <NumberFormat
            id="min-value"
            className="form-control"
            value={get(params, 0)}
            onValueChange={this.onChange}
            thousandSeparator="'"
            decimalSeparator="."
            decimalScale={2}
          />
        </FormGroup>
      </div>
    );
  }
}

IsDivisibleBy.propTypes = {
  component: PropTypes.object.isRequired
};

IsDivisibleBy.defaultProps = {

};

export default IsDivisibleBy;

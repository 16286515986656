import { chainReducers } from '../../utils';
import { FinancingRatesLoadReducer } from './FinancingRatesLoadReducer';
import { FinancingRatesSaveReducer } from './FinancingRatesSaveReducer';
import { FinancingRatesUpdateBaseRateReducer } from './FinancingRatesUpdateBaseRateReducer';
import { FinancingRatesUpdateRefiRateReducer } from './FinancingRatesUpdateRefiRateReducer';
import { FinancingRatesUpdateForwardsReducer } from './FinancingRatesUpdateForwardsReducer';

export const FinancingRatesReducer = chainReducers(
  FinancingRatesLoadReducer,
  FinancingRatesSaveReducer,
  FinancingRatesUpdateBaseRateReducer,
  FinancingRatesUpdateRefiRateReducer,
  FinancingRatesUpdateForwardsReducer,
);

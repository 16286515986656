import './PermissionGroupItem.css';

import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Select from 'react-select';
import { get } from 'lodash'
import { TableCell, TableRow } from '../Common/Table';
import { EMAIL_SUBSCRIPTIONS_PROP_TYPES_ITEM } from './PermissionGroupPropTypes';
import { getEmailSubscriptionsLoading } from '../../selectors/EmailSubscriptions/emailSubscriptionsCommonSelectors';
import { getLanguage } from '../../selectors/commonSelectors';
import { CONSULTANT_UPDATE } from '../../actions/PermissionGroupAction';
import { FormattedMessage } from 'react-intl'

class PermissionGroupItemConnected extends React.PureComponent {

  state = {
    isModalOpen: false,
    isConfirmationDialogOpen: false,
  };

  render() {
    const { item, locale, entityCodes } = this.props;
    return (
      <React.Fragment>
        <TableRow className="email-subscriptions-item">
          <TableCell>
            {item.view.firstName}
          </TableCell>
          <TableCell>
            {item.view.lastName}
          </TableCell>
          <TableCell>
            {item.view.username}
          </TableCell>
          <TableCell>
            {item.view.tenant}
          </TableCell>
          <TableCell>
          <Select
              multi
              placeholder={<FormattedMessage id="permissionGroup_placeholder"/>}
              value={this.getCurrentPermissionGroup()}
              options={entityCodes.map(el => ({ label: el.text[locale], value: el.number }))}
              onChange={(option) => this.selectedUserGroup(option)}
            />
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  getCurrentPermissionGroup(){
    const { item, locale, entityCodes } = this.props;
    const currentPermissions = get(item, 'form.permission', []);
    return entityCodes.map(el => {
      if (currentPermissions.includes(el.number)) {
        return { label: el.text[locale], value: el.number }
      }
      return undefined;
    }).filter(el => el !== undefined);
  }

  selectedUserGroup(option){
    const { item, updateConsultant } = this.props;

    // dispatch updateConsultant action & update state
    const newItem = { id: item.id, permission: option.map(el => el.value) }
    updateConsultant(newItem);
  }

}

const mapStateToProps = createStructuredSelector({
  loading: getEmailSubscriptionsLoading,
  locale: getLanguage,
});

const mapDispatchToProps = {
  updateConsultant: (newItem) => CONSULTANT_UPDATE.request(newItem),
};

PermissionGroupItemConnected.propTypes = {
  entityCodes: PropTypes.array,
  locale: PropTypes.string,
  items: PropTypes.array
}
PermissionGroupItemConnected.defaultProps = {
  entityCodes: [],
  locale: 'de',
  items: [],
}

export const PermissionGroupItem = connect(mapStateToProps, mapDispatchToProps)(PermissionGroupItemConnected);
PermissionGroupItem.propTypes = {
  item: EMAIL_SUBSCRIPTIONS_PROP_TYPES_ITEM.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import update from 'immutability-helper';

import Form from './Form';
import ListItem from './ListItem';

class DynamicPropList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      add: false
    };

    this.toggleAdd = this.toggleAdd.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  toggleAdd() {
    const { add } = this.state;

    this.setState({ add: !add });
  }

  onSubmit(data, index = -1) {
    const {
      id,
      editValueField,
      mapping
    } = this.props;

    const updated = index > -1
      ? update(mapping, { $splice: [[index, 1, data]] })
      : update(mapping, { $push: [data] });

    this.setState({ add: false });

    editValueField(id, 'prop', { key: 'dynamicProps', value: updated });
  }

  onRemove(index = -1) {
    const {
      id,
      editValueField,
      mapping
    } = this.props;

    if (index > -1) {
      const updated = update(mapping, { $splice: [[index, 1]] });
      editValueField(id, 'prop', { key: 'dynamicProps', value: updated });
    }
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { add } = this.state;
    const { mapping } = this.props;

    const children = mapping.map((data, index) => {
      return (
        <ListItem
          data={data}
          onRemove={() => this.onRemove(index)}
          onSubmit={(data) => this.onSubmit(data, index)}
        />
      );
    });

    return (
      <React.Fragment>
        <h4>
          Mapping

          {!add && (
            <i
              className="glyphicon glyphicon-plus"
              style={{ fontSize: '15px' }}
              onClick={this.toggleAdd}
            />
          )}
        </h4>

        {add && (
          <Form
            onCancel={this.toggleAdd}
            onSubmit={this.onSubmit}
          />
        )}

        <ListGroup className="workguide-dynamic-props-list">
          <ListGroupItem className="workguide-dynamic-props-list-item">
            <div style={{ width: '30%' }}>
              <strong>
                Pfad:
              </strong>
            </div>

            <div style={{ width: '70%' }}>
              <strong>
                JMES Path Instruktion
              </strong>
            </div>
          </ListGroupItem>

          {children}
        </ListGroup>
      </React.Fragment>
    );
  }
}

DynamicPropList.propTypes = {
  editValueField: PropTypes.func,
  mapping: PropTypes.array
};

DynamicPropList.defaultProps = {
  editValueField: noop,
  mapping: []
};

export default DynamicPropList;

import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import _, { first } from 'lodash';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';

export const GET_WORKGUIDE = 'adminUI/workguide/GET_WORKGUIDE';
export const PUT_WORKGUIDE = 'adminUI/workguide/PUT_WORKGUIDE';
export const DELETE_WORKGUIDE = 'adminUI/workguide/DELETE_WORKGUIDE';
export const SET = 'adminUI/workguide/SET';
export const WORKGUIDE_CODES_REQUEST = 'adminUI/workguide/WORKGUIDE_CODES_REQUEST';
export const WORKGUIDE_CODES_PENDING = 'adminUI/workguide/WORKGUIDE_CODES_PENDING';
export const WORKGUIDE_CODES_FULFILLED = 'adminUI/workguide/WORKGUIDE_CODES_FULFILLED';
export const WORKGUIDE_CODES_REJECTED = 'adminUI/workguide/WORKGUIDE_CODES_REJECTED';

export const GET_METADATA = 'adminUI/workguide/GET_METADATA';
export const GET_METADATA_REQUEST = 'adminUI/workguide/GET_METADATA_REQUEST';
export const GET_METADATA_PENDING = 'adminUI/workguide/GET_METADATA_PENDING';
export const GET_METADATA_FULFILLED = 'adminUI/workguide/GET_METADATA_FULFILLED';
export const GET_METADATA_REJECTED = 'adminUI/workguide/GET_METADATA_REJECTED';

export const GET_CONSULTANT = 'adminUI/workguide/GET_CONSULTANT';
export const GET_CONSULTANT_REQUEST = 'adminUI/workguide/GET_CONSULTANT_REQUEST';
export const GET_CONSULTANT_PENDING = 'adminUI/workguide/GET_CONSULTANT_PENDING';
export const GET_CONSULTANT_FULFILLED = 'adminUI/workguide/GET_CONSULTANT_FULFILLED';
export const GET_CONSULTANT_REJECTED = 'adminUI/workguide/GET_CONSULTANT_REJECTED';

export const GET_CODEGROUP = 'adminUI/workguide/GET_CODEGROUP';
export const GET_CODEGROUP_REQUEST = 'adminUI/workguide/GET_CODEGROUP_REQUEST';
export const GET_CODEGROUP_PENDING = 'adminUI/workguide/GET_CODEGROUP_PENDING';
export const GET_CODEGROUP_FULFILLED = 'adminUI/workguide/GET_CODEGROUP_FULFILLED';
export const GET_CODEGROUP_REJECTED = 'adminUI/workguide/GET_CODEGROUP_REJECTED';

export const WORKGUIDE_SAVE_CODES_REQUEST = 'adminUI/workguide/WORKGUIDE_SAVE_CODES_REQUEST';
export const WORKGUIDE_SAVE_CODES_PENDING = 'adminUI/workguide/WORKGUIDE_SAVE_CODES_PENDING';
export const WORKGUIDE_SAVE_CODES_FULFILLED = 'adminUI/workguide/WORKGUIDE_SAVE_CODES_FULFILLED';
export const WORKGUIDE_SAVE_CODES_REJECTED = 'adminUI/workguide/WORKGUIDE_SAVE_CODES_REJECTED';

export const WORKGUIDE_PRODUCTGROUPS_REQUEST = 'adminUI/workguide/WORKGUIDE_PRODUCTGROUPS_REQUEST';
export const WORKGUIDE_PRODUCTGROUPS_PENDING = 'adminUI/workguide/WORKGUIDE_PRODUCTGROUPS_PENDING';
export const WORKGUIDE_PRODUCTGROUPS_FULFILLED = 'adminUI/workguide/WORKGUIDE_PRODUCTGROUPS_FULFILLED';
export const WORKGUIDE_PRODUCTGROUPS_REJECTED = 'adminUI/workguide/WORKGUIDE_PRODUCTGROUPS_REJECTED';

export const WORKGUIDE_SERVICES_REQUEST = 'adminUI/workguide/WORKGUIDE_SERVICES_REQUEST';
export const WORKGUIDE_SERVICES_PENDING = 'adminUI/workguide/WORKGUIDE_SERVICES_PENDING';
export const WORKGUIDE_SERVICES_FULFILLED = 'adminUI/workguide/WORKGUIDE_SERVICES_FULFILLED';
export const WORKGUIDE_SERVICES_REJECTED = 'adminUI/workguide/WORKGUIDE_SERVICES_REJECTED';

export const WORKGUIDE_SERVICE_DEFINITION_REQUEST = 'adminUI/workguide/WORKGUIDE_SERVICE_DEFINITION_REQUEST';
export const WORKGUIDE_SERVICE_DEFINITION_PENDING = 'adminUI/workguide/WORKGUIDE_SERVICE_DEFINITION_PENDING';
export const WORKGUIDE_SERVICE_DEFINITION_FULFILLED = 'adminUI/workguide/WORKGUIDE_SERVICE_DEFINITION_FULFILLED';
export const WORKGUIDE_SERVICE_DEFINITION_REJECTED = 'adminUI/workguide/WORKGUIDE_SERVICE_DEFINITION_REJECTED';

export const WORKGUIDE_USAGE_REQUEST = 'adminUI/workguide/WORKGUIDE_USAGE_REQUEST';
export const WORKGUIDE_USAGE_PENDING = 'adminUI/workguide/WORKGUIDE_USAGE_PENDING';
export const WORKGUIDE_USAGE_FULFILLED = 'adminUI/workguide/WORKGUIDE_USAGE_FULFILLED';
export const WORKGUIDE_USAGE_REJECTED = 'adminUI/workguide/WORKGUIDE_USAGE_REJECTED';

export function setData({ key, value }) {
  return (dispatch) => {
    dispatch({
      type: SET,
      key,
      value
    });
  };
}

export const getWorkguidesMetadataRequest = (toload = { consultants: true, codegroups: true }) => {
  return ({ type: GET_METADATA_REQUEST, toload });
};

export const getConsultantsRequest = (props) => ({ type: GET_CONSULTANT_REQUEST, ...props });
export const getCodeGroupsRequest = (props) => ({ type: GET_CODEGROUP_REQUEST, ...props });

export const getWorkguideRequest = (id) => (
  { type: `${GET_WORKGUIDE}_REQUEST`, id }
);

export function* getWorkguide(action) {
  yield put({ type: `${GET_WORKGUIDE}_PENDING` });

  try {
    const query = QueryBuilder()
      .eq('id', action.id, { type: 'string' })
      .limit(1)
      .getQueryString();

    const url = `/work/workguide/${query}`;
    const result = yield call(handleNextHeaders, { url, noCache: true });

    yield put({ type: `${GET_WORKGUIDE}_FULFILLED`, payload: first(result) });
  } catch (e) {
    yield put({ type: `${GET_WORKGUIDE}_REJECTED`, payload: e });
  }
}

export const updateWorkguideRequest = (workguide) => ({
  type: PUT_WORKGUIDE, workguide
});

export const deleteWorkguideRequest = (workguideId) => ({
  type: `${DELETE_WORKGUIDE}_REQUEST`, workguideId
});

export function* deleteWorkguide(action) {
  try {
    yield put({ type: `${DELETE_WORKGUIDE}_PENDING` });
    yield call(axios.delete, `/work/workguide/${action.workguideId}`);
    yield put({ type: `${DELETE_WORKGUIDE}_FULFILLED` });
  } catch (e) {
    yield put({ type: `${DELETE_WORKGUIDE}_REJECTED` });
  }
}

export function workguideCodesRequest(group) {
  return { type: WORKGUIDE_CODES_REQUEST, group };
}

export function workguideProductGroupsRequest() {
  return { type: WORKGUIDE_PRODUCTGROUPS_REQUEST };
}

export function workguideServicesRequest() {
  return { type: WORKGUIDE_SERVICES_REQUEST };
}

export function workguideServiceDefinitionRequest(service) {
  return { type: WORKGUIDE_SERVICE_DEFINITION_REQUEST, service };
}

export function workguideSaveCodesRequest(props) {
  return { type: WORKGUIDE_SAVE_CODES_REQUEST, ...props };
}

export const fetchWorkguideUsageRequest = () => {
  return ({ type: WORKGUIDE_USAGE_REQUEST });
};

export function* fetchWorkguideUsage() {
  yield put({ type: WORKGUIDE_USAGE_PENDING });
  try {
    const { data } = yield call(axios.get, '/work/workguide/usage/?limit(999)');
    yield put({ type: WORKGUIDE_USAGE_FULFILLED, payload: data });
  } catch (error) {
    yield put({ type: WORKGUIDE_USAGE_REJECTED, error });
  }
}

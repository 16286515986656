import { Reducer } from '@evoja-web/redaction';
import update from 'immutability-helper';
import { get, isUndefined } from 'lodash';

import {
  ADD_ERROR,
  INCREMENT_COUNT,
  INIT_PROGRESS,
  SET_ERRORNEOUS,
  SET_FULFILLED
} from '../../actions/ReplayAuditLog/Progess';

function Progress({ total = 0 }) {
  return {
    count: 0,
    error: false,
    errors: [],
    fulfilled: false,
    requesting: true,
    total
  }
};

function addError(state, action) {
  const { dataKey, error } = action;

  return update(state, {
    progress: {
      [dataKey]: {
        errors: { $push: [[error]] }
      }
    }
  });
}

function initProgress(state, action) {
  const { dataKey, payload } = action;

  return update(state, {
    progress: {
      [dataKey]: { $set: Progress({ total: get(payload, 'total', 0) }) }
    }
  });
}

function incrementCount(state, action) {
  const { dataKey, payload } = action;
  const value = get(payload, 'value', 1);
  const total = get(payload, 'total');

  const currentValue = get(state, `progress.${dataKey}.count`, 0);
  const currentTotal = get(state, `progress.${dataKey}.total`, 0);

  return update(state, {
    progress: {
      [dataKey]: {
        count: { $set: currentValue + value },
        total: { $set: !isUndefined(total) ? total : currentTotal }
      }
    }
  });
}

function setErrorneous(state, action) {
  const { dataKey, error } = action;

  return update(state, {
    progress: {
      [dataKey]: {
        error: { $set: error },
        filfilled: { $set: false },
        requesting: { $set: false }
      }
    }
  });
}

function setFulfilled(state, action) {
  const { dataKey } = action;

  return update(state, {
    progress: {
      [dataKey]: {
        fulfilled: { $set: true },
        requesting: { $set: false }
      }
    }
  })
}

const methods = {
  [ADD_ERROR]: addError,
  [INCREMENT_COUNT]: incrementCount,
  [INIT_PROGRESS]: initProgress,
  [SET_ERRORNEOUS]: setErrorneous,
  [SET_FULFILLED]: setFulfilled
};

const defaultState = {};

export const reducer = Reducer('progress', methods, defaultState);

export default reducer.get();

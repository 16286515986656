import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';
import Toggle from 'react-toggle';
import VirtualizedSelect from 'react-virtualized-select';

class ProductSelectConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.onDefaultValueChange = this.onDefaultValueChange.bind(this);
    this.toggleMulti = this.toggleMulti.bind(this);
  }

  /**
   * Change handler for default value dropdown
   *
   * @param  {Mixed} selected Array of selected values, selected option or null
   *
   * @return void
   */
  onDefaultValueChange(selected) {
    const { id, editValueField } = this.props;
    let value = (_.isArray(selected))
     ? (selected.length > 0) ? selected.map(s => s.value) : null
     : (selected) ? [selected.value] : null;

     // We have to save defaults for all languages.
     // imho this only makes sense in case of input / textarea. Take same value for all
     // languages. Adjust this if customer wants different configuration for each language
     if (!_.isNull(value)) value = {
       de: value,
       fr: value,
       en: value
     };

    editValueField(id, 'defaultValue', value);
  }

  /**
   * Toggle the multi flag.
   * Reset defauls and debounce execution
   *
   * @return void
   */
  toggleMulti() {
    const { id, editValueField } = this.props;
    // Reset defaults if multi changes
    editValueField(id, 'defaultValue', null);
    _.debounce(editValueField, 500)(id, 'multi');
  }

  render() {
    const { productGroups, item, language } = this.props;
    const products = _.flatten(productGroups.map(g => g.products));
    const defaults = _.get(item, `props.defaults.${language}`, []);

    return (
      <React.Fragment>
        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            Mehrfachauswahl
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
          <Toggle
              checked={_.get(item, 'props.multi')===undefined?false:item.props.multi}
              onChange={this.toggleMulti}
          />
          </Col>
        </FormGroup>

        <FormGroup controlId="simpleSelectOptions">
          <Col componentClass={ControlLabel} sm={3}>
            Standard Werte
          </Col>
          <Col sm={9}>
            <VirtualizedSelect
              value={(item.props.multi) ? defaults : defaults[0]}
              options={products.map(p => ({ value: p.id, label: _.get(p, `text.${language}`) }))}
              onChange={this.onDefaultValueChange}
              multi={item.props.multi}
            />
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

ProductSelectConfiguration.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  item: PropTypes.object.isRequired,
  editValueField: PropTypes.func,
  productGroups: PropTypes.array,
  language: PropTypes.string
};

ProductSelectConfiguration.defaultProps = {
  editValueField: _.noop,
  productGroups: [],
  language: 'de'
};

export default ProductSelectConfiguration;

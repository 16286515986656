import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit, isValid } from 'redux-form';
import { toast } from 'react-toastify';
import { createSelector, createStructuredSelector } from 'reselect';
import EditContainer from '../components/EditContainer';
import { EndpointName } from '../constants/EndpointName';
import { createRef, getIdFromRef, handleGravitonError } from '../utils';
import { getLanguage } from '../selectors/commonSelectors';
import {
  getSurchargeRealestatetypes,
  getRealestatetypes,
  getLoading,
  getError,
} from '../selectors/FinancingSurchargeRealestatetype';
import {
  LOAD_SURCHARGE_REALESTATETYPE_ACTIONS,
  SAVE_SURCHARGE_REALESTATETYPE_ACTIONS,
} from '../actions/FinancingSurchargeRealestatetypeActions';
import {
  FinancingSurchargeRealestatetypeForm,
} from '../components/FinancingSurchargeRealestatetype/FinancingSurchargeRealestatetypeForm';
import {
  validateSurchargeRealestatetypeForm,
} from '../components/FinancingSurchargeRealestatetype/FinancingSurchargeRealestatetypeValidator';

const formName = 'financingSurchargeRealestatetypeForm';

class FinancingSurchargeRealestatetype extends React.Component {
  constructor(props) {
    super(props);
    props.loadData();
    this.onSubmit = this.onSubmit.bind(this);
  }

  render() {
    const { submitForm, loading, error, isFormValid } = this.props;
    if (error) {
      toast.error(handleGravitonError(error));
    }

    return (
      <EditContainer
        requesting={loading}
        title="Objektspezifische Zuschläge"
        body={this.renderOverview()}
        saveData={submitForm}
        valid={isFormValid}
      />
    );
  }

  renderOverview() {
    const {
      surchargeRealestatetypes,
      realestatetypes,
      language,
      loading,
      error,
    } = this.props;

    if (loading || error) {
      return null;
    }

    const initialValues = surchargeRealestatetypes.map(it => ({
      ...it,
      realestatetype: getIdFromRef(it.realestatetype.$ref),
    }));

    return (
      <FinancingSurchargeRealestatetypeForm
        form={formName}
        initialValues={{items: initialValues}}
        validate={validateSurchargeRealestatetypeForm}
        onSubmit={this.onSubmit}
        realestatetypes={realestatetypes}
        language={language}
      />
    );
  }

  onSubmit(formData) {
    const { saveData } = this.props;
    const updatedItems = formData.items.map((it) => ({
      ...it,
      realestatetype: {
        $ref: createRef(EndpointName.FINANCING_REALESTATETYPE, it.realestatetype),
      },
    }));
    saveData(updatedItems);
  }
}

FinancingSurchargeRealestatetype.propTypes = {
  surchargeRealestatetypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstMortgageRate: PropTypes.string.isRequired,
    secondMortgageRate: PropTypes.string.isRequired,
    withAdditionRate: PropTypes.string.isRequired,
    realestatetype: PropTypes.shape({
      $ref: PropTypes.string.isRequired,
    }),
  })).isRequired,
  realestatetypes: PropTypes.array.isRequired,
  language: PropTypes.oneOf(['de', 'en', 'fr']).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.object,
  loadData: PropTypes.func.isRequired,
  saveData: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isFormValid: PropTypes.bool.isRequired,
};

FinancingSurchargeRealestatetype.defaultProps = {
  surchargeRealestatetypes: [],
  realestatetypes: [],
  language: 'de',
  loading: true,
  error: undefined,
};

const getFormValid = createSelector([isValid(formName)], (isFormValid) => isFormValid);
const mapStateToProps = createStructuredSelector({
  surchargeRealestatetypes: getSurchargeRealestatetypes,
  realestatetypes: getRealestatetypes,
  loading: getLoading,
  error: getError,
  language: getLanguage,
  isFormValid: getFormValid,
});


const mapDispatchToProps = {
  loadData: () => LOAD_SURCHARGE_REALESTATETYPE_ACTIONS.request(),
  saveData: (items) => SAVE_SURCHARGE_REALESTATETYPE_ACTIONS.request({items}),
  submitForm: () => submit(formName),
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancingSurchargeRealestatetype);

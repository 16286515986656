import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { CodeSelect } from '@evoja-web/react-form';
import { FormGroup, ControlLabel } from 'react-bootstrap';

const WorkguideGeneralBusinessRelevance = React.memo(function WorkguideGeneralBusinessRelevance({
  codes,
  editValueField,
  workguide
}) {
  const value = get(workguide, 'businessRelevance', false);
  const brc = get(codes, 'businessRelevance', []);

  return (
    <div className="workguide-detail-general--business-relevance">
      <FormGroup>
        <ControlLabel>
          Business Relevance (Gamification score)
        </ControlLabel>

        <br />

        <CodeSelect
          id="businessRelevance"
          codes={brc}
          onChange={(id, code) => editValueField('', 'workguidePropery', { key: 'businessRelevance', value: code })}
          value={value}
        />
      </FormGroup>
    </div>
  );
});

WorkguideGeneralBusinessRelevance.propTypes = {
  codes: PropTypes.object,
  editValueField: PropTypes.func,
  workguide: PropTypes.object.isRequired
};

WorkguideGeneralBusinessRelevance.defaultProps = {
  codes: {},
  editValueField: noop
};

export default WorkguideGeneralBusinessRelevance;

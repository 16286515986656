import { call, put, select } from 'redux-saga/effects';
import { HOLIDAYS_CREATE_ACTIONS } from '../../actions/HolidaysActions';
import { getLanguage } from '../../selectors/commonSelectors';
import { getHolidays, getHolidaysList } from '../../selectors/holidaysSelectors';
import { convertDateToApi } from '../../utils/dateConverter';

export function* holidaysCreateSaga({ data: { name, startDate, endDate } }) {
  const language = yield select(getLanguage);
  const holidays = yield select(getHolidays);
  const holidaysList = yield select(getHolidaysList);

  const newItem = {
    name: {
      en: name,
      [language]: name,
    },
    startDate: yield call(convertDateToApi, startDate),
    endDate: endDate
      ? yield call(convertDateToApi, endDate)
      : yield call(convertDateToApi, startDate),
  };

  const updatedHolidays = holidays.map((list) => holidaysList && list.id === holidaysList.id
    ? { ...list, dateRanges: [...holidaysList.dateRanges, newItem] }
    : list,
  );
  yield put(HOLIDAYS_CREATE_ACTIONS.success({ holidaysLists: updatedHolidays }));
}

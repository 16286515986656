import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { CONSULTANTS_UPDATE_ACTIONS } from '../../actions/ConsultantsActions';

export function* consultantsUpdateSaga({ data: { consultant, form } }) {
  try {
    yield put(CONSULTANTS_UPDATE_ACTIONS.pending());

    let contacts = [...consultant.contacts];

    if (form.phone) {
      const existingPhoneIndex = contacts.findIndex(c => c.type === 'mobile' && c.protocol === 'tel');

      const newPhone = {
        type: 'mobile',
        protocol: 'tel',
        value: form.phone,
        uri: `tel:${form.phone}`,
      };

      if (existingPhoneIndex > -1) {
        contacts[existingPhoneIndex] = newPhone;
      } else {
        contacts.push(newPhone);
      }
    } else {
      contacts = contacts.filter(c => c.type !== 'mobile' && c.protocol !== 'tel');
    }

    if (form.email) {
      const existingEmailIndex = contacts.findIndex(c => c.type === 'business' && c.protocol === 'mailto');

      const newEmail = {
        type: 'business',
        protocol: 'mailto',
        value: form.email,
        uri: `mailto:${form.email}`,
      };

      if (existingEmailIndex > -1) {
        contacts[existingEmailIndex] = newEmail;
      } else {
        contacts.push(newEmail);
      }
    } else {
      contacts = contacts.filter(c => c.type !== 'business' && c.protocol !== 'mailto');
    }

    consultant = {
      ...consultant,
      username: form.username,
      firstName: form.firstName,
      lastName: form.lastName,
      department: form.department,
      division: form.division,
      title: form.title,
      contacts,
    };
    yield call(axios.put, `${EndpointName.PERSON_CONSULTANT}/${encodeURIComponent(consultant.id)}`, consultant);

    yield put(CONSULTANTS_UPDATE_ACTIONS.success({ consultant }));
  } catch (error) {
    yield put(CONSULTANTS_UPDATE_ACTIONS.failure(error));
  }
}

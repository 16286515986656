import axios from 'axios';
import { all, call, put, select } from 'redux-saga/effects';
import { LOAD_BASIC_PRODUCTS_ACTIONS } from '../../actions/BasicProductsActions';
import { EndpointName } from '../../constants/EndpointName';
import { mapBasicProductsToApp } from '../../services/BasicProducts';
import { getLanguage } from '../../selectors/commonSelectors';

function getAll(endpoint) {
  return `${endpoint}/?limit(9999)&sort(+order)`;
}

function entityCodes(groups) {
  const filter = `in(group,(${groups.join(',')}))`;
  return `${getAll(EndpointName.ENTITY_CODE)}&${filter}`;
}

export function* loadBasicProducts() {
  try {
    yield put(LOAD_BASIC_PRODUCTS_ACTIONS.pending());

    const [
      { data: basicProducts },
      { data: basicCategories },
      { data: basicAdditions },
      { data: basicDocuments },
      { data: coreTypes },
      { data: displayClasses },
      { data: outputClasses },
      { data: detailGroups },
      { data: changeTypes },
    ] = yield all([
      call(axios, { url: getAll(EndpointName.BASIC_PRODUCT) }),
      call(axios, { url: getAll(EndpointName.BASIC_PRODUCT_CATEGORY) }),
      call(axios, { url: getAll(EndpointName.BASIC_PRODUCT_ADDITION) }),
      call(axios, { url: getAll(EndpointName.BASIC_PRODUCT_DOCUMENT) }),

      call(axios, { url: entityCodes(['accountType', 'cardType', 'contractType', 'eServiceType']) }),
      call(axios, { url: entityCodes(['displayClass']) }),
      call(axios, { url: entityCodes(['outputClass']) }),
      call(axios, { url: entityCodes(['detailGroup']) }),
      call(axios, { url: entityCodes(['changeType']) }),
    ]);

    const language = yield select(getLanguage);
    const products = mapBasicProductsToApp(
      language,
      basicProducts,
      basicCategories,
      basicAdditions,
      basicDocuments,
    );
    const data = {
      products,

      basicProducts,
      basicCategories,
      basicAdditions,
      basicDocuments,

      coreTypes,
      displayClasses,
      outputClasses,
      detailGroups,
      changeTypes,
    };

    yield put(LOAD_BASIC_PRODUCTS_ACTIONS.success(data));
  } catch(error) {
    yield put(LOAD_BASIC_PRODUCTS_ACTIONS.failure(error));
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import _, { isUndefined, get, noop, max } from 'lodash';
import { Button } from 'react-bootstrap';
import update from 'immutability-helper';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import './Actions.css';
import Action from './Action';
import { ComponentIdMap } from '../../../packages/Workguide/index';

function getHighestOrder(actions) {
  return max(actions.map((a) => get(a, 'order')));
}

const SortableItem = SortableElement(Action);

const SortableList = SortableContainer(({
  actions,
  onChange,
  onRemove,
  ...rest
}) => {
  const children = actions.map((item, index) => {
    return (
      <SortableItem
        key={index}
        index={index}
        action={item}
        onSubmit={(data) => onChange(index, data)}
        onRemove={() => onRemove(index)}
        onChange={(data) => onChange(index, data)}
        {...rest}
      />
    );
  });

  return (
    <ul>
      {children}
    </ul>
  );
});

class WorkguideActions extends React.Component {
  constructor(props) {
    super(props);

    const { codes, fetchCodes } = props;
    const workguideStatus = get(codes, 'workguideStatus');
    if (isUndefined(workguideStatus) || get(workguideStatus, 'length', 0) === 0) fetchCodes(['workguideStatus']);

    this.state = {
      add: false
    };

    this.onAdd = this.onAdd.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  onAdd() {
    this.setState({ add: true });
  }

  onChange(index, action) {
    const { actions, onChange } = this.props;
    let updated = actions;
    if (index > -1) {
      updated = update(updated, {
        $splice: [[index, 1, action]]
      });
    } else {
      updated = update(updated, {
        $push: [action]
      });
    }

    this.setState({ add: false });
    onChange(updated);
  }

  onRemove(index) {
    const { actions, onChange } = this.props;

    if (_.has(actions, index)) {
      const updated = update(actions, {
        $splice: [[index, 1]]
      });

      onChange(updated);
    }
  }

  onCancel() {
    this.setState({ add: false });
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { actions, onChange } = this.props;
    const updated = arrayMove(actions, oldIndex, newIndex)
      .map((item, index) => ({ ...item, order: index }));

    onChange(updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { add } = this.state;
    const {
      title,
      actions,
      workguides,
      type,
      components,
      codes,
      fetchCodes,
      language
    } = this.props;

    const highestOrder = getHighestOrder(actions);
    const order = isUndefined(highestOrder)
      ? 0
      : highestOrder + 1;

    return (
      <div>
        <ComponentIdMap
          components={components}
          language={language}
        />

        <h4>
          {title}
          <Button
            onClick={this.onAdd}
            style={{ backgroundColor: '#fff', borderColor: '#fff' }}
          >
            <i className="glyphicon glyphicon-plus" />
          </Button>
        </h4>

        {add && (
          <React.Fragment>
            <Action
              edit
              action={{ order }}
              workguides={workguides}
              onChange={(action) => this.onChange(-1, action)}
              onCancel={this.onCancel}
              type={type}
              components={components}
              codes={codes}
              fetchCodes={fetchCodes}
            />

            <hr />
            <div style={{ paddingBottom: '150px' }} />
          </React.Fragment>
        )}

        <SortableList
          actions={actions}
          onChange={this.onChange}
          onRemove={this.onRemove}
          onCancel={this.onCancel}
          workguides={workguides}
          components={components}
          codes={codes}
          fetchCodes={fetchCodes}
          distance={1}
          onSortEnd={this.onSortEnd}
          useDragHandle
        />
      </div>
    );
  }
}

WorkguideActions.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.array,
  language: PropTypes.string,
  languages: PropTypes.array,
  onChange: PropTypes.func,
  workguides: PropTypes.array,
  type: PropTypes.string,
  components: PropTypes.array,
  codes: PropTypes.object,
  fetchCodes: PropTypes.func
};

WorkguideActions.defaultProps = {
  actions: [],
  language: 'de',
  languages: ['de', 'fr', 'en'],
  onChange: _.noop,
  workguides: [],
  type: 'post',
  components: [],
  fetchCodes: noop
};

export default WorkguideActions;

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import Select from 'react-select';
import { FormGroup, ControlLabel } from 'react-bootstrap';

const ScreenSelect = React.memo(({
  language,
  onChange,
  screens,
  value
}) => {
  const options = get(screens, 'data', []).map((s) => ({ value: s.id, label: get(s, `name.${language}`) }));

  return (
    <div className="textblock-screen-select">
      <FormGroup>
        <ControlLabel>
          Output
        </ControlLabel>

        <Select
          id="selectScreen"
          value={value}
          options={options}
          onChange={onChange}
        />
      </FormGroup>
    </div>
  );
});

ScreenSelect.propTypes = {
  language: PropTypes.string,
  onChange: PropTypes.func,
  screens: PropTypes.object,
  value: PropTypes.string
};

ScreenSelect.defaultProps = {
  language: 'de',
  onChange: noop,
  screens: {},
  value: undefined
};

export default ScreenSelect;

import { createSelector } from 'reselect';
import { getIdFromRef, sortByAll } from '../../utils';
import {
  getSetManagementBundlesRaw,
  getSetManagementPricesRaw,
  getSetManagementProductsRaw,
} from './setManagementCommonSelectors';
import { getLanguage } from '../commonSelectors';

export const getSetManagementPrices = createSelector([
  getSetManagementPricesRaw,
  getSetManagementBundlesRaw,
  getSetManagementProductsRaw,
  getLanguage,
], (prices, bundles, products, locale) => {
  return prices.map((price) => {
    const bundle = bundles.find((it) => getIdFromRef(price.bundle.$ref) === it.id);
    const product = products.find((it) => getIdFromRef(price.product.$ref) === it.id);
    return { ...price, bundle, product };
  }).sort(sortByAll(
    (price) => price.bundle.name[locale],
    (price) => price.product.name[locale],
  )).map((price) => ({
    id: price.id,
    bundle: price.bundle.id,
    product: price.product.id,
    reductionType: getIdFromRef(price.reductionType.$ref),
    reductionValue: price.reductionValue,
  }));
});

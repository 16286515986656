import moment from 'moment';

/**
 * @param dates Array<Moment>
 * @param startDate Moment
 * @param endDate Moment
 */

export function groupDates(dates, startDate, endDate) {
  const ticks = 12;
  const diff = endDate.diff(startDate);
  const delta = Math.ceil(diff / ticks);

  const groups = [];
  let prevDate = moment(startDate);
  while (prevDate.isBefore(endDate)) {
    const nextDate = moment(prevDate).add(delta, 'milliseconds');
    groups.push({
      from: prevDate,
      to: nextDate,
      count: 0,
    });
    prevDate = nextDate;
  }

  dates.reduce((result, date) => {
    const index = result.findIndex((group) => date.isBetween(group.from, group.to, null, '[)'));
    if (index !== -1) {
      result[index].count++;
    }
    return result;
  }, groups);

  return groups;
}

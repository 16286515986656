import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Col, FormGroup, FormControl, ControlLabel, Button, InputGroup, ListGroupItem } from 'react-bootstrap';

import DragHandle from '../../DragHandle';

class Option extends React.Component {
  constructor(props) {
    super(props);
    const { value, label, languages } = props;

    this.state = {
      edit: props.edit,
      value,
      label: languages.reduce((result, lang) => {
        result[lang] = label[lang];
        return result;
      }, {})
    };

    this.onValueChange = this.onValueChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  onEdit() {
    this.setState({ edit: true });
  }

  onValueChange(path, value) {
    let updated = { ...this.state };
    updated = _.set(updated, path, value);

    this.setState(updated);
  }

  onSubmit() {
    const { value, label } = this.state;
    const { languages, onChange, index } = this.props;

    const data = {
      value,
      label: languages.reduce((result, lang) => {
        result[lang] = label[lang] || label.de;
        return result;
      }, {})
    };

    onChange(index, data);

    this.setState({ edit: false });
  }

  onCancel() {
    const { value, label, languages, onCancel } = this.props;

    this.setState({
      edit: false,
      value,
      label: languages.reduce((result, lang) => {
        result[lang] = label[lang];
        return result;
      }, {})
    });

    onCancel();
  }

  onRemove() {
    const { index, onRemove } = this.props;

    onRemove(index);
  }

  isValid() {
    const { value, label } = this.state;
    return (
      value
      && _.get(label, 'de')
      && value.trim().length > 0
      && _.get(label, 'de', '').trim().length > 0
    );
  }

  renderForm() {
    const { value, label } = this.state;
    const { languages, edit } = this.props;

    return (
      <div style={{ width: '100%', padding: '10px' }}>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={1}>ID</Col>
          <Col sm={11}>
            <FormControl
              type="text"
              value={value || ''}
              onChange={(ev) => this.onValueChange('value', _.get(ev, 'target.value'))}
              disabled={!edit}
            />
          </Col>
        </FormGroup>

        {languages.map(l => (
          <FormGroup key={l}>
            <Col componentClass={ControlLabel} sm={1}>{l}</Col>
            <Col sm={11}>
              <FormControl
                type="text"
                value={label[l] || ''}
                onChange={(ev) => this.onValueChange(`label.${l}`, _.get(ev, 'target.value'))}
              />
            </Col>
          </FormGroup>
        ))}

        <div style={{ textAlign: 'right' }}>
          <Button disabled={!this.isValid()} bsStyle="primary" onClick={this.onSubmit}>
            <i className="glyphicon glyphicon-ok" />
          </Button>
          <Button onClick={this.onCancel}>
            <i className="glyphicon glyphicon-remove" />
          </Button>
        </div>
      </div>
    );
  }

  renderOption() {
    const { value, label, language } = this.props;

    return (
      <InputGroup className="full-width">
        <InputGroup.Addon><DragHandle /></InputGroup.Addon>
        <ListGroupItem>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'inline-block', width: '90%' }}>
              <div><strong>ID:</strong> {value}</div>
              <div>{label[language]}</div>
            </div>
            <div style={{ display: 'inline-block', width: '10%', textAlign: 'right', color: 'grey' }}>

            </div>
          </div>
        </ListGroupItem>
        <InputGroup.Addon>
          <span style={{ paddingRight: '5px' }}>
            <i className="glyphicon glyphicon-pencil" onClick={this.onEdit} />
          </span>
          <span className="glyphicon glyphicon-remove" onClick={this.onRemove} />
        </InputGroup.Addon>
      </InputGroup>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { edit } = this.state;

    return (edit)
      ? this.renderForm()
      : this.renderOption()
  }
}

Option.propTypes = {
  value: PropTypes.string,
  label: PropTypes.object,
  language: PropTypes.string,
  languages: PropTypes.array,
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onCancel: PropTypes.func
};

Option.defaultProps = {
  value: undefined,
  label: {},
  language: 'de',
  languages: ['de', 'fr', 'en'],
  edit: false,
  onChange: _.noop,
  onRemove: _.noop,
  onCancel: _.noop
};

export default Option;

import './FinancingRatesForwardsForm.css';

import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormInput, FormSelect } from '../FormControls';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { FinancingRatesSyncButton } from './FinancingRatesSyncButton';

function renderRow(index, onRemove, forwardPeriods, syncForwards) {
  return (
    <div key={index} className="forwards-form__row">
      <Field component={FormSelect}
             name={`forwards[${index}].forwardFrom`}
             placeholder="Zeitperiode"
             multi={false}
             clearable={false}
             className="forwards-form__row-period"
             options={forwardPeriods}/>
      <Field component={FormInput}
             name={`forwards[${index}].forwardRate`}
             className="forwards-form__row-rate"
             type="number"
             placeholder="Rate"/>
      <div className="forwards-form__row-control">
        <FinancingRatesSyncButton syncForwards={syncForwards}/>
        <Button onClick={onRemove}>
          <span className="icon-008-bin"/>
        </Button>
      </div>
    </div>
  );
}

const FieldArrayRenderer = ({ fields, forwardPeriods, currentValues, syncForwards }) => {
  const items = fields.map((it, index) => {
    const removeItem = () => fields.remove(index);
    const sync = () => syncForwards(currentValues[index]);
    return renderRow(
      index,
      removeItem,
      filterForwardPeriods(forwardPeriods, currentValues, index),
      sync,
    );
  });
  const createItem = () => fields.push({});
  return (
    <React.Fragment>
      {items}
      <Button bsStyle="primary" name="newLink" onClick={createItem}>
        <span className="icon-061-plus"/> Neuer Eintrag
      </Button>
    </React.Fragment>
  );
};

function ForwardsForm({ handleSubmit, form, forwardPeriods, currentValues, syncForwards }) {
  const options = forwardPeriods.map((it) => ({
    label: it.label,
    value: it.value.forwardFrom,
  }));
  return (
    <div className="forwards-form">
      <div className="forwards-form__row forwards-form__row--header">
        <div className="forwards-form__row-period">Zeitperiode</div>
        <div className="forwards-form__row-rate">Rate</div>
        <div className="forwards-form__row-control"/>
      </div>
      <Form name={form} onSubmit={handleSubmit}>
        <FieldArray
          name="forwards"
          component={FieldArrayRenderer}
          forwardPeriods={options}
          currentValues={currentValues}
          syncForwards={syncForwards}
        />
      </Form>
    </div>
  );
}

export const FinancingRatesForwardsForm = reduxForm()(ForwardsForm);

function filterForwardPeriods(forwardPeriods, currentValues, currentIndex) {
  const currentItem = currentValues[currentIndex];
  return forwardPeriods.filter((period) =>
    (currentItem && currentItem.forwardFrom === period.value) ||
    !currentValues.some((it) => it.forwardFrom === period.value),
  );
}

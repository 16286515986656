import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import Select from 'react-select';
import { FormGroup, InputGroup, ControlLabel } from 'react-bootstrap';

import './TextblockSelect.css';

class TextblockSelect extends React.Component {
  constructor(props) {
    super(props);

    this.onNext = this.onNext.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  /**
   * Next textblock callback
   *
   * @return  void
   */
  onNext() {
    const {
      onChange,
      output,
      value
    } = this.props;
    const data = get(output, 'data', []);

    const current = data.findIndex((t) => t.id === value);
    const index = current === get(data, 'length', 0) - 1
      ? 0
      : current + 1;
    const next = get(data, index);
    const id = get(next, 'id', value);

    onChange(id);
  }

  /**
   * Prev textblock callback
   *
   * @return  void
   */
  onPrev() {
    const {
      onChange,
      output,
      value
    } = this.props;
    const data = get(output, 'data', []);

    const current = data.findIndex((t) => t.id === value);
    const index = current === 0
      ? get(data, 'length', 0) - 1
      : current - 1;

    const prev = get(data, index);
    const id = get(prev, 'id', value);

    onChange(id);
  }

  /**
   * Select textblock callback
   *
   * @return  void
   */
  onChange({ value }) {
    const { onChange } = this.props;

    onChange(value);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      output,
      value
    } = this.props;

    const options = get(output, 'data', []).map((t) => ({ value: t.id, label: t.id }));
    const disabled = get(options, 'length', 0) === 0;

    return (
      <div className="textblock-textblock-select">
        <FormGroup>
          <ControlLabel>
            Textblock
          </ControlLabel>

          <InputGroup>
            <InputGroup.Addon
              onClick={disabled ? noop : this.onPrev}
              style={{ cursor: 'pointer' }}
            >
              {'<'}
            </InputGroup.Addon>

            <Select
              disabled={disabled}
              id="textblockSelect"
              onChange={this.onChange}
              options={options}
              value={value}
            />

            <InputGroup.Addon
              onClick={disabled ? noop : this.onNext}
              style={{ cursor: 'pointer' }}
            >
              {'>'}
            </InputGroup.Addon>
          </InputGroup>
        </FormGroup>
      </div>
    );
  }
}

TextblockSelect.propTypes = {
  language: PropTypes.string,
  onChange: PropTypes.func,
  output: PropTypes.object,
  value: PropTypes.string
};

TextblockSelect.defaultProps = {
  language: 'de',
  onChange: noop,
  output: {},
  value: undefined
};

export default TextblockSelect;

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';

import ApprovalList from './List';

class Approvals extends React.Component {
  constructor(props) {
    super(props);

    const { fetchCodes } = props;

    fetchCodes('workguideApprovalType');
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      workguide,
      editValueField,
      codes,
      consultants
    } = this.props;

    return (
      <div className="workguide-approvals">
        <ApprovalList
          approval={get(workguide, 'approval', [])}
          editValueField={editValueField}
          codes={codes}
          consultants={consultants}
        />
      </div>
    );
  }
}

Approvals.propTypes = {
  workguide: PropTypes.object,
  editValueField: PropTypes.func,
  codes: PropTypes.object,
  consultants: PropTypes.array
};

Approvals.defaultProps = {
  workguide: undefined,
  editValueField: noop,
  code: {},
  consultants: []
};

export default Approvals;

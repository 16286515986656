import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, isNil, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import update from 'immutability-helper';
import cl from 'classnames';

import './Action.css';
import Collapsible from '../../../../../General/Collapsible';
import ConfigurationComponents from './Configuration/Components';
import NumberInput from '../../Field/Number';
import ConditionList from '../../Condition/List';
import ConditionComponents from '../../Condition/Components';
import Label from '../../Field/Label';
import Toggle from '../../Field/Toggle';

class WorkflowTreeAction extends React.Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onJmesParamChange = this.onJmesParamChange.bind(this);
    this.onConditionChange = this.onConditionChange.bind(this);
  }

  /**
   * Set the given value on key
   *
   * @param   {String}  key    Key of the value to set
   * @param   {Mixed}   value  Value to set
   *
   * @return  void
   */
  onValueChange(key, value) {
    const {
      action,
      onChange
    } = this.props;

    const updated = isNil(value)
      ? update(action, { $unset: [key] })
      : update(action, { [key]: { $set: value } });

    onChange({ action: updated });
  }

  /**
   * Handle remove icon click
   *
   * @param   {Event}  event  Click event
   *
   * @return  void
   */
  onRemove(event) {
    const {
      action,
      onRemove
    } = this.props;

    event.stopPropagation();
    event.preventDefault();

    onRemove({ action, event });
  }

  /**
   * Handle change of a jmes param
   *
   * @param   {String}  key    Key of the param to set
   * @param   {String}  value  JMES instruction to set
   *
   * @return  void
   */
  onJmesParamChange(key, value) {
    const {
      action,
      onChange
    } = this.props;

    const updated = isNil(value)
      ? update(action, { jmesParams: { $unset: [key] } })
      : update(action, { jmesParams: { [key]: { $set: value } } });

    onChange({ action: updated });
  }

  /**
   * Handle conditions change
   *
   * @param   {Array}  condition  Initial and condition
   *
   * @return void
   */
  onConditionChange({ condition }) {
    const {
      action,
      onChange
    } = this.props;

    const updated = isNil(condition)
      ? update(action, { $unset: ['condition'] })
      : update(action, { condition: { $set: condition } });

    onChange({ action: updated });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      action,
      collapsed,
      onChange,
      validations
    } = this.props;

    const Component = get(ConfigurationComponents, action.type, () => null);
    const label = (
      <div className="workflow-tree-form-edit-node--actions--action--label">
        <FormattedMessage
          id={`Workguide.Workflow.Form.EditNode.Action.${action.type}`}
          tagName="div"
        />
        <div
          className="mdi mdi-trash-can-outline"
          onClick={this.onRemove}
        />
      </div>
    );

    return (
      <Collapsible
        className={cl({
          'workflow-tree-form-edit-node--actions--action': true,
          'workflow-tree-form-edit-node--collapsible--invalid': !isEmpty(validations),
        })}
        isCollapsed={collapsed}
        label={label}
      >
        <NumberInput
          id="order"
          onChange={this.onValueChange}
          value={get(action, 'order')}
          validations={get(validations, 'order')}
        />

        <div style={{ paddingBottom: '1em' }} />

        <Toggle
          id="stopExecutionOnError"
          onChange={this.onValueChange}
          value={get(action, 'stopExecutionOnError')}
          validations={get(validations, 'stopExecutionOnError', false)}
        />

        <Label>
          <FormattedMessage id="Workguide.Workflow.Form.EditNode.Title.Condition" />
        </Label>

        <ConditionList
          // We have to pass potential components to the list as importing them there would lead to a dependency cycle
          components={ConditionComponents}
          condition={get(action, 'condition')}
          isRemovable={false}
          onChange={this.onConditionChange}
          operation="and"
          validations={get(validations, 'condition')}
        />

        <Component
          action={action}
          onActionChange={onChange}
          onValueChange={this.onValueChange}
          onJmesParamChange={this.onJmesParamChange}
          validations={validations}
        />

        <div style={{ paddingBottom: '1em' }} />
      </Collapsible>
    );
  }
}

WorkflowTreeAction.propTypes = {
  action: PropTypes.object.isRequired,
  collapsed: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  validations: PropTypes.object
};

WorkflowTreeAction.defaultProps = {
  collapsed: true,
  onChange: noop,
  onRemove: noop,
  validations: {}
};

export default WorkflowTreeAction;

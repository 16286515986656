import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';
import { EndpointName } from '../../constants/EndpointName';
import { ACCOUNT_WORKGUIDES_LOAD } from '../../actions/WorkguideStatusActions';

export function* workguideStatusGetAccountWorkguidesSaga(action) {
  try {
    yield put(ACCOUNT_WORKGUIDES_LOAD.pending());

    const accountId = get(action, 'data.accountId', '');
    const { data: workguides, headers } = yield call(axios, { url: `${EndpointName.PROVISION_WORKGUIDE}/?eq(accountName,${accountId})` });

    const totalCount = headers['x-total-count']
      ? parseInt(headers['x-total-count'], 10)
      : workguides.length;

    yield put(ACCOUNT_WORKGUIDES_LOAD.success({ workguides, totalCount }));
  } catch (error) {
    yield put(ACCOUNT_WORKGUIDES_LOAD.failure(error));
  }
}

import { createSelector } from 'reselect';
import { getAnalyticsLogsConsultants } from './analyticsLogsCommonSelectors';

export const getAnalyticsLogsDivision = createSelector([
  getAnalyticsLogsConsultants,
], (consultants) => {
  const usersGroupedByDivision = consultants.reduce((result, consultant) => {
    if (!consultant.division) {
      return result;
    }

    const key = consultant.division;
    if (!result[key]) {
      result[key] = new Set([]);
    }
    result[key].add(consultant.username);
    return result;
  }, {});

  return Object.keys(usersGroupedByDivision).map((key) => ({
    division: key,
    users: [...usersGroupedByDivision[key]],
  }));
});

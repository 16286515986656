import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { FormControl } from 'react-bootstrap';

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(ev, ...rest) {
    const { id, onChange } = this.props;

    const value = get(ev, 'target.value', '');

    const v = get(value.trim(), 'length', 0) > 0
      ? value.trim()
      : undefined;

    onChange(id, v, ev, ...rest);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      componentClass,
      id,
      onPaste,
      type,
      value
    } = this.props;

    return (
      <FormControl
        componentClass={componentClass}
        id={id}
        onChange={this.onChange}
        onPaste={onPaste}
        type={type}
        value={value}
      />
    );
  }
}

Input.propTypes = {
  componentClass: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onPaste: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string
};

Input.defaultProps = {
  componentClass: undefined,
  onChange: noop,
  onPaste: noop,
  type: 'text',
  value: undefined
};

export default Input;

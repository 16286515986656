import './AnalyticsMonthSelector.css';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { classList } from '../../utils/classList';
import { ANALYTICS_PROP_TYPES_LOCALE } from './AnalyticsPropTypes';

const CURRENT_DATE = moment().startOf('month');
const DATE_FORMAT = 'MMMM YYYY';

export class AnalyticsMonthSelector extends React.Component {
  state = {
    selectedDate: CURRENT_DATE,
  };
  constructor(props) {
    super(props);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
  }

  render() {
    const { locale } = this.props;
    const { selectedDate } = this.state;
    const prevClasses = classList({
      'month-selector__item': true,
      'month-selector__item--disabled': false,
    });
    const nextClasses = classList({
      'month-selector__item': true,
      'month-selector__item--disabled': selectedDate.isSameOrAfter(CURRENT_DATE),
    });

    const prev = moment(selectedDate).subtract(1, 'month').locale(locale).format(DATE_FORMAT);
    const current = moment(selectedDate).locale(locale).format(DATE_FORMAT);
    const next = moment(selectedDate).add(1, 'month').locale(locale).format(DATE_FORMAT);

    return (
      <div className="month-selector">
        <div className={classList(prevClasses, 'month-selector__arrow')} onClick={this.prev}>
          <span className="icon-014-chevron-left"/>
        </div>
        <div className={prevClasses} onClick={this.prev}>
          {prev}
        </div>
        <div className="month-selector__item month-selector__item--active">
          {current}
        </div>
        <div className={nextClasses} onClick={this.next}>
          {next}
        </div>
        <div className={classList(nextClasses, 'month-selector__arrow')} onClick={this.next}>
          <span className="icon-015-chevron-right"/>
        </div>
      </div>
    );
  }

  next() {
    const { selectedDate } = this.state;
    this.onChange(moment(selectedDate).add(1, 'month').startOf('month'));
  }

  prev() {
    const { selectedDate } = this.state;
    this.onChange(moment(selectedDate).subtract(1, 'month').startOf('month'));
  }

  onChange(date) {
    const { onChange } = this.props;
    this.setState({ selectedDate: date }, () => onChange(date));
  }
}

AnalyticsMonthSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  locale: ANALYTICS_PROP_TYPES_LOCALE,
};

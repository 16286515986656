import './ApiClientForm.css';

import React from 'react';
import Spinner from '../Spinner';
import { Checkbox } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, FieldArray, Form, reduxForm, change } from 'redux-form';
import { FormInput, FormSelect } from '../FormControls';
import { Button } from '../Common/Button';
import { ApiClientHeadersRenderer } from './ApiClientHeadersRenderer';
import { ModalBody, ModalDialog, ModalFooter, ModalHeader } from '../Common/Modal';
import { ApiClientServiceInfo } from './ApiClientServiceInfo';

const METHOD_OPTIONS = [
  { label: 'GET', value: 'get' },
  { label: 'POST', value: 'post' },
  { label: 'PUT', value: 'put' },
  { label: 'PATCH', value: 'patch' },
  { label: 'DELETE', value: 'delete' },
  { label: 'OPTIONS', value: 'options' },
];

export class ApiClientReduxForm extends React.Component {
  state = {
    isModalServiceOpen: false,
    isModalParamsOpen: false,
    manuelServiceUrl: false,
  };

  toggleCheckbox = () => {
    this.setState({ manuelServiceUrl: !this.state.manuelServiceUrl });
    this.props.resetCustomUrl()
  };

  render() {
    const { handleSubmit, invalid, execute, executing, endpoints } = this.props;
    const { manuelServiceUrl } = this.state;

    return (
      <Form onSubmit={handleSubmit(execute)} className="api-client-form">
        <div className="api-client-form__body">
          {executing && (
            <div className="api-client-form__spinner">
              <Spinner text="Executing..." />
            </div>
          )}

          <div className="api-client-form__section">
            <div className="api-client-form__label">Service</div>
            <div className="api-client-form__input">
              <div style={{ display: 'flex' }}>
                <Checkbox onChange={() => this.toggleCheckbox()} />
                <p>URL manuell setzen</p>
              </div>

              {manuelServiceUrl ? (
                <Field component={FormInput} size="large" type="text" name="customUrl" placeholder="/example/test/" />
              ) : (
                <Field
                  component={FormSelect}
                  size="large"
                  name="service"
                  placeholder=""
                  clearable={true}
                  options={endpoints}
                  displayErrors={false}
                />
              )}
            </div>
            <div className="api-client-form__action">{this.renderServiceHelpButton()}</div>
          </div>

          <div className="api-client-form__section">
            <div className="api-client-form__label">Method</div>
            <div className="api-client-form__input">
              <Field
                component={FormSelect}
                size="large"
                name="method"
                placeholder=""
                clearable={true}
                options={METHOD_OPTIONS}
                displayErrors={false}
              />
            </div>
            <div className="api-client-form__action" />
          </div>

          <div className="api-client-form__section">
            <div className="api-client-form__label">Params</div>
            <div className="api-client-form__input">
              <Field component={FormInput} size="large" type="text" name="params" placeholder="" />
            </div>
            <div className="api-client-form__action">{this.renderParamsHelpButton()}</div>
          </div>

          <div className="api-client-form__section">
            <div className="api-client-form__label">Headers</div>
            <div className="api-client-form__headers">
              <FieldArray component={ApiClientHeadersRenderer} name="headers" />
            </div>
          </div>

          <div className="api-client-form__section">
            <div className="api-client-form__label">Body</div>
            <div className="api-client-form__input">
              <Field component={FormInput} type="text" componentClass="textarea" name="body" placeholder="" />
            </div>
            <div className="api-client-form__action" />
          </div>

          <div className="api-client-form__footer">
            <Button bsStyle="primary" type="submit" disabled={invalid || executing}>
              Execute
            </Button>
            {invalid && <div className="api-client-form__invalid">Required: "Service" and "Method"</div>}
          </div>
        </div>
      </Form>
    );
  }

  renderParamsHelpButton() {
    const { textParamsHelp } = this.props;
    if (!textParamsHelp) {
      return null;
    }

    const { isModalParamsOpen } = this.state;
    return (
      <React.Fragment>
        <span className="icon-046-information-circle" onClick={this.openModalParams} />
        <ModalDialog show={isModalParamsOpen} onHide={this.closeModalParams}>
          <ModalBody>{textParamsHelp}</ModalBody>
          <ModalFooter>
            <Button onClick={this.closeModalParams} bsSize="sm">
              Abbrechen
            </Button>
          </ModalFooter>
        </ModalDialog>
      </React.Fragment>
    );
  }

  renderServiceHelpButton() {
    const { currentValues, loadingService, schema } = this.props;
    const { isModalServiceOpen, manuelServiceUrl } = this.state;
    if (!currentValues || !currentValues.service || manuelServiceUrl ) {
      return null;
    }


    return (
      <React.Fragment>
        <span className="icon-046-information-circle" onClick={this.openModalService} />
        <ModalDialog show={isModalServiceOpen} onHide={this.closeModalService} bsSize="lg">
          <ModalHeader>{currentValues.service}</ModalHeader>
          <ModalBody>
            {loadingService && <Spinner />}
            {!loadingService && schema && <ApiClientServiceInfo schema={schema} />}
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.closeModalService} bsSize="sm">
              Abbrechen
            </Button>
          </ModalFooter>
        </ModalDialog>
      </React.Fragment>
    );
  }

  openModalService = () => {
    const { loadService, currentValues } = this.props;
    if (!currentValues || !currentValues.service) {
      return;
    }
    loadService(currentValues.service);
    this.setState({ isModalServiceOpen: true });
  };
  closeModalService = () => this.setState({ isModalServiceOpen: false });

  openModalParams = () => this.setState({ isModalParamsOpen: true });
  closeModalParams = () => this.setState({ isModalParamsOpen: false });
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetCustomUrl: () =>
      dispatch(change('apiClientForm', 'customUrl', '')),
  };
};

export const ApiClientForm = connect(null, mapDispatchToProps)(reduxForm({})(ApiClientReduxForm));

import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { createRef } from '../../utils';
import { getNewDocuments, getRemovedDocuments, getUpdatedDocuments } from '../../selectors/BasicProducts';

export function* saveBasicDocumentsSaga() {
  const newDocuments = yield select(getNewDocuments);
  const removedDocuments = yield select(getRemovedDocuments);
  const updatedDocuments = yield select(getUpdatedDocuments);

  yield all(
    removedDocuments.map((document) => call(axios, {
      url: createRef(EndpointName.BASIC_PRODUCT_DOCUMENT, document.id),
      method: 'delete',
    }))
  );

  yield all(
    newDocuments.map((document) => call(axios, {
      url: EndpointName.BASIC_PRODUCT_DOCUMENT,
      method: 'post',
      data: document,
    }))
  );

  yield all(
    updatedDocuments.map((document) => call(axios, {
      url: createRef(EndpointName.BASIC_PRODUCT_DOCUMENT, document.id),
      method: 'put',
      data: document,
    }))
  );
}

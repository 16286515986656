import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Row, Col, FormControl, ControlLabel, FormGroup, Checkbox } from 'react-bootstrap';
import Select from 'react-select';

import './LocalFilterForm.css';

const conditions = [{
  value: 'eq',
  label: 'Entspricht'
}, {
  value: 'ne',
  label: 'Entspricht nicht'
}, {
  value: 'contains',
  label: 'Beinhaltet'
}, {
  value: 'ge',
  label: 'Grösser gleich'
}, {
  value: 'le',
  label: 'Kleiner gleich'
}];

class LocalFilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.onTextInputChange = this.onTextInputChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  onTextInputChange(ev) {
    const { data, onChange } = this.props;

    const { id, value } = ev.target;

    const updated = { ...data, [id]: value };

    onChange(updated);
  }

  onSelectChange(id, selected) {
    const { data, onChange } = this.props;

    const updated = { ...data, [id]: get(selected, 'value') };

    onChange(updated);
  }

  onCheckboxChange(ev) {
    const { data, onChange } = this.props;
    const { id } = ev.target;

    const updated = { ...data, type: id };

    onChange(updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { data } = this.props;

    return (
      <div className="replay-audit-log-local-filter-form" style={{ width: '100%' }}>
        <Row className="replay-audit-log-local-filter-form--row">
          <FormGroup>
            <Col componentClass={ControlLabel} lg={2}>
              JMES Pfad Anweisung
            </Col>

            <Col lg={10}>
              <FormControl
                id="path"
                value={get(data, 'path', '')}
                onChange={this.onTextInputChange}
              />
            </Col>
          </FormGroup>
        </Row>

        <Row className="replay-audit-log-local-filter-form--row">
          <FormGroup>
            <Col componentClass={ControlLabel} lg={2}>
              Bedingung
            </Col>

            <Col lg={10}>
              <Select
                id="condition"
                value={get(data, 'condition')}
                options={conditions}
                onChange={(selected) => this.onSelectChange('condition', selected)}
              />
            </Col>
          </FormGroup>
        </Row>

        <Row className="replay-audit-log-local-filter-form--row">
          <FormGroup>
            <Col componentClass={ControlLabel} lg={2}>
              Wert(e)
            </Col>

            <Col lg={10}>
              <FormControl
                id="value"
                value={get(data, 'value', '')}
                onChange={this.onTextInputChange}
              />
            </Col>
          </FormGroup>
        </Row>

        <Row>
          <Col componentClass={ControlLabel} lg={2}>
            Datentyp
          </Col>

          <Col lg={2}>
            <FormGroup>
              <Checkbox
                checked={get(data, 'type') === 'string'}
                id="string"
                inline
                onChange={this.onCheckboxChange}
              >
                <div style={{ paddingTop: '3px' }}>
                  String
                </div>
              </Checkbox>
            </FormGroup>
          </Col>

          <Col lg={2}>
            <FormGroup>
              <Checkbox
                checked={get(data, 'type') === 'number'}
                id="number"
                inline
                onChange={this.onCheckboxChange}
              >
                <div style={{ paddingTop: '3px' }}>
                  Number
                </div>
              </Checkbox>
            </FormGroup>
          </Col>

          <Col lg={2}>
            <FormGroup>
              <Checkbox
                checked={get(data, 'type') === 'boolean'}
                id="boolean"
                inline
                onChange={this.onCheckboxChange}
              >
                <div style={{ paddingTop: '3px' }}>
                  Boolean
                </div>
              </Checkbox>
            </FormGroup>
          </Col>

          <Col lg={2}>
            <FormGroup>
              <Checkbox
                checked={get(data, 'type') === 'date'}
                id="date"
                inline
                onChange={this.onCheckboxChange}
              >
                <div style={{ paddingTop: '3px' }}>
                  Datum
                </div>
              </Checkbox>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

LocalFilterForm.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func
};

LocalFilterForm.defaultProps = {
  data: {},
  onChange: noop
};

export default LocalFilterForm;

import { SET_COMMON_DATA } from '../actions/CommonActions';

export function CommonReducer(
  state = {
    search: '',
  },
  action,
) {
  switch (action.type) {
    case SET_COMMON_DATA:
      return { ...state, [action.key]: action.value };

    default: {
      return state;
    }
  }
}

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { get, has } from 'lodash';
import moment from 'moment'
import update from 'immutability-helper'
import Select from 'react-select';

import { getEntityCodes, getAccountWorkguides } from '../../selectors/WorkguideStatus/workguideStatusCommonSelectors';
import { getLanguage } from '../../selectors/commonSelectors';
import { WORKGUIDE_STATUS_ENTITIY_CODE_LOAD, ACCOUNT_WORKGUIDES_LOAD, ACCOUNT_WORKGUIDES_UPDATE } from '../../actions/WorkguideStatusActions';
import { Table, TableHead, TableCell, TableRow } from '../Common/Table';

const AccountSearchWorkguidesTable = props => {
    useEffect(() => {
        const { load_entity_code } = props;
        load_entity_code();
    }, [])

    const { entityCodes, locale, workguides, load_account_workguides, update_account_workguide } = props;
    const workguidesData = get(workguides, 'data', [])

    const updateTableData = (index, option) => {
        const status = entityCodes.find(x => x.number === get(option, 'value', ''));
        if (!status) return null;
        const updated = update(workguidesData[index], { status: { $set: status } })
        update_account_workguide({ workguide: updated });

        const accountId = get(workguidesData, '[0].accountName', '');
        load_account_workguides({ accountId });
    }


    return (
        <div>
            <h3><FormattedMessage id="workguideStatus_accountWorkguides" />:</h3>
            <Table>
                <TableHead>
                    <TableCell>
                        Workguide
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id="workguideStatus_date" />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage id="workguideStatus_status" />
                    </TableCell>
                </TableHead>

                {workguidesData.length > 0 && workguidesData.map((item, i) => {
                    return (
                        <TableRow key={i}>
                            <TableCell>
                                {get(item, `clientState.workguideTitle.${locale}`, '')}
                            </TableCell>
                            <TableCell>
                                {has(item, 'paymentDate') ? moment(get(item, 'paymentDate')).format('DD.MM.YYYY') : '-'}
                            </TableCell>
                            <TableCell>
                                <Select
                                    value={get(item, 'status.number', null)}
                                    isClearable={false}
                                    options={entityCodes.map(el => ({ label: el.text[locale], value: el.number }))}
                                    onChange={(option) => updateTableData(i, option)}
                                />
                            </TableCell>
                        </TableRow>)
                })}
            </Table>
        </div>
    );
};

AccountSearchWorkguidesTable.defaultProps = {
    entityCodes: [],
    workguides: [],
    locale: 'de'
}

AccountSearchWorkguidesTable.propTypes = {
};

const mapStateToProps = createStructuredSelector({
    entityCodes: getEntityCodes,
    workguides: getAccountWorkguides,
    locale: getLanguage
});

const mapDispatchToProps = {
    load_entity_code: () => WORKGUIDE_STATUS_ENTITIY_CODE_LOAD.request(),
    load_account_workguides: (data) => ACCOUNT_WORKGUIDES_LOAD.request(data),
    update_account_workguide: (data) => ACCOUNT_WORKGUIDES_UPDATE.request(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSearchWorkguidesTable);
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { Virtuoso } from 'react-virtuoso'

import RecordListItem from './RecordListItem';

class RecordList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false
    };

    this.toggle = this.toggle.bind(this);
    this.renderItemContent = this.renderItemContent.bind(this);
  }

  toggle() {
    const { collapsed } = this.state;

    this.setState({ collapsed: !collapsed });
  }

  renderItemContent(index) {
    const { records } = this.props;

    const record = get(records, index);

    const className = {
      'replay-audit-log-record-list-item--errorneous': get(record, 'error')
    }

    return <RecordListItem
      className={className}
      record={record}
      error={get(record, 'error')}
    />
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { collapsed } = this.state;
    const { records } = this.props;

    const listHeight = get(records, 'length', 0) > 0
      ? '1500px'
      : '0px';

    return (
      <div className="replay-audit-log-record-list">
        <Row>
          <Col lg={12}>
            <h4
              style={{ cursor: 'pointer' }}
              onClick={this.toggle}
            >
              Einträge ({get(records, 'length', 0)})
            </h4>
          </Col>
        </Row>

        {!collapsed && (
          <Row>
            <Col lg={12}>
              <Virtuoso
                className="list-group"
                data={records}
                style={{ height: listHeight }}
                totalCount={get(records, 'length', 0)}
                itemContent={this.renderItemContent}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

RecordList.propTypes = {
  records: PropTypes.array
};

RecordList.defaultProps = {
  records: []
};

export default RecordList;

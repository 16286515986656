import { chain, isNil, get } from 'lodash';

/**
 * Loop over paths and return the first value that is not undefined and matches the given filter.
 * Return the defaultReturn if no value is defined.
 *
 * @param {Object}   item          Source item
 * @param {Array}    paths         Array of paths to seach for a value
 * @param {Function} filter        Optional filter function to filter out invalid values
 * @param {Mixed}    defaultReturn Default return value if everything is undefined or doesn't match the given filter
 *
 * @return  {Mixed} value Value or default return
 */
export default function getPossibleSourceItemValueFromPaths({
  item,
  paths = [],
  filter = (v) => v,
  defaultReturn = undefined
}) {
  const value = chain(paths)
    .map((p) => get(item, p))
    .compact()
    .filter((v) => filter(v))
    .first()
    .value();

  return isNil(value)
    ? defaultReturn
    : value;
}

import React from 'react';
import { addLocaleData, IntlProvider } from 'react-intl';
import de from 'react-intl/locale-data/de';
import fr from 'react-intl/locale-data/fr';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { I18N_LOCALES } from '../../constants/I18nLocales';
import { getLanguage, getTranslations } from '../../selectors/commonSelectors';
import flattenTranslations from '../../utils/flattenTranslations';

addLocaleData([...de, ...fr]);

function IntlProviderWithState({ children, locale, translations }) {
  const i18nLocale = I18N_LOCALES[locale];

  return (
    <IntlProvider locale={i18nLocale} messages={flattenTranslations(translations)}>
      {children}
    </IntlProvider>
  );
}

const mapStateToProps = createStructuredSelector({
  locale: getLanguage,
  translations: getTranslations,
});
export const ConnectedIntlProvider = connect(mapStateToProps, {})(IntlProviderWithState);

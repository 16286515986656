import React, { Component } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { InputGroup, FormControl, Modal, Button } from 'react-bootstrap';
import DragHandle from '../DragHandle';

import EquitySettings from './EquitySettings';
import CollateralSettings from './CollateralSettings';
import CashflowSettings from './CashflowSettings';

class Item extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
    };
  }

  modalOpen() {
    this.setState({
      modalOpen: true,
    });
  }

  modalClose() {
    this.setState({
      modalOpen: false,
    });
  }

  settingForm() {
    switch (this.props.type) {
      case 'equity':
        return (<EquitySettings {...this.props} />);
      case 'collateral':
        return (<CollateralSettings {...this.props} />);
      case 'cashflow':
        return (<CashflowSettings {...this.props} />);
      default:
        break;
    }
  }

  render() {
    const { id, item, language, onRemove, editEventField } = this.props;

    return (
      <div className="SortableHOCItem">
        <InputGroup style={{flexGrow: 1}}>
          <InputGroup.Addon>
            <DragHandle />
          </InputGroup.Addon>

          <div className="input-wrapper">
            <FormControl
              type="text"
              name="itemName"
              value={item.name[language]}
              onChange={editEventField.bind(this, 'name', id)}
            />
          </div>
          <InputGroup.Addon style={{ cursor: 'pointer' }}>
            <span style={{ marginRight: '10px' }}>
              <i className="icon-213-setting" onClick={this.modalOpen.bind(this)}>
                <span className="hidden">remove</span>
              </i>
            </span>
            <span onClick={onRemove.bind(this, id)}>
              <i className="icon-008-bin"><span className="hidden">remove</span></i>
            </span>
          </InputGroup.Addon>
        </InputGroup>
        <Modal show={this.state.modalOpen} onHide={this.modalClose.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Detaileinstellungen für {item.name[language]}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.settingForm()}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.modalClose.bind(this)}><span className="icon-202-clear-circle" />
              Schliessen
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

const SortableItem = SortableElement(Item);

export default class MyElement extends Component {
  render() {
    const {
      id, item, type, collateralTypes, language, onRemove, editEventField, editValueField,
    } = this.props;
    return (
      <SortableItem
        index={id}
        id={id}
        item={item}
        collateralTypes={collateralTypes}
        type={type}
        editEventField={editEventField}
        editValueField={editValueField}
        onRemove={onRemove}
        language={language}
      />
    );
  }
}

export default function parseUrl(url) {
  const parser = document.createElement('a');
  parser.href = url;
  const parsedUrl = {
    protocol: parser.protocol,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    hash: parser.hash,
    search: parser.search,
    origin: parser.origin
  };

  return parsedUrl;
}

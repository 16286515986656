import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import configAction from '../../actions/Environment/Config';

const {
  CONFIG_PENDING,
  CONFIG_FULFILLED,
  CONFIG_REJECTED
} = configAction;

export default function* getConfig(request) {
  yield put({ type: CONFIG_PENDING });

  try {
    const { data: payload } = yield call(axios, {
      url: '/environment.json',
      method: 'get',
      baseURL: null,
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });

    axios.defaults.baseURL = payload.api_url;

    yield put({ type: CONFIG_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CONFIG_REJECTED, error });

    return error;
  }
}

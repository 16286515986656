import React from 'react';
import PropTypes from 'prop-types';
import { chain, get, noop, isNil, isArray, some } from 'lodash';
import { CodeSelect } from '@evoja-web/react-form';

const CustomerTaskTypeSelect = React.memo(({
  codes,
  config,
  id,
  language,
  multi,
  onChange,
  orderOrigin,
  value
}) => {
  const origin = isArray(orderOrigin)
    ? orderOrigin
    : [orderOrigin];

  const ids = chain(config)
    .get('data', [])
    .filter((c) => some(origin, (o) => get(o, 'id') === get(c, 'orderOrigin.id')))
    .map((c) => get(c, 'customerTaskTypeId'))
    .compact()
    .uniq()
    .value();

  const filtered = chain(codes)
    .get('groups.customerTaskType', [])
    .filter((t) => ids.includes(t.id))
    .value();

  return (
    <CodeSelect
      id={id}
      codes={filtered}
      disabled={isNil(orderOrigin)}
      language={language}
      multi={multi}
      onChange={onChange}
      value={value}
    />
  );
});

CustomerTaskTypeSelect.propTypes = {
  codes: PropTypes.object,
  config: PropTypes.object,
  id: PropTypes.string.isRequired,
  language: PropTypes.string,
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  orderOrigin: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
};

CustomerTaskTypeSelect.defaultProps = {
  codes: { groups: {} },
  config: {},
  language: 'de',
  multi: false,
  onChange: noop,
  orderOrigin: undefined,
  value: undefined
};

export default CustomerTaskTypeSelect;

import { takeEvery, takeLatest } from 'redux-saga/effects';
import { financingProfilingLoadSaga } from './financingProfilingLoadSaga';
import { financingProfilingSaveSaga } from './financingProfilingSaveSaga';
import { financingProfilingUpdateSaga } from './financingProfilingUpdateSaga';
import {
  FINANCING_PROFILING_LOAD_ACTIONS,
  FINANCING_PROFILING_SAVE_ACTIONS,
  FINANCING_PROFILING_UPDATE_ACTIONS,
} from '../../actions/FinancingProfilingActions';

export function* financingProfilingSagas() {
  yield takeLatest(FINANCING_PROFILING_LOAD_ACTIONS.REQUEST, financingProfilingLoadSaga);
  yield takeLatest(FINANCING_PROFILING_SAVE_ACTIONS.REQUEST, financingProfilingSaveSaga);
  yield takeEvery(FINANCING_PROFILING_UPDATE_ACTIONS.REQUEST, financingProfilingUpdateSaga);
}

import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { createRef } from '../../utils';
import { getNewAdditions, getRemovedAdditions, getUpdatedAdditions } from '../../selectors/BasicProducts';

export function* saveBasicAdditionSaga() {
  const newAdditions = yield select(getNewAdditions);
  const removedAdditions = yield select(getRemovedAdditions);
  const updatedAdditions = yield select(getUpdatedAdditions);

  yield all(
    newAdditions.map((addition) => call(axios, {
      url: EndpointName.BASIC_PRODUCT_ADDITION,
      method: 'post',
      data: addition,
    }))
  );

  yield all(
    removedAdditions.map((addition) => call(axios, {
      url: createRef(EndpointName.BASIC_PRODUCT_ADDITION, addition.id),
      method: 'delete',
    }))
  );

  yield all(
    updatedAdditions.map((addition) => call(axios, {
      url: createRef(EndpointName.BASIC_PRODUCT_ADDITION, addition.id),
      method: 'put',
      data: addition,
    }))
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isEmpty, noop } from 'lodash';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import './Form.css';
import Condition from './Condition';
import DisabledWorkguideComponents from './DisabledWorkguideComponents';
import ComponentIdMap from '../../../../General/ComponentIdMap';
import NodeIdMap from '../../General/NodeIdMap';
import JmesInputHelp from '../../../../General/JmesInputHelp';
import ConnectionValidationDefinition from '../../../../../lib/Validation/ConnectionValidationDefinition';
import { getValidator } from '../../../../../../../globals';
import workguideActions from '../../../../../actions/Actions';

class WorkflowFormEditConnection extends React.Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentWillUnmount() {
    const { workguideActions } = this.props;

    workguideActions.workflowTreeEditConnectionFormReset();
  }

  /**
   * Set the given value for the given key in data
   *
   * @param   {String}  key    Key in data
   * @param   {Mixed}   value  Value to set
   *
   * @return  void
   */
  onValueChange(key, value) {
    const { workguideActions } = this.props;

    workguideActions.workflowTreeEditConnectionFormSetValue(key, value);
  }

  /**
   * Submit the form
   *
   * @return  void
   */
  onSubmit() {
    const {
      form,
      onSubmit,
      workguideActions
    } = this.props;

    const data = get(form, 'data', {});
    workguideActions.workflowTreeEditConnectionFormSaveRequest({ data });
    onSubmit({ data });
  }

  /**
   * Cancel edit and reset the form
   *
   * @return  void
   */
  onCancel() {
    const {
      onCancel,
      workguideActions
    } = this.props;

    workguideActions.workflowTreeEditConnectionFormReset();
    onCancel();
  }

  /**
   * Validate current form data
   *
   * @return  {Object} result Validation result
   */
  validate() {
    const { form } = this.props;

    const validator = getValidator();

    return validator.validate(ConnectionValidationDefinition, get(form, 'data', {}));
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      form,
      language,
      workguide
    } = this.props;

    const validations = this.validate();

    return (
      <div className="workflow-tree-form-edit-connection">
        <ComponentIdMap components={get(workguide, 'components', [])} />

        <NodeIdMap nodes={get(workguide, 'workflow.tree.nodes', [])} />

        <JmesInputHelp />

        <div style={{ paddingTop: '2em' }} />

        <Condition
          form={form}
          language={language}
          onValueChange={this.onValueChange}
          validations={validations}
        />

        <DisabledWorkguideComponents
          form={form}
          language={language}
          onValueChange={this.onValueChange}
          onTranslatableValueChange={this.onTranslatableValueChange}
          workguide={workguide}
          validations={validations}
        />

        <div className="workflow-tree-form-edit-connection--buttons">
          <Button
            bsStyle="primary"
            disabled={!isEmpty(validations)}
            onClick={this.onSubmit}
          >
            <FormattedMessage id="Workguide.Workflow.Form.Button.Submit" />
          </Button>

          <div style={{ paddingRight: '1em' }} />

          <Button
            onClick={this.onCancel}
            outline
          >
            <FormattedMessage id="Workguide.Workflow.Form.Button.Cancel" />
          </Button>
        </div>
      </div>
    );
  }
}

WorkflowFormEditConnection.propTypes = {
  form: PropTypes.object,
  language: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  workguide: PropTypes.object.isRequired,
  workguideActions: PropTypes.object.isRequired
};

WorkflowFormEditConnection.defaultProps = {
  form: {},
  language: 'de',
  onCancel: noop,
  onSubmit: noop
};

function mapStateToProps(state) {
  return {
    form: state.workguide.workflowTreeEditConnectionForm,
    workguide: state.workguide.workguide
  };
}

function mapDispatchToProps(dispatch) {
  return {
    workguideActions: bindActionCreators(workguideActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  WorkflowFormEditConnection
);

import { createSelector } from 'reselect';
import { getAnalyticsState } from './analyticsCommonSelectors';
import { getCommonSearch } from '../commonSelectors';
import { getAnalyticsConsultants } from './analyticsActveUsersSelectors';
import { convertDateToApp } from '../../utils/dateConverter';

export const getAnalyticsHistoryLoading = createSelector([getAnalyticsState], (state) => state.historyLoading);
export const getAnalyticsHistory = createSelector([getAnalyticsState], (state) => state.history);

export const getAnalyticsHistoryItems = createSelector([
  getAnalyticsState,
], (state) => state.historyItems.map((item) => ({
  date: item.date,
  requestCount: item.items.reduce((count, it) => count + it.requestCount, 0),
})));

const getHistoryItemsDetailsByDates = createSelector([getAnalyticsState], (state) => state.historyItemsDetails);
const getHistoryItemsDetails = createSelector([
  getHistoryItemsDetailsByDates,
], (items) => {
  return items.reduce((result, it) => result.concat(it.items), []);
});

const getHistoryItemsDetailsMapped = createSelector([
  getHistoryItemsDetails,
  getAnalyticsConsultants,
], (historyItems, consultants) => {
  const groupedByUser = historyItems.reduce((result, item) => {
    const key = item.username.toLowerCase();
    if (!result[key]) {
      result[key] = mapHistoryItem(item, consultants);
    } else {
      const firstRequest = convertDateToApp(item.firstRequest);
      const lastRequest = convertDateToApp(item.lastRequest);

      result[key].requestCount += item.requestCount;
      result[key].firstRequest = firstRequest.isBefore(result[key].firstRequest)
        ? firstRequest
        : result[key].firstRequest;
      result[key].lastRequest = lastRequest.isAfter(result[key].lastRequest)
        ? lastRequest
        : result[key].lastRequest;
    }
    return result;
  }, {});

  return Object.keys(groupedByUser).map((key) => groupedByUser[key]);
});

export const getHistoryUniqUsersStatistic = createSelector([
  getHistoryItemsDetailsByDates,
], (items) => {
  const counts = items.map((item) => item.items.length);
  if (!counts.length) {
    return {
      max: 0,
      min: 0,
      avg: 0,
    };
  }
  return {
    max: Math.max(...counts),
    min: Math.min(...counts),
    avg: Math.round(counts.reduce((result, it) => result + it, 0) / counts.length),
  };
});

export const getAnalyticsHistoryItemsDetails = createSelector([
  getHistoryItemsDetailsMapped,
  getCommonSearch,
], (historyItems, search) => {
  return !search
    ? historyItems
    : historyItems.filter((user) => (
      contains(user.username, search) ||
      contains(user.name, search) ||
      contains(user.department, search) ||
      contains(user.division, search)
    ));
});

function contains(string, search) {
  return string.toLowerCase().indexOf(search.toLowerCase()) !== -1;
}

function compareUsers(username1, username2) {
  return username1.toLowerCase() === username2.toLowerCase();
}

function mapHistoryItem(historyItem, consultants) {
  const user = consultants.find((it) => compareUsers(it.username, historyItem.username));
  return {
    username: user ? user.username : historyItem.username,
    name: user
      ? [user.firstName, user.lastName].filter(Boolean).join(' ')
      : historyItem.username,
    department: user && user.department ? user.department : '',
    division: user && user.division ? user.division : '',
    firstRequest: convertDateToApp(historyItem.firstRequest),
    lastRequest: convertDateToApp(historyItem.lastRequest),
    requestCount: historyItem.requestCount,
  };
}

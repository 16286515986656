import { takeLatest } from 'redux-saga/effects';
import {
  LOAD_SURCHARGE_REALESTATETYPE_ACTIONS,
  SAVE_SURCHARGE_REALESTATETYPE_ACTIONS,
} from '../../actions/FinancingSurchargeRealestatetypeActions';
import { loadFinancingSurchargeRealestatetype } from './loadFinancingSurchargeRealestatetypeSaga';
import { saveFinancingSurchargeRealestatetype } from './saveFinancingSurchargeRealestatetypeSaga';

export function* surchargeRealestatetypesSagas() {
  yield takeLatest(LOAD_SURCHARGE_REALESTATETYPE_ACTIONS.REQUEST, loadFinancingSurchargeRealestatetype);
  yield takeLatest(SAVE_SURCHARGE_REALESTATETYPE_ACTIONS.REQUEST, saveFinancingSurchargeRealestatetype);
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, noop, slice } from 'lodash';

import { SortableTable } from '../Common/SortableTable';
import Pagination from '../Pagination/PaginationNoTotalCount';
import { JOB_PROCESSING_PAGE_SIZE } from '../../constants/JobProcessingConstants';

import ExecutedJobsHistoryItem from './ExecutedJobsHistoryItem';

const ExecutedJobsHistoryTable = ({
  executionHistory,
  intl,
  jobProcessingActions,
  language,
  jobId,
  documentId
}) => {
  const dataKey = `${jobId}-${documentId}`;

  const SORTABLE_FIELDS = [
      { key: 'success', label: intl.formatMessage({ id: 'jobProcessing_successful' }), sortFn: () => undefined },
      { key: 'createDate', label: intl.formatMessage({ id: 'jobProcessing_createDate' }), sortFn: () => undefined },
      { key: 'message', label: intl.formatMessage({ id: 'jobProcessing_information' }), sortFn: () => undefined },
  ];

  const renderItem = (item) => (
      <ExecutedJobsHistoryItem
          key={item.id}
          item={item}
          intl={intl}
          language={language}
      />
  );

  const history = get(executionHistory, dataKey, {});

  const {
    data = [],
    limit = JOB_PROCESSING_PAGE_SIZE,
    offset = 0,
    requesting
  } = history;

  const items = slice(data, offset, limit + offset);

  const onPageChange = ({ limit, offset }) => {
    jobProcessingActions.executionHistoryRequest({
      dataKey,
      job: jobId,
      document: documentId,
      data,
      limit,
      offset
    });
  }

  return (
    <div>
      <SortableTable
        items={items}
        sortableFields={SORTABLE_FIELDS}
        renderItem={renderItem}
        className="bulk-loads-results__table"
        onSort={noop}
        sortField={''}
        sortType={''}
        isExternalSortField={true}
      />

      <Pagination
        recordCount={get(data, 'length', 0)}
        onPageChange={onPageChange}
        requesting={requesting}
        pageSize={JOB_PROCESSING_PAGE_SIZE}
      />
    </div>
  );
};

ExecutedJobsHistoryTable.propTypes = {
  executionHistory: PropTypes.object,
  intl: PropTypes.object,
  jobProcessingActions: PropTypes.object.isRequired,
  language: PropTypes.string,
  jobId: PropTypes.string,
  documentId: PropTypes.string
};

ExecutedJobsHistoryTable.defaultProps = {
  executionHistory: {}
}

export default ExecutedJobsHistoryTable;

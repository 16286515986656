import { call, put } from 'redux-saga/effects';
import { get, reduce, isUndefined, isArray, omit, orderBy, compact } from 'lodash';
import moment from 'moment';

// ToDo: Move functions like handleNextHeaders to own repo
import auditLogAction from '../../actions/ReplayAuditLog/AuditLog';
import getCurrentRecords from './getCurrentRecords';
import {
  INCREMENT_COUNT,
  INIT_PROGRESS,
  SET_ERRORNEOUS,
  SET_FULFILLED
} from '../../actions/ReplayAuditLog/Progess';

function getQueryString(filter = {}) {
  const query = reduce(filter, (result, value, key) => {
    const { data } = value;

    return !isUndefined(data)
      ? `${result}&${key}=${isArray(data) ? data.join(',') : data}`
      : result;
  }, '');

  return query;
}

const {
  AUDIT_LOG_PENDING,
  AUDIT_LOG_FULFILLED,
  AUDIT_LOG_REJECTED
} = auditLogAction;

function* fetchChunk(request) {
  const {
    filter,
    limit = 50,
    offset = 0,
    source
  } = request;
  let { payload = [] } = request;

  const query = getQueryString(omit(filter, ['fetchRecord']));
  const url = `/analytics/auditing-requests?limit=${limit}&offset=${offset}&showRequestBody=1${query}`;

  const { data } = yield call(source, {
    url,
    method: 'get',
    timeout: 60000
  });

  payload = [...payload, ...data];
  const recordCount = get(data, 'length', 0);

  yield put({ type: INCREMENT_COUNT, dataKey: 'auditLog', payload: { value: recordCount, total: get(payload, 'length', 0) } });

  if (recordCount > 0 && recordCount >= limit) {
    return yield call(fetchChunk, {
      ...request,
      offset: offset + limit,
      payload
    });
  }

  return payload;
}

export default function* getAuditLog(request) {
  yield put({ type: AUDIT_LOG_PENDING });
  yield put({ type: INIT_PROGRESS, dataKey: 'auditLog', payload: { total: 200 } });

  try {
    const { filter } = request;

    const data = yield call(fetchChunk, request);

    const payload = orderBy(compact(data), (a) => moment(a.createdAt), ['asc']);

    if (get(filter, 'fetchRecord')) {
      yield call(getCurrentRecords, { ...request, records: payload });
    }
    yield put({ type: AUDIT_LOG_FULFILLED, payload });
    yield put({ type: SET_FULFILLED, dataKey: 'auditLog' });

    return payload;
  } catch (error) {
    yield put({ type: AUDIT_LOG_REJECTED, error });
    yield put({ type: SET_ERRORNEOUS, dataKey: 'auditLog', error });

    return error;
  }
}

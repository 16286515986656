import {
  API_CLIENT_EXECUTE,
  API_CLIENT_LOAD,
  API_CLIENT_LOAD_SERVICE,
  API_CLIENT_LOGIN,
} from '../actions/ApiClientActions';

const initialState = {
  textBlocks: [],
  loading: false,
  executing: false,
  error: undefined,
  isAuthorized: undefined,
  endpoints: [],
  loadingService: false,
  schema: undefined,
  result: undefined,
};

export function ApiClientReducer(state = initialState, action) {
  switch (action.type) {
    case API_CLIENT_LOAD.PENDING:
      return { ...state, loading: true, error: undefined };
    case API_CLIENT_LOAD.SUCCESS: {
      const { textBlocks, endpoints } = action.data;
      return { ...state, loading: false, error: undefined, textBlocks, endpoints };
    }
    case API_CLIENT_LOAD.FAILURE:
      return { ...state, loading: false, error: action.data };

    case API_CLIENT_EXECUTE.PENDING:
      return { ...state, executing: true, error: undefined, result: undefined };
    case API_CLIENT_EXECUTE.SUCCESS: {
      const { result } = action.data;
      return { ...state, executing: false, result };
    }
    case API_CLIENT_EXECUTE.FAILURE: {
      const { result } = action.data;
      return { ...state, executing: false, result };
    }

    case API_CLIENT_LOGIN.PENDING:
      return { ...state, executing: true, error: undefined };
    case API_CLIENT_LOGIN.SUCCESS: {
      const { isAuthorized } = action.data;
      return { ...state, executing: false, isAuthorized };
    }
    case API_CLIENT_LOGIN.FAILURE:
      return { ...state, executing: false, error: action.data };

    case API_CLIENT_LOAD_SERVICE.PENDING:
      return { ...state, loadingService: true, error: undefined };
    case API_CLIENT_LOAD_SERVICE.SUCCESS: {
      const { schema } = action.data;
      return { ...state, loadingService: false, schema };
    }
    case API_CLIENT_LOAD_SERVICE.FAILURE:
      return { ...state, loadingService: false, error: action.data };

    default:
      return state;
  }
}

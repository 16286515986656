import { get } from 'lodash';

export default {
  type: 'object',
  required: false,
  validations: {
    id: {
      type: 'string',
      required: true,
      validations: {}
    },
    operation: {
      type: 'string',
      required: true,
      validations: {}
    },
    conditions: {
      type: 'array',
      required: false,
      validations: {
        id: {
          type: 'string',
          required: true,
          validations: {}
        },
        operation: {
          type: 'string',
          required: true,
          validations: {}
        },
        conditions: {
          type: 'array',
          required: ({ parent }) => ['and', 'or'].includes(get(parent, 'operation')),
          validations: {
            id: {
              type: 'string',
              required: true,
              validations: {}
            },
            operation: {
              type: 'string',
              required: true,
              validations: {}
            },
            conditions: {
              type: 'array',
              required: ({ parent }) => ['and', 'or'].includes(get(parent, 'operation')),
              validations: {}
            },
            criteria: {
              type: 'object',
              required: ({ parent }) => !['and', 'or'].includes(get(parent, 'operation')),
              validations: {
                path: {
                  type: 'string',
                  required: true,
                  validations: {}
                },
                values: {
                  type: 'array',
                  required: true,
                  validations: {}
                }
              }
            }
          }
        },
        criteria: {
          type: 'object',
          required: ({ parent }) => !['and', 'or'].includes(get(parent, 'operation')),
          validations: {
            path: {
              type: 'string',
              required: true,
              validations: {}
            },
            values: {
              type: 'array',
              required: true,
              validations: {}
            }
          }
        }
      }
    }
  }
};

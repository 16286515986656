import axios from 'axios';
import { parseLinkHeader } from '@web3-storage/parse-link-header';
import parseUrl from 'url-parse';

/**
 * Follow the next headers returned from backend
 *
 * @param  {[type]} params    [description]
 * @param  {Array}  [data=[]] [description]
 *
 * @return {Array} data Array of data
 */
export default async function followNextHeaders(params, data = []) {
  const result = await axios(params);
  const linkHeaders = parseLinkHeader(result.headers.link, { maxHeaderLength: 5000 });

  data = [ ...data, ...result.data ];
  if (linkHeaders.hasOwnProperty('next')) {
    const parsed = parseUrl(linkHeaders.next.url);
    const url = `${parsed.pathname}${parsed.query.replace(/(%2C)/ig, ",")}`;
    return followNextHeaders({ ...params, url }, data);
  }

  return data;
}

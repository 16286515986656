import { Reducers } from '@evoja-web/redaction';

import {
  codeMappingsReducer,
  formReducer,
  saveReducer
} from './CodeMapping/index';

export const reducers = Reducers([
  codeMappingsReducer,
  formReducer,
  saveReducer
]);

export default reducers.get();

import React from 'react';
import { InputGroup, FormControl, Modal, Button } from 'react-bootstrap';
import DragHandle from '../DragHandle';
import { SortableElement } from 'react-sortable-hoc';
import { BasicProductsDetails, BasicProductsDetailsFormSubmitButton } from './BasicProductsDetails';
import { mapBasicAdditionToApp } from '../../services/BasicProducts';

const styles = {
  productName: { width: '80%' },
  category: { width: '20%' },
  controlGroup: { cursor: 'pointer' },
  settingsButton: { marginRight: '10px' },
};

class Item extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDetailsOpen: false,
    };

    this.openDetails = this.openDetails.bind(this);
    this.closeDetails = this.closeDetails.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeCategory = this.onChangeCategory.bind(this);
  }

  render() {
    const { product } = this.props;

    return (
      <div className="SortableHOCItem">
        <InputGroup className="navbar-form navbar-right" style={{width: '100%'}}>

          <InputGroup.Addon>
            <DragHandle />
          </InputGroup.Addon>

          <FormControl
            style={styles.productName}
            type="text"
            name="productName"
            value={product.name}
            onChange={this.onChangeName}/>

          <FormControl
            componentClass="select"
            style={styles.category}
            value={product.category}
            onChange={this.onChangeCategory}>
            {this.renderCategories()}
          </FormControl>

          <InputGroup.Addon style={styles.controlGroup}>
            <span onClick={this.openDetails} style={styles.settingsButton}>
              <i className="icon-213-setting"/>
            </span>
            <span onClick={this.removeProduct}>
              <i className="icon-008-bin"/>
            </span>
          </InputGroup.Addon>

        </InputGroup>
        {this.renderModal()}
      </div>
    );
  }


  openDetails() {
    this.setState({isDetailsOpen: true});
  }

  closeDetails() {
    this.setState({isDetailsOpen: false});
  }

  onChangeName(event) {
    const { product } = this.props;
    const { value } = event.target;
    this.updateProduct({...product, name: value});
  }

  onChangeCategory(event) {
    const { product, categories } = this.props;
    const { value } = event.target;
    const category = categories.find((it) => it.id === value);
    if (!category) {
      return;
    }
    this.updateProduct({
      ...product,
      category: value,
      additionDefault: category.defaults
        ? mapBasicAdditionToApp(category.defaults)
        : {
          displayClass: '',
          outputClass: '',
          detailGroup: [],
          changeType: [],
        },
    });
  }

  updateProduct(product) {
    const { saveProduct } = this.props;
    saveProduct(product);
  }

  removeProduct() {
    const { removeProduct, product } = this.props;
    removeProduct(product);
  }

  renderCategories() {
    const { categories, language } = this.props;
    return categories.map((category) => (
      <option value={category.id} key={category.id}>{category.name[language]}</option>
    ));
  }

  renderModal() {
    const {
      product,
      language,
      categories,
      additions,
      documents,
      displayClasses,
      detailGroups,
      changeTypes,
      outputClasses,
      coreTypes,
      saveProduct,
    } = this.props;

    return (
      <Modal show={this.state.isDetailsOpen} onHide={this.closeDetails}>
        <Modal.Header closeButton>
          <Modal.Title>Detaileinstellungen für {product.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BasicProductsDetails
            product={product}
            language={language}
            categories={categories}
            additions={additions}
            documents={documents}
            displayClasses={displayClasses}
            detailGroups={detailGroups}
            changeTypes={changeTypes}
            outputClasses={outputClasses}
            coreTypes={coreTypes}
            saveProduct={saveProduct}
            closeDialog={this.closeDetails} />
        </Modal.Body>
        <Modal.Footer>
          <BasicProductsDetailsFormSubmitButton/>
          <Button onClick={this.closeDetails}>
            <span className="icon-202-clear-circle" />Schliessen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export const BasicProductsItem = SortableElement(Item);

import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  HOLIDAYS_CREATE_ACTIONS,
  HOLIDAYS_EDIT_ACTIONS,
  HOLIDAYS_LOAD_ACTIONS,
  HOLIDAYS_REMOVE_ACTIONS,
  HOLIDAYS_SAVE_ACTIONS,
} from '../../actions/HolidaysActions';
import { holidaysLoadSaga } from './holidaysLoadSaga';
import { holidaysRemoveSaga } from './holidaysRemoveSaga';
import { holidaysCreateSaga } from './holidaysCreateSaga';
import { holidaysEditSaga } from './holidaysEditSaga';
import { holidaysSaveSaga } from './holidaysSaveSaga';

export function* holidaysSagas() {
  yield takeLatest(HOLIDAYS_LOAD_ACTIONS.REQUEST, holidaysLoadSaga);
  yield takeLatest(HOLIDAYS_SAVE_ACTIONS.REQUEST, holidaysSaveSaga);
  yield takeEvery(HOLIDAYS_CREATE_ACTIONS.REQUEST, holidaysCreateSaga);
  yield takeEvery(HOLIDAYS_EDIT_ACTIONS.REQUEST, holidaysEditSaga);
  yield takeEvery(HOLIDAYS_REMOVE_ACTIONS.REQUEST, holidaysRemoveSaga);
}

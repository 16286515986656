import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../actions/FinancingLoanProductActions';

import Spinner from '../Spinner';

class CoreTypes extends React.Component {
  componentWillMount() {
    this.props.dispatch(actions.getCoreTypes(this.props.productId));
  }

  // embed the products in to the EditContainer component
  render() {
    const coreTypes = this.props.coreTypes.map((coreType, i) => (<li
      className="list-group-item"
      key={i}
    >{coreType.text.de}
                                                                 </li>));
    return (this.props.requesting ? <Spinner /> : <ul className="list-group">{coreTypes}</ul>);
  }
}

function mapStateToProps(state) {
  return {
    coreTypes: state.financingLoanProducts.coreTypes,
    requesting: state.financingLoanProducts.requesting,
    language: state.login.language,
    hasError: state.financingLoanProducts.hasError,  
  }
}

export default connect(mapStateToProps)(CoreTypes);
export function validateSurchargeRealestatetypeForm(formData) {
  if (!formData) {
    return null;
  }
  return {
    items: formData.items.map((item) => ({
      id: !item.id,
      realestatetype: !item.realestatetype,
      firstMortgageRate: !isDecimal(item.firstMortgageRate),
      secondMortgageRate: !isDecimal(item.secondMortgageRate),
      withAdditionRate: !isDecimal(item.withAdditionRate),
    })),
  };
}

function isDecimal(value) {
  return /^[+-]?\d+(\.\d+)?$/.test(value);
}

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import './BulkLoadsExpandContent.css';
import { classList } from '../../utils/classList';
import { ExpandContent } from '../Common/ExpandContent';

export class BulkLoadsExpandContent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      expanded: get(props, 'expanded', false)
    };
  }

  render() {
    const { expanded } = this.state;
    const { title = '', children, titleSize = '' } = this.props;
    const iconClasses = classList({
      'icon-013-chevron-down': true,
      'bulk-loads-expand-content__icon': true,
      'bulk-loads-expand-content__icon--collapsed': !expanded,
    });

    return (
      <div className="bulk-loads-expand-content">
        <div className="bulk-loads-expand-content__header" onClick={this.toggleExpanded}>
          <div className="bulk-loads-expand-content__title" style={{ fontSize: titleSize }}>{title}</div>
          <div className={iconClasses}/>
        </div>
        <ExpandContent expanded={expanded} className="bulk-loads-expand-content__body">
          {children}
        </ExpandContent>
      </div>
    );
  }

  toggleExpanded = () => this.setState((prev) => ({ expanded: !prev.expanded }));
}

BulkLoadsExpandContent.propTypes = {
  expanded: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleSize: PropTypes.string,
};

BulkLoadsExpandContent.defaultProps = {
  expanded: false
}

import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import _ from 'lodash';

import EditContainer from '../components/EditContainer';
import * as actions from '../actions/FinancingLoanProductActions';

import CoreTypes from '../components/FinancingLoanProducts/CoreTypes';
import LoanProductDetails from '../components/FinancingLoanProducts/LoanProductDetails';

class FinancingLoanProducts extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      modalTitle: '',
    };
  }

  componentWillMount() {
    this.props.dispatch(actions.getLoanProducts());
  }

  modalOpen(title, target, id, event) {
    event.preventDefault();
    this.setState({
      showModal: true,
      modalTitle: title,
      modalTarget: target,
      productId: id,
    });
  }

  modalClose(event) {
    event.preventDefault();
    this.setState({
      showModal: false,
    });
  }

  setModalBody() {
    switch (this.state.modalTarget) {
      case 'FinnovaProducts':
        return (<CoreTypes productId={this.state.productId} />);
      case 'LoanProductDetails':
        return (<LoanProductDetails productId={this.state.productId} />);
      default:
        break;
    }
  }


  // embed the products in to the EditContainer component
  render() {
    let { requesting, loanProducts } = this.props;
    loanProducts = _.sortBy(loanProducts, ['sort']);
    const loanProduct = loanProducts.map((product, i) => {
      let color = '';
      switch (product.productType) {
        case 'mortgage':
          switch (product.durationType) {
            case 'fixed':
              color = '#F1C40F';
              break;
            case 'variable':
              color = '#92d654';
              break;
            default:
              break;
          }
          break;
        case 'rollover':
          color = '#b86bff';
          break;
        default:
          break;
      }
      /* eslint jsx-a11y/href-no-hash: 0 */
      return (
        <div key={i} className="panel panel-default">
          <div className="panel-heading" style={{ backgroundColor: color }}>
            {product.name.de} {product.shortName ? product.shortName.de : ''}
          </div>
          <div className="panel-body">
            <ul className="nav nav-pills">
              <li className=""><a>Usanz<span
                className="badge"
              >{product.usanceType === 'ch' ? 'CH' : 'INT'}
              </span>
              </a>
              </li>
              <li className=""><a
                href="#"
                onClick={this.modalOpen.bind(this, 'Finnova Produkte', 'FinnovaProducts', product.id)}
              >Finnova
                Produkte<span className="badge">{product.coreType.length}</span>
              </a>
              </li>
              <li className=""><a
                href="#"
                onClick={this.modalOpen.bind(this, 'Laufzeiten / Zinsen', 'LoanProductDetails', product.id)}
              >Laufzeiten<span
                className="badge"
              >{product.availableDurations.length}
              </span>
              </a>
              </li>
            </ul>
          </div>
        </div>);
    });

    return (
      <div>
        <EditContainer
          requesting={requesting}
          title="Produkte Eigenheim"
          body={loanProduct}
        />
        <Modal bsSize="large" show={this.state.showModal} onHide={this.modalClose.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.setModalBody()}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.modalClose.bind(this)}><span className="icon-202-clear-circle" />
              Schliessen
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loanProducts: state.financingLoanProducts.loanProducts,
    requesting: state.financingLoanProducts.requesting,
    language: state.login.language,
    hasError: state.financingLoanProducts.hasError,
  
  }
}

export default connect(mapStateToProps)(FinancingLoanProducts);

import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { TextEditor, ValidationResult } from '@evoja-web/react-form';

import Label from './Label';

const FormFieldComment = React.memo(function WorkflowFieldComment({
  id,
  onChange,
  showLabel,
  validations,
  value
}) {
  return (
    <div className="workflow-tree-form-element">
      {showLabel && (
        <Label id={id} />
      )}

      <div className="workflow-tree-form-element--component">
        <TextEditor
          id={id}
          name={id}
          onChange={onChange}
          value={value}
        />
      </div>

      <ValidationResult
        show
        validations={validations}
      />
    </div>
  );
});

FormFieldComment.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  showLabel: PropTypes.bool,
  validations: PropTypes.object,
  value: PropTypes.string
};

FormFieldComment.defaultProps = {
  id: 'comment',
  onChange: noop,
  showLabel: false,
  validations: {},
  value: undefined
};

export default FormFieldComment;

import { takeLatest } from 'redux-saga/effects';
import {
  NOTICE_IMPORT_FILE_LIST_LOAD,
  NOTICE_IMPORT_FILE_POST,
  NOTICE_IMPORT_VALIDATION,
  NOTICE_IMPORT_IMPORT,
  NOTICE_IMPORT_DELETE, NOTICE_IMPORT_EXAMPLES,
} from '../../actions/NoticeImportActions';
import {
  noticeImportFileListLoadSaga,
  noticeImportFilePostSaga,
} from './noticeImportFileSaga';
import { noticeImportImportSaga } from './noticeImportImportSaga';
import { noticeImportExamplesSaga } from './noticeImportExamplesSaga';
import { noticeImportValidationSaga } from './noticeImportValidationSaga';
import { noticeImportDeleteSaga } from './noticeImportDeleteSaga';


export function* noticeImportSagas() {
  yield takeLatest(NOTICE_IMPORT_FILE_LIST_LOAD.REQUEST, noticeImportFileListLoadSaga);
  yield takeLatest(NOTICE_IMPORT_EXAMPLES.REQUEST, noticeImportExamplesSaga);
  yield takeLatest(NOTICE_IMPORT_FILE_POST.REQUEST, noticeImportFilePostSaga);
  yield takeLatest(NOTICE_IMPORT_IMPORT.REQUEST, noticeImportImportSaga);
  yield takeLatest(NOTICE_IMPORT_VALIDATION.REQUEST, noticeImportValidationSaga);
  yield takeLatest(NOTICE_IMPORT_DELETE.REQUEST, noticeImportDeleteSaga);
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isUndefined } from 'lodash';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';

import './listItem.css';
import DragHandle from '../../DragHandle';
import Form from './Form';
import LanguageAwareInput from '../../Common/LanguageAwareInput';
import {
  getOperationFromDateValue,
  getValueFromDateValue
} from './utils';

class ApprovalListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false
    };

    this.onEdit = this.onEdit.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onEdit(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    this.setState({ edit: true });
  }

  onCancel() {
    const { onCancel } = this.props;
    this.setState({ edit: false });

    onCancel();
  }

  onRemove(ev) {
    const { onRemove } = this.props;

    ev.preventDefault();
    ev.stopPropagation();

    this.setState({ edit: false });

    onRemove();
  }

  onSubmit(data) {
    const { onSubmit } = this.props;

    this.setState({ edit: false });
    onSubmit(data);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { edit } = this.state;
    const {
      data,
      consultants,
      codes
    } = this.props;

    const defaultCompletionDate = getValueFromDateValue(data);
    const completionDateOperation = getOperationFromDateValue(get(data, 'completionDate.defaultValue'));
    const assignees = consultants
      .filter((c) => get(data, 'consultant.defaultValue', []).includes(get(c, 'id')))
      .map((consultant) => `${get(consultant, 'firstName')} ${get(consultant, 'lastName')} (${get(consultant, 'username')})`)
      .join(', ');

    return (
      <div className="SortableHOCItem">
        <div className="workguide-approval-list-item-container">
          <div className="workguide-approval-list-item-header">
            <div className="pull-right">
              <div
                className="glyphicon glyphicon-pencil"
                style={{ paddingRight: '5px', cursor: 'pointer' }}
                onClick={this.onEdit}
              />
              <span
                className="glyphicon glyphicon-remove"
                style={{ cursor: 'pointer' }}
                onClick={this.onRemove}
              />
             </div>
          </div>

          <div className="workguide-approval-list-item">
            <div className="workguide-approval-list-item-drag-handle">
              <DragHandle />
            </div>

            <div style={{ width: '100%' }}>
              {edit && (
                <Form
                  onCancel={this.onCancel}
                  consultants={consultants}
                  codes={codes}
                  onSubmit={this.onSubmit}
                  data={data}
                />
              )}

              {!edit && (
                <div className="workguide-approval-list-item-content">
                  <Row>
                    <Col lg={8} md={8} style={{ fontWeight: 'bold' }}>
                      Titel
                    </Col>

                    <Col lg={2} md={2}>
                      Workguide kann nicht berarbeitet werden:
                      {' '}
                      {get(data, 'isWorkguideImmutable', false) ? 'Ja' : 'Nein'}
                    </Col>

                    <Col lg={2} md={2}>
                      Ist Finaler Schritt:
                      {' '}
                      {get(data, 'isFinalStep', false) ? 'Ja' : 'Nein'}
                    </Col>

                    <Col lg={12} md={12}>
                      <LanguageAwareInput
                        edit={false}
                        values={get(data, 'title', {})}
                      />
                    </Col>
                  </Row>

                  <Row style={{ paddingBottom: '15px' }} />

                  <Row>
                    <Col lg={12} md={12} style={{ fontWeight: 'bold' }}>
                      Beschreibung
                    </Col>

                    <Col lg={12} md={12}>
                      <LanguageAwareInput
                        edit={false}
                        values={get(data, 'description', {})}
                      />
                    </Col>
                  </Row>

                  <Row style={{ paddingBottom: '40px' }} />

                  <Row>
                    <Col lg={12} md={12} style={{ fontWeight: 'bold' }}>
                      Fälligkeitsdatum
                    </Col>
                    <Col lg={12} md={12}>
                      <span className="workguide-approval-list-item-config-value">
                        Standardwert:
                      </span>

                      <span className="workguide-approval-list-item-config-value">
                        {!isUndefined(defaultCompletionDate) && get(defaultCompletionDate, 'length', 0) > 0 ? moment(defaultCompletionDate).format('L') : completionDateOperation}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} md={6}>
                      <span className="workguide-approval-list-item-config-value">
                        Pflichtfeld:
                      </span>

                      <span className="workguide-approval-list-item-config-value">
                        {get(data, 'completionDate.required', false) ? 'Ja' : 'Nein'}
                      </span>
                    </Col>

                    <Col lg={6} md={6}>
                      <span className="workguide-approval-list-item-config-value">
                        Gesperrt:
                      </span>

                      <span className="workguide-approval-list-item-config-value">
                        {get(data, 'completionDate.disabled', false) ? 'Ja' : 'Nein'}
                      </span>
                    </Col>
                  </Row>

                  <Row style={{ paddingTop: '5px' }}>
                    <Col lg={12} md={12} style={{ fontWeight: 'bold' }}>
                      Bewilligungsinstanz
                    </Col>
                    <Col lg={12} md={12}>
                      <span className="workguide-approval-list-item-config-value">
                        Standardwert:
                      </span>

                      <span className="workguide-approval-list-item-config-value">
                        {assignees}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} md={6}>
                      <span className="workguide-approval-list-item-config-value">
                        Pflichtfeld:
                      </span>

                      <span className="workguide-approval-list-item-config-value">
                        {get(data, 'consultant.required', false) ? 'Ja' : 'Nein'}
                      </span>
                    </Col>

                    <Col lg={6} md={6}>
                      <span className="workguide-approval-list-item-config-value">
                        Gesperrt:
                      </span>

                      <span className="workguide-approval-list-item-config-value">
                        {get(data, 'consultant.disabled', false) ? 'Ja' : 'Nein'}
                      </span>
                    </Col>
                  </Row>

                  <Row style={{ paddingTop: '5px' }}>
                    <Col lg={12} md={12} style={{ fontWeight: 'bold' }}>
                      Kommentar
                    </Col>
                    <Col lg={12} md={12}>
                      <span className="workguide-approval-list-item-config-value">
                        Standardwert:
                      </span>

                      <LanguageAwareInput
                        edit={false}
                        values={get(data, 'comment.defaultValue', {})}
                      />

                      <div style={{ paddingBottom: '20px' }}/>
                    </Col>
                  </Row>

                  <Row style={{ paddingBottom: '10px' }} />

                  <Row>
                    <Col lg={6} md={6}>
                      <span className="workguide-approval-list-item-config-value">
                        Pflichtfeld:
                      </span>

                      <span className="workguide-approval-list-item-config-value">
                        {get(data, 'comment.required', false) ? 'Ja' : 'Nein'}
                      </span>
                    </Col>

                    <Col lg={6} md={6}>
                      <span className="workguide-approval-list-item-config-value">
                        Gesperrt:
                      </span>

                      <span className="workguide-approval-list-item-config-value">
                        {get(data, 'comment.disabled', false) ? 'Ja' : 'Nein'}
                      </span>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ApprovalListItem.propTypes = {
  onSubmit: PropTypes.func,
  onRemove: PropTypes.func,
  onCancel: PropTypes.func,
  consultants: PropTypes.array,
  codes: PropTypes.object
};

ApprovalListItem.defaultProps = {
  onSubmit: noop,
  onRemove: noop,
  onCancel: noop,
  consultants: [],
  codes: {}
};

export default ApprovalListItem;

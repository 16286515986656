import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { ValidationResult } from '@evoja-web/react-form';

import Label from './Label';
import FlexDateConfiguration from '../../../../Form/Field/Date/Configuration/Flex';

const FormFieldDate = React.memo(function FormFieldDate({
  id,
  onChange,
  showLabel,
  validations,
  value
}) {
  const onValueChange = useCallback((value) => {
    onChange(id, value);
  }, [id, onChange]);

  return (
    <div className="workflow-tree-form-element">
      {showLabel && (
        <Label id={id} />
      )}

      <div className="workflow-tree-form-element--component">
        <FlexDateConfiguration
          onChange={onValueChange}
          value={value}
        />
      </div>

      <ValidationResult
        show
        validations={validations}
      />
    </div>
  );
});

FormFieldDate.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  showLabel: PropTypes.bool,
  validations: PropTypes.object,
  value: PropTypes.string
};

FormFieldDate.defaultProps = {
  id: 'date',
  onChange: noop,
  showLabel: true,
  validations: {},
  value: undefined
};

export default FormFieldDate;

import './FinancingProfiling.css';

import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { handleGravitonError } from '../../utils';
import PageContainer from '../Common/PageContainer';
import { FinancingProfilingProfiles } from './FinancingProfilingProfiles';

export class FinancingProfiling extends React.Component {
  constructor(props) {
    super(props);
    props.load();
  }

  render() {
    const { loading, error, save } = this.props;
    if (error) {
      toast.error(handleGravitonError(error));
    }

    return (
      <PageContainer
        title={<FormattedMessage id="financingProfiling_title"/>}
        requesting={loading}
        saveData={save}
        isTransparentLoader={true}
      >
        {this.renderTable()}
      </PageContainer>
    );
  }

  renderTable() {
    const { profileOptions, predictionOptions } = this.props;
    return (
      <div className="financing-profiling__table">
        <div className="financing-profiling__row financing-profiling__row--header">
          <div className="financing-profiling__cell financing-profiling__cell--title"/>
          {profileOptions.map((it) => <div className="financing-profiling__cell" key={it.value}>{it.label}</div>)}
        </div>
        {predictionOptions.map((prediction) => this.renderRow(prediction))}
      </div>
    );
  }

  renderRow(prediction) {
    const { profileOptions } = this.props;

    return (
      <div className="financing-profiling__row" key={prediction.value}>
        <div className="financing-profiling__cell financing-profiling__cell--title">
          {prediction.label}
        </div>
        {profileOptions.map((it) => this.renderCell(it.value, prediction.value))}
      </div>
    );
  }

  renderCell(profileId, predictionId) {
    const { profiles: allProfiles, profileOptions, predictionOptions, loanProductOptions, durations } = this.props;
    const profiles = allProfiles.filter((it) => it.profileId === profileId && it.predictionId === predictionId);

    return (
      <div className="financing-profiling__cell" key={`${profileId}-${predictionId}`}>
        <FinancingProfilingProfiles
          profileId={profileId}
          predictionId={predictionId}
          profiles={profiles}
          profileOptions={profileOptions}
          predictionOptions={predictionOptions}
          loanProductOptions={loanProductOptions}
          durations={durations}
          onChange={this.onChange}
          onRemove={this.onRemove}
        />
      </div>
    );
  }

  onRemove = (product) => {
    const { profiles, update } = this.props;
    update(profiles.filter((it) => it.id !== product.id));
  };

  onChange = (product) => {
    const { profiles, update } = this.props;
    update(
      product.id
        ? profiles.map((it) => it.id === product.id ? product : it)
        : [...profiles, product],
    );
  };
}

FinancingProfiling.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,

  load: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,

  profiles: PropTypes.array.isRequired,
  profileOptions: PropTypes.array.isRequired,
  predictionOptions: PropTypes.array.isRequired,
  loanProductOptions: PropTypes.array.isRequired,
  durations: PropTypes.array.isRequired,
};

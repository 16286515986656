import { Reducers } from '@evoja-web/redaction';

import { reducer as applyLocalFiltersReducer } from './ReplayAuditLog/ApplyLocalFilter';
import { reducer as auditLogReducer } from './ReplayAuditLog/AuditLog';
import { reducer as connectSourceReducer } from './ReplayAuditLog/ConnectSource';
import { reducer as connectTargetReducer } from './ReplayAuditLog/ConnectTarget';
import { reducer as currentRecordReducer } from './ReplayAuditLog/CurrentRecord';
import { reducer as filterReducer } from './ReplayAuditLog/Filter';
import { reducer as localFiltersReducer } from './ReplayAuditLog/LocalFilters';
import { reducer as preparedRecordsReducer } from './ReplayAuditLog/PreparedRecords';
import { reducer as progressReducer } from './ReplayAuditLog/Progress';
import { reducer as replayReducer } from './ReplayAuditLog/Replay';
import { reducer as replayRecordReducer } from './ReplayAuditLog/ReplayRecord';

export const reducers = Reducers([
  applyLocalFiltersReducer,
  auditLogReducer,
  connectSourceReducer,
  connectTargetReducer,
  currentRecordReducer,
  filterReducer,
  localFiltersReducer,
  preparedRecordsReducer,
  progressReducer,
  replayReducer,
  replayRecordReducer
]);

export default reducers.get();

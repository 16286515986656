import React from 'react';
import PropTypes from 'prop-types';
import { range, noop, get, isInteger } from 'lodash';
import { Pagination } from 'react-bootstrap';
import PacmanLoader from "react-spinners/PacmanLoader";

import './PaginationNoTotalCount.css';

class PaginationNoTotalCount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 1,
      offset: 0,
      pageCount: 1
    };

    this.hasPrev = this.hasPrev.bind(this);
    this.hasNext = this.hasNext.bind(this);
    this.onFirst = this.onFirst.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onNext = this.onNext.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { current, pageCount } = state;
    const { recordCount, requesting, pageSize } = props;

    if (requesting) return null;

    const updatedPageCount = Math.ceil(recordCount / pageSize);

    if (updatedPageCount !== pageCount) {
      const active = current > updatedPageCount ? 1 : current;

      return {
        ...state,
        current: active,
        offset: (active - 1) * pageSize,
        pageCount: updatedPageCount
      }
    }

    return null;
  }

  onPageChange(page = 1) {
    const { onPageChange, pageSize } = this.props;

    const offset = (page - 1) * pageSize;

    this.setState({ current: page, offset });

    onPageChange({ page, limit: pageSize, offset });
  }

  hasPrev() {
    const { offset } = this.state;

    return offset > 0;
  }

  hasNext() {
    const { recordCount, pageSize } = this.props;

    return (
      recordCount > 0
      && isInteger(recordCount / pageSize)
    );
  }

  // Thx react-bootstrap... disabled does only change styles but not disable onClick...
  onFirst() {
    const { current } = this.state;

    if (current === 1) return;

    this.onPageChange(1);
  }

  onPrev() {
    const { current } = this.state;

    if (!this.hasPrev()) return;

    this.onPageChange(current - 1);
  }

  onNext() {
    const { current } = this.state;

    if (!this.hasNext()) return;

    this.onPageChange(current + 1);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { current, pageCount } = this.state;
    const { requesting } = this.props;

    const numbers = range(1, pageCount + 1).map((num) => {
      return (
        <Pagination.Item
          key={num}
          disabled={num === current || requesting}
          onClick={() => this.onPageChange(num)}
        >
          {num}
        </Pagination.Item>
      );
    });

    return (
      <div className="pagination-no-total-count">
        {requesting && (
          <div className="pagination-no-total-count--requesting">
            <PacmanLoader size={8} />
          </div>
        )}

        <Pagination className="pagination-no-total-count--pagination">
          <Pagination.First
            disabled={current === 1 || requesting}
            onClick={this.onFirst}
          />
          <Pagination.Prev
            disabled={!this.hasPrev() || requesting}
            onClick={this.onPrev}
          />

          {numbers}

          <Pagination.Next
            disabled={!this.hasNext() || requesting}
            onClick={this.onNext}
          />
        </Pagination>
      </div>
    );
  }
}

PaginationNoTotalCount.propTypes = {
  onPageChange: PropTypes.func,
  pageSize: PropTypes.number,
  recordCount: PropTypes.number,
  requesting: PropTypes.bool
};

PaginationNoTotalCount.defaultProps = {
  onPageChange: noop,
  pageSize: 25,
  recordCount: 0,
  requesting: false
};

export default PaginationNoTotalCount;

import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { Button } from 'react-bootstrap';

import Item from './Item';

const Items = SortableContainer(({
                                   items, language, onAdd, onRemove, editEventField, editValueField,
                                   knowledgeExperiences, assetSegments, assetAllocations, assetClasses,
                                   investmentStrategies, serviceSubTypes, investmentDocuments,
}) => {
  const aItems = items.map((item, i) => (
    <Item
      key={i}
      index={i}
      id={i}
      item={item}
      language={language}
      onRemove={onRemove}
      editEventField={editEventField}
      editValueField={editValueField}
      knowledgeExperiences={knowledgeExperiences}
      assetSegments={assetSegments}
      assetAllocations={assetAllocations}
      assetClasses={assetClasses}
      investmentStrategies={investmentStrategies}
      serviceSubTypes={serviceSubTypes}
      investmentDocuments={investmentDocuments}
    />
  ));

  return (
    <div>
      <div className="SortableHOCList">
        {aItems}
      </div>
      <Button
        bsStyle="primary"
        name="newItem"
        onClick={onAdd.bind(this)}
      >
        <span className="icon-061-plus" /> Neuer Eintrag
      </Button>
    </div>
  );
});

export default Items;

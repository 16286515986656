import _ from 'lodash';
import update from 'immutability-helper';

import {
  CODES_REQUEST,
  CODES_PENDING,
  CODES_FULFILLED,
  CODES_REJECTED,
  CODE_SAVE_REQUEST,
  CODE_SAVE_FULFILLED,
  CODE_SAVE_REJECTED,
  CODE_DELETE_REQUEST,
  CODE_DELETE_FULFILLED,
  CODE_DELETE_REJECTED
} from '../actions/CodeActions';

const defaultState = {
  requesting: false,
  error: false,
  groups: {},
  pending: [],
  failed: []
};

export default function CodeReducer(state = defaultState, action) {
  switch (action.type) {
    case CODES_REQUEST: {
      const pending = _.uniq([ ...state.pending, ...action.groups ]);

      return {
        ...state,
        requesting: true,
        pending
      };
    }
    case CODES_PENDING: {
      return {
        ...state,
        requesting: true,
        error: false
      }
    }
    case CODES_FULFILLED: {
      const grouped = action.payload.reduce((result, code) => {
        result[code.group] = result[code.group] || [];
        result[code.group].push(code);
        result[code.group] = _.orderBy(result[code.group], ['order'], ['asc'])
        return result;
      }, {});

      const groups = { ...state.groups, ...grouped }

      return {
        ...state,
        groups,
        requesting: false,
        error: false,
        pending: _.difference(state.pending, _.map(groups, (v, k) => k)),
        failed: _.difference(state.failed, _.map(groups, (v, k) => k))
      };
    }
    case CODES_REJECTED: {
      return {
        ...state,
        requesting: false,
        error: action.error,
        pending: _.difference(state.pending, action.groups),
        failed: [ ...state.failed, ...action.groups ]
      };
    }
    case CODE_SAVE_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: false
      }
    }
    case CODE_SAVE_FULFILLED: {
      const { code } = action;
      const group = _.get(state, `groups.${code.group}`, []);
      const index = group.findIndex(c => c.id === code.id);

      let updated = group;
      if (index > -1) {
        updated = update(updated, {
          $splice: [[index, 1, code]]
        });
      } else {
        updated = update(updated, {
          $push: [code]
        });
      }

      return update(state, {
        groups: {
          [code.group]: { $set: _.orderBy(updated, ['order'], ['asc']) }
        }
      });
    }
    case CODE_SAVE_REJECTED: {
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    }
    case CODE_DELETE_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: false
      };
    }
    case CODE_DELETE_FULFILLED: {
      const { code } = action;
      const group = _.get(state, `groups.${code.group}`);
      const index = group.findIndex(c => c.id === code.id);

      let updated = group;
      if (index > -1) {
        updated = update(updated, {
          $splice: [[index, 1]]
        });
      }

      return update(state, {
        groups: {
          [code.group]: { $set: updated }
        }
      });
    }
    case CODE_DELETE_REJECTED: {
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    }
    default: {
      return state;
    }
  }
}

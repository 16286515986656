import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, ControlLabel, FormControl } from 'react-bootstrap';
import Toggle from 'react-toggle';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import _ from 'lodash';

export default class CollateralSettings extends Component {
  constructor(props) {
    super(props);
    this.multiselectChange = this.multiselectChange.bind(this);
  }

  multiselectChange(values) {
    this.props.editValueField('coreType', this.props.id, values)
  }

  render() {
    const {
      id, item, language, editEventField, editValueField, collateralTypes,
    } = this.props;
    /** ************
    OK, this looks confusing and need some explanation
    The following lines will solve the problem, that finnova codes
    can change over time and must not be in sync with eVoja configuration
    so when a finnova code is no longer available, this should be displayed
    to the user and not throw a error. So here we check for that.

    1: we check if collateralTypes are already loaded to the store
    2: than we loop over all coreTypes in this collateralType
    3: check if we have a corresponding coreType in enity/codes (This check is needed because
       the core type configured to this collateralType may be deleted on the core system
       in the meantime)
    4: if there is still a corresponding core type, get the record from /entity/codes return
       it to be stored in the selectedCoreTypes constant (this will be our preselected values
       in the MultiSelect)
    6: if there is no corresponding core type in entity/codes then return a "dummy" Object
      of a entity/code
       to display, that this code is no longer available
    8: if collateralTypes is not already loaded, return a empty array
       (else case for check on line 1)
    ************** */
    /* 1 */ const selectedCoreTypes = collateralTypes.length > 0 ?
      /* 2 */ item.coreType.map((o) => {
        /* 3 */ if (_.find(collateralTypes, { id: _.last(o.$ref.split('/')) })) {
          /* 4 */ return collateralTypes.filter(type => type.id === _.last(o.$ref.split('/')))[0];
          /* 5 */ }
        /* 6 */ return { id: _.last(o.$ref.split('/')), text: { de: `Verknüpfter Code ${_.last(o.$ref.split('/'))} nicht gefunden` } };
        /* 7 */
        /* 8 */ }) : [];
    /** ************
    End of explanation
    ************** */
    return (
      <div>
        <Form>
          <ControlLabel>Zusatzsicherheiten Bezeichnung</ControlLabel>
          <FormControl
            placeholder="Bezeichnung"
            type="text"
            value={item.name[language]}
            name="title"
            onChange={editEventField.bind(this, 'name', id)}
          />
          <label style={{ display: 'flex', paddingTop: '10px' }}>
            <Toggle
              checked={item.steeringCode}
              onChange={editEventField.bind(this, 'steeringCode', id)}
            />
            <span style={{ paddingLeft: '10px' }}>Reduziert die Amortisation</span>
          </label>
          <label style={{ display: 'flex', paddingTop: '10px' }}>
            <Toggle
              checked={item.steeringCodeBorrowingBase}
              onChange={editEventField.bind(this, 'steeringCodeBorrowingBase', id)}
            />
            <span style={{ paddingLeft: '10px' }}>Reduziert die Nettobelehnung</span>
          </label>
          <label style={{ display: 'flex', paddingTop: '10px' }}>
            <span style={{ paddingRight: '10px', paddingTop: '14px' }}>Anrechnung in Prozent</span>
            <div className="slider-horizontal" style={{ width: '200px', display: 'inline-block' }}>
              <Slider
                min={0}
                max={100}
                value={item.acceptanceFactor * 100}
                onChange={editValueField.bind(this, 'acceptanceFactor', id)}
                tooltip={false}
                step={5}
              />
            </div>
            <span className="value" style={{ marginLeft: '5px', paddingTop: '14px' }}>{item.acceptanceFactor * 100}%</span>
          </label>
          <hr />
          <ControlLabel>Verknüpfte Finnova Codes</ControlLabel>
          <Select
            multi
            placeholder="Bitte Finnova Codes auswählen"
            value={selectedCoreTypes.map(o => ({ label: o.text[language], value: o.id }))}
            options={collateralTypes.map(o => ({ label: o.text[language], value: o.id }))}
            onChange={this.multiselectChange}
            closeOnSelect={false}
          />
        </Form>
      </div>
    );
  }
}

CollateralSettings.propTypes = {
  id: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  editEventField: PropTypes.func.isRequired,
  editValueField: PropTypes.func.isRequired,
  collateralTypes: PropTypes.array.isRequired,
};

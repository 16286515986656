import {
  GET,
  PUT,
  DEL,
  SET_DATA,
  SET_CHANGED,
  GET_MODULES,
} from '../actions/CheckpointActions';

export default function CheckpointsReducer(
  state = {
    checkpoints: [],
    modules: [],
    requesting: false,
    error: {},
    hasError: false,
    changed: false,
  },
  action,
) {
  switch (action.type) {
    case SET_CHANGED: {
      return { ...state, changed: action.data };
    }
    case SET_DATA: {
      return { ...state, checkpoints: action.data };
    }
    case `${GET}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${GET}_FULFILLED`: {
      return { ...state, requesting: false, checkpoints: action.payload.data };
    }
    case `${GET}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload.data,
      };
    }
    case `${GET_MODULES}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${GET_MODULES}_FULFILLED`: {
      return { ...state, requesting: false, modules: action.payload.data };
    }
    case `${GET_MODULES}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload.data,
      };
    }
    case `${PUT}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${PUT}_FULFILLED`: {
      return { ...state, requesting: false };
    }
    case `${PUT}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case `${DEL}_PENDING`: {
      return { ...state, requesting: true };
    }
    case `${DEL}_FULFILLED`: {
      return { ...state, requesting: false };
    }
    case `${DEL}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

import { DataKeyReducer } from '@evoja-web/redaction';
import { get } from 'lodash';
import update from 'immutability-helper';

import action, { updateLeadAction } from '../actions/UpdateLead';

const { UPDATE_LEAD_FULFILLED } = action;

function afterUpdateLeadFulfilled(state, action) {
  const { payload } = action;

  const index = get(state, 'leads.data', []).findIndex((l) => l.id === payload.id);

  return index > -1
    ? update(state, {
      leads: {
        data: { $splice: [[index, 1, payload]] }
      }
    })
    : state;
}

export const reducer = DataKeyReducer('updateLead', updateLeadAction);
reducer.after(UPDATE_LEAD_FULFILLED, afterUpdateLeadFulfilled);

export default reducer.get();

import { UI_NOTES_UPDATE_ACTIONS } from '../../actions/UiNotesActions';

export function uiNotesUpdateReducer(state, action) {
  switch (action.type) {
    case UI_NOTES_UPDATE_ACTIONS.PENDING:
      return {
        ...state,
        saving: true,
      };

    case UI_NOTES_UPDATE_ACTIONS.SUCCESS:
      return {
        ...state,
        saving: false,
        data: {
          ...state.data,
          notes: state.data.notes.map(it => it.id === action.data.note.id ? action.data.note : it),
        },
      };

    case UI_NOTES_UPDATE_ACTIONS.FAILURE:
      return {
        ...state,
        saving: false,
        error: action.data,
      };

    default:
      return state;
  }
}

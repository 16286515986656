import './FeatureFlagList.css';

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { ListGroup } from 'reactstrap';
import FeatureFlagItem from './FeatureFlagItem';

function FeatureFlagList({ featureFlags, onUpdate, language }) {
  return (
    <div className="feature-flag-list">
      <div className="feature-flag-list__header">
        <div className="feature-flag-list__text">Identifier</div>
        <div className="feature-flag-list__text-short">Name</div>
      </div>
      <ListGroup>
        {featureFlags.map((featureFlag, index) => (
          <FeatureFlagItem
            key={get(featureFlag, 'id')}
            index={index}
            id={index}
            featureFlag={featureFlag}
            onUpdate={onUpdate}
            language={language}
          />
        ))}
      </ListGroup>
    </div>
  );
}

FeatureFlagList.propTypes = {
  featureFlags: PropTypes.array,
  onUpdate: PropTypes.func,
  language: PropTypes.string
};

FeatureFlagList.defaultProps = {
  featureFlags: [],
  onUpdate: noop,
  language: 'de'
};

export default FeatureFlagList;

import { isNil } from 'lodash';
import Fuse from 'fuse.js';

import getPossibleSourceItemNamePaths from './getPossibleSourceItemNamePaths';

const fuseOptions = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1
};

/**
 * Filter the given items by the given search term.
 * Return all items if searchTerm is null or undefined
 *
 * @param   {Array}  items       Array of items to filter
 * @param   {String} language    User language
 * @param   {String} searchTerm  Search term
 *
 * @return  {Array} filtered Filtered items
 */
export default function filterSourceItemsBySearchTerm({
  items = [],
  language = 'de',
  searchTerm
}) {
  if (isNil(searchTerm)) {
    return items;
  }

  const options = {
    ...fuseOptions,
    keys: [
      ...getPossibleSourceItemNamePaths({ language }),
      'data.id',
      'data.type',
      'data.serviceUri'
    ]
  };

  const fuse = new Fuse(items, options);

  return fuse.search(searchTerm);
}

import './AnalyticsLogsSearchResults.css';

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { PROP_TYPES_DATES, PROP_TYPES_SEARCH_RESULT_ITEM } from './AnalyticsLogsPropTypes';
import { groupDates } from '../../services/AnalyticsLogs/groupDates';
import { convertDateToApp } from '../../utils/dateConverter';

class AnalyticsLogsSearchResultsChartWithIntl extends React.PureComponent {
  render() {
    return (
      <div className="analytics-logs-results-chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={this.createOptions()}
        />
      </div>
    );
  }

  createOptions() {
    const { searchResults, dates, intl } = this.props;
    const resultsDates = searchResults.map((it) => convertDateToApp(it.createdAt));
    const startDate = dates.startDate
      ? dates.startDate
      : resultsDates[resultsDates.length - 1].startOf('date');
    const endDate = dates.startDate && dates.endDate
      ? dates.endDate
      : resultsDates[0].endOf('date');

    const startDateFormatted = startDate.format('DD.MM.YYYY');
    const endDateFormatted = endDate.format('DD.MM.YYYY');
    const isOneDay = startDateFormatted === endDateFormatted;
    const dateFormat = isOneDay ? 'HH:mm' : 'DD.MM.YYYY HH:mm';

    const data = groupDates(resultsDates, startDate, endDate);
    const seriesData = data.map((it) => it.count);
    const categories = data.map((it) => [
      it.from.format(dateFormat),
      it.to.format(dateFormat),
    ].join(isOneDay ? ' - ' : '<br/>'));

    return {
      chart: {
        type: 'column',
      },
      title: {
        text: isOneDay
          ? startDateFormatted
          : `${startDateFormatted} - ${endDateFormatted}`,
      },
      tooltip: {
        headerFormat: '{point.key}<br/>',
        pointFormat: '<b>{point.y}</b>',
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: categories,
      },
      yAxis: {
        title: {
          text: intl.formatMessage({ id: 'analyticsLogs_searchResultsChart_requests' }),
        },
      },
      series: [
        {
          data: seriesData,
        },
      ],
    };
  }
}

export const AnalyticsLogsSearchResultsChart = injectIntl(AnalyticsLogsSearchResultsChartWithIntl);
AnalyticsLogsSearchResultsChart.propTypes = {
  searchResults: PropTypes.arrayOf(PROP_TYPES_SEARCH_RESULT_ITEM).isRequired,
  dates: PROP_TYPES_DATES.isRequired,
};

import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import initSourceAction from '../actions/InitSource';
import getModules from './getModules';
import getServices from './getServices';

const {
  INIT_SOURCE_PENDING,
  INIT_SOURCE_FULFILLED,
  INIT_SOURCE_REJECTED
} = initSourceAction;

export default function* initSource() {
  yield put({ type: INIT_SOURCE_PENDING });

  try {
    const modules = yield call(getModules, { axiosInstance: axios });
    const services = yield call(getServices, { axiosInstance: axios });

    const payload = {
      axiosInstance: axios,
      modules,
      services
    };

    yield put({ type: INIT_SOURCE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: INIT_SOURCE_REJECTED, error });

    return error;
  }
}

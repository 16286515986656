import { createSelector } from 'reselect';
import { getConsultantsRaw } from './permissionGroupCommonSelectors';

export const getConsultants = createSelector([
    getConsultantsRaw,
], (consultants) => {
  function mapConsultant(consultant) {
    return {
      id: consultant.id,
      view: {
        firstName: consultant.firstName,
        lastName: consultant.lastName,
        username: consultant.username,
        tenant: consultant.tenant,
        permission: consultant.permission
      },
      form: {
        firstName: consultant.firstName,
        lastName: consultant.lastName,
        username: consultant.username,
        tenant: consultant.tenant,
        permission: consultant.permission,
      },
      };
    }

  return consultants.map((consultant) => mapConsultant(consultant));
});

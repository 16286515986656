import { createSelector } from 'reselect';

export const getRefErrorsState = (state) => state.refErrors;
export const getRefErrorsError = createSelector([getRefErrorsState], (state) => state.error);
export const getRefErrorsLoading = createSelector([getRefErrorsState], (state) => state.loading);
export const getRefErrorsTextBlocks = createSelector([getRefErrorsState], (state) => state.textBlocks);
export const getRefErrorsResultsRaw = createSelector([getRefErrorsState], (state) => state.results);
export const getRefErrorsSearchParams = createSelector([getRefErrorsState], (state) => state.searchParams);
export const getAllRefErrors = createSelector([getRefErrorsState], (state) => state.allResults);
export const getRefErrorsResultsCount = createSelector([
  getRefErrorsResultsRaw,
], (results) => results ? results.totalCount : 0);

const getPathname = () => window.location.pathname;
export const getRefErrorsListId = createSelector([getPathname], (pathname) => {
  const match = pathname.match(/^\/ref-errors\/(.+)$/);
  return match ? decodeURIComponent(match[1]) : null;
});

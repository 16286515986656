import { CONSULTANTS_CREATE_ACTIONS } from '../../actions/ConsultantsActions';

export function consultantsCreateReducer(state, action) {
  switch (action.type) {
    case CONSULTANTS_CREATE_ACTIONS.PENDING:
      return {
        ...state,
        saving: true,
      };

    case CONSULTANTS_CREATE_ACTIONS.SUCCESS:
      return {
        ...state,
        saving: false,
        data: {
          ...state.data,
          consultants: [
            ...state.data.consultants,
            action.data.consultant,
          ],
        },
      };

    case CONSULTANTS_CREATE_ACTIONS.FAILURE:
      return {
        ...state,
        saving: false,
        error: action.data,
      };

    default:
      return state;
  }
}

import '../Common/SortableTable.css';

import React from 'react';
import PropTypes from 'prop-types';
import { noop, orderBy } from 'lodash';
import { Table, TableCell, TableHead } from '../Common/Table';
import { SortIndicator } from '../Common/SortIndicator';
import { classList } from '../../utils/classList';
import { BULK_LOADS_DEFAULT_SORT } from '../../constants/BulkLoadsConstants';

let sortFields = {
	sortField: BULK_LOADS_DEFAULT_SORT.sortField,
	sortType: BULK_LOADS_DEFAULT_SORT.sortDirection
}

export class BulkLoadsSortableTable extends React.Component {
	state = {
		sortField: this.props.sortField,
		sortType: this.props.sortType,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (!nextProps.isExternalSortField) {
			return null;
		}

		if (
			nextProps.sortField === prevState.sortField &&
			nextProps.sortType === prevState.sortType
		) {
			return null;
		}

		return {
			sortField: nextProps.sortField,
			sortType: nextProps.sortType,
		};
	}

	render() {
		const { sortableFields, renderItem, className } = this.props;
		const items = this.getSortedItems();

		return (
			<Table className={classList('sortable-table', className)}>
				<TableHead>
					{sortableFields.map((field, index) => this.renderHeadCell(field, index))}
				</TableHead>

				{items.map((item, index) => renderItem(item, index))}
			</Table>
		);
	}

	renderHeadCell(field, index) {
		if (!field.sortFn) {
			return (
				<TableCell className="sortable-table__cell" key={index}>
					{field.label || " "}
				</TableCell>
			);
		}

		const sortFieldIndex = sortFields.sortField.indexOf(field.key);
		let sortIndicator;

		if (sortFieldIndex >= 0) {
			sortIndicator = sortFields.sortType.at(sortFieldIndex);
		} else {
			sortIndicator = undefined;
		}

		return (
			<TableCell className="sortable-table__cell" key={index} onClick={this.sortBy(field.key)}>
				<span className="sortable-table__sortable">
					{field.label}
					<SortIndicator sort={sortIndicator} />
				</span>
			</TableCell>
		);
	}

	addingToSearchFields(sortField, sortType, field) {
		if (sortFields.sortField.includes(field)) {

			const sortFieldIndex = sortFields.sortField.indexOf(field);

			if (sortField === undefined && sortType === undefined || sortFields.sortType.at(sortFieldIndex) === 'desc') {
				sortFields.sortField.splice(sortFieldIndex, 1);
				sortFields.sortType.splice(sortFieldIndex, 1);
			}
			else {
				sortFields.sortField.splice(sortFieldIndex, 1);
				sortFields.sortType.splice(sortFieldIndex, 1);

				sortFields.sortField.push(sortField);
				sortFields.sortType.push('desc');
			}
		} else {
			sortFields.sortField.push(sortField);
			sortFields.sortType.push(sortType);
		}
	}

	sortBy(field) {
		return () => this.setState((prev) => {
			const sortField = prev.sortField !== field
				? field
				: prev.sortType === 'asc' ? field : undefined;
			const sortType = prev.sortField !== field
				? 'asc'
				: prev.sortType === 'asc' ? 'desc' : undefined;

			const { onSort = noop } = this.props;

			this.addingToSearchFields(sortField, sortType, field);

			onSort(sortFields.sortField, sortFields.sortType);

			return {
				sortField,
				sortType,
			};
		});
	}

	getSortedItems() {
		const { items, sortableFields } = this.props;
		const { sortField, sortType } = this.state;
		if (!sortField) {
			return items;
		}

		const sortableField = sortableFields.find((it) => it.key === sortField);
		if (!sortableField || !sortableField.sortFn) {
			return items;
		}

		return orderBy(items, [sortableField.sortFn], [sortType]);
	}
}

BulkLoadsSortableTable.propTypes = {
	items: PropTypes.array.isRequired,
	sortableFields: PropTypes.arrayOf(PropTypes.shape({
		key: PropTypes.string.isRequired,
		label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		sortFn: PropTypes.func,
	})).isRequired,
	renderItem: PropTypes.func.isRequired,
	className: PropTypes.string,
	onSort: PropTypes.func,
	sortField: PropTypes.array,
	sortType: PropTypes.array,
	isExternalSortField: PropTypes.bool,
};

import './BulkLoadsDetailsChart.css';

import { isUndefined } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

import { BULK_LOAD_PROP_TYPES_RESULT_ITEM } from './BulkLoadsPropTypes';
import { BULK_LOADS_CHART_COLORS } from '../../constants/BulkLoadsConstants';

export class BulkLoadsLoaderChart extends React.PureComponent {
    state = {
        className: '',
    };

    render() {
        const { chartType } = this.props;
        const options = chartType === 'TargetLoader' ? this.createOptionsTargetLoader() : this.createOptionsStagingLoader();
        
        return (
            <div className="bulk-loads-loader-chart">
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType="chart"
                    options={options}
                    immutable={true}
                    containerProps={{ className: 'bulk-loads-loader-chart__chart-wrapper' }}
                />
                <p style={{fontSize: 12}}><b style={{ color: 'red' }}>Errors: </b>Loads ohne Sql execution time, MongoWite und MongoIndexierung</p>
            </div>
        );
    }

    createOptionsTargetLoader() {
        const { item } = this.props;
        const this_ = this;
        const startDates = item.actions.map((it) => it.start).filter(Boolean);
        const endDates = item.actions.map((it) => it.end).filter(Boolean);
        const maxDate = moment.max([...endDates, ...startDates]);
        const loadConfigItems = item.actions.filter(el => el.parameters.loadConfigObject)
        const data = loadConfigItems.map((it) => ({
            actionId: it.id,
            name: it.label,
            start: it.start
                ? it.start.valueOf()
                : undefined,
            end: it.end
                ? it.end.valueOf()
                : maxDate.valueOf(),
            color: BULK_LOADS_CHART_COLORS[it.state],
            metrics: {
                ...it.metrics,
                mongoDbDatabaseWriteTimeSeconds: this.convertUndefinedValues(it.metrics.mongoDbDatabaseWriteTimeSeconds)
            },
            state: it.state,
            // data for tooltips
            working: it.start,
            done: it.end,
            parameters: it.parameters
        }));
        data.sort((a, b) => {
            const totalA = a.metrics.mongoDbDatabaseWriteTimeSeconds;
            const totalB = b.metrics.mongoDbDatabaseWriteTimeSeconds;

            return totalA < totalB ? 1 : -1;
        });

        return {
            chart: {
                type: 'bar',
                height: data.length * 40 + 160
            },

            title: {
                text: 'TargetLoader'
            },
            tooltip: {
                formatter: function () {
                    const time = this_.formatDate({ value: this.y });
                    return `${this.x} ${time}`;
                }
            },
            xAxis: {
                // Every single load
                categories: data.map(el => el.parameters.loadConfigObject),
                labels: {
                    formatter: function() {
                        const currentItem = data.find(el => el.parameters.loadConfigObject === this.value);
                        if(!currentItem) return;
                        const mongoWrite = currentItem.metrics.mongoDbDatabaseWriteTimeSeconds;
                        return `<p style="color:${ mongoWrite === 0 ? 'red': 'black'}">${this.value}</p>`;
                    }
                }
            },
            yAxis: {
                opposite: true,
                labels: {
                    formatter: this.formatDate,
                    rotation: -30
                },

            },

            legend: {
                reversed: true
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                },
            },
            series: [
                {
                    name: 'Write SQL results into MongoDB',
                    data: data.map(el => el.metrics.mongoDbDatabaseWriteTimeSeconds),
                    dataSorting: {
                        enabled: true
                    },
                }
            ]
        }
    }

    createOptionsStagingLoader() {
        const { item } = this.props;
        const this_ = this;
        const startDates = item.actions.map((it) => it.start).filter(Boolean);
        const endDates = item.actions.map((it) => it.end).filter(Boolean);
        const maxDate = moment.max([...endDates, ...startDates]);
        const source = item.actions.filter(el => el.parameters.source)
        const data = source.map((it) => ({
            actionId: it.id,
            name: it.label,
            start: it.start
                ? it.start.valueOf()
                : undefined,
            end: it.end
                ? it.end.valueOf()
                : maxDate.valueOf(),
            color: BULK_LOADS_CHART_COLORS[it.state],
            metrics: {
                ...it.metrics,
                spoolingTimeSeconds: this.convertUndefinedValues(it.metrics.spoolingTimeSeconds),
                mongoDbDatabaseWriteTimeSeconds: this.convertUndefinedValues(it.metrics.mongoDbDatabaseWriteTimeSeconds)
            },

            // data for tooltips
            working: it.start,
            done: it.end,
            parameters: it.parameters
        }));

        data.sort((a, b) => {
            const totalA = a.metrics.spoolingTimeSeconds + a.metrics.mongoDbDatabaseWriteTimeSeconds;
            const totalB = b.metrics.spoolingTimeSeconds + b.metrics.mongoDbDatabaseWriteTimeSeconds;

            return totalA < totalB ? 1 : -1;
        });

        return {
            chart: {
                type: 'bar',
                height: data.length * 40 + 160
            },
            title: {
                text: 'Staging'
            },
            xAxis: {
                // Every single load
                categories: data.map(el => el.parameters.source),
                labels: {
                    formatter: function() {
                        const currentItem = data.find(el => el.parameters.source === this.value);
                        if(!currentItem) return;
                        const fetchTime = currentItem.metrics.spoolingTimeSeconds;
                        const writeTime = currentItem.metrics.mongoDbDatabaseWriteTimeSeconds;
                        return `<p style="color:${fetchTime === 0 && writeTime === 0 ? 'red': 'black'}">${this.value}</p>`;
                    }
                }
            },
            yAxis: {
                opposite: true,
                labels: {
                    formatter: this.formatDate,
                    rotation: -30
                }
            },
            tooltip: {
                formatter: function () {
                    const time = this_.formatDate({ value: this.y });
                    return `${this.series.name}: ${time}`;
                }
            },
            legend: {
                reversed: true
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: [
                {
                    name: 'File Temp geladen',
                    data: data.map(el => el.metrics.spoolingTimeSeconds +  el.metrics.spoolingInitializeSeconds),
                },
                {
                    name: 'Temp File => mariadb',
                    data: data.map(el => el.metrics.loadDataInfileTimeSeconds),
                }
            ]
        }
    }

    formatDate(data) {
        const val = data.value;
        let start = moment();
        let end = moment().add(val, 's');
        let diff = end.diff(start);
        return moment.utc(diff).format("HH:mm:ss");
    }

    convertUndefinedValues(value) {
        return isUndefined(value) ? 0 : value
    }

}

BulkLoadsLoaderChart.propTypes = {
    item: BULK_LOAD_PROP_TYPES_RESULT_ITEM.isRequired,
    chartType: PropTypes.oneOf(['TargetLoader', 'Staging'])
};

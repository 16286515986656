import jmespath from '@metrichor/jmespath-plus';
import { isArray, some } from 'lodash';

import { convertValue, convertValues } from './convert';

export default function jmesConditionContains(config, data) {
  const { path, type, value = '' } = config;

  const values = convertValues(value.split(','), type);
  const resolved = jmespath.search(data, path);
  const converted = isArray(resolved)
    ? convertValues(resolved, type)
    : convertValue(resolved, type);

  return isArray(converted)
    ? type === 'date'
      ? some(values, (value) => some(converted, (c) => value.isSame(c)))
      : some(values, (value) => converted.includes(value))
    : type === 'date'
      ? some(values, (value) => value.isSame(converted))
      : some(values, (value) => converted.includes(value));
}

import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import { get, noop } from 'lodash';

const HiddenCols = React.memo(({ component, options, onChange }) => {
  const hiddenCols = get(component, 'props.hiddenCols', []);

  const children = options.map(o => (
    <div key={o.value} style={{ display: 'flex' }}>
      <div>
        <Toggle
          checked={!hiddenCols.find(c => c === o.value)}
          onChange={() => onChange(o.value)}
        />
      </div>
      <div style={{ paddingLeft: '20px' }}>{o.label}</div>
    </div>
  ));

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
});

HiddenCols.propTypes = {
  component: PropTypes.object.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func
};

HiddenCols.defaultProps = {
  options: [],
  onChange: noop
};

export default HiddenCols;

export function mapChecklistSections(checklist, config) {
  const { value: checklistModule = 'pos' } = config;
  if (
    !checklist[0] ||
    !checklist[0].modules ||
    !checklist[0].modules[checklistModule] ||
    !checklist[0].modules[checklistModule].useCases.length) {
    return [];
  }
  const { sections } = checklist[0].modules[checklistModule].useCases[0];
  return sections;
}

import { call, put } from 'redux-saga/effects';
import { NOTICE_IMPORT_VALIDATION } from '../../actions/NoticeImportActions';
import moment from 'moment';
import axios from 'axios';
import { EndpointName } from '../../constants/EndpointName';
import { VALIDATION_DATE_FORMATS } from '../../constants/NoticeImport';
import { get, every } from 'lodash';

export function* noticeImportValidationSaga(action) {
  const {
    availableProducts,
    consultantByUsernameMap,
    customerByNumberMap,
    entityCodes,
    fileId,
    rows,
    user
  } = action.data;

 try {
    yield put(NOTICE_IMPORT_VALIDATION.pending());
    const columnsMap = {
      0: {
        fieldName: 'kundenNr',
        validate: column => customerByNumberMap[column],
        errorText: 'notFound',
      },
      1: {
        fieldName: 'Erstelldatum',
        validate: column => moment(column, VALIDATION_DATE_FORMATS, true).isValid(),
        errorText: 'format',
      },
      2: {
        fieldName: 'Kontaktart',
        validate: column => entityCodes.data.find(item => item.id === column && item.group === 'orderOrigin'),
        errorText: 'notFound',
      },
      3: {
        fieldName: 'Thema',
        validate: column => !column || entityCodes.data.find(item => item.id === column && item.group === 'customerNoteTopic'),
        errorText: 'notFound',
      },
      4: {
        fieldName: 'MA',
        validate: column => Boolean(consultantByUsernameMap[column]),
        errorText: 'notFound',
      },
      5: {
        fieldName: 'Initiative',
        validate: column => !column || entityCodes.data.find(item => item.id === column && item.group === 'initiative'),
        errorText: 'notFound',
      },
      6: {
        fieldName: 'Qualität',
        validate: column => !column || entityCodes.data.find(item => item.id === column && item.group === 'noteQuality'),
        errorText: 'notFound',
      },
      7: {
        fieldName: 'Währung',
        validate: (column, row) => !row[8] || (row[8] && ['CHF', 'EUR', 'USD'].indexOf(column) !== -1),
        errorText: 'format',
      },
      8: {
        fieldName: 'Betrag',
        validate: column => !column || !isNaN(Number(column)),
        errorText: 'format',
      },
      9: {
        fieldName: 'InterneTeilnehmer',
        validate: column => {
          if (!column) {
            return true;
          }
          let consultantsExist = true;
          column.split(';').forEach(consultant => {
            if (!consultantByUsernameMap[consultant]) {
              consultantsExist = false;
            }
          });
          return consultantsExist;
        },
        errorText: 'notFound',
      },
      10: {
        fieldName: 'ExterneTeilnehmer',
        validate: column => !column || column.length <= 3000,
        errorText: 'tooLong',
      },
      11: {
        fieldName: 'Entscheid',
        validate: column => !column || column.length <= 3000,
        errorText: 'tooLong',
      },
      12: {
        fieldName: 'NächsterTermin',
        validate: column => !column || moment(column, VALIDATION_DATE_FORMATS, true).isValid(),
        errorText: 'format',
      },
      13: {
        fieldName: 'Produkte',
        validate: column => {
          return !column
            ? true
            : every(column.split(';'), (id) => availableProducts.find((p) => get(p, 'id') === id));
        },
        errorText: 'notFound',
      },
      14: {
        fieldName: 'Inhalt',
        validate: column => !column || column.length <= 3000,
        errorText: 'tooLong',
      },
    };

    const errors = [];
    rows.forEach((row, lineNumber) => {
      row.forEach((column, columnNumber) => {
        const columnInfo = columnsMap[columnNumber];

        if (columnInfo && !columnInfo.validate(column, row)) {
          errors.push({
            lineNumber: lineNumber + 1,
            columnNumber: columnNumber + 1,
            fieldName: columnInfo.fieldName,
            errorText: columnInfo.errorText,
          });
        }
      });
    });

    yield call(axios, {
      url: `${EndpointName.FILE}/${fileId}`,
      method: 'put',
      data: {
        id: fileId,
        metadata: {
          additionalProperties: [
            {
              name: 'lastAction',
              value: 'uploaded',
            },
            {
              name: 'totalRowsCount',
              value: `${rows.length}`,
            },
            {
              name: 'username',
              value: user.username,
            },
            {
              name: 'status',
              value: 'uploaded',
            },
          ],
        },
        links: [{
          type: 'module',
          $ref: `/core/module/adminv2-notice-import`,
        }],
      },
    });

    if (errors.length > 0) {
      yield call(axios, {
        url: `${EndpointName.FILE}/${fileId}`,
        method: 'delete',
      });
      throw errors;
    }

    yield put(NOTICE_IMPORT_VALIDATION.success(action.data.length));

  } catch (errors) {
    yield put(NOTICE_IMPORT_VALIDATION.failure(errors));
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ListGroupItem } from 'react-bootstrap';
import Highlight from 'react-highlight'

import MappingForm from './MappingForm';

export default class MappingListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(item) {
    const { onChange } = this.props;
    this.setState({ edit: false });
    onChange(item);
  }

  render() {
    const { edit } = this.state;
    const { item, onRemove, method, editable } = this.props;

    if (edit) return (
      <MappingForm
        onSubmit={this.onSubmit}
        onCancel={() => this.setState({ edit: false })}
        method={method}
        mapping={item}
      />
    );

    return (
      <ListGroupItem>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <div><strong>Pfad</strong></div>
            <div>{item.key}</div>
          </div>

          <div>
            <div><strong>Operation</strong></div>
            <div>{item.operation}</div>
          </div>

          <div style={{ width: '70%' }}>
            <div><strong>JMES Path Instruktion</strong></div>
            <Highlight className="js">
              {item.value}
            </Highlight>
          </div>

          {(editable) ? (
            <div>
              <span>
                <i className="glyphicon glyphicon-pencil" onClick={() => this.setState({ edit: true })}/>
              </span>
              <span onClick={onRemove}>
                <i className="glyphicon glyphicon-remove" />
              </span>
            </div>
          ) : null}
        </div>
      </ListGroupItem>
    )
  }
}

MappingListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  method: PropTypes.string,
  editable: PropTypes.bool,
  onChange: PropTypes.func
};

MappingListItem.defaultProps = {
  onRemove: _.noop,
  method: undefined,
  editable: false,
  onChange: _.noop
};

import _ from 'lodash';

import {
  GET,
  GET_CLASS,
  GET_SEGMENTS,
  GET_KNOWLEDGE_EXPERIENCES,
  GET_ALLOC,
  GET_STRATEGY,
  GET_SUBTYPE,
  GET_DOCUMENT,
  PUT,
  SET,
} from '../actions/InvestmentProductActions';

const initialState = ({
  products: [],
  segments: [],
  knowledgeExperiences: [],
  classes: [],
  strategies: [],
  serviceSubTypes: [],
  allocations: [],
  investmentDocuments: [],
  initialInvestmentDocuments: [],
  requesting: false,
  error: {},
  hasError: false,
  removedItems: [],
  productsToDelete: [],
  productsToUpdate: [],
});
export default function InvestmentProductReducer(state = initialState, action) {
  switch (action.type) {
    case SET: {
      return { ...state, [action.key]: action.value };
    }
    case `${GET}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${GET}_FULFILLED`: {
      return { ...state, products: _.sortBy(action.payload.data, o => o.order) };
    }
    case `${GET_CLASS}_FULFILLED`: {
      return { ...state, classes: _.sortBy(action.payload.data, o => o.order) };
    }
    case `${GET_ALLOC}_FULFILLED`: {
      return { ...state, allocations: _.sortBy(action.payload.data, o => o.order) };
    }
    case `${GET_SEGMENTS}_FULFILLED`: {
      return { ...state, segments: _.sortBy(action.payload.data, o => o.order) };
    }
    case `${GET_KNOWLEDGE_EXPERIENCES}_FULFILLED`: {
      return { ...state, knowledgeExperiences: _.sortBy(action.payload.data, o => o.order) };
    }
    case `${GET_STRATEGY}_FULFILLED`: {
      return { ...state, strategies: _.sortBy(action.payload.data, o => o.sort) };
    }
    case `${GET_SUBTYPE}_FULFILLED`: {
      return { ...state, serviceSubTypes: _.sortBy(action.payload.data, o => o.sort) };
    }
    case `${GET_DOCUMENT}_FULFILLED`: {
      return { ...state, investmentDocuments: action.payload, initialInvestmentDocuments: action.payload };
    }
    case `${GET}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case `${PUT}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${PUT}_FULFILLED`: {
      return {
        ...state,
        requesting: false,
        productsToDelete: [],
        productsToUpdate: [],
        initialInvestmentDocuments: state.investmentDocuments,
      };
    }
    case `${PUT}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

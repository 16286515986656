import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { v4 } from 'uuid';
import { defaultsDeep } from 'lodash';
import { get } from 'lodash';

import codeMappingActions from '../../actions/CodeMappingActions';


const {
  CODE_MAPPING_FORM_SAVE_PENDING,
  CODE_MAPPING_FORM_SAVE_FULFILLED,
  CODE_MAPPING_FORM_SAVE_REJECTED,
  CODE_MAPPING_SAVE_FULFILLED
} = codeMappingActions;

export default function* saveForm(request) {
  const { data } = request;

  yield put({ type: CODE_MAPPING_FORM_SAVE_PENDING });

  try {
    const defaults = {
      id: v4()
    };

    const payload = defaultsDeep(data, defaults);

    const url = `/entity/codemapping/${get(payload, 'id')}`;
    yield call(axios, {
      url,
      method: 'put',
      data: payload
    });

    yield put({ type: CODE_MAPPING_FORM_SAVE_FULFILLED, payload });
    yield put({ type: CODE_MAPPING_SAVE_FULFILLED, id: get(payload, 'id'), payload });

    return payload;
  } catch (error) {
    yield put({ type: CODE_MAPPING_FORM_SAVE_REJECTED, error });

    return error;
  }
}

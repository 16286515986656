import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { createSelector } from 'reselect';
import { getLanguage } from '../selectors/commonSelectors';
import {
  getBasicServices,
  getAdditions,
  getCategories,
  getChangeTypes,
  getCoreTypes,
  getDetailGroups,
  getDisplayClasses,
  getDocuments,
  getOutputClasses,
  getProducts,
} from '../selectors/BasicProducts';
import EditContainer from '../components/EditContainer';
import { BasicProductsList } from '../components/BasicProducts/BasicProductsList';
import { isBasicProductValid } from '../components/BasicProducts/BasicProductValidator';
import {
  LOAD_BASIC_PRODUCTS_ACTIONS,
  SAVE_BASIC_PRODUCTS_ACTIONS,
  SORT_BASIC_PRODUCTS_ACTIONS,
  CREATE_BASIC_PRODUCTS_ACTIONS,
  UPDATE_BASIC_PRODUCTS_ACTIONS,
  REMOVE_BASIC_PRODUCTS_ACTIONS,
} from '../actions/BasicProductsActions';
import { handleGravitonError } from '../utils';

class BasicProducts extends React.Component {
  constructor(props) {
    super(props);
    props.load();
  }

  render() {
    const { loading, error, save, products } = this.props;

    if (error) {
      toast.error(handleGravitonError(error));
    }

    const isValid = products.every(product => isBasicProductValid(product));
    return (
      <EditContainer
        requesting={loading}
        saveData={save}
        valid={isValid}
        title="BDL-Produkte"
        body={this.renderProductList()}
      />
    );
  }

  renderProductList() {
    const {
      language,
      products,
      categories,
      additions,
      documents,
      displayClasses,
      detailGroups,
      changeTypes,
      outputClasses,
      coreTypes,

      sort,
      createProduct,
      saveProduct,
      removeProduct,
    } = this.props;

    return (
      <BasicProductsList
        language={language}
        products={products}
        categories={categories}
        additions={additions}
        documents={documents}
        displayClasses={displayClasses}
        detailGroups={detailGroups}
        changeTypes={changeTypes}
        outputClasses={outputClasses}
        coreTypes={coreTypes}

        createProduct={createProduct}
        saveProduct={saveProduct}
        removeProduct={removeProduct}

        onSortEnd={sort}
        useDragHandle
        useWindowAsScrollContainer
        helperClass="SortableHOCHelper"
      />
    );
  }
}



export const getLoading = createSelector([
  getBasicServices,
], (basicServices) => basicServices.loading);

export const getError = createSelector([
  getBasicServices,
], (basicServices) => basicServices.error);

const mapStateToProps = createSelector([
  getLanguage,
  getLoading,
  getError,
  getProducts,
  getCategories,
  getAdditions,
  getDocuments,
  getDisplayClasses,
  getDetailGroups,
  getChangeTypes,
  getOutputClasses,
  getCoreTypes,
], (
  language,
  loading,
  error,
  products,
  categories,
  additions,
  documents,
  displayClasses,
  detailGroups,
  changeTypes,
  outputClasses,
  coreTypes,
) => ({
  language,
  loading,
  error,
  products,
  categories,
  additions,
  documents,
  displayClasses,
  detailGroups,
  changeTypes,
  outputClasses,
  coreTypes,
}));


const mapDispatchToProps = {
  load: () => LOAD_BASIC_PRODUCTS_ACTIONS.request(),
  save: () => SAVE_BASIC_PRODUCTS_ACTIONS.request(),
  sort: ({oldIndex, newIndex}) => SORT_BASIC_PRODUCTS_ACTIONS.request({oldIndex, newIndex}),
  createProduct: () => CREATE_BASIC_PRODUCTS_ACTIONS.request(),
  saveProduct: (product) => UPDATE_BASIC_PRODUCTS_ACTIONS.request({product}),
  removeProduct: (product) => REMOVE_BASIC_PRODUCTS_ACTIONS.request({product}),
};
export default connect(mapStateToProps, mapDispatchToProps)(BasicProducts);

import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {
  REDUCER_STATUS_PENDING, REDUCER_STATUS_PROGRESS,
} from '../../constants/ReducerStatuses';


export class Progress extends React.Component {

  render() {
    const { progressResult } = this.props;
    const { status, results} = progressResult;
    if (!progressResult
      || !progressResult.status
      || status !== REDUCER_STATUS_PROGRESS)
    {
      return null;
    }

    let resultNow = 0;
    let progressPercent = 0;
    if (status === REDUCER_STATUS_PROGRESS) {
      resultNow = results.success + results.fail;
      progressPercent = Math.round(resultNow / results.total * 100);
    }

    return (
      <div>
        {status === REDUCER_STATUS_PROGRESS &&
        <div>

          {results.action === 'FetchingLeads' && (
          <div>
            <FormattedMessage
              id="leadsSwitchImport_FetchingLeads_rows"
              values={{
                success: results.success,
                total: results.total,
              }}
            />
            {results.fail > 0 &&
            <span> (<FormattedMessage id={`leadsSwitchImport_status_fail`} /> {results.fail})</span>
            }
            <br />
            <FormattedMessage
              id="leadsSwitchImport_FetchingLeads_leads"
              values={{
                fetchedLeadsCount: results.fetchedLeadsCount,
              }}
            />
          </div>
          )}

          {results.action === 'PatchingLeads' && (
          <div>
            <FormattedMessage
              id="leadsSwitchImport_PatchingLeads_leads"
              values={{
                success: results.success,
                total: results.total,
              }}
            />
            {results.fail > 0 &&
            <span> (<FormattedMessage id={`leadsSwitchImport_status_fail`} /> {results.fail})</span>
            }
          </div>
          )}

        </div>
        }
        {(status === REDUCER_STATUS_PENDING || status === REDUCER_STATUS_PROGRESS) &&
        <ProgressBar
          now={progressPercent}
          label={`${progressPercent}%`}
        />
        }
      </div>
    );
  }
}

Progress.propTypes = {
  progressResult: PropTypes.object,
};

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import * as forms from './Params/index';

class ParamsForm extends React.Component {
  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { 
      target,
      language,
      selected,
      action,
      codes,
      consultants,
      productGroups,
      onChange,
      value,
      edit
    } = this.props;

    const Component = _.get(forms, action);
    if (!Component) return null;

    return(
      <Component
        target={target}
        language={language}
        selected={selected}
        codes={codes}
        consultans={consultants}
        productGroups={productGroups}
        onChange={onChange}
        value={value}
        edit={edit}
      />
    );
  }
}

ParamsForm.propTypes = {
  target: PropTypes.object.isRequired,
  language: PropTypes.string,
  action: PropTypes.string,
  codes: PropTypes.object,
  consultants: PropTypes.array,
  productGroups: PropTypes.array,
  selected: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.array,
  path: PropTypes.string,
  edit: PropTypes.bool
};

ParamsForm.defaultProps = {
  language: 'de',
  action: undefined,
  codes: {},
  consultants: [],
  productGroups: [],
  selected: [],
  onChange: _.noop,
  value: [],
  path: undefined,
  edit: false
};

export default ParamsForm;

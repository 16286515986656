import React, { Component } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { InputGroup, FormControl, Modal, Button } from 'react-bootstrap';
import _, { has, get, omit } from 'lodash';

import DragHandle from '../DragHandle';
import './DetailComponent.css';

import ComponentDetails from './DetailComponentDetails';

class Item extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
    };

    this.onDuplicate = this.onDuplicate.bind(this);
  }

  onDuplicate() {
    const {
      duplicateComponent,
      item
    } = this.props;

    duplicateComponent(get(item, 'props.id'));
  }

  modalOpen() {
    this.setState({
      modalOpen: true,
    });
  }

  modalClose() {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    const {
      addComponent,
      codeGroups,
      codes,
      consultants,
      delComponent,
      editValueField,
      fetchCodes,
      id,
      item,
      items,
      language,
      productGroups,
      ...rest
    } = this.props;

    return (
      <div className="SortableHOCItem">
        <InputGroup className="full-width">
          <InputGroup.Addon>
            <DragHandle />
          </InputGroup.Addon>
          <FormControl
            type="text"
            name={`${id}.formFieldTitle`}
            value={_.get(item, `props.title[${language}]`)}
            onChange={editValueField.bind(this)}
          />
          <InputGroup.Addon style={{ cursor: 'pointer', zIndex: '9999' }}>
            <span style={{ marginRight: '10px' }}>
              <i className="icon-213-setting" onClick={this.modalOpen.bind(this)}>
                <span className="hidden">settings</span>
              </i>
            </span>

            <span style={{ marginRight: '10px' }}>
              <i className="mdi mdi-content-duplicate" onClick={this.onDuplicate.bind(this)}>
                <span className="hidden">duplicate</span>
              </i>
            </span>

            <span onClick={delComponent.bind(this, id)}>
              <i className="icon-008-bin"><span className="hidden">remove</span></i>
            </span>
          </InputGroup.Addon>
        </InputGroup>
        <Modal show={this.state.modalOpen} onHide={this.modalClose.bind(this)} bsSize="large" dialogClassName="workguide-component-configuration-modal">
          <Modal.Header closeButton>
            <Modal.Title>
              Detaileinstellungen für
              {_.get(item, `props.title[${language}]`)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ComponentDetails
              {...rest}
              addComponent={addComponent}
              codeGroups={codeGroups}
              codes={codes}
              consultants={consultants}
              delComponent={delComponent}
              editValueField={editValueField}
              fetchCodes={fetchCodes}
              id={id}
              item={item}
              items={items}
              language={language}
              productGroups={productGroups}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.modalClose.bind(this)}>
              <span className="icon-202-clear-circle" />
              Schliessen
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

const SortableItem = SortableElement(Item);

export default class MyElement extends Component {
  renderItem() {
    const {
      addComponent,
      id,
      item,
      items,
      type,
      language,
      codeGroups,
      delComponent,
      duplicateComponent,
      editValueField,
      codes,
      fetchCodes,
      consultants,
      productGroups,
      ...rest
    } = this.props;

    return (
      <SortableItem
        {...rest}
        addComponent={addComponent}
        codeGroups={codeGroups}
        codes={codes}
        consultants={consultants}
        delComponent={delComponent}
        duplicateComponent={duplicateComponent}
        editValueField={editValueField}
        fetchCodes={fetchCodes}
        id={id}
        index={id}
        item={item}
        items={items}
        language={language}
        productGroups={productGroups}
        type={type}
      />
    );
  }

  render() {
    const { fieldGroup, item } = this.props;

    return fieldGroup
      ? fieldGroup === get(item, 'fieldGroup')
        ? this.renderItem()
        : null
      : has(item, 'fieldGroup')
        ? null
        : this.renderItem();
  }
}

import jmespath from '@metrichor/jmespath-plus';
import { some } from 'lodash';

import { convertValue, convertValues } from './convert';

export default function jmesConditionEq(config, data) {
  const { path, type, value = '' } = config;

  const values = convertValues(value.split(','), type);
  const resolved = jmespath.search(data, path);
  const converted = convertValue(resolved, type);

  return type === 'date'
    ? some(values, (value) => value.isSame(converted))
    : some(values, (value) => value === converted);
}

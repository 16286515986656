import { select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { createRef } from '../../utils';
import { getSetManagementProductsRaw } from '../../selectors/SetManagement/setManagementCommonSelectors';
import { getLanguage } from '../../selectors/commonSelectors';

export function* setManagementChangeProducts(products) {
  const productsRaw = yield select(getSetManagementProductsRaw);
  if (!products) {
    return productsRaw;
  }

  const locale = yield select(getLanguage);
  return productsRaw.map((productRaw) => {
    const product = products.find((it) => it.id === productRaw.id);
    if (!product) {
      return productRaw;
    }

    return {
      ...productRaw,
      order: product.order,
      domain: product.domain
        ? product.domain.map((domain) => ({ $ref: createRef(EndpointName.ENTITY_CODE, domain) }))
        : [],
      bundle: product.bundle
        ? product.bundle.map((bundle) => ({ $ref: createRef(EndpointName.BASIC_PRODUCT_BUNDLE_BUNDLE, bundle) }))
        : [],
      name: product.name === undefined
        ? productRaw.name
        : {
          ...productRaw.name,
          [locale]: product.name,
        },
      shortName: product.shortName === undefined
        ? productRaw.shortName
        : {
          ...productRaw.shortName,
          [locale]: product.shortName,
        },
      unitPrice: product.unitPrice
        ? product.unitPrice
        : undefined,
    };
  });
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, size, noop } from 'lodash';
import { BeatLoader } from 'react-spinners';

import Service from './ServiceCall/Service';
import Method from './ServiceCall/Method';
import Properties from './ServiceCall/Properties';
import EventPolling from './ServiceCall/EventPolling';
import Mapping from './ServiceCall/Mapping';
import { actions as gravitonActions } from '../../../../Graviton/index';

class ActionConfigurationServiceCall extends React.Component {
  constructor(props) {
    super(props);

    const {
      gravitonActions,
      services
    } = props;

    if (size(services.data) === 0) {
      gravitonActions.servicesRequest();
    }
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      action,
      services,
      schema,
      onActionChange,
      onJmesParamChange,
      onValueChange,
      validations
    } = this.props;
    const url = get(action, 'url');

    if (!get(services, 'fulfilled', false)) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <BeatLoader />
        </div>
      );
    }

    return (
      <div className="workguide-action-configuration-service-call">
        <Service
          action={action}
          onActionChange={onActionChange}
          onChange={onValueChange}
          onJmesParamChange={onJmesParamChange}
          services={services}
          validations={validations}
        />

        <Method
          action={action}
          onChange={onValueChange}
          schema={get(schema, url)}
          validations={validations}
        />

        <EventPolling
          action={action}
          onChange={onValueChange}
          validations={validations}
        />

        <Properties
          action={action}
          onJmesParamChange={onJmesParamChange}
          schema={get(schema, url)}
          validations={validations}
        />

        <Mapping
          action={action}
          onJmesParamChange={onJmesParamChange}
          validations={validations}
        />
      </div>
    );
  }
}

ActionConfigurationServiceCall.propTypes = {
  action: PropTypes.object.isRequired,
  gravitonActions: PropTypes.object.isRequired,
  onActionChange: PropTypes.func,
  onJmesParamChange: PropTypes.func,
  onValueChange: PropTypes.func,
  services: PropTypes.object,
  schema: PropTypes.object,
  validations: PropTypes.object
};

ActionConfigurationServiceCall.defaultProps = {
  onActionChange: noop,
  onJmesParamChange: noop,
  onValueChange: noop,
  services: {},
  schema: {},
  validations: {}
};

function mapStateToProps(state) {
  return {
    services: get(state, 'graviton.services'),
    schema: get(state, 'graviton.schema')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    gravitonActions: bindActionCreators(gravitonActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionConfigurationServiceCall);

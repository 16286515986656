import axios from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ANALYTICS_LOGS_SEARCH } from '../../actions/AnalyticsLogsActions';
import { getAnalyticsLogsDivision } from '../../selectors/AnalyticsLogs/analyticsLogsDivisionSelector';
import { getAnalyticsLogsDepartments } from '../../selectors/AnalyticsLogs/analyticsLogsDepartmetSelector';
import { EndpointName } from '../../constants/EndpointName';
import { convertDateToApi } from '../../utils/dateConverter';

export function* analyticsLogsSearchSaga(action) {
  try {
    yield put(ANALYTICS_LOGS_SEARCH.pending());

    const {
      dates: {
        startDate,
        endDate,
      } = {},
      users = [],
      methods = [],
      divisions = [],
      departments = [],
      endpoint,
      requestParams,
      responseCode,
    } = action.data;
    const groupedDivisions = yield select(getAnalyticsLogsDivision);
    const groupedDepartments = yield select(getAnalyticsLogsDepartments);

    const dateFrom = startDate
      ? startDate.startOf('date')
      : undefined;
    const dateTo = endDate
      ? endDate.endOf('date')
      : undefined;

    let uniqUserNamesQuery;
    if (typeof users === 'object') {
      uniqUserNamesQuery = [...new Set(users
        .concat(
          groupedDivisions.reduce((result, it) =>
              divisions.indexOf(it.division) === -1
                ? result
                : result.concat(it.users),
            []),
        )
        .concat(
          groupedDepartments.reduce((result, it) =>
              departments.indexOf(it.department) === -1
                ? result
                : result.concat(it.users),
            []),
        ),
      )]
        .map((username) => username.toUpperCase())
        .join(',');
    }
    if (typeof users === 'string') {
      uniqUserNamesQuery = users
        .replace(/\s/g, "")
        .toUpperCase();
    }
    

    const requestUri = endpoint && requestParams
      ? `${endpoint}${requestParams}`
      : requestParams
        ? `*${requestParams}`
        : endpoint;

    const query = [
      dateFrom
        ? `fromDate=${encodeURIComponent(convertDateToApi(dateFrom))}`
        : undefined,
      dateTo
        ? `toDate=${encodeURIComponent(convertDateToApi(dateTo))}`
        : undefined,
      uniqUserNamesQuery
        ? `username=${encodeURIComponent(uniqUserNamesQuery)}`
        : undefined,
      methods.length
        ? `method=${encodeURIComponent(methods.join(','))}`
        : undefined,
      requestUri
        ? `requestUri=${encodeURIComponent(requestUri)}`
        : undefined,
      responseCode
        ? `responseCode=${encodeURIComponent(responseCode)}`
        : undefined,
      'showResponseBody=1&limit=1000',
    ].filter(Boolean).join('&');

    const { data: searchResults } = yield call(axios, { url: `${EndpointName.ANALYTICS_REQUESTS}?${query}`, timeout: 60 * 10 * 1000 });

    yield put(ANALYTICS_LOGS_SEARCH.success({ searchResults, startDate: dateFrom, endDate: dateTo }));
  } catch (error) {
    yield put(ANALYTICS_LOGS_SEARCH.failure(error));
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop } from 'lodash';
import { Modal } from 'react-bootstrap';

import './EditNode.css';
import EditConnectionForm from '../Form/EditConnection/Form';
import workguideActions from '../../../../actions/Actions';

class WorkflowTreeModalEditConnetion extends React.Component {
  constructor(props) {
    super(props);

    const { connection, workguideActions } = props;
    workguideActions.workflowTreeEditConnectionFormInitRequest({ data: connection });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      connection,
      consultants,
      isOpen,
      language,
      onEditFormSubmit,
      onHide
    } = this.props;

    return (
      <Modal
        bsSize="lg"
        className="workflow-tree-modal-edit-node"
        show={isOpen}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          {`Edit ${get(connection, 'id')}`}
        </Modal.Header>

        <Modal.Body>
          <EditConnectionForm
            connection={connection}
            consultants={consultants}
            language={language}
            onCancel={onHide}
            onSubmit={onEditFormSubmit}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

WorkflowTreeModalEditConnetion.propTypes = {
  connection: PropTypes.object.isRequired,
  consultants: PropTypes.array,
  isOpen: PropTypes.bool,
  language: PropTypes.string,
  onEditFormSubmit: PropTypes.func,
  onHide: PropTypes.func,
  workguideActions: PropTypes.object.isRequired
};

WorkflowTreeModalEditConnetion.defaultProps = {
  consultants: [],
  isOpen: false,
  language: 'de',
  onEditFormSubmit: noop,
  onHide: noop
};

function mapDispatchToProps(dispatch) {
  return {
    workguideActions: bindActionCreators(workguideActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(
  WorkflowTreeModalEditConnetion
);

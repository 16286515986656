import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { REDUCER_STATUS_PENDING, REDUCER_STATUS_PROGRESS } from '../../constants/ReducerStatuses';
import { TableCell, TableRow } from '../Common/Table';
import Spinner from '../Spinner'
import { NoticeImportProgress } from './NoticeImportProgress';
import './NoticeImportFileRow.css';

const DATE_FORMAT = 'DD.MM.YYYY hh:mm';

export class NoticeImportFileRow extends React.Component {

  constructor(props) {
    super(props);

    this.displayStatus = this.displayStatus.bind(this);
    this.displayProgress = this.displayProgress.bind(this);
    this.displayDeleteButton = this.displayDeleteButton.bind(this);
  }

  displayStatus() {
    const { importResult, deleteResult } = this.props;
    return (
      (!importResult || [REDUCER_STATUS_PENDING, REDUCER_STATUS_PROGRESS].indexOf(importResult.status) === -1)
      && (!deleteResult || [REDUCER_STATUS_PENDING, REDUCER_STATUS_PROGRESS].indexOf(deleteResult.status) === -1)
    );
  }

  displayProgress() {
    const { importResult, deleteResult } = this.props;
    return (
      (importResult && [REDUCER_STATUS_PENDING, REDUCER_STATUS_PROGRESS].indexOf(importResult.status) !== -1)
      ||
      (deleteResult && [REDUCER_STATUS_PENDING, REDUCER_STATUS_PROGRESS].indexOf(deleteResult.status) !== -1)
    );
  }

  displayDeleteButton() {
    const { importResultAll, deleteResultAll } = this.props;
    return  (
      (!deleteResultAll || (
        deleteResultAll.status !== REDUCER_STATUS_PENDING
        && deleteResultAll.status !== REDUCER_STATUS_PROGRESS
      ))
      &&
      (!importResultAll || (
        importResultAll.status !== REDUCER_STATUS_PENDING
        && importResultAll.status !== REDUCER_STATUS_PROGRESS
      ))
    );
  }

  render() {
    const {
      file,
      onDelete,
      importResult,
      deleteResult,
    } = this.props;

    return (
      <TableRow className="notice-import-file-row">
        <TableCell>
          {moment(file.metadata.createDate).format(DATE_FORMAT)}<br />
        </TableCell>
        <TableCell>
          {file.username}
        </TableCell>
        <TableCell>
          {file.statusText}
        </TableCell>
        <TableCell>
          <NoticeImportProgress progressResult={importResult} />
          <NoticeImportProgress progressResult={deleteResult} />
          {this.displayStatus() &&
          <div>
            { file.imported }<br />{moment(file.metadata.modificationDate).format(DATE_FORMAT)}
          </div>
          }
        </TableCell>
        <TableCell>
          {file.metadata.filename}
        </TableCell>
        <TableCell>
          {this.displayProgress() &&
          <div><Spinner text={false} screen={false} /></div>
          }
          {this.displayDeleteButton() &&
          <Button
            className="notice-import-file-row__import"
            onClick={() => onDelete(file.id)}
          >
            <FormattedMessage id="noticeImport_button_delete" />
          </Button>
          }
        </TableCell>
      </TableRow>
    );
  }
}

NoticeImportFileRow.propTypes = {
  file: PropTypes.object,
  onDelete: PropTypes.func,
  importResult: PropTypes.object,
  importResultAll: PropTypes.object,
  deleteResult: PropTypes.object,
  deleteResultAll: PropTypes.object,
};

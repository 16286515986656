import React from 'react';
import PropTypes from 'prop-types';

export function BulkLoadsResultsTime({ seconds: totalSeconds, placeholder = '-' }) {
  if (totalSeconds === undefined) {
    return <React.Fragment>{placeholder}</React.Fragment>;
  }

  if (totalSeconds < 0) {
    return <React.Fragment>--:--:--</React.Fragment>;
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = (totalSeconds % 3600) % 60;

  return (
    <React.Fragment>
      {to2Digit(hours)}:{to2Digit(minutes)}:{to2Digit(seconds)}
    </React.Fragment>
  );
}

BulkLoadsResultsTime.propTypes = {
  seconds: PropTypes.number,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

function to2Digit(value) {
  return value < 10 ? `0${value}` : value;
}

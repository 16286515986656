import { createSelector } from 'reselect';
import { getFilter, getApiPromotions } from './promotionSelectors';

export const getTotalCount = createSelector([
  getApiPromotions,
  getFilter,
], (
  promotions,
  filter,
) => promotions.filter((promotion) => {
  if (filter.show === 'active') {
    return promotion.active;
  } else if (filter.show === 'inactive') {
    return !promotion.active;
  } else {
    return true;
  }
}).length);

import './RefErrorsExpandContent.css';

import React from 'react';
import PropTypes from 'prop-types';
import { classList } from '../../utils/classList';
import { ExpandContent } from '../Common/ExpandContent';

export class RefErrorsExpandContent extends React.PureComponent {
  state = {
    expanded: true,
  };

  render() {
    const { expanded } = this.state;
    const { title = '', children } = this.props;
    const iconClasses = classList({
      'icon-013-chevron-down': true,
      'ref-errors-expand-content__icon': true,
      'ref-errors-expand-content__icon--collapsed': !expanded,
    });

    return (
      <div className="ref-errors-expand-content">
        <div className="ref-errors-expand-content__header" onClick={this.toggleExpanded}>
          <div className="ref-errors-expand-content__title">{title}</div>
          <div className={iconClasses}/>
        </div>
        <ExpandContent expanded={expanded} className="ref-errors-expand-content__body">
          {children}
        </ExpandContent>
      </div>
    );
  }

  toggleExpanded = () => this.setState((prev) => ({ expanded: !prev.expanded }));
}

RefErrorsExpandContent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

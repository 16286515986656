import axios from 'axios';
import { some, isEmpty, isUndefined } from 'lodash';
import { all, call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { UI_NOTES_FETCH_ACTIONS } from '../../actions/UiNotesActions';

export function* uiNotesFetchSaga({data}) {
  try {
    yield put(UI_NOTES_FETCH_ACTIONS.pending());
    var query ='';
    if(!isUndefined(data) && !some(data.sortField, isEmpty)) {
      query = `sort(${data.sortType === "desc" ? '-' : '+'}${data.sortField})&`;

    }
    
if(!isUndefined(data)){

}

    const [
      { data: notes },
      { data: types },
    ] = yield all([
      call(axios, { url: `${EndpointName.WORK_UI_NOTES}/?${query}limit(9999)` }),
      call(axios, { url: `${EndpointName.ENTITY_CODE}/?eq(group,userInterfaceNoteType)&limit(9999)` }),
      
    ]);
    

    yield put(UI_NOTES_FETCH_ACTIONS.success({ notes, types, images: [] }));
  } catch (error) {
    yield put(UI_NOTES_FETCH_ACTIONS.failure(error));
  }
}

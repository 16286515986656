import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, reduce, orderBy, size } from 'lodash';
import { TableVirtuoso } from 'react-virtuoso';
import { BeatLoader } from 'react-spinners';
import update from 'immutability-helper';

import './Table.css';
import HeaderRow from '../../components/Table/HeaderRow';
import BodyRow from '../../components/Table/BodyRow';
import leadMassMutationActions from '../../actions/Actions';

/**
 * Sort leads by sort order
 *
 * @param   {Array}   leads      Leads array
 * @param   {Object}  sortOrder  Sort order
 *
 * @return  {Array} sorted Sorted leads
 */
function sortLeads({ leads = [], sortOrder = {} }) {
  const { paths = [], directions = [] } = reduce(sortOrder, (result, order) => {
    const { path, direction } = order;
    const paths = [...get(result, 'paths', []), path];
    const directions = [...get(result, 'directions', []), direction];

    return { paths, directions };
  }, { paths: [], directions: [] });

  return orderBy(leads, paths, directions);
}

/**
 * Render the beat loaded
 *
 * @return {ReactElement} markup
 */
function renderLoader() {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
      <BeatLoader />
    </div>
  );
}

class LeadMassMutationTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortOrder: {}
    };

    this.onLeadSelect = this.onLeadSelect.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
  }

  /**
   * Handle sort change
   *
   * @param   {Object}  updated  Updated order object
   *
   * @return void
   */
  onSortChange(updated) {
    this.setState({ sortOrder: updated });
  }

  /**
   * Select / deselect the given lead
   *
   * @param   {Object}  lead  Lead
   *
   * @return  void
   */
  onLeadSelect({ lead }) {
    const {
      leadMassMutationActions,
      selectedLeads
    } = this.props;

    const index = get(selectedLeads, 'data', []).findIndex((id) => lead.id === id);
    const payload = index > -1
      ? update(get(selectedLeads, 'data', []), { $splice: [[index, 1]] })
      : update(get(selectedLeads, 'data', []), { $push: [lead.id] });

    leadMassMutationActions.selectedLeadsFulfilled({ payload });
  }

  /**
   * Select / deselect all leads
   *
   * @return  {[type]}  [return description]
   */
  onSelectAll() {
    const {
      leadMassMutationActions,
      leads
    } = this.props;

    const payload = this.allLeadsSelected()
      ? []
      : get(leads, 'data', []).map((l) => l.id);

    leadMassMutationActions.selectedLeadsFulfilled({ payload });
  }

  /**
   * Check if all leads are selected
   *
   * @return  {Boolean}
   */
  allLeadsSelected() {
    const {
      leads,
      selectedLeads
    } = this.props;

    const leadCount = size(leads.data);
    const selectedCount = size(selectedLeads.data);

    return (
      leadCount > 0
      && leadCount === selectedCount
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { sortOrder } = this.state;
    const {
      consultants,
      language,
      leads,
      requesting,
      selectedLeads,
      updateLead
    } = this.props;

    if (requesting) {
      return renderLoader();
    }

    const data = sortLeads({ leads: get(leads, 'data', []), sortOrder });
    const height = get(data, 'length', 0) > 0
      ? 800
      : 50;

    return (
      <div className="lead-mass-mutation--table">
        <TableVirtuoso
          style={{ height, width: '100%' }}
          data={data}
          // This is how virtuoso works...
          // eslint-disable-next-line
          fixedHeaderContent={() => (
            <HeaderRow
              allLeadsSelected={this.allLeadsSelected()}
              consultants={consultants}
              language={language}
              onSelectAll={this.onSelectAll}
              onSortChange={this.onSortChange}
              sortOrder={sortOrder}
            />
          )}
          // Same here...
          // eslint-disable-next-line
          itemContent={(index, lead) => {
            const update = get(updateLead, lead.id);

            return (
              <BodyRow
                consultants={consultants}
                isSelected={get(selectedLeads, 'data', []).includes(lead.id)}
                language={language}
                lead={lead}
                onSelect={this.onLeadSelect}
                update={update}
              />
            );
          }}
        />
      </div>
    );
  }
}

LeadMassMutationTable.propTypes = {
  consultants: PropTypes.object,
  leadMassMutationActions: PropTypes.object.isRequired,
  language: PropTypes.string,
  leads: PropTypes.object,
  requesting: PropTypes.bool,
  selectedLeads: PropTypes.object,
  updateLead: PropTypes.object
};

LeadMassMutationTable.defaultProps = {
  consultants: {},
  language: 'de',
  leads: {},
  requesting: false,
  selectedLeads: {},
  updateLead: {}
};

function mapStateToProps(state, ownProps) {
  return {
    consultants: state.leadMassMutation.consultants,
    language: state.login.language,
    leads: state.leadMassMutation.leads,
    requesting: state.leadMassMutation.leads.requesting,
    selectedLeads: state.leadMassMutation.selectedLeads,
    updateLead: state.leadMassMutation.updateLead
  };
}

function mapDispatchToProps(dispatch) {
  return {
    leadMassMutationActions: bindActionCreators(leadMassMutationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadMassMutationTable);

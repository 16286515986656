import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { TextInput, Toggle, NumberInput, ValidationResult } from '@evoja-web/react-form';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const ActionConfigurationRuediNotification = React.memo(function ActionConfigurationRuediNotification({
  action,
  onJmesParamChange,
  validations
}) {
  return (
    <div className="workguide-action-configuration-ruedi-notification">
      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.Action.Configuration.RuediNotification.ContentDe" />
        </ControlLabel>

        <TextInput
          id="contentDe"
          onChange={onJmesParamChange}
          value={get(action, 'jmesParams.contentDe')}
        />

        <ValidationResult
          show
          validations={get(validations, 'contentDe')}
        />
      </FormGroup>

      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.Action.Configuration.RuediNotification.ContentFr" />
        </ControlLabel>

        <TextInput
          id="contentFr"
          onChange={onJmesParamChange}
          value={get(action, 'jmesParams.contentFr')}
        />

        <ValidationResult
          show
          validations={get(validations, 'contentFr')}
        />
      </FormGroup>

      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.Action.Configuration.RuediNotification.SingleDelivery" />
        </ControlLabel>

        <Toggle
          id="singleDelivery"
          onChange={onJmesParamChange}
          value={get(action, 'jmesParams.singleDelivery')}
        />

        <ValidationResult
          show
          validations={get(validations, 'singleDelivery')}
        />
      </FormGroup>

      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.Action.Configuration.RuediNotification.NoPersisting" />
        </ControlLabel>

        <Toggle
          id="noPersisting"
          onChange={onJmesParamChange}
          value={get(action, 'jmesParams.noPersisting')}
        />

        <ValidationResult
          show
          validations={get(validations, 'noPersisting')}
        />
      </FormGroup>

      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.Action.Configuration.RuediNotification.Topic" />
        </ControlLabel>

        <TextInput
          id="topic"
          onChange={onJmesParamChange}
          value={get(action, 'jmesParams.topic')}
        />

        <ValidationResult
          show
          validations={get(validations, 'topic')}
        />
      </FormGroup>

      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.Action.Configuration.RuediNotification.Type" />
        </ControlLabel>

        <TextInput
          id="type"
          onChange={onJmesParamChange}
          value={get(action, 'jmesParams.type')}
        />

        <ValidationResult
          show
          validations={get(validations, 'type')}
        />
      </FormGroup>

      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.Action.Configuration.RuediNotification.TypeId" />
        </ControlLabel>

        <TextInput
          id="typeId"
          onChange={onJmesParamChange}
          value={get(action, 'jmesParams.typeId')}
        />

        <ValidationResult
          show
          validations={get(validations, 'typeId')}
        />
      </FormGroup>

      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.Action.Configuration.RuediNotification.TypeState" />
        </ControlLabel>

        <TextInput
          id="typeState"
          onChange={onJmesParamChange}
          value={get(action, 'jmesParams.typeState')}
        />

        <ValidationResult
          show
          validations={get(validations, 'typeState')}
        />
      </FormGroup>

      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.Action.Configuration.RuediNotification.TypeBasedDeliveryBlockedInSeconds" />
        </ControlLabel>

        <NumberInput
          id="typeBasedDeliveryBlockedInSeconds"
          onChange={onJmesParamChange}
          value={get(action, 'jmesParams.typeBasedDeliveryBlockedInSeconds')}
        />

        <ValidationResult
          show
          validations={get(validations, 'typeBasedDeliveryBlockedInSeconds')}
        />
      </FormGroup>
    </div>
  );
});

ActionConfigurationRuediNotification.propTypes = {
  action: PropTypes.object.isRequired,
  onJmesParamChange: PropTypes.func,
  validations: PropTypes.object
};

ActionConfigurationRuediNotification.defaultProps = {
  onJmesParamChange: noop,
  validations: {}
};

export default ActionConfigurationRuediNotification;

import './Banks.css';

import React from 'react';
import { Button } from '../Common/Button';
import Bank from './Bank';
import { SortableList } from '../Common/SortableList';

export function Banks({
  banks, language, onAdd, onRemove, onEdit, onSortEnd, onSort,
}) {
  const aBanks = banks.map((bank, index) => (
    <Bank
      item={bank}
      key={bank.id}
      index={index}
      language={language}
      onRemove={onRemove}
      onEdit={onEdit}
    />
  ));

  return (
    <div className="bank-list">
      <div className="bank-list__header">
        <div className="bank-list__text">Name</div>
        <div className="bank-list__text-short">Abkürzung</div>
      </div>
      <SortableList onSortEnd={onSortEnd}>
        {aBanks}
      </SortableList>
      <div className="bank-list__actions">
        <Button bsStyle="primary" name="sortItem" onClick={onSort}>
          Sortieren
        </Button>

        <Button bsStyle="primary" name="newItem" onClick={onAdd}>
          Neuer Eintrag
        </Button>
      </div>
    </div>
  );
}

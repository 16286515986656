import { call, put } from 'redux-saga/effects';
import { v4 } from 'uuid';

import editConnectionFormAction from '../../actions/Workflow/EditConnectionForm';

const {
  WORKFLOW_TREE_EDIT_CONNECTION_FORM_INIT_PENDING,
  WORKFLOW_TREE_EDIT_CONNECTION_FORM_INIT_FULFILLED,
  WORKFLOW_TREE_EDIT_CONNECTION_FORM_INIT_REJECTED
} = editConnectionFormAction;

export default function* initEditConnectionForm(request) {
  const { data } = request;

  yield put({ type: WORKFLOW_TREE_EDIT_CONNECTION_FORM_INIT_PENDING });

  try {
    const defaults = {
      id: v4()
    };

    const payload = {
      ...defaults,
      ...data
    };

    yield put({ type: WORKFLOW_TREE_EDIT_CONNECTION_FORM_INIT_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: WORKFLOW_TREE_EDIT_CONNECTION_FORM_INIT_REJECTED, error });

    return error;
  }
}

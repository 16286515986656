import { takeLatest } from 'redux-saga/effects';
import loaderTriggerActions from '../../actions/LoaderTriggerActions';
import loaderTriggerGetEventStatusActions from '../../actions/LoaderTriggerGetEventStatusActions';
import triggerLoader from './triggerLoader';
import getEventStatus from './getEventStatus';

const { LOADER_TRIGGER_REQUEST } = loaderTriggerActions;
const { GET_EVENT_STATUS_REQUEST } = loaderTriggerGetEventStatusActions;

export default function* loaderTriggerSagas() {
  yield takeLatest(LOADER_TRIGGER_REQUEST, triggerLoader);  
  yield takeLatest(GET_EVENT_STATUS_REQUEST, getEventStatus);  
}
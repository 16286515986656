import React from 'react';
import PropTypes from 'prop-types';
import { get, noop , debounce, isUndefined } from 'lodash';
import NumberFormat from 'react-number-format';
import cl from 'classnames';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import update from 'immutability-helper';

import './ComponentSize.css';

const Input = React.memo(({ size, value, onChange }) => {
  function isAllowed({ floatValue }) {
    return (
      (floatValue >= 0 && floatValue <= 12 && Number.isInteger(floatValue))
      || isUndefined(floatValue)
    );
  }
  return (
    <FormGroup>
      <ControlLabel style={{ paddingRight: '5px', textAlign: 'left' }}>
        {size}
      </ControlLabel>

      <NumberFormat
        value={value}
        onValueChange={(value) => onChange(size, get(value, 'floatValue'))}
        className="form-control"
        isAllowed={isAllowed}
      />
    </FormGroup>
  );
});

Input.propTypes = {
  size: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func
};

Input.defaultProps = {
  value: undefined,
  onChange: noop
};

const availableSizes = ['xs', 'sm', 'md', 'lg', 'xl'];

class ComponentSize extends React.Component {
  constructor(props) {
    super(props);

    const { component } = props;

    this.state = {
      collapsed: true,
      data: availableSizes.reduce((result, size) => {
        result[size] = get(component, `props.size.${size}`, 12);
        return result;
      }, {})
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onChange = debounce(this.onChange.bind(this), 500);
  }

  onChange(value) {
    const { id, editValueField } = this.props;
    editValueField(id, 'prop', { key: 'size', value });
  }

  onInputChange(size, value) {
    const { data } = this.state;

    const updated = isUndefined(value)
      ? update(data, { $unset: [size] })
      : update(data, { [size]: { $set: value } });

    this.setState({ data: updated });
    this.onChange(updated);
  }

  renderInputFields() {
    const { data } = this.state;

    const children = availableSizes.map((size) => {
      const value = get(data, size);

      return (
        <div className="workguide-component-size--item">
          <Input
            size={size}
            value={value}
            onChange={this.onInputChange}
          />
        </div>
      );
    });

    return children;
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { collapsed } = this.state;

    const className = cl({
      'workguide-component-size': true,
      'workguide-component-size--collapsed': collapsed
    });

    return (
      <div className={className}>
        {this.renderInputFields()}
      </div>
    );
  }
}

ComponentSize.propTypes = {
  editValueField: PropTypes.func
};

ComponentSize.defaultProps = {
  editValueField: noop
};

export default ComponentSize;

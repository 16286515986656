import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';

import { getNettedAccountsWorkguides, getNettedAccountsWorkguidesTotalCount } from '../../selectors/WorkguideStatus/workguideStatusCommonSelectors';
import { NETTED_ACCOUNTS_WORKGUIDES_LOAD } from '../../actions/WorkguideStatusActions';
import { Table, TableHead, TableCell, TableRow } from '../Common/Table';

const NettedAccountsTable = props => {
    useEffect(() => {
        const { load_netted_accounts_workguides } = props;
        load_netted_accounts_workguides();
    }, [])

    const { workguides } = props;

    return (
        <div>
            <h3><FormattedMessage id="workguideStatus_nettedAccountsWorkguides" />:</h3>
            <Table>
                <TableHead>
                    <TableCell>
                        ID
                    </TableCell>
                    <TableCell>
                        Account Name
                    </TableCell>
                </TableHead>

                {workguides.length > 0 && workguides.map((item, i) => {
                    return (
                        <TableRow key={i}>
                            <TableCell>
                                {get(item, '_id', '-')}
                            </TableCell>
                            <TableCell>
                                {get(item, 'accountName', '-')}
                            </TableCell>
                        </TableRow>)
                })}
            </Table>
        </div>
    );
};



NettedAccountsTable.defaultProps = {
    workguides: [],
}

NettedAccountsTable.propTypes = {
};

const mapStateToProps = createStructuredSelector({
    workguides: getNettedAccountsWorkguides,
    totalCount: getNettedAccountsWorkguidesTotalCount
});

const mapDispatchToProps = {
    load_netted_accounts_workguides: () => NETTED_ACCOUNTS_WORKGUIDES_LOAD.request()
};

export default connect(mapStateToProps, mapDispatchToProps)(NettedAccountsTable);
import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';
import { EndpointName } from '../../constants/EndpointName';
import { ACCOUNT_SEARCH_LOAD } from '../../actions/WorkguideStatusActions';

export function* workguideStatusSearchAccountSaga(action) {
  try {
    yield put(ACCOUNT_SEARCH_LOAD.pending());

    const numberFormatted = get(action, 'data.numberFormatted', '');
    const { data: accounts } = yield call(axios, { url: `${EndpointName.ACCOUNT}/?eq(numberFormatted,${numberFormatted})&limit(1)` });

    yield put(ACCOUNT_SEARCH_LOAD.success({ account: accounts[0] }));
  } catch (error) {
    yield put(ACCOUNT_SEARCH_LOAD.failure(error));
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';
import Select from 'react-select';
import { get, noop } from 'lodash';

class InputConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.inputTypes = [
      {value: "uuidv4", label: "UUID v4"}
    ];
  }

  render() {
    const { id, item, editValueField } = this.props;

    return (
      <React.Fragment>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            ID Typ
          </Col>
          <Col sm={9}>
            <Select
              placeholder="ID Typ Ausprägung wählen"
              clearable={false}
              name={`components.${id}.props.type`}
              options={this.inputTypes}
              value={get(item, "props.type")}
              onChange={(selectedOption) => editValueField(id, 'componentType', selectedOption.value)}
            />
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

InputConfiguration.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  item: PropTypes.object.isRequired,
  editValueField: PropTypes.func,
  language: PropTypes.string
};

InputConfiguration.defaultProps = {
  editValueField: noop,
  language: 'de'
};

export default InputConfiguration;

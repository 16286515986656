import './SortableList.css';

import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { classList } from '../../utils/classList';

export const SortableContainerComponent = SortableContainer(({ children, className }) => (
  <div className={classList('sortable-list SortableHOCList', className)}>
    {children}
  </div>
));

export function SortableList({ children, ...restProps }) {
  return (
    <SortableContainerComponent
      useDragHandle={true}
      useWindowAsScrollContainer={true}
      helperClass="SortableHOCHelper"
      {...restProps}
    >
      {children}
    </SortableContainerComponent>
  );
}

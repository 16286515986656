import axios from 'axios';
import { EndpointName } from '../../constants/EndpointName';
import { CONSULTANT_LOAD } from '../../actions/PermissionGroupAction';
import { createSearchQuery } from './createSearchQuery';
import { call, put, select } from 'redux-saga/effects';
import { getConsultantsSearchParams } from '../../selectors/PermissionGroup/permissionGroupCommonSelectors'  

export function* permissionGroupGetConsultantsSaga() {
  try {
    yield put(CONSULTANT_LOAD.pending());

    const searchParams = yield select(getConsultantsSearchParams);
    const searchQuery = yield call(createSearchQuery, searchParams);
    const { data: consultants, headers } = yield call(axios, { url: `${EndpointName.PERSON_CONSULTANT}/?${searchQuery}`, });

    const totalCount = headers['x-total-count']
      ? parseInt(headers['x-total-count'], 10)
      : consultants.length;

    yield put(CONSULTANT_LOAD.success({ consultants, totalCount }));
  } catch (error) {
    yield put(CONSULTANT_LOAD.failure(error));
  }
}

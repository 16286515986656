import './BulkLoadsActionDetails.css';

import React from 'react';
import { Table, TableCell, TableRow } from '../Common/Table';
import { BULK_LOAD_PROP_TYPES_ACTION } from './BulkLoadsPropTypes';
import { BulkLoadsResultsTime } from './BulkLoadsResultsTime';
import { BulkLoadsStateIcon } from './BulkLoadsStateIcon';

export class BulkLoadsActionDetails extends React.PureComponent {
  render() {
    const { action } = this.props;
    return (
      <div className="bulk-loads-action-details">
        <Table className="bulk-loads-action-details__details-table">
          <TableRow>
            <TableCell className="bulk-loads-action-details__label">Load ID</TableCell>
            <TableCell className="bulk-loads-action-details__value">{action.loadId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="bulk-loads-action-details__label">Component</TableCell>
            <TableCell className="bulk-loads-action-details__value">{action.component}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="bulk-loads-action-details__label">State</TableCell>
            <TableCell className="bulk-loads-action-details__value">
              <BulkLoadsStateIcon state={action.state} /> {action.state}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="bulk-loads-action-details__label">Result</TableCell>
            <TableCell className="bulk-loads-action-details__value">{action.result}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="bulk-loads-action-details__label">Runtime</TableCell>
            <TableCell className="bulk-loads-action-details__value">
              <BulkLoadsResultsTime seconds={action.metrics.secondsWorking} />
            </TableCell>
          </TableRow>
        </Table>

        {action.errorMessage ? (
          <Table className="bulk-loads-action-details__details-table" style={{ maxWidth: '100%' }}>
            <TableRow>
              <TableCell className="bulk-loads-action-details__label">Error Message</TableCell>
            </TableRow>
            <TableRow>
              {/* For some reason, maxWidth needs to be set to some value in order to actually have the table restrict the width correctly. */}
              <TableCell className="bulk-loads-action-details__value" style={{ overflowX: 'auto', maxWidth: '0px' }}>
                {action.errorMessage}
              </TableCell>
            </TableRow>
          </Table>
        ) : null}

        <Table className="bulk-loads-action-details__details-table">
          <TableRow>
            <TableCell className="bulk-loads-action-details__label">Loaded Records</TableCell>
            <TableCell className="bulk-loads-action-details__value">{action.metrics.loadedRecords}</TableCell>
          </TableRow>
        </Table>
        <Table className="bulk-loads-action-details__details-table">

          <TableRow>
            <TableCell className="bulk-loads-action-details__label">Mongo post processing time</TableCell>
            <TableCell className="bulk-loads-action-details__value">{action.metrics.mongoDbPostProcessingSeconds}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="bulk-loads-action-details__label">Mongo write time</TableCell>
            <TableCell className="bulk-loads-action-details__value">{action.metrics.mongoDbDatabaseWriteTimeSeconds}</TableCell>
          </TableRow>
        </Table>

        <Table className="bulk-loads-action-details__details-table">
          <TableRow>
            <TableCell className="bulk-loads-action-details__label">Reference errors</TableCell>
            <TableCell className="bulk-loads-action-details__value">{action.metrics.refErrors}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="bulk-loads-action-details__label">Reference errors references</TableCell>
            <TableCell className="bulk-loads-action-details__value">{action.metrics.refErrorsRef}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="bulk-loads-action-details__label">Reference errors embedded</TableCell>
            <TableCell className="bulk-loads-action-details__value">{action.metrics.refErrorsEmbed}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="bulk-loads-action-details__label">Insert error count</TableCell>
            <TableCell className="bulk-loads-action-details__value">{action.metrics.insertErrorCount}</TableCell>
          </TableRow>
        </Table>
      </div>
    );
  }
}

BulkLoadsActionDetails.propTypes = {
  action: BULK_LOAD_PROP_TYPES_ACTION,
};

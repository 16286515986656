import './ApiClientLoginForm.css';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Form, reduxForm } from 'redux-form';
import Spinner from '../Spinner';
import { FormInput } from '../FormControls';
import { Button } from '../Common/Button';

export class ApiClientLoginReduxForm extends React.Component {
  state = {
    isPasswordChanged: false,
  };

  render() {
    const { invalid, executing, authError, handleSubmit } = this.props;
    const { isPasswordChanged } = this.state;
    return (
      <Form onSubmit={handleSubmit(this.handleSubmit)} className="api-client-login-form">
        {executing && <div className="api-client-login-form__spinner"><Spinner text="Authorization..."/></div>}
        <div className="api-client-login-form__input">
          <Field
            component={FormInput}
            size='large'
            type="password"
            name="password"
            placeholder="Password"
            displayErrors={false}
            onChange={this.handleChange}
          />
          {authError && !isPasswordChanged ? (
            <div className="api-client-login-form__error">
              <FormattedMessage id="apiClient_authError"/>
            </div>
          ): null}
        </div>
        <Button bsStyle="primary" type="submit" disabled={invalid || executing}>
          Continue
        </Button>
      </Form>
    );
  }

  handleChange = () => this.setState({ isPasswordChanged: true });
  handleSubmit = (formData) => {
    const { login } = this.props;
    this.setState({ isPasswordChanged: false }, () => login(formData));
  };
}

export const ApiClientLoginForm = reduxForm({})(ApiClientLoginReduxForm);

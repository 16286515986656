import { put, select } from 'redux-saga/effects';
import { HOLIDAYS_EDIT_ACTIONS } from '../../actions/HolidaysActions';
import { getLanguage } from '../../selectors/commonSelectors';
import { getHolidays, getHolidaysList } from '../../selectors/holidaysSelectors';

export function* holidaysEditSaga({ data: { item, name } }) {
  const language = yield select(getLanguage);
  if (item.name[language] === name) {
    return;
  }

  const updatedItem = {
    ...item,
    name: {
      ...item.name,
      [language]: name,
    },
  };

  const holidays = yield select(getHolidays);
  const holidaysList = yield select(getHolidaysList);
  const updatedDataRanges = holidaysList.dateRanges.map((it) => it === item ? updatedItem : it);

  const updatedHolidays = holidays.map((list) => holidaysList && list.id === holidaysList.id
    ? { ...list, dateRanges: updatedDataRanges }
    : list,
  );
  yield put(HOLIDAYS_EDIT_ACTIONS.success({ holidaysLists: updatedHolidays }));
}

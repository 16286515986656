import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  FINANCING_PROFILING_LOAD_ACTIONS,
  FINANCING_PROFILING_SAVE_ACTIONS,
  FINANCING_PROFILING_UPDATE_ACTIONS,
} from '../actions/FinancingProfilingActions';
import {
  getFinancingProfilingDurations,
  getFinancingProfilingError,
  getFinancingProfilingLoading,
} from '../selectors/FinancingProfiling/financingProfilingCommonSelectors';
import { FinancingProfiling } from '../components/FinancingProfiling/FinancingProfiling';
import { getFinancingProfilingProfiles } from '../selectors/FinancingProfiling/financingProfilingProfilesSelector';
import {
  getFinancingProfilingLoanProductOptions,
  getFinancingProfilingPredictionOptions,
  getFinancingProfilingProfileOptions,
} from '../selectors/FinancingProfiling/financingProfilingOptionsSelectors';

const mapStateToProps = createStructuredSelector({
  loading: getFinancingProfilingLoading,
  error: getFinancingProfilingError,
  profiles: getFinancingProfilingProfiles,
  profileOptions: getFinancingProfilingProfileOptions,
  predictionOptions: getFinancingProfilingPredictionOptions,
  loanProductOptions: getFinancingProfilingLoanProductOptions,
  durations: getFinancingProfilingDurations,
});

const mapDispatchToProps = {
  load: () => FINANCING_PROFILING_LOAD_ACTIONS.request(),
  update: (profiles) => FINANCING_PROFILING_UPDATE_ACTIONS.request({ profiles }),
  save: () => FINANCING_PROFILING_SAVE_ACTIONS.request(),
};

export const FinancingProfilingPage = connect(mapStateToProps, mapDispatchToProps)(FinancingProfiling);

import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import {
  GET_CONSULTANT_PENDING,
  GET_CONSULTANT_FULFILLED,
  GET_CONSULTANT_REJECTED
} from '../../actions/WorkguideActions';

export default function* getWorkguideConsultants() {
  yield put({ type: GET_CONSULTANT_PENDING });

  try {
    const payload = yield call(handleNextHeaders, { url: '/person/consultant/?limit(9999)' });
    yield put({ type: GET_CONSULTANT_FULFILLED, payload, data: payload });

    return payload;
  } catch (error) {
    yield put({ type: GET_CONSULTANT_REJECTED, payload: error, error });

    return error;
  }
}

import React from 'react';
import Dropzone from 'react-dropzone';
import { Button, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import Spinner from '../Spinner';
import { REDUCER_STATUS_FULFILLED, REDUCER_STATUS_PROGRESS, REDUCER_STATUS_FAILURE } from '../../constants/ReducerStatuses';
import { Progress } from './Progress';
import './Import.css';
import { isUndefined } from 'lodash';

class Import extends React.Component {
  constructor(props) {
    super(props);

    this.onDrop = this.onDrop.bind(this);
    this.onImport = this.onImport.bind(this);
    this.displayImportButton = this.displayImportButton.bind(this);
  }

  displayErrors(rejectedFiles) {
    rejectedFiles.forEach((file) => {
      toast.error(`${file.name} hat den falschen Dateityp. Es werden nur CSV-Dateien akzeptiert`, {
        autoClose: 10000,
        position: toast.POSITION.TOP_CENTER,
      });
    });
  }

  onDrop(acceptedFiles, rejectedFiles) {
    const { onCsv } = this.props;
    acceptedFiles.forEach((file) => {

      onCsv(file);
      toast.info(`${file.name} wurde akzeptiert`, { autoClose: 2000 });
    });
    if (rejectedFiles) {
      this.displayErrors(rejectedFiles);
    }
  }

  onImport() {
    const { onImport, csvResult } = this.props;
    onImport(csvResult.results.rows);
  }

  displayImportButton() {
    const { csvResult, importResult } = this.props;

    return (
      csvResult.status === REDUCER_STATUS_FULFILLED &&
      csvResult.results.rows.length &&
      importResult.status !== REDUCER_STATUS_FULFILLED &&
      importResult.status !== REDUCER_STATUS_PROGRESS &&
      importResult.status !== REDUCER_STATUS_FAILURE
    );
  }

  render() {
    const { csvResult, importResult } = this.props;

    return (
      <Row style={{ marginTop: '10px' }}>
        <Col sm={6}>
          <Dropzone accept=".csv" onDrop={this.onDrop} multiple={false}>
            <h1 className="text-center">
              <i className="icon-088-upload-cloud" />
            </h1>
            <p className="text-center">
              <FormattedMessage id="leadsSwitchImport_drop_text" />
            </p>
          </Dropzone>
        </Col>
        <Col sm={6}>
          {csvResult.status === REDUCER_STATUS_FAILURE && !isUndefined(csvResult.apiError) && (
            <div>
              <div>
                <FormattedMessage id="leadsSwitchImport_api_error" />
              </div>

              <p style={{color: 'red'}}>{csvResult.apiError.message}</p>
            </div>
          )}
          {csvResult.loading && (
            <div className="leads-switch-import__validation">
              <FormattedMessage id="leadsSwitchImport_validation_title" />
              <Spinner text={false} screen={false} />
            </div>
          )}

          {csvResult.status === REDUCER_STATUS_FAILURE && (
            <div>
              <div>
                <FormattedMessage id="leadsSwitchImport_validation_fail" />
              </div>
              {csvResult.errors.map((error, i) => (
                <div key={i}>
                  <FormattedMessage
                    id="leadsSwitchImport_validation_error"
                    values={{
                      lineNumber: error.lineNumber,
                      fieldName: error.fieldName,
                    }}
                  />
                  - <FormattedMessage id={`leadsSwitchImport_validation_${error.errorText}`} />
                </div>
              ))}
            </div>
          )}

          {this.displayImportButton() && (
            <div>
              <FormattedMessage id="leadsSwitchImport_validation_success" />
              <Button className="leads-switch-import__button" onClick={this.onImport}>
                <FormattedMessage id="leadsSwitchImport_button_import" />
              </Button>
            </div>
          )}

          <Progress progressResult={importResult} />

          {importResult.status === REDUCER_STATUS_FULFILLED && (
            <div>
              <FormattedMessage
                id="leadsSwitchImport_done_status"
                values={{
                  success: importResult.results.success,
                  total: importResult.results.total,
                }}
              />
              {importResult.results.fail > 0 && (
                <FormattedMessage id="leadsSwitchImport_done_fail" values={{ fail: importResult.results.fail }} />
              )}
            </div>
          )}

          {importResult.status === REDUCER_STATUS_FAILURE && Array.isArray(importResult.errors) && (
            <div>{importResult.errors[0].status === 'LEADS_NOT_FOUND' && <FormattedMessage id="leadsSwitchImport_leads_notfound" />}</div>
          )}
        </Col>
      </Row>
    );
  }
}

export default Import;

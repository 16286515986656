import './ChecklistHeader.css';

import React from 'react';
import { Toggle } from '../Common/Toggle';

export function ChecklistHeader({ label, title, isChecked, onChange, children }) {
  return (
    <div className="checklist-header">
      <div className="checklist-header__text">
        {title}
      </div>
      <label>
        <Toggle checked={isChecked} onChange={onChange}/>
        <span>{label}</span>
      </label>
      <div className="checklist-header__control">
        {children}
      </div>
    </div>
  );
}

import './ApiClientServiceInfo.css';

import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view'

export class ApiClientServiceInfo extends React.Component {

  render() {
    const { schema } = this.props;
    return (
      <div className="api-client-service-info">
        {this.renderSearchable()}

        <div className="api-client-service-info__section">
          <div className="api-client-service-info__label">Raw</div>
          <div className="api-client-service-info__body">
            <ReactJson
              src={schema}
              name={false}
              displayDataTypes={false}
              collapsed={2}
              indentWidth={2}
              iconStyle="triangle"
              onAdd={false}
              onEdit={false}
              onDelete={false}
              enableClipboard={false}
            />
          </div>
        </div>
      </div>
    );
  }

  renderSearchable() {
    const { schema } = this.props;
    if (
      !schema.items ||
      !schema.items.searchable ||
      !schema.items.searchable.length
    ) {
      return null;
    }

    return (
      <div className="api-client-service-info__section">
        <div className="api-client-service-info__label">Searchable fields</div>
        <div className="api-client-service-info__body">
          {schema.items.searchable.map((field) => (
            <div className="api-client-service-info__item" key={field}>{field}</div>
          ))}
        </div>
      </div>
    );
  }
}

ApiClientServiceInfo.propTypes = {
  schema: PropTypes.object.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Toggle, ValidationResult } from '@evoja-web/react-form';

import './Toggle.css';
import Label from './Label';

const FormFieldToggle = React.memo(function FormFieldToggle({
  id,
  onChange,
  validations,
  value
}) {
  return (
    <div className="workflow-tree-form-element">
      <div className="workflow-tree-form-element--component">
        <Toggle
          id={id}
          name={id}
          className="workflow-tree-form-element--component--toggle"
          onChange={onChange}
          value={value}
        >
          <Label id={id} />
        </Toggle>
      </div>

      <ValidationResult
        show
        validations={validations}
      />
    </div>
  );
});

FormFieldToggle.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.string
};

FormFieldToggle.defaultProps = {
  id: 'toggle',
  onChange: noop,
  validations: {},
  value: undefined
};

export default FormFieldToggle;

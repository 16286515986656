import React from 'react';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import update from 'immutability-helper';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';

import { CORE_CONFIG_LOAD, CORE_CONFIG_SAVE, setData, setModule } from '../actions/CoreConfigActions';
import { getCommonSearch, getLanguage } from '../selectors/commonSelectors';
import {
  getCoreConfigError,
  getCoreConfigHasError,
  getCoreConfigLoading,
  getCoreConfigSelectedPanel,
} from '../selectors/CoreConfig/coreConfigCommonSelectors';
import {
  getCoreConfigConfigs,
  getCoreConfigConfigsInitial,
  getCoreConfigConfigsMapped,
} from '../selectors/CoreConfig/coreConfigConfigsSelectors';
import { getCoreConfigModules, getCoreConfigModulesInitial } from '../selectors/CoreConfig/coreConfigModulesSelectors';
import PageContainer from '../components/Common/PageContainer';
import ModuleItems from '../components/CoreConfig/ModuleItems';
import { CORE_CONFIG_PANELS, Menu } from '../components/CoreConfig/Menu';
import { ConfigPanel } from '../components/CoreConfig/ConfigPanel';
import { handleGravitonError } from '../utils';
import { SearchBlock } from '../components/Common/SearchBlock';

class CoreConfig extends React.Component {
  constructor(props) {
    super(props);
    props.loadData();

    this.updateConfig = this.updateConfig.bind(this);
    this.updateModule = this.updateModule.bind(this);
  }

  updateModule(id, value) {
    const { modules, language, setModule } = this.props;
    const newModules = update(modules, {
      [id]: { name: { [language]: { $set: value } } },
    });
    setModule({ value: newModules[id], id: newModules[id].id });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { modules, setData } = this.props;
    const sortedModules = arrayMove(modules, oldIndex, newIndex)
      .map((module, index) => ({
        ...module,
        order: index * 100,
      }));
    setData({ key: 'modules', value: sortedModules });
  };

  updateConfig(items) {
    const { configs, setData } = this.props;
    const updatedConfigs = configs.map((config) => {
      const item = items.find((it) => it.id === config.id);
      return item ? { ...config, value: item.value } : config;
    });
    setData({ key: 'configs', value: updatedConfigs });
  }

  render() {
    const { requesting, saveData, selectedPanel, setData, hasError, error } = this.props;
    if (hasError) {
      toast.error(handleGravitonError(error));
    }

    return (
      <PageContainer
        requesting={requesting}
        title="Basiskonfiguration"
        header={<SearchBlock/>}
        saveData={saveData}
      >
        <Menu selectedPanel={selectedPanel} setData={setData}/>
        {selectedPanel === CORE_CONFIG_PANELS.CONFIG
          ? this.renderConfigs()
          : this.renderModules()
        }
      </PageContainer>
    );
  }

  renderConfigs() {
    const { configsMapped, search } = this.props;
    const filteredConfigs = !search
      ? configsMapped
      : configsMapped.filter((config) =>
        config.label.toLowerCase().indexOf(search.toLowerCase()) !== -1,
      );

    return (
      <ConfigPanel items={filteredConfigs} update={this.updateConfig}/>
    );
  }

  renderModules() {
    const { modules, language, search } = this.props;
    const filteredModules = !search
      ? modules
      : modules.filter((module) =>
        module.key.toLowerCase().indexOf(search.toLowerCase()) !== -1,
      );
    return (
      <ModuleItems
        items={filteredModules}
        language={language}
        edit={this.updateModule}
        onSortEnd={this.onSortEnd}
        useDragHandle={true}
        useWindowAsScrollContainer={true}
        helperClass="SortableHOCHelper"
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getLanguage,
  error: getCoreConfigError,
  requesting: getCoreConfigLoading,
  selectedPanel: getCoreConfigSelectedPanel,
  configs: getCoreConfigConfigs,
  configsMapped: getCoreConfigConfigsMapped,
  modules: getCoreConfigModules,
  hasError: getCoreConfigHasError,
  initialConfigs: getCoreConfigConfigsInitial,
  initialModules: getCoreConfigModulesInitial,
  search: getCommonSearch,
});

const mapDispatchToProps = {
  setModule,
  setData,
  saveData: () => CORE_CONFIG_SAVE.request(),
  loadData: () => CORE_CONFIG_LOAD.request(),
};

export default connect(mapStateToProps, mapDispatchToProps)(CoreConfig);

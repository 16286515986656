import React from 'react';
import PropTypes from 'prop-types';
import _, { get } from 'lodash';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';
import Toggle from 'react-toggle';
import HiddenCols from './HiddenCols'

const ContractsConfiguration = ({ id, item, editValueField }) => {
  const onlyActiveItems = get(item, 'props.onlyActiveItems', false);

  return (
    <React.Fragment>
      <FormGroup controlId="codeGroupMulti">
        <Col componentClass={ControlLabel} sm={3}>
          Mehrfachauswahl
        </Col>
        <Col sm={9} style={{ paddingTop: '10px' }}>
          <Toggle
            checked={_.get(item, 'props.multi') === undefined ? false : item.props.multi}
            onChange={() => editValueField(id, 'multi')}
          />
        </Col>
      </FormGroup>

      <FormGroup controlId="onlyActiveItems">
        <Col componentClass={ControlLabel} sm={3}>
          Nur aktive Konten
        </Col>
        <Col sm={9} style={{ paddingTop: '10px' }}>
          <Toggle
            checked={onlyActiveItems}
            onChange={() => editValueField(id, 'prop', { key: 'onlyActiveItems', value: !onlyActiveItems })}
          />
        </Col>
      </FormGroup>

      <FormGroup>
        <Col componentClass={ControlLabel} sm={3}>
          Spaten anzeigen
        </Col>
        <Col sm={9} style={{ paddingTop: '10px' }}>
          <HiddenCols
            component={item}
            options={[{
              value: 'contractType',
              label: 'Vertragstyp'
            }, {
              value: 'contractNumber',
              label: 'Vertragsnummer'
            }, {
              value: 'status',
              label: 'Status'
            }, {
              value: 'authorizedDate',
              label: 'Authorisierungsdatum'
            }, {
              value: 'validFrom',
              label: 'Gültig ab'
            }, {
              value: 'validTo',
              label: 'Gültig bis'
            }, {
              value: 'owner',
              label: 'Vertragsinhaber'
            }, {
              value: 'note',
              label: 'Bemerkung'
            }, {
              value: 'address',
              label: 'Versandadresse'
            }, {
              value: 'blocked',
              label: 'Gesperrt'
            }]}
            onChange={(value) => editValueField(id, 'hiddenCols', value)}
          />
        </Col>
      </FormGroup>
    </React.Fragment>
  );
};

ContractsConfiguration.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  item: PropTypes.object.isRequired,
  editValueField: PropTypes.func
};

ContractsConfiguration.defaultProps = {
  editValueField: _.noop
};

export default ContractsConfiguration;

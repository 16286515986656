import './HolidaysUpdateForm.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form, reduxForm } from 'redux-form';
import { FormInput } from '../FormControls';
import { isMultiDayHoliday } from '../../utils/holidayUtils';
import { FormattedGravitonDate } from '../FormattedGravitonDate';

class HolidaysUpdateReduxForm extends React.Component {
  render() {
    const { onCancel, onSubmit } = this.props;
    const { handleSubmit, submitFailed, invalid } = this.props;

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton={true}>
          <Modal.Title>
            {this.renderModalTitle()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="holidays-update-form__name">
            <Field component={FormInput}
                   type="text"
                   name="name"
                   placeholder="Name"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button"
                  bsStyle="default"
                  onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit"
                  bsStyle="primary"
                  disabled={submitFailed ? invalid : false}>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    );
  }

  renderModalTitle() {
    const { item } = this.props;
    if (isMultiDayHoliday(item)) {
      return (
        <React.Fragment>
          <FormattedGravitonDate value={item.startDate} format="DD.MM.YYYY"/>
          {" - "}
          <FormattedGravitonDate value={item.endDate} format="DD.MM.YYYY"/>
        </React.Fragment>
      );
    } else {
      return (
        <FormattedGravitonDate value={item.startDate} format="DD.MM.YYYY"/>
      );
    }
  }
}

export const HolidaysUpdateForm = reduxForm({
  form: 'holidays-update-form',
  validate: validateForm,
})(HolidaysUpdateReduxForm);

HolidaysUpdateForm.propTypes = {
  year: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,

  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

function validateForm(formData) {
  return {
    name: !formData.name,
  };
}

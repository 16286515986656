import { LOAD_PROMOTIONS_FILES_ACTIONS } from '../../actions/PromotionActions';

export function LoadPromotionsFilesReducer(state, action) {
  switch (action.type) {
    case LOAD_PROMOTIONS_FILES_ACTIONS.PENDING:
      return updateFile(state, action.meta.file, {loading: true, error: undefined});

    case LOAD_PROMOTIONS_FILES_ACTIONS.SUCCESS:
      return updateFile(state, action.meta.file, {
        loading: false,
        error: undefined,
        blob: action.data,
        url: URL.createObjectURL(new Blob([action.data], {type: action.meta.file.metadata.mime})),
      });

    case LOAD_PROMOTIONS_FILES_ACTIONS.FAILURE:
      return updateFile(state, action.meta.file, {loading: false, error: action.data});

    default:
      return state;
  }
}

function updateFile(state, file, fileContent) {
  return {
    ...state,
    files: {
      ...state.files,
      [file.id]: {
        ...state.files[file.id],
        ...fileContent,
      },
    },
  };
}

import { NOTICE_IMPORT_DELETE } from '../../actions/NoticeImportActions';
import {
  REDUCER_STATUS_PENDING,
  REDUCER_STATUS_PROGRESS,
  REDUCER_STATUS_FULFILLED,
  REDUCER_STATUS_FAILURE,
} from '../../constants/ReducerStatuses';


const initialState = {
  loading: false,
  error: undefined,
  results: undefined,
  status: undefined,
  fileId: undefined,
};

export function NoticeImportDeleteReducer(state = initialState, action) {
  switch (action.type) {
    case NOTICE_IMPORT_DELETE.PENDING:
      return {
        ...state,
        fileId: action.meta.fileId,
        loading: true,
        error: undefined,
        status: REDUCER_STATUS_PENDING,
      };
    case NOTICE_IMPORT_DELETE.PROGRESS:
      return {
        ...state,
        fileId: action.meta.fileId,
        loading: true,
        results: action.meta,
        error: undefined,
        status: REDUCER_STATUS_PROGRESS,
      };
    case NOTICE_IMPORT_DELETE.SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        results: action.data,
        status: REDUCER_STATUS_FULFILLED,
      };
    }
    case NOTICE_IMPORT_DELETE.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.data,
        status: REDUCER_STATUS_FAILURE,
      };
    default:
      return state;
  }
}

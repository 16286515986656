import { put, select } from 'redux-saga/effects';
import { getFinancingRatesBaseRates } from '../../selectors/FinancingRates/financingRatesCommonSelectors';
import { FINANCING_RATES_UPDATE_BASE_RATE_ACTIONS } from '../../actions/FinancingRatesActions';

export function* financingRatesUpdateBaseRateSaga({ data: { item, value } }) {
  if (item.baseRate === value) {
    return;
  }

  const baseRates = yield select(getFinancingRatesBaseRates);
  const updatedBaseRates = baseRates.map((it) =>
    it === item
      ? { ...it, baseRate: value }
      : it,
  );

  yield put(FINANCING_RATES_UPDATE_BASE_RATE_ACTIONS.success({ baseRates: updatedBaseRates }));
}

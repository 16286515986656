import React from 'react';
import PropTypes from 'prop-types';
import { noop, isNil } from 'lodash';
import cl from 'classnames';

import './Collapsible.css';

class Collapsible extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: props.isCollapsed
    };

    this.onExlapse = this.onExlapse.bind(this);
  }

  /**
   * Update internal state with value from props if it changes
   *
   * @param   {Object}  prevProps  Prev props
   *
   * @return  void
   */
  componentDidUpdate(prevProps) {
    const { isCollapsed } = this.props;
    const { isCollapsed: prevIsCollapsed } = prevProps;

    if (isCollapsed !== prevIsCollapsed) {
      this.setState({ collapsed: isCollapsed });
    }
  }

  /**
   * Expand / collapse
   *
   * @return  void
   */
  onExlapse() {
    const { collapsed } = this.state;
    const {
      onCollapse,
      onExpand
    } = this.props;

    const callback = collapsed
      ? onExpand
      : onCollapse;

    this.setState({ collapsed: !collapsed });

    callback();
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { collapsed } = this.state;
    const {
      children,
      className,
      label
    } = this.props;

    const contentClassNames = cl({
      'collapsible-content': true,
      'collapsible-content--collapsed': collapsed,
      'collapsible-content--expanded': !collapsed
    });

    const titleClassName = cl({
      'collapsible-title': true,
      'collapsible-title--collapsed': collapsed,
      'collapsible-title--expanded': !collapsed
    });

    return (
      <div className={cl('collapsible', className)}>
        {!isNil(label) && (
          <div
            className={titleClassName}
            onClick={this.onExlapse}
          >
            <div className="collapsible-title--label">
              {label}
            </div>
            <div
              className={cl({
                'collapsible-title--exlapse': true,
                mdi: true,
                'mdi-chevron-up': !collapsed,
                'mdi-chevron-down': collapsed
              })}
            />
          </div>
        )}

        <div className={contentClassNames}>
          {children}
        </div>
      </div>
    );
  }
}

Collapsible.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  isCollapsed: PropTypes.bool,
  label: PropTypes.node,
  onCollapse: PropTypes.func,
  onExpand: PropTypes.func
};

Collapsible.defaultProps = {
  children: [],
  className: undefined,
  isCollapsed: false,
  label: undefined,
  onCollapse: noop,
  onExpand: noop
};

export default Collapsible;

import './LeadqualificationOptions.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '../Common/Button';
import { createRef } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';
import { InputControl } from '../Common/InputControl';

export class LeadqualificationOptions extends React.PureComponent {
  state = {
    destinations: this.props.options[0]
      ? [...this.props.options[0].destinations]
      : [],
    triggers: this.props.options[0]
      ? [...this.props.options[0].triggers]
      : [],
  };

  render() {
    const { onCancel } = this.props;
    return (
      <div className="leadqualification-options">
        {this.renderDestinations()}
        {this.renderTriggers()}

        <div className="leadqualification-options__buttons">
          <Button
            onClick={this.handleSave}
            bsSize="sm"
            bsStyle="primary"
            className="leadqualification-options__save"
            disabled={!this.isValid()}
          >
            <FormattedMessage id="common_save"/>
          </Button>
          <Button onClick={onCancel} bsSize="sm">
            <FormattedMessage id="common_cancel"/>
          </Button>
        </div>
      </div>
    );
  }

  renderDestinations() {
    const { destinations } = this.state;
    return (
      <div className="leadqualification-options__destinations">
        <div className="leadqualification-options__destinations-title">
          <FormattedMessage id="leadqualification_options_recipients"/>
        </div>
        {destinations.map((destination, index) => this.renderDestination(destination, index))}

        <div className="leadqualification-options__button-add">
          <Button onClick={this.handleAddDestination} bsStyle="primary" bsSize="sm">
            <FormattedMessage id="leadqualification_options_add"/>
          </Button>
        </div>
      </div>
    );
  }

  renderDestination(destination, index) {
    return (
      <div className="leadqualification-options__destination" key={index}>
        <InputControl
          key={index}
          type="email"
          value={destination}
          onChange={this.handleChangeRecipient.bind(this, index)}
          className={this.isValidDestination(destination) ? '' : 'leadqualification-options__invalid'}
        />
        <div className="icon-008-bin leadqualification-options__button-remove"
             onClick={this.handleRemoveDestination.bind(this, index)}/>
      </div>
    );
  }

  renderTriggers() {
    const { statuses } = this.props;
    return (
      <div className="leadqualification-options__triggers">
        <div className="leadqualification-options__triggers-title">
          <FormattedMessage id="leadqualification_options_triggers"/>
        </div>

        {statuses.map((status) => this.renderTrigger(status))}
      </div>
    );
  }

  renderTrigger(status) {
    const { locale } = this.props;
    const { triggers } = this.state;
    const isChecked = triggers.some((it) => it === status.id);
    return (
      <div className="leadqualification-options__trigger" key={status.id}>
        <label>
          <input
            id={status.id}
            type="checkbox"
            checked={isChecked}
            onChange={this.handleChangeTrigger.bind(this, status)}
          />
          {status.text[locale]}
        </label>
      </div>
    );
  }

  isValid() {
    const { destinations } = this.state;
    return destinations.every((it) => this.isValidDestination(it));
  }

  isValidDestination(destination) {
    return /^\S+@\S+$/.test(destination);
  }

  handleAddDestination = () => this.setState((prev) => ({ destinations: [...prev.destinations, ""] }));

  handleChangeRecipient = (indexToChange, { target: { value } }) => this.setState((prev) => ({
    destinations: prev.destinations.map((it, index) => index === indexToChange ? value : it),
  }));

  handleRemoveDestination = (indexToRemove) => this.setState((prev) => ({
    destinations: prev.destinations.filter((_, index) => index !== indexToRemove),
  }));

  handleChangeTrigger = (status) => this.setState((prev) => ({
    triggers: prev.triggers.some((it) => it === status.id)
      ? prev.triggers.filter((it) => it !== status.id)
      : [...prev.triggers, status.id]
  }));

  handleSave = () => {
    const { onSave } = this.props;
    const { destinations, triggers } = this.state;
    const toSave = !destinations.length && !triggers.length
      ? []
      : [{
        destinations: destinations,
        triggers: triggers,
        protocol: 'mailto',
        template: {
          $ref: createRef(EndpointName.FILE, 'mailtemplate-lead'),
        },
      }];

    onSave(toSave);
  };
}

LeadqualificationOptions.propTypes = {
  options: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

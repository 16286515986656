import axios from 'axios';

export const GET = 'adminUI/Checklist/GET';
export const PUT = 'adminUI/Checklist/PUT';
export const SET = 'adminUI/Checklist/SET';

export function setData({ key, value }) {
  return (dispatch) => {
    dispatch({
      type: SET,
      key,
      value,
    });
  };
}

export function getChecklist() {
  return (dispatch) => {
    dispatch({
      type: GET,
      payload: axios({
        url: '/checklist/config/checklist/complete',
      }),
    });
  };
}

export function updateChecklist(checklist) {
  return (dispatch) => {
    dispatch({
      type: PUT,
      payload: axios
        .delete('/checklist/config/checklist/complete')
        .then(() => axios.put('/checklist/config/checklist/complete', checklist)),
    });
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ListGroup, DropdownButton, MenuItem } from 'react-bootstrap';
import update from 'immutability-helper';

import ListItem from './ListItem';
import DependencyForm from './Form';

class DependencyList extends React.Component {
  constructor(props) {
    super(props);

    const { codes, fetchCodes } = props;

    this.state = {
      add: {
        show: false,
        type: undefined,
        custom: false
      }
    };

    // Account types are required for account dependencies. Fetch them if not already done
    if (!codes.accountType) fetchCodes('accountType');
    if (!codes.contractType) fetchCodes('contractType');
    if (!codes.contactVariation) fetchCodes('contactVariation');
    if (!codes.paymentOrderType) fetchCodes('paymentOrderType');
    if (!codes.relationType) fetchCodes('relationType');
    if (!codes.portfolioKind) fetchCodes('portfolioKind');
    if (!codes.cardType) fetchCodes('cardType');

    this.onDependencyChange = this.onDependencyChange.bind(this);
    this.onDependencyRemove = this.onDependencyRemove.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
  }

  /**
   * Handle dependency change.
   * Update or add the given dependency to the list
   *
   * @param  {Object} dependency Dependency
   * @param  {Number} [index=-1] Index (push if -1, else splice)
   *
   * @return void
   */
  onDependencyChange(dependency, index = -1) {
    const { dependencies, onChange } = this.props;
    let updated = [ ...dependencies ];

    if (index > -1) {
      updated = update(updated, {
        $splice: [[index, 1, dependency]]
      });
    } else {
      updated = update(updated, {
        $push: [dependency]
      });
    }

    if (_.get(this, 'state.add.show', false)) this.toggleForm();

    onChange(updated);
  }

  /**
   * Remove the dependency on the given index
   *
   * @param  {Number} index Index
   *
   * @return void
   */
  onDependencyRemove(index) {
    const { dependencies, onChange } = this.props;
    if (index > -1) {
      const updated = update(dependencies, {
        $splice: [[index, 1]]
      });

      onChange(updated);
    }
  }

  /**
   * Toggle the dependency form
   *
   * @param  {Boolean} [custom=false] Customer dependency (allow to define jmespath instruction)
   *
   * @return void
   */
  toggleForm(custom = false) {
    const { add } = this.state;

    if (add.show) {
      this.setState({ add: { show: false, custom: false } });
    } else {
      this.setState({ add: { show: true, custom } });
    }
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { add } = this.state;
    const {
      language,
      dependencies,
      target,
      codes,
      consultants,
      productGroups
    } = this.props;

    const children = dependencies.map((dependency, index) => (
      <ListItem
        key={index}
        target={target}
        language={language}
        dependency={dependency}
        codes={codes}
        consultants={consultants}
        productGroups={productGroups}
        onChange={(dependency) => this.onDependencyChange(dependency, index)}
        onRemove={() => this.onDependencyRemove(index)}
        style={{ backgroundColor: (index % 2 === 0) ? '#ffffff' : '#f4f6f9'}}
      />
    ));

    return(
      <React.Fragment>
        <ListGroup>
          {children}
        </ListGroup>

        {(add.show) ? (
          <div>
            <DependencyForm
              target={target}
              language={language}
              onSubmit={this.onDependencyChange}
              onCancel={() => this.setState({ add: {
                show: false,
                type: undefined,
                custom: false
              } })}
            />
          </div>
        ) : null}

        <div style={{ paddingTop: '10px' }}>
          <DropdownButton id="dependency-type" title="Neuer Eintrag" bsStyle="primary" disabled={!add}>
            <MenuItem onClick={() => this.toggleForm(false)}>
              Standard
            </MenuItem>

            <MenuItem onClick={() => this.toggleForm(true)}>
              Custom
            </MenuItem>
          </DropdownButton>
        </div>
      </React.Fragment>
    );
  }
}

DependencyList.propTypes = {
  language: PropTypes.string,
  target: PropTypes.object.isRequired,
  dependencies: PropTypes.array,
  codes: PropTypes.object,
  consultants: PropTypes.array,
  productGroups: PropTypes.array,
  onChange: PropTypes.func,
  fetchCodes: PropTypes.func
};

DependencyList.defaultProps = {
  language: 'de',
  dependencies: [],
  codes: {},
  consultants: [],
  productGroups: [],
  onChange:_.noop,
  fetchCodes: _.noop
};

function mapStateToProps(state, ownProps) {
  return {
    language: state.login.language,
    consultants: state.workguide.consultants,
    codeGroups: state.workguide.codeGroups,
    codes: state.workguide.codes,
    productGroups: state.workguide.productGroups
  };
}

export default connect(mapStateToProps)(DependencyList);

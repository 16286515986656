import { connect } from 'react-redux';
import NoticeImport from '../components/NoticeImport/NoticeImport';
import {
  NOTICE_IMPORT_FILE_LIST_LOAD,
  NOTICE_IMPORT_EXAMPLES,
  NOTICE_IMPORT_FILE_POST,
  NOTICE_IMPORT_VALIDATION,
  NOTICE_IMPORT_IMPORT,
  NOTICE_IMPORT_DELETE,
} from '../actions/NoticeImportActions';

const mapStateToProps = (state) => {
  return {
    language: state.login.language,
    selectedModule: 'noticeImport',
    fileListResult: state.noticeImportFileListLoad,
    examplesResult: state.noticeImportExamples,
    filePostResult: state.noticeImportFilePost,
    validationResult: state.noticeImportValidation,
    importResult: state.noticeImportImport,
    deleteResult: state.noticeImportDelete,
    user: state.login.session,
    userId: state.login.session.id,
  };
};

const mapDispatchToProps = {
  fileListLoad: () => NOTICE_IMPORT_FILE_LIST_LOAD.request(),
  examplesLoad: () => NOTICE_IMPORT_EXAMPLES.request(),
  filePost: (file, user) => NOTICE_IMPORT_FILE_POST.request({ file, user }),
  validation: (filePostResults) => NOTICE_IMPORT_VALIDATION.request(filePostResults),
  import: (filePostResults) => NOTICE_IMPORT_IMPORT.request(filePostResults),
  delete: (user, fileId) => NOTICE_IMPORT_DELETE.request({ user, fileId }),
};

export default connect(mapStateToProps, mapDispatchToProps)(NoticeImport);

import './AnalyticsLogsSearchForm.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, Form, reduxForm } from 'redux-form';
import {
  PROP_TYPES_DEPARTMENT,
  PROP_TYPES_DIVISION,
  PROP_TYPES_OPTION,
  PROP_TYPES_USER,
} from './AnalyticsLogsPropTypes';
import { classList } from '../../utils/classList';
import { ExpandContent } from '../Common/ExpandContent';
import { Button } from '../Common/Button';
import { FormattedDate } from '../Common/FormattedDate';
import { HTTP_CODES } from '../../constants/HttpCodes';
import { FormDateRange, FormInput, FormSelect } from '../FormControls';

const METHOD_OPTIONS = ['GET', 'PUT', 'POST', 'DELETE', 'PATCH']
  .map((it) => ({ label: it, value: it }));

const RESPONSE_CODE_OPTIONS = Object.keys(HTTP_CODES)
  .map((key) => ({
    label: `${key} ${HTTP_CODES[key].title}`,
    value: key,
  }));

class AnalyticsLogsSearchReduxForm extends React.PureComponent {
  state = {
    expanded: true,
    searchResults: this.props.searchResults,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.searchResults === prevState.searchResults) {
      return null;
    }

    return {
      expanded: nextProps.searchResults.length === 0,
      searchResults: nextProps.searchResults,
    };
  }

  render() {
    const { expanded } = this.state;
    const { intl, handleSubmit, search, loading, invalid, tenant } = this.props;
    const { currentValues: { dates } = {}, endpoints } = this.props;

    const iconClasses = classList({
      'icon-013-chevron-down': true,
      'analytics-logs-search__icon': true,
      'analytics-logs-search__icon--collapsed': !expanded,
    });

    return (
      <div className="analytics-logs-search">
        <div className="analytics-logs-search__header" onClick={this.toggleExpanded}>
          <div className="analytics-logs-search__title">
            <FormattedMessage id="analyticsLogs_searchForm_title"/>
          </div>
          {!expanded && (
            <div className="analytics-logs-search__info">
              {dates && dates.startDate && (
                <span>
                    <FormattedMessage id="analyticsLogs_searchForm_form"/>
                  {' '}
                  <FormattedDate value={dates.startDate}/>
                  </span>
              )}
              {dates && dates.endDate && (
                <span>
                    {' '}
                  <FormattedMessage id="analyticsLogs_searchForm_to"/>
                  {' '}
                  <FormattedDate value={dates.endDate}/>
                  </span>
              )}
            </div>
          )}
          <div className={iconClasses}/>
        </div>

        <ExpandContent expanded={expanded} className="analytics-logs-search__body">
          <Form onSubmit={handleSubmit(search)} key="form">
            <Field component={FormDateRange}
              label={<FormattedMessage id="analyticsLogs_searchForm_labelDates" />}
              labelSize={3}
              name="dates"
              displayFormat="DD.MM.YYYY"
              isOutsideRange={this.isOutsideRange}
              minimumNights={0}
              startDatePlaceholderText={intl.formatMessage({ id: 'analyticsLogs_searchForm_startDatePlaceholder' })}
              endDatePlaceholderText={intl.formatMessage({ id: 'analyticsLogs_searchForm_endDatePlaceholder' })}
              disabled={loading}
            />
            {tenant === 'entb' ? (
              <Field component={FormInput}
                size='large'
                label={<FormattedMessage id="analyticsLogs_searchForm_labelUsers" />}
                labelSize={3}
                name="users"
                disabled={loading}
              />
            ) : (
                <Field component={FormSelect}
                  size='large'
                  label={<FormattedMessage id="analyticsLogs_searchForm_labelUsers" />}
                  labelSize={3}
                  name="users"
                  multi={true}
                  clearable={true}
                  options={this.getUserOptions()}
                  disabled={loading}
                  displayErrors={false}
                />
              )}
            <Field component={FormSelect}
                   size='large'
                   label={<FormattedMessage id="analyticsLogs_searchForm_labelDepartments"/>}
                   labelSize={3}
                   name="departments"
                   multi={true}
                   clearable={true}
                   options={this.getDepartmentOptions()}
                   disabled={loading}
            />
            <Field component={FormSelect}
                   size='large'
                   label={<FormattedMessage id="analyticsLogs_searchForm_labelDivisions"/>}
                   labelSize={3}
                   name="divisions"
                   multi={true}
                   clearable={true}
                   options={this.getDivisionOptions()}
                   disabled={loading}
            />
            <Field component={FormSelect}
                   size='large'
                   label={<FormattedMessage id="analyticsLogs_searchForm_labelEndpoint"/>}
                   labelSize={3}
                   name="endpoint"
                   multi={false}
                   clearable={true}
                   options={endpoints}
                   disabled={loading}
            />
            <Field component={FormSelect}
                   size='large'
                   label={<FormattedMessage id="analyticsLogs_searchForm_labelMethods"/>}
                   labelSize={3}
                   name="methods"
                   multi={true}
                   clearable={true}
                   options={METHOD_OPTIONS}
                   disabled={loading}
            />
            <Field component={FormInput}
                   size='large'
                   label={<FormattedMessage id="analyticsLogs_searchForm_labelRequestParams"/>}
                   labelSize={3}
                   name="requestParams"
                   disabled={loading}
            />
            <Field component={FormSelect}
                   size='large'
                   label={<FormattedMessage id="analyticsLogs_searchForm_labelResponseCode"/>}
                   labelSize={3}
                   name="responseCode"
                   multi={false}
                   clearable={false}
                   options={RESPONSE_CODE_OPTIONS}
                   disabled={loading}
            />

            <Button bsStyle="primary"
                    type="submit"
                    className="analytics-logs-search__button"
                    disabled={loading || invalid}
            >
              <FormattedMessage id="common_search"/>
            </Button>
            {invalid && (
              <div className="analytics-logs-search__invalid">
                <FormattedMessage id="analyticsLogs_searchForm_labelError"/>
              </div>
            )}
          </Form>
        </ExpandContent>
      </div>
    );
  }

  toggleExpanded = () => this.setState((prev) => ({ expanded: !prev.expanded }));

  isOutsideRange(date) {
    return date.startOf('date').valueOf() > Date.now();
  }

  getDivisionOptions() {
    const { divisions } = this.props;
    return divisions.map((it) => ({ label: it.division, value: it.division }));
  }

  getDepartmentOptions() {
    const { departments } = this.props;
    return departments.map((it) => ({ label: it.department, value: it.department }));
  }

  getUserOptions() {
    const { users } = this.props;
    return users.map((it) => ({
      label: `${it.username} (${it.name})`,
      value: it.username,
    }));
  }
}

export const AnalyticsLogsSearchForm = reduxForm({})(injectIntl(AnalyticsLogsSearchReduxForm));
AnalyticsLogsSearchForm.propTypes = {
  loading: PropTypes.bool,
  searchResults: PropTypes.array,
  search: PropTypes.func.isRequired,
  departments: PropTypes.arrayOf(PROP_TYPES_DEPARTMENT).isRequired,
  divisions: PropTypes.arrayOf(PROP_TYPES_DIVISION).isRequired,
  users: PropTypes.arrayOf(PROP_TYPES_USER).isRequired,
  endpoints: PropTypes.arrayOf(PROP_TYPES_OPTION).isRequired,
  currentValues: PropTypes.object,
  tenant: PropTypes.string
};

import React from 'react';
import PropTypes from 'prop-types';
import { chain, isNil, noop, size } from 'lodash';
import { Select, NumberInput } from '@evoja-web/react-form';

import './FlexSelect.css';

function getSelectedFromValue(value) {
  if (isNil(value)) {
    return value;
  }

  return chain(value)
    .split('-')
    .slice(0, 2)
    .compact()
    .join('-')
    .value();
}

function getAddOrSubtractFromValue(value = '') {
  if (isNil(value)) {
    return value;
  }

  return chain(value)
    .split('-')
    .get(2)
    .value();
}

class FormFieldDateConfigutationFlexSelect extends React.Component {
  constructor(props) {
    super(props);

    const { value } = props;

    this.state = {
      selected: getSelectedFromValue(value),
      addOrSubtract: getAddOrSubtractFromValue(value)
    };

    this.onSelectedChange = this.onSelectedChange.bind(this);
    this.onAddOrSubtractChange = this.onAddOrSubtractChange.bind(this);
  }

  /**
   * Handle selection change in dropdown and update parent
   *
   * @param   {String}  key    Form element id
   * @param   {String}  value  Selected option value
   *
   * @return  void
   */
  onSelectedChange(key, value) {
    const { id, onChange } = this.props;

    // Set selected value and unset addOrSubtract in state
    this.setState({ selected: value, addOrSubtract: undefined });

    // If value has no additional parts (e.g. -add), call parent with value.
    // Else call parent onChange with undefined to unset the current value
    const v = !isNil(value) && size(value.split('-')) === 1
      ? value
      : undefined;

    onChange(id, v);
  }

  /**
   * Handle number input change and update parent
   *
   * @param   {String}  key    Form element id
   * @param   {String}  value  Form element value
   *
   * @return  void
   */
  onAddOrSubtractChange(key, value) {
    const { selected } = this.state;
    const { id, onChange } = this.props;

    this.setState({ addOrSubtract: value });

    // If value is not nil call parent onChange with concated selected and value
    // Else call parent onChange with undefined to unset the current value
    const v = !isNil(value)
      ? `${selected}-${value}`
      : undefined;

    onChange(id, v);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      selected,
      addOrSubtract
    } = this.state;
    const {
      disabled,
      options
    } = this.props;

    return (
      <div className="workguide-form-field-configuration workguide-form-field-date-configuration-flex--select">
        <div className="workguide-form-field-configuration workguide-form-field-date-configuration-flex--select--dropdown">
          <Select
            id="selected"
            disabled={disabled}
            onChange={this.onSelectedChange}
            options={options}
            value={selected}
          />
        </div>

        <div className="workguide-form-field-configuration workguide-form-field-date-configuration-flex--select--number">
          {!isNil(selected) && (selected.startsWith('current-add') || selected.startsWith('current-subtract')) && (
            <NumberInput
              id="addOrSubtract"
              disabled={disabled}
              onChange={this.onAddOrSubtractChange}
              value={addOrSubtract}
            />
          )}
        </div>
      </div>
    );
  }
}

FormFieldDateConfigutationFlexSelect.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.string
};

FormFieldDateConfigutationFlexSelect.defaultProps = {
  disabled: false,
  onChange: noop,
  options: [],
  value: undefined
};

export default FormFieldDateConfigutationFlexSelect;

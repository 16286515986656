import React from 'react';
import PropTypes from 'prop-types';
import { has, get, set, map, filter, find, isUndefined, compact } from 'lodash';
import { ListGroup, ListGroupItem, DropdownButton, MenuItem } from 'react-bootstrap';

import * as available from './Validations/index';

function getPossibleValidations(component) {
  const type = get(component, 'component');

  return filter(available, a => a.availableFor.includes(type));
}

class FieldValidations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validations: props.validations
    };

    this.onAdd = this.onAdd.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  onRemove(validation) {
    const { validations, onChange } = this.props;
    let updated = { ...validations };
    delete updated[validation]

    onChange(updated);
  }

  onAdd(validation, params = []) {
    const { validations, onChange } = this.props;
    let updated = { ...validations };
    const key = get(validation, 'key');

    const validationParams = !has(updated, key)
      ? get(validation, 'defaultParams', params)
      : params;

    set(updated, key, validation.get(validationParams));

    onChange(updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { validations, component } = this.props;

    const options = getPossibleValidations(component)
      .filter(o => isUndefined(validations[o.key]))
      .map(o => (
        <MenuItem key={o.validation} onClick={() => this.onAdd(o)}>
          {o.label}
        </MenuItem>
      ));

    const items = compact(map(validations, (params, key) => {
      const validation = find(available, a => a.key === key);
      // In some cases (e.g. file meta) the validation can't be edited by the user.
      if (isUndefined(validation)) return;

      const ConfigurationForm = get(validation, 'ConfigurationForm');

      return (
        <ListGroupItem key={key}>
          <span className="pull-right">
            <i
              className="glyphicon glyphicon-remove"
              onClick={() => this.onRemove(key)}
              style={{ color: 'grey' }}
            />
          </span>
          {validation.label}
          {ConfigurationForm && (
            <ConfigurationForm
              component={component}
              validation={validation}
              onChange={(params) => this.onAdd(validation, params)}
            />
          )}
        </ListGroupItem>
      )
    }));

    return(
      <div>
        <div style={{ textAlign: 'left' }}>
          <DropdownButton
            id="field-validations"
            disabled={options.length < 1}
            title={<i className="glyphicon glyphicon-plus" />}
            style={{ backgroundColor: '#fff', borderColor: '#fff' }}
          >
            {options}
          </DropdownButton>
        </div>

        <ListGroup>
          {items}
        </ListGroup>
      </div>
    );
  }
}

FieldValidations.propTypes = {
  component: PropTypes.object.isRequired,
  validations: PropTypes.object
};

FieldValidations.defaultProps = {
  validations: {}
};

export default FieldValidations;

import { call, put } from 'redux-saga/effects';
import { get, isNil } from 'lodash';

import actions from '../actions/Actions';
import { getDatabase } from '../../../IndexDb';

const {
  MODULE_FULFILLED,
  TOGGLE_FAVOURITE_PENDING,
  TOGGLE_FAVOURITE_FULFILLED,
  TOGGLE_FAVOURITE_REJECTED
} = actions;

export default function* toggleFavourite(request) {
  const {
    dataKey,
    isFavourite
  } = request;

  yield put({ type: TOGGLE_FAVOURITE_PENDING, dataKey });

  try {
    const db = yield call(getDatabase);
    const table = db.table('module');
    const record = yield call(table.get, { id: dataKey });

    if (isNil(record)) {
      const error = new Error(`No module with id ${dataKey} found in index db!`);
      yield put({ type: TOGGLE_FAVOURITE_REJECTED, dataKey, error });

      return error;
    }

    const value = !isNil(isFavourite)
      ? isFavourite
      : !get(record, 'isFavourite', false);

    const payload = {
      ...record,
      isFavourite: value
    };

    yield call(table.put, payload);

    yield put({ type: MODULE_FULFILLED, dataKey, payload });
    yield put({ type: TOGGLE_FAVOURITE_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: TOGGLE_FAVOURITE_REJECTED, dataKey, error });

    return error;
  }
}

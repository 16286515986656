import { isNil } from 'lodash';
import Fuse from 'fuse.js';

const fuseOptions = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1
};

/**
 * Filter the given modules by the given search term.
 * Return modules if the search term is empty.
 *
 * @param   {Array}  modules     Array of modules to filter
 * @param   {String} searchTerm  Search term
 * @param   {String} language    User language
 *
 * @return  {Array} filtered Filtered modules
 */
export default function filterBySearchTerm({ modules = [], searchTerm, language = 'de' }) {
  if (isNil(searchTerm) || searchTerm.trim().length === 0) {
    return modules;
  }

  const options = {
    ...fuseOptions,
    keys: [`name.${language}`, `service.*.description.${language}`]
  };

  const fuse = new Fuse(modules, options);

  return fuse.search(searchTerm);
}

import { createSelector } from 'reselect';
import { getLanguage } from '../commonSelectors';
import { API_CLIENT_TEXT_BLOCKS_MAP } from '../../constants/ApiClientTextBlocks';
import { getApiClientTextBlocks } from './apiClientCommonSelectors';

export const getApiClientTexts = createSelector([
  getApiClientTextBlocks,
  getLanguage,
], (textBlocks, language) => {
  return Object.keys(API_CLIENT_TEXT_BLOCKS_MAP).reduce((result, key) => {
    const textBlock = textBlocks.find((it) => it.id === API_CLIENT_TEXT_BLOCKS_MAP[key]);
    return {
      ...result,
      [key]: textBlock && textBlock.text[language]
        ? textBlock.text[language]
        : '',
    };
  }, {});
});

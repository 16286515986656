import './FinancingRatesSyncButton.css';

import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export class FinancingRatesSyncButton extends React.Component {
  state = {
    isModalOpen: false,
  };

  constructor(props) {
    super(props);
    this.cancel = this.cancel.bind(this);
    this.confirm = this.confirm.bind(this);
    this.modalOpen = this.modalOpen.bind(this);
  }

  render() {
    return (
      <React.Fragment>
        <Button onClick={this.modalOpen}>
          <span className="icon-085-synchronise"/>
        </Button>
        {this.renderModal()}
      </React.Fragment>
    );
  }

  renderModal() {
    const { isModalOpen } = this.state;

    return (
      <Modal show={isModalOpen}
             onHide={this.cancel}
             className="forwards-sync-modal"
             backdropClassName="forwards-sync-modal__backdrop">
        <Modal.Body>
          Zins Synchronisation auf andere Raten mit gleicher Zeitperiode ausführen?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.confirm} bsStyle="primary">
            <span className="icon-012-check-mark-circle"/>
            Synchronisieren
          </Button>
          <Button onClick={this.cancel}>
            <span className="icon-202-clear-circle"/>
            Abbrechen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  modalOpen() {
    this.setState({ isModalOpen: true });
  }

  cancel() {
    this.setState({ isModalOpen: false });
  }

  confirm() {
    const { syncForwards } = this.props;
    this.setState({ isModalOpen: false });
    syncForwards();
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import _ from 'lodash';

import * as actions from '../../actions/FinancingLoanProductActions';

import Spinner from '../Spinner';
import Forwards from './Forwards';

class LoanProductDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      selForwardRate: 0,
      selDurationId: 0,
    };
  }

  componentWillMount() {
    this.props.dispatch(actions.getLoanProductDetails(this.props.productId));
  }

  selectForward(durationId, forwardRate) {
    this.setState({
      selDurationId: durationId,
      selForwardRate: forwardRate,
    });
  }

  detailTable() {
    const { selDurationId, selForwardRate } = this.state;
    const { libor } = this.props;
    const durations = _.sortBy(this.props.durations, ['durationInYears']);
    const liborRate = libor.liborRate ? parseFloat(libor.liborRate) * 100 : 0;
    _.set(durations[selDurationId], 'forward', selForwardRate);
    const details = durations.map((duration, i) => {
      const baseRate = parseFloat(duration.rate.baseRate) * 100;
      const refiRate = parseFloat(duration.refiRate.interestRate) * 100;
      const forward = duration.forward ? parseFloat(duration.forward) * 100 : 0;
      return (
        <tr key={i}>
          <td>{duration.rate.designation}</td>
          <td>{duration.durationInYears}</td>
          <td>{baseRate.toFixed(3)}</td>
          <td>{refiRate.toFixed(3)}</td>
          {duration.forwards.length > 0 ?
            <td style={{ width: '220px', textAlign: 'center' }}><Forwards
              id={i}
              forwards={duration.forwards}
              selectForward={this.selectForward.bind(this)}
            />
            </td> : null}
          {libor.liborRate ? <td>{liborRate.toFixed(3)}</td> : null}
          <td>{(baseRate + refiRate + liborRate + forward).toFixed(3)}</td>
        </tr>
      );
    });

    return (
      <Table striped bordered condensed hover>
        <thead>
          <tr>
            <th>Bezeichnung</th>
            <th>Laufzeit</th>
            <th>Basiszins</th>
            <th>Refi Zins</th>
            {this.props.durations.length > 0 && this.props.durations[0].forwards.length > 0 ?
              <th>Forward</th> : null}
            {libor.liborRate ? <th>Libor</th> : null}
            <th>Total Zins</th>
          </tr>
        </thead>
        <tbody>
          {details}
        </tbody>
      </Table>
    );
  }

  // embed the products in to the EditContainer component
  render() {
    // const coreTypes = this.state.coreTypes.map((coreType, i) => <div key={i}>{coreType.text.de}</div>)
    return (this.props.requesting ? <Spinner /> : this.detailTable());
  }
}

function mapStateToProps(state) {
  return {
    durations: state.financingLoanProducts.durations,
    libor: state.financingLoanProducts.libor,
    requesting: state.financingLoanProducts.requesting,
    language: state.login.language,
    hasError: state.financingLoanProducts.hasError,  
  }
}

export default connect(mapStateToProps)(LoanProductDetails);
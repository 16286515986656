import { call, put } from 'redux-saga/effects';

import axios from 'axios';
import actions from '../../actions/FeatureFlag/FeatureFlagForm';
import getFeatureFlags from './getFeatureFlags';

const {
  FEATURE_FLAG_FORM_SAVE_PENDING,
  FEATURE_FLAG_FORM_SAVE_FULFILLED,
  FEATURE_FLAG_FORM_SAVE_REJECTED
} = actions;

export default function* initFeatureFlagForm(request) {
  yield put({ type: FEATURE_FLAG_FORM_SAVE_PENDING });

  try {
    const {
      id,
      name,
      identifier,
      description,
      active = false
    } = request;

    const url = `/core/featureflag/${id}`;

    const payload = yield call(axios, {
      url,
      method: 'put',
      data: {
        name,
        identifier,
        description,
        active
      }
    });

    // Reload Data after Feature Flag save
    yield call(getFeatureFlags);

    yield put({ type: FEATURE_FLAG_FORM_SAVE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: FEATURE_FLAG_FORM_SAVE_REJECTED, error });

    return error;
  }
}

import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ANALYTICS_LOGS_COMPARISON,
  ANALYTICS_LOGS_DETAILS,
  ANALYTICS_LOGS_LOAD,
  ANALYTICS_LOGS_SEARCH,
} from '../../actions/AnalyticsLogsActions';
import { analyticsLogsLoadSaga } from './analyticsLogsLoadSaga';
import { analyticsLogsSearchSaga } from './analyticsLogsSearchSaga';
import { analyticsLogsDetailsSaga } from './analyticsLogsDetailsSaga';
import { analyticsLogsComparisonSaga } from './analyticsLogsComparisonSaga';

export function* analyticsLogsSagas() {
  yield takeLatest(ANALYTICS_LOGS_LOAD.REQUEST, analyticsLogsLoadSaga);
  yield takeEvery(ANALYTICS_LOGS_SEARCH.REQUEST, analyticsLogsSearchSaga);
  yield takeEvery(ANALYTICS_LOGS_DETAILS.REQUEST, analyticsLogsDetailsSaga);
  yield takeEvery(ANALYTICS_LOGS_COMPARISON.REQUEST, analyticsLogsComparisonSaga);
}

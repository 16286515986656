import Highcharts from 'highcharts';
import highchartsGantt from 'highcharts/modules/gantt';

/*
 Override `alignDataLabel` to place the label outside the bar if the bar's width less than the label's width
 */
highchartsGantt(Highcharts);
Highcharts.wrap(
  Highcharts.seriesTypes.gantt.prototype,
  'alignDataLabel',
  function () {
    const args = Array.prototype.slice.call(arguments);
    const proceed = args.shift();
    const point = args[0];

    proceed.apply(this, args);

    if (
      point.dataLabel &&
      point.dataLabel.options.adjustPosition &&
      point.shapeArgs &&
      point.dataLabel.width > point.shapeArgs.width
    ) {
      if (point.dataLabel.options.align === 'left') {
        point.dataLabel.xSetter(
          point.shapeArgs.x +
          point.shapeArgs.width +
          (point.dataLabel.padding || 0) / 2
        );
      }

      if (point.dataLabel.options.align === 'right') {
        point.dataLabel.xSetter(
          point.shapeArgs.x -
          point.dataLabel.width -
          (point.dataLabel.padding || 0) / 2
        );
      }
    }
  }
);

/*
 Override `translatePoint` to avoid the point shifting to the left if `minPointLength` is set
 */
Highcharts.wrap(
  Highcharts.seriesTypes.gantt.prototype,
  'translatePoint',
  function () {
    const args = Array.prototype.slice.call(arguments);
    const proceed = args.shift();
    const point = args[0];

    proceed.apply(this, args);

    const series = point.series;
    const options = series.options;
    const minPointLength = options.minPointLength || 0;

    if (minPointLength) {
      const xAxis = series.xAxis;
      const plotX = point.plotX;
      const posX = Highcharts.pick(point.x2, point.x + (point.len || 0));
      const plotX2 = xAxis.translate(posX, 0, 0, 0, 1);
      const length = Math.abs(plotX2 - plotX);
      const crisper = Highcharts.pick(options.borderWidth, 1) % 2 / 2;
      const widthDifference = Math.max(minPointLength - length, 0);
      const newPlotX2 = Math.min(
        Math.max(plotX2 + widthDifference, 0),
        xAxis.len,
      );
      point.shapeArgs.x = Math.floor(Math.min(plotX, newPlotX2)) + crisper;
    }
  }
);

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import Select from 'react-select';

const paths = {
  AccountSelect: 'value.accountType.id',
  Authorizations: 'value.contractType.id',
  ContractAccountSelection: 'value.contractType.id',
  Contracts: 'value.contractType.id',
  CustomerCards: 'value.cardType.id',
  CustomerContacts: 'value.variation.id',
  CustomerLsv: 'value.orderType.id',
  CustomerPortfolios: 'value.portfolioKind.id',
  CustomerRelations: 'value.relationType.id',
  PartnerContracts: 'value.contractType.id',
};

const optionFunctions = {
  //ProductGroupSelect: (target, language, values) => {}
  AccountSelect: (target, language, values) => _.get(values, `codes.accountType`, []).map(o => ({ value: o.id, label: _.get(o, `text.${language}`, '') })),
  ActivitySubStatus: (target, language, values) => _.get(values, `codes.activitySubStatus`, []).map(o => ({ value: o.id, label: _.get(o, `text.${language}`, '') })),
  Authorizations: (target, language, values) => _.get(values, `codes.contractType`, []).map(o => ({ value: o.id, label: _.get(o, `text.${language}`, '') })),
  CodeSelect: (target, language, values) => _.get(values, `codes.${_.get(target, 'props.group')}`, []).map(o => ({ value: o.id, label: _.get(o, `text.${language}`, '') })),
  ConsultantSelect: (target, language, values) => _.get(values, 'consultants', []).map(o => ({ value: _.get(o, 'id'), label: `${_.get(o, 'firstName')} ${_.get(o, 'lastName')}` })),
  ContractAccountSelection: (target, language, values) => _.get(values, `codes.contractType`, []).map(o => ({ value: o.id, label: _.get(o, `text.${language}`, '') })),
  Contracts: (target, language, values) => _.get(values, `codes.contractType`, []).map(o => ({ value: o.id, label: _.get(o, `text.${language}`, '') })),
  CustomerCards: (target, language, values) => _.get(values, `codes.cardType`, []).map(o => ({ value: o.id, label: _.get(o, `text.${language}`, '') })),
  CustomerContacts: (target, language, values) => _.get(values, `codes.contactVariation`, []).map(o => ({ value: o.id, label: _.get(o, `text.${language}`, '') })),
  CustomerLsv: (target, language, values) => _.get(values, `codes.paymentOrderType`, []).map(o => ({ value: o.id, label: _.get(o, `text.${language}`, '') })),
  CustomerPortfolios: (target, language, values) => _.get(values, `codes.portfolioKind`, []).map(o => ({ value: o.id, label: _.get(o, `text.${language}`, '') })),
  CustomerRelations: (target, language, values) => _.get(values, `codes.relationType`, []).map(o => ({ value: o.id, label: _.get(o, `text.${language}`, '') })),
  MultiSelect: (target, language, values) => _.get(target, 'props.options', []).map(o => ({ value: _.get(o, 'value'), label: _.get(o, `label.${language}`) })),
  OverwriteRecipient: (target, language, values) => _.get(target, 'props.options', []).map(o => ({ value: _.get(o, 'value'), label: _.get(o, `label.${language}`) })),
  PartnerContracts: (target, language, values) => _.get(values, `codes.contractType`, []).map(o => ({ value: o.id, label: _.get(o, `text.${language}`, '') })),
  SingleSelect: (target, language, values) => _.get(target, 'props.options', []).map(o => ({ value: _.get(o, 'value'), label: _.get(o, `label.${language}`) }))
};

function getOptions(target, language, values = {}) {
  const func = _.get(optionFunctions, `${_.get(target, 'component')}`, () => {
    console.warn('UNSUPPORTED COMPONENT');
    return [];
  });

  return func(target, language, values);
}

const FilterParams = ({ target, language, codes, consultants, productGroups, value, onChange, edit }) => {
  const options = getOptions(target, language, { codes, consultants, productGroups })

  function onParamsChange(selected) {
    const component = `${_.get(target, 'component')}`;
    const params = (selected) ? selected : [];

    return onChange(params.map(p => p.value), _.get(paths, component));
  }

  return (edit) ? (
    <FormGroup style={{ padding: '12px' }}>
      <ControlLabel>
        Werte
      </ControlLabel>

      <Select
        options={options}
        multi={true}
        value={value}
        onChange={onParamsChange}
      />
    </FormGroup>
  ) : (
    <div>{value.map(v => _.get(options.find(o => o.value === v), 'label', '')).join(', ')}</div>
  );
}

FilterParams.propTypes = {
  target: PropTypes.object.isRequired,
  language: PropTypes.string,
  action: PropTypes.string,
  codes: PropTypes.object,
  consultants: PropTypes.array,
  productGroups: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.array
};

FilterParams.defaultProps = {
  language: 'de',
  action: undefined,
  codes: {},
  consultants: [],
  productGroups: [],
  onChange: _.noop,
  value: []
};

export default FilterParams;

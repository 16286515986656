import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';
import { EndpointName } from '../../constants/EndpointName';
import { NETTED_ACCOUNTS_WORKGUIDES_UPDATE } from '../../actions/WorkguideStatusActions';
import update from 'immutability-helper';

export function* workguideStatusUpdateNettedAccountsWorkguidesSaga(action) {
  try {
    yield put(NETTED_ACCOUNTS_WORKGUIDES_UPDATE.pending());
    
    const workguides = get(action, 'data.workguides', []);
    const entityCodes = get(action, 'data.entityCodes', []);

    for (let workguide of workguides) {
        const { data: workguideToUpdate } = yield call(axios, { url: `${EndpointName.PROVISION_WORKGUIDE}/${workguide._id}` });
        
        const status = entityCodes.find(x => x.id === 'workguideStatus-6');
        const updatedWorkguide = update(workguideToUpdate, { status: { $set: status }})
        
        yield call(axios, {
          method: 'put',
          url: `${EndpointName.PROVISION_WORKGUIDE}/${updatedWorkguide.id}`,
          data: updatedWorkguide,
        });
    }

    yield put(NETTED_ACCOUNTS_WORKGUIDES_UPDATE.success());
  } catch (error) {
    yield put(NETTED_ACCOUNTS_WORKGUIDES_UPDATE.failure(error));
  }
}

import { put } from 'redux-saga/effects';

// ToDo: Move functions like handleNextHeaders to own repo
import localFiltersAction from '../../actions/ReplayAuditLog/LocalFilters';

const {
  SET_LOCAL_FILTERS_PENDING,
  SET_LOCAL_FILTERS_FULFILLED,
  SET_LOCAL_FILTERS_REJECTED
} = localFiltersAction;

export default function* setLocalFilters(request) {
  yield put({ type: SET_LOCAL_FILTERS_PENDING });

  try {
    const { payload } = request;

    yield put({ type: SET_LOCAL_FILTERS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: SET_LOCAL_FILTERS_REJECTED, error });

    return error;
  }
}

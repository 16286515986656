import { get, sumBy, reduce, last } from 'lodash';
import moment from 'moment';

export default function LoadObjectDataSet({ loadId, name, results, options = {} }) {
  const load = get(results, 'loads', []).find((l) => get(l, 'id') === loadId);
  const actions = get(load, `actionMap.${name}`, []).map((id) => get(results, 'actions', []).find((a) => get(a, 'id') === id));

  const stagerActions = actions.filter((a) => get(a, 'component') === 'ml3k.stager');
  const targetLoaderActions = actions.filter((a) => get(a, 'component') === 'ml3k.targetloader');

  const summedUp = {
    // fetchTimeAll: sumBy(stagerActions, 'metrics.fetchTimeAll'),
    // databaseWriteTime: sumBy(stagerActions, 'metrics.databaseWriteTime'),
    // sqlExecutionTime: sumBy(targetLoaderActions, 'metrics.sqlExecutionTime'),
    // mongoDBWriteTime: sumBy(targetLoaderActions, 'metrics.mongoDBWriteTime'),
    // mongoIndexCreationTime: sumBy(targetLoaderActions, 'metrics.mongoIndexCreationTime'),
    // mongoPostProcessingTime: sumBy(targetLoaderActions, 'metrics.mongoPostProcessingTime'),
    spoolingInitializeSeconds: sumBy(stagerActions, 'metrics.spoolingInitializeSeconds'),
    spoolingTimeSeconds: sumBy(stagerActions, 'metrics.spoolingTimeSeconds'),
    loadDataInfileTimeSeconds: sumBy(stagerActions, 'metrics.loadDataInfileTimeSeconds'),
    dataProviderInitializeSeconds: sumBy(targetLoaderActions, 'metrics.dataProviderInitializeSeconds'),
    dataProviderWorkingSeconds: sumBy(targetLoaderActions, 'metrics.dataProviderWorkingSeconds'),
    mongoDbPostProcessingSeconds: sumBy(targetLoaderActions, 'metrics.mongoDbPostProcessingSeconds'),
    mongoDbDatabaseWriteTimeSeconds: sumBy(targetLoaderActions, 'metrics.mongoDbDatabaseWriteTimeSeconds'),

  };

  const data = reduce(summedUp, (result, value = 0, key) => {
    result.push((last(result) + value));

    return result;
  }, [0]).map((f) => (f / 60).toFixed(2));

  return {
    label: moment(loadId, 'YYYYMMDDHHmmssSSS').format('DD.MM.YYYY HH:mm:ss'),
    fill: false,
    lineTension: 0,
    data,
    ...options
  };
}

import PropTypes from 'prop-types';

export const ConsultantsShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  department: PropTypes.string,
  division: PropTypes.string,
  title: PropTypes.string,
  contacts: PropTypes.arrayOf(PropTypes.object),
});

import './RefErrorsChart.css';

import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { uniqBy } from 'lodash';
import { Button, Modal } from 'react-bootstrap';
import { RefErrorsModalResults } from './RefErrorsModalResult';

export class RefErrorsTotalChart extends React.PureComponent {
  constructor(props){
    super(props)

    this.state = {
      isModalOpen: false,
      clickedElement: null
    }
  }

  render() {
    const { results } = this.props;
    if (!results) {
      return null;
    }
    const options = this.createOptions();
    if(!options) {
      return null;
    }

    return (
      <div className="ref-errors-chart">
        
          <HighchartsReact
            highcharts={Highcharts}
            constructorType="chart"
            options={options}
            immutable={true}
          />
          <div className="ref-errors-modal-container">
           {this.state.isModalOpen ? this.renderModal() : null}
          </div>
      </div>
    );
  }

  createOptions() {
    const data = this.compareData();
    // Sort data descending
    data.sort((a,b) => b.refErrors.length - a.refErrors.length)
    const this_ = this;
    return {
      chart: {
          type: 'bar',
          height: data.length * 40 + 160
      },
      title: {
          text: 'RefErrors'
      },

      xAxis: {
        categories: data.map(el => el.collection.collectionName),
        labels: {
          formatter: function() {
              const currentItem = data.find(el => el.collection.collectionName === this.value);
              if(!currentItem) return;
              return `${this.value} (${currentItem.refErrors.length})`;
          }
      }
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
            cursor: 'pointer',
            stacking: 'normal',
            point: {
              events: {
                  click: function () {
                    const currentItem = data.find(el => el.collection.collectionName === this.category);
                    this_.openModal(currentItem.collection.collectionName)
                  }
              }
          }
        }
    },
      series: [
        {
          name: "Reference Errors",
          data: data.map(el => ([el.collection.collectionName, el.refErrors.length !== 0 ? el.refErrors.length: 0.01])),
          dataSorting: {
            enabled: true
        },
        }
      ]

  }
  }
  compareData() {
    const { results} = this.props;
    const collections = uniqBy(results, (e) => e.collectionName)
    
    const data = collections.map(collection => {
    const collectionErrors = results.filter(ref => ref.collectionName === collection.collectionName)
     const reducedErrors = collectionErrors.reduce((prev, curr) => {
       prev.push({ ...curr.refErrors, id: curr.id })
       return prev
      },[])
    
      return {
        collection,
        refErrors: reducedErrors.map(el => el['0'])
      }
      
    })
    return data; 
  }

  renderModal() {
    const { isModalOpen, clickedElement } = this.state;
    const { results, locale } = this.props;
    const resultOptions = results.filter(el => el.collectionName === clickedElement);

    return (
      <Modal className="ref-error-modal" show={isModalOpen} onHide={this.closeModal}>
        <Modal.Body>
        <RefErrorsModalResults
            results={resultOptions}
            resultsTotalCount={resultOptions.length}
            locale={locale}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.closeModal} bsSize="sm">
            Schliessen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  closeModal = () => this.setState({ isModalOpen: false });
  openModal = (id) => this.setState({ isModalOpen: true, clickedElement: id })
}
RefErrorsTotalChart.propTypes = {
  results: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
};

import {
  ANALYTICS_USER_SUMMARY_LOAD,
  ANALYTICS_USER_SUMMARY_EXPORT,
} from '../actions/AnalyticsUserSummaryActions';

const initialState = {
  exporting: false,
  loading: false,
  error: undefined,
  banks: [],
};

export function AnalyticsUserSummaryReducer(state = initialState, action) {
  switch (action.type) {
    case ANALYTICS_USER_SUMMARY_LOAD.PENDING:
      return { ...state, loading: true, error: undefined };
    case ANALYTICS_USER_SUMMARY_LOAD.SUCCESS:
      const { banks } = action.data;
      return { ...state, loading: false, error: undefined, banks };
    case ANALYTICS_USER_SUMMARY_LOAD.FAILURE:
      return { ...state, loading: false, error: action.data };

    case ANALYTICS_USER_SUMMARY_EXPORT.PENDING:
      return { ...state, exporting: true, error: undefined };
    case ANALYTICS_USER_SUMMARY_EXPORT.SUCCESS:
      return { ...state, exporting: false, error: undefined };
    case ANALYTICS_USER_SUMMARY_EXPORT.FAILURE:
      return { ...state, exporting: false, error: action.data };

    default:
      return state;
  }
}

import './PermissionGroup.css';

import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { handleGravitonError } from '../../utils';
import PageContainer from '../Common/PageContainer';
import { PermissionGroupTable } from './PermissionGroupTable';
import { PermissionGroupSearchForm } from './PermissionGroupSearchForm';
import { PERMISSION_GROUP_PROP_TYPES_SEARCH_PARAMS } from './PermissionGroupPropTypes'

export class PermissionGroup extends React.Component {
  constructor(props) {
    super(props);
    props.load_consultant();
    props.load_entity_code();
  }

  render() {
    const { error, loading, consultants, entityCodes, search, searchParams } = this.props;
    if (error) {
      toast.error(handleGravitonError(error));
    }
    return (
      <PageContainer
        title={<FormattedMessage id="permissionGroup_title"/>}
        requesting={loading}
        isTransparentLoader={true}
      >
        <div className="permission-group">
          <div className="permission-group__search">
            <PermissionGroupSearchForm
              form="emailSubscriptionsSearchForm"
              search={search}
              loading={loading}
              items={consultants}
            />
          </div>

          <div className="permission-group__table">
            <PermissionGroupTable
              items={consultants}
              itemsTotalCount={consultants.length}
              search={search}
              searchParams={searchParams}
              codes={entityCodes}
            /> 
          </div>
        </div>
      </PageContainer>
    );
  }
}

PermissionGroup.propTypes = {
  search: PropTypes.func.isRequired,
  searchParams: PERMISSION_GROUP_PROP_TYPES_SEARCH_PARAMS,
  subscriptionTypeOptions: PropTypes.arrayOf(PropTypes.object),
  subscriptionStateOptions: PropTypes.arrayOf(PropTypes.object),
};

import axios from 'axios';

export const GET = 'adminUI/agenda/GET';
export const PUT = 'adminUI/agenda/PUT';
export const NEW = 'adminUI/agenda/NEW';
export const SET = 'adminUI/agenda/SET';

export function getAgendas() {
  return (dispatch) => {
    dispatch({
      type: GET,
      payload: axios({
        url: 'agenda/config/',
      }),
    });
  };
}

export function updateAgenda({ agenda }) {
  return (dispatch) => {
    dispatch({
      type: PUT,
      payload: axios({
        url: `agenda/config/${agenda.id}`,
        method: 'put',
        data: agenda,
      }),
    });
  };
}

export function setData({ key, value }) {
  return dispatch =>
    dispatch({
      type: SET,
      key,
      value,
    });
}

import React from 'react';
import PropTypes from 'prop-types';
import { has, get, set, defaultsDeep, isUndefined, noop, isEmpty } from 'lodash';
import { FormGroup, FormControl, Row, Col, Button } from 'react-bootstrap';
import { flattenValidationResult } from '@ultritium/validate';

import './Form.css';
import { getValidator } from '../../globals';

const Title = React.memo(({ children }) => {
  return (
    <div>
      <strong>
        {children}
      </strong>
    </div>
  );
});

const ValidationResult = ({ validations }) => {
  const flat = flattenValidationResult(validations);

  return (
    <div className="validation-result">
      {flat.map((validation) => (
        <div key={get(validation, 'key')} className="validation-result-item">
          {get(validation, 'message')}
        </div>
      ))}
    </div>
  );
};

class CodeForm extends React.Component {
  constructor(props) {
    super(props);

    const defaults = {
      id: `${get(props, 'group')}-`,
      recordOrigin: 'map',
      text: {},
      textShort: {}
    };
    const data = get(props, 'data', {});

    this.state = {
      data: defaultsDeep({}, data, defaults),
      disabled: {
        id: !isUndefined(get(data, 'id')),
        number: !isUndefined(get(data, 'number'))
      }
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onChange(path, ev) {
    const { data } = this.state;
    const value = get(get(ev, 'target.value', '').trim(), 'length', 0) > 0
      ? get(ev, 'target.value', '')
      : undefined;

    const updated = { ...data };
    set(updated, path, value);

    this.setState({ data: updated });
  }

  onSubmit() {
    const { data } = this.state;
    const { languages, onSubmit } = this.props;

    const defaults = {
      text: languages.reduce((result, language) => {
        result[language] = get(data, 'text.de');
        return result;
      }, {}),
      textShort: languages.reduce((result, language) => {
        result[language] = get(data, 'textShort.de');
        return result;
      }, {}),
      recordOrigin: 'map'
    };

    onSubmit(defaultsDeep({}, data, defaults));
  }

  onCancel() {
    const { data, onCancel } = this.props;

    this.setState({ data });

    onCancel();
  }

  validate() {
    const { data, disabled } = this.state;
    const { codes, group } = this.props;
    const validator =  getValidator();

    const idValidation = get(disabled, 'id', false) ? 'noop' : 'isValidCodeId';
    const numberValidation = get(disabled, 'number', false) ? 'noop' : 'isValidCodeNumber';

    const definition = {
      validations: {
        id: {
          type: 'string',
          required: true,
          validations: {
            [idValidation]: [get(codes, group, []), group]
          }
        },
        number: {
          type: 'string',
          required: true,
          validations: {
            [numberValidation]: [get(codes, group, []), group]
          }
        },
        text: {
          type: 'object',
          validations: {
            de: {
              type: 'string',
              required: true
            }
          }
        },
        textShort: {
          type: 'object',
          required: true,
          validations: {
            de: {
              type: 'string',
              required: true
            }
          }
        }
      }
    };

    return validator.validate(definition, data);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { data, disabled } = this.state;
    const { languages } = this.props;
    const validations = this.validate();

    return (
      <div className="code-form">
        <Row>
          <Col lg={6} md={6} sm={12}>
            <FormGroup>
              <Title>
                ID
              </Title>

              <FormControl
                type="text"
                value={get(data, 'id', '')}
                onChange={(ev) => this.onChange('id', ev)}
                disabled={get(disabled, 'id', false)}
              />

              <ValidationResult validations={validations.id} />
            </FormGroup>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <FormGroup>
              <Title>
                Nummer
              </Title>

              <FormControl
                type="text"
                value={get(data, 'number', '')}
                onChange={(ev) => this.onChange('number', ev)}
                disabled={get(disabled, 'number', false)}
              />

              <ValidationResult validations={validations.number} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          {languages.map((l) => {
            return (
              <Col key={l} lg={4} md={4} sm={1}>
                <FormGroup>
                  <Title>
                    Text {l}
                  </Title>

                  <FormControl
                    type="text"
                    value={get(data, `text.${l}`, '')}
                    onChange={(ev) => this.onChange(`text.${l}`, ev)}
                  />

                  {has(validations, `text.${l}`) && (
                    <ValidationResult validations={get(validations, `text.${l}`, {})} />
                  )}
                </FormGroup>
              </Col>
            );
          })}
        </Row>

        <Row>
          {languages.map((l) => {
            return (
              <Col key={l}  lg={4} md={4} sm={1}>
                <FormGroup>
                  <Title>
                    Text kurz {l}
                  </Title>

                  <FormControl
                    type="text"
                    value={get(data, `textShort.${l}`, '')}
                    onChange={(ev) => this.onChange(`textShort.${l}`, ev)}
                  />

                  {has(validations, `textShort.${l}`) && (
                    <ValidationResult validations={get(validations, `textShort.${l}`, {})} />
                  )}

                </FormGroup>
              </Col>
            );
          })}
        </Row>

        <Row style={{ textAlign: 'right' }}>
          <div className="code-form-buttons">
            <div style={{ paddingRight: '10px'}}>
              <Button
                bsStyle="primary"
                onClick={this.onSubmit}
                disabled={!isEmpty(validations)}
              >
                Speichern
              </Button>
            </div>

            <div>
              <Button onClick={this.onCancel}>
                Verwerfen
              </Button>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}

CodeForm.propTypes = {
  data: PropTypes.object.isRequired,
  languages: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

CodeForm.defaultProps = {
  languages: ['de', 'fr', 'en'],
  onSubmit: noop,
  onCancel: noop
};

export default CodeForm;

const initialCount = 3;
const countInRow = 4;

export function FilterPromotionsReducer(state, action) {
  const { mode } = action.data;
  return {...state, filter: {...state.filter, show: mode, displayCount: initialCount}};
}

export function ShowMorePromotionsReducer(state) {
  const newCount = state.filter.displayCount + countInRow;
  const maxItems = state.promotions.length;

  return {
    ...state,
    filter: {...state.filter, displayCount: Math.max(newCount, maxItems)},
  };
}

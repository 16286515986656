import { SAVE_SURCHARGE_REALESTATETYPE_ACTIONS } from '../../actions/FinancingSurchargeRealestatetypeActions';

export function SaveFinancingSurchargeRealestatetypeReducer(state, action) {
  switch (action.type) {
    case SAVE_SURCHARGE_REALESTATETYPE_ACTIONS.PENDING:
      return {...state, loading: true, error: undefined};
    case SAVE_SURCHARGE_REALESTATETYPE_ACTIONS.SUCCESS:
      return {...state, loading: false, error: undefined};
    case SAVE_SURCHARGE_REALESTATETYPE_ACTIONS.FAILURE:
      return {...state, loading: false, error: action.data};
    default:
      return state;
  }
}

import { Actions } from '@evoja-web/redaction';

import { configAction } from './Config';
import { consultantsAction } from './Consultants';
import { editFormAction } from './EditForm';
import { leadsAction } from './Leads';
import { locationsAction } from './Locations';
import { productsAction } from './Products';
import { regionsAction } from './Regions';
import { searchFormAction } from './SearchForm';
import { selectedLeadsAction } from './SelectedLeads';
import { updateLeadAction } from './UpdateLead';

export const actions = Actions([
  configAction,
  consultantsAction,
  editFormAction,
  leadsAction,
  locationsAction,
  productsAction,
  regionsAction,
  searchFormAction,
  selectedLeadsAction,
  updateLeadAction
]);

export default actions.get();

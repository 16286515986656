import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import { filter, get } from 'lodash';
import { List } from 'react-virtualized';
import { FormControl, Form, ProgressBar } from 'react-bootstrap';
import Fuse from 'fuse.js'
import { FormattedMessage } from 'react-intl';

import EditContainer from '../components/EditContainer';
import * as leadSwitchActions from '../actions/LeadSwitch';
import {
  LEADS_SWITCH_IMPORT_CSV,
  LEADS_SWITCH_IMPORT_IMPORT,
} from '../actions/LeadsSwitchImportActions';
import Item from '../components/LeadSwitch/Item';
import { Tabs } from '../components/Common/Tabs';
import Import from '../components/LeadSwitch/Import';

class LeadSwitch extends React.Component {
  constructor() {
    super();
    this.changeValue = this.changeValue.bind(this);
    this.saveData = this.saveData.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);

    this.state = {
      filterValue: "",
      from: 0,
      to: 0,
      selectedTab: 'manual',
    }
  }

  componentWillMount() {
    this.props.actions.leadSwitchRequest();
  }


  setFilterValue = (event) => {
    this.setState({
      filterValue: event.target.value
    });
    this.myList.forceUpdateGrid();
  }


  renderItem = () => {
    const leads = this.filterList();
    return (
      leads.map((o,i) => <Item key={o.id} transfer={true} index={i} item={o} changeValue={this.changeValue} />)
    );
  }

  rowRenderer = ({key, index, style}) => {
    return (
      <div
        key={key}
        style={style}
      >
        {this.renderItem()[index]}
      </div>
    )
  }

  filterList = () => {
    const { language, leads } = this.props;
    if (this.state.filterValue.length > 0) {
      const options = {
        shouldSort: true,
        threshold: 0.3,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          `customerTaskType.text.${language}`,
          "customer.customerNumber",
          "customer.designation"
        ]
      }
      const fuse = new Fuse(leads, options);
      return(fuse.search(this.state.filterValue, options));
    } else {
      return leads;
    }
  };

  changeValue(input) {
    const { actions } = this.props;
    switch (input.field) {
      case "from":
        this.setState({from: input.value});
        actions.leadSwitchRequest({ staffMemberId: input.value });
        break;
      case "to":
        this.setState({to: input.value});
        actions.setTarget({ staffMemberId: input.value })
        break;
      case "transfer":
        actions.setTransfer({leadId: input.item.id, transferState: input.value});
        this.myList.forceUpdateGrid();
        break;
      default:
    }
  }

  tabs() {
    const { selectedTab } = this.state;
    const { onCsv, onImport, csvResult, importResult } = this.props;

    return (
      <div>
        <Tabs
          items={[
            { key: 'manual', label: 'Manual' },
            { key: 'import', label: 'Import' },
          ]}
          selected={selectedTab}
          onChange={this.onChangeTab}
        />
        <div>
          {selectedTab === 'manual' && (
            <React.Fragment>
              {this.leadSwitchManual()}
            </React.Fragment>
          )}
          {selectedTab === 'import' && (
            <React.Fragment>
              <Import
                onCsv={onCsv}
                onImport={onImport}
                csvResult={csvResult}
                importResult={importResult}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );

  }

  onChangeTab(selectedTab) {
    this.setState({ selectedTab });
  }


  leadSwitchManual() {
    const { consultants, leads, progressIndex, progressTotal, inProgress } = this.props;

    const search = (
      <Form inline style={{ display: 'inline', position: 'relative', float: 'right' }}>
        <FormControl
          style={{ marginLeft: 20, display: "inline" }}
          placeholder="Suchbegriff eingeben"
          onChange={this.setFilterValue}
          value={this.state.filterValue}
        />
      </Form>
    );

    const now = 100 / progressTotal * (progressIndex + 1);
    return inProgress ?
      (
        <div style={{ margin: 15 }}>
          <FormattedMessage id="leadswitch_please_wait"/>
          <ProgressBar active now={now} />
          <p>{progressIndex + 1} / {progressTotal}</p>
        </div>) :
      (
        <div>
          <div style={{
            height: 60,
            display: 'flex',
            alignItems: 'center',
            alignContent: 'flex-start',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}>
            <div
              style={{ flex: 0, marginLeft: 20, minWidth: 300 }}
            >
              <Select
                placeholder="Bitte Kundenberater auswählen"
                value={this.state.from}
                options={consultants.map(o => ({ label: `${o.lastName} ${o.firstName} (${o.username})`, value: o.id }))}
                onChange={(value) => this.changeValue({ value: get(value, 'value') ? value.value : "", field: "from" })}
                closeOnSelect={true}
              />
            </div>
            <div
              style={{ flex: 0, marginLeft: 20, minWidth: 30 }}
            >
            </div>
            <div
              style={{ flex: 0, marginLeft: 20, minWidth: 300 }}
            >
              <Select
                placeholder="Bitte Ziel-Kundenberater auswählen"
                value={this.state.to}
                options={consultants.map(o => ({ label: `${o.lastName} ${o.firstName} (${o.username})`, value: o.id }))}
                onChange={(value) => this.changeValue({ value: get(value, 'value') ? value.value : "", field: "to" })}
                closeOnSelect={true}
              />
            </div>
            <div
              style={{ flex: '2 1 0%', marginLeft: 20, minWidth: 300 }}
            >
              {search}
            </div>

          </div>
          <div style={{
            height: 60,
            display: 'flex',
            alignItems: 'center',
            alignContent: 'flex-start',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
          >
            <div style={{ marginLeft: 20, minWidth: 80 }} ><strong>Übertragen</strong></div>
            <div style={{ flex: 0, marginLeft: 20, minWidth: 100 }}><strong>Partner Nr.</strong></div>
            <div style={{ flex: 0, marginLeft: 20, minWidth: 150 }}><strong>Kunde</strong></div>
            <div style={{ flex: 0, marginLeft: 20, minWidth: 150 }}><strong>Berater</strong></div>
            <div style={{ flex: 0, marginLeft: 20, minWidth: 100 }}><strong>Status</strong></div>
            <div style={{ flex: 0, marginLeft: 20, minWidth: 200 }}><strong>Typ</strong></div>
            <div style={{ flex: 0, marginLeft: 20, minWidth: 100 }}><strong>Art</strong></div>
            <div style={{ flex: 0.3, marginLeft: 20 }}><strong>Notiz</strong></div>
          </div>
          <hr style={{ margin: 0 }} />
          <List
            ref={ref => (this.myList = ref)}
            width={1300}
            height={300}
            rowCount={leads.length}
            rowHeight={61}
            rowRenderer={this.rowRenderer}
          />
        </div>
      )
  }

  saveData() {
    const { actions, staffMemberIdTo, session } = this.props;
    const { selectedTab } = this.state;

    const leadIds = filter(this.filterList(), (o) => o.transfer).map(o => ({ id: o.id, staffMemberId: o.staffMemberId, additionalEditor: o.additionalEditor }));

    actions.leadSwitchRequest({ leadIds, staffMemberIdTo, consultantId: get(session, 'id'), leadSwitchType: selectedTab });
    this.myList.forceUpdateGrid();
  }

  render() {
    const { requesting, staffMemberIdFrom, staffMemberIdTo } = this.props;

    return(
      <EditContainer
        requesting={requesting}
        title={<div><span>Leads einem neuen Berater zuweisen</span></div>}
        body={this.tabs()}
        saveData={this.saveData}
        valid={staffMemberIdFrom !== staffMemberIdTo && staffMemberIdFrom !== "" && staffMemberIdTo !== ""}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    consultants: state.leadswitch.consultants,
    leads: state.leadswitch.leads,
    staffMemberIdTo: state.leadswitch.staffMemberIdTo,
    staffMemberIdFrom: state.leadswitch.staffMemberIdFrom,
    progressIndex: state.leadswitch.progressIndex,
    progressTotal: state.leadswitch.progressTotal,
    inProgress: state.leadswitch.inProgress,
    requesting: state.leadswitch.requesting,
    language: state.login.language,
    hasError: state.leadswitch.hasError,
    error: state.leadswitch.error,
    csvResult: state.leadsSwitchImportCsv,
    importResult: state.leadsSwitchImportImport,
    session: state.login.session
  }
};

function mapDispatchToProps(dispatch) {

  return {
    actions: bindActionCreators(leadSwitchActions, dispatch),
    onCsv: (file) => dispatch(LEADS_SWITCH_IMPORT_CSV.request({ file })),
    onImport: (rows) => dispatch(LEADS_SWITCH_IMPORT_IMPORT.request({ rows }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadSwitch);

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { EmailSubscriptions } from '../components/EmailSubscriptions/EmailSubscriptions';
import { getLanguage, getLoginState } from '../selectors/commonSelectors';
import { EMAIL_SUBSCRIPTIONS_LOAD, EMAIL_SUBSCRIPTIONS_REMOVE_MARKETING, EMAIL_SUBSCRIPTIONS_SEARCH, EMAIL_SUBSCRIPTIONS_IMPORT_CSV } from '../actions/EmailSubscriptionsActions';
import {
  getCsvUploadResult,
  getDeletedMarketingRecords,
  getEmailSubscriptionsError,
  getEmailSubscriptionsItemsCount,
  getEmailSubscriptionsLoading,
  getEmailSubscriptionsSearchParams,
  getEmailSubscriptionsItemsRaw
  } from '../selectors/EmailSubscriptions/emailSubscriptionsCommonSelectors';
import { getEmailSubscriptionsItems } from '../selectors/EmailSubscriptions/emailSubscriptionsItemsSelector';
import {
  getEmailSubscriptionsStateOptions,
  getEmailSubscriptionsTypeOptions,
} from '../selectors/EmailSubscriptions/emailSubscriptionsOptionsSelector';
import { injectIntl } from 'react-intl';


const mapStateToProps = createStructuredSelector({
  loading: getEmailSubscriptionsLoading,
  error: getEmailSubscriptionsError,
  locale: getLanguage,
  items: getEmailSubscriptionsItems,
  itemsTotalCount: getEmailSubscriptionsItemsCount,
  searchParams: getEmailSubscriptionsSearchParams,
  subscriptionTypeOptions: getEmailSubscriptionsTypeOptions,
  subscriptionStateOptions: getEmailSubscriptionsStateOptions,
  deletedMarketing: getDeletedMarketingRecords,
  csvUploadResult: getCsvUploadResult,
  creator: getLoginState,
  rawData: getEmailSubscriptionsItemsRaw
});

const mapDispatchToProps = {
  load: () => EMAIL_SUBSCRIPTIONS_LOAD.request(),
  search: (options) => EMAIL_SUBSCRIPTIONS_SEARCH.request(options),
  deleteMarketing: () => EMAIL_SUBSCRIPTIONS_REMOVE_MARKETING.request(),
  onCsv: (file, creator) => EMAIL_SUBSCRIPTIONS_IMPORT_CSV.request({ file, creator }),
};

export const EmailSubscriptionsPage = connect(mapStateToProps, mapDispatchToProps)(injectIntl(EmailSubscriptions));

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cronstrue from 'cronstrue/i18n';
import { Button, Modal } from 'react-bootstrap';
import { get, isUndefined } from 'lodash';
import { FormattedMessage } from 'react-intl';
import ReactJson from 'react-json-view';

import './JobProcessingLoadCard.css';

const JobProcessingLoadCard = ({ action, condition, id, name, periodicity, source, type, language, interactive }) => {
  const [showModal, setShowModal] = useState(false);
  const colorActionList = [
    { action: 'post', color: '#f3a91b' },
    { action: 'get', color: '#0099E0' },
    { action: 'put', color: '#7252ac' },
    { action: 'delete', color: '#f22d54' },
  ];
  return (
    <div className="card">
      <div className="row-one">
        <p className="card-title">{type}</p>
        <p className="action" style={{ backgroundColor: colorActionList.find((el) => el.action === action).color }}>
          {action}
        </p>
      </div>
      <div className="row-two">
        <p className="card-label">
          <FormattedMessage id="jobProcessing_description" />:
        </p>
        <p style={{ fontSize: 14 }}>{get(name, language)}</p>
      </div>

      <div className="row-three">
        {!isUndefined(periodicity) && <p>{cronstrue.toString(periodicity, { locale: language, verbose: true })}</p>}
      </div>
      <i
        className="icon-046-information-circle detail-icon"
        onClick={() => {
          setShowModal(true);
        }}
      ></i>

      <div className="static-modal">
        <Modal show={showModal}>
          <Modal.Header>
            <Modal.Title>{type}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="modal-row">
              <p className="card-label">Id:</p>
              <p>{id}</p>
            </div>

            <div className="modal-row">
              <p className="card-label">
                <FormattedMessage id="jobProcessing_description" />:
              </p>
              <p>{get(name, language, '')}</p>
            </div>

            <div className="modal-row">
              <p className="card-label">Action:</p>
              <p>{action}</p>
            </div>

            <div className="modal-row">
              <p className="card-label">Condition expression:</p>
              <p>{get(condition, 'expression', '')}</p>
            </div>

            <div className="modal-row">
              <p className="card-label">Condition name:</p>
              <p>{get(condition, 'name', '')}</p>
            </div>

            <div className="modal-row">
              <p className="card-label">Source:</p>
              <p>{source}</p>
            </div>
            <div className="modal-row" style={{ marginTop: 10, marginBottom: 10 }}>
              <p className="card-label">Interactive:</p>
              <ReactJson
                src={interactive}
                name={false}
                displayDataTypes={false}
                collapsed={2}
                indentWidth={2}
                iconStyle="triangle"
                onAdd={false}
                onEdit={false}
                onDelete={false}
                enableClipboard={false}
              />
            </div>

            <div className="modal-row">
              <p className="card-label">Periodicity:</p>
              {!isUndefined(periodicity) ? (
                <p>
                  {periodicity} ({cronstrue.toString(periodicity, { locale: language, verbose: true })})
                </p>
              ) : null}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => {
                setShowModal(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

JobProcessingLoadCard.propTypes = {
  action: PropTypes.string,
  condition: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.object,
  periodicity: PropTypes.string,
  source: PropTypes.string,
  type: PropTypes.string,
};

export default JobProcessingLoadCard;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { getAccount, getWorkguideStatusLoading, getAccountWorkguides } from '../../selectors/WorkguideStatus/workguideStatusCommonSelectors';
import { ACCOUNT_SEARCH_LOAD, ACCOUNT_WORKGUIDES_LOAD, ACCOUNT_WORKGUIDES_CLEAR } from '../../actions/WorkguideStatusActions';

const AccountSearchForm = props => {
    const [query, setQuery] = useState('');

    const { account, workguides, loading, load_account_workguides, load_account_search, clear_account_workguides } = props;

    const search = (q) => {
        setQuery(q);
        load_account_search({ numberFormatted: q });
        clear_account_workguides()
    }

    const getValidation = () => {
        if (loading) {
            return null;
        }
        if (get(account, 'data', undefined)) {
            return 'success';
        } else {
            return 'error';
        }
    }

    if (get(account, 'data', undefined) && !get(account, 'loading', false) &&
        !get(workguides, 'data', undefined) && !get(workguides, 'loading', false)) {
        load_account_workguides({ accountId: get(account, 'data.id', undefined) });
    }

    return (
        <div>
            <form>
                <FormGroup
                    validationState={getValidation()}
                >
                    <ControlLabel><FormattedMessage id="workguideStatus_accountNumber" />:</ControlLabel>
                    <FormControl
                        type="text"
                        value={query}
                        onChange={(e) => search(e.target.value)}
                    />
                    <FormControl.Feedback />
                </FormGroup>
            </form>
        </div>
    );
};

AccountSearchForm.propTypes = {

};

AccountSearchForm.defaultProps = {
    account: undefined,
}

const mapStateToProps = createStructuredSelector({
    account: getAccount,
    workguides: getAccountWorkguides,
    loading: getWorkguideStatusLoading
});

const mapDispatchToProps = {
    load_account_search: (data) => ACCOUNT_SEARCH_LOAD.request(data),
    load_account_workguides: (data) => ACCOUNT_WORKGUIDES_LOAD.request(data),
    clear_account_workguides: (data) => ACCOUNT_WORKGUIDES_CLEAR.request()
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSearchForm);
import { isEqual } from 'lodash';
import memoizeOne from 'memoize-one';

export function getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++ ) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function getLoadColors(loadIds = []) {
  return loadIds.reduce((result, id) => {
    result[id] = getRandomColor();

    return result;
  }, {})
}

export const getMemoizedLoadColors = memoizeOne(getLoadColors, isEqual);

import { createSelector } from 'reselect';
import { getProducts } from './getBasicServices';
import { getRemovedProducts } from './getRemovedProducts';

export const getExistingProducts = createSelector([
  getProducts,
  getRemovedProducts,
], (products, removedProducts) => {
  return products.filter((product) => !removedProducts.some((it) => it.id === product.id));
});

import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@evoja-web/react-form';
import { chain, get, isArray, noop } from 'lodash';

const ProductSelect = React.memo(({
  codeMappings,
  id,
  language,
  multi,
  onChange,
  products,
  value
}) => {
  const ids = chain(codeMappings)
    .get('data', [])
    .find((m) => m.id === 'noteProduct', {})
    .get('mapping', [])
    .map((m) => get(m, 'value'))
    .flatten()
    .compact()
    .uniq()
    .value();

  const options = chain(products)
    .get('data', [])
    .filter((p) => ids.includes(p.id))
    .map((p) => ({ value: p.id, label: get(p, `text.${language}`), product: p }))
    .value();

  const v = isArray(value)
    ? value.map((v) => v.id)
    : get(value, 'id');

  return (
    <Select
      id={id}
      multi={multi}
      onChange={onChange}
      options={options}
      returnValue="product"
      value={v}
      virtualized
    />
  );
});

ProductSelect.propTypes = {
  codeMappings: PropTypes.array,
  id: PropTypes.string.isRequired,
  language: PropTypes.string,
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  products: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
};

ProductSelect.defaultProps = {
  codeMappings: {},
  language: 'de',
  multi: false,
  onChange: noop,
  products: {},
  value: undefined
};

export default ProductSelect;

import axios from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { API_CLIENT_LOAD_SERVICE } from '../../actions/ApiClientActions';
import { getApiClientEndpoints } from '../../selectors/ApiClient/apiClientCommonSelectors';

export function* apiClientLoadServiceSaga(action) {
  try {
    yield put(API_CLIENT_LOAD_SERVICE.pending());
    const { service } = action.data;
    const endpoints = yield select(getApiClientEndpoints);
    const endpoint = endpoints.find((it) => it.url === service);
    const { data: schema } = yield call(axios, { url: endpoint.schema });
    yield put(API_CLIENT_LOAD_SERVICE.success({ schema }));
  } catch (error) {
    yield put(API_CLIENT_LOAD_SERVICE.failure(error));
  }
}

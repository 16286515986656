import { call } from 'redux-saga/effects';
import { removeHost, prepareGravitonRef } from '@evoja-web/redux-saga-utils';
import { get } from 'lodash';

/**
 * Get available endpoints from graviton
 *
 * @param   {Axios}  axiosInstance  Axios instance to use
 *
 * @return  {Array}  endpoints Array of services from /
 */
export default function* getServices({ axiosInstance }) {
  try {
    const { data } = yield call(axiosInstance, {
      url: '/',
      method: 'get'
    });

    const services = get(data, 'services', []).map((e) => ({
      name: removeHost(e.$ref),
      url: prepareGravitonRef(e.$ref, axiosInstance.defaults.baseURL),
      uri: prepareGravitonRef(e.$ref),
      schema: e.profile
    }));

    return services;
  } catch (error) {
    throw new Error(`Unable to load available services from "${axiosInstance.defaults.baseURL}"`, { cause: error });
  }
}

import { chainReducers } from '../../utils';
import { consultantsFetchReducer } from './consultantsFetchReducer';
import { consultantsCreateReducer } from './consultantsCreateReducer';
import { consultantsUpdateReducer } from './consultantsUpdateReducer';
import { consultantsDeleteReducer } from './consultantsDeleteReducer';

const initialState = {
  data: null,
  loading: false,
  saving: false,
  error: null,
};

export const consultantsReducer = chainReducers(
  (state = initialState) => state,
  consultantsFetchReducer,
  consultantsCreateReducer,
  consultantsUpdateReducer,
  consultantsDeleteReducer,
);

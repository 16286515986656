export function UiNotesFormValidator(formData) {
  switch (formData.typeId) {
    case 'userInterfaceNoteType-1':
      return {
        text: !formData.text,
        title: !formData.title,
        publishDate: !formData.publishDate,
      };

    case 'userInterfaceNoteType-2':
      return {
        text: !formData.text,
      };

    default:
      return {
        typeId: true,
      };
  }
}

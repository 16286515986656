import update from 'immutability-helper';
import { chain, get, size } from 'lodash';

/**
 * Update the isConditional flag on the given nodes.
 *
 * @param   {Array}  nodes  Array of nodes to update
 *
 * @return  {Array} nodes Updated nodes
 */
export default function updateIsConditional(nodes = []) {
  return nodes.reduce((result, node, index) => {
    const connections = chain(node)
      .get('connections', [])
      .map((connection) => {
        return {
          ...connection,
          isConditional: size(get(node, 'connections', [])) > 1
        };
      })
      .value();

    return update(result, { [index]: { connections: { $set: connections } } });
  }, nodes);
}

import React from 'react';
import { get } from 'lodash';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';

import DynamicPropsList from '../DynamicProps/List';

class PdfOpeningFZ extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      id,
      item,
      editValueField
    } = this.props;

    return (
      <React.Fragment>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Dynamische Properties
          </Col>

          <Col sm={9}>
            <DynamicPropsList
              id={id}
              editValueField={editValueField}
              mapping={get(item, 'props.dynamicProps', [])}
            />
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}
 PdfOpeningFZ.propTypes = {

};
 PdfOpeningFZ.defaultProps = {

};

export default PdfOpeningFZ;

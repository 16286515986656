import { Actions } from '@evoja-web/redaction';

import { codeMappingsAction } from './CodeMapping/CodeMappings';
import { formAction } from './CodeMapping/Form';
import { saveAction } from './CodeMapping/Save';

export const actions = Actions([
  codeMappingsAction,
  formAction,
  saveAction
]);

export default actions.get();

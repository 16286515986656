import './RefErrorsResultsItem.css';

import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { TableCell, TableRow } from '../Common/Table';
import { REF_ERRORS_PROP_TYPES_RESULT_ITEM } from './RefErrorsPropTypes';
import { ModalBody, ModalDialog, ModalFooter, ModalHeader } from '../Common/Modal';
import { Button } from '../Common/Button';
import { RefErrorsResultsDetails } from './RefErrorsResultsDetails';
import { FormattedGravitonDate } from '../FormattedGravitonDate';
import { DATE_TIME_FORMAT } from '../../constants/DateTime';

export class RefErrorsResultsItem extends React.Component {
  state = {
    isModalOpen: false,
  };

  render() {
    const { item } = this.props;
    return (
      <TableRow className="ref-errors-results-item">
        <TableCell className="ref-errors-results-item__id">
          {item.id}
        </TableCell>
        <TableCell className="ref-errors-results-item__origin">
          {item.collectionName} {item.recordId}
        </TableCell>
        <TableCell className="ref-errors-results-item__date">
          {item.loadedAt ? <FormattedGravitonDate value={item.loadedAt} format={DATE_TIME_FORMAT}/> : null}
        </TableCell>
        <TableCell className="ref-errors-results-item__target">
          {this.renderRefErrors()}
          {this.renderModal()}
        </TableCell>
      </TableRow>
    );
  }

  renderRefErrors() {
    const { item } = this.props;
    return item.refErrors.map((it) => (
        <div className="ref-errors-results-item__target-item" key={it.targetId}>
          <div>{it.targetCollection} {it.targetId}</div>
          <div className="ref-errors-results-item__icon icon-046-information-circle"
               onClick={() => this.openModal(it.targetId)}/>
        </div>
      ),
    );
  }

  renderModal() {
    const { item } = this.props;
    const { isModalOpen, targetId } = this.state;
    if (!targetId) {
      return null;
    }

    const refError = item.refErrors.find((it) => it.targetId === targetId);
    if (!refError) {
      return null;
    }

    return (
      <ModalDialog show={isModalOpen} onHide={this.closeModal}>
        <ModalHeader closeButton={true}>
          <div>
            <b>{item.loadId}</b>{' '}
            <Link to={`/bulkloads/${encodeURIComponent(item.loadId)}`} className="ref-errors-results-item__link">
              <span className="icon-048-link"/>
            </Link>
          </div>
          <div>
            {item.loadedAt ? <FormattedGravitonDate value={item.loadedAt} format={DATE_TIME_FORMAT}/> : null}
          </div>
        </ModalHeader>
        <ModalBody>
          <RefErrorsResultsDetails item={item} refError={refError}/>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.closeModal} bsSize="sm">
            <FormattedMessage id="common_close"/>
          </Button>
        </ModalFooter>
      </ModalDialog>
    );
  }

  openModal = (targetId) => this.setState({ isModalOpen: true, targetId });
  closeModal = () => this.setState({ isModalOpen: false });
}

RefErrorsResultsItem.propTypes = {
  item: REF_ERRORS_PROP_TYPES_RESULT_ITEM,
};

import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  getFinancingProfilingDurations,
  getFinancingProfilingEntityCodes,
  getFinancingProfilingLoanProducts,
} from '../FinancingProfiling/financingProfilingCommonSelectors';
import { getLanguage } from '../commonSelectors';
import {
  financingProfilingColumnsOrder,
  financingProfilingRowsOrder,
} from '../../constants/FinancingProfilingConstants';
import { getIdFromRef } from '../../utils';

export const getFinancingProfilingProfileOptions = createSelector([
  getFinancingProfilingEntityCodes,
  getLanguage,
], (entityCodes, locale) => _.sortBy(
  getOptionsByGroup(entityCodes, 'financingProfile', locale),
  (it) => financingProfilingColumnsOrder[it.value]
));

export const getFinancingProfilingPredictionOptions = createSelector([
  getFinancingProfilingEntityCodes,
  getLanguage,
], (entityCodes, locale) => _.sortBy(
  getOptionsByGroup(entityCodes, 'financingProfilingPrediction', locale),
  (it) => financingProfilingRowsOrder[it.value]
));

function getOptionsByGroup(options, group, locale) {
  return options
    .filter((it) => it.group === group)
    .map((it) => ({ label: it.text[locale], value: it.id }));
}


export const getFinancingProfilingLoanProductOptions = createSelector([
  getFinancingProfilingLoanProducts,
  getFinancingProfilingDurations,
  getLanguage,
], (loanProducts, durations, locale) => loanProducts.map((loanProduct) => {
  const availableDurations = loanProduct.availableDurations
    .map((available) => durations.find((duration) => duration.id === getIdFromRef(available.$ref)))
    .filter(Boolean);

  const defaultDurationId = loanProduct.defaultDuration
    ? getIdFromRef(loanProduct.defaultDuration.$ref)
    : undefined;

  const shortName = loanProduct.shortName && loanProduct.shortName[locale]
    ? ` (${loanProduct.shortName[locale]})`
    : '';
  return {
    value: loanProduct.id,
    label: loanProduct.name[locale] + shortName,
    durations: availableDurations.map((duration) => ({
      id: duration.id,
      durationInYears: duration.durationInYears,
      isDefault: duration.id === defaultDurationId,
    })),
  };
}));

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from 'react-select';

const CriteriaCodeSelect = ({ source, criteria, language, onChange, codes, edit, operation }) => {
  const multi = (operation === 'contains' || _.get(source, 'props.multi'));
  // JMES Path differs if source is a multi dropdown
  const path = _.get(source, 'props.multi', false)
    ? `${_.get(source, 'props.id')}[*].value.id`
    // if operation is contains, make sure that the selected value is also an array
    : (operation === 'contains') ? `[${_.get(source, 'props.id')}.value.id]` : `${_.get(source, 'props.id')}.value.id`;

  function onValueChange(selected) {
    if (multi) {
      onChange({
        source: _.get(source, 'props.id'),
        path,
        values: selected.map(s => s.value)
      });
    } else {
      onChange({
        source: _.get(source, 'props.id'),
        path,
        values: (selected) ? [selected.value] : []
      });
    }
  }

  const options = _.get(codes, _.get(source, 'props.group'), []).map(o => ({ value: o.id, label: _.get(o, `text.${language}`) }));

  return (edit)
    ? (
      <React.Fragment>
        <strong>Wert</strong>

        <Select
          options={options}
          onChange={onValueChange}
          multi={multi}
          value={(multi) ? _.get(criteria, 'values', []) : _.get(criteria, 'values.0')}
        />
      </React.Fragment>
    )
    :(
      <React.Fragment>
        {_.get(criteria, 'values', []).map(v => _.get(options.find(o => o.value === v), 'label')).join(', ')}
      </React.Fragment>
    );
}

CriteriaCodeSelect.propTypes = {
  source: PropTypes.object.isRequired,
  criteria: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func,
  codes: PropTypes.object,
  edit: PropTypes.bool,
  operation: PropTypes.string
};

CriteriaCodeSelect.defaultProps = {
  criteria: {},
  language: 'de',
  onChange: _.noop,
  codes: {},
  edit: false,
  operation: 'eq'
};

export default CriteriaCodeSelect;

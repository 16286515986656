import { Reducers } from '@evoja-web/redaction';

import { reducer as featureFlagReducer } from './FeatureFlag';
import { reducer as featureFlagFormReducer } from './FeatureFlagForm';

export const reducers = Reducers([
  featureFlagReducer,
  featureFlagFormReducer
]);

export default reducers.get();

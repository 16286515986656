import { URL_PATTERN } from '../../constants/URLPattern';

export function isExternalLinkUrlValid(url) {
  if (!url) {
    return false;
  }

  return URL_PATTERN.test(url);
}

export function isExternalLinkTextValid(text) {
  return !!text;
}

export function isExternalLinkValid(link, language) {
  return isExternalLinkUrlValid(link.url) &&
    isExternalLinkUrlValid(link.translatedUrl[language]) &&
    isExternalLinkTextValid(link.name[language]);
}

import axios from 'axios';
import { call } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';

export function* financingRatesLoadForwardPeriodsSaga() {
  const { data: forwardPeriodsConfig } = yield call(axios, {
    url: `${EndpointName.CORE_CONFIG}/financingForwardPeriods`,
  });

  if (!forwardPeriodsConfig.value) {
    return [];
  }

  return forwardPeriodsConfig.value
    .split(',')
    .map((period) => {
      const [forwardFrom, forwardUntil] = period.split('-').map((it) => parseInt(it, 10));
      return {
        label: `${period} Tage`,
        value: {
          forwardFrom,
          forwardUntil,
        },
      };
    })
    .sort((a, b) => a.value.forwardFrom - b.value.forwardFrom);
}

import { select } from 'redux-saga/effects';
import { PERMISSION_GROUP_PAGE_SIZE } from '../../constants/PermissionGroupConstants';
import { getLanguage } from '../../selectors/commonSelectors';

export function* createSearchQuery(params) {
  const { pageNumber, sortField, sortDirection } = params;
  const { firstName, lastName, username, tenant } = params;

  const locale = yield select(getLanguage);
  const queryParams = [
    pageNumber > 0
      ? `limit(${PERMISSION_GROUP_PAGE_SIZE},${(pageNumber - 1) * PERMISSION_GROUP_PAGE_SIZE})`
      : `limit(${PERMISSION_GROUP_PAGE_SIZE})`,
  ];

  if (sortField) {
    queryParams.push(getSortQuery(sortField, sortDirection, locale));
  }

  if (firstName) {
    queryParams.push(`eq(firstName,string:${encodeURIComponent(firstName)})`);
  }

  if (lastName) {
    queryParams.push(`eq(lastName,string:${encodeURIComponent(lastName)})`);
  }

  if (username) {
    queryParams.push(`eq(username,string:${encodeURIComponent(username)})`);
  }

  if (tenant) {
    queryParams.push(`eq(tenant,string:${encodeURIComponent(tenant)})`);
  }

  return queryParams.join('&');
}

function getSortQuery(sortField, sortDirection, locale) {
  const sortSign = (sortDirection === 'desc' ? '-' : '+');

  switch (sortField) {
    case 'name':
      return `sort(${sortSign}firstName,${sortSign}lastName)`;
    default:
      return `sort(${sortSign}${sortField})`;
  }
}

import './AnalyticsHistoryUsers.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SortableTable } from '../Common/SortableTable';
import { PROP_TYPES_HISTORY_DETAILS_ITEM, PROP_TYPES_HISTORY_STAT } from './AnalyticsPropTypes';
import { TableCell, TableRow } from '../Common/Table';
import { FormattedDate } from '../Common/FormattedDate';

const SORTABLE_FIELDS = [
  {
    key: 'username',
    label: <FormattedMessage id="analytics_historyUsers_username"/>,
    sortFn: (item) => item.username,
  },
  {
    key: 'name',
    label: <FormattedMessage id="analytics_historyUsers_name"/>,
    sortFn: (item) => item.name,
  },
  {
    key: 'department',
    label: <FormattedMessage id="analytics_historyUsers_department"/>,
    sortFn: (item) => item.department,
  },
  {
    key: 'division',
    label: <FormattedMessage id="analytics_historyUsers_division"/>,
    sortFn: (item) => item.division,
  },
  {
    key: 'firstRequest',
    label: <FormattedMessage id="analytics_historyUsers_firstRequest"/>,
    sortFn: (item) => item.firstRequest,
  },
  {
    key: 'lastRequest',
    label: <FormattedMessage id="analytics_historyUsers_lastRequest"/>,
    sortFn: (item) => item.lastRequest,
  },
  {
    key: 'requestCount',
    label: <FormattedMessage id="analytics_historyUsers_requestCount"/>,
    sortFn: (item) => item.requestCount,
  },
];

export class AnalyticsHistoryUsers extends React.Component {
  render() {
    const { textDetails = '', users, statistic } = this.props;
    const { min, max, avg } = statistic;

    return (
      <div className="analytics-history-users">
        {textDetails && <div className="analytics-history-users__text">{textDetails}</div>}

        <div className="analytics-history-users__statistic">
          <FormattedMessage id="analytics_historyUsers_text" values={{ min, max, avg }}/>
        </div>

        <SortableTable
          items={users}
          sortableFields={SORTABLE_FIELDS}
          renderItem={this.renderItem}
          className="analytics-history-users__table"
        />
      </div>
    );
  }

  renderItem(user, index) {
    return (
      <TableRow key={index}>
        <TableCell>
          {user.username}
        </TableCell>
        <TableCell>
          {user.name}
        </TableCell>
        <TableCell>
          {user.department}
        </TableCell>
        <TableCell>
          {user.division}
        </TableCell>
        <TableCell>
          <FormattedDate value={user.firstRequest} format="DD.MM.YYYY HH:mm:ss"/>
        </TableCell>
        <TableCell>
          <FormattedDate value={user.lastRequest} format="DD.MM.YYYY HH:mm:ss"/>
        </TableCell>
        <TableCell>
          {user.requestCount}
        </TableCell>
      </TableRow>
    );
  }
}

AnalyticsHistoryUsers.propTypes = {
  textDetails: PropTypes.string,
  users: PropTypes.arrayOf(PROP_TYPES_HISTORY_DETAILS_ITEM).isRequired,
  statistic: PROP_TYPES_HISTORY_STAT.isRequired,
};

import './SetManagementBundleEdit.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Table, TableCell, TableRow } from '../Common/Table';
import { SelectControl } from '../Common/SelectControl';
import { Button } from '../Common/Button';
import { InputControl } from '../Common/InputControl';

export class SetManagementBundleEdit extends React.Component {
  constructor(props) {
    super(props);
    const { bundle } = props;

    this.state = {
      bundle: { ...bundle },
    };
  }

  render() {
    const { domains, onCancel } = this.props;
    const { bundle } = this.state;

    return (
      <div className="set-management-bundle-edit">
        <Table>
          <TableRow>
            <TableCell className="set-management-bundle-edit__label">
              <FormattedMessage id="setManagement_bundle_domain" />
            </TableCell>
            <TableCell className="set-management-bundle-edit__value">
              <SelectControl
                value={bundle.domain}
                multi={false}
                clearable={false}
                options={domains}
                onChange={this.onChangeDomain}
                className={bundle.domain ? '' : 'set-management-bundle-edit__invalid'}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="set-management-bundle-edit__label">
              <FormattedMessage id="setManagement_bundle_name" />
            </TableCell>
            <TableCell className="set-management-bundle-edit__value">
              <InputControl
                type="text"
                value={bundle.name || ''}
                onChange={this.onChangeName}
                className={bundle.name ? '' : 'set-management-bundle-edit__invalid'}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="set-management-bundle-edit__label">
              <FormattedMessage id="setManagement_bundle_shortName" />
            </TableCell>
            <TableCell className="set-management-bundle-edit__value">
              <InputControl type="text" value={bundle.shortName || ''} onChange={this.onChangeShortName} />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="set-management-bundle-edit__label">
              <FormattedMessage id="setManagement_bundle_bundlePrice" />
            </TableCell>
            <TableCell className="set-management-bundle-edit__value">
              <InputControl
                type="number"
                value={bundle.bundlePrice.toString() || ''}
                onChange={this.onChangePrice}
                className={Number.isFinite(bundle.bundlePrice) ? '' : 'set-management-bundle-edit__invalid'}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="set-management-bundle-edit__label">
              <FormattedMessage id="setManagement_bundle_bundlePriceReduced" />
            </TableCell>
            <TableCell className="set-management-bundle-edit__value">
              <InputControl type="number" value={bundle.bundlePriceReduced || ''} onChange={this.onChangePriceReduced} />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="set-management-bundle-edit__label">
              <FormattedMessage id="setManagement_bundle_bundlePriceYouth" />
            </TableCell>
            <TableCell className="set-management-bundle-edit__value">
              <InputControl
                type="number"
                value={bundle.bundlePriceYouth === undefined ? '' : bundle.bundlePriceYouth}
                onChange={this.onChangePriceYouth}
              />
            </TableCell>
          </TableRow>
        </Table>

        <div className="set-management-bundle-edit__buttons">
          <Button
            onClick={this.save}
            bsSize="small"
            bsStyle="primary"
            className="set-management-bundle-edit__save"
            disabled={!this.isValid()}
          >
            <FormattedMessage id="common_save" />
          </Button>
          <Button onClick={onCancel} bsSize="small">
            <FormattedMessage id="common_close" />
          </Button>
        </div>
      </div>
    );
  }

  isValid() {
    const { bundle } = this.state;
    return bundle.name && bundle.domain && Number.isFinite(bundle.bundlePrice);
  }

  onChangeDomain = (domain) =>
    this.setState((prev) => ({
      bundle: {
        ...prev.bundle,
        domain: domain ? domain.value : undefined,
      },
    }));

  onChangeName = ({ target: { value } }) =>
    this.setState((prev) => ({
      bundle: {
        ...prev.bundle,
        name: value,
      },
    }));

  onChangeShortName = ({ target: { value } }) =>
    this.setState((prev) => ({
      bundle: {
        ...prev.bundle,
        shortName: value,
      },
    }));

  onChangePrice = ({ target: { value } }) =>
    this.setState((prev) => ({
      bundle: {
        ...prev.bundle,
        bundlePrice: value ? parseFloat(value) : undefined,
      },
    }));


  onChangePriceYouth = ({ target: { value } }) => {
    this.setState((prev) => ({
      bundle: {
        ...prev.bundle,
        bundlePriceYouth: isNaN(value) ? undefined : parseFloat(value),
      },
    }));
    
  }

  save = () => this.props.onSave(this.state.bundle);
}

SetManagementBundleEdit.propTypes = {
  bundle: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

import './HolidaysList.css';

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { HolidaysListDay } from './HolidaysListDay';
import { HolidaysCreateForm } from './HolidaysCreateForm';
import { ANALYTICS_PROP_TYPES_LOCALE } from '../Analytics/AnalyticsPropTypes';
import { ModalDialog } from '../Common/Modal';

export class HolidaysList extends React.Component {
  state = {
    isCreateFormOpen: false,
  };

  constructor(props) {
    super(props);

    this.handleCreateOpen = this.handleCreateOpen.bind(this);
    this.handleCreateCancel = this.handleCreateCancel.bind(this);
    this.handleCreateSubmit = this.handleCreateSubmit.bind(this);
  }

  render() {
    const { holidays, edit, remove, locale } = this.props;
    return (
      <div className="holidays-list">
        {holidays.map((item, index) => (
          <HolidaysListDay key={`${holidays.length}--${index}`}
                           locale={locale}
                           item={item}
                           edit={edit}
                           remove={remove}/>
        ))}

        <div className="holidays-list__create">
          <Button bsStyle="primary"
                  name="newLink"
                  onClick={this.handleCreateOpen}>
            <span className="icon-061-plus"/> Neuer Eintrag
          </Button>
        </div>

        {this.renderCreateModal()}
      </div>
    );
  }

  renderCreateModal() {
    const { year } = this.props;
    const { isCreateFormOpen } = this.state;

    const initialFormValues = {
      name: '',
      isMultiDay: false,
      startDate: moment([year, 0, 1]),
      endDate: null,
    };

    return (
      <ModalDialog
        show={isCreateFormOpen}
        keyboard={true}
        backdrop="static"
        onHide={this.handleCreateCancel}
      >
        <HolidaysCreateForm
          year={year}
          initialValues={initialFormValues}
          onCancel={this.handleCreateCancel}
          onSubmit={this.handleCreateSubmit}
        />
      </ModalDialog>
    );
  }

  handleCreateOpen() {
    this.setState({ isCreateFormOpen: true });
  }

  handleCreateCancel() {
    this.setState({ isCreateFormOpen: false });
  }

  handleCreateSubmit(formData) {
    const { create } = this.props;
    create(formData.name, formData.startDate, formData.endDate);

    this.setState({ isCreateFormOpen: false });
  }
}

HolidaysList.propTypes = {
  locale: ANALYTICS_PROP_TYPES_LOCALE.isRequired,

  year: PropTypes.number,
  holidays: PropTypes.array,

  edit: PropTypes.func,
  remove: PropTypes.func,
  create: PropTypes.func,
};

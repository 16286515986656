import axios from 'axios';
import { all, take, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { parseLinkHeader } from '@web3-storage/parse-link-header';
import { get } from 'lodash';

import {
  PUT_WORKGUIDE,
  GET_CONSULTANT_REQUEST,
  GET_CODEGROUP_REQUEST,
  GET_METADATA_REQUEST,
  WORKGUIDE_CODES_REQUEST,
  WORKGUIDE_CODES_PENDING,
  WORKGUIDE_CODES_FULFILLED,
  WORKGUIDE_CODES_REJECTED,
  WORKGUIDE_PRODUCTGROUPS_REQUEST,
  WORKGUIDE_PRODUCTGROUPS_PENDING,
  WORKGUIDE_PRODUCTGROUPS_FULFILLED,
  WORKGUIDE_PRODUCTGROUPS_REJECTED,
  WORKGUIDE_SERVICES_REQUEST,
  WORKGUIDE_SERVICES_PENDING,
  WORKGUIDE_SERVICES_FULFILLED,
  WORKGUIDE_SERVICES_REJECTED,
  WORKGUIDE_SERVICE_DEFINITION_REQUEST,
  WORKGUIDE_SERVICE_DEFINITION_PENDING,
  WORKGUIDE_SERVICE_DEFINITION_FULFILLED,
  WORKGUIDE_SERVICE_DEFINITION_REJECTED,
  WORKGUIDE_SAVE_CODES_REQUEST,
  WORKGUIDE_SAVE_CODES_PENDING,
  WORKGUIDE_SAVE_CODES_FULFILLED,
  WORKGUIDE_SAVE_CODES_REJECTED
} from '../actions/WorkguideActions';

import { removeHost } from '../utils';
import getConsultants from './Workguide/getConsultants';
import getCodeGroups from './Workguide/getCodeGroups';
import getWorkguidesMetadata from './Workguide/getWorkguidesMetadata';

export function* updateWorkguide(action) {
  try {
    yield put({ type: `${PUT_WORKGUIDE}_PENDING` });
    yield call(axios.put, `/work/workguide/${action.workguide.id}`, action.workguide)
    yield put({ type: `${PUT_WORKGUIDE}_FULFILLED` });
  } catch (e) {
    yield put({ type: `${PUT_WORKGUIDE}_REJECTED` });
  }
}

export function* fetchCodes(request) {
  const { group } = request;
  yield put({ type: WORKGUIDE_CODES_PENDING });

  try {
    let codes = [];
    let nextCodes = [];
    let linkHeaders = { next: { url: `/entity/code/?limit(9999)&eq(group,${group})` } };
    while (linkHeaders.hasOwnProperty('next')) {
      nextCodes = yield call(axios.get, removeHost(linkHeaders.next.url));
      codes = [ ...codes, ...nextCodes.data ];
      linkHeaders = parseLinkHeader(nextCodes.headers.link, { maxHeaderLength: 5000 });
    }

    yield put({ type: WORKGUIDE_CODES_FULFILLED, group, codes });
  } catch (error) {
    yield put({ type: WORKGUIDE_CODES_REJECTED, error });

    return error;
  }
}

export function* fetchProductGroups() {
  yield put({ type: WORKGUIDE_PRODUCTGROUPS_PENDING });

  try {
    const { data } = yield call(axios, { url: '/basic/productgroup/?limit(9999)' });
    yield put({ type: WORKGUIDE_PRODUCTGROUPS_FULFILLED, data });

    return data;
  } catch (error) {
    yield put({ type: WORKGUIDE_PRODUCTGROUPS_REJECTED, error });

    return error;
  }
}

/**
 * Get a list of all available services
 *
 * @return {Generator}
 */
export function* fetchServices() {
  yield put({ type: WORKGUIDE_SERVICES_PENDING });

  try {
    const { data } = yield call(axios, {
      url: '/',
      method: 'get'
    });

    const services = data.services
      .map((service) => removeHost(service.$ref))
      .map((service) => service.substr(0, service.length - 1));

    yield put({ type: WORKGUIDE_SERVICES_FULFILLED, payload: services });

    return data;
  } catch(error) {
    yield put({ type: WORKGUIDE_SERVICES_REJECTED, error });

    return error;
  }
}

/**
 * Get the schema for the given service
 *
 * @param  {Object} request Action request
 *
 * @return {Generator}
 */
export function* fetchServiceDefinition(request) {
  const { service } = request;
  yield put({ type: WORKGUIDE_SERVICE_DEFINITION_PENDING });

  try {
    const url = `/schema${service}/collection`;

    const { data } = yield call(axios, {
      url,
      method: 'get'
    });

    yield put({ type: WORKGUIDE_SERVICE_DEFINITION_FULFILLED, payload: { service, definition: data} });

    return data;
  } catch (error) {
    yield put({ type: WORKGUIDE_SERVICE_DEFINITION_REJECTED, error });

    return error;
  }
}

export function updateCode(code) {
  const url = `/entity/code/${get(code, 'id')}`;

  return axios({
    url,
    method: 'put',
    data: code
  });
}

export function* saveWorkguideCodes(request) {
  const { codes } = request;
  yield put({ type: WORKGUIDE_SAVE_CODES_PENDING });

  try {
    yield all(codes.map((code) => call(updateCode, code)));
    yield put({ type: WORKGUIDE_SAVE_CODES_FULFILLED, payload: codes });
  } catch (error) {
    yield put({ type: WORKGUIDE_SAVE_CODES_REJECTED, error });
  }
}

export default function* workguideLegacyFlow() {
  yield takeEvery(WORKGUIDE_CODES_REQUEST, fetchCodes);
  yield takeLatest(GET_CONSULTANT_REQUEST, getConsultants);
  yield takeLatest(GET_CODEGROUP_REQUEST, getCodeGroups);
  yield takeLatest(GET_METADATA_REQUEST, getWorkguidesMetadata);
  yield takeLatest(WORKGUIDE_PRODUCTGROUPS_REQUEST, fetchProductGroups);
  yield takeLatest(WORKGUIDE_SERVICES_REQUEST, fetchServices);
  yield takeEvery(WORKGUIDE_SERVICE_DEFINITION_REQUEST, fetchServiceDefinition);
  yield takeLatest(WORKGUIDE_SAVE_CODES_REQUEST, saveWorkguideCodes);
  while (true) {
    const workguide = yield take(PUT_WORKGUIDE);
    yield call(updateWorkguide, workguide);
  }
}

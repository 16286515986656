import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { get, slice } from 'lodash';

import { EndpointName } from '../../constants/EndpointName';
import { BULK_LOADS_SEARCH } from '../../actions/BulkLoadsActions';
import { loadActionsByLoads } from './loadActionsByLoadSaga';
import { createSearchQuery } from './createSearchQuery';
import { BULK_LOADS_PAGE_SIZE } from '../../constants/BulkLoadsConstants';

const filterByActionMapID = (results, actionMapID) => {
  const actionMap = results.loads[0].actionMap;

  for (const key in actionMap) {
    if (actionMap[key].includes(actionMapID)) return results
  }
  return {
    ...results,
    loads: []
  };
}

export function* bulkLoadsSearchSaga(action) {
  try {
    yield put(BULK_LOADS_SEARCH.pending());

    const {
      limit = BULK_LOADS_PAGE_SIZE,
      offset = 0,
      resultsRaw = {}
    } = get(action, 'data', {});


    const chunk = slice(get(resultsRaw, 'loads', []), offset, limit + offset);

    let loads = [];
    let actions = [];
    // If we already have data for the given limit / offset,
    // use current data but load actions for the given chunk
    // as it's hard to figure which actions we have to keep and which to load
    if (get(chunk, 'length', 0) > 0) {
      loads = get(resultsRaw, 'loads', []);
      actions = yield call(loadActionsByLoads, chunk);
    } else {
      const query = createSearchQuery(action.data);
      const { data } = yield call(axios, {
        url: `${EndpointName.LOADER_LOAD}/?${query}`,
      });

      loads = [...get(resultsRaw, 'loads', []), ...data];
      actions = yield call(loadActionsByLoads, data);
    }

    let results = {
      loads,
      actions,
    };

    if (action.data.loadconfig && loads.length > 0) {
      results = filterByActionMapID(results, action.data.loadconfig);
    }

    yield put(BULK_LOADS_SEARCH.success({
      searchParams: action.data,
      results,
      limit,
      offset
    }));
  } catch (error) {
    yield put(BULK_LOADS_SEARCH.failure(error));
  }
}

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  UI_NOTES_FETCH_ACTIONS,
  UI_NOTES_CREATE_ACTIONS,
  UI_NOTES_DELETE_ACTIONS,
  UI_NOTES_UPDATE_ACTIONS,
} from '../actions/UiNotesActions';
import {
  getUiNotesItems,
  getUiNotesTypes,
  getUiNotesLoading,
  getUiNotesSaving,
} from '../selectors/UiNotes/uiNotesSelectors';
import { getLanguage } from '../selectors/commonSelectors';
import UiNotes from '../components/UiNotes/UiNotes';

const mapStateToProps = createStructuredSelector({
  notes: getUiNotesItems,
  types: getUiNotesTypes,

  loading: getUiNotesLoading,
  saving: getUiNotesSaving,

  language: getLanguage,
});

const mapDispatchToProps = {
  fetchNotes: (sortOption) => UI_NOTES_FETCH_ACTIONS.request(sortOption),
  createNote: ({ form }) => UI_NOTES_CREATE_ACTIONS.request({ form }),
  deleteNote: ({ note }) => UI_NOTES_DELETE_ACTIONS.request({ note }),
  updateNote: ({ note, form }) => UI_NOTES_UPDATE_ACTIONS.request({ note, form }),
};

export const UiNotesPage = connect(mapStateToProps, mapDispatchToProps)(UiNotes);

import React from 'react';
import PropTypes from 'prop-types';
import MarkdownEditor from '../MarkdownEditor';

const DetailInfos = ({ content, targetField, title, editValueField }) => {
  const updateFunction = (value) => {
    editValueField(targetField, 'text', value)  
  }

  return (
    <MarkdownEditor 
      content={content}
      updateFunction={updateFunction}
      title={title}
    />
  )
}

DetailInfos.propTypes = {
  content: PropTypes.string,
  targetField: PropTypes.string.isRequired,
  title: PropTypes.string,
  editValueField: PropTypes.func.isRequired
}

DetailInfos.defaultProps = {
  title: "",
  content: ""
}

export default DetailInfos;


import {
  ANALYTICS_ACTIVE_USERS_LOAD,
  ANALYTICS_HISTORY_SEARCH,
  ANALYTICS_HISTORY_SEARCH_DETAILS,
} from '../actions/AnalyticsActions';
import { sortByAll } from '../utils';

const initialState = {
  activeSessions: [],
  history: [],
  historyItems: [],
  historyItemsDetails: [],
  historyLoading: false,
  consultants: [],
  textBlocks: [],
  loading: false,
  error: undefined,
};

export function AnalyticsReducer(state = initialState, action) {
  switch (action.type) {
    case ANALYTICS_ACTIVE_USERS_LOAD.PENDING:
      return { ...state, loading: true, error: undefined };
    case ANALYTICS_ACTIVE_USERS_LOAD.SUCCESS: {
      const { activeSessions, consultants, textBlocks, history, historyItems } = action.data;
      return {
        ...state,
        loading: false,
        error: undefined,
        activeSessions,
        consultants,
        textBlocks,
        history: sortByDate(history),
        historyItems: sortByDate(historyItems),
      };
    }
    case ANALYTICS_ACTIVE_USERS_LOAD.FAILURE:
      return { ...state, loading: false, error: action.data };

    case ANALYTICS_HISTORY_SEARCH.PENDING:
      return { ...state, historyLoading: true, error: undefined };
    case ANALYTICS_HISTORY_SEARCH.SUCCESS: {
      const { history, historyItems } = action.data;
      return {
        ...state,
        historyLoading: false,
        error: undefined,
        history: sortByDate(history),
        historyItems: sortByDate(historyItems),
      };
    }
    case ANALYTICS_HISTORY_SEARCH.FAILURE:
      return { ...state, historyLoading: false, error: action.data };

    case ANALYTICS_HISTORY_SEARCH_DETAILS.PENDING:
      return { ...state, historyLoading: true, error: undefined };
    case ANALYTICS_HISTORY_SEARCH_DETAILS.SUCCESS: {
      const { history, historyItemsDetails } = action.data;
      return {
        ...state,
        historyLoading: false,
        error: undefined,
        history: sortByDate(history),
        historyItemsDetails: sortByDate(historyItemsDetails),
      };
    }
    case ANALYTICS_HISTORY_SEARCH_DETAILS.FAILURE:
      return { ...state, historyLoading: false, error: action.data };

    default:
      return state;
  }
}

function sortByDate(items) {
  return items
    .sort(sortByAll((item) => item.date.valueOf()))
    .reverse();
}

import { call } from 'redux-saga/effects';
import { get, isNil } from 'lodash';

/**
 * Transfer a file to the target.
 * Download the binary file from source, create FormData, append metadata and upload it to the target.
 *
 * @param   {Object}  item    Item to transfer
 * @param   {Object}  target  Target system from state.paramTransfer.target
 *
 * @return  {Object} result Result
 */
export default function* transferFile({ item, source, target }) {
  const sourceInstance = get(source, 'data.axiosInstance');
  const targetInstance = get(target, 'data.axiosInstance');

  const { uri, data } = item;

  if (isNil(sourceInstance)) {
    throw new Error(`Unable to transfer file ${item.dataKey} to the target system! No axios source instance given`);
  }

  if (isNil(targetInstance)) {
    throw new Error(`Unable to transfer file ${item.dataKey} to the target system! No axios target instance given`);
  }

  const { data: blob } = yield call(sourceInstance, {
    url: uri,
    method: 'get',
    responseType: 'blob'
  });

  const metadata = JSON.stringify({
    links: get(data, 'links'),
    metadata: {
      filename: get(data, 'metadata.filename'),
      order: get(data, 'metadata.order'),
      additionalProperties: get(data, 'metadata.additionalProperties'),
      action: []
    }
  });

  const formData = new FormData();
  formData.append('upload', blob);
  formData.append('metadata', metadata);

  const result = yield call(targetInstance, {
    url: uri,
    method: 'put',
    data: formData
  });

  return result;
}

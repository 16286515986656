export function createActions(type) {
  const REQUEST = (type + "_REQUEST");
  const PENDING = (type + "_PENDING");
  const PROGRESS = (type + "_PROGRESS");
  const SUCCESS = (type + "_SUCCESS");
  const FAILURE = (type + "_FAILURE");

  return {
    REQUEST: REQUEST,
    PENDING: PENDING,
    PROGRESS: PROGRESS,
    SUCCESS: SUCCESS,
    FAILURE: FAILURE,

    request: (data, meta) => ({
      type: REQUEST,
      data: data,
      meta: meta,
    }),
    pending: (meta) => ({
      type: PENDING,
      data: undefined,
      meta: meta,
    }),
    progress: (meta) => ({
      type: PROGRESS,
      data: undefined,
      meta: meta,
    }),
    success: (data, meta) => ({
      type: SUCCESS,
      data: data,
      meta: meta,
    }),
    failure: (data, meta) => ({
      type: FAILURE,
      data: data,
      meta: meta,
    }),
  };
}

import './BulkLoadsChart.css';

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { BULK_LOAD_PROP_TYPES_RESULT_ITEM } from './BulkLoadsPropTypes';
import { BulkLoadsExpandContent } from './BulkLoadsExpandContent';
import { BULK_LOADS_CHART_COLORS } from '../../constants/BulkLoadsConstants';
import { DATE_TIME_FORMAT } from '../../constants/DateTime';

export class BulkLoadsChart extends React.PureComponent {
  state = {
    className: '',
  };

  render() {
    const { results } = this.props;
    if (!results.length) {
      return null;
    }

    return (
      <div className="bulk-loads-chart">
        <BulkLoadsExpandContent title="Zeitreihe (Abfolge)">
          <HighchartsReact
            highcharts={Highcharts}
            constructorType="ganttChart"
            options={this.createOptions()}
            immutable={true}
            containerProps={{className: 'bulk-loads-chart__chart-wrapper'}}
          />
        </BulkLoadsExpandContent>
      </div>
    );
  }

  createOptions() {
    const { results } = this.props;
    const startDates = results.map((it) => it.open ? it.open : it.working).filter(Boolean);
    const endDates = results.map((it) => it.done).filter(Boolean);
    const maxDate = moment.max([...endDates, ...startDates]);
    const data = results.map((it) => ({
      name: it.id,
      start: it.working
        ? it.working.valueOf()
        : it.open
          ? it.open.valueOf()
          : undefined,
      end: it.done
        ? it.done.valueOf()
        : it.working
          ? maxDate.valueOf()
          : it.open.valueOf(),
      color: BULK_LOADS_CHART_COLORS[it.state],

      // data for tooltips
      open: it.open,
      working: it.working,
      done: it.done,
    }));

    const { className } = this.state;
    const _this = this;

    return {
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      chart: {
        animation: false,
        className: 'bulk-loads-chart__chart',
        backgroundColor: 'transparent',
        style: {
          position: 'relative',
          zIndex: 1,
        },
      },
      time: {
        useUTC: false,
      },
      xAxis: [{
        type: 'datetime',
        gridLineWidth: 1,
        gridLineDashStyle: 'LongDash',
        dateTimeLabelFormats: {
          day: '%d.%m.%Y',
          week: '%d.%m.%Y',
          hour: '%d.%m - %H:%M',
        },
        opposite: false,
        grid: {
          borderColor: 'transparent',
        },
        startOnTick: true,
        endOnTick: true,
        labels: {
          align: 'center',
          style: {
            fontSize: '11px',
            textOverflow: 'none',
            whiteSpace: 'nowrap',
          },
          useHTML: true,
        },
        className: className,
        tickPositioner: function () {
          if (this.tickPositions.length > 13) {
            _this.setState({ className: 'bulk-loads-chart__rotate-labels' });
          } else if (className) {
            _this.setState({ className: '' });
          }
          return this.tickPositions;
        },
      }],
      yAxis: {
        staticScale: 35,
        visible: false,
      },
      plotOptions: {
        series: {
          clip: false,
          dataLabels: {
            enabled: true,
            format: '{point.name}',
            style: {
              cursor: 'default',
              color: 'black',
              margin: '10px',
            },
            align: 'left',
            padding: 2,
            adjustPosition: true,
          },
          tooltip: {
            pointFormatter: function () {
              const point = this;
              return [
                '<b>' + (point.name) + '</b>',
                point.working
                  ? `Start: ${point.working.format(DATE_TIME_FORMAT)}`
                  : undefined,
                point.open && !point.working
                  ? `Open: ${point.open.format(DATE_TIME_FORMAT)}`
                  : undefined,
                point.done
                  ? `End: ${point.done.format(DATE_TIME_FORMAT)}`
                  : undefined,
              ].filter(Boolean).join('<br/>');
            },
          },
        },
      },

      series: [{
        animation: false,
        name: '',
        borderRadius: 0,
        pointWidth: 30,
        minPointLength: 10,
        data: _.orderBy(data, ['start'], ['desc']),
      }],
    };
  }
}

BulkLoadsChart.propTypes = {
  results: PropTypes.arrayOf(BULK_LOAD_PROP_TYPES_RESULT_ITEM),
};

import './SortIndicator.css';

import * as React from "react";
import { classList } from '../../utils/classList';

export function SortIndicator({ sort }) {
    const className = classList("sort-indicator", {
        [`sort-indicator--${sort}`]: !!sort,
    });

    return (
        <span className={className}>
            <span className="sort-indicator__arrow">
                <span className="icon-060-play-filled"/>
            </span>
        </span>
    );
}

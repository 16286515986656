import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { createRef } from '../../utils';
import { getRemovedProducts } from '../../selectors/BasicProducts';

export function* removeBasicProductsSaga() {
  const removedProducts = yield select(getRemovedProducts);
  yield all(
    removedProducts.map((product) => call(axios, {
      url: createRef(EndpointName.BASIC_PRODUCT, product.id),
      method: 'delete',
    }))
  );
}

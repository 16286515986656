import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { REF_ERRORS_LOAD, REF_ERRORS_SEARCH, REF_ERRORS_LOAD_ALL } from '../actions/RefErrorsActions';
import { getRefErrorsTexts } from '../selectors/RefErrors/refErrorsTextSelector';
import {
  getRefErrorsError,
  getRefErrorsLoading,
  getRefErrorsResultsCount,
  getRefErrorsSearchParams,
  getAllRefErrors,
  getRefErrorsResultsRaw
} from '../selectors/RefErrors/refErrorsCommonSelectors';
import { RefErrors } from '../components/RefErrors/RefErrors';
import { getRefErrorsResults } from '../selectors/RefErrors/refErrorsResultsSelector';
import { getLanguage } from '../selectors/commonSelectors';

const mapStateToProps = createStructuredSelector({
  loading: getRefErrorsLoading,
  texts: getRefErrorsTexts,
  error: getRefErrorsError,
  results: getRefErrorsResults,
  resultsTotalCount: getRefErrorsResultsCount,
  searchParams: getRefErrorsSearchParams,
  locale: getLanguage,
  allErrors: getAllRefErrors,
  resultsRaw: getRefErrorsResultsRaw
});

const mapDispatchToProps = {
  load: () => REF_ERRORS_LOAD.request(),
  search: (options) => REF_ERRORS_SEARCH.request(options),
  loadPerDay: (options) => REF_ERRORS_LOAD_ALL.request(options)
};

export const RefErrorsPage = connect(mapStateToProps, mapDispatchToProps)(RefErrors);

import { all, call, put } from 'redux-saga/effects';
import { handleNextHeaders, deslashUri, prepareGravitonRef } from '@evoja-web/redux-saga-utils';
import { get, isNil, flatten } from 'lodash';
import QueryBuilder from '@evoja-web/rql-query-builder';

import sourceItemsAction from '../actions/SourceItems';

const {
  SOURCE_ITEMS_PENDING,
  SOURCE_ITEMS_FULFILLED,
  SOURCE_ITEMS_REJECTED
} = sourceItemsAction;

/**
 * Get all items from the given service
 *
 * @param   {Axios}   axiosInstance  Axios instance to use
 * @param   {Object}  service        Service $ref from module
 *
 * @return  {Array} items Array of items
 */
function* getItemsFromService({ axiosInstance, service }) {
  const query = QueryBuilder()
    .ne('excludeFromParamTransfer', true)
    .limit(1000)
    .getQueryString();

  const serviceUri = prepareGravitonRef(get(service, 'service.$ref'));
  const url = `${serviceUri}/${query}`;

  const result = yield call(handleNextHeaders, { url, axiosInstance });
  const items = result.map((r) => ({
    serviceUri,
    sourceUrl: deslashUri(`${axiosInstance.defaults.baseURL}/${serviceUri}/${r.id}`),
    dataKey: deslashUri(`${serviceUri}/${r.id}`),
    uri: deslashUri(`${serviceUri}/${r.id}`),
    service,
    data: r
  }));

  return items;
}

/**
 * Get all items of all service of the given module
 *
 * @param {String} dataKey Data key (module key)
 * @param {Object} module  Module from /core/module (be aware that services were filtered in /components/Modules)
 * @param {Object} source
 *
 * @return  {Array} items Array of source items
 */
export default function* getSourceItems({
  dataKey,
  module,
  source
}) {
  yield put({ type: SOURCE_ITEMS_PENDING, dataKey });

  try {
    const axiosInstance = get(source, 'data.axiosInstance');

    if (isNil(axiosInstance)) {
      throw new Error('Unable to load items from source system. No axios instance given!');
    }

    const service = get(module, 'service', []);

    const operations = service.map((s) => call(getItemsFromService, { axiosInstance, module, service: s }));
    const result = yield all(operations);

    const payload = flatten(result);

    yield put({ type: SOURCE_ITEMS_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    const e = new Error(`Unable to load source items for module with key ${dataKey}!`, { cause: error });
    yield put({ type: SOURCE_ITEMS_REJECTED, dataKey, error: e });

    return error;
  }
}

import './ConsultantsFormContent.css';
import React from 'react';
import { Field } from 'redux-form';
import { FormInput } from '../FormControls';

export class ConsultantsFormContent extends React.Component {
  render() {
    return (
      <div className="consultants-form-content">
        <Field component={FormInput}
               label={<span className="consultants-form-content__label">First name</span>}
               size="large"
               type="text"
               labelSize={12}
               name="firstName"
               placeholder=""
        />
        <Field component={FormInput}
               label={<span className="consultants-form-content__label">Last name</span>}
               size="large"
               type="text"
               labelSize={12}
               name="lastName"
               placeholder=""
        />
        <Field component={FormInput}
               label={<span className="consultants-form-content__label">Username</span>}
               size="large"
               type="text"
               labelSize={12}
               name="username"
               placeholder=""
        />
        <Field component={FormInput}
               label={<span className="consultants-form-content__label">Department</span>}
               size="large"
               type="text"
               labelSize={12}
               name="department"
               placeholder=""
        />
        <Field component={FormInput}
               label={<span className="consultants-form-content__label">Division</span>}
               size="large"
               type="text"
               labelSize={12}
               name="division"
               placeholder=""
        />
        <Field component={FormInput}
               label={<span className="consultants-form-content__label">Title</span>}
               size="large"
               type="text"
               labelSize={12}
               name="title"
               placeholder=""
        />
        <Field component={FormInput}
              label={<span className="consultants-form-content__label">Phone</span>}
              size="large"
              type="phone"
              labelSize={12}
              name="phone"
              placeholder=""
        />
        <Field component={FormInput}
               label={<span className="consultants-form-content__label">Email</span>}
               size="large"
               type="text"
               labelSize={12}
               name="email"
               placeholder=""
        />
        <Field component={FormInput}
               label={<span className="consultants-form-content__label">Password</span>}
               size="large"
               type="password"
               labelSize={12}
               name="password"
               placeholder=""
        />
        <Field component={FormInput}
               label={<span className="consultants-form-content__label">Repeat password</span>}
               size="large"
               type="password"
               labelSize={12}
               name="repeatPassword"
               placeholder=""
        />
      </div>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { TextEditor, ValidationResult } from '@evoja-web/react-form';

import Label from './Label';

const FormFieldDescription = React.memo(function FormFieldDescription({
  id,
  onChange,
  validations,
  value
}) {
  return (
    <div className="workflow-tree-form-element">
      <Label id={id} />

      <div className="workflow-tree-form-element--component">
        <TextEditor
          id={id}
          name={id}
          onChange={onChange}
          value={value}
        />
      </div>

      <ValidationResult
        show
        validations={validations}
      />
    </div>
  );
});

FormFieldDescription.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.string
};

FormFieldDescription.defaultProps = {
  id: 'description',
  onChange: noop,
  validations: {},
  value: undefined
};

export default FormFieldDescription;

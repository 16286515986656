import './UiNotes.css';
import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { convertDateToApp } from '../../utils/dateConverter';
import PageContainer from '../Common/PageContainer';
import { SortableTable } from '../Common/SortableTable';
import { TableCell, TableRow } from '../Common/Table';
import { FormattedDate } from '../Common/FormattedDate';
import { Pagination } from '../Common/Pagination';
import { ModalDialog } from '../Common/Modal';
import { UiNotesShape, UiNotesTypeShape } from './UiNotesShape';
import { UiNotesCreateForm } from './UiNotesCreateForm';
import { UiNotesUpdateForm } from './UiNotesUpdateForm';
import { UiNotesDeleteForm } from './UiNotesDeleteForm';
import { UiNotesFormValidator } from './UiNotesFormValidator';

class UiNotes extends React.Component {
  constructor(props) {
    super(props);
    props.fetchNotes();

    this.renderItem = this.renderItem.bind(this);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);

    this.handleCreateClick = this.handleCreateClick.bind(this);
    this.handleCreateCancel = this.handleCreateCancel.bind(this);
    this.handleCreateSubmit = this.handleCreateSubmit.bind(this);

    this.handleUpdateClick = this.handleUpdateClick.bind(this);
    this.handleUpdateCancel = this.handleUpdateCancel.bind(this);
    this.handleUpdateSubmit = this.handleUpdateSubmit.bind(this);

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
    this.handleDeleteSubmit = this.handleDeleteSubmit.bind(this);

    this.state = {
      page: 1,
      isCreating: false,
      updatingNote: null,
      deletingNote: null,
    };

    this.pageSize = 25;
    this.sortableFields = [
      {
        key: 'type',
        label: 'Typ',
        sortFn: (item) => item.type ? item.type.text[props.language] : '',
      },
      {
        key: 'active',
        label: 'Aktiv',
        sortFn: (item) => item.enabled,
      },
      {
        key: 'publishDate',
        label: 'Publikationsdatum',
        sortFn: (item) => item.publishDate,
      },
      {
        key: `title.${props.language}`,
        label: 'Titel',
        sortFn: (item) => item.title ? item.title[props.language] : '',
      },
      {
        key: 'action',
        label: (
          <a className="ui-notes__button" onClick={() => this.handleCreateClick()}>
            <span className="icon-061-plus"/>
          </a>
        ),
        sortFn: undefined,
      },
    ];
  }

  renderNotes() {
    const { notes } = this.props;
    if (!notes) {
      return null;
    }

    const { page } = this.state;
    const items = notes.slice((page - 1) * this.pageSize, page * this.pageSize);

    return (
      <div className="ui-notes">
        <SortableTable
          className="ui-notes__table"
          items={items}
          renderItem={this.renderItem}
          sortableFields={this.sortableFields}
          onSort={this.handleSortChange}
        />
        {this.renderPagination()}

        {this.renderCreateModal()}
        {this.renderUpdateModal()}
        {this.renderDeleteModal()}
      </div>
    );
  }

  renderItem(note) {
    const { language } = this.props;
    return (
      <TableRow key={note.id} className="ui-notes__row">
        <TableCell className="ui-notes__type">
          {note.type ? note.type.text[language] : null}
        </TableCell>
        <TableCell className="ui-notes__active">
          {note.enabled ? 'Yes' : 'No'}
        </TableCell>
        <TableCell className="ui-notes__date">
          {note.publishDate
           ? <FormattedDate value={note.publishDate} format="DD.MM.YYYY"/>
           : 'Immediate'}
        </TableCell>
        <TableCell className="ui-notes__title">
          {note.title ? note.title[language] : null}
        </TableCell>
        <TableCell className="ui-notes__action">
          <a className="ui-notes__button" onClick={() => this.handleUpdateClick(note)}>
            <span className="icon-025-edit"/>
          </a>
          <a className="ui-notes__button" onClick={() => this.handleDeleteClick(note)}>
            <span className="icon-008-bin"/>
          </a>
        </TableCell>
      </TableRow>
    );
  }


  renderPagination() {
    const { notes } = this.props;
    const { page } = this.state;

    const pageCount = Math.ceil(notes.length / this.pageSize);
    if (pageCount < 2) {
      return null;
    }

    return (
      <div className="ui-notes__pagination">
        <Pagination
          prev={true}
          next={true}
          first={true}
          last={true}
          boundaryLinks={true}
          ellipsis={true}
          items={pageCount}
          maxButtons={7}
          activePage={page}
          onSelect={this.handlePageChange}
        />
      </div>
    );
  }

  renderCreateModal() {
    const { types, language } = this.props;
    const { isCreating } = this.state;

    return (
      <ModalDialog
        show={isCreating}
        keyboard={true}
        backdrop="static"
        onHide={this.handleCreateCancel}
        className="ui-notes__modal"
      >
        <UiNotesCreateForm
          form="ui-note-create-form"
          initialValues={this.getCreateFormValues()}
          validate={UiNotesFormValidator}
          types={types}
          language={language}
          onCancel={this.handleCreateCancel}
          onSubmit={this.handleCreateSubmit}
        />
      </ModalDialog>
    );
  }

  renderUpdateModal() {
    const { types, language } = this.props;
    const { updatingNote } = this.state;

    return (
      <ModalDialog
        show={updatingNote !== null}
        keyboard={true}
        backdrop="static"
        onHide={this.handleUpdateCancel}
        className="ui-notes__modal"
      >
        <TransitionGroup>
          {updatingNote && (
            <UiNotesUpdateForm
              form="ui-note-update-form"
              initialValues={this.getUpdateFormValues(updatingNote)}
              validate={UiNotesFormValidator}
              types={types}
              language={language}
              onCancel={this.handleUpdateCancel}
              onSubmit={this.handleUpdateSubmit}
            />
          )}
        </TransitionGroup>
      </ModalDialog>
    );
  }

  renderDeleteModal() {
    const { deletingNote } = this.state;

    return (
      <ModalDialog show={deletingNote !== null} onHide={this.handleDeleteCancel}>
        <TransitionGroup>
          {deletingNote && (
            <UiNotesDeleteForm
              onCancel={this.handleDeleteCancel}
              onSubmit={this.handleDeleteSubmit}
            />
          )}
        </TransitionGroup>
      </ModalDialog>
    );
  }

  handlePageChange(page) {
    this.setState({ page });
  }

  handleSortChange(sortField, sortType) {


    this.props.fetchNotes({sortField, sortType})
    this.setState({ page: 1 });
  }

  handleCreateClick() {
    this.setState({ isCreating: true });
  }

  handleCreateCancel() {
    this.setState({ isCreating: false });
  }

  handleCreateSubmit(form) {
    const { createNote } = this.props;
    createNote({ form });

    this.setState({ isCreating: false });
  }

  handleUpdateClick(note) {
    this.setState({ updatingNote: note });
  }

  handleUpdateCancel() {
    this.setState({ updatingNote: null });
  }

  handleUpdateSubmit(form) {
    const { updatingNote } = this.state;
    const { updateNote } = this.props;
    updateNote({ form, note: updatingNote });

    this.setState({ updatingNote: null });
  }


  handleDeleteClick(note) {
    this.setState({ deletingNote: note });
  }

  handleDeleteCancel() {
    this.setState({ deletingNote: null });
  }

  handleDeleteSubmit() {
    const { deletingNote } = this.state;
    const { deleteNote } = this.props;
    deleteNote({ note: deletingNote });

    this.setState({ deletingNote: null });
  }

  getCreateFormValues() {
    return {
      enabled: false,
      typeId: undefined,
      publishDate: undefined,
      title: "",
      text: "",
      image: null,
    };
  }

  getUpdateFormValues(note) {
    const { language } = this.props;

    return {
      enabled: note.enabled,
      typeId: note.type ? note.type.id : undefined,
      publishDate: note.publishDate ? convertDateToApp(note.publishDate) : undefined,
      title: note.title ? note.title[language] : '',
      text: note.text ? note.text[language] : '',
      image: note.image ? note.image.$ref : null,
    };
  }

  render() {
    const { loading, saving } = this.props;

    return (
      <PageContainer
        requesting={loading || saving}
        title="Release News/Systemmeldungen"
        isTransparentLoader={!loading}
      >
        {this.renderNotes()}
      </PageContainer>
    );
  }
}

UiNotes.propTypes = {
  language: PropTypes.string.isRequired,

  notes: PropTypes.arrayOf(UiNotesShape),
  types: PropTypes.arrayOf(UiNotesTypeShape),

  loading: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,

  fetchNotes: PropTypes.func.isRequired,
  createNote: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
};

export default UiNotes;

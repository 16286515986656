import './BulkLoadsDetailsChart.css';

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { BULK_LOAD_PROP_TYPES_RESULT_ITEM } from './BulkLoadsPropTypes';
import { BULK_LOADS_CHART_COLORS } from '../../constants/BulkLoadsConstants';
import { DATE_TIME_FORMAT } from '../../constants/DateTime';

export class BulkLoadsDetailsChart extends React.PureComponent {
  state = {
    className: '',
  };

  render() {
    return (
      <div className="bulk-loads-details-chart">
        <HighchartsReact
          highcharts={Highcharts}
          constructorType="ganttChart"
          options={this.createOptions()}
          immutable={true}
          containerProps={{className: 'bulk-loads-details-chart__chart-wrapper'}}
        />
      </div>
    );
  }

  createOptions() {
    const { item } = this.props;
    const startDates = item.actions.map((it) => it.start).filter(Boolean);
    const endDates = item.actions.map((it) => it.end).filter(Boolean);
    const maxDate = moment.max([...endDates, ...startDates]);
    const data = item.actions.map((it) => ({
      actionId: it.id,
      name: it.label,
      start: it.start
        ? it.start.valueOf()
        : undefined,
      end: it.end
        ? it.end.valueOf()
        : maxDate.valueOf(),
      color: BULK_LOADS_CHART_COLORS[it.result === 'error' ? it.result : it.state],

      // data for tooltips
      working: it.start,
      done: it.end,
    }));

    const { className } = this.state;
    const _this = this;

    return {
      title: {
        text: '',
      },
      chart: {
        animation: false,
        className: 'bulk-loads-details-chart__chart'
      },
      credits: {
        enabled: false,
      },
      time: {
        useUTC: false,
      },
      xAxis: [{
        type: 'datetime',
        gridLineWidth: 1,
        gridLineDashStyle: 'LongDash',
        dateTimeLabelFormats: {
          day: '%d.%m.%Y',
          week: '%d.%m.%Y',
          hour: '%d.%m - %H:%M',
        },
        opposite: false,
        grid: {
          borderColor: 'transparent',
        },
        startOnTick:true,
        endOnTick: true,
        labels: {
          align: 'center',
          style: {
            fontSize: '11px',
            textOverflow: 'none',
            whiteSpace: 'nowrap',
          },
          useHTML: true,
        },
        className: className,
        tickPositioner: function () {
          if (this.tickPositions.length > 15) {
            _this.setState({ className: 'bulk-loads-details-chart__rotate-labels' });
          } else if (className) {
            _this.setState({ className: '' });
          }
          return this.tickPositions;
        },
        offset: -10,
      }],
      yAxis: {
        staticScale: 35,
        visible: false,
      },
      plotOptions: {
        series: {
          clip: false,
          dataLabels: {
            enabled: true,
            format: '{point.name}',
            style: {
              cursor: 'pointer',
              color: 'black',
            },
            align: 'left',
            padding: 2,
            adjustPosition: true,
          },
          tooltip: {
            pointFormatter: function () {
              const point = this;
              return [
                '<b>' + (point.name) + '</b>',
                point.working
                  ? `Start: ${point.working.format(DATE_TIME_FORMAT)}`
                  : undefined,
                point.done
                  ? `End: ${point.done.format(DATE_TIME_FORMAT)}`
                  : undefined,
              ].filter(Boolean).join('<br/>');
            },
          },
        },
      },

      series: [{
        animation: false,
        cursor: 'pointer',
        name: '',
        borderRadius: 0,
        pointWidth: 20,
        minPointLength: 10,
        data: _.orderBy(data, ['start'], ['desc']),
        point: {
          events: {
            click: (event) => {
              const { onSelect } = this.props;
              onSelect(event.point.actionId);
            },
          },
        },
      }],
    };
  }
}

BulkLoadsDetailsChart.propTypes = {
  item: BULK_LOAD_PROP_TYPES_RESULT_ITEM.isRequired,
  onSelect: PropTypes.func.isRequired,
};

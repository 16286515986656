import { all, put } from 'redux-saga/effects';
import csvtojson from 'csvtojson';
import {
  LEADS_SWITCH_IMPORT_CSV, LEADS_SWITCH_IMPORT_IMPORT,
} from '../../actions/LeadsSwitchImportActions';
import axios from 'axios';
import { EndpointName } from '../../constants/EndpointName';
import { LONG_REQUEST_ITEM_COUNT } from '../../constants/LeadsSwitchImport';

const reader = new FileReader();

export function* leadsSwitchImportCsvSaga(action) {

  try {
    yield put(LEADS_SWITCH_IMPORT_CSV.pending());
    yield put(LEADS_SWITCH_IMPORT_IMPORT.pending());
    const { file } = action.data;

    const fileContent = yield readFile(file);

    let rows = yield csvtojson({
      noheader: true,
      output: 'csv',
      delimiter: [";"],
    }).fromString(fileContent);
    rows = rows.splice(1);

    let customerNumbers = [];
    rows.forEach(row => {
      customerNumbers.push(row[1]);
      customerNumbers.push(row[4]);
      customerNumbers.push(row[5]);
    });
    customerNumbers = [ ...new Set(customerNumbers) ];

    // fetch Customers
    const customerNumbersSplited = [];
    for (let i = 0; i < Math.ceil(customerNumbers.length / LONG_REQUEST_ITEM_COUNT); i++) {
      const start = i * LONG_REQUEST_ITEM_COUNT;
      customerNumbersSplited[i] = customerNumbers.slice(start, start + LONG_REQUEST_ITEM_COUNT);
    }
    let customerList = [];
    let apiError = undefined;
    yield all(
      customerNumbersSplited.map((chunk) => new Promise((resolve, reject) => {
        axios({
          url: `${EndpointName.PERSON_CUSTOMER}/?in(customerNumber,(${chunk.map(number => `string:${number}`).join(',')}))&limit(999999)`,
          method: 'get',
        }).then(response => {
          customerList = customerList.concat(response.data);
          resolve(response);
        }).catch(error => {
          console.error(error);
          apiError = error;
          resolve(error);
        });
      }))
      );
      const customerByNumberMap = {};
      customerList.forEach(customer => {
        customerByNumberMap[customer.customerNumber] = customer;
      });

    // validation
    const columnsMap = {
      1: {
        fieldName: 'kundenNr', // customerNumber
        validate: column => customerByNumberMap[column],
        errorText: 'notFound',
      },
      4: {
        fieldName: 'beraterAlt', // current consultant.username
        validate: column => customerByNumberMap[column],
        errorText: 'notFound',
      },
      5: {
        fieldName: 'beraterNeu', // new consultant.username
        validate: column => customerByNumberMap[column],
        errorText: 'notFound',
      },
    };
    const errors = [];
    rows.forEach((row, lineNumber) => {
      row.forEach((column, columnNumber) => {
        const columnInfo = columnsMap[columnNumber];

        if (columnInfo && !columnInfo.validate(column, row)) {
          errors.push({
            lineNumber: lineNumber + 1,
            columnNumber: columnNumber + 1,
            fieldName: columnInfo.fieldName,
            errorText: columnInfo.errorText,
          });
        }
      });
    });

    if (errors.length > 0) {
      const error = { errors, apiError };
      throw error;
    }

    yield put(LEADS_SWITCH_IMPORT_CSV.success({ rows: rows.map(row => ({
        customer: customerByNumberMap[row[1]],
        consultant: customerByNumberMap[row[4]],
        consultantNew: customerByNumberMap[row[5]],
      }))
    }));

  } catch ({ errors, apiError }) {
    yield put(LEADS_SWITCH_IMPORT_CSV.failure({ apiError, errors }));
  }
}


const readFile = file => new Promise((resolve, reject) => {
  try {
    reader.readAsBinaryString(file);
    reader.onloadend = evt => {
      if (evt.target.readyState === FileReader.DONE) {
        resolve(evt.target.result);
      }
    };
  } catch (error) {
    reject(error);
  }
});

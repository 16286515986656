import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {
  REDUCER_STATUS_PENDING, REDUCER_STATUS_PROGRESS,
} from '../../constants/ReducerStatuses';


export class NoticeImportProgress extends React.Component {

  render() {
    const { progressResult } = this.props;
    if (!progressResult || !progressResult.status) {
      return null;
    }
    const { status, results } = progressResult;

    let resultNow = 0;
    let progressPercent = 0;
    if (status === REDUCER_STATUS_PROGRESS) {
      resultNow = results.success + results.fail;
      progressPercent = Math.round(resultNow / results.total * 100);
    }

    return (
      <div>
        {status === REDUCER_STATUS_PROGRESS &&
        <div>
          <FormattedMessage id={`noticeImport_status_${results.action}`} />
          {'\u00A0'}{results.success}
          {'\u00A0'}<FormattedMessage id={`noticeImport_status_from`} /> {results.total}
          {results.fail > 0 &&
          <span> (<FormattedMessage id={`noticeImport_status_fail`} /> {results.fail})</span>
          }
        </div>
         }
        {(status === REDUCER_STATUS_PENDING || status === REDUCER_STATUS_PROGRESS) &&
        <ProgressBar
          now={progressPercent}
          label={`${progressPercent}%`}
        />
        }
      </div>
    );
  }
}

NoticeImportProgress.propTypes = {
  progressResult: PropTypes.object,
};

import React from 'react';
import PropTypes from 'prop-types';
import { get, isUndefined } from 'lodash';
import { FormGroup, FormControl, ControlLabel, Tabs, Tab, Col } from 'react-bootstrap';
import update from 'immutability-helper';

class DynamicCheckConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'de'
    };

    this.setActiveTab = this.setActiveTab.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
  }

  onMessageChange(fieldId, value, language) {
    const { id, item, editValueField } = this.props;
    const currentOptions = get(item, 'props.options', []);
    let updatedOptions = [ ...currentOptions ];

    const index = currentOptions.findIndex(o => get(o, 'value') === fieldId);
    const currentOption = get(currentOptions, index);
    const updatedOption = !isUndefined(currentOption)
      ? update(currentOption, { label: { [language]: { $set: value } } })
      : { value: fieldId, label: { [language]: value } };

    if (index > -1) updatedOptions = update(currentOptions, {
      $splice: [[index, 1]]
    });

    updatedOptions = update(updatedOptions, {
      $push: [updatedOption]
    })

    editValueField(id, 'componentOptions', updatedOptions)
  }

  onConditionChange(fieldId, value) {
    const { id, item, editValueField, languages } = this.props;
    const currentOptions = get(item, 'props.options', []);
    let updatedOptions = [ ...currentOptions ];
    const index = updatedOptions.findIndex(o => get(o, 'label.de') === fieldId);

    let option = languages.reduce((result, language) => {
      result.label[language] = fieldId;
      return result;
    }, { label: {} });

    option = { ...option, value: value };

    if (index > -1) updatedOptions = update(updatedOptions, {
      $splice: [[index, 1]]
    });

    updatedOptions = update(updatedOptions, {
      $push: [option]
    });

    editValueField(id, 'componentOptions', updatedOptions);
  }

  setActiveTab(language) {
    this.setState({ activeTab: language });
  }

  renderTabs() {
    const { item, languages } = this.props;
    const options = get(item, 'props.options', []);
    const successOption = options.find(o => get(o, 'value') === 'successLabel');
    const errorOption= options.find(o => get(o, 'value') === 'errorLabel');

    const tabs = languages.map(l => (
      <Tab key={l} eventKey={l} title={l}>
        <FormGroup>
          <ControlLabel>Meldung wenn erfüllt </ControlLabel>
          <FormControl
            value={get(successOption, `label.${l}` ,'')}
            type="text"
            onChange={ev => this.onMessageChange('successLabel', get(ev, 'target.value'), l)}
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>Meldung wenn nicht erfüllt</ControlLabel>
          <FormControl
            value={get(errorOption, `label.${l}`, '')}
            type="text"
            onChange={ev => this.onMessageChange('errorLabel', get(ev, 'target.value'), l)}
          />
        </FormGroup>
      </Tab>
    ));

    return tabs;
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { activeTab } = this.state;
    const { item } = this.props;

    const path = get(item, 'props.options', []).find(o => get(o, 'label.de') === 'path');
    const equals = get(item, 'props.options', []).find(o => get(o, 'label.de') === 'equals');

    return(
      <React.Fragment>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Konfiguration
          </Col>

          <Col sm={9}>
            <FormGroup>
              <ControlLabel>Pfad zu Wert</ControlLabel>
              <p>
                Es können alle Werte aus dem aktuellen State als Pfad verwendet werden (z.B. powersearch.customer.firstName). <br/>
                Zudem stehen die aktuellen Form Daten ebenfalls zur Verfügung (z.B. formData.meinFeld.value)
              </p>
              <FormControl
                type="text"
                value={get(path, `value`, '')}
                onChange={ev => this.onConditionChange('path', get(ev, 'target.value'))}
              />
            </FormGroup>

            <FormGroup>
              <ControlLabel>Entspricht</ControlLabel>
              <FormControl
                type="text"
                value={get(equals, 'value', '')}
                onChange={ev => this.onConditionChange('equals', get(ev, 'target.value'))}
              />
            </FormGroup>

            <Tabs
              id="config-title"
              activeKey={activeTab}
              defaultActiveKey="de"
              onSelect={this.setActiveTab}
            >
              {this.renderTabs()}
            </Tabs>
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

DynamicCheckConfiguration.propTypes = {
  languages: PropTypes.array
};

DynamicCheckConfiguration.defaultProps = {
  languages: ['de', 'fr', 'en']
};

export default DynamicCheckConfiguration;

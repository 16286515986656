import { FINANCING_PROFILING_UPDATE_ACTIONS } from '../../actions/FinancingProfilingActions';

export function FinancingProfilingUpdateReducer(state, action) {
  switch (action.type) {
    case FINANCING_PROFILING_UPDATE_ACTIONS.SUCCESS:
      const { profiles } = action.data;
      return { ...state, profiles };
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import { TableCell, TableRow } from '../Common/Table';
import { FormattedDate } from '../Common/FormattedDate';
import { classList } from '../../utils/classList';

import './ExecutedJobsResultsItem.css';
import './ExecutedJobsHistoryItem.css';

const ExecutedJobsHistoryItem = props => {
    const { item } = props;
    const messageTooltip = (
        <Tooltip id={`message-tooltip-${item.id}`}>
            {item.message}
        </Tooltip>
    );
    const booleanIcons = classList({
        'icon-012-check-mark-circle': item.success === true,
        'green': item.success === true,
        'icon-202-clear-circle': item.success === false,
        'red': item.success === false,
        'icon-size': true
    });

    return (
        <React.Fragment>
            <TableRow>
                <TableCell className="job-processing-results-item__success">
                    <span className={booleanIcons}></span>
                </TableCell>
                <TableCell className="job-processing-results-item__createDate">
                    {item.createDate ? <FormattedDate value={item.createDate} format="DD.MM.YYYY HH:mm:ss" /> : '-'}
                </TableCell>
                <TableCell className="job-processing-results-item__message">
                    <OverlayTrigger placement="top" overlay={messageTooltip}>
                        <p className={'job-processing-history__ellpsis'}>
                            {item.message ? item.message : '-'}
                        </p>
                    </OverlayTrigger>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

ExecutedJobsHistoryItem.propTypes = {
    item: PropTypes.object,
    language: PropTypes.string,
    intl: PropTypes.object
};

export default ExecutedJobsHistoryItem;
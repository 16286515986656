import './ExternalLinkList.css';

import React from 'react';
import { ExternalLinkItem } from './ExternalLinkItem';
import { SortableList } from '../Common/SortableList';
import { Button } from '../Common/Button';

export function ExternalLinkList({ links, language, onChange, onCreate, onRemove, onSortEnd, categories }) {
  return (
    <div className="external-links-list">
      <div className="external-links-list__header">
        <div className="external-links-list__text">Name</div>
        <div className="external-links-list__text-short">Link</div>
      </div>
      <SortableList onSortEnd={onSortEnd}>
        {links.map((link, index) => (
          <ExternalLinkItem
            key={link.id}
            index={index}
            id={index}
            link={link}
            language={language}
            onChange={onChange}
            onRemove={onRemove}
            categories={categories}
          />
        ))}
      </SortableList>
      <div className="external-links-list__actions">
        <Button bsStyle="primary" name="newItem" onClick={onCreate}>
          Neuer Eintrag
        </Button>
      </div>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, sortBy } from 'lodash';
import moment from 'moment';

import LoadsChart from './LoadsChart';
import LoadObjectChart from './LoadObjectChart';
import { BulkLoadsExpandContent } from '../BulkLoadsExpandContent';
import { getMemoizedLoadColors } from './utils';

class BulkLoadsMetrcisOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deselectedLoads: []
    };

    this.onLoadSelectionChange = this.onLoadSelectionChange.bind(this);
  }

  onLoadSelectionChange(loadId) {
    const { deselectedLoads } = this.state;

    const updated = deselectedLoads.includes(loadId)
      ? deselectedLoads.filter((id) => id !== loadId)
      : [...deselectedLoads, loadId];

    this.setState({ deselectedLoads: updated });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { deselectedLoads } = this.state;
    const {
      loadColors,
      results
    } = this.props;

    const loadIds = get(results, 'loads', []).map((l) => get(l, 'id'));
    const selectedLoadIds = get(deselectedLoads, 'length', 0) > 0
      ? loadIds.filter((id) => !deselectedLoads.includes(id))
      : loadIds;

    return (
      <div className="bulk-loads-metrics-overview">
        <BulkLoadsExpandContent title="Zeitreihe (Metriken)">
          <div style={{ paddingBottom: '40px' }} />
          <strong>
            Übersicht Load
          </strong>

          <LoadsChart
            loadColors={loadColors}
            onLoadSelectionChange={this.onLoadSelectionChange}
            results={results}
          />

          <div style={{ paddingBottom: '40px' }} />

          <strong>
            Übersicht Loadobjekte
          </strong>

          <div style={{ paddingBottom: '20px' }} />

          <LoadObjectChart
            loadColors={loadColors}
            loadIds={selectedLoadIds}
            results={results}
          />
        </BulkLoadsExpandContent>
      </div>
    );
  }
}

BulkLoadsMetrcisOverview.propTypes = {
  loadColors: PropTypes.object,
  results: PropTypes.object
};

BulkLoadsMetrcisOverview.defaultProps = {
  loadColors: {},
  results: {}
};

function mapStateToProps(state, ownProps) {
  const results = get(state, 'bulkLoads.results', {});
  const loadIds = get(results, 'loads', []).map((l) => get(l, 'id'));

  return {
    loadColors: getMemoizedLoadColors(loadIds),
    results: {
      ...results,
      loads: sortBy(get(results, 'loads', []), [(o) => moment(o.id, 'YYYYMMDDHHmmssSSS')])
    }
  };
}

export default connect(mapStateToProps)(BulkLoadsMetrcisOverview);

import './RefErrorsChart.css';

import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FormattedMessage, injectIntl } from 'react-intl';
import { REF_ERRORS_PROP_TYPES_RESULT_ITEM } from './RefErrorsPropTypes';
import { RefErrorsExpandContent } from './RefErrorsExpandContent';

class RefErrorsChartWithIntl extends React.PureComponent {
  render() {
    const { results } = this.props;
    if (!results.length) {
      return null;
    }

    return (
      <div className="ref-errors-chart">
        <RefErrorsExpandContent title={<FormattedMessage id="refErrors_chart_title"/>}>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType="chart"
            options={this.createOptions()}
            immutable={true}
          />
        </RefErrorsExpandContent>
      </div>
    );
  }

  createOptions() {
    const { intl } = this.props;
    const { categories, data } = this.prepareData();
    return {
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      chart: {
        animation: false,
        type: 'bar',
      },
      legend: false,
      tooltip: {
        headerFormat: [
          '<span style="font-size: 13px">',
          intl.formatMessage({ id: 'refErrors_chart_tooltip' }),
          '<b>{point.key}</b></span><br/>'
        ].join(''),
        pointFormat: '{series.name}: <b>{point.y}</b><br/>',
      },
      xAxis: {
        title: {
          text: '',
        },
        categories,
        labels: {
          style: {
            fontSize: '13px',
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: intl.formatMessage({ id: 'refErrors_chart_yAxis' }),
          align: 'high',
        },
        labels: {
          overflow: 'justify',
        },
      },
      series: [{
        animation: false,
        cursor: 'pointer',
        name: 'Errors',
        pointWidth: 30,
        minPointLength: 10,
        data: data,
        point: {
          events: {
            click: (event) => {
              const { onSelect } = this.props;
              onSelect(event.point.category);
            },
          },
        },
      }],
    };
  }

  prepareData() {
    const { results } = this.props;
    const groupedByCollection = results.reduce((result, it) => {
      const key = it.collectionName;
      result[key] = it.refErrors.length + (result[key] ? result[key] : 0);
      return result;
    }, {});
    const categories = Object.keys(groupedByCollection);

    return {
      categories,
      data: categories.map((category) => ({
        category: category,
        y: groupedByCollection[category],
      })),
    };
  }
}

export const RefErrorsChart = injectIntl(RefErrorsChartWithIntl);
RefErrorsChart.propTypes = {
  results: PropTypes.arrayOf(REF_ERRORS_PROP_TYPES_RESULT_ITEM),
  onSelect: PropTypes.func.isRequired,
};

import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import {
  API_CLIENT_EXECUTE,
  API_CLIENT_LOAD,
  API_CLIENT_LOAD_SERVICE,
  API_CLIENT_LOGIN,
} from '../actions/ApiClientActions';
import { API_CLIENT_FORM, ApiClient } from '../components/ApiClient/ApiClient';
import { getApiClientTexts } from '../selectors/ApiClient/apiClientTextSelector';
import {
  getApiClientError,
  getApiClientExecuting,
  getApiClientIsAuthorized,
  getApiClientLoading,
  getApiClientLoadingService,
  getApiClientSchema,
  getApiClientResult,
} from '../selectors/ApiClient/apiClientCommonSelectors';
import { getApiClientEndpointOptions } from '../selectors/ApiClient/apiClientEndpointOptionsSelector';

const mapStateToProps = createStructuredSelector({
  loading: getApiClientLoading,
  loadingService: getApiClientLoadingService,
  executing: getApiClientExecuting,
  texts: getApiClientTexts,
  error: getApiClientError,
  isAuthorized: getApiClientIsAuthorized,
  endpoints: getApiClientEndpointOptions,
  currentValues: getFormValues(API_CLIENT_FORM),
  schema: getApiClientSchema,
  result: getApiClientResult,
});

const mapDispatchToProps = {
  load: () => API_CLIENT_LOAD.request(),
  login: (formData) => API_CLIENT_LOGIN.request({ password: formData.password }),
  execute: (options) => API_CLIENT_EXECUTE.request(options),
  loadService: (service) => API_CLIENT_LOAD_SERVICE.request({ service }),
};

export const ApiClientPage = connect(mapStateToProps, mapDispatchToProps)(ApiClient);

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { ListGroup, Button } from 'react-bootstrap';

import Option from './Option';

const SortableItem = SortableElement(({ position, value, label, language, languages, onChange, onRemove }) => {
  return (
    <div className="SortableHOCItem above-the-clouds">
      <Option
        value={value}
        label={label}
        language={language}
        languages={languages}
        onChange={onChange}
        onRemove={onRemove}
        index={position}
      />
    </div>
  );
});

const SortableList = SortableContainer(({ options, language, languages, onChange, onRemove }) => {
  const items = options.map((o, index) => (
    <SortableItem
      id={o.value}
      key={o.value}
      index={index}
      position={index}
      value={o.value}
      label={o.label}
      language={language}
      languages={languages}
      onChange={onChange}
      onRemove={onRemove}
    />
  ));

  return (
    <ListGroup>{items}</ListGroup>
  );
});


class OptionList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false
    };

    this.onChange = this.onChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { options, onChange } = this.props;
    let updated = [ ...options ];

    updated = arrayMove(updated, oldIndex, newIndex);
    onChange(updated);
  }

  onChange(index, option) {
    const { options, onChange } = this.props;
    const updated = [ ...options ];

    updated.splice(index, 1, option);
    onChange(updated);
  }

  onRemove(index) {
    const { options, onChange } = this.props;
    let updated = [ ...options ];

    updated.splice(index, 1);
    onChange(updated);
  }

  onAdd(index, option) {
    const { options, onChange } = this.props;
    let updated = [ ...options, option ];

    onChange(updated);
    this.setState({ edit: false });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { options, language, languages } = this.props;

    return(
      <div>
        <SortableList
          distance={10}
          options={options}
          language={language}
          languages={languages}
          onChange={this.onChange}
          onRemove={this.onRemove}
          onSortEnd={this.onSortEnd}
        />

        {(this.state.edit) ? (
          <Option
            languages={languages}
            onChange={this.onAdd}
            edit={true}
            index={-1}
            onCancel={() => this.setState({ edit: false })}
          />
        ) : null}

        <div style={{ textAlign: 'right' }}>
          <Button bsStyle="primary" onClick={() => this.setState({ edit: true })}>
            <i className="glyphicon glyphicon-plus" />
          </Button>
        </div>
      </div>
    );
  }
}

OptionList.propTypes = {
  options: PropTypes.array,
  language: PropTypes.string,
  languages: PropTypes.array,
  onChange: PropTypes.func
};

OptionList.defaultProps = {
  options: [],
  language: 'de',
  languages: ['de', 'fr', 'en'],
  onChange: _.noop
};

export default OptionList;

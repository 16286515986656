import React from 'react';
import PropTypes from 'prop-types';
import _, { get } from 'lodash';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';
import Toggle from 'react-toggle';
import Select from 'react-select';

const inputTypes = [{
  value: 'input',
  label: 'Standard'
}, {
  value: 'permission',
  label: 'Mit Auswahl der Berechtigung pro Konto'
}];

const PersonalAndPowerOfAttorneyAccounts = ({ id, item, editValueField }) => {
  function onTypeChange(selected) {
    const { value } = selected;

    editValueField(id, 'prop', { key: 'type', value });
  }

  const onlyActiveItems = get(item, 'props.onlyActiveItems', false);

  return (
    <React.Fragment>
      <FormGroup>
        <Col componentClass={ControlLabel} sm={3}>
          Eingabefeld Art
        </Col>
        <Col sm={9}>
          <Select
            placeholder="Eingabefeld Ausprägung wählen"
            clearable={false}
            name={_.get(item, 'props.id')}
            options={inputTypes}
            value={item.props.type}
            onChange={onTypeChange}
          />
        </Col>
      </FormGroup>

      <FormGroup controlId="codeGroupMulti">
        <Col componentClass={ControlLabel} sm={3}>
          Mehrfachauswahl
        </Col>
        <Col sm={9} style={{ paddingTop: '10px' }}>
          <Toggle
            checked={_.get(item, 'props.multi') === undefined ? false : item.props.multi}
            onChange={() => editValueField(id, 'multi')}
          />
        </Col>
      </FormGroup>

      <FormGroup controlId="onlyActiveItems">
        <Col componentClass={ControlLabel} sm={3}>
          Nur aktive Konten
        </Col>
        <Col sm={9} style={{ paddingTop: '10px' }}>
          <Toggle
            checked={onlyActiveItems}
            onChange={() => editValueField(id, 'prop', { key: 'onlyActiveItems', value: !onlyActiveItems })}
          />
        </Col>
      </FormGroup>
    </React.Fragment>
  );
};

PersonalAndPowerOfAttorneyAccounts.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  item: PropTypes.object.isRequired,
  editValueField: PropTypes.func
};

PersonalAndPowerOfAttorneyAccounts.defaultProps = {
  editValueField: _.noop
};

export default PersonalAndPowerOfAttorneyAccounts;

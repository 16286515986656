import React from 'react';
import { connect } from 'react-redux';
import { isFunction } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';

import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Spinner from '../components/Spinner';
import ToastMessage from '../components/ToastMessage'

class LayoutWithState extends React.Component {

  render() {
    const { loading } = this.props;
    const toastData = this.props.toast;
    if (loading) {
      return <Spinner />;
    }

    if (toastData && isFunction(toast[toastData.kind])) {
      toast[toastData.kind](
        <ToastMessage
          title={toastData.title}
          description={toastData.description}
          values={toastData.values}
          html={toastData.html}
          onClick={toastData.onClick}
        />
      );
    }

    return (
      <div>
        <ToastContainer
          position="top-right"
          type="default"
          autoClose={15000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnHover
          style={{ zIndex: '99999' }}
        />
        <Header />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.config.loading,
    toast: state.toast
  };
}

export default connect(mapStateToProps)(LayoutWithState);

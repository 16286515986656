import { call, put } from 'redux-saga/effects';
import { has, get, slice } from 'lodash';
import QueryBuilder from '@evoja-web/rql-query-builder';
import moment from 'moment';
import axios from 'axios';

import executedAction from '../../actions/JobProcessing/Executed';
import { JOB_PROCESSING_PAGE_SIZE } from '../../constants/JobProcessingConstants';

const {
  EXECUTED_PENDING,
  EXECUTED_FULFILLED,
  EXECUTED_REJECTED
} = executedAction;

function prepareQuery(request = {}) {
  const builder = QueryBuilder();

  if (get(request, 'filters.job.length', 0) > 0) {
    builder.like('job.type', get(request, 'filters.job', '').slice(1));
  }

  if (has(request, 'filters.timespan.startDate')) {
    builder.ge('createDate', moment(get(request, 'filters.timespan.startDate')).startOf('day').utc().format())
  }

  if (has(request, 'filters.timespan.endDate')) {
    builder.le('createDate', moment(get(request, 'filters.timespan.endDate')).endOf('day').utc().format())
  }

  if (has(request, 'filters.customer')) {
    builder.eq('jobDetails.customer', get(request, 'filters.customer'), { type: 'string' })
  }

  if (has(request, 'filters.success')) {
    builder.eq('success', get(request, 'filters.success'));
  }

  if (has(request, 'filters.since')) {
    builder.ge('createDate', moment(get(request, 'filters.since')).utc().format())
  }

  builder.sort([`${get(request, 'sort.sortType', 'desc') === 'desc' ? '-' : '+'}${get(request, 'sort.sortField', 'createDate')}`])
  builder.limit(get(request, 'limit', 10), get(request, 'offset', 0));

  return builder.getQueryString();
}

export default function* getExecuted(request) {
  yield put({ type: EXECUTED_PENDING });

  try {
    const {
      data = [],
      limit = JOB_PROCESSING_PAGE_SIZE,
      offset
    } = request;

    const chunk = slice(data, offset, limit + offset);

    // If we already have data for the given chunk, return current data but update limit / offset
    if (get(chunk, 'length', 0) > 0) {
      yield put({ type: EXECUTED_FULFILLED, payload: data, limit, offset });
      return data;
    }

    const result = yield call(axios, {
      url: `/event/status/worker/job-processing/${prepareQuery(request)}`,
      method: 'get'
    });

    const payload = [...data, ...get(result, 'data', [])];

    yield put({ type: EXECUTED_FULFILLED, payload, limit, offset });

    return payload;
  } catch (error) {
    yield put({ type: EXECUTED_REJECTED, error });

    return error;
  }
}

import './AnalyticsUserSummary.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PageContainer from '../Common/PageContainer';
import { PROP_TYPES_BANK } from './AnalyticsUserSummaryPropTypes';
import { AnalyticsUserSummaryExportForm } from './AnalyticsUserSummaryExportForm';

export class AnalyticsUserSummary extends React.Component {
  constructor(props) {
    super(props);
    props.load();
  }

  render() {
    const { loading, exporting, banks } = this.props;

    return (
      <PageContainer title={<FormattedMessage id="analyticsUserSummary_title"/>} requesting={loading}>
        <div className="analytics-user-summary">
          <AnalyticsUserSummaryExportForm
            banks={banks}
            exporting={exporting}
            onExport={this.props.export}
          />
        </div>
      </PageContainer>
    );
  }
}

AnalyticsUserSummary.propTypes = {
  locale: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  exporting: PropTypes.bool.isRequired,
  banks: PropTypes.arrayOf(PROP_TYPES_BANK).isRequired,
  load: PropTypes.func.isRequired,
  export: PropTypes.func.isRequired,
};

import uuid from 'uuid';
import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { CONSULTANTS_CREATE_ACTIONS } from '../../actions/ConsultantsActions';

export function* consultantsCreateSaga({ data: { form } }) {
  try {
    yield put(CONSULTANTS_CREATE_ACTIONS.pending());

    const contacts = [];

    if (form.phone) {
      contacts.push({
        type: 'mobile',
        protocol: 'tel',
        value: form.phone,
        uri: `tel:${form.phone}`,
      });
    }

    if (form.email) {
      contacts.push({
        type: 'business',
        protocol: 'mailto',
        value: form.email,
        uri: `mailto:${form.email}`,
      });
    }

    const consultant = {
      id: yield call(uuid.v4),
      username: form.username,
      firstName: form.firstName,
      lastName: form.lastName,
      department: form.department,
      division: form.division,
      title: form.title,
      contacts,
    };
    yield call(axios.put, `${EndpointName.PERSON_CONSULTANT}/${encodeURIComponent(consultant.id)}`, consultant);

    yield put(CONSULTANTS_CREATE_ACTIONS.success({ consultant }));
  } catch (error) {
    yield put(CONSULTANTS_CREATE_ACTIONS.failure(error));
  }
}

import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { get, slice } from 'lodash';

import { EndpointName } from '../../constants/EndpointName';
import { REF_ERRORS_SEARCH } from '../../actions/RefErrorsActions';
import { createSearchQuery } from './createSearchQuery';
import { REF_ERRORS_PAGE_SIZE } from '../../constants/RefErrorsConstants';

export function* refErrorsSearchSaga(action) {
  try {
    yield put(REF_ERRORS_SEARCH.pending());

    const {
      limit = REF_ERRORS_PAGE_SIZE,
      offset = 0,
      resultsRaw
    } = get(action, 'data', {});

    const chunk = slice(get(resultsRaw, 'refErrors', []), offset, limit + offset);
    if (get(chunk, 'length', 0) > 0) {
      yield put(REF_ERRORS_SEARCH.success({
        searchParams: action.data,
        results: {
          refErrors: get(resultsRaw, 'refErrors', [])
        },
      }));

      return;
    }


    const query = createSearchQuery(action.data);
    const { data: refErrors } = yield call(axios, {
      url: `${EndpointName.LOADER_REF_ERROR}/?${query}`,
    });

    yield put(REF_ERRORS_SEARCH.success({
      searchParams: action.data,
      results: {
        refErrors: [...get(resultsRaw, 'refErrors', []), ...refErrors]
      },
    }));
  } catch (error) {
    yield put(REF_ERRORS_SEARCH.failure(error));
  }
}

import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get, isNil } from 'lodash';

import moduleAction from '../actions/Module';
import { getDatabase } from '../../../IndexDb';

const {
  MODULE_PENDING,
  MODULE_FULFILLED,
  MODULE_REJECTED
} = moduleAction;

export default function* getModule(request) {
  const { dataKey } = request;

  yield put({ type: MODULE_PENDING, dataKey });

  try {
    const db = yield call(getDatabase);
    const expired = yield call(db.isExpired, { table: 'module' });
    const table = db.table('module');
    const local = yield call(table.get, { id: dataKey });

    if (!isNil(local) && !expired) {
      yield put({ type: MODULE_FULFILLED, dataKey, payload: local });

      return local;
    }

    const url = `/core/module/${dataKey}`;
    const { data } = yield call(axios, {
      url,
      method: 'get'
    });

    const payload = {
      ...data,
      isFavourite: get(local, 'isFavourite', false)
    };

    yield put({ type: MODULE_FULFILLED, dataKey, payload });

    // Update module in index db
    yield call(table.put, payload);

    return payload;
  } catch (error) {
    yield put({ type: MODULE_REJECTED, dataKey, error });

    return error;
  }
}

import './AnalyticsLogs.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { handleGravitonError } from '../../utils';
import PageContainer from '../Common/PageContainer';
import { AnalyticsLogsSearchForm } from './AnalyticsLogsSearchForm';
import { AnalyticsLogsSearchResults } from './AnalyticsLogsSearchResults';
import {
  PROP_TYPES_DATES,
  PROP_TYPES_DEPARTMENT,
  PROP_TYPES_DETAILS,
  PROP_TYPES_DIVISION,
  PROP_TYPES_OPTION,
  PROP_TYPES_USER,
} from './AnalyticsLogsPropTypes';
import { validateAnalyticsLogsForm } from '../../services/AnalyticsLogs/AnalyticsLogsFormValidator';

export const ANALYTICS_LOGS_SEARCH_FORM_NAME = "analyticsLogsSearchForm";

export class AnalyticsLogs extends React.Component {
  constructor(props) {
    super(props);
    props.load();
  }

  render() {
    const { error, text, searchResults, search, dates, locale, tenant } = this.props;
    const { requestDetails, getRequestDetails, getComparisonDetails, comparisonDetails } = this.props;
    const { loading, loadingSearch, loadingDetails, loadingComparison, currentValues } = this.props;
    const { departments, divisions, users, endpoints } = this.props;
    if (error) {
      toast.error(handleGravitonError(error));
    }

    return (
      <PageContainer title={<FormattedMessage id="analyticsLogs_title"/>} requesting={loading}>
        <div className="analytics-logs">
          {text ? <div className="analytics-logs__text">{text}</div> : null}
          <div className="analytics-logs__search-form">
            <AnalyticsLogsSearchForm
              form={ANALYTICS_LOGS_SEARCH_FORM_NAME}
              search={search}
              loading={loadingSearch}
              departments={departments}
              divisions={divisions}
              users={users}
              endpoints={endpoints}
              searchResults={searchResults}
              currentValues={currentValues}
              validate={validateAnalyticsLogsForm}
              shouldValidate={validateAlways}
              locale={locale}
              tenant={tenant}
            />
          </div>
          <div className="analytics-logs__search-results">
            <AnalyticsLogsSearchResults
              searchResults={searchResults}
              getRequestDetails={getRequestDetails}
              requestDetails={requestDetails}
              getComparisonDetails={getComparisonDetails}
              comparisonDetails={comparisonDetails}
              loadingSearch={loadingSearch}
              loadingDetails={loadingDetails}
              loadingComparison={loadingComparison}
              dates={dates}
              locale={locale}
            />
          </div>
        </div>
      </PageContainer>
    );
  }
}

AnalyticsLogs.propTypes = {
  loading: PropTypes.bool,
  loadingSearch: PropTypes.bool,
  loadingDetails: PropTypes.bool,
  loadingComparison: PropTypes.bool,
  error: PropTypes.string,
  text: PropTypes.string,
  tenant: PropTypes.string,
  searchResults: PropTypes.array.isRequired,
  requestDetails: PROP_TYPES_DETAILS,
  dates: PROP_TYPES_DATES.isRequired,
  comparisonDetails: PropTypes.shape({
    first: PROP_TYPES_DETAILS,
    second: PROP_TYPES_DETAILS,
  }),

  search: PropTypes.func.isRequired,
  getRequestDetails: PropTypes.func.isRequired,
  getComparisonDetails: PropTypes.func.isRequired,

  departments: PropTypes.arrayOf(PROP_TYPES_DEPARTMENT).isRequired,
  divisions: PropTypes.arrayOf(PROP_TYPES_DIVISION).isRequired,
  users: PropTypes.arrayOf(PROP_TYPES_USER).isRequired,
  endpoints: PropTypes.arrayOf(PROP_TYPES_OPTION).isRequired,
};

function validateAlways() {
  return true;
}

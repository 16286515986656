import './ConfirmationDialog.css';

import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from './Button';

export function ConfirmationDialog(props) {
  const { isOpen, cancel, confirm, content, title, textCancel, textConfirm } = props;

  return (
    <Modal show={isOpen}
           onHide={cancel}
           className="confirmation-dialog"
           backdropClassName="confirmation-dialog__backdrop">
      <Modal.Header closeButton={true}>
        {title}
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button onClick={confirm} bsStyle="primary" bsSize="sm">
          {textConfirm || <FormattedMessage id="common_confirm" />}
        </Button>
        <Button onClick={cancel} bsSize="sm">
          {textCancel || <FormattedMessage id="common_cancel" />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  textConfirm: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  textCancel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirm: PropTypes.func,
  cancel: PropTypes.func,
};

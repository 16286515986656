import { Actions } from '@evoja-web/redaction';

import { servicesAction } from './Api/Services';
import { schemaAction } from './Api/Schema';

export const actions = Actions([
  servicesAction,
  schemaAction
]);

export default actions.get();

import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import { get } from 'lodash'
import { LoaderTriggerRefreshEventStatus } from './LoaderTriggerRefreshEventStatus'
import './LoaderTriggeringForm.css'

//render key/value fields for parameter (diffload)
const renderParameterfields = ({ fields }) => (
  <div>
    <Button type="button" className="addParameter" onClick={() => fields.push({})}>
      Add Parameter
    </Button>
    {fields.map((parameter, index) => (
      <div key={index}>
        <label className="parameterLabel">Parameter #{index + 1}</label>
        <Field
          name={`${parameter}.key`}
          component={parameterfield}
          label="key"
        />
        <Field
          name={`${parameter}.value`}
          component={parameterfield}
          label="value"
        />
        <Button
          className="removeParameter"
          onClick={() => fields.remove(index)}
        >
          X
        </Button>
      </div>
    ))}
  </div>
)

const parameterfield = ({ input, label }) => (
  <input {...input} type="text" placeholder={label} className="text-input" />
)

/*
 * Form for triggering loads
 */
let LoaderTriggeringForm = props => {
  let {
    loadType = "Regular Load",
    objects = "",
    loadDeps = false,
    diffLoadParameter,
    reset,
    loaderTriggerData,
    loaderTriggerEventStatus,
    loaderTriggerActions,
    loaderTriggerGetEventStatusActions
  } = props

  let triggerObj = {
    "objects": objects.replaceAll(" ", "").replaceAll("\n", ""),
    "loadDeps": loadDeps,
    "diffLoadParameter": diffLoadParameter
  }

  const [observeEventStatus, setObserveEventStatus] = useState(false);
  const [triggerData, setTriggerData] = useState(loaderTriggerData);
  const [eventStatus, setEventStatus] = useState(loaderTriggerEventStatus);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const removeEmpty = (obj) => {
    let newObj = obj;
    Object.keys(obj).forEach(key => {
      if (obj[key] === undefined || obj[key] === "") {
        delete obj[key];
      }
    });
    return newObj;
  };

  const triggerLoad = async (e) => {
    e.preventDefault();
    const loadObj = removeEmpty(triggerObj);
    loaderTriggerActions.loaderTriggerRequest({ data: loadObj });

    if (!triggerData.requesting) {
      fetchEventStatus();
      setObserveEventStatus(true);
    }
  }

  const getEventStatusResponseId = () => {
    //reponse: link="...<http://varnish-backend:9080/event/status/61f3b9d520139009c8586ef3>..."
    let eventStatusResponseLink = get(triggerData, 'data.headers.link', '-');
    let eventStatusResponseRegex = /event\/status\/(.*?)(\>)/;
    let eventStatusResponseId = eventStatusResponseRegex.exec(eventStatusResponseLink);

    if (eventStatusResponseId === null) return null;

    return eventStatusResponseId[1];
  }

  const getEventStatusStatus = () => {
    //eventStatus: status = [ { action: xy, status: xy, workerId: xy }, {...} ]
    let eventStatusStatus = get(eventStatus, 'data[0].status', '?');
    return eventStatusStatus;
  }

  const fetchEventStatus = () => {
    //get /event/status/id={loader_id} status_value
    loaderTriggerGetEventStatusActions.getEventStatusRequest({ data: `?eq(id,${getEventStatusResponseId()})` })
  }

  if (observeEventStatus) {
    setTimeout(() => {
      fetchEventStatus();
    }, 15000);
  }

  //to get triggerData from next state
  useEffect(() => {
    setTriggerData(loaderTriggerData);
  }, [loaderTriggerData]);

  //to get eventStatus from next state
  useEffect(() => {
    setEventStatus(loaderTriggerEventStatus);
  }, [loaderTriggerEventStatus]);

  const hiddenFileInput = useRef(null);

  const importFile = () => {
    hiddenFileInput.current.click();
  };

  const fileChange = event => {
    const fileUploaded = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = () => {
      document.getElementById('jsonInput').textContent = fileReader.result;
    }
    fileReader.readAsText(fileUploaded);
  };

  const saveJSON = () => {
    let jsonInput = document.getElementById('jsonInput');

    if (jsonInput.innerText === '') return;

    let jsonObject;

    try {
      jsonObject = JSON.parse(jsonInput.innerText);

      // update redux-form fields
      if (get(jsonObject, 'diffLoadParameter.length') > 0) {
        props.change('loadType', 'diffLoad')
      }
      props.change('objects', get(jsonObject, 'objects'));
      props.change('loadDeps', get(jsonObject, 'loadDeps'));
      props.change('diffLoadParameter', get(jsonObject, 'diffLoadParameter'));

      setShow(false);
    } catch (error) {
      alert(`Invalid JSON Error: \n${error}`);
    }
  }

  return (
    <form onSubmit={(e) => triggerLoad(e)}>
      <div className="form-group">
        <label>Loadtyp</label>
        <Field
          className="select-input"
          name="loadType"
          component="select"
          placeholder="loadType"
        >
          <option value="regularLoad">Regular Load</option>
          <option value="diffLoad">Diff Load</option>
        </Field>
      </div>

      <div className="form-group">
        <label>Loadobjekt/-e <i style={{ color: 'gray' }}>(kommasepariert)</i></label>
        <Field
          className="textarea-input"
          name="objects"
          component="textarea"
          type="text"
        />
      </div>

      <div className="form-group">
        <label htmlFor="loadDeps">Dependencies laden</label>
        <Field
          className="checkbox-input"
          name="loadDeps"
          id="loadDeps"
          component="input"
          type="checkbox"
        />
      </div>

      {loadType === 'diffLoad' && (
        <div className="form-group">
          <label>Diff Load - Parameter</label>
          <FieldArray name="diffLoadParameter" component={renderParameterfields} />
        </div>)}

      <div className="form-group">
        <label style={{ fontWeight: 'bold' }}>Preview:</label>
        <pre id='preview' style={{ color: 'green' }}> {JSON.stringify(triggerObj, null, 4)} </pre>

        {observeEventStatus &&
          <div>
            <label>Event Status  <LoaderTriggerRefreshEventStatus interval={15} /></label>
            <p>id: {getEventStatusResponseId()}</p>
            <p> status: {getEventStatusStatus()}</p>
          </div>
        }

        {get(triggerData, 'loadingStatus', '-') === "LOADING_STATUS_PENDING"
          ?
          <Button className="triggerButton" disabled={true}>
            <h5 className="sc-spinner-text">
              Loading
              <span className="sc-spinner-dots">...</span>
            </h5>
          </Button>
          :
          <Button type="submit" className="triggerButton">
            Trigger
          </Button>
        }

        <Button className="clearButton" onClick={(e) => { reset(); setObserveEventStatus(false); e.target.blur() }}>
          Clear
        </Button>

        <Button className="clearButton" onClick={(e) => { handleShow(); e.target.blur() }}>
          Create JSON
        </Button>

        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Create JSON</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Write your own JSON or import a file (.json):
            <br />
            <pre contentEditable id='jsonInput' style={{ width: '100%', height: '100%', color: 'green' }}></pre>
          </Modal.Body>
          <Modal.Footer>
            <input type="file" accept='application/json' ref={hiddenFileInput} onChange={fileChange} style={{ display: 'none' }} />
            <Button variant="primary" onClick={importFile}>
              Import File
            </Button>

            <Button variant="primary" onClick={saveJSON}>
              Save Changes
            </Button>

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </form>
  )
}

LoaderTriggeringForm = reduxForm({
  form: 'LoaderTriggeringForm'
})(LoaderTriggeringForm)

const selector = formValueSelector('LoaderTriggeringForm')
LoaderTriggeringForm = connect(state => {
  const loadType = selector(state, 'loadType')
  const objects = selector(state, 'objects')
  const loadDeps = selector(state, 'loadDeps')
  const diffLoadParameter = selector(state, 'diffLoadParameter')
  return {
    loadType,
    objects,
    loadDeps,
    diffLoadParameter
  }
})(LoaderTriggeringForm)

export default LoaderTriggeringForm
import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';

import initTargetAction from '../actions/InitTarget';
import getModules from './getModules';
import getServices from './getServices';

const {
  INIT_TARGET_PENDING,
  INIT_TARGET_FULFILLED,
  INIT_TARGET_REJECTED
} = initTargetAction;

/**
 * Login to the target system
 *
 * @param   {Axios}   axiosInstance Axios instance to use
 * @param   {String}  username      Username
 * @param   {String}  password      password
 *
 * @return  {[type]}            [return description]
 */
function* targetLogin({
  axiosInstance,
  username,
  password
}) {
  try {
    const { data } = yield call(axiosInstance, {
      url: '/auth',
      method: 'post',
      contentType: 'application/json',
      data: {
        username,
        password,
      }
    });

    // Set token on axios instance for following requests
    // eslint-disable-next-line no-param-reassign
    axiosInstance.defaults.headers.common['X-REST-Token'] = get(data, 'token');

    return data;
  } catch (error) {
    throw new Error(`Login to "${axiosInstance.defaults.baseURL}/auth" with user "${username}" rejeceted!`, { cause: error });
  }
}

/**
 * Init the target system
 * -> Login
 * -> Load availabe modules from /core/module
 * -> Load available services from graviton
 *
 * @param   {String}  password  Password
 * @param   {String}  username  Username
 * @param   {Object}  target  Target config
 *
 * @return  {[type]}           [return description]
 */
export default function* initTarget({
  password,
  target,
  username
}) {
  yield put({ type: INIT_TARGET_PENDING });

  try {
    const axiosInstance = axios.create({
      baseURL: get(target, 'url')
    });

    const login = yield call(targetLogin, { axiosInstance, username, password });
    const modules = yield call(getModules, { axiosInstance });
    const services = yield call(getServices, { axiosInstance });

    const payload = {
      axiosInstance,
      config: { ...target, username },
      login,
      modules,
      services
    };

    yield put({ type: INIT_TARGET_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: INIT_TARGET_REJECTED, error });

    return error;
  }
}

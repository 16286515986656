import { select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { createRef } from '../../utils';
import { getSetManagementPricesRaw } from '../../selectors/SetManagement/setManagementCommonSelectors';

export function* setManagementChangePrices(prices) {
  const pricesRaw = yield select(getSetManagementPricesRaw);
  if (!prices) {
    return pricesRaw;
  }

  return pricesRaw.map((priceRaw) => {
    const price = prices.find((it) => it.id === priceRaw.id);
    if (!price) {
      return priceRaw;
    }

    return {
      ...priceRaw,
      bundle: { $ref: createRef(EndpointName.BASIC_PRODUCT_BUNDLE_BUNDLE, price.bundle) },
      product: { $ref: createRef(EndpointName.BASIC_PRODUCT_BUNDLE_PRODUCT, price.product) },
      reductionType: { $ref: createRef(EndpointName.ENTITY_CODE, price.reductionType) },
      reductionValue: price.reductionValue,
    };
  });
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
import update from 'immutability-helper';

import Form from './LocalFilterForm';
import LoadButton from './LoadButton';
import Progress from './Progress';
import RecordList from './RecordList';

class LocalFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false
    };

    this.onAdd = this.onAdd.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  onAdd() {
    const {
      filters,
      onChange
    } = this.props;

    onChange({ payload: [...get(filters, 'data', []), { path: '', condition: undefined, value: undefined, type: 'string' }] });
  }

  onRemove(index = -1) {
    const {
      filters,
      onChange
    } = this.props;

    const updated = index > -1
      ? update(get(filters, 'data', []), { $splice: [[index, 1]] })
      : get(filters, 'data', []);

    onChange({ payload: updated });
  }

  onChange(index, filter) {
    const {
      filters,
      onChange
    } = this.props;

    const updated = update(get(filters, 'data', []), {
      $splice: [[index, 1, filter]]
    });

    onChange({ payload: updated });
  }

  toggle() {
    const { collapsed } = this.state;

    this.setState({ collapsed: !collapsed })
  }

  renderListGroupItems() {
    const { filters } = this.props;

    return get(filters, 'data', []).map((filter, index) => {
      return (
        <ListGroupItem key={index}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '98%' }}>
              <Form
                data={filter}
                onChange={(data) => this.onChange(index, data)}
              />
            </div>

            <div style={{ width: '2%', textAlign: 'right' }}>
              <span
                className="mdi mdi-minus-circle-outline"
                style={{ fontSize: '1.3em' }}
                onClick={() => this.onRemove(index)}
              />
             </div>
          </div>
        </ListGroupItem>
      );
    });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { collapsed } = this.state;
    const {
      filtered,
      filters,
      onSubmit,
      progress
    } = this.props;

    return (
      <div className="replay-audit-log-local-filters">
        <Row>
          <Col lg={12}>
            <h3>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ cursor: 'pointer' }} onClick={this.toggle}>
                  Lokale Filter (JMES)
                </div>

                {!collapsed && (
                  <div className="pull-rigth" style={{ cursor: 'pointer', fontSize: '0.8em' }} onClick={this.onAdd}>
                    <span className="mdi mdi-plus-circle-outline" />
                  </div>
                )}
              </div>
            </h3>
          </Col>
        </Row>

        {!collapsed && (
          <React.Fragment>
            <Row style={{ paddingBottom: '20px' }}>
              <Col lg={12}>
                <i>
                  Die gefundenen Records können via JMES Path weiter gefiltert werden. <br />
                  Folgende Properties stehen zur Verfügung <br />
                  - state: Zugriff auf den lokalen State der Applikation (Bitte beachten, dass Daten nicht zwingen geladen sein müssen)<br />
                  - record: Record aus dem Audit Log
                  - record.body: Geparster body (record.requestBody beinhaltet den Request body als string)
                  - record.current: Wenn "Aktuelle Daten laden" ausgewählt wurde, beinhaltet dies die aktuellen Daten des Eintrags
                </i>
              </Col>
            </Row>

            <ListGroup>
              {this.renderListGroupItems()}
            </ListGroup>
          </React.Fragment>
        )}

        {!collapsed && get(filters, 'data.length', 0) > 0 && (
          <React.Fragment>
            <Row>
              <Col lg={12}>
                <LoadButton
                  onClick={() => onSubmit({ filters: get(filters, 'data', []) })}
                  error={get(filtered, 'error')}
                  fulfilled={get(filtered, 'fulfilled', false)}
                  requesting={get(filtered, 'requesting', false)}
                >
                  Daten filtern
                </LoadButton>
              </Col>
            </Row>

            {(get(progress, 'localFilters.fulfilled', false) || get(progress, 'localFilters.requesting', false)) && (
              <Progress
                title="Lokale Daten filtern"
                progress={get(progress, 'localFilters')}
              />
            )}

            {get(filtered, 'fulfilled', false) && (
              <RecordList records={get(filtered, 'data', [])}/>
            )}
          </React.Fragment>
        )}


      </div>
    );
  }
}

LocalFilters.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  progress: PropTypes.object.isRequired
};

LocalFilters.defaultProps = {
  filters: {},
  onChange: noop,
  onSubmit: noop
};

export default LocalFilters;

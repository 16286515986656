import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';

import CustomJmesInput from '../../../Field/CustomJmesInput';

class WorkflowTreeActionSendApprovalMail extends React.PureComponent {
  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      action,
      onJmesParamChange
    } = this.props;

    return (
      <div className="workflow-tree-form-edit-node--actions--action--send-approval-mail-configuration">
        <CustomJmesInput
          id="recipient"
          prefix=""
          onChange={onJmesParamChange}
          value={get(action, 'jmesParams.recipient')}
        />
      </div>
    );
  }
}

WorkflowTreeActionSendApprovalMail.propTypes = {
  action: PropTypes.object.isRequired,
  onJmesParamChange: PropTypes.func
};

WorkflowTreeActionSendApprovalMail.defaultProps = {
  onJmesParamChange: noop
};

export default WorkflowTreeActionSendApprovalMail;

import React from 'react';
import PropTypes from 'prop-types';
import { Form, reduxForm } from 'redux-form';
import { Button } from '../Common/Button';
import { ModalBody, ModalFooter, ModalHeader } from '../Common/Modal';
import { UiNotesTypeShape } from './UiNotesShape';
import { UiNotesFormContent } from './UiNotesFormContent';

class UiNotesCreateReduxForm extends React.Component {
  render() {
    const { types, language, onCancel, onSubmit } = this.props;
    const { handleSubmit, submitFailed, invalid } = this.props;

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader closeButton={true}>
          <div>UI Notiz erstellen</div>
        </ModalHeader>
        <ModalBody>
          <UiNotesFormContent types={types}
                              language={language}/>
        </ModalBody>
        <ModalFooter>
          <Button type="button"
                  onClick={onCancel}
                  bsSize="sm">
            Abbrechen
          </Button>
          <Button type="submit"
                  disabled={submitFailed ? invalid : false}
                  bsSize="sm"
                  bsStyle="primary">
            Speichern
          </Button>
        </ModalFooter>
      </Form>
    );
  }
}

export const UiNotesCreateForm = reduxForm({})(UiNotesCreateReduxForm);
UiNotesCreateForm.propTypes = {
  types: PropTypes.arrayOf(UiNotesTypeShape).isRequired,
  language: PropTypes.string.isRequired,

  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

import './FinancingProfilingProfiles.css';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { FinancingProfilingProduct } from './FinancingProfilingProduct';
import { ModalBody, ModalDialog, ModalHeader } from '../Common/Modal';
import { FinancingProfilingEdit } from './FinancingProfilingEdit';

export class FinancingProfilingProfiles extends React.Component {
  state = {
    isModalOpen: false,
  };

  render() {
    const { profiles } = this.props;
    const profilesByLimits = Object.values(
      _.groupBy(profiles, (profile) => `${profile.limitStart}-${profile.limitEnd}`)
    );

    return (
      <div className="financing-profiling-profiles">
        <div className="financing-profiling-profiles__items">
          {profilesByLimits.map((profilesInGroup, index) => this.renderProfile(profilesInGroup, index))}
        </div>

        <div className="financing-profiling-profiles__add" onClick={this.openModal}>
          <span className="icon-061-plus"/>
        </div>
        {this.renderModal()}
      </div>
    );
  }

  renderProfile(profiles, groupIndex) {
    const { loanProductOptions, durations, profileOptions, predictionOptions, onRemove, onChange } = this.props;
    return (
      <div className="financing-profiling-profiles__profile" key={groupIndex}>
        <div className="financing-profiling-profiles__limits">
          <FormattedNumber value={profiles[0].limitStart}/> - <FormattedNumber value={profiles[0].limitEnd}/>
        </div>

        {profiles.map((product, index) => (
          <div className="financing-profiling-profiles__product" key={index}>
            <FinancingProfilingProduct
              product={product}
              durations={durations}
              profileOptions={profileOptions}
              predictionOptions={predictionOptions}
              loanProductOptions={loanProductOptions}
              onChange={onChange}
              onRemove={onRemove}
            />
          </div>
        ))}
      </div>
    );
  }

  renderModal() {
    const { isModalOpen } = this.state;
    const { profileId, predictionId, durations, loanProductOptions, predictionOptions, profileOptions } = this.props;
    const newProduct = {
      id: undefined,
      profileId: profileId,
      predictionId: predictionId,
      limitStart: undefined,
      limitEnd: undefined,
      productId: undefined,
      productTitle: undefined,
      durations: [],
    };

    return (
      <ModalDialog show={isModalOpen} onHide={this.closeModal}>
        <ModalHeader closeButton={true}>
          <Modal.Title>
            <div className="financing-profiling-profiles__modal-title">
              <FormattedMessage id="financingProfiling_add_title"/>
            </div>
          </Modal.Title>
        </ModalHeader>
        <ModalBody>
          <FinancingProfilingEdit
            product={newProduct}
            profileOptions={profileOptions}
            predictionOptions={predictionOptions}
            loanProductOptions={loanProductOptions}
            durations={durations}
            onCancel={this.closeModal}
            onSave={this.handleAdd}
          />
        </ModalBody>
      </ModalDialog>
    );
  }

  openModal = () => this.setState({ isModalOpen: true });
  closeModal = () => this.setState({ isModalOpen: false });
  handleAdd = (product) => {
    const { onChange } = this.props;
    onChange(product);
    this.closeModal();
  };
}

FinancingProfilingProfiles.propTypes = {
  profileId: PropTypes.string.isRequired,
  predictionId: PropTypes.string.isRequired,
  profiles: PropTypes.array.isRequired,
  profileOptions: PropTypes.array.isRequired,
  predictionOptions: PropTypes.array.isRequired,
  loanProductOptions: PropTypes.array.isRequired,
  durations: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { TextEditor } from '@evoja-web/react-form';

class WysiwygEditor extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(id, value) {
    const { onChange } = this.props;

    onChange(value);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      className,
      rows,
      value
    } = this.props;

    return (
      <TextEditor
        id="wysiwyg"
        className={className}
        rows={rows}
        value={value}
        onChange={this.onChange}
      />
    );
  }
}

WysiwygEditor.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  value: PropTypes.string
};

WysiwygEditor.defaultProps = {
  className: undefined,
  onChange: noop,
  rows: 20,
  value: undefined
};

export default WysiwygEditor;

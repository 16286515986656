import React from 'react';
import PropTypes from 'prop-types';
import { get, isUndefined, noop } from 'lodash';

/**
 * Legacy add component.
 * This component auto migrate itself to the new "flex-" structure on mount
 */
class DateFieldDefaultValueAdd extends React.Component {
  constructor(props) {
    super(props);

    this.migrate();
  }

  /**
   * Migrate the current value to the new "flex-" structure
   *
   * @return  void
   */
  migrate() {
    const {
      onMigrate,
      value
    } = this.props;

    if (isUndefined(value)) {
      return;
    }

    const n = get(value.split('-'), 1);
    const updatedValue = `flex-{current}--{current}--{current-add-${n}}`;

    onMigrate({ updatedValue, updatedType: 'flex' });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    return null;
  }
}

DateFieldDefaultValueAdd.propTypes = {
  onMigrate: PropTypes.func,
  value: PropTypes.string
};

DateFieldDefaultValueAdd.defaultProps = {
  onMigrate: noop,
  value: undefined
};

export default DateFieldDefaultValueAdd;

import React from 'react';
import { Panel, Button } from 'react-bootstrap';
import 'react-rangeslider/lib/index.css';
import 'react-select/dist/react-select.css';

export default class InterestItem extends React.Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
    };
  }

  render() {
    const { item, language, onDeleteInterest } = this.props;
    return(
      <Panel>
        <div style={{
          height: 60,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'flex-start',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          }}
        >
          <span style={{ marginLeft: 19, width: 250 }} >{item.text[language]}</span>
          <div
          style={{
              flex: 0,
              marginLeft: 15,
              marginBottom: 0
            }}
          >
          <Button onClick={() => onDeleteInterest(item.id)}>-</Button>
          </div>
          </div>
      </Panel>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { has, get, noop, isNil } from 'lodash';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Select, ValidationResult } from '@evoja-web/react-form';
import { removeHost } from '@evoja-web/redux-saga-utils';
import update from 'immutability-helper';

import { actions as gravitonActions } from '../../../../../Graviton/index';

class ActionConfigurationServiceCallService extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const {
      action,
      gravitonActions,
      services,
      schema
    } = this.props;

    // Load schema on mount if not already done (edit)
    const value = get(action, 'url');
    if (!has(schema, `${value}.data.json`)) {
      const service = get(services, 'data', []).find((s) => removeHost(get(s, '$ref')) === value);
      gravitonActions.schemaRequest({ dataKey: value, service });
    }
  }

  /**
   *  Load the service definition from backend if necessary
   *
   * @param   {String}  key       [key description]
   * @param   {String}  value     [value description]
   * @param   {Object}  selected  [selected description]
   *
   * @return  void
   */
  onChange(key, value, selected) {
    const {
      action,
      gravitonActions,
      onActionChange,
      schema
    } = this.props;

    if (!isNil(value) && !has(schema, `${value}.data.json`)) {
      gravitonActions.schemaRequest({ dataKey: value, service: selected.service });
    }

    const updated = update(action, {
      url: { $set: value },
      $unset: ['method'],
      jmesParams: { $unset: ['mapping'] }
    });

    onActionChange({ action: updated });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      action,
      services,
      validations
    } = this.props;

    const options = get(services, 'data', [])
      .filter((s) => removeHost(get(s, '$ref')) !== '/')
      .map((s) => ({
        label: removeHost(get(s, '$ref')),
        value: removeHost(get(s, '$ref')),
        service: s
      }));

    return (
      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.Action.Configuration.ServiceCall.Service" />
        </ControlLabel>

        <Select
          id="url"
          options={options}
          onChange={this.onChange}
          value={get(action, 'url')}
        />

        <ValidationResult
          show
          validations={get(validations, 'url')}
        />
      </FormGroup>
    );
  }
}

ActionConfigurationServiceCallService.propTypes = {
  action: PropTypes.object.isRequired,
  gravitonActions: PropTypes.object.isRequired,
  onActionChange: PropTypes.func,
  services: PropTypes.object,
  schema: PropTypes.object,
  validations: PropTypes.object
};

ActionConfigurationServiceCallService.defaultProps = {
  onActionChange: noop,
  services: {},
  schema: {},
  validations: {}
};

function mapStateToProps(state) {
  return {
    services: get(state, 'graviton.services'),
    schema: get(state, 'graviton.schema')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    gravitonActions: bindActionCreators(gravitonActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionConfigurationServiceCallService);

export const REF_ERRORS_TEXT_BLOCKS_MAP = {
  textIntro: 'adminUIRefErrorsIntro',
};

export const REF_ERRORS_PAGE_SIZE = 25;
export const REF_ERRORS_MODAL_PAGE_SIZE = 10;

export const REF_ERRORS_DEFAULT_SORT = {
  sortField: 'loadedAt',
  sortDirection: 'desc',
};

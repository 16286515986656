import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { InputGroup, FormControl } from 'react-bootstrap';
import DragHandle from '../DragHandle';

const Item = (props) => {
  const { id, item, language, onItemChange, onItemRemove } = props;

  // Need to add z-index to the item when item is inside a modal (https://github.com/clauderic/react-sortable-hoc/issues/87)
  return (
    <div className="SortableHOCItem" style={{ zIndex: '9999' }}>
      <InputGroup>
        <InputGroup.Addon>
          <DragHandle />
        </InputGroup.Addon>

        <FormControl
          type="text"
          name="itemName"
          value={item.name[language]}
          onChange={(ev) => onItemChange(id, ev)}
        />

        <InputGroup.Addon style={{ cursor: 'pointer'}}>
          <span onClick={onItemRemove.bind(this, id)}>
            <i className="icon-008-bin"><span className="hidden">remove</span></i>
          </span>
        </InputGroup.Addon>
      </InputGroup>
    </div>
  );
};

const SortableItem = SortableElement(Item);

export default class ChecklistItem extends React.Component {
  render() {
    return(<SortableItem {...this.props}/>);
  }
}

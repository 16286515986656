import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import cl from 'classnames';

import './EmailSubscriptionsExport.css';
import * as codeActions from '../../../actions/CodeActions';
import List from './List';
import CsvExport from './CsvExport';
import { ExpandContent } from '../../Common/ExpandContent';
import followNextHeaders from '../../../utils/asyncFollowNextHeaders';

function getDefinition({ language }) {
  const definition = [
    {
      title: 'email',
      dataKey: 'email'
    },
    {
      title: 'title',
      dataKey: 'title'
    },
    {
      title: 'firstName',
      dataKey: 'firstName'
    },
    {
      title: 'lastName',
      dataKey: 'lastName'
    },
    {
      title: 'gender',
      dataKey: 'gender',
      dataGetter: (i) => get(i, 'gender') === 'm' || get(i, 'gender') === 'male' ? 'Herr' : 'Frau'
    },
    {
      title: 'subscriptionType',
      dataGetter: (i) => get(i, 'subscriptionType', []).map((i) => get(i, `text.${language}`)).join(', ')
    },
    {
      title: 'language',
      dataKey: 'language',
      dataGetter: (i) => get(i, 'language', '').toUpperCase()
    }];

  return definition;
}

class EmailSubscriptionsExport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      generating: false
    };

    const { codeActions, language } = this.props;
    codeActions.codesRequest(['subscriptionType']);

    this.csv = CsvExport({ definition: getDefinition({ language }) });

    this.onExportRequest = this.onExportRequest.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  async onExportRequest(subscriptionType) {
    const { language } = this.props;

    this.setState({ generating: true });

    try {
      const data = await followNextHeaders({
        url: `/email/subscriptions/?eq(subscriptionType.id,${get(subscriptionType, 'id')})&eq(subscriptionState.id,subscriptionState-2)&limit(1000)`,
        method: 'get'
      });

      this.csv
        .generate(data)
        .download(`Abonnemente-${get(subscriptionType, `text.${language}`)}`);
    } catch (err) {

    }

    // Get data and create...

    this.setState({ generating: false });
  }

  toggle() {
    const { expanded } = this.state;

    this.setState({ expanded: !expanded });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { expanded, generating } = this.state;
    const { codes, requesting } = this.props;

    const iconClassName = cl({
      'mdi mdi-chevron-right': !expanded,
      'mdi mdi-chevron-down': expanded,
      'email-subscriptions-export--title--icon': true
    });

    return (
      <div className="email-subscriptions-export">
        <div
          className="email-subscriptions-export--title"
          onClick={this.toggle}
        >
          <div>
            Empfänger exportieren
          </div>

          <div>
            <span className={iconClassName} />
          </div>
        </div>

        <ExpandContent expanded={expanded}>
          <List
            onExportRequest={this.onExportRequest}
            requesting={requesting || generating}
            subscriptionTypes={get(codes, 'groups.subscriptionType', [])}
          />
        </ExpandContent>
      </div>
    );
  }
}

EmailSubscriptionsExport.propTypes = {
  codes: PropTypes.object,
  language: PropTypes.string,
  requesting: PropTypes.bool
};

EmailSubscriptionsExport.defaultProps = {
  codes: { groups: {} },
  language: 'de',
  requesting: false
};

function isRequesting(state) {
  return get(state, 'codes.requesting', false);
}

function mapStateToProps(state, ownProps) {
  return {
    codes: state.codes,
    language: state.login.language,
    requesting: isRequesting(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    codeActions: bindActionCreators(codeActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailSubscriptionsExport);

import PropTypes from 'prop-types';

export const PROP_TYPES_USER = PropTypes.shape({
  username: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const PROP_TYPES_DEPARTMENT = PropTypes.shape({
  department: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const PROP_TYPES_DIVISION = PropTypes.shape({
  division: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const PROP_TYPES_SEARCH_RESULT_ITEM = PropTypes.shape({
  id: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  userAgent: PropTypes.string,
  createdAt: PropTypes.string,
  requestTimeS: PropTypes.number,
  method: PropTypes.string,
  requestUri: PropTypes.string,
  responseCode: PropTypes.number,
});

export const PROP_TYPES_DETAILS = PropTypes.shape({
  id: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  name: PropTypes.string,
  department: PropTypes.string,
  division: PropTypes.string,
  userAgent: PropTypes.string,
  createdAt: PropTypes.string,
  requestTimeS: PropTypes.number,
  method: PropTypes.string,
  requestUri: PropTypes.string,
  requestBody: PropTypes.string,
  responseCode: PropTypes.number,
});

export const PROP_TYPES_DATES = PropTypes.shape({
  startDate: PropTypes.object,
  endDate: PropTypes.object,
});

export const PROP_TYPES_OPTION = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

import { defaultsDeep, isUndefined } from 'lodash';

export default function SpecialComponentDefaultValue(key, initial) {
  if (isUndefined(key)) throw new Error('You must provide a key (field name in form)!');

  return defaultsDeep(initial, {
    key,
    defaults: undefined,
    disabled: false
  });
}

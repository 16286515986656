import { isNil } from 'lodash';

/**
 * Filter the given items by the given status.
 * Return all items if the given status is null or undefined
 *
 * @param   {Array}  items   Array of items to filter
 * @param   {String} status  Item status (@see constants.js -> TRANSFER_ITEM_STATUS_)
 *
 * @return  {Array} filtered Filtered items
 */
export default function filterSourceItemsByStatus({ items = [], status }) {
  if (isNil(status)) {
    return items;
  }

  return items.filter((i) => i.status === status);
}


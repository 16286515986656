import './AnalyticsUserSummaryExportForm.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { PROP_TYPES_BANK } from './AnalyticsUserSummaryPropTypes';
import { SelectControl } from '../Common/SelectControl';
import { Button } from '../Common/Button';

export class AnalyticsUserSummaryExportForm extends React.Component {
  state = {
    selectedBank: null,
  };

  getSelectOptions = () => this.props.banks
    .map((bank) => ({
      value: bank.webDomain,
      label: bank.bankname,
      bank: bank,
    }))
    .sort((a, b) => a.label > b.label ? 1 : -1);

  onChangeBank = (option) => this.setState({
    selectedBank: option ? option.bank : null,
  });

  onClickExport = () => {
    const { onExport } = this.props;
    const { selectedBank } = this.state;
    onExport(selectedBank);
  };

  render() {
    const { exporting } = this.props;
    const { selectedBank } = this.state;

    return (
      <div className="analytics-user-summary-export-form">
        <div className="analytics-user-summary-export-form__bank">
          <SelectControl
            value={selectedBank ? selectedBank.webDomain : null}
            multi={false}
            clearable={true}
            disabled={exporting}
            options={this.getSelectOptions()}
            onChange={this.onChangeBank}
          />
        </div>
        <div className="analytics-user-summary-export-form__export">
          <Button bsStyle="primary" disabled={exporting} onClick={this.onClickExport}>
            {exporting && (
              <span className="analytics-user-summary-export-form__indicator">
                <span className="analytics-user-summary-export-form__spinner">
                  <span className="icon-085-synchronise"/>
                </span>
              </span>
            )}
            <FormattedMessage id="analyticsUserSummary_export"/>
          </Button>
        </div>
      </div>
    );
  }
}

AnalyticsUserSummaryExportForm.propTypes = {
  exporting: PropTypes.bool.isRequired,
  banks: PropTypes.arrayOf(PROP_TYPES_BANK).isRequired,
  onExport: PropTypes.func.isRequired,
};

import { CONSULTANTS_DELETE_ACTIONS } from '../../actions/ConsultantsActions';

export function consultantsDeleteReducer(state, action) {
  switch (action.type) {
    case CONSULTANTS_DELETE_ACTIONS.PENDING:
      return {
        ...state,
        saving: true,
      };

    case CONSULTANTS_DELETE_ACTIONS.SUCCESS:
      return {
        ...state,
        saving: false,
        data: {
          ...state.data,
          consultants: state.data.consultants.filter(it => it.id !== action.data.consultant.id),
        },
      };

    case CONSULTANTS_DELETE_ACTIONS.FAILURE:
      return {
        ...state,
        saving: false,
        error: action.data,
      };

    default:
      return state;
  }
}

import { createSelector } from 'reselect';
import { getLanguage } from '../commonSelectors';
import {
  getSetManagementBundlesRaw,
  getSetManagementDomains,
  getSetManagementProductsRaw,
  getSetManagementReductionTypes,
} from './setManagementCommonSelectors';

export const getSetManagementDomainOptions = createSelector([
  getSetManagementDomains,
  getLanguage,
], (items, language) => items.map((it) => ({ label: it.text[language], value: it.id })));

export const getSetManagementReductionTypeOptions = createSelector([
  getSetManagementReductionTypes,
  getLanguage,
], (items, language) => items.map((it) => ({ label: it.text[language], value: it.id })));

export const getSetManagementBundleOptions = createSelector([
  getSetManagementBundlesRaw,
  getLanguage,
], (items, language) => items.map((it) => ({ label: it.name[language], value: it.id })));

export const getSetManagementProductOptions = createSelector([
  getSetManagementProductsRaw,
  getLanguage,
], (items, language) => items.map((it) => ({ label: it.name[language], value: it.id })));

import { all, call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { chain, get } from 'lodash';

import actions from '../actions/Actions';
import getCategories from './getCategories';
import { getDatabase } from '../../../IndexDb';

const {
  MODULE_FULFILLED,
  MODULES_PENDING,
  MODULES_FULFILLED,
  MODULES_REJECTED
} = actions;

/**
 * Parse the category refs and replace it with the acutal code
 *
 * @param   {Array}  categories  Codes of group moduleCategory
 * @param   {Module} module      Module from /core/module
 *
 * @return  {Array} modules Modules with categories (codes)
 */
function getModuleCategories({ categories = [], module }) {
  return chain(module)
    .get('categories', [])
    .map(({ $ref = '' }) => {
      const parser = new URL($ref, 'http://foo.bar');

      const parts = get(parser, 'pathname', '').split('/');
      const index = parts.findLastIndex((p) => p === 'code');
      const id = get(parts, index + 1);

      return categories.find((c) => c.id === id);
    })
    .compact()
    .value();
}

/**
 * Check if the given module is flagged as favourite (stored in indexDb)
 *
 * @param   {Object}  module  Module from /core/module
 * @param   {Array}   local   Array of modules stored in index db
 *
 * @return  {Boolean}
 */
function isFavourite({ module, local }) {
  return chain(local)
    .find((m) => m.id === module.id)
    .get('isFavourite', false)
    .value();
}

export default function* getModules(request) {
  yield put({ type: MODULES_PENDING });

  try {
    const db = yield call(getDatabase);
    const local = yield call(db.getAllRecords, { table: 'module' });
    const expired = yield call(db.isExpired, { table: 'module' });
    if (!expired) {
      // Dispatch MODULE_FULFILLED for each module
      yield all(local.map((module) => put({ type: MODULE_FULFILLED, dataKey: module.id, payload: module })));
      yield put({ type: MODULES_FULFILLED, payload: local });

      return local;
    }

    const query = QueryBuilder()
      .eq('app.ref.$id', 'adminv2')
      .limit(500)
      .getQueryString();

    const url = `/core/module/${query}`;
    const remote = yield call(handleNextHeaders, url);
    const categories = yield call(getCategories);
    const payload = chain(remote)
      .map((module) => ({
        ...module,
        categories: getModuleCategories({ categories, module }),
        isFavourite: isFavourite({ module, local })
      }))
      .orderBy(['isFavourite', 'order', 'category.number'], ['asc', 'asc', 'asc'])
      .value();

    // Dispatch MODULE_FULFILLED for each module
    yield all(payload.map((module) => put({ type: MODULE_FULFILLED, dataKey: module.id, payload: module })));
    // Update index db
    yield call(db.bulkUpdate, { table: 'module', data: payload, clear: true });

    yield put({ type: MODULES_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: MODULES_REJECTED, error });

    return error;
  }
}

import axios from 'axios';
import { call, put, all } from 'redux-saga/effects';
import _, { get, isNil } from 'lodash';
import moment from 'moment';

import { NOTICE_IMPORT_IMPORT } from '../../actions/NoticeImportActions';
import { EndpointName } from '../../constants/EndpointName';
import {
  PARALLEL_REQUESTS_COUNT,
  DATE_FORMAT, VALIDATION_DATE_FORMATS
} from '../../constants/NoticeImport';
import { DATE_FORMAT_GRV, DATE_TIMEZONE } from '../../constants/DateTime';
import getActivitesAfterCount from './getActivitiesAfterCount';


const createActivityFromRow = (row, customer, entityCodes, consultantByUsernameMap, fileId, availableProducts) => {
  const activity = {};
  if (row[8]) {
    activity.amount = Number(row[8]);
  }

  const consultantId = get(row, 4);
  const consultant = get(consultantByUsernameMap, consultantId);

  if (!isNil(consultant)) {
    activity.consultantId = get(consultant, 'id');
    activity.creator = get(consultant, 'id');
    activity.consultantFirstName = get(consultant, 'firstName');
    activity.consultantLastName = get(consultant, 'lastName');
  }

  if (row[1]) {
    activity.contactDate = moment(row[1], VALIDATION_DATE_FORMATS).format(DATE_FORMAT)
      +  moment(row[1], VALIDATION_DATE_FORMATS).tz(DATE_TIMEZONE).format('ZZ');
  }
  activity.createdAt = moment().tz(DATE_TIMEZONE).format(DATE_FORMAT_GRV);

  if (row[7]) {
    const currencyCode = entityCodes.data.find(code => code.text.de === row[7]);
    if (currencyCode) {
      activity.currency = currencyCode;
    }
  }
  activity.customer = {
    id: get(customer, 'id'),
    tenant: get(customer, 'tenant'),
    consultantId: get(customer, 'consultant.id'),
    designation: get(customer, 'designation'),
    customerNumberAlternate: get(customer, 'customerNumberAlternate'),
    legalAddress: get(customer, 'legalAddress'),
  };
  activity.customerId = get(customer, 'id');
  if (row[11]) {
    activity.decision = row[11];
  }
  if (row[10]) {
    activity.externalParticipantsCustomers = row[10];
  }
  if (row[5]) {
    activity.initiative = entityCodes.data.find(code => code.id === row[5]);
  }
  if (row[9]) {
    let consultants = [];
    row[9].split(';').forEach(username => {
      if (consultantByUsernameMap[username]) {
        consultants = consultants.concat(consultantByUsernameMap[username]);
      }
    });
    activity.internalParticipantsConsultants = consultants;
  }
  if (row[12]) {
    activity.nextContact = moment(row[12], VALIDATION_DATE_FORMATS).format(DATE_FORMAT)
      +  moment(row[12], VALIDATION_DATE_FORMATS).tz(DATE_TIMEZONE).format('ZZ');
  }
  activity.note = row[14];
  if (row[6]) {
    activity.noteQuality = entityCodes.data.find(code => code.id === row[6]);
  }
  if (row[3]) {
    activity.noteTopic = entityCodes.data.find(code => code.id === row[3]);
  }
  if (row[2]) {
    activity.orderOrigin = entityCodes.data.find(code => code.id === row[2]);
  }
  if (row[13]) {
    const products = row[13].split(';').map((id) => availableProducts.find((p) => get(p, 'id') === id));
    activity.recommendedProduct = products;
  }
  activity.massUploadFile = {
    $ref: `/file/${fileId}`,
  };
  return activity;
};

export function* noticeImportImportSaga(action) {
  try {
    const { user, userId, fileId } = action.data;
    const {
      availableProducts,
      consultantByUsernameMap,
      customerByNumberMap,
      entityCodes,
      rows
    } = action.data;

    yield put(NOTICE_IMPORT_IMPORT.pending({ fileId }));

    // preparing activityList for import
    const activityList = [];
    rows.forEach(row => {
      const customer = customerByNumberMap[row[0]];
      const activity = createActivityFromRow(row, customer, entityCodes, consultantByUsernameMap, fileId, availableProducts);
      activity.creator = get(activity, 'creator', userId);
      activityList.push(activity);
    });

    // import
    let successImport = 0;
    let failImport = 0;
    const itemCountImport = activityList.length;
    const iterationCountImport = Math.ceil(itemCountImport / PARALLEL_REQUESTS_COUNT);
    for (let i = 0; i < iterationCountImport; i++) {
      const start = i * PARALLEL_REQUESTS_COUNT;
      const processIds = activityList.slice(start, start + PARALLEL_REQUESTS_COUNT);
      yield all(
        //eslint-disable-next-line
        processIds.map(activity => {
          const promise = new Promise(function(resolve, reject) {
            axios({
              url: EndpointName.WORK_ACTIVITY,
              method: 'post',
              data: activity,
            }).then(function (response) {
              successImport += 1;
              resolve(response)
            }).catch(function (error) {
              console.error(error);
              failImport += 1;
              resolve(error);
            });
          });
          return promise;
        })
      );
      yield put(NOTICE_IMPORT_IMPORT.progress({
        fileId,
        success: successImport,
        fail: failImport,
        action: 'imported',
        total: rows.length,
      }));
    }

    const activityAfterImportCount = yield call(getActivitesAfterCount, fileId);

    // update file row
    const totalRowsCount = rows.length;
    yield call(axios, {
      url: `${EndpointName.FILE}/${fileId}`,
      method: 'put',
      data: {
        id: fileId,
        metadata: {
          additionalProperties: [
            {
              name: 'imported',
              value: 'imported',
            },
            {
              name: 'lastAction',
              value: 'imported',
            },
            {
              name: 'totalRowsCount',
              value: `${totalRowsCount}`,
            },
            {
              name: 'importedRowsCount',
              value: `${activityAfterImportCount}`,
            },
            {
              name: 'failRowsCount',
              value: `${totalRowsCount - activityAfterImportCount}`,
            },
            {
              name: 'username',
              value: user.username,
            },
            {
              name: 'status',
              value: totalRowsCount === activityAfterImportCount? 'success' : 'fail',
            },
          ],
        },
        links: [{
          type: 'module',
          $ref: `/core/module/adminv2-notice-import`,
        }],
      },
    });

    yield put(NOTICE_IMPORT_IMPORT.success({
      total: totalRowsCount,
      success: successImport,
      fail: failImport,
      fileId,
    }));
  } catch (error) {
    console.error('>> error', error);
    yield put(NOTICE_IMPORT_IMPORT.failure(error));
  }
}

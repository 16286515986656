/* eslint jsx-a11y/href-no-hash: 0 */
import React from 'react';
import Toggle from 'react-toggle';
import { FormControl } from 'react-bootstrap';
import AgendaList from './List';

export default class AModule extends React.Component {
  render() {
    const labelStyle = {
        color: 'gray',
        fontSize: '40%',
        paddingLeft: '5px',
        marginRight: '25px',
      },
      inputStyle = {
        display: 'inline-block',
        width: '35%',
        border: 'none',
        fontSize: '24px',
        paddingLeft: '0',
      },
      imageKey = {
        display: 'inline-block',
        width: '120px',
        marginLeft: '12px',
        fontSize: '100%',
      };

    let {
        modules, language, onAdd, onRemove, onEdit, onChecked, onToggle, onSortEnd,
        onEditMain, editImageKey, setSelectedModule,
      } = this.props,
      selectedModule = this.props.selectedModule > (modules.length - 1) ? 0 : this.props.selectedModule;

    const toggleLabel = this.props.modules[selectedModule].collapsed ? 'Eingeklappt darstellen' : 'Ausgeklappt darstellen';

    modules = this.props.modules.map((module, i) =>
      (<li key={i} className={i === selectedModule ? 'active' : ''}>
        <a href="#" onClick={setSelectedModule.bind(this, i)}>
          <span>
            {module.name[language]}
          </span>
        </a>
       </li>));

    return (
      <div>
        <ul className="nav nav-tabs">
          {modules}
        </ul>
        <h3>
          <FormControl
            type="text"
            name="AgendaTab"
            value={this.props.modules[selectedModule].name[language]}
            onChange={onEditMain.bind(this)}
            onFocus={onEditMain.bind(this)}
            style={inputStyle}
          />
          <div style={{ float: 'right' }}>
            <label>
              <Toggle
                className="toggle-agenda"
                checked={!this.props.modules[selectedModule].collapsed}
                onChange={onToggle.bind(this)}
              />
              <span style={labelStyle}>{toggleLabel}</span>
            </label>

            <span style={{ fontSize: '40%' }}>
                            imageKey:
                            <FormControl
                              type="text"
                              name="imageKey"
                              value={this.props.modules[selectedModule].imageKey}
                              onChange={editImageKey.bind(this)}
                              style={imageKey}
                            />
            </span>
          </div>
        </h3>
        <AgendaList
          modules={this.props.modules[selectedModule].items}
          selectedModule={selectedModule}
          language={language}
          onAdd={onAdd}
          onRemove={onRemove}
          onEdit={onEdit}
          onChecked={onChecked}
          onSortEnd={onSortEnd}
          useDragHandle
          helperClass="SortableHOCHelper"
        />
      </div>
    );
  }
}

import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import {
  getCoreConfigConfigs,
  getCoreConfigConfigsInitial,
} from '../../selectors/CoreConfig/coreConfigConfigsSelectors';

export function* coreConfigSaveConfigsSaga() {
  const initialConfigs = yield select(getCoreConfigConfigsInitial);
  const configs = yield select(getCoreConfigConfigs);
  yield all(configs
    .filter((config) => {
      const initial = initialConfigs.find((it) => it.id === config.id);
      return initial && initial.value !== config.value;
    })
    .map((config) => call(axios, {
      method: 'patch',
      url: `${EndpointName.CORE_CONFIG}/${encodeURIComponent(config.id)}`,
      data: [{
        op: 'replace',
        path: '/value',
        value: config.value,
      }],
    })),
  );
}

import { takeLatest } from 'redux-saga/effects';
import { takeLatestByDataKey } from '@evoja-web/redux-saga-utils';

import actions from '../actions/Actions';
import getConfig from './getConfig';
import getConsultants from './getConsultants';
import getLeads from './getLeads';
import getProducts from './getProducts';
import saveEditForm from './saveEditForm';
import updateLead from './updateLead';

const {
  CONFIG_REQUEST,
  CONSULTANTS_REQUEST,
  LEADS_REQUEST,
  PRODUCTS_REQUEST,
  UPDATE_LEAD_REQUEST,
  EDIT_FORM_SAVE_REQUEST
} = actions;

export default function* leadMassMutationRootSaga() {
  yield takeLatest(CONFIG_REQUEST, getConfig);
  yield takeLatest(CONSULTANTS_REQUEST, getConsultants);
  yield takeLatest(LEADS_REQUEST, getLeads);
  yield takeLatest(PRODUCTS_REQUEST, getProducts);
  yield takeLatest(EDIT_FORM_SAVE_REQUEST, saveEditForm);
  yield takeLatestByDataKey(UPDATE_LEAD_REQUEST, updateLead);
}

import { v4 } from 'uuid';
import {
  ADD_TOAST
} from '../actions/Toast';

const defaultState = null;

export const methods = {
  default: state => state
}

methods[ADD_TOAST] = (state, action) => {
  const { kind, title, description, values, html, onClick } = action;
  const toast = {
    id: v4(),
    kind,
    title,
    description,
    values,
    html,
    onClick
  };

  return toast;
}


export default function toastReducer(state = defaultState, action) {
  const func = methods[action.type] || methods['default'];

  return func(state, action);
}

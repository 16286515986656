import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Select } from '@evoja-web/react-form';
import { ControlLabel, FormGroup } from 'react-bootstrap';

const options = [{
  label: 'Alle Benutzer erstellen',
  value: 'Workguides.Create.AllUsers'
}, {
  label: 'Alle Benutzer bearbeiten',
  value: 'Workguides.Edit.AllUsers'
}, {
  label: 'Administrator erstellen',
  value: 'Workguides.Create.Administrator'
}, {
  label: 'Administrator bearbeiten',
  value: 'Workguides.Edit.Administrator'
}, {
  label: 'Backoffice erstellen',
  value: 'Workguides.Create.Backoffice'
}, {
  label: 'Backoffice bearbeiten',
  value: 'Workguides.Edit.Backoffice'
}, {
  label: 'KDC erstellen',
  value: 'Workguides.Create.Kdc'
}, {
  label: 'KDC bearbeiten',
  value: 'Workguides.Edit.Kdc'
}, {
  label: 'Lead Distributor erstellen',
  value: 'Workguides.Create.LeadDistributor'
}, {
  label: 'Lead Distributor bearbeiten',
  value: 'Workguides.Edit.LeadDistributor'
}, {
  label: 'Postfinance erstellen',
  value: 'Workguides.Create.Postfinance'
}, {
  label: 'Postfinance bearbeiten',
  value: 'Workguides.Edit.Postfinance'
}, {
  label: 'UV Consultant erstellen',
  value: 'Workguides.Create.UvConsultant'
}, {
  label: 'UV Consultant bearbeiten',
  value: 'Workguides.Edit.UvConsultant'
}, {
  label: 'Pledge Resolve erstellen',
  value: 'Workguides.Create.PledgeResolve'
}, {
  label: 'Pledge Resolve bearbeiten',
  value: 'Workguides.Edit.PledgeResolve'
}];

class WorkguidePermissions extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Handle value change
   *
   * @param   {String}  id        Form element id
   * @param   {Array}   selected  Selected values
   *
   * @return  void
   */
  onChange(id, selected) {
    const { editValueField } = this.props;

    editValueField('', 'workguidePropery', { key: 'permissions', value: selected });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { workguide } = this.props;

    return (
      <FormGroup>
        <ControlLabel>
          Workguide Berechtigungen
        </ControlLabel>

        <Select
          id="permissions"
          multi
          onChange={this.onChange}
          options={options}
          value={get(workguide, 'permissions')}
        />
      </FormGroup>
    );
  }
}

WorkguidePermissions.propTypes = {
  editValueField: PropTypes.func,
  workguide: PropTypes.object.isRequired
};

WorkguidePermissions.defaultProps = {
  editValueField: noop
};

export default WorkguidePermissions;

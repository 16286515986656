import React from 'react';
import PropTypes from 'prop-types';
import { get, uniq, flatten, isUndefined, isNull, sortBy } from 'lodash';
import { Line } from 'react-chartjs-2';
import Select from 'react-virtualized-select';

import LoadObjectDataSet from './LoadObjectDataSet';
import { getRandomColor } from './utils';

function getAvailableLoadObject({ loadIds, results }) {
  const loadObjects = get(results, 'loads', [])
    .filter((load) => loadIds.includes(get(load, 'id')))
    .map((load) => Object.keys(get(load, 'actionMap', {})));

  return uniq(flatten(loadObjects));

}

function DataSets({ loadIds, results, name, colors }) {
  return loadIds.map((loadId) => {
    const options = { borderColor: get(colors, loadId, getRandomColor()) };

    return LoadObjectDataSet({ loadId, results, name, options });
  });
}

class LoadObjectsChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: undefined,
      selectedLoadObject: undefined
    };

    this.onLoadObjectChange = this.onLoadObjectChange.bind(this);
  }

  onLoadObjectChange(selected) {
    this.setState({ selectedLoadObject: get(selected, 'value') });
  }

  renderSelect() {
    const { selectedLoadObject } = this.state;
    const {
      loadIds,
      results
    } = this.props;

    const loadObjects = getAvailableLoadObject({ loadIds, results });

    const options = loadObjects.map((name) => ({ value: name, label: name }));

    return (
      <Select
        value={selectedLoadObject}
        onChange={this.onLoadObjectChange}
        options={sortBy(options, ['label'])}
      />
    )
  }

  renderLoadObjectChart() {
    const { selectedLoadObject } = this.state;
    const {
      loadColors,
      loadIds,
      results
    } = this.props;

    const datasets = DataSets({
      colors: loadColors,
      loadIds,
      results,
      name: selectedLoadObject
    });

    const data = {
      labels: [
        'spoolingInitializeSeconds',
        'spoolingTimeSeconds',
        'loadDataInfileTimeSeconds',
        'dataProviderInitializeSeconds',
        'dataProviderWorkingSeconds',
        'mongoDbPostProcessingSeconds',
        'mongoDbDatabaseWriteTimeSeconds'
      ],
      datasets
    };

    return <Line data={data} />;
  }

  render() {
    const { selectedLoadObject } = this.state;

    return (
      <div className="bulk-loads-metrics-charts--load-objects-chart">
        {this.renderSelect()}

        {!isUndefined(selectedLoadObject) && !isNull(selectedLoadObject) && this.renderLoadObjectChart()}
      </div>
    );
  }
}

LoadObjectsChart.propTypes = {
  loadColors: PropTypes.object,
  loadIds: PropTypes.array,
  results: PropTypes.object
};

LoadObjectsChart.defaultProps = {
  loadColors: {},
  loadIds: [],
  results: {}
};

export default LoadObjectsChart;

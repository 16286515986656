import { convertDateToApp } from './dateConverter';

export function isMultiDayHoliday(holiday) {
  if (!holiday.endDate) {
    return false;
  } else if (holiday.startDate === holiday.endDate) {
    return false;
  }

  const startDate = convertDateToApp(holiday.startDate);
  const endDate = convertDateToApp(holiday.endDate);

  return startDate.add(1, "day").isSameOrBefore(endDate);
}

export function isHoliday(holiday, date) {
  const startDate = convertDateToApp(holiday.startDate);
  const endDate = holiday.endDate ? convertDateToApp(holiday.endDate) : startDate;

  return (
    startDate.isSameOrBefore(date, 'day') &&
    endDate.isSameOrAfter(date, 'day')
  );
}

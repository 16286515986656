import axios from 'axios';
import { all, call, put, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { createSearchQuery } from './createSearchQuery';
import { EMAIL_SUBSCRIPTIONS_LOAD } from '../../actions/EmailSubscriptionsActions';
import { getEmailSubscriptionsSearchParams } from '../../selectors/EmailSubscriptions/emailSubscriptionsCommonSelectors';

export function* emailSubscriptionsLoadDataSaga() {
  try {
    yield put(EMAIL_SUBSCRIPTIONS_LOAD.pending());
    const searchParams = yield select(getEmailSubscriptionsSearchParams);
    const searchQuery = yield call(createSearchQuery, searchParams);
    const [
      { data: items, headers },
      { data: entityCodes },
    ] = yield all([
      call(axios, {
        url: `${EndpointName.EMAIL_SUBSCRIPTIONS}/?${searchQuery}`,
      }),
      call(axios, {
        url: `${EndpointName.ENTITY_CODE}/?in(group,(subscriptionType,subscriptionState))&limit(1000)`,
      }),
    ]);

    yield put(EMAIL_SUBSCRIPTIONS_LOAD.success({ items, entityCodes }));
  } catch (error) {
    yield put(EMAIL_SUBSCRIPTIONS_LOAD.failure(error));
  }
}

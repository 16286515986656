import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';

const authorizationCodes = [
  'contractType-6026',
  'contractType-6027',
  'contractType-115',
  'contractType-302',
  'contractType-6092',
  'contractType-102'
]

const CriteriaAuthorizations = ({ codes, edit, language, onChange, operation, source, criteria }) => {
  const multi = (operation === 'contains' || _.get(source, 'props.multi'));
  // Find all possible authorizations types from contracts code...
  const { contractType = [] } = codes;
  const path = (_.get(source, 'props.multi'))
    ? `${_.get(source, 'props.id')}[*].value.contractType.id`
    // if operation is contains, make sure that the selected value is also an array
    : (operation ==='contains') ? `[${_.get(source, 'props.id')}.value.contractType.id]` : `${_.get(source, 'props.id')}.value.contractType.id`;

  function onValueChange(selected) {
    if (multi) {
      onChange({
        source: _.get(source, 'props.id'),
        path,
        values: selected.map(s => s.value)
      });
    } else {
      onChange({
        source: _.get(source, 'props.id'),
        path,
        values: (selected) ? [selected.value] : []
      });
    }
  }

  const options = contractType
    .filter(t => authorizationCodes.includes(_.get(t, 'id')))
    .map(t => ({ value: t.id, label: _.get(t, `text.${language}`) }));

  return (edit)
    ? (
      <React.Fragment>
        <strong>Wert</strong>

        <Select
          options={options}
          onChange={onValueChange}
          multi={multi}
          value={(multi) ? _.get(criteria, 'values', []) : _.get(criteria, 'values.0')}
        />
      </React.Fragment>
    )
    :(
      <React.Fragment>
        {_.get(criteria, 'values', []).map(v => _.get(options.find(o => o.value === v), 'label')).join(', ')}
      </React.Fragment>
    );
}

CriteriaAuthorizations.propTypes = {
  types: PropTypes.array,
  edit: PropTypes.bool,
  language: PropTypes.string,
  onChange: PropTypes.func,
  operation: PropTypes.string,
  codes: PropTypes.object
};

CriteriaAuthorizations.defaultProps = {
  types: [],
  edit: false,
  language: 'de',
  onChange: _.noop,
  operation: 'eq',
  codes: {}
};

export default CriteriaAuthorizations;

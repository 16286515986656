import { Reducers } from '@evoja-web/redaction';

import { reducer as configReducer } from './Config';
import { reducer as consultantsReducer } from './Consultants';
import { reducer as editFormReducer } from './EditForm';
import { reducer as leadReducer } from './Leads';
import { reducer as locationsReducer } from './Locations';
import { reducer as productsReducer } from './Products';
import { reducer as regionsReducer } from './Regions';
import { reducer as searchFormReducer } from './SearchForm';
import { reducer as selectedLeadsReducer } from './SelectedLeads';
import { reducer as updateLeadReducer } from './UpdateLead';

export const reducers = Reducers([
  configReducer,
  consultantsReducer,
  editFormReducer,
  leadReducer,
  locationsReducer,
  productsReducer,
  regionsReducer,
  searchFormReducer,
  selectedLeadsReducer,
  updateLeadReducer
]);

export default reducers.get();

import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { reduce, set } from 'lodash';

import configAction from '../../actions/Workflow/Config';

const {
  WORKFLOW_TREE_CONFIG_SAVE_PENDING,
  WORKFLOW_TREE_CONFIG_SAVE_FULFILLED,
  WORKFLOW_TREE_CONFIG_SAVE_REJECTED
} = configAction;

export default function* saveTreeConfig(request) {
  const {
    data,
    workguideId
  } = request;

  yield put({ type: WORKFLOW_TREE_CONFIG_SAVE_PENDING });

  try {
    const { data: workguide } = yield call(axios, {
      url: `/work/workguide/${workguideId}`,
      method: 'get'
    });

    const payload = reduce(data, (result, value, key) => {
      return set(result, `workflow.${key}`, value);
    }, workguide);

    const result = yield call(axios, {
      url: `/work/workguide/${workguideId}`,
      method: 'put',
      data: payload
    });

    yield put({ type: WORKFLOW_TREE_CONFIG_SAVE_FULFILLED, payload, result });

    return payload;
  } catch (error) {
    yield put({ type: WORKFLOW_TREE_CONFIG_SAVE_REJECTED, error });

    return error;
  }
}

import { put, select } from 'redux-saga/effects';
import { getFinancingRatesForwards } from '../../selectors/FinancingRates/financingRatesCommonSelectors';
import { FINANCING_RATES_SYNC_FORWARDS_ACTIONS } from '../../actions/FinancingRatesActions';

export function* financingRatesSyncForwardsSaga({ data: { forward } }) {
  if (!forward || !forward.forwardRate) {
    return;
  }

  const allForwards = yield select(getFinancingRatesForwards);
  const updatedForwards = allForwards
    .map((it) => it.forwardFrom === forward.forwardFrom
      ? { ...it, forwardRate: forward.forwardRate }
      : it,
    );

  yield put(FINANCING_RATES_SYNC_FORWARDS_ACTIONS.success({ forwards: updatedForwards }));
}

import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import { getFinancingRatesNewForwards } from '../../selectors/FinancingRates/financingRatesForwardSelectors';
import { EndpointName } from '../../constants/EndpointName';
import { createRef } from '../../utils';

export function* financingRatesCreateForwardsSaga() {
  const newForwards = yield select(getFinancingRatesNewForwards);
  yield all(
    newForwards.map((forward) => call(axios, {
      url: createRef(EndpointName.FINANCING_FORWARD, forward.id),
      method: 'put',
      data: forward,
    })),
  );
}

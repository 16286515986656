import moment from 'moment';
import { REF_ERRORS_LOAD, REF_ERRORS_SEARCH, REF_ERRORS_LOAD_ALL } from '../actions/RefErrorsActions';
import { REF_ERRORS_DEFAULT_SORT } from '../constants/RefErrorsConstants';

const initialState = {
  textBlocks: [],
  loading: false,
  error: undefined,
  results: undefined,
  allResults: undefined,
  searchParams: {
    dateFrom: moment().subtract(1, 'day'),
    dateTo: moment(),
    ...REF_ERRORS_DEFAULT_SORT,
  },
};

export function RefErrorsReducer(state = initialState, action) {
  switch (action.type) {
    case REF_ERRORS_LOAD.PENDING:
      return { ...state, loading: true, error: undefined };
    case REF_ERRORS_LOAD.SUCCESS: {
      const { textBlocks, results } = action.data;
      return { ...state, loading: false, error: undefined, textBlocks, results };
    }
    case REF_ERRORS_LOAD.FAILURE:
      return { ...state, loading: false, error: action.data };

    case REF_ERRORS_SEARCH.PENDING:
      return { ...state, loading: true, error: undefined };
    case REF_ERRORS_SEARCH.SUCCESS: {
      const { searchParams, results } = action.data;
      return { ...state, loading: false, results, searchParams };
    }
    case REF_ERRORS_SEARCH.FAILURE:
      return { ...state, loading: false, error: action.data };

      case REF_ERRORS_LOAD_ALL.PENDING:
        return { ...state, loading: true, error: undefined };
      case REF_ERRORS_LOAD_ALL.SUCCESS: {
        const {results } = action.data;
        return { ...state, loading: false, error: undefined, allResults: results };
      }
      case REF_ERRORS_LOAD_ALL.FAILURE:
        return { ...state, loading: false, error: action.data };

    default:
      return state;
  }
}
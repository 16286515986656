import uuid from 'uuid';
import axios from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { UI_NOTES_CREATE_ACTIONS } from '../../actions/UiNotesActions';
import { getUiNotesImage, getUiNotesTypes } from '../../selectors/UiNotes/uiNotesSelectors';
import { convertDateToApi } from '../../utils/dateConverter';
import { uiNotesUploadImage } from './uiNotesUploadImage';

export function* uiNotesCreateSaga({ data: { form } }) {
  try {
    yield put(UI_NOTES_CREATE_ACTIONS.pending());

    const uploadedImage = yield select(getUiNotesImage);
    if (uploadedImage) {
      yield call(uiNotesUploadImage, uploadedImage);
    }

    const types = yield select(getUiNotesTypes);

    const note = {
      id: yield call(uuid.v4),
      enabled: form.enabled,

      type: form.typeId
        ? types.find(code => code.id === form.typeId)
        : undefined,
      title: {
        de: form.title,
        en: form.title,
        fr: form.title,
      },
      text: {
        de: form.text,
        en: form.text,
        fr: form.text,
      },
      publishDate: form.publishDate
        ? yield call(convertDateToApi, form.publishDate)
        : undefined,
      image: form.image
        ? { $ref: form.image }
        : undefined,
    };
    yield call(axios.put, `${EndpointName.WORK_UI_NOTES}/${encodeURIComponent(note.id)}`, note);

    yield put(UI_NOTES_CREATE_ACTIONS.success({ note }));
  } catch (error) {
    yield put(UI_NOTES_CREATE_ACTIONS.failure(error));
  }
}

import { select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { createRef } from '../../utils';
import { getSetManagementBundlesRaw } from '../../selectors/SetManagement/setManagementCommonSelectors';
import { getLanguage } from '../../selectors/commonSelectors';

export function* setManagementChangeBundles(bundles) {
  const bundlesRaw = yield select(getSetManagementBundlesRaw);
  if (!bundles) {
    return bundlesRaw;
  }

  const locale = yield select(getLanguage);
  return bundlesRaw.map((bundleRaw) => {
    const bundle = bundles.find((it) => it.id === bundleRaw.id);
    if (!bundle) {
      return bundleRaw;
    }

    return {
      ...bundleRaw,
      order: bundle.order,
      domain: bundle.domain
        ? [{ $ref: createRef(EndpointName.ENTITY_CODE, bundle.domain) }]
        : [],
      name: bundle.name === undefined
        ? bundleRaw.name
        : {
          ...bundleRaw.name,
          [locale]: bundle.name,
        },
      shortName: bundle.shortName === undefined
        ? bundleRaw.shortName
        : {
          ...bundleRaw.shortName,
          [locale]: bundle.shortName,
        },
      bundlePrice: bundle.bundlePrice
        ? bundle.bundlePrice
        : undefined,
      bundlePriceReduced: bundle.bundlePriceReduced
        ? bundle.bundlePriceReduced
        : undefined,
      bundlePriceYouth: bundle.bundlePriceYouth,
    };
  });
}

import { get, reduce } from 'lodash';

import {
  TRANSFER_ITEM_STATUS_WAITING,
  TRANSFER_ITEM_STATUS_PENDING,
  TRANSFER_ITEM_STATUS_FULFILLED,
  TRANSFER_ITEM_STATUS_REJECTED
} from '../../constants';

const status = {
  [TRANSFER_ITEM_STATUS_PENDING]: ({ item }) => get(item, 'requesting', false),
  [TRANSFER_ITEM_STATUS_FULFILLED]: ({ item }) => get(item, 'fulfilled', false),
  [TRANSFER_ITEM_STATUS_REJECTED]: ({ item }) => get(item, 'error') instanceof Error
};

/**
 * Get the current status for the given item
 *
 * @param   {Object}  item  Item from state.paramTransfer.transferItem
 *
 * @return  {String} status Status of the item
 */
export default function getItemStatus({ item }) {
  return reduce(status, (result, check, key) => {
    if (check({ item })) {
      return key;
    }

    return result;
  }, TRANSFER_ITEM_STATUS_WAITING);
}

import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, Col, FormControl, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import Toggle from 'react-toggle';
import _, { get } from 'lodash';

class InputConfiguration extends React.Component {
  constructor(props) {
    super(props);
    const { item, languages } = props;
    const defaults = get(item, 'props.defaults', {});

    this.inputTypes = [
      { value: 'input', label: 'Einzeilger Text' },
      { value: 'textarea', label: 'Mehrzeiliger Text' },
      { value: 'number', label: 'Zahl' },
      { value: 'phone', label: 'Telefonnummer' },
      { value: 'email', label: 'Email Adresse' },
    ];

    const isJmesInput = languages.reduce((result, language) => {
      result[language] = get(defaults, `${language}[0]`, '').includes('jmes:');
      return result;
    }, {});

    this.state = {
      isJmesInput,
    };

    this.onDefaultValueChange = this.onDefaultValueChange.bind(this);
    this.toggleJmesInput = this.toggleJmesInput.bind(this);
    this.onJmesChange = this.onJmesChange.bind(this);
    this.onMaxLengthChange = this.onMaxLengthChange.bind(this);
  }

  toggleJmesInput(language) {
    const { isJmesInput } = this.state;
    const { id, item, editValueField } = this.props;
    const defaults = get(item, 'props.defaults', {});

    this.setState({ isJmesInput: { ...isJmesInput, [language]: !isJmesInput[language] } });
    editValueField(id, 'defaultValue', { ...defaults, [language]: [] });
  }

  onJmesChange(language, ev) {
    const { id, item, editValueField } = this.props;
    const defaults = get(item, 'props.defaults', {});
    const value = get(ev, 'target.value');

    editValueField(id, 'defaultValue', { ...defaults, [language]: [`jmes:${value.replace('jmes:', '')}`] });
  }

  onDefaultValueChange(language, ev) {
    const { id, editValueField, item } = this.props;
    const defaults = _.get(item, `props.defaults`, {});
    const value = get(ev, 'target.value') && get(ev, 'target.value', '').trim().length > 0 ? [ev.target.value] : [];

    editValueField(id, 'defaultValue', { ...defaults, [language]: value });
  }
  onMaxLengthChange(ev) {
    const { id, editValueField } = this.props;

    let value = get(ev, 'target.value', '');
    value = get(value.trim(), 'length', 0) === 0
      ? undefined
      : parseInt(value, 10);
      editValueField(id, 'prop', { key: 'maxLength', value })
  }

  onRangeChange(type, ev) {
    const { id, editValueField } = this.props;
    let value = get(ev, 'target.value', '');
    if(!value || value <= 0) return;
    value = value % 1 !== 0 ? parseFloat(value): parseInt(value, 10);

    editValueField(id, type, value);
  }

  onInputTypeChange(type, selected) {
    const { id, editValueField } = this.props;
    this.setState({ selectedInputType: selected });
    editValueField(id, type, selected);
  }

  /**
   * Render tabs default values
   *
   * @return {Array} tabs Array of tab elements
   */
  renderTabs(col) {
    const { isJmesInput } = this.state;
    const { item, languages } = this.props;
    const defaults = _.get(item, `props.defaults`, {});
    const type = isJmesInput
      ? 'text'
      : get(item, 'props.type');

    const tabs = languages.map((l) => (
      <Tab key={l} eventKey={l} title={l}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <Toggle checked={get(this, `state.isJmesInput.${l}`, false)} onChange={() => this.toggleJmesInput(l)} />
          </div>
          <div style={{ paddingLeft: '10px' }}>Pfad manuell eingeben</div>
        </div>

        <FormControl
          value={get(defaults, `${l}[0]`, '')}
          type={type}
          onChange={(ev) => (get(this, `state.isJmesInput.${l}`, false) ? this.onJmesChange(l, ev) : this.onDefaultValueChange(l, ev))}
        />
      </Tab>
    ));

    return tabs;
  }

  render() {
    const { active } = this.state;
    const { id, item, editValueField } = this.props;

    return (
      <React.Fragment>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Lookup
          </Col>
          <Col sm={9}>
            <Toggle
              disabled={_.get(item, 'props.type') === 'number'}
              checked={_.get(item, 'props.lookup', false)}
              onChange={(e) => editValueField(id, 'lookup', e.target.checked)}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Als Mail Subject verwenden
          </Col>
          <Col sm={9}>
            <Toggle
              checked={_.get(item, 'props.useAsMailSubject', false)}
              onChange={(e) => editValueField(id, 'useAsMailSubject', e.target.checked)}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Eingabefeld Art
          </Col>
          <Col sm={9}>
            <Select
              placeholder="Eingabefeld Ausprägung wählen"
              clearable={false}
              name={`components.${id}.props.type`}
              options={this.inputTypes}
              value={item.props.type}
              onChange={(selectedOption) => this.onInputTypeChange('componentType', selectedOption.value)}
            />
          </Col>
        </FormGroup>

        <FormGroup>
        <Col componentClass={ControlLabel} sm={3}>
          Maximale Textlänge
        </Col>
        <Col sm={9}>
          <FormControl
            value={get(item, 'props.maxLength', '')}
            type="number"
            onChange={this.onMaxLengthChange}
            style={{ width: '20%' }}
          />
        </Col>
      </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Standard Wert
          </Col>

          <Col sm={9}>
            <Tabs id="action-title" activeKey={active} onSelect={this.setActiveTab}>
              {this.renderTabs()}
            </Tabs>
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

InputConfiguration.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  item: PropTypes.object.isRequired,
  editValueField: PropTypes.func,
  language: PropTypes.string,
  languages: PropTypes.array,
};

InputConfiguration.defaultProps = {
  editValueField: _.noop,
  language: 'de',
  languages: ['de', 'fr', 'en'],
};

export default InputConfiguration;

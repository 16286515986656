export const CODES_REQUEST = 'adminUI/codes/CODES_REQUEST';
export const CODES_PENDING = 'adminUI/codes/CODES_PENDING';
export const CODES_FULFILLED = 'adminUI/codes/CODES_FULFILLED';
export const CODES_REJECTED = 'adminUI/codes/CODES_REJECTED';

export const CODE_SAVE_REQUEST = 'adminUI/codes/CODE_SAVE_REQUEST';
export const CODE_SAVE_PENDING = 'adminUI/codes/CODE_SAVE_PENDING';
export const CODE_SAVE_FULFILLED = 'adminUI/codes/CODE_SAVE_FULFILLED';
export const CODE_SAVE_REJECTED = 'adminUI/codes/CODE_SAVE_REJECTED';

export const CODE_DELETE_REQUEST = 'adminUI/codes/CODE_DELETE_REQUEST';
export const CODE_DELETE_PENDING = 'adminUI/codes/CODE_DELETE_PENDING';
export const CODE_DELETE_FULFILLED = 'adminUI/codes/CODE_DELETE_FULFILLED';
export const CODE_DELETE_REJECTED = 'adminUI/codes/CODE_DELETE_REJECTED';

export function codesRequest(groups = []) {
  return { type: CODES_REQUEST, groups };
}

export function codeSaveRequest(code) {
  return { type: CODE_SAVE_REQUEST, code };
}

export function codeDeleteRequest(code) {
  return { type: CODE_DELETE_REQUEST, code };
}

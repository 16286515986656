import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ANALYTICS_ACTIVE_USERS_LOAD,
  ANALYTICS_HISTORY_SEARCH,
  ANALYTICS_HISTORY_SEARCH_DETAILS,
} from '../../actions/AnalyticsActions';
import { loadActiveUsersSaga } from './analyticsLoadActiveUsersSaga';
import { historySearchSaga } from './analyticsHistorySearchSaga';
import { historySearchDetailsSaga } from './analyticsHistorySearchDetailsSaga';

export function* analyticsSagas() {
  yield takeLatest(ANALYTICS_ACTIVE_USERS_LOAD.REQUEST, loadActiveUsersSaga);
  yield takeEvery(ANALYTICS_HISTORY_SEARCH.REQUEST, historySearchSaga);
  yield takeEvery(ANALYTICS_HISTORY_SEARCH_DETAILS.REQUEST, historySearchDetailsSaga);
}

import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, ControlLabel, FormControl, Col } from 'react-bootstrap';
import Toggle from 'react-toggle';
import 'react-rangeslider/lib/index.css';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import _ from 'lodash';

import { getHostName, getIdFromRef } from '../../utils';

export default class InvestmentProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.multiselectChange = this.multiselectChange.bind(this);
    this.editValueField = this.props.editValueField.bind(this);
  }

  multiselectChange(values) {
    this.props.editValueField('coreType', this.props.id, values)
  }

  render() {
    const {
      id, item, language, editEventField,
      knowledgeExperiences, assetSegments, assetAllocations, assetClasses,
      serviceSubTypes, investmentStrategies, investmentDocuments,
    } = this.props;

    const assetClassesSelect = assetClasses.map((o) => ({label: o.name[language], value: o.id}));
    const assetSegmentSelect = assetSegments.map((o) => ({label: o.text[language], value: o.id}));
    const knowledgeExperienceSelect = knowledgeExperiences.map((o) => ({label: o.text[language], value: o.number}));
    const assetServiceSubTypesSelect = serviceSubTypes.map(o => ({ label: o.name[language], value: o.id }));
    const assetStrategiesSelect = investmentStrategies.map(o => ({ label: o.name[language], value: o.id }));
    const investmentDocument = getInvestmentDocumentUrl(investmentDocuments, item.investmentDocument);
    const productBasicInformation = getInvestmentDocumentUrl(investmentDocuments, item.productBasicInformation);

    const assetAllocationSelect = assetAllocations.map((o) => {
      return (
        ({ label: _.find(investmentStrategies, (strategy) => strategy.id === _.last(o.strategy.$ref.split('/'))).name[language], value: o.id })
      );
    });

    const apiRefName = serviceSubTypes[0].productsPerStrategy[0].strategy
      ? getHostName(serviceSubTypes[0].productsPerStrategy[0].strategy.$ref)
      : '';
    const selectedServiceSubTypes = _.filter(
      serviceSubTypes,
      { productsPerStrategy:
        [ { product:
          [ { $ref: `${apiRefName}/investment/product/${item.id}` }
        ] }
      ] });

    // evaluate the value of the multiselect for strategy
    let selectedStrategyIds = [];
    if (selectedServiceSubTypes.length > 0) {

      selectedStrategyIds = _.filter(selectedServiceSubTypes[0].productsPerStrategy,
        { product: [ { $ref: `${apiRefName}/investment/product/${item.id}` } ] }
      ).map((s) => getIdFromRef(s.strategy.$ref));
    }
    const selectedStrategies = selectedStrategyIds.map((o) => _.find(investmentStrategies, (strategy) => strategy.id === o));

    return (
      <div>
      <Form horizontal>
        <FormGroup controlId="formHorizontalName">
          <Col componentClass={ControlLabel} sm={3}>
            Produktname
          </Col>
          <Col sm={9}>
            <FormControl
              onChange={editEventField.bind(this, 'name', id)}
              type="text"
              value={item.name[language]}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalProductType">
          <Col componentClass={ControlLabel} sm={3}>
            Produkt Typ
          </Col>
          <Col sm={9}>
            <FormControl
              componentClass="select"
              value={item.productType}
              onChange={editEventField.bind(this, 'productType', id)}
            >
              <option value="saving">Ersparnisse</option>
              <option value="investment">Investition</option>
            </FormControl>
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalVariant">
          <Col componentClass={ControlLabel} sm={3}>
            Variante
          </Col>
          <Col sm={9}>
            <FormControl
              value={item.variant || ''}
              type="text"
              onChange={editEventField.bind(this, 'variant', id)}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalHasNote">
          <Col componentClass={ControlLabel} sm={3}>
            Hat Notiz
          </Col>
          <Col sm={9}>
          <Toggle
              checked={item.hasNote}
              onChange={editEventField.bind(this, 'hasNote', id)}
          />
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalMinMax">
          <Col componentClass={ControlLabel} sm={3}>
            Min. Wert
          </Col>
          <Col sm={3}>
            <FormControl
              value={item.minValue}
              type="text"
              onChange={editEventField.bind(this, 'minValue', id)}
            />
          </Col>
          <Col componentClass={ControlLabel} sm={3}>
            Max. Wert
          </Col>
          <Col sm={3}>
            <FormControl
              value={item.maxValue}
              type="text"
              onChange={editEventField.bind(this, 'maxValue', id)}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalKnowledgeExperience">
          <Col componentClass={ControlLabel} sm={3}>
            Kenntnisse & Erfahrungen
          </Col>
          <Col sm={9}>
            <Select
              placeholder="Bitte Kenntnisse & Erfahrungen zuteilen"
              clearable={true}
              name="knowledgeExperience"
              options={knowledgeExperienceSelect}
              value={item.knowledgeExperience}
              onChange={editEventField.bind(this, 'knowledgeExperience', id)}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalAssetClasses">
          <Col componentClass={ControlLabel} sm={3}>
            Anlageklassen
          </Col>
          <Col sm={9}>
            <Select
              placeholder="Bitte Anlageklasse zuteilen"
              clearable={true}
              name="assetClass"
              options={assetClassesSelect}
              value={item.assetClass?_.last(item.assetClass.$ref.split('/')):null}
              onChange={(selectedOption) => this.editValueField(selectedOption, 'assetClass', id)}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalAssetAllocations">
          <Col componentClass={ControlLabel} sm={3}>
            Asset Allocation
          </Col>
          <Col sm={9}>
            <Select
                placeholder="Bitte Asset Allocation zuteilen"
                clearable={true}
                name="assetAllocation"
                options={assetAllocationSelect}
                value={item.assetAllocation?_.last(item.assetAllocation.$ref.split('/')):null}
                onChange={(selectedOption) => this.editValueField(selectedOption, 'assetAllocation', id)}
              />
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalAssetSegments">
          <Col componentClass={ControlLabel} sm={3}>
            Kapital Segmente
          </Col>
          <Col sm={9}>
            <Select
                clearable={true}
                placeholder="Bitte Kapitalsegmente zuteilen"
                name="assetSegment"
                options={assetSegmentSelect}
                value={item.assetSegment?_.last(item.assetSegment.$ref.split('/')):null}
                onChange={(selectedOption) => this.editValueField(selectedOption, 'assetSegment', id)}
              />
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalAssetStrategy">
          <Col componentClass={ControlLabel} sm={3}>
            Strategie
          </Col>
          <Col sm={9}>
            <Select
              multi
              clearable={false}
              placeholder="Bitte Strategien zuteilen"
              options={assetStrategiesSelect}
              onChange={(selectedOption) => this.editValueField(selectedOption, 'strategy', id, selectedServiceSubTypes)}
              value={ _.uniqBy(selectedStrategies.map((o) => ({ label: o.name[language], value: o.id }))) }
              closeOnSelect={false}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalAssetServiceSubType">
          <Col componentClass={ControlLabel} sm={3}>
            Servicetyp
          </Col>
          <Col sm={9}>
            <Select
              placeholder="Bitte Servicetyp auswählen"
              clearable={false}
              options={assetServiceSubTypesSelect}
              onChange={(selectedOption) => this.editValueField(selectedOption, 'serviceSubType', id, selectedStrategies)}
              closeOnSelect={true}
              value={{ label: _.get(selectedServiceSubTypes, `0.name.${language}`), value: _.get(selectedServiceSubTypes, '0.id') }}
            />
          </Col>
        </FormGroup>
        <FormGroup controlId="formHorizontalDocumentUrl">
          <Col componentClass={ControlLabel} sm={3}>
            URL Fact-Sheet
          </Col>
          <Col sm={9}>
            <FormControl
              value={investmentDocument}
              type="text"
              onChange={editEventField.bind(this, 'investmentDocument', id)}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalValor">
          <Col componentClass={ControlLabel} sm={3}>
            Valor
          </Col>
          <Col sm={9}>
            <FormControl
              onChange={editEventField.bind(this, 'valorNumber', id)}
              type="text"
              value={item.valorNumber || ''}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalBasicInfoUrl">
          <Col componentClass={ControlLabel} sm={3}>
            URL Basisinformationsblatt
          </Col>
          <Col sm={9}>
            <FormControl
              value={productBasicInformation}
              type="text"
              onChange={editEventField.bind(this, 'productBasicInformation', id)}
            />
          </Col>
        </FormGroup>
        </Form>
      </div>
    );
  }
}

InvestmentProductDetails.propTypes = {
  id: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  editEventField: PropTypes.func.isRequired,
  editValueField: PropTypes.func.isRequired,
  assetSegments: PropTypes.array,
  knowledgeExperiences: PropTypes.array,
  assetAllocations: PropTypes.array,
  assetClasses: PropTypes.array,
  serviceSubTypes: PropTypes.array,
  investmentStrategies: PropTypes.array,
  investmentDocuments: PropTypes.array,
};

InvestmentProductDetails.defaultProps = {
  assetSegments: [],
  knowledgeExperiences: [],
  assetAllocations: [],
  assetClasses: [],
  serviceSubTypes: [],
  investmentStrategies: [],
  investmentDocuments: [],
};


function getInvestmentDocumentUrl(investmentDocuments, $refObject) {
  const documentId = $refObject && $refObject.$ref
    ? getIdFromRef($refObject.$ref)
    : undefined;
  const investmentDocument = _.find(investmentDocuments, { id: documentId });
  return _.get(investmentDocument, 'documentType.0.documentSource', '');
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';

import { handleGravitonError } from '../utils';
import FeatureFlagList from '../components/FeatureFlag/FeatureFlagList';
import PageContainer from '../components/Common/PageContainer';
import featureFlagActions from '../actions/FeatureFlag/Actions';
import FeatureFlagModal from '../components/FeatureFlag/FeatureFlagModal';

class FeatureFlagsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editModal: {
        isOpen: false,
        featureFlag: undefined
      }
    };

    this.onUpdate = this.onUpdate.bind(this);
    this.onEditModalClose = this.onEditModalClose.bind(this);
  }

  componentDidMount() {
    const { featureFlagActions } = this.props;
    featureFlagActions.featureFlagRequest();
  }

  onUpdate(featureFlag) {
    this.setState({
      editModal: {
        isOpen: true,
        featureFlag
      }
    });
  }

  onEditModalClose() {
    this.setState({
      editModal: {
        isOpen: false,
        featureFlag: undefined
      }
    });
  }

  renderEditModal() {
    const { language } = this.props;
    const { editModal: { isOpen, featureFlag } } = this.state;

    if (!isOpen) return null;

    return (
      <FeatureFlagModal
        isOpen={isOpen}
        onCloseModal={this.onEditModalClose}
        language={language}
        featureFlag={featureFlag}
      />
    );
  }

  render() {
    const { requesting, featureFlags, language, error } = this.props;
    if (error) {
      toast.error(handleGravitonError(error));
    }
    return (
      <PageContainer
        requesting={requesting}
        title="Feature Flags"
      >
        <FeatureFlagList
          featureFlags={featureFlags}
          language={language}
          onUpdate={this.onUpdate}
        />
        {this.renderEditModal()}
      </PageContainer>
    );
  }
}

FeatureFlagsPage.propTypes = {
  featureFlagActions: PropTypes.object.isRequired,
  requesting: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  featureFlags: PropTypes.array,
  language: PropTypes.string,
};

FeatureFlagsPage.defaultProps = {
  requesting: true,
  error: false,
  featureFlags: [],
  language: 'de'
};

function mapStateToProps(state) {
  return {
    requesting: state.featureFlags.featureFlags.requesting,
    error: state.featureFlags.featureFlags.error,
    featureFlags: state.featureFlags.featureFlags.data,
    language: state.login.language,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    featureFlagActions: bindActionCreators(featureFlagActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeatureFlagsPage);

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, slice } from 'lodash';

import './EmailSubscriptionsTable.css';
import { SortableTable } from '../Common/SortableTable';
import { EMAIL_SUBSCRIPTIONS_PAGE_SIZE } from '../../constants/EmailSubscriptionsConstants';
import {
  EMAIL_SUBSCRIPTIONS_PROP_TYPES_ITEM,
  EMAIL_SUBSCRIPTIONS_PROP_TYPES_SEARCH_PARAMS,
} from './EmailSubscriptionsPropTypes';
import { EmailSubscriptionsItem } from './EmailSubscriptionsItem';
import { EmailSubscriptionsCreateItem } from './EmailSubscriptionsCreateItem';
import Pagination from '../Pagination/PaginationNoTotalCount';

const SORTABLE_FIELDS = [
  {
    key: 'firstName',
    label: <FormattedMessage id="emailSubscriptions_firstName" />,
    sortFn: () => undefined,
  },
  {
    key: 'lastName',
    label: <FormattedMessage id="emailSubscriptions_lastName" />,
    sortFn: () => undefined,
  },
  {
    key: 'email',
    label: <FormattedMessage id="emailSubscriptions_email" />,
    sortFn: () => undefined,
  },
  {
    key: 'subscriptionType',
    label: <FormattedMessage id="emailSubscriptions_subscriptionType" />,
    sortFn: () => undefined,
  },
  {
    key: 'subscriptionState',
    label: <FormattedMessage id="emailSubscriptions_subscriptionState" />,
    sortFn: () => undefined,
  },
  {
    key: 'createDate',
    label: <FormattedMessage id="emailSubscriptions_createDate" />,
    sortFn: () => undefined,
  },
  {
    key: 'empty',
    label: '',
    sortFn: undefined,
  },
];

export class EmailSubscriptionsTable extends React.PureComponent {
  onChangePage = ({ limit, offset }) => {
    const { rawData, search, searchParams } = this.props;
    search({
      ...searchParams,
      limit,
      offset,
      rawData
    });
  };

  sort = (field, direction) => {
    const { search, searchParams } = this.props;
    search({
      ...searchParams,
      sortField: field,
      sortDirection: direction,
      rawData: [],
      limit: EMAIL_SUBSCRIPTIONS_PAGE_SIZE,
      offset: 0,
    });
  };

  renderItem = (item) => <EmailSubscriptionsItem key={item.id} item={item} />;

  renderContent( items, searchParams ) {
    const {
      limit = EMAIL_SUBSCRIPTIONS_PAGE_SIZE,
      offset = 0
    } = searchParams;

    const data = slice(items, offset, limit + offset);

    return (
      <div className="email-subscriptions-table__content">
        {items.length > 0 && <SortableTable
          items={data}
          sortableFields={SORTABLE_FIELDS}
          renderItem={this.renderItem}
          className="email-subscriptions-table__table"
          onSort={this.sort}
          sortField={searchParams.sortField}
          sortType={searchParams.sortDirection}
          isExternalSortField={true}
        />}
        <div className="email-subscriptions-table__add">
          <EmailSubscriptionsCreateItem />
        </div>
      </div>
    );
  }

  render() {
    const { items, loading, searchParams } = this.props;

    return (
      <div className="email-subscriptions-table">
        {this.renderContent(items, searchParams)}
        <Pagination
          onPageChange={this.onChangePage}
          pageSize={EMAIL_SUBSCRIPTIONS_PAGE_SIZE}
          recordCount={get(items, 'length', 0)}
          requesting={loading}
        />
      </div>
    );
  }
}

EmailSubscriptionsTable.propTypes = {
  items: PropTypes.arrayOf(EMAIL_SUBSCRIPTIONS_PROP_TYPES_ITEM),
  search: PropTypes.func.isRequired,
  searchParams: EMAIL_SUBSCRIPTIONS_PROP_TYPES_SEARCH_PARAMS,
  loading: PropTypes.bool,
  rawData: PropTypes.array
};

EmailSubscriptionsTable.defaultProps = {
  loading: false,
  rawData: []
}



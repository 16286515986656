import { call, put } from 'redux-saga/effects';

// ToDo: Move functions like handleNextHeaders to own repo
import codeMappingsAction from '../../actions/CodeMapping/CodeMappings';
import handleNextHeaders from '../../utils/handleNextHeaders';

const {
  CODE_MAPPINGS_PENDING,
  CODE_MAPPINGS_FULFILLED,
  CODE_MAPPINGS_REJECTED
} = codeMappingsAction;

export default function* getCodeMapping(request) {
  yield put({ type: CODE_MAPPINGS_PENDING });

  try {
    const url = '/entity/codemapping/?limit(200)';
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: CODE_MAPPINGS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CODE_MAPPINGS_REJECTED, error });

    return error;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isNil } from 'lodash';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { TextInput, ValidationResult } from '@evoja-web/react-form';
import Highlight from 'react-highlight';

import './MappingEntry.css';

class ActionConfigurationServiceCallMappingEntry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false
    };

    this.onEdit = this.onEdit.bind(this);
    this.onReadOnly = this.onReadOnly.bind(this);
  }

  componentDidMount() {
    const {
      action,
      id
    } = this.props;

    const value = get(action, `jmesParams.mapping.${id}`);

    if (isNil(value)) {
      this.onEdit();
    }
  }

  /**
   * Toggle edit mode
   *
   * @return  void
   */
  onEdit() {
    const {
      action,
      id
    } = this.props;

    this.setState({ edit: true }, () => {
      const el = document.getElementById(`mapping-entry-${action.id}-${id}`);
      if (!isNil(el)) {
        el.focus();
      }
    });
  }

  /**
   * Toggle read only mode
   *
   * @return  void
   */
  onReadOnly() {
    this.setState({ edit: false });
  }

  /**
   * Render the preview (read only)
   *
   * @return  void
   */
  renderPreview() {
    const {
      action,
      id
    } = this.props;

    const value = get(action, `jmesParams.mapping.${id}`);

    return (
      <div onDoubleClick={this.onEdit}>
        <Highlight className="js">
          {value}
        </Highlight>
      </div>
    );
  }

  /**
   * Render edit
   *
   * @return  void
   */
  renderForm() {
    const {
      action,
      id,
      onChange
    } = this.props;

    const value = get(action, `jmesParams.mapping.${id}`);

    return (
      <FormGroup>
        <TextInput
          autofocus
          id={`mapping-entry-${action.id}-${id}`}
          onBlur={this.onReadOnly}
          onChange={(key, value, ...rest) => onChange(id, value, ...rest)}
          type="textarea"
          value={value}
        />
      </FormGroup>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { edit } = this.state;
    const {
      id,
      onRemove
    } = this.props;

    return (
      <div className="workguide-action-configuration-service-call--mapping--entry">
        <div className="workguide-action-configuration-service-call--mapping--entry--label">
          <ControlLabel>
            {id}
          </ControlLabel>
        </div>

        <div className="workguide-action-configuration-service-call--mapping--entry--value">
          {!edit && this.renderPreview()}
          {edit && this.renderForm()}
        </div>

        <div
          className="mdi mdi-trash-can-outline workguide-action-configuration-service-call--mapping--entry--icon"
          onClick={() => onRemove(id)}
        />
      </div>
    );
  }
}

ActionConfigurationServiceCallMappingEntry.propTypes = {
  action: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func
};

ActionConfigurationServiceCallMappingEntry.defaultProps = {
  onChange: noop,
  onRemove: noop
};

export default ActionConfigurationServiceCallMappingEntry;

import { all, call, put, select } from 'redux-saga/effects';
import { FINANCING_PROFILING_SAVE_ACTIONS } from '../../actions/FinancingProfilingActions';
import {
  getFinancingProfilingInitial,
  getFinancingProfilingProfilesRaw,
} from '../../selectors/FinancingProfiling/financingProfilingCommonSelectors';
import axios from 'axios';
import { compareRefs, createRef } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';

export function* financingProfilingSaveSaga() {
  try {
    yield put(FINANCING_PROFILING_SAVE_ACTIONS.pending());

    const profiles = yield select(getFinancingProfilingProfilesRaw);
    const { profiles: initialProfiles } = yield select(getFinancingProfilingInitial);

    const removed = getRemoved(profiles, initialProfiles);
    if (removed.length) {
      yield all(removed.map((profile) => call(axios, {
        url: createRef(EndpointName.FINANCING_PROFILING, profile.id),
        method: 'delete',
      })));
    }

    const changed = getChanged(profiles, initialProfiles);
    if (changed.length) {
      yield all(changed.map((profile) => call(axios, {
        url: createRef(EndpointName.FINANCING_PROFILING, profile.id),
        method: 'put',
        data: profile,
      })));
    }

    yield put(FINANCING_PROFILING_SAVE_ACTIONS.success());
  } catch (error) {
    yield put(FINANCING_PROFILING_SAVE_ACTIONS.failure(error));
  }
}

function getChanged(profiles, initialProfiles) {
  return profiles.filter((current) => {
    const initial = initialProfiles.find((it) => it.id === current.id);
    return initial ? !compareProfiles(current, initial) : true;
  });
}

function getRemoved(profiles, initialProfiles) {
  return initialProfiles.filter((initial) => !profiles.some((current) => current.id === initial.id));
}

function compareProfiles(a, b) {
  return (
    a.limitStart === b.limitStart &&
    a.limitEnd === b.limitEnd &&
    compareRefs(a.profile, b.profile) &&
    compareRefs(a.prediction, b.prediction) &&
    compareProducts(a.defaultProducts, b.defaultProducts) &&
    compareProducts(a.availableProducts, b.availableProducts)
  );
}

function compareProducts(a, b) {
  return (
    a.length === b.length &&
    a.every((itemA) => b.some((itemB) =>
      compareRefs(itemA.product, itemB.product) &&
      compareRefs(itemA.duration, itemB.duration)))
  );
}

import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { API_CLIENT_EXECUTE } from '../../actions/ApiClientActions';
import { EndpointName } from '../../constants/EndpointName';

export function* apiClientExecuteSaga(action) {
  try {
    yield put(API_CLIENT_EXECUTE.pending());

    const { service, method, params, body, headers, customUrl } = action.data;

    const request = createRequest(service, method, params, body, headers, customUrl);
    const startTimestamp = Date.now();
    const response = yield call(axios, request);

    yield put(API_CLIENT_EXECUTE.success({
      result: {
        request: action.data,
        response,
        duration: Date.now() - startTimestamp,
      },
    }));
  } catch (error) {
    yield put(API_CLIENT_EXECUTE.failure({
      result: {
        request: action.data,
        response: error.response ? error.response : error,
      },
    }));
  }
}

function createRequest(service, method, params, body, headers, customUrl) {
  let url = service;

  if(customUrl !== undefined){
    url = customUrl;
  }

  const request = {
    method: method,
    url: params
      ? `${url}${params}`
      : url,
  };

  if (body) {
    request.data = JSON.parse(body);
  }

  if (headers && headers.length) {
    request.headers = headers
      .filter((header) => header.key && header.value)
      .reduce((result, header) => {
        result[header.key.toLowerCase()] = header.value;
        return result;
      }, {});
  }

  if (isFileLoadingRequest(service, method, params, request.headers)) {
    request.responseType = 'blob';
  }

  return request;
}

function isFileLoadingRequest(service, method, params, headers) {
  if (service !== EndpointName.FILE + '/') {
    return false;
  }

  if (method !== 'get') {
    return false;
  }

  if (!params || params[0] === '?') {
    return false;
  }

  return (
    !headers ||
    !headers['accept'] ||
    headers['accept'] !== 'application/json'
  );
}

/**
 * Get an array of possible paths to the source items name value
 *
 * @param   {String}  language  user language
 *
 * @return  {Array} paths Array of possible paths
 */
export default function getPossibleSourceItemNamePaths({ language = 'de' }) {
  return [
    `data.title.${language}`,
    `data.name.${language}`,
    `data.description.${language}`,
    `data.text.${language}`,
    `data.orderOrigin.text.${language}`,
    'data.metadata.filename'
  ];
}

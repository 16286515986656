import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Tab, Tabs, FormControl } from 'react-bootstrap';

class Title extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'de'
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(language, ev) {
    const { id, onChange } = this.props;

    const value = get(ev, 'target.value', '');

    onChange(id, language, value, ev);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { active } = this.state;
    const {
      id,
      languages,
      values,
      edit,
      type
    } = this.props;

    const tabs = languages.map(l => (
      <Tab id={l} key={l} eventKey={l} title={l}>
        {edit ? (
          <FormControl
            type={type}
            value={get(values, l, '')}
            onChange={(ev) => this.onChange(l, ev)}
          />
        ) : get(values, l, '')}
      </Tab>
    ));

    return(
      <div>
        <Tabs
          id={`language-aware-input-${id}`}
          activeKey={active}
          onSelect={this.setActive}
        >
          {tabs}
        </Tabs>
      </div>
    );
  }
}

Title.propTypes = {
  id: PropTypes.string.isRequired,
  language: PropTypes.string,
  languages: PropTypes.array,
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string
};

Title.defaultProps = {
  language: 'de',
  languages: ['de', 'fr', 'en'],
  edit: false,
  onChange: noop,
  type: 'text'
};

export default Title;

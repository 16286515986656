import { put } from 'redux-saga/effects';
import { v4 } from 'uuid';

import editNodeFormAction from '../../actions/Workflow/EditNodeForm';

const {
  WORKFLOW_TREE_EDIT_NODE_FORM_INIT_PENDING,
  WORKFLOW_TREE_EDIT_NODE_FORM_INIT_FULFILLED,
  WORKFLOW_TREE_EDIT_NODE_FORM_INIT_REJECTED
} = editNodeFormAction;

export default function* initEditNodeForm(request) {
  const { data } = request;

  yield put({ type: WORKFLOW_TREE_EDIT_NODE_FORM_INIT_PENDING });

  try {
    const defaults = {
      id: v4(),
      title: {},
      description: {},
      comment: {
        default: {}
      }
    };

    const payload = {
      ...defaults,
      ...data
    };

    yield put({ type: WORKFLOW_TREE_EDIT_NODE_FORM_INIT_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: WORKFLOW_TREE_EDIT_NODE_FORM_INIT_REJECTED, error });

    return error;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { Form, ControlLabel, FormControl } from 'react-bootstrap';
import Toggle from 'react-toggle';
import Slider from 'react-rangeslider';

export default function CashflowSettings(props) {
  const {
    id, item, language, editEventField, editValueField,
  } = props;
  return (
    <div>
      <Form>
        <ControlLabel>Eigenmittel Bezeichnung</ControlLabel>
        <FormControl
          placeholder="Bezeichnung"
          type="text"
          value={item.name[language]}
          name="title"
          onChange={editEventField.bind(this, 'name', id)}
        />
        <label style={{ display: 'flex', paddingTop: '10px' }}>
          <Toggle
            checked={item.income}
            onChange={editEventField.bind(this, 'income', id)}
          />
          <span style={{ paddingLeft: '10px' }}>Zählt als Einkommen</span>
        </label>
        <label style={{ display: 'flex', paddingTop: '10px' }}>
          <Toggle
            checked={item.reducing}
            onChange={editEventField.bind(this, 'reducing', id)}
          />
          <span style={{ paddingLeft: '10px' }}>Reduziert die Einnahmen/Ausgaben</span>
        </label>
        <label style={{ display: 'flex', paddingTop: '10px' }}>
          <Toggle
            checked={item.isAdditionalExpenseOrEarning}
            onChange={editEventField.bind(this, 'isAdditionalExpenseOrEarning', id)}
          />
          <span style={{ paddingLeft: '10px' }}>Zusammenfassung der Einnahmen/Ausgaben in der App und im Output</span>
        </label>
        <label style={{ display: 'flex', paddingTop: '10px' }}>
          <span style={{ paddingRight: '10px', paddingTop: '14px' }}>Anrechnung in Prozent</span>
          <div className="slider-horizontal" style={{ width: '200px', display: 'inline-block' }}>
            <Slider
              min={0}
              max={100}
              value={item.transferability * 100}
              onChange={editValueField.bind(this, 'transferability', id)}
              tooltip={false}
              step={10}
            />
          </div>
          <span className="value" style={{ marginLeft: '5px', paddingTop: '14px' }}>{item.transferability * 100}%</span>
        </label>
      </Form>
    </div>
  );
}

CashflowSettings.propTypes = {
  id: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  editEventField: PropTypes.func.isRequired,
  editValueField: PropTypes.func.isRequired,
};

import { createActions } from '../utils';

export const SET_LINKS = 'adminUI/externalLinks/SET_LINKS';
export const EXTERNAL_LINKS_LOAD = createActions("EXTERNAL_LINKS_LOAD");
export const EXTERNAL_LINKS_SAVE = createActions("EXTERNAL_LINKS_SAVE");

export function setExternalLinks(links) {
  return (dispatch) => {
    dispatch({
      type: SET_LINKS,
      data: links,
    });
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { FormGroup, ControlLabel, Col, FormControl } from 'react-bootstrap';
import Toggle from 'react-toggle';

class ToggleConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isJmesInput: get(props, 'item.props.defaults.de.0', '').startsWith('jmes:')
    };

    this.onDefaultValueChange = this.onDefaultValueChange.bind(this);
    this.toggleJmesInput = this.toggleJmesInput.bind(this);
    this.onJmesChange = this.onJmesChange.bind(this);
  }

  onDefaultValueChange(event) {
    const { id, editValueField, item } = this.props;

    let value = [];
    value.push(get(item, 'props.defaults.de[0]', "false")==="true"?"false":"true");
    // We have to save defaults for all languages.
    // imho this only makes sense in case of input / textarea. Take same value for all
    // languages. Adjust this if customer wants different configuration for each language
    value = {
      de: value,
      fr: value,
      en: value
    };
    editValueField(id, 'defaultValue', value);
  }

  onJmesChange(ev) {
    const { id, editValueField } = this.props;
    const value = get(ev, 'target.value');
    const path = `jmes:${value.replace('jmes:', '')}`;

    editValueField(id, 'defaultValue', { de: [path], fr: [path], en: [path] });
  }

  toggleJmesInput() {
    const { isJmesInput } = this.state;
    const { id, editValueField } = this.props;

    this.setState({ isJmesInput: !isJmesInput });

    editValueField(id, 'defaultValue', { de: [], fr: [], en: [] });
  }

  render() {
    const { isJmesInput } = this.state;
    const { item } = this.props;

    const defaults = get(item, 'props.defaults', {});
    return (
      <React.Fragment>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Standard Wert
          </Col>

          <Col sm={9} style={{ paddingTop: '10px' }}>
            <Toggle
              checked={get(defaults, 'de.0', 'false') === 'true' ? true : false}
              onChange={this.onDefaultValueChange}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col sm={3} />
          <Col sm={9}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <Toggle checked={isJmesInput} onChange={this.toggleJmesInput} />
              </div>
              <div style={{ paddingLeft: '10px' }}>Pfad manuell eingeben</div>
            </div>
          </Col>
        </FormGroup>

        {isJmesInput && (
          <React.Fragment>
            <Col sm={3} />
            <Col sm={9}>
              <FormControl
                value={get(defaults, `de[0]`, '')}
                type={item.props.type}
                onChange={this.onJmesChange}
              />
            </Col>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

ToggleConfiguration.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  item: PropTypes.object.isRequired,
  editValueField: PropTypes.func,
  language: PropTypes.string
};

ToggleConfiguration.defaultProps = {
  editValueField: noop,
  language: 'de'
};

export default ToggleConfiguration;

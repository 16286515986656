import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup } from 'react-bootstrap';
import Markdown from 'react-remarkable';

const MarkdownEditor = ({ content, updateFunction, title }) => {
  return (
    <div className="markdown-area">
      <p style={{ marginLeft: '-10px' }} ><a href="https://jonschlinkert.github.io/remarkable/demo/" target="_new">Hilfe zu Markdown</a></p>
      <FormGroup controlId="formControlsTextarea" className="markdown-input">
        <FormControl 
          componentClass="textarea"
          rows={28}
          placeholder={title}
          value={content}
          onChange={(e) => updateFunction(e.target.value)}
        />
      </FormGroup>
      <div className="markdown-preview">
        <Markdown options={{ html: true, xhtmlOut: true, linkify: true }}>
          {content}
        </Markdown>
      </div>
    </div>
 
  )
}

MarkdownEditor.propTypes = {
  content: PropTypes.string.isRequired,
  updateFunction: PropTypes.func.isRequired,
  title: PropTypes.string
}

Markdown.defaultPropTypes = {
  title: ""
}

export default MarkdownEditor;
import axios from 'axios';
import _ from 'lodash';

export const FLP_GET_PENDING = 'adminUI/financingLoanProducts/FLP_GET_PENDING';
export const FLP_GET_FULFILLED = 'adminUI/financingLoanProducts/FLP_GET_FULFILLED';
export const FLP_GET_REJECTED = 'adminUI/financingLoanProducts/FLP_GET_REJECTED';
export const CORETYPE_GET_PENDING = 'adminUI/financingLoanProducts/CORETYPE_GET_PENDING';
export const CORETYPE_GET_FULFILLED = 'adminUI/financingLoanProducts/CORETYPE_GET_FULFILLED';
export const CORETYPE_GET_REJECTED = 'adminUI/financingLoanProducts/CORETYPE_GET_REJECTED';
export const DURATION_GET_PENDING = 'adminUI/financingLoanProducts/DURATION_GET_PENDING';
export const DURATION_GET_FULFILLED = 'adminUI/financingLoanProducts/DURATION_GET_FULFILLED';
export const DURATION_GET_REJECTED = 'adminUI/financingLoanProducts/DURATION_GET_REJECTED';
export const LIBOR_GET_PENDING = 'adminUI/financingLoanProducts/LIBOR_GET_PENDING';
export const LIBOR_GET_FULFILLED = 'adminUI/financingLoanProducts/LIBOR_GET_FULFILLED';
export const LIBOR_GET_REJECTED = 'adminUI/financingLoanProducts/LIBOR_GET_REJECTED';


export function getLoanProducts() {
  return (dispatch) => {
    dispatch({
      type: FLP_GET_PENDING,
    });
    return axios({
      url: '/financing/loanproduct/',
    })
      .then((response) => {
        dispatch({
          type: FLP_GET_FULFILLED,
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FLP_GET_REJECTED,
          data: error,
        });
      });
  };
}

export function getCoreTypes(productId) {
  return (dispatch) => {
    dispatch({
      type: CORETYPE_GET_PENDING,
    });
    return axios({
      url: `/financing/loanproduct/${_.replace(productId, /-/g, '%2D')}`,
    })
      .then((response) => {
        response.data.coreType.forEach((element) => {
          const parser = document.createElement('a'); // eslint-disable-line no-undef
          parser.href = element.$ref;
          axios({
            url: parser.pathname,
          })
            .then((coreTypeResponse) => {
              dispatch({
                type: CORETYPE_GET_FULFILLED,
                data: coreTypeResponse.data,
              });
            })
            .catch((error) => {
              dispatch({
                type: CORETYPE_GET_REJECTED,
                data: error,
              });
            });
        });
      })
      .catch((error) => {
        dispatch({
          type: CORETYPE_GET_REJECTED,
          data: error,
        });
      });
  };
}

function getRef(ref) {
  const parser = document.createElement('a'); // eslint-disable-line no-undef
  parser.href = ref;
  return axios({
    url: parser.pathname,
  });
}

function getForwards(durationId) {
  return axios({
    url: `/financing/forward/?limit(200)&eq(duration.ref.$id,${_.replace(durationId, /-/g, '%2D')})`,
  });
}

function getRate(durationId) {
  return axios({
    url: `/financing/rate/?eq(duration.ref.$id,${_.replace(durationId, /-/g, '%2D')})`,
  });
}

export function getLoanProductDetails(productId) {
  return (dispatch) => {
    dispatch({
      type: DURATION_GET_PENDING,
    });
    return axios({
      url: `/financing/loanproduct/${_.replace(productId, /-/g, '%2D')}`,
    })
      .then((response) => {
        response.data.availableDurations.forEach((element) => {
        // get duration
          axios.all([getRef(element.$ref)])
            .then(axios.spread((durationResponse) => {
            // get refiRate, rate and forwards for every duration
              const { id, refiRate } = durationResponse.data;
              axios.all([getRef(refiRate.$ref), getForwards(id), getRate(id)])
                .then(axios.spread((refiRateResponse, forwardsResponse, rateResponse) => {
                  dispatch({
                    type: DURATION_GET_FULFILLED,
                    duration: durationResponse.data,
                    refiRate: refiRateResponse.data,
                    forwards: forwardsResponse.data,
                    rate: rateResponse.data[0],
                  });
                }));
            }))
            .catch((error) => {
              dispatch({
                type: DURATION_GET_REJECTED,
                data: error,
              });
            });
        });

        // get libor rate if libor exists (only for rollover products)
        if (typeof (response.data.liborRate) !== 'undefined') {
          const parser = document.createElement('a'); // eslint-disable-line no-undef
          parser.href = response.data.liborRate.$ref;
          axios({
            url: parser.pathname,
          })
            .then((libor) => {
              dispatch({
                type: LIBOR_GET_FULFILLED,
                libor: libor.data,
              });
            });
        }
      })
    // duration error block
      .catch((error) => {
        dispatch({
          type: DURATION_GET_REJECTED,
          data: error,
        });
      });
  };
}

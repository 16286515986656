import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';
import { default as Select } from 'react-select';
import Toggle from 'react-toggle';

import OptionList from '../Select/OptionList';

class SingleSelectConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.onDefaultValueChange = this.onDefaultValueChange.bind(this);
  }

  onDefaultValueChange(selected) {
    const { id, editValueField } = this.props;
    let value = (selected) ? [selected.value] : null;

    // We have to save defaults for all languages.
    // imho this only makes sense in case of input / textarea. Take same value for all
    // languages. Adjust this if customer wants different configuration for each language
    if (!_.isNull(value)) value = {
      de: value,
      fr: value,
      en: value
    };

    editValueField(id, 'defaultValue', value);
  }

  render() {
    const { id, item, editValueField, language } = this.props;
    const defaults = _.get(item, `props.defaults.${language}`, []);

    return (
      <React.Fragment>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Als Mail Subject verwenden
          </Col>
          <Col sm={9}>
            <Toggle
              checked={_.get(item, 'props.useAsMailSubject', false)}
              onChange={(e) => editValueField(id, 'useAsMailSubject', e.target.checked)}
            />
          </Col>
        </FormGroup>
        <FormGroup controlId="simpleSelectOptions">
          <Col componentClass={ControlLabel} sm={3}>
            Werte im Dropdown
          </Col>
          <Col sm={9}>
            <OptionList
              options={_.get(item, 'props.options', [])}
              onChange={(options) => editValueField(id, 'componentOptions', options)}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Standard Wert
          </Col>

          <Col sm={9}>
            <Select
              value={defaults[0]}
              onChange={this.onDefaultValueChange}
              options={item.props.options.map(o => ({ value: o.value, label: _.get(o, `label.${language}`) }))}
            />
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

SingleSelectConfiguration.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  item: PropTypes.object.isRequired,
  editValueField: PropTypes.func,
  language: PropTypes.string
};

SingleSelectConfiguration.defaultProps = {
  editValueField: _.noop,
  language: 'de'
};

export default SingleSelectConfiguration;

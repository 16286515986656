import axios from 'axios';
import { all, call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { EXTERNAL_LINKS_LOAD } from '../../actions/ExternalLinkActions';
import { sortByAll } from '../../utils';

export function* externalLinksLoadSagas() {
  try {
    yield put(EXTERNAL_LINKS_LOAD.pending());

    const [
      { data: links },
      { data: categories },
    ] = yield all([
      call(axios, { url: `${EndpointName.CORE_EXTERNAL_LINK}/?limit(999999)` }),
      call(axios, { url: `${EndpointName.ENTITY_CODE}/?eq(group,linkCategory)&limit(999999)` }),
    ]);

    const externalLinks = links
      .map((link) => link.translatedUrl
        ? link
        : {
          ...link,
          translatedUrl: {
            de: link.url,
            en: link.url,
            fr: link.url,
          },
        })
      .sort(sortByAll((it) => it.order));

    yield put(EXTERNAL_LINKS_LOAD.success({ externalLinks, categories }));
  } catch (error) {
    yield put(EXTERNAL_LINKS_LOAD.failure(error));
  }
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

import './ExecutedJobs.css';
import ExecutedJobsFilterForm from './ExecutedJobsFilterForm';
import ExecutedJobsResultsTable from './ExecutedJobsResultsTable';
import { JOB_PROCESSING_PAGE_SIZE } from '../../constants/JobProcessingConstants';

const ExecutedJobs = ({
  definitions,
  executed,
  executionHistory,
  intl,
  jobProcessingActions,
  language,
  summary
}) => {
  const [searchParams, setSearchParams] = useState({});
  const [sort, setSort] = useState({});
  const [clearForm, setClearForm] = useState(false);

  useEffect(() => {
    jobProcessingActions.summaryRequest();
    jobProcessingActions.executedRequest({
      filters: { timespan: { startDate: moment().subtract(24, 'hours') } },
      limit: JOB_PROCESSING_PAGE_SIZE,
      offset: 0
    });
  }, []);

  useEffect(() => {
      if (!searchParams || isEmpty(searchParams)) return;

      jobProcessingActions.executedRequest({
        filters: searchParams,
        limit: JOB_PROCESSING_PAGE_SIZE,
        offset: 0,
        data: []
      });
  }, [searchParams]);

  const onFilter = (params, operation) => {
      setSort({});

      if (operation === 'clear') {
          setClearForm(true);
          return setSearchParams({ ...params });
      }
      if (operation === 'resetParams') {
          return setSearchParams({ ...params });
      }

      return setSearchParams({ ...searchParams, ...params });
  }

  const onPageChange = ({ limit, offset }) => {
    jobProcessingActions.executedRequest({
      filters: searchParams,
      limit,
      offset,
      data: get(executed, 'data', []),
      sort
    });
  }

  const onSort = (sort = {}) => {
    setSort(sort);

    jobProcessingActions.executedRequest({
      filters: searchParams,
      limit: JOB_PROCESSING_PAGE_SIZE,
      offset: 0,
      data: [],
      sort
    });
  }

  return (
    <div>
      <h2><FormattedMessage id="jobProcessing_overview" />:</h2>
      <div className="job-processing-overview__container">
        <Button
          className="job-processing-overview__btn green"
          onClick={() => onFilter({ success: true, timespan: { startDate: moment().subtract(24, 'hours') } }, 'clear')}
        >
          <FormattedMessage id="jobProcessing_successful" />
          <span>: </span>
          <b>
            {get(summary, 'data', []).filter(item => item.success === true).length}
          </b>
        </Button>

        <Button
          className="job-processing-overview__btn red"
          onClick={() => onFilter({ success: false, timespan: { startDate: moment().subtract(24, 'hours')  } }, 'clear')}
        >
          <FormattedMessage id="jobProcessing_failed" />
          <span>: </span>
          <b>
            {get(summary, 'data', []).filter(item => item.success === false).length}
          </b>
        </Button>
      </div>

      <ExecutedJobsFilterForm
        clearForm={clearForm}
        definitions={definitions}
        form={'executedJobsFilterForm'}
        intl={intl}
        language={language}
        loading={get(executed, 'requesting', false)}
        onFilter={onFilter}
        setClearForm={setClearForm}
      />

      <ExecutedJobsResultsTable
        executed={executed}
        executionHistory={executionHistory}
        jobProcessingActions={jobProcessingActions}
        language={language}
        onFilter={onFilter}
        onPageChange={onPageChange}
        onSort={onSort}
        sort={sort}
      />
    </div>
  );
};

ExecutedJobs.propTypes = {
  definitions: PropTypes.object,
  executed: PropTypes.object,
  executionHistory: PropTypes.object,
  intl: PropTypes.object.isRequired,
  jobProcessingActions: PropTypes.object.isRequired,
  language: PropTypes.string,
  summary: PropTypes.object
};

ExecutedJobs.defaultProps = {
  definitions: {},
  executed: {},
  executionHistory: {},
  summary: {}
}

export default injectIntl(ExecutedJobs);
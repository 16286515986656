import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isNil } from 'lodash';

import DateValue from '../../Configuration/DateField/DateValue/DateValue';

class DateValidationConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Handle value change
   *
   * @param   {String}  value  Value from DateValue component
   *
   * @return  void
   */
  onChange(key, value) {
    const { onChange } = this.props;

    return isNil(value)
      ? onChange()
      : onChange([value]);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      component,
      validation
    } = this.props;

    return (
      <DateValue
        id="validation"
        onChange={this.onChange}
        value={get(component, `props.validations.${validation.key}.${validation.key}.0`)}
      />
    );
  }
}

DateValidationConfiguration.propTypes = {
  component: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  validation: PropTypes.object.isRequired
};

DateValidationConfiguration.defaultProps = {
  onChange: noop
};

export default DateValidationConfiguration;

import './ConfigPanelItem.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import Select from 'react-select';
import { classList } from '../../utils/classList';

export class ConfigPanelItem extends React.Component {
  state = {
    value: this.props.item.value,
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.save = this.save.bind(this);
    this.reset = this.reset.bind(this);
  }

  render() {
    const { item } = this.props;
    const { value } = this.state;

    const actionClass = classList({
      "config-panel-item__action": true,
      "config-panel-item__action--disabled": value === item.default,
    });
    return (
      <div className="config-panel-item">
        <div className="config-panel-item__title">{item.label}</div>
        <div className="config-panel-item__value">
          {this.renderEditControl()}
        </div>
        <div className={actionClass}>
          <span className="icon-067-reply-message" onClick={this.reset}/>
        </div>
      </div>
    );
  }

  renderEditControl() {
    const { item } = this.props;
    const { value } = this.state;
    const isBool = item.default === "true" || item.default === "false";
    if (!isBool) {
      return (
        <FormControl type="text" value={value} onChange={this.onChange} onBlur={this.save}/>
      );
    }

    const options = [
      { label: "true", value: "true" },
      { label: "false", value: "false" },
    ];
    return (
      <Select value={value} options={options} onChange={this.onSelect} clearable={false}/>
    );
  }

  onChange(event) {
    this.setState({ value: event.target.value });
  }

  onSelect(option) {
    this.setState({ value: option ? option.value : null }, this.save);
  }

  reset() {
    const { item } = this.props;
    this.setState({ value: item.default }, this.save);
  }

  save() {
    const { item, save } = this.props;
    const { value } = this.state;
    if (!value) {
      this.reset();
      return;
    }
    if (value === item.value) {
      return;
    }
    save(item.id, value);
  }
}

ConfigPanelItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  save: PropTypes.func.isRequired,
};

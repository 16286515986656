import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';

// ToDo: Move functions like handleNextHeaders to own repo
import replayActions from '../../actions/ReplayAuditLog';
import replayRecord from './replayRecord';
import {
  INCREMENT_COUNT,
  INIT_PROGRESS,
  SET_ERRORNEOUS,
  SET_FULFILLED
} from '../../actions/ReplayAuditLog/Progess';

const {
  REPLAY_PENDING,
  REPLAY_FULFILLED,
  REPLAY_REJECTED,
  REPLAY_RECORD_REQUEST
} = replayActions;

export default function* replay(request) {
  yield put({ type: REPLAY_PENDING });

  try {
    const { connection, records } = request;

    yield put({ type: INIT_PROGRESS, dataKey: 'replay', payload: { total: get(records, 'length', 0) } });

    for (let record of records) {
      const dataKey = get(record, 'id');

      yield put({ type: REPLAY_RECORD_REQUEST, dataKey })
      yield call(replayRecord, { connection, dataKey, record });
      yield put({ type: INCREMENT_COUNT, dataKey: 'replay' });
    }

    const payload = [];

    yield put({ type: REPLAY_FULFILLED, payload });
    yield put({ type: SET_FULFILLED, dataKey: 'replay' });

    return payload;
  } catch (error) {
    yield put({ type: REPLAY_REJECTED, error });
    yield put({ type: SET_ERRORNEOUS, dataKey: 'replay', error });

    return error;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import Highlight from 'react-highlight'
import { ListGroupItem } from 'react-bootstrap';

import './listItem.css';
import Form from './Form';

class DynamicPropsListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false
    };

    this.toggleEdit = this.toggleEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(data) {
    const { onSubmit } = this.props;

    this.toggleEdit();

    onSubmit(data);
  }

  toggleEdit() {
    const { edit } = this.state;

    this.setState({ edit: !edit });
  }

  renderForm() {
    const { data } = this.props;

    return (
      <ListGroupItem>
        <Form
          data={data}
          onCancel={this.toggleEdit}
          onSubmit={this.onSubmit}
        />
      </ListGroupItem>
    );
  }

  renderValues() {
    const {
      data,
      onRemove
    } = this.props;

    return (
      <ListGroupItem className="workguide-dynamic-props-list-item">
        <div style={{ width: '30%' }}>
          {get(data, 'path', '')}
        </div>

        <div style={{ display: 'flex', width: '70%' }}>
          <div style={{ width: '95%' }}>
            <Highlight className="js">
              {get(data, 'jmes')}
            </Highlight>
          </div>

          <div style={{ width: '5%', textAlign: 'right' }}>
            <span>
              <i className="glyphicon glyphicon-pencil" onClick={this.toggleEdit}/>
            </span>
            <span onClick={onRemove}>
              <i className="glyphicon glyphicon-remove" />
            </span>
          </div>
        </div>
      </ListGroupItem>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { edit } = this.state;

    return edit
      ? this.renderForm()
      : this.renderValues();
  }
}

DynamicPropsListItem.propTypes = {
  data: PropTypes.object,
  onRemove: PropTypes.func
};

DynamicPropsListItem.defaultProps = {
  data: {},
  onRemove: noop
};

export default DynamicPropsListItem;

import { createSelector } from 'reselect';

export const getUiNotesState = (state) => state.uiNotes;

export const getUiNotesItems = createSelector([
  getUiNotesState,
], (state) => state.data ? state.data.notes : null);

export const getUiNotesTypes = createSelector([
  getUiNotesState,
], (state) => state.data ? state.data.types : null);

export const getUiNotesImage = createSelector([
  getUiNotesState,
], (state) => state.image);

export const getUiNotesLoading = createSelector([
  getUiNotesState,
], (state) => state.loading);

export const getUiNotesSaving = createSelector([
  getUiNotesState,
], (state) => state.saving);

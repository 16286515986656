import { takeLatestByDataKey } from '@evoja-web/redux-saga-utils';
import { takeLatest } from 'redux-saga/effects';

import actions from '../actions/Actions';
import getCategories from './getCategories';
import getModule from './getModule';
import getModules from './getModules';
import onToggleFavourite from './onToggleFavourite';

const {
  CATEGORIES_REQUEST,
  MODULE_REQUEST,
  MODULES_REQUEST,
  TOGGLE_FAVOURITE_REQUEST
} = actions;

export default function* moduleRootSaga() {
  yield takeLatest(CATEGORIES_REQUEST, getCategories);
  yield takeLatest(MODULES_REQUEST, getModules);
  yield takeLatestByDataKey(MODULE_REQUEST, getModule);
  yield takeLatestByDataKey(TOGGLE_FAVOURITE_REQUEST, onToggleFavourite);
}

import React from 'react';
import PropTypes from 'prop-types';
import { has, get, upperFirst, noop, flow, size, last } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';
import update from 'immutability-helper';
import { Dropdown, MenuItem } from 'react-bootstrap';

import './List.css';

class WorkflowTreeConditionList extends React.Component {
  constructor(props) {
    super(props);

    this.onConditionAdd = this.onConditionAdd.bind(this);
    this.onConditionRemove = this.onConditionRemove.bind(this);
    this.onConditionChange = this.onConditionChange.bind(this);
  }

  /**
   * Add a new condition with the given operation
   *
   * @param   {String}  operation  Operation
   *
   * @return  void
   */
  onConditionAdd({ operation }) {
    const {
      condition,
      onChange
    } = this.props;

    const c = {
      id: `cnd${last(v4().split('-'))}`,
      operation
    };

    // Make sure necessary values are set on condition before adding the condition
    const updated = flow([
      (data) => {
        return has(data, 'operation')
          ? data
          : update(data, { operation: { $set: get(this, 'props.operation') } });
      },
      (data) => {
        return has(data, 'conditions')
          ? data
          : update(data, { conditions: { $set: [] } });
      },
      (data) => {
        const id = `cnd${last(v4().split('-'))}`;

        return has(data, 'id')
          ? data
          : update(data, { id: { $set: id } });
      },
      (data) => {
        return update(data, {
          conditions: { $push: [c] }
        });
      }
    ])(condition);

    onChange({ condition: updated });
  }

  /**
   * Remove the given condition
   *
   * @return  void
   */
  onConditionRemove({ condition: removed }) {
    const {
      condition,
      onChange
    } = this.props;

    const index = get(condition, 'conditions', []).findIndex((c) => c.id === removed.id);
    if (index > -1) {
      const updated = update(condition, {
        conditions: { $splice: [[index, 1]] }
      });

      const value = size(updated.conditions) > 0
        ? updated
        : undefined;

      onChange({ condition: value });
    }
  }

  /**
   * Handle condition change
   *
   * @param   {Object}  condition  Updated condition
   *
   * @return  void
   */
  onConditionChange({ condition: changed }) {
    const {
      condition,
      onChange
    } = this.props;

    const index = get(condition, 'conditions', []).findIndex((c) => c.id === changed.id);
    if (index > -1) {
      const updated = update(condition, {
        conditions: { $splice: [[index, 1, changed]] }
      });

      onChange({ condition: updated });
    }
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      components,
      condition,
      isRemovable,
      operation,
      onRemove,
      showLabel,
      validations
    } = this.props;

    const children = get(condition, 'conditions', [])
      .map((c, index) => {
        const Component = get(components, c.operation, components.generic);

        return (
          <Component
            key={c.id}
            components={components}
            condition={c}
            operation={c.operation}
            onChange={this.onConditionChange}
            onRemove={this.onConditionRemove}
            validations={get(validations, `conditions.${index}`)}
          />
        );
      });

    return (
      <div className="workflow-tree-form-condition--condition-list">
        <div className="workflow-tree-form-condition--condition-list--header">
          <div>
            {showLabel && (
              <FormattedMessage id={`Workguide.Workflow.Form.Condition.Title.${upperFirst(operation)}`} />
            )}
          </div>

          <div className="d-flex align-items-center">
            {isRemovable && (
              <div
                className="mdi mdi-trash-can-outline pointer"
                onClick={() => onRemove({ condition })}
              />
            )}

            <Dropdown
              id={`condition-list-select-operation--${condition.id}`}
              pullRight
            >
              <Dropdown.Toggle
                style={{ backgroundColor: '#ffffff', border: '0px', boxShadow: 'unset' }}
                noCaret
              >
                <div className="mdi mdi-plus-box-outline" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <MenuItem onClick={() => this.onConditionAdd({ operation: 'and' })}>
                  <FormattedMessage id="Workguide.Workflow.Form.Condition.Title.And" />
                </MenuItem>

                <MenuItem onClick={() => this.onConditionAdd({ operation: 'or' })}>
                  <FormattedMessage id="Workguide.Workflow.Form.Condition.Title.Or" />
                </MenuItem>

                <MenuItem onClick={() => this.onConditionAdd({ operation: 'eq' })}>
                  <FormattedMessage id="Workguide.Workflow.Form.Condition.Title.Eq" />
                </MenuItem>

                <MenuItem onClick={() => this.onConditionAdd({ operation: 'contains' })}>
                  <FormattedMessage id="Workguide.Workflow.Form.Condition.Title.Contains" />
                </MenuItem>

                <MenuItem onClick={() => this.onConditionAdd({ operation: 'gt' })}>
                  <FormattedMessage id="Workguide.Workflow.Form.Condition.Title.Gt" />
                </MenuItem>

                <MenuItem onClick={() => this.onConditionAdd({ operation: 'lt' })}>
                  <FormattedMessage id="Workguide.Workflow.Form.Condition.Title.Lt" />
                </MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="workflow-tree-form-condition--condition-list--conditions">
          {children}
        </div>
      </div>
    );
  }
}

WorkflowTreeConditionList.propTypes = {
  components: PropTypes.object,
  condition: PropTypes.object,
  isRemovable: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  operation: PropTypes.string,
  showLabel: PropTypes.bool,
  validations: PropTypes.object
};

WorkflowTreeConditionList.defaultProps = {
  components: {},
  condition: {},
  isRemovable: true,
  onChange: noop,
  onRemove: noop,
  operation: undefined,
  showLabel: true,
  validations: {}
};

export default WorkflowTreeConditionList;

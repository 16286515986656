import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from 'react-select';

const CriteriaMultiSelect = ({ source, criteria, language, onChange, edit }) => {
  function onValueChange(selected) {
    onChange({
      source: _.get(source, 'props.id'),
      path: `${_.get(source, 'props.id')}[*].value`,
      values: selected.map(s => s.value)
    });
  }

  const options = _.get(source, 'props.options', []).map(o => ({ value: o.value, label: _.get(o, `label.${language}`) }));

  return (edit)
    ? (
      <React.Fragment>
        <strong>Wert</strong>

        <Select
          options={_.get(source, 'props.options', []).map(o => ({ value: o.value, label: _.get(o, `label.${language}`) }))}
          onChange={onValueChange}
          value={_.get(criteria, 'values', [])}
          multi={true}
        />
      </React.Fragment>
    )
    : (
      <React.Fragment>
        {_.get(criteria, 'values', []).map(v => _.get(options.find(o => o.value === v), 'label')).join(',')}
      </React.Fragment>
    );
}

CriteriaMultiSelect.propTypes = {
  source: PropTypes.object.isRequired,
  criteria: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func,
  edit: PropTypes.bool,
  operation: PropTypes.string
};

CriteriaMultiSelect.defaultProps = {
  criteria: {},
  language: 'de',
  onChange: _.noop,
  edit: false,
  operation: 'eq'
};

export default CriteriaMultiSelect;

import { call } from 'redux-saga/effects';
import axios from 'axios';

export default function* connect(request) {
  const {
    uri,
    username,
    password
  } = request;

  const instance = axios.create({
    baseURL: uri
  });

  const { data } = yield call(instance, {
    url: '/auth',
    method: 'post',
    data: {
      username,
      password
    }
  });

  instance.defaults.headers.common['X-REST-Token'] = data.token;

  return {
    instance,
    username,
    password
  };
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import { withAcl } from '@evoja-web/client-acl';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.onLogout = this.onLogout.bind(this);
  }

  /**
   * Logout the user
   *
   * @return void
   */
  onLogout() {
    const {
      history,
      location
    } = this.props;

    history.push(`/logout?redir=${location.pathname}`);
  }

  render() {
    const {
      acl,
      setLanguage,
      history
    } = this.props;

    return (
      <Nav pullRight bsStyle="pills" id="transferConfig" >
        {(acl.isAllowed('Modules.ShowAll') || acl.isAllowed('Module.ParamTransfer.Show')) && (
          <NavItem onClick={() => history.push('/paramTransfer')}>
            <i className="glyphicon glyphicon-transfer" />
            {' '}
            <FormattedMessage id="ParamTransfer.Title" />
          </NavItem>
        )}
        <NavDropdown title={<FormattedMessage id="header_language"/>} id="languageSelector">
          <MenuItem onSelect={setLanguage} eventKey="de"><FormattedMessage id="header_language_de"/></MenuItem>
          <MenuItem onSelect={setLanguage} eventKey="fr"><FormattedMessage id="header_language_fr"/></MenuItem>
          <MenuItem onSelect={setLanguage} eventKey="en"><FormattedMessage id="header_language_en"/></MenuItem>
        </NavDropdown>
        <NavItem href="#" id="signout" onClick={this.onLogout}>
          <i className="glyphicon glyphicon-off" />
          {' '}
          <FormattedMessage id="header_logout" />
        </NavItem>
      </Nav>
    );
  }
}

Navigation.propTypes = {
  acl: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  setLanguage: PropTypes.func.isRequired
};

Navigation.defaultProps = {

};

export default withAcl()(
  withRouter(Navigation)
);

import './Button.css';

import React from 'react';
import { Button as ReactButton } from 'react-bootstrap';
import { classList } from '../../utils/classList';

export function Button({ className, ...props }) {
  return (
    <ReactButton {...props} className={classList("button-component", className)}/>
  );
}

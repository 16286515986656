import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';

import actions from '../../actions/LeadHistory/Actions';

const {
  LEAD_HISTORY_PENDING,
  LEAD_HISTORY_FULFILLED,
  LEAD_HISTORY_REJECTED
} = actions;

export default function* getLeadHistory() {
  yield put({ type: LEAD_HISTORY_PENDING });

  try {
    const query = QueryBuilder()
      .limit(1000)
      .getQueryString();

    const url = `/work/leadmanagement/history/${query}`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: LEAD_HISTORY_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: LEAD_HISTORY_REJECTED, error });

    return error;
  }
}

import './HolidaysCalendarMonth.css';

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { TranslatedMessage } from '../Common/TranslatedMessage';
import { FormattedDate } from '../Common/FormattedDate';
import { isHoliday } from '../../utils/holidayUtils';

export class HolidaysCalendarMonth extends React.Component {
  constructor(props) {
    super(props);
    this.setMonth = this.setMonth.bind(this);
  }

  render() {
    const { title, year, month } = this.props;

    return (
      <div className="holidays-calendar-month" onClick={this.setMonth}>
        <div className="holidays-calendar-month__title">{title}</div>
        <div className="holidays-calendar-month__content">
          {Array(moment([year, month, 1]).daysInMonth())
            .fill(null)
            .map((_, index) => moment([year, month, index + 1]))
            .map((date) => this.renderDay(date))}
        </div>
      </div>
    );
  }

  renderDay(date) {
    const { holidays } = this.props;
    return holidays
      .filter((holiday) => isHoliday(holiday, date))
      .map((holiday, index) => (
        <div className="holidays-calendar-month__day" key={index}>
          <span className="holidays-calendar-month__number">
            <FormattedDate value={date} format="DD"/>
          </span>
          <TranslatedMessage value={holiday.name}/>
        </div>
      ));
  }

  setMonth() {
    const { setMonth, month } = this.props;
    setMonth(month);
  }
}

HolidaysCalendarMonth.propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  holidays: PropTypes.array.isRequired,
  title: PropTypes.node.isRequired,
  setMonth: PropTypes.func.isRequired,
};

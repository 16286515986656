import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

/**
 * Legacy today component.
 * This component auto migrate itself to the new "flex-" structure on mount
 */
class DateFieldDefaultValueToday extends React.Component {
  constructor(props) {
    super(props);

    this.migrate();
  }

  /**
   * Migrate the current value to the new "flex-" structure
   *
   * @return  void
   */
  migrate() {
    const { onMigrate } = this.props;

    const updatedValue = 'flex-{current}--{current}--{current}';

    onMigrate({ updatedValue, updatedType: 'flex' });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    return null;
  }
}

DateFieldDefaultValueToday.propTypes = {
  onMigrate: PropTypes.func
};

DateFieldDefaultValueToday.defaultProps = {
  onMigrate: noop
};

export default DateFieldDefaultValueToday;

import './AnalyticsActiveUserSessions.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { PROP_TYPES_ACTIVE_SESSION } from './AnalyticsPropTypes';
import { TableCell, TableRow } from '../Common/Table';
import { FormattedGravitonDate } from '../FormattedGravitonDate';
import { convertDateToApp } from '../../utils/dateConverter';
import { SortableTable } from '../Common/SortableTable';

const SORTABLE_FIELDS = [
  {
    key: 'keyCreatedAt',
    label: <FormattedMessage id="analytics_activeUserSessions_keyCreatedAt"/>,
    sortFn: (item) => convertDateToApp(item.keyCreatedAt),
  },
  {
    key: 'keyRefreshedAt',
    label: <FormattedMessage id="analytics_activeUserSessions_keyRefreshedAt"/>,
    sortFn: (item) => convertDateToApp(item.keyRefreshedAt),
  },
];

export function AnalyticsActiveUserSessions({ sessions }) {
  return (
    <SortableTable
      items={sessions}
      sortableFields={SORTABLE_FIELDS}
      renderItem={renderItem}
      className="analytics-active-user-sessions"
    />
  );
}

AnalyticsActiveUserSessions.propTypes = {
  sessions: PropTypes.arrayOf(PROP_TYPES_ACTIVE_SESSION),
};

function renderItem(session) {
  return (
    <TableRow key={session.id}>
      <TableCell>
        <FormattedGravitonDate value={session.keyCreatedAt} format="DD.MM.YYYY HH:mm:ss"/>
      </TableCell>
      <TableCell>
        <FormattedGravitonDate value={session.keyRefreshedAt} format="DD.MM.YYYY HH:mm:ss"/>
      </TableCell>
    </TableRow>
  );
}

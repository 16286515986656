import './SetManagementPricingEdit.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Table, TableCell, TableRow } from '../Common/Table';
import { SelectControl } from '../Common/SelectControl';
import { Button } from '../Common/Button';
import { InputControl } from '../Common/InputControl';

export class SetManagementPricingEdit extends React.Component {
  constructor(props) {
    super(props);
    const { price } = props;

    this.state = {
      price: { ...price },
    };
  }

  render() {
    const { bundles, products, reductionTypes, onCancel } = this.props;
    const { price } = this.state;

    return (
      <div className="set-management-pricing-edit">
        <Table>
          <TableRow>
            <TableCell className="set-management-pricing-edit__label">
              <FormattedMessage id="setManagement_pricing_bundle"/>
            </TableCell>
            <TableCell className="set-management-pricing-edit__value">
              <SelectControl
                value={price.bundle}
                multi={false}
                clearable={false}
                options={bundles}
                onChange={this.onChangeBundle}
                className={price.bundle ? '' : 'set-management-pricing-edit__invalid'}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="set-management-pricing-edit__label">
              <FormattedMessage id="setManagement_pricing_product"/>
            </TableCell>
            <TableCell className="set-management-pricing-edit__value">
              <SelectControl
                value={price.product}
                multi={false}
                clearable={false}
                options={products}
                onChange={this.onChangeProduct}
                className={price.product ? '' : 'set-management-pricing-edit__invalid'}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="set-management-pricing-edit__label">
              <FormattedMessage id="setManagement_pricing_reductionType"/>
            </TableCell>
            <TableCell className="set-management-pricing-edit__value">
              <SelectControl
                value={price.reductionType}
                multi={false}
                clearable={false}
                options={reductionTypes}
                onChange={this.onChangeReductionType}
                className={price.reductionType ? '' : 'set-management-pricing-edit__invalid'}
              />
            </TableCell>
          </TableRow>

          {this.shouldDisplayReductionValue() && (
            <TableRow>
              <TableCell className="set-management-pricing-edit__label">
                <FormattedMessage id="setManagement_pricing_reductionValue"/>
              </TableCell>
              <TableCell className="set-management-pricing-edit__value">
                <InputControl
                  type="number"
                  value={price.reductionValue || ''}
                  onChange={this.onChangeReductionValue}
                  className={Number.isFinite(price.reductionValue) ? '' : 'set-management-pricing-edit__invalid'}
                />
              </TableCell>
            </TableRow>
          )}
        </Table>

        <div className="set-management-pricing-edit__buttons">
          <Button
            onClick={this.save}
            bsSize="small"
            bsStyle="primary"
            className="set-management-pricing-edit__save"
            disabled={!this.isValid()}
          >
            <FormattedMessage id="common_save"/>
          </Button>
          <Button
            onClick={onCancel}
            bsSize="small"
          >
            <FormattedMessage id="common_close" />
          </Button>
        </div>
      </div>
    );
  }

  shouldDisplayReductionValue() {
    const { price } = this.state;
    return [
      'setAdvisorReductionType-1',
      'setAdvisorReductionType-4',
    ].indexOf(price.reductionType) === -1;
  }

  isValid() {
    const { price } = this.state;
    return price.bundle &&
      price.product &&
      price.reductionType &&
      (this.shouldDisplayReductionValue() ? Number.isFinite(price.reductionValue) : true);
  }

  onChangeBundle = (option) => this.setState(({ price }) => ({
    price: {
      ...price,
      bundle: option ? option.value : undefined,
    }
  }));

  onChangeProduct = (option) => this.setState(({ price }) => ({
    price: {
      ...price,
      product: option ? option.value : undefined,
    }
  }));

  onChangeReductionType = (option) => this.setState(({ price }) => {
    const newReductionType = option ? option.value : undefined;
    return {
      price: {
        ...price,
        reductionType: newReductionType,
        reductionValue: newReductionType === price.reductionType
          ? price.reductionValue
          : undefined,
      }
    };
  });

  onChangeReductionValue = ({ target: { value } }) => this.setState(({ price }) => ({
    price: {
      ...price,
      reductionValue: value
        ? price.reductionType === 'setAdvisorReductionType-2'
          ? Math.abs(parseInt(value, 10))
          : Math.abs(parseFloat(value))
        : undefined,
    },
  }));

  save = () => this.props.onSave(this.state.price);
}

SetManagementPricingEdit.propTypes = {
  price: PropTypes.object.isRequired,
  bundles: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  reductionTypes: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

import './AnalyticsActiveUser.css';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { PROP_TYPES_ACTIVE_USER } from './AnalyticsPropTypes';
import { TableCell, TableRow } from '../Common/Table';
import { Button } from '../Common/Button';
import { ModalBody, ModalDialog, ModalFooter, ModalHeader } from '../Common/Modal';
import { AnalyticsActiveUserSessions } from './AnalyticsActiveUserSessions';

export class AnalyticsActiveUser extends React.Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      isModalOpen: false,
    };
  }

  render() {
    const { user } = this.props;
    return (
      <TableRow className="analytics-active-user">
        <TableCell>
          {user.username}
        </TableCell>
        <TableCell>
          {user.name}
        </TableCell>
        <TableCell>
          {user.department}
        </TableCell>
        <TableCell>
          {user.division}
        </TableCell>
        <TableCell>
          {user.sessions.length}
        </TableCell>
        <TableCell>
          <span className="analytics-active-user__icon icon-046-information-circle" onClick={this.openModal}/>
          {this.renderModal()}
        </TableCell>
      </TableRow>
    );
  }

  renderModal() {
    const { user, text='' } = this.props;
    const { isModalOpen } = this.state;
    return (
      <ModalDialog show={isModalOpen} onHide={this.closeModal}>
        <ModalHeader closeButton={true}>
          <div><FormattedMessage id="analytics_activeUser_title"/></div>
          <div>{user.username} ({user.name})</div>
        </ModalHeader>
        <ModalBody>
          <div>{text}</div>
          <div className="analytics-active-user__table">
            <AnalyticsActiveUserSessions sessions={user.sessions}/>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.closeModal} bsSize="sm">
            <FormattedMessage id="common_cancel"/>
          </Button>
        </ModalFooter>
      </ModalDialog>
    );
  }
  openModal() {
    this.setState({ isModalOpen: true });
  }
  closeModal() {
    this.setState({ isModalOpen: false });
  }
}

AnalyticsActiveUser.propTypes = {
  user: PROP_TYPES_ACTIVE_USER,
  text: PropTypes.string,
};

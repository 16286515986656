import './InputControl.css';

import React from 'react';
import { classList } from '../../utils/classList';
import { FormControl } from 'react-bootstrap';

export function InputControl({ className, ...restProps }) {
  return (
    <FormControl {...restProps} className={classList('input-component', className)}/>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd'
import cl from 'classnames';

const WorkflowTreeDraggable = React.memo(function WorkflowTreeDraggable({
  children,
  className,
  id,
  item,
  style,
  type
}) {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type,
    item: { ...item, type },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging()
      };
    }
  }));

  const classNames = cl(className, {
    'workflow-tree-draggable': true,
    'workflow-tree-draggable--dragging': isDragging
  });

  return (
    <div
      className={classNames}
      id={id}
      ref={dragRef}
      style={style}
    >
      {children}
    </div>
  );
});

WorkflowTreeDraggable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  id: PropTypes.string.isRequired,
  item: PropTypes.object,
  style: PropTypes.object,
  type: PropTypes.string.isRequired
};

WorkflowTreeDraggable.defaultProps = {
  children: [],
  className: undefined,
  item: {},
  style: {}
};

export default WorkflowTreeDraggable;

import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';

import transferItemAction from '../actions/TransferItem';
import defaultTransferItem from './defaultTransferItem';
import transferFile from './transferFile';
import transferWorkguide from './transferWorkguide';

const {
  TRANSFER_ITEM_PENDING,
  TRANSFER_ITEM_FULFILLED,
  TRANSFER_ITEM_REJECTED
} = transferItemAction;

const sagas = {
  '/work/workguide': transferWorkguide,
  '/file': transferFile
};

export default function* transferItem({
  dataKey,
  item,
  source,
  target
}) {
  yield put({ type: TRANSFER_ITEM_PENDING, dataKey });

  try {
    const { serviceUri } = item;
    const saga = get(sagas, serviceUri, defaultTransferItem);
    const payload = yield call(saga, { item, source, target });

    yield put({ type: TRANSFER_ITEM_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: TRANSFER_ITEM_REJECTED, dataKey, error });

    return error;
  }
}

import axios from 'axios';
import { all, call, put, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { BULK_LOADS_LOAD } from '../../actions/BulkLoadsActions';
import { BULK_LOADS_STATES, BULK_LOADS_TEXT_BLOCKS_MAP } from '../../constants/BulkLoadsConstants';
import { loadActionsByLoads } from './loadActionsByLoadSaga';
import { getBulkLoadsListId, getBulkLoadsSearchParams } from '../../selectors/BulkLoads/bulkLoadsCommonSelectors';
import { createSearchQuery } from './createSearchQuery';

export function* bulkLoadsLoadDataSaga() {
  try {
    yield put(BULK_LOADS_LOAD.pending());
    const textBlocksIds = Object.values(BULK_LOADS_TEXT_BLOCKS_MAP);
    const loadId = yield select(getBulkLoadsListId);
    const searchParams = yield select(getBulkLoadsSearchParams);
    const searchQuery = `${EndpointName.LOADER_LOAD}/?${createSearchQuery(searchParams)}`;
    const loadsRequest = searchQuery + (loadId ? `&eq(id,string:${encodeURIComponent(loadId)})` : '');
    const [{ data: textBlocks }, { data: loads, headers }, averageCompletionTime] = yield all([
      call(axios, { url: `${EndpointName.CORE_TEXT_BLOCK}/?in(id,(${textBlocksIds.join(',')}))` }),
      call(axios, { url: loadsRequest }),
      call(calculateAverageCompletionTime),
    ]);

    const actions = yield call(loadActionsByLoads, loads);
    yield put(
      BULK_LOADS_LOAD.success({
        textBlocks,
        results: {
          loads,
          actions,
        },
        averageCompletionTime,
      })
    );
  } catch (error) {
    yield put(BULK_LOADS_LOAD.failure(error));
  }
}

function* calculateAverageCompletionTime() {
  const { data: lastDoneLoads } = yield call(axios, {
    url: `${EndpointName.LOADER_LOAD}/?eq(state,${BULK_LOADS_STATES.DONE})&limit(20)`,
  });

  const totalSeconds = lastDoneLoads.reduce((result, it) => result + (it.metrics.secondsWorking > 0 ? it.metrics.secondsWorking : 0), 0);
  const totalActions = lastDoneLoads.reduce((result, it) => result + it.actionCount.total, 0);
  return totalActions > 0 && totalSeconds > 0 ? totalSeconds / totalActions : 0;
}

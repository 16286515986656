import { createActions } from '../utils';

export const SET = 'adminUI/coreConfig/SET';
export const SET_MODULE = 'adminUI/coreConfig/SET_MODULE';
export const CORE_CONFIG_SAVE = createActions("CORE_CONFIG_SAVE");
export const CORE_CONFIG_LOAD = createActions("CORE_CONFIG_LOAD");

export function setModule({ id, value }) {
  return dispatch =>
    dispatch({
      type: SET_MODULE,
      id,
      value,
    });
}

export function setData({ key, value }) {
  return dispatch =>
    dispatch({
      type: SET,
      key,
      value,
    });
}

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { noop } from 'lodash';
import cl from 'classnames';

import './LoadButton.css';
import Spinner from '../Spinner';

const LoadButton = React.memo(({
  children,
  className,
  disabled,
  error,
  fulfilled,
  onClick,
  requesting
}) => {
  const bsStyle = error
    ? 'danger'
    : fulfilled
      ? 'success'
      : 'primary';

  return (
    <Button
      bsStyle={bsStyle}
      className={cl('replay-audit-log-load-button', className)}
      disabled={disabled}
      onClick={onClick}
    >
      {requesting && (
        <div>
          <Spinner text="" screen="sm" />
        </div>
      )}

      <div>
        {children}
      </div>
    </Button>
  );
});

LoadButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  fulfilled: PropTypes.bool,
  onClick: PropTypes.func,
  requesting: PropTypes.bool
};

LoadButton.defaultProps = {
  children: [],
  className: undefined,
  disabled: false,
  error: false,
  fulfilled: false,
  onClick: noop,
  requesting: false
};

export default LoadButton;

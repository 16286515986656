import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, slice } from 'lodash';

import './RefErrorsResults.css';
import { SortableTable } from '../Common/SortableTable';
import { RefErrorsResultsItem } from './RefErrorsResultsItem';
import { REF_ERRORS_PROP_TYPES_RESULT_ITEM, REF_ERRORS_PROP_TYPES_SEARCH_PARAMS } from './RefErrorsPropTypes';
import { REF_ERRORS_PAGE_SIZE } from '../../constants/RefErrorsConstants';
import { RefErrorsExpandContent } from './RefErrorsExpandContent';
import Pagination from '../Pagination/PaginationNoTotalCount';

const SORTABLE_FIELDS = [
  {
    key: 'id',
    label: <FormattedMessage id="refErrors_results_id"/>,
    sortFn: () => undefined,
  },
  {
    key: 'origin',
    label: <FormattedMessage id="refErrors_results_origin"/>,
    sortFn: () => undefined,
  },
  {
    key: 'loadedAt',
    label: <FormattedMessage id="refErrors_results_date"/>,
    sortFn: () => undefined,
  },
  {
    key: 'target',
    label: <FormattedMessage id="refErrors_results_targets"/>,
    sortFn: () => undefined,
  },
];

export class RefErrorsResults extends React.PureComponent {
  onChangePage = ({ limit, offset }) => {
    const {
      search,
      searchParams,
      resultsRaw
    } = this.props;

    search({
      ...searchParams,
      limit,
      offset,
      resultsRaw
    });
  };

  sort = (field, direction) => {
    const { search, searchParams } = this.props;
    search({
      ...searchParams,
      sortField: field,
      sortDirection: direction,
      limit: REF_ERRORS_PAGE_SIZE,
      offset: 0,
      resultsRaw: {}
    });
  };

  renderItem = (item) => <RefErrorsResultsItem key={item.id} item={item} />;

  renderContent() {
    const { results, searchParams } = this.props;
    if (!results.length) {
      return null;
    }

    const {
      limit = REF_ERRORS_PAGE_SIZE,
      offset = 0
    } = searchParams;

    const data = slice(results, offset, limit + offset);

    return (
      <div className="ref-errors-results__content">
        <SortableTable
          items={data}
          sortableFields={SORTABLE_FIELDS}
          renderItem={this.renderItem}
          className="ref-errors-results__table"
          onSort={this.sort}
          sortField={searchParams.sortField}
          sortType={searchParams.sortDirection}
        />
      </div>
    );
  }

  render() {
    const { loading, results } = this.props;

    return (
      <div className="ref-errors-results">
        <RefErrorsExpandContent title={<FormattedMessage id="refErrors_results_title" />}>
          {this.renderContent()}
          <Pagination
            onPageChange={this.onChangePage}
            pageSize={REF_ERRORS_PAGE_SIZE}
            recordCount={get(results, 'length', 0)}
            requesting={loading}
          />
        </RefErrorsExpandContent>
      </div>
    );
  }
}

RefErrorsResults.propTypes = {
  loading: PropTypes.bool,
  results: PropTypes.arrayOf(REF_ERRORS_PROP_TYPES_RESULT_ITEM),
  resultsTotalCount: PropTypes.number.isRequired,
  search: PropTypes.func.isRequired,
  searchParams: REF_ERRORS_PROP_TYPES_SEARCH_PARAMS,
  rawData: PropTypes.object
};

RefErrorsResults.defaultProps = {
  loading: false,
  rawData: {}
}

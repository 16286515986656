import axios from 'axios';
import { all, call, put } from 'redux-saga/effects';
import { LOAD_SURCHARGE_REALESTATETYPE_ACTIONS } from '../../actions/FinancingSurchargeRealestatetypeActions';
import { EndpointName } from '../../constants/EndpointName';

function getAll(endpoint) {
  return `${endpoint}/?limit(9999)`;
}

export function* loadFinancingSurchargeRealestatetype() {
  try {
    yield put(LOAD_SURCHARGE_REALESTATETYPE_ACTIONS.pending());

    const [
      { data: surchargeRealestatetypes },
      { data: realestatetypes },
    ] = yield all([
      call(axios, { url: getAll(EndpointName.FINANCING_SURCHARGE_REALESTATETYPE) }),
      call(axios, { url: getAll(EndpointName.FINANCING_REALESTATETYPE) }),
    ]);

    yield put(LOAD_SURCHARGE_REALESTATETYPE_ACTIONS.success({
      surchargeRealestatetypes,
      realestatetypes,
    }));
  } catch(error) {
    yield put(LOAD_SURCHARGE_REALESTATETYPE_ACTIONS.failure(error));
  }
}

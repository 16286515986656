import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Select from 'react-select';
import { Button } from 'react-bootstrap';

import Params from './Params';
import { actionMap } from '../constants';

export function getSupportedAction(target) {
  const component = _.get(target, 'component');
  return _.has(actionMap, component) ? _.get(actionMap, component) : [];
}

const Spacer = () => <div style={{ paddingBottom: '10px' }}></div>;

class ActionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: _.get(props, 'action.type'),
      params: _.get(props, 'action.params', []),
      path: _.get(props, 'action.path')
    };

    this.onTypeChange = this.onTypeChange.bind(this);
    this.onParamsChange = this.onParamsChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onTypeChange(selected) {
    this.setState({ type: (selected) ? selected.value : undefined, params: [] });
  }

  onParamsChange(params, path = undefined) {
    this.setState({ params, path });
  }

  onSubmit() {
    const { type, params, path } = this.state;
    const { onSubmit } = this.props;

    const action = {
      type,
      params
    };

    if (path) action.path = path;

    onSubmit(action);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { type, params, path } = this.state;
    const { language, target, codes, consultants, productGroups, onCancel } = this.props;

    const options = getSupportedAction(target);

    return(
      <div className="workguide-dependency-condition-form" style={{ backgroundColor: '#e8eaed', padding: '10px' }}>
        <strong>Aktion:</strong>
        <Select
          value={type}
          options={options}
          onChange={this.onTypeChange}
        />

        <Spacer />

          {(type) ? (
            <Params
              target={target}
              language={language}
              codes={codes}
              consultants={consultants}
              productGroups={productGroups}
              action={type}
              onChange={this.onParamsChange}
              value={params}
              path={path}
              edit={true}
            />
          ) : null}

        <Spacer />

        <Button
          bsStyle="primary"
          disabled={!type}
          onClick={this.onSubmit}
        >
          <i className="glyphicon glyphicon-ok" />
        </Button>

        <Button
          onClick={onCancel}
        >
          <i className="glyphicon glyphicon-remove" />
        </Button>
      </div>
    );
  }
}

ActionForm.propTypes = {
  target: PropTypes.object.isRequired,
  action: PropTypes.object,
  language: PropTypes.string,
  components: PropTypes.array,
  codes: PropTypes.object,
  consultants: PropTypes.array,
  productGroups: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

ActionForm.defaultProps = {
  language: 'de',
  components: [],
  codes: {},
  consultants: [],
  productGroups: [],
  onSubmit: _.noop,
  onCancel: _.noop
};

function mapStateToProps(state, ownProps) {
  const { components } = state.workguide.workguide;

  return {
    language: state.login.language,
    components,
    consultants: state.workguide.consultants,
    codeGroups: state.workguide.codeGroups,
    codes: state.workguide.codes,
    productGroups: state.workguide.productGroups
  };
}

export default connect(mapStateToProps)(ActionForm);

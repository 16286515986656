import { createSelector } from 'reselect';
import { getBasicProducts, getProducts } from './getBasicServices';

export const getRemovedProducts = createSelector([
  getProducts,
  getBasicProducts,
], (products, basicProducts) => {
  return  basicProducts.filter(
    (basicProduct) => !products.some((product) => product.id === basicProduct.id)
  );
});

import { chainReducers } from '../../utils';
import { FinancingProfilingLoadReducer } from './FinancingProfilingLoadReducer';
import { FinancingProfilingSaveReducer } from './FinancingProfilingSaveReducer';
import { FinancingProfilingUpdateReducer } from './FinancingProfilingUpdateReducer';

export const FinancingProfilingReducer = chainReducers(
  FinancingProfilingLoadReducer,
  FinancingProfilingSaveReducer,
  FinancingProfilingUpdateReducer,
);

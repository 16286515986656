import './HolidaysListDay.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl } from 'react-bootstrap';
import { FormattedGravitonDate } from '../FormattedGravitonDate';
import { ANALYTICS_PROP_TYPES_LOCALE } from '../Analytics/AnalyticsPropTypes';
import { isMultiDayHoliday } from '../../utils/holidayUtils';

export class HolidaysListDay extends React.Component {
  state = {
    name: getName(this.props.item, this.props.locale),
  };

  constructor(props) {
    super(props);
    this.remove = this.remove.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.save = this.save.bind(this);
  }

  render() {
    const { item } = this.props;
    const { name } = this.state;

    return (
      <div className="holidays-list-day">
        <div className="holidays-list-day__date">
          {isMultiDayHoliday(item)
            ? (
              <React.Fragment>
                <FormattedGravitonDate value={item.startDate} format="DD.MM"/>
                {" - "}
                <FormattedGravitonDate value={item.endDate} format="DD.MM"/>
              </React.Fragment>
            )
            : (
              <FormattedGravitonDate value={item.startDate} format="DD.MM"/>
            )}
        </div>
        <div className="holidays-list-day__title">
          <FormControl
            value={name}
            placeholder="Name"
            required={true}
            onChange={this.onChangeName}
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocus}
            onBlur={this.save}
          />
        </div>
        <div className="holidays-list-day__controls">
          <Button onClick={this.remove}>
            <span className="icon-008-bin"/>
          </Button>
        </div>
      </div>
    );
  }

  remove() {
    const { remove, item } = this.props;
    remove(item);
  }

  save() {
    const { edit, item, locale } = this.props;
    const { name } = this.state;
    if (!name) {
      this.setState({ name: getName(item, locale) });
      return;
    }

    edit(item, name);
  }

  onChangeName(event) {
    const { value } = event.target;
    this.setState({ name: value });
  }

  onKeyDown(event) {
    if (event.keyCode === 13) {
      this.save();
    }
  }

  onFocus() {
    const { item, locale } = this.props;
    this.setState({ name: getName(item, locale) });
  }
}

HolidaysListDay.propTypes = {
  locale: ANALYTICS_PROP_TYPES_LOCALE.isRequired,

  item: PropTypes.shape({
    name: PropTypes.object,
    date: PropTypes.string,
  }),
  edit: PropTypes.func,
  remove: PropTypes.func,
};

function getName(item, locale) {
  return (
    item.name[locale] ||
    item.name.en ||
    ''
  );
}

/**
 * Filter modules by acl permissions
 *
 * @param   {Acl}    acl      Acl instance
 * @param   {Array}  modules  Modules to filter
 *
 * @return  {Array} modules Allowed modules
 */
export default function filterModulesByAcl({ acl, modules = [] }) {
  // Just return all... if user is allowed to see all modules
  if (acl.isAllowed('Modules.ShowAll')) {
    return modules;
  }

  return modules.filter((m) => acl.isAllowed(`Modules.${m.id}.Show`));
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Col, Row, FormControl, ControlLabel } from 'react-bootstrap';
import Select, { Creatable } from 'react-select';
import moment from 'moment';
import Toggle from 'react-toggle';
import NumberFormat from 'react-number-format';

import './Filter.css';

const methods = [{
  value: 'POST',
  label: 'POST'
}, {
  value: 'PUT',
  label: 'PUT'
}, {
  value: 'PATCH',
  label: 'PATCH'
}, {
  value: 'DELETE',
  label: 'DELETE'
}];

class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false
    };

    this.onTextInputChange = this.onTextInputChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const { onChange } = this.props;

    onChange({ dataKey: 'fromDate', payload: moment().tz('UTC').startOf('day').utc().format() });
    onChange({ dataKey: 'toDate', payload: moment().tz('UTC').endOf('day').utc().format() });
  }

  onTextInputChange(ev) {
    const { onChange } = this.props;
    const { id, value = 0 } = ev.target;

    const payload = get(value.trim(), 'length', 0) > 0
      ? value
      : undefined;

    onChange({ dataKey: id, payload });
  }

  onSelectChange(id, selected = []) {
    const { onChange } = this.props;
    const payload = get(selected, 'length', 0) > 0
      ? selected.map((s) => get(s, 'value'))
      : undefined;

    onChange({ dataKey: id, payload });
  }

  toggle() {
    const { collapsed } = this.state;

    this.setState({ collapsed: !collapsed });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { collapsed } = this.state;
    const {
      filter,
      limit,
      onChange,
      onLimitChange
    } = this.props;

    return (
      <div className="replay-audit-log-filter">
        <Row>
          <Col lg={12}>
            <h3
              style={{ cursor: 'pointer' }}
              onClick={this.toggle}
            >
              Filter
            </h3>
          </Col>
        </Row>

        {!collapsed && (
          <React.Fragment>
            <Row className="replay-audit-log-filter--row">
              <Col lg={1}>
                <ControlLabel>Request URI: </ControlLabel>
              </Col>

              <Col lg={11}>
                <FormControl
                  id="requestUri"
                  value={get(filter, 'requestUri.data', '')}
                  onChange={this.onTextInputChange}
                />
              </Col>
            </Row>

            <Row className="replay-audit-log-filter--row">
              <Col lg={1}>
                <ControlLabel>Von: </ControlLabel>
              </Col>

              <Col lg={11}>
                <FormControl
                  id="fromDate"
                  value={get(filter, 'fromDate.data', '')}
                  onChange={this.onTextInputChange}
                />
              </Col>
            </Row>

            <Row className="replay-audit-log-filter--row">
              <Col lg={1}>
                <ControlLabel>Bis: </ControlLabel>
              </Col>

              <Col lg={11}>
                <FormControl
                  id="toDate"
                  value={get(filter, 'toDate.data', '')}
                  onChange={this.onTextInputChange}
                />
              </Col>
            </Row>

            <Row className="replay-audit-log-filter--row">
              <Col lg={1}>
                <ControlLabel>Benutzer: </ControlLabel>
              </Col>

              <Col lg={11}>
                <FormControl
                  id="username"
                  value={get(filter, 'username.data', '')}
                  onChange={this.onTextInputChange}
                />
              </Col>
            </Row>

            <Row className="replay-audit-log-filter--row">
              <Col lg={1}>
                <ControlLabel>Methode: </ControlLabel>
              </Col>

              <Col lg={11}>
                <Select
                  id="method"
                  value={get(filter, 'method.data', [])}
                  onChange={(selected) => this.onSelectChange('method', selected)}
                  options={methods}
                  multi
                />
              </Col>
            </Row>

            <Row className="replay-audit-log-filter--row">
              <Col lg={1}>
                <ControlLabel>Status Code: </ControlLabel>
              </Col>

              <Col lg={11}>
                <Creatable
                  id="responseCode"
                  multi
                  value={get(filter, 'responseCode.data', []).map((o) => ({ value: o, label: o }))}
                  onChange={(selected) => this.onSelectChange('responseCode', selected)}
                  closeOnSelect={false}
                />
              </Col>
            </Row>

            <Row className="replay-audit-log-filter--row">
              <Col lg={1}>
                <ControlLabel>Aktuelle Daten laden: </ControlLabel>
              </Col>

              <Col lg={11}>
                <Toggle
                  checked={get(filter, 'fetchRecord.data', false)}
                  onChange={() => onChange({ dataKey: 'fetchRecord', payload: !get(filter, 'fetchRecord.data', false) })}
                />

                <i>
                  Wenn selektiert, wird versucht die aktellen Daten via API (GET) anhand der Request URL zu laden.
                </i>
              </Col>
            </Row>

            <Row className="replay-audit-log-filter--row">
              <Col lg={1}>
                <ControlLabel>Anzahl Einträge pro Request (pagination): </ControlLabel>
              </Col>

              <Col lg={11}>
                <NumberFormat
                  className="form-control"
                  value={limit}
                  onValueChange={({ floatValue }) => onLimitChange(floatValue)}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </div>
    );
  }
}

Filter.propTypes = {
  limit: PropTypes.number,
  onChange: PropTypes.func,
  onLimitChange: PropTypes.func
};

Filter.defaultProps = {
  limit: 50,
  onChange: noop,
  onLimitChange: noop
};

export default Filter;

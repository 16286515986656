import { createSelector } from 'reselect';
import _ from 'lodash';
import { getCoreConfigState } from './coreConfigCommonSelectors';

export const getCoreConfigModules = createSelector([
    getCoreConfigState,
  ], (state) =>
    _.sortBy(state.modules, m => m.order),
);
export const getCoreConfigModulesInitial = createSelector([getCoreConfigState], (state) => state.initialModules);

import './PermissionGroupSearchForm.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, Form, reduxForm } from 'redux-form';
import { classList } from '../../utils/classList';
import { ExpandContent } from '../Common/ExpandContent';
import { Button } from '../Common/Button';
import { FormInput } from '../FormControls';
import { PERMISSION_GROUP_DEFAULT_SORT } from '../../constants/PermissionGroupConstants';

class PermissionGroupSearchReduxForm extends React.PureComponent {
  state = {
    expanded: false,
    items: this.props.items,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.items === prevState.items) {
      return null;
    }

    return {
      expanded: !nextProps.items || nextProps.items.length === 0,
      items: nextProps.items,
    };
  }

  render() {
    const { expanded } = this.state;
    const { handleSubmit, loading } = this.props;

    const iconClasses = classList({
      'icon-013-chevron-down': true,
      'email-subscriptions-search__icon': true,
      'email-subscriptions-search__icon--collapsed': !expanded,
    });

    return (
      <div className="email-subscriptions-search">
        <Form onSubmit={handleSubmit(this.search)}>
          <div className="email-subscriptions-search__header" onClick={this.toggleExpanded}>
            <div className="email-subscriptions-search__title">
              <FormattedMessage id="emailSubscriptions_searchTitle"/>
            </div>
            <div className={iconClasses}/>
          </div>

          <ExpandContent expanded={expanded} className="email-subscriptions-search__body">
            <div className="email-subscriptions-search__column">
              <Field component={FormInput}
                     size='large'
                     type="text"
                     label={<FormattedMessage id="permissionGroup_first_name"/>}
                     labelSize={4}
                     name="firstName"
              />
              <Field component={FormInput}
                     size='large'
                     type="text"
                     label={<FormattedMessage id="permissionGroup_name"/>}
                     labelSize={4}
                     name="lastName"
              />
              <Field component={FormInput}
                     size='large'
                     type="text"
                     label={<FormattedMessage id="permissionGroup_username"/>}
                     labelSize={4}
                     name="username"
              />
              <Field component={FormInput}
                     size='large'
                     type="text"
                     label={<FormattedMessage id="permissionGroup_tenant"/>}
                     labelSize={4}
                     name="tenant"
              />
            </div>

            <div className="email-subscriptions-search__footer">
              <Button bsStyle="primary" type="submit" disabled={loading}>
                <FormattedMessage id="emailSubscriptions_searchButton"/>
              </Button>
            </div>
          </ExpandContent>
        </Form>
      </div>
    );
  }

  toggleExpanded = () => this.setState((prev) => ({ expanded: !prev.expanded }));
  search = (searchParams) => this.props.search({
    ...searchParams,
    ...PERMISSION_GROUP_DEFAULT_SORT,
  });
}

export const PermissionGroupSearchForm = reduxForm({})(PermissionGroupSearchReduxForm);
PermissionGroupSearchForm.PropTypes = {
  search: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  items: PropTypes.array,
};

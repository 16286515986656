import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { has, get } from 'lodash';
import { HtmlPreview } from '@evoja-web/react-core-textblock';

import './Detail.css';

const LeadMassMutationTableLeadDetail = React.memo(({
  consultants,
  language,
  lead
}) => {
  const publishDate = has(lead, 'publishDate')
    ? moment(lead.publishDate).format('L')
    : '-';
  const staffMember = get(consultants, 'data', []).find((c) => c.id === lead.staffMemberId);

  return (
    <div className="lead-mass-mutation--table--body-row--detail">
      <Row className="lead-mass-mutation--table--body-row--detail--row">
        <Col lg={4} md={4}>
          <div className="lead-mass-mutation--table--body-row--detail--col-title">
            <FormattedMessage id="LeadMassMutation.Detail.PublishDate" />
          </div>

          <div className="lead-mass-mutation--table--body-row--detail--col-value">
            {publishDate}
          </div>
        </Col>

        <Col lg={4} md={4}>
          <div className="lead-mass-mutation--table--body-row--detail--col-title">
            <FormattedMessage id="LeadMassMutation.Detail.StaffMemberRegion" />
          </div>

          <div className="lead-mass-mutation--table--body-row--detail--col-value">
            {`${get(staffMember, 'location.name')} / ${get(staffMember, 'location.description')}`}
          </div>
        </Col>

        <Col lg={4} md={4}>
          <div className="lead-mass-mutation--table--body-row--detail--col-title">
            <FormattedMessage id="LeadMassMutation.Detail.AdditionalEditor" />
          </div>

          <div className="lead-mass-mutation--table--body-row--detail--col-value">
            {`${get(staffMember, 'location.name')} / ${get(staffMember, 'location.description')}`}
          </div>
        </Col>
      </Row>

      <Row className="lead-mass-mutation--table--body-row--detail--row">
        <Col lg={4} md={4}>
          <div className="lead-mass-mutation--table--body-row--detail--col-title">
            <FormattedMessage id="LeadMassMutation.Detail.Product" />
          </div>

          <div className="lead-mass-mutation--table--body-row--detail--col-value">
            {get(lead, `successProduct.text.${language}`, '-')}
          </div>
        </Col>

        <Col lg={4} md={4}>
          <div className="lead-mass-mutation--table--body-row--detail--col-title">
            <FormattedMessage id="LeadMassMutation.Detail.ExternalReference" />
          </div>

          <div className="lead-mass-mutation--table--body-row--detail--col-value">
            {get(lead, 'externalReference', '-')}
          </div>
        </Col>
      </Row>

      <Row className="lead-mass-mutation--table--body-row--detail--row">
        <Col lg={4} md={4}>
          <div className="lead-mass-mutation--table--body-row--detail--col-title">
            <FormattedMessage id="LeadMassMutation.Detail.Leadinstruction" />
          </div>

          <div className="lead-mass-mutation--table--body-row--detail--col-value">
            <HtmlPreview>
              {get(lead, 'leadinstruction', '-')}
            </HtmlPreview>
          </div>
        </Col>

        <Col lg={4} md={4}>
          <div className="lead-mass-mutation--table--body-row--detail--col-title">
            <FormattedMessage id="LeadMassMutation.Detail.Note" />
          </div>

          <div className="lead-mass-mutation--table--body-row--detail--col-value">
            {get(lead, 'note', '-')}
          </div>
        </Col>
      </Row>
    </div>
  );
});

LeadMassMutationTableLeadDetail.propTypes = {
  consultants: PropTypes.object,
  language: PropTypes.string,
  lead: PropTypes.object
};

LeadMassMutationTableLeadDetail.defaultProps = {
  consultants: {},
  language: 'de',
  lead: {}
};

export default LeadMassMutationTableLeadDetail;

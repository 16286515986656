import { put } from 'redux-saga/effects';

import configAction from '../../actions/Workflow/Config';

const {
  WORKFLOW_TREE_CONFIG_INIT_PENDING,
  WORKFLOW_TREE_CONFIG_INIT_FULFILLED,
  WORKFLOW_TREE_CONFIG_INIT_REJECTED
} = configAction;

export default function* initConfig(request) {
  const { data } = request;

  yield put({ type: WORKFLOW_TREE_CONFIG_INIT_PENDING });

  try {
    const payload = data;

    yield put({ type: WORKFLOW_TREE_CONFIG_INIT_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: WORKFLOW_TREE_CONFIG_INIT_REJECTED, error });

    return error;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isUndefined } from 'lodash';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import Select from 'react-virtualized-select';
import update from 'immutability-helper';

const typeOptions = [{
  value: 'warning',
  label: 'Warnung'
}, {
  value: 'danger',
  label: 'Fehler'
}, {
  value: 'success',
  label: 'Erfolg'
}];

class ExistingWorkguideCheckConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.onSelectChange = this.onSelectChange.bind(this);
    this.onInit = this.onInit.bind(this);

    this.onInit();
  }

  onInit() {
    const {
      editValueField,
      id,
      item,
      workguide
    } = this.props;

    const options = get(item, 'props.options', []);
    const workguideOption = options.find(o => get(o, 'label.de') === 'workguide');
    const typeOption = options.find(o => get(o, 'label.de') === 'type');

    let updated = [...options];
    if (isUndefined(workguideOption)) {
      updated = update(updated, {
        $push: [{ value: get(workguide, 'id'), label: { de: 'workguide' } }]
      });
    }

    if (isUndefined(typeOption)) {
      updated = update(updated, {
        $push: [{ value: 'warning', label: { de: 'type' } }]
      });
    }

    editValueField(id, 'componentOptions', updated);
  }

  onSelectChange(which, selected = {}) {
    const {
      editValueField,
      id,
      item
    } = this.props;

    const options = get(item, 'props.options', []);
    const index = options.findIndex(o => get(o, 'label.de') === which);

    const updated = index < 0
      ? update(options, { $push: [{ value: get(selected, 'value'), label: { de: which } }] })
      : update(options, { [index]: { value: { $set: get(selected, 'value') } } });

    editValueField(id, 'componentOptions', updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { 
      codes,
      item,
      language,
      workguides
    } = this.props;

    const options = get(item, 'props.options', []);
    const workguideOption = options.find(o => get(o, 'label.de') === 'workguide');
    const statusOption= options.find(o => get(o, 'label.de') === 'status');
    const typeOption= options.find(o => get(o, 'label.de') === 'type');

    return (
      <FormGroup>
        <Col componentClass={ControlLabel} sm={3}>
          Konfiguration
        </Col>

        <Col sm={9}>
          <FormGroup>
            <ControlLabel>Workguide</ControlLabel>

            <Select
              onChange={(...args) => this.onSelectChange('workguide', ...args)}
              options={workguides.map((w) => ({ value: get(w, 'id'), label: get(w, `title.${language}`) }))}
              value={get(workguideOption, 'value', undefined)}
            />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Status</ControlLabel>

            <Select
              onChange={(...args) => this.onSelectChange('status', ...args)}
              options={get(codes, 'workguideStatus', []).map((c) => ({ value: get(c, 'id'), label: get(c, `text.${language}`) }))}
              value={get(statusOption, 'value', undefined)}
            />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Typ</ControlLabel>

            <Select
              onChange={(...args) => this.onSelectChange('type', ...args)}
              options={typeOptions}
              value={get(typeOption, 'value', undefined)}
            />
          </FormGroup>
        </Col>
      </FormGroup>
    );
  }
}

ExistingWorkguideCheckConfiguration.propTypes = {
  editValueField: PropTypes.func,
  language: PropTypes.string,
  workguides: PropTypes.array
};

ExistingWorkguideCheckConfiguration.defaultProps = {
  editValueField: noop,
  language: 'de',
  workguides: []
};

export default ExistingWorkguideCheckConfiguration;

import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { mapBasicProductToApi } from '../../services/BasicProducts';
import { getBasicProducts, getCategories, getNewProducts } from '../../selectors/BasicProducts';
import { getLanguage } from '../../selectors/commonSelectors';
import { createRef } from '../../utils';

export function* createBasicProductsSaga() {
  const newProducts = yield select(getNewProducts);
  const language = yield select(getLanguage);
  const basicProducts = yield select(getBasicProducts);
  const basicCategories = yield select(getCategories);

  yield all(
    newProducts.map((product) => call(axios, {
      url: createRef(EndpointName.BASIC_PRODUCT, product.id),
      method: 'put',
      data: mapBasicProductToApi(product, language, basicProducts, basicCategories),
    }))
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isEmpty } from 'lodash';
import cl from 'classnames';

import './Node.css';
import { WORKFLOW_TREE_START_NODE } from '../../../../constants';
import Draggable from './Draggable';
import EditNodeModal from '../Modal/EditNode';
import Connections from './Connections';

class WorkflowTreeNode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditModal: false,
      showToolbar: false
    };

    this.timeoutId = undefined;

    this.onSourceClick = this.onSourceClick.bind(this);
    this.onTargetClick = this.onTargetClick.bind(this);
    this.onEditFormSubmit = this.onEditFormSubmit.bind(this);
    this.onDuplicate = this.onDuplicate.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onToggleEditModal = this.onToggleEditModal.bind(this);
    this.onShowToolbar = this.onShowToolbar.bind(this);
    this.onHideToolbar = this.onHideToolbar.bind(this);
  }

  /**
   * Handle click to the source attach point
   *
   * @param   {Object}  event  Click event
   *
   * @return  void
   */
  onSourceClick(event) {
    const {
      connecting,
      node,
      onConnectStart,
      position
    } = this.props;

    event.stopPropagation();
    event.preventDefault();

    // Click to source is not possible if there's already a source selected
    if (connecting) {
      return;
    }

    onConnectStart({ node, position, event });
  }

  /**
   * Handle click to the target attach point
   *
   * @param   {Object}  event  Click event
   *
   * @return  void
   */
  onTargetClick(event) {
    const {
      connecting,
      node,
      onConnectEnd,
      position
    } = this.props;

    event.stopPropagation();
    event.preventDefault();

    // Click is only possible if a source was selected
    if (!connecting) {
      return;
    }

    onConnectEnd({ node, position, event });
  }

  /**
   * Handle edit form submit
   *
   * @return  void
   */
  onEditFormSubmit({ data }) {
    const { onChange } = this.props;

    this.setState({ showEditModal: false });
    onChange({ node: data });
  }

  /**
   * Duplicate this node
   *
   * @return  void
   */
  onDuplicate() {
    const {
      node,
      onDuplicate
    } = this.props;

    onDuplicate({ node });
  }

  /**
   * Remove this node
   *
   * @return  void
   */
  onRemove() {
    const {
      node,
      onRemove
    } = this.props;

    onRemove({ node });
  }

  /**
   * Toggle the edit modal
   *
   * @return  void
   */
  onToggleEditModal() {
    const { showEditModal } = this.state;

    this.setState({ showEditModal: !showEditModal });
  }

  /**
   * Show the toolbar
   *
   * @return  void
   */
  onShowToolbar() {
    clearTimeout(this.timeoutId);

    this.setState({ showToolbar: true });
  }

  /**
   * Hide the toolbar
   *
   * @return  void
   */
  onHideToolbar() {
    this.timeoutId = setTimeout(() => {
      this.setState({ showToolbar: false });
      window.clearTimeout(this.timeoutId);
    }, 200);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      showEditModal,
      showToolbar
    } = this.state;
    const {
      connecting,
      connectionStart,
      consultants,
      language,
      node,
      onConnectionChange,
      onConnectionRemove,
      position,
      validations,
      connectionValidations,
      workguide
    } = this.props;

    const style = {
      top: get(position, 'y', 0),
      left: get(position, 'x', 0)
    };

    return (
      <>
        <Draggable
          id={`node-draggable-${node.id}`}
          className={cl({
            'workflow-tree-node': true,
            'workflow-tree-node--invalid': !isEmpty(validations)
          })}
          item={node}
          style={style}
          type={node.type}
        >
          <div
            className="workflow-tree-node--content pointer"
            onDoubleClick={this.onToggleEditModal}
          >
            {node.type !== WORKFLOW_TREE_START_NODE && (
              <div
                id={`workflow-tree-node-attach-point-${node.id}-target`}
                className="workflow-tree-node--content--attach-point"
                onClick={this.onTargetClick}
              />
            )}

            {showToolbar && (
              <div
                className="workflow-tree-node--content--toolbar"
                onMouseEnter={this.onShowToolbar}
                onMouseLeave={this.onHideToolbar}
              >
                {node.type !== WORKFLOW_TREE_START_NODE && (
                  <>
                    <div
                      className="mdi mdi-content-duplicate"
                      onClick={this.onDuplicate}
                    />

                    <div
                      className="mdi mdi-trash-can-outline"
                      onClick={this.onRemove}
                    />
                  </>
                )}

              </div>
            )}

            <div
              className="workflow-tree-node--content--details"
              onMouseEnter={this.onShowToolbar}
              onMouseLeave={this.onHideToolbar}
            >
              {get(node, `title.${language}`)}
            </div>

            <div
              id={`workflow-tree-node-attach-point-${node.id}-source`}
              className={cl('workflow-tree-node--content--attach-point', { 'bg-info': connecting && get(connectionStart, 'id') === get(node, 'id') })}
              onClick={this.onSourceClick}
            />
          </div>
        </Draggable>

        <Connections
          node={node}
          onConnectionChange={onConnectionChange}
          onConnectionRemove={onConnectionRemove}
          position={position}
          validations={connectionValidations}
        />

        {showEditModal && (
          <EditNodeModal
            consultants={consultants}
            isOpen
            language={language}
            onEditFormSubmit={this.onEditFormSubmit}
            onHide={this.onToggleEditModal}
            node={node}
            workguide={workguide}
          />
        )}
      </>
    );
  }
}

WorkflowTreeNode.propTypes = {
  connecting: PropTypes.bool,
  connectionStart: PropTypes.object,
  consultants: PropTypes.array,
  language: PropTypes.string,
  node: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onConnectEnd: PropTypes.func,
  onConnectStart: PropTypes.func,
  onConnectionChange: PropTypes.func,
  onConnectionRemove: PropTypes.func,
  onDuplicate: PropTypes.func,
  onRemove: PropTypes.func,
  position: PropTypes.object,
  validations: PropTypes.object,
  connectionValidations: PropTypes.object,
  workguide: PropTypes.object.isRequired
};

WorkflowTreeNode.defaultProps = {
  connecting: false,
  connectionStart: undefined,
  consultants: [],
  language: 'de',
  onChange: noop,
  onConnectEnd: noop,
  onConnectStart: noop,
  onConnectionChange: noop,
  onConnectionRemove: noop,
  onDuplicate: noop,
  onRemove: noop,
  position: {},
  validations: {},
  connectionValidations: {}
};

export default WorkflowTreeNode;

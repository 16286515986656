import axios from 'axios';
import { all, call, put } from 'redux-saga/effects';
import { ANALYTICS_LOGS_COMPARISON } from '../../actions/AnalyticsLogsActions';
import { EndpointName } from '../../constants/EndpointName';

export function* analyticsLogsComparisonSaga(action) {
  try {
    yield put(ANALYTICS_LOGS_COMPARISON.pending());

    const { first, second } = action.data;
    const queryFirst = `requestId=${encodeURIComponent(first.id)}&showResponseBody=1&showRequestBody=1`;
    const querySecond = `requestId=${encodeURIComponent(second.id)}&showResponseBody=1&showRequestBody=1`;
    const [
      { data: firstDetails },
      { data: secondDetails },
    ]= yield all([
      call(axios, { url: `${EndpointName.ANALYTICS_REQUESTS}?${queryFirst}` }),
      call(axios, { url: `${EndpointName.ANALYTICS_REQUESTS}?${querySecond}` }),
    ]);
    const comparisonDetails = {
      first: firstDetails[0],
      second: secondDetails[0],
    };

    yield put(ANALYTICS_LOGS_COMPARISON.success({ comparisonDetails }));
  } catch (error) {
    yield put(ANALYTICS_LOGS_COMPARISON.failure(error));
  }
}

import { takeLatest } from 'redux-saga/effects';
import { refErrorsLoadDataSaga } from './refErrorsLoadDataSaga';
import { refErrorsLoadAllSaga } from './refErrorsLoadAllSaga';
import { refErrorsSearchSaga } from './refErrorsSearchSaga';
import { REF_ERRORS_LOAD, REF_ERRORS_LOAD_ALL, REF_ERRORS_SEARCH } from '../../actions/RefErrorsActions';

export function* refErrorsSagas() {
  yield takeLatest(REF_ERRORS_LOAD.REQUEST, refErrorsLoadDataSaga);
  yield takeLatest(REF_ERRORS_LOAD_ALL.REQUEST, refErrorsLoadAllSaga);
  yield takeLatest(REF_ERRORS_SEARCH.REQUEST, refErrorsSearchSaga);
}

import { getIdFromRef } from '../../utils';

export function clearRemovedFiles(files, promotions) {
  const updatedFiles = {...files};
  const allFileIds = promotions.reduce((result, promotion) => {
    const existingFiles = [
      promotion.image ? getIdFromRef(promotion.image.$ref) : undefined,
      promotion.teaserImage ? getIdFromRef(promotion.teaserImage.$ref) : undefined,
      ...promotion.document.map((document) => getIdFromRef(document.$ref)),
    ].filter(Boolean);
    return existingFiles.length ? [...result, ...existingFiles] : result;
  }, []);
  Object.keys(files).forEach((fileId) => {
    if (!allFileIds.includes(fileId)) {
      delete updatedFiles[fileId];
    }
  });
  return updatedFiles;
}

import React from 'react';
import PropTypes from 'prop-types';
import { deburr, filter, get, includes, toLower, noop } from 'lodash';
import { toast } from 'react-toastify';
import { Link, withRouter } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import { handleGravitonError } from '../utils';
import PageContainer from '../components/Common/PageContainer';
import { SearchBlock } from '../components/Common/SearchBlock';
import { SortableTable } from '../components/Common/SortableTable';
import { TableCell, TableRow } from '../components/Common/Table';
import { Button } from '../components/Common/Button';

class WorkguideList extends React.Component {
  constructor(props) {
    super(props);
    const { language } = props;
    this.sortableFields = [
      {
        key: 'title',
        label: <FormattedMessage id="workguideList_title" />,
        sortFn: (item) => get(item, `title.${language}`, '').toLowerCase(),
      },
      {
        key: 'type',
        label: <FormattedMessage id="workguideList_type" />,
        sortFn: (item) => item.type,
      },
      {
        key: 'target',
        label: <FormattedMessage id="workguideList_responsible_mail" />,
        sortFn: (item) => item.target,
      },
      {
        key: 'defaultAssignee',
        label: <FormattedMessage id="workguideList_assignee" />,
        sortFn: (item) => item.defaultAssignee,
      },
      {
        key: 'usage',
        label: <FormattedMessage id="workguideList_usage" />,
        sortFn: (item) => item.count,
      },
      {
        key: 'excludeFromParamTransfer',
        label: <FormattedMessage id="workguideList_no_params" />,
        sortFn: (item) => item.excludeFromParamTransfer,
      },
      {
        key: 'active',
        label: <FormattedMessage id="workguideList_active" />,
        sortFn: (item) => item.active,
      },
      {
        key: 'edit',
        label: <FormattedMessage id="workguideList_action" />
      }
    ];

    this.renderItem = this.renderItem.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
  }

  filterAndCountWorkguides() {
    const {
      workguides, workguideUsage, language, search
    } = this.props;
    const lowerCaseSearch = deburr(toLower(search));
    const filteredWorkguides = filter(workguides, (item) => includes(deburr(toLower(item.title[language])), lowerCaseSearch));

    filteredWorkguides.forEach((item) => {
      const usage = workguideUsage.find((u) => u.workguideId === item.id);
      item.count = usage ? usage.count : 0;
    });

    return filteredWorkguides;
  }

  formattedBolean(value) {
    return (
      <span
        className={value ? 'icon-012-check-mark-circle green' : 'icon-202-clear-circle red'}
        style={{ fontSize: 25 }}
      />
    );
  }

  renderItem(workguide) {
    const {
      history,
      language,
      consultants
    } = this.props;

    const defaultAssignee = consultants.find((consultant) => consultant.id === workguide.defaultAssignee);
    return (
      <TableRow key={workguide.id} style={{ height: 60, width: '100%' }}>
        <TableCell style={{ paddingLeft: 15, background: '#b5b1b11c' }}>
          {get(workguide, `title.${language}`, '-')}
        </TableCell>

        <TableCell style={{ paddingLeft: 15 }}>
          {get(workguide, 'type', '-')}
        </TableCell>

        <TableCell style={{ paddingLeft: 15, maxWidth: 370, background: '#b5b1b11c' }}>
          {get(workguide, 'target', '-')}
        </TableCell>

        <TableCell style={{ paddingLeft: 15 }}>
          {`${get(defaultAssignee, 'lastName', '')} ${get(defaultAssignee, 'firstName', '-')}`}
        </TableCell>

        <TableCell style={{ paddingLeft: 15, background: '#b5b1b11c' }}>
          {!['bpf', 'info', 'bpfCustom'].includes(get(workguide, 'type')) ? get(workguide, 'count') : '-'}
        </TableCell>

        <TableCell style={{ paddingLeft: 15, textAlign: 'center' }}>
          {this.formattedBolean(!get(workguide, 'excludeFromParamTransfer'))}
        </TableCell>

        <TableCell style={{ paddingLeft: 15, background: '#b5b1b11c', textAlign: 'center' }}>
          {this.formattedBolean(get(workguide, 'active'))}
        </TableCell>

        <TableCell
          style={{ paddingLeft: 15, maxWidth: 100, textAlign: 'center' }}
          onClick={() => history.push(`/workguidedetail/${encodeURIComponent(workguide.id)}`)}
        >
          <span className="icon-025-edit" style={{ fontSize: 25 }} />
        </TableCell>
      </TableRow>
    );
  }

  renderHeader() {
    const { onNewWorkguide } = this.props;
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 15 }}>
        <SearchBlock />
        <Button bsStyle="primary" onClick={onNewWorkguide}>
          <FormattedMessage id="workguideList_create" />
        </Button>
        <Link to="/workguideCodes">
          <Button>
            <FormattedMessage id="workguideList_codes" />
          </Button>
        </Link>
      </div>
    );
  }

  render() {
    const { requesting, hasError, error } = this.props;
    if (hasError) {
      toast.error(handleGravitonError(error));
    }

    return (
      <PageContainer
        requesting={requesting}
        title={<FormattedMessage id="workguideList_list_of_workguides" />}
        header={this.renderHeader()}
        fullwidth
      >
        <SortableTable
          items={this.filterAndCountWorkguides()}
          sortableFields={this.sortableFields}
          renderItem={this.renderItem}
          sortField="title"
          sortType="asc"
        />
      </PageContainer>
    );
  }
}

WorkguideList.propTypes = {
  history: PropTypes.object.isRequired,
  language: PropTypes.string,
  search: PropTypes.string,
  workguides: PropTypes.array,
  workguideUsage: PropTypes.array,
  consultants: PropTypes.array,
  hasError: PropTypes.bool,
  requesting: PropTypes.bool,
  onNewWorkguide: PropTypes.func,
};

WorkguideList.defaultProps = {
  language: 'de',
  search: '',
  workguides: [],
  workguideUsage: [],
  consultants: [],
  hasError: false,
  requesting: false,
  onNewWorkguide: noop
};

export default withRouter(WorkguideList);

import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { UI_NOTES_IMAGE_FETCH_ACTIONS } from '../../actions/UiNotesActions';

export function* uiNotesImageFetchSaga({ data: { $ref } }) {
  if (!$ref) {
    yield put(UI_NOTES_IMAGE_FETCH_ACTIONS.success(null));
    return;
  }

  try {
    yield put(UI_NOTES_IMAGE_FETCH_ACTIONS.pending());

    const filePath = new URL($ref).pathname;
    const { data: file } = yield call(axios.get, filePath, {
      timeout: 50000,
      responseType: 'blob',
    });

    yield put(UI_NOTES_IMAGE_FETCH_ACTIONS.success({ $ref, file }));
  } catch (error) {
    yield put(UI_NOTES_IMAGE_FETCH_ACTIONS.failure(error));
  }
}

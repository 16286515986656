import PropTypes from 'prop-types';

export const PROP_TYPES_ACTIVE_SESSION = PropTypes.shape({
  username: PropTypes.string.isRequired,
  keyCreatedAt: PropTypes.string.isRequired,
  keyRefreshedAt: PropTypes.string.isRequired,
});

export const PROP_TYPES_ACTIVE_USER = PropTypes.shape({
  username: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  department: PropTypes.string,
  division: PropTypes.string,
  sessions: PropTypes.arrayOf(PROP_TYPES_ACTIVE_SESSION).isRequired,
});

export const PROP_TYPES_HISTORY_ITEM = PropTypes.shape({
  date: PropTypes.object.isRequired,
  requestCount: PropTypes.number.isRequired,
});

export const PROP_TYPES_HISTORY_DETAILS_ITEM = PropTypes.shape({
  username: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  department: PropTypes.string,
  division: PropTypes.string,
  firstRequest: PropTypes.object,
  lastRequest: PropTypes.object,
  requestCount: PropTypes.number,
});

export const PROP_TYPES_HISTORY_STAT = PropTypes.shape({
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  avg: PropTypes.number.isRequired,
});

export const ANALYTICS_PROP_TYPES_LOCALE = PropTypes.oneOf(['de', 'en', 'fr']);

import { takeEvery, takeLatest } from 'redux-saga/effects';

import replayActions from '../actions/ReplayAuditLog';

import applyLocalFilters from './ReplayAuditLog/applyLocalFilters';
import connectSource from './ReplayAuditLog/connectSource';
import connectTarget from './ReplayAuditLog/connectTarget';
import getAuditLog from './ReplayAuditLog/getAuditLog';
import replay from './ReplayAuditLog/replay';
import setFilter from './ReplayAuditLog/setFilter';
import setLocalFilters from './ReplayAuditLog/setLocalFilters';

const {
  APPLY_LOCAL_FILTERS_REQUEST,
  AUDIT_LOG_REQUEST,
  CONNECT_SOURCE_REQUEST,
  CONNECT_TARGET_REQUEST,
  REPLAY_REQUEST,
  SET_FILTER_REQUEST,
  SET_LOCAL_FILTERS_REQUEST
} = replayActions;

export default function* replayAuditLogRootSaga() {
  yield takeLatest(AUDIT_LOG_REQUEST, getAuditLog);
  yield takeLatest(CONNECT_SOURCE_REQUEST, connectSource);
  yield takeLatest(CONNECT_TARGET_REQUEST, connectTarget);
  yield takeLatest(APPLY_LOCAL_FILTERS_REQUEST, applyLocalFilters);
  yield takeLatest(REPLAY_REQUEST, replay);
  yield takeEvery(SET_FILTER_REQUEST, setFilter);
  yield takeEvery(SET_LOCAL_FILTERS_REQUEST, setLocalFilters);
}

import { HOLIDAYS_CREATE_ACTIONS } from '../../actions/HolidaysActions';

export function HolidaysCreateReducer(state, action) {
  switch (action.type) {
    case HOLIDAYS_CREATE_ACTIONS.SUCCESS:
      return { ...state, holidaysLists: action.data.holidaysLists };
    default:
      return state;
  }
}

import { all, call, put } from 'redux-saga/effects';
import { SET_MANAGEMENT_SAVE_ACTIONS } from '../../actions/SetManagementActions';
import { setManagementSaveBundles } from './setManagementSaveBundles';
import { setManagementSaveProducts } from './setManagementSaveProducts';
import { setManagementSavePrices } from './setManagementSavePrices';

export function* setManagementSaveSaga() {
  try {
    yield put(SET_MANAGEMENT_SAVE_ACTIONS.pending());

    yield all([
      call(setManagementSaveBundles),
      call(setManagementSaveProducts),
      call(setManagementSavePrices),
    ]);

    yield put(SET_MANAGEMENT_SAVE_ACTIONS.success());
  } catch (error) {
    yield put(SET_MANAGEMENT_SAVE_ACTIONS.failure(error));
  }
}

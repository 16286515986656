import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { DateInput } from '@evoja-web/react-form';

class DateFieldDefaultValueFixed extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Handle date selection change
   *
   * @param   {String}  id     Form element id
   * @param   {String}  value  Date string
   *
   * @return  void
   */
  onChange(id, value) {
    const { onChange } = this.props;

    onChange(value);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { value } = this.props;

    return (
      <div className="workguide-component-config--date-field--default-value--fixed">
        <DateInput
          id="fixed"
          onChange={this.onChange}
          value={value}
        />
      </div>
    );
  }
}

DateFieldDefaultValueFixed.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
};

DateFieldDefaultValueFixed.defaultProps = {
  onChange: noop,
  value: undefined
};

export default DateFieldDefaultValueFixed;

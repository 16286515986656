import axios from 'axios';
import { EndpointName } from '../../constants/EndpointName';
import { CONSULTANT_UPDATE } from '../../actions/PermissionGroupAction';
import { call, put } from 'redux-saga/effects'; 

export function* permissionGroupUpdateConsultantsSaga({ data }) {
  try {
    yield put(CONSULTANT_UPDATE.pending());
    const patch = [
      {
        op: 'remove',
        path: `/permission`,
      },
      {
        op: 'add',
        path: `/permission`,
        value: data.permission
      },
    ];
    yield call(axios, {
        method: 'patch',
        url: `${EndpointName.PERSON_CONSULTANT}/${data.id}`,
        data: patch,
      });

    yield put(CONSULTANT_UPDATE.success(data));
  } catch (error) {
    yield put(CONSULTANT_UPDATE.failure(error));
  }
}

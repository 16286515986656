import { compareRefs, createRef, getIdFromRef } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';

export function mapBasicProductAdditionToApp(product, additions) {
  const addition = additions.find((addition) => compareRefs(
    createRef(EndpointName.BASIC_PRODUCT, product.id),
    addition.product.$ref,
  ));

  if (!addition) {
    return undefined;
  }

  return mapBasicAdditionToApp(addition);
}

export function mapBasicAdditionToApp(addition) {
  return {
    displayClass: getIdFromRef(addition.displayClass.$ref),
    outputClass: getIdFromRef(addition.outputClass.$ref),
    detailGroup: addition.detailGroup
      ? addition.detailGroup.map((it) => getIdFromRef(it.$ref))
      : [],
    changeType: addition.changeType
      ? addition.changeType.map((it) => getIdFromRef(it.$ref))
      : [],
  };
}

export function mapBasicAdditionToApi(product) {
  return {
    product: {
      $ref: createRef(EndpointName.BASIC_PRODUCT, product.id),
    },
    displayClass: {
      $ref: createRef(EndpointName.ENTITY_CODE, product.addition.displayClass),
    },
    outputClass: {
      $ref: createRef(EndpointName.ENTITY_CODE, product.addition.outputClass),
    },
    detailGroup: product.addition.detailGroup.map((it) => ({
      $ref: createRef(EndpointName.ENTITY_CODE, it)
    })),
    changeType: product.addition.changeType.map((it) => ({
      $ref: createRef(EndpointName.ENTITY_CODE, it)
    })),
  };
}

import './BulkLoadsStateIcon.css';

import React from 'react';
import { classList } from '../../utils/classList';
import { BULK_LOADS_STATES } from '../../constants/BulkLoadsConstants';
import { BULK_LOAD_PROP_TYPES_STATE } from './BulkLoadsPropTypes';

export function BulkLoadsStateIcon({ state }) {
  const classes = classList({
    'icon-045-hourglass': state === BULK_LOADS_STATES.OPEN,
    'icon-007-backup': state === BULK_LOADS_STATES.ACTIVE,
    'icon-012-check-mark-circle': state === BULK_LOADS_STATES.DONE,
    'icon-202-clear-circle': state === BULK_LOADS_STATES.ERROR,
    'bulk-loads-state-icon': true,
    [`bulk-loads-state-icon--${state}`]: true,
  });

  return (
    <span className={classes} title={state}/>
  );
}

BulkLoadsStateIcon.propTypes = {
  state: BULK_LOAD_PROP_TYPES_STATE.isRequired,
};

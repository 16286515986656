import axios from 'axios';
import { all, call, put } from 'redux-saga/effects';
import { SET_MANAGEMENT_LOAD_ACTIONS } from '../../actions/SetManagementActions';
import { EndpointName } from '../../constants/EndpointName';

export function* setManagementLoadSaga() {
  try {
    yield put(SET_MANAGEMENT_LOAD_ACTIONS.pending());

    const [
      { data: bundles },
      { data: products },
      { data: prices },
      { data: entityCodes },
    ] = yield all([
      call(axios, { url: `${EndpointName.BASIC_PRODUCT_BUNDLE_BUNDLE}/?limit(1000)` }),
      call(axios, { url: `${EndpointName.BASIC_PRODUCT_BUNDLE_PRODUCT}/?limit(1000)` }),
      call(axios, { url: `${EndpointName.BASIC_PRODUCT_BUNDLE_PRICING}/?limit(1000)` }),
      call(axios, { url: `${EndpointName.ENTITY_CODE}/?in(group,(setAdvisorDomain,setAdvisorReductionType))&limit(1000)` }),
    ]);

    const domains = entityCodes.filter((it) => it.group === 'setAdvisorDomain');
    const reductionTypes = entityCodes.filter((it) => it.group === 'setAdvisorReductionType');

    yield put(SET_MANAGEMENT_LOAD_ACTIONS.success({
      bundles,
      products,
      prices,
      domains,
      reductionTypes,
    }));
  } catch (error) {
    yield put(SET_MANAGEMENT_LOAD_ACTIONS.failure(error));
  }
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Panel, Modal } from 'react-bootstrap';
import { get } from 'lodash';

import './Detail.css';
import Form from './Form';

const CodeMappingDetail = React.memo(({ data }) => {
  const [isCollapsed, toggleCollapsed] = useState(true);
  const [isModalOpen, toggleModal] = useState(false);

  const { name, mapping, recordOrigin } = data;

  function onEdit(ev) {
    ev.stopPropagation();
    toggleModal(true);
  }

  return (
    <React.Fragment>
      <Panel className="code-mapping-detail">
        <Panel.Heading>
          <div
            className="code-mapping-detail--header"
            onClick={() => toggleCollapsed(!isCollapsed)}
          >
            <div style={{ fontSize: '1.2em' }}>
              <span className={isCollapsed ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} />
            </div>

            <div>
              {name}
            </div>

            {recordOrigin !== 'core'? 
              <div
                className="code-mapping-detail--header--edit-icon"
                onClick={onEdit}
              >
                <span className="mdi mdi-pencil-outline" />
              </div>
            :''}
          </div>
        </Panel.Heading>

        {!isCollapsed && (
          <Panel.Body>
          <div className="code-mapping-detail--body">
              {mapping.map((item) => {
                return (
                  <div className="code-mapping-detail--content-item">
                    <div className="code-mapping-detail--content-item-key">
                      Key: {get(item, 'key')}
                    </div>

                    <div className="code-mapping-detail--content-item-value">
                      {get(item, 'value', []).map((codeId) => {
                        return (
                          <div className="code-mapping-detail--content-item-value--code-id">
                            - {codeId}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </Panel.Body>
        )}
      </Panel>

      <Modal
        bsSize="large"
        show={isModalOpen}
        onHide={() => toggleModal(false)}
      >
        <Modal.Header>
          Code Mapping Bearbeiten
        </Modal.Header>

        <Modal.Body>
          <Form
            mappingId={get(data, 'id')}
            onCancel={() => toggleModal(false)}
            onSubmit={() => toggleModal(false)}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
});

CodeMappingDetail.propTypes = {
  data: PropTypes.object.isRequired
};

CodeMappingDetail.defaultProps = {

};

export default CodeMappingDetail;

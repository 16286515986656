import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormControl } from 'react-bootstrap';

const CriteriaInput = ({ source, criteria, onChange, edit }) => {
  function onValueChange(ev) {
    const value = _.get(ev, 'target.value');
    onChange({
      source: _.get(source, 'props.id'),
      path: `${_.get(source, 'props.id')}`,
      values: (value) ? [value] : []
    });
  }

  return (edit)
    ? (
      <React.Fragment>
        <strong>Wert</strong>

        <FormControl
          onChange={onValueChange}
          type="text"
          value={_.get(criteria, 'values.0', '')}
        />
      </React.Fragment>
    )
    : (
      <span>{_.get(criteria, 'values', []).join(', ')}</span>
    );
}

CriteriaInput.propTypes = {
  source: PropTypes.object.isRequired,
  criteria: PropTypes.object,
  onChange: PropTypes.func,
  edit: PropTypes.bool,
  operation: PropTypes.string
};

CriteriaInput.defaultProps = {
  criteria: {},
  onChange: _.noop,
  edit: false,
  operation: 'eq'
};

export default CriteriaInput;

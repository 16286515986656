import './Item.css';
import React, { Component } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Modal, InputGroup, FormControl, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import ConditionForm from './ConditionForm';
import DragHandle from '../DragHandle';

class Item extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
    };
    this.modalOpen = this.modalOpen.bind(this);
    this.modalClose = this.modalClose.bind(this);
  }

  modalOpen() {
    this.setState({
      modalOpen: true,
    });
  }

  modalClose() {
    this.setState({
      modalOpen: false,
    });
  }


  render() {
    const {
      displayCondition, id, module, section, onUpdate, defaultCheckedState, text,
    } = this.props;
    const dCondition = this.props.displayCondition === undefined ? '' : displayCondition;

    return (
      <div className="checklist-item SortableHOCItem">
        <InputGroup>
          <InputGroup.Addon className="checklist-item__checkbox">
            <input
              type="checkbox"
              name="checkedState"
              value={`checkbox-${id}`}
              checked={defaultCheckedState}
              onChange={onUpdate.bind(this, 'updateItem', module, section, id)}
            />
          </InputGroup.Addon>
          <FormControl
            type="text"
            name="checklistItem"
            value={text}
            onChange={onUpdate.bind(this, 'updateItem', module, section, id)}
          />
          <InputGroup.Addon className="checklist-item__control">
            <span style={{ cursor: 'pointer', color: dCondition.length > 0 ? 'Blue' : 'inherit' }}
                  className="icon-046-information-circle"
                  onClick={this.modalOpen}/>
            <span style={{ cursor: 'pointer' }}
                  className="icon-008-bin"
                  onClick={onUpdate.bind(this, 'deleteItem', module, section, id)}/>
          </InputGroup.Addon>
          <InputGroup.Addon className="checklist-item__drag">
            <DragHandle />
          </InputGroup.Addon>
        </InputGroup>
        <Modal show={this.state.modalOpen} onHide={this.modalClose} bsSize="large">
          <Modal.Header closeButton>
            <Modal.Title>Anzeigebedingungen für {text}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ConditionForm
              module={module}
              section={section}
              index={id}
              onUpdate={onUpdate}
              displayCondition={dCondition}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.modalClose}>
              Schliessen
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

Item.propTypes = {
  module: PropTypes.number.isRequired,
  section: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  displayCondition: PropTypes.string.isRequired,
  defaultCheckedState: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};


const SortableItem = SortableElement(Item);


export default class MyElement extends Component {
  render() {
    const {
      index, id, module, section, onUpdate, defaultCheckedState, text, displayCondition,
    } = this.props;
    return (
      <SortableItem
        index={index}
        collection={section}
        id={id}
        module={module}
        section={section}
        onUpdate={onUpdate}
        defaultCheckedState={defaultCheckedState}
        text={text}
        displayCondition={displayCondition}
      />
    );
  }
}

import { takeLatest } from 'redux-saga/effects';

import jobProcessingActions from '../../actions/JobProcessingActions';

import getDefinitions from './getDefinitions';
import getExecuted from './getExecuted';
import getExecutionHistory from './getExecutionHistory';
import getSummary from './getSummary';
import triggerJob from './triggerJob';

const {
  DEFINITIONS_REQUEST,
  EXECUTED_REQUEST,
  EXECUTION_HISTORY_REQUEST,
  SUMMARY_REQUEST,
  TRIGGER_JOB_REQUEST
} = jobProcessingActions;

export default function* jobProcessingRootSaga() {
  yield takeLatest(DEFINITIONS_REQUEST, getDefinitions);
  yield takeLatest(EXECUTED_REQUEST, getExecuted);
  yield takeLatest(EXECUTION_HISTORY_REQUEST, getExecutionHistory);
  yield takeLatest(SUMMARY_REQUEST, getSummary);
  yield takeLatest(TRIGGER_JOB_REQUEST, triggerJob);
}
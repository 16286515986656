import axios from 'axios';
import { get, isEqual } from 'lodash';
import { all, call, select } from 'redux-saga/effects';
import {
  getSetManagementOriginalProducts,
  getSetManagementProductsRaw,
} from '../../selectors/SetManagement/setManagementCommonSelectors';
import { createRef, getIdFromRef } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';

export function* setManagementSaveProducts() {
  const productsOriginal = yield select(getSetManagementOriginalProducts);
  const products = yield select(getSetManagementProductsRaw);

  const toSave = products.filter((product) => {
    const original = productsOriginal.find((it) => it.id === product.id);
    return original
      ? !isProductsEqual(product, original)
      : false;
  });

  yield all(
    toSave.map((product) => call(axios, {
      method: 'put',
      url: createRef(EndpointName.BASIC_PRODUCT_BUNDLE_PRODUCT, product.id),
      data: product,
    }))
  );
}

function isProductsEqual(product, original) {
  const originalDomains = get(original, 'domain', []).map((domain) => getIdFromRef(domain.$ref));
  const updatedDomains = get(product, 'domain', []).map((domain) => getIdFromRef(domain.$ref));

  const originalBundle = get(original, 'bundle', []).map((bundle) => getIdFromRef(bundle.$ref));
  const updatedBundle = get(product, 'bundle', []).map((bundle) => getIdFromRef(bundle.$ref));

  return product.order === original.order
    && product.unitPrice === original.unitPrice
    && isEqual(product.name, original.name)
    && isEqual(product.shortName, original.shortName)
    && isEqual(originalDomains, updatedDomains)
    && isEqual(originalBundle, updatedBundle)
}

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import * as fields from './index.js';

const CriteriaField = ({
  source,
  criteria,
  onChange,
  codes,
  consultants,
  productGroups,
  edit,
  operation
}) => {
  const Field = (_.has(fields, _.get(source, 'component'))) ? _.get(fields, _.get(source, 'component')) : fields.Unsupported;

  return (
    <Field
      source={source}
      criteria={criteria}
      onChange={onChange}
      codes={codes}
      consultants={consultants}
      productGroups={productGroups}
      edit={edit}
      operation={operation}
    />
  );
}

CriteriaField.propTypes = {
  source: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
  criteria: PropTypes.object,
  onChange: PropTypes.func,
  codes: PropTypes.object,
  consultants: PropTypes.array,
  productGroups: PropTypes.array,
  edit: PropTypes.bool,
  operation: PropTypes.string
};

CriteriaField.defaultProps = {
  onChange: _.noop,
  criteria: {},
  codes: {},
  consultants: [],
  productGroups: [],
  edit: false,
  operation: 'eq'
};

export default CriteriaField;

import { createSelector } from 'reselect';

export const getBasicServices = (state) => state.basicProducts;

export const getProducts = createSelector([
  getBasicServices,
], (basicServices) => basicServices.products);
export const getBasicProducts = createSelector([
  getBasicServices,
], (basicServices) => basicServices.basicProducts);
export const getCategories = createSelector([
  getBasicServices,
], (basicServices) => basicServices.basicCategories);
export const getAdditions = createSelector([
  getBasicServices,
], (basicServices) => basicServices.basicAdditions);
export const getDocuments = createSelector([
  getBasicServices,
], (basicServices) => basicServices.basicDocuments);

export const getDisplayClasses = createSelector([
  getBasicServices,
], (basicServices) => basicServices.displayClasses);
export const getDetailGroups = createSelector([
  getBasicServices,
], (basicServices) => basicServices.detailGroups);
export const getChangeTypes = createSelector([
  getBasicServices,
], (basicServices) => basicServices.changeTypes);
export const getOutputClasses = createSelector([
  getBasicServices,
], (basicServices) => basicServices.outputClasses);
export const getCoreTypes = createSelector([
  getBasicServices,
], (basicServices) => basicServices.coreTypes);

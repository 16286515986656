import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from 'react-select';

import { operations } from '../constants';

const operationMap = {
  AcceptDecline: [operations.eq],
  AccountSelect: [operations.eq, operations.contains],
  ActivitySubStatus: [operations.eq, operations.contains],
  Authorizations: [operations.eq, operations.contains],
  Check: [operations.eq],
  CodeSelect: [operations.eq, operations.contains],
  ConsultantSelect: [operations.eq, operations.contains],
  Contracts: [operations.eq, operations.contains],
  CustomerCards: [operations.eq, operations.contains],
  DateField: [],
  Input: [operations.eq, operations.contains],
  MultiSelect: [operations.eq, operations.contains],
  NumberField: [operations.eq, operations.contains, operations.gt, operations.lt],
  OverwriteRecipient: [operations.eq, operations.contains],
  PartnerContracts: [operations.eq, operations.contains],
  SingleSelect: [operations.eq, operations.contains],
  Toggle: [operations.eq],
  CamundaAddressChangeProcesses: [operations.eq]
};

function getProssibleOperations(item) {
  let component = _.get(item, 'component');
  if (_.get(item, 'props.type') === 'number') component = 'NumberField';

  return (_.has(operationMap, component)) ? _.get(operationMap, component) : [];
}

class Operations extends React.Component {
  constructor(props) {
    super(props);

    this.onOperationChange = this.onOperationChange.bind(this);
  }

  onOperationChange(selected) {
    const { onChange } = this.props;

    return onChange((selected) ? selected.value : undefined);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { source, selected } = this.props;
    const operations = getProssibleOperations(source);

    if (operations.length === 0) return (
      <div>Feldtyp {_.get(source, 'component')} wird im Moment nicht unterstützt</div>
    );

    return(
      <React.Fragment>
        <strong>Operation</strong>

        <Select
          value={selected}
          options={operations}
          onChange={this.onOperationChange}
        />
      </React.Fragment>
    );
  }
}

Operations.propTypes = {
  source: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func
};

Operations.defaultProps = {
  selected: undefined,
  onChange: _.noop
};

export default Operations;

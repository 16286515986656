import { createSelector } from 'reselect';
import {
  getFinancingRatesForwards,
  getFinancingRatesInitial,
  getFinancingRatesState,
} from './financingRatesCommonSelectors';

export const getFinancingRatesForwardPeriods = createSelector([
  getFinancingRatesState,
], (state) => state.forwardPeriods);

export const getFinancingRatesInitialForwards = createSelector([
  getFinancingRatesInitial,
], (initialData) => initialData.forwards);

export const getFinancingRatesRemovedForwards = createSelector([
  getFinancingRatesForwards,
  getFinancingRatesInitialForwards,
], (forwards, initialForwards) => initialForwards.filter(
  (initial) => !forwards.some((forward) => forward.id === initial.id),
));

export const getFinancingRatesNewForwards = createSelector([
  getFinancingRatesForwards,
  getFinancingRatesInitialForwards,
], (forwards, initialForwards) => {
  return forwards.filter(
    (forward) => !initialForwards.some((initial) => forward.id === initial.id),
  );
});

export const getFinancingRatesChangedForwards = createSelector([
  getFinancingRatesForwards,
  getFinancingRatesInitialForwards,
], (forwards, initialForwards) => forwards.filter((forward) => {
  const initial = initialForwards.find((it) => it.id === forward.id);
  if (!initial) {
    return false;
  }
  return (
    initial.forwardRate !== forward.forwardRate ||
    initial.forwardFrom !== forward.forwardFrom ||
    initial.forwardUntil !== forward.forwardUntil
  );
}));

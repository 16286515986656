import { init } from '@sentry/browser';


export function sentryInit(dsn) {

  init({
    dsn,
    debug: false,
    enabled: true,
    release: process.env.BUILD_VERSION,
  });
}

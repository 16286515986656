import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import { getSurchargeRealestatetypes } from '../../selectors/FinancingSurchargeRealestatetype';
import { createRef } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';

export function* removeSurchargeRealestatetypesSaga(changedItems) {
  const surchargeRealestatetypes = yield select(getSurchargeRealestatetypes);
  const removed = surchargeRealestatetypes.filter(
    (initial) => !changedItems.some((changed) => initial.id === changed.id)
  );

  yield all(
    removed.map((item) => call(axios, {
      url: createRef(EndpointName.FINANCING_SURCHARGE_REALESTATETYPE, item.id),
      method: 'delete',
    }))
  );
}

import { DefaultReducer } from '@evoja-web/redaction';
import update from 'immutability-helper';

import { applyLocalFiltersAction } from '../../actions/ReplayAuditLog/ApplyLocalFilters';
import auditLogAction from '../../actions/ReplayAuditLog/AuditLog';

const {
  AUDIT_LOG_ERROR,
  AUDIT_LOG_REQUEST
} = auditLogAction;

function onAuditLogRequestOrError(state) {
  return update(state, {
    filtered: {
      data: { $set: [] },
      fulfilled: { $set: false },
      requesting: { $set: false }
    },
    progress: {
      $unset: ['localFilters']
    }
  });
}

export const reducer = DefaultReducer('filtered', applyLocalFiltersAction);
reducer.addMethod(AUDIT_LOG_REQUEST, onAuditLogRequestOrError);
reducer.addMethod(AUDIT_LOG_ERROR, onAuditLogRequestOrError);

export default reducer.get();

import { UI_NOTES_FETCH_ACTIONS } from '../../actions/UiNotesActions';

export function uiNotesFetchReducer(state, action) {
  switch (action.type) {
    case UI_NOTES_FETCH_ACTIONS.PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case UI_NOTES_FETCH_ACTIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data,
      };

    case UI_NOTES_FETCH_ACTIONS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    default:
      return state;
  }
}

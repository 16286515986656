import { call, put } from 'redux-saga/effects';
import { CORE_CONFIG_SAVE } from '../../actions/CoreConfigActions';
import { coreConfigSaveConfigsSaga } from './coreConfigSaveConfigsSaga';
import { coreConfigSaveModulesSaga } from './coreConfigSaveModulesSaga';

export function* coreConfigSaveSaga() {
  try {
    yield put(CORE_CONFIG_SAVE.pending());

    yield call(coreConfigSaveConfigsSaga);
    yield call(coreConfigSaveModulesSaga);

    yield put(CORE_CONFIG_SAVE.success());
  } catch (error) {
    yield put(CORE_CONFIG_SAVE.failure(error));
  }
}

import './SearchBlock.css';

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FormControl } from 'react-bootstrap';
import { createStructuredSelector } from 'reselect';
import { setCommonData } from '../../actions/CommonActions';
import { getCommonSearch } from '../../selectors/commonSelectors';

class SearchBlockWithState extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeSearch = this.onChangeSearch.bind(this);
  }

  render() {
    const { search, intl } = this.props;
    return (
      <div className="search-block">
        <FormControl
          type="text"
          placeholder={intl.formatMessage({ id: 'common_searchPlaceholder' })}
          value={search}
          onChange={this.onChangeSearch}
        />
        <span className="search-block__icon icon-077-search"/>
      </div>
    );
  }

  onChangeSearch(event) {
    const { setCommonData } = this.props;
    setCommonData({ key: 'search', value: event.target.value });
  }

  componentWillUnmount() {
    const { setCommonData } = this.props;
    setCommonData({ key: 'search', value: '' });
  }
}

const mapStateToProps = createStructuredSelector({
  search: getCommonSearch,
});

const mapDispatchToProps = {
  setCommonData,
};

export const SearchBlock = injectIntl(connect(mapStateToProps, mapDispatchToProps)(SearchBlockWithState));

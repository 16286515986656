import './FinancingRatesDuration.css';

import React from 'react';
import { getFormValues, submit } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DURATION_PROP_TYPES, FORWARD_PERIOD_PROP_TYPES, FORWARD_PROP_TYPES } from './FinancingRatesPropTypes';
import { FinancingRatesForwardsForm } from './FinancingRatesForwardsForm';
import { createSelector, createStructuredSelector } from 'reselect';

const formName = 'financingRatesForwardsForm';

class FinancingRatesForwardsDialogConnected extends React.Component {
  state = {
    isModalOpen: false,
  };

  constructor(props) {
    super(props);
    this.modalClose = this.modalClose.bind(this);
    this.modalOpen = this.modalOpen.bind(this);
    this.update = this.update.bind(this);
  }

  render() {
    return (
      <div>
        <Button onClick={this.modalOpen}>
          <span className="icon-048-link"/>
        </Button>
        {this.renderModal()}
      </div>
    );
  }

  renderModal() {
    const { isModalOpen } = this.state;
    const { forwards, forwardPeriods, title, currentFormValues, syncForwards } = this.props;

    return (
      <Modal show={isModalOpen} onHide={this.modalClose}>
        <Modal.Header closeButton={true}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FinancingRatesForwardsForm
            form={formName}
            initialValues={{ forwards }}
            currentValues={currentFormValues}
            forwardPeriods={forwardPeriods}
            syncForwards={syncForwards}
            onSubmit={this.update}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.modalClose}>
            <span className="icon-202-clear-circle"/>
            Schliessen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  update(formData) {
    const { duration, updateForwards } = this.props;
    updateForwards(duration, formData.forwards);
  }

  modalOpen() {
    this.setState({ isModalOpen: true });
  }

  modalClose() {
    const { submitForm } = this.props;
    submitForm(formName);
    this.setState({ isModalOpen: false });
  }
}

FinancingRatesForwardsDialogConnected.propTypes = {
  duration: DURATION_PROP_TYPES.isRequired,
  forwards: PropTypes.arrayOf(FORWARD_PROP_TYPES).isRequired,
  forwardPeriods: PropTypes.arrayOf(FORWARD_PERIOD_PROP_TYPES).isRequired,
  updateForwards: PropTypes.func.isRequired,
  syncForwards: PropTypes.func.isRequired,
};

export const getFinancingRatesForwardsFormValues = createSelector([
  getFormValues(formName)
], (formData) => formData && formData.forwards ? formData.forwards : []);
const mapStateToProps = createStructuredSelector({
  currentFormValues: getFinancingRatesForwardsFormValues,
});

const mapDispatchToProps = {
  submitForm: (form) => submit(form),
};
export const FinancingRatesForwardsDialog = connect(mapStateToProps, mapDispatchToProps)(
  FinancingRatesForwardsDialogConnected,
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import Toggle from 'react-toggle';
import { FormGroup } from 'react-bootstrap';

import { fetchTranslations, updateTranslations, exportMissingTranslations } from '../../services/Translations';
import { Button } from '../Common/Button';
import PageContainer from '../Common/PageContainer';
import AutocompleteCell from './AutocompleteCell';
import { get } from 'lodash';

async function handleCellValueChanged(event) {
  const { data, valueChanged } = event;
  // only update if anything changed
  if (!valueChanged) {
    return;
  }
  await updateTranslations(data);
}

async function onExport() {
  const csvData = await exportMissingTranslations();
  const url = URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'translations_export.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  URL.revokeObjectURL(url);
}

class Translations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        { field: 'key', filter: 'agTextColumnFilter', flex: 2 },
        { colId: 'app', headerName: 'Applikation', field: 'application.name.de', filter: 'agTextColumnFilter', flex: 1 },
        { field: 'namespace', filter: 'agTextColumnFilter', flex: 1 },
        {
          colId: 'de',
          headerName: 'Deutsch',
          filter: 'agTextColumnFilter',
          field: 'value.de',
          editable: true,
          flex: 2,
          minWidth: 300,
          cellEditor: 'agLargeTextCellEditor',
          cellEditorPopup: true,
          filterParams: {
            maxNumConditions: 1
          }
        },
        {
          colId: 'fr',
          headerName: 'Französisch',
          filter: 'agTextColumnFilter',
          field: 'value.fr',
          editable: true,
          cellEditor: AutocompleteCell,
          cellEditorParams: {
            searchByLanguage: 'de'
          },
          cellEditorPopup: true,
          flex: 2,
          filterParams: {
            maxNumConditions: 1
          },
          // Ignore enter on edit as this would prevent the underlaying select onChange from firing
          // https://issue.swisscom.ch/browse/MAP-9023
          suppressKeyboardEvent: ({ event }) => {
            if (get(event, 'type') === 'keydown' && get(event, 'keyCode') === 13) {
              return true;
            }

            return false;
          }
        },
      ],
      rowData: [],
      paginationPageSize: 20,
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
      overlayNoRowsTemplate: '<span>No data to display</span>',
      searchTerm: undefined,
      showMissingTranslations: false
    };

    this.onGridReady = this.onGridReady.bind(this);
    this.onToggleMissingTranslations = this.onToggleMissingTranslations.bind(this);
  }

  onGridReady(params) {
    const { paginationPageSize } = this.state;

    this.gridApi = params.api;

    const datasource = {
      getRows: async (params) => {
        const { startRow } = params;
        const limit = paginationPageSize;
        const offset = startRow;

        try {
          const data = await fetchTranslations(params.filterModel, limit, offset);
          const lastRow = data.length < limit ? offset + data.length : -1;

          params.successCallback(data, lastRow);
        } catch (error) {
          params.failCallback();
        }
      }
    };

    params.api.setGridOption('datasource', datasource);
  }

  async onToggleMissingTranslations() {
    const { showMissingTranslations } = this.state;
    const filterComponent = await this.gridApi.getColumnFilterInstance('fr');

    if (showMissingTranslations) {
      filterComponent.setModel({});
    } else {
      filterComponent.setModel({ filterType: 'text', type: 'blank' });
    }

    this.setState({ showMissingTranslations: !showMissingTranslations });
    this.gridApi.onFilterChanged();
  }

  render() {
    const {
      columnDefs,
      overlayLoadingTemplate,
      overlayNoRowsTemplate,
      paginationPageSize,
      showMissingTranslations
    } = this.state;

    const { translations } = this.props;

    return (
      <PageContainer title="Traduction" fullwidth>
        <div className="ag-theme-quartz" style={{ height: '75vh', width: '100%' }}>
          <FormGroup style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <Toggle
              checked={showMissingTranslations}
              onChange={this.onToggleMissingTranslations}
            />
            <strong style={{ marginLeft: 5 }}>Fehlende Übersetzungen anzeigen</strong>
          </FormGroup>

          <AgGridReact
            defaultColDef={{ flex: 1, sortable: false }}
            columnDefs={columnDefs}
            rowData={translations}
            pagination
            paginationPageSize={paginationPageSize}
            overlayLoadingTemplate={overlayLoadingTemplate}
            overlayNoRowsTemplate={overlayNoRowsTemplate}
            onGridReady={this.onGridReady}
            rowSelection="multiple"
            rowModelType="infinite"
            infiniteInitialRowCount={1}
            paginationPageSizeSelector={paginationPageSize}
            cacheBlockSize={paginationPageSize} // Match this with the number of rows you fetch per API call
            onCellEditingStopped={handleCellValueChanged}
            cellValueChanged={this.handleCellValueChanged}
          />

          <Button onClick={onExport} style={{ marginTop: 10 }}>Export</Button>
        </div>
      </PageContainer>
    );
  }
}

Translations.propTypes = {
  translations: PropTypes.array
};

Translations.defaultProps = {
  translations: []
};

export default Translations;

import React from 'react';

export default function Spinner({ text = 'loading', screen = 'lg' }) {
  return (
    <div className="sc-spinner-container">
      <span className={`sc-spinner ${screen ? 'sc-spinner-' + screen : ''}`} />
      {text &&
      <h5 className="sc-spinner-text">
        {text}
        <span className="sc-spinner-dots">...</span>
      </h5>
      }
    </div>
  );
}

import React from 'react';
import _ from 'lodash';
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';

export default class IdInput extends React.Component {
  constructor() {
    super();
    this.state = {
      touched: false,
      dirty: false,
    };
  }

  /**
   * Call parent onValueChange and set dirty to true
   *
   * @param  {Event} ev Event
   *
   * @return {NumberInput} this This instance
   */
  onValueChange(ev) {
    const { onValueChange } = this.props;
    this.setState({ dirty: true });
    onValueChange('id', ev);

    return this;
  }

  /**
   * Set this field to touched
   *
   * @param {Boolean} [touched=true] Touched
   *
   * @return {IdInput} this This instance
   */
  setTouched(touched = true) {
    this.setState({ touched });

    return this;
  }

  /**
   * Render the delete icon inside the ID input
   *
   * @return {ReactElement} markup
   */
  renderDeleteIcon() {
    const style = {
      position: 'absolute',
      right: '5px',
      top: 0,
      bottom: 0,
      height: '14px',
      margin: 'auto',
      fontSize: '12px',
      fontWeight: 'lighter',
      cursor: 'pointer',
      color: '#ccc',
    }

    return (<span className="icon-022-close" style={style} onClick={ () => this.onValueChange({ target: { value: '' } }) }/>);
  }

  /**
   * Render the input
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      value, disabled, validation, label='ID', placeholder='ID',
    } = this.props;

    const {
      touched, dirty,
    } = this.state;

    let validationState = null;
    if (touched || dirty) validationState = _.isEmpty(validation) ? 'success' : 'error';

    return (
      <FormGroup validationState={validationState}>
        <ControlLabel>{label}</ControlLabel>
        <div className="btn-group" style={{ width: '100%' }}>
          <FormControl
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={this.onValueChange.bind(this)}
            onFocus={this.setTouched.bind(this)}
            disabled={disabled}
          />
          { disabled ? '' : this.renderDeleteIcon() }
        </div>
        { ((touched || dirty) && !_.isEmpty(validation)) ? <HelpBlock style={{ whiteSpace: 'pre-wrap' }}>{validation.message}</HelpBlock> : '' }
      </FormGroup>
    );
  }
}

import axios from 'axios';
import { call } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';

export function* loadActionsByLoads(loads) {
  const loadIds = loads.map((it) => `string:${it.id}`);
  if (!loadIds.length) {
    return [];
  }

  const { data: actions } = yield call(axios, {
    url: `${EndpointName.LOADER_ACTION}/?in(loadId,(${loadIds.join(',')}))&limit(99999)`,
  });

  return actions;
}

import { DataKeyReducer } from '@evoja-web/redaction';
import update from 'immutability-helper';

import action, { replayRecordAction } from '../../actions/ReplayAuditLog/ReplayRecord';

const { REPLAY_RECORD_REJECTED } = action;

function onReplayRecordError(state, action) {
  const { dataKey, error, record } = action;

  return update(state, {
    replayed: {
      [dataKey]: {
        requesting: { $set: false },
        fulfilled: { $set: false },
        error: { $set: error },
        data: { $set: record }
      }
    }
  });
}

export const reducer = DataKeyReducer('replayed', replayRecordAction);
reducer.replaceMethod(REPLAY_RECORD_REJECTED, onReplayRecordError);

export default reducer.get();

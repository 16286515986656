import IsDivisibleBy from '../configuration/IsDivisibleBy';

export default {
  key: 'isDivisibleBy',
  label: 'Ist teilbar durch' ,
  validation: 'isDivisibleBy',
  availableFor: ['Input'],
  ConfigurationForm: IsDivisibleBy,
  get: function(params) {
    return { isDivisibleBy: params };
  }
}

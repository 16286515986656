import './ApiClientResponse.css';

import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { Table, TableCell, TableRow } from '../Common/Table';
import { HTTP_CODES } from '../../constants/HttpCodes';
import { ApiClientResponseBody } from './ApiClientResponseBody';

export class ApiClientResponse extends React.PureComponent {
  render() {
    const { result: { request, response, duration } } = this.props;
    const statusDescription = HTTP_CODES[response.status]
      ? (
        <span className="api-client-response__response-code"
              title={HTTP_CODES[response.status].description}>
          {HTTP_CODES[response.status].title}
        </span>
      )
      : null;

    return (
      <Table className="api-client-response">
        <TableRow>
          <TableCell className="api-client-response__label">Method</TableCell>
          <TableCell className="api-client-response__value">{request.method.toUpperCase()}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell className="api-client-response__label">Request</TableCell>
          <TableCell className="api-client-response__value">
            {request.service || request.customUrl}{request.params ? request.params : ''}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className="api-client-response__label">Response</TableCell>
          <TableCell className="api-client-response__value">
            {response.status} {statusDescription}
            {duration ? ` (${duration} ms)` : null}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className="api-client-response__label">Headers</TableCell>
          <TableCell className="api-client-response__value">
            <ReactJson
              src={response.headers}
              name={false}
              displayDataTypes={false}
              collapsed={2}
              indentWidth={2}
              iconStyle="triangle"
              onAdd={false}
              onEdit={false}
              onDelete={false}
              enableClipboard={false}
            />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className="api-client-response__label">Body</TableCell>
          <TableCell className="api-client-response__value">
            <ApiClientResponseBody response={response}/>
          </TableCell>
        </TableRow>
      </Table>
    );
  }
}

ApiClientResponse.propTypes = {
  result: PropTypes.shape({
    request: PropTypes.object.isRequired,
    response: PropTypes.object.isRequired,
    duration: PropTypes.number,
  }).isRequired,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { chain, get, noop, isNil, isEmpty } from 'lodash';

import Connection from './Connection';

const WorkflowTreeConnections = React.memo(function WorkflowTreeConnections({
  node,
  onConnectionChange,
  onConnectionRemove,
  position,
  validations
}) {
  const [isUpdating, setIsUpdating] = useState(false);

  // Add a small delay to make sure the nodes are rendered before drawing the connections
  useEffect(() => {
    setIsUpdating(true);

    setTimeout(() => {
      setIsUpdating(false);
    }, 20);
  }, [node, position, setIsUpdating]);

  if (isUpdating) {
    return null;
  }

  const children = chain(node)
    .get('connections', [])
    .map((connection, index) => {
      if (isNil(connection.source) || isNil(connection.target)) {
        return undefined;
      }

      return (
        <Connection
          key={`connection-${connection.source}-${connection.target}`}
          color={isEmpty(get(validations, index)) ? 'green' : 'red'}
          connection={connection}
          onChange={onConnectionChange}
          onRemove={onConnectionRemove}
        />
      );
    })
    .compact()
    .value();

  return (
    <div className="workflow-tree-connections">
      {children}
    </div>
  );
});

WorkflowTreeConnections.propTypes = {
  node: PropTypes.object.isRequired,
  onConnectionChange: PropTypes.func,
  onConnectionRemove: PropTypes.func,
  position: PropTypes.object,
  validations: PropTypes.object
};

WorkflowTreeConnections.defaultProps = {
  onConnectionChange: noop,
  onConnectionRemove: noop,
  position: {},
  validations: {}
};

export default WorkflowTreeConnections;

import axios from 'axios';
import { all, call, put } from 'redux-saga/effects';
import {

  NOTICE_IMPORT_EXAMPLES,
} from '../../actions/NoticeImportActions';
import { EndpointName } from '../../constants/EndpointName';
import { NOTICE_EXAMPLE_TEMPLATE, NOTICE_EXAMPLE_DOCUMENT } from '../../constants/NoticeImport';


export function* noticeImportExamplesSaga(action) {
  try {
    yield put(NOTICE_IMPORT_EXAMPLES.pending());

    const examplesMeta = yield call(axios, {
      url: `${EndpointName.FILE}/?in(id,(${NOTICE_EXAMPLE_TEMPLATE},${NOTICE_EXAMPLE_DOCUMENT}))`,
      method: 'get',
    });

    const examplesBlob = yield all(examplesMeta.data.map(file => {
      const promise = new Promise((resolve, reject) => {
        axios({
          url: `${EndpointName.FILE}/${file.id}`,
          method: 'get',
          timeout: 50000,
          responseType: 'blob'
        }).then(blob => {
          resolve({ ...file, blob });
        });
      });
      return promise;

    }));

    const examplesByFileId = {};
    examplesBlob.forEach(file => {
      examplesByFileId[file.id] = file;
    });

    yield put(NOTICE_IMPORT_EXAMPLES.success(examplesByFileId));
  } catch (error) {
    yield put(NOTICE_IMPORT_EXAMPLES.failure(error));
  }
}


import React from 'react';
import PropTypes from 'prop-types';
import { chain, get, noop, isNil, map } from 'lodash';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Select } from '@evoja-web/react-form';
import update from 'immutability-helper';

class ActionConfigurationServiceCallProperties extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mappingKey: undefined
    };

    this.onAdd = this.onAdd.bind(this);
  }

  /**
   * Add the currently selected property to the mapping
   *
   * @return  void
   */
  onAdd() {
    const { mappingKey } = this.state;
    const {
      action,
      onJmesParamChange
    } = this.props;

    const current = get(action, 'jmesParams.mapping', {});
    const updated = update(current, { [mappingKey]: { $set: undefined } });

    onJmesParamChange('mapping', updated);
    this.setState({ mappingKey: undefined });
  }

  /**
   * Get the properties for the selected method from spec
   *
   * @return  {Object} properties Properties for the given service / method combination
   */
  getPropertiesByMethod() {
    const {
      action,
      schema
    } = this.props;

    const url = get(action, 'url');
    // Show component of "put" in case of patch
    const method = get(action, 'method') === 'patch'
      ? 'put'
      : get(action, 'method');

    // Check where to get the component from
    const path = ['get', 'post'].includes(method)
      ? `data.json.paths.${url}.${method}.requestBody.content.application/json.schema.$ref`
      : `data.json.paths.${url}{id}.${method}.requestBody.content.application/json.schema.$ref`;

    // Get the component from configured requestBody
    const component = chain(schema)
      .get(path)
      .split('/')
      .last()
      .value();

    // Get properties for the given component
    const properties = get(schema, `data.json.components.schemas.${component}.properties`, {});

    return properties;
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { mappingKey } = this.state;
    const {
      action,
      validations
    } = this.props;

    const options = map(this.getPropertiesByMethod(), (prop, key) => ({ value: key, label: key }));

    return (
      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.Action.Configuration.ServiceCall.AddMapping" />
        </ControlLabel>

        <div
          className="d-flex align-items-center justify-content-between"
          style={{ width: '100%' }}
        >
          <div style={{ width: '100%' }}>
            <Select
              id="mappingKey"
              disabled={isNil(get(action, 'method'))}
              options={options}
              onChange={(key, value) => this.setState({ mappingKey: value })}
              value={mappingKey}
            />
          </div>

          <div
            className="mdi mdi-plus-box-outline"
            onClick={isNil(mappingKey) ? noop : this.onAdd}
            style={{ paddingLeft: '0.5em', fontSize: '1.7em' }}
          />
        </div>
      </FormGroup>
    );
  }
}

ActionConfigurationServiceCallProperties.propTypes = {
  action: PropTypes.object.isRequired,
  onJmesParamChange: PropTypes.func,
  schema: PropTypes.object,
  validations: {}
};

ActionConfigurationServiceCallProperties.defaultProps = {
  onJmesParamChange: noop,
  schema: undefined,
  validations: {}
};

export default ActionConfigurationServiceCallProperties;

import React from 'react';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { ImagePreview } from './FilePreview';
import { mapNewDocument } from '../../services/Promotions';

export default class ImageDropzone extends React.Component {
  constructor() {
    super();
    this.state = {
      touched: false,
      dirty: false,
    };
    this.onImageDrop = this.onImageDrop.bind(this);
  }

  onValueChange(value) {
    const { id, onImageDrop } = this.props;
    this.setState({ dirty: true });
    onImageDrop(id, value);
  }

  /**
   * Set this field to touched
   *
   * @param {Boolean} [touched=true] Touched
   */
  setTouched(touched = true) {
    this.setState({ touched });
  }

  /**
   * Gets called when an image was dropped into one of the drop zones.
   * Check whether the image size is like expected or not.
   *
   * @param  {Array}   files  Array of files
   *
   * @return {Promise}
   */
  async onImageDrop(files) {
    const file = files[0];
    const { language } = this.props;

    if (!await this.isSizeValid(file)) {
      return;
    }

    this.onValueChange(mapNewDocument(file, language));
  }

  async isSizeValid(file) {
    const { size } = this.props;
    if (!size) {
      return Promise.resolve(true);
    }
    const image = document.createElement('img');
    image.src = file.preview;

    return new Promise((resolve) => {
      image.onload = (event) => {
        const { width, height } = event.target;
        const isSizeValid = width === size.width && height === size.height;
        if (!isSizeValid) {
          toast.error(
            `Das Bild entsprich nicht den Vorgaben! Breite: ${size.width}px, Höhe: ${size.height}px`,
            { position: toast.POSITION.TOP_CENTER }
          );
        }
        resolve(isSizeValid);
      }
    });
  }

  renderImage() {
    const { value } = this.props;
    return <ImagePreview file={value} thumbnail={true}/>;
  }

  /**
   * If no value is set, render the dropzone
   */
  renderDropzone() {
    return(
      <Dropzone
        accept="image/*"
        onDrop={ this.onImageDrop }>
        <h1 className="text-center">
          <i className="icon-088-upload-cloud"/>
        </h1>
        <p className="text-center">
          Neue Dateien hier her ziehen oder klicken um sie auszuwählen
        </p>
      </Dropzone>
    );
  }

  render() {
    const { id, value, label='', onImageRemove } = this.props;
    const imageRemoveButton = (
      <span className="pull-right" onClick={() => onImageRemove(id)}>
        <i className="icon-008-bin"/>
      </span>
    );

    return (
      <FormGroup>
        <ControlLabel style={ { width: '100%' } }>
          <div>
          { label }
          { value.id ? imageRemoveButton : null }
        </div>
        </ControlLabel>
        { value.id ? this.renderImage() : this.renderDropzone() }
      </FormGroup>
    );
  }
}

import { createSelector } from 'reselect';
import { getLanguage } from '../commonSelectors';

export const getAnalyticsLogsState = (state) => state.analyticsLogs;
export const getAnalyticsLogsError = createSelector([getAnalyticsLogsState], (state) => state.error);
export const getAnalyticsLogsLoading = createSelector([getAnalyticsLogsState], (state) => state.loading);
export const getAnalyticsLogsLoadingSearch = createSelector([getAnalyticsLogsState], (state) => state.loadingSearch);
export const getAnalyticsLogsLoadingDetails = createSelector([getAnalyticsLogsState], (state) => state.loadingDetails);
export const getAnalyticsLogsLoadingComparison = createSelector([getAnalyticsLogsState], (state) => state.loadingComparison);
export const getAnalyticsLogsSearchResults = createSelector([getAnalyticsLogsState], (state) => state.searchResults);
export const getAnalyticsLogsConsultants = createSelector([getAnalyticsLogsState], (state) => state.consultants);
export const getAnalyticsLogsEndpoints = createSelector([getAnalyticsLogsState], (state) => state.endpoints);
export const getAnalyticsLogsDates = createSelector([
  getAnalyticsLogsState
], (state) => ({
  startDate: state.startDate,
  endDate: state.endDate,
}));
export const getAnalyticsLogsTextBlock = createSelector([getAnalyticsLogsState], (state) => state.textBlock);
export const getAnalyticsLogsText = createSelector([
  getAnalyticsLogsTextBlock,
  getLanguage,
  ], (textBlock, language) =>
    textBlock && textBlock.text[language]
      ? textBlock.text[language]
      : '',
);

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormGroup, FormControl, ControlLabel, Tabs, Tab, Button } from 'react-bootstrap';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onChange(ev) {
    const { data } = this.state;
    let { id, value } = ev.target;

    if (id === 'id') value = _.camelCase(_.deburr(value));

    this.setState({
      data: _.set({ ...data }, id, value)
    });
  }

  onSubmit() {
    const { data } = this.state;
    const { isNew, onSubmit } = this.props;

    const code = {
      ...data,
      id: (isNew) ? `targetCustomerGroup-${data.id}` : data.id,
      number: data.id,
      group: 'targetCustomerGroup',
      recordOrigin: 'map',
      text: {
        ...data.text,
        en: _.get(data, 'text.en', _.get(data, 'text.de'))
      },
      textShort: {
        ..._.get(data, 'textShort', {}),
        en: _.get(data, 'textShort.en', _.get(data, 'textShort.de', ''))
      }
    };

    if (data.order) code.order = parseInt(data.order, 10);

    this.setState({ data: {} });

    onSubmit(code);
  }

  onCancel() {
    const { onCancel } = this.props;
    this.setState({ data: {} });

    onCancel();
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { isNew } = this.props;

    return (
      <form>
        <FormGroup>
          <ControlLabel>ID</ControlLabel>
          <FormControl
            id="id"
            type="text"
            value={_.get(this, 'state.data.id', '')}
            placeholder="Code ID"
            onChange={this.onChange}
            disabled={!isNew}
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>Reihenfolge</ControlLabel>
          <FormControl
            id="order"
            type="number"
            value={_.get(this, 'state.data.order', 0)}
            placeholder="Reihenfolge des Codes"
            onChange={this.onChange}
          />
        </FormGroup>

        <Tabs defaultActiveKey="de" id="text">
          <Tab eventKey="de" title="Deutsch">
            <FormGroup>
              <ControlLabel>Text</ControlLabel>
              <FormControl
                id="text.de"
                type="text"
                value={_.get(this, 'state.data.text.de', '')}
                placeholder="Bezeichnung Deutsch"
                onChange={this.onChange}
              />
            </FormGroup>

            <FormGroup>
              <ControlLabel>Text (kurz)</ControlLabel>
              <FormControl
                id="textShort.de"
                type="text"
                value={_.get(this, 'state.data.textShort.de', '')}
                placeholder="Kurzbezeichnung Deutsch "
                onChange={this.onChange}
              />
            </FormGroup>
          </Tab>

          <Tab eventKey="fr" title="Französisch">
            <FormGroup>
              <ControlLabel>Text</ControlLabel>
              <FormControl
                id="text.fr"
                type="text"
                value={_.get(this, 'state.data.text.fr', '')}
                placeholder="Bezeichnung Französisch"
                onChange={this.onChange}
              />
            </FormGroup>

            <FormGroup>
              <ControlLabel>Text (kurz)</ControlLabel>
              <FormControl
                id="textShort.fr"
                type="text"
                value={_.get(this, 'state.data.textShort.fr', '')}
                placeholder="Kurzbezeichnung Französisch"
                onChange={this.onChange}
              />
            </FormGroup>
          </Tab>

          <Tab eventKey="en" title="Englisch">
            <FormGroup>
              <ControlLabel>Text</ControlLabel>
              <FormControl
                id="text.en"
                type="text"
                value={_.get(this, 'state.data.text.en', '')}
                placeholder="Bezeichnung Englisch"
                onChange={this.onChange}
              />
            </FormGroup>

            <FormGroup>
              <ControlLabel>Text (kurz)</ControlLabel>
              <FormControl
                id="textShort.en"
                type="text"
                value={_.get(this, 'state.data.textShort.en', '')}
                placeholder="Kurzbezeichnung Englisch"
                onChange={this.onChange}
              />
            </FormGroup>
          </Tab>
        </Tabs>

        <div className="pull-right">
          <Button
            bsStyle="primary"
            onClick={this.onSubmit}
          >
            Speichern
          </Button>
          <span style={{ paddingRight: '10px' }} />
          <Button onClick={this.onCancel}>Abbrechen</Button>
        </div>

        <div style={{ paddingBottom: '20px' }}></div>

      </form>
    );
  }
}

Form.propTypes = {
  language: PropTypes.string,
  data: PropTypes.object,
  isNew: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  language: 'de',
  data: {},
  isNew: false,
  onSubmit: _.noop,
  onCancel: _.noop
};

export default Form;

import './BulkLoadsResultsItem.css';

import React from 'react';
import { TableCell, TableRow } from '../Common/Table';
import { FormattedDate } from '../Common/FormattedDate';
import { ExpandContent } from '../Common/ExpandContent';
import { classList } from '../../utils/classList';
import { BULK_LOAD_PROP_TYPES_RESULT_ITEM } from './BulkLoadsPropTypes';
import { BulkLoadsStateIcon } from './BulkLoadsStateIcon';
import { BulkLoadsQuality } from './BulkLoadsQuality';
import { BulkLoadsResultsTime } from './BulkLoadsResultsTime';
import { BulkLoadsResultsDetails } from './BulkLoadsResultsDetails';

export class BulkLoadsResultsItem extends React.PureComponent {
  state = {
    expanded: false,
  };

  render() {
    const { item } = this.props;
    const { expanded } = this.state;

    const iconClasses = classList({
      'icon-015-chevron-right': true,
      'bulk-loads-results-item__icon': true,
      'bulk-loads-results-item__icon--expanded': expanded,
    });
    const rowClasses = classList({
      'bulk-loads-results-item': true,
      'bulk-loads-results-item--expanded': expanded,
    });

    return (
      <React.Fragment>
        <TableRow className={rowClasses}>
          <TableCell className="bulk-loads-results-item__state">
            <BulkLoadsStateIcon state={item.state}/>
          </TableCell>
          <TableCell className="bulk-loads-results-item__open">
            {item.open ? <FormattedDate value={item.open} format="DD.MM.YYYY HH:mm:ss"/> : '-'}
          </TableCell>
          <TableCell className="bulk-loads-results-item__done">
            {item.done ? <FormattedDate value={item.done} format="DD.MM.YYYY HH:mm:ss"/> : '-'}
          </TableCell>
          <TableCell className="bulk-loads-results-item__count">
            {item.count > 0 ? item.count : '-'}
          </TableCell>
          <TableCell className="bulk-loads-results-item__time">
            {!item.done && <BulkLoadsResultsTime seconds={item.seconds}/>}
            {this.renderTimeToCompletionPercent(item)}
          </TableCell>
          <TableCell className="bulk-loads-results-item__quality">
            <BulkLoadsQuality quality={item.quality} />
          </TableCell>
          <TableCell onClick={this.toggleExpanded}>
            <div className={iconClasses}/>
          </TableCell>
        </TableRow>

        <TableRow className="bulk-loads-results-item__details">
          <TableCell colSpan={8}>
            <ExpandContent expanded={expanded} className="bulk-loads-results-item__details-content">
              {this.renderDetails()}
            </ExpandContent>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  renderDetails() {
    const { item } = this.props;
    return <BulkLoadsResultsDetails item={item}/>;
  }

  renderTimeToCompletionPercent(item) {
    if (item.percent === undefined) {
      return null;
    }

    if (item.percent === 100 && item.working && item.done) {
      const seconds = item.done.diff(item.working, 'seconds');
      return (
        <span><BulkLoadsResultsTime seconds={seconds}/> elapsed</span>
      );
    }

    return (
      <span> ({item.percent}%)</span>
    );
  }

  toggleExpanded = () => this.setState((prev) => ({ expanded: !prev.expanded }));
}

BulkLoadsResultsItem.propTypes = {
  item: BULK_LOAD_PROP_TYPES_RESULT_ITEM,
};

import { Actions } from '@evoja-web/redaction';

import { configAction as workflowTreeConfigAction } from './Workflow/Config';
import { editNodeFormAction as workflowTreeEditNodeFormAction } from './Workflow/EditNodeForm';
import { editConnectionFormAction as workflowTreeEditConnectionFormAction } from './Workflow/EditConnectionForm';

export const actions = Actions([
  workflowTreeConfigAction,
  workflowTreeEditNodeFormAction,
  workflowTreeEditConnectionFormAction
]);

export default actions.get();

export function validateEmailSubscriptionsEditForm(form) {
  return {
    email: !form.email || !/^\S+@\S+$/.test(form.email),
    firstName: !form.firstName,
    lastName: !form.lastName,
    gender: !form.gender,
    language: !form.language,
    subscriptionState: !form.subscriptionState,
    subscriptionType: !form.subscriptionType,
  };
}

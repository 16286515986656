import React from 'react';
import { FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap';
import SimpleSelect from 'react-select';
import 'react-select/dist/react-select.css';
import _ from 'lodash';

export default class Select extends React.Component {
  constructor() {
    super();
    this.state = {
      touched: false,
      dirty: false
    };
  }

  /**
   * Call parent onValueChange and set dirty to true
   *
   * @param  {Object} item Selected item
   *
   * @return {Select} this This instance
   */
  onValueChange(item) {
    const { id, onValueChange } = this.props;
    this.setState({ dirty: true });

    let value = item;
    if (!_.isUndefined(item) && item !== null) value = item.value;
    onValueChange(id, value);

    return this;
  }

  /**
   * Set this field to touched
   *
   * @param {Boolean} [touched=true] Touched
   *
   * @return {Select} this This instance
   */
  setTouched(a, b,c,touched=true) {
    this.setState({ touched });

    return this;
  }

  /**
   * Render the input
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      value, options, placeholder, label, validation, allowEmpty = true,
    } = this.props;
    const { touched, dirty } = this.state;

    let validationState = null;
    if (touched || dirty) validationState = _.isEmpty(validation) ? 'success' : 'error';

    return(
      <FormGroup validationState={ validationState}>
        <ControlLabel>{label}</ControlLabel>
        <SimpleSelect
          placeholder={placeholder}
          defaultValue={undefined}
          value={value}
          clearable={!allowEmpty}
          onChange={this.onValueChange.bind(this)}
          onFocus={this.setTouched.bind(this)}
          options={options}>
        </SimpleSelect>
        { ((touched || dirty) && !_.isEmpty(validation)) ? <HelpBlock>{validation.message}</HelpBlock> : '' }
      </FormGroup>
    )
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { chain, get, noop, isNil } from 'lodash';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Select, ValidationResult } from '@evoja-web/react-form';

const ActionConfigurationServiceCallMethod = React.memo(function ActionConfigurationServiceCallMethod({
  action,
  onChange,
  schema,
  validations
}) {
  const url = get(action, 'url');
  const options = chain([
    ...Object.keys(get(schema, `data.json.paths.${url}`, {})),
    ...Object.keys(get(schema, `data.json.paths.${url}{id}`, {}))
  ])
    .uniq()
    .filter((m) => ['post', 'put'].includes(m))
    .map((m) => ({
      value: m,
      label: m.toUpperCase()
    }))
    .value();

  return (
    <FormGroup>
      <ControlLabel>
        <FormattedMessage id="Workguide.Action.Configuration.ServiceCall.Method" />
      </ControlLabel>

      <Select
        id="method"
        disabled={isNil(schema)}
        options={options}
        onChange={onChange}
        value={get(action, 'method')}
      />

      <ValidationResult
        show
        validations={get(validations, 'method')}
      />
    </FormGroup>
  );
});

ActionConfigurationServiceCallMethod.propTypes = {
  action: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  schema: PropTypes.object,
  validations: PropTypes.object
};

ActionConfigurationServiceCallMethod.defaultProps = {
  onChange: noop,
  schema: undefined,
  validations: {}
};

export default ActionConfigurationServiceCallMethod;

import './FinancingRates.css';

import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { getIdFromRef, handleGravitonError } from '../../utils';
import EditContainer from '../EditContainer';
import { Col, Grid, Row } from 'react-bootstrap';
import {
  BASE_RATE_PROP_TYPES,
  DURATION_PROP_TYPES,
  FORWARD_PERIOD_PROP_TYPES,
  FORWARD_PROP_TYPES,
  REFI_RATE_PROP_TYPES,
} from './FinancingRatesPropTypes';
import { FinancingRatesDuration } from './FinancingRatesDuration';

export class FinancingRates extends React.Component {
  constructor(props) {
    super(props);
    props.load();
  }

  render() {
    const { loading, error, save } = this.props;
    if (error) {
      toast.error(handleGravitonError(error));
    }

    return (
      <EditContainer requesting={loading}
                     title="Laufzeiten und Zinssätze"
                     body={this.renderContent()}
                     saveData={save}
      />
    );
  }

  renderContent() {
    const { durations, updateBaseRate, updateRefiRate, forwardPeriods, updateForwards, syncForwards } = this.props;
    return (
      <Grid className="financing-rates">
        <Row className="financing-rates__header">
          <Col sm={2}>ID</Col>
          <Col sm={1}>Produkttyp</Col>
          <Col sm={4}>Bezeichnung</Col>
          <Col sm={2}>Basissatz:</Col>
          <Col sm={2}>Refinanzierungssatz</Col>
          <Col sm={1}/>
        </Row>
        <hr/>
        {durations.map((duration) => (
          <FinancingRatesDuration
            key={duration.id}
            duration={duration}
            refiRate={this.getRefiRate(duration)}
            baseRate={this.getBaseRate(duration)}
            forwards={this.getForwards(duration)}
            forwardPeriods={forwardPeriods}
            updateBaseRate={updateBaseRate}
            updateRefiRate={updateRefiRate}
            updateForwards={updateForwards}
            syncForwards={syncForwards}
          />
        ))}
      </Grid>
    );
  }

  getRefiRate(duration) {
    const { refiRates } = this.props;
    const refiRateId = duration.refiRate
      ? getIdFromRef(duration.refiRate.$ref)
      : undefined;
    return refiRates.find((it) => refiRateId === it.id);
  }

  getBaseRate(duration) {
    const { baseRates } = this.props;
    return baseRates.find((it) => it.duration && getIdFromRef(it.duration.$ref) === duration.id);
  }

  getForwards(duration) {
    const { forwards } = this.props;
    return forwards
      .filter((it) => it.duration && getIdFromRef(it.duration.$ref) === duration.id)
      .sort((a, b) => a.forwardFrom - b.forwardFrom);
  }
}

FinancingRates.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  durations: PropTypes.arrayOf(DURATION_PROP_TYPES).isRequired,
  baseRates: PropTypes.arrayOf(BASE_RATE_PROP_TYPES).isRequired,
  refiRates: PropTypes.arrayOf(REFI_RATE_PROP_TYPES).isRequired,
  forwards: PropTypes.arrayOf(FORWARD_PROP_TYPES).isRequired,
  forwardPeriods: PropTypes.arrayOf(FORWARD_PERIOD_PROP_TYPES).isRequired,
  updateBaseRate: PropTypes.func.isRequired,
  updateRefiRate: PropTypes.func.isRequired,
  updateForwards: PropTypes.func.isRequired,
  syncForwards: PropTypes.func.isRequired,
  load: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

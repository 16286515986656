import axios from 'axios';
import { all, call, put, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { EXTERNAL_LINKS_SAVE } from '../../actions/ExternalLinkActions';
import { createRef, getIdFromRef } from '../../utils';
import { getExternalLinks, getExternalLinksOriginal } from '../../selectors/ExternalLinks/externalLinksCommonSelectors';
import { get } from 'lodash';

export function* externalLinksSaveSagas() {
  try {
    yield put(EXTERNAL_LINKS_SAVE.pending());

    const externalLinks = yield select(getExternalLinks);
    const originalLinks = yield select(getExternalLinksOriginal);

    yield call(removeLinks, externalLinks, originalLinks);
    yield call(saveChangedLinks, externalLinks, originalLinks);

    yield put(EXTERNAL_LINKS_SAVE.success());
  } catch (error) {
    yield put(EXTERNAL_LINKS_SAVE.failure(error));
  }
}

function* removeLinks(externalLinks, originalLinks) {
  const removedLinks = originalLinks.filter((originalLink) =>
    !externalLinks.some((link) => link.id === originalLink.id));
  yield all(
    removedLinks.map((link) =>
      call(axios.delete, createRef(EndpointName.CORE_EXTERNAL_LINK, link.id)),
    ),
  );
}

function* saveChangedLinks(externalLinks, originalLinks) {
  yield all(
    externalLinks
      .filter((link) => {        
        const initial = originalLinks.find((it) => it.id === link.id);

        return !initial || isLinkChanged(link, initial);
      })
      .map((link) =>
        call(axios.put, createRef(EndpointName.CORE_EXTERNAL_LINK, link.id), link),
      ),
  );
}

function isLinkChanged(link, initial) {
 
  return (
    link.url !== initial.url ||
    link.order !== initial.order ||
    link.name.de !== initial.name.de ||
    link.name.en !== initial.name.en ||
    link.name.fr !== initial.name.fr ||
    link.translatedUrl.de !== initial.translatedUrl.de ||
    link.translatedUrl.en !== initial.translatedUrl.en ||
    link.translatedUrl.fr !== initial.translatedUrl.fr ||
    isCategoriesChanged(link.categories, initial.categories) ||
    isPermissionChanged(get(link, "permissions", []), get(initial, "permissions", [])) ||
    link.importance !== initial.importance 
  );
}

function isCategoriesChanged(categories, original) {
  if (categories.length !== original.length) {
    return true;
  }

  const originalIds = original.map((it) => getIdFromRef(it.$ref)).sort();
  return categories
    .map((it) => getIdFromRef(it.$ref)).sort()
    .some((value, index) => value !== originalIds[index]);
}

function isPermissionChanged(permission, original) {
  if (permission.length !== original.length) {
    return true;
  }

  return permission.some((value, index) => value !== original[index]);
}


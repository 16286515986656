import './ApiClientResponseBody.css';

import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'lodash';

export class ApiClientBlobContent extends React.PureComponent {
  state = {
    isLoading: true,
  };

  constructor(props) {
    super(props);
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsText(props.blob);
    })
      .then((content) => this.setState({ content, isLoading: false }))
      .catch((error) => this.setState({ error, isLoading: false }));
  }

  render() {
    const { content, isLoading, error } = this.state;
    const { processContent = identity } = this.props;
    return isLoading
      ? null
      : error
        ? error.toString()
        : processContent(content);
  }
}

ApiClientBlobContent.propTypes = {
  blob: PropTypes.object.isRequired,
  processContent: PropTypes.func,
};

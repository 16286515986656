import { createSelector } from 'reselect';
import { getLanguage } from '../commonSelectors';
import { getBulkLoadsTextBlocks } from './bulkLoadsCommonSelectors';
import { BULK_LOADS_TEXT_BLOCKS_MAP } from '../../constants/BulkLoadsConstants';

export const getBulkLoadsTexts = createSelector([
  getBulkLoadsTextBlocks,
  getLanguage,
], (textBlocks, language) => {
  return Object.keys(BULK_LOADS_TEXT_BLOCKS_MAP).reduce((result, key) => {
    const textBlock = textBlocks.find((it) => it.id === BULK_LOADS_TEXT_BLOCKS_MAP[key]);
    return {
      ...result,
      [key]: textBlock && textBlock.text[language]
        ? textBlock.text[language]
        : '',
    };
  }, {});
});

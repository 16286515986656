import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';

import './Info.css';

const ParamTransferTargetInfo = React.memo(function ParamTransferTargetInfo({
  onLogout,
  target
}) {
  return (
    <div className="param-transfer-target--info">
      <FormattedMessage
        id="ParamTransfer.TargetInfo.Title"
        tagName="h4"
        values={{ name: get(target, 'data.config.name', '') }}
      />

      <FormattedMessage
        id="ParamTransfer.TargetInfo.Url"
        tagName="p"
        values={{ url: get(target, 'data.config.url', '') }}
      />

      <FormattedMessage
        id="ParamTransfer.TargetInfo.Username"
        tagName="p"
        values={{ username: get(target, 'data.config.username', '') }}
      />

      <Button
        className="param-transfer-target--info--logout"
        bsStyle="primary"
        onClick={onLogout}
      >
        <FormattedMessage id="ParamTransfer.TargetInfo.Logout" />
      </Button>
    </div>
  );
});

ParamTransferTargetInfo.propTypes = {
  onLogout: PropTypes.func,
  target: PropTypes.object.isRequired
};

ParamTransferTargetInfo.defaultProps = {
  onLogout: noop
};

export default ParamTransferTargetInfo;

import { createSelector } from 'reselect';

export const getFinancingRatesState = (state) => state.financingRates;
export const getFinancingRatesLoading = createSelector([getFinancingRatesState], (state) => state.loading);
export const getFinancingRatesError = createSelector([getFinancingRatesState], (state) => state.error);
export const getFinancingRatesDurations = createSelector([getFinancingRatesState], (state) => state.durations);
export const getFinancingRatesForwards = createSelector([getFinancingRatesState], (state) => state.forwards);
export const getFinancingRatesRefiRates = createSelector([getFinancingRatesState], (state) => state.refiRates);
export const getFinancingRatesBaseRates = createSelector([getFinancingRatesState], (state) => state.baseRates);
export const getFinancingRatesInitial = createSelector([getFinancingRatesState], (state) => state.initialData);

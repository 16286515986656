import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isNil } from 'lodash';
import { TextInput } from '@evoja-web/react-form';
import { FormGroup, ControlLabel } from 'react-bootstrap';

class CreateCustomerSuspensionPostActionConfig extends React.PureComponent {
  componentDidMount() {
    const { onChange, params } = this.props;

    // Set customer.id as default if no value is set
    const jmesCustomerId = get(params, 'jmesCustomerId');
    if (isNil(jmesCustomerId)) {
      onChange('jmesCustomerId', 'customer.id');
    }
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      edit,
      onChange,
      params
    } = this.props;

    return (
      <div className="workguide-post-action-configuraton workguide-post-action-configuraton--create-customer-suspension">
        <FormGroup>
          <ControlLabel>
            Pfad zur ID des Kunden (jmes Anweisung). Default: Aktuell gewählter Kunde
          </ControlLabel>

          <TextInput
            id="jmesCustomerId"
            name="jmesCustomerId"
            disabled={!edit}
            onChange={onChange}
            value={get(params, 'jmesCustomerId', '')}
          />
        </FormGroup>
      </div>
    );
  }
}

CreateCustomerSuspensionPostActionConfig.propTypes = {
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  params: PropTypes.object
};

CreateCustomerSuspensionPostActionConfig.defaultProps = {
  edit: false,
  onChange: noop,
  params: {}
};

export default CreateCustomerSuspensionPostActionConfig;

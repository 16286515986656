import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';

import categoriesAction from '../actions/Categories';
import { getDatabase } from '../../../IndexDb';

const {
  CATEGORIES_PENDING,
  CATEGORIES_FULFILLED,
  CATEGORIES_REJECTED
} = categoriesAction;

export default function* getCategories() {
  yield put({ type: CATEGORIES_PENDING });

  try {
    const db = yield call(getDatabase);
    const expired = yield call(db.isExpired, { table: 'moduleCategory' });

    if (!expired) {
      const payload = yield call(db.getAllRecords, { table: 'moduleCategory' });
      yield put({ type: CATEGORIES_FULFILLED, payload });

      return payload;
    }

    const query = QueryBuilder()
      .eq('group', 'moduleCategory')
      .limit(500)
      .getQueryString();

    const url = `/entity/code/${query}`;
    const payload = yield call(handleNextHeaders, url);

    // Update index db
    yield call(db.bulkUpdate, { table: 'moduleCategory', data: payload, clear: true });

    yield put({ type: CATEGORIES_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CATEGORIES_REJECTED, error });

    return error;
  }
}

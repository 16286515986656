import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { get, noop } from 'lodash';
import Toggle from 'react-toggle';

const Cancelable = React.memo(({
  editValueField,
  workguide
}) => {
  return (
    <div className="workguide-detail-general-cancelable">
      <FormGroup>
        <ControlLabel>
          Workguide kann storniert werden
        </ControlLabel>

        <br />

        <Toggle
          checked={get(workguide, 'isCancelable', true)}
          onChange={() => editValueField('', 'workguidePropery', { key: 'isCancelable', value: !get(workguide, 'isCancelable', true) })}
        />
      </FormGroup>
    </div>
  );
});

Cancelable.propTypes = {
  editValueField: PropTypes.func,
  workguide: PropTypes.object.isRequired
};

Cancelable.defaultProps = {
  editValueField: noop
};

export default Cancelable;

import { createSelector } from 'reselect';
import { getLanguage } from '../commonSelectors';
import { getRefErrorsTextBlocks } from './refErrorsCommonSelectors';
import { REF_ERRORS_TEXT_BLOCKS_MAP } from '../../constants/RefErrorsConstants';

export const getRefErrorsTexts = createSelector([
  getRefErrorsTextBlocks,
  getLanguage,
], (textBlocks, language) => {
  return Object.keys(REF_ERRORS_TEXT_BLOCKS_MAP).reduce((result, key) => {
    const textBlock = textBlocks.find((it) => it.id === REF_ERRORS_TEXT_BLOCKS_MAP[key]);
    return {
      ...result,
      [key]: textBlock && textBlock.text[language]
        ? textBlock.text[language]
        : '',
    };
  }, {});
});

import { get, isNil } from 'lodash';

/**
 * Filter the given modules by category id.
 * Return all modules if categoryId is undefined or "*"
 *
 * @param   {Array}  modules     Array of modules to filter
 * @param   {String} categoryId  Category id
 *
 * @return  {Array} filtered Filtered modules
 */
export default function filterByCategoryId({ modules = [], categoryId }) {
  if (isNil(categoryId) || categoryId === '*') {
    return modules;
  }

  return modules.filter((module) => {
    const ids = get(module, 'categories', []).map((c) => c.id);

    return ids.includes(categoryId);
  });
}

import './RefErrorsSearchForm.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { classList } from '../../utils/classList';
import { ExpandContent } from '../Common/ExpandContent';
import { Button } from '../Common/Button';
import { FormInput, FormDateTime } from '../FormControls';
import { REF_ERRORS_DEFAULT_SORT, REF_ERRORS_PAGE_SIZE } from '../../constants/RefErrorsConstants';

class RefErrorsSearchReduxForm extends React.PureComponent {
  state = {
    expanded: false,
    results: this.props.results,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.results === prevState.results) {
      return null;
    }

    return {
      expanded: !nextProps.results || nextProps.results.length === 0,
      results: nextProps.results,
    };
  }

  toggleExpanded = () => this.setState((prev) => ({ expanded: !prev.expanded }));
  isOutsideRange = () => false;
  search = (searchParams) => this.props.search({
    ...searchParams,
    ...REF_ERRORS_DEFAULT_SORT,
    limit: REF_ERRORS_PAGE_SIZE,
    offset: 0,
    resultsRaw: {}
  })

  render() {
    const { expanded } = this.state;
    const { handleSubmit, loading } = this.props;

    const iconClasses = classList({
      'icon-013-chevron-down': true,
      'ref-errors-search__icon': true,
      'ref-errors-search__icon--collapsed': !expanded,
    });

    return (
      <div className="ref-errors-search">
        <Form onSubmit={handleSubmit(this.search)}>
          <div className="ref-errors-search__header" onClick={this.toggleExpanded}>
            <div className="ref-errors-search__title">
              <FormattedMessage id="refErrors_search_title"/>
            </div>
            <div className={iconClasses}/>
          </div>

          <ExpandContent expanded={expanded} className="ref-errors-search__body">
            <div className="ref-errors-search__column">
              <Field component={FormInput}
                     size='large'
                     label={<FormattedMessage id="refErrors_search_collection"/>}
                     labelSize={4}
                     name="collection"
              />
              <Field component={FormInput}
                     size='large'
                     label={<FormattedMessage id="refErrors_search_recordId"/>}
                     labelSize={4}
                     name="recordId"
              />
             <Field component={FormDateTime}
                    label={<FormattedMessage id="refErrors_search_dateFrom"/>}
                    labelSize={4}
                    name="dateFrom"
                    isOutsideRange={this.isOutsideRange}
              />
            </div>
            <div className="ref-errors-search__column">
              <Field component={FormInput}
                     size='large'
                     label={<FormattedMessage id="refErrors_search_targetCollection"/>}
                     labelSize={4}
                     name="targetCollection"
              />
              <Field component={FormInput}
                     size='large'
                     label={<FormattedMessage id="refErrors_search_targetId"/>}
                     labelSize={4}
                     name="targetId"
              />
              <Field component={FormDateTime}
                    label={<FormattedMessage id="refErrors_search_dateTo"/>}
                    labelSize={4}
                    name="dateTo"
                    isOutsideRange={this.isOutsideRange}
              />
            </div>

            <div className="ref-errors-search__footer">
              <Button bsStyle="primary" type="submit" disabled={loading}>
                <FormattedMessage id="common_search"/>
              </Button>
            </div>
          </ExpandContent>
        </Form>
      </div>
    );
  }
}

export const RefErrorsSearchForm = reduxForm({})(RefErrorsSearchReduxForm);
RefErrorsSearchForm.propTypes = {
  search: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  results: PropTypes.array,
};

import { createSelector } from 'reselect';
import { getAnalyticsLogsConsultants } from './analyticsLogsCommonSelectors';

export const getAnalyticsLogsDepartments = createSelector([
  getAnalyticsLogsConsultants,
], (consultants) => {
  const usersGroupedByDepartment = consultants.reduce((result, consultant) => {
    if (!consultant.department) {
      return result;
    }

    const key = consultant.department;
    if (!result[key]) {
      result[key] = new Set([]);
    }
    result[key].add(consultant.username);
    return result;
  }, {});

  return Object.keys(usersGroupedByDepartment).map((key) => ({
    department: key,
    users: [...usersGroupedByDepartment[key]],
  }));
});

import React from 'react';
import { FormGroup, Col, FormControl, InputGroup } from 'react-bootstrap';
import { get } from 'lodash';

export class IsGeInput extends React.Component {
  constructor(){
    super()
    this.onChange = this.onChange.bind(this);
  }
  onChange(event){
    const { onChange } = this.props
    let value = get(event, 'target.value', '')
    value = parseFloat(value);
    onChange([value])
  }

  getCurrentValue() {
    const { validation, component } = this.props;
    return component.props.validations[validation.key].isGe[0];
  }

  render(){
    return (
      <FormGroup>
      <Col sm={10}>
        <Col >
          <InputGroup>
            <InputGroup.Addon>Min</InputGroup.Addon>
            <FormControl
              value={this.getCurrentValue()}
              step="0.01"
              type="number"
              aria-label="min"
              onChange={this.onChange}
            />
          </InputGroup>
        </Col>

      </Col>
    </FormGroup>
    )
  }
}

import React, { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { Form, reduxForm, Field, FieldArray, getFormValues, submit } from 'redux-form';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormInput, FormSelect, FormToggle } from './FormControls';
import { BasicProductsDocumentsRenderer } from './BasicProductsDocumentsRenderer';
import { mapBasicAdditionToApp } from '../../services/BasicProducts';
import { validateProductForm } from './BasicProductValidator';

const basicProductDetailsFormName = 'basicProductDetails';

class BasicProductsDetailsForm extends React.Component {
  constructor(props) {
    super(props);

    this.saveProduct = this.saveProduct.bind(this);
    this.updateAddition = this.updateAddition.bind(this);
    this.onChangeCategory = this.onChangeCategory.bind(this);
  }

  render() {
    const { handleSubmit, coreTypes } = this.props;
    return (
      <Form className="form-horizontal"
            name={basicProductDetailsFormName}
            onSubmit={handleSubmit(this.saveProduct)}>
        <Field component={FormInput}
               label="Produktname"
               name="name"
               type="text"/>

        <Field component={FormSelect}
               label="Kategorie"
               name="category"
               placeholder="Kategorie"
               multi={false}
               clearable={false}
               onChange={this.onChangeCategory}
               options={this.renderCategories()}/>

        <Field component={FormSelect}
               label="Core type"
               name="coreType"
               placeholder="Core type"
               multi={true}
               clearable={false}
               options={this.getOptions(coreTypes)}/>

        <Field component={FormInput}
               label="Produktlimite"
               name="maxOccurrence"
               type="number"/>

        <Field component={FormInput}
               label="ID"
               name="id"
               disabled={true}
               type="text"/>

        {this.renderAddition()}
        <FieldArray name="documents" component={BasicProductsDocumentsRenderer}/>
      </Form>
    );
  }

  renderAddition() {
    const {
      displayClasses,
      detailGroups,
      changeTypes,
      outputClasses,
      product,
      currentFormValues,
    } = this.props;
    const isReadonly = currentFormValues
      ? !currentFormValues.showAddition
      : !product.showAddition;
    const fieldName = isReadonly ? 'additionDefault' : 'addition';

    return (
      <Fragment>
        <Field component={FormToggle}
               label="Zusatzdaten"
               name="showAddition"
               onChange={this.updateAddition}/>

        <Field component={FormSelect}
               label="Display class"
               name={`${fieldName}.displayClass`}
               placeholder="Display class"
               multi={false}
               clearable={false}
               disabled={isReadonly}
               options={this.getOptions(displayClasses)}/>

        <Field component={FormSelect}
               label="Output class"
               name={`${fieldName}.outputClass`}
               placeholder="Output class"
               multi={false}
               clearable={false}
               disabled={isReadonly}
               options={this.getOptions(outputClasses)}/>

        <Field component={FormSelect}
               label="Detail Gruppen"
               name={`${fieldName}.detailGroup`}
               placeholder="Detail Gruppen"
               multi={true}
               clearable={false}
               disabled={isReadonly}
               options={this.getOptions(detailGroups)}/>

        <Field component={FormSelect}
               label="Mutations Typen"
               name={`${fieldName}.changeType`}
               placeholder="Mutations Typen"
               multi={true}
               clearable={false}
               disabled={isReadonly}
               options={this.getOptions(changeTypes)}/>
      </Fragment>
    );
  }

  saveProduct(formData) {
    const { saveProduct, product, closeDialog } = this.props;
    saveProduct({...product, ...formData});
    closeDialog();
  }

  updateAddition(event, showAddition) {
    const { change, product } = this.props;
    change("addition", showAddition
      ? product.addition || {
        displayClass: '',
        outputClass: '',
        detailGroup: [],
        changeType: [],
      }
      : null
    );
  }

  onChangeCategory(event, categoryId) {
    const { change, categories } = this.props;
    const category = categories.find((it) => it.id === categoryId);
    if (!category) {
      return;
    }
    change("additionDefault", category.defaults
      ? mapBasicAdditionToApp(category.defaults)
      : {
        displayClass: '',
        outputClass: '',
        detailGroup: [],
        changeType: [],
      });
  }

  renderCategories() {
    const { categories, language } = this.props;
    return categories.map((category) => ({
      value: category.id,
      label: category.name[language],
    }));
  }

  getOptions(entityCodes) {
    const { language } = this.props;
    return entityCodes.map((entityCode) => ({
      value: entityCode.id,
      label: entityCode.text[language],
    }));
  }
}




const BasicProductsReduxForm = reduxForm({
  form: basicProductDetailsFormName,
  validate: validateProductForm,
})(BasicProductsDetailsForm);


export const getBasicProductDetailsFormValues = createSelector([
  getFormValues(basicProductDetailsFormName),
], (formData) => formData);

const mapStateToProps = createSelector([
  getBasicProductDetailsFormValues,
], (
  currentFormValues,
) => ({
  currentFormValues,
}));
const BasicProductsDetailsFormWithState = connect(mapStateToProps)(BasicProductsReduxForm);

export function BasicProductsDetails(props) {
  const { product } = props;

  return (
    <BasicProductsDetailsFormWithState initialValues={product} {...props}/>
  );
}

function SubmitButton(props) {
  const { dispatch, currentFormValues } = props;
  const isDisabled = currentFormValues && currentFormValues.submitFailed && !currentFormValues.valid;
  const onClick = () => dispatch(submit(basicProductDetailsFormName));
  return (
    <Button onClick={onClick}
            className="pull-left"
            bsStyle="primary"
            disabled={isDisabled}>
      <span className="icon-012-check-mark-circle" /> Anwenden
    </Button>
  );
}
export const BasicProductsDetailsFormSubmitButton = connect(mapStateToProps)(SubmitButton);

import React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { SortableElement } from 'react-sortable-hoc';

import DragHandle from '../DragHandle';

const AgendaItem = SortableElement(({
  isChecked, id, text, language, onChecked, onEdit, onRemove,
}) =>
  (
    <div className="SortableHOCItem">
      <InputGroup>
        <InputGroup.Addon>
          <DragHandle />
          <input
            type="checkbox"
            name="checkedState"
            value={isChecked}
            checked={isChecked}
            onChange={onChecked.bind(this, id)}
          />
        </InputGroup.Addon>
        <FormControl
          type="text"
          name="AgendaItem"
          value={text[language]}
          onChange={onEdit.bind(this, id)}
          onFocus={onEdit.bind(this, id)}
        />
        <InputGroup.Addon style={{ cursor: 'pointer' }}>
          <span onClick={onRemove.bind(this, id)}>
            <i className="icon-008-bin" />
          </span>
        </InputGroup.Addon>
      </InputGroup>
    </div>
  ));

export default AgendaItem;

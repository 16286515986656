import uuid from 'uuid';
import { mapBasicAdditionToApp } from '../../services/BasicProducts';

export function CreateBasicProductsReducer(state) {
  const { products, basicCategories } = state;
  const category = basicCategories[0];
  const newProduct = {
    id: uuid.v4(),
    name: '',
    order: products.length + 1,
    maxOccurrence: undefined,
    category: category.id,
    coreType: [],
    addition: undefined,
    additionDefault: category.defaults
      ? mapBasicAdditionToApp(category.defaults)
      : {
        displayClass: '',
        outputClass: '',
        detailGroup: [],
        changeType: [],
      },
    documents: [],
    orderCategory: category.order,
    showAddition: false,
  };

  return {...state, products: [...products, newProduct]};
}

import { call, put } from 'redux-saga/effects';
import { removeHost } from '@evoja-web/redux-saga-utils';
import axios from 'axios';
import { get } from 'lodash';

import schemaAction from '../../actions/Api/Schema';

const {
  SCHEMA_PENDING,
  SCHEMA_FULFILLED,
  SCHEMA_REJECTED
} = schemaAction;

export default function* getSchema(request) {
  const { dataKey, service } = request;

  yield put({ type: SCHEMA_PENDING, dataKey });

  try {
    const { data: json } = yield call(axios, {
      url: removeHost(get(service, 'api-docs.json.$ref')),
      method: 'get'
    });

    const { data: yaml } = yield call(axios, {
      url: removeHost(get(service, 'api-docs.yaml.$ref')),
      method: 'get'
    });

    const payload = {
      json,
      yaml
    };

    yield put({ type: SCHEMA_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: SCHEMA_REJECTED, dataKey, error });

    return error;
  }
}

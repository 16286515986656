import { call, put } from 'redux-saga/effects';
import { v4 } from 'uuid';
import { get, defaultsDeep, isUndefined } from 'lodash';
import axios from 'axios';

import formAction from '../../actions/CodeMapping/Form';

const {
  CODE_MAPPING_FORM_INIT_PENDING,
  CODE_MAPPING_FORM_INIT_FULFILLED,
  CODE_MAPPING_FORM_INIT_REJECTED
} = formAction;

export function* getEntry(id) {
  const url = `/entity/codemapping/?eq(id,${id})`;

  const { data } = yield call(axios, {
    url,
    method: 'get'
  });

  return get(data, 0);
}

export default function* initForm(request) {
  let { data } = request;

  yield put({ type: CODE_MAPPING_FORM_INIT_PENDING });

  try {
    const id = get(data, 'id');
    if (!isUndefined(id)) {
      data = yield call(getEntry, id);
    }

    const defaults = {
      id: v4(),
      mapping: [],
      recordOrigin: 'map'
    };

    const payload = defaultsDeep({}, data, defaults);

    yield put({ type: CODE_MAPPING_FORM_INIT_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CODE_MAPPING_FORM_INIT_REJECTED, error });

    return error;
  }
}

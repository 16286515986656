import './ApiClientResponseBody.css';

import 'file-saver';
import React from 'react';
import PropTypes from 'prop-types';
import { has } from 'lodash';
import ReactJson from 'react-json-view';
import { Image } from 'react-bootstrap';
import { classList } from '../../utils/classList';
import { saveAsFile } from '../../services/ApiClient/ApiClientFileSaver';
import { ApiClientBlobContent } from './ApiClientBlobContent';
import { ApiClientPdfPreview } from './ApiClientPdfPreview';

export class ApiClientResponseBody extends React.PureComponent {
  state = {
    isPreviewMode: true,
  };

  render() {
    return (
      <div className="api-client-response-body">
        {this.renderButtons()}
        <div className="api-client-response-body__content">
          {this.renderContent()}
        </div>
      </div>
    );
  }

  renderButtons() {
    const { response } = this.props;
    if (response instanceof Error) {
      return null;
    }
    if (!response.data) {
      return null;
    }
    if (response.status < 200 || response.status > 399) {
      return null;
    }

    const contentType = getContentType(response);
    return (
      <div className="api-client-response-body__buttons">
        {this.renderPreviewButton(contentType)}
        {this.renderRawButton(contentType)}
        <span className="api-client-response-body__button icon-024-download-cloud"
              onClick={this.download}
              title="Save content to file"/>
      </div>
    );
  }

  renderContent() {
    const { response } = this.props;
    if (response instanceof Error) {
      return response.toString();
    }
    if (!response.data) {
      return null;
    }

    const contentType = getContentType(response);
    const { isPreviewMode } = this.state;
    return isPreviewMode
      ? this.renderPreview(response.data, contentType)
      : this.renderRaw(response.data, contentType);
  }

  renderPreview(data, contentType) {
    switch (contentType) {
      case 'application/pdf':
        return (
          <div className="api-client-response-body__pdf">
            <ApiClientPdfPreview blob={data}/>
          </div>
        );

      case 'image/jpeg':
      case 'image/tiff':
      case 'image/bmp':
      case 'image/png':
      case 'image/gif': {
        const url = URL.createObjectURL(data);
        return (<Image src={url} className="api-client-response-body__img"/>);
      }

      case 'application/json':
        return (data instanceof Blob)
          ? <ApiClientBlobContent blob={data} processContent={parseAsJson}/>
          : <ReactJson
              src={data}
              name={false}
              displayDataTypes={false}
              collapsed={2}
              indentWidth={2}
              iconStyle="triangle"
              onAdd={false}
              onEdit={false}
              onDelete={false}
              enableClipboard={false}
            />;

      case 'text/html':
        // check if has error message
      if (has(data[0], 'message')) {
          return (
          <ReactJson
          src={data}
          name={false}
          displayDataTypes={false}
          collapsed={2}
          indentWidth={2}
          iconStyle="triangle"
          onAdd={false}
          onEdit={false}
          onDelete={false}
          enableClipboard={false}
          />
          );
       } else {
          const url = URL.createObjectURL(data);
            return <iframe src={url} width={750} height={500} title="Preview" />;
        }

      case 'text/plain':
        return (data instanceof Blob)
          ? <ApiClientBlobContent blob={data} processContent={parseAsJson}/>
          : data;

      default:
        return <i>Cannot display content of "{contentType}" data type</i>;
    }
  }

  renderRaw(data, contentType) {
    switch (contentType) {
      case 'application/json':
        return (
          <div className="api-client-response-body__raw">{JSON.stringify(data)}</div>
        );

      case 'text/html':
      case 'text/plain':
        return (
          <pre className="api-client-response-body__raw">
            <ApiClientBlobContent blob={data}/>
          </pre>
        );

      default:
        return null;
    }
  }

  renderPreviewButton(contentType) {
    const { isPreviewMode } = this.state;
    switch (contentType) {
      case 'application/json':
      case 'text/html':
      case 'text/plain':
        const previewButtonClasses = classList(
          'icon-156-postcard',
          'api-client-response-body__button',
          { 'api-client-response-body__button--active': isPreviewMode },
        );
        return (
          <span className={previewButtonClasses}
                onClick={this.preview}
                title="Preview"/>
        );

      default:
        return null;
    }
  }

  renderRawButton(contentType) {
    const { isPreviewMode } = this.state;
    switch (contentType) {
      case 'application/json':
      case 'text/html':
      case 'text/plain':
        const rawButtonClasses = classList(
          'icon-224-console',
          'api-client-response-body__button',
          { 'api-client-response-body__button--active': !isPreviewMode },
        );
        return (
          <span className={rawButtonClasses} title="Raw response view" onClick={this.raw}/>
        );

      default:
        return null;
    }
  }

  preview = () => this.setState({ isPreviewMode: true });
  raw = () => this.setState({ isPreviewMode: false });
  download = () => saveAsFile(this.props.response.data, getContentType(this.props.response));
}

ApiClientResponseBody.propTypes = {
  response: PropTypes.object.isRequired,
};

function getContentType(response) {
  const contentType = response.headers['content-type'];
  return contentType.split(';')[0];
}

function parseAsJson(content) {
  try {
    const data = JSON.parse(content);
    return (
      <ReactJson
        src={data}
        name={false}
        displayDataTypes={false}
        collapsed={2}
        indentWidth={2}
        iconStyle="triangle"
        onAdd={false}
        onEdit={false}
        onDelete={false}
        enableClipboard={false}
      />
    );
  } catch (e) {
    return content;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isUndefined, debounce } from 'lodash';
import { FormGroup, FormControl, InputGroup, ControlLabel } from 'react-bootstrap';
import update from 'immutability-helper';

import codeMappingActions from '../../../actions/CodeMappingActions';

function updateMapping(mapping, score, workguide) {
  const workguideId = get(workguide, 'id');
  const current = get(mapping, 'mapping', []);
  const currentIndex = current.findIndex((m) => get(m, 'value', []).includes(workguideId));
  const nextIndex = current.findIndex((m) => get(m, 'key') === score);

  let updated = mapping;
  if (currentIndex > -1) {
    const currentValues = get(current, `${currentIndex}.value`, []);
    updated = update(updated, {
      mapping: {
        [currentIndex]: {
          value: { $set: currentValues.filter((v) => v !== workguideId) }
        }
      }
    });
  }

  if (nextIndex > -1) {
    updated = update(updated, {
      mapping: {
        [nextIndex]: {
          value: { $push: [workguideId] }
        }
      }
    });
  } else {
    updated = update(updated, {
      mapping: {
        $push: [{ key: score, value: [workguideId] }]
      }
    });
  }

  return updated;
}

class WorkguideScore extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      score: undefined
    };

    const { codeMappingActions } = props;

    codeMappingActions.codeMappingsRequest();

    this.onValueChange = this.onValueChange.bind(this);
    this.onSubmit = debounce(this.onSubmit.bind(this), 500);
  }

  componentDidUpdate(prevProps) {
    const { requesting } = this.props;
    const { requesting: prevRequesting } = prevProps;

    if (!requesting && prevRequesting) {
      this.setState({ score: this.getCurrentValue() });
    }
  }

  onValueChange(ev) {
    const value = get(ev, 'target.value', '');
    const score = get(value.trim(), 'length', 0) > 0
      ? value
      : undefined;

    this.setState({ score });

    this.onSubmit();
  }

  onSubmit() {
    const { score } = this.state;
    const {
      codeMappingActions,
      scoreMapping,
      provisionScoreMapping,
      workguide
    } = this.props;

    codeMappingActions.codeMappingSaveRequest({ id: 'mapWorkguideScore', data: updateMapping(scoreMapping, score, workguide) });
    codeMappingActions.codeMappingSaveRequest({ id: 'provisionWorkguideScore', data: updateMapping(provisionScoreMapping, score, workguide) });
  }

  getCurrentValue() {
    const { scoreMapping, workguide } = this.props;

    const workguideId = get(workguide, 'id');
    const mapping = get(scoreMapping, 'mapping', []);
    const current = mapping.find((m) => get(m, 'value', []).includes(workguideId));

    return get(current, 'key');
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { score } = this.state;
    const { requesting } = this.props;
    const isValid = !isUndefined(score);

    return (
      <div className="workguide-detail-general-score">
        <FormGroup>
          <ControlLabel>
            Score
          </ControlLabel>

          <InputGroup>
            <FormControl
              id="score"
              name="score"
              value={get(this, 'state.score', '')}
              onChange={this.onValueChange}
              disabled={requesting}
            />

            <InputGroup.Addon>
              <span className="mdi mdi-check-outline" style={{ color: isValid ? 'green' : 'lightgray' }} />
            </InputGroup.Addon>
          </InputGroup>
        </FormGroup>
      </div>
    );
  }
}

WorkguideScore.propTypes = {
  codeMappingActions: PropTypes.object.isRequired,
  requesting: PropTypes.bool,
  workguide: PropTypes.object.isRequired
};

WorkguideScore.defaultProps = {
  requesting: false
};

function isRequesting(state) {
  return get(state, 'codeMapping.mappings.requesting', false);
}

function mapStateToProps(state, ownProps) {
  return {
    requesting: isRequesting(state),
    mappings: state.codeMapping.mappings,
    scoreMapping: get(state, 'codeMapping.mappings.data', []).find((m) => get(m, 'id') === 'mapWorkguideScore'),
    provisionScoreMapping: get(state, 'codeMapping.mappings.data', []).find((m) => get(m, 'id') === 'provisionWorkguideScore')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    codeMappingActions: bindActionCreators(codeMappingActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkguideScore);

import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';

import servicesAction from '../../actions/Api/Services';

const {
  SERVICES_PENDING,
  SERVICES_FULFILLED,
  SERVICES_REJECTED
} = servicesAction;

export default function* getServices() {
  yield put({ type: SERVICES_PENDING });

  try {
    const url = '/';
    const { data } = yield call(axios, {
      url,
      method: 'get'
    });
    const payload = get(data, 'services', []);

    yield put({ type: SERVICES_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: SERVICES_REJECTED, error });

    return error;
  }
}

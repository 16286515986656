import {
  GET,
  PUT,
  NEW,
  SET,
} from '../actions/AgendaActions';

export default function AgendaReducer(
  state = {
    agendas: [],
    selectedModule: 0,
    requesting: false,
    error: {},
    hasError: false,
    changed: false,
  },
  action,
) {
  switch (action.type) {
    case NEW: {
      return { ...state, changed: action.data };
    }
    case `${GET}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${GET}_FULFILLED`: {
      return { ...state, requesting: false, agendas: action.payload.data };
    }
    case `${GET}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case `${PUT}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${PUT}_FULFILLED`: {
      return { ...state, requesting: false };
    }
    case `${PUT}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case SET: {
      return { ...state, [action.key]: action.value };
    }
    default: {
      return state;
    }
  }
}

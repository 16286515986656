import React from 'react';

class CustomerFinancingTranchesBalancingConfiguration extends React.Component {
  constructor(props) {
    super(props);

    const {
      editValueField,
      id
    } = props;

    // Set hideValidations to true. Component will render validations itself
    editValueField(id, 'hideValidations', true);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    // Acutally nothing to configure here. Just call editValueField in consturtor to set "hideValidation" to true
    return null;
  }
}

export default CustomerFinancingTranchesBalancingConfiguration;

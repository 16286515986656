import './Tabs.css';

import React from 'react';
import PropTypes from 'prop-types';
import { classList } from '../../utils/classList';

export function Tabs({ items, selected, onChange }) {
  return (
    <div className="tabs">
      {items.map((item) => (
        <TabsItem
          key={item.key}
          item={item}
          selected={selected}
          onChange={onChange}
        />
      ))}
    </div>
  );
}

Tabs.propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


class TabsItem extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  render() {
    const { item, selected } = this.props;
    const classes = classList({
      'tabs__item': true,
      'tabs__item--active': selected === item.key,
    });
    return (
      <div className={classes} onClick={this.onChange} key={item.key}>
        {item.label}
      </div>
    );
  }

  onChange() {
    const { item, onChange } = this.props;
    onChange(item.key);
  }
}

TabsItem.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

import { call, put } from 'redux-saga/effects';
import axios from 'axios';

// ToDo: Move functions like handleNextHeaders to own repo
import loaderTriggerGetEventStatusActions from '../../actions/LoaderTriggerGetEventStatusActions';

const {
  GET_EVENT_STATUS_PENDING,
  GET_EVENT_STATUS_FULFILLED,
  GET_EVENT_STATUS_REJECTED
} = loaderTriggerGetEventStatusActions;

export default function* getEventStatusSaga(request) {

  yield put({ type: GET_EVENT_STATUS_PENDING });

  try {
    const response = yield call(axios, {
      url: '/event/status/' + request.data,
      method: 'get',
      data: request.data
    });
    
    yield put({ type: GET_EVENT_STATUS_FULFILLED, data: response.data[0].status });
  } catch (error) {
    yield put({ type: GET_EVENT_STATUS_REJECTED, error });

    return error;
  }
}

import React from 'react';
import { FormGroup, ControlLabel, FormControl, Col } from 'react-bootstrap';
import VirtualizedSelect from 'react-virtualized-select';
import Toggle from 'react-toggle';

export function FormRow(props) {
  const { id, label, meta: { error, touched }, children } = props;
  const validationState = touched && error ? 'error' : null;

  return (
    <FormGroup controlId={id} validationState={validationState}>
      <Col componentClass={ControlLabel} sm={3}>
        {label}
      </Col>
      <Col sm={9}>
        {children}
        <FormControl.Feedback/>
      </Col>
    </FormGroup>
  );
}

export function FormInput(props) {
  const { input, ...restProps } = props;

  return (
    <FormRow {...props}>
      <FormControl {...input} {...restProps} />
    </FormRow>
  );
}

export function FormSelect(props) {
  const { multi, options, input, ...restProps } = props;
  const onChange = (value) => input.onChange(multi
    ? value.map(it => it.value)
    : value ? value.value : null
  );
  const onBlur = () => input.onBlur(input.value);

  return (
    <FormRow {...props}>
      <VirtualizedSelect options={options}
                         multi={multi}
                         {...input}
                         value={input.value}
                         onChange={onChange}
                         onBlur={onBlur}
                         {...restProps}/>
    </FormRow>
  );
}

export function FormToggle(props) {
  const { input, ...restProps } = props;
  return (
    <FormRow {...props}>
      <Toggle
        checked={input.value}
        onChange={input.onChange}
        {...restProps}/>
    </FormRow>
  );
}

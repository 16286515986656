import { HOLIDAYS_REMOVE_ACTIONS } from '../../actions/HolidaysActions';

export function HolidaysRemoveReducer(state, action) {
  switch (action.type) {
    case HOLIDAYS_REMOVE_ACTIONS.SUCCESS:
      return { ...state, holidaysLists: action.data.holidaysLists };
    default:
      return state;
  }
}

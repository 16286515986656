import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { INIT_ACTIONS } from '../actions/InitActions';

export function* initSaga() {
  try {
    yield put(INIT_ACTIONS.pending());

    const { data: i18nMessages } = yield call(axios, {
      url: `/translations.json`,
      method: 'get',
      baseURL: null,
    });

    yield put(INIT_ACTIONS.success(i18nMessages));
  } catch (error) {
    yield put(INIT_ACTIONS.failure(error));
  }
}

import './SetManagement.css';

import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { handleGravitonError } from '../../utils';
import PageContainer from '../Common/PageContainer';
import { Tabs } from '../Common/Tabs';
import { SetManagementBundle } from './SetManagementBundle';
import { SetManagementPricing } from './SetManagementPricing';
import { SetManagementProduct } from './SetManagementProduct';

export class SetManagement extends React.Component {
  state = {
    selectedTabKey: tabs[0].key,
  };

  constructor(props) {
    super(props);
    props.load();
  }

  render() {
    const { loading, error, save } = this.props;
    if (error) {
      toast.error(handleGravitonError(error));
    }

    const { selectedTabKey } = this.state;
    return (
      <PageContainer
        title={<FormattedMessage id="setManagement_title"/>}
        requesting={loading}
        saveData={save}
      >
        <div className="set-management">
          <Tabs
            items={tabs}
            selected={selectedTabKey}
            onChange={this.onChangeTab}
          />

          <div className="set-management__content">
            {this.renderTab()}
          </div>
        </div>
      </PageContainer>
    );
  }

  renderTab() {
    const { selectedTabKey } = this.state;
    const { bundles, prices, products, domains, reductionTypes, bundleOptions, productOptions } = this.props;

    switch (selectedTabKey) {
      case 'bundle':
        return <SetManagementBundle bundles={bundles}
                                    domains={domains}
                                    onChange={this.onChangeBundles}/>;
      case 'pricing':
        return <SetManagementPricing prices={prices}
                                     bundles={bundleOptions}
                                     products={productOptions}
                                     reductionTypes={reductionTypes}
                                     onChange={this.onChangePrices}/>;
      case 'product':
        return <SetManagementProduct products={products}
                                     domains={domains}
                                     bundles={bundleOptions}
                                     onChange={this.onChangeProducts}/>;

      default:
        return null;
    }
  }

  onChangeTab = (selectedTabKey) => this.setState({ selectedTabKey });
  onChangeBundles = (bundles) => this.props.change({ bundles });
  onChangeProducts = (products) => this.props.change({ products });
  onChangePrices = (prices) => this.props.change({ prices });
}

SetManagement.propTypes = {
  load: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.object,
  bundles: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  prices: PropTypes.array.isRequired,
  domains: PropTypes.array.isRequired,
  reductionTypes: PropTypes.array.isRequired,
  bundleOptions: PropTypes.array.isRequired,
  productOptions: PropTypes.array.isRequired,
};

const tabs = [
  { key: 'bundle', label: <FormattedMessage id="setManagement_bundle_title"/> },
  { key: 'pricing', label: <FormattedMessage id="setManagement_pricing_title"/> },
  { key: 'product', label: <FormattedMessage id="setManagement_product_title"/> },
];

import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { UI_NOTES_DELETE_ACTIONS } from '../../actions/UiNotesActions';

export function* uiNotesDeleteSaga({ data: { note } }) {
  try {
    yield put(UI_NOTES_DELETE_ACTIONS.pending());

    yield call(axios.delete, `${EndpointName.WORK_UI_NOTES}/${encodeURIComponent(note.id)}`);

    if (note.image) {
      const filePath = new URL(note.image.$ref).pathname;
      yield call(axios.delete, filePath, { validateStatus: false });
    }

    yield put(UI_NOTES_DELETE_ACTIONS.success({ note }));
  } catch (error) {
    yield put(UI_NOTES_DELETE_ACTIONS.failure(error));
  }
}

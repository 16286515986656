import {
  ANALYTICS_LOGS_COMPARISON,
  ANALYTICS_LOGS_DETAILS,
  ANALYTICS_LOGS_LOAD,
  ANALYTICS_LOGS_SEARCH,
} from '../actions/AnalyticsLogsActions';

const initialState = {
  startDate: undefined,
  endDate: undefined,
  searchResults: [],
  consultants: [],
  endpoints: [],
  requestDetails: undefined,
  comparisonDetails: undefined,
  textBlock: undefined,
  error: undefined,
};

export function AnalyticsLogsReducer(state = initialState, action) {
  switch (action.type) {
    case ANALYTICS_LOGS_LOAD.PENDING:
      return { ...state, loading: true, error: undefined };
    case ANALYTICS_LOGS_LOAD.SUCCESS:
      const { consultants, textBlock, endpoints } = action.data;
      return { ...state, loading: false, error: undefined, consultants, textBlock, endpoints };
    case ANALYTICS_LOGS_LOAD.FAILURE:
      return { ...state, loading: false, error: action.data };

    case ANALYTICS_LOGS_SEARCH.PENDING:
      return { ...state, loadingSearch: true, error: undefined };
    case ANALYTICS_LOGS_SEARCH.SUCCESS:
      const { searchResults, startDate, endDate } = action.data;
      return { ...state, loadingSearch: false, error: undefined, searchResults, startDate, endDate };
    case ANALYTICS_LOGS_SEARCH.FAILURE:
      return { ...state, loadingSearch: false, error: action.data };

    case ANALYTICS_LOGS_DETAILS.PENDING:
      return { ...state, loadingDetails: true, error: undefined };
    case ANALYTICS_LOGS_DETAILS.SUCCESS:
      const { requestDetails } = action.data;
      return { ...state, loadingDetails: false, error: undefined, requestDetails };
    case ANALYTICS_LOGS_DETAILS.FAILURE:
      return { ...state, loadingDetails: false, error: action.data };

    case ANALYTICS_LOGS_COMPARISON.PENDING:
      return { ...state, loadingComparison: true, error: undefined };
    case ANALYTICS_LOGS_COMPARISON.SUCCESS:
      const { comparisonDetails } = action.data;
      return { ...state, loadingComparison: false, error: undefined, comparisonDetails };
    case ANALYTICS_LOGS_COMPARISON.FAILURE:
      return { ...state, loadingComparison: false, error: action.data };

    default:
      return state;
  }
}

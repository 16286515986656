import './BulkLoadsRefresh.css';

import React from 'react';
import PropTypes from 'prop-types';
import { classList } from '../../utils/classList';
import { BULK_LOAD_PROP_TYPES_RESULT_ITEM } from './BulkLoadsPropTypes';
import { BULK_LOADS_REFRESH_INTERVAL } from '../../constants/BulkLoadsConstants';

export class BulkLoadsRefresh extends React.Component {
  timer = undefined;
  state = {
    seconds: BULK_LOADS_REFRESH_INTERVAL,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.results === prevState.results) {
      return null;
    }

    return {
      seconds: BULK_LOADS_REFRESH_INTERVAL,
      results: nextProps.results,
    };
  }

  render() {
    const { refreshing } = this.props;
    const { seconds } = this.state;
    const classes = classList(
      'icon-085-synchronise',
      'bulk-loads-refresh__button',
      { 'bulk-loads-refresh__button--active': refreshing },
    );

    if (seconds === BULK_LOADS_REFRESH_INTERVAL) {
      this.startTimer();
    }

    return (
      <div className="bulk-loads-refresh">
        {seconds}s
        <span className={classes} onClick={this.onClickRefresh}/>
      </div>
    );
  }

  startTimer = () => {
    this.stopTimer();
    this.timer = setTimeout(this.updateTimer, 1000);
  };

  updateTimer = () => {
    const { refresh } = this.props;
    const { seconds } = this.state;
    if (seconds === 0) {
      refresh();
      return;
    }

    this.setState((prev) => ({ seconds: prev.seconds - 1 }));
    this.startTimer();
  };

  stopTimer = () => clearTimeout(this.timer);

  onClickRefresh = () => {
    this.stopTimer();
    this.props.refresh();
  };

  componentWillUnmount() {
    this.stopTimer();
  }
}

BulkLoadsRefresh.propTypes = {
  results: PropTypes.arrayOf(BULK_LOAD_PROP_TYPES_RESULT_ITEM),
  refreshing: PropTypes.bool,
  refresh: PropTypes.func,
};

import { LOAD_SURCHARGE_REALESTATETYPE_ACTIONS } from '../../actions/FinancingSurchargeRealestatetypeActions';

const initialState = ({
  surchargeRealestatetypes: [],
  realestatetypes: [],

  loading: false,
  error: undefined,
});

export function LoadFinancingSurchargeRealestatetypeReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SURCHARGE_REALESTATETYPE_ACTIONS.PENDING:
      return {...state, loading: true, error: undefined};
    case LOAD_SURCHARGE_REALESTATETYPE_ACTIONS.SUCCESS:
      const {
        surchargeRealestatetypes,
        realestatetypes,
      } = action.data;
      return {
        ...state,
        loading: false,
        error: undefined,

        surchargeRealestatetypes,
        realestatetypes,
      };
    case LOAD_SURCHARGE_REALESTATETYPE_ACTIONS.FAILURE:
      return {...state, loading: false, error: action.data};
    default:
      return state;
  }
}

import './BulkLoads.css';
import './BulkLoadsChartSettings';

import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { handleGravitonError } from '../../utils';
import PageContainer from '../Common/PageContainer';
import { BulkLoadsSearchForm } from './BulkLoadsSearchForm';
import { BulkLoadsResults } from './BulkLoadsResults';
import { BULK_LOAD_PROP_TYPES_RESULT_ITEM, BULK_LOAD_PROP_TYPES_SEARCH_PARAMS } from './BulkLoadsPropTypes';
import { BulkLoadsChart } from './BulkLoadsChart';
import BulkLoadsMetricsLoadOverview from './BulkLoadsMetrics/Overview';
import { BulkLoadsExpandContent } from './BulkLoadsExpandContent';
import LoaderTriggeringForm from './LoaderTriggeringForm';

const SEARCH_FORM = "bulkLoadsSearchForm";

export class BulkLoads extends React.Component {
  constructor(props) {
    super(props);
    props.load();
  }

  renderSearchForm() {
    const { search, loading, results } = this.props;
    return (
      <BulkLoadsSearchForm
        form={SEARCH_FORM}
        search={search}
        loading={loading}
        results={results}
      />
    );
  }

  render() {
    const {
      error,
      loaderTriggerActions,
      loaderTriggerData,
      loaderTriggerEventStatus,
      loaderTriggerGetEventStatusActions,
      loading,
      refresh,
      refreshing,
      results,
      resultsRaw,
      resultsTotalCount,
      search,
      searchParams,
      texts
    } = this.props;
    if (error) {
      toast.error(handleGravitonError(error));
    }

    return (
      <PageContainer title="Loads" requesting={loading} isTransparentLoader={true}>

        <BulkLoadsExpandContent title="Übersicht / Analyse" titleSize="30px">
          <div className="bulk-loads">
            {texts.textIntro ? <div className="bulk-loads__text">{texts.textIntro}</div> : null}
            {this.renderSearchForm()}

            <BulkLoadsResults
              loading={loading}
              results={results}
              resultsRaw={resultsRaw}
              resultsTotalCount={resultsTotalCount}
              refresh={refresh}
              refreshing={refreshing}
              search={search}
              searchParams={searchParams}
            />

            <BulkLoadsChart results={results} />

            <BulkLoadsMetricsLoadOverview />
          </div>
        </BulkLoadsExpandContent>

        <BulkLoadsExpandContent title="Triggering" titleSize="30px">
          <div className="loads-triggering">
            <LoaderTriggeringForm
              loaderTriggerData={loaderTriggerData}
              loaderTriggerEventStatus={loaderTriggerEventStatus}
              loaderTriggerActions={loaderTriggerActions}
              loaderTriggerGetEventStatusActions={loaderTriggerGetEventStatusActions}
            >
            </LoaderTriggeringForm>
          </div>
        </BulkLoadsExpandContent>

      </PageContainer>
    );
  }
}

BulkLoads.propTypes = {
  loading: PropTypes.bool,
  texts: PropTypes.shape({
    textIntro: PropTypes.string,
  }),
  error: PropTypes.object,
  results: PropTypes.arrayOf(BULK_LOAD_PROP_TYPES_RESULT_ITEM),
  resultsRaw: PropTypes.object,
  resultsTotalCount: PropTypes.number,
  load: PropTypes.func,
  search: PropTypes.func,
  refresh: PropTypes.func,
  refreshing: PropTypes.bool,
  searchParams: BULK_LOAD_PROP_TYPES_SEARCH_PARAMS,
};

BulkLoads.defaultProps = {
  resultsRaw: {}
}

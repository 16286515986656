import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { BULK_LOADS_LOAD, BULK_LOADS_REFRESH, BULK_LOADS_SEARCH } from '../actions/BulkLoadsActions';
import loaderTriggerActions from '../actions/LoaderTriggerActions';
import loaderTriggerGetEventStatusActions from '../actions/LoaderTriggerGetEventStatusActions';
import { getBulkLoadsTexts } from '../selectors/BulkLoads/bulkLoadsTextSelector';
import {
  getBulkLoadsError,
  getBulkLoadsLoading,
  getBulkLoadsRefreshing,
  getBulkLoadsResultsCount,
  getBulkLoadsSearchParams,
  getBulkLoadsResultsRaw
} from '../selectors/BulkLoads/bulkLoadsCommonSelectors';
import { getLoaderTriggerData } from '../selectors/LoaderTrigger/loaderTriggerSelector'
import { getloaderTriggerEventStatus } from '../selectors/LoaderTrigger/loaderTriggerSelector'
import { BulkLoads } from '../components/BulkLoads/BulkLoads';
import { getBulkLoadsResults } from '../selectors/BulkLoads/bulkLoadsResultsSelector';

const mapStateToProps = createStructuredSelector({
  loading: getBulkLoadsLoading,
  texts: getBulkLoadsTexts,
  error: getBulkLoadsError,
  results: getBulkLoadsResults,
  resultsTotalCount: getBulkLoadsResultsCount,
  searchParams: getBulkLoadsSearchParams,
  resultsRaw: getBulkLoadsResultsRaw,
  refreshing: getBulkLoadsRefreshing,
  loaderTriggerData: getLoaderTriggerData,
  loaderTriggerEventStatus: getloaderTriggerEventStatus
});

const mapDispatchToProps = (dispatch) => {
  return {
    load: () => dispatch(BULK_LOADS_LOAD.request()),
    search: (options) => dispatch(BULK_LOADS_SEARCH.request(options)),
    refresh: () => dispatch(BULK_LOADS_REFRESH.request()),
    loaderTriggerActions: bindActionCreators(loaderTriggerActions, dispatch),
    loaderTriggerGetEventStatusActions: bindActionCreators(loaderTriggerGetEventStatusActions, dispatch),
  }
};

export const BulkLoadsPage = connect(mapStateToProps, mapDispatchToProps)(BulkLoads);

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PacmanLoader from 'react-spinners/PacmanLoader';

import './Loader.css';

const ParamTransferLoader = React.memo(function ParamTransferLoader({ color, size }) {
  return (
    <div className="param-transfer-loader">
      <PacmanLoader
        color="#474b4e"
        size={15}
      />
    </div>
  );
});

ParamTransferLoader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

ParamTransferLoader.defaultProps = {
  color: '#474b4e',
  size: 15
};

export default ParamTransferLoader;

import React from 'react';
import PropTypes from 'prop-types';
import _, { defaultsDeep } from 'lodash';
import { FormattedMessage } from 'react-intl';

const ToastMessage = ({
  title,
  description,
  values,
  onClick
}) => {
  const defaults = {
    p: (...value) => <p>{value}</p>,
    strong: (...value) => <strong>{value}</strong>
  };

  return (
    <div style={{ maxWidth: '100%' }} onClick={onClick}>
      <h6><FormattedMessage id={title} values={defaultsDeep({}, values, defaults)} /></h6>
      {description ? <p><FormattedMessage id={description} values={defaultsDeep({}, values, defaults)} /></p> : ''}
    </div>
  );
};

ToastMessage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  values: PropTypes.object,
  onClick: PropTypes.func
};

ToastMessage.defaultProps = {
  description: '',
  values: {},
  onClick: _.noop
};

export default ToastMessage;

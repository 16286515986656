import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import Toggle from 'react-toggle';

const ResendMailIfPending = React.memo(({
  editValueField,
  workguide
}) => {
  const value = get(workguide, 'resendMailIfPending', false);

  return (
    <div className="workguide-detail-general-resend-mail-if-pending">
      <FormGroup>
        <ControlLabel>
          E-Mail im Status "Pendent" erneut versenden
        </ControlLabel>

        <br />

        <Toggle
          checked={value}
          onChange={() => editValueField('', 'workguidePropery', { key: 'resendMailIfPending', value: !value })}
        />
      </FormGroup>
    </div>
  );
});

ResendMailIfPending.propTypes = {
  editValueField: PropTypes.func,
  workguide: PropTypes.object.isRequired
};

ResendMailIfPending.defaultProps = {
  editValueField: noop
};

export default ResendMailIfPending;

import axios from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import {
  EMAIL_SUBSCRIPTIONS_SAVE,
  EMAIL_SUBSCRIPTIONS_SEARCH
} from '../../actions/EmailSubscriptionsActions';
import {
  getEmailSubscriptionsEntityCodes,
  getEmailSubscriptionsItemsRaw,
  getEmailSubscriptionsSearchParams
} from '../../selectors/EmailSubscriptions/emailSubscriptionsCommonSelectors';
import { EMAIL_SUBSCRIPTIONS_PAGE_SIZE } from '../../constants/EmailSubscriptionsConstants';
import { isNil } from 'lodash';

export function* emailSubscriptionsSaveSaga({ data: formData, meta: itemId }) {
  try {
    yield put(EMAIL_SUBSCRIPTIONS_SAVE.pending());
    const items = yield select(getEmailSubscriptionsItemsRaw);
    const entityCodes = yield select(getEmailSubscriptionsEntityCodes);
    const isNew = !items.some((it) => it.id === itemId);
    const searchParams = select(getEmailSubscriptionsSearchParams);

    

    const updatedItem = {
      id: itemId,
      email: formData.email,
      title: formData.title,
      firstName: formData.firstName,
      lastName: formData.lastName,
      gender: formData.gender,
      language: formData.language,
      subscriptionState: entityCodes.find((it) => it.id === formData.subscriptionState),
      subscriptionType: entityCodes.filter((it) => formData.subscriptionType.indexOf(it.id) !== -1),
    };

    const urlSuffix = isNil(itemId) ? '' : `/${itemId}`

    yield call(axios, {
      method: isNil(itemId) ? 'post' : 'put',
      url: `${EndpointName.EMAIL_SUBSCRIPTIONS}` + urlSuffix,
      data: updatedItem,
    });

    yield put(EMAIL_SUBSCRIPTIONS_SAVE.success({
      items: isNew
        ? items
        : items.map((it) => it.id === itemId ? updatedItem : it)
    }));

    if (isNew) {
      yield put(EMAIL_SUBSCRIPTIONS_SEARCH.request({
        ...searchParams,
        limit: EMAIL_SUBSCRIPTIONS_PAGE_SIZE,
        offset: 0,
        rawData: []
      }))
    }
  } catch (error) {
    yield put(EMAIL_SUBSCRIPTIONS_SAVE.failure(error));
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { get, noop, isNil } from 'lodash';
import Toggle from 'react-toggle';

class ExcludeFromParamTransfer extends React.PureComponent {
  render() {
    const {
      editValueField,
      workguide
    } = this.props;

    return (
      <div className="workguide-detail-general--exclude-from-param-transfer">
        <FormGroup>
          <ControlLabel>
            Vom Param-Transfer ausschliessen
          </ControlLabel>

          <br />

          <Toggle
            checked={get(workguide, 'excludeFromParamTransfer', false)}
            onChange={() => editValueField(
              '',
              'workguidePropery',
              { key: 'excludeFromParamTransfer', value: !get(workguide, 'excludeFromParamTransfer', false) }
            )}
          />
        </FormGroup>
      </div>
    );
  }
}

ExcludeFromParamTransfer.propTypes = {
  editValueField: PropTypes.func,
  workguide: PropTypes.object.isRequired
};

ExcludeFromParamTransfer.defaultProps = {
  editValueField: noop
};

export default ExcludeFromParamTransfer;

import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import {
  ANALYTICS_LOGS_COMPARISON,
  ANALYTICS_LOGS_DETAILS,
  ANALYTICS_LOGS_LOAD,
  ANALYTICS_LOGS_SEARCH,
} from '../actions/AnalyticsLogsActions';
import {
  getAnalyticsLogsDates,
  getAnalyticsLogsError,
  getAnalyticsLogsLoading,
  getAnalyticsLogsLoadingComparison,
  getAnalyticsLogsLoadingDetails,
  getAnalyticsLogsLoadingSearch,
  getAnalyticsLogsSearchResults,
  getAnalyticsLogsText,
} from '../selectors/AnalyticsLogs/analyticsLogsCommonSelectors';
import { ANALYTICS_LOGS_SEARCH_FORM_NAME, AnalyticsLogs } from '../components/AnalyticsLogs/AnalyticsLogs';
import { getAnalyticsLogsDepartments } from '../selectors/AnalyticsLogs/analyticsLogsDepartmetSelector';
import { getAnalyticsLogsDivision } from '../selectors/AnalyticsLogs/analyticsLogsDivisionSelector';
import { getAnalyticsLogsUsers } from '../selectors/AnalyticsLogs/analyticsLogsUserSelector';
import { getAnalyticsLogsRequestDetails } from '../selectors/AnalyticsLogs/analyticsLogsRequestDetails';
import { getAnalyticsLogsEndpointOptions } from '../selectors/AnalyticsLogs/analyticsLogsEndpointOptionsSelector';
import { getLanguage, getTenant } from '../selectors/commonSelectors';
import { getAnalyticsLogsComparisonDetails } from '../selectors/AnalyticsLogs/analyticsLogsComparisonDetails';

const mapStateToProps = createStructuredSelector({
  loading: getAnalyticsLogsLoading,
  loadingSearch: getAnalyticsLogsLoadingSearch,
  loadingDetails: getAnalyticsLogsLoadingDetails,
  loadingComparison: getAnalyticsLogsLoadingComparison,
  error: getAnalyticsLogsError,
  text: getAnalyticsLogsText,
  searchResults: getAnalyticsLogsSearchResults,
  requestDetails: getAnalyticsLogsRequestDetails,
  departments: getAnalyticsLogsDepartments,
  divisions: getAnalyticsLogsDivision,
  users: getAnalyticsLogsUsers,
  dates: getAnalyticsLogsDates,
  currentValues: getFormValues(ANALYTICS_LOGS_SEARCH_FORM_NAME),
  locale: getLanguage,
  endpoints: getAnalyticsLogsEndpointOptions,
  comparisonDetails: getAnalyticsLogsComparisonDetails,
  tenant: getTenant,
});

const mapDispatchToProps = {
  load: () => ANALYTICS_LOGS_LOAD.request(),
  search: (options) => ANALYTICS_LOGS_SEARCH.request(options),
  getRequestDetails: (requestId, createdAt) => ANALYTICS_LOGS_DETAILS.request({ requestId, createdAt }),
  getComparisonDetails: (first, second) => ANALYTICS_LOGS_COMPARISON.request({ first, second }),
};

export const AnalyticsLogsPage = connect(mapStateToProps, mapDispatchToProps)(AnalyticsLogs);

import axios from 'axios';

export const GET = 'adminUI/Checkpoints/GET';
export const PUT = 'adminUI/Checkpoints/PUT';
export const DEL = 'adminUI/Checkpoints/DEL';
export const SET_DATA = 'adminUI/Checkpoints/SET_DATA';
export const SET_CHANGED = 'adminUI/Checkpoints/SET_CHANGED';
export const GET_MODULES = 'adminUI/Checkpoints/GET_MODULES';

export function setCheckpoints(checkpoints) {
  return (dispatch) => {
    dispatch({
      type: SET_DATA,
      data: checkpoints,
    });
  };
}

export function setChanged(changed) {
  return (dispatch) => {
    dispatch({
      type: SET_CHANGED,
      data: changed,
    });
  };
}

export function getCheckpoints() {
  return (dispatch) => {
    dispatch({
      type: GET,
      payload: axios({
        url: '/checklist/checkpoint/?limit(1000)',
      }),
    });
  };
}

export function getModules() {
  return (dispatch) => {
    dispatch({
      type: GET_MODULES,
      payload: axios({
        url: '/core/module/?limit(20)in(id,(financing,investment,provision,retirement,basicServices))&sort(+order)',
      }),
    });
  };
}

export function updateCheckpoints(checkpoints, removedCheckpoints = []) {
  return (dispatch) => {
    checkpoints.map((checkpoint) => { // eslint-disable-line array-callback-return
      if (checkpoint.hasChanged) {
        delete checkpoint.hasChanged; // eslint-disable-line no-param-reassign
        dispatch({
          type: PUT,
          payload: axios({
            url: `/checklist/checkpoint/${checkpoint.id}`,
            method: 'put',
            data: checkpoint,
          }),
        });
      }
    });
    removedCheckpoints.forEach((checkpointId) => {
      dispatch({
        type: DEL,
        payload: axios({
          url: `/checklist/checkpoint/${checkpointId}`,
          method: 'delete',
        }),
      });
    });
  };
}

import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import clientInfo from '../../clientInfo';

import logo from './logo.png';

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <h6><img alt="copyright" src={logo} style={{ height: '32px', margin: '0px' }} />Copyright &copy;
              { moment().year() }
            </h6>
          </div>
          <div className="col-sm-6" style={{ textAlign: 'right' }}>
            <h6>Version: {_.get(clientInfo, "version")} ({moment(clientInfo.timestamp).format('L LT')})</h6>
          </div>
        </div>
      </div>
    </footer>
  );
}

import './SetManagementPricingItem.css';

import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ModalBody, ModalDialog, ModalHeader } from '../Common/Modal';
import { TableCell, TableRow } from '../Common/Table';
import { SetManagementPricingEdit } from './SetManagementPricingEdit';

export class SetManagementPricingItem extends React.Component {
  state = {
    isModalOpen: false,
  };

  render() {
    const { price, bundles, products, reductionTypes } = this.props;
    const bundle = bundles.find((it) => it.value === price.bundle);
    const product = products.find((it) => it.value === price.product);
    const reductionType = reductionTypes.find((it) => it.value === price.reductionType);

    return (
      <TableRow className="set-management-pricing-item">
        <TableCell className="set-management-pricing-item__bundle">
          {bundle ? bundle.label : null}
        </TableCell>
        <TableCell className="set-management-pricing-item__product">
          {product ? product.label : null}
        </TableCell>
        <TableCell className="set-management-pricing-item__reduction-type">
          {reductionType ? reductionType.label : null}
        </TableCell>
        <TableCell className="set-management-pricing-item__reduction-value">
          {price.reductionValue && <FormattedNumber value={price.reductionValue}/>}
        </TableCell>
        <TableCell className="set-management-pricing-item__control">
          <span className="icon-025-edit" onClick={this.openModal}/>
          {this.renderModal()}
        </TableCell>
      </TableRow>
    );
  }

  renderModal() {
    const { isModalOpen } = this.state;
    const { price, bundles, products, reductionTypes } = this.props;

    return (
      <ModalDialog show={isModalOpen} onHide={this.closeModal}>
        <ModalHeader closeButton={true}>
          <Modal.Title>
            <div className="set-management-pricing-item__modal-title">ID: {price.id}</div>
          </Modal.Title>
        </ModalHeader>
        <ModalBody>
          <SetManagementPricingEdit
            price={price}
            bundles={bundles}
            products={products}
            reductionTypes={reductionTypes}
            onSave={this.save}
            onCancel={this.closeModal}
          />
        </ModalBody>
      </ModalDialog>
    );
  }

  openModal = () => this.setState({ isModalOpen: true });
  closeModal = () => this.setState({ isModalOpen: false });
  save = (price) => {
    const { onChange } = this.props;
    onChange(price);
    this.closeModal();
  };
}

SetManagementPricingItem.propTypes = {
  price: PropTypes.object.isRequired,
  bundles: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  reductionTypes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

import axios from 'axios';
import { all, call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { FINANCING_RATES_LOAD_ACTIONS } from '../../actions/FinancingRatesActions';
import { financingRatesLoadForwardPeriodsSaga } from './financingRatesLoadForwardPeriodsSaga';

export function* financingRatesLoadSaga() {
  try {
    yield put(FINANCING_RATES_LOAD_ACTIONS.pending());

    const [
      { data: durations },
      { data: forwards },
      { data: baseRates },
      { data: refiRates },
      forwardPeriods,
    ] = yield all([
      call(axios, { url: getAll(EndpointName.FINANCING_DURATION) }),
      call(axios, { url: getAll(EndpointName.FINANCING_FORWARD) }),
      call(axios, { url: getAll(EndpointName.FINANCING_RATE) }),
      call(axios, { url: getAll(EndpointName.FINANCING_REFI_INTEREST_RATE) }),
      call(financingRatesLoadForwardPeriodsSaga),
    ]);

    yield put(FINANCING_RATES_LOAD_ACTIONS.success({
      durations,
      forwards,
      baseRates,
      refiRates,
      forwardPeriods,
    }));
  } catch (error) {
    yield put(FINANCING_RATES_LOAD_ACTIONS.failure(error));
  }
}

function getAll(endpoint) {
  return `${endpoint}/?limit(9999)`;
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, isNil, noop } from 'lodash';
import { Button, Panel } from 'react-bootstrap';
import Select from 'react-select';
import Toggle from 'react-toggle';
import update from 'immutability-helper';

import Title from './ActionTitle';
import DragHandle from '../../DragHandle';
import * as configuration from './Configuration/index';

function getConfigurationForm(component) {
  return get(configuration, component);
}

const possibleActions = {
  pre: [],
  post: [{
    value: 'RedirectToWorkguide',
    label: 'Anderen Workguide aufrufen'
  }, {
    value: 'ServiceCall',
    label: 'Service aufrufen'
  }, {
    value: 'ExternalServiceCall',
    label: 'Externen Service aufrufen'
  }, {
    value: 'NoteToEDossier',
    label: 'Notiz als PDF ins EDossier exportieren'
  }, {
    value: 'CreateCustomerSuspension',
    label: 'Kundensperrung auslösen'
  }, {
    value: 'DeleteCustomerSuspension',
    label: 'Kundensperrung aufheben'
  }, {
    value: 'CreateCardSuspension',
    label: 'Kartensperrung(en) auslösen'
  }, {
    value: 'DeleteCardSuspension',
    label: 'Kartensperrung(en) aufheben'
  }]
};

class WorkguideAction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: props.edit,
      action: props.action
    };

    this.onActionChange = this.onActionChange.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onDependsChange = this.onDependsChange.bind(this);
    this.onExecuteOnStatusChange = this.onExecuteOnStatusChange.bind(this);
    this.onParamChange = this.onParamChange.bind(this);
    this.onParamsChange = this.onParamsChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onStopExecutionOnErrorChange = this.onStopExecutionOnErrorChange.bind(this);
  }

  onActionChange(selected) {
    const { action } = this.state;
    this.setState({ action: { ...action, component: (selected) ? selected.value : undefined } });
  }

  onStopExecutionOnErrorChange() {
    const { action } = this.state;
    const params = get(action, 'params', {});
    const stopExecutionOnError = get(params, 'stopExecutionOnError', false);

    const updated = {
      ...action,
      params: {
        ...params,
        stopExecutionOnError: !stopExecutionOnError
      }
    };

    this.setState({ action: updated });
  }

  onTitleChange(language, value) {
    const { action } = this.state;
    this.setState({
      action: { ...action, title: { ...action.title, [language]: value } }
    });
  }

  onDependsChange(selected) {
    const { action } = this.state;

    const componentIds = selected.map((s) => get(s, 'value'));

    this.setState({
      action: { ...action, params: { ...action.params, dependsOn: componentIds } }
    });
  }

  onExecuteOnStatusChange(selected) {
    const { action } = this.state;

    const values = selected.map((s) => get(s, 'value'));

    this.setState({
      action: { ...action, params: { ...action.params, executeOnStatus: values } }
    });
  }

  onParamChange(param, value) {
    const { action } = this.state;

    const params = get(action, 'params', {});
    const updated = isNil(value)
      ? update(params, { $unset: [param] })
      : update(params, { [param]: { $set: value } });

    this.setState({
      action: { ...action, params: updated }
    });
  }

  onParamsChange(params) {
    const { action } = this.state;
    const updated = { ...action, params: { ...action.params, ...params } };

    this.setState({ action: updated });
  }

  onSubmit() {
    const { action } = this.state;
    const { onChange } = this.props;

    this.setState({ edit: false });
    onChange(action);
  }

  onCancel() {
    const { action, onCancel } = this.props;
    this.setState({ action, edit: false });

    onCancel();
  }

  getDependsOnOptions() {
    const { components, language } = this.props;

    return components
      .filter((component) => get(component, 'component') === 'PostActionCondition')
      .map((component) => ({ value: get(component, 'props.id'), label: get(component, `props.title.${language}`) }));
  }

  getExecuteOnStatusOptions() {
    const { codes, language } = this.props;

    const options = get(codes, 'workguideStatus', [])
      .map((code) => ({ value: get(code, 'id'), label: get(code, `text.${language}`) }));

    return options;
  }

  /**
   * Render action
   *
   * @return {ReactElement} markup
   */
  renderAction() {
    const { action } = this.state;
    const {
      codes,
      components,
      fetchCodes,
      language,
      onRemove,
      type,
      workguides,
    } = this.props;
    const Configuration = getConfigurationForm(action.component);
    const dependsOn = get(action, 'params.dependsOn', []).map((id) => {
      const component = components.find((c) => get(c, 'props.id') === id);

      return get(component, `props.title.${language}`);
    });

    const executeOnStatus = get(action, 'params.executeOnStatus', []).map((id) => {
      const code = get(codes, 'workguideStatus', []).find((c) => get(c, 'id') === id);

      return get(code, `text.${language}`);
    });

    return (
      <div className="SortableHOCItem">
        <div className="workguide-action--item">
          <div className="workguide-action--item-draghandle">
            <DragHandle />
          </div>

          <div className="workguide-action--item-content">
            <Panel>
              <Panel.Heading style={{ height: '40px' }}>
                {get(get(possibleActions, type, []).find((a) => a.value === action.component), 'label')}
                <span className="pull-right">
                  <span onClick={() => this.setState({ edit: true })}><i className="glyphicon glyphicon-pencil" /></span>
                  <span onClick={onRemove}><i className="glyphicon glyphicon-remove" /></span>
                </span>
              </Panel.Heading>
              <Panel.Body>
                <strong>Titel</strong>
                <br />
                {get(action, 'title.de')}
                <br />
                <br />

                <strong>Ausführung bei Fehler stoppen</strong>
                <br />
                {get(action, 'params.stopExecutionOnError', false) ? 'Ja' : 'Nein'}
                <br />
                <br />

                <strong>Abhängig von</strong>
                <br />
                {dependsOn.join(', ')}
                <br />
                <br />

                <strong>Nur bei folgendem Status ausführen</strong>
                <br />
                {executeOnStatus.join(', ')}
                <br />
                <br />

                {(!isNil(Configuration)) ? (
                  <React.Fragment>
                    <strong>Parameter</strong>
                    <Configuration
                      codes={codes}
                      edit={false}
                      fetchCodes={fetchCodes}
                      params={action.params}
                      onChange={this.onParamChange}
                      onChangeMany={this.onParamsChange}
                      workguides={workguides}
                    />
                  </React.Fragment>
                ) : null}
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Render form
   *
   * @return {ReactElement} markup
   */
  renderForm() {
    const { action } = this.state;
    const {
      codes,
      fetchCodes,
      type,
      workguides
    } = this.props;

    const Configuration = getConfigurationForm(action.component);

    return (
      <div>
        <h4>Aktion</h4>
        <Select
          value={action.component}
          options={get(possibleActions, type, []).map((a) => a)}
          onChange={this.onActionChange}
        />

        <h4>Abhängig von</h4>
        <Select
          value={get(action, 'params.dependsOn')}
          options={this.getDependsOnOptions()}
          onChange={this.onDependsChange}
          multi
        />

        <h4>Nur bei folgendem Status ausführen</h4>
        <Select
          value={get(action, 'params.executeOnStatus')}
          options={this.getExecuteOnStatusOptions()}
          onChange={this.onExecuteOnStatusChange}
          multi
        />

        <Title
          values={action.title}
          onChange={this.onTitleChange}
        />

        <h4>Ausführung bei Fehler stoppen</h4>
        <Toggle
          checked={get(action, 'params.stopExecutionOnError', false)}
          onChange={this.onStopExecutionOnErrorChange}
        />

        {(!isNil(Configuration)) ? (
          <React.Fragment>
            <h4>Parameter</h4>
            <Configuration
              codes={codes}
              edit
              fetchCodes={fetchCodes}
              params={action.params}
              onChange={this.onParamChange}
              onChangeMany={this.onParamsChange}
              workguides={workguides}
            />
          </React.Fragment>
        ) : null}

        <div style={{ width: '100%', height: '50px' }}>
          <div className="pull-right" style={{ paddingTop: '10px' }}>
            <Button bsStyle="primary" onClick={this.onSubmit}>Speichern</Button>
            <Button onClick={this.onCancel}>Verwerfen</Button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { edit } = this.state;

    return (edit) ? this.renderForm() : this.renderAction();
  }
}

WorkguideAction.propTypes = {
  action: PropTypes.object,
  codes: PropTypes.object,
  components: PropTypes.array,
  edit: PropTypes.bool,
  fetchCodes: PropTypes.func,
  language: PropTypes.string,
  languages: PropTypes.array,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  type: PropTypes.string,
  workguides: PropTypes.array
};

WorkguideAction.defaultProps = {
  action: {},
  codes: {},
  components: [],
  edit: false,
  fetchCodes: noop,
  language: 'de',
  languages: ['de', 'fr', 'en'],
  onCancel: noop,
  onChange: noop,
  onRemove: noop,
  type: 'post',
  workguides: []
};

export default WorkguideAction;

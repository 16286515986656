import axios from 'axios';
import { call, put, all } from 'redux-saga/effects';
import { NOTICE_IMPORT_DELETE } from '../../actions/NoticeImportActions';
import { EndpointName } from '../../constants/EndpointName';
import { LONG_RESPONSE_ITEM_COUNT, PARALLEL_REQUESTS_COUNT } from '../../constants/NoticeImport';
import getActivitesAfterCount from './getActivitiesAfterCount';

export function* noticeImportDeleteSaga(action) {
  try {
    const { user, fileId } = action.data;

    yield put(NOTICE_IMPORT_DELETE.pending({ fileId }));

    //request item for delete
    const activityForDelete = yield call(axios, {
      url: `${EndpointName.WORK_ACTIVITY}/?eq(massUploadFile.ref.$id,${fileId})&limit(10)`,
      method: 'get',
      timeout: 60000,
      headers: {
        'x-graviton-total-count': 1
      }
    });
    const activityForDeleteCount = Number(
      activityForDelete.headers['x-total-count']
      || activityForDelete.headers['x-record-count']
    );

    let activityForDeleteIds = [];
    const pages = [];
    for (let i = 0; i < Math.ceil(activityForDeleteCount / LONG_RESPONSE_ITEM_COUNT); i++) {
      pages.push(i);
    }
    yield all(
      pages.map((page) => {
        const promise = new Promise((resolve, reject) => {
          axios({
            url: `${EndpointName.WORK_ACTIVITY}/?eq(massUploadFile.ref.$id,${fileId})`
                  + `&limit(${LONG_RESPONSE_ITEM_COUNT},${ page * LONG_RESPONSE_ITEM_COUNT })`,
            method: 'get',
            timeout: 60000,
          }).then(function (response) {
            activityForDeleteIds = activityForDeleteIds.concat(response.data.map(item => item.id));
            resolve(response);
          }).catch(function (error) {
            resolve(error);
          });
        });
        return promise;
      })
    );

    // delete items
    let success = 0;
    let fail = 0;
    const itemCount = activityForDeleteIds.length;
    const iterationCount = Math.ceil(itemCount / PARALLEL_REQUESTS_COUNT);
    for (let i = 0; i < iterationCount; i++) {
      const start = i * PARALLEL_REQUESTS_COUNT;
      const processIds = activityForDeleteIds.slice(start, start + PARALLEL_REQUESTS_COUNT);
      yield all(
        //eslint-disable-next-line
        processIds.map(activity => {
          const promise = new Promise((resolve, reject) => {
            axios({
              url: `${EndpointName.WORK_ACTIVITY}/${activity}`,
              method: 'delete',
            }).then(function (response) {
              success += 1;
              resolve(response);
            }).catch(function (error) {
              console.error(error);
              fail += 1;
              resolve(error);
            });
          });
          return promise;
        })
      );
      yield put(NOTICE_IMPORT_DELETE.progress({
        fileId,
        success: success,
        fail: fail,
        action: 'deleted',
        total: activityForDeleteIds.length,
      }));
    }

    const activityAfterDeleteCount = yield call(getActivitesAfterCount, fileId);

    if (activityAfterDeleteCount === 0) {
      yield call(axios, {
        url: `${EndpointName.FILE}/${fileId}`,
        method: 'delete',
      });
    } else {
      yield call(axios, {
        url: `${EndpointName.FILE}/${fileId}`,
        method: 'put',
        data: {
          id: fileId,
          metadata: {
            additionalProperties: [
              {
                name: 'imported',
                value: 'imported',
              },
              {
                name: 'lastAction',
                value: 'deleted',
              },
              {
                name: 'totalRowsCount',
                value: `${activityForDeleteIds.length}`,
              },
              {
                name: 'importedRowsCount',
                value: `${success}`,
              },
              {
                name: 'failRowsCount',
                value: `${fail}`,
              },
              {
                name: 'username',
                value: user.username,
              },
              {
                name: 'status',
                value: 'success',
              },
            ],
          },
          links: [{
            type: 'module',
            $ref: `/core/module/adminv2-notice-import`,
          }],
        },
      });
    }
    yield put(NOTICE_IMPORT_DELETE.success({
      total: activityForDeleteIds.length,
      success: success,
      fail: fail,
      fileId,
    }));
  } catch (error) {
    console.error(error);
    yield put(NOTICE_IMPORT_DELETE.failure(error));
  }
}

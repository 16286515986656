import { call } from 'redux-saga/effects';
import { get, isNil } from 'lodash';

/**
 * Default transfer saga.
 * Just put data to target
 *
 * @param   {Object}  item    Item to transfer
 * @param   {Object}  target  Target system from state.paramTransfer.target
 *
 * @return  {Object} result Result
 */
export default function* defaultTransferItem({ item, target }) {
  const axiosInstance = get(target, 'data.axiosInstance');

  const { uri, data } = item;

  if (isNil(axiosInstance)) {
    throw new Error(`Unable to transfer item ${item.dataKey} to the target system! No axios instance given`);
  }

  if (isNil(uri)) {
    throw new Error(`Unable to transfer item ${item.dataKey} to the target system! No uri given`);
  }

  if (isNil(data)) {
    throw new Error(`Unable to transfer item ${item.dataKey} to the target system! No data given`);
  }

  const result = yield call(axiosInstance, {
    url: uri,
    method: 'put',
    data
  });

  return result;
}

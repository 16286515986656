import React, { Fragment } from 'react';
import { ControlLabel, FormGroup, Button, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { FormInput } from './FormControls';

export function BasicProductsDocumentsRenderer(props) {
  const { fields } = props;
  const items = fields.map((document, index) => {
    const field = `documents[${index}]`;
    const style = {
      background: index % 2 === 0 ? '#ffffff' : '#f4f6f8',
      paddingTop: '15px',
    };
    const removeHandler = () => fields.remove(index);
    return (
      <FormGroup key={index} style={style}>
        <Col sm={1}>&nbsp;</Col>
        <Col sm={9}>
          <Field component={FormInput}
                 label="Titel"
                 name={`${field}.title`}
                 type="text"/>
          <Field component={FormInput}
                 label="URL"
                 name={`${field}.url`}
                 type="text"/>
          <Field component={FormInput}
                 label="Quelle"
                 name={`${field}.source`}
                 type="text"/>
        </Col>
        <Col sm={2} style={{lineHeight: '128px'}}>
          <Button bsStyle="default"
                  name="removeItem"
                  style={{verticalAlign: 'middle'}}
                  onClick={removeHandler}>
            <span className="icon-008-bin"/>
          </Button>
        </Col>
      </FormGroup>
    );
  });

  const addDocument = () => fields.push({});
  return (
    <Fragment>
      <FormGroup>
        <Col componentClass={ControlLabel} sm={3}>Dokumente</Col>
        <Col sm={9}>
          <Button bsStyle="primary" name="addItem" onClick={addDocument}>
            <span className="icon-061-plus" /> Neues Dokument
          </Button>
        </Col>
      </FormGroup>
      {items}
    </Fragment>
  );
}

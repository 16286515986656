import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PanelGroup, Panel, Form, FormGroup, FormControl } from 'react-bootstrap';
import jmespath from 'jmespath';

export default class ConditionForm extends Component {
  constructor() {
    super();
    this.state = {
      examples: [
        {
          title: 'Liegenschaftspreis grösser als 1 Mio.',
          value: 'to_number(dossierData.financing.consultation.realEstate.price) > `1000000`',
        },
        {
          title: "Anlageprofil 'HOCH'",
          value: "dossierData.investment.consultation.riskToleranceAnswerStorage.calculatedProfile.id == 'profile-4'",
        },
      ],
    };
  }

  render() {
    const {
      module, section, index, onUpdate, displayCondition,
    } = this.props;
    let jmesError = false;
    const displayConditionLength = displayCondition === undefined ? 0 : displayCondition.length;
    // just fire the displayConditon syntax against this dummy object to verify
    // the correct syntax
    try {
      jmespath.search({ foo: 'value' }, displayCondition);
    } catch (e) {
      jmesError = true;
    }
    const examples = this.state.examples.map((example, i) => {
      const boundItemClick = onUpdate.bind(this, 'updateItem', module, section, index, {
        name: 'displayCondition',
        value: example.value,
      });
      return (
        <Panel
          header={example.title}
          key={example.title}
          eventKey={i}
        >
          <span role="button" tabIndex="-1" className="icon-193-website" onClick={boundItemClick} />
          {' '}{example.value}
        </Panel>);
    });

    return (
      <div>
        <Form>
          <FormGroup
            controlId="displayCondition"
            // eslint-disable-next-line no-nested-ternary
            validationState={displayConditionLength > 0 ? jmesError ? 'error' : 'success' : null}
          >
            <FormControl
              rows="5"
              componentClass="textarea"
              placeholder="Anzeigebedingungen"
              value={displayCondition}
              name="displayCondition"
              onChange={onUpdate.bind(this, 'updateItem', module, section, index)}
            />
            <FormControl.Feedback />
          </FormGroup>
        </Form>
        <a rel="noopener noreferrer" target="_blank" href="http://jmespath.org/tutorial.html">Weitere Informationen zur
          Einschränkungs-Syntax
        </a>
        <hr />
        <h3>Funktionierende Beispiele</h3>
        <PanelGroup defaultActiveKey="1" accordion>
          {examples}
        </PanelGroup>
      </div>
    );
  }
}

ConditionForm.propTypes = {
  module: PropTypes.number.isRequired,
  section: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  displayCondition: PropTypes.string.isRequired,
};

import './SetManagementPricing.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Table, TableCell, TableHead, TableRow } from '../Common/Table';
import { SelectControl } from '../Common/SelectControl';
import { SetManagementPricingItem } from './SetManagementPricingItem';

export class SetManagementPricing extends React.Component {
  state = {
    bundle: undefined,
    product: undefined,
  };

  render() {
    const { bundle, product } = this.state;
    const { bundles, products, reductionTypes } = this.props;

    return (
      <div className="set-management-pricing">
        <div className="set-management-pricing__title">
          <FormattedMessage id="setManagement_pricing_title"/>
        </div>

        <Table className="set-management-pricing__table">
          <TableRow>
            <TableCell className="set-management-pricing__item-bundle">
              <SelectControl
                className="set-management-pricing__select"
                value={bundle}
                multi={false}
                options={bundles}
                onChange={this.onSelectBundle}
              />
            </TableCell>
            <TableCell className="set-management-pricing__item-product">
              <SelectControl
                className="set-management-pricing__select"
                value={product}
                multi={false}
                options={products}
                onChange={this.onSelectProduct}
              />
            </TableCell>
            <TableCell colSpan={4}/>
          </TableRow>
          <TableHead>
            <TableCell className="set-management-pricing__item-bundle">
              <FormattedMessage id="setManagement_pricing_bundle"/>
            </TableCell>
            <TableCell className="set-management-pricing__item-product">
              <FormattedMessage id="setManagement_pricing_product"/>
            </TableCell>
            <TableCell className="set-management-pricing__item-reduction-type">
              <FormattedMessage id="setManagement_pricing_reductionType"/>
            </TableCell>
            <TableCell className="set-management-pricing__item-reduction-value">
              <FormattedMessage id="setManagement_pricing_reductionValue"/>
            </TableCell>
            <TableCell/>
          </TableHead>
          {this.getPrices().map((price, index) => (
            <SetManagementPricingItem
              key={price.id}
              index={index}
              id={index}
              price={price}
              bundles={bundles}
              products={products}
              reductionTypes={reductionTypes}
              onChange={this.onChange}
            />
          ))}
        </Table>
      </div>
    );
  }

  onSelectBundle = (bundle) => this.setState({ bundle });
  onSelectProduct = (product) => this.setState({ product });

  onChange = (price) => {
    const { prices, onChange } = this.props;
    onChange(
      prices.map((it) => it.id === price.id ? price : it)
    );
  };

  getPrices() {
    const { prices } = this.props;
    const { bundle, product } = this.state;

    return bundle || product
      ? prices
        .filter((it) => !bundle || it.bundle === bundle.value)
        .filter((it) => !product || it.product === product.value)
      : prices;
  }
}

SetManagementPricing.propTypes = {
  prices: PropTypes.array.isRequired,
  bundles: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  reductionTypes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

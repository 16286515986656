import ConfigurationForm from '../configuration/Date';

export default {
  key: 'minDate',
  label: 'Minimum Datum',
  validation: 'minDate',
  availableFor: ['DateField'],
  ConfigurationForm,
  get: function(params) {
    return { minDate: params };
  }
}

import './DateTimePicker.css';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import { InputControl } from './InputControl';

export class DateTimePicker extends React.Component {
  state = {
    focused: false,
  };

  constructor(props) {
    super(props);

    this.onDateFocusChange = this.onDateFocusChange.bind(this);
    this.onTimeFocus = this.onTimeFocus.bind(this);
    this.onTimeBlur = this.onTimeBlur.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onHoursChange = this.onHoursChange.bind(this);
    this.onMinutesChange = this.onMinutesChange.bind(this);
  }

  render() {
    const { value, ...restProps } = this.props;
    const { focused } = this.state;

    const date = value ? moment(value) : null;
    const hours = date ? `0${date.hours()}`.substr(-2) : '';
    const minutes = date ? `0${date.minutes()}`.substr(-2) : '';

    return (
      <div className="datetime-picker">
        <div className="datetime-picker__date">
          <SingleDatePicker
            date={date}
            focused={focused}
            onDateChange={this.onDateChange}
            onFocusChange={this.onDateFocusChange}
            disableScroll={false}
            {...restProps}
          />
        </div>
        <div className="datetime-picker__hours">
          <InputControl
            type="number"
            value={hours}
            onChange={this.onHoursChange}
            onFocus={this.onTimeFocus}
            onBlur={this.onTimeBlur}
            readOnly={date === null}
            min={0}
            max={23}
          />
        </div>
        <div className="datetime-picker__colon">:</div>
        <div className="datetime-picker__minutes">
          <InputControl
            type="number"
            value={minutes}
            onChange={this.onMinutesChange}
            onFocus={this.onTimeFocus}
            onBlur={this.onTimeBlur}
            readOnly={date === null}
            min={0}
            max={59}
          />
        </div>
      </div>
    );
  }

  onTimeFocus() {
    const { onFocus } = this.props;
    onFocus();
  }

  onTimeBlur() {
    const { onBlur } = this.props;
    onBlur();
  }

  onDateFocusChange(event) {
    if (event.focused) {
      const { onFocus } = this.props;
      onFocus();

      this.setState({ focused: true });
    } else {
      const { onBlur } = this.props;
      onBlur();

      this.setState({ focused: false });
    }
  }

  onDateChange(newDate) {
    const { onChange } = this.props;
    if (!newDate) {
      onChange(null);
      return;
    }

    const { value } = this.props;
    if (value) {
      const date = moment(value);
      onChange(moment(newDate).hours(date.hours()).minutes(date.minutes()));
    } else {
      onChange(moment(newDate).startOf('day'));
    }
  }

  onHoursChange(event) {
    const { value, onChange } = this.props;
    if (!value) {
      return;
    }

    const hours = event.target.valueAsNumber;
    const normalized = Math.max(Math.min(hours, 23), 0);
    onChange(moment(value).hours(normalized));
  }

  onMinutesChange(event) {
    const { value, onChange } = this.props;
    if (!value) {
      return;
    }

    const minutes = event.target.valueAsNumber;
    const normalized = Math.max(Math.min(minutes, 59), 0);
    onChange(moment(value).minutes(normalized));
  }
}

DateTimePicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

DateTimePicker.defaultProps = {
  onBlur: () => {},
  onFocus: () => {},
};

import './CalendarMonth.css';

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const DAYS_IN_WEEK = 7;

export class CalendarMonth extends React.Component {
  render() {
    const days = this.getDays();

    // days of week starting from Monday
    const weekDays = moment.weekdaysShort().slice(1).concat(moment.weekdaysShort().slice(0, 1));

    return (
      <div className="calendar-month">
        <div className="calendar-month__week">
          {weekDays.map((day) => (
            <div className="calendar-month__week-day" key={day}>
              {day}
            </div>
          ))}
        </div>
        <div className="calendar-month__content">
          {days.map((day, index) => this.renderDay(day, index))}
        </div>
      </div>
    );
  }

  renderDay(day, index) {
    const { renderDay } = this.props;
    const gridPosition = this.getGridPosition(index);
    return (
      <div className="calendar-month__day"
           key={`${gridPosition.col}-${gridPosition.row}`}
           style={{gridColumn: gridPosition.col, gridRow: gridPosition.row}}>
        {renderDay(day, index)}
      </div>
    );
  }

  getDays() {
    const { year, month } = this.props;
    const daysInMonth = moment([year, month]).daysInMonth();
    return Array.from({ length: daysInMonth }, (_, day) => day + 1);
  }

  getGridPosition(index) {
    const { year, month } = this.props;
    const weekDay = moment([year, month, 1]).isoWeekday();
    const gridStart = weekDay + index;
    const col = gridStart % DAYS_IN_WEEK === 0
      ? DAYS_IN_WEEK
      : gridStart % DAYS_IN_WEEK;
    const row = Math.ceil(gridStart / DAYS_IN_WEEK);

    return { col, row };
  }
}

CalendarMonth.propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  renderDay: PropTypes.func.isRequired,
};

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  HOLIDAYS_CREATE_ACTIONS,
  HOLIDAYS_EDIT_ACTIONS,
  HOLIDAYS_LOAD_ACTIONS,
  HOLIDAYS_REMOVE_ACTIONS,
  HOLIDAYS_SAVE_ACTIONS,
  HOLIDAYS_SELECT_YEAR_ACTIONS,
  HOLIDAYS_VIEW_MODE_ACTIONS,
} from '../actions/HolidaysActions';
import {
  getHolidays,
  getHolidaysError,
  getHolidaysList,
  getHolidaysLoading,
  getHolidaysViewMode,
  getHolidaysYear,
} from '../selectors/holidaysSelectors';
import Holidays from '../components/Holidays/Holidays';
import { getLanguage } from '../selectors/commonSelectors';

const mapStateToProps = createStructuredSelector({
  holidays: getHolidays,
  holidaysList: getHolidaysList,
  year: getHolidaysYear,
  loading: getHolidaysLoading,
  error: getHolidaysError,
  viewMode: getHolidaysViewMode,
  locale: getLanguage,
});

const mapDispatchToProps = {
  load: () => HOLIDAYS_LOAD_ACTIONS.request(),
  setViewMode: (viewMode) => HOLIDAYS_VIEW_MODE_ACTIONS.request({ viewMode }),
  selectYear: (year) => HOLIDAYS_SELECT_YEAR_ACTIONS.request({ year }),
  save: () => HOLIDAYS_SAVE_ACTIONS.request(),
  create: (name, startDate, endDate) => HOLIDAYS_CREATE_ACTIONS.request({ name, startDate, endDate }),
  edit: (item, name) => HOLIDAYS_EDIT_ACTIONS.request({ item, name }),
  remove: (item) => HOLIDAYS_REMOVE_ACTIONS.request({ item }),
};

export const HolidaysPage = connect(mapStateToProps, mapDispatchToProps)(Holidays);

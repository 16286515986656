import { LOAD_BASIC_PRODUCTS_ACTIONS } from '../../actions/BasicProductsActions';

const initialState = ({
  products: [],

  basicProducts: [],
  basicAdditions: [],
  basicDocuments: [],
  basicCategories: [],

  coreTypes: [],
  displayClasses: [],
  detailGroups: [],
  changeTypes: [],
  outputClasses: [],

  loading: false,
  error: undefined,
});

export function LoadBasicProductsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_BASIC_PRODUCTS_ACTIONS.PENDING:
      return {...state, loading: true, error: undefined};
    case LOAD_BASIC_PRODUCTS_ACTIONS.SUCCESS:
      const {
        products,

        basicProducts,
        basicAdditions,
        basicDocuments,
        basicCategories,

        coreTypes,
        displayClasses,
        outputClasses,
        detailGroups,
        changeTypes,
      } = action.data;
      return {
        ...state,
        loading: false,
        error: undefined,

        products,
        basicProducts,
        basicAdditions,
        basicDocuments,
        basicCategories,

        coreTypes,
        displayClasses,
        outputClasses,
        detailGroups,
        changeTypes,
      };
    case LOAD_BASIC_PRODUCTS_ACTIONS.FAILURE:
      return {...state, loading: false, error: action.data};
    default:
      return state;
  }
}

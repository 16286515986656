import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Tabs, Tab } from 'react-bootstrap';

import PageContainer from '../components/Common/PageContainer';
import { getWorkguideStatusLoading } from '../selectors/WorkguideStatus/workguideStatusCommonSelectors';
import NettedAccountTab from '../components/WorkguideStatus/NettedAccountsTab';
import AccountSearchTab from '../components/WorkguideStatus/AccountSearchTab';

const WorkguideStatus = props => {
    const { loading } = props;

    return (
        <PageContainer
            title={<FormattedMessage id="workguideStatus_title" />}
            requesting={loading}
            isTransparentLoader={true}
        >
            <Tabs id="workguide_status_tabs">
                <Tab eventKey={1} title={<FormattedMessage id="workguideStatus_nettedAccounts" />}>
                    <NettedAccountTab />
                </Tab>
                <Tab eventKey={2} title={<FormattedMessage id="workguideStatus_searchAccount" />}>
                    <AccountSearchTab />
                </Tab>
            </Tabs>
        </PageContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    loading: getWorkguideStatusLoading
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkguideStatus);
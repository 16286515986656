import './AnalyticsLogsSearchResults.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SortableTable } from '../Common/SortableTable';
import { AnalyticsLogsSearchResultsItem } from './AnalyticsLogsSearchResultsItem';
import { PROP_TYPES_DATES, PROP_TYPES_DETAILS, PROP_TYPES_SEARCH_RESULT_ITEM } from './AnalyticsLogsPropTypes';
import Spinner from '../Spinner';
import { Pagination } from '../Common/Pagination';
import { ModalBody, ModalDialog, ModalFooter, ModalHeader } from '../Common/Modal';
import { Button } from '../Common/Button';
import { AnalyticsLogsSearchResultsChart } from './AnalyticsLogsSearchResultsChart';
import { AnalyticsLogsSearchResultsComparison } from './AnalyticsLogsSearchResultsComparison';

export class AnalyticsLogsSearchResults extends React.PureComponent {
  constructor(props) {
    super(props);
    this.renderItem = this.renderItem.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.openChart = this.openChart.bind(this);
    this.closeChart = this.closeChart.bind(this);
    this.addToComparison = this.addToComparison.bind(this);
    this.closeComparisonModal = this.closeComparisonModal.bind(this);

    this.state = {
      page: 1,
      compareFirst: undefined,
      compareSecond: undefined,
      searchResults: undefined,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.searchResults !== nextProps.searchResults) {
      return {
        page: 1,
        searchResults: nextProps.searchResults,
        compareFirst: undefined,
        compareSecond: undefined,
      };
    }
    return null;
  }

  render() {
    const { loadingSearch } = this.props;
    if (loadingSearch) {
      return <Spinner/>;
    }

    return (
      <div className="analytics-logs-results">
        {this.renderWarning()}
        {this.renderContent()}
        {this.renderPagination()}
      </div>
    );
  }

  renderContent() {
    const searchResults = this.getItems();
    if (!searchResults.length) {
      return null;
    }

    const { page } = this.state;
    const start = (page - 1) * PAGINATION_ITEMS_ON_PAGE;
    const end = page * PAGINATION_ITEMS_ON_PAGE;
    const items = searchResults.slice(start, end);

    return (
      <div className="analytics-logs-results__content">
        {this.renderChartButton()}
        <SortableTable
          items={items}
          sortableFields={SORTABLE_FIELDS}
          renderItem={this.renderItem}
          className="analytics-logs-results__table"
        />
        {this.renderComparisonModal()}
      </div>
    );
  }

  renderItem(item) {
    const { requestDetails, getRequestDetails, loadingDetails } = this.props;
    const { compareFirst } = this.state;

    return (
      <AnalyticsLogsSearchResultsItem
        key={item.id}
        loading={loadingDetails}
        item={item}
        requestDetails={requestDetails}
        getRequestDetails={getRequestDetails}
        addToComparison={this.addToComparison}
        isSelected={compareFirst && compareFirst.id === item.id}
      />
    );
  }

  renderWarning() {
    const { searchResults } = this.props;
    if (searchResults.length < 1000) {
      return null;
    }

    return (
      <div className="analytics-logs-results__alert">
        <FormattedMessage id="analyticsLogs_searchResults_warning"/>
      </div>
    );
  }

  renderPagination() {
    const { page } = this.state;
    const countPages = this.getCountPages();
    if (countPages < 2) {
      return null;
    }

    return (
      <div className="analytics-logs-results__pagination">
        <Pagination
          prev={true}
          next={true}
          first={true}
          last={true}
          boundaryLinks={true}
          ellipsis={true}
          items={countPages}
          maxButtons={7}
          activePage={page}
          onSelect={this.onChangePage}
        />
      </div>
    );
  }

  getItems() {
    const { compareFirst, searchResults } = this.state;
    return searchResults.filter((it) => !compareFirst || (
      it.method === 'PUT' &&
      it.requestUri === compareFirst.requestUri
    ));
  }

  getCountPages() {
    return Math.ceil(this.getItems().length / PAGINATION_ITEMS_ON_PAGE);
  }

  renderChartButton() {
    const { searchResults, dates } = this.props;
    const { isChartOpen } = this.state;
    return (
      <React.Fragment>
        <div className="analytics-logs-results__chart icon-161-signal" onClick={this.openChart}/>

        <ModalDialog show={isChartOpen} onHide={this.closeChart} bsSize="lg">
          <ModalHeader closeButton={true}>
            <FormattedMessage id="analyticsLogs_searchResults_chart"/>
          </ModalHeader>
          <ModalBody>
            <AnalyticsLogsSearchResultsChart
              searchResults={searchResults}
              dates={dates}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.closeChart} bsSize="sm">
              <FormattedMessage id="common_close"/>
            </Button>
          </ModalFooter>
        </ModalDialog>
      </React.Fragment>
    );
  }

  onChangePage(page) {
    this.setState({ page });
  }
  openChart() {
    this.setState({ isChartOpen: true });
  }
  closeChart() {
    this.setState({ isChartOpen: false });
  }

  addToComparison(item) {
    const { compareFirst, page } = this.state;
    const { getComparisonDetails } = this.props;

    if (compareFirst) {
      if (item.id === compareFirst.id) {
        this.setState({ compareFirst: undefined });
      } else {
        this.setState({ compareSecond: item });
        getComparisonDetails(compareFirst, item);
      }
    } else {
      this.setState({ compareFirst: item }, () => {
        if (page > this.getCountPages()) {
          this.setState({ page: 1 });
        }
      });
    }
  }

  renderComparisonModal() {
    const { compareFirst, compareSecond } = this.state;
    const isOpen = !!(compareFirst && compareSecond);
    return (
      <ModalDialog show={isOpen} onHide={this.closeComparisonModal} bsSize="lg">
        <ModalHeader closeButton={true}>
          <FormattedMessage id="analyticsLogs_searchResults_titleComparison"/>
        </ModalHeader>
        <ModalBody>
          {this.renderComparisonModalContent()}
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.closeComparisonModal} bsSize="sm">
            <FormattedMessage id="common_close"/>
          </Button>
        </ModalFooter>
      </ModalDialog>
    );
  }

  renderComparisonModalContent() {
    const { compareFirst, compareSecond } = this.state;
    if (!compareFirst || !compareSecond) {
      return null;
    }

    const { comparisonDetails, loadingComparison } = this.props;
    if (loadingComparison) {
      return <Spinner/>;
    }
    if (!comparisonDetails) {
      return null;
    }

    return <AnalyticsLogsSearchResultsComparison comparisonDetails={comparisonDetails} />;
  }

  closeComparisonModal() {
    this.setState({ compareSecond: undefined });
  }
}

AnalyticsLogsSearchResults.propTypes = {
  loadingSearch: PropTypes.bool,
  loadingDetails: PropTypes.bool,
  loadingComparison: PropTypes.bool,
  searchResults: PropTypes.arrayOf(PROP_TYPES_SEARCH_RESULT_ITEM).isRequired,
  requestDetails: PROP_TYPES_DETAILS,
  getRequestDetails: PropTypes.func.isRequired,
  getComparisonDetails: PropTypes.func.isRequired,
  comparisonDetails: PropTypes.shape({
    first: PROP_TYPES_DETAILS,
    second: PROP_TYPES_DETAILS,
  }),
  dates: PROP_TYPES_DATES.isRequired,
};

const SORTABLE_FIELDS = [
  {
    key: 'username',
    label: <FormattedMessage id="analyticsLogs_searchResults_username"/>,
    sortFn: (item) => item.username,
  },
  {
    key: 'createdAt',
    label: <FormattedMessage id="analyticsLogs_searchResults_createdAt"/>,
    sortFn: (item) => item.createdAt,
  },
  {
    key: 'method',
    label: <FormattedMessage id="analyticsLogs_searchResults_method"/>,
    sortFn: (item) => item.method,
  },
  {
    key: 'requestUri',
    label: <FormattedMessage id="analyticsLogs_searchResults_requestUri"/>,
    sortFn: (item) => item.requestUri,
  },
  {
    key: 'empty',
    label: '',
    sortFn: undefined,
  },
  {
    key: 'empty',
    label: '',
    sortFn: undefined,
  },
];
const PAGINATION_ITEMS_ON_PAGE = 25;

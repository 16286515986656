import axios from 'axios';
import moment from 'moment';
import { all, call } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { convertDateToApi } from '../../utils/dateConverter';
import { sortByAll } from '../../utils';

const CHUNK_SIZE = 30;

export function* loadHistoryChunksSaga(maxDate, daysToLoad) {
  const countChunks = Math.ceil(daysToLoad / CHUNK_SIZE);
  const historyChunks = yield all(
    Array.from(new Array(countChunks)).map(function* (_, chunkNumber) {
      const daysBeforeFirstInChunk = CHUNK_SIZE * (chunkNumber + 1);
      const daysBeforeLastInChunk = (CHUNK_SIZE * chunkNumber) + (chunkNumber > 0 ? 1 : 0);
      const dateFrom = moment(maxDate).subtract(daysBeforeFirstInChunk, 'days');
      const dateTo = moment(maxDate).subtract(daysBeforeLastInChunk, 'days');

      const query = [
        `fromDate=${encodeURIComponent(convertDateToApi(dateFrom))}`,
        `toDate=${encodeURIComponent(convertDateToApi(dateTo))}`,
        'limit=10000',
      ].join('&');

      const { data: items } = yield call(axios, {
        url: `${EndpointName.ANALYTICS_HISTORY_DAILY}?${query}`,
        timeout: 60000
      });
      return splitByDates(items, dateFrom, dateTo);
    }),
  );

  return historyChunks
    .reduce((storage, chunk) => storage.concat(chunk), [])
    .sort(sortByAll((it) => it.date.valueOf()))
    .reverse();
}

function splitByDates(items, startDate, endDate) {
  const result = [];
  const date = moment(startDate).startOf('date');
  const filterItemsFn = (it) =>
    it.year === date.get('year') &&
    it.month === date.get('month') + 1 &&
    it.day === date.get('date');

  while (date.isSameOrBefore(endDate)) {
    result.push({
      date: moment(date),
      items: items.filter(filterItemsFn),
    });
    date.add(1, 'day');
  }
  return result;
}

import React from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'react-bootstrap';
import { get, noop } from 'lodash';
import cl from 'classnames';

import './ListItem.css';

const EmailSubscriptionsExportListItem = React.memo(({
  language,
  onExportRequest,
  requesting,
  subscriptionType
}) => {
  const iconClassName = cl({
    'email-subscriptions-export-type-list-item-content--icon': true,
    'email-subscriptions-export-type-list-item-content--icon-disabled': requesting
  });

  return (
    <ListGroupItem>
      <div className="email-subscriptions-export-type-list-item-content">
        <div>
          {get(subscriptionType, `text.${language}`)}
        </div>

        <div
          className={iconClassName}
          disabled={requesting}
          onClick={requesting ? noop : () => onExportRequest(subscriptionType)}
        >
          <span className="mdi mdi-download" />
        </div>
      </div>
    </ListGroupItem>
  );
});

EmailSubscriptionsExportListItem.propTypes = {
  language: PropTypes.string,
  onExportRequest: PropTypes.func,
  requesting: PropTypes.bool,
  subscriptionType: PropTypes.object.isRequired
};

EmailSubscriptionsExportListItem.defaultProps = {
  language: 'de',
  onExportRequest: noop,
  requesting: false
};

export default EmailSubscriptionsExportListItem;

import './SetManagementProduct.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { arrayMove } from 'react-sortable-hoc';
import { sortByAll } from '../../utils';
import { Table, TableCell, TableHead } from '../Common/Table';
import { SortableList } from '../Common/SortableList';
import { SetManagementProductItem } from './SetManagementProductItem';

export class SetManagementProduct extends React.Component {
  render() {
    const { products, domains, bundles } = this.props;
    return (
      <div className="set-management-product">
        <div className="set-management-product__title">
          <FormattedMessage id="setManagement_product_title"/>
        </div>

        <SortableList onSortEnd={this.onSort} className="set-management-product__list">
          <Table>
            <TableHead>
              <TableCell className="set-management-product__name">
                <FormattedMessage id="setManagement_product_name"/>
              </TableCell>
              <TableCell className="set-management-product__domain">
                <FormattedMessage id="setManagement_product_domain"/>
              </TableCell>
              <TableCell className="set-management-product__unit-price">
               Bundel
              </TableCell>
              <TableCell className="set-management-product__unit-price">
                <FormattedMessage id="setManagement_product_unitPrice"/>
              </TableCell>
              <TableCell colSpan={2}/>
            </TableHead>
            {products.map((product, index) => (
              <SetManagementProductItem
                key={product.id}
                index={index}
                id={index}
                product={product}
                domains={domains}
                bundles={bundles}
                onChange={this.onChange}
              />
            ))}
          </Table>
        </SortableList>
      </div>
    );
  }

  onSort = ({ oldIndex, newIndex }) => {
    const { products, onChange } = this.props;
    const sorted = arrayMove(products, oldIndex, newIndex)
      .map((item, index) => ({ ...item, order: index }))
      .sort(sortByAll((it) => it.order));
    onChange(sorted);
  };

  onChange = (product) => {
    const { products, onChange } = this.props;
    onChange(
      products.map((it) => it.id === product.id ? product : it)
    );
  };
}

SetManagementProduct.propTypes = {
  products: PropTypes.array.isRequired,
  domains: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

import { createSelector } from 'reselect';
import { getIdFromRef } from '../../utils';
import { getAdditions } from './getBasicServices';
import { getExistingProducts } from './getExistingProducts';

export const getRemovedAdditions = createSelector([
  getExistingProducts,
  getAdditions,
], (products, additions) => {
  const additionsFromRemovedProducts = additions.filter((addition) =>
    !products.some((product) => getIdFromRef(addition.product.$ref) === product.id)
  );
  const productsWithoutAdditions = products.filter((product) => !product.showAddition);
  const removedAdditions = additions.filter((addition) =>
    productsWithoutAdditions.some((product) => getIdFromRef(addition.product.$ref) === product.id)
  );

  return [...additionsFromRemovedProducts, ...removedAdditions];
});

import moment from 'moment';
import { call, put } from 'redux-saga/effects';
import { ANALYTICS_HISTORY_SEARCH_DETAILS } from '../../actions/AnalyticsActions';
import { historyLoadSaga } from './analyticsLoadHistorySaga';

export function* historySearchDetailsSaga(action) {
  const { startDate, endDate } = action.data;
  yield put(ANALYTICS_HISTORY_SEARCH_DETAILS.pending());

  const { history, items } = yield call(
    historyLoadSaga,
    startDate,
    endDate ? endDate : moment(startDate).endOf('date'),
  );

  yield put(ANALYTICS_HISTORY_SEARCH_DETAILS.success({ history, historyItemsDetails: items }));
}

import React from 'react';

export class LoaderTriggerRefreshEventStatus extends React.Component {
  timer = undefined;
  state = {
    seconds: this.props.interval,
  };

  render() {
    const { seconds } = this.state;

    if (seconds === this.props.interval) {
      this.startTimer();
    }

    return (
      <span className="icon-085-synchronise" >{seconds}s</span>
    );
  }

  startTimer = () => {
    this.stopTimer();
    this.timer = setTimeout(this.updateTimer, 1000);
  };

  updateTimer = () => {
    const { seconds } = this.state;
    if (seconds === 0) {
      this.setState({ seconds: this.props.interval });
      return;
    }

    this.setState((prev) => ({ seconds: prev.seconds - 1 }));
    this.startTimer();
  };

  stopTimer = () => clearTimeout(this.timer);

  onClickRefresh = () => {
    this.stopTimer();
    this.props.refresh();
  };

  componentWillUnmount() {
    this.stopTimer();
  }
}
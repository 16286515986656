import './Holidays.css';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Pagination } from '@react-bootstrap/pagination';
import { Button, ButtonGroup } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { handleGravitonError, sortByAll } from '../../utils';
import EditContainer from '../EditContainer';
import { HolidaysCalendar } from './HolidaysCalendar';
import { HolidaysList } from './HolidaysList';
import { ANALYTICS_PROP_TYPES_LOCALE } from '../Analytics/AnalyticsPropTypes';

function filterHolidaysByYear(holidays, year) {
  const sortedDates = [...holidays].sort(sortByAll((it) => moment(it.startDate).valueOf()));
  return sortedDates.filter((it) => moment(it.startDate).get('year') === year);
}

class Holidays extends React.Component {
  constructor(props) {
    super(props);

    props.load();

    this.setViewModeCalendar = this.setViewModeCalendar.bind(this);
    this.setViewModeList = this.setViewModeList.bind(this);
    this.onSelectHolidaysList = this.onSelectHolidaysList.bind(this);
    this.onSelectHolidaysYear = this.onSelectHolidaysYear.bind(this);
  }

  onSelectHolidaysList(option) {
    const { history } = this.props;

    history.push(option
      ? `/holidays/${encodeURIComponent(option.id)}`
      : '/holidays',
    );
  }

  onSelectHolidaysYear(option) {
    const { selectYear } = this.props;
    if (option) {
      selectYear(option);
    }
  }

  setViewModeCalendar() {
    const { setViewMode } = this.props;
    setViewMode('calendar');
  }

  setViewModeList() {
    const { setViewMode } = this.props;
    setViewMode('list');
  }

  renderHeader() {
    const { viewMode } = this.props;
    return (
      <div className="holidays__header">
        <span className="holidays__title">Feiertage</span>
        <ButtonGroup>
          <Button active={viewMode === 'calendar'} onClick={this.setViewModeCalendar}>
            <i className="glyphicon glyphicon-calendar"/>
          </Button>
          <Button active={viewMode === 'list'} onClick={this.setViewModeList}>
            <i className="glyphicon glyphicon-list"/>
          </Button>
        </ButtonGroup>
      </div>
    );
  }

  renderContent(selectedList) {
    const { holidays } = this.props;

    return (
      <div className="holidays__content">
        <div className="holidays__selector">
          <Select value={selectedList}
                  options={holidays}
                  valueKey="id"
                  labelKey="name"
                  onChange={this.onSelectHolidaysList}
          />
        </div>

        {this.renderHolidays(selectedList)}
      </div>
    );
  }

  renderHolidays(selectedList) {
    if (!selectedList) {
      return null;
    }

    const { year, viewMode, create, edit, remove, locale } = this.props;

    return (
      <div className="holidays__container">
        <Pagination maxButtons={3}
                    items={3000}
                    prev={true}
                    next={true}
                    activePage={year}
                    ellipsis={false}
                    onSelect={this.onSelectHolidaysYear}
        />
        {viewMode === 'calendar'
          ? <HolidaysCalendar holidays={selectedList.dateRanges}
                              year={year}
                              create={create}
                              edit={edit}
                              remove={remove}
                              locale={locale}/>
          : <HolidaysList holidays={filterHolidaysByYear(selectedList.dateRanges, year)}
                          year={year}
                          create={create}
                          edit={edit}
                          remove={remove}
                          locale={locale}/>}
      </div>
    );
  }

  render() {
    const { loading, error, holidaysList, save } = this.props;
    if (error) {
      toast.error(handleGravitonError(error));
    }

    return (
      <EditContainer requesting={loading}
        title={this.renderHeader()}
        body={this.renderContent(holidaysList)}
        saveData={save} />
    );
  }
}

Holidays.propTypes = {
  locale: ANALYTICS_PROP_TYPES_LOCALE.isRequired,
  holidays: PropTypes.array,
  holidaysList: PropTypes.shape({
    dateRanges: PropTypes.array,
    name: PropTypes.string,
  }),
  year: PropTypes.number,
  loading: PropTypes.bool,
  error: PropTypes.object,
  viewMode: PropTypes.oneOf(['list', 'calendar']),

  save: PropTypes.func,
  edit: PropTypes.func,
  remove: PropTypes.func,
  create: PropTypes.func,
};

export default withRouter(Holidays);

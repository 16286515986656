import React from 'react';
import PropTypes from 'prop-types';
import { get, isUndefined, noop } from 'lodash';
import { Col, Row, ListGroupItem, Tabs, Tab } from 'react-bootstrap';
import Highlight from 'react-highlight'
import moment from 'moment';
import ReactJsonViewCompare from 'react-json-view-compare';
import cl from 'classnames';

import './RecordListItem.css';

class RecordListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      activeTab: 1
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { collapsed } = this.state;
    const { onToggle } = this.props;

    this.setState({ collapsed: !collapsed });
    onToggle();
  }

  renderTabs() {
    const { record, error, result } = this.props;
    const body = get(record, 'body');
    const current = get(record, 'current')

    return (
      <Tabs>
        <Tab eventKey={1} title="Request Body">
          <Highlight className="json" style={{ textAlign: 'left' }}>
            {JSON.stringify(get(record, 'body', {}), null, 2)}
          </Highlight>
        </Tab>

        {!isUndefined(current) && (
          <Tab eventKey={2} title="Aktuelle Daten" disabled={isUndefined(current)}>
            <Highlight className="json" style={{ textAlign: 'left' }}>
              {JSON.stringify(get(record, 'current', {}), null, 2)}
            </Highlight>
          </Tab>
        )}

        {!isUndefined(body) && !isUndefined(current) && (
          <Tab eventKey={3} title="Diff">
            <ReactJsonViewCompare
              oldData={body}
              newData={current}
            />
          </Tab>
        )}

        {!isUndefined(result) && (
          <Tab eventKey={4} title="Resultat">
            <Highlight className="json" style={{ textAlign: 'left' }}>
              {JSON.stringify(result, null, 2)}
            </Highlight>
          </Tab>
        )}

        {error && (
          <Tab eventKey={4} title="Fehler">
            <Highlight className="json" style={{ textAlign: 'left' }}>
              {JSON.stringify(error, Object.getOwnPropertyNames(error), 2)}
            </Highlight>
          </Tab>
        )}
      </Tabs>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { collapsed } = this.state;
    const { record, className, style } = this.props;

    return (
      <ListGroupItem className={cl('replay-audit-log-record-list-item', className)} style={style}>
        <div onClick={this.toggle}>
          <Row>
            <Col sm={3}>
              Benutzer
            </Col>

            <Col sm={3}>
              Zeit
            </Col>

            <Col sm={3}>
              Methode
            </Col>

            <Col sm={3}>
              Request URI
            </Col>
          </Row>

          <Row>
              <Col sm={3}>
                {get(record, 'username')}
              </Col>

              <Col sm={3}>
                {moment(get(record, 'createdAt')).format('DD.MM.YYYY hh:mm:ss')}
              </Col>

              <Col sm={3}>
                {get(record, 'method')}
              </Col>

              <Col sm={3}>
                {get(record, 'requestUri')}
              </Col>
            </Row>
          </div>

          {!collapsed && this.renderTabs()}
      </ListGroupItem>
    );
  }
}

RecordListItem.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  onToggle: PropTypes.func,
  record: PropTypes.object.isRequired,
  result: PropTypes.object,
  style: PropTypes.object
};

RecordListItem.defaultProps = {
  error: false,
  onToggle: noop,
  result: undefined,
  style: {}
};

export default RecordListItem;

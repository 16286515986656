import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Panel, Modal, Button, FormControl } from 'react-bootstrap';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Toggle from 'react-toggle';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import _ from 'lodash';

import MarkdownEditor from '../MarkdownEditor';
import { ModalBody, ModalDialog, ModalHeader } from '../Common/Modal';
import { LeadqualificationOptions } from './LeadqualificationOptions';

export default class Item extends React.Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
      modalOptionsOpen: false,
    };
  }

  modalOpen() {
    this.setState({
      modalOpen: true,
    });
  }

  modalClose() {
    this.setState({
      modalOpen: false,
    });
  }

  updateFunction = (value) => {
    const { changeValue, item, language } = this.props;
    changeValue({ item, value: value, field: "leadinstruction", language })
  }

  render() {
    const { item, workguides, language, changeValue, leadgroups } = this.props;
    let { postActionCodes, orderOriginCodes } = this.props;

    postActionCodes = item.workguideId === "" ? _.filter(postActionCodes, c => c.id === "postAction-2") : postActionCodes;
    orderOriginCodes = ['orderOrigin-AU', 'orderOrigin-PO', 'orderOrigin-PU']
      .map((id) => orderOriginCodes.find(code => code.id === id))
      .filter(Boolean);

    return (
      <Panel>
        <div style={{
          height: 60,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'flex-start',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
        >

          <div style={{ flex: 0, marginLeft: 20, minWidth: 140 }}>
            <Select
              value={_.get(item, 'orderOrigin.id')}
              options={orderOriginCodes.map(o => ({ label: o.text[language], value: o.id, code: o }))}
              onChange={(value) => changeValue({ item, value: _.get(value, 'code'), field: 'orderOrigin' })}
            />
          </div>
          <div style={{ marginLeft: 20, width: 250 }}>
            {item.leadTypeText[language]}
          </div>
          <div style={{ flex: 0, marginLeft: 20, minWidth: 130 }}>
            <Slider
              min={1}
              max={10}
              value={item.importance}
              tooltip={false}
              onChange={(value) => changeValue({ item, value, field: "importance" })}
            />
          </div>
          <div style={{ flex: 0, marginLeft: 20, minWidth: 50 }}>
            {item.importance}
          </div>
          <div style={{ flex: 0, marginLeft: 20, minWidth: 55 }}>
            <Toggle
              onChange={() => changeValue({ item, value: !item.forNewLeadAvailable, field: "forNewLeadAvailable" })}
              checked={item.forNewLeadAvailable}
            />
          </div>
          <div style={{ flex: 0, marginLeft: 20, minWidth: 55 }}>
            <Toggle
              onChange={() => changeValue({
                item,
                value: !item.forNewLeadAvailableBackoffice,
                field: "forNewLeadAvailableBackoffice"
              })}
              checked={item.forNewLeadAvailable ? true : item.forNewLeadAvailableBackoffice}
              disabled={item.forNewLeadAvailable ? true : false}
            />
          </div>
          <div style={{ flex: 0, marginLeft: 20, minWidth: 140 }}>
            <Select
              placeholder="Bitte zugehörigen Post Action auswählen"
              value={_.get(item.postAction, "id")}
              options={postActionCodes.map(o => ({ label: o.text[language], value: o.id, code: o }))}
              onChange={(value) => changeValue({ item, value: _.get(value, 'code'), field: "postAction" })}
            />
          </div>
          <div style={{ flex: 0, marginLeft: 20, minWidth: 140 }}>
            <Select
              placeholder="Bitte zugehörigen Workguide auswählen"
              value={item.workguideId}
              options={workguides.map(o => ({ label: o.title[language], value: o.id }))}
              onChange={(value) => changeValue({
                item,
                value: _.get(value, 'value') ? value.value : "",
                field: "workguideId"
              })}
              closeOnSelect={true}
            />
          </div>

          <div style={{ flex: 0, marginLeft: 20, minWidth: 140 }}>
            <Select
              placeholder="Bitte Lead-Gruppe wählen"
              value={{ "label": _.get(item, `leadGroup.text[${language}]`), "value": _.get(item, "leadGroup") }}
              options={leadgroups.map(o => ({ label: o.text[language], value: o }))}
              onChange={(value) => changeValue({
                item,
                value: _.get(value, 'value') ? value.value : {},
                field: "leadGroup"
              })}
              closeOnSelect={true}
            />
          </div>
          <div style={{ flex: 0, marginLeft: 20, minWidth: 70 }}>
            <FormControl
              id="shiftDueDate"
              type="number"
              style={{ minWidth: 70 }}
              value={_.get(item, 'shiftDueDate', 0)}
              onChange={(value) => changeValue({ item, value: value.target.value, field: "shiftDueDate" })}
            />
          </div>
          <div style={{ flex: 0, marginLeft: 20, minWidth: 70 }}>
            <FormControl
              id="shiftExpireDate"
              type="number"
              style={{ minWidth: 70 }}
              value={_.get(item, 'shiftExpireDate', 0)}
              onChange={(value) => changeValue({ item, value: value.target.value, field: "shiftExpireDate" })}
            />
          </div>
          <div style={{ flex: 0.1, marginLeft: 20, minWidth: 100 }}>
            <Button onClick={this.modalOpen.bind(this)} title="Leadinstruktionen">
              <span className="icon-227-speech-bubble-filled"/>
            </Button>

            <Button onClick={this.openModalOptions} style={{ marginLeft: '10px' }}>
              <span className="glyphicon glyphicon-flag"/>
            </Button>
          </div>
        </div>

        <Modal show={this.state.modalOpen} onHide={this.modalClose.bind(this)} dialogClassName="markdown-modal">
          <Modal.Header closeButton>
            <Modal.Title>Leadinstruktionen für {item.leadTypeText[language]}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Eingabe begrenzt auf 3000 Zeichen</div>
            <MarkdownEditor
              updateFunction={(value) => (value && value.length <= 3000) ? this.updateFunction(value) : _.noop}
              title="Leadinstruktionen erfassen"
              content={item.leadinstruction[language]}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.modalClose.bind(this)}><span className="icon-202-clear-circle"/>
              Schliessen
            </Button>
          </Modal.Footer>
        </Modal>

        {this.renderModalOptions()}
      </Panel>
    );
  }

  renderModalOptions() {
    const { item, language, statuses } = this.props;
    return (
      <ModalDialog show={this.state.modalOptionsOpen} onHide={this.closeModalOptions}>
        <ModalHeader closeButton>
          <FormattedMessage id="leadqualification_options_title"/>
        </ModalHeader>
        <ModalBody>
          <LeadqualificationOptions
            options={item.notificationOptions}
            statuses={statuses}
            onSave={this.onChangeNotificationOptions}
            onCancel={this.closeModalOptions}
            locale={language}
          />
        </ModalBody>
      </ModalDialog>
    );
  }

  openModalOptions = () => this.setState({ modalOptionsOpen: true });
  closeModalOptions = () => this.setState({ modalOptionsOpen: false });
  onChangeNotificationOptions = (options) => {
    const { item, changeValue } = this.props;
    changeValue({
      item,
      value: options,
      field: "notificationOptions",
    });
    this.closeModalOptions();
  };
}

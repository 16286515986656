import axios from 'axios';
import { all, call, put, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { BULK_LOADS_REFRESH } from '../../actions/BulkLoadsActions';
import { loadActionsByLoads } from './loadActionsByLoadSaga';
import { getBulkLoadsResultsRaw } from '../../selectors/BulkLoads/bulkLoadsCommonSelectors';
import { BULK_LOADS_PAGE_SIZE, BULK_LOADS_STATES } from '../../constants/BulkLoadsConstants';

export function* bulkLoadsRefreshSaga() {
  const results = yield select(getBulkLoadsResultsRaw);
  if (!results) {
    return;
  }

  const prevOpenAndActiveLoads = results.loads
    .filter((it) => it.state === BULK_LOADS_STATES.OPEN || it.state === BULK_LOADS_STATES.ACTIVE);
  if (!prevOpenAndActiveLoads.length) {
    return;
  }

  try {
    yield put(BULK_LOADS_REFRESH.pending());
    const loadsIdsQuery = prevOpenAndActiveLoads.map((it) => `string:${it.id}`).join(',');

    const [
      { data: loads },
      actions,
    ] = yield all([
      call(axios, {
        url: `${EndpointName.LOADER_LOAD}/?in(id,(${loadsIdsQuery}))&limit(${BULK_LOADS_PAGE_SIZE})`,
      }),
      call(loadActionsByLoads, prevOpenAndActiveLoads),
    ]);

    const updatedResult = {
      ...results,
      loads: results.loads.map((oldLoad) => {
        const updated = loads.find((newLoad) => newLoad.id === oldLoad.id);
        return updated ? updated : oldLoad;
      }),
      actions: results.actions.map((oldAction) => {
        const updated = actions.find((newAction) => newAction.id === oldAction.id);
        return updated ? updated : oldAction;
      }),
    };

    yield put(BULK_LOADS_REFRESH.success({
      results: updatedResult,
    }));
  } catch (error) {
    yield put(BULK_LOADS_REFRESH.failure(error));
  }
}

import { FINANCING_PROFILING_SAVE_ACTIONS } from '../../actions/FinancingProfilingActions';

export function FinancingProfilingSaveReducer(state, action) {
  switch (action.type) {
    case FINANCING_PROFILING_SAVE_ACTIONS.PENDING:
      return { ...state, loading: true, error: undefined };
    case FINANCING_PROFILING_SAVE_ACTIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        initialData: {
          profiles: [...state.profiles],
        },
      };
    case FINANCING_PROFILING_SAVE_ACTIONS.FAILURE:
      return { ...state, loading: false, error: action.data };
    default:
      return state;
  }
}

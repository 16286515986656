import React from 'react';
import PropTypes from 'prop-types';
import _, { get } from 'lodash';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';
import Toggle from 'react-toggle';

const CustomerSelectionConfiguration = React.memo(({ id, item, editValueField }) => {
  const onlyActiveItems = get(item, 'props.onlyActiveItems', false);

  return (
    <React.Fragment>
      <FormGroup controlId="codeGroupMulti">
        <Col componentClass={ControlLabel} sm={3}>
          Mehrfachauswahl
        </Col>
        <Col sm={9} style={{ paddingTop: '10px' }}>
          <Toggle
            checked={_.get(item, 'props.multi') === undefined ? false : item.props.multi}
            onChange={() => editValueField(id, 'multi')}
          />
        </Col>
      </FormGroup>

      <FormGroup controlId="onlyActiveItems">
        <Col componentClass={ControlLabel} sm={3}>
          Nur aktive Kunden
        </Col>
        <Col sm={9} style={{ paddingTop: '10px' }}>
          <Toggle
            checked={onlyActiveItems}
            onChange={() => editValueField(id, 'prop', { key: 'onlyActiveItems', value: !onlyActiveItems })}
          />
        </Col>
      </FormGroup>
    </React.Fragment>
  );
});

CustomerSelectionConfiguration.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  item: PropTypes.object.isRequired,
  editValueField: PropTypes.func
};

CustomerSelectionConfiguration.defaultProps = {
  editValueField: _.noop
};

export default CustomerSelectionConfiguration;

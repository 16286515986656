import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import definitionsAction from '../../actions/JobProcessing/Definitions';

const {
  DEFINITIONS_PENDING,
  DEFINITIONS_FULFILLED,
  DEFINITIONS_REJECTED
} = definitionsAction;

export default function* getDefinitions(request) {
  yield put({ type: DEFINITIONS_PENDING });

  try {
    const url = '/core/job-processing/';
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: DEFINITIONS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: DEFINITIONS_REJECTED, error });

    return error;
  }
}


import { call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { CONSULTANTS_FETCH_ACTIONS } from '../../actions/ConsultantsActions';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

export function* consultantsFetchSaga() {
  try {
    yield put(CONSULTANTS_FETCH_ACTIONS.pending());

    
    const consultants = yield call(handleNextHeaders, { url: `${EndpointName.PERSON_CONSULTANT}/?limit(1000)` });

    yield put(CONSULTANTS_FETCH_ACTIONS.success({ consultants }));
  } catch (error) {
    yield put(CONSULTANTS_FETCH_ACTIONS.failure(error));
  }
}

import PropTypes from 'prop-types';
import { BULK_LOADS_STATES } from '../../constants/BulkLoadsConstants';

export const BULK_LOAD_PROP_TYPES_STATE = PropTypes.oneOf([
  BULK_LOADS_STATES.OPEN,
  BULK_LOADS_STATES.ACTIVE,
  BULK_LOADS_STATES.DONE,
  BULK_LOADS_STATES.ERROR,
]);

export const BULK_LOAD_PROP_TYPES_ACTION = PropTypes.shape({
  id: PropTypes.string,
  start: PropTypes.object,
  end: PropTypes.object,
  label: PropTypes.string,
  state: BULK_LOAD_PROP_TYPES_STATE,

  loadId: PropTypes.string,
  component: PropTypes.string,
  result: PropTypes.string,
  workHistory: PropTypes.object,
  metrics: PropTypes.shape({
    loadedRecords: PropTypes.number,
    foreignOriginRecords: PropTypes.number,
    foreignOriginRecordsTransferTime: PropTypes.number,
    mongoDbPostProcessingSeconds: PropTypes.number,
    mongoDbDatabaseWriteTimeSeconds: PropTypes.number,
    refErrors: PropTypes.number,
    refErrorsRef: PropTypes.number,
    refErrorsEmbed: PropTypes.number,
    insertErrorCount: PropTypes.number,
    secondsWorking: PropTypes.number,
  }),
});

export const BULK_LOAD_PROP_TYPES_RESULT_ITEM = PropTypes.shape({
  id: PropTypes.string.isRequired,
  state: BULK_LOAD_PROP_TYPES_STATE,
  open: PropTypes.object,
  done: PropTypes.object,
  count: PropTypes.number,
  seconds: PropTypes.number,
  percent: PropTypes.number,
  quality: BULK_LOAD_PROP_TYPES_STATE,
  metrics: PropTypes.shape({
    secondsWaiting: PropTypes.number,
    secondsWorking: PropTypes.number,
  }),
  actionCount: PropTypes.shape({
    open: PropTypes.number,
    success: PropTypes.number,
    working: PropTypes.number,
    error: PropTypes.number,
    done: PropTypes.number,
    total: PropTypes.number,
  }),
  actions: PropTypes.arrayOf(BULK_LOAD_PROP_TYPES_ACTION),
});

export const BULK_LOAD_PROP_TYPES_SEARCH_PARAMS = PropTypes.shape({
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  states: PropTypes.arrayOf(BULK_LOAD_PROP_TYPES_STATE),
  pageNumber: PropTypes.number,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
});

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Col, Row } from 'react-bootstrap';

import Progress from './Progress';
import RecordList from './RecordList';

class SourceData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { collapsed } = this.state;

    this.setState({ collapsed: !collapsed });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { collapsed } = this.state;
    const {
      progress,
      prepared
    } = this.props;

    const description = `
      Anzeige der geladenen Records bzw. des Totals ist leider nicht möglich,
      da der Analytics Service keinen x-total-count header zurück gibt. \n
      Die daten werden in chunks geladen. Das Total sollte nach dem Laden des
      jeweiligen chunks aktualisiert werden...
    `;

    return (
      <div className="replay-audit-log-source-data">
        <Row>
          <Col lg={12} style={{ cursor: 'pointer' }}>
            <h3 onClick={this.toggle}>
              Source Daten
            </h3>
          </Col>
        </Row>

        {!collapsed && (
          <React.Fragment>
            {(get(progress, 'auditLog.requesting', false) || get(progress, 'auditLog.fulfilled', false)) && (
              <Progress
                description={description}
                title="Records aus Audit Log"
                progress={get(progress, 'auditLog')}
              />
            )}

            {(get(progress, 'currentRecords.requesting', false) || get(progress, 'currentRecords.fulfilled', false)) && (
              <Progress
                title="Aktuelle Daten"
                progress={get(progress, 'currentRecords')}
              />
            )}

            <RecordList records={get(prepared, 'data', [])} />
          </React.Fragment>
        )}
      </div>
    );
  }
}

SourceData.propTypes = {
  progress: PropTypes.object.isRequired,
  record: PropTypes.array
};

SourceData.defaultProps = {
  record: []
};

export default SourceData;

import { takeLatest } from 'redux-saga/effects';
import {
  CONSULTANT_LOAD,
  ENTITIY_CODE_LOAD,
  CONSULTANT_SEARCH,
  CONSULTANT_UPDATE
} from '../../actions/PermissionGroupAction';
import { permissionGroupGetConsultantsSaga } from './permissionGroupGetConsultantsSaga';
import { permissionGroupGetEntityCodesSaga } from './permissionGroupGetEntityCodesSaga';
import { permissionGroupSearchSaga } from './permissionGroupSearchSaga';
import { permissionGroupUpdateConsultantsSaga } from './permissionGroupUpdateConsultantSaga'

export function* permissonGroupSagas() {
  yield takeLatest(CONSULTANT_LOAD.REQUEST, permissionGroupGetConsultantsSaga);
  yield takeLatest(ENTITIY_CODE_LOAD.REQUEST, permissionGroupGetEntityCodesSaga);
  yield takeLatest(CONSULTANT_SEARCH.REQUEST, permissionGroupSearchSaga);
  yield takeLatest(CONSULTANT_UPDATE.REQUEST, permissionGroupUpdateConsultantsSaga);
}

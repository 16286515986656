import React from 'react';
import PropTypes from 'prop-types';
import { chain, get, isNil } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import { prepareGravitonRef } from '@evoja-web/redux-saga-utils';

import Module from './Module';

/**
 * Check if the services of the given module are available on both - source and target
 *
 * @param   {Object}  module          Module from /core/module
 * @param   {Array}   sourceServices  Array of services on the source system
 * @param   {Array}   targetServices  Array of services on the target system
 *
 * @return  {Array} services Array of services available on source and target
 */
function getAvaliableServices({ module, sourceServices, targetServices }) {
  return chain(module)
    .get('service', [])
    .filter((service) => {
      const uri = prepareGravitonRef(get(service, 'service.$ref'));
      const s = sourceServices.find((s) => s.uri === uri);
      const t = targetServices.find((s) => s.uri === uri);

      return !isNil(s) && !isNil(t);
    })
    .value();
}

class ParamTransferModules extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * Get an array of modules that are available on the source and the target system.
   * Check if the configured service of the module exists on both systems
   *
   * @return  {Array} modules Array of available modules
   */
  getAvailableModules() {
    const {
      source,
      target
    } = this.props;

    const sourceServices = get(source, 'data.services', []);
    const targetServices = get(target, 'data.services', []);
    const sourceModules = get(source, 'data.modules', []);
    const modules = chain(target)
      .get('data.modules', [])
      .map((module) => {
        const service = getAvaliableServices({ module, sourceServices, targetServices });

        return {
          ...module,
          service
        };
      })
      .filter((module) => get(module, 'service.length', 0) > 0)
      .filter((module) => {
        const s = sourceModules.find((m) => m.key === module.key);

        return !isNil(s);
      })
      .value();

    return modules;
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const modules = this.getAvailableModules();

    const children = modules.map((module) => {
      return (
        <Row
          key={module.key}
          className="param-transfer-modules--row"
        >
          <Col
            className="param-transfer-modules--row--col"
            lg={12}
            md={12}
          >
            <Module module={module} />
          </Col>
        </Row>
      );
    });

    return (
      <div className="param-transfer-modules">
        {children}
      </div>
    );
  }
}

ParamTransferModules.propTypes = {
  source: PropTypes.object,
  target: PropTypes.object
};

ParamTransferModules.defaultProps = {
  source: {},
  target: {}
};

export default ParamTransferModules;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import { Table, TableCell, TableRow } from '../Common/Table';
import { SelectControl } from '../Common/SelectControl';
import { Button } from '../Common/Button';
import { InputControl } from '../Common/InputControl';

import './SetManagementProductEdit.css';

export class SetManagementProductEdit extends React.Component {
  constructor(props) {
    super(props);
    const { product } = props;

    this.state = {
      product: { ...product },
    };
  }

  render() {
    const { domains, onCancel, bundles } = this.props;
    const { product } = this.state;

    return (
      <div className="set-management-product-edit">
        <Table>
        <TableRow>
            <TableCell className="set-management-pricing-edit__label">
              <FormattedMessage id="setManagement_pricing_bundle"/>
            </TableCell>
            <TableCell className="set-management-pricing-edit__value">
              <SelectControl
                value={product.bundle}
                multi
                clearable={false}
                options={bundles}
                onChange={this.onChangeBundles}
                className={product.bundle ? '' : 'set-management-product-edit__invalid'}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="set-management-product-edit__label">
              <FormattedMessage id="setManagement_product_domain"/>
            </TableCell>
            <TableCell className="set-management-product-edit__value">
              <SelectControl
                value={product.domain}
                multi
                clearable={false}
                options={domains}
                onChange={this.onChangeDomain}
                className={product.domain ? '' : 'set-management-product-edit__invalid'}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="set-management-product-edit__label">
              <FormattedMessage id="setManagement_product_name"/>
            </TableCell>
            <TableCell className="set-management-product-edit__value">
              <InputControl
                type="text"
                value={product.name || ''}
                onChange={this.onChangeName}
                className={product.name ? '' : 'set-management-product-edit__invalid'}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="set-management-product-edit__label">
              <FormattedMessage id="setManagement_product_shortName"/>
            </TableCell>
            <TableCell className="set-management-product-edit__value">
              <InputControl
                type="text"
                value={product.shortName || ''}
                onChange={this.onChangeShortName}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="set-management-product-edit__label">
              <FormattedMessage id="setManagement_product_unitPrice"/>
            </TableCell>
            <TableCell className="set-management-product-edit__value">
              <InputControl
                type="number"
                value={product.unitPrice || ''}
                onChange={this.onChangePrice}
                className={Number.isFinite(product.unitPrice) ? '' : 'set-management-product-edit__invalid'}
              />
            </TableCell>
          </TableRow>
        </Table>

        <div className="set-management-product-edit__buttons">
          <Button
            onClick={this.save}
            bsSize="small"
            bsStyle="primary"
            className="set-management-product-edit__save"
            disabled={!this.isValid()}
          >
            <FormattedMessage id="common_save"/>
          </Button>
          <Button
            onClick={onCancel}
            bsSize="small"
          >
            <FormattedMessage id="common_close" />
          </Button>
        </div>
      </div>
    );
  }

  isValid() {
    const { product } = this.state;
    return product.name && product.domain && Number.isFinite(product.unitPrice);
  }

  onChangeDomain = (selected = []) => this.setState((prev) => ({
    product: {
      ...prev.product,
      domain: selected.map((s) => get(s, 'value')),
    }
  }));
  onChangeBundles = (selected = []) => {
    this.setState((prev) => ({
      product: {
        ...prev.product,
        bundle: selected.map((s) => get(s, 'value')),
      }
    }));
  }
  onChangeName = ({ target: { value } }) => this.setState((prev) => ({
    product: {
      ...prev.product,
      name: value,
    },
  }));

  onChangeShortName = ({ target: { value } }) => this.setState((prev) => ({
    product: {
      ...prev.product,
      shortName: value,
    },
  }));

  onChangePrice = ({ target: { value } }) => this.setState((prev) => ({
    product: {
      ...prev.product,
      unitPrice: value ? parseFloat(value) : undefined,
    },
  }));

  save = () => this.props.onSave(this.state.product);
}

SetManagementProductEdit.propTypes = {
  product: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

import { call, select } from 'redux-saga/effects';
import { getAnalyticsHistory } from '../../selectors/Analytics/analyticsHistoryUsersSelectors';
import { loadHistoryChunksSaga } from './analyticsLoadHistoryChunksSaga';

export function* historyLoadSaga(startDate, endDate) {
  const historyStorage = yield select(getAnalyticsHistory);
  const minExistingDate = historyStorage.length
    ? historyStorage[historyStorage.length - 1].date
    : endDate;

  const countDaysToLoad = minExistingDate.diff(startDate, 'days');
  if (countDaysToLoad <= 0) {
    return {
      history: historyStorage,
      items: getHistory(historyStorage, startDate, endDate),
    };
  }

  const newHistoryData = yield call(loadHistoryChunksSaga, minExistingDate, countDaysToLoad);
  const history = historyStorage.concat(newHistoryData);

  return {
    history,
    items: getHistory(history, startDate, endDate),
  };
}

function getHistory(storage, startDate, endDate) {
  return storage.filter((item) => item.date.isBetween(startDate, endDate, null, '[]'));
}

import { createSelector } from 'reselect';
import { getAnalyticsState } from './analyticsCommonSelectors';
import { getCommonSearch } from '../commonSelectors';

export const getAnalyticsActiveSessions = createSelector([getAnalyticsState], (state) => state.activeSessions);
export const getAnalyticsConsultants = createSelector([getAnalyticsState], (state) => state.consultants);

export const getAnalyticsActiveUsersList = createSelector([
  getAnalyticsActiveSessions,
  getAnalyticsConsultants,
  getCommonSearch,
], (activeSessions, consultants, search) => {
  const uniqUsers = [...new Set(activeSessions.map(it => it.username.toLowerCase()))];
  const users = uniqUsers.map((username) => {
    const user = consultants.find((it) => it.username.toLowerCase() === username);
    if (!user) {
      return undefined;
    }
    return {
      username: user.username,
      name: [user.firstName, user.lastName].filter(Boolean).join(' '),
      department: user.department || '',
      division: user.division || '',
      sessions: activeSessions.filter((it) => it.username.toLowerCase() === username),
    };
  }).filter(Boolean);

  return !search
    ? users
    : users.filter((user) => (
      contains(user.username, search) ||
      contains(user.name, search) ||
      contains(user.department, search) ||
      contains(user.division, search)
    ));
});

function contains(string, search) {
  return string.toLowerCase().indexOf(search.toLowerCase()) !== -1;
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';

import './ReplayAuditLog.css';
import EditContainer from '../components/EditContainer';
import Connection from '../components/ReplayAuditLog/Connection';
import Filter from '../components/ReplayAuditLog/Filter';
import LocalFilters from '../components/ReplayAuditLog/LocalFilters';
import LoadButton from '../components/ReplayAuditLog/LoadButton';
import SourceData from '../components/ReplayAuditLog/SourceData';
import Replay from '../components/ReplayAuditLog/Replay';
import { ApiClientLoginForm as LoginForm } from '../components/ApiClient/ApiClientLoginForm';
import replayActions from '../actions/ReplayAuditLog';
import { API_CLIENT_LOGIN } from '../actions/ApiClientActions';
import { validateApiClientLoginForm } from '../services/ApiClient/ApiClientLoginFormValidator';

class ReplayAuditLog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: 50
    };

    this.onLoadAuditLog = this.onLoadAuditLog.bind(this);
    this.onReplayAuditLog = this.onReplayAuditLog.bind(this);
    this.onUseCurrentConnection = this.onUseCurrentConnection.bind(this);
    this.onLimitChange = this.onLimitChange.bind(this);
  }

  /**
   * Load data from autit log with current filters
   *
   * @return void
   */
  onLoadAuditLog() {
    const { limit } = this.state;
    const {
      filter,
      replayActions,
      source,
      target
    } = this.props;

    replayActions.auditLogRequest({
      connection: get(source, 'data.instance'),
      filter,
      limit,
      source: get(source, 'data.instance'),
      target: get(target, 'data.instance')
    });
  }

  /**
   * Replay audit log with current data
   *
   * @return void
   */
  onReplayAuditLog() {
    const {
      replayActions,
      target
    } = this.props;

    const records = this.getRecordsToReplay();

    replayActions.replayRecordResetAll();
    replayActions.replayRequest({ connection: get(target, 'data.instance'), records });
  }

  /**
   * Use current connection (axios) for the given system or not
   *
   * @param  {String}  system      System name (source or target)
   * @param  {Boolean} [use=false] Use or not
   *
   * @return void
   */
  onUseCurrentConnection(system, use = false) {
    const { replayActions } = this.props;

    const [connect, reset] = system === 'source'
      ? [replayActions.connectSourceFulfilled, replayActions.connectSourceReset]
      : [replayActions.connectTargetFulfilled, replayActions.connectTargetReset];

    if (use) {
      connect({ payload: { instance: axios } });
    } else {
      reset();
    }
  }

  onLimitChange(limit = 50) {
    this.setState({ limit });
  }

  /**
   * Check if a replay is possible (data loaded and filtered)
   *
   * @return {Boolean}
   */
  isReplayPossible() {
    const {
      auditLog,
      localFilters,
      filtered
    } = this.props;

    return get(localFilters, 'data.length', 0) > 0
      ? get(filtered, 'fulfilled', false) && get(filtered, 'data.length', 0) > 0
      : get(auditLog, 'fulfilled', false) && get(auditLog, 'data.length', 0) > 0;
  }

  /**
   * Get the records to replay.
   * If there are local filters defined, use filtered data, else data from service
   *
   * @return {Array} data Filtered data
   */
  getRecordsToReplay() {
    const {
      filtered,
      localFilters,
      prepared
    } = this.props;

    return get(localFilters, 'data.length', 0) > 0
      ? get(filtered, 'data', [])
      : get(prepared, 'data', []);
  }

  /**
   * Render the login form
   *
   * @return {ReactElement} markup
   */
  renderLoginForm() {
    const {
      isAuthorized,
      onLogin
    } = this.props;

    return (
      <LoginForm
        form="replayAuditLogLoginForm"
        login={onLogin}
        validate={validateApiClientLoginForm}
        authError={isAuthorized === false}
      />
    );
  }

  /**
   * Render the page body
   *
   * @return {ReactElement} markup
   */
  renderBody() {
    const { limit } = this.state;
    const {
      auditLog,
      filter,
      filtered,
      isAuthorized,
      localFilters,
      prepared,
      progress,
      replay,
      replayActions,
      replayed,
      source,
      target
    } = this.props;

    if (!isAuthorized) return this.renderLoginForm();

    return (
      <div className="replay-audit-log">
        <Connection
          title="Quellsystem konfigurieren"
          system="source"
          connection={source}
          onSubmit={replayActions.connectSourceRequest}
          onUseCurrent={this.onUseCurrentConnection}
        />

        <hr/>

        <Connection
          title="Zielsystem konfigurieren"
          system="target"
          connection={target}
          onSubmit={replayActions.connectTargetRequest}
          onUseCurrent={this.onUseCurrentConnection}
        />

        <hr/>

        <Filter
          filter={filter}
          limit={limit}
          onChange={replayActions.setFilterRequest}
          onLimitChange={this.onLimitChange}
        />

        <hr/>

        <Row>
          <Col lg={12}>
            <LoadButton
              disabled={get(auditLog, 'requesting', false) || !get(source, 'fulfilled', false) || !get(target, 'fulfilled', true)}
              error={get(auditLog, 'error', false)}
              fulfilled={get(auditLog, 'fulfilled', false)}
              requesting={get(auditLog, 'requesting', false)}
              onClick={this.onLoadAuditLog}
            >
              Daten aus Audit Log laden
            </LoadButton>
          </Col>
        </Row>

        <hr/>

        <SourceData
          progress={progress}
          prepared={prepared}
        />

        <hr/>

        {get(auditLog, 'fulfilled', false) && (
          <LocalFilters
            filtered={filtered}
            filters={localFilters}
            onChange={replayActions.setLocalFiltersRequest}
            onSubmit={replayActions.applyLocalFiltersRequest}
            progress={progress}
          />
        )}

        <hr />
        {/*  || get(replay, 'fulfilled', false) */}
        {this.isReplayPossible() && (
          <React.Fragment>
            <Row>
              <Col lg={12}>
                <LoadButton
                  className="replay-audit-log-make-it-so"
                  disabled={get(replay, 'requesting', false)}
                  error={get(replay, 'error', false)}
                  fulfilled={get(replay, 'fulfilled', false)}
                  requesting={get(replay, 'requesting', false)}
                  onClick={this.onReplayAuditLog}
                >
                  Make it so!
                </LoadButton>
              </Col>
            </Row>

            {(get(replay, 'requesting', false) || get(replay, 'fulfilled', false)) && (
              <Replay
                records={this.getRecordsToReplay()}
                replayed={replayed}
                progress={progress}
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    return (
      <EditContainer
        requesting={false}
        title={<h2>Replay aus Audit Log</h2>}
        body={this.renderBody()}
      />
    );
  }
}

ReplayAuditLog.propTypes = {
  auditLog: PropTypes.object,
  filter: PropTypes.object,
  filtered: PropTypes.object,
  isAuthorized: PropTypes.bool,
  localFilters: PropTypes.object,
  onLogin: PropTypes.func,
  prepared: PropTypes.object,
  progress: PropTypes.object,
  replay: PropTypes.object,
  replayed: PropTypes.object,
  source: PropTypes.object,
  target: PropTypes.object
};

ReplayAuditLog.defaultProps = {
  auditLog: {},
  filter: {},
  filtered: {},
  isAuthorized: false,
  localFilters: {},
  onLogin: noop,
  prepared: {},
  progress: {},
  replay: {},
  replayed: {},
  source: {},
  target: {}
};

function mapStateToProps(state, ownProps) {
  return {
    auditLog: state.replayAuditLog.auditLog,
    filter: state.replayAuditLog.filter,
    filtered: state.replayAuditLog.filtered,
    isAuthorized: state.apiClient.isAuthorized,
    localFilters: state.replayAuditLog.localFilters,
    prepared: state.replayAuditLog.prepared,
    progress: state.replayAuditLog.progress,
    replay: state.replayAuditLog.replay,
    replayed: state.replayAuditLog.replayed,
    source: state.replayAuditLog.source,
    target: state.replayAuditLog.target,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLogin: bindActionCreators((formData) => API_CLIENT_LOGIN.request({ password: formData.password }), dispatch),
    replayActions: bindActionCreators(replayActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplayAuditLog);

import { Actions } from '@evoja-web/redaction';

import { leadHistoryAction } from './LeadHistory';
import { saveLeadHistoryAction } from './SaveLeadHistory';

export const actions = Actions([
  leadHistoryAction,
  saveLeadHistoryAction
]);

export default actions.get();

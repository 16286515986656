import axios from 'axios';
import { all, call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { API_CLIENT_LOAD } from '../../actions/ApiClientActions';
import { API_CLIENT_TEXT_BLOCKS_MAP } from '../../constants/ApiClientTextBlocks';
import { removeHost } from '../../utils';

export function* apiClientLoadSaga() {
  try {
    yield put(API_CLIENT_LOAD.pending());
    const textBlocksIds = Object.values(API_CLIENT_TEXT_BLOCKS_MAP);

    const [
      { data: textBlocks },
      { data: grvServices },
    ] = yield all([
      call(axios, { url: `${EndpointName.CORE_TEXT_BLOCK}/?in(id,(${textBlocksIds.join(',')}))` }),
      call(axios, { url: `/` }),
    ]);

    const endpoints = grvServices.services.map((it) => mapEndpoint(it));
    yield put(API_CLIENT_LOAD.success({
      textBlocks,
      endpoints,
    }));
  } catch (error) {
    yield put(API_CLIENT_LOAD.failure(error));
  }
}

function mapEndpoint(endpoint) {
  return {
    url: removeHost(endpoint.$ref),
    schema: removeHost(endpoint.profile),
  }
}

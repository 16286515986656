import axios from 'axios';
import { all, call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { FINANCING_PROFILING_LOAD_ACTIONS } from '../../actions/FinancingProfilingActions';
import {
  financingProfilingColumnsOrder,
  financingProfilingRowsOrder,
} from '../../constants/FinancingProfilingConstants';

export function* financingProfilingLoadSaga() {
  try {
    yield put(FINANCING_PROFILING_LOAD_ACTIONS.pending());

    const entityCodeIds = [
      ...Object.keys(financingProfilingColumnsOrder),
      ...Object.keys(financingProfilingRowsOrder),
    ];
    const [
      { data: entityCodes },
      { data: profiles },
      { data: loanProducts },
      { data: durations },
    ] = yield all([
      call(axios, { url: getEntityCodes(entityCodeIds) }),
      call(axios, { url: getAll(EndpointName.FINANCING_PROFILING) }),
      call(axios, { url: getAll(EndpointName.FINANCING_LOAN_PRODUCT) }),
      call(axios, { url: getAll(EndpointName.FINANCING_DURATION) }),
    ]);

    yield put(FINANCING_PROFILING_LOAD_ACTIONS.success({
      entityCodes,
      profiles,
      loanProducts,
      durations,
    }));
  } catch (error) {
    yield put(FINANCING_PROFILING_LOAD_ACTIONS.failure(error));
  }
}

function getAll(endpoint) {
  return `${endpoint}/?limit(10000)`;
}

function getEntityCodes(ids) {
  const filter = `in(id,(${ids.join(',')}))`;
  return `${EndpointName.ENTITY_CODE}/?${filter}&limit(10000)`;
}

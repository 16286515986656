import React from 'react';
import PropTypes from 'prop-types';
import { has, get, noop } from 'lodash';
import { CodeSelect, NumberInput } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

class NoteToEDossierPostActionConfig extends React.Component {
  constructor(props) {
    super(props);

    const { fetchCodes } = props;
    fetchCodes(['archiveDocumentType']);

    this.onFilenameChange = this.onFilenameChange.bind(this);
  }

  onFilenameChange(ev) {
    const { onChange } = this.props;

    const value = get(ev, 'target.value', '');

    const filename = value.trim().length === 0
      ? undefined
      : value.trim();

    onChange('filename', filename);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      codes,
      edit,
      language,
      onChange,
      params
    } = this.props;

    const archiveDocumentType = has(params, 'formNr')
      ? get(codes, 'archiveDocumentType', []).find((c) => c.number === get(params, 'formNr'))
      : undefined;

    return (
      <div>
        <FormGroup>
          <ControlLabel>
            <FormattedMessage id="Workguide.PostAction.NoteToEDossier.FormNr" />
          </ControlLabel>

          <CodeSelect
            id="formNr"
            codes={get(codes, 'archiveDocumentType', [])}
            disabled={!edit}
            language={language}
            onChange={(id, value) => onChange(id, get(value, 'number'))}
            value={archiveDocumentType}
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>
            <FormattedMessage id="Workguide.PostAction.NoteToEDossier.SearchValue" />
          </ControlLabel>

          <NumberInput
            id="searchValue"
            disabled={!edit}
            onChange={(id, value) => onChange(id, `${value}`)}
            value={get(params, 'searchValue')}
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>
            <FormattedMessage id="Workguide.PostAction.NoteToEDossier.Filename" />
          </ControlLabel>

          <FormControl
            id="filename"
            disabled={!edit}
            onChange={this.onFilenameChange}
            value={get(params, 'filename', '')}
          />
        </FormGroup>
      </div>
    );
  }
}

NoteToEDossierPostActionConfig.propTypes = {
  codes: PropTypes.object,
  edit: PropTypes.bool,
  fetchCodes: PropTypes.func,
  onChange: PropTypes.func,
  params: PropTypes.object,
  language: PropTypes.string
};

NoteToEDossierPostActionConfig.defaultProps = {
  codes: {},
  edit: false,
  fetchCodes: noop,
  onChange: noop,
  params: {},
  language: 'de'
};

export default NoteToEDossierPostActionConfig;

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ListGroupItem } from 'react-bootstrap';

import ConditionForm from './Form';
import CriteriaField from '../CriteriaField/CriteriaField.js';

import { operations } from '../constants';

class ListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormCancel = this.onFormCancel.bind(this);
  }

  /**
   * Handle form submit and hide form
   *
   * @param  {Object} condition Condition object
   *
   * @return void
   */
  onFormSubmit(condition) {
    const { onChange } = this.props;
    this.setState({ edit: false });

    onChange(condition);
  }

  /**
   * Handle form cancel (hide form)
   *
   * @return {[type]} [description]
   */
  onFormCancel() {
    this.setState({ edit: false });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { edit } = this.state;
    const {
      language,
      codes,
      consultants,
      productGroups,
      components,
      onRemove,
      target,
      condition
    } = this.props;

    const criteria = _.get(condition, 'criteria');
    const operation = _.get(condition, 'operation');
    const source = _.get(criteria, 'source');
    const component = components.find(c => _.get(c, 'props.id') === source);

    // Show warning if soruce component could not be found.
    // This can be the case if the source component id was changed
    if (_.isUndefined(component)) return (
      <ListGroupItem>
        <div className="pull-right">
          <span onClick={onRemove}><i className="glyphicon glyphicon-remove" /></span>
        </div>
        <p><strong>Source Feld {source} wurde konnte nicht gefunden werden!</strong></p>
        <p> Wurde das Feld umbenannt oder gelöscht?</p>
      </ListGroupItem>
    );

    // If we're in edit mode, render form instead of item
    if (edit) return (
      <ConditionForm
        target={target}
        condition={condition}
        onSubmit={this.onFormSubmit}
        onCancel={this.onFormCancel}
      />
    );

    return(
      <ListGroupItem>
        <div>
          <div className="pull-right">
            <span onClick={() => this.setState({ edit: true })}><i className="glyphicon glyphicon-pencil" /></span>
            <span onClick={onRemove}><i className="glyphicon glyphicon-remove" /></span>
          </div>
        </div>

        <div>
          <span style={{ paddingLeft: '10px' }}>
            {_.get(components.find(c => _.get(c, 'props.id') === _.get(criteria, 'source')), `props.title.${language}`, 'fooooo')}
            {' ('}
            {_.get(components.find(c => _.get(c, 'props.id') === _.get(criteria, 'source')), 'props.id', 'fooooo')}
            {')'}
          </span>
          <span style={{ paddingLeft: '10px' }}><strong>{_.get(operations, `${operation}.label`)}</strong></span>
          <span style={{ paddingLeft: '10px' }}>
            <CriteriaField
              edit={false}
              language={language}
              target={{}}
              source={component}
              criteria={criteria}
              onChange={this.onCriteriaChange}
              codes={codes}
              consultants={consultants}
              productGroups={productGroups}
              operation={operation}
            />
          </span>
        </div>
      </ListGroupItem>
    );
  }
}

ListItem.propTypes = {
  index: PropTypes.number.isRequired,
  target: PropTypes.object.isRequired,
  language: PropTypes.string,
  condition: PropTypes.object,
  components: PropTypes.array,
  codes: PropTypes.object,
  consultants: PropTypes.array,
  productGroups: PropTypes.array,
  onChange: PropTypes.func
};

ListItem.defaultProps = {
  language: 'de',
  condition: {},
  components: [],
  codes: {},
  consultants: [],
  productGroups: [],
  onChange: _.noop
};

export default ListItem;

import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import { getSurchargeRealestatetypes } from '../../selectors/FinancingSurchargeRealestatetype';
import { createRef, getIdFromRef } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';

export function* updateSurchargeRealestatetypesSaga(changedItems) {
  const initialValues = yield select(getSurchargeRealestatetypes);
  const toUpdate = changedItems.filter((changedItem) => {
    const initialItem = initialValues.find((it) => it.id === changedItem.id);
    if (!initialItem) {
      return false;
    }
    return (
      initialItem.firstMortgageRate  !== changedItem.firstMortgageRate  ||
      initialItem.secondMortgageRate !== changedItem.secondMortgageRate ||
      initialItem.withAdditionRate   !== changedItem.withAdditionRate   ||
      getIdFromRef(initialItem.realestatetype.$ref) !== getIdFromRef(changedItem.realestatetype.$ref)
    );
  });

  yield all(
    toUpdate.map((item) => call(axios, {
      url: createRef(EndpointName.FINANCING_SURCHARGE_REALESTATETYPE, item.id),
      method: 'put',
      data: item,
    }))
  );
}

import { createSelector } from 'reselect';
import { ANALYTICS_TEXT_BLOCKS_MAP } from '../../constants/AnalyticsTextBlocks';
import { getLanguage } from '../commonSelectors';

export const getAnalyticsState = (state) => state.analytics;
export const getAnalyticsError = createSelector([getAnalyticsState], (state) => state.error);
export const getAnalyticsLoading = createSelector([getAnalyticsState], (state) => state.loading);
export const getAnalyticsTextBlocks = createSelector([getAnalyticsState], (state) => state.textBlocks);

export const getAnalyticsTexts = createSelector([
  getAnalyticsTextBlocks,
  getLanguage,
], (textBlocks, language) => {
  return Object.keys(ANALYTICS_TEXT_BLOCKS_MAP).reduce((result, key) => {
    const textBlock = textBlocks.find((it) => it.id === ANALYTICS_TEXT_BLOCKS_MAP[key]);
    return {
      ...result,
      [key]: textBlock && textBlock.text[language]
        ? textBlock.text[language]
        : '',
    };
  }, {});
});

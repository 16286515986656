import React, { Component } from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { Grid, Row, Col, DropdownButton, MenuItem, Checkbox, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import _ from 'lodash';

import PdfPreview from '../components/Documents/PdfPreview';
import MyDropzone from '../components/Documents/MyDropzone';
import MoreButton from '../components/Documents/MoreButton';
import EditContainer from '../components/EditContainer';
import Spinner from '../components/Spinner';

import * as actions from '../actions/DocumentActions';
import { handleGravitonError } from "../utils";
import { createStructuredSelector } from 'reselect';
import { getDirectFileLink, getLanguage } from '../selectors/commonSelectors';
import {
  getButtons,
  getDocuments,
  getError,
  getHasError,
  getModules,
  getRequesting,
  getRequestingChunk,
  getScreens,
  getSearchString,
  getSelectedModule,
  getShowButtons,
  getTotalItems,
} from '../selectors/Documents';

const SortableItem = SortableElement(({
  metadata, save, links, delFile, id, modules, screens, buttons, url, loading, error,
}) =>
  (<Col md={4}>
    <div
      style={{
        width: '220px',
        minHeight: '350px',
        textAlign: 'left',
      }}
    >
      <PdfPreview
        metadata={metadata}
        blob={url}
        loading={loading}
        error={error}
        delFile={delFile.bind(this)}
        save={save}
        id={id}
        links={links}
        modules={modules}
        screens={screens}
        buttons={buttons}
      />
    </div>
   </Col>));

const SortableList = SortableContainer(({
  items, delFile, modules, screens, buttons, save, getDirectFileLink,
}) =>
  (<Row>
    {items.map((value, index) =>
      (<SortableItem
        key={`item-${index}`}
        index={index}
        id={value.id}
        metadata={value.metadata}
        loading={value.loading}
        error={value.error}
        links={value.links}
        delFile={delFile}
        save={save}
        modules={modules}
        screens={screens}
        buttons={buttons}
        url={getDirectFileLink(value.id)}
      />))}
   </Row>));

class Documents extends Component {
  constructor(props) {
    super(props);

    props.dispatch(actions.getModules());
    props.dispatch(actions.getButtons());
    props.dispatch(actions.getDocumentsWithMetadata());

    this.save = this.save.bind(this);
    this.loadNextChunk = this.loadNextChunk.bind(this);
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const newOrder = arrayMove(this.props.documents, oldIndex, newIndex);

    newOrder.forEach((element, index) => {
      element = update(element, {
        metadata: { order: { $set: index } },
      });
      this.props.dispatch(actions.updateDocument(element.id, element.metadata, element.links));
    });
  };

  loadNextChunk() {
    const {
      totalItems, documents, dispatch, selectedModule, searchString,
    } = this.props;
    if (totalItems > documents.length) {
      dispatch(actions.getDocumentsWithMetadata({
        searchString, chunked: true, offset: documents.length, module: selectedModule,
      }));
    }
  }

  // handle dropzone file-drop event
  onDrop(acceptedFiles, rejectedFiles) {
    const { selectedModule, language } = this.props;
    acceptedFiles.filter((file) => isFilenameValid(file.name)).forEach((file) => {
      toast.info(`${file.name} wurde akzeptiert`, { autoClose: 2000 });
      this.props.dispatch(actions.saveDocument(file, selectedModule, language));
    });
    acceptedFiles.filter((file) => !isFilenameValid(file.name)).forEach((file) => {
      toast.error(
        `${file.name} enthält unzulässige Zeichen. Nur die folgenden Zeichen sind zulässig: a-zA-Z0-9_.-`,
        { autoClose: 20000 },
      );
    });
    rejectedFiles.forEach((file) => {
      toast.error(
        `${file.name} hat den falschen Dateityp. Es werden nur PDF-Dateien und Bilder akzeptiert`,
        { autoClose: 20000 },
      );
    });
  }

  selectModule(module) {
    const selectedModule = module === 'all' ? 'all' : this.props.modules[module].id;
    const { dispatch, searchString } = this.props;
    dispatch(actions.setData({ key: 'selectedModule', value: selectedModule }));
    dispatch(actions.setData({ key: 'module', value: module }));
    dispatch(actions.setData({ key: 'showButtons', value: false }));
    dispatch(actions.getDocumentsWithMetadata({ searchString, module: selectedModule }));
  }

  // filter documents with button informations when checkbox is checked
  selectButton() {
    const { showButtons, selectedModule, dispatch } = this.props;
    dispatch(actions.setData({ key: 'searchString', value: '' }));
    dispatch(actions.setData({ key: 'showButtons', value: !this.props.showButtons }));
    if (!showButtons) {
      dispatch(actions.getDocumentsWithMetadata({ module: selectedModule, button: true }));
    } else {
      dispatch(actions.getDocumentsWithMetadata({ module: selectedModule, button: false }));
    }
  }

  moduleSelector() {
    const {
      modules, selectedModule, language, searchString,
    } = this.props;
    const keyModules = _.keyBy(modules, 'id');
    const items = modules.map((module, i) =>
      <MenuItem key={i} eventKey={i}>{module.name[language]}</MenuItem>);
    const displayModule = _.get(keyModules, `${selectedModule}.name.${language}`, 'Alle Module');


    const dropzone = selectedModule !== 'all' ?
      (<MyDropzone onDrop={this.onDrop.bind(this)} />) :
      '';

    return (
      <div>
        <DropdownButton
          onSelect={this.selectModule.bind(this)}
          bsStyle="primary"
          title="Modul"
          id="moduleSelector"
        >
          <MenuItem key="all" eventKey="all">Alle Module</MenuItem>
          {items}
        </DropdownButton>
        <span style={{ marginLeft: '5px' }}>{displayModule}</span>
        <Checkbox
          onChange={this.selectButton.bind(this)}
          checked={this.props.showButtons}
          inline
          style={{ marginLeft: '10px' }}
        >
          Dokumente hinter Buttons
        </Checkbox>
        <span className="pull-right">
          <Button onClick={this.search.bind(this)} bsSize="small" bsStyle="primary">suchen</Button>
          <input placeholder="Dateinamen" value={searchString} onChange={this.setSearch.bind(this)} type="text" style={{ marginRight: 5, marginLeft: 10 }} />
          <span onClick={this.resetSearch.bind(this)} className="icon-202-clear-circle" />
        </span>
        { dropzone }
      </div>
    );
  }

  setSearch(e) {
    this.props.dispatch(actions.setData({ key: 'searchString', value: e.target.value }));
  }

  resetSearch() {
    this.props.dispatch(actions.setData({ key: 'searchString', value: '' }));
    this.props.dispatch(actions.getDocumentsWithMetadata({ searchString: '' }));
  }

  search() {
    const { searchString, selectedModule } = this.props;
    this.props.dispatch(actions.getDocumentsWithMetadata({ searchString, module: selectedModule }));
  }

  delFile(fileId, event) {
    event.preventDefault();
    this.props.dispatch(actions.deleteDocument(fileId));
  }

  save(fileId, filename, moduleId, screenId, buttonId, isMandatory) {
    let { language, documents } = this.props;
    // set new filename
    const documentIndex = _.findIndex(documents, document => document.id === fileId);
    documents = update(documents, {
      [documentIndex]: { metadata: { filename: { $set: filename } } },
    });
    // find module link index
    const moduleIndex = _.findIndex(documents[documentIndex].links, link => link.type === 'module');
    // replace existing links when shifting module
    documents = update(documents, {
      [documentIndex]: { links: { [moduleIndex]: { $ref: { $set: `/core/module/${moduleId}` } } } },
    });
    // find language link index
    const langIndex = _.findIndex(documents[documentIndex].links, link => link.type === 'language');
    // add language link if necessary
    if (langIndex < 0) {
      documents = update(documents, {
        [documentIndex]: { links: { $push: [{ type: 'language', $ref: `/i18n/language/${language}` }] } },
      });
    }

    // set directLink button key/value pair in additionalProperties
    const additionalPropertiesNames = ['button', 'mandatoryAdvisoryDocument'];
    const additionalProperties = documents[documentIndex].metadata.additionalProperties
      .filter(property => !additionalPropertiesNames.includes(property.name));
    documents = update(documents, {
      [documentIndex]: { metadata: { additionalProperties: { $set: additionalProperties } } },
    });
    if (buttonId !== 'none') {
      documents = update(documents, {
        [documentIndex]: { metadata: { additionalProperties: { $push: [{ name: 'button', value: buttonId }] } } },
      });
    }
    if (isMandatory) {
      documents = update(documents, {
        [documentIndex]: { metadata: { additionalProperties: {
          $push: [{ name: 'mandatoryAdvisoryDocument', value: "true" }],
        } } },
      });
    }

    documents = update(documents, {
      [documentIndex]: { links: { $set: documents[documentIndex].links.filter(link => link.type !== 'screen')} },
    });
    if (screenId !== 'none') {
      const screenLink = {
        type: 'screen',
        $ref: `/core/screen/${screenId}`
      };
      documents = update(documents, {
        [documentIndex]: { links: { $push: [screenLink]} },
      });
    }

    // save to the API
    this.props.dispatch(actions.updateDocument(
      fileId,
      documents[documentIndex].metadata,
      documents[documentIndex].links,
    ));
    // remove array entry from store when module has shifted
    if (
      _.last(documents[documentIndex].links[moduleIndex].$ref.split('/')) !== this.props.selectedModule &&
        this.props.selectedModule !== 'all'
    ) {
      this.props.dispatch(actions.setData({ key: 'documents', value: documents.filter(document => document.id !== documents[documentIndex].id) }));
    }
  }

  preview() {
    const { totalItems, requestingChunk, documents, getDirectFileLink } = this.props;
    return (
      <div>
        <Grid>
          <SortableList
            items={this.props.documents}
            delFile={this.delFile.bind(this)}
            onSortEnd={this.onSortEnd.bind(this)}
            save={this.save}
            modules={this.props.modules}
            screens={this.props.screens}
            buttons={this.props.buttons}
            getDirectFileLink={getDirectFileLink}
            axis="xy"
            distance={10}
            useWindowAsScrollContainer
            hideSortableGhost
          />
        </Grid>
        <div style={{ textAlign: 'center' }}>
          {
          requestingChunk ? <Spinner /> :
            totalItems > documents.length ?
              <MoreButton value="Mehr anzeigen" onClick={this.loadNextChunk} /> :
            ''
        }
        </div>
      </div>
    );
  }

  render() {
    const { hasError, error } = this.props;
    if (hasError) {
      toast.error(handleGravitonError(error));
    }
    return (
      <EditContainer
        requesting={this.props.requesting}
        header={this.moduleSelector()}
        title="Bankdokumente"
        body={this.preview()}
      />
    );
  }
}

function isFilenameValid(name) {
  return /^[0-9a-zA-Z-_.]*$/.test(name);
}

const mapStateToProps = createStructuredSelector({
  documents: getDocuments,
  requesting: getRequesting,
  requestingChunk: getRequestingChunk,
  modules: getModules,
  screens: getScreens,
  buttons: getButtons,
  showButtons: getShowButtons,
  selectedModule: getSelectedModule,
  language: getLanguage,
  totalItems: getTotalItems,
  hasError: getHasError,
  error: getError,
  searchString: getSearchString,
  getDirectFileLink: getDirectFileLink,
});

export default connect(mapStateToProps)(Documents);

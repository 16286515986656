import { put } from 'redux-saga/effects';

// ToDo: Move functions like handleNextHeaders to own repo
import filterAction from '../../actions/ReplayAuditLog/Filter';

const {
  SET_FILTER_PENDING,
  SET_FILTER_FULFILLED,
  SET_FILTER_REJECTED
} = filterAction;

export default function* setFilter(request) {
  const { dataKey, payload } = request;

  yield put({ type: SET_FILTER_PENDING, dataKey });

  try {
    yield put({ type: SET_FILTER_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: SET_FILTER_REJECTED, dataKey, error });

    return error;
  }
}

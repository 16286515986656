import './FinancingRatesDuration.css';

import React from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import {
  BASE_RATE_PROP_TYPES,
  DURATION_PROP_TYPES,
  FORWARD_PERIOD_PROP_TYPES,
  FORWARD_PROP_TYPES,
  REFI_RATE_PROP_TYPES,
} from './FinancingRatesPropTypes';
import PropTypes from 'prop-types';
import { FinancingRatesForwardsDialog } from './FinancingRatesForwardsDialog';

export class FinancingRatesDuration extends React.Component {
  state = {
    baseRateValue: this.props.baseRate ? this.props.baseRate.baseRate : '',
    refiRateValue: this.props.refiRate ? this.props.refiRate.interestRate : '',
  };

  constructor(props) {
    super(props);
    this.updateBaseRate = this.updateBaseRate.bind(this);
    this.updateRefiRate = this.updateRefiRate.bind(this);
    this.onChangeBaseRate = this.onChangeBaseRate.bind(this);
    this.onChangeRefiRate = this.onChangeRefiRate.bind(this);
  }

  render() {
    const {
      duration,
      baseRate,
      refiRate,
      forwards,
      forwardPeriods,
      updateForwards,
      syncForwards,
    } = this.props;
    const { baseRateValue, refiRateValue } = this.state;
    const forwardsDialogTitle = `Forwards ${baseRate ? baseRate.designation : ''} (${duration.id})`;

    return (
      <div className="financing-rates-duration">
        <Row>
          <Col sm={2}>{duration.id}</Col>
          <Col sm={1}>{duration.productType}</Col>
          <Col sm={4}>{baseRate ? baseRate.designation : null}</Col>
          <Col sm={2}>
            <FormControl
              type="number"
              value={baseRateValue}
              placeholder={baseRate ? 'Base Rate' : 'n/a'}
              disabled={!baseRate}
              onChange={this.onChangeBaseRate}
              onBlur={this.updateBaseRate}
            />
          </Col>
          <Col sm={2}>
            <FormControl
              type="number"
              value={refiRateValue}
              placeholder={refiRate ? 'Refi Rate' : 'n/a'}
              disabled={!refiRate}
              onChange={this.onChangeRefiRate}
              onBlur={this.updateRefiRate}
            />
          </Col>
          <Col sm={1}>
            <FinancingRatesForwardsDialog
              duration={duration}
              title={forwardsDialogTitle}
              forwards={forwards}
              forwardPeriods={forwardPeriods}
              updateForwards={updateForwards}
              syncForwards={syncForwards}
            />
          </Col>
        </Row>
      </div>
    );
  }

  updateBaseRate() {
    const { updateBaseRate, baseRate } = this.props;
    if (!baseRate) {
      return;
    }

    const { baseRateValue } = this.state;
    if (!baseRateValue) {
      this.setState({ baseRateValue: '0.0000' });
      return;
    }
    updateBaseRate(baseRate, baseRateValue);
  }

  updateRefiRate() {
    const { updateRefiRate, refiRate } = this.props;
    if (!refiRate) {
      return;
    }

    const { refiRateValue } = this.state;
    if (!refiRateValue) {
      this.setState({ refiRateValue: '0.0000' });
      return;
    }
    updateRefiRate(refiRate, refiRateValue);
  }

  onChangeBaseRate(event) {
    const { value } = event.target;
    this.setState({ baseRateValue: value });
  }

  onChangeRefiRate(event) {
    const { value } = event.target;
    this.setState({ refiRateValue: value });
  }
}

FinancingRatesDuration.propTypes = {
  duration: DURATION_PROP_TYPES.isRequired,
  baseRate: BASE_RATE_PROP_TYPES,
  refiRate: REFI_RATE_PROP_TYPES,
  forwards: PropTypes.arrayOf(FORWARD_PROP_TYPES).isRequired,
  forwardPeriods: PropTypes.arrayOf(FORWARD_PERIOD_PROP_TYPES).isRequired,
  updateBaseRate: PropTypes.func.isRequired,
  updateRefiRate: PropTypes.func.isRequired,
  updateForwards: PropTypes.func.isRequired,
  syncForwards: PropTypes.func.isRequired,
};

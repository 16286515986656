import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Common/Button';
import { ModalBody, ModalFooter, ModalHeader } from '../Common/Modal';

export class UiNotesDeleteForm extends React.Component {
  render() {
    const { onCancel, onSubmit } = this.props;

    return (
      <form>
        <ModalHeader closeButton={true}>
          <div>Delete UI note</div>
        </ModalHeader>
        <ModalBody>
          Are you sure?
        </ModalBody>
        <ModalFooter>
          <Button type="button"
                  onClick={onCancel}
                  bsSize="sm">
            Cancel
          </Button>
          <Button type="button"
                  onClick={onSubmit}
                  bsSize="sm"
                  bsStyle="primary">
            Delete
          </Button>
        </ModalFooter>
      </form>
    );
  }
}

UiNotesDeleteForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

import axios from 'axios';
import { isEqual } from 'lodash';
import { all, call, select } from 'redux-saga/effects';
import {
  getSetManagementBundlesRaw,
  getSetManagementOriginalBundles,
} from '../../selectors/SetManagement/setManagementCommonSelectors';
import { createRef, getIdFromRef } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';

export function* setManagementSaveBundles() {
  const bundlesOriginal = yield select(getSetManagementOriginalBundles);
  const bundles = yield select(getSetManagementBundlesRaw);

  const toSave = bundles.filter((bundle) => {
    const original = bundlesOriginal.find((it) => it.id === bundle.id);
    return original
      ? !isBundlesEqual(bundle, original)
      : false;
  });


  yield all(
    toSave.map((bundle) => call(axios, {
      method: 'put',
      url: createRef(EndpointName.BASIC_PRODUCT_BUNDLE_BUNDLE, bundle.id),
      data: bundle,
    }))
  );
}

function isBundlesEqual(bundle, original) {
  return bundle.order === original.order &&
    bundle.bundlePrice === original.bundlePrice &&
    bundle.bundlePriceReduced === original.bundlePriceReduced &&
    bundle.bundlePriceYouth === original.bundlePriceYouth &&
    isEqual(bundle.name, original.name) &&
    isEqual(bundle.shortName, original.shortName) &&
    getIdFromRef(bundle.domain[0].$ref) === getIdFromRef(original.domain[0].$ref);
}

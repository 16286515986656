import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, defaultsDeep, orderBy } from 'lodash';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import update from 'immutability-helper';
import { Button, Row, Col } from 'react-bootstrap';

import ApprovalListItem from './ListItem';
import ApprovalForm from './Form';

const SortableItem = SortableElement(ApprovalListItem);

const SortableList = SortableContainer(({
  approval,
  onSubmit,
  onRemove,
  ...rest
}) => {
  const children = approval.map((item, index) => {
    return (
      <SortableItem
        key={index}
        index={index}
        data={item}
        onSubmit={(data) => onSubmit(index, data)}
        onRemove={() => onRemove(index)}
        {...rest}
      />
    );
  });

  return (
    <ul>
      {children}
    </ul>
  )
});

class ApprovalList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      add: false
    };

    this.onSortEnd = this.onSortEnd.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  onAdd() {
    this.setState({ add: true });
  }

  onCancel() {
    this.setState({ add: false });
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { approval, editValueField } = this.props;
    const updated = arrayMove(approval, oldIndex, newIndex)
      .map((item, index) => ({ ...item, order: index }));

    editValueField('approval', 'approval', updated);
  }

  onSubmit(index = -1, data) {
    const { approval, editValueField } = this.props;

    data = defaultsDeep({}, data, { order: get(approval, 'length', 0) });

    // Update isFinalStep on approvals if isFinalStep is set to true on data
    // as only one approval can be the final step
    let updated = get(data, 'isFinalStep', false)
      ? approval.map((a) => update(a, { 'isFinalStep': { $set: false } }))
      : approval;


    updated = index > -1
      ? update(updated, { $splice: [[index, 1, data]] })
      : update(updated, { $push: [data] });

    editValueField('approval', 'approval', updated);

    this.setState({ add: false });
  }

  onRemove(index = -1) {
    const {
      approval,
      editValueField
    } = this.props;

    let updated = [...approval];
    if (index > -1) {
      updated = update(approval, {
        $splice: [[index, 1]]
      });
    }

    editValueField('approval', 'approval', updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { add } = this.state;
    const {
      approval,
      codes,
      consultants
    } = this.props;

    const ordered = orderBy(
      approval,
      ['order'],
      ['asc']
    );

    return (
      <div className="workguide-approval-list">
        <Button
          onClick={this.onAdd}
          style={{ backgroundColor: '#fff', borderColor: '#fff' }}
        >
          <i className="glyphicon glyphicon-plus" />
          <span style={{ paddingLeft: '5px' }}>
            Hinzufügen
          </span>
        </Button>

        {add && (
          <Row style={{ padding: '20px', paddingLeft: '100px' }}>
            <Col lg={12} md={12}>
              <ApprovalForm
                onSubmit={(data) => this.onSubmit(-1, data)}
                onCancel={this.onCancel}
                codes={codes}
                consultants={consultants}
              />
            </Col>
          </Row>
        )}

        <SortableList
          approval={ordered}
          onSortEnd={this.onSortEnd}
          distance={1}
          onSubmit={this.onSubmit}
          onRemove={this.onRemove}
          useDragHandle
          consultants={consultants}
          codes={codes}
        />
      </div>
    );
  }
}

ApprovalList.propTypes = {
  approval: PropTypes.array,
  editValueField: PropTypes.func,
  codes: PropTypes.object,
  consultants: PropTypes.array
};

ApprovalList.defaultProps = {
  approval: [],
  editValueField: noop,
  codes: {},
  consultants: []
};

export default SortableContainer(ApprovalList);

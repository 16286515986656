import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import cl from 'classnames';

import './Card.css';

const ModuleOverviewCard = React.memo(function ModuleOverviewCard({
  language,
  module,
  onClick,
  onToggleFavourite,
  tenant
}) {
  const id = get(module, 'id', false);
  const isFavourite = get(module, 'isFavourite', false);
  const title = get(module, `name.${language}`);
  const description = get(module, `service.0.description.${language}`, title);

  return (
    <div className="module-overview--card">
      <div className="module-overview--card--header">
        <div
          className={cl('module-overview--card--header--favourite', {
            mdi: true,
            'mdi-star-outline': !isFavourite,
            'mdi-star': isFavourite,
            'text-warning': isFavourite
          })}
          onClick={() => onToggleFavourite({ module })}
        />

        <div className="module-overview--card--header--title">
          {title}
        </div>
      </div>

      <div
        className="module-overview--card--content"
        onClick={() => onClick({ module })}
      >
        <div className="module-overview--card--content--image">
          <img
            src={`/images/${tenant}/badge_${id}.jpg`}
            alt={title}
            className="img-circle"
          />
        </div>

        <div className="module-overview--card--content--description">
          {description}
        </div>
      </div>
    </div>
  );
});

ModuleOverviewCard.propTypes = {
  language: PropTypes.string,
  module: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onToggleFavourite: PropTypes.func,
  tenant: PropTypes.string
};

ModuleOverviewCard.defaultProps = {
  language: 'de',
  onClick: noop,
  onToggleFavourite: noop,
  tenant: 'swisscom'
};

export default ModuleOverviewCard;

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import {
  Col,
  FormGroup,
  Tab,
  Tabs,
  ControlLabel,
  FormControl
} from 'react-bootstrap';
import Toggle from 'react-toggle';
import Select from 'react-select';

class ActivitySubStatusConfiguration extends React.Component {
  constructor(props) {
    super(props);

    const {
      codes,
      fetchCodes,
      item,
      languages
    } = props;

    const defaults = get(item, 'props.defaults', {});
    const isJmesInput = languages.reduce((result, language) => {
      result[language] = get(defaults, `${language}[0]`, '').includes('jmes:');
      return result;
    }, {});

    this.state = {
      activeTab: 'de',
      isJmesInput
    };

    // Fetch necessary codes
    if (!codes.activitySubStatus) fetchCodes('activitySubStatus');

    this.onToggleJmesDefaultInput = this.onToggleJmesDefaultInput.bind(this);
    this.onJmesDefaultChange = this.onJmesDefaultChange.bind(this);
    this.onSelectDefaultChange = this.onSelectDefaultChange.bind(this);
    this.onActiveTabChange = this.onActiveTabChange.bind(this);
  }

  /**
   * Switch active tab
   *
   * @param   {String}  key  Tab key
   *
   * @return  void
   */
  onActiveTabChange(key) {
    this.setState({ activeTab: key });
  }

  /**
   * Toggle jmes input field
   *
   * @param   {String}  language  Current language
   *
   * @return  void
   */
  onToggleJmesDefaultInput(language) {
    const { isJmesInput, selected } = this.state;
    const { id, item, editValueField } = this.props;
    const defaults = get(item, 'props.defaults', {});

    this.setState({
      isJmesInput: { ...isJmesInput, [language]: !isJmesInput[language] },
      selected: { ...selected, [language]: undefined }
    });
    editValueField(id, 'defaultValue', { ...defaults, [language]: [] });
  }

  /**
   * Handle jmes input change
   *
   * @param   {String}  language Current language
   * @param   {Event}   ev       Change event
   *
   * @return  void
   */
  onJmesDefaultChange(language, ev) {
    const { id, item, editValueField } = this.props;
    const defaults = get(item, 'props.defaults', {});
    const value = get(ev, 'target.value');

    editValueField(id, 'defaultValue', { ...defaults, [language]: [`jmes:${value.replace('jmes:', '')}`] });
  }

  /**
   * Handle change of the default value select
   *
   * @return  {[type]}  [return description]
   */
  onSelectDefaultChange(lang, selected) {
    const {
      id,
      item,
      editValueField,
      languages
    } = this.props;

    const defaults = get(item, 'props.defaults', {});
    const value = get(selected, 'value');
    const updated = languages
      .filter((l) => l !== lang)
      .reduce((result, l) => {
        return {
          ...result,
          [l]: get(defaults, l, [value])
        };
      }, { [lang]: [value] });

    editValueField(id, 'defaultValue', updated);
  }

  /**
   * Render default value tabs for each given language
   *
   * @return  {[type]}  [return description]
   */
  renderTabs() {
    const {
      codes,
      item,
      languages
    } = this.props;

    const defaults = get(item, 'props.defaults', {});
    const options = get(codes, 'activitySubStatus', [])
      .map((code) => ({
        value: get(code, 'id'),
        label: get(code, 'text.de')
      }));

    const tabs = languages.map((l) => (
      <Tab key={l} eventKey={l} title={l}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <Toggle
              checked={get(this, `state.isJmesInput.${l}`, false)}
              onChange={() => this.onToggleJmesDefaultInput(l)}
            />
          </div>
          <div style={{ paddingLeft: '10px' }}>Pfad manuell eingeben</div>
        </div>

        {get(this, `state.isJmesInput.${l}`, false) ? (
          <FormControl
            type="text"
            value={get(defaults, `${l}[0]`, '')}
            onChange={(ev) => this.onJmesDefaultChange(l, ev)}
          />
        ) : (
          <Select
            options={options}
            value={get(defaults, `${l}[0]`)}
            onChange={(selected) => this.onSelectDefaultChange(l, selected)}
          />
        )}
      </Tab>
    ));

    return tabs;
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { activeTab } = this.state;
    return (
      <div className="workguide-activity-sub-status-configuration">
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Standard Wert
          </Col>

          <Col sm={9}>
            <Tabs
              id="action-title"
              activeKey={activeTab}
              onSelect={this.onActiveTabChange}
            >
              {this.renderTabs()}
            </Tabs>
          </Col>
        </FormGroup>
      </div>
    );
  }
}

ActivitySubStatusConfiguration.propTypes = {
  codes: PropTypes.object,
  editValueField: PropTypes.func,
  fetchCodes: PropTypes.func,
  id: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  languages: PropTypes.array
};

ActivitySubStatusConfiguration.defaultProps = {
  codes: {},
  editValueField: noop,
  fetchCodes: noop,
  languages: ['de', 'fr', 'en']
};

export default ActivitySubStatusConfiguration;

import { attachReducer, chainReducers } from '../utils';
import {
  LoadBasicProductsReducer,
  SaveBasicProductsReducer,
  SortBasicProductsReducer,
  CreateBasicProductsReducer,
  UpdateBasicProductsReducer,
  RemoveBasicProductsReducer,
} from './BasicProducts';
import {
  SORT_BASIC_PRODUCTS_ACTIONS,
  CREATE_BASIC_PRODUCTS_ACTIONS,
  UPDATE_BASIC_PRODUCTS_ACTIONS,
  REMOVE_BASIC_PRODUCTS_ACTIONS,
} from '../actions/BasicProductsActions';

export const BasicProductsReducer = chainReducers(
  LoadBasicProductsReducer,
  SaveBasicProductsReducer,
  attachReducer(SORT_BASIC_PRODUCTS_ACTIONS.REQUEST, SortBasicProductsReducer),
  attachReducer(CREATE_BASIC_PRODUCTS_ACTIONS.REQUEST, CreateBasicProductsReducer),
  attachReducer(UPDATE_BASIC_PRODUCTS_ACTIONS.REQUEST, UpdateBasicProductsReducer),
  attachReducer(REMOVE_BASIC_PRODUCTS_ACTIONS.REQUEST, RemoveBasicProductsReducer),
);

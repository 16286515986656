import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import _ from 'lodash';
import { List } from 'react-virtualized';
import { FormControl, Form } from 'react-bootstrap';
import Fuse from 'fuse.js'

import EditContainer from '../components/EditContainer';
import * as leadqualificationActions from '../actions/Leadqualification';
import Item from '../components/Leadqualification/Item';

class Leadqualification extends React.Component {
  constructor() {
    super();
    this.updateService = _.debounce(this.updateService, 200);

    this.state = {
      filterValue: "",
    }
  }

  componentWillMount() {
    const { actions } = this.props;

    actions.leadqualificationRequest();
    actions.codesRequest('postAction');
    actions.codesRequest('orderOrigin');
  }

  changeValue = ({ item, value, field, language=false }) => {
    const { actions, leadtypes } = this.props;
    const index = _.findIndex(leadtypes, { id: item.id });
    let newLeadtypes;
    let newLeadtype;
    if (language) {
      newLeadtypes = update(leadtypes, {
        [index]: {
          [field]: {
            [language]: { $set: value }
          }
        }
      });
      newLeadtype = update(item, {
        $unset: ["leadTypeText"],
        [field]: {
          [language]: { $set: value}}
      });
    } else {
      newLeadtypes = update(leadtypes, {
        [index]: {
          [field]: { $set: value }
        }
      });
      newLeadtype = update(item, {
        $unset: ["leadTypeText"],
        [field]: { $set: value }
      });

    }

    if (field === "forNewLeadAvailable") {
      newLeadtype = update(item, {
        [field]: { $set: value },
        forNewLeadAvailableBackoffice: { $set: value },
        $unset: ["leadTypeText"]
      });
    }

    actions.setData({key: "leadtypes", value: newLeadtypes});
    this.myList.forceUpdateGrid();
    this.updateService(newLeadtype);
  }

  updateService = (newLeadtype) => {
    this.props.actions.leadqualificationRequest({leadqualification: newLeadtype});
  }

  setFilterValue = (event) => {
    this.setState({
      filterValue: event.target.value
    });
    this.myList.forceUpdateGrid();
  }

  renderItem = () => {
    const { workguides, language, codes, leadgroups, customerTaskStatuses } = this.props;
    const leadtypes = this.filterList();
    return (
      leadtypes.map((o) => (
        <Item
          key={o.id}
          item={o}
          workguides={workguides}
          language={language}
          changeValue={this.changeValue}
          leadgroups={leadgroups}
          postActionCodes={codes.postAction}
          orderOriginCodes={codes.orderOrigin}
          statuses={customerTaskStatuses}
        />))
    );
  }

  rowRenderer = ({key, index, style}) => {
    return (
      <div
        key={key}
        style={style}
      >
        {this.renderItem()[index]}
      </div>
    )
  }

  filterList = () => {
    const { language, leadtypes } = this.props;
    if (this.state.filterValue.length > 0) {
      const options = {
        shouldSort: true,
        threshold: 0.3,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          `leadTypeText.${language}`
        ]
      }
      const fuse = new Fuse(leadtypes, options);
      return(fuse.search(this.state.filterValue, options));
    } else {
      return leadtypes;
    }
  };

  leadQualifications() {
    return (
      <div style={{overflow: 'auto'}}>

        <div style={{
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'flex-start',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          width: 1600,
          }}
        >
          <div style={{ marginLeft: 20, width: 140 }} />
          <div style={{ marginLeft: 20, width: 250 }} />
          <div style={{ marginLeft: 20, minWidth: 200 }} />
          <div style={{ marginLeft: 40, minWidth: 155 }}>
            <strong>Neuer Lead erlaubt</strong>
          </div>
        </div>


        <div style={{
          height: 60,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'flex-start',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          width: 1600,
          }}
        >

          <div style={{ flex: 0, marginLeft: 20, minWidth: 140, width: 140 }}>
            <strong><FormattedMessage id="leadqualification_tableHeader_orderOrigin"/></strong>
          </div>


          <div style={{ marginLeft: 20, width: 250 }} ><strong>Leadart</strong></div>
          <div style={{ flex: 0, marginLeft: 20, minWidth: 200 }}><strong>Wichtigkeit</strong></div>

          <div style={{ flex: 0, marginLeft: 20, minWidth: 55 }}><strong>Front</strong></div>
          <div style={{ flex: 0, marginLeft: 20, minWidth: 55 }}><strong>Back-Office</strong></div>

          <div style={{ flex: 0, marginLeft: 20, minWidth: 140 }}><strong>Post Action</strong></div>

          <div style={{ flex: 0, marginLeft: 20, minWidth: 140 }}><strong>Workguide zuteilen</strong></div>

          <div style={{ flex: 0, marginLeft: 20, minWidth: 140 }}><strong>Leadgruppe zuteilen</strong></div>

          <div style={{ flex: 0, marginLeft: 20, minWidth: 70 }}><strong>Termin +/- Tage</strong></div>
          <div style={{ flex: 0, marginLeft: 20, minWidth: 70 }}><strong>Verfall +/- Tage</strong></div>
        </div>


        <hr style={{margin: 0}}/>
      <List
        ref={ref => (this.myList = ref)}
        width={1600}
        height={550}
        rowCount={this.props.leadtypes.length}
        rowHeight={61}
        rowRenderer={this.rowRenderer}
      />
      </div>
    )
  }

  render() {
    const { requesting } = this.props;
    const search = (
    <Form inline style={{display: "inline"}}>
      <FormControl
        style={{ marginLeft: 20, display: "inline" }}
        placeholder="Suchbegriff eingeben"
        onChange={this.setFilterValue}
        value={this.state.filterValue}
      />
    </Form>
    );

    return(
      <EditContainer
        requesting={requesting}
        title={<div><span>Leadarten Qualifizieren</span>{search}</div>}
        body={this.leadQualifications()}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    leadtypes: state.leadqualification.leadtypes,
    workguides: state.leadqualification.workguides,
    leadgroups: state.leadqualification.leadgroups,
    requesting: state.leadqualification.requesting,
    customerTaskStatuses: state.leadqualification.customerTaskStatuses,
    language: state.login.language,
    hasError: state.leadqualification.hasError,
    error: state.leadqualification.error,
    codes: state.workguide.codes,
  }
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(leadqualificationActions, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(Leadqualification);

import { NOTICE_IMPORT_FILE_POST } from '../../actions/NoticeImportActions';
import {
  REDUCER_STATUS_PENDING,
  REDUCER_STATUS_FULFILLED,
  REDUCER_STATUS_FAILURE,
} from '../../constants/ReducerStatuses';


const initialState = {
  loading: false,
  error: undefined,
  results: undefined,
  status: undefined,
};

export function NoticeImportFilePostReducer(state = initialState, action) {
  switch (action.type) {
    case NOTICE_IMPORT_FILE_POST.PENDING:
      return {
        ...state,
        loading: true,
        error: undefined,
        status: REDUCER_STATUS_PENDING,
      };
    case NOTICE_IMPORT_FILE_POST.SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined,
        results: action.data,
        status: REDUCER_STATUS_FULFILLED,
      };
    }
    case NOTICE_IMPORT_FILE_POST.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.data,
        status: REDUCER_STATUS_FAILURE,
      };
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { has, noop, some } from 'lodash';
import { ButtonSelect } from '@evoja-web/react-form';

import Label from './Label';

const FormFieldLanguageSelect = React.memo(function LanguageSelect({
  id,
  languages,
  onChange,
  validations,
  value
}) {
  const options = languages.map((l) => {
    const label = some(validations, (v) => has(v, l))
      ? <span className="label--invalid">{l}</span>
      : <span>{l}</span>;

    return {
      value: l,
      label
    };
  });

  return (
    <div className="workflow-tree-form-element">
      <Label id={id} />

      <div className="workflow-tree-form-element--component">
        <ButtonSelect
          id={id}
          onChange={onChange}
          options={options}
          value={value}
        />
      </div>
    </div>
  );
});

FormFieldLanguageSelect.propTypes = {
  id: PropTypes.string,
  languages: PropTypes.array,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.array
};

FormFieldLanguageSelect.defaultProps = {
  id: 'language',
  languages: ['de', 'fr', 'en', 'it'],
  onChange: noop,
  validations: {},
  value: undefined
};

export default FormFieldLanguageSelect;

import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import csvtojson from 'csvtojson';
import iconv from 'iconv-lite';
import Buffer from 'buffer';
import { get, uniq, flatten, compact } from 'lodash';
import { ChunkLoader } from '@evoja-web/redux-saga-utils';

import {
  NOTICE_IMPORT_FILE_POST,
  NOTICE_IMPORT_FILE_LIST_LOAD,
} from '../../actions/NoticeImportActions';
import { EndpointName } from '../../constants/EndpointName';
import { CODE_GROUPS, LONG_REQUEST_ITEM_COUNT } from '../../constants/NoticeImport';
import handleNextHeaders from '../../utils/handleNextHeaders';

export function* getProducts() {
  const url = '/basic/productgroup/?limit(1000)';

  const groups = yield handleNextHeaders(url);

  // Not all products can be selected via MAP UI (when creating a product).
  // The products there are filtered by code mapping: https://api-dev-vab.apps.grv.scbs.ch/entity/codemapping/noteProduct
  // ATM this is not necessary as we trust the user in the AdminUI but if this is a requirement in the feature
  // one has to load the mapping from the code mappings service and filter the products.
  const products = uniq(
    flatten(groups.map((g) => get(g, 'products')))
  );

  return products;
}

export function* getCustomers(ids = []) {
  const cl = ChunkLoader()
    .setServiceUrl('/person/customer/')
    .setKey('customerNumber')
    .setChunkSize(30)
    .setTimeout(60000)
    .setMaxParallel(5)
    .setIdentifiers(compact(uniq(ids)), { type: 'string' });

  const result = yield call(cl.execute);

  return result.reduce((customers, customer) => {
    return {
      ...customers,
      [get(customer, 'customerNumber')]: customer
    }
  }, {});
}

export function* getConsultants(userNames = []) {
  const cl = ChunkLoader()
    .setServiceUrl('/person/consultant/')
    .setKey('username')
    .setChunkSize(30)
    .setTimeout(60000)
    .setMaxParallel(5)
    .setIdentifiers(compact(uniq(userNames)));

  const result = yield call(cl.execute);

  return result.reduce((consultants, consultant) => {
    return {
      ...consultants,
      [get(consultant, 'username')]: consultant
    }
  }, {});
}

export function* noticeImportFileListLoadSaga(action) {
  try {
    yield put(NOTICE_IMPORT_FILE_LIST_LOAD.pending());

    const url = `${EndpointName.FILE}/?limit(1000,0)`
      + '&eq(links.ref.$id,adminv2-notice-import)'
      + '&sort(-metadata.createDate)';

    const resp = yield call(axios, {
      url,
      method: 'get',
    });

    yield put(NOTICE_IMPORT_FILE_LIST_LOAD.success(resp.data));
  } catch (error) {
    yield put(NOTICE_IMPORT_FILE_LIST_LOAD.failure(error));
  }
}

export function* noticeImportFilePostSaga(action) {
 try {
    yield put(NOTICE_IMPORT_FILE_POST.pending());
    const { file, user } = action.data;
    const formData = new FormData();
    const metaData = {
      links: [
        {
          type: 'module',
          $ref: '/core/module/adminv2-notice-import',
        }
      ],
      metadata: {
        order: 9999,
        action: [],
        filename: file.name,
        additionalProperties: [
          {
            name: 'username',
            value: user.username,
          },
        ],
      },
    };
    formData.append('upload', file);
    formData.append('metadata', JSON.stringify(metaData));
    const responsePost  = yield call(axios, {
      url: EndpointName.FILE,
      method: 'post',
      headers: {'Content-Type': 'multipart/form-data'},
      data: formData,
    });

    const fileId = responsePost.headers.location.replace('/file/', '');

    const csvFile = yield call(axios, {
      url: `${EndpointName.FILE}/${fileId}`,
      method: 'get',
      responseType: 'arraybuffer',
    });

    const fileData = iconv.decode(Buffer.Buffer.from(csvFile.data), 'latin1');

    let rows = yield csvtojson({
        noheader: true,
        output: 'csv',
        delimiter: [";"],
      }).fromString(fileData);
    rows = rows.splice(1);

    yield call(axios, {
      url: `${EndpointName.FILE}/${fileId}`,
      method: 'put',
      data: {
        id: fileId,
        metadata: {
          additionalProperties: [
            {
              name: 'lastAction',
              value: 'uploaded',
            },
            {
              name: 'totalRowsCount',
              value: `${rows.length}`,
            },
            {
              name: 'username',
              value: user.username,
            },
            {
              name: 'status',
              value: 'uploaded',
            },
          ],
        },
        links: [{
          type: 'module',
          $ref: `/core/module/adminv2-notice-import`,
        }],
      },
    });

    // loading entityCodes
    const entityCodes = yield call(axios, {
      url: `${EndpointName.ENTITY_CODE}/?in(group,(${CODE_GROUPS.join(',')}))&limit(999999)`,
      method: 'get',
    });

    const availableProducts = yield call(getProducts);
    const customerIds = rows.map((row) => get(row, 0));
    const customerByNumberMap = yield call(getCustomers, customerIds);

    let consultantUserNames = [];
    rows.forEach(row => {
      if (row[9]) {
        consultantUserNames = consultantUserNames.concat(row[9].split(';'));
      }
      if (row[4]) {
        consultantUserNames.push(row[4]);
      }
    });

    const consultantByUsernameMap = yield call(getConsultants, consultantUserNames);
    const consultantUserNamesSplited = [];
    for (let i = 0; i < Math.ceil(consultantUserNames.length / LONG_REQUEST_ITEM_COUNT); i++) {
      const start = i * LONG_REQUEST_ITEM_COUNT;
      consultantUserNamesSplited[i] = consultantUserNames.slice(start, start + LONG_REQUEST_ITEM_COUNT);
    }

    const personConsultantList = [];

    yield put(NOTICE_IMPORT_FILE_POST.success({
      availableProducts,
      consultantByUsernameMap,
      customerByNumberMap,
      entityCodes,
      fileId,
      personConsultantList,
      rows,
      user,
    }));
  } catch (error) {
    yield put(NOTICE_IMPORT_FILE_POST.failure(error));
  }
}

import './Items.css';

import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import _ from 'lodash';
import Item from './Item';


const Items = SortableContainer(({
  module, section, items, language, onUpdate, modalOpen, modalClose, showModal,
}) => {
  items = _.sortBy(items, [function (o) {
    return o.order;
  }]);

  const aItems = items.map((item, i) => (
    <Item
      key={item.id}
      index={i}
      id={i}
      module={module}
      section={section}
      defaultCheckedState={item.defaultCheckedState}
      displayCondition={item.displayCondition}
      onUpdate={onUpdate}
      modalOpen={modalOpen}
      modalClose={modalClose}
      showModal={showModal}
      text={item.name[language]}
    />
  ));

  return (
    <div className="checklist-items SortableHOCList">
      {aItems}
    </div>
  );
});

export default Items;

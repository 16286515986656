import uuid from 'uuid';
import { EndpointName } from '../../constants/EndpointName';
import { createRef } from '../../utils';

export function mapNewDocument(file, language) {
  const links = [
    {
      type: 'language',
      $ref: createRef(EndpointName.LANGUAGE, language),
    },
    {
      type: 'module',
      $ref: createRef(EndpointName.CORE_MODULE, 'promotion'),
    },
  ];

  if (file.type === 'application/pdf') {
    links.push(
      {
        type: 'screen',
        $ref: createRef(EndpointName.CORE_SCREEN, 'output-common'),
      },
      {
        type: 'evojaDocumentType',
        $ref: createRef(EndpointName.ENTITY_CODE, 'evojaDocumentType-promotion'),
      },
    );
  }

  return {
    id: uuid.v4(),
    blob: file,
    url: file.preview,
    metadata: {
      mime: file.type,
      filename: file.name.replace(/[^A-Za-z0-9\-_]/g, '-'),
      size: file.size,
      additionalProperties: [],
      language: language,
      order: 0,
    },
    links: links,
  };
}

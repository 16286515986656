import './EmailSubscriptionsSearchForm.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, Form, reduxForm } from 'redux-form';
import { classList } from '../../utils/classList';
import { ExpandContent } from '../Common/ExpandContent';
import { Button } from '../Common/Button';
import { FormInput, FormSelect } from '../FormControls';
import {
  EMAIL_SUBSCRIPTIONS_DEFAULT_SORT,
  EMAIL_SUBSCRIPTIONS_PAGE_SIZE
} from '../../constants/EmailSubscriptionsConstants';

class EmailSubscriptionsSearchReduxForm extends React.PureComponent {
  state = {
    expanded: false,
    items: this.props.items,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.items === prevState.items) {
      return null;
    }

    return {
      expanded: !nextProps.items || nextProps.items.length === 0,
      items: nextProps.items,
    };
  }

  render() {
    const { expanded } = this.state;
    const { handleSubmit, loading, subscriptionTypeOptions, subscriptionStateOptions } = this.props;

    const iconClasses = classList({
      'icon-013-chevron-down': true,
      'email-subscriptions-search__icon': true,
      'email-subscriptions-search__icon--collapsed': !expanded,
    });

    return (
      <div className="email-subscriptions-search">
        <Form onSubmit={handleSubmit(this.search)}>
          <div className="email-subscriptions-search__header" onClick={this.toggleExpanded}>
            <div className="email-subscriptions-search__title">
              <FormattedMessage id="emailSubscriptions_searchTitle"/>
            </div>
            <div className={iconClasses}/>
          </div>

          <ExpandContent expanded={expanded} className="email-subscriptions-search__body">
            <div className="email-subscriptions-search__column">
              <Field component={FormInput}
                     size='large'
                     type="text"
                     label={<FormattedMessage id="emailSubscriptions_firstName"/>}
                     labelSize={4}
                     name="firstName"
              />
              <Field component={FormInput}
                     size='large'
                     type="text"
                     label={<FormattedMessage id="emailSubscriptions_lastName"/>}
                     labelSize={4}
                     name="lastName"
              />
              <Field component={FormInput}
                     size='large'
                     type="text"
                     label={<FormattedMessage id="emailSubscriptions_email"/>}
                     labelSize={4}
                     name="email"
              />
            </div>
            <div className="email-subscriptions-search__column">
              <Field component={FormSelect}
                     size='large'
                     label={<FormattedMessage id="emailSubscriptions_subscriptionType"/>}
                     labelSize={4}
                     name="subscriptionTypes"
                     multi={true}
                     clearable={true}
                     options={subscriptionTypeOptions}
              />
              <Field component={FormSelect}
                     size='large'
                     label={<FormattedMessage id="emailSubscriptions_subscriptionState"/>}
                     labelSize={4}
                     name="subscriptionStates"
                     multi={true}
                     clearable={true}
                     options={subscriptionStateOptions}
              />
            </div>

            <div className="email-subscriptions-search__footer">
              <Button bsStyle="primary" type="submit" disabled={loading}>
                <FormattedMessage id="emailSubscriptions_searchButton"/>
              </Button>
            </div>
          </ExpandContent>
        </Form>
      </div>
    );
  }

  toggleExpanded = () => this.setState((prev) => ({ expanded: !prev.expanded }));
  search = (searchParams) => this.props.search({
    ...searchParams,
    ...EMAIL_SUBSCRIPTIONS_DEFAULT_SORT,
    limit: EMAIL_SUBSCRIPTIONS_PAGE_SIZE,
    offset: 0,
    currentData: []
  });
}

export const EmailSubscriptionsSearchForm = reduxForm({})(EmailSubscriptionsSearchReduxForm);
EmailSubscriptionsSearchForm.propTypes = {
  search: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  items: PropTypes.array,
};

import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { toast } from 'react-toastify';

// ToDo: Move functions like handleNextHeaders to own repo
import loaderTriggerActions from '../../actions/LoaderTriggerActions';
import { EndpointName } from '../../constants/EndpointName';

const {
  LOADER_TRIGGER_PENDING,
  LOADER_TRIGGER_FULFILLED,
  LOADER_TRIGGER_REJECTED
} = loaderTriggerActions;

export default function* loaderTriggerSaga(request) {
  yield put({ type: LOADER_TRIGGER_PENDING });

  try {
    const response = yield call(axios, {
      url: EndpointName.LOADER_TRIGGER,
      method: 'post',
      data: { ...request.data, load: 'whatever' }
    });

    // yield put({ type: LOAD_TRIGGER_FULFILLED, data: { status: response.status }});
    yield put({ type: LOADER_TRIGGER_FULFILLED, data: response });

    toast.success('Success', { autoClose: 5000 });
  } catch (error) {
    yield put({ type: LOADER_TRIGGER_REJECTED, error });

    const errorMessage = error.response.data[0].message;

    toast.error(errorMessage, { autoClose: 5000 });
    return error;
  }
}

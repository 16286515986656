import './AnalyticsDateRange.css';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { DateRangePicker } from '../Common/DateRangePicker';
import { Button } from '../Common/Button';

export class AnalyticsDateRange extends React.Component {
  state = {
    startDate: null,
    endDate: null,
    focusedInput: null,
  };

  constructor(props) {
    super(props);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.onDatesChange = this.onDatesChange.bind(this);
    this.isOutsideRange = this.isOutsideRange.bind(this);
    this.search = this.search.bind(this);
  }

  render() {
    const { startDate, endDate, focusedInput } = this.state;
    return (
      <div className="analytics-date-range">
        <DateRangePicker
          startDate={startDate}
          startDateId="analytics-start-date-id"
          endDate={endDate}
          endDateId="analytics-end-date-id"
          onDatesChange={this.onDatesChange}
          focusedInput={focusedInput}
          onFocusChange={this.onFocusChange}
          required={true}
          displayFormat="DD/MM/YYYY"
          isOutsideRange={this.isOutsideRange}
          openDirection="up"
        />

        <Button
          className="analytics-date-range__button"
          bsSize="sm"
          bsStyle="primary"
          onClick={this.search}
        >
          <FormattedMessage id="common_search"/>
        </Button>
      </div>
    );
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput });
  }

  onDatesChange({ startDate, endDate }) {
    this.setState({ startDate, endDate });
  }

  search() {
    const { startDate, endDate } = this.state;
    const { onChange } = this.props;
    if (
      !startDate || !startDate.isValid() ||
      !endDate || !endDate.isValid()
    ) {
      return;
    }
    onChange(startDate, endDate);
  }

  isOutsideRange(date) {
    return date.valueOf() > Date.now();
  }
}

AnalyticsDateRange.propTypes = {
  onChange: PropTypes.func.isRequired,
};

import { createSelector } from 'reselect';
import { getFilter } from './promotionSelectors';
import { getPromotions } from './getPromotions';

export const getVisiblePromotions = createSelector([
  getPromotions,
  getFilter,
], (
  promotions,
  filter,
) => promotions
  .filter((promotion) => {
    if (filter.show === 'active') {
      return promotion.active;
    } else if (filter.show === 'inactive') {
      return !promotion.active;
    } else {
      return true;
    }
  })
  .slice(0, filter.displayCount));

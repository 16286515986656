import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { EMAIL_SUBSCRIPTIONS_IMPORT_CSV } from '../../actions/EmailSubscriptionsActions';
import { EndpointName } from '../../constants/EndpointName';

function csvToArray(str, delimiter = '/t', fallbackDelimiter = ';') {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  // if no delimiter is found in string, the fallback delimiter is used
  const headerRow = str.slice(0, str.indexOf('\n'));
  let headers;
  if (headerRow.includes(delimiter)) {
    headers = headerRow.split(delimiter);
  } else {
    headers = headerRow.split(fallbackDelimiter);
  }

  // return the array
  return headers;
}

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onabort = () => console.error('file reading was aborted');
    reader.onerror = () => console.error('file reading has failed');
    reader.onload = () => {
      const data = csvToArray(reader.result);
      resolve(data);
    };
    reader.readAsText(file);
  });
}

export function* uploadCsvFileSaga(action) {
  const { file, creator } = action.data;

  try {
    yield put(EMAIL_SUBSCRIPTIONS_IMPORT_CSV.pending());

    const contentBuffer = yield readFileAsync(file);

    const csvSchema = ['email', 'firstName', 'lastName', 'gender', 'title', 'subscriptionType', 'language\r', 'language'];

    // Validate csv headers
    const validationErrors = [];
    contentBuffer.forEach((el) => {
      if (!csvSchema.includes(el)) {
        validationErrors.push(el);
      }
    });

    if (validationErrors.length > 0) {
      toast.error(`CSV format ist not correct, please check columns. Following fields are not valid: ${validationErrors.join(',')} \n To get the right format download the example csv`);
      throw 'CSV format not correct';
    }

    const formData = new FormData();
    formData.append('upload', file);
    formData.append('metadata', JSON.stringify({
      metadata: {
        additionalProperties: [
          {
            name: 'action',
            value: 'ImportNewsletter',
          },
          {
            name: 'author',
            value: creator,
          },
        ]
      }
    }));

    const { data, headers } = yield call(axios, {
      url: '/file/',
      method: 'post',
      data: formData
    });
    const payload = {
      type: 'ImportNewsletter',
      interactive: {
        executionTime: moment().add(20, 'seconds'),
        parameters: {
          source: headers.location
        }
      }
    };

    const jobProcessingResult = yield call(axios, {
      url: EndpointName.CORE_JOB_PROCESSING,
      method: 'post',
      data: payload,
    });

    yield put(EMAIL_SUBSCRIPTIONS_IMPORT_CSV.success({ results: data }));
    toast.success('CSV erfolgreich hochgeladen! Weitere Informationen zum Status unter Batch Jobs / Ausgeführte Jobs', {});
  } catch (error) {
    yield put(EMAIL_SUBSCRIPTIONS_IMPORT_CSV.failure(error));
  }
}

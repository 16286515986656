import { put } from 'redux-saga/effects';
import { ACCOUNT_WORKGUIDES_CLEAR } from '../../actions/WorkguideStatusActions';

export function* workguideStatusClearAccountWorkguidesSaga() {
  try {
    yield put(ACCOUNT_WORKGUIDES_CLEAR.pending());
    yield put(ACCOUNT_WORKGUIDES_CLEAR.success());
  } catch (error) {
    yield put(ACCOUNT_WORKGUIDES_CLEAR.failure(error));
  }
}

import { Actions } from '@evoja-web/redaction';

import { definitionsAction } from './JobProcessing/Definitions';
import { executedAction } from './JobProcessing/Executed';
import { executionHistoryAction } from './JobProcessing/ExecutionHistory';
import { summaryAction } from './JobProcessing/Summary';
import { triggerJobAction } from './JobProcessing/TriggerJob';


export const actions = Actions([
  definitionsAction,
  executedAction,
  executionHistoryAction,
  summaryAction,
  triggerJobAction
]);

export default actions.get();
import { LEADS_SWITCH_IMPORT_CSV } from '../../actions/LeadsSwitchImportActions';
import {
  REDUCER_STATUS_PENDING,
  REDUCER_STATUS_FULFILLED,
  REDUCER_STATUS_FAILURE,
} from '../../constants/ReducerStatuses';


const initialState = {
  loading: false,
  errors: undefined,
  results: undefined,
  status: undefined,
  apiError: undefined
};

export function LeadsSwitchImportCsvReducer(state = initialState, action) {
  switch (action.type) {
    case LEADS_SWITCH_IMPORT_CSV.PENDING:
      return {
        ...state,
        loading: true,
        errors: undefined,
        status: REDUCER_STATUS_PENDING,
      };
    case LEADS_SWITCH_IMPORT_CSV.SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: undefined,
        results: action.data,
        status: REDUCER_STATUS_FULFILLED,
      };
    }
    case LEADS_SWITCH_IMPORT_CSV.FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.data.errors,
        apiError: action.data.apiError,
        status: REDUCER_STATUS_FAILURE,
      };
    default:
      return state;
  }
}

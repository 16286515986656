import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isNil } from 'lodash';
import { Select } from '@evoja-web/react-form';
import { FormControl, ControlLabel, FormGroup } from 'react-bootstrap';

// Use ES template literal in client to replace placeholders with real values
// @see https://lodash.com/docs/4.17.15#template
/* eslint-disable no-template-curly-in-string */
const options = [{
  label: '360-Grad: Übersicht',
  type: 'internal',
  value: '/customer/${customerId}/overview'
}, {
  label: '360-Grad: Portfolio',
  type: 'internal',
  value: '/customer/${customerId}/portfolio'
}, {
  label: '360-Grad: Produkte',
  type: 'internal',
  value: '/customer/${customerId}/products'
}, {
  label: '360-Grad: Instruktionen',
  type: 'internal',
  value: '/customer/${customerId}/instructions'
}, {
  label: '360-Grad: Aktivitäten',
  type: 'internal',
  value: '/customer/${customerId}/workbench'
}, {
  label: '360-Grad: Leads',
  type: 'internal',
  value: '/customer/${customerId}/leads'
}, {
  label: '360-Grad: Dokumente',
  type: 'internal',
  value: '/customer/${customerId}/documents'
}, {
  label: '360-Grad: Vollmachten',
  type: 'internal',
  value: '/customer/${customerId}/powerOfAttorney'
}, {
  label: '360-Grad: Stammdaten',
  type: 'internal',
  value: '/customer/${customerId}/baseData'
}, {
  label: 'Dashboard',
  type: 'internal',
  value: '/dashboard'
}, {
  label: 'Leadmanagement',
  type: 'internal',
  value: '/leadmanagement'
}, {
  label: 'Aktivitätenübersicht',
  type: 'internal',
  value: '/workbench/overview'
}, {
  label: 'Kundenbuch',
  type: 'internal',
  value: '/customeroverview'
}, {
  label: 'Externe URL',
  type: 'external',
  value: 'external'
}];
/* eslint-enable no-template-curly-in-string */

class WorkguideRedirect extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onExternalUriChange = this.onExternalUriChange.bind(this);
  }

  /**
   * Handle select change
   *
   * @param   {String}  id        Form element id
   * @param   {String}  value     Selected value
   * @param   {Object}  option    Selected option
   *
   * @return  void
   */
  onChange(id, v, option) {
    const { editValueField } = this.props;

    const { type, value } = option;
    const uri = value === 'external'
      ? ''
      : value;

    const updated = isNil(option)
      ? undefined
      : { type, uri };

    editValueField('', 'workguidePropery', { key: 'redirect', value: updated });
  }

  /**
   * Handle external uri input change
   *
   * @param   {Event}  ev  Change event
   *
   * @return  void
   */
  onExternalUriChange(ev) {
    const {
      editValueField,
      workguide
    } = this.props;

    const { value = '' } = get(ev, 'target', {});
    const current = get(workguide, 'redirect', {});
    const updated = {
      ...current,
      uri: value.trim()
    };

    editValueField('', 'workguidePropery', { key: 'redirect', value: updated });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { workguide } = this.props;

    const { type, uri } = get(workguide, 'redirect', {});
    const value = type === 'external'
      ? 'external'
      : uri;

    return (
      <div className="workguide-detail-general-redirect">
        <FormGroup>
          <ControlLabel>
            Redirect nach Ausführung
          </ControlLabel>

          <br />

          <Select
            id="target"
            onChange={this.onChange}
            options={options}
            value={value}
          />
        </FormGroup>

        {type === 'external' && (
          <FormGroup>
            <ControlLabel>
              Externe URI
            </ControlLabel>

            <br />

            <FormControl
              id="externalUri"
              onChange={this.onExternalUriChange}
              value={uri || ''}
            />
          </FormGroup>
        )}
      </div>
    );
  }
}

WorkguideRedirect.propTypes = {
  editValueField: PropTypes.func,
  workguide: PropTypes.object.isRequired
};

WorkguideRedirect.defaultProps = {
  editValueField: noop
};

export default WorkguideRedirect;

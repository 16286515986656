import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { get, noop } from 'lodash';

const CustomerLsvConfiguration = React.memo(({
  editValueField,
  id,
  item
}) => {
  return (
    <FormGroup controlId="codeGroupMulti">
      <Col componentClass={ControlLabel} sm={3}>
        Mehrfachauswahl
      </Col>
      <Col sm={9} style={{ paddingTop: '10px' }}>
      <Toggle
          checked={get(item, 'props.multi')===undefined?false:item.props.multi}
          onChange={() => editValueField(id, 'multi')}
      />
      </Col>
    </FormGroup>
  );
});

CustomerLsvConfiguration.propTypes = {
  editValueField: PropTypes.func,
  id: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired
};

CustomerLsvConfiguration.defaultProps = {
  editValueField: noop
};

export default CustomerLsvConfiguration;

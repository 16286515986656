import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from 'react-select';
import update from 'immutability-helper';
import { Col, Button } from 'react-bootstrap';

import ConditionList from './Condition/List';
import ConditionForm from './Condition/Form';
import ActionList from './Action/List';
import { possibleOptions } from './Condition/Add';

class DependencyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: undefined,
      condition: undefined,
      actions: {
        if: [],
        else: []
      }
    };

    this.onTypeChange = this.onTypeChange.bind(this);
    this.onConditionChange = this.onConditionChange.bind(this);
    this.onConditionsChange = this.onConditionsChange.bind(this);
    this.onActionsChange = this.onActionsChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Condition type change
   *
   * @param  {Object} selected Selected option from react-select
   *
   * @return void
   */
  onTypeChange(selected) {
    this.setState({
      type: _.get(selected, 'value'),
      condition: undefined
    });
  }

  /**
   * Handle a condition change.
   * This function is called if it is a single condition.
   * In this case, we can directly set it as condition
   *
   * @param  {Object} condition Condition object
   *
   * @return void
   */
  onConditionChange(condition) {
    this.setState({ condition });
  }

  /**
   * Handle conditions change.
   * This function is called it it is an and / or condition.
   * In this case, set the given operation and conditions
   *
   * @param  {Array}  conditions Array of conditions
   * @param  {String} operation  Operation (and/or)
   *
   * @return void
   */
  onConditionsChange(conditions, operation) {
    const condition = {
      operation,
      conditions
    };

    this.setState({ condition });
  }

  /**
   * Handle action change
   *
   * @param  {String} type  Action type (if/else)
   * @param  {Array}  items Array of actions
   *
   * @return void
   */
  onActionsChange(type, items) {
    const { actions } = this.state;
    const updated = update(actions, {
      [type]: { $set: items }
    })

    this.setState({ actions: updated });
  }

  /**
   * Handle form submit
   *
   * @return void
   */
  onSubmit() {
    const { condition, actions } = this.state;
    const { target, onSubmit } = this.props;

    // Entry point for recursion is always an and.
    // Conditions array always contains only one item.
    // This item can then have multiple conditions and so on...
    const dependency = {
      target: _.get(target, 'props.id'),
      operation: 'and',
      custom: false,
      actions,
      conditions: [condition]
    };

    onSubmit(dependency);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { type, condition, actions } = this.state;
    const { target, language, onCancel } = this.props;
    const conditions = (condition)
      ? (['and', 'or'].includes(type)) ? _.get(condition, 'conditions', []) : [condition]
      : []

    return(
      <div>
        <Select
          options={possibleOptions}
          value={type}
          onChange={this.onTypeChange}
        />

        <div style={{ paddingBottom: '20px' }} />

        <strong>Wenn</strong>
        <ConditionList
          target={target}
          conditions={conditions}
          operation={type}
          onChange={(conditions) => (['and', 'or'].includes(type)) ? this.onConditionsChange(conditions, type) : this.onConditionChange(conditions[0])}
        />

        {(type === 'single' && !condition) ? (
          <ConditionForm
            target={target}
            condition={condition}
            onSubmit={this.onConditionChange}
          />
        ) : null}

        <div style={{ paddingBottom: '20px' }} />

        {(condition) ? (
          <React.Fragment>
            <ActionList
              label="Dann"
              language={language}
              target={target}
              actions={_.get(actions, 'if', [])}
              onChange={(actions) => this.onActionsChange('if', actions)}
            />

            <ActionList
              label="Sonst"
              language={language}
              target={target}
              actions={_.get(actions, 'else', [])}
              onChange={(actions) => this.onActionsChange('else', actions)}
            />
          </React.Fragment>
        ) : null}

        <Col lg={12} md={12} sm={12} style={{ textAlign: 'right' }}>
          <Button
            bsStyle="primary"
            onClick={this.onSubmit}
            disabled={!condition || actions.if.length === 0}
          >
            Speichern
          </Button>
          <Button onClick={onCancel}>Verwerfen</Button>
        </Col>
      </div>
    );
  }
}

DependencyForm.propTypes = {
  target: PropTypes.object.isRequired,
  language: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

DependencyForm.defaultProps = {
  language: 'de',
  onSubmit: _.noop,
  onCancel: _.noop
};

export default DependencyForm;

import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { Button } from 'react-bootstrap';
import AgendaItem from './Item';

const AgendaList = SortableContainer(({
  selectedModule, modules, language, onRemove, onEdit, onChecked, onAdd,
}) => {
  const aAgendas = modules.map((agenda, i) => (
    <AgendaItem
      key={i}
      id={i}
      index={i}
      selectedModule={selectedModule}
      text={agenda.name}
      order={agenda.order}
      isChecked={agenda.isChecked}
      language={language}
      onRemove={onRemove}
      onEdit={onEdit}
      onChecked={onChecked}
    />
  ));

  return (
    <div>
      <div className="SortableHOCList">
        {aAgendas}
      </div>
      <Button
        bsStyle="primary"
        name="newItem"
        onClick={onAdd}
      >
        <span className="icon-061-plus" /> Neuer Eintrag
      </Button>

    </div>
  );
});

export default AgendaList;

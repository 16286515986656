import './BulkLoadsResultsDetails.css';

import React from 'react';
import { Table, TableCell, TableHead, TableRow } from '../Common/Table';
import { FormattedDate } from '../Common/FormattedDate';
import { BULK_LOAD_PROP_TYPES_RESULT_ITEM } from './BulkLoadsPropTypes';
import { DATE_TIME_FORMAT } from '../../constants/DateTime';
import { BulkLoadsResultsTime } from './BulkLoadsResultsTime';
import { BulkLoadsDetailsChart } from './BulkLoadsDetailsChart';
import { ModalBody, ModalDialog, ModalFooter, ModalHeader } from '../Common/Modal';
import { Button } from '../Common/Button';
import { BulkLoadsActionDetails } from './BulkLoadsActionDetails';
import { BulkLoadsLoaderChart } from './BulkLoadsLoaderChart';
import { get } from 'lodash';

export class BulkLoadsResultsDetails extends React.PureComponent {
  state = {
    isModalOpen: false,
    actionId: undefined,
  };

  render() {
    const { item } = this.props;
    const action = item.actions.find((it) => it.loadId === item.id);
    const chartType = !(get(action, "parameters.loadConfigObject")===undefined) ? 'TargetLoader': 'Staging';

    return (
      <div className="bulk-loads-results-details">
        <Table className="bulk-loads-results-details__details-table">
          <TableHead>
            <TableCell colSpan={6}>Timeline</TableCell>
          </TableHead>

          <TableRow>
            <TableCell className="bulk-loads-results-details__label">Open</TableCell>
            <TableCell className="bulk-loads-results-details__value">
              {item.open ? <FormattedDate value={item.open} format={DATE_TIME_FORMAT}/> : '-'}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="bulk-loads-results-details__label">Working</TableCell>
            <TableCell className="bulk-loads-results-details__value">
              {item.working ? <FormattedDate value={item.working} format={DATE_TIME_FORMAT}/> : '-'}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="bulk-loads-results-details__label">Done</TableCell>
            <TableCell className="bulk-loads-results-details__value">
              {item.done ? <FormattedDate value={item.done} format={DATE_TIME_FORMAT}/> : '-'}
            </TableCell>
          </TableRow>
        </Table>

        <Table className="bulk-loads-results-details__details-table">
          <TableHead>
            <TableCell colSpan={4}>Metrics</TableCell>
          </TableHead>

          <TableRow>
            <TableCell className="bulk-loads-results-details__label">Waiting</TableCell>
            <TableCell className="bulk-loads-results-details__value">
              <BulkLoadsResultsTime seconds={item.metrics.secondsWaiting}/>
            </TableCell>

            <TableCell className="bulk-loads-results-details__label">Working</TableCell>
            <TableCell className="bulk-loads-results-details__value">
              <BulkLoadsResultsTime seconds={item.metrics.secondsWorking}/>
            </TableCell>
          </TableRow>
        </Table>

        <Table className="bulk-loads-results-details__details-table">
          <TableHead>
            <TableCell colSpan={4}>Actions</TableCell>
          </TableHead>

          <TableRow>
            <TableCell className="bulk-loads-results-details__label">Open</TableCell>
            <TableCell className="bulk-loads-results-details__value">{item.actionCount.open}</TableCell>

            <TableCell className="bulk-loads-results-details__label">Success</TableCell>
            <TableCell className="bulk-loads-results-details__value">{item.actionCount.success}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="bulk-loads-results-details__label">Working</TableCell>
            <TableCell className="bulk-loads-results-details__value">{item.actionCount.working}</TableCell>

            <TableCell className="bulk-loads-results-details__label">Error</TableCell>
            <TableCell className="bulk-loads-results-details__value">{item.actionCount.error}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="bulk-loads-results-details__label">Done</TableCell>
            <TableCell className="bulk-loads-results-details__value">{item.actionCount.done}</TableCell>

            <TableCell className="bulk-loads-results-details__label">Total</TableCell>
            <TableCell className="bulk-loads-results-details__value">{item.actionCount.total}</TableCell>
          </TableRow>
        </Table>

        <BulkLoadsLoaderChart item={item} chartType={chartType} />
        <BulkLoadsDetailsChart item={item} onSelect={this.openModal}/>
        {this.renderModal()}
      </div>
    );
  }

  renderModal() {
    const { isModalOpen, actionId } = this.state;
    const { item } = this.props;
    const action = item.actions.find((it) => it.id === actionId);
    if (!action) {
      return null;
    }

    return (
      <ModalDialog show={isModalOpen} onHide={this.closeModal} bsSize="lg">
        <ModalHeader closeButton={true}>
          {action.label}
        </ModalHeader>
        <ModalBody>
          <BulkLoadsActionDetails action={action} key={actionId}/>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.closeModal} bsSize="sm">
            Schliessen
          </Button>
        </ModalFooter>
      </ModalDialog>
    );
  }

  openModal = (id) => this.setState({ isModalOpen: true, actionId: id });
  closeModal = () => this.setState({ isModalOpen: false });
}

BulkLoadsResultsDetails.propTypes = {
  item: BULK_LOAD_PROP_TYPES_RESULT_ITEM,
};

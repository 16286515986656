import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';

import './ListItem.css';
import DragHandle from '../DragHandle';
import Form from './Form';

class CodeListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.collapse = this.collapse.bind(this);
  }

  onSubmit(data) {
    const { onSubmit } = this.props;

    this.collapse();
    onSubmit(data);
  }

  collapse() {
    const { collapsed } = this.state;

    this.setState({ collapsed: !collapsed });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { collapsed } = this.state;
    const { codes, data, group, language } = this.props;

    return (
      <div className="SortableHOCItem">
        <div className="code-list-item-container">
          <div className="workguide-approval-list-item">
            <div className="workguide-approval-list-item-drag-handle">
              <DragHandle />
            </div>

            <div className="code-list-item-content">
              <div onClick={this.collapse}>
                {get(data, `text.${language}`)}
                {` (${get(data, 'id')})`}
              </div>

              {!collapsed && (
                <div className="code-list-item-content-collapsible">
                  <Form
                    codes={codes}
                    data={data}
                    onSubmit={this.onSubmit}
                    onCancel={this.collapse}
                    group={group}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CodeListItem.propTypes = {
  data: PropTypes.object.isRequired,
  language: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  group: PropTypes.string
};

CodeListItem.defaultProps = {
  language: 'de',
  onSubmit: noop,
  onCancel: noop,
  group: undefined
};

export default CodeListItem;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ANALYTICS_USER_SUMMARY_LOAD, ANALYTICS_USER_SUMMARY_EXPORT } from '../actions/AnalyticsUserSummaryActions';
import { getLanguage } from '../selectors/commonSelectors';
import { getAnalyticsUserSummaryLoading } from '../selectors/AnalyticsUserSummary/getAnalyticsUserSummaryLoading';
import { getAnalyticsUserSummaryExporting } from '../selectors/AnalyticsUserSummary/getAnalyticsUserSummaryExporting';
import { getAnalyticsUserSummaryBanks } from '../selectors/AnalyticsUserSummary/getAnalyticsUserSummaryBanks';
import { AnalyticsUserSummary } from '../components/AnalyticsUserSummary/AnalyticsUserSummary';

const mapStateToProps = createStructuredSelector({
  locale: getLanguage,
  loading: getAnalyticsUserSummaryLoading,
  exporting: getAnalyticsUserSummaryExporting,
  banks: getAnalyticsUserSummaryBanks,
});

const mapDispatchToProps = {
  load: () => ANALYTICS_USER_SUMMARY_LOAD.request(),
  export: (bank) => ANALYTICS_USER_SUMMARY_EXPORT.request(bank),
};

export const AnalyticsUserSummaryPage = connect(mapStateToProps, mapDispatchToProps)(AnalyticsUserSummary);

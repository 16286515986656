import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import {
  getSetManagementOriginalPrices,
  getSetManagementPricesRaw,
} from '../../selectors/SetManagement/setManagementCommonSelectors';
import { createRef, getIdFromRef } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';

export function* setManagementSavePrices() {
  const pricesOriginal = yield select(getSetManagementOriginalPrices);
  const prices = yield select(getSetManagementPricesRaw);

  const toSave = prices.filter((price) => {
    const original = pricesOriginal.find((it) => it.id === price.id);
    return original
      ? !isPricesEqual(price, original)
      : false;
  });

  yield all(
    toSave.map((price) => call(axios, {
      method: 'put',
      url: createRef(EndpointName.BASIC_PRODUCT_BUNDLE_PRICING, price.id),
      data: price,
    }))
  );
}

function isPricesEqual(price, original) {
  return price.reductionValue === original.reductionValue &&
    getIdFromRef(price.bundle.$ref) === getIdFromRef(original.bundle.$ref) &&
    getIdFromRef(price.product.$ref) === getIdFromRef(original.product.$ref) &&
    getIdFromRef(price.reductionType.$ref) === getIdFromRef(original.reductionType.$ref);
}

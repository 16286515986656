import { takeEvery, takeLatest } from 'redux-saga/effects';
import { setManagementLoadSaga } from './setManagementLoadSaga';
import { setManagementChangeSaga } from './setManagementChangeSaga';
import { setManagementSaveSaga } from './setManagementSaveSaga';
import {
  SET_MANAGEMENT_CHANGE_ACTIONS,
  SET_MANAGEMENT_LOAD_ACTIONS,
  SET_MANAGEMENT_SAVE_ACTIONS,
} from '../../actions/SetManagementActions';

export function* setManagementSagas() {
  yield takeLatest(SET_MANAGEMENT_LOAD_ACTIONS.REQUEST, setManagementLoadSaga);
  yield takeEvery(SET_MANAGEMENT_CHANGE_ACTIONS.REQUEST, setManagementChangeSaga);
  yield takeLatest(SET_MANAGEMENT_SAVE_ACTIONS.REQUEST, setManagementSaveSaga);
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isNil } from 'lodash';
import { FormGroup, FormControl, ControlLabel, Tabs, Tab } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import update from 'immutability-helper';
import { HtmlPreview } from '@evoja-web/react-core-textblock';

import './Edit.css';

class TextblockEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeLanguage: 'de'
    };

    this.onOrderChange = this.onOrderChange.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  /**
   * Handle onValueChange of order input
   *
   * @param   {Object}  values  Values from react-number-select
   *
   * @return  void
   */
  onOrderChange(values) {
    const { data, onChange } = this.props;

    const value = get(values, 'floatValue');
    const updated = isNil(value)
      ? update(data, { $unset: ['order'] })
      : update(data, { order: { $set: value } });

    onChange(updated);
  }

  /**
   * Handle textarea input change
   *
   * @param   {Event}  ev  OnChange event
   *
   * @return  void
   */
  onTextChange(ev) {
    const { activeLanguage } = this.state;
    const {
      data,
      onChange
    } = this.props;

    const value = get(ev, 'target.value', '');
    const updated = update(data, { text: { [activeLanguage]: { $set: value } } });

    onChange(updated);
  }

  /**
   * Handle language tab change
   *
   * @param   {String}  language  Selected language
   *
   * @return  void
   */
  onLanguageChange(language) {
    this.setState({ activeLanguage: language });
  }

  /**
   * Render a tab for each language given
   *
   * @return  {Array} tabs Array of tab elements
   */
  renderTabs() {
    const { activeLanguage } = this.state;
    const {
      data,
      languages
    } = this.props;

    return languages.map((l) => {
      return (
        <Tab
          eventKey={l}
          title={l}
        >
          <FormControl
            id={`text-${activeLanguage}`}
            onChange={this.onTextChange}
            rows="7"
            componentClass="textarea"
            value={get(data, `text.${l}`, '')}
          />
        </Tab>
      );
    });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { activeLanguage } = this.state;
    const { data } = this.props;

    return (
      <div className="textblock-edit">
        <FormGroup>
          <ControlLabel>
            Reihenfolge
          </ControlLabel>

          <NumberFormat
            id="order"
            value={get(data, 'order', null)}
            onValueChange={this.onOrderChange}
          />
        </FormGroup>

        <div className="textblock--textblock-html">
          <HtmlPreview>
            {get(data, `text.${activeLanguage}`, '')}
          </HtmlPreview>
        </div>

        <FormGroup>
          <ControlLabel>
            Inhalt
          </ControlLabel>

          <Tabs
            activeKey={activeLanguage}
            onSelect={this.onLanguageChange}
            id="languages"
          >
            {this.renderTabs()}
          </Tabs>
        </FormGroup>
      </div>
    );
  }
}

TextblockEdit.propTypes = {
  data: PropTypes.object,
  languages: PropTypes.array,
  onChange: PropTypes.func
};

TextblockEdit.defaultProps = {
  data: {},
  languages: ['de', 'fr', 'en', 'it'],
  onChange: noop
};

export default TextblockEdit;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { PermissionGroup } from '../components/PermissionGroup/PermissionGroup';
import { getLanguage } from '../selectors/commonSelectors';
import { getConsultantsCount, getConsultantsError, getEntityCodes, getPermissionGroupLoading, getConsultantsSearchParams } from '../selectors/PermissionGroup/permissionGroupCommonSelectors'
import { getConsultants } from '../selectors/PermissionGroup/permissionGroupConsultantsSelector'
import { ENTITIY_CODE_LOAD, CONSULTANT_LOAD, CONSULTANT_SEARCH } from '../actions/PermissionGroupAction';



const mapStateToProps = createStructuredSelector({
  error: getConsultantsError,
  loading: getPermissionGroupLoading,
  consultants: getConsultants,
  consultantsTotalCount: getConsultantsCount,
  entityCodes: getEntityCodes,
  locale: getLanguage,
  searchParams: getConsultantsSearchParams,
});

const mapDispatchToProps = {
  load_entity_code: () => ENTITIY_CODE_LOAD.request(),
  load_consultant: () => CONSULTANT_LOAD.request(),
  search: (options) => CONSULTANT_SEARCH.request(options)
};

export const PermissionGroupPage = connect(mapStateToProps, mapDispatchToProps)(PermissionGroup);

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';
import Toggle from 'react-toggle';

const CustomerProductsConfiguration = ({ id, item, editValueField }) => {
  return (
    <FormGroup controlId="codeGroupMulti">
      <Col componentClass={ControlLabel} sm={3}>
        Mehrfachauswahl
      </Col>
      <Col sm={9} style={{ paddingTop: '10px' }}>
      <Toggle
          checked={_.get(item, 'props.multi')===undefined?false:item.props.multi}
          onChange={() => editValueField(id, 'multi')}
      />
      </Col>
    </FormGroup>
  );
}

CustomerProductsConfiguration.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  item: PropTypes.object.isRequired,
  editValueField: PropTypes.func
};

CustomerProductsConfiguration.defaultProps = {
  editValueField: _.noop
};

export default CustomerProductsConfiguration;

import { Reducers } from '@evoja-web/redaction';

import { reducer as workflowTreeConfigReducer } from './Workflow/Config';
import { reducer as workflowTreeEditNodeFormReducer } from './Workflow/EditNodeForm';
import { reducer as workflowTreeEditConnectionFormReducer } from './Workflow/EditConnectionForm';

export const reducers = Reducers([
  workflowTreeConfigReducer,
  workflowTreeEditNodeFormReducer,
  workflowTreeEditConnectionFormReducer
]);

export default reducers.get();

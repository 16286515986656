import React from 'react';
import PropTypes from 'prop-types';
import { Form, reduxForm } from 'redux-form';
import { Button } from '../Common/Button';
import { ModalBody, ModalFooter, ModalHeader } from '../Common/Modal';
import { ConsultantsFormContent } from './ConsultantsFormContent';

class ConsultantsCreateReduxForm extends React.Component {
  render() {
    const { onCancel, onSubmit } = this.props;
    const { handleSubmit, submitFailed, invalid } = this.props;

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader closeButton={true}>
          <div>Create Consultant</div>
        </ModalHeader>
        <ModalBody>
          <ConsultantsFormContent/>
        </ModalBody>
        <ModalFooter>
          <Button type="button"
                  onClick={onCancel}
                  bsSize="sm">
            Cancel
          </Button>
          <Button type="submit"
                  disabled={submitFailed ? invalid : false}
                  bsSize="sm"
                  bsStyle="primary">
            Create
          </Button>
        </ModalFooter>
      </Form>
    );
  }
}

export const ConsultantsCreateForm = reduxForm({})(ConsultantsCreateReduxForm);
ConsultantsCreateForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

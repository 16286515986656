import { UI_NOTES_CREATE_ACTIONS } from '../../actions/UiNotesActions';

export function uiNotesCreateReducer(state, action) {
  switch (action.type) {
    case UI_NOTES_CREATE_ACTIONS.PENDING:
      return {
        ...state,
        saving: true,
      };

    case UI_NOTES_CREATE_ACTIONS.SUCCESS:
      return {
        ...state,
        saving: false,
        data: {
          ...state.data,
          notes: [
            ...state.data.notes,
            action.data.note,
          ],
        },
      };

    case UI_NOTES_CREATE_ACTIONS.FAILURE:
      return {
        ...state,
        saving: false,
        error: action.data,
      };

    default:
      return state;
  }
}

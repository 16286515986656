import React, { Component } from 'react';
import _ from 'lodash';
import { Modal, Button, Glyphicon, Image } from 'react-bootstrap';
import { Pagination } from '@react-bootstrap/pagination';
import { PdfPreview } from '@evoja-web/react-document-preview';

import MetadataForm from './MetadataForm';

const ErrorIcon = <Glyphicon glyph="warning-sign" style={{fontSize: '200%', textAlign: 'center', marginTop: '10px'}}/>;

const widthInsideList = 220;
const widthInsideModal = 570;

export default class LegacyPdfPreview extends Component {
  constructor(props) {
    super(props);
    this.state = { pageNumber: 1, numPages: null, showModal: false, imageDisplay: ErrorIcon  };
    this.onDocumentLoad = this.onDocumentLoad.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.modalOpen = this.modalOpen.bind(this);
    this.modalClose = this.modalClose.bind(this);
  }

  onDocumentLoad({ numPages }) {
    this.setState({ numPages });
  }

  onDocumentLoadError(blob) {
    this.setState({
      ...this.state,
      imageDisplay: <Image width={widthInsideList} src={blob} onError={this.onImageLoadError} />
     });
  }

  onImageLoadError() {
    this.setState({
      ...this.state,
      imageDisplay: ErrorIcon
    });
  }

  previewPagination(page, pages) {
    return (
      <Pagination
        boundaryLinks
        prev
        next
        ellipsis
        items={pages}
        maxButtons={8}
        activePage={page}
        style={{ marginTop: '0px' }}
        onSelect={this.handleSelect}
      />
    );
  }

  modalClose() {
    this.setState({
      showModal: false,
      page: 1,
    });
  }

  modalOpen() {
    this.setState({
      showModal: true,
    });
  }

  handleSelect(event) {
    this.setState({ pageNumber: event });
  }

  renderPdf(params) {
    const { imageDisplay } = this.state;
    const { blob } = this.props;
    const { width } = params;
    if (!blob) {
      return null;
    }

    return (
      <div style={{ width: `${width}px` }}>
        <PdfPreview
          blob={blob}
          errorTitle={imageDisplay}
        />
      </div>
    );
  }

  render() {
    const { pageNumber, numPages } = this.state;
    const pagination = numPages ? this.previewPagination(pageNumber, numPages) : null;

    const { metadata } = this.props;
    const filename = metadata.filename.length > 30 ?
      `${metadata.filename.substring(0, 30)}...` :
      metadata.filename;
    const button = _.find(metadata.additionalProperties, { name: 'button' });

    return (
      <div
        style={{ width: `${widthInsideList}px`, minHeight: '350px', textAlign: 'left' }}
      >
        <span style={{ fontSize: '80%', textAlign: 'left' }}>{filename}</span>

        <button
          type="button"
          className="close"
          onClick={this.props.delFile.bind(this, this.props.id)}
        >
          <span aria-hidden="true"><span className="icon-008-bin" /></span>
        </button>
        <div onClick={this.modalOpen} style={{textAlign: 'center'}}>
          {this.renderPdf({width: widthInsideList})}
        </div>

        {/* Render Button Link over PDF-Preview */}
        {button !== undefined ? (
          <span
            style={{
                backgroundColor: 'white',
                opacity: 0.8,
                position: 'absolute',
                top: '23px',
                left: '16px',
              }}
          >
            <img
              alt="link"
              src="/images/link-button.png"
              width="32px"
            />
            {' '}
            {button.value}
          </span>
          ) :
          ''}

        <Modal show={this.state.showModal} onHide={this.modalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Metadaten bearbeiten</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              {this.renderPdf({width: widthInsideModal})}
              {pagination}
            </div>
            <hr />
            <MetadataForm
              ref="MetadataForm"
              id={this.props.id}
              metadata={this.props.metadata}
              links={this.props.links}
              modules={this.props.modules}
              screens={this.props.screens}
              buttons={this.props.buttons}
              language={this.props.language}
              save={this.props.save}
              modalClose={this.modalClose}
            />
          </Modal.Body>
          <Modal.Footer>
            {this.props.save &&
            <Button
              className="pull-left"
              bsStyle="primary"
              onClick={() => this.refs.MetadataForm.saveForm()}>
              <span className="icon-012-check-mark-circle"/> Speichern
            </Button>
            }
            <Button onClick={this.modalClose}>
              <span className="icon-202-clear-circle" /> Schliessen
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

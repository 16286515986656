import { all, call, put } from 'redux-saga/effects';
import { get, uniq } from 'lodash';

// ToDo: Move functions like handleNextHeaders to own repo
import currentRecordAction from '../../actions/ReplayAuditLog/CurrentRecord';
import {
  ADD_ERROR,
  INCREMENT_COUNT,
  INIT_PROGRESS,
  SET_ERRORNEOUS,
  SET_FULFILLED
} from '../../actions/ReplayAuditLog/Progess';

const {
  CURRENT_RECORD_REQUEST,
  CURRENT_RECORD_PENDING,
  CURRENT_RECORD_FULFILLED,
  CURRENT_RECORD_REJECTED
} = currentRecordAction;

function* getRecord({ target, uri }) {
  yield put({ type: CURRENT_RECORD_REQUEST, dataKey: uri });
  try {
    yield put({ type: CURRENT_RECORD_PENDING, dataKey: uri });
    const { data } = yield call(target, {
      url: uri,
      method: 'get'
    });

    yield put({ type: CURRENT_RECORD_FULFILLED, dataKey: uri, payload: data });
    yield put({ type: INCREMENT_COUNT, dataKey: 'currentRecords' });

    return data;
  } catch (error) {
    yield put({ type: CURRENT_RECORD_REJECTED, dataKey: uri, error });
    yield put({ type: ADD_ERROR, dataKey: 'currentRecords', error });

    return error;
  }
}

export default function* getCurrentRecord(request) {
  try {
    const { records, target } = request;
    const requestUris = uniq(
      records
        // Only possible for put requests
        .filter((r) => ['PUT'].includes(get(r, 'method')))
        .map((r) => get(r, 'requestUri'))
    );

    yield put({ type: INIT_PROGRESS, dataKey: 'currentRecords', payload: { total: get(requestUris, 'length', 0) } });

    const operations = requestUris.map((uri) => getRecord({ target, uri }));

    yield all(operations);

    yield put({ type: SET_FULFILLED, dataKey: 'currentRecords' });
  } catch (error) {
    yield put({ type: SET_ERRORNEOUS, dataKey: 'currentRecords', error });

    return error;
  }
}

import { FINANCING_RATES_UPDATE_BASE_RATE_ACTIONS } from '../../actions/FinancingRatesActions';

export function FinancingRatesUpdateBaseRateReducer(state, action) {
  switch (action.type) {
    case FINANCING_RATES_UPDATE_BASE_RATE_ACTIONS.SUCCESS:
      return { ...state, baseRates: action.data.baseRates };
    default:
      return state;
  }
}

import './RefErrorsResults.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SortableTable } from '../Common/SortableTable';
import { Pagination } from '../Common/Pagination';
import { RefErrorsResultsItem } from './RefErrorsResultsItem';
import { REF_ERRORS_PROP_TYPES_RESULT_ITEM } from './RefErrorsPropTypes';
import { REF_ERRORS_MODAL_PAGE_SIZE } from '../../constants/RefErrorsConstants';

const SORTABLE_FIELDS = [
  {
    key: 'id',
    label: <FormattedMessage id="refErrors_results_id"/>,
    sortFn: () => undefined,
  },
  {
    key: 'origin',
    label: <FormattedMessage id="refErrors_results_origin"/>,
    sortFn: () => undefined,
  },
  {
    key: 'target',
    label: <FormattedMessage id="refErrors_results_targets"/>,
    sortFn: () => undefined,
  },
];

export class RefErrorsModalResults extends React.PureComponent {
  constructor(props){
    super(props)
    const { results } = props;
    this.state = {
      currentPage: 0,
      currentPageResult: results.slice(0, REF_ERRORS_MODAL_PAGE_SIZE),
    }
  }
  render() {
    return (
      <div className="ref-errors-results">
          {this.renderContent()}
          {this.renderPagination()}
      </div>
    );
  }

  renderContent() {
    const { currentPageResult } = this.state;
    if (!currentPageResult.length) {
      return null;
    }

    return (
      <div className="ref-errors-results__content">
        <SortableTable
          items={currentPageResult}
          sortableFields={SORTABLE_FIELDS}
          renderItem={this.renderItem}
          className="ref-errors-results__table"
        />
      </div>
    );
  }

  renderPagination() {
    const { results, resultsTotalCount } = this.props;
    const { currentPage } = this.state;
    if (!results) {
      return null;
    }

    const countPages = Math.ceil(resultsTotalCount / REF_ERRORS_MODAL_PAGE_SIZE);
    if (countPages < 2) {
      return null;
    }

    return (
      <div className="ref-errors-results__pagination">
        <Pagination
          prev={true}
          next={true}
          first={true}
          last={true}
          boundaryLinks={true}
          ellipsis={true}
          items={countPages}
          maxButtons={7}
          activePage={currentPage}
          onSelect={this.onChangePage}
        />
      </div>
    );
  }

  renderItem = (item) => <RefErrorsResultsItem key={item.id} item={item}/>;

  onChangePage = (page) => {
    const { results } = this.props;
    if(page === -1) return;
    const pageNumber = page > 0 ? page : 0;
    this.setState({currentPage: pageNumber, currentPageResult: results.slice(pageNumber === 0 ? 0 : REF_ERRORS_MODAL_PAGE_SIZE * pageNumber, (REF_ERRORS_MODAL_PAGE_SIZE * pageNumber) + REF_ERRORS_MODAL_PAGE_SIZE)})

  };
}

RefErrorsModalResults.propTypes = {
  loading: PropTypes.bool,
  results: PropTypes.arrayOf(REF_ERRORS_PROP_TYPES_RESULT_ITEM),
  resultsTotalCount: PropTypes.number.isRequired,
};

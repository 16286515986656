import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { SortableContainer } from 'react-sortable-hoc';
import _ from 'lodash';

import Item from './DetailComponent';


const Items = SortableContainer(({
  addComponent,
  codeGroups,
  codes,
  consultants,
  delComponent,
  duplicateComponent,
  editValueField,
  fetchCodes,
  fieldGroup,
  items,
  language,
  productGroups,
  onSortEnd,
  ...rest
}) => {
  // Find all code selects in components and fetch necessary code groups that are not includes in codes.
  // This is necessary for form dependencies. Else, the user has to open all code field manually to trigger
  // the request
  useEffect(() => {
    const codeSelects = items.filter(i => _.get(i, 'component') === 'CodeSelect');
    const diff = _.compact(_.uniq(_.difference(codeSelects.map((i) => _.get(i, 'props.group')), Object.keys(codes))));

    if (!_.isEmpty(diff)) {
      diff.map((group) => fetchCodes(group));
    }
  }, [fetchCodes, items, codes]);

  const aItems = items.map((item, i) => (
    <Item
      {...rest}
      addComponent={addComponent}
      codeGroups={codeGroups}
      codes={codes}
      consultants={consultants}
      delComponent={delComponent}
      duplicateComponent={duplicateComponent}
      editValueField={editValueField}
      fetchCodes={fetchCodes}
      fieldGroup={fieldGroup}
      id={i}
      index={i}
      item={item}
      items={items}
      key={i}
      language={language}
      productGroups={productGroups}
    />
  ));

  return (
    <div>
      <h3>Formularfelder</h3>
      <div className="SortableHOCList">
        {aItems}
      </div>
      <Button
        bsStyle="primary"
        name="newItem"
        onClick={addComponent.bind(this)}
      >
        <span className="icon-061-plus" /> Neuer Eintrag
      </Button>
    </div>
  );
});

export default Items;

import { call, put } from 'redux-saga/effects';

// ToDo: Move functions like handleNextHeaders to own repo
import connectSourceAction from '../../actions/ReplayAuditLog/ConnectSource';
import connect from './connect';

const {
  CONNECT_SOURCE_PENDING,
  CONNECT_SOURCE_FULFILLED,
  CONNECT_SOURCE_REJECTED
} = connectSourceAction;

export default function* connectSource(request) {
  yield put({ type: CONNECT_SOURCE_PENDING });

  try {
    const payload = yield call(connect, request);

    yield put({ type: CONNECT_SOURCE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CONNECT_SOURCE_REJECTED, error });

    return error;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ListGroup } from 'react-bootstrap';
import copy from 'copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import cl from 'classnames';

import Collapsible from './Collapsible';

class WorkguideComponentIdMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: undefined
    };

    this.onItemClick = this.onItemClick.bind(this);
  }

  /**
   * Handle click on item
   *
   * @param   {String}  value  Component id
   *
   * @return  void
   */
  onItemClick(value) {
    this.setState({ copied: value });
    copy(value);

    setTimeout(() => this.setState({ copied: undefined }), 500);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { copied } = this.state;
    const {
      components,
      language
    } = this.props;

    const children = components.map((component) => {
      const id = get(component, 'props.id');

      return (
        <div
          key={id}
          className="list-group-item"
          onClick={() => this.onItemClick(id)}
        >
          <div className="d-flex align-item-center justify-content-between">
            <div>
              {`${get(component, `props.title.${language}`)} (${get(component, 'props.type')}) - ${id}`}
            </div>

            <div
              className={cl({
                mdi: true,
                'mdi-clipboard-check': copied === id,
                'mdi-clipboard-outline': copied !== id
              })}
            />
          </div>
        </div>
      );
    });

    return (
      <Collapsible
        className="workguide-component-id-map"
        isCollapsed
        label={<FormattedMessage id="Workguide.General.ComponentIdMap.Title" />}
      >
        <ListGroup>
          {children}
        </ListGroup>
      </Collapsible>
    );
  }
}

WorkguideComponentIdMap.propTypes = {
  components: PropTypes.array,
  language: PropTypes.string
};

WorkguideComponentIdMap.defaultProps = {
  components: [],
  language: 'de'
};

export default WorkguideComponentIdMap;

import { UI_NOTES_IMAGE_FETCH_ACTIONS, UI_NOTES_IMAGE_UPLOAD_ACTIONS } from '../../actions/UiNotesActions';

export function uiNotesImageReducer(state, action) {
  switch (action.type) {
    case UI_NOTES_IMAGE_FETCH_ACTIONS.SUCCESS:
    case UI_NOTES_IMAGE_UPLOAD_ACTIONS.SUCCESS:
      return {
        ...state,
        image: action.data,
      };

    default:
      return state;
  }
}

import { REF_ERRORS_PAGE_SIZE } from '../../constants/RefErrorsConstants';
import { convertDateToApi } from '../../utils/dateConverter';

export function createSearchQuery({
  sortField,
  sortDirection,
  collection,
  recordId,
  targetCollection,
  targetId,
  dateFrom,
  dateTo,
  limit = REF_ERRORS_PAGE_SIZE,
  offset = 0
}) {
  const queryParams = [`limit(${limit},${offset})`]

  if (sortField) {
    queryParams.push(`sort(${(sortDirection === 'desc' ? '-' : '+') + sortField})`);
  }

  if (collection) {
    queryParams.push(`like(collectionName,${collection}*)`);
  }

  if (recordId) {
    queryParams.push(`eq(recordId,string:${recordId})`);
  }

  if (targetCollection) {
    queryParams.push(`like(refErrors.targetCollection,${targetCollection}*)`);
  }

  if (targetId) {
    queryParams.push(`eq(refErrors.targetId,string:${targetId})`);
  }

  if (dateFrom && dateFrom.isValid()) {
    queryParams.push(`ge(loadedAt,${convertDateToApi(dateFrom.startOf('dateTime'))})`);
  }

  if (dateTo && dateTo.isValid()) {
    queryParams.push(`le(loadedAt,${convertDateToApi(dateTo.endOf('dateTime'))})`);
  }

  return queryParams.join('&');
}
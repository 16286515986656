import { put } from 'redux-saga/effects';

import editNodeFormAction from '../../actions/Workflow/EditNodeForm';

const {
  WORKFLOW_TREE_EDIT_NODE_FORM_SAVE_PENDING,
  WORKFLOW_TREE_EDIT_NODE_FORM_SAVE_FULFILLED,
  WORKFLOW_TREE_EDIT_NODE_FORM_SAVE_REJECTED
} = editNodeFormAction;

export default function* saveEditNodeForm(request) {
  const { data } = request;

  yield put({ type: WORKFLOW_TREE_EDIT_NODE_FORM_SAVE_PENDING });

  try {
    const payload = data;

    yield put({ type: WORKFLOW_TREE_EDIT_NODE_FORM_SAVE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: WORKFLOW_TREE_EDIT_NODE_FORM_SAVE_REJECTED, error });

    return error;
  }
}

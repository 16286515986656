import './FeatureFlagItem.css';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop } from 'lodash';
import { Modal } from 'react-bootstrap';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { ModalBody, ModalDialog, ModalFooter, ModalHeader } from '../Common/Modal';
import { Button } from '../Common/Button';
import { getValidator } from '../../globals';
import featureFlagActions from '../../actions/FeatureFlag/Actions';

class FeatureFlagItem extends React.Component {
  constructor(props) {
    super(props);

    const { actions, featureFlag } = props;

    this.submit = this.submit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onChangeActive = this.onChangeActive.bind(this);
    this.onChangeObject = this.onChangeObject.bind(this);
    this.onChange = this.onChange.bind(this);

    actions.featureFlagFormInitRequest({ featureFlag });
  }

  onChangeActive() {
    const { actions, formData } = this.props;

    const value = !get(formData, 'active');

    actions.featureFlagFormSetValue('active', value);
  }

  onChangeObject(ev, id, objectId) {
    const { actions, formData } = this.props;
    const { value } = ev.target;

    const object = get(formData, objectId);
    const updatedObject = { ...object, [id]: value };

    actions.featureFlagFormSetValue(objectId, updatedObject);
  }

  onChange(ev, id) {
    const { actions } = this.props;
    const { value } = ev.target;

    actions.featureFlagFormSetValue(id, value);
  }

  closeModal() {
    const { onCloseModal, actions } = this.props;

    actions.featureFlagFormReset();

    onCloseModal();
  }

  submit() {
    const { formData, actions, onCloseModal } = this.props;

    actions.featureFlagFormSaveRequest({
      ...formData
    });

    onCloseModal();
  }

  /**
   * Validate current form data
   *
   * @return  {Object} result  Validation result
   */
  validate() {
    const { formData } = this.props;

    const definition = {
      validations: {
        name: {
          type: 'object',
          de: {
            type: 'string',
            required: true
          }
        },
        description: {
          type: 'object',
          de: {
            type: 'string',
            required: true
          }
        },
        active: {
          type: 'boolean',
          required: true
        }
      }
    };

    const validator = getValidator();

    const validationResults = validator.validate(definition, formData);
    return validationResults;
  }

  render() {
    const {
      formData,
      showValidations,
      featureFlag,
      isOpen,
      language
    } = this.props;

    const validationResult = this.validate();

    return (
      <ModalDialog show={isOpen} onHide={this.closeModal} bsSize="medium">
        <ModalHeader closeButton>
          <Modal.Title>
            <div>{get(featureFlag, 'identifier')}</div>
            <div className="feature-flag-item__modal-title">
              {get(featureFlag, `name.${language}`)}
              {` (${language})`}
            </div>
          </Modal.Title>
        </ModalHeader>
        <ModalBody style={{ padding: '0 35px' }}>
          <Row>
            <Col>
              <FormGroup>
                <Label>
                  Name
                  {` (${language})`}
                </Label>
                <Input
                  id="name"
                  type="text"
                  onChange={(ev) => this.onChangeObject(ev, `${language}`, 'name')}
                  value={get(formData, `name.${language}`)}
                  showValidations={showValidations}
                  validations={get(validationResult, `name.${language}`, {})}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>
                  Identifier
                </Label>
                <Input
                  readOnly
                  id="identifier"
                  type="text"
                  value={get(formData, 'identifier')}
                  showValidations={showValidations}
                  validations={validationResult.identifier}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>
                  Beschreibung
                  {` (${language})`}
                </Label>
                <Input
                  id="description"
                  onChange={(ev) => this.onChangeObject(ev, `${language}`, 'description')}
                  rows={4}
                  type="textarea"
                  value={get(formData, `description.${language}`)}
                  showValidations={showValidations}
                  validations={get(validationResult, `description.${language}`, {})}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label check style={{ width: '50px' }}>
                  Aktiv
                </Label>
                <Input
                  type="checkbox"
                  checked={get(formData, 'active')}
                  onClick={this.onChangeActive}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.submit}>
            Speichern
          </Button>
          <Button onClick={this.closeModal}>
            Schliessen
          </Button>
        </ModalFooter>
      </ModalDialog>
    );
  }
}

FeatureFlagItem.propTypes = {
  actions: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  featureFlag: PropTypes.object,
  showValidations: PropTypes.bool,
  formData: PropTypes.object,
  language: PropTypes.string
};

FeatureFlagItem.defaultProps = {
  isOpen: false,
  onCloseModal: noop,
  featureFlag: undefined,
  showValidations: true,
  formData: {},
  language: 'de'
};

function mapStateToProps(state) {
  return {
    requesting: state.featureFlags.featureFlags.requesting,
    error: state.featureFlags.featureFlags.error,
    formData: state.featureFlags.featureFlagForm.data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(featureFlagActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeatureFlagItem);

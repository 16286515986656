import React, { Component, createRef } from 'react';
import { Creatable } from 'react-select';
import PropTypes from 'prop-types';
import { get, noop, uniqBy } from 'lodash';

import { fetchTranslations } from '../../services/Translations';

class AutocompleteCellEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? { value: props.value, label: props.value } : null,
      options: [],
    };
    this.autocompleteRef = createRef();

    this.onChange = this.onChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
  }

  async componentDidMount() {
    await this.loadOptions();
  }

  /**
   * Handle selection change.
   * Call grid onValueChange / stopEditing to save the selected value
   *
   * @param   {Object}  selectedOption  Selected option
   *
   * @return  void
   */
  onChange(selectedOption) {
    const { onValueChange, stopEditing } = this.props;
    this.setState({ value: selectedOption });

    const value = get(selectedOption, 'value', '');
    onValueChange(value);
    stopEditing();
  }

  onCreate(inputValue) {
    const newOption = { value: inputValue, label: inputValue };

    this.setState((prevState) => ({ value: newOption, options: [...prevState.options, newOption] }));
  }

  async loadOptions() {
    const { colDef } = this.props;

    const colId = get(colDef, 'colId');
    const searchByLanguage = get(this, 'props.searchByLanguage');
    const filterBy = get(this, `props.data.value.${searchByLanguage}`, '');

    try {
      const filterModel = {
        [searchByLanguage]: {
          filter: filterBy,
          filterType: 'text',
          type: 'match'
        }
      };

      const options = await fetchTranslations(filterModel, 20, 0);
      const values = options.map((el) => ({ value: get(el, `value.${colId}`), label: get(el, `value.${colId}`) })) || [];

      this.setState({ options: uniqBy(values, 'value') });
    } catch (error) {
      // We want to show the error in console
      // eslint-disable-next-line
      console.error('Error fetching suggestions:', error);
      this.setState({ options: [] });
    }
  }

  render() {
    const { column } = this.props;
    const { value, options } = this.state;

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Creatable
          onChange={this.onChange}
          onCreateOption={this.onCreate}
          options={options}
          ref={this.autocompleteRef}
          style={{ width: get(column, 'actualWidth') }}
          value={value}
        />
      </div>
    );
  }
}

AutocompleteCellEditor.propTypes = {
  colDef: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  onValueChange: PropTypes.func,
  stopEditing: PropTypes.func,
  value: PropTypes.string
};

AutocompleteCellEditor.defaultProps = {
  onValueChange: noop,
  stopEditing: noop,
  value: undefined
};

export default AutocompleteCellEditor;

export const SET_COMMON_DATA = 'SET_COMMON_DATA';

export function setCommonData({ key, value }) {
  return dispatch =>
    dispatch({
      type: SET_COMMON_DATA,
      key,
      value,
    });
}

import './AnalyticsLogsSearchResultsItem.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TableCell, TableRow } from '../Common/Table';
import { ModalBody, ModalDialog, ModalFooter, ModalHeader } from '../Common/Modal';
import { Button } from '../Common/Button';
import { PROP_TYPES_DETAILS, PROP_TYPES_SEARCH_RESULT_ITEM } from './AnalyticsLogsPropTypes';
import Spinner from '../Spinner';
import { FormattedGravitonDate } from '../FormattedGravitonDate';
import { AnalyticsLogsSearchResultsDetails } from './AnalyticsLogsSearchResultsDetails';
import { classList } from '../../utils/classList';

class AnalyticsLogsSearchResultsItemWithIntl extends React.PureComponent {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addToComparison = this.addToComparison.bind(this);

    this.state = {
      isModalOpen: false,
    };
  }

  render() {
    const { item, isSelected, intl } = this.props;
    const displayComparisonButton = item.method === 'PUT';
    const classes = classList(
      'analytics-logs-results-item',
      {
        'analytics-logs-results-item--selected': isSelected,
      },
    );
    return (
      <TableRow className={classes}>
        <TableCell>
          {item.username}
        </TableCell>
        <TableCell className="analytics-logs-results-item__timestamp">
          <FormattedGravitonDate value={item.createdAt} format="DD.MM.YYYY HH:mm:ss"/>
        </TableCell>
        <TableCell>
          {item.method}
        </TableCell>
        <TableCell className="analytics-logs-results-item__request">
          {item.requestUri}
        </TableCell>
        <TableCell className="analytics-logs-results-item__action">
          <span className="analytics-logs-results-item__icon-details" onClick={this.openModal}>i</span>
          {this.renderModal()}
        </TableCell>
        <TableCell className="analytics-logs-results-item__action analytics-logs-results-item__action--compare">
          {displayComparisonButton &&
            <span className="analytics-logs-results-item__icon-compare"
                  onClick={this.addToComparison}
                  title={intl.formatMessage({ id: 'analyticsLogs_searchResultsItem_comparison' })}>D</span>
          }
        </TableCell>
      </TableRow>
    );
  }

  renderModal() {
    const { item } = this.props;
    const { isModalOpen } = this.state;
    return (
      <ModalDialog show={isModalOpen} onHide={this.closeModal} bsSize="lg">
        <ModalHeader closeButton={true}>
          <FormattedMessage id="analyticsLogs_searchResultsItem_title" values={{ id: item.id }}/>
        </ModalHeader>
        <ModalBody>
          {this.renderModalContent()}
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.closeModal} bsSize="sm">
            <FormattedMessage id="common_close"/>
          </Button>
        </ModalFooter>
      </ModalDialog>
    );
  }

  renderModalContent() {
    const { requestDetails, loading, item } = this.props;
    if (loading) {
      return <Spinner/>;
    }

    if (!requestDetails) {
      return (
        <div className="analytics-logs-results-item__alert">
          <FormattedMessage id="analyticsLogs_searchResultsItem_error" values={{ id: item.id }}/>
        </div>
      );
    }

    return (
      <AnalyticsLogsSearchResultsDetails requestDetails={requestDetails}/>
    );
  }

  openModal() {
    const { requestDetails, getRequestDetails, item } = this.props;
    if (!requestDetails || item.id !== requestDetails.id) {
      getRequestDetails(item.id, item.createdAt);
    }
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  addToComparison() {
    const { addToComparison, item } = this.props;
    addToComparison(item);
  }
}

export const AnalyticsLogsSearchResultsItem = injectIntl(AnalyticsLogsSearchResultsItemWithIntl);
AnalyticsLogsSearchResultsItem.propTypes = {
  loading: PropTypes.bool,
  item: PROP_TYPES_SEARCH_RESULT_ITEM,
  requestDetails: PROP_TYPES_DETAILS,
  getRequestDetails: PropTypes.func.isRequired,
};

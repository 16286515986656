import { HOLIDAYS_LOAD_ACTIONS } from '../../actions/HolidaysActions';

const initialState = {
  holidaysLists: [],
  year: new Date().getFullYear(),
  initialData: [],
  loading: true,
  error: undefined,
  viewMode: 'calendar',
};

export function HolidaysLoadReducer(state = initialState, action) {
  switch (action.type) {
    case HOLIDAYS_LOAD_ACTIONS.PENDING:
      return { ...state, loading: true, error: undefined };
    case HOLIDAYS_LOAD_ACTIONS.SUCCESS:
      return { ...state, loading: false, error: undefined, holidaysLists: action.data, initialData: action.data };
    case HOLIDAYS_LOAD_ACTIONS.FAILURE:
      return { ...state, loading: false, error: action.data };
    default:
      return state;
  }
}

import { createSelector } from 'reselect';

export const getFinancingProfilingState = (state) => state.financingProfiling;
export const getFinancingProfilingLoading = createSelector([getFinancingProfilingState], (state) => state.loading);
export const getFinancingProfilingError = createSelector([getFinancingProfilingState], (state) => state.error);
export const getFinancingProfilingInitial = createSelector([getFinancingProfilingState], (state) => state.initialData);
export const getFinancingProfilingLoanProducts = createSelector([getFinancingProfilingState], (state) => state.loanProducts);
export const getFinancingProfilingDurations = createSelector([getFinancingProfilingState], (state) => state.durations);
export const getFinancingProfilingEntityCodes = createSelector([getFinancingProfilingState], (state) => state.entityCodes);
export const getFinancingProfilingProfilesRaw = createSelector([getFinancingProfilingState], (state) => state.profiles);

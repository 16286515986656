import { get } from 'lodash';

import ConditionValidaitonDefinition from './ConditionValidaitonDefinition';

export default {
  type: 'object',
  validations: {
    id: {
      type: 'string',
      required: true,
      validations: {}
    },
    source: {
      type: 'string',
      required: true,
      validations: {}
    },
    target: {
      type: 'string',
      required: true,
      validations: {}
    },
    condition: {
      ...ConditionValidaitonDefinition,
      required: ({ parent }) => get(parent, 'isConditional', false)
    }
  },
  disabledWorkguideComponents: {
    type: 'array',
    required: false,
    validations: {}
  }
};

import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import { getBasicProducts, getCategories, getUpdatedProducts } from '../../selectors/BasicProducts';
import { createRef } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';
import { mapBasicProductToApi } from '../../services/BasicProducts';
import { getLanguage } from '../../selectors/commonSelectors';

export function* updateBasicProductsSaga() {
  const updatedProducts = yield select(getUpdatedProducts);
  const language = yield select(getLanguage);
  const basicProducts = yield select(getBasicProducts);
  const basicCategories = yield select(getCategories);

  yield all(
    updatedProducts.map((product) => call(axios, {
      url: createRef(EndpointName.BASIC_PRODUCT, product.id),
      method: 'put',
      data: mapBasicProductToApi(product, language, basicProducts, basicCategories),
    }))
  );
}

import React from 'react';
import { Col, ControlLabel, Form, FormControl, FormGroup } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { getIdFromRef } from "../../utils";

const emptyOption = { id: 'none', value: 'Keine Auswahl' };

export default class MetadataForm extends React.Component {
  constructor(props) {
    super(props);

    const module = props.links.find((it) => it.type === 'module');
    const screen = props.links.find((it) => it.type === 'screen');
    const button = props.metadata.additionalProperties.find((it) => it.name === 'button');
    const mandatory = props.metadata.additionalProperties.find((it) => it.name === 'mandatoryAdvisoryDocument');

    this.state = {
      fileId: props.id,
      filename: props.metadata.filename,
      moduleId: module ? getIdFromRef(module.$ref) : '',
      screenId: screen ? getIdFromRef(screen.$ref) : emptyOption.id,
      buttonId: button ? button.value : emptyOption.id,
      directButtons: [],
      changed: false,
      isReadonly: !props.save,
      isMandatory: !!mandatory,
    };

    this.setFilename = this.setFilename.bind(this);
    this.setModule = this.setModule.bind(this);
    this.setScreen = this.setScreen.bind(this);
    this.setButton = this.setButton.bind(this);
    this.toggleMandatory = this.toggleMandatory.bind(this);
  }

  saveForm() {
    const { save, modalClose } = this.props;
    const { fileId, filename, moduleId, screenId, buttonId, isMandatory } = this.state;
    save(fileId, filename, moduleId, screenId, buttonId, isMandatory);
    modalClose();
  }

  setFilename(event) {
    const regex = /^[0-9a-zA-Z-_.]*$/;
    if (regex.test(event.target.value)) {
      this.setState({
        filename: event.target.value,
        changed: true,
      });
    }
  }

  setModule(event) {
    const { screenId } = this.state;
    const moduleId = event.target.value;
    const isScreenInModule = this.getScreensByModule(moduleId).some(screen => screen.id === screenId);

    this.setState({
      moduleId: moduleId,
      screenId: isScreenInModule ? screenId : emptyOption.id,
    });
  }

  setScreen(event) {
    this.setState({
      screenId: event.target.value,
    });
  }

  setButton(event) {
    this.setState({
      buttonId: event.target.value,
    });
  }

  toggleMandatory() {
    this.setState((prev) => ({ isMandatory: !prev.isMandatory }));
  }

  render() {
    const { filename, moduleId, screenId, buttonId, isReadonly, isMandatory } = this.state;

    return (
      <Form horizontal>
        <FormGroup controlId="formHorizontalFilename">
          <Col componentClass={ControlLabel} sm={3}>
            Dateiname
          </Col>
          <Col sm={9}>
            <FormControl
              onChange={this.setFilename}
              type="text"
              value={filename}
              readOnly={isReadonly}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalFilesize">
          <Col componentClass={ControlLabel} sm={3}>
            Dateigrösse
          </Col>
          <Col sm={9}>
            <FormControl
              readOnly
              type="text"
              value={`${Math.floor(this.props.metadata.size / 1024)} Kilobyte`}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="formControlsSelect">
          <Col componentClass={ControlLabel} sm={3}>
            Modul
          </Col>
          <Col sm={9}>
            <FormControl
              onChange={this.setModule}
              value={moduleId}
              componentClass="select"
              placeholder="select"
              readOnly={isReadonly}
            >
              {this.getModuleOptions()}
            </FormControl>
          </Col>
        </FormGroup>

        <FormGroup controlId="formControlsSelectScreen">
          <Col componentClass={ControlLabel} sm={3}>
            Modul-Screens
          </Col>
          <Col sm={9}>
            <FormControl
              onChange={this.setScreen}
              value={screenId}
              componentClass="select"
              placeholder="select"
              readOnly={isReadonly}
            >
              {this.getScreenOptions()}
            </FormControl>
          </Col>
        </FormGroup>

        <FormGroup controlId="formControlsSelectDirectLink">
          <Col componentClass={ControlLabel} sm={3}>
            Direktlink Button
          </Col>
          <Col sm={9}>
            <FormControl
              onChange={this.setButton}
              value={buttonId}
              componentClass="select"
              placeholder="select"
              readOnly={isReadonly}
            >
              {this.getButtonOptions()}
            </FormControl>
          </Col>
        </FormGroup>

        <FormGroup controlId="formControlsMandatoryDocument">
          <Col componentClass={ControlLabel} sm={3}>
            Pflichtdokument (Anlagen)
          </Col>
          <Col sm={9}>
            <Toggle
              onChange={this.toggleMandatory}
              checked={isMandatory}
              disabled={isReadonly}
            />
          </Col>
        </FormGroup>
      </Form>
    );
  }

  getModuleOptions() {
    const { modules } = this.props;
    const items = modules.map((module) => ({id: module.id, value: module.name.de}));
    return this.getOptions(items);
  }

  getScreenOptions() {
    const { moduleId } = this.state;
    const items = this.getScreensByModule(moduleId)
      .map((screen) => ({id: screen.id, value: screen.name.de}));
    return this.getOptions([emptyOption, ...items]);
  }

  getButtonOptions() {
    return this.getOptions([emptyOption, ...this.props.buttons]);
  }

  getOptions(items) {
    return items.map((item) => <option key={item.id} value={item.id}>{item.value}</option>);
  }

  getScreensByModule(moduleId) {
    const { screens } = this.props;
    return screens.filter((screen) => {
      const screenModuleId = screen.module && screen.module.$ref
        ? getIdFromRef(screen.module.$ref)
        : undefined;
      return screenModuleId === moduleId;
    });
  }
}

import { put, select } from 'redux-saga/effects';
import { getFinancingRatesForwards } from '../../selectors/FinancingRates/financingRatesCommonSelectors';
import { getFinancingRatesForwardPeriods } from '../../selectors/FinancingRates/financingRatesForwardSelectors';
import { FINANCING_RATES_UPDATE_FORWARDS_ACTIONS } from '../../actions/FinancingRatesActions';
import { EndpointName } from '../../constants/EndpointName';
import { createRef, getIdFromRef } from '../../utils';

export function* financingRatesUpdateForwardsSaga({ data: { duration, forwards: changedForwards } }) {
  const allForwards = yield select(getFinancingRatesForwards);
  const forwardPeriods = yield select(getFinancingRatesForwardPeriods);

  const initialForwards = allForwards.filter((it) => compareDurations(it.duration, duration));
  const created = changedForwards.map((forward) => mapCreated(forward, duration, initialForwards, forwardPeriods));

  const updatedForwards = allForwards
    .map((initial) => compareDurations(initial.duration, duration)
      ? mapUpdated(initial, changedForwards, forwardPeriods)
      : initial,
    )
    .concat(created)
    .filter(Boolean);

  yield put(FINANCING_RATES_UPDATE_FORWARDS_ACTIONS.success({ forwards: updatedForwards }));
}


function mapUpdated(initial, updatedForwards, forwardPeriods) {
  const updated = updatedForwards.find((it) => it.id === initial.id);
  if (!updated) {
    return undefined;
  }

  const period = forwardPeriods.find((it) => it.value.forwardFrom === updated.forwardFrom);
  return {
    ...initial,
    forwardRate: updated.forwardRate ? updated.forwardRate : initial.forwardRate,
    forwardFrom: period ? period.value.forwardFrom : initial.forwardFrom,
    forwardUntil: period ? period.value.forwardUntil : initial.forwardUntil,
  };
}

function mapCreated(forward, duration, initialForwards, forwardPeriods) {
  if (forward.id) {
    return undefined;
  }
  if (!forward.forwardRate) {
    return undefined
  }
  const period = forwardPeriods.find((it) => it.value.forwardFrom === forward.forwardFrom);
  if (!period) {
    return undefined;
  }

  return {
    id: `${duration.id}-${period.value.forwardFrom}`,
    forwardRate: forward.forwardRate,
    forwardFrom: period.value.forwardFrom,
    forwardUntil: period.value.forwardUntil,
    duration: {
      $ref: createRef(EndpointName.FINANCING_DURATION, duration.id),
    },
  };
}

function compareDurations(durationObjectRef, duration) {
  return durationObjectRef && getIdFromRef(durationObjectRef.$ref) === duration.id;
}

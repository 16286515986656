import axios from 'axios';
import { all, call, put, select } from 'redux-saga/effects';
import { HOLIDAYS_SAVE_ACTIONS } from '../../actions/HolidaysActions';
import { EndpointName } from '../../constants/EndpointName';
import { getHolidays, getHolidaysInitial } from '../../selectors/holidaysSelectors';

export function* holidaysSaveSaga() {
  try {
    yield put(HOLIDAYS_SAVE_ACTIONS.pending());

    const initialData = yield select(getHolidaysInitial);
    const holidays = yield select(getHolidays);

    yield all(holidays
      .filter((list) => {
        const initial = initialData.find((it) => it.id === list.id);
        return initial && initial.dateRanges !== list.dateRanges;
      })
      .map((list) => call(axios, {
        method: 'patch',
        url: `${EndpointName.FINANCING_HOLIDAYS}/${encodeURIComponent(list.id)}`,
        data: [{
          op: 'replace',
          path: '/dateRanges',
          value: list.dateRanges,
        }],
      }))
    );

    yield put(HOLIDAYS_SAVE_ACTIONS.success(holidays));
  } catch(error) {
    yield put(HOLIDAYS_SAVE_ACTIONS.failure(error));
  }
}

import axios from 'axios';
import { all, call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { encodeRqlString, getIdFromRef } from '../../utils';
import { LOAD_PROMOTIONS_ACTIONS } from '../../actions/PromotionActions';

const urlModule = `${EndpointName.CORE_MODULE}/pos`;
const urlConfig = `${EndpointName.CORE_CONFIG}/?eq(key,promotionChecklistModule)`;
const urlSchema = `/schema/pos/promotion/collection`;

export function* loadPromotions() {
  try {
    yield put(LOAD_PROMOTIONS_ACTIONS.pending());

    const [
      { data: promotions },
      { data: checklist },
      { data: customerSegments },
      { data: config },
      { data: module },
      { data: schema },
    ] = yield all([
      call(axios, { url: getAll(EndpointName.PROMOTIONS) }),
      call(axios, { url: getAll(EndpointName.CHECKLIST) }),
      call(axios, { url: getEntityCodes(['customerSegment']) }),
      call(axios, { url: urlConfig }),
      call(axios, { url: urlModule }),
      call(axios, { url: urlSchema }),
    ]);

    const files = yield call(loadAllPromotionFiles, promotions);
    const data = {
      promotions,
      checklist,
      files,
      config,
      module,
      customerSegments,
      schema,
    };

    yield put(LOAD_PROMOTIONS_ACTIONS.success(data));
  } catch(error) {
    yield put(LOAD_PROMOTIONS_ACTIONS.failure(error));
  }
}

function getAll(endpoint, filter = '') {
  return `${endpoint}/?limit(9999)&sort(+order)&${filter}`;
}

function getEntityCodes(groups) {
  const filter = `in(group,(${groups.join(',')}))`;
  return `${getAll(EndpointName.ENTITY_CODE, filter)}`;
}

function getAllPromotionFiles(promotions) {
  const fileIds = promotions
    .reduce((result, promotion) => ([...result, ...getPromotionFiles(promotion)]), [])
    .map(id => encodeRqlString(id));

  return getAll(EndpointName.FILE, `in(id,(${fileIds.join(',')}))`);
}

function getPromotionFiles(promotion) {
  return [
    getIdFromRef(promotion.image.$ref),
    getIdFromRef(promotion.teaserImage.$ref),
    ...promotion.document.map(document => getIdFromRef(document.$ref)),
  ];
}

function* loadAllPromotionFiles(promotions) {
  if (!promotions.length) {
    return [];
  }
  const { data: files } = yield call(axios, { url: getAllPromotionFiles(promotions) });
  return files;
}

import _ from 'lodash';
import uuid from 'uuid';
import { createSelector } from 'reselect';
import { getIdFromRef } from '../../utils';
import { getFinancingProfilingDurations, getFinancingProfilingProfilesRaw } from './financingProfilingCommonSelectors';
import { getFinancingProfilingLoanProductOptions } from './financingProfilingOptionsSelectors';

export const getFinancingProfilingProfiles = createSelector([
  getFinancingProfilingLoanProductOptions,
  getFinancingProfilingDurations,
  getFinancingProfilingProfilesRaw,
], (loanProductOptions, durations, profilesRaw) => {
  return _.sortBy(profilesRaw, ['limitStart', 'limitEnd'])
    .reduce((result, profile) => {
      const availableProducts = groupDurationsByProduct(profile.availableProducts, loanProductOptions, durations);
      const defaultProducts = groupDurationsByProduct(profile.defaultProducts, loanProductOptions, durations);
      const products = availableProducts.map((product) => mapProduct(profile, product, defaultProducts));
      return result.concat(products);
    }, []);
});

function groupDurationsByProduct(productsRaw, loanProductOptions, durations) {
  const durationsByProducts = productsRaw
    .map((it) => ({
      productId: getIdFromRef(it.product),
      durationId: getIdFromRef(it.duration),
    }))
    .reduce((result, { productId, durationId }) => {
      if (!result[productId]) {
        result[productId] = [];
      }
      result[productId].push(durationId);
      return result;
    }, {});

  return Object.entries(durationsByProducts).map(([productId, durationIds]) => {
    const loanProductOption = loanProductOptions.find((option) => option.value === productId);
    return {
      id: productId,
      title: loanProductOption ? loanProductOption.label : productId,
      durations: _.sortBy(
        durationIds
          .map((durationId) => durations.find((duration) => duration.id === durationId))
          .filter(Boolean),
        (it) => it.durationInYears,
      ),
    };
  });
}

function mapProduct(profile, product, defaultProducts) {
  const defaultProduct = defaultProducts.find((it) => it.id === product.id);
  const defaultDurations = defaultProduct ? defaultProduct.durations : [];
  return {
    id: uuid.v4(),
    parentId: profile.id,
    profileId: getIdFromRef(profile.profile),
    predictionId: getIdFromRef(profile.prediction),
    limitStart: profile.limitStart,
    limitEnd: profile.limitEnd,
    productId: product.id,
    productTitle: product.title,
    durations: product.durations.map((duration) => ({
      id: duration.id,
      durationInYears: duration.durationInYears,
      isDefault: defaultDurations.some((defaultDuration) => defaultDuration.id === duration.id),
    })),
  };
}

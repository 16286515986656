import { put, select } from 'redux-saga/effects';
import { get, every, orderBy, compact } from 'lodash';
import moment from 'moment';

// ToDo: Move functions like handleNextHeaders to own repo
import applyLocalFiltersAction from '../../actions/ReplayAuditLog/ApplyLocalFilters';
import * as filterFunctions from '../../js/lib/ReplayAuditLog/LocalFilters/index';
import {
  INCREMENT_COUNT,
  INIT_PROGRESS,
  SET_ERRORNEOUS,
  SET_FULFILLED
} from '../../actions/ReplayAuditLog/Progess';

const {
  APPLY_LOCAL_FILTERS_PENDING,
  APPLY_LOCAL_FILTERS_FULFILLED,
  APPLY_LOCAL_FILTERS_REJECTED
} = applyLocalFiltersAction;

function checkRecord({ filters, record, state }) {
  return every(filters, (filter) => {
    const { condition } = filter;
    const func = get(filterFunctions, condition, () => true);

    return func(filter, { state, record });
  });
}

export default function* applyLocalFilters(request) {
  yield put({ type: APPLY_LOCAL_FILTERS_PENDING });

  try {
    const { filters } = request;
    const state = yield select((state) => state);
    const prepared = get(state, 'replayAuditLog.prepared.data', []);

    yield put({ type: INIT_PROGRESS, dataKey: 'localFilters', payload: { total: get(prepared, 'length', 0) } });

    let payload = [];

    for (let record of prepared) {
      const result = checkRecord({ filters, record, state });
      if (result) payload.push(record);

      yield put({ type: INCREMENT_COUNT, dataKey: 'localFilters' });
    }

    payload = orderBy(compact(payload), (a) => moment(a.createdAt), ['asc'])

    yield put({ type: APPLY_LOCAL_FILTERS_FULFILLED, payload });
    yield put({ type: SET_FULFILLED, dataKey: 'localFilters' });

    return payload;
  } catch (error) {
    yield put({ type: APPLY_LOCAL_FILTERS_REJECTED, error });
    yield put({ type: SET_ERRORNEOUS, dataKey: 'localFilters', error });

    return error;
  }
}

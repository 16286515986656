import './Bank.css';

import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { FormControl } from 'react-bootstrap';
import DragHandle from '../DragHandle';

const Bank = SortableElement(({
  item, language, onRemove, onEdit,
}) => {
  return (
    <div className="bank-item SortableHOCItem">
      <div className="bank-item__text">
        <FormControl
          type="text"
          name="banklistItem"
          value={item.text[language]}
          onChange={onEdit.bind(this, item, 'text')}
          onFocus={onEdit.bind(this, item, 'text')}
        />
      </div>
      <div className="bank-item__text-short">
        <FormControl
          className={item.violated ?
            'is-violated input-check' :
            'input-check'}
          type="text"
          name="banklistItemShort"
          value={item.textShort[language]}
          onChange={onEdit.bind(this, item, 'textShort')}
          onFocus={onEdit.bind(this, item, 'textShort')}
        />
      </div>
      <div className="bank-item__action">
        <i className="icon-008-bin" onClick={onRemove.bind(this, item)}><span className="hidden">remove</span></i>
        <DragHandle/>
      </div>
    </div>
  );
});

export default Bank;

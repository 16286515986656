import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import PdfPreview from '../Documents/PdfPreview';
import { LOAD_PROMOTIONS_FILES_ACTIONS } from '../../actions/PromotionActions';
import { getFiles } from '../../selectors/Promotions';
import Spinner from '../Spinner';

const FilePreviewWithState = (props) => {
  const { load, file, fileContent, fileType, ...rest } = props;

  if (!fileContent) {
    if (file.url) {
      return fileType === 'image'
        ? <Image src={file.url} {...rest}/>
        : <PdfPreview blob={file.url} {...rest}/>
    }

    return null;
  }

  if (!fileContent.blob && !fileContent.loading) {
    load(file);
    return null;
  }

  if (fileContent.loading) {
    return <Spinner />;
  }

  return fileType === 'image'
    ? <Image src={fileContent.url} {...rest}/>
    : <PdfPreview blob={fileContent.url}
                  error={fileContent.error}
                  {...rest}/>;
};

const getFileId = (state, props) => props.file.id;
const mapStateToProps = createSelector([
  getFileId,
  getFiles,
], (
  fileId,
  files,
) => ({
  fileContent: files[fileId],
}));

const mapDispatchToProps = {
  load: (file) => LOAD_PROMOTIONS_FILES_ACTIONS.request(null, {file}),
};
const FilePreview = connect(mapStateToProps, mapDispatchToProps)(FilePreviewWithState);

export const ImagePreview = (props) => {
  return (<FilePreview fileType="image" {...props}/>);
};
ImagePreview.propTypes = {
  file: PropTypes.object.isRequired,
};

export const DocumentPreview = (props) => {
  return (<FilePreview fileType="document" {...props}/>);
};
DocumentPreview.propTypes = {
  file: PropTypes.object.isRequired,
};

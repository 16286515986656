import React from 'react';
import moment from 'moment';

export function FormattedDate({ value, format = "DD.MM.YYYY" }) {
  if (!value) {
    return null;
  }
  const m = value instanceof moment ? value : moment(value);
  if (!m.isValid()) {
    return null;
  }
  return <React.Fragment>{m.format(format)}</React.Fragment>;
}

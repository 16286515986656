import axios from 'axios';
import { call } from 'redux-saga/effects';

export function* uiNotesUploadImage({ $ref, file }) {
  const formData = new FormData();
  formData.append('upload', file);

  yield call(axios.put, $ref, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export default {
  key: 'creditLimit',
  label: 'Keine Kreditlimite gesetzt',
  validation: 'hasNoAccountCreditLimit',
  availableFor: ['AccountSelect'],
  get: function(params) {
    return {
      creditLimit: {
        type: 'number',
        required: false,
        validations: {
          hasNoAccountCreditLimit: []
        }
      }
    };
  }
}

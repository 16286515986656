import './FinancingProfilingEdit.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableCell, TableHead, TableRow } from '../Common/Table';
import { FormattedMessage } from 'react-intl';
import { SelectControl } from '../Common/SelectControl';
import { InputControl } from '../Common/InputControl';
import { Button } from '../Common/Button';

export class FinancingProfilingEdit extends React.Component {
  state = {
    product: { ...this.props.product },
  };

  render() {
    const { product } = this.state;
    const { profileOptions, predictionOptions, loanProductOptions, onCancel } = this.props;
    const loanProduct = loanProductOptions.find((it) => it.value === product.productId);
    const durations = loanProduct ? loanProduct.durations : [];

    return (
      <div className="financing-profiling-edit">
        <Table>
          <TableRow>
            <TableCell className="financing-profiling-edit__label">
              <FormattedMessage id="financingProfiling_edit_profile"/>
            </TableCell>
            <TableCell className="financing-profiling-edit__value">
              <SelectControl
                value={product.profileId}
                multi={false}
                clearable={false}
                options={profileOptions}
                onChange={this.onChangeProfile}
                className={product.profileId ? '' : 'financing-profiling-edit__invalid'}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="financing-profiling-edit__label">
              <FormattedMessage id="financingProfiling_edit_prediction"/>
            </TableCell>
            <TableCell className="financing-profiling-edit__value">
              <SelectControl
                value={product.predictionId}
                multi={false}
                clearable={false}
                options={predictionOptions}
                onChange={this.onChangePrediction}
                className={product.predictionId ? '' : 'financing-profiling-edit__invalid'}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="financing-profiling-edit__label">
              <FormattedMessage id="financingProfiling_edit_limit"/>
            </TableCell>
            <TableCell className="financing-profiling-edit__value">
              <div className="financing-profiling-edit__limit">
                <InputControl
                  type="number"
                  value={product.limitStart !== undefined ? product.limitStart : ''}
                  onChange={this.onChangeLimitStart}
                  className={Number.isFinite(product.limitStart) ? '' : 'financing-profiling-edit__invalid'}
                />
                &mdash;
                <InputControl
                  type="number"
                  value={product.limitEnd !== undefined ? product.limitEnd : ''}
                  onChange={this.onChangeLimitEnd}
                  className={Number.isFinite(product.limitEnd) ? '' : 'financing-profiling-edit__invalid'}
                />
              </div>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="financing-profiling-edit__label">
              <FormattedMessage id="financingProfiling_edit_product"/>
            </TableCell>
            <TableCell className="financing-profiling-edit__value">
              <SelectControl
                value={product.productId}
                multi={false}
                clearable={false}
                options={loanProductOptions}
                onChange={this.onChangeLoanProduct}
                className={product.productId ? '' : 'financing-profiling-edit__invalid'}
              />
            </TableCell>
          </TableRow>
        </Table>

        <Table className="financing-profiling-edit__durations">
          <TableHead className="financing-profiling-edit__durations-head">
            <TableCell>
              <FormattedMessage id="financingProfiling_edit_years"/>
            </TableCell>
            <TableCell>
              <FormattedMessage id="financingProfiling_edit_available"/>
            </TableCell>
            <TableCell>
              <FormattedMessage id="financingProfiling_edit_default"/>
            </TableCell>
          </TableHead>

          {durations.map((duration) => this.renderDuration(duration))}
        </Table>

        <div className="financing-profiling-edit__buttons">
          <Button
            onClick={this.handleSave}
            bsSize="small"
            bsStyle="primary"
            className="financing-profiling-edit__save"
            disabled={!this.isValid()}
          >
            <FormattedMessage id="common_save"/>
          </Button>
          <Button
            onClick={onCancel}
            bsSize="small"
          >
            <FormattedMessage id="common_close"/>
          </Button>
        </div>
      </div>
    );
  }

  renderDuration(loanDuration) {
    const { product } = this.state;
    const productDuration = product.durations.find((it) => it.id === loanDuration.id);
    const isAvailable = !!productDuration;
    const isDefault = productDuration ? productDuration.isDefault : false;

    return (
      <TableRow key={loanDuration.id}>
        <TableCell>
          {productDuration ? productDuration.durationInYears : loanDuration.durationInYears}
        </TableCell>
        <TableCell>
          <InputControl
            type="checkbox"
            checked={isAvailable}
            onChange={this.onChangeAvailable.bind(this, loanDuration)}
          />
        </TableCell>
        <TableCell>
          <InputControl
            type="checkbox"
            checked={isDefault}
            onChange={this.onChangeDefault.bind(this, loanDuration)}
          />
        </TableCell>
      </TableRow>
    );
  }

  onChangeProfile = (option) => this.setState(({ product }) => ({
    product: {
      ...product,
      profileId: option ? option.value : undefined,
    },
  }));

  onChangePrediction = (option) => this.setState(({ product }) => ({
    product: {
      ...product,
      predictionId: option ? option.value : undefined,
    },
  }));

  onChangeLoanProduct = (option) => this.setState(({ product }) => ({
    product: {
      ...product,
      productId: option ? option.value : undefined,
    },
  }));

  onChangeLimitStart = ({ target: { value } }) => this.setState(({ product }) => {
    const limit = Math.abs(parseInt(value, 10));
    return {
      product: {
        ...product,
        limitStart: Number.isFinite(limit) ? limit : undefined,
      },
    };
  });

  onChangeLimitEnd = ({ target: { value } }) => this.setState(({ product }) => {
    const limit = Math.abs(parseInt(value, 10));
    return {
      product: {
        ...product,
        limitEnd: Number.isFinite(limit) ? limit : undefined,
      },
    };
  });

  onChangeAvailable = (loanDuration) => this.setState(({ product }) => {
    const productDuration = product.durations.find((it) => it.id === loanDuration.id);
    const durations = productDuration
      ? product.durations.filter((it) => it.id !== productDuration.id)
      : [...product.durations, { ...loanDuration, isDefault: false }];

    return {
      product: {
        ...product,
        durations,
      },
    };
  });

  onChangeDefault = (loanDuration) => this.setState(({ product }) => {
    const productDuration = product.durations.find((it) => it.id === loanDuration.id);
    const updated = productDuration
      ? { ...productDuration, isDefault: !productDuration.isDefault }
      : { ...loanDuration, isDefault: true };
    const durations = productDuration
      ? product.durations.map((it) => it.id === productDuration.id ? updated : it)
      : [...product.durations, updated];

    return {
      product: {
        ...product,
        durations,
      },
    };
  });

  handleSave = () => {
    const { onSave } = this.props;
    const { product } = this.state;
    onSave(product);
  };

  isValid() {
    const { product } = this.state;
    if (
      product.profileId &&
      product.predictionId &&
      Number.isFinite(product.limitStart) &&
      Number.isFinite(product.limitEnd) &&
      product.productId &&
      product.durations.length > 0
    ) {
      return true;
    }

    return false;
  };
}

FinancingProfilingEdit.propTypes = {
  product: PropTypes.object.isRequired,
  profileOptions: PropTypes.array.isRequired,
  predictionOptions: PropTypes.array.isRequired,
  loanProductOptions: PropTypes.array.isRequired,
  durations: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

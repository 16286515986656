import moment from 'moment';
import { createSelector } from 'reselect';
import { has, get } from 'lodash';
import { convertDateToApp } from '../../utils/dateConverter';
import { getBulkLoadsAverageCompletionTime, getBulkLoadsResultsRaw } from './bulkLoadsCommonSelectors';
import { BULK_LOADS_STATES } from '../../constants/BulkLoadsConstants';

export const getBulkLoadsResults = createSelector([
    getBulkLoadsResultsRaw,
    getBulkLoadsAverageCompletionTime,
  ], (results, averageCompletionTime) =>
  results
    ? results.loads.map((item) => mapResultItem(item, results.actions, averageCompletionTime))
    : []
);

function mapResultItem(item, actions, averageCompletionTime) {
  const loadActions = actions
    .filter((action) => action.loadId === item.id)
    .map(mapResultItemAction);
  const leftTime = calculateLeftTime(item, averageCompletionTime);

  return {
    id: item.id,
    state: get(item, 'actionCount.error') > 0
      ? 'error'
      : item.state,
    open: get(item, 'workHistory.open')
      ? convertDateToApp(item.workHistory.open)
      : undefined,
    working: get(item, 'workHistory.working')
      ? convertDateToApp(item.workHistory.working)
      : undefined,
    done: get(item, 'workHistory.done')
      ? convertDateToApp(item.workHistory.done)
      : undefined,
    count: get(item, 'actionCount.total'),

    seconds: leftTime ? leftTime.seconds : undefined,
    percent: leftTime ? leftTime.percent : undefined,

    quality: get(item, 'actionCount.error') > 0
      ? 'error'
      : item.state,

    metrics: item.metrics,
    actions: loadActions,
    actionCount: item.actionCount,
  };
}

function calculateLeftTime(item, averageCompletionTime) {
  if (item.state === BULK_LOADS_STATES.OPEN) {
    return undefined;
  }

  if (item.state !== BULK_LOADS_STATES.ACTIVE) {
    return {
      seconds: 0,
      percent: 100,
    };
  }

  if (!item.workHistory.working) {
    return undefined;
  }

  const workingTime = convertDateToApp(get(item, 'workHistory.working'));
  const secondsWorking = moment().diff(workingTime, 'seconds');
  const secondsExpected = Math.round(get(item, 'actionCount.open') * averageCompletionTime);
  const percent = Math.round((get(item, 'actionCount.done ') / get(item, 'actionCount.total')) * 100);
  const seconds = secondsExpected - secondsWorking;

  return {
    seconds: seconds,
    percent: percent === 100 && seconds < 0 ? 99 : percent,
  };
}

function mapResultItemAction(action) {
  return {
    ...action,
    start: get(action, 'workHistory.working')
      ? convertDateToApp(get(action, 'workHistory.working'))
      : undefined,
    end: get(action, 'workHistory.done')
      ? convertDateToApp(get(action, 'workHistory.done'))
      : undefined,
    label: has(action.parameters, 'loadConfigObject') ? `${action.parameters.loadConfigObject}: ${action.id}` :  has(action.parameters, 'source')
      ? `${action.parameters.source}: ${action.id}`
      : `${action.id}`
  };
}

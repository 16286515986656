import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import NumberFormat from 'react-number-format';

class CriteriaNumber extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || ''
    };

    this.onValueChange = this.onValueChange.bind(this);
  }

  onValueChange(values) {
    const { source, onChange } = this.props;
    const { value, floatValue } = values;

    this.setState({ value });
    onChange({
      source: _.get(source, 'props.id'),
      path: `${_.get(source, 'props.id')}`,
      values: [floatValue]
    });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { value } = this.state;
    const { edit } = this.props;

    return (edit)
      ? (
        <React.Fragment>
          <strong>Wert</strong>

          <NumberFormat
            thousandSeparator="'"
            decimalSeparator="."
            decimalScale={2}
            prefix={null}
            value={value}
            onValueChange={this.onValueChange}
            isNumericString={true}
          />
        </React.Fragment>
      )
      : (
        <span>{value}</span>
      );
  }
}

CriteriaNumber.propTypes = {
  source: PropTypes.object.isRequired,
  criteria: PropTypes.object,
  onChange: PropTypes.func,
  edit: PropTypes.bool,
  operation: PropTypes.string
};

CriteriaNumber.defaultProps = {
  criteria: {},
  onChange: _.noop,
  edit: false,
  operation: 'eq'
};

export default CriteriaNumber;

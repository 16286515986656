import { createSelector } from 'reselect';
import { get } from 'lodash';

import { EndpointName } from '../constants/EndpointName';

export const getLoginState = (state) => state.login;
export const getConfigState = (state) => state.config;
export const getCommonState = (state) => state.common;

export const getSessionState = createSelector([
  getLoginState,
], (loginState) => loginState.session);

export const getEnvironment = (state) => state.environment.config.data;

export const getBaseUrl = createSelector([
  getEnvironment,
], (envState) => envState.api_url);

export const getTenant = createSelector([
  getEnvironment,
], (envState) => envState.tenant);

export const getLanguage = createSelector([
  getLoginState,
], (loginState) => loginState.language);

export const getI18nMessages = createSelector([
  getConfigState,
], (state) => state.i18nMessages);

export const getTranslations = createSelector([
  getLanguage,
  getI18nMessages,
], (locale, i18nMessages) => i18nMessages[locale] || {});

export const getToken = createSelector([
  getSessionState,
], (sessionState) => get(sessionState, 'token'));

export const getDirectFileLink = createSelector([
  getBaseUrl,
  getToken,
], (baseUrl, token) => (fileId) => {
  return `${baseUrl}-dta-${token}${EndpointName.FILE}/${fileId}`;
});

export const getCommonSearch = createSelector([
  getCommonState,
], (state) => state.search);

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop, isNil } from 'lodash';
import { TextInput, ValidationResult } from '@evoja-web/react-form';

import Label from './Label';

const FormFieldCustomJmesInput = React.memo(function FormFieldCustomJmesInput({
  id,
  onChange,
  showLabel,
  prefix,
  validations,
  value
}) {
  const onValueChange = useCallback((key, value) => {
    const v = isNil(value)
      ? value
      : `${prefix}${value}`;

    onChange(id, v);
  }, [id, onChange, prefix]);

  const v = isNil(value)
    ? value
    : value.replace(prefix, '');

  return (
    <div className="workflow-tree-form-element">
      {showLabel && (
        <Label id={id} />
      )}

      <div className="workflow-tree-form-element--component">
        <TextInput
          id={id}
          name={id}
          onChange={onValueChange}
          value={v}
        />
      </div>

      <ValidationResult
        show
        validations={validations}
      />
    </div>
  );
});

FormFieldCustomJmesInput.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  showLabel: PropTypes.bool,
  validations: PropTypes.object,
  value: PropTypes.string
};

FormFieldCustomJmesInput.defaultProps = {
  id: 'customJmesInput',
  onChange: noop,
  prefix: '',
  showLabel: true,
  validations: {},
  value: undefined
};

export default FormFieldCustomJmesInput;

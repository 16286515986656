import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import JobProcessingLoadCard from './JobProcessingLoadCard'
import { get } from 'lodash';

const DefinitionJobs = props => {
  const {
    definitions,
    jobProcessingActions,
    language
  } = props;

  useEffect(() => {
    jobProcessingActions.definitionsRequest();
  }, []);

  return (
    <div className='card-container'>
      {get(definitions, 'data', []).map((load, i) => {
        return (
          <JobProcessingLoadCard
            key={i}
            action={load.action}
            condition={load.condition}
            id={load.id}
            name={load.name}
            periodicity={get(load,'periodicity')}
            source={load.source}
            type={load.type}
            interactive={get(load,'interactive','')}
            language={language}
          />
        )
      })}
    </div>
  );
};

DefinitionJobs.propTypes = {
  definitions: PropTypes.object,
  jobProcessingActions: PropTypes.object.isRequired,
  language: PropTypes.string
};

DefinitionJobs.defaultProps = {
  definitions: {},
  language: 'de'
}

export default DefinitionJobs;
import './FinancingProfilingProduct.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ModalBody, ModalDialog, ModalHeader } from '../Common/Modal';
import { FinancingProfilingEdit } from './FinancingProfilingEdit';

export class FinancingProfilingProduct extends React.PureComponent {
  state = {
    isModalOpen: false,
  };

  render() {
    const { product } = this.props;
    return (
      <div className="financing-profiling-product">
        <div className="financing-profiling-product__header">
          <span>{product.productTitle}</span>
          <span>
            <span className="financing-profiling-product__link"
                  onClick={this.openModal}>
              <i className="icon-025-edit"/>
            </span>
              <span className="financing-profiling-product__link"
                    onClick={this.handleRemove}>
              <i className="icon-008-bin"/>
            </span>
            {this.renderModal()}
          </span>
        </div>
        <div className="financing-profiling-product__durations">
          {this.renderDurations()} <FormattedMessage id="financingProfiling_product_years"/>
        </div>
      </div>
    );
  }

  renderModal() {
    const { isModalOpen } = this.state;
    const { product, durations, loanProductOptions, predictionOptions, profileOptions } = this.props;

    return (
      <ModalDialog show={isModalOpen} onHide={this.closeModal}>
        <ModalHeader closeButton={true}>
          <Modal.Title>
            <div className="financing-profiling-product__modal-title">
              <FormattedMessage id="financingProfiling_edit_title"/>
            </div>
          </Modal.Title>
        </ModalHeader>
        <ModalBody>
          <FinancingProfilingEdit
            product={product}
            profileOptions={profileOptions}
            predictionOptions={predictionOptions}
            loanProductOptions={loanProductOptions}
            durations={durations}
            onCancel={this.closeModal}
            onSave={this.handleSave}
          />
        </ModalBody>
      </ModalDialog>
    );
  }

  renderDurations() {
    const { product } = this.props;
    return product.durations.map((duration) => {
      return `${duration.durationInYears}${duration.isDefault ? '*' : ''}`;
    }).join(', ');
  }

  openModal = () => this.setState({ isModalOpen: true });
  closeModal = () => this.setState({ isModalOpen: false });
  handleRemove = () => {
    const { onRemove, product } = this.props;
    onRemove(product);
  };
  handleSave = (product) => {
    const { onChange } = this.props;
    onChange(product);
    this.closeModal();
  };
}

FinancingProfilingProduct.propTypes = {
  product: PropTypes.object.isRequired,
  profileOptions: PropTypes.array.isRequired,
  predictionOptions: PropTypes.array.isRequired,
  loanProductOptions: PropTypes.array.isRequired,
  durations: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

import { createSelector } from 'reselect';

export const getHolidaysState = (state) => state.holidays;
export const getHolidaysError = createSelector([getHolidaysState], (state) => state.error);
export const getHolidaysLoading = createSelector([getHolidaysState], (state) => state.loading);
export const getHolidays = createSelector([getHolidaysState], (state) => state.holidaysLists);
export const getHolidaysInitial = createSelector([getHolidaysState], (state) => state.initialData);
export const getHolidaysViewMode = createSelector([getHolidaysState], (state) => state.viewMode);
export const getHolidaysYear = createSelector([getHolidaysState], (state) => state.year);

const getPathname = () => window.location.pathname;
const getHolidaysListId = createSelector([getPathname], (pathname) => pathname.replace('/holidays/', ''));

export const getHolidaysList = createSelector([
  getHolidaysState,
  getHolidaysListId,
], (state, listId) => state.holidaysLists.find((it) => it.id === listId));

import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get, omit } from 'lodash';

import triggerJobAction from '../../actions/JobProcessing/TriggerJob';

const {
  TRIGGER_JOB_PENDING,
  TRIGGER_JOB_FULFILLED,
  TRIGGER_JOB_REJECTED
} = triggerJobAction;

export default function* triggerJob(request) {
  yield put({ type: TRIGGER_JOB_PENDING });

  try {
    const {
      executionTime,
      replayDates,
      selectedJob
    } = request;

    const interactive = get(replayDates, 'length', 0) > 0
      ? {
        executionTime,
        parameters: {
          replayDates,
        }
      }
      : { executionTime };

    const data = omit({
      type: selectedJob,
      interactive
    }, ['id']);

    const result = yield call(axios, {
      url: '/core/job-processing/action',
      method: 'post',
      data
    });

    const payload = {
      data,
      result
    };

    yield put({ type: TRIGGER_JOB_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: TRIGGER_JOB_REJECTED, error });

    return error;
  }
}

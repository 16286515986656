import './HolidaysModeMonth.css';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { HolidaysCalendarDay } from './HolidaysCalendarDay';
import { CalendarMonth } from '../Common/CalendarMonth';
import { ANALYTICS_PROP_TYPES_LOCALE } from '../Analytics/AnalyticsPropTypes';
import { isHoliday } from '../../utils/holidayUtils';

export class HolidaysModeMonth extends React.Component {
  constructor(props) {
    super(props);
    this.renderDay = this.renderDay.bind(this);
  }

  render() {
    const { month, year, reset } = this.props;
    const months = moment.months();
    return (
      <div className="holidays-mode-month">
        <div className="holidays-mode-month__title">
          <span className="holidays-mode-month__title-text">{months[month]}</span>
          <span className="holidays-mode-month__close" onClick={reset}>&times;</span>
        </div>
        <CalendarMonth
          year={year}
          month={month}
          renderDay={this.renderDay}
        />
      </div>
    );
  }

  renderDay(day) {
    const { create, edit, remove, month, year, holidays, locale } = this.props;
    const date = moment([year, month, day]);
    const holidaysList = holidays.filter((it) => isHoliday(it, date));

    return (
      <HolidaysCalendarDay
        locale={locale}
        title={day}
        holidays={holidaysList}
        date={date}
        create={create}
        edit={edit}
        remove={remove}
      />
    );
  }
}

HolidaysModeMonth.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  holidays: PropTypes.array,
  reset: PropTypes.func,
  edit: PropTypes.func,
  remove: PropTypes.func,
  create: PropTypes.func,
  locale: ANALYTICS_PROP_TYPES_LOCALE.isRequired,
};

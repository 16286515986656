import { all, put } from 'redux-saga/effects';
import {
  LEADS_SWITCH_IMPORT_IMPORT,
} from '../../actions/LeadsSwitchImportActions';
import axios from 'axios';
import { EndpointName } from '../../constants/EndpointName';
import { PARALLEL_REQUESTS_COUNT } from '../../constants/LeadsSwitchImport';


export function* leadsSwitchImportImportSaga(action) {

  try {
    yield put(LEADS_SWITCH_IMPORT_IMPORT.pending());

    const { rows } = action.data;

    // fetching leads and added to rows
    let successFetchingLeads = 0;
    let failFetchingLeads = 0;
    let fetchedLeadsCount = 0;

    yield put(LEADS_SWITCH_IMPORT_IMPORT.progress({
      success: successFetchingLeads,
      fail: failFetchingLeads,
      action: 'FetchingLeads',
      total: rows.length,
      fetchedLeadsCount,
    }));

    const rowsCountFetchingLeads = rows.length;
    const iterationCountFetchingLeads = Math.ceil(rowsCountFetchingLeads / PARALLEL_REQUESTS_COUNT);
    for (let i = 0; i < iterationCountFetchingLeads; i++) {
      const start = i * PARALLEL_REQUESTS_COUNT;
      const processRows = rows.slice(start, start + PARALLEL_REQUESTS_COUNT);

      yield all(
        // eslint-disable-next-line
        processRows.map(row => new Promise(resolve => {
          axios({
            url: `${EndpointName.WORK_LEAD_MANAGEMENT}/` +
            `?and(eq(customerId,string:${row.customer.id}),` +
            `eq(staffMemberId,string:${row.consultant.id}),` +
            `out(customerTaskStatus.number,(string:52,string:53,string:55,string:50,string:2)))`,
            method: 'get',
          }).then(response => {
            row.leads = response.data;
            fetchedLeadsCount += row.leads.length;
            successFetchingLeads += 1;
            resolve(response);
          }).catch(error => {
            console.error(error);
            failFetchingLeads += 1;
            resolve(error);
          });
        }))
      );

      yield put(LEADS_SWITCH_IMPORT_IMPORT.progress({
        success: successFetchingLeads,
        fail: failFetchingLeads,
        action: 'FetchingLeads',
        total: rows.length,
        fetchedLeadsCount,
      }));
    }

    // preparing leads array
    const leads = [];
    rows.forEach(row =>
      row.leads.forEach(lead =>
        leads.push({
          id: lead.id,
          oldStaffMemberId: lead.staffMemberId,
          staffMemberId: row.consultantNew.id,
          additionalEditor: lead.additionalEditor
        })
      )
    );
    if (!leads.length) {
      // eslint-disable-next-line
      throw [{ status: 'LEADS_NOT_FOUND'}];
    }

    // patching staffMemberId for each lead
    let successPatchingLeads = 0;
    let failPatchingLeads = 0;
    const rowsCountPatchingLeads = leads.length;
    const iterationCountPatchingLeads = Math.ceil(rowsCountPatchingLeads / PARALLEL_REQUESTS_COUNT);
    for (let i = 0; i < iterationCountPatchingLeads; i++) {
      const start = i * PARALLEL_REQUESTS_COUNT;
      const processLeads = leads.slice(start, start + PARALLEL_REQUESTS_COUNT);

      yield all(
        // eslint-disable-next-line
        processLeads.map(lead => new Promise(resolve => {
          const patchdata =
          lead.additionalEditor === lead.oldStaffMemberId
            ? [
                { op: 'replace', path: '/staffMemberId', value: lead.staffMemberId },
                { op: 'replace', path: '/additionalEditor', value: lead.staffMemberId },
              ]
            : [{ op: 'replace', path: '/staffMemberId', value: lead.staffMemberId }];
          axios({
            url: `${EndpointName.WORK_LEAD_MANAGEMENT}/${lead.id}`,
            method: 'patch',
            data: patchdata,
          }).then(response => {
            successPatchingLeads += 1;
            resolve(response);
          }).catch(error => {
            console.error(error);
            failPatchingLeads += 1;
            resolve(error);
          });

        }))
      );

      yield put(LEADS_SWITCH_IMPORT_IMPORT.progress({
        success: successPatchingLeads,
        fail: failPatchingLeads,
        action: 'PatchingLeads',
        total: leads.length,
      }));
    }

    yield put(LEADS_SWITCH_IMPORT_IMPORT.success({
      success: successPatchingLeads,
      fail: failPatchingLeads,
      action: 'PatchingLeads',
      total: leads.length,
    }));
  } catch (errors) {
    console.error(errors);
    yield put(LEADS_SWITCH_IMPORT_IMPORT.failure(errors));
  }
}

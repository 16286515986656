import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { NETTED_ACCOUNTS_WORKGUIDES_LOAD } from '../../actions/WorkguideStatusActions';

export function* workguideStatusGetNettedAccountsWorkguidesSaga() {
  try {
    yield put(NETTED_ACCOUNTS_WORKGUIDES_LOAD.pending());
    
    const { data: workguides, headers } = yield call(axios, { url: `${EndpointName.ANALYTICS_WORKGUIDE_NETTED_ACCOUNTS}` });

    const totalCount = headers['x-total-count']
      ? parseInt(headers['x-total-count'], 10)
      : workguides.length;

    yield put(NETTED_ACCOUNTS_WORKGUIDES_LOAD.success({ workguides, totalCount }));
  } catch (error) {
    yield put(NETTED_ACCOUNTS_WORKGUIDES_LOAD.failure(error));
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Virtuoso } from 'react-virtuoso'

import RecordListItem from './RecordListItem';

class ReplayRecordList extends React.Component {
  constructor(props) {
    super(props);

    this.renderItemContent = this.renderItemContent.bind(this);
  }

  renderItemContent(index) {
    const { records, replayed } = this.props;

    const record = get(records, index);

    const id = get(record, 'id');
    const item = get(replayed, id, {});
    const { error, fulfilled, requesting, data } = item;

    const className = {
      'replay-audit-log-record-list-item--errorneous': error,
      'replay-audit-log-record-list-item--fulfilled': fulfilled,
      'replay-audit-log-record-list-item--requesting': requesting
    }

    return (
      <RecordListItem
        className={className}
        key={get(record, 'id')}
        record={record}
        error={error}
        result={data}
      />
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { records } = this.props;

    return (
      <div className="replay-audit-log-record-list">
        <Virtuoso
          className="list-group"
          data={records}
          style={{ height: '1500px' }}
          totalCount={get(records, 'length', 0)}
          itemContent={this.renderItemContent}
        />
      </div>
    );
  }
}

ReplayRecordList.propTypes = {
  records: PropTypes.array,
  replayed: PropTypes.object
};

ReplayRecordList.defaultProps = {
  records: []
};

export default ReplayRecordList;

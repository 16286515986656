import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

export default function MyDropzone(props) {
  const style = {
    height: '100px',
    border: '1px dashed black',
    marginTop: '10px',
  };

  return (
    <Dropzone
      style={style}
      multiple
      onDrop={props.onDrop}
      accept={["application/pdf", "image/*"]}
    >
      <h1 className="text-center">
        <i className="icon-088-upload-cloud" />
      </h1>
      <p className="text-center">
        Neue Dateien hier her ziehen oder klicken um sie auszuwählen
      </p>
    </Dropzone>
  );
}

MyDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
};

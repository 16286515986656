import axios from 'axios';
import sjcl from 'sjcl';
import { call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { API_CLIENT_LOGIN } from '../../actions/ApiClientActions';
import { API_CLIENT_CONFIG } from '../../constants/ApiClientConfig';

export function* apiClientLoginSaga(action) {
  try {
    yield put(API_CLIENT_LOGIN.pending());

    const configIds = Object.values(API_CLIENT_CONFIG);
    const { data: config } = yield call(axios, { url: `${EndpointName.CORE_CONFIG}/?in(id,(${configIds.join(',')}))` });

    const salt = config.find((it) => it.id === API_CLIENT_CONFIG.salt);
    const originalPassword = config.find((it) => it.id === API_CLIENT_CONFIG.password);

    if (!salt || !originalPassword) {
      throw new Error(
        `Missing server configuration: "${API_CLIENT_CONFIG.salt}" or "${API_CLIENT_CONFIG.password}"`
      );
    }

    const { password } = action.data;
    const bitArray = sjcl.hash.sha256.hash(`${salt.value}${password}`);
    const isAuthorized = sjcl.codec.hex.fromBits(bitArray) === originalPassword.value;
    yield put(API_CLIENT_LOGIN.success({ isAuthorized }));
  } catch (error) {
    yield put(API_CLIENT_LOGIN.failure(error));
  }
}

import MaxStringLengthConfiguration from '../configuration/MaxStringLength';

export default {
  key: 'maxStringLength',
  label: 'Maximale länge der Eingabe' ,
  validation: 'maxStringLength',
  availableFor: ['Input'],
  ConfigurationForm: MaxStringLengthConfiguration,
  get: function(params) {
    return { maxStringLength: params };
  }
}

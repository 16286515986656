import axios from 'axios';
import { all, call, select, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { createRef } from '../../utils';
import { SAVE_PROMOTIONS_ACTIONS } from '../../actions/PromotionActions';
import { getPromotions, getApiPromotions, getUpdatedSections } from '../../selectors/Promotions';
import { getLanguage } from '../../selectors/commonSelectors';
import { isPromotionChanged, mapPromotionToApi } from '../../services/Promotions';
import { saveDocumentsSaga } from './saveDocumentsSaga';

export function* savePromotionsSaga(action) {
  try {
    const { promotion, sections } = action.data;
    yield put(SAVE_PROMOTIONS_ACTIONS.pending({promotion}));

    const language = yield select(getLanguage);
    const rawPromotions = yield select(getPromotions);
    const rawPromotion = rawPromotions.find((it) => promotion.id === it.id);
    const apiPromotions = yield select(getApiPromotions);
    const apiPromotion = apiPromotions.find((it) => promotion.id === it.id);
    const updatedPromotion = mapPromotionToApi(promotion, apiPromotion, language);

    if (!isPromotionChanged(promotion, rawPromotion)) {
      yield put(SAVE_PROMOTIONS_ACTIONS.success({
        apiPromotion: updatedPromotion,
        appPromotion: promotion,
        updatedSections: [],
      }));
      return;
    }

    yield call(axios, {
      url: createRef(EndpointName.PROMOTIONS, promotion.id),
      method: 'put',
      data: updatedPromotion,
    });

    const getUpdatedSectionsService = yield select(getUpdatedSections);
    const updatedSections = getUpdatedSectionsService(sections, rawPromotion);
    if (updatedSections.length) {
      yield all(
        updatedSections.map((section) => call(axios, {
          url: createRef(EndpointName.CHECKLIST_SECTION, section.id),
          method: 'put',
          data: section,
        })),
      );
    }

    yield call(saveDocumentsSaga, promotion, rawPromotion);

    yield put(SAVE_PROMOTIONS_ACTIONS.success({
      apiPromotion: updatedPromotion,
      appPromotion: promotion,
      updatedSections: updatedSections,
    }));
  } catch(error) {
    const { promotion } = action.data;
    yield put(SAVE_PROMOTIONS_ACTIONS.failure(error, {promotion}));
  }
}

import { createSelector } from 'reselect';

export const getEmailSubscriptionsState = (state) => state.emailSubscriptions;
export const getEmailSubscriptionsError = createSelector([getEmailSubscriptionsState], (state) => state.error);
export const getEmailSubscriptionsLoading = createSelector([getEmailSubscriptionsState], (state) => state.loading);
export const getEmailSubscriptionsSearchParams = createSelector([getEmailSubscriptionsState], (state) => state.searchParams);
export const getEmailSubscriptionsItemsRaw = createSelector([getEmailSubscriptionsState], (state) => state.items);
export const getEmailSubscriptionsItemsCount = createSelector([getEmailSubscriptionsState], (state) => state.totalCount);
export const getEmailSubscriptionsEntityCodes = createSelector([getEmailSubscriptionsState], (state) => state.entityCodes);
export const getDeletedMarketingRecords = createSelector([getEmailSubscriptionsState], (state) => state.deletedResult);
export const getCsvUploadResult = createSelector([getEmailSubscriptionsState], (state) => state.deletedResult);
import uuid from 'uuid';
import { call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { UI_NOTES_IMAGE_UPLOAD_ACTIONS } from '../../actions/UiNotesActions';
import { createRef } from '../../utils';

export function* uiNotesImageUploadSaga({ data: { file } }) {
  if (!file) {
    yield put(UI_NOTES_IMAGE_UPLOAD_ACTIONS.success(null));
    return;
  }

  try {
    yield put(UI_NOTES_IMAGE_UPLOAD_ACTIONS.pending());

    const $ref = yield call(
      createRef,
      EndpointName.FILE,
      yield call(uuid.v4),
    );

    yield put(UI_NOTES_IMAGE_UPLOAD_ACTIONS.success({ $ref, file }));
  } catch (error) {
    yield put(UI_NOTES_IMAGE_UPLOAD_ACTIONS.failure(error));
  }
}

export const CODE_GROUPS = [
  'orderOrigin',
  'customerNoteTopic',
  'customerNoteType',
  'noteQuality',
  'cardType',
  'vabSpecialProduct',
  'contractType',
  'currencyIso',
  'productGroup',
  'initiative',
];

export const PARALLEL_REQUESTS_COUNT = 100;

export const LONG_RESPONSE_ITEM_COUNT = 1000;

export const LONG_REQUEST_ITEM_COUNT = 300;

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const VALIDATION_DATE_FORMATS = ['DD.MM.YYYY HH:mm:ss', 'DD.MM.YYYY'];

export const NOTICE_EXAMPLE_TEMPLATE = 'notice-upload-template';
export const NOTICE_EXAMPLE_DOCUMENT = 'notice-upload-document';

import axios from 'axios';
import { all, call, select, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { createRef } from '../../utils';
import { REMOVE_PROMOTIONS_ACTIONS } from '../../actions/PromotionActions';
import { getSections } from '../../selectors/Promotions';

export function* removePromotionsSaga(action) {
  try {
    const { promotion } = action.data;
    yield put(REMOVE_PROMOTIONS_ACTIONS.pending({promotion}));

    yield all(
      getRemovedFileIds(promotion).map((fileId) => call(axios, {
        url: createRef(EndpointName.FILE, fileId),
        method: 'delete',
      }))
    );

    const sections = yield select(getSections);
    const updatedSections = getUpdatedChecklistSections(promotion, sections);
    yield all(
      updatedSections.map((section) => call(axios, {
        url: createRef(EndpointName.CHECKLIST_SECTION, section.id),
        method: 'put',
        data: section,
      }))
    );

    yield call(axios, {
      url: createRef(EndpointName.PROMOTIONS, promotion.id),
      method: 'delete',
    });

    yield put(REMOVE_PROMOTIONS_ACTIONS.success({promotion, updatedSections}));
  } catch(error) {
    yield put(REMOVE_PROMOTIONS_ACTIONS.failure(error));
  }
}

function getRemovedFileIds(promotion) {
  return [
    promotion.image ? promotion.image.id : undefined,
    promotion.teaserImage ? promotion.teaserImage.id : undefined,
    ...promotion.document.map((document) => document.id),
  ].filter(Boolean);
}

function getUpdatedChecklistSections(promotion, sections) {
  return sections.map((section) => ({
    ...section,
    items: section.items.filter((item) => !promotion.checklistItem.includes(item.id))
  }));
}

import PropTypes from 'prop-types';

export const REF_OBJECT_PROP_TYPES = PropTypes.shape({
  $ref: PropTypes.string,
});

export const DURATION_PROP_TYPES = PropTypes.shape({
  id: PropTypes.string,
  productType: PropTypes.string,
  refiRate: REF_OBJECT_PROP_TYPES,
  mortgageType: PropTypes.number,
  durationInYear: PropTypes.number,
});

export const BASE_RATE_PROP_TYPES = PropTypes.shape({
  id: PropTypes.string,
  designation: PropTypes.string,
  baseRate: PropTypes.string,
  duration: REF_OBJECT_PROP_TYPES,
});

export const REFI_RATE_PROP_TYPES = PropTypes.shape({
  id: PropTypes.string,
  interestRate: PropTypes.string,
});

export const FORWARD_PROP_TYPES = PropTypes.shape({
  id: PropTypes.string,
  forwardRate: PropTypes.string,
  duration: REF_OBJECT_PROP_TYPES,
  forwardFrom: PropTypes.number,
  forwardUntil: PropTypes.number,
});

export const FORWARD_PERIOD_PROP_TYPES = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.shape({
    forwardFrom: PropTypes.number,
    forwardUntil: PropTypes.number,
  }),
});

import { takeLatest } from 'redux-saga/effects';
import { takeLatestByDataKey } from '@evoja-web/redux-saga-utils';

import actions from '../actions/Actions';
import getServices from './Api/getServices';
import getSchema from './Api/getSchema';

const {
  SERVICES_REQUEST,
  SCHEMA_REQUEST
} = actions;

export default function* workguideRootSaga() {
  yield takeLatest(SERVICES_REQUEST, getServices);
  yield takeLatestByDataKey(SCHEMA_REQUEST, getSchema);
}

export const GET = 'adminUI/leadSwitch/GET';
export const GET_CONSULTANTS = 'adminUI/leadSwitch/GET_CONSULTANTS';
export const PUT = 'adminUI/leadSwitch/PUT';
export const SET_TRANSFER = 'adminUI/leadSwitch/SET_TRANSFER';
export const SET_TARGET = 'adminUI/leadSwitch/SET_TARGET';
export const REQUEST = 'adminUI/leadSwitch/REQUEST';

export const leadSwitchRequest = ({
  staffMemberId = false,
  leadIds = false,
  staffMemberIdTo = false,
  consultantId,
  leadSwitchType
} = {}) => {
  return { type: REQUEST, staffMemberId, leadIds, staffMemberIdTo, consultantId, leadSwitchType };
};

export const setTransfer = ({ leadId, transferState }) => {
  return dispatch =>
    dispatch({
      type: SET_TRANSFER,
      leadId,
      transferState,
    });
}

export const setTarget = ({ staffMemberId }) => {
  return dispatch =>
    dispatch({
      type: SET_TARGET,
      staffMemberId
    });
}
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ControlLabel, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import { get, isString, noop, isUndefined } from 'lodash';

const Addkey = React.memo(({ data, onSubmit, onCancel }) => {
  const [value, setValue] = useState();

  const currentKeys = get(data, 'mapping', []).map((m) => get(m, 'key'));
  const isValid = !isUndefined(value) && !currentKeys.includes(value);

  function onChange(ev) {
    let updated = get(ev, 'target.value', '');

    if (isString(updated) && updated.trim().length === 0) updated = undefined;

    setValue(updated);
  }

  return (
    <div>
      <FormGroup>
        <ControlLabel>
          KEY
        </ControlLabel>

        <InputGroup>
          <FormControl
            id="key"
            name="key"
            value={value}
            onChange={onChange}
          />
          <InputGroup.Addon>
            <span className="mdi mdi-check-outline" style={{ color: isValid ? 'green' : 'red' }} />
          </InputGroup.Addon>
        </InputGroup>
      </FormGroup>

      <div style={{ width: '100%', textAlign: 'right', paddingTop: '10px' }}>
        <Button
          bsStyle="primary"
          onClick={() => onSubmit(value)}
          disabled={!isValid}
        >
          Hinzufügen
        </Button>

        <span style={{ paddingRight: '5px' }} />

        <Button onClick={onCancel}>
          Abbrechen
        </Button>
      </div>
    </div>
  );
});

Addkey.propTypes = {
  data: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

Addkey.defaultProps = {
  data: {},
  onCancel: noop,
  onSubmit: noop
};

export default Addkey;

import React from 'react';
import PropTypes from 'prop-types';
import { get, debounce } from 'lodash';
import { Col, FormGroup, FormControl, ControlLabel, Tabs, Tab } from 'react-bootstrap';
import update from 'immutability-helper';

import DetailComponents from '../DetailComponents';

class FieldGroup extends React.Component {
  constructor(props) {
    super(props);

    const { item, editValueField } = props;

    this.state = {
      activeTab: 'de',
      colTitles: get(item, 'props.colTitles', [{}, {}])
    };

    this.onComponentAdd = this.onComponentAdd.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.debouncedEditValueField = debounce(editValueField, 500);
    this.setActiveTab = this.setActiveTab.bind(this);
  }

  onComponentAdd() {
    const { addFieldGroupComponent, item } = this.props;

    addFieldGroupComponent(get(item, 'props.id'));
  }

  /**
   * Handle change of one of the text field (title/description)
   *
   * @param  {String} field   Field name
   * @param  {String} lanuage Language
   * @param  {Object} ev      Event from input
   *
   * @return void
   */
  onTitleChange(col, language, value) {
    const { id } = this.props;
    const current = get(this, `state.colTitles`, []);
    const updated = update(current, { [col]: { [language]: { $set: value } } });
    this.setState({ colTitles: updated });

    this.debouncedEditValueField(id, 'colTitles', updated);
  }

  /**
   * Set current active tab
   *
   * @param {String} language Selected language
   */
  setActiveTab(language) {
    this.setState({ activeTab: language });
  }

  /**
   * Render tabs for col titles
   *
   * @return {Array} tabs Array of tab elements
   */
  renderTabs(col) {
    const { languages } = this.props;

    const tabs = languages.map(l => (
      <Tab key={l} eventKey={l} title={l}>
        <ControlLabel style={{ textAlign: 'left' }}>
          Titel
        </ControlLabel>
        <FormControl
          type="text"
          value={get(this, `state.colTitles.${col}.${l}`, '')}
          onChange={(ev) => this.onTitleChange(col, l, get(ev, 'target.value'))}
        />
      </Tab>
    ));

    return tabs;
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { active } = this.state;
    const {
      codeGroups,
      codes,
      consultants,
      delComponent,
      dropHandler,
      editValueField,
      fetchCodes,
      language,
      languages,
      items,
      productGroups,
      item,
      ...rest
    } = this.props;

    return(
      <div className="worguide-field-group">
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Spaltentitel Links
          </Col>

          <Col sm={9}>
            <Tabs
              id="action-title"
              activeKey={active}
              onSelect={this.setActiveTab}
            >
              {this.renderTabs(0)}
            </Tabs>
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Spaltentitel Rechts
          </Col>

          <Col sm={9}>
            <Tabs
              id="action-title"
              activeKey={active}
              onSelect={this.setActiveTab}
            >
              {this.renderTabs(1)}
            </Tabs>
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>

          </Col>

          <Col sm={9}>
            <DetailComponents
              {...rest}
              addComponent={this.onComponentAdd}
              codeGroups={codeGroups}
              codes={codes}
              consultants={consultants}
              delComponent={delComponent}
              editValueField={editValueField}
              fetchCodes={fetchCodes}
              fieldGroup={get(item, 'props.id')}
              helperClass="SortableHOCHelper"
              items={items}
              language={language}
              onSortEnd={dropHandler}
              productGroups={productGroups}
              useDragHandle
              useWindowAsScrollContainer
            />
          </Col>
        </FormGroup>
      </div>
    );
  }
}

FieldGroup.propTypes = {
  languages: PropTypes.array
};

FieldGroup.defaultProps = {
  languages: ['de', 'fr', 'en']
};

export default FieldGroup;

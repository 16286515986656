import axios from 'axios';
import update from 'immutability-helper';

export const INIT = 'adminUI/financingSurchargeLendingrate/INIT';
export const ALL = 'adminUI/financingSurchargeLendingrate/ALL';
export const GET = 'adminUI/financingSurchargeLendingrate/GET';
export const POST = 'adminUI/financingSurchargeLendingrate/POST';
export const PUT = 'adminUI/financingSurchargeLendingrate/PUT';
export const DELETE = 'adminUI/financingSurchargeLendingrate/DELETE';
export const DELETE_ITEMS = 'adminUI/financingSurchargeLendingrate/DELETE_ITEMS';
export const SAVE_LIST = 'adminUI/financingSurchargeLendingrate/SAVE_LIST';
export const GET_SCHEMA = 'adminUI/financingSurchargeLendingrate/GET_SCHEMA';

export const ALL_DURATIONS = 'adminUI/financingSurchargeLendingrate/ALL_DURATIONS';
export const ALL_USETYPES = 'adminUI/financingSurchargeLendingrate/ALL_USETYPES';
export const ALL_LOANPRODUCTS = 'adminUI/financingSurchargeLendingrate/ALL_LOANPRODUCTS';
export const ADD_ITEM = 'adminUI/financingSurchargeLendingrate/ADD_ITEM';
export const UPDATE_ITEM = 'adminUI/financingSurchargeLendingrate/UPDATE_ITEM';
export const REMOVE_ITEM = 'adminUI/financingSurchargeLendingrate/REMOVE_ITEM';

/**
 * Get all entries from backend
 *
 * @return {Function}
 */
export function getAll() {
  return dispatch => dispatch({
    type: ALL,
    payload: axios({
      url: '/financing/surcharge-lendingrate/?limit(1000)',
    }),
  });
}

/**
 * Get an entry by id
 *
 * @param  {String} id Id
 *
 * @return {Function}
 */
export function get(id) {
  return dispatch => dispatch({
    type: GET,
    meta: { id },
    payload: axios({
      url: `/financing/surcharge-lendingrate/${id}`,
    }),
  });
}

/**
 * Create a new entry
 *
 * @param  {Object} data Data
 *
 * @return {Function}
 */
export function post(data) {
  return dispatch => dispatch({
    type: POST,
    payload: axios({
      url: '/financing/surcharge-lendingrate/',
      method: 'post',
      data,
    }),
  });
}

/**
 * Update the given item
 *
 * @param  {String} id   Id
 * @param  {Object} data Data
 *
 * @return {Function}
 */
export function put(id, data) {
  return dispatch => dispatch({
    type: PUT,
    payload: axios({
      url: `/financing/surcharge-lendingrate/${id}`,
      method: 'put',
      data,
    }),
  });
}

/**
 * Remove the given item from backend
 *
 * @param  {String} id Id
 *
 * @return {Function}
 */
export function remove(id) {
  return dispatch => dispatch({
    type: DELETE,
    meta: { id },
    payload: axios({
      url: `/financing/surcharge-lendingrate/${id}`,
      method: 'delete',
    }),
  });
}

/**
 * Save new/changes items and delete removed
 *
 * @return {Function}
 */
export function save() {
  return async (dispatch, getState) => {
    const { rates, removed } = getState().financingSurchargeLendingrate;

    dispatch({ type: `${SAVE_LIST}_PENDING` });

    try {
      const items = rates.filter(rate => rate.changed)
        .map((item) => {
          let updated = { ...item };
          // Remove unused properties
          updated = update(updated, { $unset: ['changed', 'isNew', 'validation'] });

          // rate, lendingRateStart and lendingRateEnd are stored
          // as strings but the api expects floats -> convert
          ['lendingRateStart', 'lendingRateEnd', 'rate'].forEach((field) => {
            if (updated[field]) {
              updated = update(updated, { [field]: { $set: parseFloat(item[field]) } });
            }
          });

          // Rate must be a string. Convert it...
          updated = update(updated, { rate: { $set: updated.rate.toString() } });

          // Write $refs
          if (updated.realEstateUseType) {
            updated = update(updated, { realEstateUseType: { $set: { $ref: `/entity/code/${updated.realEstateUseType.id}` } } });
          }
          if (updated.duration) {
            updated = update(updated, { duration: { $set: { $ref: `/financing/duration/${updated.duration.id}` } } });
          }

          return updated;
        });

      await Promise.all(items.map(item => dispatch(put(item.id, item))));
      await Promise.all(items.map(item => dispatch(get(item.id))));

      await dispatch({
        type: DELETE_ITEMS,
        payload: Promise.all(removed.map(item => dispatch(remove(item.id)))),
      });
    } catch (err) {
      return dispatch({ type: `${SAVE_LIST}_REJECTED`, payload: { data: err } });
    }

    return dispatch({ type: `${SAVE_LIST}_FULFILLED` });
  };
}

/**
 * Get schema from api
 *
 * @return {Function}
 */
export function getSchema() {
  return dispatch => dispatch({
    type: GET_SCHEMA,
    payload: axios({
      url: 'schema/financing/surcharge-lendingrate/collection',
    }),
  });
}

/**
 * Get all durations from backend
 *
 * @return {Function}
 */
export function getAllDurations() {
  return dispatch => dispatch({
    type: ALL_DURATIONS,
    payload: axios({
      url: '/financing/duration/?limit(1000)',
    }),
  });
}

/**
 * Get all useTypes from backend
 *
 * @return {Function}
 */
export function getAllUseTypes() {
  return dispatch => dispatch({
    type: ALL_USETYPES,
    payload: axios({
      url: '/entity/code/?eq(group,realEstateUseType)&limit(1000)',
    }),
  });
}

/**
 * Get all loan products from backend
 *
 * @return {Function}
 */
export function getAllLoanProducts() {
  return dispatch => dispatch({
    type: ALL_LOANPRODUCTS,
    payload: axios({
      url: '/financing/loanproduct/?limit(1000)',
    }),
  });
}

/**
 * Add a new item to the store
 *
 * @param {Object} item New item
 *
 * @return {Function}
 */
export function addItem(item) {
  return dispatch => dispatch({ type: ADD_ITEM, payload: item });
}

/**
 * Update the given item (current) with updated in store
 *
 * @param  {Object} current Current item in store
 * @param  {Object} updated Updated item
 *
 * @return {Function}
 */
export function updateItem(current, updated) {
  return dispatch => dispatch({ type: UPDATE_ITEM, payload: { current, updated } });
}

/**
 * Remove the given item from store
 *
 * @param  {String} id Item id
 *
 * @return {Function}
 */
export function removeItem(item) {
  return dispatch => dispatch({ type: REMOVE_ITEM, payload: item });
}

/**
 * Load all necessary data from api
 *
 * @return {Function}
 */
export function init() {
  return async (dispatch) => {
    dispatch({ type: `${INIT}_PENDING` });

    try {
      await Promise.all([
        dispatch(getAllDurations()),
        dispatch(getAllUseTypes()),
        dispatch(getSchema()),
        dispatch(getAllLoanProducts()),
      ]);

      await dispatch(getAll());
    } catch (err) {
      return dispatch({ type: `${INIT}_REJECTED`, payload: { data: err } });
    }

    return dispatch({ type: `${INIT}_FULFILLED` });
  };
}

import React  from 'react';
import { Col, Row, FormGroup, Button, Form } from 'react-bootstrap';
import { FormInput, FormSelect } from '../FormControls';
import { Field, FieldArray, reduxForm } from 'redux-form';

function renderRow(index, onRemove, realestateOptions) {
  return (
    <Row key={index}>
      <Col sm={3}>
        <Field component={FormInput}
               name={`items[${index}].id`}
               type="text"
               placeholder="ID"/>
      </Col>
      <Col sm={2}>
        <Field component={FormSelect}
               name={`items[${index}].realestatetype`}
               placeholder="Objekttyp"
               multi={false}
               clearable={false}
               options={realestateOptions}/>
      </Col>
      <Col sm={2}>
        <Field component={FormInput}
               name={`items[${index}].firstMortgageRate`}
               type="text"
               placeholder="Zuschlag 1. Hypothek"/>
      </Col>
      <Col sm={2}>
        <Field component={FormInput}
               name={`items[${index}].secondMortgageRate`}
               type="text"
               placeholder="Zuschlag 2. Hypothek"/>
      </Col>
      <Col sm={2}>
        <Field component={FormInput}
               name={`items[${index}].withAdditionRate`}
               type="text"
               placeholder="Zuschlag Überbelehnung"/>
      </Col>
      <Col sm={1}>
        <FormGroup>
          <Button onClick={onRemove}>
            <i className="icon-008-bin" style={{margin: '0'}}/>
          </Button>
        </FormGroup>
      </Col>
    </Row>
  );
}

const FieldArrayRenderer = ({ fields, realestateOptions }) => {
  const items = fields.map((it, index) => {
    const removeItem = () => fields.remove(index);
    return renderRow(index, removeItem, realestateOptions);
  });
  const createItem = () => fields.push({});
  return (
    <React.Fragment>
      {items}
      <Button bsStyle="primary" name="newLink" onClick={createItem}>
        <span className="icon-061-plus" /> Neuer Eintrag
      </Button>
    </React.Fragment>
  );
};

function SurchargeRealestatetypeForm({ handleSubmit, form, realestatetypes, language }) {
  const realestateOptions = realestatetypes.map((it) => ({
    value: it.id,
    label: it.name[language],
  }));

  return (
    <div>
      <Row style={{paddingBottom: '10px', fontWeight: 'bold'}}>
        <Col sm={3}>ID</Col>
        <Col sm={2}>Objekttyp</Col>
        <Col sm={2}>Zuschlag 1. Hypothek</Col>
        <Col sm={2}>Zuschlag 2. Hypothek</Col>
        <Col sm={2}>Zuschlag Überbelehnung</Col>
      </Row>
      <Form name={form} onSubmit={handleSubmit}>
        <FieldArray name="items"
                    component={FieldArrayRenderer}
                    realestateOptions={realestateOptions}/>
      </Form>
    </div>
  );
}

export const FinancingSurchargeRealestatetypeForm = reduxForm()(SurchargeRealestatetypeForm);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { chain, get, isNil } from 'lodash';
import { createIsRequesting } from '@evoja-web/redaction';
import { FormattedMessage } from 'react-intl';
import { Grid, Row, Col, FormGroup, InputGroup, FormControl } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { withAcl } from '@evoja-web/client-acl';

import './Overview.css';
import Categories from '../components/Overview/Categories';
import Card from '../components/Overview/Card';
import moduleActions from '../actions/Actions';
import filterByCategoryId from '../lib/Utils/filterByCategoryId';
import filterBySearchTerm from '../lib/Utils/filterBySearchTerm';
import filterModulesByAcl from '../lib/Utils/filterModulesByAcl';
import getLoginUrl from '../../../utils/getLoginUrl';

class ModuleOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryId: '*',
      searchTerm: undefined
    };

    const { moduleActions } = this.props;
    moduleActions.categoriesRequest();
    moduleActions.modulesRequest();

    this.onCategoryIdChange = this.onCategoryIdChange.bind(this);
    this.onToggleFavourite = this.onToggleFavourite.bind(this);
    this.onSearchTermChange = this.onSearchTermChange.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
  }

  /**
   * Redirect to the login page if the user has no session
   *
   * @return  void
   */
  componentDidMount() {
    const { session } = this.props;

    if (isNil(session)) {
      const url = getLoginUrl();
      window.location.replace(url);
    }
  }

  /**
   * Handle category change
   *
   * @param   {String}  id  Id of the active category
   *
   * @return  void
   */
  onCategoryIdChange(categoryId) {
    this.setState({ categoryId });
  }

  /**
   * Handle search term change
   *
   * @param   {Event}  ev  Change event
   *
   * @return  void
   */
  onSearchTermChange(ev) {
    const value = get(ev, 'target.value', '');
    const searchTerm = value.trim().length === 0
      ? undefined
      : value;

    this.setState({ searchTerm });
  }

  /**
   * Set the given module as favourite
   *
   * @param   {String}  id  Module id
   *
   * @return  void
   */
  onToggleFavourite({ module }) {
    const { moduleActions } = this.props;

    moduleActions.toggleFavouriteRequest({ dataKey: module.id });
  }

  /**
   * Handle click on a card (redirect to page)
   *
   * @param {Object} module Module that was clicked
   *
   * @return  void
   */
  onCardClick({ module }) {
    const { history } = this.props;

    const path = get(module, 'path');

    if (!isNil(path)) {
      history.push(path);
    }
  }

  /**
   * Render the module cards
   *
   * @return {Array} modules Module cards
   */
  renderModules() {
    const {
      categoryId,
      searchTerm
    } = this.state;
    const {
      acl,
      environment,
      language,
      modules
    } = this.props;

    return chain(modules)
      .map((m) => m.data)
      .compact()
      .thru((modules) => filterByCategoryId({ modules, categoryId }))
      .thru((modules) => filterBySearchTerm({ modules, searchTerm, language }))
      .thru((modules) => filterModulesByAcl({ acl, modules }))
      .orderBy(['isFavourite', 'order', 'category.number'], ['desc', 'asc', 'asc'])
      .map((module) => {
        return (
          <div
            key={module.id}
            className="module-overview--modules-row--col"
          >
            <Card
              language={language}
              module={module}
              onClick={this.onCardClick}
              onToggleFavourite={this.onToggleFavourite}
              tenant={get(environment, 'config.data.tenant')}
            />
          </div>
        );
      })
      .value();
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      categoryId,
      searchTerm
    } = this.state;
    const {
      categories,
      language
    } = this.props;

    return (
      <Grid className="module-overview">
        <Row>
          <Col lg={12} md={12}>
            <FormattedMessage
              id="Module.Overview.Title"
              tagName="h2"
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <FormattedMessage
              id="Module.Overview.Description"
              tagName="h3"
            />
          </Col>
        </Row>

        <Row className="module-overview--categories-row">
          <Col lg={12} md={12}>
            <Categories
              active={categoryId}
              categories={categories}
              language={language}
              onChange={this.onCategoryIdChange}
            />
          </Col>
        </Row>

        <Row className="module-overview--search-row">
          <Col lg={12} md={12}>
            <FormGroup>
              <InputGroup>
                <InputGroup.Addon>
                  <span className="mdi mdi-magnify" />
                </InputGroup.Addon>

                <FormControl
                  type="text"
                  value={searchTerm || ''}
                  onChange={this.onSearchTermChange}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        <div className="module-overview--modules-row">
          {this.renderModules()}
        </div>
      </Grid>
    );
  }
}

ModuleOverview.propTypes = {
  acl: PropTypes.object.isRequired,
  categories: PropTypes.object,
  environment: PropTypes.object,
  history: PropTypes.object.isRequired,
  language: PropTypes.string,
  moduleActions: PropTypes.object.isRequired,
  modules: PropTypes.object,
  session: PropTypes.object
};

ModuleOverview.defaultProps = {
  categories: {},
  environment: {},
  language: 'de',
  modules: {},
  session: undefined
};

const paths = [
  'module.categories',
  (state) => chain(state).get('module.modules', {}).some((m) => get(m, 'requesting', false))
];
const isRequesting = createIsRequesting({ path: paths });

function mapStateToProps(state, ownProps) {
  return {
    categories: state.module.categories,
    environment: state.environment,
    language: state.login.language,
    modules: state.module.modules,
    requesting: isRequesting(state, ownProps),
    session: state.login.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withAcl()(
    withRouter(ModuleOverview)
  )
);

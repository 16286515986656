import './HolidaysCalendar.css';
import React from 'react';
import PropTypes from 'prop-types';
import { HolidaysModeYear } from './HolidaysModeYear';
import { HolidaysModeMonth } from './HolidaysModeMonth';
import { ANALYTICS_PROP_TYPES_LOCALE } from '../Analytics/AnalyticsPropTypes';

export class HolidaysCalendar extends React.Component {
  state = {
    month: undefined,
  };

  constructor(props) {
    super(props);
    this.setMonth = this.setMonth.bind(this);
    this.resetMonth = this.resetMonth.bind(this);
  }

  render() {
    const { year, holidays, create, edit, remove, locale } = this.props;
    const { month } = this.state;
    return month === undefined
      ? <HolidaysModeYear onSelectMonth={this.setMonth}
                          year={year}
                          holidays={holidays}
                          locale={locale}/>
      : <HolidaysModeMonth year={year}
                           month={month}
                           holidays={holidays}
                           reset={this.resetMonth}
                           create={create}
                           edit={edit}
                           remove={remove}
                           locale={locale}/>;
  }

  setMonth(month) {
    this.setState({ month });
  }
  resetMonth() {
    this.setState({ month: undefined });
  }
}

HolidaysCalendar.propTypes = {
  year: PropTypes.number,
  holidays: PropTypes.array,

  edit: PropTypes.func,
  remove: PropTypes.func,
  create: PropTypes.func,

  locale: ANALYTICS_PROP_TYPES_LOCALE.isRequired,
};

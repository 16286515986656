import './PageContainer.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button } from './Button';
import Spinner from '../Spinner';

class PageContainer extends React.Component {
  render() {
    const { fullwidth } = this.props;
    return (
      <div className="page-container container" style={fullwidth ? { width: '90%' } : {}}>
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderFooter()}
      </div>
    );
  }

  renderHeader() {
    const { closeLink = '/', title, header } = this.props;
    return (
      <div className="page-container__header">
        <div className="page-container__header-title" style={{ width: '45%' }}>{title}</div>
        <div className="page-container__header-content" style={{ width: '50%' }}>{header}</div>
        <div className="page-container__header-action" style={{ width: '5%' }}>
          <Link to={closeLink}>&times;</Link>
        </div>
      </div>
    );
  }

  renderBody() {
    const { requesting, children, isTransparentLoader, fullwidth } = this.props;
    return (
      <div className="page-container__body">
        {requesting
          ? isTransparentLoader ? <div className="page-container__spinner"><Spinner /></div> : <Spinner />
          : null}
        {isTransparentLoader || !requesting ? children : null}
      </div>
    );
  }

  renderFooter() {
    const {
      closeLink = '/', saveData, footer, valid = true, requesting
    } = this.props;
    return (
      <div className="page-container__footer">
        <div className="page-container__footer-content">{footer}</div>
        <div className="page-container__footer-actions">
          {!requesting && saveData && (
            <Button onClick={saveData} bsStyle="primary" bsSize="large" disabled={!valid}>
              <FormattedMessage id="common_save" />
            </Button>
          )}

          <Link to={closeLink}>
            <Button bsSize="large"><FormattedMessage id="common_cancel" /></Button>
          </Link>
        </div>
      </div>
    );
  }
}

PageContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  header: PropTypes.element,
  footer: PropTypes.element,
  requesting: PropTypes.bool,
  isTransparentLoader: PropTypes.bool,
  saveData: PropTypes.func,
  closeLink: PropTypes.string,
  valid: PropTypes.bool,
  fullwidth: PropTypes.bool
};

PageContainer.defaultProps = {
  fullwidth: false
};

export default PageContainer;
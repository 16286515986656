import { takeLatest } from 'redux-saga/effects';
import {
  EMAIL_SUBSCRIPTIONS_LOAD,
  EMAIL_SUBSCRIPTIONS_REMOVE,
  EMAIL_SUBSCRIPTIONS_SAVE,
  EMAIL_SUBSCRIPTIONS_SEARCH,
  EMAIL_SUBSCRIPTIONS_REMOVE_MARKETING,
  EMAIL_SUBSCRIPTIONS_IMPORT_CSV
} from '../../actions/EmailSubscriptionsActions';
import { emailSubscriptionsLoadDataSaga } from './emailSubscriptionsLoadDataSaga';
import { emailSubscriptionsSearchSaga } from './emailSubscriptionsSearchSaga';
import { emailSubscriptionsSaveSaga } from './emailSubscriptionsSaveSaga';
import { emailSubscriptionsRemoveSaga } from './emailSubscriptionsRemoveSaga';
import { emailSubscriptionsRemoveMarketingSaga } from './emailSubscriptionsRemoveMarketing';
import { uploadCsvFileSaga } from './uploadCsvFile';

export function* emailSubscriptionsSagas() {
  yield takeLatest(EMAIL_SUBSCRIPTIONS_LOAD.REQUEST, emailSubscriptionsLoadDataSaga);
  yield takeLatest(EMAIL_SUBSCRIPTIONS_SEARCH.REQUEST, emailSubscriptionsSearchSaga);
  yield takeLatest(EMAIL_SUBSCRIPTIONS_SAVE.REQUEST, emailSubscriptionsSaveSaga);
  yield takeLatest(EMAIL_SUBSCRIPTIONS_REMOVE.REQUEST, emailSubscriptionsRemoveSaga);
  yield takeLatest(EMAIL_SUBSCRIPTIONS_REMOVE_MARKETING.REQUEST, emailSubscriptionsRemoveMarketingSaga);
  yield takeLatest(EMAIL_SUBSCRIPTIONS_IMPORT_CSV.REQUEST, uploadCsvFileSaga);
}

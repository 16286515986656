import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, orderBy, isUndefined } from 'lodash';
import Select from 'react-select';
import { FormGroup, Row, Col, Button } from 'react-bootstrap';

import EditContainer from '../components/EditContainer';
import List from '../components/WorkguideCodes/List';
import Form from '../components/WorkguideCodes/Form';
import * as workguideActions from '../actions/WorkguideActions';

class WorkguideCodes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCodeGroup: undefined,
      showForm: false
    };

    this.fetchCodes();

    this.onChange = this.onChange.bind(this);
    this.onCodeGroupChange = this.onCodeGroupChange.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { saveCodes } = this.props;
    const { saveCodes: prevSaveCodes } = prevProps;

    if (get(saveCodes, 'fulfilled', true) && !get(prevSaveCodes, 'fulfilled', false)) {
      this.fetchCodes();
    }
  }

  onCodeGroupChange(selected) {
    const { value } = selected;

    this.setState({ selectedCodeGroup: value });
  }

  onChange(codes) {
    const { workguideActions } = this.props;

    this.setState({ showForm: false });
    workguideActions.workguideSaveCodesRequest({ codes });
  }

  getCodesForList(selectedCodeGroup) {
    const { codes } = this.props;

    return orderBy(
      get(codes, selectedCodeGroup, []).filter((code) => get(code, 'recordOrigin') === 'map'),
      ['order'],
      ['asc']
    );
  }

  toggleForm() {
    const { showForm } = this.state;

    this.setState({ showForm: !showForm });
  }

  fetchCodes() {
    const { codeGroups, workguideActions } = this.props;

    codeGroups.forEach((group) => workguideActions.workguideCodesRequest(group));
  }

  renderBodyContent() {
    const { selectedCodeGroup, showForm } = this.state;
    const { codes, codeGroups } = this.props;

    return (
      <div className="workguide-codes">
        <Row style={{ paddingLeft: '50px' }}>
          <Col lg={12} md={12} sm={12}>
            <div style={{ fontWeight: 'bold' }}>
              Code Gruppe
            </div>
            <FormGroup>
              <Select
                value={selectedCodeGroup}
                options={codeGroups.map((group) => ({ value: group, label: group }))}
                onChange={this.onCodeGroupChange}
                clearable={false}
              />
            </FormGroup>
          </Col>
        </Row>

        {!isUndefined(selectedCodeGroup) && (
          <Row style={{ paddingLeft: '50px', paddingBottom: '20px' }}>
            <Col lg={4} md={4} sm={12} />

            <Col lg={4} md={4} sm={12}>
              <Button
                bsStyle="primary"
                onClick={this.toggleForm}
                style={{ width: '100%' }}
                disabled={showForm}
              >
                Neuen Eintrag hinzufügen
              </Button>
            </Col>

            <Col lg={4} md={4} sm={12} />
          </Row>
        )}

        {showForm && (
          <Row style={{ paddingLeft: '50px', paddingBottom: '20px'  }}>
            <Col lg={12} md={12} sm={12}>
              <Form
                codes={codes}
                group={selectedCodeGroup}
                data={{ group: selectedCodeGroup }}
                onSubmit={(code) => this.onChange([code])}
                onCancel={this.toggleForm}
              />
            </Col>
          </Row>
        )}

        <List
          codes={codes}
          group={selectedCodeGroup}
          items={this.getCodesForList(selectedCodeGroup)}
          onChange={this.onChange}
        />
      </div>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { requesting } = this.props;

    return (
      <EditContainer
        requesting={requesting}
        title={'Workguide Codes bearbeiten'}
        body={this.renderBodyContent()}
        footer={null}
        closeLink="/workguide"
      />
    );
  }
}

WorkguideCodes.propTypes = {
  codes: PropTypes.object,
  codeGroups: PropTypes.array
};

WorkguideCodes.defaultProps = {
  codes: {},
  codeGroups: ['workguideRejectReason']
};

function isRequesting(state) {
  return (
    get(state, 'workguide.codesRequesting', false)
    || get(state, 'workguide.saveCodes.requesting', false)
  );
}

function mapStateToProps(state, ownProps) {
  return {
    codes: state.workguide.codes,
    requesting: isRequesting(state),
    saveCodes: state.workguide.saveCodes
  };
}

function mapDispatchToProps(dispatch) {
  return { workguideActions: bindActionCreators(workguideActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkguideCodes);

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middleware = applyMiddleware(
  promise(),
  thunk,
  sagaMiddleware,
  createLogger(),
);

export default createStore(rootReducer, middleware);

sagaMiddleware.run(sagas);

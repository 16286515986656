import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { LOAD_PROMOTIONS_FILES_ACTIONS } from '../../actions/PromotionActions';

export function* loadPromotionsFilesSaga({ meta }) {
  try {
    yield put(LOAD_PROMOTIONS_FILES_ACTIONS.pending(meta));

    const { data } = yield call(axios, {
      url: `${EndpointName.FILE}/${meta.file.id}`,
      method: 'get',
      timeout: 50000,
      responseType: 'blob',
    });

    yield put(LOAD_PROMOTIONS_FILES_ACTIONS.success(data, meta));
  } catch(error) {
    yield put(LOAD_PROMOTIONS_FILES_ACTIONS.failure(error, meta));
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { Toggle } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';

const DateFieldConfigUseAsDueDate = React.memo(function DateFieldConfigUseAsDueDate({
  id,
  onChange,
  value
}) {
  return (
    <FormGroup controlId="codeGroupMulti">
      <Col componentClass={ControlLabel} sm={3}>
        <FormattedMessage id="Workguide.Configuration.DateField.UseAsDueDate" />
      </Col>
      <Col sm={9} style={{ paddingTop: '10px' }}>
        <Toggle
          id={id}
          value={value}
          onChange={onChange}
        />
      </Col>
    </FormGroup>
  );
});

DateFieldConfigUseAsDueDate.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool
};

DateFieldConfigUseAsDueDate.defaultProps = {
  id: 'useAsDueDate',
  onChange: noop,
  value: undefined
};

export default DateFieldConfigUseAsDueDate;

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SearchBlock } from '../../components/Common/SearchBlock';
import { getCommonSearch } from '../../selectors/commonSelectors';
import { get } from 'lodash';
import _ from 'lodash';

import Detail from './Detail';
import codeMappingActions from '../../actions/CodeMappingActions';
import PageContainer from '../../components/Common/PageContainer';

class CodeMappingOverview extends React.Component {
  constructor(props) {
    super(props);

    const { codeMappingActions } = props;

    codeMappingActions.codeMappingsRequest();
  }

  renderOverview() {
    const { mappings , search } = this.props;

    const data = get(mappings, 'data', []);
    const codes = data.map((item) => {
      const { id } = item;

      return (
        <Detail
          key={id}
          data={item}
        />
      );
    })
    const filteredChildren = search
    ? _.filter(codes, item => _.includes(_.deburr(_.toLower(item.key)), _.deburr(_.toLower(search))))
    : codes;

    return (
      <div className="code-mapping-overview">
        {filteredChildren}
      </div>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { requesting } = this.props;

    return (
      <PageContainer
        requesting={requesting}
        title={'Code Mappings'}
        header={<SearchBlock/>}
      >
        {this.renderOverview()}
      </PageContainer>
    );
  }
}

CodeMappingOverview.propTypes = {

};

CodeMappingOverview.defaultProps = {

};

function isRequesting(state) {
  return get(state, 'codeMapping.mappings.requesting', false);
}

function mapStateToProps(state, ownProps) {
  return {
    search: getCommonSearch(state),
    language: state.login.language,
    mappings: state.codeMapping.mappings,
    requesting: isRequesting(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    codeMappingActions: bindActionCreators(codeMappingActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CodeMappingOverview);

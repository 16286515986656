import { createSelector } from 'reselect';
import { getAnalyticsLogsConsultants, getAnalyticsLogsState } from './analyticsLogsCommonSelectors';

const getComparisonDetails = createSelector([getAnalyticsLogsState], (state) => state.comparisonDetails);
export const getAnalyticsLogsComparisonDetails = createSelector([
  getComparisonDetails,
  getAnalyticsLogsConsultants,
], (details) => {
  if (!details) {
    return undefined;
  }

  return {
    first: mapItem(details.first),
    second: mapItem(details.second),
  };
});

function mapItem(details) {
  return {
    id: details.id,
    username: details.username,
    createdAt: details.createdAt,
    requestUri: details.requestUri,
    responseCode: details.responseCode,
    requestBody: formatRequestBody(details.requestBody),
    responseBody: formatRequestBody(details.responseBody)
  };
}

function formatRequestBody(requestBody) {
  try {
    const body = JSON.parse(requestBody);
    return JSON.stringify(body, null, 2);
  } catch (e) {
    return requestBody;
  }
}

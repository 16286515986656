export const USER_PERMISSION_GROUPS = [
  'LEAD_DISTRIBUTOR',
  'UV_CONSULTANT',
  'KDC',
  'BACKOFFICE',
  'POSTFINANCE',
  'ADMINISTRATOR',
  'PLEDGE_RESOLVE',
  'PERSONNEL_RIGHTS',
  'ELECTRONIC_BANKING_CENTER',
  'CUSTOMER_SERVICE',
  'CUSTOMER_SERVICE_GUEMLIGEN',
  'CUSTOMER_SERVICE_BIEL',
  'COMPLIANCE'
];
/* global FormData */

import axios from 'axios';

export const GET_METADATA = 'adminUI/Documents/GET_METADATA';
export const GET_MODULES = 'adminUI/Documents/GET_MODULES';
export const GET_BUTTONS = 'adminUI/Documents/GET_BUTTONS';
export const PUT = 'adminUI/Document/PUT';
export const POST = 'adminUI/Document/POST';
export const DEL = 'adminUI/Documents/DEL';
export const SET_DATA = 'adminUI/Documents/SET_DATA';

export function getMetadata({
  itemsPerPage = 6,
  offset = 0,
  module = 'all',
  button = false,
  language = 'de',
  searchString = '',
  documentType = 'evojaDocumentType%2Dbank',
  chunked = false,
} = {}) {
  let url = `/file/?limit(${itemsPerPage},${offset})`
   + `&eq(links.ref.$id,${documentType})`
   + `&or(ne(links.type,language),eq(links.ref.$id,${language}))`
   + '&sort(+metadata.order)';

  url = searchString.length > 0 ? `${url}&like(metadata.filename,*${searchString}*)` : url;

  url = module === 'all' ?
    url : `${url}&eq(links.ref.$id,${module})`;

  url = button ?
    `${url}&eq(metadata.additionalProperties.name,button)` : url;

  return (dispatch) => {
    dispatch({
      type: `${GET_METADATA}_PENDING`,
      chunked,
    });
    return axios({
      url,
    })
      .then((response) => {
        dispatch({
          type: `${GET_METADATA}_FULFILLED`,
          data: response.data,
          headers: response.headers,
          chunked,
        });
      })
      .catch((error) => {
        dispatch({
          type: `${GET_METADATA}_REJECTED`,
          error,
        });
      });
  };
}

export function getDocumentsWithMetadata({
  itemsPerPage: ip = 6,
  offset: os = 0,
  module: md = 'all',
  button: bt = false,
  language: lg = 'de',
  searchString: ss = '',
  documentType: dt = 'evojaDocumentType%2Dbank',
  chunked: cd = false,
} = {}) {
  return (dispatch) => dispatch(getMetadata({
    searchString: ss,
    itemsPerPage: ip,
    offset: os,
    module: md,
    button: bt,
    language: lg,
    documentType: dt,
    chunked: cd,
  }));
}

function createMetadata(moduleId, language, filename) {
  const links = [
    {
      type: 'evojaDocumentType',
      $ref: '/entity/code/evojaDocumentType-bank',
    },
    {
      type: 'language',
      $ref: `/i18n/language/${language}`,
    },
  ];

  if (moduleId !== 'all') {
    links.push({
      type: 'module',
      $ref: `/core/module/${moduleId}`,
    });
  }

  return JSON.stringify({
    links,
    metadata: {
      order: 9999,
      action: [],
      filename: filename,
    },
  });
}

// POST DOCUMENTS
function postDocument(file, moduleId, language) {
  const formData = new FormData();
  formData.append('upload', file);
  formData.append('metadata', createMetadata(moduleId, language, file.name));
  return (dispatch) => {
    dispatch({ type: `${POST}_PENDING` });
    return axios({
      url: '/file',
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    })
      .then((response) => {
        dispatch({
          type: `${POST}_FULFILLED`,
          payload: response.headers.location,
        });
      })
      .catch((error) => {
        dispatch({
          type: `${POST}_REJECTED`,
          payload: error,
        });
      });
  };
}

function getMetadataByUrl(url) {
  return (dispatch) => {
    dispatch({ type: `${POST}_METADATA_PENDING` });

    return axios({
      headers: { Accept: 'application/json' },
      url,
    })
      .then((response) => {
        dispatch({
          type: `${POST}_METADATA_FULFILLED`,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: `${POST}_METADATA_REJECTED`,
          payload: error,
        });
      });
  };
}

export function saveDocument(file, moduleId, language = 'de') {
  return (dispatch, getState) => dispatch(postDocument(file, moduleId, language)).then(() => {
    const { newDocumentUrl } = getState().documents;
    return dispatch(getMetadataByUrl(newDocumentUrl));
  });
}

export function updateDocument(id, metadata, links) {
  const data = ({
    id,
    metadata,
    links,
  });
  return (dispatch) => {
    dispatch({
      type: PUT,
      meta: { id, metadata, links },
      payload: axios({
        url: `/file/${id}`,
        method: 'put',
        data,
      }),
    });
  };
}

// DELETE DOCUMENTS
export function deleteDocument(id) {
  return (dispatch) => {
    dispatch({
      type: DEL,
      meta: { id },
      payload: axios({
        url: `/file/${id}`,
        method: 'delete',
      }),
    });
  };
}

export function getModules() {
  return (dispatch) => {
    dispatch({
      type: GET_MODULES,
      payload: axios('/core/module/?or(eq(app.ref.$id,string:evoja),eq(app.ref.$id,string:bap))&limit(999)&sort(+order)')
        .then((response) => Promise.all([
          response.data,
          axios('/core/screen/?limit(999)'
            + `&in(module.ref.$id,(${response.data.map((it) => it.id).join(",")}))`
            + '&sort(+order)')
        ]))
        .then(([modules, screensResponse]) => ({ modules, screens: screensResponse.data })),
    });
  };
}

export function getButtons() {
  return (dispatch) => {
    dispatch({
      type: GET_BUTTONS,
      payload: axios({
        url: '/core/config/?eq(key,document%2dbutton)&limit(999)',
      }),
    });
  };
}

export function setData({ key, value }) {
  return (dispatch) => {
    dispatch({
      type: SET_DATA,
      key,
      value,
    });
  };
}

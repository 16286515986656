import './RefErrors.css';

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { SingleDatePicker } from 'react-dates';
import { handleGravitonError } from '../../utils';
import PageContainer from '../Common/PageContainer';
import { RefErrorsSearchForm } from './RefErrorsSearchForm';
import { RefErrorsResults } from './RefErrorsResults';
import { REF_ERRORS_PROP_TYPES_RESULT_ITEM, REF_ERRORS_PROP_TYPES_SEARCH_PARAMS } from './RefErrorsPropTypes';
import { RefErrorsChart } from './RefErrorsChart';
import { RefErrorsTotalChart } from './RefErrorsTotalChart';

const SEARCH_FORM = 'refErrorsSearchForm';

export class RefErrors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment(),
      focused: false,
    };
    props.load();
    props.loadPerDay({
      fromDate: moment().startOf('day'),
      toDate: moment().endOf('day'),
    });
  }

  onSelectCollection = (category) => {
    const { search } = this.props;
    search({ collection: category });
  };

  dataChanged(date) {
    const { loadPerDay } = this.props;
    this.setState({ selectedDate: date });
    loadPerDay({
      fromDate: moment(date).startOf('day'),
      toDate: moment(date).endOf('day'),
    });
  }

  renderSearchForm() {
    const { search, loading, results, searchParams, locale } = this.props;
    return (
      <RefErrorsSearchForm
        form={SEARCH_FORM}
        search={search}
        loading={loading}
        results={results}
        initialValues={searchParams}
        enableReinitialize={true}
        keepDirtyOnReinitialize={true}
        locale={locale}
      />
    );
  }

  render() {
    const { error, texts, loading, locale } = this.props;
    const {
      search,
      searchParams,
      results,
      resultsTotalCount,
      resultsRaw,
      allErrors
    } = this.props;
    if (error) {
      toast.error(handleGravitonError(error));
    }

    return (
      <PageContainer title={<FormattedMessage id="refErrors_title" />} requesting={loading} isTransparentLoader={true}>
        <div className="ref-errors">
          {texts.textIntro ? <div className="ref-errors__text">{texts.textIntro}</div> : null}
          {this.renderSearchForm()}
          <RefErrorsResults
            resultsRaw={resultsRaw}
            results={results}
            resultsTotalCount={resultsTotalCount}
            search={search}
            searchParams={searchParams}
            locale={locale}
            loading={loading}
          />
          <RefErrorsChart results={results} onSelect={this.onSelectCollection} />
          <div style={{ display: 'flex' }}>
            <p>Datum auswählen:</p>
            <SingleDatePicker
              date={this.state.selectedDate}
              onDateChange={(date) => this.dataChanged(date)}
              focused={this.state.focused}
              isOutsideRange={() => false}
              onFocusChange={({ focused }) => this.setState({ focused })}
              id="refErrorDateFilter"
            />
          </div>
          <RefErrorsTotalChart results={allErrors} />
        </div>
      </PageContainer>
    );
  }
}

RefErrors.propTypes = {
  loading: PropTypes.bool,
  texts: PropTypes.shape({
    textIntro: PropTypes.string,
  }),
  error: PropTypes.object,
  results: PropTypes.arrayOf(REF_ERRORS_PROP_TYPES_RESULT_ITEM),
  resultsTotalCount: PropTypes.number,
  load: PropTypes.func,
  search: PropTypes.func,
  searchParams: REF_ERRORS_PROP_TYPES_SEARCH_PARAMS,
  resultsRaw: PropTypes.object
};

RefErrors.defaultProps = {
  resultsRaw: {}
}

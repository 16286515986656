import { createSelector } from 'reselect';
import { getAdditions } from './getBasicServices';
import { mapBasicAdditionToApi } from '../../services/BasicProducts';
import { getIdFromRef } from '../../utils';
import { getProductsWithAdditions } from './getProductsWithAdditions';

export const getNewAdditions = createSelector([
  getProductsWithAdditions,
  getAdditions,
], (products, additions) => {
  return products
    .filter((product) => !additions.some((addition) => getIdFromRef(addition.product.$ref) === product.id))
    .map((product) => mapBasicAdditionToApi(product));
});

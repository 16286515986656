import {
  WORKGUIDE_STATUS_ENTITIY_CODE_LOAD,
  ACCOUNT_WORKGUIDES_LOAD,
  NETTED_ACCOUNTS_WORKGUIDES_LOAD,
  NETTED_ACCOUNTS_WORKGUIDES_UPDATE,
  ACCOUNT_WORKGUIDES_UPDATE,
  ACCOUNT_WORKGUIDES_CLEAR,
  ACCOUNT_SEARCH_LOAD
} from '../actions/WorkguideStatusActions';
const initialState = {
  loading: false,
  error: undefined,
  codes: undefined
};

export function WorkguideStatusReducer(state = initialState, action) {
  switch (action.type) {
    case WORKGUIDE_STATUS_ENTITIY_CODE_LOAD.PENDING:
      return { ...state, loading: true, error: undefined };
    case WORKGUIDE_STATUS_ENTITIY_CODE_LOAD.SUCCESS: {
      const { codes } = action.data;
      return { ...state, loading: false, codes };
    }
    case WORKGUIDE_STATUS_ENTITIY_CODE_LOAD.FAILURE:
      return { ...state, loading: false, error: action.data };

    case ACCOUNT_WORKGUIDES_LOAD.PENDING:
      return { ...state, loading: true, accountWorkguides: { ...state.accountWorkguides, loading: true, error: undefined } };
    case ACCOUNT_WORKGUIDES_LOAD.SUCCESS: {
      const { workguides, totalCount } = action.data;
      return { ...state, loading: false, accountWorkguides: { ...state.accountWorkguides, data: workguides, loading: false, totalCount } };
    }
    case ACCOUNT_WORKGUIDES_LOAD.FAILURE:
      return { ...state, loading: false, accountWorkguides: { ...state.accountWorkguides, loading: false, error: action.data } };

    case ACCOUNT_WORKGUIDES_UPDATE.PENDING:
      return { ...state, loading: true, error: undefined };
    case ACCOUNT_WORKGUIDES_UPDATE.SUCCESS:
      return { ...state, loading: false };
    case ACCOUNT_WORKGUIDES_UPDATE.FAILURE:
      return { ...state, loading: false, error: action.data };
    
      case ACCOUNT_WORKGUIDES_CLEAR.PENDING:
      return { ...state, loading: true, accountWorkguides: { ...state.accountWorkguides, error: undefined } };
    case ACCOUNT_WORKGUIDES_CLEAR.SUCCESS:
      return { ...state, loading: false, accountWorkguides: { ...state.accountWorkguides, data: undefined } };
    case ACCOUNT_WORKGUIDES_CLEAR.FAILURE:
      return { ...state, loading: false, accountWorkguides: { ...state.accountWorkguides, error: action.data } };

    case NETTED_ACCOUNTS_WORKGUIDES_LOAD.FAILURE:
      return { ...state, loading: false, error: action.data };
    case NETTED_ACCOUNTS_WORKGUIDES_LOAD.PENDING:
      return { ...state, loading: true, error: undefined };
    case NETTED_ACCOUNTS_WORKGUIDES_LOAD.SUCCESS: {
      const { workguides, totalCount } = action.data;
      return { ...state, loading: false, nettedAccountsWorkguides: workguides, nettedAccountsWorkguidesTotalCount: totalCount };
    }

    case NETTED_ACCOUNTS_WORKGUIDES_UPDATE.FAILURE:
      return { ...state, loading: false, error: action.data };
    case NETTED_ACCOUNTS_WORKGUIDES_UPDATE.PENDING:
      return { ...state, loading: true, error: undefined };
    case NETTED_ACCOUNTS_WORKGUIDES_UPDATE.SUCCESS:
      return { ...state, loading: false };

    case ACCOUNT_SEARCH_LOAD.PENDING:
      return { ...state, loading: true, account: { ...state.account, loading: true, error: undefined } };
    case ACCOUNT_SEARCH_LOAD.SUCCESS: {
      const { account } = action.data;
      return { ...state, loading: false, account: { ...state.account, data: account, loading: false } };
    }
    case ACCOUNT_SEARCH_LOAD.FAILURE:
      return { ...state, loading: false, account: { ...state.account, loading: false, error: action.data } };

    default:
      return state;
  }
}

import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  FINANCING_RATES_LOAD_ACTIONS,
  FINANCING_RATES_SAVE_ACTIONS,
  FINANCING_RATES_SYNC_FORWARDS_ACTIONS,
  FINANCING_RATES_UPDATE_BASE_RATE_ACTIONS,
  FINANCING_RATES_UPDATE_FORWARDS_ACTIONS,
  FINANCING_RATES_UPDATE_REFI_RATE_ACTIONS,
} from '../../actions/FinancingRatesActions';
import { financingRatesLoadSaga } from './financingRatesLoadSaga';
import { financingRatesSaveSaga } from './financingRatesSaveSaga';
import { financingRatesUpdateBaseRateSaga } from './financingRatesUpdateBaseRateSaga';
import { financingRatesUpdateRefiRateSaga } from './financingRatesUpdateRefiRateSaga';
import { financingRatesUpdateForwardsSaga } from './financingRatesUpdateForwardsSaga';
import { financingRatesSyncForwardsSaga } from './financingRatesSyncForwardsSaga';

export function* financingRatesSagas() {
  yield takeLatest(FINANCING_RATES_LOAD_ACTIONS.REQUEST, financingRatesLoadSaga);
  yield takeLatest(FINANCING_RATES_SAVE_ACTIONS.REQUEST, financingRatesSaveSaga);
  yield takeEvery(FINANCING_RATES_UPDATE_BASE_RATE_ACTIONS.REQUEST, financingRatesUpdateBaseRateSaga);
  yield takeEvery(FINANCING_RATES_UPDATE_REFI_RATE_ACTIONS.REQUEST, financingRatesUpdateRefiRateSaga);
  yield takeEvery(FINANCING_RATES_UPDATE_FORWARDS_ACTIONS.REQUEST, financingRatesUpdateForwardsSaga);
  yield takeEvery(FINANCING_RATES_SYNC_FORWARDS_ACTIONS.REQUEST, financingRatesSyncForwardsSaga);
}

import PropTypes from 'prop-types';

export const REF_ERRORS_PROP_TYPES_RESULT_ITEM = PropTypes.shape({
  id: PropTypes.string.isRequired,
  loadId: PropTypes.string,
  loadedAt: PropTypes.string,
  collectionName: PropTypes.string,
  recordId: PropTypes.string,
  refErrors: PropTypes.arrayOf(PropTypes.shape({
    localField: PropTypes.string,
    relationType: PropTypes.string,
    targetCollection: PropTypes.string,
    targetCollectionField: PropTypes.string,
    targetId: PropTypes.string,
  })),
});

export const REF_ERRORS_PROP_TYPES_SEARCH_PARAMS = PropTypes.shape({
  pageNumber: PropTypes.number,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  collection: PropTypes.string,
  recordId: PropTypes.string,
  targetCollection: PropTypes.string,
  targetId: PropTypes.string,
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
});

import {
  GET,
  PUT,
  SET,
} from '../actions/Leadqualification';

const initialState = ({
  leadtypes: [],
  workguides: [],
  leadgroups: [],
  customerTaskStatuses: [],
  requesting: false,
  error: {},
  hasError: false,
});
export default function LeadqualificationReducer(state = initialState, action) {
  switch (action.type) {
    case SET: {
      return { ...state, [action.key]: action.value };
    }
    case `${GET}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${GET}_FULFILLED`: {
      return { ...state, requesting: false, leadtypes: action.data };
    }
    case `${GET}_WORKGUIDES_FULFILLED`: {
      return { ...state, requesting: false, workguides: action.data };
    }
    case `${GET}_LEADGROUPS_FULFILLED`: {
      return { ...state, requesting: false, leadgroups: action.data };
    }
    case `${GET}_CUSTOMER_TASK_STATUSES_FULFILLED`: {
      return { ...state, requesting: false, customerTaskStatuses: action.data };
    }
    case `${GET}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case `${PUT}_PENDING`: {
      return { ...state, hasError: false };
    }
    case `${PUT}_FULFILLED`: {
      return { ...state };
    }
    case `${PUT}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

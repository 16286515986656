export function classList(...args) {
    return args.reduce((result, item) => {
        if (typeof item === "string") {
            return item ? [...result, item] : result;
        }
        if (Array.isArray(item)) {
            const sub = classList(...item);
            return sub ? [...result, sub] : result;
        }
        if (typeof item === "object") {
            return item ? [...result, ...Object.keys(item).filter((key) => item[key])] : result;
        }
        return result;
    }, []).join(" ");
}

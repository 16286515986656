import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, debounce } from 'lodash';
import { FormGroup, FormControl, ControlLabel, Col, Tabs, Tab } from 'react-bootstrap';
import update from 'immutability-helper';

import MarkdownEditor from '../../MarkdownEditor';

class InfoBoxConfiguration extends React.Component {
  constructor(props) {
    super(props);
    const { item, editValueField } = props;

    this.state = {
      activeTab: 'de',
      blockTitle: get(item, 'props.blockTitle'),
      blockContent: get(item, 'props.blockContent')
    };

    this.setActiveTab = this.setActiveTab.bind(this);
    this.debouncedEditValueField = debounce(editValueField, 500);
  }

  /**
   * Set current active tab
   *
   * @param {String} language Selected language
   */
  setActiveTab(language) {
    this.setState({ activeTab: language });
  }

  /**
   * Handle change of one of the text field (title/description)
   *
   * @param  {String} field   Field name
   * @param  {String} lanuage Language
   * @param  {Object} ev      Event from input
   *
   * @return void
   */
  onTextChange(field, language, value) {
    const { id } = this.props;
    const current = get(this, `state.${field}`, {});
    const updated = update(current, { [language]: { $set: value } });
    this.setState({ [field]: updated });

    this.debouncedEditValueField(id, 'prop', { key: field, value: updated });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { active } = this.state;
    const { languages } = this.props;

    const tabs = languages.map(l => (
      <Tab key={l} eventKey={l} title={l}>
        <ControlLabel style={{ textAlign: 'left'}}>
          Titel
        </ControlLabel>
        <FormControl
          type="text"
          value={get(this, `state.blockTitle.${l}`, '')}
          onChange={(ev) => this.onTextChange('blockTitle', l, get(ev, 'target.value'))}
        />

        <ControlLabel style={{ textAlign: 'left'}}>
          Inhalt
        </ControlLabel>
        <div style={{ paddingLeft: '1%' }}>
          <MarkdownEditor
            content={get(this, `state.blockContent.${l}`, '')}
            updateFunction={value => this.onTextChange('blockContent', l, value)}
            title="Inhalt"
          />
        </div>
      </Tab>
    ));

    return(
      <React.Fragment>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Inhalt
          </Col>

          <Col sm={9}>
            <Tabs
              id="action-title"
              activeKey={active}
              onSelect={this.setActiveTab}
            >
              {tabs}
            </Tabs>
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

InfoBoxConfiguration.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  item: PropTypes.object,
  languages: PropTypes.array,
  editValueField: PropTypes.func
};

InfoBoxConfiguration.defaultProps = {
  id: undefined,
  item: undefined,
  languages: ['de', 'fr', 'en'],
  editValueField: noop
};

export default InfoBoxConfiguration;

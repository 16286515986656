import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from 'react-select';

const CriteriaSingleSelect = ({ source, criteria, language, onChange, edit, operation }) => {
  function onValueChange(selected) {
    const multi = operation === 'contains';

    if (multi) {
      onChange({
        source: _.get(source, 'props.id'),
        path: `[${_.get(source, 'props.id')}.value]`,
        values: selected.map(s => s.value)
      });
    } else {
      onChange({
        source: _.get(source, 'props.id'),
        path: `${_.get(source, 'props.id')}.value`,
        values: (selected) ? [selected.value] : []
      });
    }
  }

  const options = _.get(source, 'props.options', []).map(o => ({ value: o.value, label: _.get(o, `label.${language}`) }));

  return (edit)
    ? (
      <React.Fragment>
        <strong>Wert</strong>

        <Select
          options={options}
          onChange={onValueChange}
          value={(_.get(source, 'props.multi') || operation === 'contains') ? _.get(criteria, 'values', []) : _.get(criteria, 'values.0')}
          multi={operation === 'contains'}
        />
      </React.Fragment>
    )
    : (
      <React.Fragment>
        {_.get(criteria, 'values', []).map(v => _.get(options.find(o => o.value === v), 'label')).join(',')}
      </React.Fragment>
    );
}

CriteriaSingleSelect.propTypes = {
  source: PropTypes.object.isRequired,
  criteria: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func,
  edit: PropTypes.bool,
  multi: PropTypes.bool,
  operation: PropTypes.string
};

CriteriaSingleSelect.defaultProps = {
  criteria: {},
  language: 'de',
  onChange: _.noop,
  edit: false,
  multi: false,
  operation: 'eq'
};

export default CriteriaSingleSelect;

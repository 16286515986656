import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { handleGravitonError } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';
import { ANALYTICS_USER_SUMMARY_LOAD } from '../../actions/AnalyticsUserSummaryActions';

export function* analyticsUserSummaryLoadSaga() {
  try {
    yield put(ANALYTICS_USER_SUMMARY_LOAD.pending());

    const { data: banks } = yield call(axios.get, `${EndpointName.ANALYTICS_BANK_INFO}?limit=999999`);

    yield put(ANALYTICS_USER_SUMMARY_LOAD.success({ banks }));
  } catch (error) {
    yield put(ANALYTICS_USER_SUMMARY_LOAD.failure(error));

    yield call(toast.error, yield call(handleGravitonError, error));
  }
}

import './FeatureFlagItem.css';

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Collapse, ListGroupItem } from 'reactstrap';

class FeatureFlagItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.onUpdate = this.onUpdate.bind(this);
  }

  onUpdate(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    const { featureFlag, onUpdate } = this.props;
    onUpdate(featureFlag);
  }

  render() {
    const { isOpen } = this.state;
    const { featureFlag, language } = this.props;

    return (
      <ListGroupItem className="feature-flag-item">
        <div className="feature-flag-item--sub-div" onClick={() => this.setState({ isOpen: !isOpen })}>
          <div className="feature-flag-item__text">
            <div className="feature-flag-item__name">{get(featureFlag, 'identifier')}</div>
          </div>

          <div className="feature-flag-item__text">
            <div className="feature-flag-item__name">{get(featureFlag, `name.${language}`)}</div>
          </div>

          <div className="feature-flag-item__control">
            <span className="icon-025-edit" onClick={this.onUpdate} />
          </div>
        </div>
        <Collapse isOpen={isOpen}>
          <div className="feature-flag-item__collaps">
            <div className="feature-flag-item__name">{get(featureFlag, `description.${language}`)}</div>
          </div>
        </Collapse>
      </ListGroupItem>
    );
  }
}

FeatureFlagItem.propTypes = {
  featureFlag: PropTypes.object,
  onUpdate: PropTypes.func,
  language: PropTypes.string
};

FeatureFlagItem.defaultProps = {
  featureFlag: {},
  onUpdate: noop,
  language: 'de'
};

export default FeatureFlagItem;

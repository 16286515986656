import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { getFinancingRatesRefiRates } from '../../selectors/FinancingRates/financingRatesCommonSelectors';
import { createRef } from '../../utils';

export function* financingRatesSaveRefiRatesSaga(initialRates) {
  const refiRates = yield select(getFinancingRatesRefiRates);

  yield all(refiRates
    .filter((refiRate) => {
      const initial = initialRates.find((it) => it.id === refiRate.id);
      return initial && initial.interestRate !== refiRate.interestRate;
    })
    .map((refiRate) => call(axios, {
      method: 'patch',
      url: createRef(EndpointName.FINANCING_REFI_INTEREST_RATE, refiRate.id),
      data: [{
        op: 'replace',
        path: '/interestRate',
        value: refiRate.interestRate,
      }],
    })),
  );
}

export function sortByAll(mapper, ...rest) {
  return (a, b) => {
    const aa = mapper(a);
    const bb = mapper(b);

    if (aa !== bb) {
      return aa < bb ? -1 : 1;
    } else if (!rest.length) {
      return 0;
    } else {
      return sortByAll(rest[0], ...rest.slice(1))(a, b);
    }
  };
}

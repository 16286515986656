import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, FormGroup } from 'react-bootstrap';
import IdInput from './IdInput';
import NumberInput from './NumberInput';
import Select from './Select';

export default class EditForm extends React.Component {
  constructor() {
    super();
    this.onValueChange = this.onValueChange.bind(this);
    this.setValue = this.setValue.bind(this);
  }
  /**
   * Only update the component if item, language, durations ore useTypes changed
   *
   * @param  {[type]} nextProps Updated properties
   *
   * @return {Boolean}
   */
  shouldComponentUpdate(nextProps) {
    const {
      item: currentItem,
      language: currentLanguage,
      durations: currentDurations,
      useTypes: currentUseTypes,
    } = this.props;

    const {
      item,
      language,
      durations,
      useTypes,
    } = nextProps;

    if (currentItem !== item) return true;
    if (currentLanguage !== language) return true;
    if (currentDurations !== durations) return true;
    if (currentUseTypes !== useTypes) return true;

    return false;
  }

  /**
   * Gets called from onChange.
   * Get value from ev.target.value and call setValue
   *
   * @param  {String} key Key on object to update
   * @param  {Event}  ev  Event
   *
   * @return {EditForm} this This instance
   */
  onValueChange(key, ev) {
    const { value } = ev.target;

    return this.setValue(key, value);
  }

  /**
   * Wrapper that passes item, key and value to onItemChange from props
   *
   * @param {String} key    Field to update
   * @param {Mixed}  value  New value
   *
   * @return {EditForm} this This instance
   */
  setValue(key, value) {
    const { item, onItemChange } = this.props;
    onItemChange(item, key, value);

    return this;
  }

  /**
   * Concat the label for the given duration
   *
   * @param  {Object} duration Duration object
   *
   * @return {String} label Label for dropdown
   */
  getDurationLabel(duration) {
    const { loanProducts, language } = this.props;

    // Try to find assigned loanProduct (first item found in availableDurations)
    const loanProduct = loanProducts.find(product => product.availableDurations.find(d => d.$ref.includes(duration.id))) || {};
    const { name: { [language]: name = '' } = '' } = loanProduct;
    let years = '';
    if (duration.durationInYears > 1) years = `${duration.durationInYears} Jahre`;
    const label = `${duration.id} "${name} ${years}"`;

    return label.trim();
  }

  /**
   * Render the delete icon inside the ID input
   *
   * @return {ReactElement} markup
   */
  renderDeleteIcon() {
    const style = {
      position: 'absolute',
      right: '5px',
      top: 0,
      bottom: 0,
      height: '14px',
      margin: 'auto',
      fontSize: '12px',
      fontWeight: 'lighter',
      cursor: 'pointer',
      color: '#ccc',
    };

    return (<span className="icon-022-close" style={style} onClick={this.setValue.bind(this, 'id', {target: {value: ''}})} />);
  }

  /**
   * Render the edit form
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      item,
      durations,
      useTypes,
      language,
    } = this.props;

    let duration = {};
    if (item.duration) {
      const label = this.getDurationLabel(item.duration);
      duration = { label, value: item.duration };
    }

    const useType = item.realEstateUseType || { text: {} };
    const { validation = { valid: true, errors: {} } } = item;

    const durationValues = durations.map((dur) => {
      const label = this.getDurationLabel(dur);
      return { label, value: dur };
    });


    return (
      <Form>
        <Row>
          <Col lg={3} md={4} sm={6} xs={12} style={{ minHeight: '125px' }}>
            <IdInput
              value={item.id}
              disabled={!item.isNew}
              validation={validation.errors.id}
              onValueChange={this.onValueChange}
            />
          </Col>

          <Col lg={3} md={4} sm={6} xs={12} style={{ minHeight: '125px' }}>
            <FormGroup>
              <NumberInput
                id="rate"
                label="Rate"
                value={item.rate}
                validation={validation.errors.rate}
                onValueChange={this.onValueChange}
              />
            </FormGroup>
          </Col>

          <Col lg={3} md={4} sm={6} xs={12} style={{ minHeight: '125px' }}>
            <FormGroup>
              <Select
                id="duration"
                label="Zeitraum"
                value={duration}
                validation={validation.errors.duration}
                onValueChange={this.setValue}
                options={durationValues}
              />
            </FormGroup>
          </Col>

          <Col lg={3} md={4} sm={6} xs={12} style={{ minHeight: '125px' }}>
            <FormGroup>
              <Select
                id="realEstateUseType"
                label="Nutzungsart"
                value={{ label: useType.text[language], value: useType.id }}
                validation={validation.errors.useType}
                onValueChange={this.setValue}
                options={useTypes.map(type => ({ label: type.text[language], value: type }))}
              />
            </FormGroup>
          </Col>

          <Col lg={3} md={4} sm={6} xs={12} style={{ minHeight: '125px' }}>
            <NumberInput
              id="lendingRateStart"
              label="Start"
              value={item.lendingRateStart}
              validation={validation.errors.lendingRateStart}
              onValueChange={this.onValueChange}
            />
          </Col>

          <Col lg={3} md={4} sm={6} xs={12} style={{ minHeight: '125px' }}>
            <NumberInput
              id="lendingRateEnd"
              label="Ende"
              value={item.lendingRateEnd}
              validation={validation.errors.lendingRateEnd}
              onValueChange={this.onValueChange}
            />
          </Col>

          <Col lg={3} md={4} sm={6} xs={12} style={{ minHeight: '125px' }}>
            <FormGroup>
              <Select
                id="lendingRateType"
                label="Typ"
                value={{ label: item.lendingRateType, value: item.lendingRateType }}
                validation={validation.errors.lendingRateType}
                allowEmpty={false}
                onValueChange={this.setValue}
                options={['brut', 'net'].map(type => ({ label: type, value: type }))}
              />
            </FormGroup>
          </Col>
        </Row>

      </Form>
    );
  }
}

EditForm.propTypes = {
  item: PropTypes.object,
  language: PropTypes.string,
  durations: PropTypes.array,
  useTypes: PropTypes.array,
  loanProducts: PropTypes.array,
  onItemChange: PropTypes.func,
};

EditForm.defaultProps = {
  item: {},
  language: 'en',
  durations: [],
  useTypes: [],
  loanProducts: [],
  onItemChange: null,
};

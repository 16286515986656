export default {
  key: 'isWorkguideCheckFulfilled',
  label: 'Prüfpunkt erfüllt',
  validation: 'isWorkguideCheckFulfilled',
  availableFor: ['Check'],
  get: function(params) {
    return {
      value: {
        type: 'string',
        validations: {
          isWorkguideCheckFulfilled: params
        }
      }
    };
  }
}

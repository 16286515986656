export function validateProductForm(product) {
  return {
    name: !product.name,
    category: !product.category,
    addition: {
      displayClass: product.showAddition && !product.addition.displayClass,
      outputClass: product.showAddition && !product.addition.outputClass,
    },
    documents: product.documents.map((document) =>
      ['url', 'source', 'title']
        .reduce((result, field) => ({...result, [field]: !document[field]}), {})
    ),
  };
}

export function isBasicProductValid(product) {
  const errors = validateProductForm(product);
  return !(errors.name ||
    errors.category ||
    errors.addition.displayClass ||
    errors.addition.outputClass ||
    errors.documents.some((it) => it.url || it.source || it.title)
  );
}

import { createSelector } from 'reselect';
import { getBasicProducts, getProducts } from './getBasicServices';

export const getNewProducts = createSelector([
  getProducts,
  getBasicProducts,
], (products, basicProducts) => {
  return products.filter(
    (product) => !basicProducts.some((basicProduct) => product.id === basicProduct.id)
  );
});

import './RefErrorsResultsDetails.css';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table, TableCell, TableRow } from '../Common/Table';
import { REF_ERRORS_PROP_TYPES_RESULT_ITEM } from './RefErrorsPropTypes';

export function RefErrorsResultsDetails({ item, refError }) {
  return (
    <Table className="ref-errors-results-details">
      <TableRow>
        <TableCell className="ref-errors-results-details__label">
          <FormattedMessage id="refErrors_resultsDetails_collectionName"/>
        </TableCell>
        <TableCell className="ref-errors-results-details__value">
          {item.collectionName}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="ref-errors-results-details__label">
          <FormattedMessage id="refErrors_resultsDetails_recordId"/>
        </TableCell>
        <TableCell className="ref-errors-results-details__value">
          {item.recordId}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="ref-errors-results-details__label">
          <FormattedMessage id="refErrors_resultsDetails_localField"/>
        </TableCell>
        <TableCell className="ref-errors-results-details__value">
          {refError.localField}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="ref-errors-results-details__label">
          <FormattedMessage id="refErrors_resultsDetails_relationType"/>
        </TableCell>
        <TableCell className="ref-errors-results-details__value">
          {refError.relationType}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="ref-errors-results-details__label">
          <FormattedMessage id="refErrors_resultsDetails_targetCollection"/>
        </TableCell>
        <TableCell className="ref-errors-results-details__value">
          {refError.targetCollection}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="ref-errors-results-details__label">
          <FormattedMessage id="refErrors_resultsDetails_targetCollectionField"/>
        </TableCell>
        <TableCell className="ref-errors-results-details__value">
          {refError.targetCollectionField}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="ref-errors-results-details__label">
          <FormattedMessage id="refErrors_resultsDetails_targetId"/>
        </TableCell>
        <TableCell className="ref-errors-results-details__value">
          {refError.targetId}
        </TableCell>
      </TableRow>
    </Table>
  );
}

RefErrorsResultsDetails.propTypes = {
  item: REF_ERRORS_PROP_TYPES_RESULT_ITEM,
};

export const FILE_EXTENSIONS = {
  'application/json': 'json',
  'application/pdf': 'pdf',
  'image/jpeg': 'jpeg',
  'image/tiff': 'tiff',
  'image/bmp': 'bmp',
  'image/png': 'png',
  'image/gif': 'gif',
  'text/plain': 'txt',
  'text/html': 'html',
};

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import Toggle from 'react-toggle';

class CustomerRelationsConfiguration extends React.PureComponent {
  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      editValueField,
      id,
      item
    } = this.props;

    return (
      <React.Fragment>
        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            Mehrfachauswahl
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
          <Toggle
              checked={get(item, 'props.multi', false)}
              onChange={() => editValueField(id, 'multi')}
          />
          </Col>
        </FormGroup>

        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            Selektion (Checkboxen) ausblenden
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
          <Toggle
              checked={get(item, 'props.hideSelection', false)}
              onChange={() => editValueField(id, 'prop', { key: 'hideSelection', value: !get(item, 'props.hideSelection', false) })}
          />
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

CustomerRelationsConfiguration.propTypes = {
  id: PropTypes.string.isRequired,
  editValueField: PropTypes.func,
  item: PropTypes.object.isRequired
};

CustomerRelationsConfiguration.defaultProps = {
  editValueField: noop
};

export default CustomerRelationsConfiguration;

import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, pick, noop } from 'lodash';
import { Row, Col, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import Toggle from 'react-toggle';

import './Connection.css';
import LoadButton from './LoadButton';
import { getValidator } from '../../globals';

const validationDefinition = {
  validations: {
    uri: {
      type: 'string',
      required: true
    },
    username: {
      type: 'string',
      required: true
    },
    password: {
      type: 'string',
      required: true
    }
  }
};

class Connection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      uri: undefined,
      username: undefined,
      password: undefined,
      useCurrent: false
    }

    this.onValueChange = this.onValueChange.bind(this);
    this.onUseCurrent = this.onUseCurrent.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  onValueChange(ev) {
    const { id, value = '' } = ev.target;

    const updated = get(value.trim(), 'length', 0) <= 0
      ? undefined
      : value;

    this.setState({ [id]: updated });
  }

  onUseCurrent() {
    const { useCurrent } = this.state;
    const { onUseCurrent, system } = this.props;

    this.setState({ useCurrent: !useCurrent });
    onUseCurrent(system, !useCurrent);
  }

  toggle() {
    const { collapsed } = this.state;

    this.setState({ collapsed: !collapsed });
  }

  validate() {
    const {
      uri,
      username,
      password
    } = this.state;

    const validator = getValidator();

    return validator.validate(validationDefinition, { uri, username, password });
  }

  renderButton(validations) {
    const {
      connection,
      onSubmit
    } = this.props;

    const requesting = get(connection, 'requesting', false);
    const fulfilled = get(connection, 'fulfilled', false);
    const disabled = (
      !isEmpty(validations)
      || fulfilled
      || requesting
    );

    const data = pick(this.state, ['uri', 'username', 'password']);

    return (
      <LoadButton
        disabled={disabled}
        error={get(connection, 'error', false)}
        fulfilled={fulfilled}
        onClick={() => onSubmit(data)}
        requesting={requesting}
      >
        {!fulfilled && !requesting && 'Verbinden'}
        {fulfilled && !requesting && 'Verbunden'}
      </LoadButton>
    );
  }

  renderForm() {
    const {
      uri = '',
      username = '',
      password = ''
    } = this.state;

    const validations = this.validate();

    return (
      <React.Fragment>
        <Row className="replay-audit-log-connection--row">
          <FormGroup>
            <Col componentClass={ControlLabel} lg={1}>
              URI
            </Col>

            <Col lg={11}>
              <FormControl
                id="uri"
                value={uri}
                onChange={this.onValueChange}
              />
            </Col>
          </FormGroup>
        </Row>

        <Row className="replay-audit-log-connection--row">
          <FormGroup>
            <Col componentClass={ControlLabel} lg={1}>
              Username
            </Col>

            <Col lg={11}>
              <FormControl
                id="username"
                value={username}
                onChange={this.onValueChange}
              />
            </Col>
          </FormGroup>
        </Row>

        <Row className="replay-audit-log-connection--row">
          <FormGroup>
            <Col componentClass={ControlLabel} lg={1}>
              Password
            </Col>

            <Col lg={11}>
              <FormControl
                id="password"
                type="password"
                value={password}
                onChange={this.onValueChange}
              />
            </Col>
          </FormGroup>
        </Row>

        <Row>
          <Col lg={12}>
            {this.renderButton(validations)}
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      collapsed,
      useCurrent
    } = this.state;
    const {
      connection,
      title
    } = this.props;

    const error = get(connection, 'error', false);
    const fulfilled = get(connection, 'fulfilled', false);

    return (
      <div className="replay-audit-log-connection">
        <Row className="replay-audit-log-connection--row">
          <Col lg={12}>
            <h3
              style={{ cursor: 'pointer' }}
              onClick={this.toggle}
            >
              {title}

              {fulfilled && (
                <span
                  className="mdi mdi-lan-connect"
                  style={{ paddingLeft: '10px', color: 'green' }}
                />
              )}

              {error && (
                <span
                  className="mdi mdi-alert-circle-outline"
                  style={{ paddingLeft: '10px', color: 'red' }}
                />
              )}

              {!fulfilled && !error && (
                <span
                  className="mdi mdi-lan-disconnect"
                  style={{ paddingLeft: '10px', color: 'black' }}
                />
              )}
            </h3>
          </Col>
        </Row>

        {!collapsed && (
          <React.Fragment>
            <Row className="replay-audit-log-connection--row">
              <FormGroup>
                <Col componentClass={ControlLabel} lg={1}>
                  Aktuelle Verbindung verwenden
                </Col>

                <Col lg={11}>
                  <Toggle
                    checked={useCurrent}
                    onChange={this.onUseCurrent}
                  />
                </Col>
              </FormGroup>
            </Row>

            {!useCurrent && this.renderForm()}

            {error && (
              <Row>
                <Col lg={12}>
                  {get(error, 'message', '')}
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

Connection.propTypes = {
  system: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  onUseCurrent: PropTypes.func
};

Connection.defaultProps = {
  onSubmit: noop,
  onUseCurrent: noop
};

export default Connection;

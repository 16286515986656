import { isEqual } from 'lodash';
import { createSelector } from "reselect";
import { getSections } from './promotionSelectors';
import { getLanguage } from '../commonSelectors';

export const getUpdatedSections = createSelector([
  getLanguage,
  getSections,
], (
  language,
  rawSections,
) => (sections, promotion) => {
  const allPromotionItems = sections.reduce((result, it) => [...result, ...it.items], []);
  const removedItemsIds = promotion
    ? promotion.checklistItem
      .filter((itemId) => !allPromotionItems.some((item) => item.id === itemId))
      .map((item) => item.id)
    : [];

  return rawSections.map((rawSection) => {
    const section = sections.find((it) => it.id === rawSection.id);
    if (!section) {
      return rawSection;
    }

    const newItems = section.items
      .filter((it) => !promotion || !promotion.checklistItem.includes(it.id))
      .map((item) => ({
        ...item,
        name: {
          ...item.name,
          en: item.name.en ? item.name.en : item.name[language],
        },
        displayCondition: getDisplayCondition(item.id),
      }));
    const updatedItems = rawSection.items
      .filter((it) => !removedItemsIds.includes(it.id))
      .map((rawItem) => {
        const item = section.items.find((it) => it.id === rawItem.id);
        if (!item) {
          return rawItem;
        }

        return {
          ...rawItem,
          name: {
            ...rawItem.name,
            en: rawItem.name.en ? rawItem.name.en : item.name[language],
            [language]: item.name[language],
          },
          order: item.order,
          displayCondition: getDisplayCondition(item.id),
        };
      })
      .concat(newItems);

    return {...rawSection, items: updatedItems};
  }).filter((updatedSection) => {
    const rawSection = rawSections.find((raw) => raw.id === updatedSection.id);
    return !isEqual(rawSection.items, updatedSection.items);
  });
});

function getDisplayCondition(itemId) {
  return `length(dossierData.promotion.consultation.items[*]`
    + `.{selected: selected, ref: length(promotionItem.checklistItem[?ends_with("$ref", '/${itemId}')])}`
    + `[?selected == \`true\` && ref > \`0\`]) > \`0\``;
}
import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { noop } from 'lodash';
import cl from 'classnames';

import './Board.css';
import { WORKFLOW_TREE_NODE } from '../../../../constants';

const WorkflowTreeBoard = React.memo(function WorkflowTreeBoard({
  children,
  className,
  onClick,
  onDrop
}) {
  const [{ hidden, isDragging, isOver }, dropRef] = useDrop(() => ({
    accept: [WORKFLOW_TREE_NODE],
    drop: onDrop,
    collect: (monitor) => {
      return {
        isDragging: monitor.internalMonitor.isDragging(),
        isOver: monitor.isOver()
      };
    }
  }));

  if (hidden) return null;

  const classNames = cl(className, {
    'workflow-tree-board': true,
    'workflow-tree-board--dragging': isDragging,
    'workflow-tree-board--hover': isOver
  });

  return (
    <div
      className={classNames}
      id="workflow-tree-board"
      onClick={onClick}
      ref={dropRef}
    >
      {children}
    </div>
  );
});

WorkflowTreeBoard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  onClick: PropTypes.func,
  onDrop: PropTypes.func
};

WorkflowTreeBoard.defaultProps = {
  children: [],
  className: undefined,
  onClick: noop,
  onDrop: noop
};

export default WorkflowTreeBoard;

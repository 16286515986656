import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get, noop, slice } from 'lodash';

import { SortableTable } from '../Common/SortableTable';
import { JOB_PROCESSING_PAGE_SIZE } from '../../constants/JobProcessingConstants';
import Pagination from '../Pagination/PaginationNoTotalCount';
import ExecutedJobsResultsItem from './ExecutedJobsResultsItem';

const ExecutedJobsResultsTable = props => {
  const {
    executed,
    executionHistory,
    intl,
    jobProcessingActions,
    language,
    onPageChange,
    onSort,
    sort
  } = props;

  const SORTABLE_FIELDS = [
      { key: 'success', label: intl.formatMessage({ id: 'jobProcessing_successful' }), sortFn: () => undefined },
      { key: 'createDate', label: intl.formatMessage({ id: 'jobProcessing_createDate' }), sortFn: () => undefined },
      { key: 'job', label: intl.formatMessage({ id: 'jobProcessing_job' }), sortFn: () => undefined },
      { key: 'customerNumberAlternate', label: intl.formatMessage({ id: 'jobProcessing_customer' }) },
      { key: 'message', label: intl.formatMessage({ id: 'jobProcessing_information' }), sortFn: () => undefined },
      { key: 'details', label: intl.formatMessage({ id: 'jobProcessing_details' }) },
      { key: 'history', label: intl.formatMessage({ id: 'jobProcessing_history' }) },
  ];

  const onSortChange = (field, type) => {
    onSort({ sortField: field, sortType: type });
  }

  const renderItem = (item) => (
    <ExecutedJobsResultsItem
      key={item.id}
      executionHistory={executionHistory}
      item={item}
      intl={intl}
      jobProcessingActions={jobProcessingActions}
      language={language}
    />
  );

  const {
    data = [],
    limit,
    offset,
    requesting
  } = executed

  const items = slice(data, offset, limit + offset);

  return (
    <div>
      <h2><FormattedMessage id="jobProcessing_results" /></h2>
      <SortableTable
        items={items}
        sortableFields={SORTABLE_FIELDS}
        renderItem={renderItem}
        className="bulk-loads-results__table"
        onSort={onSortChange}
        sortField={get(sort, 'sortField')}
        sortType={get(sort, 'sortType')}
        isExternalSortField={true}
      />

      <Pagination
        onPageChange={onPageChange}
        recordCount={get(data, 'length', 0)}
        requesting={requesting}
        pageSize={JOB_PROCESSING_PAGE_SIZE}
      />
    </div>
  );
};

ExecutedJobsResultsTable.propTypes = {
  executed: PropTypes.object,
  executionHistory: PropTypes.object,
  intl: PropTypes.object.isRequired,
  jobProcessingActions: PropTypes.object.isRequired,
  language: PropTypes.string,
  onFilter: PropTypes.func,
  onPageChange: PropTypes.func,
  onSort: PropTypes.func,
  sort: PropTypes.object
};

ExecutedJobsResultsTable.defaultProps = {
  executed: {},
  executionHistory: {},
  language: 'de',
  onFilter: noop,
  onPageChange: noop,
  onSort: noop,
  sort: {}
}

export default injectIntl(ExecutedJobsResultsTable);
import React from 'react';
import PropTypes from 'prop-types';
import { get, debounce } from 'lodash';
import { Col, FormGroup, ControlLabel, Tabs, Tab, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import update from 'immutability-helper';

const options = [{
  value: 'percent',
  label: 'Prozentual'
}, {
  value: 'amount',
  label: 'Betrag oder Anzahl'
}];

function getInputTypeFromSpecialConfig(config = []) {
  return get(
    config.find((c) => get(c, 'key') === 'inputType'),
    'value',
    'percent'
  );
}

function getHintFromSpecialConfig(config = []) {
  return get(
    config.find((c) => get(c, 'key') === 'hint'),
    'value',
    {}
  );
}

class FundinvestFunds extends React.Component {
  constructor(props) {
    super(props);

    const {
      editValueField,
      item
    } = props;

    const specialComponentConfig = get(item, 'props.specialComponentConfig', []);

    this.state = {
      activeTab: 'de',
      inputType: getInputTypeFromSpecialConfig(specialComponentConfig),
      hint: getHintFromSpecialConfig(specialComponentConfig)
    };

    this.onInputTypeChange = this.onInputTypeChange.bind(this);
    this.onHintChange = this.onHintChange.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.debouncedEditValueField = debounce(editValueField, 500);
  }

  onInputTypeChange(selected) {
    const { id, item } = this.props;
    const { hint } = this.state;

    const inputType = get(selected, 'value');
    this.setState({ inputType: get(selected, 'value') });

    const config = get(item, 'props.specialComponentConfig', []).filter((s) => !['inputType', 'hint'].includes(get(s, 'key')));

    const value = [
      ...config, {
        key: 'inputType',
        value: inputType
      }, {
        key: 'hint',
        value: hint
      }];

    this.debouncedEditValueField(id, 'prop', { key: 'specialComponentConfig', value });
  }

  onHintChange(ev) {
    const { inputType, hint, activeTab } = this.state;
    const { id, item } = this.props;
    const inputValue = get(ev, 'target.value');

    const config = get(item, 'props.specialComponentConfig', []).filter((s) => !['inputType', 'hint'].includes(get(s, 'key')));
    const updated = update(hint, { [activeTab]: { $set: inputValue } });
    this.setState({ hint: updated });

    const value = [
      ...config, {
        key: 'inputType',
        value: inputType
      }, {
        key: 'hint',
        value: hint
      }];

    this.debouncedEditValueField(id, 'prop', { key: 'specialComponentConfig', value });
  }

  /**
   * Set current active tab
   *
   * @param {String} language Selected language
   */
  setActiveTab(language) {
    this.setState({ activeTab: language });
  }

  renderTabs() {
    const { languages } = this.props;

    return languages.map(l => (
      <Tab key={l} eventKey={l} title={l}>
        <FormControl
          type="text"
          componentClass="textarea"
          value={get(this, `state.hint.${l}`, '')}
          onChange={this.onHintChange}
        />
      </Tab>
    ));
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      activeTab,
      inputType
    } = this.state;

    return (
      <div>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Art der Eingabe
          </Col>

          <Col sm={9}>
            <Select
              options={options}
              onChange={this.onInputTypeChange}
              value={inputType}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Hint (* Text)
          </Col>

          <Col sm={9}>
            <Tabs
              id="action-title"
              activeKey={activeTab}
              onSelect={this.setActiveTab}
            >
              {this.renderTabs()}
            </Tabs>
          </Col>
        </FormGroup>
      </div>
    );
  }
}

FundinvestFunds.propTypes = {
  languages: PropTypes.array
};

FundinvestFunds.defaultProps = {
  languages: ['de', 'fr', 'en']
};

export default FundinvestFunds;

import './ExternalLinkItem.css';

import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Modal } from 'react-bootstrap';
import DragHandle from '../DragHandle';
import { ModalBody, ModalDialog, ModalFooter, ModalHeader } from '../Common/Modal';
import { Button } from '../Common/Button';
import { ExternalLinkDetails } from './ExternalLinkDetails';
import { classList } from '../../utils/classList';
import { isExternalLinkValid } from './ExternalLinkValidator';

class Item extends React.Component {
  constructor() {
    super();
    this.state = {
      isModalOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  render() {
    const { link, language } = this.props;
    const url = link.translatedUrl[language] || link.url;
    const text = link.name[language];

    const classes = classList(
      'external-link-item',
      'SortableHOCItem',
      {'external-link-item--invalid': !isExternalLinkValid(link, language)},
    );

    return (
      <div className={classes}>
        <div className="external-link-item__favicon">
          {this.renderIcon(url)}
        </div>

        <div className="external-link-item__text">
          <div className="external-link-item__name">{text}</div>
          <div className="external-link-item__link">{url}</div>
        </div>

        <div className="external-link-item__control">
          <span className="icon-025-edit" onClick={this.openModal}/>
          <span className="icon-008-bin" onClick={this.onRemove}/>
        </div>

        <div className="external-link-item__drag">
          <DragHandle/>
        </div>

        {this.renderModal()}
      </div>
    );
  }

  renderIcon(url) {
    const parser = document.createElement('a');
    parser.href = url;
    const showFavicon = parser.hostname && parser.hostname !== "localhost";
    if (!showFavicon) {
      return null;
    }

    return (
      <img
        height="24"
        width="24"
        alt="drag handle"
        src={`https://www.google.com/s2/favicons?domain=${parser.hostname}`}
      />
    );
  }

  renderModal() {
    const { isModalOpen } = this.state;
    const { link, language, categories } = this.props;
    const text = link.name[language];

    return (
      <ModalDialog show={isModalOpen} onHide={this.closeModal} bsSize="large">
        <ModalHeader closeButton>
          <Modal.Title>
            <div className="external-link-item__modal-title">{text}</div>
            <div>{link.id}</div>
          </Modal.Title>
        </ModalHeader>
        <ModalBody>
          <ExternalLinkDetails
            link={link}
            onChange={this.onChange}
            categories={categories}
            language={language}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.closeModal}>
            Schliessen
          </Button>
        </ModalFooter>
      </ModalDialog>
    );
  }


  onRemove() {
    const { link, onRemove } = this.props;
    onRemove(link);
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  onChange(link) {
    const { onChange } = this.props;
    onChange(link);
  }
}

export const ExternalLinkItem = SortableElement(Item);

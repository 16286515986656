import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import { getFinancingRatesChangedForwards } from '../../selectors/FinancingRates/financingRatesForwardSelectors';
import { EndpointName } from '../../constants/EndpointName';
import { createRef } from '../../utils';

export function* financingRatesSaveForwardRatesSaga() {
  const changedForwards = yield select(getFinancingRatesChangedForwards);

  yield all(
    changedForwards.map((forward) => call(axios, {
      method: 'put',
      url: createRef(EndpointName.FINANCING_FORWARD, forward.id),
      data: forward,
    })),
  );
}

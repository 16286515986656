import _ from 'lodash';
import update from 'immutability-helper';
import {
  GET_METADATA,
  PUT,
  POST,
  DEL,
  SET_DATA,
  GET_BUTTONS,
  GET_MODULES,
} from '../actions/DocumentActions';

const initialState = {
  documents: [],
  modules: [],
  screens: [],
  buttons: [],
  selectedModule: 'all',
  itemsPerPage: 0,
  fileId: '',
  totalItems: 0,
  requesting: false,
  requestingChunk: false,
  error: {},
  hasError: false,
  showButtons: false,
  newDocumentUrl: '',
  newDocumentMetadata: {},
  searchString: '',
};

export default function DocumentReducer(state = initialState, action) {
  switch (action.type) {
    case `${GET_METADATA}_PENDING`: {
      return {
        ...state, requesting: !action.chunked, requestingChunk: !!action.chunked, hasError: false,
      };
    }
    case `${GET_METADATA}_FULFILLED`: {
      let documents = [];
      if (action.chunked) {
        documents = update(state.documents, { $push: action.data });
      } else {
        documents = update(state.documents, { $set: action.data });
      }
      return {
        ...state,
        requesting: false,
        requestingChunk: false,
        documents: _.sortBy(documents, o => o.metadata.order),
        totalItems: action.headers['x-total-count'] ?
          parseInt(action.headers['x-total-count'], 10) : action.data.length,
      };
    }
    case `${GET_METADATA}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.error,
      };
    }

    case `${GET_MODULES}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${GET_MODULES}_FULFILLED`: {
      return {
        ...state,
        requesting: false,
        modules: action.payload.modules,
        screens: action.payload.screens,
      };
    }
    case `${GET_MODULES}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload.data,
      };
    }

    case `${GET_BUTTONS}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${GET_BUTTONS}_FULFILLED`: {
      return { ...state, requesting: false, buttons: action.payload.data };
    }
    case `${GET_BUTTONS}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload.data,
      };
    }
    case `${PUT}_PENDING`: {
      const documentIndex = _.findIndex(
        state.documents,
        document => document.id === action.meta.id,
      );
      return {
        ...state,
        hasError: false,
        documents: update(state.documents, {
          [documentIndex]: { loading: { $set: true } },
        }),
      };
    }
    case `${PUT}_FULFILLED`: {
      const documentIndex = _.findIndex(
        state.documents,
        document => document.id === action.meta.id,
      );
      if (documentIndex < 0) {
        return state;
      }
      return {
        ...state,
        documents: update(state.documents, {
          [documentIndex]: {
            metadata: { $set: action.meta.metadata },
            links: { $set: action.meta.links },
            loading: { $set: false },
          },
        }),
      };
    }
    case `${PUT}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case `${POST}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${POST}_FULFILLED`: {
      return { ...state, requesting: false, newDocumentUrl: action.payload };
    }
    case `${POST}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case `${POST}_METADATA_PENDING`: {
      return { ...state, requesting: false, hasError: false };
    }
    case `${POST}_METADATA_FULFILLED`: {
      return { ...state, requesting: false, documents: [...state.documents, action.payload] };
    }
    case `${POST}_METADATA_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case `${DEL}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${DEL}_FULFILLED`: {
      return {
        ...state,
        requesting: false,
        documents: state.documents.filter(document => document.id !== action.meta.id),
      };
    }
    case `${DEL}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case SET_DATA: {
      return { ...state, [action.key]: action.value };
    }
    default: {
      return state;
    }
  }
}

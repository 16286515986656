import React from 'react';
import PropTypes from 'prop-types';
import { isNil, upperFirst } from 'lodash';
import { FormattedMessage } from 'react-intl';

import './Label.css';

const FormFieldLabel = React.memo(function FormFieldLabel({
  children,
  id
}) {
  return (
    <div className="workflow-tree-form-element--label">
      {isNil(children) && (
        <FormattedMessage id={`Workguide.Workflow.Form.Field.${upperFirst(id)}`} />
      )}

      {!isNil(children) && children}
    </div>
  );
});

FormFieldLabel.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string
};

FormFieldLabel.defaultProps = {
  children: null,
  id: undefined
};

export default FormFieldLabel;

import React from 'react';
import { FormRow } from '../FormControls';
import { UiNotesImageField } from './UiNotesImageField';

export function UiNotesFormImageRow(props) {
  const { input, ...restProps } = props;

  return (
    <FormRow {...props}>
      <UiNotesImageField
        value={input.value}
        onChange={input.onChange}
        {...restProps}/>
    </FormRow>
  );
}

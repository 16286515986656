import React from 'react';
import { Button } from 'react-bootstrap';
import { SortableContainer } from 'react-sortable-hoc';
import { BasicProductsItem } from './BasicProductsItem';

function List(props) {
  const {
    products,
    language,
    categories,
    additions,
    documents,
    displayClasses,
    detailGroups,
    changeTypes,
    outputClasses,
    coreTypes,
    createProduct,
    saveProduct,
    removeProduct,
  } = props;

  const items = products.map((product, index) => (
    <BasicProductsItem
      key={product.id}
      index={index}
      product={product}
      language={language}
      categories={categories}
      additions={additions}
      documents={documents}
      displayClasses={displayClasses}
      detailGroups={detailGroups}
      changeTypes={changeTypes}
      outputClasses={outputClasses}
      coreTypes={coreTypes}
      saveProduct={saveProduct}
      removeProduct={removeProduct}
    />
  ));

  return (
    <div>
      <div className="SortableHOCList">
        {items}
      </div>
      <Button bsStyle="primary" name="newItem" onClick={createProduct}>
        <span className="icon-061-plus"/> Neuer Eintrag
      </Button>
    </div>
  );
}

export const BasicProductsList = SortableContainer(List);

import PropTypes from 'prop-types';

export const EMAIL_SUBSCRIPTIONS_PROP_TYPES_ITEM = PropTypes.shape({
  id: PropTypes.string,
  view: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    subscriptionType: PropTypes.string,
    subscriptionState: PropTypes.string,
    createDate: PropTypes.string,
  }),
  form: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    language: PropTypes.string,
    gender: PropTypes.string,
    email: PropTypes.string,
    subscriptionType: PropTypes.array,
    subscriptionState: PropTypes.string,
  }),
});

export const EMAIL_SUBSCRIPTIONS_PROP_TYPES_SEARCH_PARAMS = PropTypes.shape({
  pageNumber: PropTypes.number,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  subscriptionTypes: PropTypes.string,
  subscriptionStates: PropTypes.string,
});

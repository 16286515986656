import jmespath from '@metrichor/jmespath-plus';
import { isArray, every } from 'lodash';

import { convertValue, convertValues } from './convert';

export default function jmesConditionNe(config, data) {
  const { path, type, value = '' } = config;

  const values = convertValues(value.split(','), type);
  const resolved = jmespath.search(data, path);
  const converted = isArray(resolved)
    ? convertValues(resolved, type)
    : convertValue(resolved, type);

  return isArray(converted)
    ? type === 'date'
      ? every(values, (value) => every(converted, (c) => !value.isSame(c)))
      : every(values, (v) => !converted.includes(v))
    : type === 'date'
      ? every(values, (value) => !value.isSame(converted))
      : every(values, (v) => v !== resolved);
}

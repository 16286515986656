import { put, select } from 'redux-saga/effects';
import { HOLIDAYS_REMOVE_ACTIONS } from '../../actions/HolidaysActions';
import { getHolidays, getHolidaysList } from '../../selectors/holidaysSelectors';

export function* holidaysRemoveSaga({ data: { item } }) {
  const holidays = yield select(getHolidays);
  const holidaysList = yield select(getHolidaysList);
  const updatedDays = holidaysList.dateRanges.filter((it) => it !== item);

  const updatedHolidays = holidays.map((list) => holidaysList && list.id === holidaysList.id
    ? { ...list, dateRanges: updatedDays }
    : list,
  );
  yield put(HOLIDAYS_REMOVE_ACTIONS.success({ holidaysLists: updatedHolidays }));
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { ListGroup } from 'react-bootstrap';

import './List.css';
import ListItem from './ListItem';

const EmailSubscriptionsExportList = React.memo(({
  language,
  onExportRequest,
  requesting,
  subscriptionTypes
}) => {
  const children = subscriptionTypes.map((type) => {
    return (
      <ListItem
        key={get(type, 'id')}
        language={language}
        requesting={requesting}
        subscriptionType={type}
        onExportRequest={onExportRequest}
      />
    );
  });

  return (
    <div className="email-subscriptions-export-type-list">
      <h3>
        Abonnomentarten
      </h3>

      <ListGroup>
        {children}
      </ListGroup>
    </div>
  );
});

EmailSubscriptionsExportList.propTypes = {
  language: PropTypes.string,
  onExportRequest: PropTypes.func,
  requesting: PropTypes.bool,
  subscriptionTypes: PropTypes.array
};

EmailSubscriptionsExportList.defaultProps = {
  language: 'de',
  onExportRequest: noop,
  requesting: false,
  subscriptionTypes: []
};

export default EmailSubscriptionsExportList;

import { Reducers } from '@evoja-web/redaction';

import { reducer as definitionsReducer } from './JobProcessing/Definitions';
import { reducer as executedReducer } from './JobProcessing/Executed';
import { reducer as executionHistoryReducer } from './JobProcessing/ExecutionHistory';
import { reducer as summaryReducer } from './JobProcessing/Summary';
import { reducer as triggerJobReducer } from './JobProcessing/TriggerJob';

const reducers = Reducers([
  definitionsReducer,
  executedReducer,
  executionHistoryReducer,
  summaryReducer,
  triggerJobReducer
]);

export default reducers.get();
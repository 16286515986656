import { FINANCING_RATES_LOAD_ACTIONS } from '../../actions/FinancingRatesActions';

const initialState = {
  durations: [],
  forwards: [],
  baseRates: [],
  refiRates: [],
  forwardPeriods: [],
  initialData: {
    durations: [],
    forwards: [],
    baseRates: [],
    refiRates: [],
  },
  loading: true,
  error: undefined,
};

export function FinancingRatesLoadReducer(state = initialState, action) {
  switch (action.type) {
    case FINANCING_RATES_LOAD_ACTIONS.PENDING:
      return { ...state, loading: true, error: undefined };
    case FINANCING_RATES_LOAD_ACTIONS.SUCCESS:
      const { durations, forwards, baseRates, refiRates, forwardPeriods } = action.data;
      return {
        ...state,
        loading: false,
        error: undefined,
        durations,
        forwards,
        baseRates,
        refiRates,
        forwardPeriods,
        initialData: {
          durations,
          forwards,
          baseRates,
          refiRates,
        },
      };
    case FINANCING_RATES_LOAD_ACTIONS.FAILURE:
      return { ...state, loading: false, error: action.data };
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cl from 'classnames';

import './Section.css';

class LeadMassMutationSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false
    };

    this.onToggle = this.onToggle.bind(this);
  }

  /**
   * Expand / collapse this section
   *
   * @return  {[type]}  [return description]
   */
  onToggle() {
    const { collapsed } = this.state;

    this.setState({ collapsed: !collapsed });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { collapsed } = this.state;
    const {
      children,
      disabled,
      title
    } = this.props;

    return (
      <div className="lead-mass-mutation--section">
        <div
          className="lead-mass-mutation--section-title"
          onClick={this.onToggle}
        >
          {title}

          <div
            className={cl({
              'lead-mass-mutation--section-title--exlapse': true,
              mdi: true,
              'mdi-chevron-down': collapsed,
              'mdi-chevron-up': !collapsed
            })}
          />
        </div>

        <div className={cl({
          'lead-mass-mutation--section-content': true,
          'lead-mass-mutation--section-content-collapsed': collapsed
        })}
        >
          <fieldset disabled={disabled}>
            {children}
          </fieldset>
        </div>
      </div>
    );
  }
}

LeadMassMutationSection.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ])
};

LeadMassMutationSection.defaultProps = {
  children: [],
  disabled: false,
  title: ''
};

export default LeadMassMutationSection;

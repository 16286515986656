import React from 'react';
import PropTypes from 'prop-types';
import { chain, noop } from 'lodash';
import { CodeSelect } from '@evoja-web/react-form';

const OrderOriginSelect = React.memo(({
  codes,
  id,
  language,
  multi,
  onChange,
  value
}) => {
  const ids = [
    'orderOrigin-AU',
    'orderOrigin-PU',
    'orderOrigin-PO'
  ];

  const filtered = chain(codes)
    .get('groups.orderOrigin', [])
    .filter((c) => ids.includes(c.id))
    .value();

  return (
    <CodeSelect
      codes={filtered}
      id={id}
      language={language}
      multi={multi}
      onChange={onChange}
      value={value}
    />
  );
});

OrderOriginSelect.propTypes = {
  codes: PropTypes.object,
  id: PropTypes.string.isRequired,
  language: PropTypes.string,
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
};

OrderOriginSelect.defaultProps = {
  codes: { groups: {} },
  language: 'de',
  multi: false,
  onChange: noop,
  value: undefined
};

export default OrderOriginSelect;

import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';
import { EndpointName } from '../../constants/EndpointName';
import { ACCOUNT_WORKGUIDES_UPDATE } from '../../actions/WorkguideStatusActions';

export function* workguideStatusUpdateWorkguideSaga(action) {
  try {
    yield put(ACCOUNT_WORKGUIDES_UPDATE.pending());

    const updatedWorkguide = get(action, 'data.workguide', undefined);
    if (!updatedWorkguide) throw new Error('No workguide in request');
    yield call(axios, {
        method: 'put',
        url: `${EndpointName.PROVISION_WORKGUIDE}/${updatedWorkguide.id}`,
        data: updatedWorkguide,
      });

    yield put(ACCOUNT_WORKGUIDES_UPDATE.success());
  } catch (error) {
    yield put(ACCOUNT_WORKGUIDES_UPDATE.failure(error));
  }
}

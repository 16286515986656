import './EmailSubscriptionsUpload.css';

import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import ReactJson from 'react-json-view';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Papa from 'papaparse';
import validator from 'validator';

import { classList } from '../../utils/classList';
import { ExpandContent } from '../Common/ExpandContent';
import { Button } from '../Common/Button';
import { ModalBody, ModalDialog, ModalFooter, ModalHeader } from '../Common/Modal';
import confirmSubmit from '../ConfirmSubmit';

export class EmailSubscriptionsUpload extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      isModalOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deletedMarketing !== this.props.deletedMarketing) {
      this.setState((prev) => ({ isModalOpen: !prev.isModalOpen }));
    }
  }

  render() {
    const { expanded, isModalOpen } = this.state;
    const {
      deleteMarketing, deletedMarketing, onCsv, creator, intl
    } = this.props;

    const iconClasses = classList({
      'icon-013-chevron-down': true,
      'email-subscriptions-search__icon': true,
      'email-subscriptions-search__icon--collapsed': !expanded,
    });

    return (
      <div className="email-subscriptions-upload">
        <div className="email-subscriptions-upload__header" onClick={this.toggleExpanded}>
          <div className="email-subscriptions-upload__title">
            <FormattedMessage id="emailSubscriptions_upload" />
          </div>
          <div className={iconClasses} />
        </div>

        <ExpandContent expanded={expanded} className="email-subscriptions-upload__body">
          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', alignItems: 'center' }}>
            <Button bsStyle="secondary" onClick={this.downloadCsv}>
              <a href="/images/csv/marketing.csv" download="marketing.csv"><FormattedMessage id="emailSubscriptions_csv_template" /></a>
            </Button>
            <div className="email-subscriptions-upload-info-modal">
              <Button bsStyle="danger" onClick={() => confirmSubmit({ intl, confirmFunction: () => deleteMarketing() })}>
                <FormattedMessage id="emailSubscriptions_delete_marketing" />
              </Button>
              {this.props.deletedMarketing.length > 0 && (
                <OverlayTrigger placement="right" overlay={<Tooltip id="delete">Gelöschte elemente anzeigen</Tooltip>}>
                  <span onClick={this.toggleModal}>
                    <i className="mdi mdi-information-outline info-icon" />
                  </span>
                </OverlayTrigger>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 20 }}>
            <Dropzone style={{ with: 200, border: 'dotted' }} accept=".csv" onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles, onCsv, creator)} multiple={false}>
              <h1 className="text-center">
                <i className="icon-088-upload-cloud" />
              </h1>
              <p className="text-center">
                <FormattedMessage id="leadsSwitchImport_drop_text" />
              </p>
            </Dropzone>
          </div>
        </ExpandContent>

        <ModalDialog show={isModalOpen} onHide={this.toggleModal}>
          <ModalHeader closeButton>
            <div>
              <FormattedMessage id="emailSubscriptions_deleted_marketing" />
            </div>
          </ModalHeader>
          <ModalBody>
            <ReactJson
              src={deletedMarketing.sort((x, y) => {
                return x !== y ? 0 : x ? -1 : 1;
              })}
              name={false}
              displayDataTypes={false}
              collapsed={2}
              indentWidth={2}
              iconStyle="triangle"
              onAdd={false}
              onEdit={false}
              onDelete={false}
              enableClipboard={false}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleModal} bsSize="sm">
              <FormattedMessage id="common_cancel" />
            </Button>
          </ModalFooter>
        </ModalDialog>

      </div>
    );
  }

  toggleModal = () => this.setState((prev) => ({ isModalOpen: !prev.isModalOpen }));

  toggleExpanded = () => this.setState((prev) => ({ expanded: !prev.expanded }));

  displayErrors(rejectedFiles) {
    rejectedFiles.forEach((file) => {
      toast.error(`${file.name} hat den falschen Dateityp. Es werden nur CSV-Dateien akzeptiert`, {
        autoClose: 10000,
        position: toast.POSITION.TOP_CENTER,
      });
    });
  }

  async onDrop(acceptedFiles, rejectedFiles, onCsv, creator) {
    const author = `${get(creator, 'session.firstName')} ${get(creator, 'session.lastName')}`;
    const file = acceptedFiles[0];
    toast.info(`${file.name} wurde akzeptiert`, { autoClose: 2000 });

    const { result, error } = await validateCsv(file);
    if (result.length > 0) {
      toast(<div>
        <p>{`${file.name} wurde akzeptiert. Folgende Fehler wurden gefunden:`}</p>
        <ul>
          {result.map((el) => <li>{el}</li>)}
        </ul>
      </div>, { type: 'warning', autoClose: 20000 });
    }
    onCsv(file, author);

    if (rejectedFiles) {
      this.displayErrors(rejectedFiles);
    }
  }
}

const validateCsv = async (file) => {
  const errors = [];
  const result = new Promise((complete, error) => {
    Papa.parse(file, {
      header: true,
      step: (results, parser) => {
        parser.pause();
        Object.keys(results.data).forEach((el) => {
          if (el === 'language' && !['DE', 'FR', 'EN'].includes(results.data[el])) errors.push(`${results.data[el]} is a unknown language`);
          if (el === 'gender' && !['Herr', 'Frau'].includes(results.data[el])) errors.push(`${results.data[el]} is a unknown gender`);
          if (el === 'email' && !validator.isEmail(results.data[el])) errors.push(`${results.data[el]} is a unknown email address`);
        });
        parser.resume();
      },
      complete,
      error
    });
  });
  return result.then(() => {
    return { result: errors };
  }).catch((error) => {
    console.error(error);
    return { error };
  });
};

EmailSubscriptionsUpload.propTypes = {};

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  CONSULTANTS_FETCH_ACTIONS,
  CONSULTANTS_CREATE_ACTIONS,
  CONSULTANTS_DELETE_ACTIONS,
  CONSULTANTS_UPDATE_ACTIONS,
} from '../actions/ConsultantsActions';
import {
  getConsultants,
  getConsultantsLoading,
  getConsultantsSaving,
} from '../selectors/Consultants/consultantsSelectors';
import Consultants from '../components/Consultants/Consultants';

const mapStateToProps = createStructuredSelector({
  consultants: getConsultants,
  loading: getConsultantsLoading,
  saving: getConsultantsSaving,
});

const mapDispatchToProps = {
  fetchConsultants: () => CONSULTANTS_FETCH_ACTIONS.request(),
  createConsultant: ({ form }) => CONSULTANTS_CREATE_ACTIONS.request({ form }),
  deleteConsultant: ({ consultant }) => CONSULTANTS_DELETE_ACTIONS.request({ consultant }),
  updateConsultant: ({ consultant, form }) => CONSULTANTS_UPDATE_ACTIONS.request({ consultant, form }),
};

export const ConsultantsPage = connect(mapStateToProps, mapDispatchToProps)(Consultants);

import { Reducers } from '@evoja-web/redaction';

import { reducer as leadHistoryReducer } from './LeadHistory';
import { reducer as saveLeadHistoryReducer } from './SaveLeadHistory';

export const reducers = Reducers([
  leadHistoryReducer,
  saveLeadHistoryReducer
]);

export default reducers.get();

import moment from 'moment';
import axios from 'axios';
import { all, call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { ANALYTICS_ACTIVE_USERS_LOAD } from '../../actions/AnalyticsActions';
import { ANALYTICS_TEXT_BLOCKS_MAP } from '../../constants/AnalyticsTextBlocks';
import { historyLoadSaga } from './analyticsLoadHistorySaga';

export function* loadActiveUsersSaga() {
  try {
    yield put(ANALYTICS_ACTIVE_USERS_LOAD.pending());

    const textBlocksIds = Object.values(ANALYTICS_TEXT_BLOCKS_MAP);
    const startDate = moment().startOf('month');
    const endDate = moment().endOf('date');

    const [
      { data: activeSessions },
      { data: consultants },
      { data: textBlocks },
      { history, items: historyItems },
    ] = yield all([
      call(axios, {
        url: `${EndpointName.ANALYTICS_ACTIVE_SESSIONS}?limit(999999)`,
        timeout: 60000
      }),
      call(axios, {
        url: `${EndpointName.PERSON_CONSULTANT}/?limit(999999)`,
        timeout: 60000
      }),
      call(axios, {
        url: `${EndpointName.CORE_TEXT_BLOCK}/?in(id,(${textBlocksIds.join(',')}))`,
        timeout: 60000
      }),
      call(historyLoadSaga, startDate, endDate),
    ]);

    yield put(ANALYTICS_ACTIVE_USERS_LOAD.success({ activeSessions, consultants, textBlocks, history, historyItems }));
  } catch (error) {
    yield put(ANALYTICS_ACTIVE_USERS_LOAD.failure(error));
  }
}

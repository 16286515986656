import './PromotionsItem.css';

import React  from 'react';
import { Col } from 'react-bootstrap';
import { ImagePreview } from './FilePreview';
import PromotionModal from './Modal';
import Spinner from '../Spinner';

export class PromotionsItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.removePromotion = this.removePromotion.bind(this);
  }

  openModal() {
    this.setState({isModalOpen: true});
  }

  closeModal() {
    this.setState({isModalOpen: false});
  }

  removePromotion() {
    const { promotion, removePromotion } = this.props;
    removePromotion(promotion);
  }

  render () {
    const { promotion, onSave, sections, language, schema, modules, customerSegments, checklist, isNew } = this.props;
    const { isModalOpen } = this.state;
    return (
      <Col md={3} sm={4} xs={1} key={promotion.id} className="promotions-item">
        <PromotionModal
          bsSize="large"
          show={isModalOpen}
          onHide={this.closeModal}
          onSave={onSave}
          promotion={promotion}
          customerSegments={customerSegments}
          checklist={checklist}
          sections={sections}
          language={language}
          schema={schema}
          modules={modules}
        />
        {isNew ? this.renderCreateButton() : this.renderImagePreview()}
      </Col>
    );
  }

  renderCreateButton() {
    return (
      <div className="promotions-item__create" onClick={this.openModal} >
        <h4>Neue Promition hinzufügen</h4>
        <span>+</span>
      </div>
    );
  }

  renderImagePreview() {
    const { promotion } = this.props;

    if (promotion.saving) {
      return <Spinner/>;
    }

    return (
      <div className="promotions-item__body">
        <h5>
          { promotion.title }
          <span className="pull-right" onClick={this.removePromotion}>
              <span className="icon-008-bin"/>
            </span>
        </h5>
        <ImagePreview file={promotion.image}
                      onClick={this.openModal}
                      thumbnail={true}/>
      </div>
    );
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Fuse from 'fuse.js';
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import uuid from 'uuid';
import { Link, withRouter } from 'react-router-dom';

import * as workguideActions from '../actions/WorkguideActions';
import email from '../components/Workguide/email.png';
import unknown from '../components/Workguide/unknown.png';
import WorkguideList from './WorkguideList';
import { getCommonSearch } from '../selectors/commonSelectors';

class Workguide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      suggestions: []
    };
    this.newWorkguide = this.newWorkguide.bind(this);
  }

  componentDidMount() {
    const { codeGroups, consultants, actions } = this.props;
    actions.fetchWorkguideUsageRequest();
    actions.getWorkguidesMetadataRequest(
      {
        consultants: !(consultants.length > 0),
        codegroups: !(codeGroups.length > 0),
      }
    );
  }

  getSuggestions = (value) => {
    const { language, workguides } = this.props;
    const options = {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        `keyword.${language}`,
        `title.${language}`
      ]
    };
    const fuse = new Fuse(workguides, options);
    return (fuse.search(value, options));
  };

  getSuggestionValue = (suggestion) => {
    return suggestion.title[this.props.language];
  };

  getSuggestionImage = (suggestion) => {
    if (suggestion.type === 'email') return email;
    return unknown;
  };

  renderSuggestion = (suggestion, { query }) => {
    const { language } = this.props;
    return (
      <div>
        <span>
          <img alt="workguide type" src={this.getSuggestionImage(suggestion)} width="48px" />
        </span>
        <span>
          {suggestion.title[language]}
        </span>
      </div>
    );
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    this.props.history.push(`/workguidedetail/${suggestion.id}`);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    setTimeout(() => {
      if (value === this.state.value) {
        this.setState({
          suggestions: this.getSuggestions(value)
        });
      }
    }, 200);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  newWorkguide() {
    const { actions, history } = this.props;
    const id = uuid.v4();
    const newWorkguide = {
      id,
      type: 'info',
      active: false,
      title: {
        de: 'Neuer Workguide',
        fr: 'Nouveau Workguide',
        en: 'New Workguide'
      },
      excludeFromParamTransfer: true,

    };
    actions.setData({ value: newWorkguide, key: 'workguide' });
    actions.updateWorkguideRequest(newWorkguide);
    history.push(`/workguidedetail/${id}`);
  }

  render() {
    const {
      workguideUsage, workguides, requesting, hasError, search, consultants
    } = this.props;

    return (
      <WorkguideList
        requesting={requesting}
        hasError={hasError}
        workguideUsage={workguideUsage}
        workguides={workguides}
        onNewWorkguide={this.newWorkguide}
        consultants={consultants}
        search={search}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.login.language,
    hasError: state.workguide.hasError,
    requesting: state.workguide.requesting,
    workguides: state.workguide.workguides,
    consultants: state.workguide.consultants,
    codeGroups: state.workguide.codeGroups,
    workguideUsage: state.workguide.workguideUsage,
    search: getCommonSearch(state),
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(workguideActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(Workguide)
);

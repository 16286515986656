import {
  FINANCING_RATES_SYNC_FORWARDS_ACTIONS,
  FINANCING_RATES_UPDATE_FORWARDS_ACTIONS,
} from '../../actions/FinancingRatesActions';

export function FinancingRatesUpdateForwardsReducer(state, action) {
  switch (action.type) {
    case FINANCING_RATES_UPDATE_FORWARDS_ACTIONS.SUCCESS:
    case FINANCING_RATES_SYNC_FORWARDS_ACTIONS.SUCCESS:
      return { ...state, forwards: action.data.forwards };
    default:
      return state;
  }
}

import update from 'immutability-helper';
import _ from 'lodash';
import { CORE_CONFIG_LOAD, CORE_CONFIG_SAVE, SET, SET_MODULE } from '../actions/CoreConfigActions';

export default function CoreConfigReducer(
  state = {
    configs: [],
    modules: [],
    initialConfigs: [],
    initialModules: [],
    selectedPanel: 'config',
    requesting: false,
    error: {},
    hasError: false,
    changed: false,
    keys: [
      { key: 'calculativeRetirementDuration', label: 'Kalkulative Pensionierungsdauer' },
      { key: 'deathCapitalStep', label: 'Todeskapital Stufen' },
      { key: 'defaultLivingCostsAfterRetirement', label: 'Lebenskosten vor Pensionierung' },
      { key: 'defaultLivingCostsBeforeRetirement', label: 'Lebenskosten nach Pensionierung' },
      { key: 'defaultLivingCostsInPercent', label: 'Total Lebenskosten in Prozent' },
      { key: 'defaultNeededIncomeForDeath', label: 'Standardeinkommen bei Tod' },
      { key: 'defaultNeededIncomeForDisability', label: 'Standardeinkommen bei Invalidität' },
      { key: 'disabilityBenefitStep', label: 'Invaliditätszuschlag Schritte' },
      { key: 'interest', label: 'Basiszinssatz' },
      { key: 'offerValidity', label: 'Offerte Gültigkeit in Tagen' },
      { key: 'offerValidityIncrease', label: 'Offerte Gültigkeit Erhöhung in Tagen' },
      { key: 'offerValidityProlongation', label: 'Offerte Gültigkeit Erhöhungsschritt' },
      { key: 'offerValidityReduction', label: 'Offerte Gültigkeit Reduktionsschritt' },
    ],
  },
  action,
) {
  switch (action.type) {
    case CORE_CONFIG_LOAD.REQUEST: {
      return { ...state, requesting: true, hasError: false };
    }
    case CORE_CONFIG_LOAD.SUCCESS: {
      const { configs, modules } = action.data;
      return {
        ...state,
        requesting: false,
        modules,
        configs,
        initialModules: modules,
        initialConfigs: configs,
      };
    }
    case CORE_CONFIG_LOAD.FAILURE: {
      return {
        ...state, requesting: false, hasError: true, error: action.data,
      };
    }

    case SET_MODULE: {
      const moduleIndex = _.findIndex(state.modules, module => module.id === action.id);
      return {
        ...state,
        modules: update(
          state.modules,
          { [moduleIndex]: { $set: action.value } },
        ),
      };
    }
    case SET: {
      return { ...state, [action.key]: action.value };
    }

    case CORE_CONFIG_SAVE.REQUEST:
      return { ...state, requesting: true, hasError: false, error: undefined };
    case CORE_CONFIG_SAVE.SUCCESS:
      return {
        ...state,
        requesting: false,
        initialConfigs: state.configs,
        initialModules: state.modules,
      };
    case CORE_CONFIG_SAVE.FAILURE:
      return { ...state, requesting: false, hasError: true, error: action.data };

    default: {
      return state;
    }
  }
}

import { createRef, getIdFromRef, compareRefs } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';
import { mapBasicAdditionToApp, mapBasicProductAdditionToApp } from './BasicProductsAdditionMapper';
import { mapBasicDocumentsToApp } from './BasicProductsDocumentsMapper';

export function mapBasicProductToApp(language, product, categories, additions, documents) {
  const category = categories.find((it) => compareRefs(
    product.category.$ref,
    it.coreType.$ref,
  ));

  if (!category) {
    throw new Error(`Could not find basic product category "${product.category.$ref}" for product "${product.id}"`);
  }

  const addition = mapBasicProductAdditionToApp(product, additions);
  const categoryAddition = category.defaults
    ? mapBasicAdditionToApp(category.defaults)
    : {
      displayClass: '',
      outputClass: '',
      detailGroup: [],
      changeType: [],
    };

  return {
    id: product.id,
    name: product.name[language],
    order: product.order,
    orderCategory: category.order,
    maxOccurrence: product.maxOccurrence,
    category: category.id,
    coreType: product.coreType
      ? product.coreType.map((it) => getIdFromRef(it.$ref))
      : [],
    showAddition: !!addition,
    addition: addition,
    additionDefault: categoryAddition,
    documents: mapBasicDocumentsToApp(product, documents),
  };
}

export function mapBasicProductToApi(product, language, basicProducts, basicCategories) {
  const basicProduct = basicProducts.find((it) => product.id === it.id);
  const category = basicCategories.find((it) => product.category === it.id);
  if (!category) {
    throw new Error(`Could not find basic product category "${product.category}" for product "${product.id}"`);
  }

  const name = basicProduct
    ? {...basicProduct.name, [language]: product.name}
    : {en: product.name, [language]: product.name};
  return {
    id: product.id,
    name: name.en ? name : {...name, en: product.name},
    order: product.order,
    maxOccurrence: product.maxOccurrence
      ? parseInt(product.maxOccurrence, 10)
      : undefined,
    category: {
      $ref: category.coreType.$ref,
    },
    coreType: product.coreType.map((it) => ({$ref: createRef(EndpointName.ENTITY_CODE, it)})),
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import { ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import update from 'immutability-helper';


class RedirectToWorkguideConfig extends React.Component {
  constructor() {
    super();

    this.state = {
      workguideId: undefined
    };

    this.onAdd = this.onAdd.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  onAdd() {
    const { workguideId } = this.state;
    const { onChange, params } = this.props;
    const selected = _.get(params, 'workguides', []);

    if (!_.isUndefined(workguideId)) {
      onChange('workguides', update(selected, { $push: [workguideId] }));
    }

    this.setState({ workguideId: undefined });
  }

  onRemove(index) {
    const { onChange, params } = this.props;
    const selected = _.get(params, 'workguides', []);

    if (index > -1) {
      onChange('workguides', update(selected, { $splice: [[index, 1]] }));
    }
  }

  onSelectChange(workguideId) {
    this.setState({ workguideId });
  }

  render() {
    const { workguideId } = this.state;
    const { edit, workguides, params } = this.props;
    const { workguides: selected = []} = params;

    const items = selected.map((s, index) => (
      <ListGroupItem key={index}>
        {_.get(workguides.find(w => w.id === s), 'title.de')}
        {(edit) ? (
          <span className="pull-right" onClick={() => this.onRemove(index)}><i className="glyphicon glyphicon-remove" /></span>
        ) : null}
      </ListGroupItem>
    ));

    return(
      <React.Fragment>
        <ListGroup>
          {items}
        </ListGroup>

        {(edit) ? (
          <div>
            <h5>Workguide</h5>
            <div style={{ width: '95%', display: 'inline-block' }}>
              <Select
                value={workguideId}
                options={workguides.map(w => ({ value: w.id, label: _.get(w, 'title.de') }))}
                onChange={(selected) => (selected) ? this.onSelectChange(selected.value) : undefined }
              />
            </div>

            <div className="pull-right" style={{ display: 'inline-block' }}>
              <Button
                disabled={_.isUndefined(this.state.workguideId)}
                onClick={this.onAdd}
                style={{ backgroundColor: '#fff', borderColor: '#fff' }}
              >
                <i className="glyphicon glyphicon-plus" />
              </Button>
            </div>
          </div>
        ) : null}

      </React.Fragment>
    );
  }
}

RedirectToWorkguideConfig.propTypes = {
  edit: PropTypes.bool,
  params: PropTypes.object,
  workguides: PropTypes.array,
  onChange: PropTypes.func
};

RedirectToWorkguideConfig.defaultProps = {
  edit: false,
  params: {},
  workguides: [],
  onChange: _.noop
};

export default RedirectToWorkguideConfig;

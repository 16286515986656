import axios from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { UI_NOTES_UPDATE_ACTIONS } from '../../actions/UiNotesActions';
import { convertDateToApi } from '../../utils/dateConverter';
import { getUiNotesImage, getUiNotesTypes } from '../../selectors/UiNotes/uiNotesSelectors';
import { getLanguage } from '../../selectors/commonSelectors';
import { uiNotesUploadImage } from './uiNotesUploadImage';

export function* uiNotesUpdateSaga({ data: { note, form } }) {
  try {
    yield put(UI_NOTES_UPDATE_ACTIONS.pending());

    const uploadedImage = yield select(getUiNotesImage);

    const originalImageRef = note.image
      ? note.image.$ref
      : null;
    const uploadedImageRef = uploadedImage
      ? uploadedImage.$ref
      : null;

    if (uploadedImageRef && uploadedImage.$ref !== originalImageRef) {
      yield call(uiNotesUploadImage, uploadedImage);
    }

    const types = yield select(getUiNotesTypes);
    const language = yield select(getLanguage);

    note = {
      ...note,
      enabled: form.enabled,

      type: form.typeId
        ? types.find(code => code.id === form.typeId)
        : undefined,
      title: note.title
        ? { ...note.title, [language]: form.title }
        : { [language]: form.title },
      text: note.text
        ? { ...note.text, [language]: form.text }
        : { [language]: form.text },
      publishDate: form.publishDate
        ? yield call(convertDateToApi, form.publishDate)
        : undefined,
      image: form.image
        ? { $ref: form.image }
        : undefined,
    };
    yield call(axios.put, `${EndpointName.WORK_UI_NOTES}/${encodeURIComponent(note.id)}`, note);

    if (originalImageRef && uploadedImageRef !== originalImageRef) {
      const filePath = new URL(originalImageRef).pathname;
      yield call(axios.delete, filePath, { validateStatus: false });
    }

    yield put(UI_NOTES_UPDATE_ACTIONS.success({ note }));
  } catch (error) {
    yield put(UI_NOTES_UPDATE_ACTIONS.failure(error));
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import ListItem from './ListItem';

const SortableItem = SortableElement(ListItem);

const SortableList = SortableContainer(({
  items,
  onSubmit,
  onCancel,
  codes,
  group,
  ...rest
}) => {
  const children = items.map((item, index) => {
    return (
      <SortableItem
        key={get(item, 'id')}
        codes={codes}
        group={group}
        index={index}
        data={item}
        onSubmit={onSubmit}
        onCancel={() => onCancel(index)}
        {...rest}
      />
    );
  });

  return (
    <ul>
      {children}
    </ul>
  )
});


class CodeList extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { items, onChange } = this.props;
    const updated = arrayMove(items, oldIndex, newIndex)
      .map((item, index) => ({ ...item, order: index }));

    onChange(updated);
  }

  onSubmit(data) {
    const { onChange } = this.props;

    onChange([data]);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { codes, items, group } = this.props;

    return (
      <div className="code-list">
        <SortableList
          items={items}
          codes={codes}
          group={group}
          onSortEnd={this.onSortEnd}
          distance={1}
          onSubmit={this.onSubmit}
          onRemove={this.onRemove}
          useDragHandle
        />
      </div>
    );
  }
}

CodeList.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  codes: PropTypes.object,
  group: PropTypes.string
};

CodeList.defaultProps = {
  items: [],
  onChange: noop,
  codes: {},
  group: undefined
};

export default CodeList;

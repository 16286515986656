import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import NettedAccountsTable from './NettedAccountsTable';
import { ModalBody, ModalDialog, ModalFooter } from '../Common/Modal';
import { NETTED_ACCOUNTS_WORKGUIDES_UPDATE } from '../../actions/WorkguideStatusActions';
import { getNettedAccountsWorkguides, getEntityCodes } from '../../selectors/WorkguideStatus/workguideStatusCommonSelectors';

const NettedAccountsTab = props => {
    const { workguides, entityCodes, update_netted_accounts_workguides } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const updateWorkguides = () => {
        if (!workguides || workguides.length < 1 || !entityCodes || entityCodes.length < 1) {
            setIsModalOpen(false);
            return;
        };

        update_netted_accounts_workguides({ workguides, entityCodes });
        setIsModalOpen(false);
    }

    return (
        <div>
            <NettedAccountsTable />
            <Button bsStyle="primary" style={{marginTop: '10px'}} onClick={() => setIsModalOpen(true)}>
                <FormattedMessage id="workguideStatus_updateStatus" />
            </Button>
            <ModalDialog show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                <ModalBody>
                    <FormattedMessage tagName='b' id="workguideStatus_updateConfirmation" />
                </ModalBody>
                <ModalFooter>
                    <Button bsStyle="primary" onClick={() => updateWorkguides()}>
                        <FormattedMessage id="common_confirm" />
                    </Button>
                    <Button onClick={() => setIsModalOpen(false)}>
                        <FormattedMessage id="common_cancel" />
                    </Button>
                </ModalFooter>
            </ModalDialog>
        </div>
    );
};

NettedAccountsTab.propTypes = {

};

const mapStateToProps = createStructuredSelector({
    workguides: getNettedAccountsWorkguides,
    entityCodes: getEntityCodes,
});

const mapDispatchToProps = {
    update_netted_accounts_workguides: (data) => NETTED_ACCOUNTS_WORKGUIDES_UPDATE.request(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(NettedAccountsTab);
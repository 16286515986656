import './UiNotesFormContent.css';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { FormDateTime, FormInput, FormSelect, FormToggle, FormWysiwygEditor } from '../FormControls';
import { UiNotesTypeShape } from './UiNotesShape';
import { UiNotesFormImageRow } from './UiNotesFormControls';

export class UiNotesFormContent extends React.Component {
  constructor(props) {
    super(props);

    this.typeOptions = props.types.map((code) => ({
      label: code.text[props.language],
      value: code.id,
    }));
  }

  render() {
    return (
      <div className="ui-notes-form-content">
        <Field component={FormToggle}
               label={"Aktiv"}
               size="large"
               name="enabled"
        />
        <Field component={FormSelect}
               label={<span className="ui-notes-form-content__label">Typ</span>}
               size="large"
               labelSize={3}
               name="typeId"
               placeholder="- Select -"
               multi={false}
               clearable={true}
               options={this.typeOptions}
        />
        <Field component={FormDateTime}
               label={<span className="ui-notes-form-content__label">Publikationsdatum</span>}
               labelSize={3}
               name="publishDate"
               placeholder=""
        />
        <Field component={FormInput}
               componentClass="textarea"
               className="ui-notes-form-content__textarea"
               rows={2}
               label={<span className="ui-notes-form-content__label">Titel</span>}
               size="large"
               type="text"
               labelSize={12}
               name="title"
               placeholder=""
        />
        <Field component={FormWysiwygEditor}
               className="ui-notes-form-content__textarea wysiwyg-editor"
               label={<span className="ui-notes-form-content__label">Text</span>}
               size="large"
               labelSize={12}
               name="text"
               placeholder=""
        />
        <Field component={UiNotesFormImageRow}
               label={<span className="ui-notes-form-content__label">Bild</span>}
               labelSize={12}
               name="image"
        />
      </div>
    );
  }
}

UiNotesFormContent.propTypes = {
  types: PropTypes.arrayOf(UiNotesTypeShape).isRequired,
  language: PropTypes.string.isRequired,
};

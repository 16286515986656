import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, noop, isNil } from 'lodash';
import { TextInput, ValidationResult } from '@evoja-web/react-form';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const ActionConfigurationCreateCustomerSuspension = React.memo(function ActionConfigurationCreateCustomerSuspension({
  action,
  onJmesParamChange,
  validations
}) {
  useEffect(() => {
    if (isNil(get(action, 'jmesParams.customerId'))) {
      onJmesParamChange('customerId', 'customer.id');
    }
    // This effect must only run once when the component mounts to set the default value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="workguide-action-configuration-create-customer-suspension">
      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.Action.Configuration.CustomerSuspension.JmesCustomerId" />
        </ControlLabel>

        <TextInput
          id="customerId"
          onChange={onJmesParamChange}
          value={get(action, 'jmesParams.customerId')}
        />

        <ValidationResult
          show
          validations={get(validations, 'customerId')}
        />
      </FormGroup>
    </div>
  );
});

ActionConfigurationCreateCustomerSuspension.propTypes = {
  action: PropTypes.object.isRequired,
  onJmesParamChange: PropTypes.func,
  validations: PropTypes.object
};

ActionConfigurationCreateCustomerSuspension.defaultProps = {
  onJmesParamChange: noop,
  validations: {}
};

export default ActionConfigurationCreateCustomerSuspension;

import _ from 'lodash'

export * from './attachReducer';
export * from './chainReducers';
export * from './createActions';
export * from './sortByAll';

// Re export
export { default as handleNextHeaders } from './handleNextHeaders';
export { default as parseUrl } from './parseUrl';

export function removeHost(url) {
  const parser = document.createElement('a');
  parser.href = url;
  return parser.pathname + parser.search.replace(/(%2C)/ig, ",")
}

export function getIdFromRef(url) {
  const parser = document.createElement('a');
  parser.href = url;
  return _.last(_.split(parser.pathname, '/'))
}

export function getHostName(url) {
  const parser = document.createElement('a');
  parser.href = url;
  return `${parser.protocol}//${parser.host}`
}

export function encodeRqlString(value) {
  return encodeURIComponent(value).replace(/[-_.~!\\'*()]/g, function (char) {
    return '%' + char.charCodeAt(0).toString(16).toUpperCase();
  });
}

export function handleGravitonError(error) {
  if (_.isArray(_.get(error, 'response.data'))) {
    return _.join(error.response.data.map((e) => `${e.propertyPath} ==> ${e.message}`), ' / ')
  }

  if (_.get(error, 'response.data.message')) {
    return error.response.data.message
  }

  return _.get(error, "message")
}

export function compareRefs(a, b) {
  return removeHost(a) === removeHost(b);
}

export function createRef(endpoint, id) {
  return `${endpoint}/${encodeURIComponent(id)}`;
}

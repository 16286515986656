import _ from 'lodash';

import {
  BANK_LIST_SAVE,
  GET,
  SET,
} from '../actions/BanklistActions';

const initialState = ({
  list: [],
  requesting: false,
  error: {},
  hasError: false,
  removedItems: [],
});
export default function BanklistReducer(state = initialState, action) {
  switch (action.type) {
    case SET: {
      return { ...state, [action.key]: action.value };
    }
    case `${GET}_PENDING`: {
      return { ...state, requesting: true, hasError: false };
    }
    case `${GET}_FULFILLED`: {
      return { ...state, requesting: false, list: _.sortBy(action.payload.data, o => o.order || 0) };
    }
    case `${GET}_REJECTED`: {
      return {
        ...state, requesting: false, hasError: true, error: action.payload,
      };
    }
    case BANK_LIST_SAVE.REQUEST: {
      return { ...state, requesting: true, hasError: false };
    }
    case BANK_LIST_SAVE.SUCCESS: {
      return { ...state, requesting: false, removedItems: [] };
    }
    case BANK_LIST_SAVE.FAILURE: {
      return {
        ...state, requesting: false, hasError: true, error: action.data,
      };
    }

    default: {
      return state;
    }
  }
}

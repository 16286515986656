import './Table.css';

import React from 'react';
import { classList } from '../../utils/classList';

export function Table(props) {
    return (
        <table {...props} className={classList('table-component', props.className)}>
            <tbody>{props.children}</tbody>
        </table>
    );
}

export function TableRow(props) {
    return <tr {...props} className={classList('table-component__row', props.className)}>{props.children}</tr>;
}

export function TableHead(props) {
    return <tr {...props} className={classList('table-component__head', props.className)}>{props.children}</tr>;
}

export function TableCell(props) {
    return <td {...props} className={classList('table-component__cell', props.className)}>{props.children}</td>;
}

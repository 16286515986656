import './ModuleItems.css';

import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import Item from './ModuleItem';

const Items = SortableContainer(({ items, language, edit }) => {
  const aItems = items.map((item, i) => (
    <Item
      key={item.id}
      index={i}
      id={i}
      item={item}
      language={language}
      edit={edit}
    />
  ));

  return (
    <div className="modules-panel">
      <div className="modules-panel__header">
        <div className="modules-panel__title">Modul</div>
        <div className="modules-panel__value">Name</div>
      </div>

      <div className="SortableHOCList">
        {aItems}
      </div>
    </div>
  );
});

export default Items;

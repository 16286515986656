import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'moment/locale/fr-ch';
import 'moment/locale/de-ch';
import { get } from 'lodash';
import '@mdi/font/css/materialdesignicons.min.css';
import '@evoja-web/comstrap-legacy/dist/css/comstrap.min.css';
import 'react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'highlight.js/styles/atom-one-light.css';
import '@evoja-web/react-form/dist/bundle.esm.css';

import './App.css';
import './index.css';
import { sentryInit } from './modules/Sentry';
import { init as initIndexDb } from './IndexDb';
import App from './App';

import store from './Store';

(async function run() {
  await initIndexDb();

  const sentryUrl = get(store.getState(), 'login.environment.sentry_dsn', '');

  if (sentryUrl && sentryUrl.toLowerCase() !== 'null') {
    sentryInit(sentryUrl);
  }

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
}());

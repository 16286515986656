import './Consultants.css';
import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import PageContainer from '../Common/PageContainer';
import { SortableTable } from '../Common/SortableTable';
import { TableCell, TableRow } from '../Common/Table';
import { Pagination } from '../Common/Pagination';
import { ModalDialog } from '../Common/Modal';
import { ConsultantsShape } from './ConsultantsShape';
import { ConsultantsCreateForm } from './ConsultantsCreateForm';
import { ConsultantsUpdateForm } from './ConsultantsUpdateForm';
import { ConsultantsDeleteForm } from './ConsultantsDeleteForm';
import { ConsultantsFormValidator } from './ConsultantsFormValidator';

export class Consultants extends React.Component {
  constructor(props) {
    super(props);
    props.fetchConsultants();

    this.renderItem = this.renderItem.bind(this);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);

    this.handleCreateClick = this.handleCreateClick.bind(this);
    this.handleCreateCancel = this.handleCreateCancel.bind(this);
    this.handleCreateSubmit = this.handleCreateSubmit.bind(this);

    this.handleUpdateClick = this.handleUpdateClick.bind(this);
    this.handleUpdateCancel = this.handleUpdateCancel.bind(this);
    this.handleUpdateSubmit = this.handleUpdateSubmit.bind(this);

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
    this.handleDeleteSubmit = this.handleDeleteSubmit.bind(this);

    this.state = {
      page: 1,
      isCreating: false,
      updatingConsultant: null,
      deletingConsultant: null,
    };

    this.pageSize = 25;
    this.sortableFields = [
      {
        key: 'firstName',
        label: 'First name',
        sortFn: (item) => item.firstName,
      },
      {
        key: 'lastName',
        label: 'Last name',
        sortFn: (item) => item.lastName,
      },
      {
        key: 'username',
        label: 'Username',
        sortFn: (item) => item.username,
      },
      {
        key: 'active',
        label: 'Active',
        sortFn: (item) => item.active,
      },
      {
        key: 'action',
        label: (
          <a className="consultants__button" onClick={() => this.handleCreateClick()}>
            <span className="icon-061-plus"/>
          </a>
        ),
        sortFn: undefined,
      },
    ];
  }

  handlePageChange(page) {
    this.setState({ page });
  }

  handleSortChange() {
    this.setState({ page: 1 });
  }

  handleCreateClick() {
    this.setState({ isCreating: true });
  }

  handleCreateCancel() {
    this.setState({ isCreating: false });
  }

  handleCreateSubmit(form) {
    const { createConsultant } = this.props;
    createConsultant({ form });

    this.setState({ isCreating: false });
  }

  handleUpdateClick(consultant) {
    this.setState({ updatingConsultant: consultant });
  }

  handleUpdateCancel() {
    this.setState({ updatingConsultant: null });
  }

  handleUpdateSubmit(form) {
    const { updatingConsultant } = this.state;
    const { updateConsultant } = this.props;
    updateConsultant({ form, consultant: updatingConsultant });

    this.setState({ updatingConsultant: null });
  }


  handleDeleteClick(consultant) {
    this.setState({ deletingConsultant: consultant });
  }

  handleDeleteCancel() {
    this.setState({ deletingConsultant: null });
  }

  handleDeleteSubmit() {
    const { deletingConsultant } = this.state;
    const { deleteConsultant } = this.props;
    deleteConsultant({ consultant: deletingConsultant });

    this.setState({ deletingConsultant: null });
  }

  getCreateFormValues() {
    return {
      firstName: "",
      lastName: "",
      username: "",
      department: "",
      division: "",
      title: "",
      phone: "",
      email: "",
      password: null,
      repeatPassword: null,
    };
  }

  getUpdateFormValues(consultant) {
    const phoneContact = consultant.contacts.find(c => c.type === 'mobile' && c.protocol === 'tel');
    const emailContact = consultant.contacts.find(c => c.type === 'business' && c.protocol === 'mailto');

    return {
      firstName: consultant.firstName,
      lastName: consultant.lastName,
      username: consultant.username,
      department: consultant.department,
      division: consultant.division,
      title: consultant.title,
      phone: phoneContact ? phoneContact.value : '',
      email: emailContact ? emailContact.value : '',
      password: null,
      repeatPassword: null,
    };
  }

  renderConsultants() {
    const { consultants } = this.props;
    if (!consultants) {
      return null;
    }

    const { page } = this.state;
    const items = consultants.slice((page - 1) * this.pageSize, page * this.pageSize);

    return (
      <div className="consultant">
        <SortableTable
          className="consultants__table"
          items={items}
          renderItem={this.renderItem}
          sortableFields={this.sortableFields}
          onSort={this.handleSortChange}
        />
        {this.renderPagination()}

        {this.renderCreateModal()}
        {this.renderUpdateModal()}
        {this.renderDeleteModal()}
      </div>
    );
  }

  renderItem(consultant) {
    return (
      <TableRow key={consultant.id} className="consultants__row">
        <TableCell className="consultants__firstName">
          {consultant.firstName}
        </TableCell>
        <TableCell className="consultants__lastName">
          {consultant.lastName}
        </TableCell>
        <TableCell className="consultants__username">
          {consultant.username}
        </TableCell>
        <TableCell className="consultants__active">
          {consultant.active ? 'Yes' : 'No'}
        </TableCell>
        <TableCell className="consultants__action">
          <a className="consultants__button" onClick={() => this.handleUpdateClick(consultant)}>
            <span className="icon-025-edit"/>
          </a>
          <a className="consultants__button" onClick={() => this.handleDeleteClick(consultant)}>
            <span className="icon-008-bin"/>
          </a>
        </TableCell>
      </TableRow>
    );
  }

  renderPagination() {
    const { consultants } = this.props;
    const { page } = this.state;

    const pageCount = Math.ceil(consultants.length / this.pageSize);
    if (pageCount < 2) {
      return null;
    }

    return (
      <div className="consultants__pagination">
        <Pagination
          prev={true}
          next={true}
          first={true}
          last={true}
          boundaryLinks={true}
          ellipsis={true}
          items={pageCount}
          maxButtons={7}
          activePage={page}
          onSelect={this.handlePageChange}
        />
      </div>
    );
  }

  renderCreateModal() {
    const { isCreating } = this.state;

    return (
      <ModalDialog
        show={isCreating}
        keyboard={true}
        backdrop="static"
        onHide={this.handleCreateCancel}
        className="consultants__modal"
      >
        <ConsultantsCreateForm
          form="consultant-create-form"
          initialValues={this.getCreateFormValues()}
          validate={ConsultantsFormValidator}
          onCancel={this.handleCreateCancel}
          onSubmit={this.handleCreateSubmit}
        />
      </ModalDialog>
    );
  }

  renderUpdateModal() {
    const { updatingConsultant } = this.state;

    return (
      <ModalDialog
        show={updatingConsultant !== null}
        keyboard={true}
        backdrop="static"
        onHide={this.handleUpdateCancel}
        className="consultants__modal"
      >
        <TransitionGroup>
          {updatingConsultant && (
            <ConsultantsUpdateForm
              form="consultant-update-form"
              initialValues={this.getUpdateFormValues(updatingConsultant)}
              validate={ConsultantsFormValidator}
              onCancel={this.handleUpdateCancel}
              onSubmit={this.handleUpdateSubmit}
            />
          )}
        </TransitionGroup>
      </ModalDialog>
    );
  }

  renderDeleteModal() {
    const { deletingConsultant } = this.state;

    return (
      <ModalDialog show={deletingConsultant !== null} onHide={this.handleDeleteCancel}>
        <TransitionGroup>
          {deletingConsultant && (
            <ConsultantsDeleteForm
              onCancel={this.handleDeleteCancel}
              onSubmit={this.handleDeleteSubmit}
            />
          )}
        </TransitionGroup>
      </ModalDialog>
    );
  }

  render() {
    const { loading, saving } = this.props;

    return (
      <PageContainer
        requesting={loading || saving}
        title="Consultants"
        isTransparentLoader={!loading}
      >
        {this.renderConsultants()}
      </PageContainer>
    );
  }
}

Consultants.propTypes = {
  consultants: PropTypes.arrayOf(ConsultantsShape),
  loading: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  fetchConsultants: PropTypes.func.isRequired,
  createConsultant: PropTypes.func.isRequired,
  updateConsultant: PropTypes.func.isRequired,
  deleteConsultant: PropTypes.func.isRequired,
};


export default Consultants;

import './Modal.css';

import React from 'react';
import { Modal } from 'react-bootstrap';
import { classList } from '../../utils/classList';

export function ModalDialog({ children, className, ...restProps }) {
  return (
    <Modal className={classList('modal-component', className)}
           backdropClassName="modal-component__backdrop"
           {...restProps}>
      {children}
    </Modal>
  );
}

export function ModalHeader({ children, ...restProps }) {
  return (
    <Modal.Header closeButton={true} className="modal-component-header" {...restProps}>
      {children}
    </Modal.Header>
  );
}

export function ModalBody({ children, ...restProps }) {
  return (
    <Modal.Body className="modal-component-body" {...restProps}>
      {children}
    </Modal.Body>
  );
}

export function ModalFooter({ children, ...restProps }) {
  return (
    <Modal.Footer className="modal-component-footer" {...restProps}>
      {children}
    </Modal.Footer>
  );
}

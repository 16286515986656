import { BULK_LOADS_LOAD, BULK_LOADS_REFRESH, BULK_LOADS_SEARCH } from '../actions/BulkLoadsActions';
import { BULK_LOADS_DEFAULT_SORT } from '../constants/BulkLoadsConstants';

const initialState = {
  textBlocks: [],
  loading: false,
  error: undefined,
  refreshing: false,
  results: undefined,
  averageCompletionTime: 0,
  searchParams: {
    ...BULK_LOADS_DEFAULT_SORT,
  },
};

export function BulkLoadsReducer(state = initialState, action) {
  switch (action.type) {
    case BULK_LOADS_LOAD.PENDING:
      return { ...state, loading: true, error: undefined };
    case BULK_LOADS_LOAD.SUCCESS: {
      const { textBlocks, results, averageCompletionTime } = action.data;
      return { ...state, loading: false, error: undefined, textBlocks, results, averageCompletionTime };
    }
    case BULK_LOADS_LOAD.FAILURE:
      return { ...state, loading: false, error: action.data };

    case BULK_LOADS_SEARCH.PENDING:
      return { ...state, loading: true, error: undefined };
    case BULK_LOADS_SEARCH.SUCCESS: {
      const { searchParams, results } = action.data;
      return { ...state, loading: false, results, searchParams };
    }
    case BULK_LOADS_SEARCH.FAILURE:
      return { ...state, loading: false, error: action.data };

    case BULK_LOADS_REFRESH.PENDING:
      return { ...state, refreshing: true, error: undefined };
    case BULK_LOADS_REFRESH.SUCCESS: {
      const { results } = action.data;
      return { ...state, refreshing: false, results };
    }
    case BULK_LOADS_REFRESH.FAILURE:
      return { ...state, refreshing: false, error: action.data };

    default:
      return state;
  }
}

import { Actions } from '@evoja-web/redaction';

import { featureFlagAction } from './FeatureFlag';
import { featureFlagFormAction } from './FeatureFlagForm';

export const actions = Actions([
  featureFlagAction,
  featureFlagFormAction
]);

export default actions.get();

import React from 'react';
import PropTypes from 'prop-types';
import { get, isNil, noop, upperFirst, isArray, size } from 'lodash';
import update from 'immutability-helper';
import { FormattedMessage } from 'react-intl';
import { CreatableSelect, ValidationResult } from '@evoja-web/react-form';

import './Generic.css';
import CustomerJmesInput from '../Field/CustomJmesInput';

class WorkflowTreeCondition extends React.Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
    this.onCriteriaValueChange = this.onCriteriaValueChange.bind(this);
  }

  componentDidMount() {
    const {
      condition,
      onChange
    } = this.props;

    const criteria = get(condition, 'criteria');
    if (isNil(criteria)) {
      const updated = update(condition, { criteria: { $set: {} } });
      onChange({ condition: updated });
    }
  }

  /**
   * Handle condition value change
   *
   * @param   {String}  key    Key in condition
   * @param   {Mixed}   value  Value to set
   *
   * @return  void
   */
  onValueChange(key, value) {
    const {
      condition,
      onChange
    } = this.props;

    const updated = isNil(value)
      ? update(condition, { $unset: [key] })
      : update(condition, { [key]: { $set: value } });

    onChange({ condition: updated });
  }

  /**
   * Update criteria and set value on key
   *
   * @param   {String}  key    Key
   * @param   {String}  value  Value
   *
   * @return  void
   */
  onCriteriaValueChange(key, value) {
    const {
      condition,
      onChange
    } = this.props;

    const criteria = get(condition, 'criteria', {});
    const updated = isNil(value) || (isArray(value) && size(value) === 0)
      ? update(criteria, { $unset: [key] })
      : update(criteria, { [key]: { $set: value } });

    onChange({ condition: { ...condition, criteria: updated } });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      condition,
      onRemove,
      validations
    } = this.props;

    return (
      <div className="workflow-tree-form-condition--condition-generic">
        <div className="d-flex align-items-center">
          <FormattedMessage
            id={`Workguide.Workflow.Form.Condition.Title.${upperFirst(condition.operation)}`}
            tagName="strong"
          />

          <div style={{ paddingLeft: '2em' }} />

          <div
            className="mdi mdi-trash-can-outline pointer"
            onClick={() => onRemove({ condition })}
          />
        </div>

        <div style={{ paddingBottom: '0.5em' }} />

        <FormattedMessage
          id="Workguide.Workflow.Form.Condition.If"
          tagName="div"
        />

        <CustomerJmesInput
          id="path"
          onChange={this.onCriteriaValueChange}
          showLabel={false}
          prefix=""
          value={get(condition, 'criteria.path')}
          validations={get(validations, 'criteria.path')}
        />

        <div style={{ paddingBottom: '1em' }} />

        <FormattedMessage
          id={`Workguide.Workflow.Form.Condition.Operation.${upperFirst(condition.operation)}`}
          tagName="div"
        />

        <CreatableSelect
          id="values"
          onChange={this.onCriteriaValueChange}
          value={get(condition, 'criteria.values', [])}
        />

        <ValidationResult
          show
          validations={get(validations, 'criteria.values')}
        />
      </div>
    );
  }
}

WorkflowTreeCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  validations: PropTypes.object
};

WorkflowTreeCondition.defaultProps = {
  onChange: noop,
  onRemove: noop,
  validations: {}
};

export default WorkflowTreeCondition;

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { DropdownButton, MenuItem } from 'react-bootstrap';

export const possibleOptions = [{
  value: 'single',
  label: 'Einfache Bedingung'
}, {
  value: 'and',
  label: 'UND Bedingung'
}, {
  value: 'or',
  label: 'ODER Bedingung'
}];

const DropDownAdd = ({ onClick, options }) => {
  const items = possibleOptions.filter(o => options.includes(o.value))
    .map((o, index) => (
    <MenuItem
      key={o.value}
      eventKey={index}
      onClick={() => onClick(o.value)}
    >
      {o.label}
    </MenuItem>
  ));

  return (
    <DropdownButton
      bsStyle="default"
      title={<i onClick={onClick} className="glyphicon glyphicon-plus"></i>}
      id={`dropdown-basic-add`}
    >
      {items}
    </DropdownButton>
  );
}


DropDownAdd.propTypes = {
  onClick: PropTypes.func,
  options: PropTypes.array
};

DropDownAdd.defaultProps = {
  onClick: _.noop,
  options: []
};

export default DropDownAdd;

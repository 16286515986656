import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isNil, first, isEmpty, forEach } from 'lodash';
import { actions as textblockActions } from '@evoja-web/react-core-textblock';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';

import '@evoja-web/react-core-textblock/dist/bundle.esm.css';
import EditContainer from '../components/EditContainer';
import ScreenSelect from '../components/Textblock/ScreenSelect';
import TextblockSelect from '../components/Textblock/TextblockSelect';
import EditForm from '../components/Textblock/Edit';

class Textblock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedScreenId: undefined,
      selectedTextblockId: undefined,
      updated: {}
    };

    const { textblockActions } = this.props;
    textblockActions.screensRequest();

    this.onScreenSelectChange = this.onScreenSelectChange.bind(this);
    this.onTextblockSelectChange = this.onTextblockSelectChange.bind(this);
    this.onTextbockChange = this.onTextbockChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Handle screen (output) change
   *
   * @param   {String}  value  Screenid
   *
   * @return  void
   */
  onScreenSelectChange({ value }) {
    const { textblockActions } = this.props;

    this.setState({ selectedScreenId: value });

    if (!isNil(value)) {
      textblockActions.outputRequest({ dataKey: value });
    }
  }

  /**
   * Handle selection change of the textblock select
   *
   * @param   {String}  id  Selected textblock id
   *
   * @return  void
   */
  onTextblockSelectChange(id) {
    this.setState({ selectedTextblockId: id });
  }

  /**
   * Handle onChange of textblock data.
   * Write updated version of the textblock object to state
   *
   * @param   {Object}  data  Textblock data
   *
   * @return  void
   */
  onTextbockChange(data) {
    const { updated } = this.state;

    const { id } = data;

    this.setState({ updated: { ...updated, [id]: data } });
  }

  /**
   * Submit all changes
   *
   * @return  {[type]}  [return description]
   */
  onSubmit() {
    const { updated } = this.state;
    const { textblockActions } = this.props;

    forEach(updated, (textblock, id) => textblockActions.upsertTextblockRequest({ dataKey: id, textblock }));
  }

  /**
   * Get data for the selected output (screen)
   *
   * @return  {Object} output Data of selected output
   */
  getSelectedOutput() {
    const { selectedScreenId } = this.state;
    const { outputs } = this.props;

    const output = get(outputs, selectedScreenId, {});

    return output;
  }

  /**
   * Get the textblock id to use.
   * If no id is selected in state, take the id of the first textblock in the selected output
   *
   * @return  {String} id Selected textblock id or first id of output
   */
  getSelectedTextblockId() {
    const { selectedTextblockId } = this.state;

    const output = this.getSelectedOutput();

    return isNil(selectedTextblockId)
      ? get(first(output.data), 'id')
      : selectedTextblockId;
  }

  /**
   * Get the textblock data for edit.
   * Return the updated version from state (if one exists) or the original version from service
   *
   * @return  {Object} data Textblock data
   */
  getTextblockData() {
    const { updated } = this.state;

    const output = this.getSelectedOutput();
    const selectedTextblockId = this.getSelectedTextblockId();

    const data = get(
      updated,
      selectedTextblockId,
      get(output, 'data', []).find((t) => t.id === selectedTextblockId)
    );

    return data;
  }

  /**
   * Render the edit container content
   *
   * @return {ReactElement} markup
   */
  renderContent() {
    const {
      selectedScreenId,
      updated
    } = this.state;
    const {
      language,
      screens
    } = this.props;

    const selectedTextblockId = this.getSelectedTextblockId();
    const textblockData = this.getTextblockData();

    return (
      <div>
        <FormattedMessage
          id="textBlocks_disclaimer"
          tagName="p"
        />

        <div>
          <ScreenSelect
            language={language}
            onChange={this.onScreenSelectChange}
            screens={screens}
            value={selectedScreenId}
          />
        </div>

        <div>
          <TextblockSelect
            language={language}
            onChange={this.onTextblockSelectChange}
            output={this.getSelectedOutput()}
            value={selectedTextblockId}
          />
        </div>

        {!isNil(textblockData) && (
          <EditForm
            data={textblockData}
            onChange={this.onTextbockChange}
          />
        )}

        <div style={{ width: '100%', textAlign: 'right' }}>
          <Button
            bsStyle="primary"
            disabled={isEmpty(updated)}
            onClick={this.onSubmit}
          >
            Speichern
          </Button>
        </div>
      </div>
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { requesting } = this.props;

    return (
      <EditContainer
        requesting={requesting}
        title={<FormattedMessage id="textBlocks_title" />}
        body={this.renderContent()}
      />
    );
  }
}

Textblock.propTypes = {
  language: PropTypes.string,
  outputs: PropTypes.object,
  requesting: PropTypes.bool,
  screens: PropTypes.object,
  textblockActions: PropTypes.object.isRequired
};

Textblock.defaultProps = {
  language: 'de',
  outputs: {},
  requesting: false,
  screens: {}
};

function mapStateToProps(state, ownProps) {
  return {
    outputs: state.textblock.output,
    language: state.login.language,
    screens: state.textblock.screens
  };
}

function mapDispatchToProps(dispatch) {
  return {
    textblockActions: bindActionCreators(textblockActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Textblock);

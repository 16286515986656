import { all, call, put } from 'redux-saga/effects';
import { SET_MANAGEMENT_CHANGE_ACTIONS } from '../../actions/SetManagementActions';
import { setManagementChangeBundles } from './setManagementChangeBundles';
import { setManagementChangeProducts } from './setManagementChangeProducts';
import { setManagementChangePrices } from './setManagementChangePrices';

export function* setManagementChangeSaga({ data: { bundles, products, prices } }) {
  try {
    yield put(SET_MANAGEMENT_CHANGE_ACTIONS.pending());

    const [
      updatedBundles,
      updatedProducts,
      updatedPrices,
    ] = yield all([
      call(setManagementChangeBundles, bundles),
      call(setManagementChangeProducts, products),
      call(setManagementChangePrices, prices),
    ]);

    yield put(SET_MANAGEMENT_CHANGE_ACTIONS.success({
      bundles: updatedBundles,
      products: updatedProducts,
      prices: updatedPrices,
    }));
  } catch (error) {
    yield put(SET_MANAGEMENT_CHANGE_ACTIONS.failure(error));
  }
}

import { createSelector } from 'reselect';

export const getConsultantsState = (state) => state.consultants;

export const getConsultants = createSelector([
  getConsultantsState,
], (state) => state.data ? state.data.consultants : null);


export const getConsultantsLoading = createSelector([
  getConsultantsState,
], (state) => state.loading);

export const getConsultantsSaving = createSelector([
  getConsultantsState,
], (state) => state.saving);

import React from 'react';
import PropTypes from 'prop-types';
import { chain, noop, every, isNil, range } from 'lodash';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';

import './Flex.css';
import FlexSelect from './FlexSelect';

/**
 * Get options for the year dropdown
 *
 * @return  {Array} options Year options
 */
function getYearOptions() {
  const current = moment().year();

  const year = range(current, current + 7);

  return [
    ...year.map((y) => ({ value: `${y}`, label: y })),
    { value: 'current', label: 'Aktuelles Jahr' },
    { value: 'current-add', label: 'Aktuelles Jahr +' },
    { value: 'current-subtract', label: 'Aktuelles Jahr -' }
  ];
}

/**
 * Get the options for the month dropdown
 *
 * @return  {Array} months Month options
 */
function getMonthOptions() {
  return [
    ...moment
      .months()
      .map((m, k) => ({ value: `${k}`, label: m })),
    { value: 'current', label: 'Aktueller Monat' },
    { value: 'current-add', label: 'Aktueller Monat +' },
    { value: 'current-subtract', label: 'Aktueller Monat -' }
  ];
}

/**
 * Get options for the day dropdown
 *
 * @param   {Number}  year   Selected year
 * @param   {Number}  month  Selected month
 *
 * @return  {Array} options Day options
 */
function getDayOptions({ year, month }) {
  if (isNil(year) || isNil(month)) {
    return [];
  }

  const days = moment()
    .year(year)
    .month(month)
    .daysInMonth();

  return [
    ...range(1, days + 1).map((d) => ({ value: `${d}`, label: d })),
    { value: 'current', label: 'Aktueller Tag' },
    { value: 'current-add', label: 'Aktueller Tag +' },
    { value: 'current-subtract', label: 'Aktueller Tag -' }
  ];
}

/**
 * Split the current value from props into year / month / day
 *
 * @param   {String}  value  Value parts
 *
 * @return  {Object} values Object containing year / month / day
 */
function getValuesFromProps({ value }) {
  const [year, month, day] = chain(value)
    .replace('flex-', '')
    .split('--')
    .map((v) => v.replace('{', '').replace('}', ''))
    .value();

  return {
    year,
    month,
    day
  };
}

class FormFieldDateConfigurationFlex extends React.Component {
  constructor(props) {
    super(props);

    const { year, month, day } = getValuesFromProps(props);

    this.state = {
      year,
      month,
      day
    };

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Handle change of one of the selects
   *
   * @param   {String}  id     Form element id
   * @param   {String}  value  Selected value
   *
   * @return  {[type]}         [return description]
   */
  onChange(id, value) {
    const { year, month, day } = this.state;
    const { onChange } = this.props;

    this.setState({ [id]: value });

    const updated = {
      year,
      month,
      day,
      [id]: value
    };

    const v = every(updated, (v) => !isNil(v))
      ? `flex-{${updated.year}}--{${updated.month}}--{${updated.day}}`
      : undefined;

    onChange(v);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { year, month, day } = this.state;

    return (
      <div className="workguide-form-field-configuration workguide-form-field-date-configuration-flex--template">
        <Row style={{ width: '100%' }}>
          <Col
            className="workguide-form-field-configuration workguide-form-field-date-configuration-flex--col"
            lg={4}
            md={4}
          >
            <FlexSelect
              id="year"
              onChange={this.onChange}
              options={getYearOptions()}
              value={year}
            />
          </Col>

          <Col
            className="workguide-form-field-configuration workguide-form-field-date-configuration-flex--col"
            lg={4}
            md={4}
          >
            <FlexSelect
              id="month"
              disabled={isNil(year)}
              onChange={this.onChange}
              options={getMonthOptions()}
              value={month}
            />
          </Col>

          <Col
            className="workguide-form-field-configuration workguide-form-field-date-configuration-flex--col"
            lg={4}
            md={4}
          >
            <FlexSelect
              id="day"
              disabled={isNil(month)}
              onChange={this.onChange}
              options={getDayOptions({ year, month })}
              value={day}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

FormFieldDateConfigurationFlex.propTypes = {
  onChange: PropTypes.func,
  // Value is used by getValuesFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.string
};

FormFieldDateConfigurationFlex.defaultProps = {
  onChange: noop,
  value: ''
};

export default FormFieldDateConfigurationFlex;

import React from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import _ from 'lodash';

export default class Forwards extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      value: 1,
      forwardRate: 0,
      forwardFrom: 0,
      forwardUntil: 0,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentWillMount() {
    let { forwards } = this.props;
    forwards = _.sortBy(forwards, ['forwardFrom', 'forwardUntil']);
    this.setState({
      forwardRate: (parseFloat(forwards[0].forwardRate) * 100).toFixed(3),
      forwardFrom: forwards[0].forwardFrom,
      forwardUntil: forwards[0].forwardUntil,
    });
  }

  handleSelect(value) {
    let { forwards } = this.props;
    forwards = _.sortBy(forwards, ['forwardFrom', 'forwardUntil']);
    this.setState({
      value,
    });
    this.props.selectForward(this.props.id, forwards[value - 1].forwardRate);
  }

  render() {
    const { value } = this.state;
    let { forwards } = this.props;
    forwards = _.sortBy(forwards, ['forwardFrom', 'forwardUntil']);
    const formatLabel = value => `${forwards[value - 1].forwardFrom} - ${forwards[value - 1].forwardUntil} Tage / ${(parseFloat(forwards[value - 1].forwardRate) * 100).toFixed(3)}`;
    return (
      <span>
        <div className="value">{formatLabel(value)}</div>
        <div className="slider-horizontal">
          <Slider
            min={1}
            max={forwards.length}
            value={value}
            onChange={this.handleSelect.bind(this)}
            tooltip={false}
            format={formatLabel}
            step={1}
          />
        </div>
      </span>
    );
  }
}

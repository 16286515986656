import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import {
  getCoreConfigModules,
  getCoreConfigModulesInitial,
} from '../../selectors/CoreConfig/coreConfigModulesSelectors';

export function* coreConfigSaveModulesSaga() {
  const initialModules = yield select(getCoreConfigModulesInitial);
  const modules = yield select(getCoreConfigModules);
  yield all(modules
    .map((module) => {
      const initial = initialModules.find((it) => it.id === module.id);
      if (!initial) {
        return undefined;
      }

      const operations = [];
      if (
        initial.name.de !== module.name.de ||
        initial.name.en !== module.name.en ||
        initial.name.fr !== module.name.fr
      ) {
        operations.push({
          op: 'replace',
          path: '/name',
          value: module.name,
        });
      }

      if (initial.order !== module.order) {
        operations.push({
          op: 'replace',
          path: '/order',
          value: module.order,
        });
      }

      if (!operations.length) {
        return undefined;
      }

      return call(axios, {
        method: 'patch',
        url: `${EndpointName.CORE_MODULE}/${encodeURIComponent(module.id)}`,
        data: operations,
      });
    })
    .filter(Boolean)
  );
}

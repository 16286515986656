import ConfigurationForm from '../configuration/ArrayValuesGe';

export default {
  key: 'arrayValuesGe',
  label: 'Summierte Werte grösser als (array oder array von objekten) ' ,
  validation: 'arrayValuesGe',
  availableFor: ['FundinvestFunds'],
  ConfigurationForm,
  defaultParams: [0, 'amount'],
  get: function(params) {
    return { arrayValuesGe: params };
  }
}

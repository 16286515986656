import './Pagination.css';
import React from 'react';
import { Pagination as BootstrapPagination } from '@react-bootstrap/pagination';
import { classList } from '../../utils/classList';

export function Pagination({ prev, next, first, last, className, ...restProps }) {
  const newPrev = prev === true ? <span className="pagination-component__control icon-014-chevron-left"/> : prev;
  const newNext = next === true ? <span className="pagination-component__control icon-015-chevron-right"/> : next;
  const newFirst = first === true ? <span className="pagination-component__control icon-040-go-to-start"/> : first;
  const newLast = last === true ? <span className="pagination-component__control icon-038-go-to-end"/> : last;
  const newClassName = classList('pagination-component', className);

  return (
    <BootstrapPagination
      {...restProps}
      className={newClassName}
      prev={newPrev}
      next={newNext}
      first={newFirst}
      last={newLast}
    />
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { LoginContainer } from '@evoja-web/react-login';

import './LoginContainer.css';

const LoginContainerWrapper = React.memo((props = {}) => {
  return (
    <LoginContainer {...props} />
  );
});

export default LoginContainerWrapper;

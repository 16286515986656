import { takeLatest } from 'redux-saga/effects';

import actions from '../../actions/FeatureFlag/Actions';
import getFeatureFlags from './getFeatureFlags';
import initFeatureFlagForm from './initFeatureFlagForm';
import saveFeatureFlagForm from './saveFeatureFlagForm';

const {
  FEATURE_FLAG_REQUEST,
  FEATURE_FLAG_FORM_INIT_REQUEST,
  FEATURE_FLAG_FORM_SAVE_REQUEST
} = actions;

export default function* generalRootSaga() {
  yield takeLatest(FEATURE_FLAG_REQUEST, getFeatureFlags);
  yield takeLatest(FEATURE_FLAG_FORM_INIT_REQUEST, initFeatureFlagForm);
  yield takeLatest(FEATURE_FLAG_FORM_SAVE_REQUEST, saveFeatureFlagForm);
}

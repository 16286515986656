import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { arrayMove } from 'react-sortable-hoc';
import update from 'immutability-helper';
import _, { get } from 'lodash';
import uuid from 'uuid';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

import EditContainer from '../components/EditContainer';
import * as TypeActions from '../actions/TypeActions';

import Items from '../components/Types/Items';

class EquityType extends Component {
  constructor(props) {
    super(props);

    this.onSortEnd = this.onSortEnd.bind(this);
  }
  componentWillMount() {
    const type = this.getType();

    this.props.actions.getTypes(type);
    if (this.getType() === 'collateral') {
      this.props.actions.getCollateralTypes();
    }
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { types } = this.props;
    let newOrder = arrayMove(types[this.getType()], oldIndex, newIndex);

    newOrder = newOrder.map((o, i) => {
      o.order = i * 100;
      return (o);
    });
    const newTypes = update(types, { $set: newOrder });
    this.props.actions.setTypeData({ value: newTypes, type: this.getType() });
  };

  getType() {
    const { match } = this.props;

    return get(match, 'params.type');
  }

  add() {
    const { actions, types } = this.props;

    const type = this.getType();

    const newIndex = (types[type].length + 1) * 100;
    const id = uuid.v4();
    const newItem = {
      id: `${type}type-${id}`,
      name: {
        de: '',
        en: '',
        fr: '',
      },
      order: newIndex,
    };
    switch (type) {
      case 'equity':
        newItem.isLiquidity = false;
        newItem.isEffective = false;
        break;
      case 'collateral':
        newItem.acceptanceFactor = 1;
        newItem.steeringCode = false;
        newItem.steeringCodeBorrowingBase = false;
        newItem.coreType = [];
        break;
      case 'cashflow':
        newItem.transferability = 1;
        newItem.income = true;
        newItem.reducing = false;
        newItem.isAdditionalExpenseOrEarning = false;
        break;
      default:
    }
    // use concat instead of push for immutabillity
    const newTypes = types[type].concat(newItem);
    actions.setTypeData({ type, value: _.sortBy(newTypes, o => o.order) });
  }

  // handle CRUD operations on the banklist modules/items
  remove(index) {
    const {
      types,
      removedItems,
      actions
    } = this.props;
    const type = this.getType();

    const removedTypeId = types[type][index].id;
    // use removedBank when saveData is triggered to delete the bank.id globally
    const newRemovedItems = update(removedItems[type], {
      $push: [removedTypeId],
    });
    // remove bank from list (it will be deleted globally only when saveData was triggered)
    // do it in immutable way with filter
    const newTypes = types[type].filter((type, i) => i !== index);

    actions.setTypeData({ type: type, value: newTypes });
    actions.setRemovedTypeData({ type, value: newRemovedItems });
  }

  editEventField(element, id, event) {
    const {
      types,
      language,
      actions
    } = this.props;
    const type = this.getType();

    let newTypes = [];
    switch (element) {
      // handle name fields
      case 'name':
        if (event.target.value.length <= 100) {
          newTypes = update(types[type], {
            [id]: { name: { [language]: { $set: event.target.value } } },
          });

          // english is the main language in graviton. make sure english has always a value
          if (language !== 'en') {
            newTypes = update(newTypes, {
              [id]: { name: { en: { $set: event.target.value } } },
            });
          }
        } else {
          newTypes = types[type];
        }
        break;
      // handle toggles
      case 'steeringCode':
      case 'steeringCodeBorrowingBase':
      case 'isLiquidity':
      case 'isEffective':
      case 'income':
      case 'reducing':
      case 'isAdditionalExpenseOrEarning':
        newTypes = update(types[type], {
          [id]: { [element]: { $set: !types[type][id][element] } },
        });
        break;
      default:
        newTypes = types[type];
    }
    actions.setTypeData({ type, value: newTypes });
  }


  editValueField(element, id, value) {
    const {
      types,
      actions
    } = this.props;
    const type = this.getType();

    let newTypes = [];
    switch (element) {
      case 'transferability':
      case 'acceptanceFactor':
        newTypes = update(types[type], {
          [id]: { [element]: { $set: value / 100 } },
        });
        break;
      case 'coreType':
        newTypes = update(types[type], {
          [id]: {
            [element]: { $set: value.map(v => ({ $ref: `http://localhost/entity/code/${v.value}` })) },
          },
        });
        break;
      default:
        newTypes = types[type];
    }
    actions.setTypeData({ type, value: newTypes });
  }

  // save the changes in ChecklistStore back to the graviton service
  saveData(event) {
    event.preventDefault();
    const {
      types,
      actions,
      removedItems
    } = this.props;
    const type = this.getType();

    _.forEach(types[type], (type) => {
      actions.updateType({ type, value: type });
    });
    _.forEach(removedItems[type], (item) => {
      actions.deleteType({ type, id: item });
    });
  }

  typeView() {
    const {
      types,
      language,
      collateralTypes
    } = this.props;
    const type = this.getType();

    return (
      <Items
        items={types[type]}
        collateralTypes={collateralTypes}
        language={language}
        type={type}
        onAdd={this.add.bind(this)}
        onRemove={this.remove.bind(this)}
        editEventField={this.editEventField.bind(this)}
        editValueField={this.editValueField.bind(this)}
        onSortEnd={this.onSortEnd}
        useDragHandle
        useWindowAsScrollContainer
        helperClass="SortableHOCHelper"
      />
    );
  }

  render() {
    const {
      requesting,
      error,
      hasError
    } = this.props;
    const type = this.getType();

    if (hasError) {
      toast.error(get(error, 'response.data.message'));
    }

    let title = '';
    switch (type) {
      case 'equity':
        title = 'Eigenmittel Eigenheim';
        break;
      case 'cashflow':
        title = 'Einkommens- und Kostentypen Eigenheim';
        break;
      case 'collateral':
        title = 'Zusatzsicherheiten Eigenheim';
        break;
      default:
        break;
    }
    return (
      <EditContainer
        requesting={requesting}
        saveData={this.saveData.bind(this)}
        title={title}
        body={this.typeView()}
      />);
  }
}

function mapStateToProps(state) {
  return {
    types: state.types.types,
    removedItems: state.types.removedItems,
    collateralTypes: state.types.collateralTypes,
    requesting: state.types.requesting,
    language: state.login.language,
    error: state.types.error,
    hasError: state.types.hasError,
  };
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(TypeActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(EquityType)
);

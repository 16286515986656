import React from 'react';
import PropTypes from 'prop-types';
import { get, slice } from 'lodash';

import './BulkLoadsResults.css';
import { BulkLoadsSortableTable } from './BulkLoadsSortableTable';
import { BulkLoadsResultsItem } from './BulkLoadsResultsItem';
import { BULK_LOAD_PROP_TYPES_RESULT_ITEM, BULK_LOAD_PROP_TYPES_SEARCH_PARAMS } from './BulkLoadsPropTypes';
import { BULK_LOADS_PAGE_SIZE, BULK_LOADS_STATES } from '../../constants/BulkLoadsConstants';
import { BulkLoadsExpandContent } from './BulkLoadsExpandContent';
import { BulkLoadsRefresh } from './BulkLoadsRefresh';
import Pagination from '../Pagination/PaginationNoTotalCount';

export class BulkLoadsResults extends React.PureComponent {
  onChangePage = ({ limit, offset }) => {
    const { resultsRaw, search, searchParams } = this.props;

    search({
      ...searchParams,
      resultsRaw,
      limit,
      offset
    });
  };

  sort = (field, direction) => {
    const { search, searchParams } = this.props;
    const limit = get(searchParams, 'pageSize', BULK_LOADS_PAGE_SIZE);

    search({
      ...searchParams,
      sortField: field,
      sortDirection: direction,
      resultsRaw: {},
      limit,
      offet: 0
    });
  };

  renderItem = (item) => <BulkLoadsResultsItem key={item.id} item={item} />;

  renderContent() {
    const { results, searchParams } = this.props;
    if (!results.length) {
      return null;
    }

    const limit = get(searchParams, 'pageSize', BULK_LOADS_PAGE_SIZE);
    const offset = get(searchParams, 'offset', 0);

    const data = slice(results, offset, limit + offset);

    const SORTABLE_FIELDS = [
      { key: 'state', label: 'State', sortFn: () => undefined },
      { key: 'workHistory.open', label: 'Opened', sortFn: () => undefined },
      { key: 'workHistory.done', label: 'Completed', sortFn: () => undefined },
      { key: 'actionCount.total', label: 'Count', sortFn: () => undefined },
      { key: 'time', label: 'Time to completion', sortFn: undefined },
      { key: 'quality', label: 'Quality', sortFn: undefined },
      { key: 'empty', label: this.renderRefreshButton(), sortFn: undefined },
    ];

    return (
      <div className="bulk-loads-results__content">
        <BulkLoadsSortableTable
          items={data}
          sortableFields={SORTABLE_FIELDS}
          renderItem={this.renderItem}
          className="bulk-loads-results__table"
          onSort={this.sort}
          sortField={searchParams.sortField}
          sortType={searchParams.sortDirection}
          isExternalSortField={true}
        />
      </div>
    );
  }

  renderRefreshButton() {
    const { refresh, refreshing, results } = this.props;
    return results.some((it) => it.state === BULK_LOADS_STATES.OPEN || it.state === BULK_LOADS_STATES.ACTIVE)
      ? (
        <BulkLoadsRefresh
          refresh={refresh}
          refreshing={refreshing}
          results={results}
        />
      )
      : null;
  }

  render() {
    const {
      loading,
      results,
      searchParams
    } = this.props;

    const pageSize = get(searchParams, 'pageSize', BULK_LOADS_PAGE_SIZE);

    return (
      <div className="bulk-loads-results">
        <BulkLoadsExpandContent title="Übersicht Loads">
          {this.renderContent()}
          <Pagination
            recordCount={get(results, 'length', 0)}
            onPageChange={this.onChangePage}
            pageSize={pageSize}
            requesting={loading}
          />
        </BulkLoadsExpandContent>
      </div>
    );
  }
}

BulkLoadsResults.propTypes = {
  loading: PropTypes.bool,
  results: PropTypes.arrayOf(BULK_LOAD_PROP_TYPES_RESULT_ITEM),
  resultsRaw: PropTypes.object,
  resultsTotalCount: PropTypes.number.isRequired,
  refresh: PropTypes.func.isRequired,
  refreshing: PropTypes.bool,
  search: PropTypes.func.isRequired,
  searchParams: BULK_LOAD_PROP_TYPES_SEARCH_PARAMS,
};

BulkLoadsResults.defaultProps = {
  resultsRaw: {}
}

import { Reducers } from '@evoja-web/redaction';

import { reducer as servicesReducer } from './Api/Services';
import { reducer as schemaReducer } from './Api/Schema';

export const reducers = Reducers([
  servicesReducer,
  schemaReducer
]);

export default reducers.get();

import { createSelector } from 'reselect';
import { getDocuments } from './getBasicServices';
import { getProductsWithDocuments } from './getProductsWithDocuments';
import { mapBasicDocumentsToApi } from '../../services/BasicProducts';
import { getIdFromRef } from '../../utils';

export const getNewDocuments = createSelector([
  getProductsWithDocuments,
  getDocuments,
], (products, documents) => {
  return products
    .filter((product) => !documents.some((document) => getIdFromRef(document.product.$ref) === product.id))
    .map((product) => mapBasicDocumentsToApi(product));
});

import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { get, slice } from 'lodash';
import { JOB_PROCESSING_PAGE_SIZE } from '../../constants/JobProcessingConstants';

import { EndpointName } from '../../constants/EndpointName';
import { createSearchQuery } from './createSearchQuery';
import { EMAIL_SUBSCRIPTIONS_SEARCH } from '../../actions/EmailSubscriptionsActions';

export function* emailSubscriptionsSearchSaga(action) {
  try {
    const {
      rawData = [],
      limit = JOB_PROCESSING_PAGE_SIZE,
      offset = 0
    } = get(action, 'data', {});
    yield put(EMAIL_SUBSCRIPTIONS_SEARCH.pending());

    const chunk = slice(rawData, offset, limit + offset);
    // If we already have data for the given chunk, return current data but update limit / offset
    if (get(chunk, 'length', 0) > 0) {
      yield put(EMAIL_SUBSCRIPTIONS_SEARCH.success({
        searchParams: action.data,
        items: rawData,
        limit,
        offset
      }));

      return;
    }

    const searchQuery = yield call(createSearchQuery, action.data);
    const { data: items } = yield call(axios, {
      url: `${EndpointName.EMAIL_SUBSCRIPTIONS}/?${searchQuery}`,
    });

    yield put(EMAIL_SUBSCRIPTIONS_SEARCH.success({
      searchParams: action.data,
      items: [...rawData, ...items],
      limit,
      offset
    }));
  } catch (error) {
    yield put(EMAIL_SUBSCRIPTIONS_SEARCH.failure(error));
  }
}

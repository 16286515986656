import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getLanguage } from '../../selectors/commonSelectors';

class TranslatedMessageWithState extends React.Component {
  render() {
    const { value = {}, locale } = this.props;
    return value[locale] || value.de || null;
  }
}

const mapStateToProps = createStructuredSelector({
  language: getLanguage,
});

export const TranslatedMessage = connect(mapStateToProps, {})(TranslatedMessageWithState);

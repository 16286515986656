import './AnalyticsLogsSearchResultsDetails.css';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table, TableCell, TableRow } from '../Common/Table';
import { PROP_TYPES_DETAILS } from './AnalyticsLogsPropTypes';
import { FormattedGravitonDate } from '../FormattedGravitonDate';
import { HTTP_CODES } from '../../constants/HttpCodes';

// HTTP Codes for which to show the response body alongside the request body
const SHOW_RESPONSE_BODY_ERROR_CODES = [400, 500];

export function AnalyticsLogsSearchResultsDetails({ requestDetails }) {
  const statusDescription = HTTP_CODES[requestDetails.responseCode]
    ? (
      <span className="analytics-logs-results-details__response-code"
            title={HTTP_CODES[requestDetails.responseCode].description}>
        {HTTP_CODES[requestDetails.responseCode].title}
      </span>
    )
    : null;

    const shouldShowResponseBody = SHOW_RESPONSE_BODY_ERROR_CODES.includes(requestDetails.responseCode);

  return (
    <Table className="analytics-logs-results-details">
      <TableRow>
        <TableCell className="analytics-logs-results-details__label">
          <FormattedMessage id="analyticsLogs_searchResultsDetails_username"/>
        </TableCell>
        <TableCell className="analytics-logs-results-details__value">
          {requestDetails.username} ({requestDetails.name})
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell className="analytics-logs-results-details__label">
          <FormattedMessage id="analyticsLogs_searchResultsDetails_department"/>
        </TableCell>
        <TableCell className="analytics-logs-results-details__value">{requestDetails.department}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell className="analytics-logs-results-details__label">
          <FormattedMessage id="analyticsLogs_searchResultsDetails_division"/>
        </TableCell>
        <TableCell className="analytics-logs-results-details__value">{requestDetails.division}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell className="analytics-logs-results-details__label">
          <FormattedMessage id="analyticsLogs_searchResultsDetails_userAgent"/>Agent</TableCell>
        <TableCell className="analytics-logs-results-details__value">{requestDetails.userAgent}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell className="analytics-logs-results-details__label">
          <FormattedMessage id="analyticsLogs_searchResultsDetails_createdAt"/>
        </TableCell>
        <TableCell className="analytics-logs-results-details__value">
          <FormattedGravitonDate value={requestDetails.createdAt} format="DD.MM.YYYY HH:mm:ss"/>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell className="analytics-logs-results-details__label">
          <FormattedMessage id="analyticsLogs_searchResultsDetails_method"/>
        </TableCell>
        <TableCell className="analytics-logs-results-details__value">{requestDetails.method}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell className="analytics-logs-results-details__label">
          <FormattedMessage id="analyticsLogs_searchResultsDetails_requestUri"/>
        </TableCell>
        <TableCell className="analytics-logs-results-details__value">{requestDetails.requestUri}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell className="analytics-logs-results-details__label">
          <FormattedMessage id="analyticsLogs_searchResultsDetails_responseCode"/>
        </TableCell>
        <TableCell className="analytics-logs-results-details__value">
          {requestDetails.responseCode} {statusDescription} ({requestDetails.requestTimeS.toFixed(3)} s)
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell className="analytics-logs-results-details__label">
          <FormattedMessage id="analyticsLogs_searchResultsDetails_requestBody"/>
        </TableCell>
        <TableCell className="analytics-logs-results-details__value">
          <pre className="analytics-logs-results-details__pre">{requestDetails.requestBody}</pre>
        </TableCell>
      </TableRow>

      {
        shouldShowResponseBody ? (
          <TableRow>
        <TableCell className="analytics-logs-results-details__label">
          <FormattedMessage id="analyticsLogs_searchResultsDetails_responseCode"/>
        </TableCell>
        <TableCell className="analytics-logs-results-details__value">
          <pre className="analytics-logs-results-details__pre">{requestDetails.responseBody}</pre>
        </TableCell>
      </TableRow>
        ) : null
      }
    </Table>
  );
}

AnalyticsLogsSearchResultsDetails.propTypes = {
  requestDetails: PROP_TYPES_DETAILS,
};

import { createSelector } from 'reselect';
import _ from 'lodash';

export const getDocumentsState = (state) => state.documents;
export const getDocuments = createSelector(
  [getDocumentsState],
  (state) => _.sortBy(state.documents, d => d.metadata.order),
);

export const getRequesting = createSelector([getDocumentsState], (state) => state.requesting);
export const getRequestingChunk = createSelector([getDocumentsState], (state) => state.requestingChunk);
export const getModules = createSelector([getDocumentsState], (state) => state.modules);
export const getScreens = createSelector([getDocumentsState], (state) => state.screens);
export const getButtons = createSelector([getDocumentsState], (state) => state.buttons);
export const getShowButtons = createSelector([getDocumentsState], (state) => state.showButtons);
export const getSelectedModule = createSelector([getDocumentsState], (state) => state.selectedModule);
export const getTotalItems = createSelector([getDocumentsState], (state) => state.totalItems);
export const getHasError = createSelector([getDocumentsState], (state) => state.hasError);
export const getError = createSelector([getDocumentsState], (state) => state.error);
export const getSearchString = createSelector([getDocumentsState], (state) => state.searchString);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import update from 'immutability-helper';
import { toast } from 'react-toastify';
import EditContainer from '../components/EditContainer';
import * as actions from '../actions/FinancingSurchargeLendingrateActions';
import List from '../components/FinancingSurchargeLendingrate/List';
import { handleGravitonError } from '../utils';


class FinancingSurchargeLendingrate extends React.Component {
  /**
   * Validate the given string to has the correct format (decimal number with 5 places)
   *
   * @param  {String} value Input string
   *
   * @return {Boolean}
   */
  static validateDecimal(value) {
    let valid = false;
    const regex = new RegExp(/^(0|[1-9]\d*)(\.\d{1,5})?$/);

    if (regex.test(value)) valid = true;

    return valid;
  }

  /**
   * constructor
   * Bind mehtods to this...
   *
   * @return void
   */
  constructor() {
    super();
    this.save = this.save.bind(this);
    this.onItemChange = this.onItemChange.bind(this);
    this.onItemRemove = this.onItemRemove.bind(this);
    this.onItemAdd = this.onItemAdd.bind(this);
    this.onItemDuplicate = this.onItemDuplicate.bind(this);
  }

  /**
   * Load necessary data from backend.
   * Make sure durations and useTypes are loaded before all reates get loaded
   *
   * @return {Promise}
   */
  async componentWillMount() {
    const { dispatch } = this.props;

    dispatch(actions.init());
  }

  /**
   * Set the given value on item and update the store
   *
   * @param  {String} id    Item id
   * @param  {String} key   Key
   * @param  {Mixed}  value New value
   *
   * @return {FinancingSurchargeLendingrate} this This instance
   */
  onItemChange(item, key, value) {
    const { dispatch } = this.props;

    // Update item
    let updated = update(item, { [key]: { $set: value }, changed: { $set: true } });

    // Validate
    const errors = this.validate(item, updated);
    const valid = _.isEmpty(errors);
    updated = update(updated, { validation: { $set: { valid, errors } } });

    dispatch(actions.updateItem(item, updated));

    return this;
  }

  /**
   * Remove the given item on the backend and from store
   *
   * @param  {Object} item Rate item
   *
   * @return {FinancingSurchargeLendingrate} this This instance
   */
  onItemRemove(item) {
    const { dispatch } = this.props;
    dispatch(actions.removeItem(item));

    return this;
  }

  /**
   * Add a new item to the store.
   * Initialy set changed to true and call validate to make sure that empty
   * items can't be saved to the backend.
   *
   * @return {FinancingSurchargeLendingrate} this This instance
   */
  onItemAdd() {
    const item = {
      isNew: true,
      changed: true,
      validation: {
        valid: false,
        errors: this.validate(this, this),
      },
    };

    this.props.dispatch(actions.addItem(item));

    return this;
  }

  /**
   * Duplicate the given item, replace id and add it to the store
   *
   * @return {FinancingSurchargeLendingrate} this This instance
   */
  onItemDuplicate(item) {
    const duplicate = _.cloneDeep(item);
    const errors = this.validate(item, item);

    // Replace id, set isNew and changed and validate
    duplicate.id = `copy_of_${item.id}`;
    duplicate.isNew = true;
    duplicate.changed = true;
    duplicate.validation = {
      valid: _.isEmpty(errors),
      errors,
    };

    this.props.dispatch(actions.addItem(duplicate));

    return this;
  }

  /**
   * Simple validation for the given item.
   *
   * @param  {Object} current Current item
   * @param  {Object} item    Rate item
   *
   * @return {Object} result Validation result
   */
  validate(current, item) {
    let { rates } = this.props;
    const { schema } = this.props;
    const result = {};

    const numberFields = ['lendingRateStart', 'lendingRateEnd', 'rate'];
    const { required } = schema.items;

    /**
     * Check for existing/empty id.
     * Remove current item before check. Else item will always be found
     */
    rates = update(rates, { $splice: [[rates.findIndex(rate => rate === current), 1]] });
    const rate = rates.find(r => r.id === item.id);
    if (!_.isUndefined(rate)) {
      result.id = {
        valid: false,
        message: 'Die angegebene ID existiert bereits',
      };
    }

    const idRegex = new RegExp(/^[a-zA-Z0-9_-]*$/);
    if (!_.isEmpty(item.id) && !idRegex.test(item.id)) {
      result.id = {
        valid: false,
        message: 'Die angegebene ID enthält ungültige Zeichen.\nErlaubte Zeichen: a-z, A-Z, 0-9, -, _',
      };
    }

    // Check types
    numberFields.forEach((field) => {
      // Nicht required, also nix machen
      if (!item[field]) return;

      if (!FinancingSurchargeLendingrate.validateDecimal(item[field])) {
        result[field] = {
          valid: false,
          message: 'Der eingegebene Wert muss eine valide Zahl sein und darf maximal 5 Nachkommastellen beinhalten',
        };
      }
    });

    required.forEach((field) => {
      if (_.isUndefined(item[field]) || _.isNull(item[field]) || _.isEmpty(item[field])) {
        result[field] = {
          valid: false,
          message: 'Dies ist ein Pflichfeld',
        };
      }
    });

    return result;
  }

  /**
   * Filter items from store.
   * Get all items that changed / were added and write them to the backend
   *
   * @return {FinancingSurchargeLendingrate} this This instance
   */
  async save() {
    const { dispatch } = this.props;
    dispatch(actions.save());

    return this;
  }

  /**
   * Render the overview (list)
   *
   * @return {ReactElement} markup
   */
  renderOverview() {
    const {
      rates,
      durations,
      useTypes,
      loanProducts,
      language,
    } = this.props;

    return (
      <div>
        <List
          items={rates}
          durations={durations}
          useTypes={useTypes}
          loanProducts={loanProducts}
          language={language}
          onItemChange={this.onItemChange}
          onItemRemove={this.onItemRemove}
          onItemAdd={this.onItemAdd}
          onItemDuplicate={this.onItemDuplicate}
        />
      </div>
    );
  }

  /**
   * Render component
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      requesting,
      alertField,
      hasError,
      error,
      rates,
    } = this.props;
    const valid = _.every(rates.filter(rate => rate.changed), rate => rate.validation.valid);

    if (hasError) {
      toast.error(handleGravitonError(error));
    }

    return (
      <div>
        <EditContainer
          title="Belehnungsspezifische Zuschläge"
          requesting={requesting}
          alertField={alertField}
          body={this.renderOverview()}
          saveData={this.save}
          valid={valid}
        />
      </div>
    );
  }
}

FinancingSurchargeLendingrate.propTypes = {
  dispatch: PropTypes.func,
  rates: PropTypes.array,
  schema: PropTypes.object,
  durations: PropTypes.array,
  useTypes: PropTypes.array,
  loanProducts: PropTypes.array,
  language: PropTypes.string,
  requesting: PropTypes.bool,
  error: PropTypes.object,
  hasError: PropTypes.bool,
  alertField: PropTypes.object,
};

FinancingSurchargeLendingrate.defaultProps = {
  dispatch: null,
  rates: [],
  schema: {},
  durations: [],
  useTypes: [],
  loanProducts: [],
  language: 'en',
  requesting: false,
  error: {},
  hasError: false,
  alertField: {},
};

function mapStateToProps(state) {
  return {
    rates: state.financingSurchargeLendingrate.rates,
    durations: state.financingSurchargeLendingrate.durations,
    useTypes: state.financingSurchargeLendingrate.useTypes,
    loanProducts: state.financingSurchargeLendingrate.loanProducts,
    schema: state.financingSurchargeLendingrate.schema,
    requesting: state.financingSurchargeLendingrate.requesting,
    language: state.login.language,
    error: state.financingSurchargeLendingrate.error,
    hasError: state.financingSurchargeLendingrate.hasError  
  }
};

export default connect(mapStateToProps)(FinancingSurchargeLendingrate);
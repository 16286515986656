import { createSelector } from 'reselect';
import { getCommonSearch, getLanguage } from './commonSelectors';

export const getBankListState = (state) => state.bankList;
export const getBankListHasError = createSelector([getBankListState], (state) => state.hasError);
export const getBankListError = createSelector([getBankListState], (state) => state.error);
export const getBankListRequesting = createSelector([getBankListState], (state) => state.requesting);
export const getBankListBanks = createSelector([getBankListState], (state) => state.list);
export const getBankListRemovedItems = createSelector([getBankListState], (state) => state.removedItems);

export const getBankListVisible = createSelector([
  getBankListBanks,
  getCommonSearch,
  getLanguage,
], (banks, search, language) => {
  return !search
    ? banks
    : banks.filter((it) =>
      searchTranslatable(it.text, search, language) ||
      searchTranslatable(it.textShort, search, language)
    );
});

function searchTranslatable(field, text, language) {
  return field &&
    field[language] &&
    field[language].toLowerCase().indexOf(text.toLowerCase()) !== -1;
}

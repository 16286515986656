import { FINANCING_PROFILING_LOAD_ACTIONS } from '../../actions/FinancingProfilingActions';

const initialState = {
  entityCodes: [],
  profiles: [],
  loanProducts: [],
  durations: [],
  initialData: {
    profiles: [],
  },
  loading: true,
  error: undefined,
};

export function FinancingProfilingLoadReducer(state = initialState, action) {
  switch (action.type) {
    case FINANCING_PROFILING_LOAD_ACTIONS.PENDING:
      return { ...state, loading: true, error: undefined };
    case FINANCING_PROFILING_LOAD_ACTIONS.SUCCESS:
      const { entityCodes, profiles, loanProducts, durations } = action.data;
      return {
        ...state,
        loading: false,
        error: undefined,
        entityCodes,
        profiles,
        loanProducts,
        durations,
        initialData: {
          profiles,
        },
      };
    case FINANCING_PROFILING_LOAD_ACTIONS.FAILURE:
      return { ...state, loading: false, error: action.data };
    default:
      return state;
  }
}

import { HOLIDAYS_SAVE_ACTIONS } from '../../actions/HolidaysActions';

export function HolidaysSaveReducer(state, action) {
  switch (action.type) {
    case HOLIDAYS_SAVE_ACTIONS.PENDING:
      return { ...state, loading: true, error: undefined };
    case HOLIDAYS_SAVE_ACTIONS.SUCCESS:
      return { ...state, loading: false, error: undefined, holidaysLists: action.data, initialData: action.data };
    case HOLIDAYS_SAVE_ACTIONS.FAILURE:
      return { ...state, loading: false, error: action.data };
    default:
      return state;
  }
}

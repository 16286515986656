import React from 'react';
import { FormattedMessage } from 'react-intl';

import './JmesInputHelp.css';
import Collapsible from './Collapsible';

const JmesInputHelp = React.memo(function JmesInputHelp() {
  return (
    <Collapsible
      className="workguide-jmes-input-help"
      isCollapsed
      label={<FormattedMessage id="Workguide.General.JmesInputHelp.Title" />}
    >
      <div className="workguide-jmes-input-help--item">
        <FormattedMessage
          id="Workguide.General.JmesInputHelp.State.Title"
          tagName="strong"
        />

        <FormattedMessage
          id="Workguide.General.JmesInputHelp.State.Hint"
          tagName="div"
        />

        <div className="text-warning">
          <FormattedMessage id="Workguide.General.JmesInputHelp.State.Warning" />
        </div>
      </div>

      <div className="workguide-jmes-input-help--item">
        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Activity.Title"
          tagName="strong"
        />

        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Activity.Hint"
          tagName="div"
        />
      </div>

      <div className="workguide-jmes-input-help--item">
        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Bpf.Title"
          tagName="strong"
        />

        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Bpf.Hint"
          tagName="div"
        />

        <div className="text-warning">
          <FormattedMessage id="Workguide.General.JmesInputHelp.Bpf.Warning" />
        </div>
      </div>

      <div className="workguide-jmes-input-help--item">
        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Codes.Title"
          tagName="strong"
        />

        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Codes.Hint"
          tagName="div"
        />

        <div className="text-warning">
          <FormattedMessage id="Workguide.General.JmesInputHelp.Codes.Warning" />
        </div>
      </div>

      <div className="workguide-jmes-input-help--item">
        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Customer.Title"
          tagName="strong"
        />

        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Customer.Hint"
          tagName="div"
        />

        <div className="text-warning">
          <FormattedMessage id="Workguide.General.JmesInputHelp.Customer.Warning" />
        </div>
      </div>

      <div className="workguide-jmes-input-help--item">
        <FormattedMessage
          id="Workguide.General.JmesInputHelp.WorkflowTree.Title"
          tagName="strong"
        />

        <FormattedMessage
          id="Workguide.General.JmesInputHelp.WorkflowTree.Hint"
          tagName="div"
        />

        <div className="text-warning">
          <FormattedMessage id="Workguide.General.JmesInputHelp.WorkflowTree.Warning" />
        </div>
      </div>

      <div className="workguide-jmes-input-help--item">
        <FormattedMessage
          id="Workguide.General.JmesInputHelp.WorkflowTreeNodes.Title"
          tagName="strong"
        />

        <FormattedMessage
          id="Workguide.General.JmesInputHelp.WorkflowTreeNodes.Hint"
          tagName="div"
        />

        <div className="text-warning">
          <FormattedMessage id="Workguide.General.JmesInputHelp.WorkflowTreeNodes.Warning" />
        </div>
      </div>

      <div className="workguide-jmes-input-help--item">
        <FormattedMessage
          id="Workguide.General.JmesInputHelp.FormData.Title"
          tagName="strong"
        />

        <FormattedMessage
          id="Workguide.General.JmesInputHelp.FormData.Hint"
          tagName="div"
        />

        <div className="text-warning">
          <FormattedMessage id="Workguide.General.JmesInputHelp.FormData.Warning" />
        </div>
      </div>

      <div className="workguide-jmes-input-help--item">
        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Language.Title"
          tagName="strong"
        />

        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Language.Hint"
          tagName="div"
        />

        <div className="text-warning">
          <FormattedMessage id="Workguide.General.JmesInputHelp.Language.Warning" />
        </div>
      </div>

      <div className="workguide-jmes-input-help--item">
        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Session.Title"
          tagName="strong"
        />

        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Session.Hint"
          tagName="div"
        />

        <div className="text-warning">
          <FormattedMessage id="Workguide.General.JmesInputHelp.Session.Warning" />
        </div>
      </div>

      <div className="workguide-jmes-input-help--item">
        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Holidays.Title"
          tagName="strong"
        />

        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Holidays.Hint"
          tagName="div"
        />
      </div>

      <div>
        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Workguide.Title"
          tagName="strong"
        />

        <FormattedMessage
          id="Workguide.General.JmesInputHelp.Workguide.Hint"
          tagName="div"
        />
      </div>
    </Collapsible>
  );
});

export default JmesInputHelp;

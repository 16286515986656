import { SAVE_BASIC_PRODUCTS_ACTIONS } from '../../actions/BasicProductsActions';

export function SaveBasicProductsReducer(state, action) {
  switch (action.type) {
    case SAVE_BASIC_PRODUCTS_ACTIONS.PENDING:
      return {...state, loading: true, error: undefined};
    case SAVE_BASIC_PRODUCTS_ACTIONS.SUCCESS:
      return {...state, loading: false, error: undefined};
    case SAVE_BASIC_PRODUCTS_ACTIONS.FAILURE:
      return {...state, loading: false, error: action.data};
    default:
      return state;
  }
}

import { call, put, select } from 'redux-saga/effects';
import axios from 'axios';
import update from 'immutability-helper';
import { chain, get, has, set, isNil, reduce } from 'lodash';
import { handleNextHeaders, removeUnwantedValues } from '@evoja-web/redux-saga-utils';
import moment from 'moment';
import QueryBuilder from '@evoja-web/rql-query-builder';

import updateLeadAction from '../actions/UpdateLead';

const {
  UPDATE_LEAD_PENDING,
  UPDATE_LEAD_FULFILLED,
  UPDATE_LEAD_REJECTED
} = updateLeadAction;

function* getStatusClosed() {
  const query = QueryBuilder()
    .eq('id', 'leadCustomerTaskStatus')
    .limit(1)
    .getQueryString();

  const url = `/entity/codemapping/${query}`;

  const result = yield call(handleNextHeaders, url);

  return chain(result)
    .first()
    .get('mapping', [])
    .find((m) => m.key === 'codeMapping-closed')
    .get('value', [])
    .value();
}

export default function* updateLead(request) {
  const {
    changes,
    dataKey,
    lead
  } = request;

  yield put({ type: UPDATE_LEAD_PENDING, dataKey });

  try {
    const session = yield select((state) => get(state, 'login.session'));
    const statusClosed = yield call(getStatusClosed);
    const isLeadClosed = statusClosed.includes(get(lead, 'customerTaskStatus.id'));
    const statusNotClosed = has(changes, 'customerTaskStatus') && !statusClosed.includes(get(changes, 'customerTaskStatus.id'));

    if (isLeadClosed && statusNotClosed) {
      set(changes, 'resolvedDate', undefined);
      set(changes, 'isClosed', false);
    }

    // Prepare the updated lead data
    const updated = reduce(changes, (result, value, key) => {
      return isNil(value)
        ? update(result, { $unset: [key] })
        : update(result, { [key]: { $set: value } });
    }, lead);

    const modification = [
      ...get(lead, 'modification', []),
      removeUnwantedValues({
        date: moment().format(),
        oldCustomerTaskStatus: get(lead, 'customerTaskStatus.id'),
        consultantId: get(session, 'id'),
        oldDueDate: get(lead, 'dueDate'),
        oldExpireDate: get(lead, 'expireDate'),
        oldCustomerResponse: get(lead, 'customerResponse')
      }, [undefined, null])
    ];

    const payload = {
      ...updated,
      modification
    };

    const url = `/work/leadmanagement/${lead.id}`;
    const result = yield call(axios, {
      url,
      method: 'put',
      data: payload
    });

    yield put({ type: UPDATE_LEAD_FULFILLED, dataKey, payload, result });

    return payload;
  } catch (error) {
    yield put({ type: UPDATE_LEAD_REJECTED, dataKey, error });

    return error;
  }
}

import './EmailSubscriptionsEditForm.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, Form, reduxForm } from 'redux-form';
import { Button } from '../Common/Button';
import { FormInput, FormSelect } from '../FormControls';
import {
  EMAIL_SUBSCRIPTIONS_GENDER_OPTIONS,
  EMAIL_SUBSCRIPTIONS_LANGUAGE_OPTIONS,
} from '../../constants/EmailSubscriptionsConstants';

class EmailSubscriptionsEditReduxForm extends React.PureComponent {
  render() {
    const { subscriptionTypeOptions, subscriptionStateOptions } = this.props;
    const { handleSubmit, invalid, close, save, saving, submitFailed } = this.props;

    return (
      <div className="email-subscriptions-edit">
        <Form onSubmit={handleSubmit(save)}>
          <Field component={FormSelect}
                 size='large'
                 label={<FormattedMessage id="emailSubscriptions_language"/>}
                 labelSize={4}
                 name="language"
                 multi={false}
                 clearable={false}
                 options={EMAIL_SUBSCRIPTIONS_LANGUAGE_OPTIONS}
          />
          <Field component={FormSelect}
                 size='large'
                 label={<FormattedMessage id="emailSubscriptions_gender"/>}
                 labelSize={4}
                 name="gender"
                 multi={false}
                 clearable={false}
                 options={EMAIL_SUBSCRIPTIONS_GENDER_OPTIONS}
          />
          <Field component={FormInput}
                 size='large'
                 type="text"
                 label={<FormattedMessage id="emailSubscriptions_titleEx"/>}
                 labelSize={4}
                 name="title"
          />
          <Field component={FormInput}
                 size='large'
                 type="text"
                 label={<FormattedMessage id="emailSubscriptions_firstName"/>}
                 labelSize={4}
                 name="firstName"
          />
          <Field component={FormInput}
                 size='large'
                 type="text"
                 label={<FormattedMessage id="emailSubscriptions_lastName"/>}
                 labelSize={4}
                 name="lastName"
          />
          <Field component={FormInput}
                 size='large'
                 type="text"
                 label={<FormattedMessage id="emailSubscriptions_email"/>}
                 labelSize={4}
                 name="email"
          />
          <Field component={FormSelect}
                 size='large'
                 label={<FormattedMessage id="emailSubscriptions_subscriptionType"/>}
                 labelSize={4}
                 name="subscriptionType"
                 multi={true}
                 clearable={true}
                 options={subscriptionTypeOptions}
          />
          <Field component={FormSelect}
                 size='large'
                 label={<FormattedMessage id="emailSubscriptions_subscriptionState"/>}
                 labelSize={4}
                 name="subscriptionState"
                 multi={false}
                 clearable={false}
                 options={subscriptionStateOptions}
          />

          <div className="email-subscriptions-edit__footer">
            <Button type="submit" bsStyle="primary" disabled={saving || (submitFailed && invalid)}
                    className="email-subscriptions-edit__save">
              <FormattedMessage id="common_save"/>
            </Button>
            <Button onClick={close}>
              <FormattedMessage id="common_close"/>
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export const EmailSubscriptionsEditForm = reduxForm({})(EmailSubscriptionsEditReduxForm);
EmailSubscriptionsEditForm.propTypes = {
  save: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  subscriptionTypeOptions: PropTypes.array,
  subscriptionStateOptions: PropTypes.array,
};

import React from 'react';
import PropTypes from 'prop-types';
import { has, get, noop, forEach } from 'lodash';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';
import Toggle from 'react-toggle';
import Select from 'react-virtualized-select';
import update from 'immutability-helper';

import SpecialComponentConfigValue from './SpecialComponentConfigValue';

class LeadForm extends React.PureComponent {
  constructor(props) {
    super(props);

    const { item, fetchCodes } = props;

    this.state = {
      selectedConsultantOption: get(get(item, 'props.specialComponentConfig', []).find(d => d.key === 'consultant'), 'type')
    };

    this.selectOptions = [
      { value: 'current', label: 'Aktueller Benutzer' },
      { value: 'customer', label: 'Hauptbetreuer des Kunden' },
      { value: 'consultant', label: 'Berater Auswahl' }
    ];

    fetchCodes('orderOrigin');
    fetchCodes('customerTaskType');

    this.onDefaultValueChange = this.onDefaultValueChange.bind(this);
  }

  /**
   * Handle value change of  input fiels
   *
   * @param  {String} key   Field name
   * @param  {Mixed}  value Field value
   *
   * @return void
   */
  onDefaultValueChange(key, value, additional = {}) {
    const { id, item, editValueField } = this.props;

    const configs = get(item, 'props.specialComponentConfig', []);
    let updated = configs;
    const index = configs.findIndex(d => d.key === key);

    if (index > -1) {
      updated = update(configs, {
        [index]: { defaults: { $set: value } }
      });

      forEach(additional, (a, k) => {
        updated = update(updated, { [index]: { [k]: { $set: a } } })
      });
    } else {
      const v = SpecialComponentConfigValue(key, { ...additional, defaults: value });

      updated = update(configs, { $push: [v] });
    }

    editValueField(id, 'specialComponentConfig', updated);
  }

  /**
   * Change edit state of the given field
   *
   * @param  {String}  key     Field name
   * @param  {Boolean} checked Checked state
   *
   * @return void
   */
  onEditableChange(key, checked) {
    const { id, item, editValueField } = this.props;

    const defaults = get(item, 'props.specialComponentConfig', []);
    const index = defaults.findIndex(d => d.key === key);

    if (index > -1) {
      const updated = update(defaults, {
        [index]: {
          disabled: { $set: !checked }
        }
      });

      editValueField(id, 'specialComponentConfig', updated);
    }
  }

  /**
   * Check if the given field already has a default value
   *
   * @param  {String} key Field name
   *
   * @return {Boolean} has True / false
   */
  hasConfigProp(key, prop = 'defaults') {
    const { item } = this.props;
    const configs = get(item, 'props.specialComponentConfig', []);

    return has(configs.find(d => d.key === key), prop);
  }

  /**
   * Get default value of the given field name
   *
   * @param  {String} key Field name
   *
   * @return {Mixed} value Default value or undefined
   */
  getConfigProp(key, prop = 'defaults') {
    const { item } = this.props;
    const defaults = get(item, 'props.specialComponentConfig', []);

    return get(defaults.find(d => d.key === key), prop);
  }

  /**
   * Check if the given field is editable
   *
   * @param  {String} key Field name
   *
   * @return {Boolean} editable Editable or not
   */
  isEditable(key) {
    const { item } = this.props;
    const defaults = get(item, 'props.specialComponentConfig', []);

    return !get(defaults.find(d => d.key === key), 'disabled', false);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { language, codes, consultants } = this.props;

    return (
      <React.Fragment>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Kontaktart Standard Wert
          </Col>

          <Col sm={4}>
            <Select
              value={this.getConfigProp('orderOrigin')}
              options={get(codes, 'orderOrigin', []).map(c => ({ value: c.id, label: get(c, `text.${language}`) }))}
              onChange={(selected) => this.onDefaultValueChange('orderOrigin', get(selected, 'value'))}
              multi={false}
            />
          </Col>

          <Col componentClass={ControlLabel} sm={3}>
            Editierbar
          </Col>

          <Col sm={2} style={{ paddingTop: '10px'}}>
            <Toggle
              checked={this.isEditable('orderOrigin')}
              onChange={(ev) => this.onEditableChange('orderOrigin', get(ev, 'target.checked'))}
              disabled={!this.hasConfigProp('orderOrigin')}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Leadart Standard Wert
          </Col>

          <Col sm={4}>
            <Select
              value={this.getConfigProp('customerTaskType')}
              options={get(codes, 'customerTaskType', []).map(c => ({ value: c.id, label: get(c, `text.${language}`) }))}
              onChange={(selected) => this.onDefaultValueChange('customerTaskType', get(selected, 'value'))}
              multi={false}
            />
          </Col>

          <Col componentClass={ControlLabel} sm={3}>
            Editierbar
          </Col>

          <Col sm={2} style={{ paddingTop: '10px'}}>
            <Toggle
              checked={this.isEditable('customerTaskType')}
              onChange={(ev) => this.onEditableChange('customerTaskType', get(ev, 'target.checked'))}
              disabled={!this.hasConfigProp('customerTaskType')}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Kundenberater
          </Col>

          <Col sm={4}>
            <Select
              value={this.getConfigProp('staffMemberId', 'defaultValueType')}
              options={this.selectOptions}
              onChange={(selected) => this.onDefaultValueChange('staffMemberId', undefined, { defaultValueType: get(selected, 'value') })}
            />
          </Col>

          <Col componentClass={ControlLabel} sm={3}>
            Editierbar
          </Col>

          <Col sm={2} style={{ paddingTop: '10px'}}>
            <Toggle
              checked={this.isEditable('staffMemberId')}
              onChange={(ev) => this.onEditableChange('staffMemberId', get(ev, 'target.checked'))}
              disabled={!this.hasConfigProp('staffMemberId')}
            />
          </Col>
        </FormGroup>

        {this.getConfigProp('staffMemberId', 'defaultValueType') === 'consultant' ? (
          <FormGroup>
            <Col componentClass={ControlLabel} sm={3}>
              Berater
            </Col>

            <Col sm={9}>
              <Select
                value={this.getConfigProp('staffMemberId', 'defaults')}
                options={consultants.map(c => ({ value: c.id, label: `${c.firstName} ${c.lastName}, ${c.username}` }))}
                onChange={selected => this.onDefaultValueChange('staffMemberId', get(selected, 'value'))}
              />
            </Col>
          </FormGroup>
        ) : null}
      </React.Fragment>
    );
  }
}

LeadForm.propTypes = {
  item: PropTypes.object.isRequired,
  language: PropTypes.string,
  codes: PropTypes.object,
  consultants: PropTypes.array,
  fetchCodes: PropTypes.func
};

LeadForm.defaultProps = {
  language: 'de',
  codes: {},
  consultants: [],
  fetchCodes: noop
};

export default LeadForm;

import './ApiClientHeadersRenderer.css';

import React from 'react';
import { Field } from 'redux-form';
import { FormInput } from '../FormControls';

export function ApiClientHeadersRenderer({ fields }) {
  const items = fields.map((it, index) => {
    const removeItem = () => fields.remove(index);
    return renderRow(index, removeItem);
  });
  const createItem = () => fields.push({});
  return (
    <div className="api-client-headers-form">
      {items}
      <span className="icon-061-plus api-client-headers-form__button" onClick={createItem}/>
    </div>
  );
}

function renderRow(index, onRemove) {
  return (
    <div className="api-client-headers-form__row" key={index}>
      <div className="api-client-headers-form__cell">
        <Field component={FormInput}
               name={`headers[${index}].key`}
               type="text"
               size='large'
               placeholder="Key"
        />
      </div>
      <div className="api-client-headers-form__cell">
        <Field component={FormInput}
               name={`headers[${index}].value`}
               type="text"
               size='large'
               placeholder="Value"
        />
      </div>
      <span className="icon-008-bin api-client-headers-form__action" onClick={onRemove}/>
    </div>
  );
}

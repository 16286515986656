import axios from 'axios';
import { all, call, select } from 'redux-saga/effects';
import { getSurchargeRealestatetypes } from '../../selectors/FinancingSurchargeRealestatetype';
import { createRef } from '../../utils';
import { EndpointName } from '../../constants/EndpointName';

export function* createSurchargeRealestatetypesSaga(changedItems) {
  const surchargeRealestatetypes = yield select(getSurchargeRealestatetypes);
  const created = changedItems.filter(
    (changed) => !surchargeRealestatetypes.some((initial) => changed.id === initial.id)
  );

  yield all(
    created.map((item) => call(axios, {
      url: createRef(EndpointName.FINANCING_SURCHARGE_REALESTATETYPE, item.id),
      method: 'put',
      data: item,
    }))
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, set } from 'lodash';
import NumberFormat from 'react-number-format';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';

function getCurrentParams(component) {
  return get(component, 'props.validations.arrayValuesGe.arrayValuesGe', []);
}

class ArrayValuesGeValidationConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.onMinChange = this.onMinChange.bind(this);
    this.onPathChange = this.onPathChange.bind(this);
  }

  onMinChange({ floatValue }) {
    const {
      component,
      onChange
    } = this.props;

    const updated = set(
      [...getCurrentParams(component)],
      0,
      floatValue
    );

    onChange(updated);
  }

  onPathChange(ev) {
    const value = get(ev, 'target.value');
    const {
      component,
      onChange
    } = this.props;

    const updated = set(
      [...getCurrentParams(component)],
      1,
      value
    );

    onChange(updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { component } = this.props;
    const params = getCurrentParams(component);

    return (
      <div style={{ paddingRight: '15px', paddingLeft: '15px' }}>
        <FormGroup>
          <ControlLabel>
            Minimum Wert
          </ControlLabel>

          <NumberFormat
            id="min-value"
            className="form-control"
            value={get(params, 0)}
            onValueChange={this.onMinChange}
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>
            Pfad zu Wert (optional) Wenn es sich um ein Array von Objekten handelt
          </ControlLabel>

          <FormControl
            type="text"
            id="object-path"
            value={get(params, 1)}
            onChange={this.onPathChange}
          />
        </FormGroup>
      </div>
    );
  }
}

ArrayValuesGeValidationConfiguration.propTypes = {
  component: PropTypes.object.isRequired,
  onChange: PropTypes.func
};

ArrayValuesGeValidationConfiguration.defaultProps = {

};

export default ArrayValuesGeValidationConfiguration;

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isNil } from 'lodash';
import { Toggle, NumberInput, ValidationResult } from '@evoja-web/react-form';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import update from 'immutability-helper';

class ActionConfigurationServiceCallEventPolling extends React.Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
  }

  /**
   * Handle value change of one of the form fields
   *
   * @param   {String}  key    Form element id
   * @param   {Mixed}   value  Form value
   *
   * @return  void
   */
  onValueChange(key, value) {
    const {
      action,
      onChange
    } = this.props;

    const current = get(action, 'eventPolling', {});
    const updated = isNil(value)
      ? update(current, { $unset: [key] })
      : update(current, { [key]: { $set: value } });

    onChange('eventPolling', updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      action,
      validations
    } = this.props;

    return (
      <div className="workguide-action-configuration-service-call--event-polling">
        <FormGroup>
          <ControlLabel>
            <FormattedMessage id="Workguide.Action.Configuration.ServiceCall.EventPolling.Active" />
          </ControlLabel>

          <Toggle
            id="active"
            onChange={this.onValueChange}
            value={get(action, 'eventPolling.active', false)}
          />

          <ValidationResult
            show
            validations={get(validations, 'eventPolling.active')}
          />
        </FormGroup>

        {get(action, 'eventPolling.active') && (
          <>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="Workguide.Action.Configuration.ServiceCall.EventPolling.Interval" />
              </ControlLabel>

              <NumberInput
                id="interval"
                onChange={this.onValueChange}
                value={get(action, 'eventPolling.interval', false)}
              />

              <ValidationResult
                show
                validations={get(validations, 'eventPolling.interval')}
              />
            </FormGroup>

            <FormGroup>
              <ControlLabel>
                <FormattedMessage id="Workguide.Action.Configuration.ServiceCall.EventPolling.MaxTries" />
              </ControlLabel>

              <NumberInput
                id="maxTries"
                onChange={this.onValueChange}
                value={get(action, 'eventPolling.maxTries', false)}
              />

              <ValidationResult
                show
                validations={get(validations, 'eventPolling.maxTries')}
              />
            </FormGroup>
          </>
        )}
      </div>
    );
  }
}

ActionConfigurationServiceCallEventPolling.propTypes = {
  action: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  validations: PropTypes.object
};

ActionConfigurationServiceCallEventPolling.defaultProps = {
  onChange: noop,
  validations: {}
};

export default ActionConfigurationServiceCallEventPolling;

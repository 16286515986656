import { takeLatest } from 'redux-saga/effects';

import actions from '../../actions/LeadHistory/Actions';
import getLeadHistory from './getLeadHistory';
import saveLeadHistory from './saveLeadHistory';

const {
  LEAD_HISTORY_REQUEST,
  SAVE_LEAD_HISTORY_REQUEST
} = actions;

export default function* generalRootSaga() {
  yield takeLatest(LEAD_HISTORY_REQUEST, getLeadHistory);
  yield takeLatest(SAVE_LEAD_HISTORY_REQUEST, saveLeadHistory);
}

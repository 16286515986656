import React from 'react';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { Col } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { mapNewDocument } from '../../services/Promotions';
import { DocumentPreview } from './FilePreview';

export const PromotionDocument = (props) => {
  const { id, document, onDocumentRemove, modules } = props;

  if (!document) {
    return null;
  }

  return (
    <Col lg={4} md={6} sm={6} key={id} style={{ zIndex: '9999' }}>
      <div style={{ width: '220px', minHeight: '350px', textAlign: 'left' }}>
        <DocumentPreview
          file={document}
          metadata={document.metadata}
          delFile={onDocumentRemove.bind(this, document)}
          modules={modules}
          links={[]}
          screens={[]}
          buttons={[]}
        />
      </div>
    </Col>
  );
};

export const SortableItem = SortableElement(PromotionDocument);

const Sortable = SortableContainer(({
  documents, onDocumentDrop, onDocumentRemove, modules,
}) => {
  const create = (
    <Col lg={4} md={6} sm={6}>
      <Dropzone
        accept="application/pdf"
        onDrop={onDocumentDrop}
        multiple={true}
      >
        <h1 className="text-center">
          <i className="icon-088-upload-cloud" />
        </h1>
        <p className="text-center">
          Neue Dateien hier her ziehen oder klicken um sie auszuwählen
        </p>
      </Dropzone>
    </Col>
  );

  const list = documents.map((item, key) => (
    <SortableItem
      key={key}
      id={key}
      index={key}
      document={item}
      onDocumentRemove={onDocumentRemove}
      modules={modules}
    />
  ));

  return (
    <div>
      { create }
      { list }
    </div>
  );
});

export default class PromotionDocuments extends React.Component {
  constructor(props) {
    super(props);

    this.onDocumentDrop = this.onDocumentDrop.bind(this);
    this.onDocumentRemove = this.onDocumentRemove.bind(this);
    this.onDocumentSort = this.onDocumentSort.bind(this);
  }

  onDocumentDrop(newDocuments, rejectedFiles) {
    const { documents, onDocumentsChange, language } = this.props;
    this.displayErrors(rejectedFiles);
    onDocumentsChange([
      ...documents,
      ...newDocuments.map((file) => mapNewDocument(file, language)),
    ]);
  }

  onDocumentRemove(document) {
    const { documents, onDocumentsChange } = this.props;
    onDocumentsChange(documents.filter((it) => it.id !== document.id));
  }

  onDocumentSort({oldIndex, newIndex}) {
    const { documents, onDocumentsChange } = this.props;
    onDocumentsChange(
      arrayMove(documents, oldIndex, newIndex)
        .map((document, index) => ({...document, metadata: {...document.metadata, order: index}}))
    );
  }

  render() {
    const { documents, modules } = this.props;

    return (
      <Sortable
        documents={documents}
        modules={modules}
        onDocumentDrop={this.onDocumentDrop}
        onDocumentRemove={this.onDocumentRemove}
        onSortEnd={this.onDocumentSort}
        axis={'xy'}
        distance={10}
        useWindowAsScrollContainer={true}
        hideSortableGhost={true}
      />
    );
  }

  displayErrors(rejectedFiles) {
    rejectedFiles.forEach((file) => {
      toast.error(
        `${file.name} hat den falschen Dateityp. Es werden nur PDF-Dateien akzeptiert`,
        { autoClose: 10000, position: toast.POSITION.TOP_CENTER },
      );
    });
  }
}

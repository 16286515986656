import { takeLatest } from 'redux-saga/effects';
import {
  API_CLIENT_EXECUTE,
  API_CLIENT_LOAD,
  API_CLIENT_LOAD_SERVICE,
  API_CLIENT_LOGIN,
} from '../../actions/ApiClientActions';
import { apiClientLoadSaga } from './apiClientLoadSaga';
import { apiClientLoginSaga } from './apiClientLoginSaga';
import { apiClientExecuteSaga } from './apiClientExecuteSaga';
import { apiClientLoadServiceSaga } from './apiClientLoadServiceSaga';

export function* apiClientSagas() {
  yield takeLatest(API_CLIENT_LOAD.REQUEST, apiClientLoadSaga);
  yield takeLatest(API_CLIENT_LOGIN.REQUEST, apiClientLoginSaga);
  yield takeLatest(API_CLIENT_LOAD_SERVICE.REQUEST, apiClientLoadServiceSaga);
  yield takeLatest(API_CLIENT_EXECUTE.REQUEST, apiClientExecuteSaga);
}

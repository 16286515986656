import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ListGroup } from 'react-bootstrap';
import update from 'immutability-helper';

import ListItem from './ListItem';
import ConditionForm from './Form';
import Add from './Add';


class ConditionList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      add: {
        show: false,
        operation: undefined
      }
    };

    this.onConditionChange = this.onConditionChange.bind(this);
    this.onSubConditionChange = this.onSubConditionChange.bind(this);
    this.onSubConditionsAdd = this.onSubConditionsAdd.bind(this);
  }

  /**
   * Handle conditions change.
   * If index is > -1, replace the condition, else add it to the array
   *
   * @param  {Object} condition  Condition object
   * @param  {Number} [index=-1] Index
   *
   * @return {[type]}            [description]
   */
  onConditionChange(condition, index = -1) {
    const { onChange } = this.props;
    let updated = [ ...this.props.conditions ];

    if (index > -1) {
      updated = update(updated, {
        $splice: [[index, 1, condition]]
      });
    } else {
      updated = update(updated, {
        $push: [condition]
      });
    }
    this.setState({ add: { show: false, type: undefined } });

    onChange(updated);
  }

  /**
   * Handle subconditions change.
   * This funciton is called if it is an and / or condition.
   * In this case, condition object is an object containing the operation and the given conditions
   *
   * @param  {[type]} subconditions [description]
   * @param  {[type]} operation     [description]
   * @return {[type]}               [description]
   */
  onSubConditionsAdd(subconditions, operation) {
    const { conditions, onChange } = this.props;
    const condition = {
      operation,
      conditions: subconditions
    };

    const updated = update(conditions, {
      $push: [condition]
    });

    this.setState({ add: { show: false, operation: undefined } });

    onChange(updated)
  }

  /**
   * Handle subcondition change.
   * This function is called if the condition is and/or.
   *
   * @param  {Array}  [subconditions=[]] [description]
   * @param  {Number} [index=-1]         [description]
   * @return {[type]}                    [description]
   */
  onSubConditionChange(subconditions = [], index = -1) {
    const { conditions, onChange } = this.props;

    // If there are no subconditions left, remove the whole conditions
    if (subconditions.length === 0) return this.onConditionRemove(index);

    const updated = update(conditions, {
      [index]: {
        conditions: { $set: subconditions }
      }
    });

    onChange(updated)
  }

  /**
   * Remove the condition on the given index
   *
   * @param  {Number} index
   *
   * @return void
   */
  onConditionRemove(index) {
    const { conditions, onChange } = this.props;

    if (index > -1) {
      const updated = update(conditions, { $splice: [[index, 1]] });

      onChange(updated);
    }
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { add } = this.state;
    const { language, components, conditions, target, operation, codes } = this.props;

    const children = conditions.map((condition, index) => (
      <React.Fragment key={index}>
        {(['and', 'or'].includes(condition.operation)) ? (
          <div style={{ padding: '20px', border: '1px solid lightgrey' }}>
            <div style={{ paddingBottom: '20px' }}>
              <div className="pull-right">
                <span onClick={() => this.onConditionRemove(index)}><i className="glyphicon glyphicon-remove" /></span>
              </div>
            </div>
            <ConditionList
              target={target}
              operation={condition.operation}
              conditions={condition.conditions}
              components={components}
              onChange={(conditions) => this.onSubConditionChange(conditions, index)}
              codes={codes}
            />
          </div>
        ) : (
          <ListItem
            key={index}
            index={index}
            target={target}
            language={language}
            condition={condition}
            components={components}
            onChange={(condition) => this.onConditionChange(condition, index)}
            onRemove={() => this.onConditionRemove(index)}
            codes={codes}
          />
        )}
        {(conditions.length > 1 && condition !== _.last(conditions)) ? (
          <div style={{ padding: '6px' }}>
            <strong>{(operation === 'or') ? 'oder' : 'und'}</strong>
          </div>
        ) : null}
      </React.Fragment>
    ));

    return(
      <div style={{ padding: '5px' }}>
        <ListGroup>
          {children}
        </ListGroup>

        {(add.show) ? (
          (['and', 'or'].includes(add.operation)) ? (
              <div style={{ padding: '20px' }}>
                <div>
                  <div className="pull-right">
                    <span onClick={() => this.setState({ add: { show: false, operation: undefined } })}><i className="glyphicon glyphicon-remove" /></span>
                  </div>
                </div>
                <ConditionList
                  target={target}
                  operation={add.operation}
                  components={components}
                  onChange={(conditions) => this.onSubConditionsAdd(conditions, add.operation)}
                  codes={codes}
                />
              </div>
            ) : (
              <ConditionForm
                target={target}
                onSubmit={this.onConditionChange}
                onCancel={() => this.setState({ add: { show: false, type: undefined } })}
                codes={codes}
              />
            )
        ) : null}

        {(['and', 'or'].includes(operation)) ? (
          <div>
            <Add
              options={['single', 'or', 'and']}
              onClick={(operation) => this.setState({ add: { show: true, operation } })}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

ConditionList.propTypes = {
  target: PropTypes.object.isRequired,
  operation: PropTypes.string,
  language: PropTypes.string,
  conditions: PropTypes.array,
  components: PropTypes.array,
  codes: PropTypes.object,
  consultants: PropTypes.array,
  productGroups: PropTypes.array,
  onChange: PropTypes.func
};

ConditionList.defaultProps = {
  language: 'de',
  operation: 'single',
  conditions: [],
  components: [],
  codes: {},
  consultants: [],
  productGroups: [],
  onChange: _.noop
};

function mapStateToProps(state, ownProps) {
  const { components } = state.workguide.workguide;

  return {
    language: state.login.language,
    components,
    consultants: state.workguide.consultants,
    codeGroups: state.workguide.codeGroups,
    codes: state.workguide.codes,
    productGroups: state.workguide.productGroups
  };
}

export default connect(mapStateToProps)(ConditionList);

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Select, ValidationResult } from '@evoja-web/react-form';
import { Alert, Button, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import './Login.css';
import { getValidator } from '../../../../globals';

const validationDefinition = {
  validations: {
    target: {
      type: 'object',
      required: true
    },
    username: {
      type: 'string',
      required: true
    },
    password: {
      type: 'string',
      required: true
    }
  }
};

class ParamTransferTargetLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: undefined,
      target: undefined,
      username: undefined
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onTargetChange = this.onTargetChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }

  /**
   * Handle target select
   *
   * @param   {String}  id        Form element id
   * @param   {String}  value     Target prop from option value
   *
   * @return  {[type]}            [return description]
   */
  onTargetChange(id, value) {
    this.setState({ target: value });
  }

  /**
   * Hande value change of the given form element id
   *
   * @param   {Event} ev Change event
   *
   * @return  void
   */
  onValueChange(ev) {
    const id = get(ev, 'target.id');
    const value = get(ev, 'target.value', '');

    const updated = value.trim().length === 0
      ? undefined
      : value.trim();

    this.setState({ [id]: updated });
  }

  /**
   * Submit the form
   *
   * @return  void
   */
  onSubmit() {
    const { onSubmit } = this.props;

    onSubmit({ formData: this.state });
  }

  /**
   * Validate current input values
   *
   * @return  {Object} result Validation result
   */
  validate() {
    const {
      password,
      target,
      username,
    } = this.state;

    const validator = getValidator();

    return validator.validate(validationDefinition, { password, target, username });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      password,
      target,
      username
    } = this.state;
    const {
      error,
      targets
    } = this.props;

    const validationResult = this.validate();

    const targetOptions = targets.map((t) => ({
      value: t.key,
      label: t.name,
      target: t
    }));

    return (
      <div className="param-transfer-target--login">
        <FormGroup>
          <ControlLabel>
            <FormattedMessage id="ParamTransfer.Login.Target" />
          </ControlLabel>

          <Select
            id="target"
            onChange={this.onTargetChange}
            options={targetOptions}
            returnValue="target"
            value={get(target, 'key')}
          />

          <ValidationResult
            show
            validations={get(validationResult, 'target')}
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>
            <FormattedMessage id="ParamTransfer.Login.Username" />
          </ControlLabel>

          <FormControl
            id="username"
            type="text"
            value={username || ''}
            onChange={this.onValueChange}
          />

          <ValidationResult
            show
            validations={get(validationResult, 'username')}
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>
            <FormattedMessage id="ParamTransfer.Login.Password" />
          </ControlLabel>

          <FormControl
            id="password"
            type="password"
            value={password || ''}
            onChange={this.onValueChange}
          />

          <ValidationResult
            show
            validations={get(validationResult, 'password')}
          />
        </FormGroup>

        <Button
          bsStyle="primary"
          className="param-transfer-target--login--submit"
          onClick={this.onSubmit}
          type="submit"
        >
          <FormattedMessage id="ParamTransfer.Login.Submit" />
        </Button>

        {(error instanceof Error) && (
          <Alert
            bsStyle="warning"
            className="param-transfer-target--login--error"
          >
            <FormattedMessage
              id="ParamTransfer.Login.Error"
              tagName="h4"
            />

            <p>
              Error:
              <br />
              {get(error, 'message', '')}
            </p>

            <p>
              Cause:
              <br />
              {get(error, 'cause.message', '')}
            </p>
          </Alert>
        )}
      </div>
    );
  }
}

ParamTransferTargetLogin.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  onSubmit: PropTypes.func,
  targets: PropTypes.array
};

ParamTransferTargetLogin.defaultProps = {
  error: undefined,
  onSubmit: noop,
  targets: []
};

export default ParamTransferTargetLogin;

import axios from 'axios';
import { call } from 'redux-saga/effects';
import { get, toNumber } from 'lodash';

import { EndpointName } from '../../constants/EndpointName';

export default function* getActivitesAfterImportCount(fileId) {
  const result = yield call(axios, {
    url: `${EndpointName.WORK_ACTIVITY}/?eq(massUploadFile.ref.$id,${fileId})&select(id)&limit(1)`,
    method: 'get',
    timeout: 60000,
    headers: {
      'x-graviton-total-count': 1
    }
  })

  const count = get(
    result,
    'headers.x-total-count',
    get(result, 'headers.x-record-count', 0)
  );

  return toNumber(count);
}

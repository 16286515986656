import { call, put } from 'redux-saga/effects';

// ToDo: Move functions like handleNextHeaders to own repo
import replayRecordAction from '../../actions/ReplayAuditLog/ReplayRecord';

const {
  REPLAY_RECORD_PENDING,
  REPLAY_RECORD_FULFILLED,
  REPLAY_RECORD_REJECTED
} = replayRecordAction;

export default function* replayRecord(request) {
  const { dataKey, record } = request;

  yield put({ type: REPLAY_RECORD_PENDING, dataKey });

  try {
    const { connection } = request;
    const { body, method, requestUri } = record;

    const payload = yield call(connection, {
      url: requestUri,
      method,
      data: body
    });

    yield put({ type: REPLAY_RECORD_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: REPLAY_RECORD_REJECTED, dataKey, error });

    return error;
  }
}

import React from 'react';

import Items from './Items';
import { ChecklistHeader } from './ChecklistHeader';
import { Button } from '../Common/Button';

export default class Section extends React.Component {
  render() {
    const {
      language, module, onUpdate, onSortEnd,
    } = this.props;
    const sections = this.props.sections.map((section, i) =>
      (<div key={i}>
        <ChecklistHeader
          title={section.name[language]}
          label={section.collapsed ? 'Eingeklappt darstellen' : 'Ausgeklappt darstellen'}
          isChecked={!section.collapsed}
          onChange={onUpdate.bind(this, 'toggleSection', module, i, 0)}>
          <Button
            bsStyle="primary"
            name="newItem"
            onClick={onUpdate.bind(this, 'createItem', module, i, section.items.length + 1)}>
            Neuer Eintrag
          </Button>
        </ChecklistHeader>

        <Items
          key={i}
          index={i}
          language={language}
          section={i}
          module={module}
          onUpdate={onUpdate}
          onSortEnd={onSortEnd}
          items={section.items}
          useDragHandle
          helperClass="SortableHOCHelper"
        />
       </div>));
    return (
      <div>
        {sections}
      </div>
    );
  }
}

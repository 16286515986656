import React from 'react';

const CriteriaUnsupported = () => {
  return (
    <div>Abhängigkeiten werden bei diesem Feldtypen im Moment nicht unterstützt</div>
  )
};

CriteriaUnsupported.propTypes = {

};

CriteriaUnsupported.defaultProps = {

};

export default CriteriaUnsupported;

import axios from 'axios';
import { all, call } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { createRef } from '../../utils';

export function* saveDocumentsSaga(promotion, rawPromotion) {
  if (!rawPromotion) {
    yield call(createNewFiles, [
      ...promotion.document,
      promotion.image,
      promotion.teaserImage,
    ]);
  } else {
    const newFiles = [
      ...promotion.document.filter((document) =>
        !rawPromotion.document.some((it) => it.id === document.id)),
      promotion.image.id !== rawPromotion.image.id ? promotion.image : undefined,
      promotion.teaserImage.id !== rawPromotion.teaserImage.id ? promotion.teaserImage : undefined,
    ].filter(Boolean);

    yield all([
      yield call(createNewFiles, newFiles),
      yield call(removeFiles, promotion, rawPromotion),
      yield call(saveChangedDocuments, promotion, rawPromotion),
    ]);
  }
}

function* removeFiles(promotion, rawPromotion) {
  const removedFiles = [
    ...rawPromotion.document.filter((document) => !promotion.document.some((it) => it.id === document.id)),
    promotion.image.id !== rawPromotion.image.id ? rawPromotion.image : undefined,
    promotion.teaserImage.id !== rawPromotion.teaserImage.id ? rawPromotion.teaserImage : undefined,
  ].filter(Boolean);

  yield all(
    removedFiles.map((file) => call(axios, {
      url: createRef(EndpointName.FILE, file.id),
      method: 'delete',
    }))
  );
}

function* saveChangedDocuments(promotion, rawPromotion) {
  const changedDocuments = promotion.document.filter((document) => {
    const rawDocument = rawPromotion.document.find((it) => it.id === document.id);
    return rawDocument && document.metadata.order !== rawDocument.metadata.order;
  });
  yield all(
    changedDocuments.map((document) => call(axios, {
      url: createRef(EndpointName.FILE, document.id),
      method: 'put',
      data: {
        id: document.id,
        metadata: document.metadata,
        links: document.links,
      },
    }))
  );
}

function* createNewFiles(newFiles) {
  yield all(
    newFiles.map((document) => {
      const formData = new FormData();
      formData.append('upload', document.blob);
      formData.append('metadata', JSON.stringify({
        id: document.id,
        metadata: document.metadata,
        links: document.links,
      }));
      return call(axios, {
        url: createRef(EndpointName.FILE, document.id),
        method: 'put',
        headers: {'Content-Type': 'multipart/form-data'},
        data: formData,
      });
    })
  );
}

import { get, sumBy, reduce, last } from 'lodash';
import moment from 'moment';

export default function LoaderDataSet({
  loadId,
  results,
  options = {}
}) {
  const loaderActions = get(results, 'actions', []).filter((a) => get(a, 'loadId') === loadId);
  const stagerActions = loaderActions.filter((a) => get(a, 'component') === 'ml3k.stager');
  const targetLoaderActions = loaderActions.filter((a) => get(a, 'component') === 'ml3k.targetloader');

  const summedUp = {
    spoolingInitializeSeconds: sumBy(stagerActions, 'metrics.spoolingInitializeSeconds'),
    spoolingTimeSeconds: sumBy(stagerActions, 'metrics.spoolingTimeSeconds'),
    loadDataInfileTimeSeconds: sumBy(stagerActions, 'metrics.loadDataInfileTimeSeconds'),
    dataProviderInitializeSeconds: sumBy(targetLoaderActions, 'metrics.dataProviderInitializeSeconds'),
    dataProviderWorkingSeconds: sumBy(targetLoaderActions, 'metrics.dataProviderWorkingSeconds'),
    mongoDbPostProcessingSeconds: sumBy(targetLoaderActions, 'metrics.mongoDbPostProcessingSeconds'),
    mongoDbDatabaseWriteTimeSeconds: sumBy(targetLoaderActions, 'metrics.mongoDbDatabaseWriteTimeSeconds'),

    // fetchTimeAll: sumBy(stagerActions, 'metrics.fetchTimeAll'),
    // databaseWriteTime: sumBy(stagerActions, 'metrics.databaseWriteTime'),
    // sqlExecutionTime: sumBy(targetLoaderActions, 'metrics.sqlExecutionTime'),
    // mongoDBWriteTime: sumBy(targetLoaderActions, 'metrics.mongoDBWriteTime'),
    // mongoIndexCreationTime: sumBy(targetLoaderActions, 'metrics.mongoIndexCreationTime'),
    // mongoPostProcessingTime: sumBy(targetLoaderActions, 'metrics.mongoPostProcessingTime'),
  };

  const data = reduce(summedUp, (result, value = 0, key) => {
    result.push((last(result) + value));

    return result;
  }, [0]).map((f) => (f / 60).toFixed(2));

  return {
    loadId,
    label: moment(loadId, 'YYYYMMDDHHmmssSSS').format('DD.MM.YYYY HH:mm:ss'),
    fill: false,
    lineTension: 0,
    borderColor: 'black',
    data,
    ...options
  };
}

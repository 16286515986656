import './AnalyticsHistory.css';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CalendarMonth } from '../Common/CalendarMonth';
import { AnalyticsDateRange } from './AnalyticsDateRange';
import { AnalyticsMonthSelector } from './AnalyticsMonthSelector';
import { ModalBody, ModalDialog, ModalFooter, ModalHeader } from '../Common/Modal';
import { Button } from '../Common/Button';
import { AnalyticsHistoryUsers } from './AnalyticsHistoryUsers';
import { SearchBlock } from '../Common/SearchBlock';
import { FormattedDate } from '../Common/FormattedDate';
import {
  PROP_TYPES_HISTORY_DETAILS_ITEM,
  PROP_TYPES_HISTORY_ITEM,
  PROP_TYPES_HISTORY_STAT,
} from './AnalyticsPropTypes';
import { classList } from '../../utils/classList';
import Spinner from '../Spinner';
import { FormattedMessage } from 'react-intl';

const CURRENT_DATE = moment().startOf('date');

export class AnalyticsHistory extends React.Component {
  state = {
    year: CURRENT_DATE.year(),
    month: CURRENT_DATE.month(),
    isModalOpen: false,
    startDate: undefined,
    endDate: undefined,
  };

  constructor(props) {
    super(props);
    this.renderDay = this.renderDay.bind(this);
    this.onChangeMonth = this.onChangeMonth.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  render() {
    const { text = '', loading, locale } = this.props;
    const { year, month } = this.state;

    return (
      <div className="analytics-history">
        <div className="analytics-history__text">
          {text}
        </div>
        <div className="analytics-history__content">
          <AnalyticsMonthSelector onChange={this.onChangeMonth} locale={locale}/>
          <div className="analytics-history__calendar">
            {loading ? <div className="analytics-history__spinner"><Spinner/></div> : null}
            <CalendarMonth year={year} month={month} renderDay={this.renderDay}/>
          </div>
          <AnalyticsDateRange onChange={this.openModal}/>
        </div>

        {this.renderModal()}
      </div>
    );
  }

  renderDay(day) {
    const { items } = this.props;
    const { year, month } = this.state;
    const date = moment([year, month, day]).startOf('date');
    const item = items.find((it) => it.date.isSame(date));

    const classes = classList({
      'analytics-history__day': true,
      'analytics-history__day--disabled': date.isAfter(moment().startOf('date')),
      'analytics-history__day--empty': !item || !item.requestCount,
    });
    return (
      <div className={classes} onClick={this.selectDate.bind(this, day)}>
        <div className="analytics-history__day-title">{day}</div>
        <div className="analytics-history__day-requests">
          {item && item.requestCount ? item.requestCount : null}
        </div>
      </div>
    );
  }

  selectDate(day) {
    const { month, year } = this.state;
    this.openModal(moment([year, month, day]).startOf('date'));
  }

  onChangeMonth(date) {
    const { search } = this.props;
    this.setState({
      year: date.get('year'),
      month: date.get('month'),
    }, () => search(date));
  }

  openModal(startDate, endDate) {
    const { searchDetails } = this.props;
    this.setState({
      isModalOpen: true,
      startDate,
      endDate,
    }, () => searchDetails(startDate, endDate));
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  renderModal() {
    const { isModalOpen, startDate, endDate } = this.state;
    const { textDetails, itemsDetails = [], loading, itemsDetailsStatistic } = this.props;

    return (
      <ModalDialog show={isModalOpen}
                   onHide={this.closeModal}
                   bsSize="lg"
                   className="analytics-history__modal">
        <ModalHeader closeButton={true}>
          <div className="analytics-history__modal-header">
            <div>
              <div><FormattedMessage id="analytics_history_title"/></div>
              <div>
                <FormattedDate value={startDate}/>
                {endDate && <React.Fragment>{' - '}<FormattedDate value={endDate}/></React.Fragment>}
              </div>
            </div>
            <SearchBlock/>
          </div>
        </ModalHeader>
        <ModalBody>
          {loading
            ? <Spinner/>
            : <AnalyticsHistoryUsers users={itemsDetails}
                                     textDetails={textDetails}
                                     statistic={itemsDetailsStatistic}/>
          }
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.closeModal} bsSize="sm">
            <FormattedMessage id="common_cancel"/>
          </Button>
        </ModalFooter>
      </ModalDialog>
    );
  }
}

AnalyticsHistory.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.arrayOf(PROP_TYPES_HISTORY_ITEM).isRequired,
  itemsDetails: PropTypes.arrayOf(PROP_TYPES_HISTORY_DETAILS_ITEM).isRequired,
  itemsDetailsStatistic: PROP_TYPES_HISTORY_STAT.isRequired,
  search: PropTypes.func.isRequired,
  searchDetails: PropTypes.func.isRequired,
  text: PropTypes.string,
  textDetails: PropTypes.string,
  locale: PropTypes.oneOf(['de', 'en', 'fr']),
};

import { createSelector } from 'reselect';

export const getBulkLoadsState = (state) => state.bulkLoads;
export const getBulkLoadsError = createSelector([getBulkLoadsState], (state) => state.error);
export const getBulkLoadsLoading = createSelector([getBulkLoadsState], (state) => state.loading);
export const getBulkLoadsRefreshing = createSelector([getBulkLoadsState], (state) => state.refreshing);
export const getBulkLoadsTextBlocks = createSelector([getBulkLoadsState], (state) => state.textBlocks);
export const getBulkLoadsResultsRaw = createSelector([getBulkLoadsState], (state) => state.results);
export const getBulkLoadsSearchParams = createSelector([getBulkLoadsState], (state) => state.searchParams);
export const getBulkLoadsResultsCount = createSelector([
  getBulkLoadsResultsRaw,
], (results) => results ? results.totalCount : 0);
export const getBulkLoadsAverageCompletionTime = createSelector([
  getBulkLoadsState
], (state) => state.averageCompletionTime);

const getPathname = () => window.location.pathname;
export const getBulkLoadsListId = createSelector([getPathname], (pathname) => {
  const match = pathname.match(/^\/bulkloads\/(.+)$/);
  return match ? decodeURIComponent(match[1]) : null;
});

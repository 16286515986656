import update from 'immutability-helper';
import { chain, get, isObject, flow, last, cloneDeep, size } from 'lodash';
import { v4 } from 'uuid';

import rearrange from './rearrangeNodes';
import { WORKFLOW_TREE_START_NODE } from '../../constants';
import updateIsConditional from './updateIsConditional';

export default function WorkflowTreeNodes({ nodes: initialNodes = [] }) {
  let nodes = initialNodes;
  let positions = {};

  /**
   * Check it the given node / node id exists
   *
   * @param   {String/Object}  node  Node or node id
   *
   * @return  {Boolean}
   */
  function hasNode(node) {
    const id = isObject(node)
      ? node.id
      : node;

    return nodes.findIndex((n) => n.id === id) > -1;
  }

  /**
   * Add the given node to nodes
   *
   * @param   {Object}  node  Node to add
   *
   * @return  {WorkflowTreeNodes} this This instance for fluent interface
   */
  function addNode(node) {
    if (hasNode(node)) {
      throw new Error(`Node with id ${node.id} already added!`);
    }

    nodes = update(nodes, {
      $push: [node]
    });

    return this;
  }

  /**
   * Remove the given node if it exists
   *
   * @param   {String/Object}  node  Node or node id to remove
   *
   * @return  {WorkflowTreeNodes} this This instance for fluent interface
   */
  function removeNode(node) {
    const id = isObject(node)
      ? node.id
      : node;

    const index = nodes.findIndex((n) => n.id === id);
    if (index < 0) {
      return nodes;
    }

    nodes = flow([
      // Remove the given node
      (data) => update(data, { $splice: [[index, 1]] }),
      // Remove the node from connections of other nodes
      (data) => {
        return data
          .reduce((result, n, index) => {
            const connections = get(n, 'connections', []).filter((c) => c.target !== id);

            return update(result, {
              [index]: {
                connections: { $set: connections }
              }
            });
          }, data);
      },
      updateIsConditional
    ])(nodes);

    return this;
  }
  /**
   * Replace (or add) the given node
   *
   * @param   {String/Object}  node  Node to replacee
   *
   * @return  {WorkflowTreeNodes} this This instance for fluent interface
   */
  function replaceNode(node) {
    const index = nodes.findIndex((n) => n.id === node.id);

    nodes = index > -1
      ? update(nodes, { $splice: [[index, 1, node]] })
      : update(nodes, { $push: [node] });

    return this;
  }

  /**
   * Duplicate the given node an generate a new id / title
   *
   * @param   {String/Object}  node  Node to duplicate
   *
   * @return  {WorkflowTreeNodes} this This instance for fluent interface
   */
  function duplicateNode(node) {
    const id = `nd${last(v4().split('-'))}`;
    const duplicate = {
      ...cloneDeep(node),
      id,
      connections: [],
      title: {
        de: `${get(node, 'title.de')}-DUPLICATE`,
        fr: `${get(node, 'title.fr')}-DUPLICATE`,
        en: `${get(node, 'title.en')}-DUPLICATE`,
        it: `${get(node, 'title.it')}-DUPLICATE`
      }
    };

    nodes = update(nodes, { $push: [duplicate] });

    return this;
  }

  /**
   * Get current nodes
   *
   * @return  {Array} nodes Array of nodes
   */
  function getNodes() {
    return nodes;
  }

  /**
   * Get current positions
   *
   * @return  {Object} positions Node positions
   */
  function getPositions() {
    return positions;
  }

  /**
   * [setNodes description]
   *
   * @param   {Array}  nodes Nodes to set
   *
   * @return  {WorkflowTreeNodes} this This instance for fluent interface
   */
  function setNodes(n = []) {
    nodes = n;

    return this;
  }

  /**
   * Return the node count
   *
   * @return  {Number} count Node count
   */
  function count() {
    return size(nodes);
  }

  /**
   * Connect target to source (set target as child of source)
   *
   * @param   {Object}  source  Source node
   * @param   {Object}  target  Target node
   *
   * @return  {WorkflowTreeNodes} this This instance for fluent interface
   */
  function connectNode({ source, target, ...rest }) {
    const index = nodes.findIndex((n) => n.id === source);
    const connections = [
      ...get(nodes, `${index}.connections`, []),
      {
        id: `connection-${source}-${target}`,
        source,
        target,
        ...rest
      }
    ];

    nodes = flow(
      (data) => update(data, { [index]: { connections: { $set: connections } } }),
      updateIsConditional
    )(nodes);

    return nodes;
  }

  /**
   * Remove the connecton between source and target
   *
   * @param   {Object}  source  Source node
   * @param   {Object}  target  Target node
   *
   * @return  {WorkflowTreeNodes} this This instance for fluent interface
   */
  function disconnectNode({ source, target }) {
    const index = nodes.findIndex((n) => n.id === source);
    const connections = get(nodes, `${index}.connections`, []).filter((c) => c.target !== target);

    nodes = flow(
      (data) => update(data, { [index]: { connections: { $set: connections } } }),
      updateIsConditional
    )(nodes);

    return this;
  }

  /**
   * Rearange nodes and its connected nodes (recursively)
   *
   * @param {Number} containerHeight Full height of the container
   * @param {Number} containerWidth  Full witdh of the container
   *
   * @return  {WorkflowTreeNodes} this This instance for fluent interface
   */
  function rearrangeNodes({
    containerHeight,
    containerWidth
  }) {
    const ids = chain(nodes)
      .find((n) => n.type === WORKFLOW_TREE_START_NODE)
      .get('connections', [])
      .map((c) => c.target)
      .value();

    positions = rearrange({
      ids,
      containerHeight,
      containerWidth,
      layer: 1,
      nodes,
      result: {
        start: {
          layer: 0,
          space: containerHeight,
          x: 0,
          y: containerHeight / 2
        }
      }
    });

    return this;
  }

  return Object.freeze({
    has: hasNode,
    add: addNode,
    remove: removeNode,
    replace: replaceNode,
    duplicate: duplicateNode,
    getNodes,
    getPositions,
    setNodes,
    count,
    rearrange: rearrangeNodes,
    connect: connectNode,
    disconnect: disconnectNode
  });
}

import React from 'react';
import PropTypes from 'prop-types';
import { get, findKey, noop, isNil } from 'lodash';
import moment from 'moment';
import { Select, Toggle } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';

import Add from './Add';
import Current from './Today';
import Subtract from './Subtract';
import Fixed from './Fixed';
import Flex from '../../../../../packages/Workguide/components/Form/Field/Date/Configuration/Flex';
import JmesInput from './Jmes';

const components = {
  add: Add,
  today: Current,
  subtract: Subtract,
  fixed: Fixed,
  flex: Flex
};

const typeFromValue = {
  add: ({ value = '' }) => value.startsWith('add'),
  subtract: ({ value = '' }) => value.startsWith('subtract'),
  today: ({ value = '' }) => value === 'today',
  flex: ({ value = '' }) => value.startsWith('flex'),
  fixed: ({ value = '' }) => moment(value, moment.ISO_8601, true).isValid(),
};

function getSelectedTypeFromValue({ language, value }) {
  return findKey(typeFromValue, (check) => check({ language, value }));
}

class DateFieldDateValue extends React.Component {
  constructor(props) {
    super(props);

    const { value } = props;

    this.state = {
      isJmes: value.startsWith('jmes:'),
      selectedType: getSelectedTypeFromValue({ value })
    };

    this.onChange = this.onChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onIsJmesChange = this.onIsJmesChange.bind(this);
    this.onMigrate = this.onMigrate.bind(this);
  }

  /**
   * Handle value change
   *
   * @param   {String}  value  Value from input
   *
   * @return  void
   */
  onChange(value) {
    const {
      id,
      onChange
    } = this.props;

    onChange(id, value);
  }

  /**
   * Handle type select change
   *
   * @param   {String}  id        Form element id
   * @param   {String}  value     Selected value
   *
   * @return  void
   */
  onTypeChange(id, value) {
    this.onChange();
    this.setState({ selectedType: value });
  }

  /**
   * Enable / disable jmes input for the given language
   *
   * @param   {String}   id        Form element id
   * @param   {Boolean}  value     Selected value
   *
   * @return  void
   */
  onIsJmesChange(id, value) {
    // Unset current default value
    this.onChange();
    this.setState({ selectedType: undefined });
    this.setState({ isJmes: value });
  }

  /**
   * On migrate function for legacy values (e.g. add, subtract...)
   *
   * @param   {String}  updatedValue  Updated default value
   * @param   {String}  updatedType   Updated type
   *
   * @return  void
   */
  onMigrate({ updatedValue, updatedType }) {
    const { selectedType } = this.state;

    this.onChange(updatedValue);
    if (!isNil(selectedType)) {
      this.setState({ selectedType: updatedType });
    }
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      isJmes,
      selectedType
    } = this.state;
    const { value } = this.props;

    const Component = isJmes
      ? JmesInput
      : get(components, selectedType, () => null);

    return (
      <div className="workguide-component-config--date-field--default-value">
        <div className="d-flex align-items-center">
          <Toggle
            id="isJmes"
            value={isJmes}
            onChange={this.onIsJmesChange}
          />

          <FormattedMessage id="Workguide.Configuration.DateField.EnterJmesPath" />
        </div>

        <div style={{ paddingTop: '0.4em' }} />

        {!isJmes && (
          <Select
            id="selectedType"
            className="pt-2"
            onChange={this.onTypeChange}
            options={[{
              value: 'fixed',
              label: 'Fixes Datum'
            }, {
              value: 'flex',
              label: 'Flexi Date'
            }]}
            value={selectedType}
          />
        )}

        <div style={{ paddingTop: '0.4em' }} />

        <Component
          onChange={this.onChange}
          onMigrate={this.onMigrate}
          value={value}
        />
      </div>
    );
  }
}

DateFieldDateValue.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string
};

DateFieldDateValue.defaultProps = {
  onChange: noop,
  value: ''
};

export default DateFieldDateValue;

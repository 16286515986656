import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DragHandle from '../DragHandle';
import { SortableElement } from 'react-sortable-hoc';
import { get } from 'lodash';

import './SetManagementProductItem.css';
import { ModalBody, ModalDialog, ModalHeader } from '../Common/Modal';
import { TableCell, TableRow } from '../Common/Table';
import { SetManagementProductEdit } from './SetManagementProductEdit';

class Item extends React.Component {
  state = {
    isModalOpen: false,
  };

  render() {
    const { product, domains, bundles } = this.props;
    const domain = domains
      .filter((domain) => get(product, 'domain', []).includes(get(domain, 'value')))
      .map((domain) => get(domain, 'label', ''))
      .join(', ');

    const bundle = bundles
      .filter((bundle) => get(product, 'bundle', []).includes(get(bundle, 'value')))
      .map((bundle) => get(bundle, 'label', ''))
      .join(', ');

    return (
      <TableRow className="set-management-product-item SortableHOCItem">
        <TableCell className="set-management-product-item__name">
          {product.name}
        </TableCell>
        <TableCell className="set-management-product-item__domain">
          {domain}
        </TableCell>
        <TableCell className="set-management-product-item__domain">
          {bundle}
        </TableCell>
        <TableCell className="set-management-product-item__unit-price">
          {product.unitPrice && <FormattedNumber value={product.unitPrice}/>}
        </TableCell>
        <TableCell className="set-management-product-item__control">
          <span className="icon-025-edit" onClick={this.openModal}/>
          {this.renderModal()}
        </TableCell>
        <TableCell className="set-management-product-item__drag">
          <DragHandle/>
        </TableCell>
      </TableRow>
    );
  }

  renderModal() {
    const { isModalOpen } = this.state;
    const { product, domains, bundles } = this.props;

    return (
      <ModalDialog show={isModalOpen} onHide={this.closeModal}>
        <ModalHeader closeButton={true}>
          <Modal.Title>
            <div className="set-management-product-item__modal-title">{product.name}</div>
            <div>ID: {product.id}</div>
          </Modal.Title>
        </ModalHeader>
        <ModalBody>
          <SetManagementProductEdit
            product={product}
            domains={domains}
            bundles={bundles}
            onSave={this.save}
            onCancel={this.closeModal}
          />
        </ModalBody>
      </ModalDialog>
    );
  }

  openModal = () => this.setState({ isModalOpen: true });
  closeModal = () => this.setState({ isModalOpen: false });
  save = (product) => {
    const { onChange } = this.props;
    onChange(product);
    this.closeModal();
  };
}

export const SetManagementProductItem = SortableElement(Item);
SetManagementProductItem.propTypes = {
  product: PropTypes.object.isRequired,
  domains: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

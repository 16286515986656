import { chain, get, minBy, maxBy, size, set } from 'lodash';

/**
 * Rearange the given nodes and its children (recursively)
 *
 * @param {Array}  ids             Array of node ids to rearrange
 * @param {Number} containerHeight Full height of the container
 * @param {Number} containerWidth  Full witdh of the container
 * @param {Number} layer           Current layer (horizontal)
 * @param {Array}  nodes           Array containing all nodes
 * @param {Object} result          Result object to set new positions on
 *
 * @return  {Object} result Result containing updated positions of all nodes and children
 */
export default function rearrangeNodes({
  ids,
  containerHeight,
  containerWidth,
  layer = 1,
  nodes = [],
  result
}) {
  // First calculate all positions for the given ids
  ids.forEach((id, index) => {
    // Get all parent positions
    const parentPositions = chain(nodes)
      .filter((n) => get(n, 'connections', []).map((c) => c.target).includes(id))
      .map((node) => get(result, node.id))
      .compact()
      .value();

    // Get min / max from parent positions
    const min = minBy(parentPositions, 'y');
    const max = maxBy(parentPositions, 'y');
    const space = size(parentPositions) > 1
      // If there are multiple parents, calculate the space between them
      ? max.y - min.y
      // If there is only one parent, use whole space of parent
      : min.space;

    // Calcualte the space per node
    const spacePerNode = space / size(ids);
    const y = size(parentPositions) > 1
      // If there are multiple parents, calculate offset from top element by subtracting half of the available space
      ? min.y + (space / 2)
      // If there is only one parent calculate offset by space per node
      : min.y - (min.space / 2) + (spacePerNode / 2) + (spacePerNode * index);

    // Get the current layer of this node from positions (if set)
    const currentLayer = get(result, `${id}.layer`, 0);
    // Check if the current layer is greater than the given one. Use the current if so as using a lower layer would move the node backwards
    const l = currentLayer > layer
      ? currentLayer
      : layer;

    const x = containerWidth * (l / 7);

    const position = {
      layer: l,
      space: spacePerNode,
      x,
      y
    };

    set(result, id, position);
  });

  // Rearrange children for each node as parent positions are now known
  ids.forEach((id) => {
    const node = nodes.find((n) => n.id === id);
    const ids = get(node, 'connections', []).map((c) => c.target);

    rearrangeNodes({
      containerHeight,
      containerWidth,
      ids,
      layer: layer + 1,
      nodes,
      result
    });
  });

  return result;
}

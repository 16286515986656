import React from 'react';
import PropTypes from 'prop-types';
import _, { get } from 'lodash';
import { ListGroupItem } from 'react-bootstrap';
import update from 'immutability-helper';

import { ConditionList } from './Condition/index';

import ActionList from './Action/List';

class ListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      addCondition: false
    };

    this.expand = this.expand.bind(this);
    this.collapse = this.collapse.bind(this);
    this.onConditionsChange = this.onConditionsChange.bind(this);
    this.onActionsChange = this.onActionsChange.bind(this);
  }

  /**
   * Expand the list
   *
   * @return void
   */
  expand() {
    this.setState({ expanded: true });
  }

  /**
   * Collapse the list
   *
   * @return void
   */
  collapse() {
    this.setState({ expanded: false });
  }

  /**
   * Show condition form
   *
   * @return void
   */
  onConditionAdd() {
    this.setState({ addCondition: true });
  }

  /**
   * Remove the condition on the given index
   *
   * @param  {Number} index Index
   *
   * @return void
   */
  onConditionRemove(index) {
    const { dependency, onChange } = this.props;

    if (index > -1) {
      const updated = update(dependency, {
        conditions: { $splice: [[index, 1]] }
      });

      onChange(updated);
    }
  }

  /**
   * Update conditions on dependency
   *
   * @param  {Array} conditions Array of conditions
   *
   * @return void
   */
  onConditionsChange(conditions) {
    const { dependency, onChange } = this.props;
    const current = get(dependency, 'conditions.0');

    const updated = ['and', 'or'].includes(get(current, 'operation'))
      ? update(dependency, { conditions: { 0: { conditions: { $set: conditions } } } })
      : update(dependency, { conditions: { $set: conditions } });

    onChange(updated);
  }

  /**
   * Update actions on dependency
   *
   * @param  {String} type    Type (if/else)
   * @param  {Array}  actions Array of actions
   *
   * @return void
   */
  onActionsChange(type, actions) {
    const { dependency, onChange } = this.props;

    const updated = update(dependency, {
      actions: {
        [type]: { $set: actions }
      }
    });

    onChange(updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      dependency,
      language,
      target,
      style,
      onRemove
    } = this.props;

    // Entry point for recursion is conditions[0] (A dependency always contains only one condition)
    const condition = _.get(dependency, 'conditions[0]');

    return(
      <ListGroupItem style={style}>
        <div>
          <div className="pull-right">
            <span onClick={onRemove}><i className="glyphicon glyphicon-remove" /></span>
          </div>
        </div>

        <div>
          <strong>Wenn</strong>
          <ConditionList
            target={target}
            conditions={(['and', 'or'].includes(condition.operation)) ? condition.conditions : dependency.conditions}
            operation={condition.operation}
            onChange={(conditions) => this.onConditionsChange(conditions, condition.operation)}
          />

          <div style={{ paddingBottom: '10px' }}/>

          <ActionList
            label="Dann"
            language={language}
            target={target}
            actions={_.get(dependency, 'actions.if', [])}
            onChange={(actions) => this.onActionsChange('if', actions)}
          />

          <ActionList
            label="Sonst"
            language={language}
            target={target}
            actions={_.get(dependency, 'actions.else', [])}
            onChange={(actions) => this.onActionsChange('else', actions)}
          />
        </div>
      </ListGroupItem>
    );
  }
}

ListItem.propTypes = {
  target: PropTypes.object.isRequired,
  dependency: PropTypes.object.isRequired,
  language: PropTypes.string,
  style: PropTypes.object,
  codes: PropTypes.object,
  consultants: PropTypes.array,
  productGroups: PropTypes.array,
  onChange: PropTypes.func,
  onRemove: PropTypes.func
};

ListItem.defaultProps = {
  language: 'de',
  style: {},
  codes: {},
  consultants: [],
  productGroups: [],
  onChange: _.noop,
  onRemove: _.noop,
};

export default ListItem;

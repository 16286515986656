import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Grid, Row, Col, Tabs, Tab } from 'react-bootstrap';

import { List as TargetCustomerGroupList } from '../components/CustomerBook';

import * as codeActions from '../actions/CodeActions';

class CustomerBook extends React.Component {
  constructor(props) {
    super(props);

    const { codeActions } = props;

    codeActions.codesRequest(['targetCustomerGroup']);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { language, codes, codeActions } = this.props;
    const {
      targetCustomerGroup = []
    } = _.get(codes, 'groups', {});

    return(
      <Grid fluid={true}>
        <Row>
          <Col lg={12}>
            <Tabs id="customer-book-config">
              <Tab eventKey="targetCustomerGroup" title="Kundenzielgruppe">
                <TargetCustomerGroupList
                  language={language}
                  codes={targetCustomerGroup}
                  onSave={codeActions.codeSaveRequest}
                  onDelete={codeActions.codeDeleteRequest}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Grid>
    );
  }
}

CustomerBook.propTypes = {
  language: PropTypes.string,
  codes: PropTypes.object
};

CustomerBook.defaultProps = {
  language: 'de',
  codes: { groups: {} }
};

function mapStateToProps(state, ownProps) {
  return {
    langauge: state.login.language,
    codes: state.codes
  };
}

function mapDispatchToProps(dispatch) {
  return { codeActions: bindActionCreators(codeActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerBook);

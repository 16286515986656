import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Select from 'react-select';
import { Button } from 'react-bootstrap';

import Operations from '../Operations/Operations.js';
import CriteriaField from '../CriteriaField/CriteriaField';

const Spacer = () => <div style={{ paddingBottom: '10px' }}></div>;

class ConditionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      source: _.get(props, 'condition.criteria.source'),
      operation: _.get(props, 'condition.operation'),
      criteria: _.get(props, 'condition.criteria')
    };

    this.onSourceChange = this.onSourceChange.bind(this);
    this.onOperationChange = this.onOperationChange.bind(this);
    this.onCriteriaChange = this.onCriteriaChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Handle source change.
   * Reset operation and criteria
   *
   * @param  {Object} selected Selected item from dropdown
   *
   * @return void
   */
   onSourceChange(selected) {
     this.setState({
       source: selected.value,
       operation: undefined,
       criteria: undefined
     });
   }

   /**
    * Handle operation change
    *
    * @param  {String} operation Selecte operation
    *
    * @return void
    */
   onOperationChange(operation) {
     this.setState({ operation });
   }

   /**
    * Handle criteria change
    *
    * @param  {Mixed} criteria Criteria values
    *
    * @return void
    */
   onCriteriaChange(criteria) {
     this.setState({ criteria });
   }

   /**
    * Handle form submit.
    * Prepare new conditions and pass it to callback
    *
    * @return {[type]} [description]
    */
   onSubmit() {
     const { operation, criteria } = this.state;
     const { onSubmit } = this.props;
     const condition = {
       operation,
       criteria
     };

     this.setState({ edit: false });

     onSubmit(condition);
   }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      source,
      operation,
      criteria
    } = this.state;

    const {
      language,
      components,
      codes,
      consultants,
      productGroups,
      onCancel
    } = this.props;

    return(
      <div className="workguide-dependency-condition-form" style={{ backgroundColor: '#e8eaed', padding: '10px' }}>
        <strong>Wenn:</strong>
        <Select
          options={components.map(i => ({ value: _.get(i, 'props.id'), item: i, label: _.get(i, `props.title.${language}`) }))}
          value={source}
          onChange={this.onSourceChange}
        />

        <Spacer />

        {(source) ? (
          <Operations
            language={language}
            target={{}}
            source={components.find(i => _.get(i, 'props.id') === source)}
            selected={operation}
            onChange={this.onOperationChange}
          />
        ) : null}

        <Spacer />
        <CriteriaField
          language={language}
          target={{}}
          source={components.find(i => _.get(i, 'props.id') === source)}
          criteria={criteria}
          onChange={this.onCriteriaChange}
          codes={codes}
          consultants={consultants}
          productGroups={productGroups}
          edit
          operation={operation}
        />

        <Spacer />

        <Button
          bsStyle="primary"
          disabled={!operation || !criteria}
          onClick={this.onSubmit}
        >
          <i className="glyphicon glyphicon-ok" />
        </Button>

        <Button
          onClick={onCancel}
        >
          <i className="glyphicon glyphicon-remove" />
        </Button>
      </div>
    );
  }
}

ConditionForm.propTypes = {
  target: PropTypes.object.isRequired,
  condition: PropTypes.object,
  language: PropTypes.string,
  components: PropTypes.array,
  codes: PropTypes.object,
  consultants: PropTypes.array,
  productGroups: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

ConditionForm.defaultProps = {
  language: 'de',
  components: [],
  codes: {},
  consultants: [],
  productGroups: [],
  onSubmit: _.noop,
  onCancle: _.noop
};

function mapStateToProps(state, ownProps) {
  const { components } = state.workguide.workguide;

  return {
    language: state.login.language,
    components,
    consultants: state.workguide.consultants,
    codeGroups: state.workguide.codeGroups,
    codes: state.workguide.codes,
    productGroups: state.workguide.productGroups
  };
}

export default connect(mapStateToProps)(ConditionForm);

import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { EndpointName } from '../../constants/EndpointName';
import { WORKGUIDE_STATUS_ENTITIY_CODE_LOAD } from '../../actions/WorkguideStatusActions';

export function* workguideStatusGetEntityCodesSaga() {
  try {
    yield put(WORKGUIDE_STATUS_ENTITIY_CODE_LOAD.pending());

    const { data: codes } = yield call(axios, { url: `${EndpointName.ENTITY_CODE}/?eq(group,string:workguideStatus)&limit(9999)` });

    if (!codes) throw new Error('No codes received');
    const codesList = ['workguideStatus-7', 'workguideStatus-8', 'workguideStatus-3', 'workguideStatus-4', 'workguideStatus-5', 'workguideStatus-6', 'workguideStatus-13'];
    const codesFiltered = codes.filter(x => codesList.includes(x.id));

    yield put(WORKGUIDE_STATUS_ENTITIY_CODE_LOAD.success({ codes: codesFiltered }));
  } catch (error) {
    yield put(WORKGUIDE_STATUS_ENTITIY_CODE_LOAD.failure(error));
  }
}

import { clearRemovedFiles } from '../../services/Promotions';
import { REMOVE_PROMOTIONS_ACTIONS } from '../../actions/PromotionActions';

export function RemovePromotionsReducer(state, action) {
  switch (action.type) {
    case REMOVE_PROMOTIONS_ACTIONS.PENDING:
      return {
        ...state,
        promotions: state.promotions.filter((it) => it.id !== action.meta.promotion.id),
      };
    case REMOVE_PROMOTIONS_ACTIONS.SUCCESS:
      const { updatedSections } = action.data;
      return {
        ...state,
        files: clearRemovedFiles(state.files, state.promotions),
        checklistSections: [...updatedSections],
      };
    default:
      return state;
  }
}

import { call, put } from 'redux-saga/effects';
import {
  LOAD_SURCHARGE_REALESTATETYPE_ACTIONS,
  SAVE_SURCHARGE_REALESTATETYPE_ACTIONS,
} from '../../actions/FinancingSurchargeRealestatetypeActions';
import { createSurchargeRealestatetypesSaga } from './createSurchargeRealestatetypesSaga';
import { removeSurchargeRealestatetypesSaga } from './removeSurchargeRealestatetypesSaga';
import { updateSurchargeRealestatetypesSaga } from './updateSurchargeRealestatetypesSaga';

export function* saveFinancingSurchargeRealestatetype({data: { items }}) {
  try {
    yield put(SAVE_SURCHARGE_REALESTATETYPE_ACTIONS.pending());

    yield call(createSurchargeRealestatetypesSaga, items);
    yield call(removeSurchargeRealestatetypesSaga, items);
    yield call(updateSurchargeRealestatetypesSaga, items);

    yield put(SAVE_SURCHARGE_REALESTATETYPE_ACTIONS.success());
    yield put(LOAD_SURCHARGE_REALESTATETYPE_ACTIONS.request());
  } catch(error) {
    yield put(SAVE_SURCHARGE_REALESTATETYPE_ACTIONS.failure(error));
  }
}

import { sortByAll } from '../../utils';
import { mapBasicProductToApp } from './BasicProductsMapper';

export function mapBasicProductsToApp(language, basicProducts, basicCategories, basicAddition, basicDocuments) {
  return basicProducts
    .map((basicProduct) => mapBasicProductToApp(
      language,
      basicProduct,
      basicCategories,
      basicAddition,
      basicDocuments,
    ))
    .sort(sortByAll(
      (product) => product.orderCategory,
      (product) => product.order,
    ));
}

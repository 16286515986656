import { LOAD_PROMOTIONS_ACTIONS } from '../../actions/PromotionActions';
import { mapChecklistSections } from '../../services/Promotions';

const initialState = ({
  promotions: [],
  checklistSections: [],
  config: {},
  schema: undefined,
  modules: [],
  customerSegments: [],
  loading: true,
  error: undefined,
  filter: {
    show: 'all',
    displayCount: 3,
  },
  files: {},
});


export function LoadPromotionsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_PROMOTIONS_ACTIONS.PENDING:
      return {...state, loading: true, error: undefined};
    case LOAD_PROMOTIONS_ACTIONS.SUCCESS:
      const {
        promotions,
        checklist,
        files,
        config,
        module,
        customerSegments,
        schema,
      } = action.data;
      return {
        ...state,
        loading: false,
        error: undefined,
        modules: [module],

        promotions,
        checklistSections: mapChecklistSections(checklist, config),
        files: files.reduce((result, file) => ({
          ...result,
          [file.id]: {
            ...result[file.id],
            ...file,
          },
        }), state.files),
        config,
        customerSegments,
        schema,
      };
    case LOAD_PROMOTIONS_ACTIONS.FAILURE:
      return {...state, loading: false, error: action.data};
    default:
      return state;
  }
}

export const EndpointName = {
  ACCOUNT: '/account/account',

  ANALYTICS_ACTIVE_SESSIONS: '/analytics/auditing-sessions-active',
  ANALYTICS_HISTORY: '/analytics/auditing-users-historical',
  ANALYTICS_HISTORY_DAILY: '/analytics/auditing-users-daily-summary',
  ANALYTICS_REQUESTS: '/analytics/auditing-requests',
  ANALYTICS_BANK_INFO: '/analytics/bank-info',
  ANALYTICS_USER_SUMMARY: '/analytics/auditing-entris-bank-user-summary',
  ANALYTICS_WORKGUIDE_NETTED_ACCOUNTS: '/analytics/workguide-netted-accounts',

  BASIC_INTERMEDIATEVIEW: '/basic/intermediateview',
  BASIC_PRODUCT: '/basic/product',
  BASIC_PRODUCT_ADDITION: '/basic/productaddition',
  BASIC_PRODUCT_CATEGORY: '/basic/productcategory',
  BASIC_PRODUCT_DOCUMENT: '/basic/productdocument',
  BASIC_PRODUCT_BUNDLE_BUNDLE: '/basic/productbundle/bundle',
  BASIC_PRODUCT_BUNDLE_PRICING: '/basic/productbundle/pricing',
  BASIC_PRODUCT_BUNDLE_PRODUCT: '/basic/productbundle/product',
  BASIC_PRODUCT_GROUP: '/basic/productgroup',

  CHECKLIST: '/checklist/configuration',
  CHECKLIST_ITEM: '/checklist/config/standard-set',
  CHECKLIST_SECTION: '/checklist/config/section',

  CORE_APP: '/core/app',
  CORE_CONFIG: '/core/config',
  CORE_EXTERNAL_LINK: '/core/external-link',
  CORE_MODULE: '/core/module',
  CORE_SCREEN: '/core/screen',
  CORE_TEXT_BLOCK: '/core/textblock',
  CORE_JOB_PROCESSING: '/core/job-processing/action',

  EVENT_STATUS_WORKER_JOB_PROCESSING: '/event/status/worker/job-processing',

  ENTITY_CODE: '/entity/code',
  ENTITY_CONTRACT: '/entity/contract',
  ENTITY_MAPPING: '/entity/codemapping',
  EMAIL_SUBSCRIPTIONS: '/email/subscriptions',
  FILE: '/file',

  FINANCING_DURATION: '/financing/duration',
  FINANCING_FORWARD: '/financing/forward',
  FINANCING_HOLIDAYS: '/financing/holiday',
  FINANCING_RATE: '/financing/rate',
  FINANCING_REALESTATETYPE: '/financing/realestatetype',
  FINANCING_REFI_INTEREST_RATE: '/financing/refi-interest-rate',
  FINANCING_SURCHARGE_REALESTATETYPE: '/financing/surcharge-realestatetype',
  FINANCING_PROFILING: '/financing/profiling',
  FINANCING_LOAN_PRODUCT: '/financing/loanproduct',

  INVESTMENT_DOCUMENT: '/investment/document',
  INVESTMENT_PRODUCT: '/investment/product',

  LANGUAGE: '/i18n/language',

  LOADER_LOAD: '/loader/load',
  LOADER_ACTION: '/loader/action',
  LOADER_REF_ERROR: '/loader/ref-error',
  LOADER_TRIGGER: '/loader/trigger',

  PERSON_CONSULTANT: '/person/consultant',
  PERSON_CUSTOMER: '/person/customer',

  PROMOTIONS: '/pos/promotion',

  PROVISION_WORKGUIDE: '/provision/workguide',

  WORK_LEAD_MANAGEMENT: '/work/leadmanagement',
  WORK_UI_NOTES: '/work/uinotes',
  WORK_ACTIVITY: '/work/activity',
};

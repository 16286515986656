import React from 'react';
import { FormGroup, Col, FormControl, InputGroup } from 'react-bootstrap';
import { get, isNaN } from 'lodash';

export class IsLeInput extends React.Component {
  constructor(){
    super()
    this.onChange = this.onChange.bind(this);
    this.getCurrentValue = this.getCurrentValue.bind(this);
  }
  onChange(event){
    const { onChange } = this.props
    let value = get(event, 'target.value', '');
    value = parseFloat(value);
    if(!isNaN(value)) {
      onChange([value])
    }
  }
  getCurrentValue() {
    const { validation, component } = this.props;
    return component.props.validations[validation.key].isLe[0];
  }
  render(){
    return (
      <FormGroup>
      <Col sm={10}>
        <Col>
          <InputGroup>
            <InputGroup.Addon>Max</InputGroup.Addon>
            <FormControl
              value={this.getCurrentValue()}
              step="0.01" 
              type="number"
              aria-label="min"
              onChange={this.onChange}
            />
          </InputGroup>
        </Col>

      </Col>
    </FormGroup>
    )
  }
}
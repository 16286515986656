import './ModuleItem.css';

import React, { Component } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { FormControl } from 'react-bootstrap';
import DragHandle from '../DragHandle';

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.item.name[props.language],
    };

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  render() {
    const { item } = this.props;
    const { value } = this.state;

    return (
      <div className="module-item SortableHOCItem">
        <div className="module-item__title">{item.key}</div>
        <div className="module-item__value">
          <FormControl
            type="text"
            name="itemName"
            value={value}
            onChange={this.onChange}
            onBlur={this.onBlur}
          />
        </div>
        <div className="module-item__action">
          <DragHandle/>
        </div>
      </div>
    );
  }

  onChange(event) {
    this.setState({ value: event.target.value })
  }

  onBlur() {
    const { edit, id, item, language } = this.props;
    const { value } = this.state;

    if (!value) {
      this.setState({ value: item.name[language] });
      return;
    }

    if (value !== item.name[language]) {
      edit(id, value);
    }
  }
}

const SortableItem = SortableElement(Item);

export default class MyElement extends Component {
  render() {
    const {
      id, item, language, edit,
    } = this.props;
    return (
      <SortableItem
        index={id}
        id={id}
        item={item}
        edit={edit}
        language={language}
      />
    );
  }
}

import './PermissionGroupTable.css';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SortableTable } from '../Common/SortableTable';
import { Pagination } from '../Common/Pagination';
import { PERMISSION_GROUP_PAGE_SIZE } from '../../constants/PermissionGroupConstants';
import { PermissionGroupItem } from './PermissionGroupItem';

export class PermissionGroupTable extends React.PureComponent {
  render() {
    return (
      <div className="permission-group-table">
        {this.renderContent()}
        {this.renderPagination()}
      </div>
    );
  }

  renderContent() {
    const { items, searchParams } = this.props;

    return (
      <div className="permission-group-table__content">
        {items.length > 0 && <SortableTable
          items={items}
          sortableFields={SORTABLE_FIELDS}
          renderItem={this.renderItem}
          className="permission-group-table__table"
          onSort={this.sort}
          sortField={searchParams.sortField}
          sortType={searchParams.sortDirection}
          isExternalSortField={true}
        />}
      </div>
    );
  }

  renderPagination() {
    const { consultants, consultantsTotalCount, searchParams } = this.props;
    if (!consultants) {
      return null;
    }

    const activePage = searchParams.pageNumber ? searchParams.pageNumber : 1;
    const countPages = Math.ceil(consultantsTotalCount / PERMISSION_GROUP_PAGE_SIZE);
    if (countPages < 2) {
      return null;
    }

    return (
      <div className="permission-group-table__pagination">
        <Pagination
          prev={true}
          next={true}
          first={true}
          last={true}
          boundaryLinks={true}
          ellipsis={true}
          items={countPages}
          maxButtons={7}
          activePage={activePage}
          onSelect={this.onChangePage}
        />
      </div>
    );
  }

  renderItem = (item) => {
    const { codes } = this.props;
    return (<PermissionGroupItem key={item.id} item={item} entityCodes={codes}/>)
  };

  onChangePage = (page) => {
    const { search, searchParams } = this.props;
    search({
      ...searchParams,
      pageNumber: page,
    });
  };

  sort = (field, direction) => {
    const { search, searchParams } = this.props;
    search({
      ...searchParams,
      sortField: field,
      sortDirection: direction,
    });
  };
}

PermissionGroupTable.propTypes = {
  items: PropTypes.array,
  itemsTotalCount: PropTypes.number.isRequired,
  search: PropTypes.func.isRequired,
};

const SORTABLE_FIELDS = [
  {
    key: 'firstName',
    label: <FormattedMessage id="permissionGroup_first_name"/>,
    sortFn: () => undefined,
  },
  {
    key: 'lastName',
    label: <FormattedMessage id="permissionGroup_name"/>,
    sortFn: () => undefined,
  },
  {
    key: 'Username',
    label: <FormattedMessage id="permissionGroup_username"/>,
    sortFn: () => undefined,
  },
  {
    key: 'tenant',
    label: <FormattedMessage id="permissionGroup_tenant"/>,
    sortFn: () => undefined,
  },
  {
    key: 'empty',
    label: '',
    sortFn: undefined,
  },
];

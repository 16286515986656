import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import { handleGravitonError } from '../utils';
import PageContainer from '../components/Common/PageContainer';
import leadHistoryActions from '../actions/LeadHistory/Actions';
import { TableCell, TableRow } from '../components/Common/Table';
import { FormattedDate } from '../components/Common/FormattedDate';
import { SortableTable } from '../components/Common/SortableTable';
import { Pagination } from '../components/Common/Pagination';
import { CONSULTANTS_FETCH_ACTIONS } from '../actions/ConsultantsActions';
import './LeadHistoryPage.css';

const historyTypeMap = {
  LeadSwitchManual: "Beraterwechsel Manuel",
  LeadSwitchImport: "Beraterwechsel Import",
  Massmutation: "Massenmutation"
};

class LeadHistoryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };

    this.pageSize = 25;

    this.renderItem = this.renderItem.bind(this);
    this.getConsultantNameById = this.getConsultantNameById.bind(this);
  }

  componentDidMount() {
    const { leadHistoryActions, fetchConsultants } = this.props;
    leadHistoryActions.leadHistoryRequest();
    fetchConsultants();
  }

  getConsultantNameById(id) {
    const { consultants } = this.props;
    const consultant = get(consultants, 'consultants', []).find(c => get(c, 'id') === id);
    return `${get(consultant, 'firstName')} ${get(consultant, 'lastName')}`;
  }

  renderPagination() {
    const { leadHistory } = this.props;
    const { page } = this.state;

    const pageCount = Math.ceil(get(leadHistory, 'length', 0) / this.pageSize);
    if (pageCount < 2) {
      return null;
    }

    return (
      <div className="ui-notes__pagination">
        <Pagination
          prev={true}
          next={true}
          first={true}
          last={true}
          boundaryLinks={true}
          ellipsis={true}
          items={pageCount}
          maxButtons={7}
          activePage={page}
          onSelect={this.handlePageChange}
        />
      </div>
    );
  }

  renderItem(history) {
    return (
      <TableRow key={history.id} className="lead-history__row">
        <TableCell className="lead-history__createAt">
          {history.createdAt ? <FormattedDate value={history.createdAt} format="DD.MM.YYYY" /> : null}
        </TableCell>
        <TableCell className="lead-history__user">
          {history.consultantId ? this.getConsultantNameById(history.consultantId) : null}
        </TableCell>
        <TableCell className="lead-history__leads">
          {history.modifiedLeadIds
            ? history.modifiedLeadIds.join(', ')
            : null}
        </TableCell>
        <TableCell className="lead-history__type">
          {history.type
            ? get(historyTypeMap, history.type, '')
            : null}
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const { requesting, leadHistory, error } = this.props;
    if (error) {
      toast.error(handleGravitonError(error));
    }
    if (!leadHistory) {
      return null;
    }

    const { page } = this.state;
    const items = leadHistory.slice((page - 1) * this.pageSize, page * this.pageSize);

    const sortableFields = [
      {
        key: 'createdAt',
        label: 'Erstellt Am',
        sortFn: (history) => history.createdAt ? <FormattedDate value={history.createdAt} format="DD.MM.YYYY" /> : null
        ,
      },
      {
        key: 'consultantId',
        label: 'Consultant',
        sortFn: (history) => this.getConsultantNameById(history.consultantId) ? history.consultantId : null,
      },
      {
        key: 'modifiedLeadIds',
        label: 'Geänderte Leads',
        sortFn: (history) => history.modifiedLeadIds ? history.modifiedLeadIds.join(', ') : null,
      },
      {
        key: 'type',
        label: 'Typ',
        sortFn: (history) => history.type ? history.type : null,
      }
    ];

    return (
      <PageContainer
        requesting={requesting}
        title="Lead History"
      >
        <SortableTable
          style={{ paddingTop: '2em' }}
          className="lead-history__table"
          items={items}
          renderItem={this.renderItem}
          sortableFields={sortableFields}
          // onSort={this.handleSortChange}
        />
        {this.renderPagination()}
      </PageContainer>
    );
  }
}

LeadHistoryPage.propTypes = {
  leadHistoryActions: PropTypes.object.isRequired,
  fetchConsultants: PropTypes.object.isRequired,
  requesting: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  leadHistory: PropTypes.array,
  language: PropTypes.string,
  consultants: PropTypes.object
};

LeadHistoryPage.defaultProps = {
  requesting: true,
  error: false,
  leadHistory: [],
  language: 'de',
  consultants: {
    consultants: []
  }
};

function mapStateToProps(state) {
  return {
    requesting: state.leadHistory.leadHistory.requesting,
    consultants: state.consultants.data,
    error: state.leadHistory.leadHistory.error,
    leadHistory: state.leadHistory.leadHistory.data,
    language: state.login.language,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchConsultants: () => dispatch(CONSULTANTS_FETCH_ACTIONS.request()),
    leadHistoryActions: bindActionCreators(leadHistoryActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadHistoryPage);

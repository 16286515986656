import axios from 'axios';

export const GET = 'adminUI/type/GET';
export const GET_COLLATERAL_TYPES = 'adminUI/type/GET_COLLATERAL_TYPES';
export const PUT = 'adminUI/type/PUT';
export const DEL = 'adminUI/type/DEL';
export const SET_TYPES = 'adminUI/type/SET_TYPES';
export const SET_REMOVED = 'adminUI/type/SET_REMOVED';

export function getTypes(type) {
  return (dispatch) => {
    dispatch({
      type: GET,
      meta: { type },
      payload: axios({
        url: `financing/${type}type/?sort(+order)&limit(100)`,
      }),
    });
  };
}

export function getCollateralTypes() {
  return (dispatch) => {
    dispatch({
      type: GET_COLLATERAL_TYPES,
      payload: axios({
        url: '/entity/code/?eq(group,collateralType)&limit(1000)',
      }),
    });
  };
}


export function updateType({ type, value }) {
  return (dispatch) => {
    dispatch({
      type: PUT,
      payload: axios({
        url: `financing/${type}type/${value.id}`,
        method: 'put',
        data: value,
      }),
    });
  };
}

export function deleteType({ type, id }) {
  return (dispatch) => {
    dispatch({
      type: DEL,
      meta: { id, type },
      payload: axios({
        url: `financing/${type}type/${id}`,
        method: 'delete',
      }),
    });
  };
}

export function setTypeData({ value, type }) {
  return dispatch =>
    dispatch({
      type: SET_TYPES,
      typeName: type,
      value,
    });
}

export function setRemovedTypeData({ value, type }) {
  return dispatch =>
    dispatch({
      type: SET_REMOVED,
      typeName: type,
      value,
    });
}

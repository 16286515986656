import { Reducers } from '@evoja-web/redaction';

import { reducer as categoriesReducer } from './Categories';
import { reducer as moduleReducer } from './Module';

export const reducers = Reducers([
  categoriesReducer,
  moduleReducer
]);

export default reducers.get();

import { DefaultValidator } from '@ultritium/validate';
import { get, isUndefined, isString } from 'lodash';


let validator;
const isInternetExplorer = /MSIE|Trident/.test(navigator.userAgent);

/**
 * Get the validator instance
 *
 * @return {DefaultValidator} validator Validator instance
 */
export function getValidator() {
  if (!validator) {
    validator = DefaultValidator();

    // Fuck ie....
    if (!isInternetExplorer) {
      const validationProvider = validator.getProvider('validation');
      const messageProvider = validator.getProvider('message');

      validationProvider.addValidation('isValidCodeId', function(value = '', codes, group) {
        if (isString(value)) value = value.trim();

        // The id must start with '${group}-' to make sure we don't overwrite a code of another group
        if (!value.includes(`${group}-`) || get(value, 'length', 0) <= get(`${group}-`, 'length', 0)) return false;

        // Try to find an existing code with the same id
        const existing = codes.find((code) => get(code, 'id') === value);

        return isUndefined(existing);
      });

      validationProvider.addValidation('isValidCodeNumber', function(value = '', codes = []) {
        const existing = codes.find((code) => get(code, 'number') === value);

        return isUndefined(existing);
      });

      validationProvider.addValidation('noop', function() {
        return true;
      });

      // Add messages
      messageProvider.addMessage('required', 'Dies ist ein Pflichtfeld!');
      messageProvider.addMessage('isValidCodeId', 'Die angegebene ID is ungültig oder wird bereits verwendet!');
      messageProvider.addMessage('isValidCodeNumber', 'Die angegebene Nummer is ungültig oder wird bereits verwendet!');
    }
  }

  return validator;
}
